<cm-select-dialog
    [data]="$data()"
    (selectItem)="onSelectItem($event)"
    (cancel)="cancelDialog()"
    [$title]="'Select asset'"
    [pageFilledStatusChange]="pageFilledStatusChange$"
    [gridSize]="gridSize"
>
    <cm-search cm-filters (valueChange)="searchText$.next($event)" placeholder="Name, description, ID, ..."></cm-search>
</cm-select-dialog>
