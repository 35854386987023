// @ts-strict-ignore
import {Injectable} from "@angular/core"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {Observable, Subject} from "rxjs"

type SelectionFilterFunction<T extends Nodes.Node> = (x: Nodes.Node) => x is T

@Injectable({
    providedIn: "root",
})
export class SelectionService {
    selectedNodes: Nodes.Node[] = []
    private nodeSelectedSource: Subject<readonly [Nodes.Node, number | null]> = new Subject<readonly [Nodes.Node, number | null]>()
    nodeSelected: Observable<readonly [Nodes.Node, number | null]> = this.nodeSelectedSource.asObservable()

    selectedSurface: Nodes.MeshSurface | null = null
    private surfaceSelectedSource: Subject<Nodes.MeshSurface> = new Subject<Nodes.MeshSurface>()
    surfaceSelected: Observable<Nodes.MeshSurface> = this.surfaceSelectedSource.asObservable()

    constructor() {}

    public selectNode(node: Nodes.Node | null, extend: boolean, extraID?: number): void {
        if (extend) {
            if (node) {
                const idx = this.selectedNodes.indexOf(node)
                if (idx >= 0) {
                    this.selectedNodes.splice(idx, 1)
                } else {
                    this.selectedNodes.unshift(node)
                }
            }
        } else {
            this.selectedNodes = node ? [node] : []
        }

        this.nodeSelectedSource.next([node, extraID ?? null])
    }

    public filter<T extends Nodes.Node>(fn: (x: Nodes.Node) => x is T): T[] {
        return this.selectedNodes.filter(fn)
    }

    public filterOne<T extends Nodes.Node>(fn: (x: Nodes.Node) => x is T): T | null {
        for (const node of this.selectedNodes) {
            if (fn(node)) {
                return node
            }
        }
        return null
    }

    public selectSurface(surface: Nodes.MeshSurface | null): void {
        this.selectedSurface = surface
        this.surfaceSelectedSource.next(surface)
    }

    public clearSelection(): void {
        this.selectedNodes = []
        //TODO: clear surface selection?
    }

    public removeFromSelection(node: Nodes.Node) {
        const idx = this.selectedNodes.indexOf(node as Nodes.Node)
        if (idx >= 0) {
            this.selectedNodes.splice(idx, 1)
        }
    }
}
