import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {FresnelInputs, FresnelOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-fresnel-node",
    templateUrl: "./fresnel-node.component.html",
    styleUrls: ["./fresnel-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class FresnelNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = FresnelOutputs
    inputs = FresnelInputs
    typeInfo = FresnelNodeType
}

export const FresnelNodeType: MaterialNodeType<typeof FresnelNodeComponent> = {
    id: "fresnel",
    label: "Fresnel",
    color: "#6e5eb2",
    name: "ShaderNodeFresnel",
    inputs: [FresnelInputs.ior, FresnelInputs.normal],
    outputs: [FresnelOutputs.fac],
    component: FresnelNodeComponent,
}
