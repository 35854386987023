import {ImageOpContextWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {HalContext} from "@common/models/hal/hal-context"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {OperatorParameterValue} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {ImageOpNodeGraphEvaluatorBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-node-graph-evaluator-base"
import {ImagePtrWebGl2} from "@app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"

export class ImageOpNodeGraphEvaluatorWebGl2 extends ImageOpNodeGraphEvaluatorBase {
    constructor(halContext: HalContext, texturesApi?: TexturesApiService, drawableImageCache?: DrawableImageCache) {
        super("preview")
        this.imageOpContextWebGL2 = new ImageOpContextWebGL2(halContext, texturesApi, drawableImageCache)
    }

    override dispose() {
        this.imageOpContextWebGL2?.dispose()
        super.dispose()
    }

    async evaluate(nodeGraph: OperatorParameterValue<ImagePtr>): Promise<ImagePtrWebGl2> {
        this.imageOpContextWebGL2.preEvaluation()
        const resultRef = await this.evaluateResultImageRef(this.imageOpContextWebGL2, nodeGraph)
        const resultImage = await this.imageOpContextWebGL2.getImage(resultRef)
        resultRef.release()
        this.imageOpContextWebGL2.postEvaluation()
        return resultImage
    }

    createDataObjectImage(dataObjectId: string): Promise<ImagePtr> {
        return this.imageOpContextWebGL2.createDataObjectImageRef(dataObjectId)
    }

    readonly imageOpContextWebGL2: ImageOpContextWebGL2
}
