<h1 mat-dialog-title>Options</h1>
<div mat-dialog-content>
    <mat-checkbox [checked]="data.mergeCompileAndRun" (change)="data.mergeCompileAndRun = $event.checked"> Merge compile and run nodes </mat-checkbox>
    <br />
    <mat-checkbox [checked]="data.outputConstants" (change)="data.outputConstants = $event.checked"> Output constants </mat-checkbox>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Export</button>
</div>
