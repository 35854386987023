import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {SelectionPossibilities, SelectionPossibility, ValueSlotComponent, resolveSelectionPossibilityValue} from "../../value-slot/value-slot.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {EnvironmentMapMode, SceneProperties, ScenePropertiesParameters, TextureResolution, UiStyle} from "@cm/lib/templates/nodes/scene-properties"

type ShadowCatcherFalloffProperties = ScenePropertiesParameters["shadowCatcherFalloff"]

@Component({
    selector: "cm-scene-properties-inspector",
    standalone: true,
    templateUrl: "./scene-properties-inspector.component.html",
    styleUrl: "./scene-properties-inspector.component.scss",
    imports: [ValueSlotComponent, InspectorSectionComponent],
})
export class ScenePropertiesInspectorComponent extends BaseInspectorComponent<SceneProperties> {
    shadowCatcherFalloff = computed(() => {
        const {shadowCatcherFalloff} = this.parameters()
        return shadowCatcherFalloff
    })
    uiStylePossibilites: SelectionPossibilities<UiStyle> = [
        {value: "default", name: "Default"},
        {value: "icons", name: "Icons"},
        {value: "hidden", name: "Hidden"},
        {value: "accordion", name: "Accordion"},
    ]
    iconSizePossibilites: SelectionPossibilities<number> = [
        {value: 24, name: "24px"},
        {value: 32, name: "32px"},
        {value: 40, name: "40px"},
    ]
    textureResolutionPossibilites: SelectionPossibilities<TextureResolution> = [
        {value: "500px", name: "500 px"},
        {value: "1000px", name: "1000 px"},
        {value: "2000px", name: "2000 px"},
        {value: "original", name: "Original"},
    ]
    textureFilteringPossibilites: SelectionPossibilities<boolean> = [
        {value: true, name: "On"},
        {value: false, name: "Off"},
    ]
    environmentMapModePossibilites: SelectionPossibilities<EnvironmentMapMode> = [
        {value: "full", name: "Diffuse+Specular"},
        {value: "specularOnly", name: "Specular Only"},
    ]
    shadowCatcherFalloffPossibilites: SelectionPossibilities<ShadowCatcherFalloffProperties> = [
        {value: {sizeX: 100, sizeZ: 100, smoothness: 1, opacity: 1}, name: "Yes"},
        {value: undefined, name: "No"},
    ]
    shadowCatcherFalloffSelected = (selectionPossibility: SelectionPossibility<ShadowCatcherFalloffProperties>) => {
        const shadowCatcherFalloff = this.shadowCatcherFalloff()
        const selectionPossibilityValue = resolveSelectionPossibilityValue(selectionPossibility.value)
        if (!shadowCatcherFalloff) return selectionPossibilityValue === undefined
        return selectionPossibilityValue !== undefined
    }
}
