import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {assertNever} from "@cm/lib/utils/utils"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {assertSameSize} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {getImgProcChannelLayout, toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {HalPainterBlendMode} from "@common/models/hal/hal-painter/types"

export type ParameterType = {
    backgroundImage: ImagePtr
    foregroundImage: ImagePtr
    alpha?: ImagePtr | number // for alpha blending, if supplied this will be used instead of alpha channel of foregroundImage
    premultipliedAlpha?: boolean // default to true
    blendMode: "normal" | "add" | "multiply" | "darken" | "lighten" | "screen"
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpBlend: ImageOpType<ParameterType, ReturnType> = {
    name: "Blend",

    WebGL2: async ({context, parameters: {backgroundImage, foregroundImage, alpha, premultipliedAlpha, blendMode, resultImage}}) => {
        const useAlphaImage = alpha && typeof alpha !== "number"
        const alphaConst = typeof alpha === "number" ? alpha : undefined
        using backgroundImageWebGl2 = await context.getImage(backgroundImage)
        using foregroundImageWebGl2 = await context.getImage(foregroundImage)
        using alphaImageWebGl2 = useAlphaImage ? await context.getImage(alpha) : undefined
        assertSameSize(backgroundImageWebGl2.ref.descriptor, foregroundImageWebGl2.ref.descriptor)
        if (alphaImageWebGl2) {
            assertSameSize(backgroundImageWebGl2.ref.descriptor, alphaImageWebGl2.ref.descriptor)
        }
        if (backgroundImage === resultImage) {
            // use actual hardware alpha blending
            const halBlend = await context.getOrCreateImageCompositor(`
                vec4 computeColor(ivec2 targetPixel) {
                    vec4 foreground = texelFetch0(targetPixel);
                    float alpha = ${
                        needsAlphaChannel(blendMode)
                            ? useAlphaImage
                                ? "texelFetch1(targetPixel).r"
                                : alphaConst !== undefined
                                  ? `float(${alphaConst})`
                                  : "foreground.a"
                            : "0.0"
                    };
                    return vec4(foreground.xyz, alpha);
                }
            `)
            const images =
                needsAlphaChannel(blendMode) && alphaImageWebGl2
                    ? [foregroundImageWebGl2.ref.halImage, alphaImageWebGl2.ref.halImage]
                    : [foregroundImageWebGl2.ref.halImage]
            await halBlend.paint(backgroundImageWebGl2.ref.halImage, images, {blendMode: getHalBlendMode(blendMode)})
        } else {
            // emulate alpha blending in shader
            const blendFunc = (mode: ParameterType["blendMode"], premultipliedAlpha: boolean) => {
                switch (mode) {
                    case "normal":
                        return `background * (1.0 - alpha) + foreground${premultipliedAlpha ? "" : " * alpha"}`
                    case "add":
                        return "vec4(background.rgb + foreground.rgb, background.a)"
                    case "multiply":
                        return "background * foreground"
                    case "darken":
                        return "min(background, foreground)"
                    case "lighten":
                        return "max(background, foreground)"
                    case "screen":
                        return "background * (1.0 - foreground) + foreground"
                    default:
                        assertNever(mode)
                }
            }
            const halBlend = await context.getOrCreateImageCompositor(`
                vec4 blendFunc(vec4 background, vec4 foreground, float alpha) {
                    return ${blendFunc(blendMode, premultipliedAlpha ?? true)};
                }

                vec4 computeColor(ivec2 targetPixel) {
                    vec4 background = texelFetch0(targetPixel);
                    vec4 foreground = texelFetch1(targetPixel);
                    float alpha = ${
                        needsAlphaChannel(blendMode)
                            ? useAlphaImage
                                ? "texelFetch2(targetPixel).r"
                                : alphaConst !== undefined
                                  ? `float(${alphaConst})`
                                  : "foreground.a"
                            : "0.0"
                    };
                    return blendFunc(background, foreground, alpha);
                }
            `)
            resultImage = await context.prepareResultImage(resultImage, backgroundImageWebGl2.ref.descriptor)
            const resultImageWebGl2 = await context.getImage(resultImage)
            const images =
                needsAlphaChannel(blendMode) && alphaImageWebGl2
                    ? [backgroundImageWebGl2.ref.halImage, foregroundImageWebGl2.ref.halImage, alphaImageWebGl2.ref.halImage]
                    : [backgroundImageWebGl2.ref.halImage, foregroundImageWebGl2.ref.halImage]
            await halBlend.paint(resultImageWebGl2.ref.halImage, images)
            resultImageWebGl2.release()
        }
        return resultImage
    },

    ImgProc: async ({context, parameters: {backgroundImage, foregroundImage, alpha, premultipliedAlpha, blendMode: mode, resultImage}}) => {
        const useAlphaImage = alpha && typeof alpha !== "number"
        const alphaConst = typeof alpha === "number" ? alpha : undefined
        using backgroundImageImgProc = await context.getImage(backgroundImage)
        using foregroundImageImgProc = await context.getImage(foregroundImage)
        using alphaImageImgProc = useAlphaImage ? await context.getImage(alpha) : undefined
        assertSameSize(backgroundImageImgProc.ref.descriptor, foregroundImageImgProc.ref.descriptor)
        if (alphaImageImgProc) {
            assertSameSize(backgroundImageImgProc.ref.descriptor, alphaImageImgProc.ref.descriptor)
        }
        let foregroundNode: Nodes.ImageNode
        if (needsAlphaChannel(mode)) {
            premultipliedAlpha = premultipliedAlpha ?? true
            let nodeAlpha: Nodes.ImageNode
            if (alphaImageImgProc) {
                nodeAlpha = Nodes.extractChannel(alphaImageImgProc.ref.node, 0)
            } else if (alphaConst !== undefined) {
                nodeAlpha = Nodes.extractChannel(Nodes.math("constLike", foregroundImageImgProc.ref.node, alphaConst), 0)
            } else {
                if (foregroundImageImgProc.ref.descriptor.channelLayout != "RGBA") {
                    throw new Error("Alpha channel neither supplied in foreground image nor in alpha image")
                }
                nodeAlpha = Nodes.extractChannel(foregroundImageImgProc.ref.node, 3)
            }
            let nodeR: Nodes.ImageNode = Nodes.extractChannel(foregroundImageImgProc.ref.node, 0)
            if (!premultipliedAlpha) {
                nodeR = Nodes.math("*", nodeR, nodeAlpha)
            }
            if (foregroundImageImgProc.ref.descriptor.channelLayout != "R") {
                let nodeG: Nodes.ImageNode = Nodes.extractChannel(foregroundImageImgProc.ref.node, 1)
                if (!premultipliedAlpha) {
                    nodeG = Nodes.math("*", nodeG, nodeAlpha)
                }
                let nodeB: Nodes.ImageNode = Nodes.extractChannel(foregroundImageImgProc.ref.node, 2)
                if (!premultipliedAlpha) {
                    nodeB = Nodes.math("*", nodeB, nodeAlpha)
                }
                foregroundNode = Nodes.combineChannels([nodeR, nodeG, nodeB, nodeAlpha], "RGBA")
            } else {
                foregroundNode = Nodes.combineChannels([nodeR, nodeR, nodeR, nodeAlpha], "RGBA")
            }
        } else {
            foregroundNode = foregroundImageImgProc.ref.node
        }
        const resultNode = Nodes.convert(
            Nodes.blend(backgroundImageImgProc.ref.node, foregroundNode, mode),
            backgroundImageImgProc.ref.descriptor.format,
            getImgProcChannelLayout(backgroundImageImgProc.ref.descriptor.channelLayout),
        ) // since the blend node always returns RGBA/float32 we need to convert to backgroundImage's format for consistent behaviour
        using result = await context.createImage(backgroundImageImgProc.ref.descriptor, resultNode)
        return await toImgProcResultImage(context, result, resultImage)
    },
}

function needsAlphaChannel(mode: ParameterType["blendMode"]) {
    return mode === "normal"
}

function getHalBlendMode(blendMode: ParameterType["blendMode"]): HalPainterBlendMode {
    switch (blendMode) {
        case "normal":
            return "normal"
        case "add":
            return "add"
        case "multiply":
            return "mul"
        case "darken":
            return "min"
        case "lighten":
            return "max"
        case "screen":
            return "screen"
        default:
            assertNever(blendMode)
    }
}
