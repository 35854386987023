import {registerNode} from "@src/graph-system/register-node"
import {DeclareListNodeTS} from "@src/graph-system/utils"
import {PricingContext, PricingNodegraph} from "@src/pricing/nodes/core"
import {PricedItemNode} from "@src/pricing/nodes/priced-item-node"
import {PriceContainer} from "@src/pricing/nodes/price-container"

/**This models the subprices of a node and should not show in the UI. The reason for this class is that nodegraphs nested into a parameter, e.g. an array of nodegraphs directly inside the parameters of a price node is not allowed by design of the graph system. */
@registerNode
export class PriceList extends DeclareListNodeTS<PricingContext, PricingNodegraph>(
    {},
    {
        nodeClass: "PriceList",
    },
) {
    mutuallyExclusive(): boolean {
        //TODO: This code assumes "And" conditions, must be adapted to VariantCondition and container taking prices
        const conditions: string[][] = []
        for (const node of this.parameters.list) {
            if (node instanceof PriceContainer) continue
            if (!(node instanceof PricedItemNode)) throw new Error(`Subprice is not a priced item, but ${node.constructor.name}`)

            // if (!node.parameters.conditionalPrice && curConditions.length !== 0) throw new Error("Unconditional node with conditions found")
            // if (!node.parameters.conditionalPrice || (node.parameters.conditionalPrice && curConditions.length !== 0)) conditions.push(node.getVariantIds())
            if (
                node.parameters.condition &&
                node.parameters.condition.parameters.condition.variantOperator === "AND" &&
                node.parameters.condition.getVariantIds().length > 0
            )
                conditions.push(node.parameters.condition.getVariantIds())
        }

        conditions.sort((a, b) => a.length - b.length)

        for (let i = 0; i < conditions.length; i++) {
            for (let j = i + 1; j < conditions.length; j++) {
                const isSubset = conditions[i].every((condition) => conditions[j].includes(condition))
                if (isSubset) {
                    return false
                }
            }
        }

        return true
    }
}
