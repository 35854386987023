@if (item.data; as material) {
    <cm-entity-card @fadeInCard [$entity]="material" [entityLink]="['/materials', material.id]" [entityQueryParamsHandling]="'preserve'" class="cm-material">
        <ng-container cm-bottom-right>
            @if (can.viewMaterialUserAssignments()) {
                @if (cardData.data; as data) {
                    <cm-assign-user
                        (click)="$event.stopPropagation()"
                        [matMenuTriggerFor]="userMenu"
                        [matMenuTriggerData]="{entity: data}"
                        [$user]="data.currentUserAssignment"
                        [nextActor]="data.nextActor"
                    >
                    </cm-assign-user>
                } @else {
                    <cm-placeholder
                        class="cm-placeholder-label cm-placeholder-label--assign-user"
                        [dark]="true"
                        format="circle"
                        @fadeInPlaceholder
                    ></cm-placeholder>
                }
            } @else if (auth.isFabricManufacturer()) {
                @if (cardData.data; as data) {
                    <cm-assign-user [nextActor]="data.nextActor"></cm-assign-user>
                } @else {
                    <cm-placeholder
                        class="cm-placeholder-label cm-placeholder-label--assign-user"
                        [dark]="true"
                        format="circle"
                        @fadeInPlaceholder
                    ></cm-placeholder>
                }
            }
        </ng-container>
        <ng-container cm-bottom-left>
            @if (can.viewMaterialPaymentState()) {
                <cm-payment-state-label
                    [item]="{id: material.id, __typename: 'Material'}"
                    [editable]="true"
                    (stateChange)="updateItem.emit({id: material.id, paymentState: $event})"
                >
                </cm-payment-state-label>
            }
            @if (cardData.data; as data) {
                <cm-state-label
                    [stateLabels]="Labels.MaterialState"
                    [state]="data.state"
                    [backgroundColor]="!auth.isStaff() && data.nextActor === Enums.NextActor.Customer ? '#f13536' : undefined"
                    [editable]="can.editMaterialState(data)"
                    (stateChange)="updateItem.emit({id: data.id, state: $event})"
                >
                </cm-state-label>
            } @else {
                <cm-placeholder class="cm-placeholder-label cm-placeholder-label--state" [dark]="true" format="rect" @fadeInPlaceholder></cm-placeholder>
            }
        </ng-container>
        <ng-container cm-subtitle>
            @if (cardData.data; as data) {
                @if (auth.isStaff()) {
                    <ng-container>
                        <cm-comment-boxes [entity]="data"></cm-comment-boxes>
                    </ng-container>
                }
            }
        </ng-container>
        <ng-container cm-top-left>
            @if (cardData.data; as data) {
                @if (can.viewMaterialLabels()) {
                    <cm-tag-labels [tags]="getTags(data)"></cm-tag-labels>
                }
            }
        </ng-container>

        <ng-container cm-top-right>
            @if (cardData.data; as data) {
                <div class="cm-action-container" (click)="$event.stopPropagation()">
                    <a (click)="copyMaterial.emit(material)" class="cm-action-icon-container">
                        <i class="far fa-copy cm-action-icon" matTooltip="Make a copy"></i>
                    </a>
                </div>
            }
        </ng-container>
    </cm-entity-card>
} @else if (item.placeholder) {
    <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
} @else if (item.error) {
    <cm-card-error @fadeInPlaceholder></cm-card-error>
}

<mat-menu #userMenu [overlapTrigger]="false" [hasBackdrop]="true">
    <ng-template matMenuContent let-entity="entity">
        <cm-assign-user-dialog
            [$user]="entity.currentUserAssignment"
            [nextActor]="entity.nextActor"
            (userChange)="updateAssignedUser.emit({contentTypeModel: Enums.ContentTypeModel.Material, item: entity, user: $event})"
            (nextActorChange)="updateItem.emit({id: entity.id, nextActor: $event})"
        ></cm-assign-user-dialog>
    </ng-template>
</mat-menu>
