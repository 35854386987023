import {HttpErrorResponse} from "@angular/common/http"
import {computed, inject, Injectable, Signal} from "@angular/core"
import {toSignal} from "@angular/core/rxjs-interop"
import {MatSnackBar} from "@angular/material/snack-bar"
import {AuthenticatedUserFragment, AuthenticatedUserMembershipFragment, ContentTypeModel, MutationLoginInput, OrganizationType} from "@api"
import {IsNotUndefined} from "@cm/lib/utils/filter"
import {decodeToken, localStorageToken} from "@common/helpers/auth/token"
import {TokenService} from "@common/services/auth/token.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Apollo} from "apollo-angular"
import {BehaviorSubject, filter, firstValueFrom, map, Observable, throwError as observableThrowError} from "rxjs"

@Injectable()
export class AuthService {
    // null if the user is not logged in
    // undefined during initial app load and during login until the user has been loaded
    // otherwise a user fragment
    public user$ = new BehaviorSubject<AuthenticatedUserFragment | null | undefined>(undefined)
    // undefined during initial app load and during login until the user has been loaded
    // null if the user is not logged in
    // otherwise a user fragment
    public $user: Signal<AuthenticatedUserFragment | null | undefined>

    userId$ = new BehaviorSubject<string | null | undefined>(undefined)
    isStaff$: Observable<boolean>

    public memberships$: Observable<AuthenticatedUserMembershipFragment[] | null>
    public $memberships: Signal<AuthenticatedUserMembershipFragment[] | null | undefined>

    public initialLoadCompleted$ = new BehaviorSubject<boolean>(false)

    apollo = inject(Apollo)
    notifications = inject(NotificationsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    snackBar = inject(MatSnackBar)
    token = inject(TokenService)

    constructor() {
        this.$user = toSignal(this.user$, {initialValue: undefined})
        this.memberships$ = this.user$.pipe(map((user) => user?.memberships ?? null))
        this.$memberships = toSignal(this.memberships$)

        this.isStaff$ = this.user$.pipe(
            filter((user) => user !== undefined),
            map((user) => !!user?.isStaff),
        )

        // allow the refresh service to trigger a reload of the current user
        // e.g. when the user's details change
        this.refresh
            .keepFetched$<AuthenticatedUserFragment>(this.userId$, ContentTypeModel.User, (id) =>
                this.sdk.gql.authenticatedUser(id).catch((error) => {
                    console.error(error)
                    this.logOut()
                    this.notifications.showInfo("You have been logged out automatically.", 10000)
                    return null
                }),
            )
            .subscribe((reloadedUser) => {
                this.user$.next(reloadedUser)
            })

        this.loadUserIdFromStorage().then((userId) => {
            this.userId$.next(userId)
            this.initialLoadCompleted$.next(true)
        })
    }

    get user(): Promise<AuthenticatedUserFragment | null> {
        return firstValueFrom(this.user$.pipe(filter(IsNotUndefined)))
    }

    async loadUserIdFromStorage(): Promise<string | null> {
        const token = localStorageToken()
        if (!token) {
            return null
        }

        try {
            const decodedToken = decodeToken(token)
            return decodedToken?.id ?? null
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async logIn(credentials: MutationLoginInput): Promise<boolean> {
        this.user$.next(undefined)
        try {
            const {
                login: {user: userIds, token},
            } = await this.sdk.gql.performLogin({input: credentials})
            this.storeToken(token)
            this.userId$.next(userIds.id)
            await this.apollo.client.resetStore()
            return true
        } catch (error) {
            console.error(error)
            // TODO: deal with other errors (e.g. server unavailable) ?
            this.snackBar.open("Login failed. Wrong email or password.", "", {duration: 3000})
            this.handleError(error as HttpErrorResponse)
            this.userId$.next(null)
            return false
        }
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        console.error(error)
        let errorMessage: string = error.message || "An error occurred."
        if (error.error) {
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred.
                errorMessage = error.error.message
            } else if (error.error.detail !== undefined) {
                // The backend returned an unsuccessful response code.
                errorMessage = error.error.detail
            }
        }
        return observableThrowError(errorMessage)
    }

    public logOut(): void {
        try {
            localStorage.removeItem("token")
        } catch {
            // probably don't have access to localStorage, should be safe to ignore
        }
        this.userId$.next(null)
        void this.apollo.client.resetStore()
    }

    public loadToken(): string | null {
        return this.token.load()
    }

    public storeToken(token: string) {
        this.token.store(token)
        void this.apollo.client.resetStore()
    }

    isLoggedIn() {
        return !!this.userId$.value
    }

    isPhotographer() {
        return !!this.$user()?.isPhotographer
    }

    $isPhotographer = computed(() => {
        return !!this.$user()?.isPhotographer
    })

    isFabricManufacturer() {
        if (!this.$user()) {
            return false
        }
        return (
            (this.$user()?.organization?.type === OrganizationType.FabricManufacturer ||
                this.$memberships()?.some((membership) => membership.organization.type === OrganizationType.FabricManufacturer)) ??
            false
        )
    }

    $isFabricManufacturer = computed(() => {
        return (
            (this.$user()?.organization?.type === OrganizationType.FabricManufacturer ||
                this.$memberships()?.some((membership) => membership.organization.type === OrganizationType.FabricManufacturer)) ??
            false
        )
    })

    isStaff() {
        if (!this.$user()) {
            return false
        }
        return this.$user()?.isStaff ?? false
    }

    $isStaff = computed(() => {
        return this.$user()?.isStaff ?? false
    })

    isSuperuser() {
        if (!this.$user()) {
            return false
        }
        return this.$user()?.isSuperuser ?? false
    }
}
