<cm-section>
    <ng-container cm-icon><i class="far fa-cube"></i></ng-container>
    <ng-container cm-title> Assets</ng-container>
    <div class="cm-assets" cm-content>
        @for (assignment of picture?.assetAssignments; track assignment) {
            <cm-entity-card [$entity]="assignment.asset" [entityLink]="['/assets', assignment.asset.id]">
                <ng-container cm-top-left>
                    @if (assignment.asset.state != Enums.AssetState.Completed) {
                        <div class="cm-elements">
                            <div class="cm-element">
                                <div
                                    [style.background-color]="Labels.ModelState.get(assignment.asset.model.state)?.background"
                                    class="cm-element-state-label"
                                ></div>
                                <cm-data-object-thumbnail class="cm-element-img" [dataObjectId]="assignment.asset.model.galleryImage?.id" />
                            </div>
                            @for (material of assignment.asset.materials; track material) {
                                <div class="cm-element">
                                    <div [style.background-color]="Labels.MaterialState.get(material.state)?.background" class="cm-element-state-label"></div>
                                    <cm-data-object-thumbnail class="cm-element-img" [dataObjectId]="material.galleryImage?.id" />
                                </div>
                            }
                        </div>
                    }
                </ng-container>
                <ng-container cm-top-right>
                    <i
                        class="cm-asset-delete far fa-trash"
                        (click)="$event.preventDefault(); $event.stopPropagation(); deletePictureAssetAssignment(assignment.id)"
                        aria-label="Delete"
                    ></i>
                </ng-container>
                <ng-container cm-bottom-left>
                    <cm-state-label [stateLabels]="Labels.AssetState" [(state)]="assignment.asset.state" [editable]="false"> </cm-state-label>
                </ng-container>
            </cm-entity-card>
        }
        @if (can.createPictureAsset()) {
            <cm-entity-add-card (click)="openSelectAssetDialog()"></cm-entity-add-card>
        }
    </div>
</cm-section>
