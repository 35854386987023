@if (descriptor) {
    <cm-inspector-section>
        <ng-container cm-title>
            {{ descriptor.name }}
        </ng-container>
        <ng-container cm-content>
            <ng-template #numberFieldTemplate let-field="field" let-context="context" let-key="key">
                <cm-input-container>
                    <ng-container cm-inline-label>
                        <span [matTooltip]="field.description">{{ field.name }}:</span>
                    </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="context[key]" (valueChange)="nodeChanged(node)" [decimalPlaces]="field.decimalPlaces ?? 2">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </ng-template>
            <ng-template #fieldTemplate let-field="field" let-context="context" let-key="key">
                @if (field.type === "node") {
                    <div class="cm-node-input">
                        <span>{{ field.name }}:</span>
                        <cm-node-reference-slot [(node)]="context[key]" (nodeChange)="nodeChanged(node)" [filter]="field.filter"> </cm-node-reference-slot>
                    </div>
                }
                @if (field.type === "numberLike") {
                    <cm-node-reference-slot fallbackText="" [node]="context[key]" (nodeChange)="context[key] = $event ?? field.default ?? 0; nodeChanged(node)">
                        <ng-container cm-before-node>
                            <span class="cm-node-slot-label" [matTooltip]="field.description">{{ field.name }}:</span>
                        </ng-container>
                        <ng-container cm-fallback>
                            <ng-container *ngTemplateOutlet="numberFieldTemplate; context: {field, context, key}"></ng-container>
                        </ng-container>
                    </cm-node-reference-slot>
                }
                @if (field.type === "number") {
                    <ng-container *ngTemplateOutlet="numberFieldTemplate; context: {field, context, key}"></ng-container>
                }
                @if (field.type === "boolean") {
                    <cm-toggle [(toggle)]="context[key]" (toggleChange)="nodeChanged(node)">
                        <ng-container cm-text>{{ field.name }}</ng-container>
                    </cm-toggle>
                }
                @if (field.type === "enum") {
                    <mat-form-field>
                        <mat-label>{{ field.name }}</mat-label>
                        <mat-select [value]="context[key]" (selectionChange)="context[key] = $event.value; nodeChanged(node)">
                            @for (entry of field.values; track entry) {
                                <mat-option [value]="entry.value">{{ entry.name }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
                @if (field.type === "tuple") {
                    @for (field_ of field.fields; track field_; let idx = $index) {
                        <ng-container *ngTemplateOutlet="fieldTemplate; context: {field: field_, context: context[key], key: idx}"></ng-container>
                    }
                }
            </ng-template>
            @for (field of descriptor.fields; track field) {
                <ng-container *ngTemplateOutlet="fieldTemplate; context: {field: field, context: node, key: field.key}"></ng-container>
            }
        </ng-container>
    </cm-inspector-section>
}
