import {NgModule} from "@angular/core"
import {ApolloLink, InMemoryCache, split} from "@apollo/client/core"
import {getMainDefinition} from "@apollo/client/utilities"
import {getHttpLink, getSseLink} from "@common/helpers/api"
import {APOLLO_OPTIONS, ApolloModule} from "apollo-angular"
import {HttpLink} from "apollo-angular/http"

export function createApollo(): ApolloLink {
    const httpLink = getHttpLink()
    const sseLink = getSseLink()

    return split(
        ({query}) => {
            const definition = getMainDefinition(query)

            return definition.kind === "OperationDefinition" && definition.operation === "subscription"
        },
        // we use the sse link for subscriptions
        sseLink,
        // and the http link for queries and mutations
        httpLink,
    )
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory() {
                return {
                    cache: new InMemoryCache(),
                    link: createApollo(),
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: "cache-and-network",
                            nextFetchPolicy: "cache-first",
                        },
                        query: {
                            fetchPolicy: "network-only",
                            // fetchPolicy: 'cache-and-network', this cannot be used for query
                            nextFetchPolicy: "network-only",
                        },
                    },
                }
            },
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}
