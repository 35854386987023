import {z} from "zod"
import {Injectable, inject} from "@angular/core"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {UtilsService} from "@legacy/helpers/utils"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {IdDetails} from "@cm/lib/api-gql/common"
import {EndpointUrls} from "@app/common/models/constants/urls"
import {firstValueFrom} from "rxjs"
import {extensionForContentType as extensionForMediaType, contentTypeForExtension} from "@cm/lib/utils/content-types"
import {MediaTypeSchema} from "@cm/lib/api-gql/data-object"
import {ExtensionT as Extension} from "@cm/lib/utils/content-types"
import {HdriDetailsFragment} from "@api"

export interface IHdriManager {
    getHdriAsBufferAndExtension(hdriIdDetails: IdDetails): Promise<{buffer: Uint8Array; extension: Extension | "hdr"} | null>
}

@Injectable({
    providedIn: "root",
})
export class HdriService implements IHdriManager {
    private sdkService = inject(SdkService)
    private utilsService = inject(UtilsService)
    private uploadService = inject(UploadGqlService)

    async getHdriAsBufferAndExtension(hdriIdDetails: IdDetails) {
        const details = (await this.sdkService.gql.hdriForEditorScene(hdriIdDetails)).hdri
        const parsedDetails = z.object({dataObject: z.object({mediaType: MediaTypeSchema, bucketName: z.string(), objectName: z.string()})}).safeParse(details)
        if (!parsedDetails.success) return null

        const buffer = await firstValueFrom(
            this.utilsService.getResourceAsBuffer(
                EndpointUrls.GoogleStorage(parsedDetails.data.dataObject.bucketName, parsedDetails.data.dataObject.objectName),
            ),
        )

        // If the media type is application/octet-stream we assume it's an HDRI
        const extension =
            parsedDetails.data.dataObject.mediaType === "application/octet-stream"
                ? ("hdr" as const)
                : extensionForMediaType(parsedDetails.data.dataObject.mediaType)
        return {buffer: new Uint8Array(buffer), extension}
    }

    async uploadHdriFromFile(file: File, hdriDetails: Pick<HdriDetailsFragment, "id" | "organization">) {
        const contentType = contentTypeForExtension(file.name.split(".").pop() ?? "")
        const dataObject = await this.uploadService.createAndUploadDataObject(
            file,
            {
                mediaType: contentType,
                organizationId: hdriDetails.organization.id,
            },
            {showUploadToolbar: true, processUpload: true},
        )
        return this.sdkService.gql.updateHdriDetails({input: {id: hdriDetails.id, dataObjectId: dataObject.id}})
    }
}
