// @ts-strict-ignore
import {slice1d, slice2d, zeros} from "@common/helpers/utils/math-utils"
import {locateFile} from "@common/helpers/web-assembly/locate-file"
import {LMHelper} from "@editor/helpers/connection-solver/lm-helper"
import * as ndarray from "ndarray"
import {SVD} from "svd-js"

import EigenLinearSolverModule, {EigenLinearSolver} from "@wasm/eigen-linear-solver"
import {NdArray} from "ndarray"
import {IParameterBlock, IResidualBlock, Matrix, Vector} from "@cm/lib/templates/interfaces/connection-solver"

let _EigenLinearSolver: EigenLinearSolver
EigenLinearSolverModule({locateFile: locateFile}).then((mod: EigenLinearSolver) => {
    _EigenLinearSolver = mod
})

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

/** Private data relating to a parameter block */
class ParameterBlockData {
    activeCount: number /** number of active references to this parameter block. If it is zero, there is no need to include these in the system. */
    indexOffset: number /** the parameter index offset for this block (offset into the complete set of parameters) */
    parameterDeltaView: Vector /** This is a "view" into a larger contiguous block of data */
    saved: number[] /** temporary storage for parameters, used to restore state when a LM step fails */
}

class ResidualBlockData {
    residualView: Vector /** This is a "view" into a larger contiguous block of data */
    jacobianViews: Matrix[] /** This is a set of "views" into larger contiguous blocks of data. Each view is a piece of the larger system Jacobian. The ordering of this array corresponds to IResidualBlock.relatedParameters.  */
    indexOffset: number /** the residual index offset for this block (offset into the complete set of residuals) */
}

function multiplyPseudoInverse(A: Matrix, x: Vector, out: Vector, scale: number): void {
    //TODO: use optimized linear algebra library...
    if (A.shape[0] >= A.shape[1]) {
        const m = A.shape[0]
        const n = A.shape[1]
        const tmpA: number[][] = []
        for (let i = 0; i < m; i++) {
            const tmpB = Array<number>(n)
            for (let j = 0; j < n; j++) {
                tmpB[j] = A.get(i, j)
            }
            tmpA.push(tmpB)
        }
        const {q, u, v} = SVD(tmpA)
        const tmpX: number[] = []
        for (let j = 0; j < n; j++) {
            let tmp = 0
            for (let i = 0; i < m; i++) {
                tmp += u[i][j] * x.get(i)
            }
            if (q[j] > -1e-9 && q[j] < 1e-9) {
                tmp = 0
            } else {
                tmp /= q[j]
            }
            tmpX.push(tmp)
        }
        for (let i = 0; i < n; i++) {
            let tmp = 0
            for (let j = 0; j < m; j++) {
                tmp += v[i][j] * tmpX[j]
            }
            out.set(i, tmp * scale)
        }
    } else {
        // transpose matrix and multiply backwards through decomposition
        A = A.transpose(1, 0)
        const m = A.shape[0]
        const n = A.shape[1]
        const tmpA: number[][] = []
        for (let i = 0; i < m; i++) {
            const tmpB = Array<number>(n)
            for (let j = 0; j < n; j++) {
                tmpB[j] = A.get(i, j)
            }
            tmpA.push(tmpB)
        }
        const {q, u, v} = SVD(tmpA)
        const tmpX: number[] = []
        for (let j = 0; j < m; j++) {
            let tmp = 0
            for (let i = 0; i < n; i++) {
                tmp += v[i][j] * x.get(i)
            }
            if (q[j] > -1e-9 && q[j] < 1e-9) {
                tmp = 0
            } else {
                tmp /= q[j]
            }
            tmpX.push(tmp)
        }

        for (let i = 0; i < m; i++) {
            let tmp = 0
            for (let j = 0; j < n; j++) {
                tmp += u[i][j] * tmpX[j]
            }
            out.set(i, tmp * scale)
        }
    }
}

function croutDecomposition(A: NdArray, L: NdArray, U: NdArray): boolean {
    const m = A.shape[0]
    const n = A.shape[1]
    if (m !== n) return false // non-square
    if (L && !U) U = L
    // diagonalize U
    for (let i = 0; i < n; i++) {
        U.set(i, i, 1)
    }
    for (let j = 0; j < n; j++) {
        for (let i = j; i < n; i++) {
            let sum = 0
            for (let k = 0; k < j; k++) {
                sum += L.get(k, i) * U.get(j, k)
            }
            L.set(j, i, A.get(j, i) - sum)
        }

        const denom = L.get(j, j)
        if (denom === 0) return false

        for (let i = j + 1; i < n; i++) {
            let sum = 0
            for (let k = 0; k < j; k++) {
                sum += L.get(k, j) * U.get(i, k)
            }
            U.set(i, j, (A.get(i, j) - sum) / denom)
        }
    }
    return true
}

function choleskyDecomposition(A: NdArray, L: NdArray) {
    const m = A.shape[0]
    for (let i = 0; i < m; i++) {
        for (let j = 0; j < m; j++) {
            L.set(i, j, 0)
        }
    }
    for (let i = 0; i < m; i++) {
        for (let j = 0; j < i + 1; j++) {
            let tmp = 0
            for (let k = 0; k < j; k++) {
                tmp += L.get(i, k) * L.get(j, k)
            }
            tmp = A.get(i, j) - tmp
            if (i == j) {
                L.set(i, j, Math.sqrt(tmp))
            } else {
                L.set(i, j, (1.0 / L.get(j, j)) * tmp)
            }
        }
    }
}

function luSolve(L: NdArray, U: NdArray, B: NdArray, X: NdArray, Y: NdArray): void {
    const m = L.shape[0],
        n = L.shape[1],
        freeY = false
    if (U.dimension === 1) {
        Y = X
        X = B
        B = U
        U = L
    }
    // LY = B, solve for Y
    for (let y = 0; y < n; y++) {
        let c = 0
        for (let x = 0; x < y; x++) {
            c += L.get(x, y) * Y.get(x)
        }
        Y.set(y, (B.get(y) - c) / L.get(y, y))
    }
    //UX = Y, solve for X
    for (let y = n - 1; y >= 0; y--) {
        let c = 0
        for (let x = n - 1; x > y; x--) {
            c += U.get(x, y) * X.get(x)
        }
        X.set(y, Y.get(y) - c)
    }
}

function _lltSolve(L: NdArray, B: NdArray, X: NdArray, Y: NdArray): void {
    const _m = L.shape[0],
        n = L.shape[1],
        _freeY = false
    // LY = B, solve for Y
    for (let y = 0; y < n; y++) {
        let c = 0
        for (let x = 0; x < y; x++) {
            c += L.get(x, y) * Y.get(x)
        }
        Y.set(y, (B.get(y) - c) / L.get(y, y))
    }
    //L^TX = Y, solve for X
    for (let y = n - 1; y >= 0; y--) {
        let c = 0
        for (let x = n - 1; x > y; x--) {
            c += L.get(y, x) * X.get(x)
        }
        X.set(y, Y.get(y) - c)
    }
}

/** Wrapper class for a general linear solver, allowing implementations to be swapped/compared more easily */
export class LinearSolver {
    private L: NdArray
    private y: NdArray
    constructor(
        private m: number,
        private n: number,
    ) {
        this.L = zeros(m, m)
        this.y = zeros(m)
    }

    solve(x: NdArray, A: NdArray, b: NdArray): boolean {
        const L = this.L
        if (!croutDecomposition(A, L, L)) {
            return false
        }
        luSolve(L, L, b, x, this.y)
        return true
    }

    solveSymmetric(x: NdArray, A: NdArray, b: NdArray): boolean {
        //         const L = this.L;
        //         choleskyDecomposition(A, L);
        //         lltSolve(L, b, x, this.y);
        //         return true;
        return this.solve(x, A, b)
    }
}

export enum SolverMode {
    GradientDescent,
    GaussNewton,
    LevenbergMarquardt,
}

/** Iterative linear solver based on the conjugated gradient method with Jacobian (diagonal) preconditioner */
export class IterativeLinearSolver {
    eps = 0.001
    maxIter = 10

    //    eps: number = 0.001;
    //    maxIter: number = 3;

    lmHelper: LMHelper

    constructor(lmHelper_: LMHelper) {
        this.lmHelper = lmHelper_
    }

    private scalarProd(x: NdArray, y: NdArray): number {
        const sz = x.size
        let sum = 0
        for (let i = 0; i < sz; i++) {
            sum += x.get(i) * y.get(i)
        }
        return sum
    }

    public preconditionAndSolve(x: NdArray, A: NdArray, b: NdArray): void {
        const sz = x.size
        const r = zeros(sz)
        const p = zeros(sz)
        const Ap = zeros(sz)

        for (let i = 0; i < sz; i++) {
            x.set(i, 0)
        }

        this.lmHelper.computePreconditionedJTJVectMult(A, x, r)
        for (let i = 0; i < sz; i++) {
            let preconditionedB = b.get(i)
            const diag = A.get(i, i)
            if (diag != 0) {
                preconditionedB = preconditionedB / diag
            }

            const tmp = r.get(i) - preconditionedB
            r.set(i, tmp)
            p.set(i, -tmp)
        }
        let rNorm = this.scalarProd(r, r)

        for (let i = 0; i < this.maxIter; i++) {
            this.lmHelper.computePreconditionedJTJVectMult(A, p, Ap)
            const pAp = this.scalarProd(p, Ap)
            const alpha = pAp !== 0 ? rNorm / pAp : 0
            for (let j = 0; j < sz; j++) {
                let tmp = x.get(j) + alpha * p.get(j)
                x.set(j, tmp)
                tmp = r.get(j) + alpha * Ap.get(j)
                r.set(j, tmp)
            }
            const rNormNew = this.scalarProd(r, r)
            const beta = rNorm !== 0 ? rNormNew / rNorm : 0
            rNorm = rNormNew
            //            console.log(`iter = ${i}, rNorm = ${rNorm}`);
            if (rNormNew < this.eps) {
                break
            }
            for (let j = 0; j < sz; j++) {
                const tmp = beta * p.get(j) - r.get(j)
                p.set(j, tmp)
            }
        }
    }

    public solve(x: NdArray, A: NdArray, b: NdArray): void {
        const sz = x.size
        const r = zeros(sz)
        const p = zeros(sz)
        const Ap = zeros(sz)

        this.lmHelper.computeJTJVectMult(A, x, r)
        for (let i = 0; i < sz; i++) {
            const tmp = r.get(i) - b.get(i)
            r.set(i, tmp)
            p.set(i, -tmp)
        }
        let rNorm = this.scalarProd(r, r)

        for (let i = 0; i < this.maxIter; i++) {
            this.lmHelper.computeJTJVectMult(A, p, Ap)
            const pAp = this.scalarProd(p, Ap)
            const alpha = pAp !== 0 ? rNorm / pAp : 0
            for (let j = 0; j < sz; j++) {
                let tmp = x.get(j) + alpha * p.get(j)
                x.set(j, tmp)
                tmp = r.get(j) + alpha * Ap.get(j)
                r.set(j, tmp)
            }
            const rNormNew = this.scalarProd(r, r)
            const beta = rNorm !== 0 ? rNormNew / rNorm : 0
            rNorm = rNormNew
            //            console.log(`iter = ${i}, rNorm = ${rNorm}`);
            if (rNormNew < this.eps) {
                break
            }
            for (let j = 0; j < sz; j++) {
                const tmp = beta * p.get(j) - r.get(j)
                p.set(j, tmp)
            }
        }
    }
}

/** Nonlinear least squares solver, operating on parameter blocks and residual blocks. These are automatically combined into a single matrix representation of the system, which is then solved using the selected method. */
export class Solver {
    public verbose = false

    private parameterBlocks = new Map<IParameterBlock, ParameterBlockData>()
    private residualBlocks = new Map<IResidualBlock, ResidualBlockData>()
    private fullJacobian: Matrix
    private allResiduals: Vector
    private allParameterDeltas: Vector
    private needsUpdate = true
    private needEval = true
    private sumSquaredResiduals = Infinity
    private stepFn: () => void
    private linearSolver: EigenLinearSolver.LeastSquaresSolverWrapper

    // only for Levenberg-Marquardt:
    private initLambda = 0.01
    private lambda = this.initLambda
    private lambdaDown = 0.5
    private lambdaUp = 2.0
    private maxLambda = 1e3

    private symmetryBreakingScale = 1e-3
    private symmetryBreakingStepsRemaining = 0
    private symmetryBreakingMaxSteps = 10

    public errorFlag = false

    public enableStats = true
    private stats = {
        count: 0,
        total: 0,
        preEval: 0,
        eval: 0,
        mulJTJ: 0,
        linearSolve: 0,
    }

    public onPreEval?: () => void

    constructor(
        private mode: SolverMode = SolverMode.LevenbergMarquardt,
        private stepSize: number = 1.0,
    ) {
        switch (mode) {
            case SolverMode.GradientDescent:
                this.stepFn = this.stepGradientDescent.bind(this)
                break
            case SolverMode.GaussNewton:
                this.stepFn = this.stepGaussNewton.bind(this)
                break
            case SolverMode.LevenbergMarquardt:
                this.stepFn = this.stepLevenbergMarquardt.bind(this)
                break
        }
    }

    destroy() {
        if (this.linearSolver) {
            this.linearSolver.delete()
            this.linearSolver = null
        }
    }

    public addResidualBlock(residualBlock: IResidualBlock): void {
        if (!this.residualBlocks.has(residualBlock)) {
            this.residualBlocks.set(residualBlock, new ResidualBlockData())
            this.needsUpdate = true
        }
    }

    public removeResidualBlock(residualBlock: IResidualBlock): void {
        this.residualBlocks.delete(residualBlock)
        this.needsUpdate = true
    }

    private prepare(): void {
        let totalResiduals = 0
        let totalParameters = 0

        if (!this.checkReady()) {
            return
        }

        this.needsUpdate = false
        this.sumSquaredResiduals = Infinity
        this.lambda = 1.0
        this.parameterBlocks.clear()

        this.residualBlocks.forEach((residualData, residualBlock) => {
            residualData.indexOffset = totalResiduals
            totalResiduals += residualBlock.size

            for (const parameterBlock of residualBlock.relatedParameters) {
                if (!this.parameterBlocks.has(parameterBlock)) {
                    this.parameterBlocks.set(parameterBlock, new ParameterBlockData())
                }
            }
        })

        this.parameterBlocks.forEach((parameterData, parameterBlock) => {
            parameterData.indexOffset = totalParameters
            totalParameters += parameterBlock.size
        })

        const cfg = new _EigenLinearSolver.Configuration(totalResiduals, totalParameters)
        this.residualBlocks.forEach((residualData, residualBlock) => {
            residualBlock.relatedParameters.forEach((parameterBlock) => {
                const parameterData = this.parameterBlocks.get(parameterBlock)
                cfg.addBlock(residualData.indexOffset, residualBlock.size, parameterData.indexOffset, parameterBlock.size)
            })
        })
        this.linearSolver.setup(cfg)
        cfg.delete()

        const solverJ = this.linearSolver.denseJacobian()
        const solverR = this.linearSolver.residuals()
        const solverP = this.linearSolver.parameterDeltas()

        this.fullJacobian = ndarray(solverJ.data, [totalResiduals, totalParameters], [1, totalResiduals])
        this.allResiduals = ndarray(solverR.data, [totalResiduals], [1])
        this.allParameterDeltas = ndarray(solverP.data, [totalParameters], [1])

        this.parameterBlocks.forEach((parameterData, parameterBlock) => {
            parameterData.parameterDeltaView = slice1d(this.allParameterDeltas, parameterData.indexOffset, parameterData.indexOffset + parameterBlock.size)
        })

        this.residualBlocks.forEach((residualData, residualBlock) => {
            residualData.jacobianViews = []
            residualData.residualView = slice1d(this.allResiduals, residualData.indexOffset, residualData.indexOffset + residualBlock.size)
            residualBlock.relatedParameters.forEach((parameterBlock) => {
                const parameterData = this.parameterBlocks.get(parameterBlock)
                const jacobianSlice = slice2d(
                    this.fullJacobian,
                    residualData.indexOffset,
                    residualData.indexOffset + residualBlock.size,
                    parameterData.indexOffset,
                    parameterData.indexOffset + parameterBlock.size,
                )
                residualData.jacobianViews.push(jacobianSlice)
            })
        })

        if (this.verbose) {
            console.log("Jacobian matrix size: ", this.fullJacobian.shape)

            if (totalParameters > totalResiduals) {
                console.warn(`Warning: system is underdetermined! totalParameters=${totalParameters} > totalResiduals=${totalResiduals}`)
            }

            if (totalParameters == 0) {
                console.warn(`Warning: system has no parameters!`)
            }

            if (totalResiduals == 0) {
                console.warn(`Warning: system has no residuals!`)
            }
        }

        this.needEval = true
    }

    /** Evaluate all residuals and jacobians by iterating over active blocks, which will fill in the appropriate sections of the full system matrices/vectors */
    private evalResidualsAndJacobians(): number {
        if (this.needEval) {
            const beginTime1 = this.enableStats ? performance.now() : undefined

            if (this.onPreEval) this.onPreEval()

            const beginTime2 = this.enableStats ? performance.now() : undefined

            this.sumSquaredResiduals = 0

            //TODO: sparse fill
            ;(this.fullJacobian.data as any).fill(0)
            ;(this.allResiduals.data as any).fill(0)

            this.parameterBlocks.forEach((parameterData, _parameterBlock) => {
                parameterData.activeCount = 0
            })

            this.residualBlocks.forEach((residualData, residualBlock) => {
                if (residualBlock.active) {
                    const residualView = residualData.residualView
                    if (residualBlock.evaluate(residualView, residualData.jacobianViews)) {
                        for (let i = 0; i < residualView.shape[0]; i++) {
                            const resVal = residualView.get(i)
                            this.sumSquaredResiduals += resVal * resVal
                        }
                        for (const parameterBlock of residualBlock.relatedParameters) {
                            const parameterData = this.parameterBlocks.get(parameterBlock)
                            parameterData.activeCount += 1
                        }
                        if (this.symmetryBreakingStepsRemaining > 0) {
                            for (const jacobian of residualData.jacobianViews) {
                                for (let i = 0; i < jacobian.shape[0]; i++) {
                                    for (let j = 0; j < jacobian.shape[1]; j++) {
                                        jacobian.set(i, j, jacobian.get(i, j) + (Math.random() - 0.5) * this.symmetryBreakingScale)
                                    }
                                }
                            }
                        }
                    }
                }
            })

            if (this.symmetryBreakingStepsRemaining > 0) {
                --this.symmetryBreakingStepsRemaining
            }

            this.needEval = false

            if (beginTime1 !== undefined) this.stats.preEval += beginTime2 - beginTime1
            if (beginTime1 !== undefined) this.stats.eval += performance.now() - beginTime2
        }

        return this.sumSquaredResiduals
    }

    /** Apply previously calculated parameter deltas to all active blocks */
    private applyParameterDeltas(): void {
        this.parameterBlocks.forEach((parameterData, parameterBlock) => {
            if (parameterData.activeCount > 0) {
                parameterBlock.updateWithDelta(parameterData.parameterDeltaView)
            }
        })
        this.needEval = true
    }

    private saveAllParams(): void {
        this.parameterBlocks.forEach((parameterData, parameterBlock) => {
            parameterData.saved = parameterBlock.save()
        })
    }

    private restoreAllParams(): void {
        this.parameterBlocks.forEach((parameterData, parameterBlock) => {
            parameterBlock.restore(parameterData.saved)
        })
        this.needEval = true
    }

    private stepGradientDescent(): void {
        this.evalResidualsAndJacobians()
        ;(this.allParameterDeltas.data as any).fill(0)
        for (let i = 0; i < this.fullJacobian.shape[0]; i++) {
            for (let j = 0; j < this.fullJacobian.shape[1]; j++) {
                this.allParameterDeltas.set(j, this.allParameterDeltas.get(j) - this.allResiduals.get(i) * this.fullJacobian.get(i, j) * this.stepSize)
            }
        }

        this.applyParameterDeltas()
    }

    private stepGaussNewton(): void {
        this.evalResidualsAndJacobians()

        multiplyPseudoInverse(this.fullJacobian, this.allResiduals, this.allParameterDeltas, -this.stepSize)

        this.applyParameterDeltas()
    }

    private stepLevenbergMarquardt(): void {
        //TODO: avoid evaluating twice? This would require storing two copies of fullJacobian and allResiduals.
        const curCost = this.evalResidualsAndJacobians()

        const J = this.fullJacobian
        const m = J.shape[0]
        const n = J.shape[1]
        const r = this.allResiduals

        // See https://en.wikipedia.org/wiki/Levenberg%E2%80%93Marquardt_algorithm

        const beginTime1 = this.enableStats ? performance.now() : undefined
        const beginTime2 = this.enableStats ? performance.now() : undefined

        this.linearSolver.solve(this.lambda, 1e-6)

        if (beginTime1 !== undefined) this.stats.mulJTJ += beginTime2 - beginTime1
        if (beginTime2 !== undefined) this.stats.linearSolve += performance.now() - beginTime2

        if (this.errorFlag) return

        // calculate new residuals, and only accept the step if the sum squared residual is lower. Adjust lambda accordinly to expand/contract the trust region.
        this.saveAllParams()
        this.applyParameterDeltas()
        const newCost = this.evalResidualsAndJacobians()
        if (newCost < curCost) {
            this.lambda *= this.lambdaDown
        } else {
            this.restoreAllParams()
            this.lambda *= this.lambdaUp
            if (this.lambda > this.maxLambda) this.lambda = this.maxLambda
            this.sumSquaredResiduals = curCost
        }
    }

    public testJacobians() {
        const eps = 1e-3
        const errTol = 1e-3

        if (this.needsUpdate) {
            this.prepare()
        }

        const numR = this.allResiduals.size
        const numP = this.allParameterDeltas.size
        this.saveAllParams()
        const residualsAtNeg = zeros(numR)
        const jacobianAt0 = zeros(...this.fullJacobian.shape)

        this.evalResidualsAndJacobians()
        for (let p = 0; p < numP; p++) {
            for (let r = 0; r < numR; r++) {
                jacobianAt0.set(r, p, this.fullJacobian.get(r, p))
            }
        }

        for (let testP = 0; testP < numP; testP++) {
            this.restoreAllParams()
            for (let p = 0; p < numP; p++) {
                this.allParameterDeltas.set(p, p === testP ? -eps : 0)
            }
            this.applyParameterDeltas()
            this.evalResidualsAndJacobians()
            for (let r = 0; r < numR; r++) {
                residualsAtNeg.set(r, this.allResiduals.get(r))
            }
            this.restoreAllParams()
            for (let p = 0; p < numP; p++) {
                this.allParameterDeltas.set(p, p === testP ? eps : 0)
            }
            this.applyParameterDeltas()
            this.evalResidualsAndJacobians()

            for (let r = 0; r < numR; r++) {
                const dr_dp_fd = (this.allResiduals.get(r) - residualsAtNeg.get(r)) / (2 * eps)
                const dr_dp_j = jacobianAt0.get(r, testP)
                const d = dr_dp_j - dr_dp_fd
                if (Math.abs(d) > errTol) {
                    console.log(`Finite-difference does not match jacobian at r=${r} p=${testP}: dr_dp_j=${dr_dp_j} dr_dp_fd=${dr_dp_fd}`)
                }
            }
        }

        this.restoreAllParams()
    }

    public checkReady(): boolean {
        if (!this.linearSolver) {
            if (!_EigenLinearSolver) {
                // not ready!
                return false
            }
            this.linearSolver = new _EigenLinearSolver.LeastSquaresSolverWrapper()
        }
        return true
    }

    public step(): number {
        const lastResidual = this.sumSquaredResiduals

        if (this.needsUpdate) {
            this.prepare()
        }

        if (this.allResiduals.shape[0] > 0 && this.allParameterDeltas.shape[0] > 0) {
            const beginTime = this.enableStats ? performance.now() : undefined

            this.stepFn()

            if (beginTime !== undefined) {
                this.stats.count += 1
                this.stats.total += performance.now() - beginTime
            }

            if (lastResidual !== Infinity) {
                const residualDelta = this.sumSquaredResiduals - lastResidual
                if (this.verbose) {
                    console.log(
                        `Residual: ${this.sumSquaredResiduals.toExponential(3)} - Delta: ${residualDelta.toExponential(
                            3,
                        )} - Lambda: ${this.lambda.toExponential(3)}`,
                    )
                }
            }
        }

        return this.sumSquaredResiduals
    }

    public dumpStats() {
        const s = this.stats
        console.log(`Solver.stats:`)
        console.log(`  total = ${s.total.toFixed(2)} msec (${s.count} iterations, ${(s.total / s.count).toFixed(3)} msec/iter)`)
        console.log(`  preEval = ${((100 * s.preEval) / s.total).toFixed(2)} %`)
        console.log(`  eval = ${((100 * s.eval) / s.total).toFixed(2)} %`)
        console.log(`  mulJTJ = ${((100 * s.mulJTJ) / s.total).toFixed(2)} %`)
        console.log(`  linearSolve = ${((100 * s.linearSolve) / s.total).toFixed(2)} %`)
        if (this.enableStats) {
            this.stats = {
                count: 0,
                total: 0,
                preEval: 0,
                eval: 0,
                mulJTJ: 0,
                linearSolve: 0,
            }
        }
    }

    public restart() {
        this.lambda = this.initLambda
        this.symmetryBreakingStepsRemaining = this.symmetryBreakingMaxSteps
    }
}
