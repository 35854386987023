import {ENTER, ESCAPE} from "@angular/cdk/keycodes"
import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from "@angular/core"

@Directive({
    selector: "[cmInput]",
    standalone: true,
})
export class InputDirective {
    constructor(private element: ElementRef) {
        this.outlineColor = "none"
        this.border = "1px solid transparent"
        this.borderRadius = "3px"
    }

    @Input("cmInput")
    set cmInput(value: string | number) {
        this.element.nativeElement.value = value
    }
    @Output("cmInputChange") valueChange = new EventEmitter<string>()

    @HostBinding("style.border") border: string
    @HostBinding("style.border-radius") borderRadius: string
    @HostBinding("style.box-shadow") boxShadow?: string
    @HostBinding("style.outline-style") outlineColor: string

    @HostListener("focus")
    onFocus() {
        this.border = "1px solid slategrey"
        this.boxShadow = "0 0 2px 0 slategrey"
    }

    @HostListener("blur")
    onBlur() {
        this.border = "1px solid transparent"
        this.boxShadow = "none"
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === ENTER && this.element.nativeElement.type !== "textarea") {
            this.element.nativeElement.blur()
        }
        if (event.keyCode === ESCAPE) {
            this.element.nativeElement.blur()
        }
    }

    @HostListener("input")
    onInput(): void {
        this.valueChange.emit(this.element.nativeElement.value)
    }
}
