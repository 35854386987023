<textarea
    class="cm-text-area"
    #inputField
    [disabled]="disabled"
    [placeholder]="placeholder ? placeholder : ''"
    [value]="(fixValue ? initialValue : value) || null"
    (change)="value = inputField.value; valueChange.emit(value)"
    (keyup)="value = inputField.value; keyUp.emit(value)"
    cdkTextareaAutosize
    cdkAutosizeMinRows="{{ minRows }}"
></textarea>
