import {ValueTypeDescriptor, inletDescriptor, outletDescriptor, scopeDescriptor} from "@src/templates/runtime-graph/descriptors"
import {IDataObject, IDataObjectNew, ITransientDataObject, ITransientDataObjectNew} from "@src/templates/interfaces/data-object"
import {MeshData} from "@src/geometry-processing/mesh-data"
import {IMaterialData, IMaterialGraph, keyForMaterialData} from "@src/templates/interfaces/material-data"
import {IMatrix4} from "@src/templates/interfaces/matrix"

export type TemplateImageData = {
    dataObject: IDataObject | ITransientDataObject
}

export type TemplateImageDataNew = {
    dataObject: IDataObjectNew | ITransientDataObjectNew
}

export namespace TypeDescriptors {
    type Desc<T> = ValueTypeDescriptor<T>

    export const inlet = inletDescriptor
    export const outlet = outletDescriptor
    export const builder = scopeDescriptor

    export function Primitive<T>(): Desc<T> {
        //@ts-ignore
        return undefined
    }

    const _Tuple = {deepCompare: 1}
    export function Tuple<T>(): Desc<T> {
        return _Tuple
    }

    export const Number = Primitive<number>()
    export const Boolean = Primitive<boolean>()
    export const String = Primitive<string>()

    export function Function<T>(): Desc<T> {
        //@ts-ignore
        return undefined
    }

    const _Identity = {
        deepCompare: (a: any, b: any) => {
            return a === b
        },
        deepCopy: (a: any) => a,
    }
    export function Identity<T>(key?: keyof T): Desc<T> {
        if (key) {
            return {
                deepCompare: (a: T, b: T) => (a && a[key]) === (b && b[key]),
                deepCopy: (a: T) => a,
            }
        } else {
            return _Identity
        }
    }

    const _Array = {
        deepCompare: 1,
        deepCopy: (a: any) => [...a],
    }
    export function Array<T>(elemType: Desc<T>): Desc<T[]> {
        //TODO: nested types
        return _Array
    }

    export function Nullable<T>(x: Desc<T>): Desc<T | null> {
        //@ts-ignore
        return x
    }

    const _JSON = {deepCompare: true}
    export function JSON<T = any>(): Desc<T> {
        return _JSON
    }

    const _ShallowJSON = {deepCompare: 1}
    export function ShallowJSON<T = any>(): Desc<T> {
        return _ShallowJSON
    }

    export function Map<K, V>(): Desc<Map<K, V>> {
        //@ts-ignore
        return undefined
        //TODO: map deep compare
    }

    export function Set<V>(): Desc<Set<V>> {
        //@ts-ignore
        return undefined
        //TODO: map deep compare
    }

    export const Matrix4: Desc<IMatrix4> = {
        deepCompare: (a, b) => {
            if (a && b) {
                return a.equals(b)
            }
            if (a || b) return false
            else return true
        },
        deepCopy: (a) => a?.copy(),
    }

    export const MaterialData: Desc<IMaterialData> = {
        deepCompare: (a, b) => keyForMaterialData(a) === keyForMaterialData(b),
    }

    export const MaterialGraph = Identity<IMaterialGraph>("uniqueId")
    export const MeshData = Identity<MeshData>("id")
    export const DataObject = Identity<IDataObject>("id")
    export const DataObjectNew = Identity<IDataObjectNew>("legacyId")
    export const TemplateImageData = Identity<TemplateImageData>()
    export const TemplateImageDataNew = Identity<TemplateImageDataNew>()

    export function TemplateNode<T>(): Desc<T> {
        return ShallowJSON<T>()
    }
}
