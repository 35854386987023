import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpShift} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-shift"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const ShiftNode = declareImageOpNode(imageOpShift)

export function shift(parameters: GetParameters<Context, ParameterType>) {
    return new ShiftNode(parameters)
}
