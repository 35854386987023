type SelectFields<T, K extends keyof T> = {
    [P in K]: T[P]
}

export const objectFieldsDifferent = <T, K extends keyof T>(
    current: T,
    previous: T | undefined,
    fields: K[],
    equal: ((a: T[K], b: T[K], field: K) => boolean) | undefined,
    callIfDifferent?: (partialCurrent: SelectFields<T, K>) => void | boolean,
) => {
    if (current === previous) return false

    const isEqual = equal ?? ((a: T[K], b: T[K]): boolean => a === b)

    const partialCurrent: Partial<SelectFields<T, K>> = {}
    const partialPrevious: Partial<SelectFields<T, K>> = {}

    let allEqual = true
    fields.forEach((field) => {
        const currentValue = current[field]
        if (!previous || !isEqual(currentValue, previous[field], field)) allEqual = false
        partialCurrent[field] = currentValue
        partialPrevious[field] = previous?.[field]
    })

    if (!allEqual) {
        const override = callIfDifferent?.(partialCurrent as SelectFields<T, K>)
        if (typeof override === "boolean") return override
    }

    //if (!allEqual) console.log("Difference detected", partialCurrent, partialPrevious)
    return !allEqual
}

export const objectDifferent = <T extends object, K extends keyof T>(
    current: T,
    previous: T | undefined,
    equal: ((a: T[K], b: T[K], field: K) => boolean) | undefined,
    callIfDifferent?: (current: T) => void | boolean,
) => {
    if (current === previous) return false

    const keys = [...new Set([...Object.keys(current), ...Object.keys(previous ?? {})])] as K[]

    return objectFieldsDifferent<T, K>(current, previous, keys, equal, callIfDifferent as (partialCurrent: SelectFields<T, K>) => void | boolean)
}

export const arrayDifferent = <T>(
    current: T[],
    previous: T[] | undefined,
    equal: ((a: T, b: T, index: number) => boolean) | undefined,
    callIfDifferent?: (current: T[]) => void | boolean,
) => {
    if (current === previous) return false

    const isEqual = equal ?? ((a: T, b: T) => a === b)
    const allEqual = (a: T[], b: T[]): boolean => {
        if (a.length !== b.length) return false
        for (let i = 0; i < a.length; i++) if (!isEqual(a[i], b[i], i)) return false
        return true
    }

    const different = !previous || !allEqual(current, previous)

    if (different) {
        const override = callIfDifferent?.(current)
        if (typeof override === "boolean") return override
    }

    //if (different) console.log("Array different", current, previous)
    return different
}

export const setDifferent = <T>(current: Set<T>, previous: Set<T> | undefined, callIfDifferent?: (current: Set<T>) => void | boolean) => {
    if (current === previous) return false

    const allEqual = (a: Set<T>, b: Set<T>): boolean => {
        if (a.size !== b.size) return false
        for (const value of a) if (!b.has(value)) return false
        return true
    }

    const different = !previous || !allEqual(current, previous)

    if (different) {
        const override = callIfDifferent?.(current)
        if (typeof override === "boolean") return override
    }

    //if (different) console.log("Set different", current, previous)
    return different
}

export const mapDifferent = <K, V>(
    current: Map<K, V>,
    previous: Map<K, V> | undefined,
    equal: ((a: V, b: V, field: K) => boolean) | undefined,
    callIfDifferent?: (current: Map<K, V>) => void | boolean,
) => {
    if (current === previous) return false

    const isEqual = equal ?? ((a: V, b: V) => a === b)

    const allEqual = (a: Map<K, V>, b: Map<K, V>): boolean => {
        if (a.size !== b.size) return false
        for (const [key, value] of a) if (!b.has(key) || !isEqual(b.get(key)!, value, key)) return false
        return true
    }

    const different = !previous || !allEqual(current, previous)

    if (different) {
        const override = callIfDifferent?.(current)
        if (typeof override === "boolean") return override
    }

    //if (different) console.log("Map different", current, previous)
    return different
}

export const anyDifference = (inputs: boolean[]) => inputs.some((input) => input)
