import {ApiFields, declareApiModel, EndpointUrls, MakeApiModelBase} from "@legacy/api-model/api-model"
import {EntityType} from "@legacy/models/entity-type"
import {DataObjectAssignment} from "@legacy/api-model/data-object-assignment"

@declareApiModel(EndpointUrls.templateRevisionsUrl, EntityType.TemplateRevision, null, true)
export class TemplateRevision extends MakeApiModelBase<TemplateRevision>() {
    @ApiFields.id({name: "template"}) template!: number
    @ApiFields.string({name: "template_name", readOnly: true}) templateName!: string
    @ApiFields.number({name: "number", readOnly: true}) number!: number
    @ApiFields.date({name: "created", readOnly: true}) created!: Date
    @ApiFields.boolean({name: "completed"}) completed!: boolean
    @ApiFields.manyRelated({name: "data_object_assignments", model: DataObjectAssignment}) dataObjectAssignments: DataObjectAssignment[] = [] // read-only
    @ApiFields.json({name: "template_graph"}) templateGraph: any
}
