import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {Context, color} from "@src/materials/types"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {
    DataObject,
    ITransientDataObject,
    ImageResource,
    ImageResourceSchema,
    getDataObjectFromImageResourceForFormatAndResolution,
    isImageResourceWithTransientDataObject,
} from "@src/materials/material-node-graph"
import {threeRGBColorNode, threeValueNode} from "@src/materials/three-utils"
import {ImageColorSpace, MediaType} from "@src/api-gql/data-object"
import {TextureResolution} from "@src/templates/nodes/scene-properties"
import {GetProperty} from "@src/graph-system/utils"

type LoadTextureDescriptor = {
    lowRes?: string
    primary: string | ITransientDataObject
    colorSpace?: THREE.ColorSpace
}

function selectTextureFormatAndResolutionFromOptionsAndMediaType(
    context: Context,
    mediaType: MediaType,
    alphaIsImportant: boolean,
): {primary: {format: "png" | "jpg"; resolution: TextureResolution}; lowRes?: {format: "jpg"; resolution: TextureResolution}} {
    if (mediaType === "image/png" && alphaIsImportant) {
        // Image may have an alpha channel, so use _only_ the original data object!
        return {
            primary: {
                format: "png",
                resolution: "original",
            },
        }
    }

    return {
        primary: {
            format: "jpg",
            resolution: context.textureResolution,
        },
        lowRes: context.progressiveTextureLoading && context.textureResolution !== "500px" ? {format: "jpg", resolution: "500px"} : undefined,
    }
}

function getTextureDescriptorForImageResource(context: Context, imageResource: ImageResource, alphaIsImportant: boolean): LoadTextureDescriptor {
    if (isImageResourceWithTransientDataObject(imageResource)) {
        return {
            primary: imageResource.transientDataObject,
            colorSpace: getThreeJSColorSpaceForDataObject(imageResource.transientDataObject),
        }
    } else {
        const {primary, lowRes} = selectTextureFormatAndResolutionFromOptionsAndMediaType(context, imageResource.mainDataObject.mediaType, alphaIsImportant)
        const primaryDataObject = getDataObjectFromImageResourceForFormatAndResolution(imageResource, primary.format, primary.resolution)
        const lowResDataObject = lowRes ? getDataObjectFromImageResourceForFormatAndResolution(imageResource, lowRes.format, lowRes.resolution) : undefined

        if (!primaryDataObject) throw Error(`No primary data object found for image resource (data object legacyId: ${imageResource.mainDataObject.legacyId})`)

        return {
            lowRes: lowResDataObject?.downloadUrl,
            primary: primaryDataObject.downloadUrl,
            colorSpace: getThreeJSColorSpaceForDataObject(primaryDataObject),
        }
    }
}

function getThreeJSColorSpaceForDataObject(dataObject: DataObject | ITransientDataObject) {
    if (dataObject.imageColorSpace === ImageColorSpace.Linear) {
        return THREE.LinearSRGBColorSpace
    } else if (dataObject.imageColorSpace === ImageColorSpace.Srgb) {
        return THREE.SRGBColorSpace
    }
}

export class TexImage extends DeclareMaterialNode(
    {
        returns: z.object({color: materialSlots, alpha: materialSlots}),
        inputs: z.object({
            vector: materialSlots.optional(),
        }),
        parameters: z.object({
            alpha: z.number().optional(),
            color: color.optional(),
            extension: z.enum(["REPEAT", "EXTEND", "CLIP", "MIRROR"]).optional(),
            imageColorspaceSettingsName: z.enum(["Linear", "Non-Color", "sRGB"]).optional(),
            interpolation: z.enum(["Linear", "Cubic", "Closest", "Smart"]).optional(),
            projection: z.enum(["FLAT", "BOX", "SPHERE", "TUBE"]).optional(),
            imageResource: ImageResourceSchema.optional(),
        }),
    },
    {
        toThree: async function (this: TexImage, {get, inputs, parameters, context}) {
            const {imageResource, imageColorspaceSettingsName, extension, interpolation} = parameters
            if (!imageResource) {
                console.warn("No image resource provided")
                return {
                    color: threeRGBColorNode(parameters.color ?? {r: 0.5, g: 0.5, b: 0.5}),
                    alpha: threeValueNode(1),
                }
            }

            const getInputColorSpace = () => {
                switch (imageColorspaceSettingsName) {
                    case "Linear":
                        return THREE.LinearSRGBColorSpace
                    case "Non-Color":
                        return THREE.NoColorSpace
                    case "sRGB":
                        return THREE.SRGBColorSpace
                }
            }

            const getWrapping = () => {
                switch (extension) {
                    case "REPEAT":
                        return THREE.RepeatWrapping
                    case "EXTEND":
                        return THREE.ClampToEdgeWrapping
                    case "MIRROR":
                        return THREE.MirroredRepeatWrapping
                }
            }

            const getMinFilter = (): THREE.MinificationTextureFilter | undefined => {
                if (context.forceFiltering === "nearest") return THREE.NearestFilter
                else if (context.forceFiltering === "linear") return THREE.LinearMipMapLinearFilter

                switch (interpolation) {
                    case "Linear":
                        return THREE.LinearMipMapLinearFilter
                    case "Smart":
                        return THREE.LinearMipMapLinearFilter
                    case "Closest":
                        return THREE.NearestFilter
                }
            }

            const getMagFilter = (): THREE.MagnificationTextureFilter | undefined => {
                if (context.forceFiltering === "nearest") return THREE.NearestFilter
                else if (context.forceFiltering === "linear") return THREE.LinearFilter

                switch (interpolation) {
                    case "Linear":
                        return THREE.LinearFilter
                    case "Closest":
                        return THREE.NearestFilter
                }
            }

            const alphaIsImportant = [...this.parents].some((parent) => parent instanceof GetProperty && parent.parameters.key === "alpha")

            const {primary, lowRes, colorSpace: dataObjectColorSpace} = getTextureDescriptorForImageResource(context, imageResource, alphaIsImportant)
            const colorSpace = dataObjectColorSpace ?? getInputColorSpace()
            const wrapping = getWrapping()
            const minFilter = getMinFilter()
            const magFilter = getMagFilter()

            if (!colorSpace) console.warn("No color space value provided, using sRGB")
            if (!minFilter) console.warn(`Warning: Unsupported minification filter ${interpolation}, using LinearMipMapLinearFilter`)
            if (!magFilter) console.warn(`Warning: Unsupported magnification filter ${interpolation}, using LinearFilter`)
            if (!wrapping) console.warn(`Warning: Unsupported extension mode: ${extension}, using repeat instead`)

            const setupTexture = (texture: THREE.Texture) => {
                texture.colorSpace = colorSpace ?? THREE.SRGBColorSpace
                texture.minFilter = getMinFilter() ?? THREE.LinearMipMapLinearFilter
                texture.magFilter = getMagFilter() ?? THREE.LinearFilter
                texture.wrapS = texture.wrapT = wrapping ?? THREE.RepeatWrapping
                texture.anisotropy = 1
                return texture
            }

            const textureLoader = new THREE.TextureLoader()

            const firstTexture = lowRes ?? primary
            const secondTexture = firstTexture !== primary ? primary : undefined

            const loadTexture = async (texture: string | ITransientDataObject) => {
                return new Promise<THREE.Texture>((resolve, reject) => {
                    const url = typeof texture === "string" ? texture : texture.toObjectURL()
                    const cleanup = () => {
                        if (typeof texture !== "string") URL.revokeObjectURL(url)
                    }

                    textureLoader.load(
                        url,
                        (texture) => {
                            resolve(setupTexture(texture))
                            cleanup()
                        },
                        undefined,
                        () => {
                            reject()
                            cleanup()
                        },
                    )
                })
            }

            const texture = await loadTexture(firstTexture)
            const textureNode = THREENodes.texture(texture, await get(inputs.vector))
            context.onThreeCreatedTexture?.(textureNode)

            if (secondTexture)
                loadTexture(secondTexture)
                    .then((secondTexture) => {
                        textureNode.value.dispose()
                        textureNode.value = secondTexture
                        context.onRequestRedraw?.(this)
                    })
                    .catch(() => {
                        console.warn("Failed to load second texture")
                    })

            return {
                color: THREENodes.color(new THREENodes.SplitNode(textureNode, "rgb")),
                alpha: new THREENodes.SplitNode(textureNode, "a"),
            }
        },
    },
) {}
