import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const sparseListDescriptor = {
    length: inletDescriptor<number>(),
    __output_value: outletDescriptor<any[]>(),
}

export class SparseList<T> implements NodeClassImpl<typeof sparseListDescriptor, typeof SparseList> {
    static descriptor = sparseListDescriptor
    static uniqueName = "SparseList"
    length!: Inlet<number>;
    [index: number]: Inlet<T>
    __output_value!: Outlet<T[]>

    run() {
        if (this.length === NotReady) {
            this.__output_value.emitIfChanged(NotReady)
            return
        }
        const list: T[] = []
        for (let n = 0; n < this.length; n++) {
            const elem = this[n]
            if (elem === NotReady) {
                continue
            }
            list.push(elem)
        }
        this.__output_value.emitIfChanged(list)
    }
}
