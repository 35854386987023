import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from "@angular/core"
import {SocketPosition} from "@node-editor/models"

@Component({
    selector: "cm-node-socket",
    templateUrl: "./node-socket.component.html",
    styleUrls: ["./node-socket.component.scss"],
    host: {
        "[class.cm-input-socket]": "type==='input'",
        "[class.cm-output-socket]": "type==='output'",
    },
    standalone: true,
})
export class NodeSocketComponent {
    @Input() type!: "input" | "output"
    @Output() connectionStart: EventEmitter<void> = new EventEmitter<void>()
    @Output() connectionEnd: EventEmitter<void> = new EventEmitter<void>()

    @HostListener("mousedown", ["$event"]) onMouseDown(event: MouseEvent) {
        event.stopPropagation()
        this.connectionStart.emit()
    }

    @HostListener("mouseup") onMouseUp() {
        this.connectionEnd.emit()
    }

    constructor(private elementRef: ElementRef) {}

    getPosition(): SocketPosition {
        const boundingRect: DOMRect = this.elementRef.nativeElement.getBoundingClientRect()
        return {
            x: boundingRect.x + boundingRect.width / 2,
            y: boundingRect.y + boundingRect.height / 2,
        }
    }
}
