import {CurveInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/curve-interpolator"
import {Vector2} from "@cm/lib/math/vector2"

export class LinearInterpolator implements CurveInterpolator {
    constructor(
        private points: Vector2[],
        private tValues: number[],
    ) {
        if (points.length !== tValues.length) {
            throw new Error("Points and tValues must have the same length.")
        }
    }

    evaluate(t: number): Vector2 {
        const n = this.points.length - 1

        if (t <= this.tValues[0]) {
            // extrapolate
            const t0 = this.tValues[0]
            const t1 = this.tValues[1]
            const u = (t - t0) / (t1 - t0)
            return this.points[0].mul(1 - u).add(this.points[1].mul(u))
        } else if (t >= this.tValues[n]) {
            // extrapolate
            const t0 = this.tValues[n - 1]
            const t1 = this.tValues[n]
            const u = (t - t0) / (t1 - t0)
            return this.points[n - 1].mul(1 - u).add(this.points[n].mul(u))
        }

        for (let i = 0; i < n; i++) {
            if (t <= this.tValues[i + 1]) {
                const t0 = this.tValues[i]
                const t1 = this.tValues[i + 1]
                const u = (t - t0) / (t1 - t0)
                return this.points[i].mul(1 - u).add(this.points[i + 1].mul(u))
            }
        }

        throw new Error("This should never happen.")
    }
}
