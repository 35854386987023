import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {Operator, OperatorInput, OperatorOutput, OperatorPanelComponentType} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {ShiftPanelComponent} from "app/textures/texture-editor/operator-stack/operators/shift/panel/shift-panel.component"
import {ShiftToolbox} from "app/textures/texture-editor/operator-stack/operators/shift/toolbox/shift-toolbox"
import {EventEmitter} from "@angular/core"
import {Vector2Like} from "@cm/lib/math/vector2"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {deepCopy} from "@cm/lib/utils/utils"
import {affineTransform} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/affine-transform-node"
import {TextureType} from "@api"
import {ImageOpNodeGraphEvaluator} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"

export class OperatorShift extends OperatorBase<TextureEditNodes.OperatorShift> {
    readonly shiftInPixelsChanged = new EventEmitter<Vector2Like>()
    readonly showGuidesChanged = new EventEmitter<boolean>()

    readonly panelComponentType: OperatorPanelComponentType = ShiftPanelComponent
    readonly canvasToolbox: ShiftToolbox

    readonly type = "operator-shift" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorShift | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-shift",
                enabled: true,
                shiftInPixels: {
                    x: 0,
                    y: 0,
                },
            },
        )

        this.canvasToolbox = new ShiftToolbox(this)
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this.canvasToolbox.remove()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        return new OperatorShift(this.callback, deepCopy(this.node))
    }

    // OperatorBase
    async getImageOpNodeGraph(evaluator: ImageOpNodeGraphEvaluator, textureType: TextureType, input: OperatorInput): Promise<OperatorOutput> {
        const resultImage = affineTransform({
            sourceImage: input,
            transform: new Matrix3x2().translate(this.shiftInPixels),
        })
        return {resultImage}
    }

    get shiftInPixels(): Vector2Like {
        return this.node.shiftInPixels
    }

    set shiftInPixels(value: Vector2Like) {
        if (this.node.shiftInPixels.x === value.x && this.node.shiftInPixels.y === value.y) {
            return
        }
        this.node.shiftInPixels = {x: value.x, y: value.y}
        this.markEdited()
        this.requestEval()
        this.shiftInPixelsChanged.emit(value)
    }

    get showGuides(): boolean {
        return this._showGuides
    }

    set showGuides(value: boolean) {
        if (this._showGuides === value) {
            return
        }
        this._showGuides = value
        this.showGuidesChanged.emit(value)
    }

    private _showGuides = true
}
