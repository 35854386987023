import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const branchDescriptor = {
    input: inletDescriptor<any>(),
    true: outletDescriptor<any>(),
    false: outletDescriptor<any>(),
}

export class Branch<T> implements NodeClassImpl<typeof branchDescriptor, typeof Branch> {
    static descriptor = branchDescriptor
    static uniqueName = "Branch"
    input!: Inlet<T>
    true!: Outlet<T>
    false!: Outlet<T>

    run() {
        if (this.input === NotReady) {
            this.true.emitIfChanged(NotReady)
            this.false.emitIfChanged(NotReady)
            return
        } else if (this.input) {
            this.true.emitIfChanged(this.input)
            this.false.emitIfChanged(NotReady)
        } else {
            this.true.emitIfChanged(NotReady)
            this.false.emitIfChanged(this.input)
        }
    }
}
