// @ts-strict-ignore
import {CommonModule} from "@angular/common"
import {Component, Input, OnDestroy, OnInit} from "@angular/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"

interface IEditorWithCapture extends IEditor {
    captureRelationParams(relation: Nodes.Relation): number[]
}

@Component({
    selector: "cm-connection-inspector",
    templateUrl: "./connection-inspector.component.html",
    styleUrls: ["./connection-inspector.component.scss"],
    standalone: true,
    imports: [
        NodeReferenceSlotComponent,
        InspectorSectionComponent,
        NumericInputComponent,
        InputContainerComponent,
        MatInputModule,
        MatSelectModule,
        ButtonComponent,
        CommonModule,
    ],
})
export class ConnectionInspectorComponent implements OnInit, OnDestroy {
    @Input() relation: Nodes.Relation | null = null
    @Input() editor: IEditorWithCapture

    NodeUtils = NodeUtils

    constructor() {}

    ngOnInit(): void {}

    captureRelationParams(): void {
        const relation = this.relation
        const params = this.editor.captureRelationParams(relation)
        const limitPrecision = (val: number) => Math.round(val * 100) * 0.01
        relation.translation = {
            x: limitPrecision(params[0]),
            y: limitPrecision(params[1]),
            z: limitPrecision(params[2]),
        }
        relation.rotation = {
            type: "fixed",
            x: limitPrecision(params[3]),
            y: limitPrecision(params[4]),
            z: limitPrecision(params[5]),
        }
        this.editor.sceneManager.markNodeChanged(relation)
    }

    getTranslationType(relation: Nodes.Relation, propertyName: "x" | "y" | "z"): "fixed" | "sliding" {
        return typeof relation.translation[propertyName] === "number" ? "fixed" : "sliding"
    }

    setTranslationType(relation: Nodes.Relation, propertyName: "x" | "y" | "z", type: "fixed" | "sliding") {
        const trans = relation.translation[propertyName]
        if (type === "fixed" && typeof trans !== "number") {
            // convert sliding to fixed
            relation.translation[propertyName] = trans.default
        } else if (type === "sliding" && typeof trans === "number") {
            // convert fixed to sliding
            relation.translation[propertyName] = Nodes.create<Nodes.Variable_R1>({
                type: "variable",
                topology: "boundedReal",
                range: [trans - 100, trans + 100],
                default: trans,
            })
        }
        this.editor.sceneManager.markNodeChanged(relation)
    }

    setRotationType(relation: Nodes.Relation, type: Nodes.RelationRotation["type"]) {
        const rotation = relation.rotation
        if (type === rotation.type) return
        if (type === "fixed") {
            relation.rotation = {
                type,
                x: 0,
                y: 0,
                z: 0,
            }
        } else if (type === "hinge") {
            relation.rotation = {
                type,
                axis: "x",
                rotation: Nodes.create<Nodes.Variable_S1>({
                    type: "variable",
                    topology: "1-sphere",
                    default: [0, 1],
                }),
            }
        } else if (type === "ball") {
            relation.rotation = {
                type,
                rotation: Nodes.create<Nodes.Variable_S3>({
                    type: "variable",
                    topology: "3-sphere",
                    default: [0, 0, 0, 1],
                }),
            }
        }
        this.editor.sceneManager.markNodeChanged(relation)
    }

    updateNode(node: Nodes.Node) {
        this.editor.sceneManager.markNodeChanged(node)
    }

    ngOnDestroy(): void {}
}
