// @ts-strict-ignore
import {Component, Input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatSliderModule} from "@angular/material/slider"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {IEditorView} from "@editor/components/surface-definer/surface-definer.component"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"

@Component({
    selector: "cm-guide-inspector",
    templateUrl: "./guide-inspector.component.html",
    styleUrls: ["./guide-inspector.component.scss"],
    standalone: true,
    imports: [InspectorSectionComponent, InputContainerComponent, NumericInputComponent, MatSliderModule, FormsModule],
})
export class GuideInspectorComponent {
    @Input() editor: IEditorView
    @Input() node: Nodes.Guide | null = null

    hasWidth(guide: Nodes.Guide): guide is Nodes.Guide & {width: Nodes.PlaneGuide["width"]} {
        return "width" in guide
    }
    hasHeight(guide: Nodes.Guide): guide is Nodes.Guide & {height: Nodes.PlaneGuide["height"]} {
        return "height" in guide
    }
}
