import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {CrosshairToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/crosshair-toolbox-item"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class SelectCloneSourceToolboxItem extends CrosshairToolboxItem {
    constructor(parent: CanvasBaseToolboxItem) {
        super(parent)
        this.cloneSourcePosition = new Vector2(0, 0)
    }

    override hitTest(point: Vector2Like): boolean {
        if (this.selected && this.isPointInImage(point)) {
            this.cursor = "crosshair"
            return true
        }
        return false
    }

    override onMouseDown(event: paper.ToolEvent): boolean {
        this.cloneSourcePosition = event.point.floor()
        return false
    }

    override onMouseDrag(event: paper.ToolEvent): boolean {
        this.cloneSourcePosition = event.point.floor()
        return false
    }

    set cloneSourcePosition(value: Vector2Like) {
        this._cloneSourcePosition.setFromVector2Like(value)
        this.crosshairPosition = this._cloneSourcePosition.add(new paper.Point(0.5, 0.5))
    }

    get cloneSourcePosition(): Vector2 {
        return this._cloneSourcePosition
    }

    private _cloneSourcePosition = new Vector2(0, 0)
}
