import {JobState, JobTaskState} from "@api"
import moment from "moment"

export const jobStateTooltip = (job: {state: JobState; started?: Date | string; cancelled?: Date | string; finished?: Date | string} | null) => {
    switch (job?.state) {
        case JobState.Init:
            return "Initializing"
        case JobState.Running:
            return job.started ? `Started ${moment(job.started).fromNow()}` : "Running"
        case JobState.Complete:
            return job.finished ? `Completed ${moment(job.finished).fromNow()}` : "Completed"
        case JobState.Failed:
            return job.finished ? `Failed ${moment(job.finished).fromNow()}` : "Failed"
        case JobState.Cancelled:
            return job.cancelled ? `Cancelled ${moment(job.cancelled).fromNow()}` : "Cancelled"
    }
    return ""
}

export const jobTaskStateTooltip = (job: {state: JobTaskState; started?: Date | string; cancelled?: Date | string; finished?: Date | string} | null) => {
    switch (job?.state) {
        case JobTaskState.Init:
            return "Initializing"
        case JobTaskState.Runnable:
            return job.started ? `Started ${moment(job.started).fromNow()}` : "Runnable"
        case JobTaskState.Queued:
            return job.started ? `Queued ${moment(job.started).fromNow()}` : "Queued"
        case JobTaskState.Running:
            return job.started ? `Started ${moment(job.started).fromNow()}` : "Running"
        case JobTaskState.Complete:
            return job.finished ? `Completed ${moment(job.finished).fromNow()}` : "Completed"
        case JobTaskState.Failed:
            return job.finished ? `Failed ${moment(job.finished).fromNow()}` : "Failed"
        case JobTaskState.Fatal:
            return job.finished ? `Fatally failed ${moment(job.finished).fromNow()}` : "Fatally failed"
        case JobTaskState.Cancelled:
            return job.cancelled ? `Cancelled ${moment(job.cancelled).fromNow()}` : "Cancelled"
    }
    return ""
}
