<cm-sidebar-layout>
    <cm-item-filters cm-filters>
        <cm-tag-search-filter></cm-tag-search-filter>
        <cm-checkboxes-filter label="User Type" name="isStaff" [options]="Labels.IsStaff"></cm-checkboxes-filter>
        <cm-checkboxes-filter label="Account" name="isActive" [options]="Labels.Enabled"></cm-checkboxes-filter>
        <cm-checkboxes-filter label="Photographer" name="isPhotographer" [options]="Labels.IsPhotographer"></cm-checkboxes-filter>
    </cm-item-filters>
    <cm-users-grid></cm-users-grid>
</cm-sidebar-layout>
<router-outlet></router-outlet>
