<h2 mat-dialog-title>New Texture</h2>
<mat-dialog-content>
    <form id="textureForm" (submit)="confirmAction()">
        <mat-form-field>
            <input [(ngModel)]="result.width" matInput type="number" [placeholder]="!imperial ? 'Width (cm)' : 'Width (in)'" name="textureWidth" />
        </mat-form-field>
        <mat-form-field>
            <input [(ngModel)]="result.height" matInput type="number" [placeholder]="!imperial ? 'Height (cm)' : 'Height (in)'" name="textureHeight" />
        </mat-form-field>
        <mat-form-field>
            <input
                [(ngModel)]="result.displacement"
                matInput
                type="number"
                [placeholder]="!imperial ? 'Displacement (cm)' : 'Displacement (in)'"
                name="textureDisplacement"
            />
        </mat-form-field>
        @if (showColorSpaceSetting) {
            <mat-form-field>
                <mat-select [(ngModel)]="result.colorSpace" placeholder="Image file color space" name="textureColorSpace">
                    @for (imageColorspaceLabel of Labels.ImageColorSpace.values(); track imageColorspaceLabel) {
                        <mat-option [value]="imageColorspaceLabel.state">{{ imageColorspaceLabel.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancelAction()">Cancel</button>
    <button mat-button form="textureForm" [disabled]="!result.width || !result.height || !result.colorSpace">Create</button>
</mat-dialog-actions>
