import {deepCopy, deepEqual} from "@src/utils/utils"
import {NotReady, Outlet, valueChanged} from "@src/templates/runtime-graph/slots"
import {DescriptorEntry, ValueTypeDescriptor} from "@src/templates/runtime-graph/descriptors"
import {GraphScheduler} from "@src/templates/runtime-graph/graph-scheduler"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {NodeId} from "@src/templates/runtime-graph/types"
import {ConnectionData, INodeInstance, ResolveAlias} from "@src/templates/runtime-graph/types"

export interface BuilderOutlet<T> {
    _typeTag?: T
    outlet: true
    node: ConnectionData
    key: string
    inst: Outlet<unknown>
    valueTypeDesc?: ValueTypeDescriptor<unknown>
}

export type BuilderInlet<T> = T | BuilderOutlet<T> | ResolveAlias<T>

function valueSnapshot<T>(x: T | typeof NotReady, valueTypeDesc?: ValueTypeDescriptor<T>): T | typeof NotReady {
    if (x === NotReady) return x
    const _deepCopy = valueTypeDesc?.deepCopy
    const _deepCompare = valueTypeDesc?.deepCompare
    if (_deepCopy) {
        if (typeof _deepCopy === "function") return _deepCopy(x)
        return deepCopy(x, _deepCopy === true ? undefined : _deepCopy)
    } else if (_deepCompare) {
        // number = depth limit, true = unlimited
        if (typeof _deepCompare === "function") throw new Error("copy/compare function mismatch")
        return deepCopy(x, _deepCompare === true ? undefined : _deepCompare)
    } else {
        return x
    }
}

function cleanupInstance(inst: INodeInstance) {
    if (inst.cleanup) {
        inst.cleanup()
    }
    const provideScopeAs = inst.$info?.provideScopeAs
    if (provideScopeAs) {
        const builder = (inst as any)[provideScopeAs] as GraphBuilder
        builder?.destroy()
    }
}

export function isBuilderOutlet(assignment?: BuilderInlet<any>): assignment is BuilderOutlet<unknown> {
    return assignment?.outlet === true
}

export class GraphBuilder {
    connectionData = new Map<NodeId, ConnectionData>()
    instanceData = new Map<NodeId, INodeInstance>()
    aliases = new Map<string, BuilderInlet<unknown>>()
    curOutlets = new Set<Outlet<unknown>>()
    inputs = new Map<string, BuilderOutlet<unknown>>()
    outputs = new Map<string, BuilderOutlet<unknown>>()
    unvisitedNodes = new Set<NodeId>()
    unvisitedInputs = new Set<string>()
    unvisitedOutputs = new Set<string>()

    // nodeClass<T>(ctor: { new (...args: any): T }, props: { [K in keyof T]: T[K] } ): T {
    //     return new ctor();
    // }

    constructor(
        readonly scopePrefix: string,
        private scheduler: GraphScheduler,
    ) {}

    reset() {
        this.connectionData.clear()
        this.instanceData.clear()
        this.aliases.clear()
        this.curOutlets.clear()
        this.inputs.clear()
        this.outputs.clear()
        this.unvisitedNodes.clear()
        this.unvisitedInputs.clear()
        this.unvisitedOutputs.clear()
    }

    destroy() {
        for (const [id, inst] of this.instanceData) {
            cleanupInstance(inst)
        }
    }

    scope() {
        return new GraphBuilderScope(this, this.scopePrefix)
    }

    finalizeChanges() {
        for (const id of this.unvisitedNodes) {
            // removed
            const inst = this.instanceData.get(id)
            this.instanceData.delete(id)
            this.connectionData.delete(id)
            if (inst) {
                cleanupInstance(inst)
            }
        }
        for (const key of this.unvisitedInputs) {
            this.inputs.delete(key)
        }
        for (const key of this.unvisitedOutputs) {
            this.outputs.delete(key)
        }
        for (const outlet of this.curOutlets) {
            outlet.targets.length = 0
        }
        this.curOutlets.clear()
        for (const [id, instDef] of this.connectionData) {
            for (const key in instDef) {
                let val = (instDef as any)[key]
                while (val instanceof ResolveAlias) {
                    const id = val.id
                    val = this.aliases.get(id)
                    if (!val) {
                        console.warn(`Could not resolve node: ${id}`)
                    }
                    ;(instDef as any)[key] = val
                }
            }
        }

        const scheduleGroup: INodeInstance[] = []
        const dependencyMap = new Map<NodeId, NodeId[]>()

        for (const [id, instDef] of this.connectionData) {
            const info = instDef.$info
            const descriptor = info.nodeClass.descriptor as any
            let inst = this.instanceData.get(id)
            const keySet: any = instDef
            let needsUpdate = false
            let didCreate = false
            const inletKeys: string[] = []
            const constKeys: string[] = []
            const upstreamNodeIds: NodeId[] = []
            if (!inst) {
                // added
                //console.log("New node:", id);
                inst = new info.nodeClass()
                inst.$id = id
                inst.$info = info
                inst.$scheduled = false
                inst.$scheduleGroup = scheduleGroup
                this.instanceData.set(id, inst)
                if (info.provideScopeAs) {
                    //TODO: by using 'id' as the scopePrefix, nested node IDs will have multiple ':' separators in the path (rootScope:Node@1/nestedScope:Node@1)
                    // Simply changing the separator _may_ not be safe, because the distinction between ':' and '/' was introduced to avoid silent collisions that
                    // messed up the change detection... need to work this out.
                    ;(inst as any)[info.provideScopeAs] = new GraphBuilder(id, this.scheduler.newScope())
                }
                needsUpdate = true
                didCreate = true
            }
            for (const key in keySet) {
                if (key === "$info" || key === "$id" || key === "$debug") continue
                const fieldDescriptor: DescriptorEntry = descriptor && descriptor[key]
                const inletValueTypeDesc = fieldDescriptor?.descType === "inlet" ? fieldDescriptor.valueTypeDesc : undefined
                const assignment = (instDef as any)[key]
                const prevAssignment = (inst as any)[key]
                if (isBuilderOutlet(assignment)) {
                    let outletInst = assignment.inst as Outlet<unknown>
                    if (!outletInst) {
                        outletInst = new Outlet<unknown>(assignment.valueTypeDesc)
                        assignment.inst = outletInst
                        //console.log("Created outlet", assignment.node.$info.id, ".", assignment.key, "prev", prevAssignment);
                    }
                    this.curOutlets.add(outletInst)
                    if (assignment.node === instDef) {
                        // this outlet belongs to this node
                        if (outletInst !== prevAssignment) {
                            outletInst.fromId = id
                            outletInst.fromKey = key
                            ;(inst as any)[key] = outletInst
                            needsUpdate = true
                            //console.log("assigned own outlet", id, ".", key, "prev", prevAssignment);
                        }
                    } else {
                        // this outlet belongs to another node, assume it connects to an inlet
                        inletKeys.push(key)
                        if (assignment.node) {
                            if (this.connectionData.has(assignment.node.$info.id)) {
                                upstreamNodeIds.push(assignment.node.$info.id)
                                //dependencies.push([inst.$id, assignment.node.$info.id]);
                            } else {
                                console.error("Could not find source node:", {instDef, key, assignment})
                            }
                        }
                        if (valueChanged(outletInst.value, prevAssignment, inletValueTypeDesc)) {
                            ;(inst as any)[key] = valueSnapshot(outletInst.value, inletValueTypeDesc)
                            needsUpdate = true
                            //console.log("assigned other outlet", assignment.node.$info.id, ".", assignment.key, "to", id, ".", key, "prev", prevAssignment);
                        }
                        outletInst.targets.push((value: any) => {
                            // console.log(outletInst.fromId, outletInst.fromKey, "  to ", id, key);
                            ;(inst as any)[key] = value
                            this.scheduler.scheduleToRun(inst!)
                        })
                    }
                } else {
                    // literal value
                    // (don't include these in inletKeys)
                    if (assignment !== undefined) {
                        constKeys.push(key)
                    }
                    if (valueChanged(assignment, prevAssignment, inletValueTypeDesc)) {
                        if (assignment === undefined) {
                            delete (inst as any)[key]
                        } else {
                            ;(inst as any)[key] = valueSnapshot(assignment, inletValueTypeDesc)
                        }
                        needsUpdate = true
                        //console.log("assigned constant value", assignment, "to", id, ".", key, "prev", prevAssignment);
                    }
                }
            }
            if (inst.$inletKeys && !deepEqual(inst.$inletKeys, inletKeys)) needsUpdate = true
            if (inst.$constKeys && !deepEqual(inst.$constKeys, constKeys)) needsUpdate = true
            inst.$inletKeys = inletKeys
            inst.$constKeys = constKeys
            dependencyMap.set(id, upstreamNodeIds)
            if ("$debug" in instDef) {
                inst.$debug = instDef.$debug
            }
            if (needsUpdate) {
                this.scheduler.scheduleToRun(inst)
            }
        }

        const sortVisited = new Set<INodeInstance>()
        const traverseSort = (node: INodeInstance) => {
            if (sortVisited.has(node)) return
            sortVisited.add(node)
            for (const upstreamId of dependencyMap.get(node.$id!)!) {
                traverseSort(this.instanceData.get(upstreamId)!)
            }
            scheduleGroup.push(node)
        }
        for (const [id, node] of this.instanceData) {
            traverseSort(node)
        }

        const inputMap = new Map<string, Outlet<unknown>>()
        const outputMap = new Map<string, Outlet<unknown>>()
        for (const [key, conn] of this.inputs) {
            if (conn.inst) {
                inputMap.set(key, conn.inst)
            }
        }
        for (const [key, conn] of this.outputs) {
            if (conn.inst) {
                outputMap.set(key, conn.inst)
            }
        }

        this.unvisitedNodes.clear()
        this.unvisitedInputs.clear()
        this.unvisitedOutputs.clear()
        this.aliases.clear()
        for (const [id, _] of this.connectionData) {
            this.unvisitedNodes.add(id)
        }
        for (const [key, _] of this.inputs) {
            this.unvisitedInputs.add(key)
        }
        for (const [key, _] of this.outputs) {
            this.unvisitedOutputs.add(key)
        }

        return (instanceToBind: any) => {
            for (const [key, innerOutlet] of outputMap) {
                const fn = (value: unknown) => {
                    const outerOutlet = instanceToBind[key] as Outlet<unknown>
                    if (outerOutlet) {
                        outerOutlet.emitIfChanged(value)
                    }
                }
                innerOutlet.targets.push(fn)
                if (innerOutlet.value !== NotReady) {
                    fn(innerOutlet.value)
                }
            }
            return () => {
                // push all input values
                for (const [key, outlet] of inputMap) {
                    outlet.emitIfChanged((instanceToBind as any)[key])
                }
            }
        }
    }
}
