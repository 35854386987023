import {Component, Input} from "@angular/core"
import {GridSize} from "@legacy/helpers/utils"

@Component({
    standalone: true,
    selector: "cm-grid-list",
    templateUrl: "./grid-list.component.html",
    styleUrls: ["./grid-list.component.scss"],
})
export class GridListComponent {
    @Input({required: true}) gridSize!: GridSize
    gridSizes = GridSize
}
