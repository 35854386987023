import {Component, Input, OnDestroy} from "@angular/core"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {filter, Subject, takeUntil} from "rxjs"
import {BrushMode, BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"

@Component({
    selector: "cm-brush-settings-panel",
    templateUrl: "./brush-settings-panel.component.html",
    styleUrls: ["./brush-settings-panel.component.scss"],
    standalone: true,
    imports: [ButtonComponent, SliderComponent],
})
export class BrushSettingsPanelComponent implements OnDestroy {
    @Input() brushSettings = new BrushSettings()
    @Input() allowBrushModeSelection = true
    @Input() disabled = false

    constructor(hotkeys: Hotkeys) {
        hotkeys
            .addShortcut(["+"])
            .pipe(
                takeUntil(this.unsubscribe),
                filter(() => this.allowBrushModeSelection),
            )
            .subscribe(() => (this.brushSettings.brushMode = BrushMode.Add))
        hotkeys
            .addShortcut(["-"])
            .pipe(
                takeUntil(this.unsubscribe),
                filter(() => this.allowBrushModeSelection),
            )
            .subscribe(() => (this.brushSettings.brushMode = BrushMode.Subtract))
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    BrushMode = BrushMode

    private unsubscribe = new Subject<void>()
}
