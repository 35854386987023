// @ts-strict-ignore
import {Component, Input, OnDestroy, OnInit} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {IEditor} from "@editor/models/editor"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"

@Component({
    selector: "cm-config-group-inspector",
    templateUrl: "./config-group-inspector.component.html",
    styleUrls: ["./config-group-inspector.component.scss"],
    standalone: true,
    imports: [InputContainerComponent, InspectorSectionComponent, StringInputComponent, ToggleComponent],
})
export class ConfigGroupInspectorComponent implements OnInit, OnDestroy {
    @Input() editor: IEditor
    @Input() node: Nodes.ConfigGroup

    isValidId = NodeUtils.isValidId

    constructor() {}

    ngOnInit(): void {}

    updateNode(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    ngOnDestroy() {}
}
