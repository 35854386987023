import {MaterialSocket} from "@material-editor/models/material-socket"
import {NodeSettings} from "@material-editor/models/material-nodes"

export const RgbMixInputs: Record<string, MaterialSocket> = {
    fac: {paramType: "socket", id: "Fac", type: "input", valueType: "scalar", label: "Fac", range: {min: 0, max: 1}},
    color1: {paramType: "socket", id: "Color1", type: "input", valueType: "color", label: "Color1"},
    color2: {paramType: "socket", id: "Color2", type: "input", valueType: "color", label: "Color2"},
}
export const RgbMixOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
}

export const blendMode: NodeSettings = {
    id: "internal.blend_type",
    valueType: "string",
    options: [
        {label: "Mix", value: "MIX"},
        {label: "Add", value: "ADD"},
        {label: "Subtract", value: "SUBTRACT"},
        {label: "Difference", value: "DIFFERENCE"},
        {label: "Multiply", value: "MULTIPLY"},
        {label: "Screen", value: "SCREEN"},
        {label: "Divide", value: "DIVIDE"},
        {label: "Lighten", value: "LIGHTEN"},
        {label: "Darken", value: "DARKEN"},
        {label: "Soft Light", value: "SOFT_LIGHT"},
        {label: "Linear Light", value: "LINEAR_LIGHT"},
        {label: "Color", value: "COLOR"},
        {label: "Hue", value: "HUE"},
        {label: "Saturation", value: "SATURATION"},
        {label: "Value", value: "VALUE"},
        {label: "Color Burn", value: "COLOR_BURN"},
        {label: "Color Dodge", value: "COLOR_DODGE"},
        {label: "Overlay", value: "OVERLAY"},
    ],
}
