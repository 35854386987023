<div class="cm-render-output-viewer">
    @if (processing || loading) {
        <cm-loading-spinner class="cm-processing-overlay">
            @if (loading) {
                <ng-container cm-text-bottom>Loading...</ng-container>
            }
            @if (processing && !loading) {
                <ng-container cm-text-bottom>Processing...</ng-container>
            }
        </cm-loading-spinner>
    }
    <cm-canvas-base #canvasBase (loadingComplete)="canvasLoadingComplete()" navigationMode="Panning"> </cm-canvas-base>
    @if (!loading && !processing) {
        <cm-render-output-viewer-controls [dataObject]="_dataObject" [imageData]="imageData" [pictureCanvas]="canvasBase">
            <ng-container cm-action-items-pos1>
                <ng-content select="[cm-action-items-pos1]"> </ng-content>
            </ng-container>
            <ng-container cm-action-items-pos2>
                <ng-content select="[cm-action-items-pos2]"> </ng-content>
            </ng-container>
        </cm-render-output-viewer-controls>
    }
</div>
