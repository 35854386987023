import {Directive, HostListener, Input, Output, EventEmitter, ElementRef} from "@angular/core"
import {DragAndDropService} from "app/templates/template-publisher/template-tree/drag-and-drop.service"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"

@Directive({
    selector: "[cmNodeDrop]",
    standalone: true,
})
export class NodeDropTargetDirective {
    @Output() cmNodeDrop = new EventEmitter()
    @Input() cmNodeDropFilter?: (node: Nodes.Node) => boolean

    constructor(private dragDropSvc: DragAndDropService) {}

    private accept(node: Nodes.Node): boolean {
        return this.cmNodeDropFilter ? this.cmNodeDropFilter(node) : true
    }

    @HostListener("dragover", ["$event"])
    onDragOver(event: DragEvent) {
        event.stopPropagation()
        if (this.accept(this.dragDropSvc.dragSource)) {
            event.preventDefault()
        }
    }

    @HostListener("drop", ["$event"])
    onDrop(event: DragEvent) {
        const node = this.dragDropSvc.dragSource
        event.stopPropagation()
        if (this.accept(node)) {
            this.cmNodeDrop.emit(node)
        }
    }
}

@Directive({
    selector: "[cmNodeDrag]",
    standalone: true,
})
export class NodeDragSourceDirective {
    @Output() cmNodeDrag = new EventEmitter()

    constructor(
        elem: ElementRef<HTMLElement>,
        private dragDropSvc: DragAndDropService,
    ) {
        elem.nativeElement.setAttribute("draggable", "true")
    }

    @HostListener("dragstart", ["$event"])
    onDragStart(event: DragEvent) {
        const nodeEvt: {context?: Nodes.Context; node?: Nodes.Node} = {}
        this.cmNodeDrag.emit(nodeEvt)
        if (nodeEvt.node) {
            this.dragDropSvc.dragStart(event, nodeEvt.context!, nodeEvt.node)
        } else {
            event.preventDefault()
        }
    }
}
