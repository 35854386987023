import {HalContext} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalPainterImageBlit, HalPainterImageBlitOptions} from "@common/models/hal/common/hal-painter-image-blit"
import {HalPainter} from "@app/common/models/hal/hal-painter"

export class HalPainterImageStretch implements HalPainter {
    constructor(readonly context: HalContext) {
        this.halPainterImageBlitColor = HalPainterImageBlit.create(this.context, BLITTING_FUNCTION_COLOR)
        this.halPainterImageBlitMonochrome = HalPainterImageBlit.create(this.context, BLITTING_FUNCTION_MONOCHROME)
    }

    dispose(): void {
        this.halPainterImageBlitColor.dispose()
        this.halPainterImageBlitMonochrome.dispose()
    }

    async paint(target: HalPaintable, sourceImage: HalImage, options?: HalPainterImageBlitOptions): Promise<void> {
        const isMonochrome = sourceImage.descriptor.channelLayout === "R"
        const painter = isMonochrome ? this.halPainterImageBlitMonochrome : this.halPainterImageBlitColor
        await painter.paint(target, sourceImage, options)
    }

    public static create(context: HalContext): HalPainterImageStretch {
        return new HalPainterImageStretch(context)
    }

    private halPainterImageBlitColor: HalPainterImageBlit
    private halPainterImageBlitMonochrome: HalPainterImageBlit
}

const BLITTING_FUNCTION_COLOR = `    
    vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
        return texture0(sourcePixel);
    }
`

const BLITTING_FUNCTION_MONOCHROME = `
    vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
        float sourceValue = texture0(sourcePixel).r;
        return vec4(sourceValue, sourceValue, sourceValue, 1);
    }
`
