<div id="execution-plan-tooltip" class="cm-execution-plan-tooltip">
    @if (title) {
        <strong>{{ title }}</strong>
    }
    @if (isWaiting) {
        <span class="state--success"><i class="fa fa-check-circle"></i>&nbsp;Waiting</span>
    }
    @if (isWaiting === false) {
        <span class="state--success"><i class="fa fa-check-circle"></i>&nbsp;Done</span>
    }
    @if (taskType) {
        <span class="state--light"><i class="fa fa-screwdriver-wrench"></i>&nbsp;{{ taskType }}</span>
    }
    @if (taskState) {
        <span [class]="jobTaskStateColor(taskState)"
            ><i class="fa">{{ jobTaskIcon(taskState) }}</i
            >&nbsp;{{ displayState(taskState) }}</span
        >
    }
    @if (inputLength || outputLength) {
        <span class="state--light">
            @if (inputLength) {
                <i class="fa fa-arrow-right-to-bracket"></i>&nbsp;{{ inputLength }}
            }
            @if (inputLength && outputLength) {
                &nbsp;
            }
            @if (outputLength) {
                <i class="fa fa-arrow-right-from-bracket"></i>&nbsp;{{ outputLength }}
            }
        </span>
    }
    @if (infoText) {
        <span class="state--light"><i class="fa fa-info-circle"></i>&nbsp;{{ infoText }}</span>
    }
    @if (errorText) {
        <span class="state--error"><i class="fa fa-exclamation-circle"></i>&nbsp;{{ errorText }}</span>
    }
    @if (progress !== undefined && progress < 100) {
        <div class="cm-simple-progress-container">
            <div class="cm-simple-progress">
                <div class="cm-simple-progress-bar" [style.width]="progress + '%'"></div>
            </div>
            <span class="state--light">{{ progress }}%</span>
        </div>
    }
</div>
