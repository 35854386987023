<cm-node-base #nodeBase [inputs]="inputs" [outputs]="outputs" [settings]="settings" [typeInfo]="typeInfo"> </cm-node-base>

<!--<cm-node [selected]="selected" [typeInfo]="typeInfo">-->
<!--    <ng-container cm-outputs>-->
<!--        <cm-node-io #nodeIo type="output" [socket]="principledBsdfOutputs.bsdf" (connectionChange)="connectionChange.emit($event)">-->
<!--            <ng-container cm-name>{{ principledBsdfOutputs.bsdf.label }}</ng-container>-->
<!--        </cm-node-io>-->
<!--    </ng-container>-->

<!--    <ng-container cm-settings>-->
<!--        <mat-form-field appearance="standard" floatLabel="never">-->
<!--            <mat-select value="ggx">-->
<!--                <mat-option value="ggx"> GGX </mat-option>-->
<!--                <mat-option value="mggx"> Multiscatter GGX </mat-option>-->
<!--            </mat-select>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field appearance="standard" floatLabel="never">-->
<!--            <mat-select value="chb">-->
<!--                <mat-option value="chb"> Christensen-Burley </mat-option>-->
<!--                <mat-option value="rw"> Random Walk </mat-option>-->
<!--            </mat-select>-->
<!--        </mat-form-field>-->
<!--    </ng-container>-->

<!--    <ng-container cm-inputs>-->
<!--        <cm-node-io #nodeIo type="color" [socket]="principledBsdfInputs.baseColor" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--        &lt;!&ndash;        <cm-node-io #nodeIo type="scalar" [socket]="principledBsdfInputs.subsurface" (connectionChange)="connectionChange.emit($event)"></cm-node-io>&ndash;&gt;-->
<!--        &lt;!&ndash;        <cm-node-io #nodeIo type="scalar" [socket]="principledBsdfInputs.subsurfaceRadius" (connectionChange)="connectionChange.emit($event)"></cm-node-io>&ndash;&gt;-->
<!--        &lt;!&ndash;        <cm-node-io #nodeIo type="color" [socket]="principledBsdfInputs.subsurfaceColor" (connectionChange)="connectionChange.emit($event)"></cm-node-io>&ndash;&gt;-->
<!--        <cm-node-io #nodeIo type="scalar" [socket]="principledBsdfInputs.metallic" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--        <cm-node-io #nodeIo type="scalar" [socket]="principledBsdfInputs.specular" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--        <cm-node-io #nodeIo type="scalar" [socket]="principledBsdfInputs.roughness" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--        <cm-node-io #nodeIo type="scalar" [socket]="principledBsdfInputs.anisotropic" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--        <cm-node-io #nodeIo type="scalar" [socket]="principledBsdfInputs.anisotropicRotation" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--        &lt;!&ndash;        <cm-node-io #nodeIo type="scalar" [min]="0" [max]="1000" [socket]="principledBsdfInputs.ior" (connectionChange)="connectionChange.emit($event)"></cm-node-io>&ndash;&gt;-->
<!--        <cm-node-io #nodeIo type="plain" [socket]="principledBsdfInputs.normal" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--        <cm-node-io #nodeIo type="plain" [socket]="principledBsdfInputs.tangent" (connectionChange)="connectionChange.emit($event)"></cm-node-io>-->
<!--    </ng-container>-->
<!--</cm-node>-->
