import {z} from "zod"

export const extractErrorMessage = (error: unknown, overrideMessageForCode: Record<number, string> = {}): string => {
    const errorMessage = error instanceof Error ? error.message : `${error}`

    // depending on the context, GraphQL errors may consist of an object or a message containing a JSON string
    return (
        parseGraphQLErrorMessage(error, overrideMessageForCode) ??
        parseGraphQLErrorMessage(extractErrorFromErrorResponse(error), overrideMessageForCode) ??
        parseGraphQLErrorMessage(extractErrorFromStringifiedJson(errorMessage), overrideMessageForCode) ??
        `${error}`
    )
}

const parseGraphQLErrorMessage = (error: unknown, overrideMessageForCode: Record<number, string> = {}): string | undefined => {
    const messageForCode: Record<number, string> = {
        401: "You need to be logged in to perform this action",
        403: "You are not authorized to perform this action",
        409: "A data-consistency rule is preventing this operation",
        500: "This action failed due to a server issue",
        ...overrideMessageForCode,
    }

    try {
        const GraphQLErrorSchema = z.object({
            message: z.string(),
            extensions: z.object({code: z.number()}),
        })
        const parsedGraphQLError = GraphQLErrorSchema.parse(error)
        const code = parsedGraphQLError.extensions.code
        return messageForCode[code] ?? parsedGraphQLError.message
    } catch {
        return undefined
    }
}

const extractErrorFromErrorResponse = (errorResponse: unknown): unknown | undefined => {
    try {
        return z
            .object({
                response: z.object({
                    errors: z.array(z.unknown()),
                }),
            })
            .parse(errorResponse)?.response?.errors?.[0]
    } catch (error) {
        return undefined
    }
}

export const extractErrorFromStringifiedJson = (errorMessage?: string): unknown | undefined => {
    try {
        if (errorMessage) {
            const jsonObject = JSON.parse(errorMessage.replace("Unexpected error.: ", ""))
            return extractErrorFromErrorResponse(jsonObject)
        } else {
            return undefined
        }
    } catch (error) {
        return undefined
    }
}
