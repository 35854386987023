@if (socket?.type) {
    <div class="cm-name" [class.cm-output-name]="socket.type === 'output'" [class.cm-input-name]="socket.type === 'input'">
        @switch (valueType) {
            @case ("plain") {
                <cm-plain-input-socket [label]="socket.label" [connected]="connected" [value]="valueAsString"></cm-plain-input-socket>
            }
            @case ("scalar") {
                <cm-scalar-input-socket
                    [range]="range"
                    [type]="socket.type"
                    [label]="socket.label"
                    [connected]="connected"
                    [value]="valueAsNumber"
                    (valueChange)="valueChange.emit({value: $event, type: valueType})"
                    [disableEditingIfConnected]="disableEditingIfConnected"
                >
                </cm-scalar-input-socket>
            }
            @case ("color") {
                <cm-color-input-socket
                    [type]="socket.type"
                    [label]="socket.label"
                    [connected]="connected"
                    [value]="valueAsNumberArray"
                    (valueChange)="valueChange.emit({value: $event, type: valueType})"
                    [disableEditingIfConnected]="disableEditingIfConnected"
                >
                </cm-color-input-socket>
            }
            @case ("vector") {
                <cm-vector-input-socket
                    [label]="socket.label"
                    [connected]="connected"
                    [value]="valueAsNumberArray"
                    (valueChange)="valueChange.emit({value: $event, type: valueType})"
                >
                </cm-vector-input-socket>
            }
            @case ("output") {
                <!-- TODO separate component also for the 'output' type ? -->
                <div>{{ socket.label }}</div>
            }
            @case ("input") {
                <!-- TODO separate component also for the 'input' type ? -->
                <div>{{ socket.label }}</div>
            }
        }
    </div>
    @if (socket.type === "input") {
        <ng-container *ngTemplateOutlet="socketTemplate"></ng-container>
    }
    @if (socket.type === "output") {
        <ng-container *ngTemplateOutlet="socketTemplate"></ng-container>
    }
    <ng-template #socketTemplate>
        <cm-node-socket
            #socketComponent
            (connectionStart)="connectionChange.emit({socket: socket, type: 'start'})"
            (connectionEnd)="connectionChange.emit({socket: socket, type: 'end'})"
            [type]="socket.type"
        >
        </cm-node-socket>
    </ng-template>
}
