import {Injectable} from "@angular/core"
import {BehaviorSubject} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class TextureInfoService {
    private diffuseTextureSizeInfoSubject = new BehaviorSubject<{width: number; height: number}>({width: 0, height: 0})

    setTextureSizeInfo(info: {textureTypeLabel: string; width: number; height: number}) {
        // For now, we only keep size of diffuse texture. Sizes of other texture types may be added in the future if needed.
        if (info.textureTypeLabel === "Diffuse") {
            this.diffuseTextureSizeInfoSubject.next({width: info.width, height: info.height})
        }
    }

    getDiffuseTextureSizeInfo() {
        return this.diffuseTextureSizeInfoSubject
    }
}
