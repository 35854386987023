@if (node) {
    <cm-inspector-section>
        <ng-container cm-title> Group </ng-container>
        <ng-container cm-content>
            <cm-node-reference-slot fallbackText="" [node]="$any(node.active)" (nodeChange)="node.active = $event ?? true; updateNode(node)">
                <ng-container cm-fallback>
                    <cm-toggle [(toggle)]="$any(node).active" (toggleChange)="updateNode(node)">
                        <ng-container cm-text>Active</ng-container>
                    </cm-toggle>
                </ng-container>
            </cm-node-reference-slot>
        </ng-container>
    </cm-inspector-section>
}
