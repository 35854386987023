// @ts-strict-ignore
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay"
import {NgClass} from "@angular/common"
import {Component, EventEmitter, Input, OnInit, Output, inject} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {StlExportService} from "@app/common/services/stl-export/stl-export.service"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ArDialogComponent} from "@common/components/dialogs/ar-dialog/ar-dialog.component"
import {SalesEnquiryDialogComponent, SalesEnquiryResult} from "@common/components/dialogs/sales-enquiry-dialog/sales-enquiry-dialog.component"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {SalesEnquiryService} from "@common/services/sales-enquiry/sales-enquiry.service"
import {TippyDirective} from "@ngneat/helipopper"
import {FilesService} from "@common/services/files/files.service"
import {SceneManager} from "@app/templates/template-system/scene-manager"

@Component({
    standalone: true,
    selector: "cm-action-menu",
    templateUrl: "./action-menu.component.html",
    styleUrls: ["./action-menu.component.scss"],
    providers: [SceneManagerService],
    imports: [CdkOverlayOrigin, MatTooltipModule, CdkConnectedOverlay, SalesEnquiryDialogComponent, ArDialogComponent, NgClass, TippyDirective],
})
export class ActionMenuComponent implements OnInit {
    @Input() snapshotEnabled = true
    @Input() salesEnquiryEnabled = false
    @Input() fullscreenEnabled = true
    @Input() arEnabled = false
    @Input() pdfGenerationEnabled = false
    @Input() stlDownloadEnabled = false
    @Input() gltfDownloadEnabled = false
    @Input() showCustomMenu = false
    @Input() customerId: number
    @Input() qrCodeDialogOpen = false
    @Input() gltfGcsObjectName: string
    @Input() getSceneManager: () => SceneManager
    @Output() downloadPdf: EventEmitter<void> = new EventEmitter<void>()
    @Output() downloadGltf: EventEmitter<void> = new EventEmitter<void>()
    @Output() saveSnapshot: EventEmitter<void> = new EventEmitter<void>()
    @Output() toggleFullscreen: EventEmitter<void> = new EventEmitter<void>()
    @Output() viewInAr: EventEmitter<void> = new EventEmitter<void>()
    @Output() closeArDialog: EventEmitter<void> = new EventEmitter<void>()
    @Output() creatingStl: EventEmitter<boolean> = new EventEmitter<boolean>()

    isFullscreenActive = false
    salesDialogOpen = false
    tpOffset: [number, number] = [0, 20]

    private localSceneManagerService = inject(SceneManagerService)
    private stlExportService = inject(StlExportService)

    constructor(
        private salesEnquiryService: SalesEnquiryService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {}

    viewInArClicked(): void {
        this.viewInAr.emit()
    }

    closeArDialogClicked(): void {
        this.closeArDialog.emit()
    }

    async exportStlClicked() {
        this.creatingStl.emit(true)
        const zippedStls = await this.stlExportService.exportStlFilesOld(this.getSceneManager(), this.localSceneManagerService)
        FilesService.downloadFile("stl-export.zip", zippedStls)
        this.creatingStl.emit(false)
    }

    submitSalesEnquiry(result: SalesEnquiryResult): void {
        this.salesEnquiryService
            .createSalesEnquiry({
                organizationLegacyId: this.customerId,
                contactName: result.name,
                email: result.email,
                phoneNumber: result.phoneNumber,
                companyName: result.companyName,
            })
            .subscribe(
                () => {
                    this.notificationService.showInfo("Sales enquiry sent.")
                    this.salesDialogOpen = false
                },
                (error: unknown) => this.notificationService.showError(`Sending sales enquiry failed: ${error}`),
            )
    }
}
