import {Box2} from "@cm/lib/math/box2"
import {Operator} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {CanvasBaseToolboxRootItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-root-item"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"

export class OperatorToolboxBase<OperatorClass extends Operator> extends CanvasBaseToolboxRootItem {
    constructor(
        readonly operator: OperatorClass,
        selectionMode: SelectionMode,
        private catchAllInputOnImage: boolean,
    ) {
        super(operator.callback.tilingCanvas.canvasBase)
        this.selectionMode = selectionMode
        this.getTileBounds = () => this.operator.callback.tilingCanvas.tileBounds
    }

    requestRedraw(): void {
        this.canvasBase?.requestRedraw()
    }

    // protected override customInputFilter(event: paper.KeyEvent | WheelEvent | paper.ToolEvent, _item: CanvasBaseToolboxItem): boolean {
    //     if (!this.catchAllInputOnImage) {
    //         return true
    //     }
    //     if (this.operator.callback.tilingCanvas.gridActive) {
    //         return true
    //     }
    //     if ("point" in event) {
    //         if (event.type === "mousedown") {
    //             this.isDragging = this.isPointInImage(event.point)
    //         } else if (event.type === "mouseup") {
    //             this.isDragging = false
    //         } else if (event.type === "mousedrag") {
    //             if (this.isDragging) {
    //                 return true
    //             }
    //         }
    //         return this.isPointInImage(event.point)
    //     } else {
    //         return true
    //     }
    // }

    override isPointInImage(point: paper.Point): boolean {
        return this.operator.callback.tilingCanvas.gridActive || super.isPointInImage(point)
    }

    private isDragging = false
    protected getTileBounds: () => Box2
}
