import {registerNode} from "@src/graph-system/register-node"
import {ImageLike, MaterialLike, Mesh, imageLike, mesh} from "@src/templates/node-types"
import {z} from "zod"
import {VisitorNodeVersion, visitNone} from "@src/graph-system/declare-visitor-node"
import {SceneNodes, MeshRenderSettings} from "@src/templates/interfaces/scene-object"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {IMaterialData} from "@src/templates/interfaces/material-data"
import {DeclareObjectNode, ObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {namedNodeParameters} from "@src/templates/nodes/named-node"
import {IDataObjectNew, dataObjectNewToImageRessource, isIDataObjectNew} from "@src/templates/interfaces/data-object"
import {BuilderInlet} from "@src/templates/runtime-graph/graph-builder"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {Nodes} from "@src/templates/legacy/template-nodes"
import {TransformColorOverlayNew} from "@src/templates/runtime-graph/nodes/transform-color-overlay-new"
import {transformDecalMask} from "@src/materials/material-node-graph-transformations"
import {NodeId} from "@src/templates/runtime-graph/types"
import {MeshDecalNew} from "@src/templates/runtime-graph/nodes/mesh-decal-new"
import {MaterialAssignment, setupMaterialAssignmentWithOverride} from "./material-assignment"
import {nodeInstance} from "@src/graph-system/instance"
import {versionChain} from "@src/graph-system/node-graph"

const decalMaskType = z.enum(["binary", "opacity"])
export type DecalMaskType = z.infer<typeof decalMaskType>

const meshDecalParameters = namedNodeParameters.merge(
    z.object({
        mesh: mesh.nullable(),
        mask: imageLike.optional(),
        invertMask: z.boolean(),
        maskType: decalMaskType,
        color: imageLike.optional(),
        offset: z.tuple([z.number(), z.number()]),
        rotation: z.number(),
        size: z.tuple([z.number().optional(), z.number().optional()]),
        distance: z.number(),
        materialAssignment: nodeInstance(MaterialAssignment).nullable(),
    }),
)
export type MeshDecalParameters = z.infer<typeof meshDecalParameters>

type V0 = ObjectNode & {
    name: string
    mesh: Mesh | null
    mask?: ImageLike
    invertMask: boolean
    maskType: "binary" | "opacity"
    color?: ImageLike
    offset: [number, number]
    rotation: number
    size: [number | undefined, number | undefined]
    distance: number
    material: MaterialLike | null
}

type V1 = Omit<V0, "material"> & {materialAssignment: MaterialAssignment | null}
const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        const {material, ...rest} = parameters
        return {...rest, materialAssignment: material ? new MaterialAssignment({node: material, side: "front"}) : null}
    },
}

@registerNode
export class MeshDecal extends DeclareObjectNode(
    {parameters: meshDecalParameters},
    {
        onVisited: {
            onCompile: function (this: MeshDecal, {context, parameters}) {
                const {mesh, materialAssignment, invertMask, mask, maskType, color, size: inputSize, offset, rotation, distance} = parameters

                if (!mesh) return visitNone(parameters)

                const {evaluator, currentTemplate} = context
                const {displayList} = currentTemplate
                const {templateContext} = evaluator
                const {sceneManager} = templateContext

                const scope = evaluator.getScope(this)

                const [meshObjectData, meshObjectDataInvalid] = scope.branch(evaluator.evaluateObject(scope, mesh))
                const [meshObjectDataDefined, meshObjectDataUndefined] = scope.branch(scope.get(meshObjectData, "meshData"))

                const getImageDataObject = (image: BuilderInlet<TemplateImageDataNew | null>, uniqueId: NodeId) =>
                    scope.lambda(
                        image,
                        (image) => {
                            if (!image) return null
                            const {dataObject} = image
                            if (isIDataObjectNew(dataObject)) return dataObject
                            console.error(`Transient data object not supported for ${uniqueId} in mesh decal`)
                            return null
                        },
                        uniqueId,
                    )

                const maskDataObject = getImageDataObject(evaluator.evaluateImage(scope, mask ?? null), "maskDataObject")
                const colorDataObject = getImageDataObject(evaluator.evaluateImage(scope, color ?? null), "colorDataObject")

                const size = computeConstrainedSizeFromDataObject(
                    scope,
                    inputSize,
                    scope.lambda(
                        scope.tuple(maskDataObject, colorDataObject),
                        ([maskDataObject, colorDataObject]) => maskDataObject ?? colorDataObject,
                        "dataObject",
                    ), // prefer getting size defaults from mask, rather than overlay image,
                )

                const {meshData} = scope.node(MeshDecalNew, {
                    sceneManager,
                    inputMeshData: meshObjectDataDefined,
                    offset,
                    rotation: rotation * (Math.PI / 180),
                    distance,
                    size,
                })

                const materialData = setupMaterialAssignmentWithOverride(scope.scope("materialAssignment"), context, this, materialAssignment)
                const [materialAssignmentData, materialAssignmentDataInvalid] = scope.branch(materialData)

                const materialGraph = scope.phi(scope.get(materialAssignmentData, "materialGraph"), materialAssignmentDataInvalid)

                const [validMaterialGraph] = scope.branch(materialGraph)
                const [validColorDataObject] = scope.branch(colorDataObject)
                const [transformedMaterialGraph, transformedMaterialGraphInvalid] = scope.branch(
                    scope.phi(
                        scope.node(TransformColorOverlayNew, {
                            material: validMaterialGraph,
                            image: scope.struct<TemplateImageDataNew>("TemplateImageDataNew", {dataObject: validColorDataObject}),
                            size,
                            useAlpha: false,
                        }).outputMaterial,
                        materialGraph,
                    ),
                )

                const alphaMaskThreshold = getAlphaMaskThresholdFromDecalMaskType(maskType)

                const decalMaterialData = scope.phi(
                    scope.lambda(
                        scope.tuple(transformedMaterialGraph, maskDataObject, colorDataObject, size, materialData),
                        ([transformedMaterialGraph, maskDataObject, colorDataObject, size, materialData]) => {
                            const materialDataWithMask: IMaterialData = {
                                name: transformedMaterialGraph.name,
                                materialGraph: transformDecalMask(transformedMaterialGraph, {
                                    maskImage: maskDataObject ? dataObjectNewToImageRessource(maskDataObject) : undefined,
                                    colorOverlayImage: colorDataObject ? dataObjectNewToImageRessource(colorDataObject) : undefined,
                                    widthCm: size[0],
                                    heightCm: size[1],
                                    invert: invertMask,
                                }),
                                side: "front",
                                alphaMaskThreshold,
                                realtimeSettings: materialData?.realtimeSettings,
                            }
                            return materialDataWithMask
                        },
                        "materialMapValid",
                    ),
                    transformedMaterialGraphInvalid,
                )

                const materialMap = scope.lambda(decalMaterialData, (materialData) => new Map([[0, materialData]]), "materialMap")

                const {visibleDirectly, visibleInReflections, visibleInRefractions} = mesh.parameters

                const transform = scope.get(meshObjectData, "matrix")

                const objectProps = this.setupObject(scope, context, "Mesh", meshData, transform)

                const sceneMesh = scope.lambda(
                    scope.phi(
                        scope.struct<SceneNodes.Mesh>("Mesh", {
                            type: "Mesh",
                            ...objectProps,
                            meshRenderSettings: scope.struct<MeshRenderSettings>("MeshRenderSettings", {}),
                            meshData,
                            materialMap,
                            visibleDirectly,
                            visibleInReflections,
                            visibleInRefractions,
                            castRealtimeShadows: mesh.parameters.castRealtimeShadows,
                            receiveRealtimeShadows: mesh.parameters.receiveRealtimeShadows,
                            isDecal: true,
                            isProcedural: true,
                        }),
                        meshObjectDataInvalid,
                        meshObjectDataUndefined,
                    ),
                    (sceneMesh) => sceneMesh ?? null,
                    "sceneMesh",
                )

                displayList.push(sceneMesh)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "MeshDecal", versionChain: versionChain([v0])},
) {}

export type MeshDecalFwd = TemplateObjectNode<MeshDecalParameters>

function getAlphaMaskThresholdFromDecalMaskType(decalMaskType: Nodes.DecalMaskType) {
    switch (decalMaskType) {
        case "binary":
            return 0.5
        case "opacity":
            return 0.0
        default:
            throw Error("Unrecognized decal mask type.")
    }
}

// compute size based on (partially) specified values and DataObject width/height
function computeConstrainedSizeFromDataObject(
    scope: GraphBuilderScope,
    inputSize: [number | undefined | null, number | undefined | null],
    dataObject: BuilderInlet<IDataObjectNew | null>,
) {
    //NOTE: these coerced equality checks (!=,  ==) are intentional, to catch both null and undefined values
    if (inputSize[0] != undefined && inputSize[1] != undefined) {
        // need to use scope.tuple here, because change detection for scope.value will not work when inputSize array is reused (it assumes reference equality check is sufficient)
        return scope.tuple(inputSize[0], inputSize[1]) // size is fully specified
    } else if (inputSize[0] == undefined && inputSize[1] == undefined) {
        return scope.tuple(10, 10) // size is unspecified
    } else {
        // size is partially specified, use aspect ratio of mask
        return scope.lambda(
            scope.tuple(inputSize, dataObject),
            ([inputSize, dataObject]): [number, number] => {
                if (!dataObject) {
                    console.error("DataObject width/height not available")
                    return [10, 10]
                }

                if (typeof dataObject.width !== "number" || typeof dataObject.height !== "number") {
                    console.error(`DataObject ${dataObject.legacyId} width/height not available`)
                    return [10, 10]
                } else if (inputSize[0] == undefined) {
                    if (inputSize[1] == undefined) {
                        console.error(`DataObject ${dataObject.legacyId} input size 0/1 not available`)
                        return [10, 10]
                    }
                    return [(inputSize[1] * dataObject.width) / dataObject.height, inputSize[1]]
                } else {
                    return [inputSize[0], (inputSize[0] * dataObject.height) / dataObject.width]
                }
            },
            "constrainedSize",
        )
    }
}
