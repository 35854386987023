import {registerNode} from "@src/graph-system/register-node"
import {namedNodeParameters} from "@src/templates/nodes/named-node"
import {DeclareObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {visitNone} from "@src/graph-system/declare-visitor-node"

const lightPortalParameters = namedNodeParameters.merge(
    z.object({
        width: z.number(),
        height: z.number(),
    }),
)
export type LightPortalParameters = z.infer<typeof lightPortalParameters>

@registerNode
export class LightPortal extends DeclareObjectNode(
    {parameters: lightPortalParameters},
    {
        onVisited: {
            onCompile: function (this: LightPortal, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {displayList} = currentTemplate
                const {width, height} = parameters

                const scope = evaluator.getScope(this)

                const objectProps = this.setupObject(scope, context, "AreaLight", undefined, undefined)
                const light = scope.struct<SceneNodes.LightPortal>("LightPortal", {
                    type: "LightPortal",
                    ...objectProps,
                    width,
                    height,
                })
                displayList.push(light)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "LightPortal"},
) {}

export type LightPortalFwd = TemplateObjectNode<LightPortalParameters>
