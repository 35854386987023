// @ts-strict-ignore
import {Injectable} from "@angular/core"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Observable, Subject} from "rxjs"
import {insertBefore, insertAfter} from "@cm/lib/utils/utils"
import TemplateGraph = Nodes.TemplateGraph

export type MoveMode = "before" | "inside" | "after"

@Injectable({
    providedIn: "root",
})
export class TemplateService {
    private contextsModifiedSource: Subject<Nodes.Context[]> = new Subject<Nodes.Context[]>()
    contextsModified: Observable<Nodes.Context[]> = this.contextsModifiedSource.asObservable()

    templateGraph: TemplateGraph

    constructor() {}

    moveNode(node: Nodes.Node, targetContext: Nodes.Context, targetNode: Nodes.Node, moveMode: MoveMode) {
        if (node === targetNode) return

        let modifiedContexts: Nodes.Context[]
        if (moveMode === "before" || moveMode === "after") {
            if (!this.moveAllowed(node, targetContext)) return
            modifiedContexts = NodeUtils.removeNodesFromContexts(this.templateGraph, [node])
            if (moveMode === "before") {
                insertBefore(targetContext.nodes, targetNode, node)
            } else {
                insertAfter(targetContext.nodes, targetNode, node)
            }
            modifiedContexts.push(targetContext)
        } else if (moveMode === "inside") {
            if (!this.moveAllowed(node, targetContext)) return
            modifiedContexts = NodeUtils.removeNodesFromContexts(this.templateGraph, [node])
            targetContext.nodes.push(node)
            modifiedContexts.push(targetContext)
        } else {
            throw new Error(`Unhandled drop mode: ${moveMode}.`)
        }
        this.contextsModifiedSource.next(modifiedContexts)
    }

    moveAllowed(node: Nodes.Node, newParent: Nodes.Context): boolean {
        // TODO: How is the Context type meant to be used? I always end up casting between Node and Context.
        const newParentNode: Nodes.Node = newParent as Nodes.Node

        // Do not allow moving a node into itself or into any of its descendants.
        if (node === newParent) return false
        if (this.isDescendant(newParentNode, node as Nodes.Context)) return false

        return NodeUtils.isParentAllowed(node, newParentNode)
    }

    isDescendant(node: Nodes.Node, potentialAncestor: Nodes.Context): boolean {
        let currentContext: Nodes.Context = NodeUtils.findContextForNode(this.templateGraph, node)
        while (currentContext) {
            if (currentContext === potentialAncestor) return true
            currentContext = NodeUtils.findContextForNode(this.templateGraph, currentContext as Nodes.Node)
        }
        return false
    }
}
