import {ChangeDetectorRef, Component, DestroyRef, OnInit, ViewContainerRef, computed, inject, input, signal, viewChild} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatTooltipModule} from "@angular/material/tooltip"
import {getNodeIconClass, getNodeIconSeconaryClass} from "@app/template-editor/helpers/template-icons"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {isOutput, isTemplateContainer, isValue, valueNodeClasses} from "@cm/lib/templates/node-types"
import {LodType} from "@cm/lib/templates/nodes/lod-type"
import {isNamedNode} from "@cm/lib/templates/nodes/named-node"
import {RigidRelation} from "@cm/lib/templates/nodes/rigid-relation"
import {TemplateNode, isTemplateNode} from "@cm/lib/templates/types"
import {getTemplateNodeClassLabel, getTemplateNodeLabel} from "@cm/lib/templates/utils"

@Component({
    selector: "cm-template-node",
    standalone: true,
    imports: [MatTooltipModule],
    templateUrl: "./template-node.component.html",
    styleUrls: ["./template-node.component.scss", "./../../helpers/template-icons.scss"],
})
export class TemplateNodeComponent implements OnInit {
    node = input.required<TemplateNode>()
    noDrag = input(false)
    name = input<string | undefined>(undefined)
    alwaysEnabled = input(false)
    iconClass = computed(() => getNodeIconClass(this.node().getNodeClass()))
    secondaryIconClass = computed(() => getNodeIconSeconaryClass(this.node().getNodeClass()))
    classLabel = computed(() => getTemplateNodeClassLabel(this.node()))
    private triggerRecomputeLabel = signal(0)
    label = computed(() => {
        this.triggerRecomputeLabel()
        return this.name() ?? getTemplateNodeLabel(this.node())
    })
    disabled = computed(() => {
        if (this.alwaysEnabled()) return false
        return !this.sceneManagerService.isNodeActive(this.node())
    })
    private sceneManagerService = inject(SceneManagerService)
    private destroyRef = inject(DestroyRef)
    private cdr = inject(ChangeDetectorRef)
    drag = inject(TemplateNodeDragService)
    nodeReference = computed(() => {
        const node = this.node()
        if (isOutput(node)) return node.parameters.template
        else return node
    })
    notClickable = input(false)

    isNodeValue = computed(() => valueNodeClasses.includes(this.node().getNodeClass()))

    private dragPlaceholder = viewChild.required("dragPlaceholder", {read: ViewContainerRef})

    ngOnInit() {
        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            for (const [node, difference] of differences.modifiedNodes) {
                if (isTemplateNode(node) && node === this.node()) {
                    if (
                        (isNamedNode(node) && difference.find((x) => x.path === "name") !== undefined) ||
                        (node instanceof RigidRelation && difference.find((x) => x.path === "targetA" || x.path === "targetB")) ||
                        (isValue(node) && difference.find((x) => x.path === "value")) ||
                        (node instanceof LodType && difference.find((x) => x.path === "lodType"))
                    ) {
                        this.triggerRecomputeLabel.update((x) => x + 1)
                        return
                    }
                }
            }
        })
    }

    onMouseEnter() {
        this.sceneManagerService.highlightNode(this.nodeReference(), true)
    }

    onMouseLeave() {
        this.sceneManagerService.highlightNode(this.nodeReference(), false)
    }

    onClick(event: MouseEvent) {
        if (this.notClickable()) return

        event.stopPropagation()
        const node = this.nodeReference()

        this.onMouseLeave()
        this.sceneManagerService.selectNode({templateNode: node, part: "root"})
    }

    getDragImage() {
        const dragPlaceholder = this.dragPlaceholder()

        const dragImage = document.createElement("div")

        dragImage.style.display = "flex"
        dragImage.style.justifyContent = "center"
        dragImage.style.alignItems = "center"
        dragImage.style.position = "absolute"
        dragImage.style.zIndex = "1000"
        dragImage.style.left = "-1000px"
        dragImage.style.backgroundColor = "rgb(255, 255, 255)"
        dragImage.style.border = "1px solid black"
        dragImage.style.padding = "5px"
        dragImage.style.fontSize = "0.75rem"
        dragImage.style.fontWeight = "500"
        dragImage.style.color = "black"

        const templateNodeComponent = dragPlaceholder.createComponent(TemplateNodeComponent)
        templateNodeComponent.setInput("node", this.node())
        this.cdr.detectChanges()

        dragImage.appendChild(templateNodeComponent.location.nativeElement)

        const nativeElement = dragPlaceholder.element.nativeElement as HTMLElement

        nativeElement.appendChild(dragImage)

        setTimeout(() => {
            nativeElement.removeChild(dragImage)
        }, 0)

        return dragImage
    }

    dragStart(event: DragEvent) {
        const dragImage = this.getDragImage()

        event.dataTransfer?.setDragImage(dragImage, 0, 0)

        this.drag.dragStart(event, this.node())
    }
}
