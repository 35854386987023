<div class="cm-page-not-found-container">
    <mat-card appearance="outlined">
        <mat-card-content>
            <div class="cm-icon-container">
                <mat-icon>error</mat-icon>
            </div>
        </mat-card-content>
        <mat-card-title>Page not found.</mat-card-title>
    </mat-card>
</div>
