import {Component, EventEmitter, Input, Output} from "@angular/core"
import {RouterModule} from "@angular/router"
import {ThumbnailComponent} from "@app/common/components/thumbnail/thumbnail.component"

@Component({
    selector: "cm-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"],
    standalone: true,
    imports: [RouterModule, ThumbnailComponent],
})
export class CardComponent {
    @Input() squareFormat = false
    @Input() defaultThumbnail = true
    @Input() pictureZoneActive = false
    @Input() link: Array<unknown> | undefined
    @Input() queryParamsHandling: "merge" | "preserve" | "" = ""
    @Input() thumbnailUrl: string | null = null
    @Input() disabled = false
    @Output() selectItem: EventEmitter<void> = new EventEmitter<void>()

    onSelectItem() {
        if (this.disabled) return
        this.selectItem.emit()
    }
}
