import {inject, Injectable} from "@angular/core"
import {ContentTypeModel, PaymentState} from "@api"
import {SdkService} from "@common/services/sdk/sdk.service"
import {ApiRequest} from "@common/models/api-request/api-request"
import {BatchApiCallById} from "@common/helpers/batch-api-call/batch-api-call-by-id"
import {IsUnique} from "@cm/lib/utils/filter"

export type RequestPayload = {
    id: string
    __typename: ContentTypeModel
}

export type ResponsePayload = {
    id: string
    paymentState: PaymentState
}

export type BatchedRequestPayload = {
    __typename: ContentTypeModel
    requests: ApiRequest<RequestPayload, ResponsePayload>[]
}

@Injectable({
    providedIn: "root",
})
export class PaymentStateService extends BatchApiCallById<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    private sdk = inject(SdkService)

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        // separate the requests by content-model-type
        const requestsByContentTypeModel = new Map<ContentTypeModel, BatchedRequestPayload>()
        requests.forEach((request) => {
            const __typename = request.payload.__typename
            if (!requestsByContentTypeModel.has(__typename)) {
                requestsByContentTypeModel.set(__typename, {__typename, requests: []})
            }
            requestsByContentTypeModel.get(request.payload.__typename)!.requests.push(request)
        })
        return Array.from(requestsByContentTypeModel.values())
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        const ids = payload.requests.map((request) => request.payload.id).filter(IsUnique)
        switch (payload.__typename) {
            case "Material":
                return this.sdk.gql.paymentStateLabelForMaterials({ids}).then((response) => response.materials)
            case "Model":
                return this.sdk.gql.paymentStateLabelForModels({ids}).then((response) => response.models)
            case "Picture":
                return this.sdk.gql.paymentStateLabelForPictures({ids}).then((response) => response.pictures)
            default:
                console.error(`Unsupported item type for payment state label: ${payload.__typename}`)
                return []
        }
    }
}
