<h1 mat-dialog-title>Edit Instance</h1>
<div mat-dialog-content style="display: flex; flex-direction: column">
    <mat-form-field>
        <mat-label>Group</mat-label>
        <mat-select [(ngModel)]="result.groupId" placeholder="Group">
            <mat-option [value]="null">(No group)</mat-option>
            @for (group of groups; track group) {
                <mat-option [value]="group.id">{{ group.name }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="delete()">Delete</button>
    <button mat-button (click)="update()">Update</button>
    <button mat-button (click)="cancel()" cdkFocusInitial matFo>Cancel</button>
</div>
