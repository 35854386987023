import {DatePipe} from "@angular/common"
import {Component, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenu, MatMenuContent, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ContentTypeModel, MutationUpdateUserInput, SystemRole, UserDetailsFragment} from "@api"
import {CopyToClipboardButtonComponent} from "@common/components/buttons/copy-to-clipboard-button/copy-to-clipboard-button.component"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {getPasswordHash} from "@common/helpers/auth/hash"
import {DialogSize} from "@common/models/dialogs"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {UsersService} from "@common/services/users/users.service"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {PasswordChangeDialogComponent} from "@platform/components/users/password-change-dialog/password-change-dialog.component"
import {MomentModule} from "ngx-moment"

@Component({
    selector: "cm-user",
    templateUrl: "./user-details.component.html",
    styleUrls: ["./user-details.component.scss"],
    standalone: true,
    imports: [
        MatInputModule,
        MatButtonModule,
        FormsModule,
        MomentModule,
        MatMenu,
        MatMenuContent,
        MatMenuItem,
        MatMenuModule,
        DatePipe,
        MatTooltipModule,
        MatSelectModule,
        MatCheckboxModule,
        CopyToClipboardButtonComponent,
        DetailsDialogLayoutComponent,
        RoutedDialogComponent,
        GalleryImageComponent,
        TitleSectionComponent,
    ],
})
export class UserDetailsComponent extends BaseDetailsComponent<UserDetailsFragment, Omit<MutationUpdateUserInput, "id">> {
    override _contentTypeModel = ContentTypeModel.User
    override _fetchItem = this.sdk.gql.userDetails
    override _updateItem = this.sdk.gql.updateUserDetails

    organizations = inject(OrganizationsService)
    users = inject(UsersService)

    matDialog = inject(MatDialog)

    openPasswordChangeDialog(): void {
        const dialogRef = this.matDialog.open(PasswordChangeDialogComponent, {
            disableClose: false,
            width: "400px",
        })

        const userId = this.$item()?.id
        if (userId) {
            dialogRef.componentInstance.onConfirm.subscribe((newPassword: string) =>
                this.notifications.withUserFeedback(
                    async () => {
                        const passwordHash = getPasswordHash(newPassword)
                        await this.sdk.gql.updateUserDetails({
                            input: {
                                id: userId,
                                password: passwordHash,
                            },
                        })
                        this.refresh.item(this.$item())
                    },
                    {
                        success: "Password changed.",
                        error: "Cannot change password.",
                    },
                ),
            )
        }
    }

    updateUserRole(role: SystemRole): void {
        if (role === SystemRole.Superadmin) {
            this.promoteToSuperUser()
        } else {
            const userId = this.$item()?.id
            if (userId) {
                this.notifications.withUserFeedback(
                    async () => {
                        this.sdk.gql.updateUserDetails({
                            input: {
                                id: userId,
                                systemRole: role,
                            },
                        })
                        this.refresh.item(this.$item())
                    },
                    {
                        success: "User role updated.",
                        error: "Cannot update user role.",
                    },
                )
            }
        }
    }

    promoteToSuperUser(): void {
        let promoteToSuperUserDialogRef: MatDialogRef<DialogComponent>

        const user = this.$item()
        if (user) {
            const dialogData = {
                title: "Promote User",
                message:
                    "The user called '" +
                    user.firstName +
                    " " +
                    user.lastName +
                    "' will be promoted to a superuser. " +
                    "This will allow the user to create/edit/delete users, customers and everything else.<br><br>Are you sure you want to continue?",
                confirmLabel: "Promote User",
                cancelLabel: "Cancel",
            }

            promoteToSuperUserDialogRef = this.matDialog.open(DialogComponent, {
                disableClose: false,
                width: "400px",
                data: dialogData,
            })

            promoteToSuperUserDialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    this.notifications.withUserFeedback(
                        async () => {
                            this.sdk.gql.updateUserDetails({
                                input: {
                                    id: user.id,
                                    systemRole: SystemRole.Superadmin,
                                },
                            })
                            this.refresh.item(user)
                        },
                        {
                            success: "User promoted to superuser.",
                            error: "Cannot promote user to superuser.",
                        },
                    )
                }
            })
        }
    }

    protected readonly DialogSize = DialogSize
}
