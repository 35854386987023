import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {UvMapOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-uv-map-node",
    templateUrl: "./uv-map-node.component.html",
    styleUrls: ["./uv-map-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class UvMapNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = UvMapOutputs
    typeInfo = UVMapNodeType
}

export const UVMapNodeType: MaterialNodeType<typeof UvMapNodeComponent> = {
    id: "uvMap",
    label: "UV Map",
    color: "#9e343e",
    name: "UVMap",
    inputs: [] as never[],
    outputs: [UvMapOutputs.uv],
    component: UvMapNodeComponent,
}
