import {AsyncCacheMap} from "@app/common/helpers/async-cache-map/async-cache-map"
import {MediaTypeSchema} from "@cm/lib/api-gql/data-object"
import {IDataObjectNew} from "@cm/lib/templates/interfaces/data-object"
import {contentTypeForExtension} from "@cm/lib/utils/content-types"
import {from, map} from "rxjs"
import {DataObjectBatchApiCall} from "./data-object-batch-api-call"
import {DataObjectDetailsForDataObjectBatchApiCallFragment} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"

export class DataObjectCache extends AsyncCacheMap<number, IDataObjectNew> {
    private dataObjectBatchApiCall: DataObjectBatchApiCall
    constructor(sdkService: SdkService) {
        super((dataObjectId: number) => {
            return from(this.dataObjectBatchApiCall.fetch({legacyId: dataObjectId})).pipe(
                map((result) => {
                    const convertToDataObject = (dataObject: Omit<DataObjectDetailsForDataObjectBatchApiCallFragment, "related">) => {
                        const {mediaType: dataObjectMediaType, ...rest} = dataObject

                        const extension = dataObject.originalFileName.split(".").pop()
                        const mediaType = dataObjectMediaType && dataObjectMediaType.length > 0 ? dataObjectMediaType : contentTypeForExtension(extension ?? "")

                        const parsedMediaType = MediaTypeSchema.safeParse(mediaType)
                        if (!parsedMediaType.success) throw Error(`Invalid media/content type: ${mediaType}`)

                        return {...rest, mediaType: parsedMediaType.data} as Omit<IDataObjectNew, "related">
                    }

                    const {related, ...rest} = result
                    return {...convertToDataObject(rest), related: related.map(convertToDataObject)}
                }),
            )
        })
        this.dataObjectBatchApiCall = new DataObjectBatchApiCall(sdkService)
    }
}
