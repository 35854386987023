// @ts-strict-ignore
import {Component, DestroyRef, inject, ViewChild, OnDestroy} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {FormsModule} from "@angular/forms"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute, Router} from "@angular/router"
import {
    DataObjectState,
    DataObjectType,
    ImageDataType,
    MaterialExplorerItemFragment,
    MaterialExplorerMaterialRevisionFragment,
    MaterialExplorerTextureFragment,
    MaterialExplorerTextureRevisionFragment,
    MaterialExplorerTextureSetFragment,
    TextureType,
} from "@api"
import {ThumbnailsService} from "@app/platform/services/thumbnails/thumbnails.service"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {LoadingSpinnerComponent} from "@common/components/progress"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"
import {UtilsService} from "@legacy/helpers/utils"
import {MeasurementTypes} from "@common/models/settings/settings"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {AddTextureDialogComponent} from "@platform/components/materials/add-texture-dialog/add-texture-dialog.component"
import {AddTextureDialogResult} from "@platform/models/dialogs/add-texture-dialog-result"
import {TextureTypeLabels} from "@platform/models/state-labels/texture-type-labels"
import {filter, firstValueFrom, Observable, Subject, switchMap} from "rxjs"
import {IsDefined} from "@cm/lib/utils/filter"
import {IMaterialConnection, IMaterialNode} from "@cm/lib/materials/material-node-graph"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"

export const fabricMaterialInputId = "combinedModels/material"

@Component({
    standalone: true,
    selector: "cm-material-explorer",
    templateUrl: "./material-explorer.component.html",
    styleUrls: ["./material-explorer.component.scss"],
    imports: [RoutedDialogComponent, LoadingSpinnerComponent, MatInputModule, FormsModule, SceneViewerComponent],
})
export class MaterialExplorerComponent implements OnDestroy {
    @ViewChild("sceneViewer") sceneViewer: SceneViewerComponent
    dropActive = {}
    dialogSizes = DialogSize
    unsubscribe = new Subject<void>()
    measurementTypes = MeasurementTypes
    textureSet: MaterialExplorerTextureSetFragment
    texture: MaterialExplorerTextureFragment
    materialRevision: MaterialExplorerMaterialRevisionFragment
    textureRevision?: MaterialExplorerTextureRevisionFragment
    materialConnections: IMaterialConnection[]
    materialNodes: IMaterialNode[]
    texImageNode: IMaterialNode
    mappingNode: IMaterialNode

    loading = false
    showSizeOptions = false

    parameterWidth: number = 15
    parameterHeight: number = 15
    parameterRotation: number = 0

    destroyRef = inject(DestroyRef)
    organizations = inject(OrganizationsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    materialGraphService = inject(MaterialGraphService)
    configMenuService = inject(ConfigMenuLegacyService)

    organizationId?: string
    organizationLegacyId?: number
    organizationTemplateLegacyId?: number
    materialExplorerItem?: MaterialExplorerItemFragment

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public utils: UtilsService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private uploadService: UploadGqlService,
        private thumbnailsService: ThumbnailsService,
    ) {
        toObservable(this.organizations.$current)
            .pipe(
                filter(IsDefined),
                switchMap((organization) => this.loadData(organization.id)),
                takeUntilDestroyed(),
            )
            .subscribe()

        this.configMenuService.materialSelected$.pipe(takeUntilDestroyed()).subscribe((_material) => {
            this.showSizeOptions = false
        })
    }

    async loadData(currentOrganizationId?: string) {
        //TODO: allow the user to specify the organization
        if (!currentOrganizationId) {
            throw new Error("No current organization.")
        }
        const {organization} = await this.sdk.gql.materialExplorerData({
            organizationId: currentOrganizationId,
        })
        if (!organization.matExplorerMaterial) {
            throw Error("No material explorer found for this user's organizations.")
        }

        this.organizationId = organization.id
        this.organizationLegacyId = organization.legacyId
        this.organizationTemplateLegacyId = organization.matExplorerTemplate?.legacyId
        this.materialExplorerItem = organization.matExplorerMaterial

        if (!this.materialExplorerItem.latestRevision) {
            throw Error("Material explorer material does not have a latest revision.")
        }

        this.materialNodes = this.materialExplorerItem.latestRevision.nodes.map((node) => this.materialGraphService.convertNodeFromFragment(node))
        this.materialConnections = this.materialExplorerItem.latestRevision.connections.map((connection) =>
            this.materialGraphService.convertConnectionFromFragment(connection),
        )

        const textureNodes = this.materialNodes.filter((node) => node.name === "TexImage")
        if (textureNodes.length !== 1) {
            throw Error("Material explorer material should have one and only one texture node.")
        }

        const mappingNodes = this.materialNodes.filter((node) => node.name === "Mapping")
        if (mappingNodes.length !== 1) {
            throw Error("Material explorer material should have one and only one mapping node.")
        }

        if (!this.materialExplorerItem.textureGroup) {
            throw Error("Material explorer material does not have any texture group.")
        }

        const textureSets = this.materialExplorerItem.textureGroup.textureSets
        if (textureSets.length !== 1) {
            throw Error("Material explorer material texture should have one and only one texture set.")
        }

        this.materialRevision = this.materialExplorerItem.latestRevision
        this.textureSet = textureSets[0]
        this.texture = this.textureSet.textures.find(({type}) => type === TextureType.Diffuse)
        this.texImageNode = textureNodes[0]
        this.mappingNode = mappingNodes[0]
    }

    async updateMaterial() {
        this.texImageNode.textureRevision = this.textureRevision.legacyId

        this.mappingNode.parameters = this.mappingNode.parameters.map((parameter) => {
            switch (parameter.name) {
                case "Rotation":
                    return {
                        ...parameter,
                        value: [0, 0, (this.parameterRotation * Math.PI) / 180.0],
                    }
                case "Scale":
                    return {
                        ...parameter,
                        value: [
                            this.measurementTypes.Imperial.convertToMetric(this.parameterWidth),
                            this.measurementTypes.Imperial.convertToMetric(this.parameterHeight),
                            1,
                        ],
                    }
                default:
                    return parameter
            }
        })

        const graph = await this.materialGraphService.graphFromNodesAndConnections(
            this.materialNodes,
            this.materialConnections,
            this.materialExplorerItem.latestRevision.legacyId,
            this.materialExplorerItem.legacyId,
            "Material Explorer Material",
        )
        this.sceneViewer.setMaterialInput(fabricMaterialInputId, graph)
        return firstValueFrom(this.sceneViewer.syncChanges())
    }

    async filesSelectedForUpload(event: Event) {
        try {
            const files: FileList = (<HTMLInputElement>event.target).files
            if (!files.length) return

            const newTextureRevision = await firstValueFrom(this.uploadNewTextureRevision(files[0], this.texture, this.textureSet))
            if (!newTextureRevision) throw new Error("Failed to create a texture revision.")
            if (newTextureRevision.dataObject.state !== DataObjectState.Completed) throw new Error("Texture data object is not completed.")

            await this.discardOldTextureRevision()
            this.textureRevision = newTextureRevision
            await this.updateMaterial()
            this.showSizeOptions = true
            this.loading = false
        } catch (error) {
            this.snackBar.open("Cannot upload file.", "", {duration: 3000})
        }
    }

    uploadNewTextureRevision(
        file: File,
        texture: MaterialExplorerTextureFragment,
        _textureSet: MaterialExplorerTextureSetFragment,
    ): Observable<MaterialExplorerTextureRevisionFragment | null> {
        const dialogRef: MatDialogRef<AddTextureDialogComponent, AddTextureDialogResult> = this.dialog.open(AddTextureDialogComponent, {
            width: "350px",
            data: {
                measurementType: this.measurementTypes.Imperial,
                showColorSpaceSetting: false,
            },
        })
        return dialogRef.afterClosed().pipe(
            filter((result) => !!result),
            switchMap(async (result: AddTextureDialogResult) => {
                this.loading = true
                const dataObject = await this.uploadService.createAndUploadDataObject(
                    file,
                    {
                        organizationId: this.organizationId,
                        type: DataObjectType.Texture,
                        imageDataType: TextureTypeLabels.get(texture.type)?.isColorData ? ImageDataType.Color : ImageDataType.NonColor,
                        imageColorSpace: result.colorSpace,
                    },
                    {processUpload: true},
                )
                await this.thumbnailsService.waitUntilAvailable(dataObject.id)
                const textureRevision = await this.sdk.gql
                    .materialExplorerCreateTextureRevision({
                        input: {
                            textureId: texture.id,
                            width: result.width,
                            height: result.height,
                            displacement: result.displacement,
                            dataObjectId: dataObject.id,
                        },
                    })
                    .then((result) => result.createTextureRevision)
                this.parameterWidth = this.measurementTypes.Imperial.convertFromMetric(result.width)
                this.parameterHeight = this.measurementTypes.Imperial.convertFromMetric(result.height)
                this.parameterRotation = 0
                return textureRevision
            }),
            takeUntilDestroyed(this.destroyRef),
        )
    }

    async discardOldTextureRevision() {
        if (!this.textureRevision) return

        await this.sdk.gql.materialExplorerDeleteTextureRevision(this.textureRevision)
        this.textureRevision = undefined
    }

    updateSize() {
        void this.updateMaterial()
    }

    overlayClosed() {
        void this.router.navigate(["../"], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }

    ngOnDestroy() {
        void this.discardOldTextureRevision()
    }
}
