import {Component} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {JobFarmGroupsTableComponent} from "@platform/components/job-farm-groups/job-farm-groups-table/job-farm-groups-table.component"
import {JobsGridComponent} from "@platform/components/jobs/jobs-grid/jobs-grid.component"
import {TableLayoutComponent} from "@platform/components/layouts/table-layout/table-layout.component"

@Component({
    selector: "cm-job-farm-groups-page",
    standalone: true,
    imports: [CheckboxesFilterComponent, ItemFiltersComponent, JobsGridComponent, RouterOutlet, JobFarmGroupsTableComponent, TableLayoutComponent],
    templateUrl: "./job-farm-groups-page.component.html",
    styleUrl: "./job-farm-groups-page.component.scss",
})
export class JobFarmGroupsPageComponent {}
