import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core"
import {RouterModule} from "@angular/router"

@Component({
    selector: "cm-tabs",
    templateUrl: "./tabs.component.html",
    styleUrls: ["./tabs.component.scss"],
    standalone: true,
    imports: [RouterModule],
})
export class TabsComponent<TabType extends string> implements OnInit {
    @Input() activeTab?: TabType
    @Input() tabs: {title: string; icon: string; id: TabType}[] = []
    @Output() onChangeTab = new EventEmitter<TabType>()

    ngOnInit() {
        this.activeTab ??= this.tabs?.[0]?.id
    }
}
