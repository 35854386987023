import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {HalContext, HalEntity} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {Box2Like} from "@cm/lib/math/box2"
import {BrushMode} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"

const BLITTING_FUNCTION = `
    uniform float u_opacity;

    vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
        vec4 preStroke = texelFetch0(sourcePixel);
        vec4 currStroke = texelFetch1(sourcePixel);
        return clamp(preStroke + currStroke * u_opacity, 0.0, 1.0);
    }
`

export class HalBlendCurrentStroke implements HalEntity {
    constructor(readonly context: HalContext) {
        this.halPainterImageBlit = HalPainterImageBlit.create(this.context, BLITTING_FUNCTION)
    }

    // HalEntity
    dispose(): void {
        this.halPainterImageBlit.dispose()
    }

    async paint(
        target: HalPaintable,
        preStrokeBrushedImage: HalImage,
        currentStrokeImage: HalImage,
        brushOpacity: number,
        brushMode: BrushMode,
        boundingBox: Box2Like,
    ): Promise<void> {
        this.halPainterImageBlit.setParameter("u_opacity", {type: "float", value: brushOpacity * (brushMode === BrushMode.Add ? 1 : -1)})
        await this.halPainterImageBlit.paint(target, [preStrokeBrushedImage, currentStrokeImage], {
            sourceRegion: boundingBox,
            targetOffset: {x: boundingBox.x, y: boundingBox.y},
        })
    }

    private halPainterImageBlit: HalPainterImageBlit
}
