import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"
import {ParameterType, imageOpLevels} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-levels"

const LevelsNode = declareImageOpNode(imageOpLevels)

export function levels(parameters: GetParameters<Context, ParameterType>) {
    return new LevelsNode(parameters)
}
