<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" [needsConfirmationToClose]="templateGraphModified()">
    <ng-container cm-overlay-content>
        @if (!templateRevision() || !templateGraph()) {
            <cm-loading-spinner>
                <ng-container cm-text-bottom>
                    <div class="cm-loading-text">Loading...</div>
                </ng-container>
            </cm-loading-spinner>
        } @else {
            <cm-template-edit
                [title]="templateRevision().template.name ?? undefined"
                [templateGraph]="templateGraph()"
                [defaultCustomerId]="templateRevision().template.organization.legacyId"
                [templateId]="templateRevision().template.id"
                [templateRevisionId]="templateRevision().id"
                (templateGraphModified)="handleTemplateGraphModified($event)"
                (requestSaveInLibrary)="handleSaveInLibrary($event)"
                (requestSave)="handleSave($event)"
                (requestOldEditor)="handleOpenInOldEditor()"
            ></cm-template-edit>
        }
    </ng-container>
</cm-routed-dialog>
