import {Component, Input} from "@angular/core"
import {Observable} from "rxjs"
import {NamedConfiguratorVariant} from "@cm/lib/pricing/nodes/core"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {TippyDirective} from "@ngneat/helipopper"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AsyncPipe, NgClass} from "@angular/common"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"

@Component({
    standalone: true,
    selector: "cm-config-button",
    templateUrl: "./config-button.component.html",
    styleUrls: ["./config-button.component.scss"],
    imports: [TippyDirective, MemoizePipe, AsyncPipe, NgClass],
})
export class ConfigButtonComponent {
    @Input() asLabel: boolean = false
    @Input() tooltipContainer: HTMLDivElement | undefined
    @Input() descriptor: Nodes.Meta.InterfaceDescriptor | undefined
    @Input() variant: Nodes.Meta.VariantInfo | undefined
    @Input() selectedVariantId: string | undefined
    @Input() notAvailableTooltip: string = "Variant not available"
    @Input() useCaptions: boolean = false

    constructor(public configMenuService: ConfigMenuLegacyService) {}

    getConfigVariantColor(): string {
        if (!this.variant) return "#898989"
        return this.configMenuService.configVariantColors[this.variant.id]
    }

    /**The CDK dragging tools did not work well here. They make the items themselves move and dragging outside of the parent
     * was problematic. So we use the native drag and drop API instead.
     *
     * Unwrapped ids are required, because a config group can be referenced from different templates, which changes the external id.
     * Since the group id is used to identify nodes in the pricing graph, only the internal / unwrapped id works.
     */
    onDragStart(event: DragEvent, variant: Nodes.Meta.VariantInfo, descriptor: Nodes.Meta.InterfaceDescriptor): void {
        const configuratorVariant: NamedConfiguratorVariant = {
            groupId: Nodes.Meta.unwrapInterfaceId(descriptor.id)[1],
            groupName: descriptor.name,
            variantId: variant.id,
            variantName: variant.name,
        }
        if (event.dataTransfer) {
            event.dataTransfer.setData("text/plain", JSON.stringify(configuratorVariant))
        }
    }

    fetchThumbnailForDataObject(id?: number): Observable<string | null> {
        return this.configMenuService.fetchThumbnailForDataObject(id)
    }
}
