import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {OperatorParameterValue} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {ImageOpNodeGraphEvaluatorBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-node-graph-evaluator-base"
import {ImageOpContextImgProc} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-imgproc"
import {ImagePtrImgProc} from "@app/textures/texture-editor/operator-stack/image-op-system/image-imgproc"
import {TexturesApiService} from "@app/textures/service/textures-api.service"

export class ImageOpNodeGraphEvaluatorImgProc extends ImageOpNodeGraphEvaluatorBase {
    constructor(texturesApi: TexturesApiService, drawableImageCache: DrawableImageCache) {
        super("final")
        this._imageOpContextImgProc = new ImageOpContextImgProc(texturesApi, drawableImageCache)
    }

    override dispose() {
        this._imageOpContextImgProc?.dispose()
        super.dispose()
    }

    async evaluate(nodeGraph: OperatorParameterValue<ImagePtr>): Promise<ImagePtrImgProc> {
        this._imageOpContextImgProc.preEvaluation()
        const resultRef = await this.evaluateResultImageRef(this._imageOpContextImgProc, nodeGraph)
        const resultImage = await this._imageOpContextImgProc.getImage(resultRef)
        resultRef.release()
        this._imageOpContextImgProc.postEvaluation()
        return resultImage
    }

    async createDataObjectImage(dataObjectId: string): Promise<ImagePtr> {
        return this._imageOpContextImgProc.createDataObjectImageRef(dataObjectId)
    }

    private _imageOpContextImgProc: ImageOpContextImgProc
}
