@if (activities === undefined) {
    <cm-placeholder [dark]="true" [style.width]="'30%'"></cm-placeholder>
    <cm-placeholder [dark]="true" [style.width]="'33%'"></cm-placeholder>
    <cm-placeholder [dark]="true" [style.width]="'32%'"></cm-placeholder>
    <cm-placeholder [dark]="true" [style.width]="'35%'"></cm-placeholder>
    <cm-placeholder [dark]="true" [style.width]="'31%'"></cm-placeholder>
} @else {
    @for (activity of activities; track activity) {
        <div>
            <span class="cm-user"> {{ activity.createdBy.firstName }} {{ activity.createdBy.lastName }}</span>
            @if (activity.historyType === HistoryType.Added) {
                <span> created this item </span>
                <span matTooltip="{{ activity.historyDate | amLocal | amDateFormat: 'D MMMM, YYYY HH:mm' }}">{{ activity.historyDate | amTimeAgo }}</span
                >.
            }
            @if (activity.historyType === HistoryType.Moved) {
                @if (!activity.previousStateLabel || activity.previousStateLabel === activity.stateLabel) {
                    <span> edited this item </span>
                    <span matTooltip="{{ activity.historyDate | amLocal | amDateFormat: 'D MMMM, YYYY HH:mm' }}">{{ activity.historyDate | amTimeAgo }}</span
                    >.
                } @else {
                    <span> moved this item from </span>
                    <span class="cm-state">{{ activity.previousStateLabel }}</span>
                    <span> to </span>
                    <span class="cm-state">{{ activity.stateLabel }} </span>
                    <span matTooltip="{{ activity.historyDate | amLocal | amDateFormat: 'D MMMM, YYYY HH:mm' }}">{{ activity.historyDate | amTimeAgo }}</span
                    >.
                }
            }
        </div>
    }
    @if (activities.length < 1) {
        <div>There are no activities yet.</div>
    }
}
