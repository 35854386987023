import {MaterialSocket} from "@material-editor/models/material-socket"

export const AddShaderOutputs: Record<string, MaterialSocket> = {
    shader: {paramType: "socket", id: "Shader", type: "output", valueType: "output", label: "Shader"},
}

export const AddShaderInputs: Record<string, MaterialSocket> = {
    shader: {paramType: "socket", id: "Shader", type: "input", valueType: "plain", label: "Shader"},
    shader_001: {paramType: "socket", id: "Shader_001", type: "input", valueType: "plain", label: "Shader"},
}
