<div>{{ label }}</div>
<div [hidden]="connected.value" class="cm-vector-inputs">
    <cm-scalar-input-socket
        [label]="'X'"
        [range]="range"
        [hideBarFill]="true"
        [connected]="connected"
        [value]="value?.[0]"
        (valueChange)="value[0] = $event; valueChange.emit(value)"
    >
    </cm-scalar-input-socket>
    <cm-scalar-input-socket
        [label]="'Y'"
        [range]="range"
        [hideBarFill]="true"
        [connected]="connected"
        [value]="value?.[1]"
        (valueChange)="value[1] = $event; valueChange.emit(value)"
    >
    </cm-scalar-input-socket>
    <cm-scalar-input-socket
        [label]="'Z'"
        [range]="range"
        [hideBarFill]="true"
        [connected]="connected"
        [value]="value?.[2]"
        (valueChange)="value[2] = $event; valueChange.emit(value)"
    >
    </cm-scalar-input-socket>
</div>
