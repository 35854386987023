import {zeros} from "@common/helpers/utils/math-utils"
import {ILinearOperatorParameterBlock, ITransformParameters3D, RotationalFreedomMode, Vector} from "@cm/lib/templates/interfaces/connection-solver"

// Quaternion product: a * b -> out
// out.w = a.w * b.w - a.x * b.x - a.y * b.y - a.z * b.z;
// out.x = a.w * b.x + a.x * b.w + a.y * b.z - a.z * b.y;
// out.y = a.w * b.y - a.x * b.z + a.y * b.w + a.z * b.x;
// out.z = a.w * b.z + a.x * b.y - a.y * b.x + a.z * b.w;

export class TransformParameters3D implements ITransformParameters3D {
    readonly size: number = 6

    public tx = 0
    public ty = 0
    public tz = 0
    public qx = 0
    public qy = 0
    public qz = 0
    public qw = 1

    public RotationalFreedomMode = RotationalFreedomMode

    public tx_free = true
    public ty_free = true
    public tz_free = true
    public r_free: RotationalFreedomMode = RotationalFreedomMode.Full

    readonly matrix = zeros(4, 4) // Output transform matrix
    readonly jacobian = zeros(6, 4, 4) // Jacobian of the transform matrix wrt. parameters
    readonly matrixInv = zeros(4, 4) // Inverse of the transform matrix
    readonly jacobianInv = zeros(6, 4, 4) // Jacobian of the inverse transform matrix wrt. parameters

    constructor() {
        this.updateMatrixAndJacobian()
    }

    hasFreeParams() {
        return this.tx_free || this.ty_free || this.tz_free || this.r_free !== RotationalFreedomMode.None
    }

    updateWithDelta(delta: Vector) {
        const tx_free = this.tx_free
        const ty_free = this.ty_free
        const tz_free = this.tz_free
        const r_free = this.r_free

        if (tx_free) this.tx += delta.get(0)
        if (ty_free) this.ty += delta.get(1)
        if (tz_free) this.tz += delta.get(2)

        if (r_free !== RotationalFreedomMode.None) {
            // take the deltas in manifold tangent space and use them to update the quaternion
            const a = delta.get(3)
            const b = delta.get(4)
            const c = delta.get(5)
            const dnorm = Math.sqrt(a * a + b * b + c * c)
            if (dnorm > 0.0) {
                const qx = this.qx
                const qy = this.qy
                const qz = this.qz
                const qw = this.qw
                const s = Math.sin(dnorm) / dnorm
                const dqw = Math.cos(dnorm)
                const dqx = s * a
                const dqy = s * b
                const dqz = s * c

                // [_qx,_qy,_qz,_qw] = QuaternionProduct [dqx,dqy,dqz,dqw] [qx,qy,qz,qw]

                let _qw = dqw * qw - dqx * qx - dqy * qy - dqz * qz
                let _qx = 0
                let _qy = 0
                let _qz = 0

                // (only calculate what is necessary, assuming constraints)
                if (r_free === RotationalFreedomMode.X_Only) {
                    _qx = dqw * qx + dqx * qw + dqy * qz - dqz * qy
                } else if (r_free === RotationalFreedomMode.Y_Only) {
                    _qy = dqw * qy - dqx * qz + dqy * qw + dqz * qx
                } else if (r_free === RotationalFreedomMode.Z_Only) {
                    _qz = dqw * qz + dqx * qy - dqy * qx + dqz * qw
                } else {
                    _qw = dqw * qw - dqx * qx - dqy * qy - dqz * qz
                    _qx = dqw * qx + dqx * qw + dqy * qz - dqz * qy
                    _qy = dqw * qy - dqx * qz + dqy * qw + dqz * qx
                    _qz = dqw * qz + dqx * qy - dqy * qx + dqz * qw
                }

                // normalize
                const sc = 1.0 / Math.sqrt(Math.max(1e-9, _qw * _qw + _qx * _qx + _qy * _qy + _qz * _qz))
                this.qw = _qw * sc
                this.qx = _qx * sc
                this.qy = _qy * sc
                this.qz = _qz * sc
            }
        }

        if (tx_free || ty_free || tz_free || r_free !== RotationalFreedomMode.None) {
            this.updateMatrixAndJacobian()
        }
    }

    public updateMatrixAndJacobian() {
        const matrix = this.matrix
        const matrixInv = this.matrixInv
        const jacobian = this.jacobian
        const jacobianInv = this.jacobianInv

        matrix.set(0, 3, this.tx)
        matrix.set(1, 3, this.ty)
        matrix.set(2, 3, this.tz)
        matrix.set(3, 3, 1)

        const tx_free = this.tx_free
        const ty_free = this.ty_free
        const tz_free = this.tz_free
        const r_free = this.r_free

        const x = this.qx
        const y = this.qy
        const z = this.qz
        const w = this.qw

        const ww = w * w
        const wx = w * x
        const wy = w * y
        const wz = w * z
        const xx = x * x
        const xy = x * y
        const xz = x * z
        const yy = y * y
        const yz = y * z
        const zz = z * z

        matrix.set(0, 0, ww + xx - yy - zz)
        matrix.set(0, 1, 2 * (xy - wz))
        matrix.set(0, 2, 2 * (wy + xz))
        matrix.set(1, 0, 2 * (wz + xy))
        matrix.set(1, 1, ww - xx + yy - zz)
        matrix.set(1, 2, 2 * (yz - wx))
        matrix.set(2, 0, 2 * (xz - wy))
        matrix.set(2, 1, 2 * (wx + yz))
        matrix.set(2, 2, ww - xx - yy + zz)

        const tx = this.tx
        const ty = this.ty
        const tz = this.tz

        matrixInv.set(0, 0, matrix.get(0, 0))
        matrixInv.set(0, 1, matrix.get(1, 0))
        matrixInv.set(0, 2, matrix.get(2, 0))
        matrixInv.set(1, 0, matrix.get(0, 1))
        matrixInv.set(1, 1, matrix.get(1, 1))
        matrixInv.set(1, 2, matrix.get(2, 1))
        matrixInv.set(2, 0, matrix.get(0, 2))
        matrixInv.set(2, 1, matrix.get(1, 2))
        matrixInv.set(2, 2, matrix.get(2, 2))
        matrixInv.set(0, 3, -(matrix.get(0, 0) * tx + matrix.get(1, 0) * ty + matrix.get(2, 0) * tz))
        matrixInv.set(1, 3, -(matrix.get(0, 1) * tx + matrix.get(1, 1) * ty + matrix.get(2, 1) * tz))
        matrixInv.set(2, 3, -(matrix.get(0, 2) * tx + matrix.get(1, 2) * ty + matrix.get(2, 2) * tz))
        matrixInv.set(3, 3, 1)

        if (tx_free) {
            jacobian.set(0, 0, 3, 1)
            jacobianInv.set(0, 0, 3, -matrixInv.get(0, 0))
            jacobianInv.set(0, 1, 3, -matrixInv.get(1, 0))
            jacobianInv.set(0, 2, 3, -matrixInv.get(2, 0))
        } else {
            jacobian.set(0, 0, 3, 0)
            jacobianInv.set(0, 0, 3, 0)
            jacobianInv.set(0, 1, 3, 0)
            jacobianInv.set(0, 2, 3, 0)
        }

        if (ty_free) {
            jacobian.set(1, 1, 3, 1)
            jacobianInv.set(1, 0, 3, -matrixInv.get(0, 1))
            jacobianInv.set(1, 1, 3, -matrixInv.get(1, 1))
            jacobianInv.set(1, 2, 3, -matrixInv.get(2, 1))
        } else {
            jacobian.set(1, 1, 3, 0)
            jacobianInv.set(1, 0, 3, 0)
            jacobianInv.set(1, 1, 3, 0)
            jacobianInv.set(1, 2, 3, 0)
        }

        if (tz_free) {
            jacobian.set(2, 2, 3, 1)
            jacobianInv.set(2, 0, 3, -matrixInv.get(0, 2))
            jacobianInv.set(2, 1, 3, -matrixInv.get(1, 2))
            jacobianInv.set(2, 2, 3, -matrixInv.get(2, 2))
        } else {
            jacobian.set(2, 2, 3, 0)
            jacobianInv.set(2, 0, 3, 0)
            jacobianInv.set(2, 1, 3, 0)
            jacobianInv.set(2, 2, 3, 0)
        }

        if (r_free !== RotationalFreedomMode.None) {
            // Rotation freedom around a single axis corresponds to restriction in the quaternion tangent space:
            //   X only -> A only
            //   Y only -> B only
            //   Z only -> C only
            //   Full -> A+B+C
            let a_free: boolean
            let b_free: boolean
            let c_free: boolean
            if (r_free === RotationalFreedomMode.X_Only) {
                a_free = true
                b_free = false
                c_free = false
            } else if (r_free === RotationalFreedomMode.Y_Only) {
                a_free = false
                b_free = true
                c_free = false
            } else if (r_free === RotationalFreedomMode.Z_Only) {
                a_free = false
                b_free = false
                c_free = true
            } else {
                a_free = true
                b_free = true
                c_free = true
            }

            const dw_da = a_free ? -x : 0
            const dx_da = a_free ? w : 0
            const dy_da = a_free ? -z : 0
            const dz_da = a_free ? y : 0
            const dw_db = b_free ? -y : 0
            const dx_db = b_free ? z : 0
            const dy_db = b_free ? w : 0
            const dz_db = b_free ? -x : 0
            const dw_dc = c_free ? -z : 0
            const dx_dc = c_free ? -y : 0
            const dy_dc = c_free ? x : 0
            const dz_dc = c_free ? w : 0

            const dww_da = dw_da * w + w * dw_da
            const dwx_da = dw_da * x + w * dx_da
            const dwy_da = dw_da * y + w * dy_da
            const dwz_da = dw_da * z + w * dz_da
            const dxx_da = dx_da * x + x * dx_da
            const dxy_da = dx_da * y + x * dy_da
            const dxz_da = dx_da * z + x * dz_da
            const dyy_da = dy_da * y + y * dy_da
            const dyz_da = dy_da * z + y * dz_da
            const dzz_da = dz_da * z + z * dz_da

            const dww_db = dw_db * w + w * dw_db
            const dwx_db = dw_db * x + w * dx_db
            const dwy_db = dw_db * y + w * dy_db
            const dwz_db = dw_db * z + w * dz_db
            const dxx_db = dx_db * x + x * dx_db
            const dxy_db = dx_db * y + x * dy_db
            const dxz_db = dx_db * z + x * dz_db
            const dyy_db = dy_db * y + y * dy_db
            const dyz_db = dy_db * z + y * dz_db
            const dzz_db = dz_db * z + z * dz_db

            const dww_dc = dw_dc * w + w * dw_dc
            const dwx_dc = dw_dc * x + w * dx_dc
            const dwy_dc = dw_dc * y + w * dy_dc
            const dwz_dc = dw_dc * z + w * dz_dc
            const dxx_dc = dx_dc * x + x * dx_dc
            const dxy_dc = dx_dc * y + x * dy_dc
            const dxz_dc = dx_dc * z + x * dz_dc
            const dyy_dc = dy_dc * y + y * dy_dc
            const dyz_dc = dy_dc * z + y * dz_dc
            const dzz_dc = dz_dc * z + z * dz_dc

            jacobian.set(3, 0, 0, dww_da + dxx_da - dyy_da - dzz_da)
            jacobian.set(3, 0, 1, 2 * (dxy_da - dwz_da))
            jacobian.set(3, 0, 2, 2 * (dwy_da + dxz_da))
            jacobian.set(3, 1, 0, 2 * (dwz_da + dxy_da))
            jacobian.set(3, 1, 1, dww_da - dxx_da + dyy_da - dzz_da)
            jacobian.set(3, 1, 2, 2 * (dyz_da - dwx_da))
            jacobian.set(3, 2, 0, 2 * (dxz_da - dwy_da))
            jacobian.set(3, 2, 1, 2 * (dwx_da + dyz_da))
            jacobian.set(3, 2, 2, dww_da - dxx_da - dyy_da + dzz_da)

            jacobian.set(4, 0, 0, dww_db + dxx_db - dyy_db - dzz_db)
            jacobian.set(4, 0, 1, 2 * (dxy_db - dwz_db))
            jacobian.set(4, 0, 2, 2 * (dwy_db + dxz_db))
            jacobian.set(4, 1, 0, 2 * (dwz_db + dxy_db))
            jacobian.set(4, 1, 1, dww_db - dxx_db + dyy_db - dzz_db)
            jacobian.set(4, 1, 2, 2 * (dyz_db - dwx_db))
            jacobian.set(4, 2, 0, 2 * (dxz_db - dwy_db))
            jacobian.set(4, 2, 1, 2 * (dwx_db + dyz_db))
            jacobian.set(4, 2, 2, dww_db - dxx_db - dyy_db + dzz_db)

            jacobian.set(5, 0, 0, dww_dc + dxx_dc - dyy_dc - dzz_dc)
            jacobian.set(5, 0, 1, 2 * (dxy_dc - dwz_dc))
            jacobian.set(5, 0, 2, 2 * (dwy_dc + dxz_dc))
            jacobian.set(5, 1, 0, 2 * (dwz_dc + dxy_dc))
            jacobian.set(5, 1, 1, dww_dc - dxx_dc + dyy_dc - dzz_dc)
            jacobian.set(5, 1, 2, 2 * (dyz_dc - dwx_dc))
            jacobian.set(5, 2, 0, 2 * (dxz_dc - dwy_dc))
            jacobian.set(5, 2, 1, 2 * (dwx_dc + dyz_dc))
            jacobian.set(5, 2, 2, dww_dc - dxx_dc - dyy_dc + dzz_dc)

            // a
            jacobianInv.set(3, 0, 0, dww_da + dxx_da - dyy_da - dzz_da)
            jacobianInv.set(3, 1, 0, 2 * (dxy_da - dwz_da))
            jacobianInv.set(3, 2, 0, 2 * (dwy_da + dxz_da))
            jacobianInv.set(3, 0, 1, 2 * (dwz_da + dxy_da))
            jacobianInv.set(3, 1, 1, dww_da - dxx_da + dyy_da - dzz_da)
            jacobianInv.set(3, 2, 1, 2 * (dyz_da - dwx_da))
            jacobianInv.set(3, 0, 2, 2 * (dxz_da - dwy_da))
            jacobianInv.set(3, 1, 2, 2 * (dwx_da + dyz_da))
            jacobianInv.set(3, 2, 2, dww_da - dxx_da - dyy_da + dzz_da)
            jacobianInv.set(3, 0, 3, -(jacobian.get(3, 0, 0) * tx + jacobian.get(3, 1, 0) * ty + jacobian.get(3, 2, 0) * tz))
            jacobianInv.set(3, 1, 3, -(jacobian.get(3, 0, 1) * tx + jacobian.get(3, 1, 1) * ty + jacobian.get(3, 2, 1) * tz))
            jacobianInv.set(3, 2, 3, -(jacobian.get(3, 0, 2) * tx + jacobian.get(3, 1, 2) * ty + jacobian.get(3, 2, 2) * tz))

            // b
            jacobianInv.set(4, 0, 0, dww_db + dxx_db - dyy_db - dzz_db)
            jacobianInv.set(4, 1, 0, 2 * (dxy_db - dwz_db))
            jacobianInv.set(4, 2, 0, 2 * (dwy_db + dxz_db))
            jacobianInv.set(4, 0, 1, 2 * (dwz_db + dxy_db))
            jacobianInv.set(4, 1, 1, dww_db - dxx_db + dyy_db - dzz_db)
            jacobianInv.set(4, 2, 1, 2 * (dyz_db - dwx_db))
            jacobianInv.set(4, 0, 2, 2 * (dxz_db - dwy_db))
            jacobianInv.set(4, 1, 2, 2 * (dwx_db + dyz_db))
            jacobianInv.set(4, 2, 2, dww_db - dxx_db - dyy_db + dzz_db)
            jacobianInv.set(4, 0, 3, -(jacobian.get(4, 0, 0) * tx + jacobian.get(4, 1, 0) * ty + jacobian.get(4, 2, 0) * tz))
            jacobianInv.set(4, 1, 3, -(jacobian.get(4, 0, 1) * tx + jacobian.get(4, 1, 1) * ty + jacobian.get(4, 2, 1) * tz))
            jacobianInv.set(4, 2, 3, -(jacobian.get(4, 0, 2) * tx + jacobian.get(4, 1, 2) * ty + jacobian.get(4, 2, 2) * tz))

            // c
            jacobianInv.set(5, 0, 0, dww_dc + dxx_dc - dyy_dc - dzz_dc)
            jacobianInv.set(5, 1, 0, 2 * (dxy_dc - dwz_dc))
            jacobianInv.set(5, 2, 0, 2 * (dwy_dc + dxz_dc))
            jacobianInv.set(5, 0, 1, 2 * (dwz_dc + dxy_dc))
            jacobianInv.set(5, 1, 1, dww_dc - dxx_dc + dyy_dc - dzz_dc)
            jacobianInv.set(5, 2, 1, 2 * (dyz_dc - dwx_dc))
            jacobianInv.set(5, 0, 2, 2 * (dxz_dc - dwy_dc))
            jacobianInv.set(5, 1, 2, 2 * (dwx_dc + dyz_dc))
            jacobianInv.set(5, 2, 2, dww_dc - dxx_dc - dyy_dc + dzz_dc)
            jacobianInv.set(5, 0, 3, -(jacobian.get(5, 0, 0) * tx + jacobian.get(5, 1, 0) * ty + jacobian.get(5, 2, 0) * tz))
            jacobianInv.set(5, 1, 3, -(jacobian.get(5, 0, 1) * tx + jacobian.get(5, 1, 1) * ty + jacobian.get(5, 2, 1) * tz))
            jacobianInv.set(5, 2, 3, -(jacobian.get(5, 0, 2) * tx + jacobian.get(5, 1, 2) * ty + jacobian.get(5, 2, 2) * tz))
        } else {
            jacobian.set(3, 0, 0, 0)
            jacobian.set(3, 0, 1, 0)
            jacobian.set(3, 0, 2, 0)
            jacobian.set(3, 1, 0, 0)
            jacobian.set(3, 1, 1, 0)
            jacobian.set(3, 1, 2, 0)
            jacobian.set(3, 2, 0, 0)
            jacobian.set(3, 2, 1, 0)
            jacobian.set(3, 2, 2, 0)

            jacobian.set(4, 0, 0, 0)
            jacobian.set(4, 0, 1, 0)
            jacobian.set(4, 0, 2, 0)
            jacobian.set(4, 1, 0, 0)
            jacobian.set(4, 1, 1, 0)
            jacobian.set(4, 1, 2, 0)
            jacobian.set(4, 2, 0, 0)
            jacobian.set(4, 2, 1, 0)
            jacobian.set(4, 2, 2, 0)

            jacobian.set(5, 0, 0, 0)
            jacobian.set(5, 0, 1, 0)
            jacobian.set(5, 0, 2, 0)
            jacobian.set(5, 1, 0, 0)
            jacobian.set(5, 1, 1, 0)
            jacobian.set(5, 1, 2, 0)
            jacobian.set(5, 2, 0, 0)
            jacobian.set(5, 2, 1, 0)
            jacobian.set(5, 2, 2, 0)

            // a
            jacobianInv.set(3, 0, 0, 0)
            jacobianInv.set(3, 1, 0, 0)
            jacobianInv.set(3, 2, 0, 0)
            jacobianInv.set(3, 0, 1, 0)
            jacobianInv.set(3, 1, 1, 0)
            jacobianInv.set(3, 2, 1, 0)
            jacobianInv.set(3, 0, 2, 0)
            jacobianInv.set(3, 1, 2, 0)
            jacobianInv.set(3, 2, 2, 0)
            jacobianInv.set(3, 0, 3, 0)
            jacobianInv.set(3, 1, 3, 0)
            jacobianInv.set(3, 2, 3, 0)

            // b
            jacobianInv.set(4, 0, 0, 0)
            jacobianInv.set(4, 1, 0, 0)
            jacobianInv.set(4, 2, 0, 0)
            jacobianInv.set(4, 0, 1, 0)
            jacobianInv.set(4, 1, 1, 0)
            jacobianInv.set(4, 2, 1, 0)
            jacobianInv.set(4, 0, 2, 0)
            jacobianInv.set(4, 1, 2, 0)
            jacobianInv.set(4, 2, 2, 0)
            jacobianInv.set(4, 0, 3, 0)
            jacobianInv.set(4, 1, 3, 0)
            jacobianInv.set(4, 2, 3, 0)

            // c
            jacobianInv.set(5, 0, 0, 0)
            jacobianInv.set(5, 1, 0, 0)
            jacobianInv.set(5, 2, 0, 0)
            jacobianInv.set(5, 0, 1, 0)
            jacobianInv.set(5, 1, 1, 0)
            jacobianInv.set(5, 2, 1, 0)
            jacobianInv.set(5, 0, 2, 0)
            jacobianInv.set(5, 1, 2, 0)
            jacobianInv.set(5, 2, 2, 0)
            jacobianInv.set(5, 0, 3, 0)
            jacobianInv.set(5, 1, 3, 0)
            jacobianInv.set(5, 2, 3, 0)
        }
    }

    save(): number[] {
        return [this.tx, this.ty, this.tz, this.qx, this.qy, this.qz, this.qw]
    }

    restore(data: number[]): void {
        this.tx = data[0]
        this.ty = data[1]
        this.tz = data[2]
        this.qx = data[3]
        this.qy = data[4]
        this.qz = data[5]
        this.qw = data[6]
        this.updateMatrixAndJacobian()
    }
}

/** Represents 2D position and orientation as a 2-vector and a rotation angle. (The output matrix is 4x4, with the 2D rotation taking place around the Z axis)
 */
export class TransformParameters2D implements ILinearOperatorParameterBlock {
    readonly size: number = 3

    public tx = 0
    public ty = 0
    public r = 0

    public tx_free = false
    public ty_free = false
    public r_free = false

    readonly matrix = zeros(4, 4) // Output transform matrix
    readonly jacobian = zeros(3, 4, 4) // Jacobian of the transform matrix wrt. parameters
    readonly matrixInv = zeros(4, 4) // Inverse of the transform matrix
    readonly jacobianInv = zeros(3, 4, 4) // Jacobian of the inverse transform matrix wrt. parameters

    constructor(public flipXZ: boolean) {
        this.updateMatrixAndJacobian()
    }

    hasFreeParams() {
        return this.tx_free || this.ty_free || this.r_free
    }

    updateWithDelta(delta: Vector) {
        const tx_free = this.tx_free
        const ty_free = this.ty_free
        const r_free = this.r_free

        if (tx_free) this.tx += delta.get(0)
        if (ty_free) this.ty += delta.get(1)
        if (r_free) this.r += delta.get(2)

        if (tx_free || ty_free || r_free) {
            this.updateMatrixAndJacobian()
        }
    }

    public updateMatrixAndJacobian() {
        const matrix = this.matrix
        const jacobian = this.jacobian
        const flip = this.flipXZ ? -1 : 1

        const tx_free = this.tx_free
        const ty_free = this.ty_free
        const r_free = this.r_free

        const a = Math.cos(this.r)
        const b = Math.sin(this.r)

        const m00 = a * flip
        const m01 = -b * flip
        const m10 = b
        const m11 = a

        matrix.set(0, 0, m00)
        matrix.set(0, 1, m01)
        matrix.set(0, 3, this.tx)
        matrix.set(1, 0, m10)
        matrix.set(1, 1, m11)
        matrix.set(1, 3, this.ty)
        matrix.set(2, 2, flip)
        matrix.set(3, 3, 1)

        this.matrixInv.set(0, 0, m00)
        this.matrixInv.set(0, 1, m10)
        this.matrixInv.set(0, 3, -(this.tx * m00 + this.ty * m10))
        this.matrixInv.set(1, 0, m01)
        this.matrixInv.set(1, 1, m11)
        this.matrixInv.set(1, 3, -(this.tx * m01 + this.ty * m11))
        this.matrixInv.set(2, 2, flip)
        this.matrixInv.set(3, 3, 1)

        const da_dr = -b
        const db_dr = a
        jacobian.set(0, 0, 3, tx_free ? 1 : 0) // tx
        jacobian.set(1, 1, 3, ty_free ? 1 : 0) // ty
        jacobian.set(2, 0, 0, r_free ? da_dr * flip : 0) // a
        jacobian.set(2, 0, 1, r_free ? -db_dr * flip : 0) // -b
        jacobian.set(2, 1, 0, r_free ? db_dr : 0) // b
        jacobian.set(2, 1, 1, r_free ? da_dr : 0) // a

        this.jacobianInv.set(0, 0, 3, tx_free ? -m00 : 0) // tx
        this.jacobianInv.set(0, 1, 3, tx_free ? -m01 : 0) // tx
        this.jacobianInv.set(1, 0, 3, ty_free ? -m10 : 0) // ty
        this.jacobianInv.set(1, 1, 3, ty_free ? -m11 : 0) // ty
        this.jacobianInv.set(2, 0, 0, r_free ? da_dr * flip : 0) // a
        this.jacobianInv.set(2, 0, 1, r_free ? db_dr : 0) // b
        this.jacobianInv.set(2, 1, 0, r_free ? -db_dr * flip : 0) // -b
        this.jacobianInv.set(2, 1, 1, r_free ? da_dr : 0) // a
    }

    save(): number[] {
        return [this.tx, this.ty, this.r]
    }

    restore(data: number[]): void {
        this.tx = data[0]
        this.ty = data[1]
        this.r = data[2]
        this.updateMatrixAndJacobian()
    }
}
