// @ts-strict-ignore
import {DataObject} from "@legacy/api-model/data-object"
import {from, map, Observable, of as observableOf, switchMap} from "rxjs"
import {MeshData, MeshDataGraph} from "@cm/lib/geometry-processing/mesh-data"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {decompressMesh, evaluateProceduralGeometry, WebAssemblyWorkerService} from "@app/editor/helpers/mesh-processing/mesh-processing"
import {CmmMesh} from "@common/models/cmm/cmm-mesh"
import {RenderNodes} from "@cm/lib/rendering/render-nodes"
import {MeshLoadSettings} from "@cm/lib/templates/interfaces/scene-manager"
import {MeshDataBatchApiCallService} from "@app/templates/template-system/mesh-data-batch-api-call.service"
import {APIService} from "@legacy/services/api/api.service"

export class MeshDataCache {
    private cmmDataObjectCache = new Map<number, Uint8Array>()
    private cache: AsyncCacheMap<string, MeshData, [number, number, MeshLoadSettings]>

    constructor(
        private workerService: WebAssemblyWorkerService,
        private meshDataBatchApiCallService: MeshDataBatchApiCallService,
        private legacyApi: APIService,
    ) {
        this.cache = new AsyncCacheMap((key, [id, plyId, settings]) => {
            const cmmData = this.cmmDataObjectCache.get(id)
            let dataObjData: Observable<Uint8Array>
            if (cmmData) {
                dataObjData = observableOf(cmmData)
            } else {
                dataObjData = from(meshDataBatchApiCallService.fetch({legacyId: id})).pipe(
                    switchMap((dataObject) => legacyApi.downloadFile(dataObject.downloadUrl, "colormass-dataobject-cache")),
                )
            }
            return dataObjData.pipe(
                switchMap((buffer) => {
                    let graph: MeshDataGraph = {
                        type: "loadMesh",
                        data: {
                            type: "dataObjectReference",
                            dataObjectId: plyId,
                        },
                    }
                    // only add subdivision node if we're actually subdividing
                    if (settings.renderSubdivisionLevel > 0) {
                        graph = {
                            type: "subdivide",
                            levels: settings.renderSubdivisionLevel,
                            input: graph,
                        }
                    }
                    return decompressMesh(workerService, graph, buffer.buffer.slice(0), settings.displaySubdivisionLevel)
                }),
            )
        })
    }

    getMeshData(dataObject: DataObject, plyDataObjectId: number, settings: MeshLoadSettings): Observable<MeshData> {
        const key = `${dataObject.id},${settings.displaySubdivisionLevel},${settings.renderSubdivisionLevel}`
        return this.cache.get(key, [dataObject.id, plyDataObjectId, settings])
    }

    invalidate() {
        this.cache.clear()
        this.cmmDataObjectCache.clear()
    }

    populateWithCmmMesh(cmmMesh: CmmMesh) {
        cmmMesh.dataObjectIds.forEach((dataObjectId, format) => {
            this.cmmDataObjectCache.set(dataObjectId, new Uint8Array(cmmMesh.data.get(format)))
        })
    }

    getProceduralMeshData(graph: RenderNodes.MeshData): Observable<MeshData> {
        return evaluateProceduralGeometry(this.workerService, graph).pipe(
            map((meshData) => {
                meshData.graph = graph
                return meshData
            }),
        )
    }
}
