import {Component, EventEmitter, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"

@Component({
    selector: "cm-dialog-rename",
    templateUrl: "./dialog-rename.component.html",
    styleUrls: ["./dialog-rename.component.scss"],
    standalone: true,
    imports: [MatInputModule, MatDialogContent, FormsModule, MatButtonModule, MatDialogActions, MatDialogTitle],
})
export class DialogRenameComponent {
    title: string
    onConfirm = new EventEmitter()

    constructor(
        public dialogRef: MatDialogRef<DialogRenameComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Record<string, string>,
    ) {
        this.title = data.title
    }

    confirm() {
        this.onConfirm.emit(this.title)
        this.dialogRef.close(false)
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }
}
