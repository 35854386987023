import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {SolverObjectData} from "@src/templates/runtime-graph/nodes/solver/object-data"
import {SolverData} from "@src/templates/runtime-graph/nodes/solver/data"
import {TransformAccessorListEntry} from "@src/templates/runtime-graph/nodes/compile-template"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {IMatrix4} from "@src/templates/interfaces/matrix"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {InterfaceDescriptor} from "@src/templates/interface-descriptors"
import {TemplateNode} from "@src/templates/types"
import {EvaluatedTemplateOutputs} from "@src/templates/types"

const TD = TypeDescriptors

const runTemplateNewDescriptor = {
    compiledTemplate: TD.inlet(TD.Function<(instanceToBind: any) => () => void>()),
    matrix: TD.inlet(TD.Nullable(TD.Matrix4)),
    solverObject: TD.inlet(TD.Nullable(TD.Identity<SolverObjectData>())),

    ready: TD.outlet(TD.Boolean),
    preDisplayList: TD.outlet(TD.Array(TD.Identity<SceneNodes.SceneNode>())),
    displayList: TD.outlet(TD.Array(TD.Identity<SceneNodes.SceneNode>())),
    descriptorList: TD.outlet(TD.Array(TD.Identity<InterfaceDescriptor<unknown>>())),
    solverData: TD.outlet(TD.Identity<SolverData>()),
    outputs: TD.outlet(TD.Identity<EvaluatedTemplateOutputs>()),
    objectData: TD.outlet(TD.Identity<ObjectData>()),
    transformAccessorList: TD.outlet(TD.Array(TD.Identity<TransformAccessorListEntry>())),
    lookupByExternalIdPath: TD.outlet(TD.Function<(x: string[]) => TemplateNode | null>()),
}

export class RunTemplateNew implements NodeClassImpl<typeof runTemplateNewDescriptor, typeof RunTemplateNew> {
    static descriptor = runTemplateNewDescriptor
    static uniqueName = "RunTemplateNew"
    compiledTemplate!: Inlet<(instanceToBind: any) => () => void>
    matrix!: Inlet<IMatrix4 | null>
    solverObject!: Inlet<SolverObjectData | null>
    ready!: Outlet<boolean>
    preDisplayList!: Outlet<SceneNodes.SceneNode[]>
    displayList!: Outlet<SceneNodes.SceneNode[]>
    descriptorList!: Outlet<InterfaceDescriptor<unknown>[]>
    solverData!: Outlet<SolverData>
    outputs!: Outlet<EvaluatedTemplateOutputs>
    objectData!: Outlet<ObjectData>
    transformAccessorList!: Outlet<TransformAccessorListEntry[]>
    lookupByExternalIdPath!: Outlet<(x: string[]) => TemplateNode | null>

    private _compiledTemplate: ((instanceToBind: any) => () => void) | null = null
    private _runCompiledTemplate: (() => void) | null = null

    run() {
        if (this.compiledTemplate === NotReady) return
        if (this.compiledTemplate !== this._compiledTemplate) {
            this._compiledTemplate = this.compiledTemplate
            if (this.compiledTemplate) {
                this._runCompiledTemplate = this.compiledTemplate(this)
            } else {
                this._runCompiledTemplate = null
            }
        }
        if (this._runCompiledTemplate) {
            this._runCompiledTemplate()
        }
    }
}
