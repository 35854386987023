import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpConvolve} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-convolve"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const ConvolveNode = declareImageOpNode(imageOpConvolve)

export function convolve(parameters: GetParameters<Context, ParameterType>) {
    return new ConvolveNode(parameters)
}
