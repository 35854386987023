<div class="cm-material-grid">
    @for (materialAssignment of materialAssignments(); track materialAssignment[0]) {
        <cm-value-slot
            [node]="node()"
            [key]="materialAssignment[0]"
            [label]="getSlotName(materialAssignment[0])"
            (mouseenter)="onMouseEnter(materialAssignment[0])"
            (mouseleave)="onMouseLeave()"
            (requestUpdate)="onRequestUpdate(materialAssignment[0], $event)"
        ></cm-value-slot>
    }
</div>
