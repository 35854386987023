// @ts-strict-ignore
import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core"

@Component({
    standalone: true,
    selector: "cm-numeric-input",
    templateUrl: "./numeric-input.component.html",
    styleUrls: ["./numeric-input.component.scss"],
})
export class NumericInputComponent implements OnInit {
    @Input() value: number
    @Input() decimalPlaces?: number
    @Input() type: "int" | "float" = "float"
    @Input() emptyValue: null | undefined | number | "disallow" = "disallow"
    @Input() convert?: {toDisplay: (x: number) => number; fromDisplay: (x: number) => number}
    @Output() valueChange = new EventEmitter<number>()
    @Input() placeholder?: string
    @Input() readonly = false
    @Input() isButton = false

    private editing = false
    private editString: string | null = null

    constructor() {}

    @Input() set optional(x: boolean) {
        this.emptyValue = x ? undefined : "disallow"
    }

    set displayString(valueStr: string) {
        if (this.editing) {
            this.editString = valueStr
        }
        if (valueStr == null) {
            if (this.emptyValue === null) {
                this.valueChange.emit(null)
            } else if (this.emptyValue === undefined) {
                this.valueChange.emit(undefined)
            } else if (typeof this.emptyValue === "number") {
                this.valueChange.emit(this.emptyValue)
            } else {
                return // not allowed
            }
        }
        let value = this.type === "int" ? parseInt(valueStr) : parseFloat(valueStr)
        if (value == null || isNaN(value)) return
        const convert = this.convert
        if (convert) value = convert.fromDisplay(value)
        if (value == null || isNaN(value)) return
        this.valueChange.emit(value)
    }

    get displayString(): string {
        if (this.editing) {
            return this.editString
        }
        let value = this.value
        const convert = this.convert
        const places = this.type === "int" ? 0 : this.decimalPlaces
        if (convert) value = convert.toDisplay(value)
        if (this.value == null) return null
        if (places != null) {
            return Number(value).toFixed(places)
        } else {
            return value.toString()
        }
    }

    onFocus() {
        this.editing = false
        this.editString = this.displayString
        this.editing = true
    }

    onBlur() {
        this.editing = false
        this.editString = null
    }

    ngOnInit(): void {}
}
