import {Component, computed, input} from "@angular/core"
import {GetJobDetailsForTemplateImageViewerQuery} from "@api"
import {JobData, isJobError, isJobPending} from "@app/template-editor/helpers/render-jobs"
import {LoadingSpinnerIconComponent} from "../../../common/components/progress/loading-spinner-icon/loading-spinner-icon.component"
import {MatTooltipModule} from "@angular/material/tooltip"

@Component({
    selector: "cm-template-job-icon",
    standalone: true,
    imports: [LoadingSpinnerIconComponent, MatTooltipModule],
    templateUrl: "./template-job-icon.component.html",
    styleUrl: "./template-job-icon.component.scss",
})
export class TemplateJobIconComponent {
    job = input.required<GetJobDetailsForTemplateImageViewerQuery["job"]>()
    iconClass = input.required<string>()

    tooltip = computed(() => {
        const job = this.job()
        if (isJobPending(job)) return `Job ${job.id} - ${job.name} - ${job.state} - ${job.progress ?? "?"}%`
        else return `Job ${job.id} - ${job.name} - ${job.state}`
    })

    isJobPending = isJobPending
    isJobError = isJobError
    isJobComplete = (job: JobData) => !isJobPending(job) && !isJobError(job)
}
