import {Catalog, CatalogDiff, CatalogItem, CatalogPrice, GetSubgraphCallback} from "@src/pricing/catalogs/catalog-interface"
import {Currency, PricingContext} from "@src/pricing/nodes/core"
import {PricedItemNode} from "@src/pricing/nodes/priced-item-node"
import {PriceList} from "@src/pricing/nodes/price-list"
import {PriceContainer} from "@src/pricing/nodes/price-container"
import {XMLParser} from "fast-xml-parser"
import {VariantConditionNode} from "@src/pricing/nodes/variant-condition-node"
import {ConfigurationList} from "@src/pricing/nodes/configuration-list"

export class DarranCatalog implements Catalog {
    items: DarranCatalogItem[]
    optionGroups: GroupMap

    constructor(items: DarranCatalogItem[], groupMap: GroupMap) {
        this.items = items
        this.optionGroups = groupMap

        this.resolveSubOptions()
        this.resolveOptions()
    }

    getAllPricesAsContext(): PricingContext {
        const priceMap = new Map<string, number>()

        this.items.forEach((item) => priceMap.set(item.uniqueId, item.price))

        this.optionGroups.forEach((group) => {
            group.options.forEach((option) => {
                priceMap.set(option.uniqueId, option.price)
            })
        })

        return new PricingContext(priceMap, Currency.Usd)
    }

    getAllPrices(): CatalogPrice[] {
        const result: CatalogPrice[] = []

        this.items.forEach((item) => result.push({uniqueId: item.uniqueId, price: item.price, currency: Currency.Usd}))

        this.optionGroups.forEach((group) => {
            group.options.forEach((option) => {
                result.push({uniqueId: option.uniqueId, price: option.price, currency: Currency.Usd})
            })
        })

        return result
    }

    search(query: string): CatalogItem[] {
        const lowerCaseQuery = query.toLowerCase()
        return this.items.filter((item) => item.pn.toLowerCase().includes(lowerCaseQuery) || item.longDescription.toLowerCase().includes(lowerCaseQuery))
    }

    resolveSubOptions(): void {
        this.optionGroups.forEach((group) => {
            group.options.forEach((option) => {
                if (option.subgroup_id !== undefined) {
                    const subGroup = this.optionGroups.get(option.subgroup_id)
                    if (subGroup === undefined) throw new Error(`Group ${group.id} has invalid subgroup id ${option.subgroup_id} in option ${option.id}`)
                    option.subOptions = subGroup
                }
            })
        })
    }

    resolveOptions(): void {
        this.items.forEach((item) => {
            item.optionGroupIds.forEach((groupId) => {
                const group = this.optionGroups.get(groupId)
                if (group === undefined) throw new Error(`Item ${item.id} has invalid group id ${groupId}`)
                if (item.optionGroups === undefined) item.optionGroups = []
                item.optionGroups.push(group)
            })
        })
    }

    detectConflicts(newCatalog: DarranCatalog): Map<string, string> {
        const errors = new Map<string, string>()

        this.items.forEach((item) => {
            const itemErrors: string[] = []
            const newItem = newCatalog.items.find((ni) => ni.uniqueId === item.uniqueId)

            if (newItem) {
                item.optionGroups?.forEach((group) => {
                    if (!newItem?.optionGroups?.some((ng) => ng.uniqueId === group.uniqueId)) {
                        itemErrors.push(`Option group '${group.name}' removed`)
                    } else {
                        const newGroup = newItem.optionGroups.find((ng) => ng.uniqueId === group.uniqueId)
                        group.options.forEach((option) => {
                            if (!newGroup?.options.some((no) => no.uniqueId === option.uniqueId))
                                itemErrors.push(`Option '${option.name}' removed from group '${group.uniqueId}'`)
                        })
                    }
                })

                item.optionGroups?.forEach((group) => {
                    group.options.forEach((option) => {
                        if (option.subOptions && option.price === 0) {
                            const newOption = this.findNewOption(newItem, option.uniqueId)

                            /*The following check covers a catalog inconsistency: Sometimes, the sub-option acts as container for the target suboptions. In this case, the price is 0 and
                            it is not possible to assign a trigger to the option. The easiest way to solve this is to delete the node from the price graph. In an update
                            of the catalog, it could be that a price is increased. Since the node is deleted, this price update is not captured. This is very unlikely.
                            Note that in other cases, the sub-option actually HAS a price and in this case it is also possible to add a trigger.*/
                            if (newOption && newOption.price !== 0)
                                itemErrors.push(`Price change detected for option '${option.name}', which could have been deleted from price graph.`)
                        }
                    })
                })
            }

            if (itemErrors.length > 0) errors.set(item.uniqueId, itemErrors.join(", "))
        })

        return errors
    }

    private findNewOption(newItem: DarranCatalogItem, optionUniqueId: string): DarranCatalogOption | undefined {
        for (const group of newItem.optionGroups || []) {
            const option = group.options.find((option) => option.uniqueId === optionUniqueId)
            if (option) return option
        }

        return undefined
    }

    diff(newCatalog: Catalog): CatalogDiff {
        const oldPrices = new Map<string, CatalogPrice>()
        const newPrices = new Map<string, CatalogPrice>()
        this.getAllPrices().forEach((price) => oldPrices.set(price.uniqueId, price))
        newCatalog.getAllPrices().forEach((price) => newPrices.set(price.uniqueId, price))

        const deletedEntries = new Set<CatalogPrice>()
        const updatedEntries = new Set<CatalogPrice>()
        const newEntries = new Set<CatalogPrice>()

        oldPrices.forEach((value, key) => {
            if (!newPrices.has(key)) deletedEntries.add(value)
            else if (newPrices.get(key)?.price !== value.price) updatedEntries.add(newPrices.get(key) as CatalogPrice)
        })

        newPrices.forEach((value, key) => {
            if (!oldPrices.has(key)) newEntries.add(value)
        })

        return {
            deletedEntries,
            updatedEntries,
            newEntries,
            conflicts: this.detectConflicts(newCatalog as DarranCatalog),
        }
    }
}

export class DarranCatalogItem implements CatalogItem {
    constructor(
        public id: string,
        public pn: string,
        public longDescription: string,
        public price: number,
        public optionGroupIds: string[],
        public optionGroups?: DarranCatalogOptionGroup[],
    ) {}

    get uniqueId() {
        return this.pn
    }

    get description() {
        return this.longDescription
    }

    get children() {
        return this.optionGroups
    }

    createPriceGraph(getSubgraph: GetSubgraphCallback): PricedItemNode {
        if (getSubgraph(this.uniqueId) !== undefined) throw new Error(`SKU ${this.description} already added`)

        let subprices: PriceList | undefined = undefined
        if (this.optionGroups !== undefined) {
            subprices = new PriceList({list: []})
            this.optionGroups.forEach((child) => subprices!.addEntry(child.createPriceGraph(getSubgraph)))
        }

        return new PricedItemNode({
            description: this.longDescription,
            uniqueId: this.uniqueId,
            sku: this.uniqueId,
            condition: new VariantConditionNode({
                condition: {variantIds: [], variantOperator: "AND", negated: false},
                currentConfigurations: new ConfigurationList({list: []}),
            }),
            subPrices: subprices,
            dependsOnSubprice: false,
        })
    }
}

export class DarranCatalogOption {
    constructor(
        public id: string,
        public name: string,
        public groupName: string,
        public description: string,
        public price: number,
        public subgroup_id?: string,
        public subOptions?: DarranCatalogOptionGroup,
    ) {}

    get uniqueId() {
        return this.groupName + this.name
    }
}

export class DarranCatalogOptionGroup {
    constructor(
        public id: string,
        public name: string,
        public description: string,
        public options: DarranCatalogOption[],
    ) {}

    //the "name" field extracted from the xml corresponds to the unique id of their excel sheet
    get uniqueId() {
        return this.name
    }

    createPriceGraph(getSubgraph: GetSubgraphCallback): PriceContainer {
        if (this.options.length === 0) throw new Error(`Option group ${this.description} has no options`)

        const existingSubgraph = getSubgraph(this.uniqueId)
        if (existingSubgraph !== undefined) {
            if (!(existingSubgraph instanceof PriceContainer)) throw new Error(`Price container expected for option group ${this.description}`)
            return existingSubgraph
        }

        const subpricesOfOptionGroup = new PriceList({list: []})
        const result = new PriceContainer({description: this.description, uniqueId: this.uniqueId, subPrices: subpricesOfOptionGroup})

        this.options.forEach((option) => {
            let subpricesOfOption: PriceList | undefined = undefined
            if (option.subOptions !== undefined) {
                subpricesOfOption = new PriceList({list: []})
                subpricesOfOption.addEntry(option.subOptions.createPriceGraph(getSubgraph))
            }
            subpricesOfOptionGroup.addEntry(
                new PricedItemNode({
                    description: option.description,
                    uniqueId: option.uniqueId,
                    condition: new VariantConditionNode({
                        condition: {variantIds: [], variantOperator: "AND", negated: false},
                        currentConfigurations: new ConfigurationList({list: []}),
                    }),
                    subPrices: subpricesOfOption,
                    sku: undefined,
                    dependsOnSubprice: false,
                }),
            )
        })

        return result
    }
}

type GroupMap = Map<string, DarranCatalogOptionGroup>

export function parseXml(xml: string): Promise<Catalog> {
    return new Promise((resolve, reject) => {
        const options = {
            ignoreAttributes: false,
            trimValues: false,
            isArray: (tagName: string) => tagName === "PRICE" || tagName === "ITEM_GROUP" || tagName === "OPTION" || tagName === "GROUP" || tagName === "ITEM",
        }

        try {
            const parser = new XMLParser(options)
            const result = parser.parse(xml)
            const items: DarranCatalogItem[] = processItems(result.CATALOG.ITEMS.ITEM)
            const groups: DarranCatalogOptionGroup[] = processGroups(result.CATALOG.GROUPS.GROUP)
            const groupMap = new Map(groups.map((obj) => [obj.id, obj]))

            if (groups.length !== groupMap.size) throw new Error("Duplicate group ids")

            verifyData(items, groupMap)

            const catalog = new DarranCatalog(items, groupMap)
            resolve(catalog)
        } catch (error) {
            reject(error)
        }
    })
}

function processItems(itemsXml: any[]): DarranCatalogItem[] {
    return itemsXml.map((itemXml) => {
        const id = itemXml["@_id"]
        const pn = itemXml["@_pn"]
        const description = itemXml.LONG_DESC.TEXT["#text"]
        const prices = itemXml.PRICES.PRICE.map((p: any) => parseFloat(p["#text"]))

        if (prices.length !== 1) throw new Error(`Item with id ${id} has multiple prices`)
        if (id === undefined) throw new Error("Item has no id")
        if (pn === undefined) throw new Error("Item has no pn")

        let itemGroups: string[] = []
        if (itemXml.ITEM_GROUPS.ITEM_GROUP !== undefined) itemGroups = itemXml.ITEM_GROUPS.ITEM_GROUP.map((g: any) => g["@_id"])

        return new DarranCatalogItem(id, pn, description, prices[0], itemGroups)
    })
}

function processGroups(groupsXml: any[]): DarranCatalogOptionGroup[] {
    return groupsXml.map((groupXml) => {
        const id = groupXml["@_id"]
        const name = groupXml["@_name"]
        const description = groupXml.DESCRIPTIONS.TEXT["#text"]
        const prices = groupXml.PRICES

        if (id === undefined) throw new Error("Group has no id")
        if (name === undefined) throw new Error("Group has no name")
        if (prices !== undefined) throw new Error(`Group with id ${id} has prices, adapt DarranCatalogOption.children`)

        let options: DarranCatalogOption[] = []
        if (groupXml.OPTIONS.OPTION !== undefined) options = processOptions(groupXml.OPTIONS.OPTION, name)
        return new DarranCatalogOptionGroup(id, name, description, options)
    })
}

function processOptions(optionsXml: any[], groupName: string): DarranCatalogOption[] {
    return optionsXml.map((optionXml) => {
        const id = optionXml["@_id"]
        const name = optionXml["@_name"]
        const description = optionXml.DESCRIPTIONS.TEXT["#text"]
        const subgroup_id = optionXml["@_subgroup_id"]

        if (id === undefined) throw new Error("Option has no id")
        if (name === undefined) throw new Error("Option has no name")

        const prices = optionXml.PRICES.PRICE.map((p: any) => parseFloat(p["#text"]))
        if (prices.length !== 1) throw new Error(`Option with id ${id} has multiple or no prices`)

        return new DarranCatalogOption(id, name, groupName, description, prices[0], subgroup_id)
    })
}

function verifyData(items: DarranCatalogItem[], groupMap: GroupMap) {
    items.forEach((item) => {
        item.optionGroupIds.forEach((groupId) => {
            if (!groupMap.has(groupId)) throw new Error(`Item ${item.id} has invalid group id ${groupId}`)
        })
    })

    groupMap.forEach((group) => {
        group.options.forEach((option) => {
            if (option.subgroup_id !== undefined && !groupMap.has(option.subgroup_id))
                throw new Error(`Group ${group.id} has invalid subgroup id ${option.subgroup_id} in option ${option.id}`)
        })
    })

    const itemPnSet = new Set(items.map((item) => item.uniqueId))
    if (itemPnSet.size !== items.length) throw new Error("Duplicate PN")

    const optionGroupNameSet = new Set(Array.from(groupMap.values()).map((value) => value.uniqueId))
    if (optionGroupNameSet.size !== groupMap.size) throw new Error("Duplicate option group names")

    const optionUniqueIdSet = new Set()
    groupMap.forEach((group) => {
        group.options.forEach((option) => {
            if (optionUniqueIdSet.has(option.uniqueId)) throw new Error(`Duplicate option uniqueId ${option.uniqueId}`)
            optionUniqueIdSet.add(option.uniqueId)
        })
    })
}
