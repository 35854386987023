import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpCreateImage} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-create-image"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const CreateImageNode = declareImageOpNode(imageOpCreateImage)

export function createImage(parameters: GetParameters<Context, ParameterType>) {
    return new CreateImageNode(parameters)
}
