// @ts-strict-ignore
import {HttpParams} from "@angular/common/http"
import {DataObject} from "@legacy/api-model/data-object"
import {ApiFields, declareApiModel, EndpointUrls, MakeApiModelBase} from "@legacy/api-model/api-model"
import {EntityType} from "@legacy/models/entity-type"

@declareApiModel(EndpointUrls.dataObjectAssignmentsUrl, EntityType.DataObjectAssignment)
export class DataObjectAssignment extends MakeApiModelBase<DataObjectAssignment>() {
    @ApiFields.enum({name: "type"}) type: DataObjectAssignmentType
    @ApiFields.id({name: "object_id"}) objectId: number
    @ApiFields.entityType({name: "content_type"}) objectEntityType: EntityType
    @ApiFields.singleReference({name: "data_object", model: DataObject}) dataObject: DataObject
    @ApiFields.string({name: "assignment_key"}) assignmentKey: string

    isCachedType(): boolean {
        switch (this.type) {
            case DataObjectAssignmentType.CachedTemplateGLTF:
            case DataObjectAssignmentType.CachedTemplateUSDZ:
                return true
            default:
                return false
        }
    }

    static getAllAssignedTo(entity: any, types?: DataObjectAssignmentType[]) {
        let filters: HttpParams = new HttpParams()
        filters = filters.set("content_type", entity.entityType)
        filters = filters.set("object_id", entity.id)
        // filters = filters.set("assignment_key", assignmentKey);
        if (types) {
            filters = filters.set("type", types.join(","))
        }
        return DataObjectAssignment.getAll(filters)
    }
}

export enum DataObjectAssignmentType {
    Attachment = 10,
    GalleryImage = 20,
    CustomerLogo = 30,
    PictureData = 40,
    PictureDataSecondary = 41,
    PictureDataTertiary = 42,
    PictureDataLinear = 43,
    CryptoMatte01 = 44,
    CryptoMatte02 = 45,
    CryptoMatte03 = 46,
    ProfilePicture = 50,
    DrawingData = 60,
    ScanMergedFile = 70,
    ScanFittingWorkPackage = 80,
    ScanAssemblyWorkPackage = 90,
    ScanCalibrationData = 100,
    ScanLog = 110,
    MeshDataDRC = 120,
    MeshDataDRCProxy = 121,
    MeshDataPLY = 122,
    TemplateConfigIcon = 130,
    CachedTemplateGLTF = 140,
    CachedTemplateUSDZ = 141,
    Icon = 150,
    MaterialTileableRender = 160,
    MaterialThumbnail_10x10 = 161,
    MaterialThumbnail_20x20 = 162,
    MaterialThumbnail_30x30 = 163,
    MaterialThumbnail_7x7_Inches = 164,
    MaterialThumbnail_9x9_Inches = 165,
    MaterialThumbnail_16x16_Inches = 166,
    MaterialCETSimple = 170,
    MaterialMapsExport = 171,
    PdfTemplate = 180,
    JobTaskData = 190,
    JobTaskInternalData = 191,
    TilingGraph = 200,
}
