@if (node) {
    <ng-content select="[cm-content]"> </ng-content>
    <cm-inspector-section>
        <ng-container cm-title> Guide </ng-container>
        <ng-container cm-content>
            <div class="cm-guide-settings">
                @if (hasWidth(node)) {
                    <cm-input-container>
                        <ng-container cm-inline-label> Width: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="node.width" (valueChange)="editor.markNodeChanged(node)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <mat-slider [max]="500" [min]="1" class="cm-slider"
                        ><input matSliderThumb [(ngModel)]="node.width" (ngModelChange)="editor.markNodeChanged(node)" />
                    </mat-slider>
                }
                @if (hasHeight(node)) {
                    <cm-input-container>
                        <ng-container cm-inline-label> Height: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="node.height" (valueChange)="editor.markNodeChanged(node)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <mat-slider [max]="500" [min]="1" class="cm-slider"
                        ><input matSliderThumb [(ngModel)]="node.height" (ngModelChange)="editor.markNodeChanged(node)" />
                    </mat-slider>
                }
            </div>
        </ng-container>
    </cm-inspector-section>
}
