import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeConvert, threeValueNode} from "@src/materials/three-utils"
import {getAll} from "@src/graph-system/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class Fresnel extends DeclareMaterialNode(
    {
        returns: z.object({fac: materialSlots}),
        inputs: z.object({
            ior: materialSlots.optional(),
            fac: materialSlots.optional(),
        }),
        parameters: z.object({ior: z.number().optional(), fac: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {ior, fac} = await getAll(inputs, get)

            const iorValue = ior ?? threeConvert(parameters.ior, threeValueNode)
            const facValue = fac ?? threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(0)

            if (!iorValue) return {fac: facValue}

            const n1 = threeValueNode(1.0)
            const r0_sqrt = THREENodes.div(THREENodes.sub(n1, iorValue), THREENodes.add(n1, iorValue))
            const r0 = THREENodes.mul(r0_sqrt, r0_sqrt)
            const NoV = new THREENodes.SplitNode(THREENodes.normalView, "z")
            const oneMinusNoV = THREENodes.sub(threeValueNode(1.0), NoV)
            const oneMinusNoV_2 = THREENodes.mul(oneMinusNoV, oneMinusNoV)
            const oneMinusNoV_3 = THREENodes.mul(oneMinusNoV_2, oneMinusNoV)
            const oneMinusNoV_5 = THREENodes.mul(oneMinusNoV_2, oneMinusNoV_3)
            const tmp1 = THREENodes.mul(THREENodes.sub(n1, r0), oneMinusNoV_5)
            const value = THREENodes.add(r0, tmp1)

            return {fac: value}
        },
    },
) {}
