<cm-action-panel-row cmIconClass="far fa-user">
    <span matTooltip="{{ $createdAt() | amLocal | amDateFormat: 'D MMMM, YYYY HH:mm' }}" matTooltipPosition="above"
        >{{ $createdByName() }}
        @if ($createdAt()) {
            ({{ $createdAt() | amTimeAgo }})
        }
    </span>
</cm-action-panel-row>
@if ($updatedAt(); as date) {
    <cm-action-panel-row cmIconClass="far fa-pen">
        <span matTooltip="{{ date | amLocal | amDateFormat: 'D MMMM, YYYY HH:mm' }}" matTooltipPosition="above"
            >{{ $updatedByName() }} ({{ date | amTimeAgo }})
        </span>
    </cm-action-panel-row>
}
<cm-action-panel-row cmIconClass="far fa-image-polaroid">
    {{ $resultResolution().width }} px × {{ $resultResolution().height }} px
    @if ($hasSquarePixels()) {
        ({{ $uniformDpi() | number: "1.2-2" }} dpi)
    } @else {
        ([{{ $individualDpi().width | number: "1.2-2" }}, {{ $individualDpi().height | number: "1.2-2" }}] dpi)
    }
</cm-action-panel-row>
<cm-action-panel-row cmIconClass="far fa-ruler-combined">
    {{ $resultSize().width | number: "1.2-2" }} cm × {{ $resultSize().height | number: "1.2-2" }} cm
    @if ($displacement()) {
        &nbsp;x {{ $displacement() | number: "1.2-2" }} cm
    }
</cm-action-panel-row>
<cm-action-panel-row cmIconClass="far fa-palette">
    {{ Labels.ImageColorSpace.get($imageColorSpace())?.label ?? "Not set" }}
    @if ($resultFileSize(); as size) {
        ({{ size | filesize: {base: 2} }})
    }
</cm-action-panel-row>
