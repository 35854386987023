import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const phiDescriptor = {
    a: inletDescriptor<any>(),
    b: inletDescriptor<any>(),
    output: outletDescriptor<any>(),
}

export class Phi<T> implements NodeClassImpl<typeof phiDescriptor, typeof Phi> {
    static descriptor = phiDescriptor
    static uniqueName = "Phi"
    a!: Inlet<T>
    b!: Inlet<T>
    output!: Outlet<T>

    run() {
        if (this.a !== NotReady) {
            this.output.emitIfChanged(this.a)
        } else if (this.b !== NotReady) {
            this.output.emitIfChanged(this.b)
        } else {
            this.output.emitIfChanged(NotReady)
        }
    }
}
