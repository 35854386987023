import {NodeGraphResultBase} from "@src/graph-system/evaluators/node-graph-result-base"
import {NodeGraph, ParameterValue, isNodeGraphInstance} from "@src/graph-system/node-graph"

export class UnCachedNodeGraphResult<ReturnType, Context> extends NodeGraphResultBase<ReturnType, Context> {
    constructor(root: ParameterValue<ReturnType, Context>, context: Context, disableValidation: boolean = false) {
        super(root, context, disableValidation)
    }

    run() {
        const get = async <ReturnType>(value: ReturnType | NodeGraph<ReturnType, Context>): Promise<ReturnType> => {
            if (isNodeGraphInstance<ReturnType, Context>(value)) return this.evaluateGraph(value, get)
            return value
        }

        return get(this.root)
    }

    runSync() {
        const getSync = <ReturnType>(value: ReturnType | NodeGraph<ReturnType, Context>): ReturnType => {
            if (isNodeGraphInstance<ReturnType, Context>(value)) return this.evaluateGraphSync(value, getSync)
            return value
        }
        return getSync(this.root)
    }
}
