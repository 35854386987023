@if ($data()?.items?.length === 0) {
    <span>None</span>
} @else {
    <div class="cm-container">
        @for (item of $data()?.items; track item?.data?.id) {
            @if (item?.data; as assignment) {
                <div class="cm-row" @fadeInCard>
                    @if (assignment?.assignedTo) {
                        <a [href]="link(assignment)">
                            <div class="cm-assignment">
                                <div class="cm-thumbnail-container">
                                    @if (thumbnail(assignment); as thumbnailDataObjectId) {
                                        <cm-data-object-thumbnail
                                            [dataObjectId]="thumbnailDataObjectId"
                                            [resolution]="DownloadResolution.Tiny"
                                            width="64"
                                            height="64"
                                            alt="Thumbnail"
                                        ></cm-data-object-thumbnail>
                                    } @else {
                                        <i class="fa fa-image-slash"></i>
                                    }
                                </div>
                                <div class="cm-text-container">
                                    <strong>{{ name(assignment) ?? assignment.assignedTo.legacyId }}</strong>
                                    <span>{{ assignment.contentTypeModel }}</span>
                                </div>
                            </div>
                        </a>
                        <button
                            mat-icon-button
                            color="warn"
                            class="cm-remove-assignment-button"
                            (click)="deleteAssignment(assignment)"
                            matTooltip="Remove assignment"
                        >
                            <i class="fa-light fa-xmark"></i>
                        </button>
                    } @else {
                        <div class="cm-assignment cm-assignment--forbidden" [cmIsLoading]="$data()?.totalCount === undefined">
                            <div class="cm-thumbnail-container">
                                <i class="fa fa-eye-slash"></i>
                            </div>
                            <div class="cm-text-container">
                                <strong>Forbidden</strong>
                                <span>You cannot view this item</span>
                            </div>
                        </div>
                    }
                </div>
            }
        }
        <cm-paginator
            [page]="$paging().page"
            (pageChange)="setPage($event)"
            [pageSize]="$paging().pageSize"
            (pageSizeChange)="setPageSize($event)"
            [totalCount]="$data().totalCount"
            [pageSizeOptions]="[]"
        ></cm-paginator>
    </div>
}
