import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {vec3} from "@src/materials/types"
import {threeValueNode} from "@src/materials/three-utils"

export class Displacement extends DeclareMaterialNode(
    {
        returns: z.object({
            displacement: materialSlots,
        }),
        inputs: z.object({
            height: materialSlots.optional(),
            midlevel: materialSlots.optional(),
            scale: materialSlots.optional(),
            normal: materialSlots.optional(),
        }),
        parameters: z.object({
            displacement: vec3.optional(),
            midlevel: z.number().optional(),
            normal: vec3.optional(),
            scale: z.number().optional(),
            space: z.enum(["OBJECT", "WORLD"]).optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            console.warn("Displacement map node not implemented for THREE materials")
            return {
                displacement: threeValueNode(0),
            }
        },
    },
) {}
