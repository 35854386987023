import {Component} from "@angular/core"
import {AssetFilterInput, SelectAssetItemFragment} from "@api"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {SelectDialogComponent} from "@common/components/dialogs/select-dialog/select-dialog.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {ItemFiltersComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {BaseSelectComponent} from "@platform/components/base/base-select/base-select.component"

@Component({
    selector: "cm-select-asset",
    standalone: true,
    imports: [
        CardErrorComponent,
        EntityResponsiveSidebarComponent,
        ItemFiltersComponent,
        SearchComponent,
        InfiniteListComponent,
        CardPlaceholderComponent,
        EntityCardComponent,
        SelectDialogComponent,
    ],
    templateUrl: "./select-asset.component.html",
    styleUrl: "./select-asset.component.scss",
})
export class SelectAssetComponent extends BaseSelectComponent<SelectAssetItemFragment, AssetFilterInput> {
    $data = this.implementDataLoader(this.sdk.gql.selectAssetItems)
}
