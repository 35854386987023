import {Inlet, Outlet} from "@src/templates/runtime-graph/slots"
import {ValueTypeDescriptor, inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const valueClassCache = new Map<ValueTypeDescriptor<any> | undefined, any>() //TODO: figure out the correct type

export function makeValueNodeClass<T>(valueType: ValueTypeDescriptor<T> | undefined) {
    // look up an existing instance to avoid JIT cache pollution due to redundant class definitions for the same value type
    const existing = valueClassCache.get(valueType)
    if (existing) {
        return existing as typeof Value
    }

    const valueDescriptor = {
        input: inletDescriptor<any>(valueType),
        output: outletDescriptor<any>(valueType),
    }

    class Value<T> implements NodeClassImpl<typeof valueDescriptor, typeof Value> {
        static descriptor = valueDescriptor
        static uniqueName = "Value"
        input!: Inlet<T>
        output!: Outlet<T>

        run() {
            this.output.emitIfChanged(this.input)
        }
    }
    valueClassCache.set(valueType, Value)
    return Value
}
