import {MaterialSocket} from "@material-editor/models/material-socket"

export const EmissionOutputs: Record<string, MaterialSocket> = {
    emission: {paramType: "socket", id: "Emission", type: "output", valueType: "output", label: "Emission"},
}

export const EmissionInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
    strength: {paramType: "socket", id: "Strength", type: "input", valueType: "scalar", label: "Strength", defaultValue: 1, range: {min: 0, max: 1000000}},
}
