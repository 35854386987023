import {IdDetails} from "@src/api-gql/common"
import {z} from "zod"
import {hashObject} from "@src/utils/hashing"
import {ImageColorSpaceSchema, MediaTypeSchema, ThumbnailResolution} from "@src/api-gql/data-object"
import {extensionForContentType} from "@src/utils/content-types"
import {DataObjectDefinitions} from "@src/utils/data-object"

type ImageFormat = DataObjectDefinitions.ImageFormat
export type ImageResolution = ThumbnailResolution

export interface IMaterialNode {
    id: string
    name: string // node type
    textureRevision?: number | ITransientDataObject // this is the TextureRevision id!
    textureSetRevision?: {
        id: string
        width: number
        height: number
        mapAssignments: {
            textureType: string // TODO can't use GQL enum here because ts-lib doesn't know about it
            dataObjectLegacyId: number
        }[]
    }
    parameters: IMaterialParameter[]
}

export interface IMaterialParameter {
    name: string
    type?: string
    value?: string | number | number[] | boolean
}

export interface IMaterialConnection {
    source: string // node id
    sourceParameter: string
    destination: string // node id
    destinationParameter: string
}

const NodeTypeSchema = z.union([
    z.literal("UVMap"),
    z.literal("Mapping"),
    z.literal("ShaderNodeRGBCurve"),
    z.literal("ShaderNodeNormalMap"),
    z.literal("OutputMaterial"),
    z.literal("ShaderNodeMath"),
    z.literal("BsdfPrincipled"),
    z.literal("ShaderNodeBsdfVelvet"),
    z.literal("ShaderNodeFresnel"),
    z.literal("ShaderNodeRGB"),
    z.literal("ShaderNodeValue"),
    z.literal("ShaderNodeBsdfGlass"),
    z.literal("ShaderNodeGamma"),
    z.literal("ShaderNodeBrightContrast"),
    z.literal("ShaderNodeHueSaturation"),
    z.literal("ShaderNodeInvert"),
    z.literal("ShaderNodeDisplacement"),
    z.literal("ShaderNodeVectorDisplacement"),
    z.literal("ShaderNodeCombineXYZ"),
    z.literal("ShaderNodeCombineHSV"),
    z.literal("ShaderNodeCombineRGB"),
    z.literal("ShaderNodeSeparateXYZ"),
    z.literal("ShaderNodeSeparateHSV"),
    z.literal("ShaderNodeSeparateRGB"),
    z.literal("ShaderNodeVectorMath"),
    z.literal("ShaderNodeBlackbody"),
    z.literal("ShaderNodeWavelength"),
    z.literal("ShaderNodeTexChecker"),
    z.literal("ShaderNodeBump"),
    z.literal("ShaderNodeNormal"),
    z.literal("ShaderNodeRGBToBW"),
    z.literal("ShaderNodeMixRGB"),
    z.literal("ShaderNodeMixShader"),
    z.literal("ShaderNodeAddShader"),
    z.literal("ShaderNodeValToRGB"),
    z.literal("ShaderNodeLightFalloff"),
    z.literal("ShaderNodeTexVoronoi"),
    z.literal("ShaderNodeBsdfDiffuse"),
    z.literal("ShaderNodeEmission"),
    z.literal("ShaderNodeBsdfTranslucent"),
    z.literal("ShaderNodeBsdfTransparent"),
    z.literal("ShaderNodeNewGeometry"),
    z.literal("TexImage"),
    z.literal("ShaderNodeTextureSet"),
    z.literal("ShaderNodeSetTexture"),
    z.literal("ShaderNodeLightPath"),
    z.literal("ShaderNodeTexCoord"),
    z.literal("ShaderNodeTexGradient"),
    z.literal("ShaderNodeTexMagic"),
    z.literal("ShaderNodeTexMusgrave"),
    z.literal("ShaderNodeTexWave"),
    z.literal("ShaderNodeTexNoise"),
    z.literal("ShaderNodeSubsurfaceScattering"),
    z.literal("ShaderNodeAmbientOcclusion"),
    z.literal("ShaderNodeTangent"),
])

export type NodeType = z.infer<typeof NodeTypeSchema>

const GetOutputNodeTypeSchema = z.literal("GetOutput")
export type GetOutputNodeType = z.infer<typeof GetOutputNodeTypeSchema>

export function isValidNodeType(nodeType: string): nodeType is NodeType | GetOutputNodeType {
    return NodeTypeSchema.or(GetOutputNodeTypeSchema).safeParse(nodeType).success
}

export function isNodeOfType<T extends NodeType | GetOutputNodeType>(node: MaterialGraphNode, nodeType: T): node is MaterialGraphNode<T> {
    return node.nodeType === nodeType
}

export function assertNodeOfType<T extends NodeType | GetOutputNodeType>(
    node: MaterialGraphNode,
    nodeType: NodeType | GetOutputNodeType,
): MaterialGraphNode<T> {
    if (node.nodeType !== nodeType) throw Error(`Expected node type ${nodeType}, got ${node.nodeType}`)
    return node as MaterialGraphNode<T>
}

const ITransientDataObjectSchema = z.object({
    imageColorSpace: ImageColorSpaceSchema.optional(),
    toObjectURL: z.function(z.tuple([])).returns(z.string()),
})

export interface ITransientDataObject extends z.infer<typeof ITransientDataObjectSchema> {}

export function isTransientDataObject(x: any): x is ITransientDataObject {
    return x && typeof x === "object" && "toObjectURL" in x
}

export const DataObjectSchema = z.object({
    legacyId: z.number(),
    imageColorSpace: ImageColorSpaceSchema.optional()
        .nullable()
        .transform((val) => (val === null ? undefined : val)),
    mediaType: MediaTypeSchema,
    width: z
        .number()
        .optional()
        .nullable()
        .transform((val) => (val === null ? undefined : val)),
    height: z
        .number()
        .optional()
        .nullable()
        .transform((val) => (val === null ? undefined : val)),
    downloadUrl: z.string(),
})
export type DataObject = z.infer<typeof DataObjectSchema>

export type RelatedDataObjectKey = `jpg:${ImageResolution}`
export type RelatedDataObject = z.infer<typeof RelatedDataObjectSchema>

export const ImageResourceSchema = z
    .object({
        metadata: z.object({widthCm: z.number().optional(), heightCm: z.number().optional()}).optional(),
    })
    .and(
        z
            .object({mainDataObject: DataObjectSchema, relatedDataObjects: z.array(DataObjectSchema).optional()})
            .or(z.object({transientDataObject: ITransientDataObjectSchema})),
    )
export const RelatedDataObjectSchema = z.object({
    legacyId: z.number(),
    downloadUrl: z.string(),
    imageColorSpace: ImageColorSpaceSchema.optional()
        .nullable()
        .transform((val) => (val === null ? undefined : val)),
})

export type ImageResource = {
    metadata?: {widthCm?: number; heightCm?: number}
} & (
    | {
          mainDataObject: DataObject
          relatedDataObjects?: DataObject[]
      }
    | {
          transientDataObject: ITransientDataObject
      }
)
//export type ImageResource = z.infer<typeof ImageResourceSchema>

export type LoadTextureRevisionArgs = {
    type: "texture-revision"
    legacyId: number
}
export type LoadDataObjectArgs = {
    type: "data-object"
    legacyId: number
    width: number
    height: number
}
export type LoadImageResourceArgs = LoadTextureRevisionArgs | LoadDataObjectArgs
export type LoadImageResource = (args: LoadImageResourceArgs) => Promise<ImageResource>

export type WrappedMaterialGraphNode<T extends NodeType = NodeType> = {
    nodeType: GetOutputNodeType
    parameters: {name: string}
    inputs: {node: UnwrappedMaterialGraphNode<T>}
}

export type UnwrappedMaterialGraphNode<T extends NodeType = NodeType> = {
    nodeType: T
    inputs?: {[name: string]: WrappedMaterialGraphNode}
    parameters?: {[name: string]: any}
    imageResources?: ImageResource[]
}

// TODO this seems a bit verbose, make it more concise?
export type MaterialGraphNode<T extends NodeType | GetOutputNodeType = NodeType | GetOutputNodeType> = NodeType | GetOutputNodeType extends T
    ? UnwrappedMaterialGraphNode | WrappedMaterialGraphNode
    : NodeType extends T
      ? UnwrappedMaterialGraphNode
      : T extends NodeType
        ? UnwrappedMaterialGraphNode<T>
        : T extends GetOutputNodeType
          ? WrappedMaterialGraphNode
          : never

export type MaterialGraphRootNode = MaterialGraphNode<"OutputMaterial">

export interface IMaterialGraph {
    readonly uniqueId: string
    readonly rootNode: MaterialGraphRootNode
    readonly materialRevisionId: number
    readonly materialId: number
    readonly name: string
}

export function isMaterialGraph(x: any): x is IMaterialGraph {
    return x && typeof x === "object" && "uniqueId" in x && "rootNode" in x && "materialRevisionId" in x && "materialId" in x && "name" in x
}

export const IMaterialGraph = z.object({}).refine(isMaterialGraph, {message: "Invalid IMaterialGraph"})

export function wrapNodeOutput<T extends NodeType>(node: UnwrappedMaterialGraphNode<T>, name: string): WrappedMaterialGraphNode {
    return {nodeType: "GetOutput", parameters: {name}, inputs: {node}}
}

export function unwrapNodeOutput<T extends MaterialGraphNode>(node: T): T extends WrappedMaterialGraphNode ? [T["inputs"]["node"], string] : [T, undefined] {
    if (isOutputWrapper(node)) {
        if (isOutputWrapper(node.inputs["node"])) throw Error("Nested output wrapper")
        return [node.inputs["node"], node.parameters["name"]] as any // TODO we can't do better than that (given than narrowing down of generic types is tricky)?
    } else {
        return [node, undefined] as any
    }
}

export function isOutputWrapper(node: MaterialGraphNode): node is WrappedMaterialGraphNode {
    return node.nodeType === "GetOutput"
}

export function isOutputWrapperForNodeType<T extends NodeType>(node: MaterialGraphNode, nodeType: T): node is WrappedMaterialGraphNode<T> {
    return node.nodeType === "GetOutput" && node.inputs["node"].nodeType === nodeType
}

export function isImageResourceWithTransientDataObject(
    imageResource: ImageResource,
): imageResource is ImageResource & {transientDataObject: ITransientDataObject} {
    return "transientDataObject" in imageResource
}

export function getDataObjectFromImageResourceForFormatAndResolution(
    imageResource: ImageResource,
    format: ImageFormat,
    resolution: ImageResolution,
): DataObject | undefined {
    if (isImageResourceWithTransientDataObject(imageResource)) return undefined
    if (extensionForContentType(imageResource.mainDataObject.mediaType) === format && resolution === "original") return imageResource.mainDataObject

    const matchSize = (dataObject: DataObject) => {
        if (resolution === "original")
            return dataObject.width === imageResource.mainDataObject.width && dataObject.height === imageResource.mainDataObject.height

        const parsedResolution = parseInt(dataObject.downloadUrl.slice(dataObject.downloadUrl.lastIndexOf("-") + 1))
        return `${dataObject.width}px` === resolution || `${dataObject.height}px` === resolution || `${parsedResolution}px` === resolution
    }

    const matchedDataObject = imageResource.relatedDataObjects?.find(
        (dataObject) => extensionForContentType(dataObject.mediaType) === format && matchSize(dataObject),
    )
    if (matchedDataObject) return matchedDataObject

    console.warn(
        `Could not find related data object for format ${format} and resolution ${resolution} in image resource, returning original data object`,
        imageResource,
    )
    return imageResource.mainDataObject
}

const perNodeInternalParamWhiteList: {[name: string]: Set<string>} = {
    ShaderNodeMath: new Set(["internal.operation", "internal.use_clamp"]),
    BsdfPrincipled: new Set(["internal.subsurface_method", "internal.distribution"]),
    ShaderNodeBsdfGlass: new Set(["internal.distribution"]),
    TexImage: new Set(["internal.image.colorspace_settings.name", "internal.interpolation", "internal.extension", "internal.projection"]),
    Mapping: new Set(["internal.rotation", "internal.scale", "internal.translation", "internal.vector_type"]),
    UVMap: new Set(["internal.from_instancer", "internal.uv_map_index"]),
    ShaderNodeNormal: new Set(["internal.space", "internal.uv_map_index"]),
    ShaderNodeDisplacement: new Set(["internal.space"]),
    ShaderNodeVectorDisplacement: new Set(["internal.space"]),
    ShaderNodeBump: new Set(["internal.invert"]),
    ShaderNodeMixRGB: new Set(["internal.blend_type", "internal.use_clamp"]),
    ShaderNodeVectorMath: new Set(["internal.operation"]),
    ShaderNodeTexVoronoi: new Set(["internal.coloring", "internal.distance", "internal.feature"]),
    ShaderNodeRGBCurve: new Set(["internal.mapping.cycles_mapping_table"]),
    ShaderNodeValToRGB: new Set(["internal.color_ramp.cycles_ramp_color_table", "internal.color_ramp.cycles_ramp_alpha_table"]),
    ShaderNodeTangent: new Set(["internal.direction_type", "internal.axis"]),
}

export const maxCurveControlPoints = 8

const internalParamWhitelist = new Set([
    "internal.blend_type", // (above?)
    "internal.color_ramp.elements[0].color[0]",
    "internal.color_ramp.elements[0].color[1]",
    "internal.color_ramp.elements[0].color[2]",
    "internal.color_ramp.elements[0].position",
    "internal.color_ramp.elements[1].color[0]",
    "internal.color_ramp.elements[1].color[1]",
    "internal.color_ramp.elements[1].color[2]",
    "internal.color_ramp.elements[1].position",
    "internal.image.colorspace_settings.name", // (above?)
    "internal.interpolation", // (above?)
    ...Array.from(Array(maxCurveControlPoints).keys())
        .map((idx) => `internal.mapping.curves[0].points[${idx}].location`)
        .flat(),
    ...Array.from(Array(maxCurveControlPoints).keys())
        .map((idx) => `internal.mapping.curves[1].points[${idx}].location`)
        .flat(),
    ...Array.from(Array(maxCurveControlPoints).keys())
        .map((idx) => `internal.mapping.curves[2].points[${idx}].location`)
        .flat(),
    ...Array.from(Array(maxCurveControlPoints).keys())
        .map((idx) => `internal.mapping.curves[3].points[${idx}].location`)
        .flat(),
    "internal.operation", // (above?)
    "internal.rotation", // (above?)
    "internal.scale", // (above?)
    "internal.translation", // (above?)
    "internal.uv_map_index",
    "internal.disabled",
    "internal.displacement.disabled",
]) // (above?)

/** Filtering function that bypasses a disabled node in the node graph. Excludes node if it has one output and exactly one input with the same data type.
 * In general, the following filtering options exist:
 *
 * 1. Mapping inputs to outputs to bypass a node.
 * 2. Use default parameters for the node, which result in a noop, this currently doesn't fit into our architecture well
 * 3. Removing a node with only outputs, such as "value" or "RGB"
 *
 * Bypassing a node is what is done in Blender most of the time. This is also illustrated in Bender's UI by a red line between sockets across the node.
 * Also, most nodes can be disabled in Blender. However, this is only intuitive for color transformation nodes such as RGBCurves, HSV or Gamma as it
 * removes the color transformation. For e.g. a math node, it is not clear which of its multiple inputs should be mapped to an output. For a "separate XYZ"
 * node, muting is possible, the node is displayed as disabled, but the behavior stays the same. A texture node seems to provide a default color when disabled.
 * Because of this, only color transformation nodes are disabled in the current implementation.
 *
 * Which nodes actually can be disabled is defined in the NodeAccessor of MaterialEditorComponent. A better solution that requires larger changes would be
 * to move this behavior entirely to the nodes as discussed here: https://github.com/colormass/platform/issues/1307#issuecomment-1566146464
 */
function filterDisabledNodes(
    nodes: IMaterialNode[],
    connections: IMaterialConnection[],
): {filteredNodes: IMaterialNode[]; filteredConnections: IMaterialConnection[]} {
    let filteredNodes: IMaterialNode[] = [...nodes]
    let filteredConnections: IMaterialConnection[] = [...connections]

    const filterDisabledNode = (nodes: IMaterialNode[], connections: IMaterialConnection[], disabledNode: IMaterialNode) => {
        //Handle disabled node. This is done using Socket.id, which is a hint that bypassing is possible, but not a real type. Type checking should be enabled here. Note that
        //the node-internal parameters are not directly related to the sockets! So IMaterialConnection.sourceParameter has nothing to do with the node's parameters.
        const disabledNodeOutputs = connections.filter((connection) => connection.source === disabledNode.id)
        const disabledNodeCompatibleInputs = connections.filter(
            (connection) => connection.destination === disabledNode.id && connection.destinationParameter === disabledNodeOutputs[0]?.sourceParameter,
        )

        if (disabledNodeOutputs.length === 1 && disabledNodeCompatibleInputs.length === 1) {
            const bypassConnection: IMaterialConnection = {
                source: disabledNodeCompatibleInputs[0].source,
                sourceParameter: disabledNodeCompatibleInputs[0].sourceParameter,
                destination: disabledNodeOutputs[0].destination,
                destinationParameter: disabledNodeOutputs[0].destinationParameter,
            }

            const filteredNodes = nodes.filter((node) => node.id !== disabledNode.id)
            const filteredConnections = connections.filter((connection) => connection.source !== disabledNode.id && connection.destination !== disabledNode.id)
            return {filteredNodes, filteredConnections: [...filteredConnections, bypassConnection]}
        } else {
            console.warn("Could not disable node: ", disabledNode)
            return {filteredNodes: nodes, filteredConnections: connections}
        }
    }

    const disableNodes = nodes.filter((node) => node.parameters.find((param) => param.name === "internal.disabled")?.value === true)
    for (const node of disableNodes) {
        ;({filteredNodes, filteredConnections} = filterDisabledNode(filteredNodes, filteredConnections, node))
    }

    return {filteredNodes, filteredConnections}
}

function filterDisabledDisplacement(
    nodes: IMaterialNode[],
    connections: IMaterialConnection[],
): {filteredNodes: IMaterialNode[]; filteredConnections: IMaterialConnection[]} {
    const disabledDisplacementOutputNodes = nodes.filter((node) => {
        if (node.name !== "OutputMaterial") return false
        const displacementDisabled = node.parameters.find((param) => param.name === "internal.displacement.disabled")
        return displacementDisabled?.value === true
    })

    return {
        filteredNodes: nodes,
        filteredConnections: connections.filter((connection) => {
            if (connection.destinationParameter === "Displacement" && disabledDisplacementOutputNodes.some((node) => node.id === connection.destination))
                return false
            return true
        }),
    }
}

async function buildGraph(
    nodes: IMaterialNode[],
    connections: IMaterialConnection[],
    rootNodeId: string,
    loadImageResource: LoadImageResource,
): Promise<MaterialGraphRootNode> {
    type GraphNode = Exclude<MaterialGraphNode, WrappedMaterialGraphNode>

    const {filteredNodes: filteredNodesTmp, filteredConnections: filteredConnectionsTmp} = filterDisabledNodes(nodes, connections)
    const {filteredNodes, filteredConnections} = filterDisabledDisplacement(filteredNodesTmp, filteredConnectionsTmp)

    const nodeMap = new Map<string, GraphNode>()
    const pending: Promise<void>[] = []
    const addParam = (node: GraphNode, name: string, value: any) => {
        if (!node.parameters) node.parameters = {}
        node.parameters[name] = value
    }
    for (const listNode of filteredNodes) {
        const parsedNodeType = NodeTypeSchema.safeParse(listNode.name)
        if (!parsedNodeType.success) throw Error(`Invalid node type: ${listNode.name}`)
        const imageResources: ImageResource[] = []
        const graphNode: GraphNode = {nodeType: parsedNodeType.data, imageResources}
        for (const parameter of listNode.parameters) {
            if (parameter.name.startsWith("internal.")) {
                const perNode = perNodeInternalParamWhiteList[listNode.name]
                if (!((perNode && perNode.has(parameter.name)) || internalParamWhitelist.has(parameter.name))) {
                    // console.log("Filtering internal parameter:", parameter.name);
                    continue
                }
            }
            addParam(graphNode, parameter.name, parameter.value)
        }
        // load resources; make sure the resources end up in imageResources as they are declared in the graphNode to associate them correctly later on
        if (listNode.textureRevision) {
            if (typeof listNode.textureRevision === "number") {
                pending.push(
                    loadImageResource({type: "texture-revision", legacyId: listNode.textureRevision}).then((resource) => {
                        imageResources.push(resource)
                    }),
                )
            } else {
                if (!listNode.textureRevision.imageColorSpace) {
                    throw Error("Transient data object image color space not set!")
                }
                imageResources.push({transientDataObject: listNode.textureRevision})
            }
        }
        if (listNode.textureSetRevision) {
            const textureSetRevision = listNode.textureSetRevision
            let pendingImageResources: Promise<ImageResource>[] = []

            if (parsedNodeType.data === "ShaderNodeTextureSet" || parsedNodeType.data === "ShaderNodeSetTexture") {
                pendingImageResources = textureSetRevision.mapAssignments.map((assignment) =>
                    loadImageResource({
                        type: "data-object",
                        legacyId: assignment.dataObjectLegacyId,
                        width: textureSetRevision.width,
                        height: textureSetRevision.height,
                    }),
                )
            } else {
                throw Error("Unsupported node type, expected ShaderNodeTextureSet or ShaderNodeSetTexture")
            }

            pending.push(
                Promise.all(pendingImageResources).then((resources) => {
                    imageResources.push(...resources)
                }),
            )
        }
        nodeMap.set(listNode.id, graphNode)
    }
    for (const connection of filteredConnections) {
        const sourceNode = nodeMap.get(connection.source)
        if (!sourceNode) {
            throw Error(`Source node (legacy id: ${connection.destination}) not found`)
        }
        const destNode = nodeMap.get(connection.destination)
        if (!destNode) {
            throw Error(`Destination node (legacy id: ${connection.destination}) not found`)
        }

        if (!destNode.inputs) destNode.inputs = {}
        destNode.inputs[connection.destinationParameter] = wrapNodeOutput(sourceNode, connection.sourceParameter)
        if (destNode.parameters && connection.destinationParameter in destNode.parameters) {
            delete destNode.parameters[connection.destinationParameter]
        }
    }
    const root = nodeMap.get(rootNodeId)
    if (!root || !isNodeOfType(root, "OutputMaterial")) throw Error("Root node not found!")
    return Promise.all(pending).then((x) => root)
}

export function isShadowCatcherMaterial(graph: IMaterialGraph): boolean {
    return !!graph.rootNode.parameters?.["shadow_catcher"]
}

export function getPhysicalSizeInfoForMaterialGraph(graph: IMaterialGraph): {widthCm: number; heightCm: number; pxPerCm: number} | undefined {
    type ImageNode = MaterialGraphNode<"TexImage" | "ShaderNodeTextureSet" | "ShaderNodeSetTexture">
    const imageNodes = new Set<ImageNode>()
    let baseColorNode: ImageNode | undefined = undefined

    const traverse = (node: MaterialGraphNode, baseColorPath: boolean) => {
        if (isNodeOfType(node, "TexImage") || isNodeOfType(node, "ShaderNodeTextureSet") || isNodeOfType(node, "ShaderNodeSetTexture")) {
            if (baseColorPath) baseColorNode = node
            else imageNodes.add(node)
        }
        if (node.inputs) {
            for (const [name, inputNode] of Object.entries(node.inputs)) {
                if (node.nodeType === "BsdfPrincipled") baseColorPath = name === "Base Color"
                traverse(inputNode, baseColorPath)
            }
        }
    }
    traverse(graph.rootNode, false)

    if (baseColorNode === undefined && imageNodes.size === 0) return undefined

    const nodeForSize = baseColorNode ?? Array.from(imageNodes)[0]

    const refImgRes = (() => {
        if (!nodeForSize.imageResources || nodeForSize.imageResources.length === 0) return undefined
        const ref = nodeForSize.imageResources[0]
        return nodeForSize.imageResources.every(
            (imgRes) => imgRes?.metadata?.widthCm === ref?.metadata?.widthCm && imgRes?.metadata?.heightCm === ref?.metadata?.heightCm,
        )
            ? ref
            : undefined
    })()

    const widthCm = refImgRes?.metadata?.widthCm
    const heightCm = refImgRes?.metadata?.heightCm
    if (widthCm && heightCm) {
        let pxPerCm: number
        if (!isImageResourceWithTransientDataObject(refImgRes)) {
            if (refImgRes.mainDataObject.width !== undefined && refImgRes.mainDataObject.height !== undefined) {
                pxPerCm = Math.max(refImgRes.mainDataObject.width, refImgRes.mainDataObject.height)
                pxPerCm /= Math.max(widthCm, heightCm)
            } else {
                throw Error("Tried to get pxPerCm from dataobject without width and height")
            }
        } else {
            throw Error("Tried to get pxPerCm from TransientDataObject")
        }
        return {
            widthCm,
            heightCm,
            pxPerCm,
        }
    }

    return undefined
}

export function getDominantTextureRepeatSizeForMaterialGraph(graph: IMaterialGraph): [number, number] | undefined {
    //NOTE: returned size is in cm
    const mappingScales = new Map<string, [number, number, number]>() // '${width} ${height}' -> [width,height,numUses]

    const visited = new Set<MaterialGraphNode>()
    const traverse = (node: MaterialGraphNode) => {
        if (visited.has(node)) return
        visited.add(node)
        if (!isOutputWrapper(node) && node?.imageResources) {
            node?.imageResources.forEach((imageResource) => {
                const widthCm = imageResource?.metadata?.widthCm
                const heightCm = imageResource?.metadata?.heightCm
                if (widthCm && heightCm) {
                    const mapScaleKey = `${widthCm} ${heightCm}`
                    let mapScaleEntry = mappingScales.get(mapScaleKey)
                    if (mapScaleEntry) {
                        mapScaleEntry[2] += 1
                    } else {
                        mapScaleEntry = [widthCm, heightCm, 1]
                        mappingScales.set(mapScaleKey, mapScaleEntry)
                    }
                }
            })
        }
        if (node.inputs) {
            for (const [name, inputNode] of Object.entries(node.inputs)) {
                traverse(inputNode)
            }
        }
    }
    traverse(graph.rootNode)

    let primaryMapScaleEntry: [number, number, number] | undefined
    for (const [_, entry] of mappingScales) {
        if (primaryMapScaleEntry === undefined || entry[2] > primaryMapScaleEntry[2]) {
            primaryMapScaleEntry = entry
        }
    }
    if (primaryMapScaleEntry) {
        return [primaryMapScaleEntry[0], primaryMapScaleEntry[1]]
    } else {
        return undefined
    }

    // const scaleX = this.materialData.mask.width;
    // const scaleY = this.materialData.mask.height;
}

export async function materialGraphFromNodesAndConnections(
    nodes: IMaterialNode[],
    connections: IMaterialConnection[],
    materialRevisionId: number,
    materialId: number,
    name: string,
    loadImageResource: LoadImageResource,
): Promise<IMaterialGraph> {
    const rootNodeId = nodes.find((x) => x.name === "OutputMaterial")?.id
    if (!rootNodeId) {
        throw new Error("No root node id found in material graph")
    }

    const rootNode = await buildGraph(nodes, connections, rootNodeId, loadImageResource)
    return {
        uniqueId: hashObject(rootNode),
        materialRevisionId,
        materialId,
        name,
        rootNode,
    }
}

export function transformMaterialGraph(graph: IMaterialGraph, fn: (root: MaterialGraphRootNode) => MaterialGraphRootNode): IMaterialGraph {
    const rootNode = fn(graph.rootNode)
    return {...graph, rootNode, uniqueId: hashObject(rootNode)}
}

export interface IMaterialGraphManager {
    graphFromMaterialRevisionId(materialRevisionId: IdDetails): Promise<IMaterialGraph>
}
