export const isMobileDevice: boolean = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
export const isAndroid: boolean = /Android/i.test(navigator.userAgent)
export const isIpadOS: boolean = /MacIntel/.test(navigator.platform) && (navigator.maxTouchPoints ? navigator.maxTouchPoints > 2 : false)
export const isIoS: boolean = /iPhone|iPad|iPod/i.test(navigator.userAgent) || isIpadOS
export const isApple: boolean = /(Mac|iPhone|iPad|iPod)/i.test(navigator.platform) || isIpadOS
export const isSafari: boolean = /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent)
export const isFirefox: boolean = /Firefox/i.test(navigator.userAgent)
export const isChrome: boolean = /Chrome/i.test(navigator.userAgent)

export function getIOSVersion() {
    // returns [major, minor, rev] or undefined;
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
        if (!v) {
            console.warn(`Failed to parse iOS version: ${navigator.appVersion}`)
            return undefined
        }
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] ?? "0", 10)] as const
    } else {
        return undefined
    }
}

export function getSafariVersion() {
    // returns [major, minor, rev] or undefined;
    if (/Safari/.test(navigator.userAgent)) {
        const v = navigator.userAgent.match(/(\d+)\.(\d+)\.?(\d+)? Safari/)
        if (!v) {
            console.warn(`Failed to parse Safari version: ${navigator.userAgent}`)
            return undefined
        }
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] ?? "0", 10)] as const
    } else {
        return undefined
    }
}
