import {Component, DoCheck, OnInit, ViewChild} from "@angular/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {VectorMathInputs, VectorMathOperation, VectorMathOutputs} from "@material-editor/models/nodes"
import {NodeIoComponent} from "@node-editor/components/node-io/node-io.component"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-vector-math-node",
    templateUrl: "./vector-math-node.component.html",
    styleUrls: ["./vector-math-node.component.scss"],
    standalone: true,
    imports: [NodeIoComponent, NodeBaseComponent, MemoizePipe, MatInputModule, MatSelectModule],
})
export class VectorMathNodeComponent implements OnInit, DoCheck {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    typeInfo = VectorMathNodeType

    outputVector = VectorMathOutputs.vector
    outputValue = VectorMathOutputs.value

    inputVector = VectorMathInputs.vector
    inputVector001 = VectorMathInputs.vector001
    inputScale = VectorMathInputs.scale

    vectorOperation = VectorMathOperation
    selectedVectorOperation = "ADD"

    ngOnInit() {
        this.nodeBase?.addParamIfMissing(VectorMathOutputs.vector.id, [0, 0, 0], VectorMathOutputs.vector.valueType)
        this.nodeBase?.addParamIfMissing(VectorMathOutputs.value.id, 0, VectorMathOutputs.value.valueType)
        this.nodeBase?.addParamIfMissing(VectorMathOperation.id, "ADD", VectorMathOperation.valueType)
        this.nodeBase?.addParamIfMissing(VectorMathInputs.vector.id, [0, 0, 0], VectorMathInputs.vector.valueType)
        this.nodeBase?.addParamIfMissing(VectorMathInputs.vector001.id, [0, 0, 0], VectorMathInputs.vector001.valueType)
        this.nodeBase?.addParamIfMissing(VectorMathInputs.scale.id, 1, VectorMathInputs.scale.valueType)
        this.nodeBase?.setParameterNoUpdate(VectorMathInputs.vector002.id, [0, 0, 0], VectorMathInputs.vector002.valueType) // always set this to 0, as it is not used
    }

    // TODO: optimize change detection
    // TODO: can/should this be generalized, so it is done in NodeBase directly instead of here?
    ngDoCheck(): void {
        this.selectedVectorOperation = this.nodeBase?.getParameter(this.vectorOperation.id)
    }

    hideOutputSocket(id: string, operation: string): boolean {
        const showValue = ["DISTANCE", "LENGTH"].includes(operation)
        if (!(id === "Value" || id === "Vector")) throw new Error("Expected 'Value' or 'Vector'")
        return id === "Value" ? !showValue : showValue
    }

    hideInputSocket(id: string, operation: string): boolean {
        if (!(id === "Vector_001" || id === "Scale")) throw new Error("Expected 'Vector_001' or 'Scale'")
        if (id === "Vector_001") {
            return ["LENGTH", "SCALE", "NORMALIZE"].includes(operation)
        } else {
            return !["SCALE"].includes(operation)
        }
    }
}

export const VectorMathNodeType: MaterialNodeType<typeof VectorMathNodeComponent> = {
    id: "vectorMathNode",
    label: "Vector Math",
    color: "#6e5eb2",
    name: "ShaderNodeVectorMath",
    inputs: [VectorMathInputs.vector, VectorMathInputs.vector001, VectorMathInputs.vector002, VectorMathInputs.scale],
    outputs: [VectorMathOutputs.vector, VectorMathOutputs.value],
    component: VectorMathNodeComponent,
}
