<div class="cm-navigation-bar-container">
    <div class="cm-navigation-bar" [style.background-color]="backgroundColor">
        <!--Large screen navigation-->
        <div class="cm-navigation-links-large-screen">
            @if (auth.$user()) {
            <a routerLink="/pictures" [queryParams]="{tab: 'projects', organizationId: this.organizations.$current()?.id}">
                <ng-container *ngTemplateOutlet="navigationLogo"></ng-container>
            </a>
            } @else {
            <a routerLink="/login">
                <ng-container *ngTemplateOutlet="navigationLogo"></ng-container>
            </a>
            }
            <ng-container *ngTemplateOutlet="navigationLinks"></ng-container>
        </div>
        <!--Small screen navigation-->
        <a class="cm-navigation-links-small-screen" (click)="sidenavOpen = !sidenavOpen">
            <ng-container *ngTemplateOutlet="navigationLogo"></ng-container>
        </a>
        <!--User-->
        @if (auth.$user()) {
        <div class="cm-right-container">
            <!-- Environment string -->
            @if (!environment.production) {
            <span class="cm-environment-name">{{ environment.name }}</span>
            } @if (auth.isStaff()) {
            <cm-toggle [toggle]="isTemplate" (toggleChange)="isTemplate = !isTemplate"></cm-toggle>
            }

            <div class="cm-user-menu" mat-button [matMenuTriggerFor]="menu">
                <span>{{ auth.$user()?.name ?? "" }}</span>
                <i class="far fa-chevron-down"></i>
            </div>
            <mat-menu #menu="matMenu">
                @if (can.scan()) {
                <a routerLink="/scanning" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-aperture cm-user-menu-icon"></i>
                    </div>
                    <span>Scanning</span>
                </a>
                } @if (can.viewCustomersMenu()) {
                <a routerLink="/organizations" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-building cm-user-menu-icon"></i>
                    </div>
                    <span>Organizations</span>
                </a>
                } @if (can.viewUsersMenu()) {
                <a routerLink="/users" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-user-friends cm-user-menu-icon"></i>
                    </div>
                    <span>Users</span>
                </a>
                } @if (can.viewHdrisMenu()) {
                <a routerLink="/hdris" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-lightbulb cm-user-menu-icon"></i>
                    </div>
                    <span>HDRIs</span>
                </a>
                } @if (can.viewJobsMenu()) {
                <a routerLink="/jobs" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-gears cm-user-menu-icon"></i>
                    </div>
                    <span>Jobs</span>
                </a>
                } @if (can.viewJobFarmInstancesMenu()) {
                <a routerLink="/render-nodes" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-server cm-user-menu-icon"></i>
                    </div>
                    <span>Render Nodes</span>
                </a>
                } @if (can.viewJobFarmGroupsMenu()) {
                <a routerLink="/render-node-groups" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-network-wired cm-user-menu-icon"></i>
                    </div>
                    <span>Render Node Groups</span>
                </a>
                } @if (can.viewTaskTypesMenu()) {
                <a routerLink="/task-types" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-diagram-project cm-user-menu-icon"></i>
                    </div>
                    <span>Task Types</span>
                </a>
                } @if (can.viewTagsMenu()) {
                <a
                    routerLink="/tags"
                    class="cm-user-menu-link"
                    mat-menu-item
                    [queryParams]="!(auth.isStaff | memoize: auth) && organizations.$current() && {organizationId: organizations.$current().id}"
                >
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-tag cm-user-menu-icon"></i>
                    </div>
                    <span>Tags</span>
                </a>
                } @if (can.viewFilesMenu()) {
                <a [queryParams]="{isRelated: 0}" routerLink="/files" class="cm-user-menu-link" mat-menu-item>
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-file cm-user-menu-icon"></i>
                    </div>
                    <span>Files</span>
                </a>
                }
                <button mat-menu-item (click)="auth.logOut()" routerLink="/login">
                    <div class="cm-user-menu-icon-container">
                        <i class="far fa-sign-out cm-user-menu-icon"></i>
                    </div>
                    <span>Log out</span>
                </button>
            </mat-menu>
        </div>
        }
    </div>
</div>
<ng-template #navigationLogo>
    <div class="cm-logo-container">
        <img src="../../../../../assets/images/colormass-logo-cm-4.svg" />
    </div>
</ng-template>
<ng-template #navigationLinks>
    @if (auth.$user()) {

    <!--Online Links: Photographers, Fabric Manufacturers, Staff (with toggle on)-->
    @if ((auth.isPhotographer | memoize:auth) || (auth.isFabricManufacturer | memoize:auth) || ((auth.isStaff | memoize:auth) && isTemplate)) {

    <ng-container *ngTemplateOutlet="templates; context: {title:  'Products', link: 'products'}"></ng-container>
    <ng-container *ngTemplateOutlet="materials"></ng-container>
    <!-- @if (auth.isFabricManufacturer | memoize:auth) {
                            <ng-container *ngTemplateOutlet="textures"></ng-container>
                            } -->
    <ng-container *ngTemplateOutlet="templates; context: {title:  'Scenes', link: 'scenes'}"></ng-container>
    <ng-container *ngTemplateOutlet="pictures; context: {title: 'Pictures'}"></ng-container>
    } @else {
    <ng-container *ngTemplateOutlet="models"></ng-container>
    <ng-container *ngTemplateOutlet="materials"></ng-container>
    <!-- @if (auth.isStaff | memoize:auth) {
                            <ng-container *ngTemplateOutlet="textures"></ng-container>
                            } -->
    <ng-container *ngTemplateOutlet="assets"></ng-container>
    <ng-container *ngTemplateOutlet="pictures"></ng-container>
    }

    <!--Offline Links: Staff (with toggle off), regular customer accounts-->
    <ng-template #templates let-title="title" let-link="link">
        <cm-navigation-item [link]="[link ? link : 'templates']" [active]="selectedLink === (link ? link : 'templates')" [filterForCustomer]="true">
            <ng-container cm-title> {{ title ? title : 'Templates' }}</ng-container>
        </cm-navigation-item>
    </ng-template>
    <ng-template #models>
        <cm-navigation-item [link]="['models']" [active]="selectedLink === 'models'" [filterForCustomer]="true">
            <ng-container cm-title> Models</ng-container>
        </cm-navigation-item>
    </ng-template>
    <ng-template #materials>
        <cm-navigation-item [link]="['materials']" [active]="selectedLink === 'materials'" [filterForCustomer]="true">
            <ng-container cm-title> Materials</ng-container>
        </cm-navigation-item>
    </ng-template>
    <!-- <ng-template #textures>
                    <cm-navigation-item [link]="['texture-groups']" [active]="selectedLink === 'texture-groups'" [filterForCustomer]="true">
                        <ng-container cm-title> Textures</ng-container>
                    </cm-navigation-item>
                </ng-template> -->
    <ng-template #assets>
        <cm-navigation-item [title]="'Assets'" [link]="['assets']" [active]="selectedLink === 'assets'" [filterForCustomer]="true">
            <ng-container cm-title> Assets</ng-container>
        </cm-navigation-item>
    </ng-template>
    <ng-template #pictures let-title="title">
        <cm-navigation-item [link]="['pictures']" [active]="selectedLink === 'pictures'" [filterForCustomer]="true">
            <ng-container cm-title> {{ title ? title : 'Pictures' }}</ng-container>
        </cm-navigation-item>
    </ng-template>

    <!--Misc-->
    @if (auth.isSuperuser | memoize:auth) {
    <cm-navigation-item [link]="['statistics']" [active]="selectedLink === 'statistics'">
        <ng-container cm-title> Statistics</ng-container>
    </cm-navigation-item>
    } @if ((auth.isSuperuser | memoize:auth) && selectedLink === 'users') {
    <cm-navigation-item [link]="['users']" [active]="selectedLink === 'users'">
        <ng-container cm-title> Users</ng-container>
    </cm-navigation-item>
    } @if ((auth.isStaff | memoize:auth) && selectedLink === 'files') {
    <cm-navigation-item [link]="['files']" [params]="{isRelated: 0}" [active]="selectedLink === 'files'">
        <ng-container cm-title> Files</ng-container>
    </cm-navigation-item>
    } @if ((auth.isStaff | memoize:auth) && selectedLink === 'hdris') {
    <cm-navigation-item [link]="['hdris']" [active]="selectedLink === 'hdris'">
        <ng-container cm-title> HDRIs</ng-container>
    </cm-navigation-item>
    } @if (((auth.isStaff | memoize:auth) || (auth.isPhotographer | memoize: auth)) && selectedLink === 'jobs') {
    <cm-navigation-item [link]="['jobs']" [active]="selectedLink === 'jobs'">
        <ng-container cm-title> Jobs</ng-container>
    </cm-navigation-item>
    } @if (((auth.isStaff | memoize:auth) || (auth.isPhotographer | memoize: auth)) && selectedLink === 'render-nodes') {
    <cm-navigation-item [link]="['render-nodes']" [active]="selectedLink === 'render-nodes'">
        <ng-container cm-title> Render Nodes</ng-container>
    </cm-navigation-item>
    } @if (((auth.isStaff | memoize:auth) || (auth.isPhotographer | memoize: auth)) && selectedLink === 'render-node-groups') {
    <cm-navigation-item [link]="['render-node-groups']" [active]="selectedLink === 'render-node-groups'">
        <ng-container cm-title> Render Node Groups</ng-container>
    </cm-navigation-item>
    } @if ((auth.isStaff | memoize:auth) && selectedLink === 'organizations') {
    <cm-navigation-item [link]="['organizations']" [active]="selectedLink === 'organizations'">
        <ng-container cm-title> Organizations</ng-container>
    </cm-navigation-item>
    } @if (((auth.isStaff | memoize:auth) || (auth.isFabricManufacturer | memoize:auth)) && selectedLink === 'scanning') {
    <cm-navigation-item [link]="['scanning']" [active]="selectedLink === 'scanning'">
        <ng-container cm-title> Scanning</ng-container>
    </cm-navigation-item>
    } }
</ng-template>
<cm-sidenav #sidenav [sidenavOpen]="sidenavOpen && !isBigScreen" (backgroundClick)="sidenavOpen = false">
    <ng-container cm-content>
        @if (!isBigScreen) {
        <div (click)="sidenavOpen = !sidenavOpen" class="cm-navigation-bar-sidenav">
            <ng-container *ngTemplateOutlet="navigationLinks"></ng-container>
        </div>
        }
    </ng-container>
</cm-sidenav>
