export const setsAreEqual = <T>(a?: Set<T>, b?: Set<T>): boolean => {
    if (!a) {
        return !b
    }
    if (!b) {
        return false
    }
    if (a.size !== b.size) {
        return false
    }
    for (const item of a) {
        if (!b.has(item)) {
            return false
        }
    }
    return true
}

export const toggleSetValue = <T>(set: Set<T>, value: T) => {
    if (set.has(value)) {
        set.delete(value)
    } else {
        set.add(value)
    }
}

export const forceSetValue = <T>(set: Set<T>, value: T, force: boolean) => {
    if (force) {
        set.add(value)
    } else {
        set.delete(value)
    }
}
