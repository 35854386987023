import {isFirefox} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {HalContext} from "@common/models/hal/hal-context"

export class WebGl2Context implements HalContext {
    readonly gl: WebGL2RenderingContext
    readonly maxDrawBuffers: number
    readonly maxTextureUnits: number
    readonly maxTextureSize: number
    readonly maxTextureLayers: number
    readonly EXT_color_buffer_float: boolean
    readonly EXT_color_buffer_half_float: boolean
    readonly EXT_float_blend: boolean

    static createFromCanvas(canvas: HTMLCanvasElement): WebGl2Context {
        console.log("Initializing WebGL2 canvas")
        if (!canvas) {
            throw Error("WebGL canvas is null.")
        }
        const gl = canvas.getContext("webgl2", {
            antialias: false,
            preserveDrawingBuffer: false,
            alpha: true,
            premultipliedAlpha: true,
        })
        if (!gl) {
            throw Error("WebGL2 context could not be obtained from canvas.")
        }
        return WebGl2Context.createFromWebGL2RenderingContext(gl)
    }

    static createFromWebGL2RenderingContext(gl: WebGL2RenderingContext): WebGl2Context {
        return new WebGl2Context(gl)
    }

    private constructor(gl: WebGL2RenderingContext) {
        this.gl = gl
        gl.disable(gl.DEPTH_TEST)
        this.maxDrawBuffers = gl.getParameter(gl.MAX_DRAW_BUFFERS)
        this.maxTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS)
        this.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE)
        this.maxTextureLayers = gl.getParameter(gl.MAX_ARRAY_TEXTURE_LAYERS)
        if (isFirefox) {
            this.maxTextureSize = Math.min(this.maxTextureSize, 8192) // interestingly firefox on win returns 16384, but only seems to handle 8192 properly, so we artificially restrict it here (checked 31.3.23)
        }
        this.EXT_color_buffer_float = gl.getExtension("EXT_color_buffer_float") != null
        this.EXT_color_buffer_half_float = gl.getExtension("EXT_color_buffer_half_float") != null
        this.EXT_float_blend = gl.getExtension("EXT_float_blend") != null
        console.log("WebGL2 context initialized.")
        console.log("  MaxDrawBuffers: ", this.maxDrawBuffers)
        console.log("  maxTextureUnits: ", this.maxTextureUnits)
        console.log("  MaxTextureSize: ", this.maxTextureSize)
        console.log("  MaxTextureLayers: ", this.maxTextureLayers)
        console.log("  EXT_color_buffer_float: ", this.EXT_color_buffer_float ? "yes" : "no")
        console.log("  EXT_color_buffer_half_float: ", this.EXT_color_buffer_half_float ? "yes" : "no")
        console.log("  EXT_float_blend: ", this.EXT_float_blend ? "yes" : "no")
    }

    // HalEntity
    get context(): HalContext {
        return this
    }

    // HalEntity
    dispose() {
        // unbind everything
        const gl = this.gl
        for (let unit = 0; unit < this.maxTextureUnits; ++unit) {
            gl.activeTexture(gl.TEXTURE0 + unit)
            gl.bindTexture(gl.TEXTURE_2D, null)
            gl.bindTexture(gl.TEXTURE_CUBE_MAP, null)
        }
        gl.bindBuffer(gl.ARRAY_BUFFER, null)
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null)
        gl.bindRenderbuffer(gl.RENDERBUFFER, null)
        gl.bindFramebuffer(gl.FRAMEBUFFER, null)
        console.log("WebGL2 canvas disposed")
    }

    // HalContext
    async flush(): Promise<void> {
        this.gl.finish()
    }
}
