import {Injectable} from "@angular/core"
import {map, fromEvent, startWith, Observable, filter, OperatorFunction, switchMap, shareReplay, distinctUntilChanged} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class TabStateService {
    // let the browser notify us when the app becomes hidden/visible again
    isActive$ = fromEvent(document, "visibilitychange").pipe(
        // this is the current visibility state - note that newly opened tabs might be in prerender state,
        // so document.hidden would be true (!)
        // https://docs.w3cub.com/dom/document/hidden.html
        map(() => ["visible", "prerender"].includes(document.visibilityState)),
        // use this value until the event gets triggered for the first time
        // note that we use true here, because the visibilitychange event does not trigger reliably in Chrome,
        // so we assume the app is visible until the event is triggered for the first time
        startWith(true),
        // we only care about emissions that actually change the value
        distinctUntilChanged(),
        // allow multiple subscribers, each receiving the last emitted value immediately on subscription
        shareReplay(1),
    )

    becomesInactive$: Observable<void> = this.isActive$.pipe(
        filter((isActive) => !isActive),
        map(() => undefined),
    )

    becomesActive$: Observable<void> = this.isActive$.pipe(
        filter((isActive) => isActive),
        map(() => undefined),
    )

    reEmitOnActivation = <ItemType>(): OperatorFunction<ItemType, ItemType> =>
        switchMap((item: ItemType) =>
            this.isActive$.pipe(
                startWith(item),
                map(() => item),
            ),
        )
}
