<cm-infinite-list [count]="totalCount" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
    <cm-list-info
        cm-list-header
        [parentList]="this"
        [enableBatchUpdates]="can.batchUpdateAssets()"
        [stateLabels]="stateLabels"
        [count]="totalCount"
        (createItemEvent)="openSelectMaterialDialog()"
    ></cm-list-info>
    @for (item of items; track item) {
        @if (item.data; as asset) {
            <cm-entity-card @fadeInCard [$entity]="asset" [entityLink]="['/assets', asset.id]" [entityQueryParamsHandling]="'preserve'">
                <ng-container cm-top-left>
                    <!--Asset boxes-->
                    @if (userIsStaff) {
                        @if (asset.state !== Enums.AssetState.Completed) {
                            <cm-asset-boxes [assetId]="asset.id"></cm-asset-boxes>
                        }
                    }
                </ng-container>
                <ng-container cm-bottom-left>
                    <cm-state-label
                        [stateLabels]="Labels.AssetState"
                        [state]="asset.state"
                        [disableCompleted]="!userIsStaff"
                        [backgroundColor]="!userIsStaff && asset.nextActor === Enums.NextActor.Customer ? '#f13536' : undefined"
                        [editable]="userIsStaff"
                        (stateChange)="updateItem({id: asset.id, state: $event})"
                    >
                    </cm-state-label>
                </ng-container>
                <ng-container cm-bottom-right>
                    @if (userIsStaff) {
                        <cm-assign-user
                            (click)="$event.stopPropagation()"
                            [matMenuTriggerFor]="userMenu"
                            [matMenuTriggerData]="{entity: asset}"
                            [$user]="asset.currentUserAssignment"
                            [nextActor]="asset.nextActor"
                        >
                        </cm-assign-user>
                    }
                </ng-container>
                <ng-container cm-subtitle>
                    @if (userIsStaff) {
                        <cm-comment-boxes [entity]="asset"></cm-comment-boxes>
                    }
                </ng-container>
            </cm-entity-card>
        } @else if (item.placeholder) {
            <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
        } @else {
            <cm-card-error @fadeInPlaceholder></cm-card-error>
        }
    }
</cm-infinite-list>
<mat-menu #userMenu [overlapTrigger]="false" [hasBackdrop]="true">
    <ng-template matMenuContent let-entity="entity">
        <cm-assign-user-dialog
            [$user]="entity.currentUserAssignment"
            [nextActor]="entity.nextActor"
            (userChange)="updateAssignedUser(Enums.ContentTypeModel.Asset, entity, $event)"
            (nextActorChange)="updateItem({id: entity.id, nextActor: $event})"
        ></cm-assign-user-dialog>
    </ng-template>
</mat-menu>
