import {Component, EventEmitter, Input, Output} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"

@Component({
    selector: "cm-add-file-card",
    standalone: true,
    imports: [MatButtonModule],
    templateUrl: "./add-file-card.component.html",
    styleUrl: "./add-file-card.component.scss",
})
export class AddFileCardComponent {
    @Input() squareFormat = false
    @Output() filesSelected = new EventEmitter<File[]>()

    selectFiles(event: Event) {
        const inputElement = event.target as HTMLInputElement
        if (inputElement.files) {
            this.filesSelected.emit(Array.from(inputElement.files))
            // reset value to make event fire again if same file is selected
            inputElement.value = ""
        }
    }
}
