<div class="node-flex-container">
    @if ($conditionalAmountNode()) {
        <div class="node-icon" matTooltip="Conditional Amount" matTooltipPosition="above">
            <i class="fa-solid fa-hashtag"></i>
        </div>
        <span class="node-text">{{ $description() }}</span>
        <div class="tools-right">
            <cm-variant-condition
                [variantConditionNode]="$conditionalAmountNode().parameters.condition"
                ($conditionRemoved)="$conditionRemoved.emit()"
            ></cm-variant-condition>
            <input class="input-width-limit" type="number" min="0" [(ngModel)]="$conditionalAmountNode().parameters.amount" />
        </div>
    }
</div>
