import {MeshBuffers} from "@cm/lib/geometry-processing/mesh-data"
import * as THREE from "three"

export class MeshBuffersWireframGeometry extends THREE.BufferGeometry {
    override type = "SegmentEdgesGeometry"

    private parameters: {meshBuffers: MeshBuffers}

    constructor(meshBuffers: MeshBuffers) {
        super()

        this.parameters = {
            meshBuffers,
        }

        if (meshBuffers.indices.length === 0) return

        const {vertices: vertexData, faceIDs, indices} = meshBuffers

        if (indices.length !== faceIDs.length) {
            throw new Error("Indices and faceIDs length mismatch")
        }

        const precisionPoints = 4
        const precision = Math.pow(10, precisionPoints)

        const indexArr = [0, 0, 0]
        const hashes = ["", "", ""]

        const edgeData = new Map<string, {index0: number; index1: number; faceID: number}>()

        const triVertices = [new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]
        const [a, b, c] = triVertices

        const setVector = (v: THREE.Vector3, index: number) => {
            const offset = index * 3
            v.x = vertexData[offset]
            v.y = vertexData[offset + 1]
            v.z = vertexData[offset + 2]
        }

        const vertices: number[] = []
        for (let i = 0; i < indices.length; i += 3) {
            indexArr[0] = indices[i]
            indexArr[1] = indices[i + 1]
            indexArr[2] = indices[i + 2]

            setVector(a, indexArr[0])
            setVector(b, indexArr[1])
            setVector(c, indexArr[2])
            const faceID = faceIDs[i]

            hashes[0] = `${Math.round(a.x * precision)},${Math.round(a.y * precision)},${Math.round(a.z * precision)}`
            hashes[1] = `${Math.round(b.x * precision)},${Math.round(b.y * precision)},${Math.round(b.z * precision)}`
            hashes[2] = `${Math.round(c.x * precision)},${Math.round(c.y * precision)},${Math.round(c.z * precision)}`

            if (hashes[0] === hashes[1] || hashes[1] === hashes[2] || hashes[2] === hashes[0]) {
                continue
            }

            for (let j = 0; j < 3; j++) {
                const jNext = (j + 1) % 3
                const vecHash0 = hashes[j]
                const vecHash1 = hashes[jNext]
                const v0 = triVertices[j]
                const v1 = triVertices[jNext]

                const hash = `${vecHash0}_${vecHash1}`
                const reverseHash = `${vecHash1}_${vecHash0}`

                const existing = edgeData.get(reverseHash)

                if (existing) {
                    if (existing.faceID !== faceID) {
                        vertices.push(v0.x, v0.y, v0.z)
                        vertices.push(v1.x, v1.y, v1.z)
                    }

                    edgeData.delete(reverseHash)
                } else {
                    const cached = edgeData.get(hash)
                    if (!cached) {
                        edgeData.set(hash, {
                            index0: indexArr[j],
                            index1: indexArr[jNext],
                            faceID,
                        })
                    }
                }
            }
        }

        // iterate over all remaining, unmatched edges and add them to the vertex array
        for (const edge of edgeData.values()) {
            const {index0, index1} = edge

            setVector(a, index0)
            setVector(b, index1)

            vertices.push(a.x, a.y, a.z)
            vertices.push(b.x, b.y, b.z)
        }

        this.setAttribute("position", new THREE.Float32BufferAttribute(vertices, 3))
    }

    override copy(source: MeshBuffersWireframGeometry): this {
        super.copy(source)

        this.parameters = Object.assign({}, source.parameters)

        return this
    }
}
