import {Component, EventEmitter, Inject, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MovePictureDialogProjectFragment} from "@api"
import {IsNonNull} from "@cm/lib/utils/filter"
import {UtilsService} from "@legacy/helpers/utils"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {from} from "rxjs"

@Component({
    selector: "cm-move-picture-dialog",
    templateUrl: "./move-picture-dialog.component.html",
    styleUrls: ["./move-picture-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogContent, MatDialogTitle, MatInputModule, FormsModule, MatSelectModule, MatDialogActions, MatButtonModule],
})
export class MovePictureDialogComponent implements OnInit {
    onConfirm = new EventEmitter<MoveCopyPictureDialogResult>()
    project?: MovePictureDialogProjectFragment
    projects: MovePictureDialogProjectFragment[] = []
    action: Action
    actions = Action
    result: MoveCopyPictureDialogResult
    loadingSets = false

    constructor(
        public dialogRef: MatDialogRef<MovePictureDialogComponent>,
        public authService: AuthService,
        public utils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public data: MoveCopyPictureDialogResult & {action: Action},
        public snackBar: MatSnackBar,
        private sdk: SdkService,
        public organizations: OrganizationsService,
    ) {
        this.action = data.action
        this.result = {
            organizationId: data.organizationId,
            setId: data.setId,
        }
    }

    ngOnInit(): void {
        this.loadProjects(this.result.organizationId)
    }

    loadProjects(organizationId: string): void {
        from(this.sdk.gql.movePictureProjects({filter: {organizationId: {equals: organizationId}}, take: 1000})).subscribe({
            next: ({projects}) => {
                this.projects = projects.filter(IsNonNull)
                for (const project of this.projects) {
                    for (const set of project.sets) {
                        if (this.result.setId === set.id) {
                            this.project = project
                        }
                    }
                }
            },
            error: () => this.snackBar.open("Cannot load projects.", "", {duration: 3000}),
        })
    }

    confirmAction(): void {
        this.onConfirm.emit(this.result)
        this.dialogRef.close(true)
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }
}

export enum Action {
    MOVE_PICTURE,
    COPY_SCENE,
}

export type MoveCopyPictureDialogResult = {
    organizationId: string
    setId: string
}
