import {Component, EventEmitter, Input, Output} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {TextureEditorDataObjectFragment} from "@api"
import {ActionBarComponent, ActionItemComponent} from "@common/components/menu"
import {FilesService} from "@common/services/files/files.service"
import {AuthService} from "@common/services/auth/auth.service"

@Component({
    selector: "cm-tiling-controls",
    templateUrl: "./tiling-controls.component.html",
    styleUrls: ["./tiling-controls.component.scss"],
    standalone: true,
    imports: [ActionItemComponent, ActionBarComponent, MatMenuModule],
})
export class TilingControlsComponent {
    @Input() dataObject: TextureEditorDataObjectFragment | undefined = undefined
    @Input() gridActive = false
    @Input() canDownload = true
    @Input() brightnessBoostActive = false

    @Output() zoomClick = new EventEmitter<void>()
    @Output() gridClick = new EventEmitter<void>()
    @Output() brightnessBoostToggle = new EventEmitter<void>()

    constructor(private authService: AuthService) {}

    get isStaff(): boolean {
        return this.authService.isStaff()
    }

    protected downloadDataObject(originalFileName: string, downloadUrl: string): void {
        FilesService.downloadFile(originalFileName, downloadUrl)
    }
}
