import {animate, style, transition, trigger} from "@angular/animations"

import {Component, Input} from "@angular/core"
import {provideAnimations} from "@angular/platform-browser/animations"
import {LoadedDataItem} from "@platform/models/data"

@Component({
    selector: "cm-table-cell",
    standalone: true,
    imports: [],
    templateUrl: "./table-cell.component.html",
    styleUrl: "./table-cell.component.scss",
    animations: [
        trigger("fadeInPlaceholder", [transition("void => *", [style({opacity: 0.4, scale: 0.7}), animate("1000ms", style({opacity: 1, scale: 0.98}))])]),
    ],
    providers: [provideAnimations()],
})
export class TableCellComponent<EntityType> {
    @Input() item?: LoadedDataItem<EntityType> = undefined
    @Input() centered = false
    @Input() header = false
}
