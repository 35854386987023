import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import * as paper from "paper"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class SmoothingIndicatorToolboxItem extends CanvasBaseToolboxItemBase {
    constructor(parent: CanvasBaseToolboxItem) {
        super(parent)
        this._group = new paper.Group()
        this.updateGfx()

        this.viewChange.subscribe(() => this.updateGfx())
    }

    set position(value: Vector2Like) {
        if (this._position.equals(value)) {
            return
        }
        this._position.setFromVector2Like(value)
        this.updateGfx()
    }

    get position(): Vector2 {
        return this._position
    }

    set angle(value: number) {
        if (this._angle === value) {
            return
        }
        this._angle = value
        this.updateGfx()
    }

    get angle(): number {
        return this._angle
    }

    set width(value: number) {
        if (this._width === value) {
            return
        }
        this._width = value
        this.updateGfx()
    }

    get width(): number {
        return this._width
    }

    set height(value: number) {
        if (this._height === value) {
            return
        }
        this._height = value
        this.updateGfx()
    }

    get height(): number {
        return this._height
    }

    private updateGfx() {
        const lineWidth = window.devicePixelRatio / this.zoomLevel
        const halfLineLengthH = this._width / 2
        const halfLineLengthV = this._height / 2

        this._group.removeChildren()

        const color = new paper.Color(1, 0, 0)
        const crosshairLineH = new paper.Path.Line(new paper.Point(-halfLineLengthH, 0), new paper.Point(halfLineLengthH, 0))
        crosshairLineH.strokeColor = color
        crosshairLineH.strokeWidth = lineWidth
        this._group.addChild(crosshairLineH)

        const crosshairLineV = new paper.Path.Line(new paper.Point(0, -halfLineLengthV), new paper.Point(0, halfLineLengthV))
        crosshairLineV.strokeColor = color
        crosshairLineV.strokeWidth = lineWidth
        this._group.addChild(crosshairLineV)

        if (halfLineLengthH > 0 && halfLineLengthV > 0) {
            const ellipse = new paper.Path.Ellipse(new paper.Rectangle(-halfLineLengthH, -halfLineLengthV, halfLineLengthH * 2, halfLineLengthV * 2))
            ellipse.strokeColor = color
            ellipse.strokeWidth = lineWidth
            this._group.addChild(ellipse)
        } else {
            if (halfLineLengthH > 0) {
                const minLine = new paper.Path.Line(new paper.Point(-halfLineLengthH, -100000), new paper.Point(-halfLineLengthH, 100000))
                minLine.strokeColor = color
                minLine.strokeWidth = lineWidth
                this._group.addChild(minLine)
                const maxLine = new paper.Path.Line(new paper.Point(halfLineLengthH, -100000), new paper.Point(halfLineLengthH, 100000))
                maxLine.strokeColor = color
                maxLine.strokeWidth = lineWidth
                this._group.addChild(maxLine)
            }
            if (halfLineLengthV > 0) {
                const minLine = new paper.Path.Line(new paper.Point(-100000, -halfLineLengthV), new paper.Point(100000, -halfLineLengthV))
                minLine.strokeColor = color
                minLine.strokeWidth = lineWidth
                this._group.addChild(minLine)
                const maxLine = new paper.Path.Line(new paper.Point(-100000, halfLineLengthV), new paper.Point(100000, halfLineLengthV))
                maxLine.strokeColor = color
                maxLine.strokeWidth = lineWidth
                this._group.addChild(maxLine)
            }
        }

        this._group.matrix = new paper.Matrix().translate(this._position).rotate(this._angle, Vector2.zero)
    }

    private _group: paper.Group
    private _position = new Vector2(0, 0)
    private _angle = 0 // in degrees CCW
    private _width = 40
    private _height = 40
}
