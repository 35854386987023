import {DeclareMaterialNode, cyclesNode, materialSlots} from "@src/materials/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {z} from "zod"
import {color, vec3} from "@src/materials/types"
import {getDefaultMaterial} from "@src/materials/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode, threeVec3Node} from "@src/materials/three-utils"
import {getAll} from "@src/graph-system/utils"

export class BsdfTranslucent extends DeclareMaterialNode(
    {
        returns: z.object({bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)}),
        inputs: z.object({
            color: materialSlots.optional(),
            normal: materialSlots.optional(),
        }),
        parameters: z.object({
            color: color.optional(),
            normal: vec3.optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, normal} = await getAll(inputs, get)
            const material = getDefaultMaterial()

            material.normalNode =
                normal ?? threeConvert(parameters.normal, threeVec3Node, (val) => val.x !== 0 || val.y !== 0 || val.z !== 0) ?? material.normalNode

            const transmissionValue =
                color ?? threeConvert(parameters.color, threeRGBColorNode, (val) => val.r > 0.05 || val.g > 0.05 || val.b > 0.05) ?? material.transmissionNode

            if (transmissionValue) {
                material.transparent = true
                material.transmissionNode = transmissionValue
                //@ts-ignore
                material.transmission = 0.01 //This is just to indicate to three that the material is translucent
            }

            return {bsdf: material}
        },
    },
) {}
