import {Component, EventEmitter, Input, Output} from "@angular/core"

@Component({
    selector: "cm-list-item",
    templateUrl: "./list-item.component.html",
    styleUrls: ["./list-item.component.scss"],
    host: {
        "[class.cm-list-item-selected]": "selected",
        "[class.cm-list-item-disabled]": "disabled",
        "[class.cm-list-item-highlighted]": "highlighted",
    },
    standalone: true,
})
export class ListItemComponent {
    @Input() selected = false
    @Input() disabled = false
    @Input() highlighted = false
    @Input() expandable = false
    @Input() expanded = false
    @Output() arrowToggle: EventEmitter<null> = new EventEmitter()
}
