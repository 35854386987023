import {IMaterialNode, IMaterialConnection, IMaterialGraph, isMaterialGraph} from "@src/materials/material-node-graph"
import {TextureResolution} from "../nodes/scene-properties"

export {IMaterialNode, IMaterialConnection, IMaterialGraph, isMaterialGraph}

export type MaterialSide = "front" | "back" | "double"

export interface IMaterialData {
    name: string
    materialGraph: IMaterialGraph
    side: MaterialSide
    alphaMaskThreshold?: number
    realtimeSettings?: {
        disable?: boolean
        textureResolution?: TextureResolution
        textureFiltering?: "linear" | "nearest"
    }
}

export interface IMaterialRevision {
    id: number
}

export interface IMaterial {
    id: number
    getLatestCyclesRevision(): IMaterialRevision | undefined
}

export function keyForMaterialData(materialData: IMaterialData) {
    return `${materialData.materialGraph.uniqueId},side:${materialData.side},alphaMaskThreshold:${materialData.alphaMaskThreshold ?? 0},disable:${materialData.realtimeSettings?.disable ?? false},textureResolution:${materialData.realtimeSettings?.textureResolution ?? "auto"},textureFiltering:${materialData.realtimeSettings?.textureFiltering ?? "auto"}`
}
