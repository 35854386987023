// @ts-strict-ignore
import {NgTemplateOutlet} from "@angular/common"
import {Component, Input, OnInit} from "@angular/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {NodeDescriptors} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"

@Component({
    selector: "cm-generic-node-inspector",
    templateUrl: "./generic-node-inspector.component.html",
    styleUrls: ["./generic-node-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        InputContainerComponent,
        MatTooltipModule,
        NumericInputComponent,
        NodeReferenceSlotComponent,
        NgTemplateOutlet,
        ToggleComponent,
        MatInputModule,
        MatSelectModule,
    ],
})
export class GenericNodeInspectorComponent implements OnInit {
    @Input() editor: IEditor
    @Input() node: Nodes.Node

    constructor() {}

    ngOnInit(): void {}

    get descriptor(): any {
        return (NodeDescriptors as any)[this.node.type]
    }

    nodeChanged(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
