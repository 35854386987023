import {CommonModule} from "@angular/common"
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from "@angular/core"
import {Subject} from "rxjs"

export type GridSize = "large" | "medium" | "small" | "tiny"

@Component({
    selector: "cm-infinite-list",
    templateUrl: "./infinite-list.component.html",
    styleUrls: ["./infinite-list.component.scss"],
    standalone: true,
    imports: [CommonModule],
})
export class InfiniteListComponent implements AfterViewInit, OnDestroy {
    // The grid type is used for the card based views, the table for row based display of items.
    @Input() listType: "grid" | "table" = "grid"
    @Input() gridSize: GridSize = "large"

    // use 0 for no items, undefined for loading state
    @Input() count: number | undefined = 0

    // called when the intersection status changes
    // true for intersecting, false for not intersecting
    @Input() intersectionStatusChange!: Subject<boolean>

    @Output() createItemEvent: EventEmitter<void> = new EventEmitter<void>()

    @Input() container?: HTMLElement
    @Input() containerSelector?: string

    // an intersection observer positioned at the bottom of the list detects when we need to load more elements
    @ViewChild("cm_intersection_observer_trigger") intersectionObserverTrigger!: ElementRef
    private intersectionObserver!: IntersectionObserver

    ngAfterViewInit() {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                const latestEntry = entries?.[entries.length - 1]
                this.intersectionStatusChange?.next(!latestEntry?.isIntersecting)
            },
            {
                root: this.container ?? document.querySelector(this.containerSelector ?? ".cm-app-main-container"),
                rootMargin: "400px",
                threshold: [0, 1],
            },
        )
        intersectionObserver.observe(this.intersectionObserverTrigger.nativeElement)
    }

    ngOnDestroy() {
        this.intersectionObserver?.disconnect()
    }
}
