import {Injectable} from "@angular/core"
import {
    ArStatisticsFilterInput,
    CreateArStatisticsGQL,
    CreateSceneStatisticsGQL,
    GetArStatisticsGQL,
    GetSceneStatisticsGQL,
    MutationCreateArStatisticsInput,
    MutationCreateSceneStatisticsInput,
    SceneStatisticsFilterInput,
} from "@api"
import {map, Observable} from "rxjs"

@Injectable()
export class StatisticsService {
    constructor(
        private getSceneStatisticsGql: GetSceneStatisticsGQL,
        private createSceneStatisticsGql: CreateSceneStatisticsGQL,
        private getArStatisticsGql: GetArStatisticsGQL,
        private createArStatisticsGql: CreateArStatisticsGQL,
    ) {}

    getSceneViewStats(filter: SceneStatisticsFilterInput = {}): Observable<number> {
        return this.getSceneStatisticsGql.fetch({filter: filter}).pipe(map((result) => result.data!.sceneStatisticsItemsCount))
    }

    recordSceneView(templateId: string): Observable<{id: string}> {
        const input: MutationCreateSceneStatisticsInput = {templateId: templateId}
        return this.createSceneStatisticsGql.mutate({input: input}).pipe(map((result) => result.data!.createSceneStatistics))
    }

    getArViewStats(filter: ArStatisticsFilterInput = {}): Observable<number> {
        return this.getArStatisticsGql.fetch({filter: filter}).pipe(map((result) => result.data!.arStatisticsItemsCount))
    }

    recordArView(templateRevisionId: string, configString: string): Observable<{id: string}> {
        const input: MutationCreateArStatisticsInput = {templateRevisionId, configString: configString}
        return this.createArStatisticsGql.mutate({input: input}).pipe(map((result) => result.data!.createArStatistics))
    }
}
