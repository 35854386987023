@for (info of exports; track info) {
    <div class="cm-maps-export">
        <div class="cm-maps-export-main">
            <div [style.width]="'100%'">
                <cm-material-exports-list-detail
                    [info]="info"
                    [deleteMapsExport]="deleteMapsExport"
                    [renameMapsExport]="renameMapsExport"
                    [sourceInfoRequestToString]="sourceInfoRequestToString"
                ></cm-material-exports-list-detail>
            </div>
        </div>
    </div>
}
