import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeRGBColorNode} from "@src/materials/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class SeparateRGB extends DeclareMaterialNode(
    {
        returns: z.object({r: materialSlots, g: materialSlots, b: materialSlots}),
        inputs: z.object({image: materialSlots.optional()}),
        parameters: z.object({r: z.number().optional(), g: z.number().optional(), b: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const color = (await get(inputs.image)) ?? threeRGBColorNode({r: parameters.r ?? 0, g: parameters.g ?? 0, b: parameters.b ?? 0})
            return {r: new THREENodes.SplitNode(color, "r"), g: new THREENodes.SplitNode(color, "g"), b: new THREENodes.SplitNode(color, "b")}
        },
    },
) {}
