import {Injectable} from "@angular/core"
import {ActivatedRoute, ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from "@angular/router"

type StoredHandle = {handle: DetachedRouteHandle; lifetime: number}
const ROUTE_REUSE_KEY = "storeRouteForReuse"

@Injectable({
    providedIn: "root",
})
export class RouteStrategy implements RouteReuseStrategy {
    private storedHandles = new Map<string, StoredHandle>()

    static getUrlForRoute(route: ActivatedRouteSnapshot | ActivatedRoute) {
        const snapshot = route instanceof ActivatedRoute ? route.snapshot : route
        let urlStr = ""
        snapshot.pathFromRoot
            .filter((u) => u.url)
            .map((u) => {
                u.url.map((segment) => {
                    urlStr = `${urlStr}/${segment.path}`
                })
            })
        return urlStr
    }

    static markRouteForReuse(route: ActivatedRoute, duration?: number) {
        route.snapshot.data[ROUTE_REUSE_KEY] = {duration: duration ?? 2}
    }

    /** Determines if this route (and its subtree) should be detached to be reused later */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data && ROUTE_REUSE_KEY in route.data
    }

    /**
     * Stores the detached route.
     *
     * Storing a `null` value should erase the previously stored value.
     */
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        const routeUrl = RouteStrategy.getUrlForRoute(route)
        if (this.storedHandles.has(routeUrl) && detachedTree === null) {
            this.storedHandles.delete(routeUrl)
            return
        }
        this.storedHandles.set(routeUrl, {handle: detachedTree, lifetime: route.data[ROUTE_REUSE_KEY].duration})
    }

    /** Determines if this route (and its subtree) should be reattached */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const routeUrl = RouteStrategy.getUrlForRoute(route)
        return !!this.storedHandles.size && this.storedHandles.has(routeUrl)
    }

    /** Retrieves the previously stored route */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const routeUrl = RouteStrategy.getUrlForRoute(route)
        const storedHandle = this.storedHandles.get(routeUrl)
        return storedHandle?.handle ?? null
    }

    /** Determines if a route should be reused */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (this.storedHandles.size) this.updateStoredHandles()
        return future.routeConfig === curr.routeConfig
    }

    private updateStoredHandles() {
        const expired: string[] = []
        for (const key of this.storedHandles.keys()) {
            const handleInfo = this.storedHandles.get(key)
            if (handleInfo) {
                if (handleInfo.lifetime === 0) {
                    expired.push(key)
                    continue
                }
                handleInfo.lifetime--
                this.storedHandles.set(key, handleInfo)
            }
        }
        expired.map((key) => this.storedHandles.delete(key))
    }
}
