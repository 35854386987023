@if (materialNode) {
    <div>
        @if (!templateEditorMode) {
            <mat-checkbox [(ngModel)]="showNode" (ngModelChange)="showNodeChange.emit(showNode)" class="cm-show-node">
                {{ slotName }}
            </mat-checkbox>
        }
        @if (isMaterialReference(materialNode) && materialNode.allowOverride) {
            <mat-checkbox [ngModel]="true" (ngModelChange)="removeAllowOverride(materialNode)"> Allow override </mat-checkbox>
        }
        <div [style.pointer-events]="showNode ? 'inherit' : 'none'">
            <cm-grid-list [gridSize]="gridSizes.Small">
                <ng-container cm-grid-tiles>
                    @if (fetchMaterialFromRevisionId | memoize: this : getMaterialRevisionId(materialNode) | async; as material) {
                        <cm-entity-card [$entity]="material" [defaultSubtitle]="false" (click)="_chooseMaterial()">
                            <ng-container cm-bottom-left>
                                @if (material.cyclesRevisions; as cyclesRevisions) {
                                    @if (cyclesRevisions.length > 0) {
                                        <div class="cm-revision-container">
                                            <div class="cm-pick-revision" (click)="$event.stopPropagation()">
                                                <ng-container>
                                                    <div
                                                        [matMenuTriggerFor]="revisionMenu"
                                                        [style.pointer-events]="cyclesRevisions.length > 1 ? 'auto' : 'none'"
                                                        class="cm-texture-revision"
                                                    >
                                                        v{{ getCurrentRevisionNumber | memoize: this : getMaterialRevisionId(materialNode) | async }}
                                                        @if (cyclesRevisions.length > 1) {
                                                            <i class="fa-regular fa-clock-rotate-left cm-revision-icon"></i>
                                                        }
                                                    </div>
                                                    <mat-menu #revisionMenu="matMenu">
                                                        @for (revision of cyclesRevisions; track revision) {
                                                            <button mat-menu-item (click)="updateToSpecificRevision(material, revision.legacyId)">
                                                                v{{ revision.number }}
                                                            </button>
                                                        }
                                                    </mat-menu>
                                                </ng-container>
                                            </div>
                                            @if (
                                                (getCurrentRevisionNumber | memoize: this : getMaterialRevisionId(materialNode) | async) !=
                                                material.latestCyclesRevision?.number
                                            ) {
                                                <div
                                                    (click)="$event.stopPropagation(); updateToLatestCyclesRevision(material)"
                                                    class="cm-material-card-new-revision-update"
                                                >
                                                    Update
                                                    <i class="far fa-arrow-up"></i>
                                                </div>
                                            }
                                        </div>
                                    }
                                }
                            </ng-container>
                        </cm-entity-card>
                    } @else {
                        <cm-entity-card [defaultThumbnail]="false" [defaultTitle]="false" (click)="_chooseMaterial()">
                            <ng-container cm-thumbnail>
                                <img class="cm-material-card-image" src="{{ noMaterialUrl }}" />
                            </ng-container>
                            <ng-container cm-title> (No Material.)</ng-container>
                        </cm-entity-card>
                    }
                </ng-container>
            </cm-grid-list>
        </div>
    </div>
}
