import {z} from "zod"
import {DataObjectDefinitions} from "@src/utils/data-object"

export type ImageFormat = DataObjectDefinitions.ImageFormat

const imageColorSpace_ = ["Gamma_2_0", "Gamma_2_2", "Linear", "Srgb", "Unknown"] as const
export const ImageColorSpaceSchema = z.enum(imageColorSpace_)
export const ImageColorSpace = ImageColorSpaceSchema.Enum
export type ImageColorSpace = z.infer<typeof ImageColorSpaceSchema>

// TODO unify that a bit with ./utils/data-object.ts. Also defined in template nodes
const thumbnailResolution_ = ["original", "2000px", "1000px", "500px"] as const
export const ThumbnailResolutionSchema = z.enum(thumbnailResolution_)
export const ThumbnailResolution = ThumbnailResolutionSchema.Enum
export type ThumbnailResolution = z.infer<typeof ThumbnailResolutionSchema>

const mediaType_ = [
    "application/zip",
    "image/jpeg",
    "image/tiff",
    "image/png",
    "image/bmp",
    "image/x-exr",
    "image/exr",
    "application/octet-stream",
    "application/draco",
    "application/ply",
] as const
export const MediaTypeSchema = z.enum(mediaType_)
export const MediaType = MediaTypeSchema.Enum
export type MediaType = z.infer<typeof MediaTypeSchema>

export enum DownloadFileType {
    Exr = "exr",
    Jpg = "jpg",
    Png = "png",
    Tiff = "tiff",
}

export function mapImageFormatToDownloadFileType(format: ImageFormat): DownloadFileType {
    switch (format) {
        case "exr":
            return DownloadFileType.Exr
        case "jpg":
            return DownloadFileType.Jpg
        case "png":
            return DownloadFileType.Png
        case "tif":
            return DownloadFileType.Tiff
        default:
            throw Error(`Unsupported format ${format}`)
    }
}

export enum DownloadResolution {
    High = "High",
    Medium = "Medium",
    Low = "Low",
    Tiny = "Tiny",
}

export function mapImageResolutionToDownloadResolution(resolution: ThumbnailResolution): DownloadResolution {
    switch (resolution) {
        case "original":
            return DownloadResolution.High
        case "2000px":
            return DownloadResolution.Medium
        case "1000px":
            return DownloadResolution.Low
        case "500px":
            return DownloadResolution.Tiny
        default:
            throw Error(`Unsupported resolution ${resolution}`)
    }
}

export function mapDownloadResolutionToImageResolution(resolution: DownloadResolution): ThumbnailResolution {
    switch (resolution) {
        case DownloadResolution.High:
            return "original"
        case DownloadResolution.Medium:
            return "2000px"
        case DownloadResolution.Low:
            return "1000px"
        case DownloadResolution.Tiny:
            return "500px"
        default:
            throw Error(`Unsupported resolution ${resolution}`)
    }
}
