import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ChannelLayout, ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {Color, ColorLike} from "@cm/lib/math/color"
import {TypedImageData} from "@cm/lib/image-processing/image-processing-actions"
import {assertNever} from "@cm/lib/utils/utils"
import {getImgProcFormat, toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"

export type ParameterType = {
    descriptor: ImageDescriptor
    fillColor?: ColorLike // default: (0,0,0,1)
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpCreateImage: ImageOpType<ParameterType, ReturnType> = {
    name: "CreateImage",

    WebGL2: async ({context, parameters: {descriptor, fillColor, resultImage}}) => {
        const halFill = await context.getOrCreateImageCompositor(`
            uniform vec4 u_fillColor;

            vec4 computeColor(ivec2 targetPixel) {
                return u_fillColor;
            }
        `)
        resultImage = await context.prepareResultImage(resultImage, descriptor)
        using resultImageWebGl2 = await context.getImage(resultImage)
        halFill.setParameter("u_fillColor", {type: "float4", value: {x: fillColor?.r ?? 0, y: fillColor?.g ?? 0, z: fillColor?.b ?? 0, w: fillColor?.a ?? 1}})
        await halFill.paint(resultImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async ({context, parameters: {descriptor, fillColor, resultImage}}) => {
        const getImgProcColorSpace = (_descriptor: ImageDescriptor): TypedImageData["colorSpace"] => {
            return "linear"
        }
        const getColorFromChannelLayout = (channelLayout: ChannelLayout, fillColor: ColorLike): number | Nodes.RGBColor | Nodes.RGBAColor => {
            switch (channelLayout) {
                case "R":
                    return fillColor.r
                case "RGB":
                    return [fillColor.r, fillColor.g, fillColor.b]
                case "RGBA":
                    return [fillColor.r, fillColor.g, fillColor.b, fillColor.a ?? 1]
                default:
                    assertNever(channelLayout)
            }
        }
        const resultNode = Nodes.createImage(
            descriptor.width,
            descriptor.height,
            getImgProcFormat(descriptor.format),
            getImgProcColorSpace(descriptor),
            getColorFromChannelLayout(descriptor.channelLayout, fillColor ?? new Color(0, 0, 0, 1)),
        )
        using result = await context.createImage(descriptor, resultNode)
        return await toImgProcResultImage(context, result, resultImage)
    },
}
