<div class="cm-container">
    <ng-content select="[cm-right-menu]"></ng-content>
    @if (salesEnquiryEnabled) {
        <a
            [class.cm-icon-container]="!showCustomMenu"
            [class.cm-sales-text-container]="showCustomMenu"
            (click)="salesDialogOpen = true"
            tp="Send enquiry"
            [tpAppendTo]="tooltipContainer"
        >
            @if (!showCustomMenu) {
                <i class="far fa-envelope"></i>
            } @else {
                @if (showCustomMenu) {
                    <i class="far fa-envelope"></i>
                    <span class="cm-sales-text">Send Enquiry</span>
                }
            }

            <div
                [tp]="salesInquiryDialog"
                tpVariation="popper"
                [tpAppendTo]="tooltipContainer"
                [tpHideOnClick]="false"
                [tpIsVisible]="salesDialogOpen"
                [tpOffset]="tpOffset"
            ></div>
            <ng-template #salesInquiryDialog let-hide>
                <cm-sales-enquiry-dialog (close)="salesDialogOpen = false" (submitEmail)="submitSalesEnquiry($event)"> </cm-sales-enquiry-dialog>
            </ng-template>
        </a>
    }
    @if (pdfGenerationEnabled) {
        <a class="cm-icon-container" (click)="downloadPdf.emit()" tp="Download PDF" [tpAppendTo]="tooltipContainer">
            <i class="far fa-file-pdf"></i>
        </a>
    }
    @if (stlDownloadEnabled) {
        <a class="cm-icon-container" (click)="exportStlClicked()" tp="Download STL" [tpAppendTo]="tooltipContainer">
            <i class="far fa-arrow-to-bottom"></i>
        </a>
    }
    @if (gltfDownloadEnabled) {
        <a class="cm-icon-container" (click)="downloadGltf.emit()" tp="Download glTF" [tpAppendTo]="tooltipContainer">
            <i class="far fa-arrow-to-bottom"></i>
        </a>
    }
    @if (snapshotEnabled) {
        <a class="cm-icon-container" (click)="saveSnapshot.emit()" tp="Capture snapshot" [tpAppendTo]="tooltipContainer">
            <i class="far fa-camera"></i>
        </a>
    }
    @if (fullscreenEnabled) {
        <a
            class="cm-icon-container"
            (click)="isFullscreenActive = !isFullscreenActive; toggleFullscreen.emit()"
            [tp]="isFullscreenActive ? 'Exit full screen' : 'Full screen'"
            [tpAppendTo]="tooltipContainer"
        >
            <i [ngClass]="isFullscreenActive ? 'far fa-compress' : 'far fa-expand'"></i>
        </a>
    }
    @if (arEnabled) {
        <a class="cm-icon-container" (click)="viewInArClicked()" tp="View in AR" [tpAppendTo]="tooltipContainer">
            <i class="far fa-cube"></i>
            <span class="cm-ar-icon-text"> AR </span>
            <div
                [tp]="arDialog"
                tpVariation="popper"
                [tpAppendTo]="tooltipContainer"
                [tpHideOnClick]="false"
                [tpIsVisible]="qrCodeDialogOpen"
                [tpOffset]="tpOffset"
            ></div>
            <ng-template #arDialog let-hide>
                <cm-ar-dialog (close)="closeArDialogClicked()" [gcsObjectName]="gltfGcsObjectName"> </cm-ar-dialog>
            </ng-template>
        </a>
    }
</div>
<div class="tooltip-container" #tooltipContainer></div>
