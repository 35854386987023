import {inject} from "@angular/core"
import {ApolloLink, HttpLink} from "@apollo/client/core"
import {setContext} from "@apollo/client/link/context"
import {onError} from "@apollo/client/link/error"
import {TokenService} from "@common/services/auth/token.service"
import {Settings} from "@common/models/settings/settings"
import {CM_COLORMASS_CLIENT} from "@common/modules/client-injector"

const uri = Settings.GQL_API_ENDPOINT

export const getHttpLink = (): ApolloLink => {
    const httpLink = new HttpLink({
        uri,
    })

    const colormassClient = inject(CM_COLORMASS_CLIENT)
    const client = setContext(() => ({
        headers: {
            "X-Colormass-Client": colormassClient,
        },
    }))

    // Get the authentication token from local storage if it exists
    const tokenService = inject(TokenService)
    const auth = setContext(() => {
        const token = tokenService.load()
        if (token) {
            return {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        }
        return {}
    })

    const errorLink = onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
            graphQLErrors.map(({message, locations, path}) => {
                console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
            })
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`)
        }
    })

    return ApolloLink.from([client, auth, errorLink, httpLink])
}
