<div [class.cm-square-container]="squareFormat">
    <div class="cm-card" (click)="onSelectItem()">
        <div class="cm-content">
            <a [routerLink]="link" [queryParamsHandling]="queryParamsHandling" class="cm-picture-container" [class.cm-disabled-label]="disabled">
                @if (defaultThumbnail) {
                    <cm-thumbnail [url]="thumbnailUrl" class="cm-picture" draggable="false"></cm-thumbnail>
                }
                <ng-content select="[cm-thumbnail]"></ng-content>
            </a>
            <div class="cm-content-top-left">
                <ng-content select="[cm-top-left]"></ng-content>
            </div>
            <div class="cm-content-top-right">
                <ng-content select="[cm-top-right]"></ng-content>
            </div>
            <div class="cm-content-bottom-left">
                <ng-content select="[cm-bottom-left]"></ng-content>
            </div>
            <div class="cm-content-bottom-right">
                <ng-content select="[cm-bottom-right]"></ng-content>
            </div>
        </div>
        <div class="cm-details">
            <div class="cm-title" [class.cm-disabled-label]="disabled">
                <ng-content select="[cm-title]"></ng-content>
            </div>
            <div class="cm-subtitle" [class.cm-disabled-label]="disabled">
                <ng-content select="[cm-subtitle]"></ng-content>
            </div>
            <div (click)="$event.stopPropagation()" class="cm-details-bottom-right">
                <ng-content select="[cm-details-bottom-right]"></ng-content>
            </div>
        </div>
    </div>
</div>
