import {imageOpCreateImagePyramid} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-create-image-pyramid"
import {Vector2} from "@cm/lib/math/vector2"
import {crossCorrelate} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/cross-correlation"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {DebugImage} from "@app/textures/texture-editor/operator-stack/image-op-system/util/debug-image"
import {Box2, Box2Like} from "@cm/lib/math/box2"

export const hierarchicalCrossCorrelation = async (
    imageOpContextWebGL2: ImageOpContextWebGL2,
    sourceImage: ImagePtr,
    sourceRegion: Box2Like | undefined,
    templateImage: ImagePtr,
    templateRegion: Box2Like | undefined,
    correlationWindowSize: number,
    searchSize: number,
    debugImage?: DebugImage,
    debugRectFn?: (rect: Box2Like, color: string) => void,
) => {
    sourceRegion ??= Box2.fromSize(await imageOpContextWebGL2.getImageDescriptor(sourceImage))
    templateRegion ??= Box2.fromSize(await imageOpContextWebGL2.getImageDescriptor(templateImage))
    const numLevels =
        Math.floor(Math.log2(Math.min(sourceRegion.width, sourceRegion.height, templateRegion.width, templateRegion.height) / correlationWindowSize)) + 1

    // create template image pyramid
    using templateImagePyramid = await imageOpCreateImagePyramid.WebGL2({
        context: imageOpContextWebGL2,
        parameters: {sourceImage: templateImage, sourceRegion: templateRegion, maxLevels: numLevels},
    })
    if (debugImage) {
        for (const image of templateImagePyramid) {
            await debugImage.addImage(image)
            const desc = await imageOpContextWebGL2.getImageDescriptor(image)
            console.log("Template pyramid level", desc.width, desc.height)
        }
    }

    // let sourceImagePyramid: ImageRef[]
    // if (this.falloffImage) {
    //     // cut out source image
    //     const sourceImage = await imageOpCopyRegion.WebGL2({
    //         context: imageOpContextWebGL2,
    //         parameters: {sourceImage: sourceImage, sourceRegion: sourceRegion},
    //     })
    //     // apply fall off
    //     const sourceImageWithFalloff = await imageOpMath.WebGL2({
    //         context: imageOpContextWebGL2,
    //         parameters: {operator: "*", operandA: sourceImage, operandB: this.falloffImage},
    //     })
    //     sourceImage.release()
    //     // create source image pyramid
    //     sourceImagePyramid = await imageOpCreateImagePyramid.WebGL2({
    //         context: imageOpContextWebGL2,
    //         parameters: {sourceImage: sourceImageWithFalloff, maxLevels: numLevels},
    //     })
    //     sourceImageWithFalloff.release()
    // } else {
    using sourceImagePyramid = await imageOpCreateImagePyramid.WebGL2({
        context: imageOpContextWebGL2,
        parameters: {sourceImage: sourceImage, sourceRegion, maxLevels: numLevels},
    })
    // }
    if (debugImage) {
        for (const image of sourceImagePyramid) {
            await debugImage.addImage(image)
            const desc = await imageOpContextWebGL2.getImageDescriptor(image)
            console.log("Source pyramid level", desc.width, desc.height)
        }
    }

    const sourceOffset = new Vector2(0, 0)
    for (let level = numLevels - 1; level >= 0; level--) {
        const levelScale = 2 ** level
        // cross-correlation
        const templateOffset = new Vector2(
            templateRegion.width / levelScale / 2 - correlationWindowSize / 2,
            templateRegion.height / levelScale / 2 - correlationWindowSize / 2,
        )
        if (debugRectFn) {
            debugRectFn(
                {
                    x: sourceRegion.x + (sourceOffset.x << level),
                    y: sourceRegion.y + (sourceOffset.y << level),
                    width: (correlationWindowSize + searchSize - 1) << level,
                    height: (correlationWindowSize + searchSize - 1) << level,
                },
                "blue",
            )
        }

        const {peakOffset, peakValue} = await crossCorrelate(
            imageOpContextWebGL2,
            sourceImagePyramid[level],
            {
                x: sourceOffset.x,
                y: sourceOffset.y,
                width: correlationWindowSize + searchSize - 1,
                height: correlationWindowSize + searchSize - 1,
            },
            templateImagePyramid[level],
            {x: templateOffset.x, y: templateOffset.y, width: correlationWindowSize, height: correlationWindowSize},
            debugImage,
        )
        if (debugImage) {
            console.log("Peak found at level", level, ":", peakOffset, peakValue)
        }

        sourceOffset.addInPlace(peakOffset)

        if (debugRectFn) {
            // draw peak box
            debugRectFn(
                {
                    x: sourceRegion.x + (sourceOffset.x << level),
                    y: sourceRegion.y + (sourceOffset.y << level),
                    width: correlationWindowSize << level,
                    height: correlationWindowSize << level,
                },
                "lightblue",
            )
            debugRectFn(
                {
                    x: templateRegion.x + (templateOffset.x << level),
                    y: templateRegion.y + (templateOffset.y << level),
                    width: correlationWindowSize << level,
                    height: correlationWindowSize << level,
                },
                "lightgreen",
            )
        }

        if (level === 0) {
            break
        }

        sourceOffset.mulInPlace(2)
        sourceOffset.addInPlace(Math.ceil(correlationWindowSize / 2 - searchSize / 2))
    }

    return sourceOffset.add(sourceRegion).add(correlationWindowSize / 2)
}
