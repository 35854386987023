import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeVec3Node} from "@src/materials/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class SeparateXYZ extends DeclareMaterialNode(
    {
        returns: z.object({x: materialSlots, y: materialSlots, z: materialSlots}),
        inputs: z.object({vector: materialSlots.optional()}),
        parameters: z.object({x: z.number().optional(), y: z.number().optional(), z: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const vector = (await get(inputs.vector)) ?? threeVec3Node({x: parameters.x ?? 0, y: parameters.y ?? 0, z: parameters.z ?? 0})
            return {x: new THREENodes.SplitNode(vector, "x"), y: new THREENodes.SplitNode(vector, "y"), z: new THREENodes.SplitNode(vector, "z")}
        },
    },
) {}
