import {Component, ViewChild} from "@angular/core"
import {TranslucentBsdfInputs, TranslucentBsdfOutputs} from "@app/material-editor/models/nodes/translucent-bsdf"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-translucent-bsdf-node",
    templateUrl: "./translucent-bsdf.component.html",
    styleUrls: ["./translucent-bsdf.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class TranslucentBsdfNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = TranslucentBsdfInputs
    outputs = TranslucentBsdfOutputs
    typeInfo = TranslucentBsdfNodeType
}

export const TranslucentBsdfNodeType: MaterialNodeType<typeof TranslucentBsdfNodeComponent> = {
    id: "translucentBsdf",
    label: "Translucent BSDF",
    color: "#4987af",
    name: "ShaderNodeBsdfTranslucent",
    inputs: [TranslucentBsdfInputs.color, TranslucentBsdfInputs.normal] as never[],
    outputs: [TranslucentBsdfOutputs.bsdf],
    component: TranslucentBsdfNodeComponent,
}
