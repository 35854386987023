<cm-node-base #nodeBase [inputs]="inputs" [outputs]="outputs" [typeInfo]="typeInfo" [settings]="settings">
    <ng-container cm-extra-content>
        @if (isDiffuseTextureSizeUpdated) {
            <div class="cm-revision-update" (click)="updateScaleToDiffuseTextureSize()">
                <i class="far fa-arrow-up"></i>
                Update scale to diffuse texture size
            </div>
        }
    </ng-container>
</cm-node-base>
