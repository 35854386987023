<div class="cm-image-viewer">
    @if (stage() !== "done") {
        <cm-loading-spinner class="cm-processing-overlay">
            <ng-container cm-text-bottom>
                @switch (stage()) {
                    @case ("loading") {
                        Loading...
                    }
                    @case ("processing") {
                        Processing image...
                    }
                    @case ("waitingRenderOutput") {
                        Waiting for rendering output...
                    }
                    @case ("waitingPostProcessingOutput") {
                        Waiting for finalized result...
                    }
                }
            </ng-container>
        </cm-loading-spinner>
    } @else {
        @if (relevantJobData(); as relevantJobData) {
            @if (isJobError(relevantJobData.job)) {
                <div class="cm-no-data">
                    <i class="cm-no-data-icon far fa-triangle-exclamation"></i>
                    <div class="cm-no-data-text">
                        {{ relevantJobData.type === "render" ? "Render job" : "Finalize job" }}
                        {{ relevantJobData.job.state === JobState.Cancelled ? " was cancelled" : " failed: " + relevantJobData.job.message }}
                    </div>
                    <cm-button
                        [disabled]="startingJob()"
                        (click)="relevantJobData.type === 'render' ? reRender() : reFinalizeRender()"
                        class="cm-render-button"
                    >
                        <ng-container cm-left-icon>
                            @if (startingJob()) {
                                <cm-loading-spinner-icon class="cm-mini-spinner"></cm-loading-spinner-icon>
                            } @else {
                                <i class="far fa-rotate-right"></i>
                            }
                        </ng-container>
                        <ng-container cm-text> Retry </ng-container>
                        <ng-container cm-left-icon> </ng-container>
                    </cm-button>
                </div>
            }
        } @else {
            <div class="cm-no-data">
                <i class="cm-no-data-icon far fa-image-slash"></i>
                <div [matTooltip]="renderSettings() ? undefined : 'Add a render-node to generate an image'">
                    <cm-button [disabled]="!renderSettings() || startingJob()" (click)="render()" class="cm-render-button">
                        <ng-container cm-left-icon>
                            @if (startingJob()) {
                                <cm-loading-spinner-icon class="cm-mini-spinner"></cm-loading-spinner-icon>
                            } @else {
                                <i class="far fa-image"></i>
                            }
                        </ng-container>
                        <ng-container cm-text> Render this variation </ng-container>
                        <ng-container cm-left-icon> </ng-container>
                    </cm-button>
                </div>
                @if (viewType() === "tree") {
                    <div class="cm-no-data-text">
                        To render more variations change to
                        <span (click)="viewTypeChanged.emit('allVariations')" class="cm-change-mode">All variations.</span>
                    </div>
                }
            </div>
        }
    }

    @if (postProcessedImageData(); as postProcessedImageData) {
        @if (renderInProgress(); as renderInProgress) {
            <div class="cm-show-progress">
                <div class="cm-render-progress-title">
                    Rendering in progress
                    <span class="cm-render-progress-percentage">{{ renderInProgress.progress ?? "?" }}%</span>
                    <i matTooltip="{{ renderInProgress.id }} - {{ renderInProgress.name }} - {{ renderInProgress.state }}" class="far fa-circle-info"></i>
                    <i matTooltip="Refresh preview (Automatically refreshes every 5 minutes)" (click)="refreshJobs()" class="far fa-refresh cm-pointer"></i>
                </div>
            </div>
        } @else if (relevantJobData()?.type === "postProcess") {
            <div class="cm-show-progress">
                <i class="far fa-palette" [matTooltip]="'Image is finalized'"></i>
            </div>
        }
        <cm-template-image-viewer-canvas #imageViewerCanvas [postProcessedImageData]="postProcessedImageData" (loadingComplete)="stage.set('done')">
            @if (stage() === "done") {
                <cm-template-image-viewer-controls [parent]="imageViewerCanvas" class="cm-viewer-controls">
                    <ng-container cm-action-items-pos1>
                        <cm-action-item [menuTriggerFor]="deleteMenu" tooltip="Delete" cmIconClass="far fa-trash" class="cm-delete"
                            ><mat-menu #deleteMenu>
                                <button (click)="deleteAll()" mat-menu-item>Delete image</button>
                                <button (click)="reRender()" mat-menu-item>Re-render image</button>
                                @if (availableJobIds().postProcess) {
                                    <button
                                        (click)="deletePostProcess()"
                                        matTooltip="This will only delete the finalized image, the preview will NOT be deleted.
            If you want to remove both images click on Delete image"
                                        mat-menu-item
                                    >
                                        Delete finalized image only
                                    </button>
                                }
                            </mat-menu></cm-action-item
                        >
                    </ng-container>
                    <ng-container cm-action-items-pos2>
                        @if (relevantJobData(); as relevantJobData) {
                            @if (relevantJobData.type === "render" && relevantJobData.job.state === JobState.Complete) {
                                <cm-button
                                    (click)="finalizeRender()"
                                    class="cm-finalize"
                                    tooltip="The image you see is only a preview. If you want to download the final .tif file you need to save the final version."
                                    [disabled]="startingJob()"
                                >
                                    <ng-container cm-left-icon>
                                        @if (startingJob()) {
                                            <cm-loading-spinner-icon class="cm-mini-spinner"></cm-loading-spinner-icon>
                                        } @else {
                                            <i class="far fa-save"></i>
                                        }
                                    </ng-container>
                                    <ng-container cm-text> Finalize </ng-container>
                                </cm-button>
                            }
                        }
                    </ng-container>
                </cm-template-image-viewer-controls>
            }
        </cm-template-image-viewer-canvas>
    }
</div>
