import {Injectable} from "@angular/core"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {CorsWorker} from "@editor/helpers/mesh-processing/CorsWorker"
import {WebWorker} from "@editor/helpers/mesh-processing/WebWorker"
import {environment} from "@environment"
import {WebAssemblyWorkerManager} from "@cm/lib/workers/webassembly-worker-manager"

export function spawnNewWebWorker(name: string): WebWorker {
    if (environment.useWebComponentConfigurator) {
        /**This worker is used for web components, which are subject to strict CORS, see comment in CorsWorker.ts
         * The url for the worker must be set explicitly, otherwise the worker's chunk file will be loaded from the origin that
         * embeds the web component, which is wrong. Important detail: When inlining the worker (i.e. providing it without chunk file),
         * the web assembly files must either be inlined or loaded by specifying locateFile. Otherwise, the files cannot be loaded. Currently,
         * It is internally assumed that the web assembly files are served from the origin's root. locateFile is already used for the web components.
         */
        const corsWorker = new CorsWorker(new URL(environment.webComponentConfiguratorUrl + "web-assembly-worker.js", import.meta.url), undefined)
        return new WebWorker(corsWorker.getWorker())
    } else {
        /** The following line is processed by webpack and must not change. The magic comment assigns a name to the chunk to make it referencable
         * in the web component worker above. The final url of the worker's chunk is determined by webpack and seems to resolve to the origin the worker
         * is used from. This does *not* work for web components: They are served from e.g. colormass.com, but are added to xyz.com. in this case,
         * the worker's chunk would be loaded from xyz.com. Note that Angular's base href has no influence on this. */
        const worker = new Worker(
            new URL(/* webpackChunkName: "web-assembly-worker" */ "app/common/helpers/web-assembly/web-assembly.worker", import.meta.url),
            {
                type: "module",
                name,
            },
        )
        return new WebWorker(worker)
    }
}

@Injectable({
    providedIn: "root",
})
export class WebAssemblyWorkerService extends WebAssemblyWorkerManager {
    constructor() {
        super(spawnNewWebWorker, isMobileDevice)
    }
}
