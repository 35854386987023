import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeValueNode} from "@src/materials/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {getAll} from "@src/graph-system/utils"

export class CombineXYZ extends DeclareMaterialNode(
    {
        returns: z.object({vector: materialSlots}),
        inputs: z.object({x: materialSlots.optional(), y: materialSlots.optional(), z: materialSlots.optional()}),
        parameters: z.object({x: z.number().optional(), y: z.number().optional(), z: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {x, y, z} = await getAll(inputs, get)
            const xValue = x ?? threeValueNode(parameters.x ?? 0)
            const yValue = y ?? threeValueNode(parameters.y ?? 0)
            const zValue = z ?? threeValueNode(parameters.z ?? 0)
            return {vector: new THREENodes.JoinNode([xValue, yValue, zValue])}
        },
    },
) {}
