@if (active && editing) {
    <div class="cm-surface-definer-card">
        <div class="cm-section-label">
            <span class="cm-selection-label-text">Surface definer</span>
        </div>
        <mat-checkbox [(ngModel)]="selectionModeTriangles">Triangle selection mode</mat-checkbox>
        <mat-checkbox [(ngModel)]="selectionModeCoplanarTriangles">Coplanar triangle selection mode</mat-checkbox>
        <mat-checkbox [(ngModel)]="surfaceModeProjection">Projection surface mode</mat-checkbox>
        <mat-checkbox [(ngModel)]="flipNormals">Flip Normals</mat-checkbox>
        <form class="cm-connection-form" [formGroup]="formGroupConnection" (ngSubmit)="onSubmit()">
            <div><span>Surface map overrides:</span></div>
            <div>
                <mat-form-field class="cm-input-field">
                    <mat-label>Centroid Offset X (horizontal):</mat-label>
                    <input formControlName="centroidOffsetX" autocomplete="off" matTextareaAutosize matInput />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="cm-input-field">
                    <mat-label>Centroid Offset Y (vertical):</mat-label>
                    <input formControlName="centroidOffsetY" autocomplete="off" matTextareaAutosize matInput />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="cm-input-field">
                    <mat-label>Scaling Z</mat-label>
                    <input formControlName="scaleZ" autocomplete="off" matTextareaAutosize matInput />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="cm-input-field">
                    <mat-label>Plane Rotation (around normal in degree):</mat-label>
                    <input formControlName="planeRotation" autocomplete="off" matTextareaAutosize matInput />
                </mat-form-field>
            </div>
            <button mat-icon-button class="cm-list-action-button" matTooltip="Update surface plane parameters" (click)="onSubmit()">Update</button>
        </form>
    </div>
}
