import * as THREE from "three"

export class ThreeShadowCatcher extends THREE.ShadowMaterial {
    private uniforms = {
        bias: {value: 0.0},
        falloffOffset: {value: new THREE.Vector3(0, 0, 0)},
        falloffScale: {value: new THREE.Vector3()},
        maskScale: {value: 0.0},
    }

    constructor() {
        super()
        this.resetFilterParameters()

        this.onBeforeCompile = (shader) => {
            shader.vertexShader =
                `varying vec3 vWPosition;\n` + shader.vertexShader.slice(0, -1) + `    vWPosition = ( modelMatrix * vec4( transformed, 1.0 ) ).xyz;\n` + `}`

            function addLineBelowGLFragColor(shaderCode: string, newLines: string) {
                const lines = shaderCode.split("\n")
                const index = lines.findIndex((line) => line.includes("gl_FragColor = "))
                if (index !== -1) lines.splice(index + 1, 0, newLines)

                return lines.join("\n")
            }

            shader.fragmentShader =
                `uniform float bias;\n` +
                `uniform vec3 falloffScale;\n` +
                `uniform vec3 falloffOffset;\n` +
                `uniform float maskScale;\n` +
                `varying vec3 vWPosition;\n` +
                addLineBelowGLFragColor(
                    shader.fragmentShader,
                    `    float alpha = gl_FragColor.a / opacity;\n` +
                        `    float mask = clamp((1.0 - length((vWPosition - falloffOffset) * falloffScale)) * maskScale, 0., 1.);\n` +
                        `    mask = smoothstep(0., 1., mask);\n` +
                        `    gl_FragColor = vec4(0., 0., 0., clamp(alpha * opacity - bias, 0., 1.) * mask);\n`,
                )

            shader.uniforms = {
                ...shader.uniforms,
                ...this.uniforms,
            }
        }
    }

    setFilterParameters(parameters: {sizeX: number; sizeZ: number; smoothness: number; opacity: number; bias: number}) {
        this.opacity = parameters.opacity
        this.uniforms.bias.value = parameters.bias
        this.uniforms.falloffScale.value = new THREE.Vector3(1 / parameters.sizeX, 0, 1 / parameters.sizeZ)
        this.uniforms.maskScale.value = 1.0 / parameters.smoothness
    }

    resetFilterParameters() {
        this.setFilterParameters({sizeX: 100000, sizeZ: 100000, smoothness: 1, opacity: 1, bias: 0.2})
    }
}
