export const NavigationHitOptions = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 1,
}

export const DrawingHitOptions = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 10,
}
