<div
    [style.display]="sidenavOpen ? 'flex' : 'none'"
    [style.position]="attachToParent ? 'absolute' : 'fixed'"
    (click)="backgroundClick.emit()"
    class="cm-backdrop"
>
    <div (click)="$event.stopPropagation()" class="cm-content-container">
        <ng-content select="[cm-content]"> </ng-content>
    </div>
</div>
