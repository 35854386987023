import * as THREENodes from "three/examples/jsm/nodes/Nodes"

const colorBurnNode = new THREENodes.FunctionNode(`
vec3 colorBurn(vec3 inputA, vec3 inputB, float f) {
    vec3 resultColor;
 
    float tmp = (1.0 - f) + f * inputB.x;
    if (tmp <= 0.0) {
        resultColor.x = 0.0;
    }
    else if ((tmp = (1.0 - (1.0 - inputA.x) / tmp)) < 0.0) {
        resultColor.x = 0.0;
    }
    else if (tmp > 1.0) {
        resultColor.x = 1.0;
    }
    else {
        resultColor.x = tmp;
    }
 
    tmp = (1.0 - f) + f * inputB.y;
    if (tmp <= 0.0) {
        resultColor.y = 0.0;
    }
    else if ((tmp = (1.0 - (1.0 - inputA.y) / tmp)) < 0.0) {
        resultColor.y = 0.0;
    }
    else if (tmp > 1.0) {
        resultColor.y = 1.0;
    }
    else {
        resultColor.y = tmp;
    }
 
    tmp = (1.0 - f) + f * inputB.z;
    if (tmp <= 0.0) {
        resultColor.z = 0.0;
    }
    else if ((tmp = (1.0 - (1.0 - inputA.z) / tmp)) < 0.0) {
        resultColor.z = 0.0;
    }
    else if (tmp > 1.0) {
        resultColor.z = 1.0;
    }
    else {
        resultColor.z = tmp;
    }
 
    return resultColor;
}
`)

export function colorBurn(inputA: THREENodes.Node, inputB: THREENodes.Node, fac: THREENodes.Node): THREENodes.Node {
    return THREENodes.call(colorBurnNode, [inputA, inputB, fac])
}

const colorDodgeNode = new THREENodes.FunctionNode(`
vec3 colorDodge(vec3 inputA, vec3 inputB, float f) {
    vec3 resultColor;
    float tmp;
 
    resultColor = inputA;
 
    if (inputA.x > 0.0) {
        tmp = 1.0 - f * inputB.x;
        if (tmp <= 0.0) {
            resultColor.x = 1.0;
        }
        else if ((tmp = (inputA.x / tmp)) > 1.0) {
            resultColor.x = 1.0;
        }
        else {
            resultColor.x = tmp;
        }
    }
 
    if (inputA.y > 0.0) {
        tmp = 1.0 - f * inputB.y;
        if (tmp <= 0.0) {
            resultColor.y = 1.0;
        }
        else if ((tmp = (inputA.y / tmp)) > 1.0) {
            resultColor.y = 1.0;
        }
        else {
            resultColor.y = tmp;
        }
    }
 
    if (inputA.z > 0.0) {
        tmp = 1.0 - f * inputB.z;
        if (tmp <= 0.0) {
            resultColor.z = 1.0;
        }
        else if ((tmp = (inputA.z / tmp)) > 1.0) {
            resultColor.z = 1.0;
        }
        else {
            resultColor.z = tmp;
        }
    }
 
    return resultColor;
}
`)

export function colorDodge(inputA: THREENodes.Node, inputB: THREENodes.Node, fac: THREENodes.Node): THREENodes.Node {
    return THREENodes.call(colorDodgeNode, [inputA, inputB, fac])
}

const overlayNode = new THREENodes.FunctionNode(`
vec3 overlay(vec3 inputA, vec3 inputB, float f) {
    vec3 resultColor;
    float invF = 1.0 - f;
 
    if (inputA.x < 0.5) {
        resultColor.x = inputA.x * (invF + 2.0 * f * inputB.x);
    }
    else {
        resultColor.x = 1.0 - (invF + 2.0 * f * (1.0 - inputB.x)) * (1.0 - inputA.x);
    }
 
    if (inputA.y < 0.5) {
        resultColor.y = inputA.y * (invF + 2.0 * f * inputB.y);
    }
    else {
        resultColor.y = 1.0 - (invF + 2.0 * f * (1.0 - inputB.y)) * (1.0 - inputA.y);
    }
 
    if (inputA.z < 0.5) {
        resultColor.z = inputA.z * (invF + 2.0 * f * inputB.z);
    }
    else {
        resultColor.z = 1.0 - (invF + 2.0 * f * (1.0 - inputB.z)) * (1.0 - inputA.z);
    }
 
    return resultColor;
}
`)

export function overlay(inputA: THREENodes.Node, inputB: THREENodes.Node, fac: THREENodes.Node): THREENodes.Node {
    return THREENodes.call(overlayNode, [inputA, inputB, fac])
}
