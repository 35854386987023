export class CmmMeta {
    originalFileSize?: number
    coordinateSystem?: number

    static fromJson(parsedJson: {originalFileSize: number; coordinate_system: number}): CmmMeta {
        const metaJson: CmmMeta = new CmmMeta()
        metaJson.originalFileSize = parsedJson.originalFileSize
        metaJson.coordinateSystem = parsedJson.coordinate_system
        return metaJson
    }
}
