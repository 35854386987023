<div class="node-flex-container">
    @if ($amountGroupNode()) {
        <div class="node-icon" matTooltip="Amount Group" matTooltipPosition="above">
            <i class="fa-solid fa-hashtag"></i>
            <i class="fa-solid fa-hashtag"></i>
        </div>
        <span class="node-text">{{ $description() }}</span>
        <div class="tools-right">
            <input class="input-width-limit" type="number" min="0" [(ngModel)]="$amountGroupNode().parameters.defaultAmount" />
        </div>
    }
</div>
