import {Box2, Box2Like} from "@cm/lib/math/box2"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {HalContext} from "@common/models/hal/hal-context"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPainterPrimitiveOptions} from "@common/models/hal/hal-painter-primitive/types"
import {HalPainter} from "@common/models/hal/hal-painter"
import {HalPainterParameterValueType} from "@app/common/models/hal/hal-painter/types"
import {createHalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor/create"

/**
 * Blits an image to a target image.
 * Blitting function is of the form:
 *      vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
 *          return texelFetch0(sourcePixel);
 *      }
 */

export class HalPainterImageBlit implements HalPainter {
    constructor(
        readonly context: HalContext,
        blittingFunction?: string,
    ) {
        this.imageCompositor = createHalPainterImageCompositor(this.context, this.getCompositingFunction(blittingFunction ?? DEFAULT_BLITTING_FUNCTION))
    }

    dispose(): void {
        this.imageCompositor.dispose()
    }

    setParameter(name: string, value: HalPainterParameterValueType): void {
        this.imageCompositor.setParameter(name, value)
    }

    async paint(target: HalPaintable, sourceImages: HalImage | [HalImage, ...HalImage[]], options?: HalPainterImageBlitOptions): Promise<void> {
        const primarySource = sourceImages instanceof Array ? sourceImages[0] : sourceImages
        const sourceRegion = options?.sourceRegion
            ? Box2.fromBox2Like(options?.sourceRegion)
            : Box2.fromPositionAndSize(
                  {
                      x: 0,
                      y: 0,
                  },
                  new Vector2(primarySource.descriptor.width, primarySource.descriptor.height),
              )
        const targetOffset = options?.targetOffset ?? {x: 0, y: 0}
        this.imageCompositor.setParameter("u_internal_blitSourceOffset", {
            type: "int2",
            value: new Vector2(sourceRegion.x - targetOffset.x, sourceRegion.y - targetOffset.y),
        })
        await this.imageCompositor.paint(target, sourceImages, {
            ...options,
            targetRegion: Box2.fromPositionAndSize(targetOffset, sourceRegion.size),
        })
    }

    private getCompositingFunction(blittingFunction: string): string {
        return `
            ${blittingFunction}
            
            uniform ivec2 u_internal_blitSourceOffset;

            vec4 computeColor(ivec2 targetPixel) {
                return computeColor(targetPixel, targetPixel + u_internal_blitSourceOffset);
            }
        `
    }

    public static create(context: HalContext, compositingFunction?: string): HalPainterImageBlit {
        return new HalPainterImageBlit(context, compositingFunction)
    }

    private imageCompositor: HalPainterImageCompositor
}

export type HalPainterImageBlitOptions = HalPainterPrimitiveOptions & {
    sourceRegion?: Box2Like
    targetOffset?: Vector2Like
}

const DEFAULT_BLITTING_FUNCTION = `
    vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
        return texelFetch0(sourcePixel);
    }
`
