import {deepEqual} from "@src/utils/utils"
import {ValueTypeDescriptor} from "@src/templates/runtime-graph/descriptors"

export const NotReady = Symbol("NotReady")

export type Inlet<T> = T | typeof NotReady

export class Outlet<T> {
    value: T | typeof NotReady = NotReady
    targets: ((value: T | typeof NotReady) => void)[] = []
    fromId: string | undefined
    fromKey: string | undefined
    constructor(readonly valueTypeDesc: ValueTypeDescriptor<unknown> | undefined) {}
    emit(value: T | typeof NotReady): void {
        this.value = value
        // console.log("Emit", value, "from", this.fromId, this.fromKey);
        // console.log("Emit from", this.fromId, this.fromKey);
        for (const fn of this.targets) {
            fn(value)
        }
    }
    emitIfChanged(value: T | typeof NotReady): void {
        if (valueChanged(value, this.value, this.valueTypeDesc)) {
            this.emit(value)
        }
    }
}

export function valueChanged<T>(a: T | typeof NotReady, b: T | typeof NotReady, valueTypeDesc?: ValueTypeDescriptor<T>) {
    if (a === NotReady || b === NotReady) return a !== b
    const _deepCompare = valueTypeDesc?.deepCompare
    if (_deepCompare) {
        if (typeof _deepCompare === "function") return !_deepCompare(a, b)
        // number = depth limit, true = unlimited
        return !deepEqual(a, b, _deepCompare === true ? undefined : _deepCompare)
    } else {
        return a !== b
    }
}
