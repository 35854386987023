import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {SeparateXyzInputs, SeparateXyzOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-separate-xyz-node",
    templateUrl: "./separate-xyz-node.component.html",
    styleUrls: ["./separate-xyz-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class SeparateXyzNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = SeparateXyzOutputs
    inputs = SeparateXyzInputs
    typeInfo = SeparateXyzNodeType
}

export const SeparateXyzNodeType: MaterialNodeType<typeof SeparateXyzNodeComponent> = {
    id: "separateXYZ",
    label: "Separate XYZ",
    color: "#4987af",
    name: "ShaderNodeSeparateXYZ",
    inputs: [SeparateXyzInputs.image],
    outputs: [SeparateXyzOutputs.red, SeparateXyzOutputs.green, SeparateXyzOutputs.blue],
    component: SeparateXyzNodeComponent,
}
