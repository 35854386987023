import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {HotkeyLayerId, Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {PictureViewerComponent} from "@platform/components/pictures/picture-viewer/picture-viewer.component"
import {Location} from "@angular/common"
import {ActionBarComponent} from "@common/components/menu/action-menu/action-bar/action-bar.component"
import {ActionItemComponent} from "@common/components/menu/action-menu/action-item/action-item.component"
import {Subject, takeUntil} from "rxjs"

@Component({
    selector: "cm-picture-viewer-dialog",
    templateUrl: "./picture-viewer-dialog.component.html",
    styleUrls: ["./picture-viewer-dialog.component.scss"],
    standalone: true,
    imports: [ActionBarComponent, RoutedDialogComponent, ActionItemComponent, PictureViewerComponent],
})
export class PictureViewerDialogComponent implements OnInit, OnDestroy {
    @ViewChild("overlayDialog", {static: true}) overlayDialog!: RoutedDialogComponent
    @Output() close = new EventEmitter<void>()
    dialogSizes = DialogSize

    private readonly hotkeyLayerId: HotkeyLayerId
    private unsubscribe = new Subject<void>()

    constructor(
        private location: Location,
        private hotkeys: Hotkeys,
    ) {
        // register hotkeys
        this.hotkeyLayerId = hotkeys.createLayer() // create a layer since this is a modal dialog
        hotkeys
            .addShortcut("Escape")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.overlayClosed())
    }

    ngOnInit(): void {}

    async closeOverlay() {
        await this.overlayDialog.closeIfClean()
    }

    overlayClosed(): void {
        this.close.emit()
    }

    ngOnDestroy() {
        this.hotkeys.removeLayer(this.hotkeyLayerId)
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
