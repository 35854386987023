import {filter, Observable, share} from "rxjs"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {refetchWithExponentialBackoff} from "@common/helpers/utils/exponential-backoff"
import {IsDefined} from "@cm/lib/utils/filter"
import {TabStateService} from "@common/services/tab-state/tab-state.service"

export const makeHeartbeat = <EmitConditionValueType, ValueType extends EmitConditionValueType>(
    itemId$: Observable<string | null | undefined>,
    refresh: RefreshService,
    tabState: TabStateService,
    fetchItem: ({id}: {id: string}) => Observable<ValueType>,
    emitWhile: (item: EmitConditionValueType) => boolean = () => true,
) =>
    itemId$.pipe(
        filter(IsDefined),
        refresh.reEmitWhenItemIsRefreshed(),
        tabState.reEmitOnActivation(),
        refetchWithExponentialBackoff((id) => fetchItem({id}), emitWhile),
        share(),
    )
