import {computed, inject, Injectable, signal} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {BasicOrganizationInfoFragment, ContentTypeModel} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {IsNonNull, IsNotUndefined} from "@cm/lib/utils/filter"
import {AuthService} from "@common/services/auth/auth.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {StateLabel} from "@labels"
import {distinctUntilChanged, filter, firstValueFrom, switchMap, BehaviorSubject} from "rxjs"
import {sortOrganizationsDetailsWithNameAlphabetically} from "@common/helpers/utils/organization"

/*
 * Provides access to organizations and organization memberships
 *
 * Use observables to subscribe to all changes,
 * signals to get the currently loaded value immediately
 * (both may be undefined during initial app load and during login),
 * and promises to get the value once the organizations have been loaded
 */
@Injectable({
    providedIn: "root",
})
export class OrganizationsService {
    // all organizations visible to the user
    $all = signal<BasicOrganizationInfoFragment[] | undefined>(undefined)
    all$ = toObservable(this.$all)
    get all() {
        return firstValueFrom(this.all$.pipe(filter(IsNotUndefined)))
    }

    // organizations that the user is a member of
    $own = signal<BasicOrganizationInfoFragment[] | undefined>(undefined)
    own$ = toObservable(this.$own)
    get own() {
        return firstValueFrom(this.own$.pipe(filter(IsNotUndefined)))
    }

    $ownIds = computed(() => this.$own()?.map((organization) => organization.id) ?? undefined)
    ownIds$ = toObservable(this.$ownIds)
    get ownIds() {
        return firstValueFrom(this.ownIds$.pipe(filter((value) => value !== undefined)))
    }

    // the user's current organization
    $current = signal<BasicOrganizationInfoFragment | null | undefined>(undefined)
    current$ = toObservable(this.$current)
    get current() {
        return firstValueFrom(this.current$.pipe(filter(IsNotUndefined)))
    }

    $editOptions = computed<StateLabel<string>[]>(() => {
        return (
            (this.auth.$user()?.isStaff ? this.$all() : this.$own())?.map((organization) => ({
                label: organization.name ?? "",
                state: organization.id,
            })) ?? []
        )
    })

    $filterOptions = computed<StateLabel<string>[]>(
        () =>
            (this.auth.$user()?.isStaff ? this.$all() : this.$own())
                ?.filter((organization) => organization.visibleInFilters)
                ?.map((organization) => ({
                    label: organization.name ?? "",
                    state: organization.id,
                })) ?? [],
    )
    $ownOptions = computed(() => {
        this.$own()?.map((organization) => ({
            label: organization.name,
            state: organization.id,
        })) ?? []
    })
    $userIsMemberOf = (organizationId: string | null | undefined) =>
        computed(() => {
            return !!organizationId && this.$ownIds()?.includes(organizationId)
        })

    public initialLoadCompleted$ = new BehaviorSubject<boolean>(false)

    auth = inject(AuthService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)

    constructor() {
        this.auth.userId$
            .pipe(
                takeUntilDestroyed(),
                distinctUntilChanged(),
                switchMap(async (userId) => {
                    switch (userId) {
                        case null: {
                            return {
                                all: [],
                                own: [],
                                current: null,
                            }
                        }
                        case undefined: {
                            return {
                                all: undefined,
                                own: undefined,
                                current: undefined,
                            }
                        }
                        default: {
                            const {user} = await this.sdk.gql.organizationMemberships({userId})
                            const {organizations} = await this.sdk.gql.allOrganizations()
                            const nonNullOrganizations = organizations.filter(IsNonNull)
                            return {
                                all: nonNullOrganizations,
                                own: nonNullOrganizations.filter(
                                    (organization) =>
                                        user.memberships?.some((membership) => membership.organization?.id === organization.id) ||
                                        user.organization?.id === organization.id,
                                ),
                                current: user?.organization ?? nonNullOrganizations?.[0] ?? null,
                            }
                        }
                    }
                }),
            )
            .subscribe(({all, own, current}) => {
                this.$all.set(all ? sortOrganizationsDetailsWithNameAlphabetically(all) : all)
                this.$own.set(own ? sortOrganizationsDetailsWithNameAlphabetically(own) : own)
                this.$current.set(current)
                this.initialLoadCompleted$.next(true)
            })
    }

    async setCurrent(organizationId: string | null) {
        const userId = this.auth.$user()?.id
        if (userId) {
            await this.sdk.gql.organizationsChangeCurrent({userId, organizationId})
            this.refresh.item({id: userId, __typename: ContentTypeModel.User})
        }
    }

    userIsMemberOf(organizationId: string | null | undefined) {
        return (!!organizationId && this.$own()?.some((organization) => organization.id === organizationId)) ?? false
    }

    async byId(organizationId: string | null) {
        return this.all.then((all) => all?.find((organization) => organization.id === organizationId) ?? null)
    }

    $byId(organizationId: string | null) {
        return computed(() => this.$all()?.find((organization) => organization.id === organizationId) ?? null)
    }

    /**
     * Return the provided organizationId if the user is a member, otherwise default to one the user is a member of.
     * @param organizationId
     * @return Promise<string> The id of an organization the current user is a member of.
     */
    async ensureUserIsMember(organizationId?: string | null): Promise<string> {
        await firstValueFrom(this.initialLoadCompleted$.pipe(filter((value) => !!value)))
        if (organizationId && this.userIsMemberOf(organizationId)) {
            return organizationId
        }
        const fallbackId = this.$current()?.id ?? this.$own()?.[0]?.id
        if (!fallbackId) {
            throw new Error("No fallback organization found")
        }
        return fallbackId
    }
}
