import {DeclareNodeTS, Node, NodeMeta} from "@src/graph-system/declare-node"
import {NodeParameters, GetParameters, NodeParamEvaluator, isNodeGraphInstance} from "@src/graph-system/node-graph"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {Context, MaterialType, context} from "@src/materials/types"
import {RenderNodes} from "@src/rendering/render-nodes"
import {z} from "zod"
import * as THREE from "three"

export const cyclesNode = RenderNodes.ShaderNodeSchema
export type CyclesNode = z.infer<typeof cyclesNode>

export const threeNode = z.instanceof(THREENodes.Node)
export type ThreeNode = z.infer<typeof threeNode>

export const materialSlots = z.union([cyclesNode, threeNode])
export type MaterialSlot = z.infer<typeof materialSlots>

export type MaterialInput = {[key: string]: MaterialSlot}
export type MaterialOutput = {[key: string]: MaterialSlot | THREE.Material}

type CycleInput<T extends MaterialSlot> = T extends CyclesNode ? T : never
type ThreeInput<T extends MaterialSlot> = T extends ThreeNode ? T : T extends THREE.Material ? T : never
type MaterialInputForType<T extends MaterialSlot, M extends MaterialType> = M extends "cycles" ? CycleInput<T> : M extends "three" ? ThreeInput<T> : never
type MaterialInputsForType<InputTypes extends MaterialInput, M extends MaterialType> = {
    [P in keyof InputTypes]: MaterialInputForType<InputTypes[P], M>
}

export function DeclareMaterialNode<
    ZodReturnType extends z.ZodType<MaterialOutput>,
    ZodInputTypes extends z.ZodType<MaterialInput>,
    ZodParamTypes extends z.ZodType<NodeParameters>,
>(
    definition: {
        returns: ZodReturnType
        inputs: ZodInputTypes
        parameters: ZodParamTypes
    },
    implementation: {
        toCycles?: (data: {
            get: NodeParamEvaluator
            context: Context
            inputs: GetParameters<Context, MaterialInputsForType<z.infer<typeof definition.inputs>, "cycles">>
            parameters: z.infer<typeof definition.parameters>
        }) => Promise<MaterialInputsForType<z.infer<typeof definition.returns>, "cycles">>
        toThree?: (data: {
            get: NodeParamEvaluator
            context: Context
            inputs: GetParameters<Context, MaterialInputsForType<z.infer<typeof definition.inputs>, "three">>
            parameters: z.infer<typeof definition.parameters>
        }) => Promise<MaterialInputsForType<z.infer<typeof definition.returns>, "three">>
    },
    meta?: NodeMeta<Context, z.infer<typeof definition.inputs>, {parameters: z.infer<typeof definition.parameters>}>,
) {
    const {returns: returnSchema, inputs: inputsSchema, parameters: paramsSchema} = definition
    type ReturnType = z.infer<typeof returnSchema>
    type InputTypes = z.infer<typeof inputsSchema>
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareMaterialNodeTS<ReturnType, InputTypes, ParamTypes>({...implementation, validation: {returnSchema, inputsSchema, paramsSchema}}, meta)
}

export function DeclareMaterialNodeTS<ReturnType extends MaterialOutput, InputTypes extends MaterialInput, ParamTypes extends NodeParameters>(
    implementation: {
        toCycles?: (data: {
            get: NodeParamEvaluator
            context: Context
            inputs: GetParameters<Context, MaterialInputsForType<InputTypes, "cycles">>
            parameters: ParamTypes
        }) => Promise<MaterialInputsForType<ReturnType, "cycles">>
        toThree?: (data: {
            get: NodeParamEvaluator
            context: Context
            inputs: GetParameters<Context, MaterialInputsForType<InputTypes, "three">>
            parameters: ParamTypes
        }) => Promise<MaterialInputsForType<ReturnType, "three">>
        validation?: {
            returnSchema?: z.ZodType<MaterialOutput>
            inputsSchema?: z.ZodType<MaterialInput>
            paramsSchema?: z.ZodType<NodeParameters>
        }
    },
    meta?: NodeMeta<Context, InputTypes, {parameters: ParamTypes}>,
) {
    const {validation} = implementation
    return DeclareNodeTS<ReturnType, Context, InputTypes, {parameters: ParamTypes}>(
        {
            run: function (this: MaterialNode<ReturnType, InputTypes, ParamTypes>, {get, parameters, context}) {
                const {parameters: currentParameters, ...inputs} = parameters
                switch (context.type) {
                    case "cycles":
                        if (!implementation.toCycles) throw new Error(`ToCycles method not implemented for ${this.getNodeClass()}`)
                        return implementation.toCycles.bind(this)({
                            get,
                            context,
                            inputs: inputs as NodeParameters as GetParameters<Context, MaterialInputsForType<InputTypes, "cycles">>,
                            parameters: currentParameters,
                        })
                    case "three":
                        if (!implementation.toThree) throw new Error(`ToThree method not implemented for ${this.getNodeClass()}`)
                        return implementation.toThree.bind(this)({
                            get,
                            context,
                            inputs: inputs as NodeParameters as GetParameters<Context, MaterialInputsForType<InputTypes, "three">>,
                            parameters: currentParameters,
                        })
                }
            },
            validation: {
                returnSchema: validation?.returnSchema,
                contextSchema: context,
                paramsSchema: validation?.inputsSchema,
                primitiveParamsSchema: z.object({parameters: validation?.paramsSchema ?? z.object({})}),
            },
        },
        meta,
    )
}

export type MaterialNode<ReturnType extends MaterialOutput = {}, InputTypes extends MaterialInput = {}, ParamTypes extends NodeParameters = {}> = Node<
    ReturnType,
    Context,
    InputTypes,
    {parameters: ParamTypes}
>

export const isMaterialNodeNode = (instance: unknown): instance is MaterialNode => isNodeGraphInstance(instance)
export const materialNodeInstance = z.any().refine(isMaterialNodeNode, {message: "Expected material node"})
