import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpConvert} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-convert"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const ConvertNode = declareImageOpNode(imageOpConvert)

export function convert(parameters: GetParameters<Context, ParameterType>) {
    return new ConvertNode(parameters)
}
