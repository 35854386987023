import {inject} from "@angular/core"
import {DataSource} from "@angular/cdk/collections"
import {AfterViewInit, Component, ViewChild} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator"
import {MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Router, RouterOutlet} from "@angular/router"
import {JobFarmInstancesTableFragment} from "@api"
import {UtilsService} from "@legacy/helpers/utils"
import {AuthService} from "@common/services/auth/auth.service"
import {ItemUtils} from "@common/services/item-utils/item-utils.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {
    EditJobFarmInstanceDialogComponent,
    JobFarmInstanceDialogResult,
} from "@platform/components/job-farm-instances/edit-job-farm-instance-dialog/edit-job-farm-instance-dialog.component"
import {MomentModule} from "ngx-moment"
import {BehaviorSubject, Observable, firstValueFrom} from "rxjs"
import {IsNonNull} from "@cm/lib/utils/filter"

@Component({
    selector: "cm-job-farm-instances-table",
    templateUrl: "./job-farm-instances-table.component.html",
    styleUrls: ["./job-farm-instances-table.component.scss"],
    standalone: true,
    imports: [MatTableModule, MatButtonModule, MomentModule, MatPaginatorModule, RouterOutlet, MatTooltipModule],
})
export class JobFarmInstancesTableComponent implements AfterViewInit {
    displayedColumns: string[] = ["id", "hostName", "cpuCores", "ram", "group", "lastReport", "state", "type", "version", "user"]
    dataSource: JobFarmInstanceDataSource

    @ViewChild(MatPaginator) paginator!: MatPaginator

    private sdkService = inject(SdkService)

    constructor(
        public router: Router,
        public authService: AuthService,
        private dialog: MatDialog,
        private notification: NotificationsService,
        public itemUtils: ItemUtils,
        public utils: UtilsService,
    ) {
        this.dataSource = new JobFarmInstanceDataSource(this.sdkService)
    }

    filtersChanged(_filters: Record<string, string>): void {
        // this.items = [];
        // this.cursor = null;
        // this.loadNextPage(filters);
    }

    private loadPageData(pageIndex: number, pageSize: number): void {
        this.dataSource.loadPageByIndex(pageIndex, pageSize).then(({pageIndex, totalCount}) => {
            this.paginator.pageIndex = pageIndex
            this.paginator.length = totalCount
        })
    }

    private reloadPageData(): void {
        this.dataSource.reload().then(({pageIndex, totalCount}) => {
            this.paginator.pageIndex = pageIndex
            this.paginator.length = totalCount
        })
    }

    private onActionSuccess() {
        this.reloadPageData()
    }

    private onActionError(err: string) {
        this.notification.showError(err)
        this.reloadPageData()
    }

    ngAfterViewInit() {
        this.paginator.page.subscribe((page) => this.loadPageData(page.pageIndex, page.pageSize))
        this.loadPageData(this.paginator.pageIndex, this.paginator.pageSize)
    }

    private async showEditDialog(item: JobFarmInstancesTableFragment) {
        const dialogRef = this.dialog.open<EditJobFarmInstanceDialogComponent, JobFarmInstancesTableFragment, JobFarmInstanceDialogResult>(
            EditJobFarmInstanceDialogComponent,
            {
                width: "350px",
                data: {...item},
            },
        )

        const result = await firstValueFrom(dialogRef.afterClosed())
        if (!result) return
        if (result?.delete) {
            await this.sdkService.gql
                .jobFarmInstancesDeleteJobFarmInstance({id: result.id})
                .then(() => {
                    this.onActionSuccess()
                    this.notification.showInfo("Instance deleted.")
                })
                .catch((error) => this.onActionError(error))
        } else if (result.groupId != item.group?.id) {
            await this.sdkService.gql
                .jobFarmInstancesUpdateJobFarmInstance({
                    input: {
                        id: result.id,
                        groupId: result.groupId,
                    },
                })
                .then(() => {
                    this.onActionSuccess()
                    this.notification.showInfo("Instance updated.")
                })
                .catch((error) => this.onActionError(error))
        }
    }

    async rowClick(item: JobFarmInstancesTableFragment) {
        return this.showEditDialog(item)
    }

    parseOtherInfo(info?: {host_name?: string; num_physical_cpus?: number; os_version?: string; total_memory?: number}) {
        if (!info) return undefined
        return {
            hostName: info.host_name as string | undefined,
            numCpuCores: info.num_physical_cpus as number | undefined,
            osVersion: info.os_version as string | undefined,
            totalMemoryGiB: info.total_memory ? Math.round(info.total_memory / (1024 * 1024 * 1024)) : undefined,
        }
    }
}

export class JobFarmInstanceDataSource implements DataSource<JobFarmInstancesTableFragment> {
    private dataSubject = new BehaviorSubject<JobFarmInstancesTableFragment[]>([])

    constructor(private sdkService: SdkService) {}

    connect(): Observable<JobFarmInstancesTableFragment[]> {
        return this.dataSubject
    }

    disconnect(): void {
        this.dataSubject.complete()
    }

    async loadPageByIndex(pageIndex: number, pageSize?: number): Promise<{pageIndex: number; totalCount: number}> {
        return this.sdkService.gql
            .jobFarmInstancesTableItems(
                // TODO: replace the previous sort order (legacyId) with a new one (like created)
                {skip: pageSize ? pageIndex * pageSize : undefined, take: pageSize},
                {fetchPolicy: "no-cache"},
            )
            .then((result) => {
                const items = result.jobFarmInstances.filter(IsNonNull)
                this.dataSubject.next(items)
                return {
                    pageIndex: 0,
                    totalCount: items.length,
                }
            })
    }

    async reload() {
        return this.loadPageByIndex(0, undefined)
    }
}
