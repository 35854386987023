<div class="cm-variations-container">
    <div class="cm-render-actions">
        <cm-button class="cm-render" [disabled]="renderAllDisabled()" [class.cm-disabled]="renderAllDisabled()" (click)="submitAllJobs('render')">
            <ng-container cm-left-icon>
                <i class="fa-solid fa-image"></i>
            </ng-container>
            <ng-container cm-text> Render All</ng-container>
        </cm-button>
        <cm-button class="cm-render" [disabled]="finalizeAllDisabled()" [class.cm-disabled]="finalizeAllDisabled()" (click)="submitAllJobs('postProcess')">
            <ng-container cm-left-icon>
                <i class="fa-solid fa-save"></i>
            </ng-container>
            <ng-container cm-text> Finalize All</ng-container>
        </cm-button>
        <cm-button class="cm-delete-button" [disabled]="deleteAllDisabled()" [class.cm-disabled]="deleteAllDisabled()" [matMenuTriggerFor]="deleteAllMenu">
            <ng-container cm-left-icon>
                <i class="far fa-trash"></i>
            </ng-container>
            <mat-menu #deleteAllMenu>
                <ng-template matMenuContent>
                    <button mat-menu-item (click)="deleteAllJobs('both')">Delete all images</button>
                    <button mat-menu-item (click)="deleteAllJobs('postProcess')">Delete all final images only</button>
                </ng-template>
            </mat-menu>
        </cm-button>
    </div>
    @if (state() === "loading") {
        <div class="cm-progress-bar">
            <mat-progress-bar [mode]="progress() === 0 ? 'indeterminate' : 'determinate'" [value]="progress()"></mat-progress-bar>
        </div>
    }
    <div class="cm-variation-list">
        @for (variation of allVariations(); track variation.hash) {
            <cm-list-item [selected]="variation.hash === currentLocalConfigurationHash()" [matTooltip]="variation.name" (click)="setConfiguration(variation)">
                <ng-container cm-title>
                    {{ variation.name }}
                </ng-container>
            </cm-list-item>
            <div class="cm-variation-list-icons">
                @if (variation.render; as render) {
                    <cm-template-job-icon [job]="render" [iconClass]="'fas fa-image'"></cm-template-job-icon>
                }
                @if (variation.postProcess; as postProcess) {
                    <cm-template-job-icon [job]="postProcess" [iconClass]="'fas fa-palette'"></cm-template-job-icon>
                }
            </div>
        }
    </div>
    <div class="cm-filler"></div>
</div>
