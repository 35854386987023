<div class="cm-pre-title-container">
    @if (expandable) {
        <i
            class="far cm-toggle-icon"
            (click)="$event.stopPropagation(); arrowToggle.emit()"
            (dblclick)="$event.stopPropagation()"
            [class.fa-angle-right]="!expanded"
            [class.fa-angle-down]="expanded"
        ></i>
    }
    <ng-content select="[cm-pre-title]"></ng-content>
</div>
<div class="cm-title-container">
    <ng-content select="[cm-title]"></ng-content>
</div>
<div class="cm-icon-container">
    <ng-content select="[cm-icons]"></ng-content>
</div>
