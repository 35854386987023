const fileExtensionMap = {
    "application/zip": "zip",
    "image/jpeg": "jpg",
    "image/tiff": "tif",
    "image/png": "png",
    "image/bmp": "bmp",
    "image/x-exr": "exr",
    "image/exr": "exr",
    "image/vnd.radiance": "hdr",
    "application/draco": "drc",
    "application/ply": "ply",
} as const

export type ExtensionT = typeof fileExtensionMap extends Record<infer C, infer E> ? E : never
export type ContentTypeT = typeof fileExtensionMap extends Record<infer C, infer E> ? C : never

export function extensionForContentType(contentType: string): ExtensionT {
    const extensinon = (fileExtensionMap as Record<string, ExtensionT>)[contentType]
    if (typeof extensinon === "undefined") throw Error(`No extension found for content/media type: ${contentType}`)
    return extensinon
}

export function contentTypeForExtension(extension: string): ContentTypeT {
    const contentType = Object.entries(fileExtensionMap).find(([_contentType, _extension]) => _extension === extension)?.[0]
    if (typeof contentType === "undefined") throw Error(`No content/media type found for extension: ${extension}`)
    return contentType as ContentTypeT
}

export function isValidContentType(contentType: string | null | undefined): contentType is ContentTypeT {
    return contentType !== null && contentType !== undefined && fileExtensionMap.hasOwnProperty(contentType)
}
