import {CubicBezierSpline, Knot} from "@src/math/cubic-bezier-spline"
import {InterpolationMode, OutOfBoundsMode, SampledFunction} from "@src/math/sampled-function"
import {Vector2, Vector2Like} from "@src/math/vector2"

export function computeRgbCurveSampledFunction(controlPoints: Vector2Like[]): SampledFunction {
    const numCurveSamples = 256
    const knots = controlPoints.map((point) => new Knot(point))
    const spline = new CubicBezierSpline(knots)
    const points = spline.evaluatePoints(numCurveSamples)
    return new SampledFunction(points, InterpolationMode.Linear, OutOfBoundsMode.Extrapolate)
}

// returns a [lutSize][3] array of numbers which map inputs for R, G and B (mapped between 0 and 1) to their respective output values
export function computeRgbCurveLUT(lutSize: number, getParamFn: (paramName: string) => [number, number] | undefined): number[][] {
    const sampledFunctions: SampledFunction[] = []
    for (let curveIndex = 0; curveIndex < 4; curveIndex++) {
        const controlPoints: Vector2[] = []
        for (let i = 0; ; i++) {
            const pointLocation: [number, number] | undefined = getParamFn(`internal.mapping.curves[${curveIndex}].points[${i}].location`)
            if (!pointLocation) {
                break
            }
            controlPoints.push(new Vector2(pointLocation[0], pointLocation[1]))
        }
        const sampledFunction = computeRgbCurveSampledFunction(controlPoints)
        sampledFunctions.push(sampledFunction)
    }
    const lut: number[][] = new Array(lutSize).fill(0).map(() => new Array(3).fill(0))
    for (let c = 0; c < 3; c++) {
        for (let i = 0; i < lut.length; i++) {
            const input = i / (lut.length - 1)
            const output = sampledFunctions[c].evaluate(sampledFunctions[3].evaluate(input)!)! // we use "extrapolate" so it can not be undefined
            lut[i][c] = output
        }
    }
    return lut
}
