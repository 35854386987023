import {StateLabel} from "@platform/models/state-labels/state-label"
import {MaterialState} from "@api"

export const MaterialStateLabels: Map<MaterialState, StateLabel<MaterialState>> = new Map<MaterialState, StateLabel<MaterialState>>([
    [MaterialState.Draft, {state: MaterialState.Draft, label: "Draft", background: "#989898"}],
    [MaterialState.InfoReview, {state: MaterialState.InfoReview, label: "Info review", background: "#989898"}],
    [MaterialState.Scanning, {state: MaterialState.Scanning, label: "Scanning", background: "#ffab4a"}],
    [MaterialState.Tiling, {state: MaterialState.Tiling, label: "Tiling", background: "#ffab4a"}],
    [MaterialState.Shading, {state: MaterialState.Shading, label: "Shading", background: "#ffab4a"}],
    [MaterialState.Review, {state: MaterialState.Review, label: "Review", background: "#7cbcb0"}],
    [MaterialState.OnHold, {state: MaterialState.OnHold, label: "On hold", background: "#000000"}],
    [MaterialState.Completed, {state: MaterialState.Completed, label: "Completed", background: "#7ec17a"}],
])
