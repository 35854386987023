import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {SeparateRgbInputs, SeparateRgbOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-separate-rgb-node",
    templateUrl: "./separate-rgb-node.component.html",
    styleUrls: ["./separate-rgb-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class SeparateRgbNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = SeparateRgbOutputs
    inputs = SeparateRgbInputs
    typeInfo = SeparateRgbNodeType
}

export const SeparateRgbNodeType: MaterialNodeType<typeof SeparateRgbNodeComponent> = {
    id: "separateRgb",
    label: "Separate RGB",
    color: "#4987af",
    name: "ShaderNodeSeparateRGB",
    inputs: [SeparateRgbInputs.image],
    outputs: [SeparateRgbOutputs.red, SeparateRgbOutputs.green, SeparateRgbOutputs.blue],
    component: SeparateRgbNodeComponent,
}
