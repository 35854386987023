import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {ConfigVariant} from "@src/templates/nodes/config-variant"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {ConfigInfo, VariantInfo} from "@src/templates/interface-descriptors"
import {visitAll} from "@src/graph-system/declare-visitor-node"
import {groupNodeParameters, GroupNodeParameters} from "@src/templates/nodes/group-node"
import {idNodeParameters, IdNodeParameters} from "@src/templates/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"

export const configGroupParameters = namedNodeParameters.merge(groupNodeParameters).merge(idNodeParameters)
export type ConfigGroupParameters = NamedNodeParameters & GroupNodeParameters & IdNodeParameters

@registerNode
export class ConfigGroup extends DeclareTemplateNodeTS<ConfigGroupParameters>(
    {
        validation: {paramsSchema: configGroupParameters},
        onVisited: {
            onFilterActive: function (this: ConfigGroup, {visit, context, parameters}) {
                const {nodes, ...rest} = parameters

                const activeConfigVariant = this.getActiveConfigVariant(context.evaluator)

                return {
                    nodes: nodes.visit(context, visit, (subNode): boolean => {
                        if (subNode instanceof ConfigVariant) {
                            return subNode === activeConfigVariant
                        } else return true
                    }),
                    ...rest,
                }
            },
            onCompile: function (this: ConfigGroup, {visit, context, parameters}) {
                const {id, name} = parameters
                const {evaluator, currentTemplate} = context
                const {activeNodeSet} = evaluator
                const {descriptorList} = currentTemplate

                const configVariants = this.getConfigVariants()

                const variants = configVariants.map<VariantInfo>((variant) => {
                    const {id, name, iconColor, iconDataObject} = variant.parameters
                    const iconDataObjectId = iconDataObject?.parameters.dataObjectId

                    return {id, name, iconColor, iconDataObjectId}
                })

                const activeConfigVariant = activeNodeSet ? configVariants.find((configVariant) => activeNodeSet.has(configVariant)) : undefined

                const activeVariant = variants.find((variant) => variant.id === activeConfigVariant?.parameters.id)

                const scope = evaluator.getScope(this)

                descriptorList.push(
                    scope.lambda(
                        null,
                        () =>
                            new ConfigInfo({
                                id,
                                name,
                                variants,
                                value: activeVariant ?? null,
                                type: "input",
                            }),
                        "configInfo",
                    ),
                )

                return visitAll(parameters, visit)
            },
        },
    },
    {nodeClass: "ConfigGroup"},
) {
    getConfigVariants = () => this.parameters.nodes.parameters.list.filter((subNode): subNode is ConfigVariant => subNode instanceof ConfigVariant)

    getActiveConfigVariant = (evaluator: NodeEvaluator) => {
        const selectedId = this.getTemplateInput(evaluator) //TODO: default?
        const activeConfigVariant = this.getConfigVariants().find((configVariant) => {
            if (selectedId === undefined) {
                //console.log(`No config selected for group ${parameters.name}. Using first (${subNode.parameters.name}) as default.`);
                return true
            } else if (configVariant.parameters.id === selectedId) return true
        })
        return activeConfigVariant ?? null
    }

    getTemplateInput(evaluator: NodeEvaluator) {
        const externalId = this.parameters.id
        const {ambientInputs} = evaluator
        const entry = ambientInputs[externalId]
        if (!entry) return undefined

        return entry.type === "string" ? (entry.value as string) : undefined
    }
}

export type ConfigGroupFwd = TemplateNode<ConfigGroupParameters>
