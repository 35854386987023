import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"

export type ParameterType = {
    drawableImageRef: DrawableImageRef
    descriptor: ImageDescriptor
}

export type ReturnType = ImagePtr

export const imageOpDrawableImage: ImageOpType<ParameterType, ReturnType> = {
    name: "DrawableImage",

    WebGL2: async ({context, parameters: {drawableImageRef: ref, descriptor}}) => {
        return await context.createDrawableImage(ref, descriptor)
    },

    ImgProc: async ({context, parameters: {drawableImageRef: ref, descriptor}}) => {
        return await context.createDrawableImage(ref, descriptor)
    },
}
