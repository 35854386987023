import {HalImage} from "@common/models/hal/hal-image"
import {HalImageOptions, HalImageSource} from "@common/models/hal/hal-image/types"
import {HalContext} from "@common/models/hal/hal-context"
import {WebGl2Context, WebGl2Image} from "@common/models/webgl2"

export const createHalImage = (context: HalContext, source?: HalImageSource, options?: Partial<HalImageOptions>): HalImage => {
    if (context instanceof WebGl2Context) {
        return new WebGl2Image(context, source, options)
    }
    throw Error("Unsupported context")
}
