import {Component, Inject, signal} from "@angular/core"
import {formatForDisplay} from "@common/helpers/utils/dates"
import {TIPPY_REF, TippyInstance} from "@ngneat/helipopper"

@Component({
    selector: "cm-entity-metadata-tooltip",
    standalone: true,
    imports: [],
    templateUrl: "./entity-metadata-tooltip.component.html",
    styleUrl: "./entity-metadata-tooltip.component.scss",
})
export class EntityMetadataTooltipComponent {
    $data = signal<{
        createdAt?: string | Date | null
        updatedAt?: string | Date | null
        createdBy?: {name: string}
        updatedBy?: {name: string}
    } | null>(null)

    constructor(@Inject(TIPPY_REF) tippy: TippyInstance) {
        this.$data.set(tippy.data)
    }

    protected readonly formatForDisplay = formatForDisplay
}
