import {Component, inject, computed, Input, model, signal} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatButtonModule} from "@angular/material/button"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {MutationCreateModelInput} from "@api"

type AddModelData = Partial<MutationCreateModelInput>

// TODO this has a lot of overlap with AddMaterialDialogComponent, factor out common base component?

@Component({
    selector: "cm-add-model-dialog",
    standalone: true,
    imports: [
        FormsModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        OrganizationSelectComponent,
        MatButtonModule,
    ],
    templateUrl: "./add-model-dialog.component.html",
    styleUrl: "./add-model-dialog.component.scss",
})
export class AddModelDialogComponent {
    @Input({required: true}) addModel?: (data: AddModelData) => Promise<{id: string; legacyId: number}>

    organizations = inject(OrganizationsService)

    $updateSelectedOrganization = signal<{id: string} | undefined | null>(null)
    $selectedOrganization = computed(() => {
        const updateSelectedOrganization = this.$updateSelectedOrganization()
        if (updateSelectedOrganization) return updateSelectedOrganization
        return this.organizations.$current()
    })
    $name = model<string>("")

    $data = computed<AddModelData>(() => {
        return {
            name: this.$name(),
            organizationId: this.$selectedOrganization()?.id,
        }
    })

    canConfirmCreate = () => !!this.$data().name && !!this.$data().organizationId

    updateOrganization = (data: {organizationId: string}) => {
        this.$updateSelectedOrganization.set({id: data.organizationId})
    }

    confirmCreate = () => {
        if (this.addModel === undefined) throw new Error("Missing addModel function in add-model-button component")
        return this.addModel(this.$data())
    }
}
