import {Component, EventEmitter, Input, Output} from "@angular/core"

@Component({
    selector: "cm-action-panel",
    templateUrl: "./action-panel.component.html",
    styleUrls: ["./action-panel.component.scss"],
    standalone: true,
})
export class ActionPanelComponent {
    @Input() arrowRight = "0"
    @Input() minWidth = "50px"
    @Input() maxWidth = "500px"
    @Input() marginRight = "0"
    @Input() visible = false

    @Output() onShow = new EventEmitter<void>()
    @Output() onHide = new EventEmitter<void>()

    show(): void {
        this.visible = true
        this.onShow.emit()
    }

    hide(): void {
        this.visible = false
        this.onHide.emit()
    }
}
