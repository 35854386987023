export const IsNonNull = <T>(value: T | null): value is T => value !== null
export const IsNotUndefined = <T>(value: T | undefined): value is T => value !== undefined
export const IsDefined = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined
export const IsTruthy = <T>(value: T | null | undefined): value is T => !!value
export const IsUnique = <T>(value: T, index: number, array: Array<T>): boolean => array.indexOf(value) === index
export const IsNumber = (value: unknown): value is number => typeof value === "number"
export const IsString = (value: unknown): value is string => typeof value === "string"
export const IsBoolean = (value: unknown): value is boolean => typeof value === "boolean"
export const IsArray = <T>(value: unknown): value is Array<T> => Array.isArray(value)
export const IsObject = (value: unknown): value is Record<string, unknown> => typeof value === "object" && value !== null && !Array.isArray(value)
