import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-image-placeholder",
    standalone: true,
    imports: [],
    templateUrl: "./image-placeholder.component.html",
    styleUrl: "./image-placeholder.component.scss",
})
export class ImagePlaceholderComponent {
    @Input() dark = false
    @Input() loading = true
}
