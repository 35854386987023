import {LoadedData, LoadedDataItem} from "@platform/models/data/types"

// loading state placeholder
// use `import {PLACEHOLDER_ITEM as _}` for shorthand convenience
export const PLACEHOLDER_ITEM = {data: null, error: null} as LoadedDataItem<unknown>

export const PLACEHOLDER_ITEMS = <EntityType>(length?: number) =>
    ({
        items: Array.from({length: length ?? 10}, () => PLACEHOLDER_ITEM),
        totalCount: undefined,
        error: null,
        complete: false,
    }) as LoadedData<EntityType>

export const ERROR_ITEMS = <EntityType>(length: number, error: Error) =>
    ({
        items: Array.from({length}, () => ({data: null, error})),
        totalCount: undefined,
        error,
        complete: false,
    }) as LoadedData<EntityType>

export const INITIAL_DATA = PLACEHOLDER_ITEMS(10)
export const EMPTY_DATA = {items: [], totalCount: undefined, error: null, complete: false} as LoadedData<unknown>
