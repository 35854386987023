import {createMeshBuffersForMaterialGroups} from "@cm/lib/geometry-processing/mesh-data"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {ThreeObject, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import * as THREE from "three"
import {MeshBuffersWireframGeometry} from "./mesh-buffers-wireframe-geometry"

export class ThreeWireframeMesh extends ThreeObject<SceneNodes.WireframeMesh> {
    protected override renderObject: THREE.Group = new THREE.Group()

    constructor(protected override threeSceneManagerService: ThreeSceneManagerService) {
        super(threeSceneManagerService)
        setThreeObjectPart(this.renderObject, this)
    }

    override setup(sceneNode: SceneNodes.WireframeMesh) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode.meshData,
                this.parameters?.meshData,
                ["uvs", "materialGroups", "vertices", "normals", "faceIDs"],
                undefined,
                ({uvs, materialGroups, vertices, normals, faceIDs}) => {
                    this.dispose(false)

                    const meshBuffersByGroup = createMeshBuffersForMaterialGroups(vertices, normals, uvs, faceIDs, materialGroups)
                    for (const meshBuffers of meshBuffersByGroup) {
                        if (meshBuffers.indices.length === 0) continue

                        const edges = new MeshBuffersWireframGeometry(meshBuffers)
                        const wireframe = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({color: 0x000000, linewidth: 1}))

                        this.renderObject.add(wireframe)
                    }
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform}) => {
                    updateTransform(transform, this.renderObject)
                },
            ),
        ])
    }

    override dispose(final: boolean) {
        const {materialManagerService} = this.threeSceneManagerService

        this.renderObject.children.forEach((child) => {
            if (child instanceof THREE.LineSegments) {
                const wireframe = child as THREE.LineSegments<MeshBuffersWireframGeometry, THREE.LineBasicMaterial, THREE.Object3DEventMap>
                wireframe.geometry.dispose()
                materialManagerService.releaseMaterial(wireframe.material)
            }
        })
        this.renderObject.clear()
    }
}
