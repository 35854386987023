import {registerNode} from "@src/graph-system/register-node"
import {PricingContext} from "@src/pricing/nodes/core"
import {ConfigurationGroupNode} from "@src/pricing/nodes/configuration-group-node"
import {DeclareListNodeTS} from "@src/graph-system/utils"

@registerNode
export class ConfigurationList extends DeclareListNodeTS<PricingContext, ConfigurationGroupNode>(
    {},
    {
        nodeClass: "ConfigurationList",
    },
) {}
