import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const filterDescriptor = {
    input: inletDescriptor<any[]>(),
    pred: inletDescriptor<((x: any) => boolean) | undefined>(),
    output: outletDescriptor<any[]>(),
}

export class Filter<T> implements NodeClassImpl<typeof filterDescriptor, typeof Filter> {
    static descriptor = filterDescriptor
    static uniqueName = "Filter"
    input!: Inlet<T[]>
    pred!: Inlet<((x: T) => boolean) | undefined>
    output!: Outlet<T[]>

    run() {
        const pred = this.pred
        if (this.input === NotReady || pred === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const list: T[] = []
        for (const x of this.input) {
            if (pred ? pred(x) : x) {
                list.push(x)
            }
        }
        this.output.emitIfChanged(list)
    }
}
