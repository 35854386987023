export const getDimensionRange = (revisions: {height: number; width: number}[]) => {
    if (revisions.length === 0) {
        throw new Error("No revisions")
    }
    return revisions.reduce<{
        maxWidth: number
        minWidth: number
        maxHeight: number
        minHeight: number
    } | null>(
        (acc, revision) => {
            if (acc) {
                return {
                    maxWidth: Math.max(acc.maxWidth, revision.width),
                    minWidth: Math.min(acc.minWidth, revision.width),
                    maxHeight: Math.max(acc.maxHeight, revision.height),
                    minHeight: Math.min(acc.minHeight, revision.height),
                }
            } else {
                return {
                    maxWidth: revision.width,
                    minWidth: revision.width,
                    maxHeight: revision.height,
                    minHeight: revision.height,
                }
            }
        },
        {
            maxWidth: revisions[0].width,
            minWidth: revisions[0].width,
            maxHeight: revisions[0].height,
            minHeight: revisions[0].height,
        },
    )
}
