import {Component, ElementRef, inject, OnDestroy, ViewChild} from "@angular/core"
import {ImageViewerItemFragment} from "@api"
import {MimeType, UtilsService} from "@legacy/helpers/utils"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsNonNull, IsNotUndefined} from "@cm/lib/utils/filter"

// TODO: upgrade Photoswipe and use ESM imports once non-GQL version has been removed
declare class PhotoSwipe {
    constructor(element: Element, photoSwipeUI: unknown, items: unknown[], options: unknown)
    init: () => void
    getCurrentIndex: () => number
}
declare const PhotoSwipeUI_Default: unknown

@Component({
    selector: "cm-image-viewer",
    templateUrl: "image-viewer.component.html",
    styleUrls: ["image-viewer.component.scss"],
    standalone: true,
})
export class ImageViewerComponent implements OnDestroy {
    @ViewChild("photoSwipeElement", {static: true}) photoSwipeElement!: ElementRef
    public options = {
        index: 0,
        closeOnScroll: true,
        shareEl: false,
        captionEl: true,
        loop: false,
    }

    sdk = inject(SdkService)

    items: ImageViewerItemFragment[] = []

    gallery: {init: () => void; getCurrentIndex: () => number} | null = null

    ngOnDestroy() {
        this.gallery = null
    }

    get currentIndex() {
        return this.gallery?.getCurrentIndex() ?? 0
    }

    async openViewer(currentDataObjectId: string, dataObjectIds: string[]) {
        const validDataObjectIds = dataObjectIds.filter(IsNotUndefined)
        const {dataObjects: imageViewerItems} = await this.sdk.gql.imageViewerItems({ids: validDataObjectIds})
        this.items = imageViewerItems
            .filter(IsNonNull)
            .filter((imageViewerItem) => UtilsService.mimeTypeMatch(MimeType.Images, imageViewerItem.mediaType))
            .filter((item) => !!item.thumbnail?.downloadUrl)
        if (!this.items.length) {
            return
        }
        const galleryItems = this.items.map((imageViewerItem) => ({
            src: imageViewerItem.thumbnail?.downloadUrl,
            w: imageViewerItem.width,
            h: imageViewerItem.height,
            title: imageViewerItem.originalFileName,
        }))
        this.options.index = this.items.findIndex(({id}) => id === currentDataObjectId) ?? 0
        this.gallery = new PhotoSwipe(this.photoSwipeElement.nativeElement, PhotoSwipeUI_Default, galleryItems, this.options)
        this.gallery?.init()
    }
}
