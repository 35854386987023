import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {TypedImageData} from "@cm/lib/image-processing/image-processing-actions"
import {assertNever} from "@cm/lib/utils/utils"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {imageOpCopyRegion} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-copy-region"
import {ChannelLayout, Format} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {ImageOpContextImgProc} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-imgproc"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"

// helper for ImgProc to copy to resultImage if it's defined, or return the result otherwise
export async function toImgProcResultImage(context: ImageOpContextImgProc, result: ImagePtr, resultImage: ImagePtr | undefined): Promise<ImagePtr> {
    if (resultImage) {
        return await imageOpCopyRegion.ImgProc({context, parameters: {sourceImage: result, resultImage}})
    } else {
        return new ImagePtr(result)
    }
}

export function getImgProcChannelLayout(channelLayout: ChannelLayout): TypedImageData["channelLayout"] {
    switch (channelLayout) {
        case "R":
            return "L"
        case "RGB":
            return "RGB"
        case "RGBA":
            return "RGBA"
        default:
            assertNever(channelLayout)
    }
}

export function getImgProcFormat(format: Format): TypedImageData["dataType"] {
    switch (format) {
        case "uint8":
            return "uint8"
        case "float32":
            return "float32"
        case "float16":
            return "float16"
        default:
            assertNever(format)
    }
}

export function getImgProcPixelAddressMode(addressMode: AddressMode): Nodes.PixelAddressMode {
    switch (addressMode) {
        case "clamp":
            return "clamp"
        case "wrap":
            return "wrap"
        case "border":
            throw new Error("Border address mode is not supported by ImgProc")
        default:
            assertNever(addressMode)
    }
}
