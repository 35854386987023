import {Component, inject, Input} from "@angular/core"
import {MatDialog} from "@angular/material/dialog"
import {ContentTypeModel} from "@api"
import {MaterialMapsExporter} from "@cm/lib/materials/material-maps-exporter"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {RenameDialogComponent} from "@common/components/dialogs/rename-dialog/rename-dialog.component"
import {AuthService} from "@common/services/auth/auth.service"
import {
    ExportConfigDBEntry,
    MaterialMapsExporterService,
    MaterialMapsExportQueryResult as MapsExportQueryResult,
} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {MaterialExportsListDetailComponent} from "@platform/components/materials/material-exports-list-detail/material-exports-list-detail.component"
import {from, map, of, switchMap} from "rxjs"

@Component({
    selector: "cm-material-exports-list",
    templateUrl: "./material-exports-list.component.html",
    styleUrls: ["./material-exports-list.component.scss"],
    standalone: true,
    imports: [MaterialExportsListDetailComponent],
})
export class MaterialExportsListComponent {
    matDialog = inject(MatDialog)
    materialMapsExporterService = inject(MaterialMapsExporterService)
    notifications = inject(NotificationsService)
    refresh = inject(RefreshService)

    constructor(public authService: AuthService) {}

    @Input() materialId?: string
    @Input() exports: MapsExportQueryResult[] = []
    @Input() sourceInfoRequestToString!: (sourceInfoRequest: MaterialMapsExporter.SourceInfoRequest) => string

    deleteMapsExport = (configDBEntry: ExportConfigDBEntry) => {
        const dialogRef = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Delete maps export",
                message: `Are you sure you want to remove export ${configDBEntry.config.root.name}?`,
                confirmLabel: "Delete item",
                cancelLabel: "Cancel",
            },
        })
        dialogRef.afterClosed().subscribe((confirmed) => {
            if (!confirmed) return
            from(this.materialMapsExporterService.deleteMapsExport(configDBEntry)).subscribe({
                next: () => {
                    this.refresh.item({id: this.materialId, __typename: ContentTypeModel.Material})
                },
                error: (err) => {
                    this.notifications.showError(`Cannot delete export: ${err}`)
                    this.refresh.item({id: this.materialId, __typename: ContentTypeModel.Material})
                },
            })
        })
    }

    renameMapsExport(configDBEntry: ExportConfigDBEntry) {
        this.matDialog
            .open(RenameDialogComponent, {
                width: "400px",
                data: {
                    currentName: configDBEntry.config.root.name,
                },
            })
            .afterClosed()
            .pipe(
                switchMap((newNameOrFalse) => {
                    if (newNameOrFalse) {
                        return from(this.materialMapsExporterService.renameMapsExport(configDBEntry, newNameOrFalse)).pipe(map(() => true))
                    }
                    return of(newNameOrFalse)
                }),
            )
            .subscribe({
                next: (ret) => {
                    if (ret) {
                        this.notifications.showInfo("Maps export renamed")
                        this.refresh.item({id: this.materialId, __typename: ContentTypeModel.Material})
                    }
                },
                error: (err) => {
                    this.notifications.showInfo(`Failed to rename maps export: ${err}`, undefined, "Dismiss")
                    this.refresh.item({id: this.materialId, __typename: ContentTypeModel.Material})
                },
            })
    }
}
