<cm-node-base #nodeBase [typeInfo]="typeInfo">
    <ng-container cm-outputs>
        <cm-node-io
            #nodeIo
            [valueType]="outputVector.valueType"
            [socket]="outputVector"
            [active]="!(hideOutputSocket | memoize: selectedVectorOperation : outputVector.id : selectedVectorOperation)"
        ></cm-node-io>
        <cm-node-io
            #nodeIo
            [valueType]="outputValue.valueType"
            [socket]="outputValue"
            [active]="!(hideOutputSocket | memoize: selectedVectorOperation : outputValue.id : selectedVectorOperation)"
        ></cm-node-io>
    </ng-container>
    <ng-container cm-settings>
        <mat-form-field>
            <mat-select
                panelWidth=""
                [value]="selectedVectorOperation"
                (selectionChange)="nodeBase.onParameterChange(vectorOperation.id, $event.value, vectorOperation.valueType, 'update')"
            >
                @for (operation of vectorOperation.options; track operation) {
                    <mat-option [value]="operation.value">{{ operation.label }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container cm-inputs>
        <cm-node-io #nodeIo [valueType]="inputVector.valueType" [socket]="inputVector"></cm-node-io>
        <cm-node-io
            #nodeIo
            [active]="!(hideInputSocket | memoize: selectedVectorOperation : inputVector001.id : selectedVectorOperation)"
            [valueType]="inputVector001.valueType"
            [socket]="inputVector001"
        ></cm-node-io>
        <cm-node-io
            #nodeIo
            [active]="!(hideInputSocket | memoize: selectedVectorOperation : inputScale.id : selectedVectorOperation)"
            [valueType]="inputScale.valueType"
            [socket]="inputScale"
        ></cm-node-io>
    </ng-container>
</cm-node-base>
