import {Component, inject, computed, Input, model, signal} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatButtonModule} from "@angular/material/button"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {CreateMaterialData} from "@platform/components/materials/materials-grid/materials-grid.component"
import {TagsService} from "@app/common/services/tags/tags.service"

type AddMaterialData = Partial<CreateMaterialData>

@Component({
    selector: "cm-add-material-dialog",
    standalone: true,
    imports: [
        FormsModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        OrganizationSelectComponent,
        MatButtonModule,
    ],
    templateUrl: "./add-material-dialog.component.html",
    styleUrl: "./add-material-dialog.component.scss",
})
export class AddMaterialDialogComponent {
    @Input({required: true}) addMaterial?: (data: AddMaterialData) => Promise<{id: string; legacyId: number}>
    @Input() isBatchMode = false

    organizations = inject(OrganizationsService)
    tags = inject(TagsService)

    $updateSelectedOrganization = signal<{id: string} | undefined | null>(null)
    $selectedOrganization = computed(() => {
        const updateSelectedOrganization = this.$updateSelectedOrganization()
        if (updateSelectedOrganization) return updateSelectedOrganization
        return this.organizations.$current()
    })
    $materialRangesForSelectedOrganization = computed(() => {
        return this.tags.$materialRange().filter((range) => range.organization?.id === this.$selectedOrganization()?.id)
    })

    $name = model<string>("")

    $data = computed<AddMaterialData>(() => {
        return {
            name: this.$name(),
            materialRangeTagId: undefined,
            organizationId: this.$selectedOrganization()?.id,
        }
    })

    canConfirmCreate = () => (!!this.$data().name || this.isBatchMode) && !!this.$data().organizationId

    updateOrganization = (data: {organizationId: string}) => {
        this.$updateSelectedOrganization.set({id: data.organizationId})
    }

    confirmCreate = () => {
        if (this.addMaterial === undefined) throw new Error("Missing addMaterial function in add-material-button component")
        return this.addMaterial(this.$data())
    }
}
