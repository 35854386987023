import {Component, Input} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"

@Component({
    standalone: true,
    selector: "cm-entity-add-card",
    templateUrl: "./entity-add-card.component.html",
    styleUrls: ["./entity-add-card.component.scss"],
    imports: [MatButtonModule],
})
export class EntityAddCardComponent {
    @Input() squareFormat = false

    constructor() {}
}
