import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"

export type ParameterType = {
    sourceImage: ImagePtr
    mode: "luminance" | "average"
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpToGrayscale: ImageOpType<ParameterType, ReturnType> = {
    name: "ToGrayscale",

    WebGL2: async ({context, parameters: {sourceImage, mode, resultImage}}) => {
        const halLevels = await context.getOrCreateImageCompositor(`
            float toGrayscale(vec3 rgb) {
                ${mode === "luminance" ? "return dot(rgb, vec3(0.2126, 0.7152, 0.0722));" : "return (rgb.r + rgb.g + rgb.b) / 3.0;"}
            }

            vec4 computeColor(ivec2 targetPixel) {
                vec4 source = texelFetch0(targetPixel);
                float grayscale = toGrayscale(source.rgb);
                return vec4(grayscale, grayscale, grayscale, 1.0);
            }
        `)
        using sourceImageWebGl2 = await context.getImage(sourceImage)
        resultImage = await context.prepareResultImage(resultImage, {
            width: sourceImageWebGl2.ref.descriptor.width,
            height: sourceImageWebGl2.ref.descriptor.height,
            channelLayout: "R",
            format: sourceImageWebGl2.ref.descriptor.format,
            isSRGB: sourceImageWebGl2.ref.descriptor.isSRGB,
        })
        using resultImageWebGl2 = await context.getImage(resultImage)
        await halLevels.paint(resultImageWebGl2.ref.halImage, sourceImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, mode, resultImage}}) => {
        using sourceImageImgProc = await context.getImage(sourceImage)
        const resultNode = Nodes.toGrayscale(sourceImageImgProc.ref.node, mode)
        using result = await context.createImage(sourceImageImgProc.ref.descriptor, resultNode)
        return await toImgProcResultImage(context, result, resultImage)
    },
}
