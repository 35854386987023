import {Component, OnInit} from "@angular/core"

@Component({
    selector: "cm-floating-button",
    templateUrl: "./floating-button.component.html",
    styleUrls: ["./floating-button.component.scss"],
    standalone: true,
})
export class FloatingButtonComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
