import {Component} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {AreaLight, AreaLightParameters} from "@cm/lib/templates/nodes/area-light"
import {constrainTransform} from "@app/template-editor/helpers/transform"
import {TransformMode} from "@app/template-editor/services/scene-manager.service"
import {Matrix4} from "@app/common/helpers/vector-math"

@Component({
    selector: "cm-area-light-inspector",
    standalone: true,
    templateUrl: "./area-light-inspector.component.html",
    styleUrl: "./area-light-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class AreaLightInspectorComponent extends BaseInspectorComponent<AreaLight> {
    lightTypePossibilites: SelectionPossibilities<AreaLightParameters["lightType"]> = [
        {value: "Corona", name: "Corona"},
        {value: "Blender", name: "Blender"},
    ]

    onChangedTargeted(value: unknown) {
        if (typeof value === "boolean") {
            const node = this.node()

            const {lockedTransform} = node.parameters
            if (!lockedTransform) return

            const {transform: constrainedTransform, target: constrainedTarget} = constrainTransform(
                new Matrix4(lockedTransform),
                {templateNode: node, part: "root"},
                TransformMode.Translate,
            )

            if (constrainedTransform) node.updateParameters({lockedTransform: constrainedTransform.toArray()})
            if (constrainedTarget) node.updateParameters({target: constrainedTarget.toArray()})
        }
    }
}
