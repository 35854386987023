<cm-menu-dialog (close)="close.emit()">
    <ng-container cm-title> Contact Sales </ng-container>
    <ng-container cm-subtitle> Add your details below and one of our sales members will get in contact with you. </ng-container>
    <ng-container cm-content>
        <form #form method="post">
            <cm-input-container>
                <ng-container cm-inline-label>
                    <i class="far fa-user"></i>
                </ng-container>
                <ng-container cm-input>
                    <cm-native-input-text (valueChange)="result.name = $event" [required]="true" [showInvalid]="showErrors" [placeholder]="'Name *'">
                    </cm-native-input-text>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label>
                    <i class="far fa-envelope"></i>
                </ng-container>
                <ng-container cm-input>
                    <cm-native-input-text
                        (valueChange)="result.email = $event"
                        [required]="true"
                        [type]="'email'"
                        [showInvalid]="showErrors"
                        [placeholder]="'Email *'"
                    >
                    </cm-native-input-text>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label>
                    <i class="far fa-phone"></i>
                </ng-container>
                <ng-container cm-input>
                    <cm-native-input-text (valueChange)="result.phoneNumber = $event" [type]="'tel'" [placeholder]="'Phone number'"> </cm-native-input-text>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label>
                    <i class="far fa-building"></i>
                </ng-container>
                <ng-container cm-input>
                    <cm-native-input-text (valueChange)="result.companyName = $event" [placeholder]="'Company'"> </cm-native-input-text>
                </ng-container>
            </cm-input-container>
            <div (click)="submitEmailForm(form)" class="cm-submit-button">Send Request</div>
        </form>
    </ng-container>
</cm-menu-dialog>
