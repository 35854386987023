import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"

export type ParameterType = {
    sourceImage: ImagePtr
}

export type ReturnType = ImageDescriptor

export type ImageDescriptor = {
    readonly width: number
    readonly height: number
    readonly channelLayout: ChannelLayout
    readonly format: Format
    readonly isSRGB: boolean
}

export type ChannelLayout = "RGBA" | "RGB" | "R"

export type Format = "uint8" | "float32" | "float16"

export const imageOpGetImageDesc: ImageOpType<ParameterType, ReturnType> = {
    name: "GetImageDesc",

    WebGL2: async ({context, parameters: {sourceImage}}) => {
        return await context.getImageDescriptor(sourceImage)
    },

    ImgProc: async ({context, parameters: {sourceImage: source}}) => {
        return await context.getImageDescriptor(source)
    },
}
