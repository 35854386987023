<div class="cm-corners-layout-container">
    <div class="cm-corners-layout-main">
        <ng-content></ng-content>
    </div>
    <div class="cm-corners-layout-top-left">
        <ng-content select="[cm-top-left]"></ng-content>
    </div>
    <div class="cm-corners-layout-top-right">
        <ng-content select="[cm-top-right]"></ng-content>
    </div>
    <div class="cm-corners-layout-bottom-right">
        <ng-content select="[cm-bottom-right]"></ng-content>
    </div>
    <div class="cm-corners-layout-bottom-left">
        <ng-content select="[cm-bottom-left]"></ng-content>
    </div>
</div>
