@if (legacyId) {
    <button (click)="$event.stopPropagation()" class="cm-simple-inert-button">
        <ng-content select="[cm-image-bottom-right]"></ng-content>
        <div
            #clipboardTooltip="matTooltip"
            matTooltip="Copy to clipboard"
            (mouseenter)="clipboardTooltip.message = 'Copy to clipboard'"
            (click)="utils.copyToClipboardWithTooltip(legacyId, clipboardTooltip)"
            class="cm-item-id"
        >
            {{ legacyId }}
        </div>
    </button>
}
