import {Component, Input} from "@angular/core"
import {MatTooltip} from "@angular/material/tooltip"
import {UtilsService} from "@legacy/helpers/utils"

@Component({
    selector: "cm-copy-legacy-id-button",
    standalone: true,
    imports: [MatTooltip],
    templateUrl: "./copy-legacy-id-button.component.html",
    styleUrl: "./copy-legacy-id-button.component.scss",
})
export class CopyLegacyIdButtonComponent {
    @Input() legacyId?: number

    constructor(public utils: UtilsService) {}
}
