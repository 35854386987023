import {Component, DestroyRef, EventEmitter, inject, Input, Output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {IdInFilter} from "@api"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {DataLoaderWithFilter} from "@platform/models/data"
import {DataLoaderService} from "@platform/services/data/data-loader/data-loader.service"
import {BehaviorSubject, debounceTime, combineLatest, map} from "rxjs"
import {FiltersService} from "@common/services/filters/filters.service"
import {DialogSize} from "@common/models/dialogs"
import {GridSize} from "@app/common/components/lists"

@Component({
    selector: "cm-base-select",
    standalone: true,
    imports: [],
    template: "",
    styleUrl: "./base-select.component.scss",
})
export class BaseSelectComponent<EntityType extends {id: string}, FilterInputType extends {search?: string | null; organizationId?: IdInFilter | null}> {
    // fixed filters applied irrespective of user selected filters
    @Input() customFilters?: Omit<FilterInputType, "search" | "organizationId">
    @Output() selectItem = new EventEmitter<EntityType>()
    @Output() done = new EventEmitter<void>()
    @Output() cancel = new EventEmitter<void>()
    @Input() dialogSize = DialogSize.Large
    @Input() gridSize: GridSize = "large"

    pageFilledStatusChange$ = new BehaviorSubject(false)
    searchText$ = new BehaviorSubject("")
    organizationsIds$ = new BehaviorSubject<string[] | undefined>(undefined)

    dataLoader = inject(DataLoaderService)
    destroyRef = inject(DestroyRef)
    filters = inject(FiltersService)
    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)

    // filters selected by the user within the dialog
    uiFilters$ = combineLatest([this.searchText$, this.organizationsIds$]).pipe(
        takeUntilDestroyed(),
        debounceTime(300),
        map(([search, maybeOrganizationIds]) => ({
            search,
            organizationId: maybeOrganizationIds ? {in: maybeOrganizationIds} : undefined,
        })),
    )

    implementDataLoader = (gqlQuery: DataLoaderWithFilter<EntityType, FilterInputType>) =>
        this.dataLoader.$batchedData<EntityType>(
            this.uiFilters$.pipe(
                takeUntilDestroyed(),
                map((uiFilters) => (skip: number, take: number) => {
                    return gqlQuery({
                        skip,
                        take,
                        filter: {
                            ...uiFilters,
                            ...this.customFilters,
                        } as FilterInputType,
                    })
                }),
            ),
            this.pageFilledStatusChange$.pipe(map((value) => !value)),
        )

    onSelectItem(item: EntityType) {
        this.selectItem.next(item)
        this.done.next()
    }

    cancelDialog() {
        this.cancel.next()
        this.done.next()
    }
}
