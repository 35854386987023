import {Component, Input} from "@angular/core"
import {SectionContentComponent} from "@common/components/item/item-details/section-content/section-content.component"
import {SectionTitleComponent} from "@common/components/item/item-details/section-title/section-title.component"

@Component({
    selector: "cm-section",
    standalone: true,
    templateUrl: "./section.component.html",
    styleUrls: ["./section.component.scss"],
    imports: [SectionTitleComponent, SectionContentComponent],
})
export class SectionComponent {
    @Input() indentContent = true
}
