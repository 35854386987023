import {map, Observable} from "rxjs"
import {MeshData} from "@cm/lib/geometry-processing/mesh-data"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {evaluateProceduralGeometry, WebAssemblyWorkerService} from "@app/editor/helpers/mesh-processing/mesh-processing"
import {RenderNodes} from "@cm/lib/rendering/render-nodes"
import {hashObject} from "@cm/lib/utils/hashing"

export class ProceduralMeshDataCache {
    private cache: AsyncCacheMap<string, MeshData, RenderNodes.MeshData>

    constructor(private workerService: WebAssemblyWorkerService) {
        this.cache = new AsyncCacheMap((key, graph) => {
            return evaluateProceduralGeometry(this.workerService, graph)
        })
    }

    getMeshData(graph: RenderNodes.MeshData): Observable<MeshData> {
        return this.cache.get(hashObject(graph), graph)
    }

    invalidate() {
        this.cache.clear()
    }
}
