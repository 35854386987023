import {JobNodes, makeJobTaskDescriptor} from "@src/job-task/job-nodes"
import DataObjectReference = JobNodes.DataObjectReference

export type UploadProcessingInput = {
    dataObject: DataObjectReference
}

export type UploadProcessingOutput = {
    dataObject: DataObjectReference
}

export const uploadProcessingMetadataTask = makeJobTaskDescriptor<UploadProcessingInput, UploadProcessingOutput>("UploadProcessing.metadata", 1)
export const uploadProcessingThumbnailsTask = makeJobTaskDescriptor<UploadProcessingInput, UploadProcessingOutput>("UploadProcessing.thumbnails", 1)
