import {NodeGraph, ParameterValue, isNodeGraphInstance} from "@src/graph-system/node-graph"
import {NodeGraphResultBase} from "@src/graph-system/evaluators/node-graph-result-base"

const TRACE = false

export class DisposableCachedNodeGraphResult<ReturnType, Context> extends NodeGraphResultBase<ReturnType, Context> {
    constructor(
        root: ParameterValue<ReturnType, Context>,
        context: Context,
        private disposeFn?: (nodeGraph: NodeGraph<unknown, Context>, result: unknown) => void,
        disableValidation: boolean = false,
    ) {
        super(root, context, disableValidation)
    }

    async run() {
        /**
         * Avoid recursion because allow maximum call stack size is low any may be exceeded.
         */
        if (!isNodeGraphInstance<ReturnType, Context>(this.root)) {
            return this.root
        }
        // count references
        const refCountByNode = new Map<NodeGraph<unknown, Context>, number>()
        const traverse = (node: NodeGraph<unknown, Context>): void => {
            const refCount = refCountByNode.get(node)
            if (refCount == null) {
                refCountByNode.set(node, 1)
                node.children.forEach((c) => traverse(c))
            } else {
                refCountByNode.set(node, refCount + 1)
            }
        }
        traverse(this.root)
        // evaluate the graph
        const cachedValueByNode = new Map<NodeGraph<unknown, Context>, unknown>()
        const pendingPromiseByNode = new Map<NodeGraph<unknown, Context>, Promise<unknown>>()
        const get = <ReturnType>(node: ParameterValue<ReturnType, Context>): Promise<ReturnType> => {
            if (TRACE) {
                console.log(`Getting parameter `, node)
            }
            if (!isNodeGraphInstance<ReturnType, Context>(node)) {
                return Promise.resolve(node)
            } else {
                // check if we have a pending promise for this parameter already
                const pendingPromise = pendingPromiseByNode.get(node)
                if (pendingPromise) {
                    if (TRACE) {
                        console.log(`Found pending promise for `, node)
                    }
                    return pendingPromise as Promise<ReturnType>
                }
                // check if we have a cached value for this parameter already
                const cachedValue = cachedValueByNode.get(node)
                if (cachedValue !== undefined) {
                    if (TRACE) {
                        console.log(`Found cached value for `, node, cachedValue)
                    }
                    return Promise.resolve(cachedValue as ReturnType)
                }
                // create a new promise for this parameter and push it onto the stack
                const promise = this.evaluateGraph(node, <R>(parameter: ParameterValue<R, Context>): Promise<R> => {
                    if (!isNodeGraphInstance<R, Context>(parameter)) {
                        return Promise.resolve(parameter)
                    } else {
                        const parameterPromise = new Promise<R>((resolve) =>
                            queueMicrotask(() => {
                                get(parameter).then((value) => {
                                    resolve(value)
                                })
                            }),
                        )
                        return parameterPromise
                    }
                }).then((value) => {
                    pendingPromiseByNode.delete(node)
                    // cache the value
                    cachedValueByNode.set(node, value)
                    // adjust ref-counts of children and dispose of them if necessary
                    const disposeFn = this.disposeFn
                    if (disposeFn) {
                        node.children.forEach((child) => {
                            let refCount = refCountByNode.get(child)
                            if (refCount === undefined) {
                                throw new Error(`Node ${child.constructor.name} not found in ref count map`)
                            }
                            if (refCount === 0) {
                                throw new Error(`Node ${child.constructor.name} reached a ref count of zero prematurely`)
                            }
                            refCount--
                            refCountByNode.set(child, refCount)
                            if (TRACE) {
                                console.log(`Decrementing ref-count of `, child, ` leaving ref-count of ${refCount}`)
                            }
                            if (refCount === 0) {
                                if (TRACE) {
                                    console.log(`Disposing `, child, cachedValueByNode.get(child))
                                }
                                const childValue = cachedValueByNode.get(child)
                                if (childValue === undefined) {
                                    throw new Error(`Cached value not found for node ${child.constructor.name}`)
                                }
                                disposeFn(child, childValue)
                            }
                        })
                    }
                    return value
                })
                pendingPromiseByNode.set(node, promise)
                return promise
            }
        }
        const rootValue = await get(this.root)
        return rootValue as ReturnType
    }

    runSync(): ReturnType {
        throw new Error(`runSync not implemented for DisposableCachedNodeGraphResult`)
    }
}
