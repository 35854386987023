import {Component} from "@angular/core"
import {SelectTemplateItemFragment, TemplateFilterInput} from "@api"
import {OwnOrganizationsFilterComponent} from "@app/common/components/filters/own-organizations-filter/own-organizations-filter.component"
import {SelectDialogComponent} from "@common/components/dialogs/select-dialog/select-dialog.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {BaseSelectComponent} from "@platform/components/base/base-select/base-select.component"

@Component({
    selector: "cm-select-template",
    standalone: true,
    imports: [SelectDialogComponent, SearchComponent, OwnOrganizationsFilterComponent],
    templateUrl: "./select-template.component.html",
    styleUrl: "./select-template.component.scss",
})
export class SelectTemplateComponent extends BaseSelectComponent<SelectTemplateItemFragment, TemplateFilterInput> {
    $data = this.implementDataLoader(this.sdk.gql.selectTemplateItems)
}
