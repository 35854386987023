import {CachedNodeGraphResult} from "@src/graph-system/evaluators/cached-node-graph-result"
import {TemplateListNode} from "@src/templates/declare-template-node"
import {getLodTypeDescription, isTemplateNode, TemplateNode, VisitMode, VisitorNodeContext} from "@src/templates/types"
import {
    isMaterialLike,
    isNodeOwner,
    isOutput,
    isTemplateContainer,
    isTemplateOwningContainer,
    isTemplateReferenceContainer,
    isValue,
    Node,
    NodeOwner,
    Switch,
    TemplateOwningContainer,
} from "@src/templates/node-types"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {mapGraphParameters, traverseGraphParameters} from "@src/graph-system/node-graph"
import {ensureValidParameters} from "@src/graph-system/validation"
import * as changeCase from "change-case"
import {RigidRelation} from "@src/templates/nodes/rigid-relation"
import {MaterialAssignment, MaterialAssignments} from "@src/templates/nodes/material-assignment"
import {Parameters, TemplateInstance} from "@src/templates/nodes/template-instance"
import {Nodes} from "@src/templates/nodes/nodes"
import {isNamedNode} from "@src/templates/nodes/named-node"
import {LodType} from "./nodes/lod-type"

export const getTemplateNodeClassLabel = (node: TemplateNode | string) => {
    return changeCase.capitalCase(typeof node === "string" ? node : node.getNodeClass())
}

export const getTemplateNodeLabel = (node: TemplateNode | null): string => {
    if (!node) return "???"

    if (isNamedNode(node) && node.parameters.name.length > 0) return node.parameters.name

    if (node instanceof RigidRelation) return `Align [${getTemplateNodeLabel(node.parameters.targetA)}] to [${getTemplateNodeLabel(node.parameters.targetB)}]`
    else if (isValue(node)) return `Value: ${JSON.stringify(node.parameters.value)}`
    else if (node instanceof LodType) return `LOD Type: ${getLodTypeDescription(node.parameters.lodType)}`

    return getTemplateNodeClassLabel(node)
}

export const getTemplateSwitchItemLabel = (node: Switch) => {
    return getTemplateNodeClassLabel(node).replace(" Switch", "")
}

export function getUniqueTemplateNodeParent(node: TemplateNode, callback?: (parent: TemplateNode) => void): TemplateNode {
    if (node.parents.size === 1) {
        const [parent] = node.parents

        if (isTemplateNode(parent)) {
            if (callback) callback(parent)
            return parent
        }
    }

    throw new Error("Node has invalid number of node parents")
}

export function getNodeOwner(node: Node | Nodes, callback?: (nodeOwner: NodeOwner) => void): NodeOwner | null {
    if (node instanceof Nodes) {
        const nodeOwner = getUniqueTemplateNodeParent(node)
        if (!isNodeOwner(nodeOwner)) throw new Error("Nodes has no node parent")
        if (callback) callback(nodeOwner)
        return nodeOwner
    }

    const nodesParents = [...node.parents].filter((nodes): nodes is Nodes => nodes instanceof Nodes)
    if (nodesParents.length === 0) return null
    else if (nodesParents.length === 1) {
        const [nodes] = nodesParents
        return getNodeOwner(nodes, callback)
    } else throw new Error("Node has multiple node parents")
}

export function expandTemplateNodesToDelete(nodes: Set<TemplateNode>) {
    const nodesToDelete = new Set<TemplateNode>()
    const collectNodesToDelete = (node: TemplateNode) => {
        nodesToDelete.add(node)
        if (isNodeOwner(node)) {
            nodesToDelete.add(node.parameters.nodes)
            node.parameters.nodes.parameters.list.forEach(collectNodesToDelete)
        }
    }
    nodes.forEach(collectNodesToDelete)

    return nodesToDelete
}

export function getReferencingDeletedTemplateNodes(templateGraph: TemplateGraph, nodes: Set<TemplateNode>) {
    const nodesToDelete = expandTemplateNodesToDelete(nodes)

    const nodesToDeleteOwningParentContainers = new Set<TemplateOwningContainer>()
    nodesToDelete.forEach((node) => {
        node.parents.forEach((parent) => {
            if (isTemplateNode(parent) && isTemplateOwningContainer(parent) && !nodesToDelete.has(parent)) nodesToDeleteOwningParentContainers.add(parent)
        })
    })

    const passReferenceDeleteToParent: {
        appliesTo: (node: TemplateNode) => boolean
        passChildReferenceDeleteToParent: ((child: TemplateNode) => boolean)[]
        mapToParentParamaters: (node: TemplateNode, parent: TemplateNode, passedReferences: Set<TemplateNode>) => Set<TemplateNode>
    }[] = [
        {
            appliesTo: (node) => node instanceof MaterialAssignment,
            passChildReferenceDeleteToParent: [(child) => isMaterialLike(child)],
            mapToParentParamaters: (node, parent) => {
                if (!(parent instanceof MaterialAssignments)) throw new Error("Invalid parent type for MaterialAssignment")
                return new Set([node])
            },
        },
        {
            appliesTo: (node) => isOutput(node),
            passChildReferenceDeleteToParent: [(child) => child instanceof TemplateInstance],
            mapToParentParamaters: (node, parent) => {
                if (!(parent instanceof Parameters)) throw new Error("Invalid parent type for Output")
                return new Set([node])
            },
        },
    ]

    const referencingDeletedNodes: Map<TemplateNode, Set<TemplateNode>> = new Map()
    for (const node of getAllTemplateNodes(templateGraph)) {
        if (nodesToDelete.has(node)) continue
        if (isTemplateOwningContainer(node)) if (nodesToDeleteOwningParentContainers.has(node)) continue
        if (isTemplateReferenceContainer(node)) {
            const templateContainerParent = getUniqueTemplateNodeParent(node)
            if (nodesToDelete.has(templateContainerParent)) continue
        }

        const referencingChildsToDelete = new Set<TemplateNode>()
        traverseGraphParameters(node.parameters, (child) => {
            if (isTemplateNode(child)) {
                if (nodesToDelete.has(child)) referencingChildsToDelete.add(child)
            }
        })

        if (referencingChildsToDelete.size > 0) {
            const passChildReferenceDeleteToParentData = passReferenceDeleteToParent.filter(
                (data) => data.appliesTo(node) && data.passChildReferenceDeleteToParent.length > 0,
            )
            if (passChildReferenceDeleteToParentData.length > 0) {
                if (passChildReferenceDeleteToParentData.length !== 1) throw new Error("Invalid passChildDeleteToParentData")
                const {passChildReferenceDeleteToParent, mapToParentParamaters} = passChildReferenceDeleteToParentData[0]

                const passedChildren = new Set(
                    [...referencingChildsToDelete].filter((child) =>
                        passChildReferenceDeleteToParent.some((passChildReferenceDeleteToParent) => passChildReferenceDeleteToParent(child)),
                    ),
                )
                if (passedChildren.size > 0) {
                    const otherChildren = new Set([...referencingChildsToDelete].filter((child) => !passedChildren.has(child)))
                    if (otherChildren.size > 0) referencingDeletedNodes.set(node, otherChildren)
                    const parent = getUniqueTemplateNodeParent(node)
                    const existing = referencingDeletedNodes.get(parent)
                    const parentChildren = mapToParentParamaters(node, parent, passedChildren)
                    if (existing) parentChildren.forEach((node) => existing.add(node))
                    else referencingDeletedNodes.set(parent, parentChildren)

                    continue
                }
            }

            referencingDeletedNodes.set(node, referencingChildsToDelete)
        }
    }

    return referencingDeletedNodes
}

export function deleteNodesFromTemplateGraph(templateGraph: TemplateGraph, nodes: Set<TemplateNode>) {
    const nodesToDelete = expandTemplateNodesToDelete(nodes)
    const referencingDeletedNodes = getReferencingDeletedTemplateNodes(templateGraph, nodesToDelete)

    //Dry run to check if we can safely unlink the referenced nodes
    for (const [node, children] of referencingDeletedNodes) {
        if (!isTemplateContainer(node)) {
            const newParameters = mapGraphParameters(node.parameters, (node) => {
                if (!isTemplateNode(node) || !children.has(node)) return node
                return null
            })
            try {
                ensureValidParameters(node, newParameters)
            } catch (e) {
                console.error("Failed to unreference node", node, children)
                throw new Error(`Failed to unreference node ${getTemplateNodeLabel(node)}`)
            }
        }
    }

    //Unlink the referenced nodes
    for (const [node, children] of referencingDeletedNodes) {
        if (isTemplateContainer(node))
            children.forEach((child) => {
                if (isTemplateNode(child)) (node as TemplateListNode<TemplateNode>).removeEntry(child)
            })
        else {
            const newParameters = mapGraphParameters(node.parameters, (node) => {
                if (!isTemplateNode(node) || !children.has(node)) return node
                return null
            })
            node.replaceParameters(newParameters)
        }
    }

    //Remove the nodes to delete
    nodesToDelete.forEach((node) => {
        node.parents.forEach((parent) => {
            if (isTemplateNode(parent) && isTemplateContainer(parent)) (parent as TemplateListNode<TemplateNode>).removeEntry(node)
        })
    })

    //Validation that all nodes are deleted
    const failedToDelete = new Set<TemplateNode>()
    for (const node of getAllTemplateNodes(templateGraph)) if (nodesToDelete.has(node)) failedToDelete.add(node)

    if (failedToDelete.size > 0) {
        console.error("Failed to delete nodes", failedToDelete)
        throw new Error("Failed to successfully delete all nodes")
    }
}

export function getAllTemplateNodes(templateGraph: TemplateGraph) {
    const allNodes: TemplateNode[] = []

    const context: VisitorNodeContext = {
        visitMode: VisitMode.TraverseAll,
        onTraverseAll: {},
        onPostUnskippedNode: function (this: TemplateNode) {
            allNodes.push(this)
        },
    }

    const resultTraverseAll = new CachedNodeGraphResult(templateGraph, context)
    resultTraverseAll.runSync()

    return allNodes
}
