import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"

export function captureSnapshot(threeSceneManagerService: ThreeSceneManagerService, mimeType: "image/png" | "image/jpeg", quality?: number) {
    const mainCanvas = threeSceneManagerService.getRenderer().domElement

    /*the main canvas has transparent background, draw it on top of a white surface.
    The fill color in the past was taken from canvas.style.background, but is empty for the new viewer.*/
    const tmpCanvas = document.createElement("canvas")
    tmpCanvas.width = mainCanvas.width
    tmpCanvas.height = mainCanvas.height
    const context = tmpCanvas.getContext("2d")
    if (!context) throw new Error("Could not get 2d context from canvas")
    context.fillStyle = "rgb(255, 255, 255)"
    context.fillRect(0, 0, mainCanvas.width, mainCanvas.height)
    context.drawImage(mainCanvas, 0, 0)

    return tmpCanvas.toDataURL(mimeType, quality)
}
