import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const gateDescriptor = {
    input: inletDescriptor<any>(),
    gate: inletDescriptor<boolean>(),
    output: outletDescriptor<any>(),
}

export class Gate<T> implements NodeClassImpl<typeof gateDescriptor, typeof Gate> {
    static descriptor = gateDescriptor
    static uniqueName = "Gate"
    input!: Inlet<T>
    gate!: Inlet<boolean>
    output!: Outlet<T>

    run() {
        if (this.input === NotReady || this.gate === NotReady) {
            // this.output.emitIfChanged(NotReady);
            return
        }
        if (!this.gate) return
        this.output.emitIfChanged(this.input)
    }
}
