// @ts-strict-ignore
import {AfterViewInit, Component, inject, ViewChild} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute} from "@angular/router"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {filter, switchMap, take, timer} from "rxjs"
import {AuthService} from "@common/services/auth/auth.service"
import {IsDefined} from "@cm/lib/utils/filter"

@Component({
    selector: "cm-backend-ar-generation",
    templateUrl: "./backend-ar-generation.component.html",
    styleUrls: ["./backend-ar-generation.component.scss"],
    standalone: true,
    imports: [SceneViewerComponent],
})
export class BackendArGenerationComponent implements AfterViewInit {
    @ViewChild("sceneViewer") sceneViewer: SceneViewerComponent
    sceneConfigurationString: string
    templateLegacyId: number

    auth = inject(AuthService)
    sdk = inject(SdkService)

    constructor(
        private route: ActivatedRoute,
        private uploadService: UploadGqlService,
        private snackBar: MatSnackBar,
    ) {
        this.decodeConfigurationString()
        this.decodeTemplateId()
    }

    ngAfterViewInit(): void {
        // need to wait for the user to be loaded due to Angular bug in headless mode:
        // component is activated before the `canActivate` guard is resolved
        this.auth.userId$.pipe(filter(IsDefined), take(1)).subscribe(() => {
            this.sceneViewer.loadTemplateWithLegacyId(this.templateLegacyId)
        })
    }

    decodeConfigurationString(): void {
        const configStringB64: string = this.route.snapshot.queryParamMap.get("configString")
        if (configStringB64 == null) {
            const errorMessage = "The configString query parameter is missing."
            this.snackBar.open(errorMessage, "", {duration: 5000})
            throw Error(errorMessage)
        }
        this.sceneConfigurationString = atob(configStringB64)
    }

    decodeTemplateId(): void {
        const templateIdString = this.route.snapshot.queryParamMap.get("templateId")
        if (templateIdString == null) {
            const errorMessage = "The templateId query parameter is missing."
            this.snackBar.open(errorMessage, "", {duration: 5000})
            throw Error(errorMessage)
        }
        this.templateLegacyId = parseInt(templateIdString, 10)
    }

    loadingCompleted(): void {
        timer(100).subscribe(async () => {
            await this.removePreviousData()
            console.log("Exporting scene with config string:", this.sceneConfigurationString)
            this.setupAndExportScene().subscribe(() => {
                console.log("Export and upload completed.")
                // Let puppeteer know that the AR generation finished.
                window.status = "ready"
            })
        })
    }

    async removePreviousData(): Promise<void> {
        const {template} = await this.sdk.gql.backendArGenerationTemplate({
            legacyId: this.templateLegacyId,
            assignmentKey: {equals: this.sceneConfigurationString},
        })
        for (const gltfAssignment of template.latestRevision.gltfAssignments) {
            // remove related data objects first to avoid foreign key constraint violation
            for (const relatedDataObject of gltfAssignment.dataObject.related) {
                await this.sdk.gql.backendArGenerationDeleteDataObject({dataObjectId: relatedDataObject.id})
            }
            await this.sdk.gql.backendArGenerationDeleteDataObject({dataObjectId: gltfAssignment.dataObject.id})
        }
    }

    setupAndExportScene() {
        return this.sceneViewer.setConfigurationString(this.sceneConfigurationString).pipe(
            switchMap(() => {
                return this.sceneViewer.generateAndUploadGLTF(this.uploadService)
            }),
        )
    }
}
