import {Vector2, Vector2Like} from "@src/math/vector2"
import {Size2Like} from "@src/math/size2"

export type Box2Like = {x: number; y: number; width: number; height: number}

export class Box2 {
    constructor(
        public x = 0,
        public y = 0,
        public width = 0,
        public height = 0,
    ) {}

    set(x: number, y: number, width: number, height: number) {
        this.x = x
        this.y = y
        this.width = width
        this.height = height
        return this
    }

    setFromPositionAndSize(position: Vector2Like, size: Vector2Like) {
        this.x = position.x
        this.y = position.y
        this.width = size.x
        this.height = size.y
        return this
    }

    setFromMinMax(min: Vector2Like, max: Vector2Like) {
        this.x = min.x
        this.y = min.y
        this.width = max.x - min.x
        this.height = max.y - min.y
        return this
    }

    setFromBounds(x1: number, y1: number, x2: number, y2: number): Box2 {
        this.x = Math.min(x1, x2)
        this.y = Math.min(y1, y2)
        this.width = Math.max(x1, x2) - this.x
        this.height = Math.max(y1, y2) - this.y
        return this
    }

    setFromBox2Like(b: Box2Like): Box2 {
        this.x = b.x
        this.y = b.y
        this.width = b.width
        this.height = b.height
        return this
    }

    get min(): Vector2 {
        return this.leftUpper
    }

    get max(): Vector2 {
        return this.rightLower
    }

    get leftUpper(): Vector2 {
        return new Vector2(this.x, this.y)
    }

    get rightUpper(): Vector2 {
        return new Vector2(this.x + this.width, this.y)
    }

    get leftLower(): Vector2 {
        return new Vector2(this.x, this.y + this.height)
    }

    get rightLower(): Vector2 {
        return new Vector2(this.x + this.width, this.y + this.height)
    }

    get position(): Vector2 {
        return this.leftUpper
    }

    get size(): Vector2 {
        return new Vector2(this.width, this.height)
    }

    toArray(): [number, number, number, number] {
        return [this.x, this.y, this.width, this.height]
    }

    static fromArray(a: number[]) {
        return new this(a[0], a[1], a[2], a[3])
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    static fromSize(size: Size2Like) {
        return new this().setFromBounds(0, 0, size.width, size.height)
    }

    static fromPositionAndSize(position: Vector2Like, size: Vector2Like) {
        return new this().setFromBounds(position.x, position.y, position.x + size.x, position.y + size.y)
    }

    static fromMinMax(min: Vector2Like, max: Vector2Like) {
        return new this().setFromMinMax(min, max)
    }

    static fromBounds(x1: number, y1: number, x2: number, y2: number) {
        return new this().setFromBounds(x1, y1, x2, y2)
    }

    static fromBox2Like(b: Box2Like) {
        return new this().setFromBox2Like(b)
    }

    equals(other: Box2Like) {
        return this.x === other.x && this.y === other.y && this.width === other.width && this.height === other.height
    }

    clone(): Box2 {
        return new Box2(this.x, this.y, this.width, this.height)
    }

    isEmpty(): boolean {
        return Box2.isEmpty(this)
    }

    static isEmpty(box: Box2Like) {
        return box.width <= 0 || box.height <= 0
    }

    makeEmpty() {
        this.x = this.y = 0
        this.width = this.height = 0
    }

    containsPoint(point: Vector2Like): boolean {
        return point.x >= this.x && point.x <= this.x + this.width && point.y >= this.y && point.y <= this.y + this.height
    }

    expandByPoint(point: Vector2Like) {
        if (this.isEmpty()) {
            this.x = point.x
            this.y = point.y
            this.width = this.height = 1 // TODO this might be an issue but in the current implementation is needed to avoid emptyness when expanding by points
        } else {
            this.x = Math.min(this.x, point.x)
            this.y = Math.min(this.y, point.y)
            this.width = Math.max(this.width, point.x - this.x)
            this.height = Math.max(this.height, point.y - this.y)
        }
        return this
    }

    expandByBox(box: Box2Like) {
        if (Box2.isEmpty(box)) {
            return this
        }
        if (this.isEmpty()) {
            this.x = box.x
            this.y = box.y
            this.width = box.width
            this.height = box.height
        } else {
            const x = Math.min(this.x, box.x)
            const y = Math.min(this.y, box.y)
            this.width = Math.max(this.x + this.width, box.x + box.width) - x
            this.height = Math.max(this.y + this.height, box.y + box.height) - y
            this.x = x
            this.y = y
        }
        return this
    }

    expandToIntegers() {
        if (!this.isEmpty()) {
            const x = Math.floor(this.x)
            const y = Math.floor(this.y)
            this.width = Math.ceil(this.x + this.width) - x
            this.height = Math.ceil(this.y + this.height) - y
            this.x = x
            this.y = y
        }
        return this
    }

    intersect(box: Box2Like) {
        if (this.isEmpty() || Box2.isEmpty(box)) {
            this.makeEmpty()
        } else {
            const x = Math.max(this.x, box.x)
            const y = Math.max(this.y, box.y)
            this.width = Math.min(this.x + this.width, box.x + box.width) - x
            this.height = Math.min(this.y + this.height, box.y + box.height) - y
            if (this.width <= 0 || this.height <= 0) {
                this.makeEmpty()
            } else {
                this.x = x
                this.y = y
            }
        }
        return this
    }
}
