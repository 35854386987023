import {TemplateRevisionDetailsForTemplateEditorFragment, TemplateRevisionFilterInput} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {deserializeNodeGraph} from "@cm/lib/graph-system/node-graph"
import {LegacyTemplateConverter} from "@cm/lib/templates/legacy-template-converter"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {jsonToGraph} from "@cm/lib/utils/graph-json"
import {Nodes as LegacyNodes} from "@cm/lib/templates/legacy/template-nodes"

export enum TemplateEditorType {
    Old = "edit",
    New = "edit-new",
}
export const isNewTemplateSystem = (graph: unknown): boolean => {
    if (!graph) return true
    if (JSON.stringify(graph).includes("$class")) return true
    return false
}

export const getEditorType = (graph: unknown): TemplateEditorType => {
    if (isNewTemplateSystem(graph)) return TemplateEditorType.New
    return TemplateEditorType.Old
}

export const getOldSystemRevision = async (templateId: string, sdk: SdkService): Promise<TemplateRevisionDetailsForTemplateEditorFragment> => {
    const filterInput: TemplateRevisionFilterInput = {
        number: 1, //the old editor's revision is always 1
        templateId,
    }

    const {templateRevisions} = await sdk.gql.getTemplateRevisionsDetailsForTemplateEditor({filter: filterInput})
    if (templateRevisions.length === 0) throw new Error("Template revision not found")
    if (templateRevisions.length > 1) throw new Error("Multiple template revisions found")

    const revision = templateRevisions[0]!
    if (!revision.graph) return revision //the old editor can handle empty graphs

    if (isNewTemplateSystem(revision.graph)) throw new Error("No revision for old editor found")

    return revision
}

export const loadGraphForNewTemplateSystem = (graphJson: any): TemplateGraph => {
    if (isNewTemplateSystem(graphJson)) {
        const templateGraph = deserializeNodeGraph(graphJson)
        if (!(templateGraph instanceof TemplateGraph)) throw new Error("Deserialized node is not a TemplateGraph")
        return templateGraph
    } else {
        const templateGraph = jsonToGraph(graphJson) as LegacyNodes.TemplateGraph
        if (templateGraph.schema !== LegacyNodes.currentTemplateGraphSchema)
            throw new Error(`Invalid template graph schema: '${templateGraph.schema}' != '${LegacyNodes.currentTemplateGraphSchema}'`)

        const converter = new LegacyTemplateConverter()
        return converter.convertTemplateGraph(templateGraph)
    }
}
