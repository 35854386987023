<h2 mat-dialog-title>{{ title }}</h2>
<div mat-dialog-content [innerHtml]="message"></div>
<div mat-dialog-actions align="end">
    <button mat-button [color]="isDestructive ? 'warn' : 'primary'" (click)="confirmAction()">{{ confirmLabel }}</button>
    @if (otherActions) {
        @for (option of otherActions; track option) {
            <button mat-button (click)="confirmAction(option.state)">{{ option.label }}</button>
        }
    }
    <button mat-button (click)="cancelAction()" autofocus>{{ cancelLabel }}</button>
</div>
