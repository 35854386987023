import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextComponent} from "@common/components/inputs/native/native-input-text/native-input-text.component"
import {FiltersService} from "@common/services/filters/filters.service"
import {debounceTime, distinctUntilChanged, Subject} from "rxjs"

@Component({
    selector: "cm-search-filter",
    templateUrl: "./search-filter.component.html",
    styleUrls: ["./search-filter.component.scss"],
    standalone: true,
    imports: [InputContainerComponent, NativeInputTextComponent],
})
export class SearchFilterComponent implements OnInit {
    public searchText = ""
    public searchTextChanged = new Subject<string>()
    @Input() placeholder = "Search..."

    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()
    @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()

    filtersService = inject(FiltersService)
    destroyRef = inject(DestroyRef)

    ngOnInit(): void {
        // grab the initial search text (if any) from the URL
        this.searchText = this.filtersService.currentStates.search ?? ""
        this.searchTextChanged.pipe(debounceTime(500), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)).subscribe((text) => {
            void this.filtersService.updateSearchText(text)
        })
    }
}
