import {Directive, Input, TemplateRef} from "@angular/core"
import {MatTable} from "@angular/material/table"

@Directive({selector: "[matCellDef]", standalone: true})
export class TypedMatCellDefDirective<T> {
    @Input()
    matCellDefTable!: MatTable<T>

    constructor(private contentTemplate: TemplateRef<T>) {}

    static ngTemplateContextGuard<T>(dir: TypedMatCellDefDirective<T>, ctx: unknown): ctx is {$implicit: T} {
        return true
    }
}
