import {Settings} from "@common/models/settings/settings"
import {TextureType} from "@api"

export const GRAPH_SCHEMA = [1, 1, 0]
export const graphSchemaString = (): string => {
    return `${GRAPH_SCHEMA[0]}.${GRAPH_SCHEMA[1]}.${GRAPH_SCHEMA[2]}`
}

export const MATERIAL_TEMPLATE_0_MATERIAL_REVISION_ID = Settings.MATERIAL_TEMPLATE_0_MATERIAL_REVISION_ID
export const MATERIAL_TEMPLATE_1_MATERIAL_REVISION_ID = Settings.MATERIAL_TEMPLATE_1_MATERIAL_REVISION_ID
export const MATERIAL_TEMPLATE_0_DISPLACEMENT_MATERIAL_REVISION_ID = Settings.MATERIAL_TEMPLATE_0_DISPLACEMENT_MATERIAL_REVISION_ID
export const MATERIAL_TEMPLATE_1_DISPLACEMENT_MATERIAL_REVISION_ID = Settings.MATERIAL_TEMPLATE_1_DISPLACEMENT_MATERIAL_REVISION_ID

export const MATERIAL_TEMPLATE_0_TEXTURE_TYPES: TextureType[] = [
    TextureType.Diffuse,
    TextureType.Normal,
    TextureType.Roughness,
    TextureType.AnisotropyRotation,
    TextureType.AnisotropyStrength,
    TextureType.F0,
    TextureType.Displacement,
]
export const MATERIAL_TEMPLATE_1_TEXTURE_TYPES: TextureType[] = [
    TextureType.Diffuse,
    TextureType.Normal,
    TextureType.SpecularStrength,
    TextureType.Metalness,
    TextureType.Anisotropy,
    TextureType.Roughness,
    TextureType.Displacement,
]

export const entryModes = ["edit", "setup", "preview"] as const
export type EntryMode = (typeof entryModes)[number]

export const NEW_NODE_INIT_POSITION = [100, -100]
export const ALLOWED_MAP_DIMENSION_VARIATION_IN_CM = 0.02
