import {Component, computed, inject, output} from "@angular/core"
import {SearchComponent} from "../../../common/components/inputs/search/search.component"
import {InfiniteListComponent} from "../../../common/components/lists/infinite-list/infinite-list.component"
import {CardErrorComponent} from "../../../common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "../../../common/components/cards/card-placeholder/card-placeholder.component"
import {cardAnimations} from "@app/common/components/cards/animations"
import {TemplateAddTemplateItemFragment, TemplateFilterInput, TemplateType} from "@api"
import {BaseSelectComponent} from "@app/platform/components/base/base-select/base-select.component"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {firstValueFrom} from "rxjs"
import {loadGraphForNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {Nodes} from "@cm/lib/templates/nodes/nodes"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {OwnOrganizationsFilterComponent} from "../../../common/components/filters/own-organizations-filter/own-organizations-filter.component"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"

@Component({
    selector: "cm-template-add-scene",
    standalone: true,
    templateUrl: "./template-add-scene.component.html",
    styleUrl: "./template-add-scene.component.scss",
    animations: [...cardAnimations],
    imports: [SearchComponent, InfiniteListComponent, CardErrorComponent, TemplateAddCardComponent, CardPlaceholderComponent, OwnOrganizationsFilterComponent],
})
export class TemplateAddSceneComponent extends BaseSelectComponent<TemplateAddTemplateItemFragment, TemplateFilterInput> {
    private rawData = this.implementDataLoader(this.sdk.gql.templateAddTemplateItems)
    data = computed(() => this.rawData().items.map((item) => ({...item, action: item.data ? () => this.addScene(item.data) : undefined})))
    onItemClicked = output()

    private sceneManagerService = inject(SceneManagerService)
    private dialog = inject(MatDialog)

    constructor() {
        super()
        this.customFilters = {type: [TemplateType.Room]}
    }

    private confirmSave(title: string, message: string) {
        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                title: title,
                message: message,
                confirmLabel: "Yes",
                cancelLabel: "No",
            },
        })

        return dialogRef.afterClosed()
    }

    private async addScene(data: TemplateAddTemplateItemFragment): Promise<false | void> {
        const legacyId = data.latestRevision?.legacyId
        if (legacyId === undefined) return

        const templateGraph = this.sceneManagerService.$templateGraph()
        if (templateGraph.parameters.nodes.parameters.list.length > 0) {
            const saveConfirmed = await firstValueFrom(
                this.confirmSave(
                    "Replace template with environement?",
                    `The current template will be completely replaced with the selected environment </br></br><b>${data.name}</b></br></br>Proceed?`,
                ),
            )
            if (!saveConfirmed) return false
        }

        const action = async () => {
            const templateRevision = await this.sdk.gql.getTemplateRevisionDetailsTemplateAddScene({legacyId})
            const graphJson = templateRevision.templateRevision.graph

            const newGraph = !graphJson
                ? new TemplateGraph({name: "Untitled Template", nodes: new Nodes({list: []})})
                : loadGraphForNewTemplateSystem(graphJson)

            this.sceneManagerService.modifyTemplateGraph((templateGraph) => {
                templateGraph.parameters.nodes.clear()
                templateGraph.updateParameters({nodes: newGraph.parameters.nodes.clone({cloneSubNode: () => true})})
            })
        }

        action()
    }
}
