import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {HsvInputs, HsvOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-hsv-node",
    templateUrl: "./hsv-node.component.html",
    styleUrls: ["./hsv-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class HsvNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = HsvOutputs
    inputs = HsvInputs
    typeInfo = HsvNodeType
}

export const HsvNodeType: MaterialNodeType<typeof HsvNodeComponent> = {
    id: "hueSaturationValue",
    label: "Hue Saturation Value",
    color: "#8d802f",
    name: "ShaderNodeHueSaturation",
    inputs: [HsvInputs.hue, HsvInputs.saturation, HsvInputs.value, HsvInputs.fac, HsvInputs.color],
    outputs: [HsvOutputs.color],
    component: HsvNodeComponent,
}
