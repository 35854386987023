@if (relation) {
    <cm-inspector-section>
        <ng-container cm-title> Connection Inspector </ng-container>
        <ng-container cm-content>
            @if (relation.type === "rigidRelation") {
                <span><i>Align</i></span>
                <cm-node-reference-slot [filter]="NodeUtils.resolvesToObject" [(node)]="$any(relation).targetA" (nodeChange)="updateNode(relation)">
                </cm-node-reference-slot>
                <span><i>to</i></span>
                <cm-node-reference-slot [filter]="NodeUtils.resolvesToObject" [(node)]="$any(relation).targetB" (nodeChange)="updateNode(relation)">
                </cm-node-reference-slot>
            }
            @if (relation.type === "attachSurfaces") {
                <span><i>Attach</i></span>
                <span
                    ><b>{{ NodeUtils.describeRelationReference(relation.targetA) }}</b></span
                >
                <span><i>to</i></span>
                <span
                    ><b>{{ NodeUtils.describeRelationReference(relation.targetB) }}</b></span
                >
            }
        </ng-container>
    </cm-inspector-section>
    @if (relation.type === "attachSurfaces") {
        <cm-inspector-section>
            <ng-container cm-title> Surface alignment </ng-container>
            <ng-container cm-content>
                <div class="cm-connection-settings">
                    <cm-input-container>
                        <ng-container cm-inline-label> X: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="relation.surfaceOffset.x" (valueChange)="updateNode(relation)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label> Y: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="relation.surfaceOffset.y" (valueChange)="updateNode(relation)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label> Angle: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="relation.surfaceOffset.angle" (valueChange)="updateNode(relation)" [decimalPlaces]="2">
                            </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                </div>
            </ng-container>
        </cm-inspector-section>
    }
    <ng-template #translationTemplate let-propertyName="propertyName">
        <div class="cm-connection-settings">
            <mat-form-field>
                <mat-label>Type:</mat-label>
                <mat-select [value]="getTranslationType(relation, propertyName)" (selectionChange)="setTranslationType(relation, propertyName, $event.value)">
                    <mat-option value="fixed">Fixed</mat-option>
                    <mat-option value="sliding">Sliding</mat-option>
                </mat-select>
            </mat-form-field>
            @if (getTranslationType(relation, propertyName) === "fixed") {
                <cm-input-container>
                    <ng-container cm-inline-label> Offset: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [decimalPlaces]="2" [(value)]="$any(relation.translation)[propertyName]" (valueChange)="updateNode(relation)">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            }
            @if (getTranslationType(relation, propertyName) === "sliding") {
                <cm-input-container>
                    <ng-container cm-inline-label> Default: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="$any(relation.translation)[propertyName].default" (valueChange)="updateNode(relation)" [decimalPlaces]="2">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Min: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input
                            [(value)]="$any(relation.translation)[propertyName].range[0]"
                            (valueChange)="updateNode(relation)"
                            [decimalPlaces]="2"
                        >
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Max: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input
                            [(value)]="$any(relation.translation)[propertyName].range[1]"
                            (valueChange)="updateNode(relation)"
                            [decimalPlaces]="2"
                        >
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            }
        </div>
    </ng-template>
    <cm-inspector-section>
        <ng-container cm-title> Translation </ng-container>
        <ng-container cm-content>
            <div>Offset X (horizontal):</div>
            <ng-container *ngTemplateOutlet="translationTemplate; context: {propertyName: 'x'}"></ng-container>
            <div>Offset Y (vertical):</div>
            <ng-container *ngTemplateOutlet="translationTemplate; context: {propertyName: 'y'}"></ng-container>
            <div>Offset Z (distance):</div>
            <ng-container *ngTemplateOutlet="translationTemplate; context: {propertyName: 'z'}"></ng-container>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Rotation </ng-container>
        <ng-container cm-content>
            <mat-form-field>
                <mat-label>Type:</mat-label>
                <mat-select [value]="relation.rotation.type" (selectionChange)="setRotationType(relation, $event.value)">
                    <mat-option value="fixed">Fixed</mat-option>
                    <mat-option value="hinge">Hinge</mat-option>
                    <mat-option value="ball">Ball</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="cm-rotation-settings">
                @if (relation.rotation.type === "fixed") {
                    <cm-input-container>
                        <ng-container cm-inline-label> X: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="relation.rotation.x" (valueChange)="updateNode(relation)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label> Y: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="relation.rotation.y" (valueChange)="updateNode(relation)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label> Z: </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="relation.rotation.z" (valueChange)="updateNode(relation)" [decimalPlaces]="2"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                }
            </div>
            @if (relation.rotation.type === "hinge") {
                <mat-form-field>
                    <mat-label>Axis:</mat-label>
                    <mat-select [(value)]="relation.rotation.axis" (valueChange)="updateNode(relation)">
                        <mat-option value="x">X</mat-option>
                        <mat-option value="y">Y</mat-option>
                        <mat-option value="z">Z</mat-option>
                    </mat-select>
                </mat-form-field>
            }
            @if (relation.rotation.type === "ball") {}
        </ng-container>
    </cm-inspector-section>
    <cm-button class="cm-blue-button" (click)="captureRelationParams()">
        <ng-container cm-text> Capture Transformation Params </ng-container>
    </cm-button>
}
