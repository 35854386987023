import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeValueNode, threeConvert} from "@src/materials/three-utils"

export class LightPath extends DeclareMaterialNode(
    {
        returns: z.object({
            diffuseDepth: materialSlots,
            glossyDepth: materialSlots,
            isCameraRay: materialSlots,
            isDiffuseRay: materialSlots,
            isGlossyRay: materialSlots,
            isReflectionRay: materialSlots,
            isShadowRay: materialSlots,
            isSingularRay: materialSlots,
            isTransmissionRay: materialSlots,
            rayDepth: materialSlots,
            rayLength: materialSlots,
            transmissionDepth: materialSlots,
            transparentDepth: materialSlots,
        }),
        inputs: z.object({}),
        parameters: z.object({
            diffuseDepth: z.number().optional(),
            glossyDepth: z.number().optional(),
            isCameraRay: z.number().optional(),
            isDiffuseRay: z.number().optional(),
            isGlossyRay: z.number().optional(),
            isReflectionRay: z.number().optional(),
            isShadowRay: z.number().optional(),
            isSingularRay: z.number().optional(),
            isTransmissionRay: z.number().optional(),
            rayDepth: z.number().optional(),
            rayLength: z.number().optional(),
            transmissionDepth: z.number().optional(),
            transparentDepth: z.number().optional(),
        }),
    },
    {
        toThree: async ({parameters}) => {
            const {
                diffuseDepth,
                glossyDepth,
                isCameraRay,
                isDiffuseRay,
                isGlossyRay,
                isReflectionRay,
                isShadowRay,
                isSingularRay,
                isTransmissionRay,
                rayDepth,
                rayLength,
                transmissionDepth,
                transparentDepth,
            } = parameters

            return {
                diffuseDepth: threeConvert(diffuseDepth, threeValueNode) ?? threeValueNode(0),
                glossyDepth: threeConvert(glossyDepth, threeValueNode) ?? threeValueNode(0),
                isCameraRay: threeConvert(isCameraRay, threeValueNode) ?? threeValueNode(0),
                isDiffuseRay: threeConvert(isDiffuseRay, threeValueNode) ?? threeValueNode(0),
                isGlossyRay: threeConvert(isGlossyRay, threeValueNode) ?? threeValueNode(0),
                isReflectionRay: threeConvert(isReflectionRay, threeValueNode) ?? threeValueNode(0),
                isShadowRay: threeConvert(isShadowRay, threeValueNode) ?? threeValueNode(0),
                isSingularRay: threeConvert(isSingularRay, threeValueNode) ?? threeValueNode(0),
                isTransmissionRay: threeConvert(isTransmissionRay, threeValueNode) ?? threeValueNode(0),
                rayDepth: threeConvert(rayDepth, threeValueNode) ?? threeValueNode(0),
                rayLength: threeConvert(rayLength, threeValueNode) ?? threeValueNode(0),
                transmissionDepth: threeConvert(transmissionDepth, threeValueNode) ?? threeValueNode(0),
                transparentDepth: threeConvert(transparentDepth, threeValueNode) ?? threeValueNode(0),
            }
        },
    },
) {}
