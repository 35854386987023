export type ArrowStyle = "none" | "standard"
export type LineStyle = "solid" | "dashed" | "dotted"

export class EdgeStyle {
    constructor(
        public readonly lineStyle: LineStyle,
        public readonly sourceArrowStyle: ArrowStyle,
        public readonly targetArrowStyle: ArrowStyle,
    ) {}
}

export class Edge {
    constructor(
        public readonly source: Node,
        public readonly target: Node,
        public readonly style: EdgeStyle,
        public readonly sourceLabel?: string,
        public readonly targetLabel?: string,
    ) {}
}

export class Node {
    constructor(
        public readonly label: string,
        public readonly color?: string,
        public readonly children?: Node[],
    ) {}
}

export class Graph {
    rootNodes: Node[] = []
    edges: Edge[] = []
}
