import {DisposableCachedNodeGraphResult} from "@cm/lib/graph-system/evaluators/disposable-cached-node-graph-result"
import {ImagePtr, isImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {traverseObject} from "@cm/lib/utils/utils"
import {OperatorParameterValue} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {ImageOpNodeGraphEvaluator, OperatorExecutionMode} from "@app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"

const TRACE = TextureEditorSettings.EnableFullTrace

export abstract class ImageOpNodeGraphEvaluatorBase implements ImageOpNodeGraphEvaluator {
    abstract createDataObjectImage(dataObjectId: string): Promise<ImagePtr>

    protected constructor(readonly mode: OperatorExecutionMode) {}

    dispose() {}

    protected async evaluateResultImageRef(context: Context, nodeGraph: OperatorParameterValue<ImagePtr>): Promise<ImagePtr> {
        const result = new DisposableCachedNodeGraphResult(nodeGraph, context, (node, nodeResult) => {
            // collect all temporary image ptr ids in result
            const collectedImagePtrsInResult = new Set<ImagePtr>()
            traverseObject(nodeResult, (value) => {
                if (isImagePtr(value) && value.ref.addressSpace === "temporary") {
                    collectedImagePtrsInResult.add(value)
                    return undefined
                }
                return value
            })
            // reduce the ref-count of all temporary images
            if (TRACE && collectedImagePtrsInResult.size > 0) {
                console.log(
                    `Releasing temporary images `,
                    Array.from(collectedImagePtrsInResult).map((imagePtr) => imagePtr.ref),
                )
            }
            collectedImagePtrsInResult.forEach((imagePtr) => imagePtr.release())
        })
        try {
            return await result.run()
        } catch (e) {
            console.error("Error in evaluateResultImageRef: ", e)
            throw e
        }
    }
}
