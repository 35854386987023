<div class="cm-title">
    <ng-content select="[cm-title]"> </ng-content>
</div>
<cm-card
    [thumbnailUrl]="
        !!getMaterialRevisionId()
            ? (fetchMaterial | memoize: this : (fetchMaterialRevision | memoize: this : getMaterialRevisionId() | async) | async)?.getThumbnailUrl()
            : ''
    "
    [defaultThumbnail]="!!getMaterialRevisionId()"
    (click)="assignment ? _chooseMaterialNode(assignment) : _addAssignment()"
    (cmNodeDrop)="_dropNode($event)"
    (cmNodeDropFilter)="(resolvesToMaterial)"
>
    @if (assignment?.node && !isMaterialReference(assignment.node)) {
        <ng-container cm-thumbnail>
            <img class="cm-thumbnail" src="assets/images/material-switch.jpg" />
        </ng-container>
    }
    @if (!assignment?.node) {
        <ng-container cm-thumbnail>
            <img class="cm-thumbnail" src="{{ noMaterialUrl }}" />
        </ng-container>
    }
    @if (getMaterialRevisionId() && hasName(assignment.node)) {
        <ng-container cm-title>
            <span class="cm-material-title-reference">
                {{ assignment.node.name }}
            </span>
        </ng-container>
    }
    @if (assignment?.node && !isMaterialReference(assignment.node) && hasName(assignment.node)) {
        <ng-container cm-title>
            <span class="cm-material-title-other-node"> <i class="far fa-project-diagram"></i> {{ assignment.node.name }} </span>
        </ng-container>
    }
    @if (!assignment?.node) {
        <ng-container cm-title>
            <span class="cm-material-title-none"> (No Material.) </span>
        </ng-container>
    }
    @if (fetchNewerRevision | memoize: this : getMaterialRevisionId() | async; as newerVersion) {
        <ng-container cm-bottom-left>
            <div (click)="$event.stopPropagation(); updateRevision(assignment, newerVersion)" class="cm-material-card-new-revision-update">
                <i class="far fa-arrow-up"></i>
                Update to v{{ newerVersion.number }}
            </div>
        </ng-container>
    }
    @if (assignment) {
        <ng-container cm-top-left>
            <div matTooltip="Remove assignment" (click)="$event.stopPropagation(); removeAssignment.emit(assignment)" class="cm-icon">
                <i class="far fa-trash"></i>
            </div>
        </ng-container>
    }
    @if (assignment) {
        <ng-container cm-top-right>
            <div
                matTooltip="Move texture"
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="meshUVMenu"
                [matMenuTriggerData]="{assignment}"
                class="cm-icon"
            >
                <i class="far fa-arrows"></i>
            </div>
        </ng-container>
    }
    @if (assignment) {
        <ng-container cm-bottom-right>
            <div
                matTooltip="Side: {{ assignment.side }}"
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="meshSideMenu"
                [matMenuTriggerData]="{assignment}"
                class="cm-icon"
            >
                <i class="far fa-bring-forward"></i>
            </div>
        </ng-container>
    }
</cm-card>
<!-- hasBackdrop needs to be false in order for node drag and drop to work! -->
<mat-menu [overlapTrigger]="false" [hasBackdrop]="false" #meshUVMenu>
    <ng-template matMenuContent let-assignment="assignment">
        <div (click)="$event.stopPropagation()" class="cm-overlay-settings">
            <cm-node-reference-slot
                class="cm-material-offset-input"
                fallbackText="Horizontal:"
                [node]="assignment.horizontalOffset"
                (nodeChange)="assignment.horizontalOffset = $event ?? undefined; updateAssignment(assignment)"
            >
                <ng-container cm-fallback>
                    <cm-numeric-input
                        [(value)]="assignment.horizontalOffset"
                        (valueChange)="updateAssignment(assignment)"
                        [decimalPlaces]="2"
                        [emptyValue]="undefined"
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-node-reference-slot>
            <cm-node-reference-slot
                class="cm-material-offset-input"
                fallbackText="Vertical:"
                [node]="assignment.verticalOffset"
                (nodeChange)="assignment.verticalOffset = $event ?? undefined; updateAssignment(assignment)"
            >
                <ng-container cm-fallback>
                    <cm-numeric-input
                        [(value)]="assignment.verticalOffset"
                        (valueChange)="updateAssignment(assignment)"
                        [decimalPlaces]="2"
                        [emptyValue]="undefined"
                    >
                    </cm-numeric-input>
                </ng-container>
            </cm-node-reference-slot>
            <cm-node-reference-slot
                class="cm-material-offset-input"
                fallbackText="Rotation:"
                [node]="assignment.rotation"
                (nodeChange)="assignment.rotation = $event ?? undefined; updateAssignment(assignment)"
            >
                <ng-container cm-fallback>
                    <cm-numeric-input [(value)]="assignment.rotation" (valueChange)="updateAssignment(assignment)" [decimalPlaces]="2" [emptyValue]="undefined">
                    </cm-numeric-input>
                </ng-container>
            </cm-node-reference-slot>
        </div>
    </ng-template>
</mat-menu>
<mat-menu #meshSideMenu="matMenu">
    <ng-template matMenuContent let-assignment="assignment">
        <button (click)="assignment.side = 'front'; updateAssignment(assignment)" mat-menu-item>Front</button>
        <button (click)="assignment.side = 'back'; updateAssignment(assignment)" mat-menu-item>Back</button>
        <button (click)="assignment.side = 'double'; updateAssignment(assignment)" mat-menu-item>Double</button>
    </ng-template>
</mat-menu>
