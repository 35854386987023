import {Component, ViewChild} from "@angular/core"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {OutputInputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-output-node",
    templateUrl: "./output-node.component.html",
    styleUrls: ["./output-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent, ToggleComponent],
})
export class OutputNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase!: MaterialNodeBase
    inputs = OutputInputs
    typeInfo = OutputNodeType

    displacementDisabled() {
        const param = this.nodeBase.getParameter("internal.displacement.disabled")
        return param === true
    }

    toggleDisplacementDisabled() {
        this.nodeBase.setParameter("internal.displacement.disabled", !this.displacementDisabled(), "boolean")
    }
}

export const OutputNodeType: MaterialNodeType<typeof OutputNodeComponent> = {
    id: "output",
    label: "Material Output",
    color: "#7c2a31",
    name: "OutputMaterial",
    inputs: [OutputInputs.surface, OutputInputs.volume, OutputInputs.displacement],
    outputs: [] as never[],
    component: OutputNodeComponent,
}
