<div class="cm-icon-container" #dropZone>
    <div class="cm-upload-background" [class.cm-drop-zone-active]="!dataObject || dropZoneActive.value">
        <input (change)="filesSelectedForUpload($event)" class="cm-select-file" type="file" />
        <i class="far fa-cloud-upload cm-upload-icon"></i>
        <span class="cm-upload-text">Drop your file here</span>
        <span class="cm-upload-text-subtitle">or click to browse for a file</span>
    </div>
    <img [alt]="altText" class="cm-data-object-thumbnail" [src]="dataObject ? dataObject.getThumbnailUrl() : noImageURL" />
    @if (allowDelete) {
        <button class="cm-delete-button" mat-icon-button matTooltip="Remove" matTooltipPosition="below" (click)="onDelete()">
            <i class="far fa-trash"></i>
        </button>
    }
</div>
