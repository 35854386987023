import {HalContext, HalEntity} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {Vector2} from "@cm/lib/math/vector2"
import {Box2} from "@cm/lib/math/box2"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {deepEqual} from "@cm/lib/utils/utils"
import {Format} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {createHalPaintableImage} from "@common/models/hal/hal-paintable-image/create"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {createHalPainterPrimitive} from "@common/models/hal/hal-painter-primitive/create"

const SHADING_FUNCTION = `
    uniform float u_brushWidth;
    uniform float u_brushHardness;

    float computeGaussian(float x, float radius) {
        float sigma = radius / 4.0;
        return exp((-x * x) / (2.0 * sigma * sigma));
    }

    vec4 computeColor(vec2 position, vec2 uv, vec4 color) {
        float r = length(uv);
        float maxHardness = 1.0 - 2.0 / u_brushWidth; // always do some anti-aliasing
        float hardness = min(u_brushHardness, maxHardness);
        float v = (r <= hardness) ? 1.0 : computeGaussian(r - hardness, 1.0 - hardness);
        return vec4(v, 0, 0, 0);
    }
`

export class HalBrushShapeGenerator implements HalEntity {
    constructor(readonly context: HalContext) {
        this.halPainterPrimitive = createHalPainterPrimitive(this.context, SHADING_FUNCTION)
        this.brushShapeImage = createHalPaintableImage(this.context)
    }

    // HalEntity
    dispose(): void {
        this.halPainterPrimitive.dispose()
        this.brushShapeImage.dispose()
    }

    async getBrushShape(brushSettings: BrushSettings, format: Format): Promise<HalImage> {
        const brushShapeImage = await this.getBrushShapeImage(brushSettings, format)
        if (!this.brushShapeSettings || !deepEqual(this.brushShapeSettings, brushSettings)) {
            this.brushShapeSettings = {
                ...brushSettings,
            }
            this.halPainterPrimitive.setParameter("u_brushWidth", {type: "float", value: brushSettings.brushWidth})
            this.halPainterPrimitive.setParameter("u_brushHardness", {type: "float", value: brushSettings.brushHardness})
            this.halPainterPrimitive.clearGeometry()
            this.halPainterPrimitive.addRect(
                Box2.fromPositionAndSize(new Vector2(0, 0), new Vector2(brushShapeImage.descriptor.width, brushShapeImage.descriptor.height)),
                Box2.fromPositionAndSize(new Vector2(-1, -1), new Vector2(2, 2)),
            )
            await this.halPainterPrimitive.paint(brushShapeImage)
        }
        return brushShapeImage
    }

    private async getBrushShapeImage(brushSettings: BrushSettings, format: Format): Promise<HalPaintableImage> {
        const brushShapeImageDimensions = this.getBrushShapeImageDimensions(brushSettings)
        if (
            this.brushShapeImage.descriptor.width !== brushShapeImageDimensions.x ||
            this.brushShapeImage.descriptor.height !== brushShapeImageDimensions.y ||
            this.brushShapeImage.descriptor.format !== format
        ) {
            await this.brushShapeImage.create(
                {
                    width: brushShapeImageDimensions.x,
                    height: brushShapeImageDimensions.y,
                    channelLayout: "R",
                    format: format,
                },
                {
                    useMipMaps: false,
                    useSRgbFormat: false,
                },
            )
        }
        return this.brushShapeImage
    }

    private getBrushShapeImageDimensions(brushSettings: BrushSettings): Vector2 {
        return new Vector2(Math.ceil(brushSettings.brushWidth), Math.ceil(brushSettings.brushWidth))
    }

    public static create(context: HalContext): HalBrushShapeGenerator {
        return new HalBrushShapeGenerator(context)
    }

    private halPainterPrimitive: HalPainterPrimitive
    private brushShapeImage: HalPaintableImage
    private brushShapeSettings: BrushSettings | undefined = undefined
}
