<h2 mat-dialog-title>{{ caption }}</h2>
<mat-dialog-content>
    <form id="renameForm" (submit)="confirmAction()">
        <mat-form-field>
            <input matInput placeholder="Name" [(ngModel)]="name" name="entityName" (focus)="selectNameWithoutExtension($any($event.target))" />
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button form="renameForm" [disabled]="!name && !allowUndefined">Rename</button>
    <button mat-button (click)="cancelAction()" autofocus>Cancel</button>
</mat-dialog-actions>
