import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeValueNode} from "@src/materials/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {getAll} from "@src/graph-system/utils"

export class CombineRGB extends DeclareMaterialNode(
    {
        returns: z.object({image: materialSlots}),
        inputs: z.object({r: materialSlots.optional(), g: materialSlots.optional(), b: materialSlots.optional()}),
        parameters: z.object({r: z.number().optional(), g: z.number().optional(), b: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {r, g, b} = await getAll(inputs, get)
            const rValue = r ?? threeValueNode(parameters.r ?? 0)
            const gValue = g ?? threeValueNode(parameters.g ?? 0)
            const bValue = b ?? threeValueNode(parameters.b ?? 0)

            return {image: THREENodes.color(new THREENodes.JoinNode([rValue, gValue, bValue]))}
        },
    },
) {}
