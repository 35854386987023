// check if a value is a class (and not an object or array)
export function isClass(target: unknown) {
    if (typeof target === "object" && target !== null) {
        return /^(object|array)$/i.test(target.constructor.name) === false
    } else {
        return false
    }
}

export function removeFromArray<T>(array: T[], toRemove: T) {
    const idx = array.findIndex((x) => x === toRemove)
    if (idx < 0) return false
    array.splice(idx, 1)
    return true
}

export function removeFromArrayByID<IdType, ElemType extends {id: IdType}>(array: ElemType[], toRemove: ElemType) {
    const idx = array.findIndex((x) => x.id == toRemove.id)
    if (idx < 0) return false
    array.splice(idx, 1)
    return true
}

export function removeSubtreeFromArrayByID<IdType, ElemType extends {id: IdType}>(
    array: ElemType[],
    toRemove: ElemType,
    relationFn: (parent: ElemType, child: ElemType) => boolean,
) {
    const idx = array.findIndex((x) => x.id == toRemove.id)
    if (idx >= 0) {
        array.splice(idx, 1)
    }
    const children = array.filter((x) => relationFn(toRemove, x))
    children.forEach((x) => removeSubtreeFromArrayByID(array, x, relationFn))
}

export function insertBefore<T>(arr: T[], after: T, ...items: T[]) {
    const index = arr.indexOf(after)
    if (index < 0) arr.push(...items)
    else arr.splice(index, 0, ...items)
}

export function insertAfter<T>(arr: T[], after: T, ...items: T[]) {
    const index = arr.indexOf(after)
    if (index < 0) arr.push(...items)
    else arr.splice(index + 1, 0, ...items)
}

export function uniqueElements<T>(arr: T[]) {
    return arr.filter((value, index, self) => self.indexOf(value) === index)
}

export function listWithIDsToMap<ObjType extends {id: number}>(arr: ObjType[]) {
    const map = new Map<number, ObjType>()
    arr.forEach((elem) => map.set(elem.id, elem))
    return map
}

export function flatMap<A, B>(arr: A[], fn: (a: A) => B[]): B[] {
    const bs: B[] = []
    arr.map((a) => bs.push(...fn(a)))
    return bs
}

export function appendToMap<K, V>(m: Map<K, V[]>, k: K, v: V) {
    const existing = m.get(k)
    if (existing) existing.push(v)
    else m.set(k, [v])
}

export function mapFirstTrue<A, B>(arr: A[], fn: (a: A) => B) {
    return arr.reduce((prev, cur) => (prev ? prev : fn(cur)), null as any as B)
}

export function filterInPlace<A>(arr: A[], condition: (a: A, idx: number, arr: A[]) => boolean): A[] {
    let i = 0,
        j = 0
    while (i < arr.length) {
        const val = arr[i]
        if (condition(val, i, arr)) arr[j++] = val
        i++
    }
    arr.length = j
    return arr
}

export function parseColor(input: string): [number, number, number] {
    if (input.substr(0, 1) == "#") {
        const collen = (input.length - 1) / 3
        const fact = [17 / 255, 1 / 255, 0.062272 / 255][collen - 1]
        return [
            parseInt(input.substr(1, collen), 16) * fact,
            parseInt(input.substr(1 + collen, collen), 16) * fact,
            parseInt(input.substr(1 + 2 * collen, collen), 16) * fact,
        ]
    } else {
        return [1, 1, 1]
    }
}

export function toHex2(d: number) {
    return ("0" + Number(d).toString(16)).slice(-2).toLowerCase()
}

export function colorToString(color: [number, number, number] | undefined) {
    if (!color) return "#000"
    return `#${toHex2(Math.round(255 * color[0]))}${toHex2(Math.round(255 * color[1]))}${toHex2(Math.round(255 * color[2]))}`
}

export type KeysOfUnion<T> = T extends any ? keyof T : never

// traverses an object calling valueMapper on each value and keep iterating on the returned value
export function traverseObject(value: unknown, valueMapper: (value: unknown) => unknown) {
    value = valueMapper(value)
    if (Array.isArray(value)) {
        value.forEach((value) => traverseObject(value, valueMapper))
    } else if (value && typeof value === "object") {
        Object.entries(value).forEach(([key, value]) => traverseObject(value, valueMapper))
    }
}

export function deepEqual<T>(a: T, b: T, depth?: number): boolean {
    const fieldDepth = depth ? depth - 1 : depth
    const typeA = typeof a
    const typeB = typeof b
    if (typeA === "number" && typeB === "number" && isNaN(a as any) && isNaN(b as any)) return true
    else if (depth === 0) return a === b
    else if (a === b) return true
    else if (a && b && typeA === "object" && typeB === "object") {
        if (Array.isArray(a) && Array.isArray(b)) {
            const length = a.length
            if (length != b.length) return false
            for (let i = length; i-- !== 0; ) {
                if (!deepEqual(a[i], b[i], fieldDepth)) return false
            }
            return true
        } else {
            const keys = Object.keys(a as unknown as object) as (keyof T)[]
            const length = keys.length
            if (length !== Object.keys(b as unknown as object).length) return false
            for (const k of keys) {
                if (!(k in (b as unknown as object))) return false
                if (!deepEqual(a[k], b[k], fieldDepth)) return false
            }
            return true
        }
    } else {
        return false
    }
}

export function debugDiff<T>(prefix: string, a: T, b: T, depth?: number): void {
    const fieldDepth = depth ? depth - 1 : depth
    if (depth === 0) {
        if (a !== b) {
            console.log(`${prefix}: ${a} != ${b}`)
        }
    } else if (a === b) return
    else if (a && b && typeof a == "object" && typeof b == "object") {
        if (Array.isArray(a) && Array.isArray(b)) {
            const length = a.length
            if (length != b.length) {
                console.log(`${prefix}.length: ${length} != ${b.length}`)
                return
            }
            for (let i = length; i-- !== 0; ) {
                debugDiff(prefix + `[${i}]`, a[i], b[i], fieldDepth)
            }
        } else {
            const keys = Object.keys(a) as KeysOfUnion<T>[]
            const length = keys.length
            if (length !== Object.keys(b).length) {
                console.log(`${prefix}: keys: ${Object.keys(a)} != ${Object.keys(b)}`)
                return
            }
            for (const k of keys) {
                debugDiff(prefix + `.${String(k)}`, a[k], b[k], fieldDepth)
            }
        }
    } else {
        console.log(`${prefix}: ${a} != ${b}`)
    }
}

export function deepCopy<T>(x: T, depth?: number, customCopyFunction?: (key: string | number | symbol, value: any) => any): T {
    if (depth === 0) {
        return x
    } else if (x && typeof x == "object") {
        if (depth !== undefined) --depth
        if (Array.isArray(x)) {
            return x.map((e) => deepCopy(e, depth, customCopyFunction)) as any as T
        } else {
            const keys = Object.keys(x) as KeysOfUnion<T>[]
            const r: any = {}
            for (const k of keys) {
                const value = x[k]
                if (customCopyFunction) {
                    const customCopiedValue = customCopyFunction(k, x[k])
                    if (customCopiedValue !== undefined) {
                        r[k] = customCopiedValue
                        continue
                    }
                }
                r[k] = deepCopy(value, depth, customCopyFunction)
            }
            return r as T
        }
    } else {
        return x
    }
}

export function getChangedFields<T extends object>(a: T, b: T | undefined): Set<keyof T> {
    const keys = Object.keys(a) as (keyof T)[]
    if (b === undefined) {
        return new Set<keyof T>(keys)
    }
    const changed = new Set<keyof T>()
    for (const k of keys) {
        if (!deepEqual(a[k], b[k])) {
            changed.add(k)
        }
    }
    return changed
}

// stringify JSON objects with sorted keys
export function sortedJSONStringify(value: any) {
    if (typeof value === "object") {
        if (value === null) {
            return JSON.stringify(value)
        } else if (Array.isArray(value)) {
            let str = "["
            for (let i = 0; i < value.length; i++) {
                if (i > 0) str += ","
                if (value[i] === undefined) {
                    str += JSON.stringify(null)
                } else {
                    str += sortedJSONStringify(value[i])
                }
            }
            str += "]"
            return str
        } else {
            let str = "{"
            let i = 0
            for (const key of Object.keys(value).sort()) {
                const v = value[key]
                if (v !== undefined) {
                    if (i > 0) str += ","
                    str += `${sortedJSONStringify(key)}:${sortedJSONStringify(v)}`
                    ++i
                }
            }
            str += "}"
            return str
        }
    } else {
        return JSON.stringify(value)
    }
}

export function isEmptyObject(obj: any) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false
        }
    }
    return true
}

export function b64toBlob(b64Data: string, contentType: string, sliceSize?: number) {
    contentType = contentType || ""
    sliceSize = sliceSize || 1024 * 128

    const byteCharacters = atob(b64Data)
    const byteArrays: Uint8Array[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray: Uint8Array = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, {type: contentType})
}

export function dataURLToBlob(dataUrl: string, contentType?: string): Blob {
    const [header, data] = dataUrl.split(",")
    if (contentType === undefined) {
        contentType = header.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)![0]
    }
    return b64toBlob(data, contentType)
}

export class TwoWayReadonlyMap<T, K> {
    map: Map<T, K>
    reverseMap: Map<K, T>

    constructor(map: Map<T, K>) {
        this.map = map
        this.reverseMap = new Map<K, T>()
        map.forEach((value, key) => {
            this.reverseMap.set(value, key)
        })
    }

    get(key: T) {
        return this.map.get(key)
    }

    reverseGet(key: K) {
        return this.reverseMap.get(key)
    }
}

export type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never

export let queueDeferredTask: (fn: () => void) => number
export let cancelDeferredTask: (handle: number) => void

export class DeferredBatchCall {
    constructor(private _fn: () => void) {}

    get isPending(): boolean {
        return this._handle !== undefined
    }

    schedule() {
        if (this._handle === undefined) {
            this._handle = queueDeferredTask(() => this.call())
        }
    }

    cancel() {
        if (this._handle !== undefined) {
            cancelDeferredTask(this._handle)
            this._handle = undefined
        }
    }

    private call() {
        this._handle = undefined
        this._fn()
    }

    private _handle: number | undefined = undefined
}

if (typeof window !== "undefined") {
    if (window.queueMicrotask) {
        queueDeferredTask = function (fn: () => void) {
            window.queueMicrotask(fn)
            return 1
        }

        cancelDeferredTask = function (handle: number) {}
    } else {
        queueDeferredTask = window.setTimeout
        cancelDeferredTask = window.clearTimeout
    }
}

class Debouncer<T extends unknown[]> {
    private args?: T
    private scheduled = false
    private fn: () => void

    constructor(fn: (...args: T) => void) {
        this.fn = () => {
            if (this.scheduled) {
                const args = this.args!
                this.scheduled = false
                this.args = undefined
                fn(...args)
            }
        }
    }

    trigger(...args: T) {
        if (!this.scheduled) {
            this.args = args
            this.scheduled = true
            queueDeferredTask(this.fn)
        }
    }
}

export function debounceFunction<T extends unknown[]>(fn: (...args: T) => void): typeof fn {
    const d = new Debouncer(fn)
    return d.trigger.bind(d as any) as any
}

export function mostCommonValue<T>(array: ArrayLike<T> & Iterable<T>): T | null {
    if (array.length == 0) return null
    const modeMap = new Map<T, number>()
    let curVal = array[0],
        curCt = 1
    for (const val of array) {
        const ct = (modeMap.get(val) ?? 0) + 1
        modeMap.set(val, ct)
        if (ct > curCt) {
            curVal = val
            curCt = ct
        }
    }
    return curVal
}

const idCounterCharTable = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

export class CompactUIDGenerator {
    private curIdCounter = 0

    reset(): void {
        this.curIdCounter = 0
    }

    next(): string {
        return (this.curIdCounter++ | 0).toString()
        // let str = "";
        // let bits = this.curIdCounter++ | 0;
        // const numChars = idCounterCharTable.length;
        // while (bits > 0) {
        //     const idx = bits % numChars;
        //     bits = (bits / numChars) | 0;
        //     str += idCounterCharTable[idx];
        // }
        // return str;
    }
}

export class CompactUIDTable<K> {
    private gen = new CompactUIDGenerator()
    private map = new Map<K, string>()

    clear(): void {
        this.gen.reset()
        this.map.clear()
    }

    intern(k: K): string {
        let v = this.map.get(k)
        if (v === undefined) {
            v = this.gen.next()
            this.map.set(k, v)
        }
        return v
    }
}

export class WeakCompactUIDTable<K extends object> {
    private gen = new CompactUIDGenerator()
    private map = new WeakMap<K, string>()

    intern(k: K): string {
        let v = this.map.get(k)
        if (v === undefined) {
            v = this.gen.next()
            this.map.set(k, v)
        }
        return v
    }
}

// wraps a value to the range [0, maxValue) including negative values
export function wrap(value: number, maxValue: number): number {
    if (maxValue <= 0) {
        throw Error("maxValue must be positive")
    }
    value = value % maxValue
    return value >= 0 ? value : value + maxValue
}

export function round(value: number, digits: number): number {
    return Math.round(value * digits * 10) / (digits * 10)
}

export function castToUint8Array(x: ArrayBuffer | ArrayBufferView) {
    if (ArrayBuffer.isView(x)) {
        return new Uint8Array(x.buffer, x.byteOffset, x.byteLength)
    } else {
        return new Uint8Array(x)
    }
}

export function castToUint16Array(x: ArrayBuffer | ArrayBufferView): Uint16Array {
    if (ArrayBuffer.isView(x)) {
        return new Uint16Array(x.buffer, x.byteOffset, Math.floor(x.byteLength / 2))
    } else {
        return new Uint16Array(x)
    }
}

export function castToUint32Array(x: ArrayBuffer | ArrayBufferView): Uint32Array {
    if (ArrayBuffer.isView(x)) {
        return new Uint32Array(x.buffer, x.byteOffset, Math.floor(x.byteLength / 4))
    } else {
        return new Uint32Array(x)
    }
}

export function castToFloat32Array(x: ArrayBuffer | ArrayBufferView): Float32Array {
    if (ArrayBuffer.isView(x)) {
        return new Float32Array(x.buffer, x.byteOffset, Math.floor(x.byteLength / 4))
    } else {
        return new Float32Array(x)
    }
}

export function halfToFloatArray(x: ArrayBuffer | ArrayBufferView): Float32Array {
    const halfArr = castToUint16Array(x)
    const length = halfArr.length
    const floatArr = new Uint32Array(length)
    for (let n = 0; n < length; n++) {
        const x = halfArr[n]
        floatArr[n] = ((x & 0x8000) << 16) | (((x & 0x7c00) + 0x1c000) << 13) | ((x & 0x03ff) << 13)
    }
    return castToFloat32Array(floatArr)
}

export function floatToHalfArray(x: ArrayBuffer | ArrayBufferView): Uint16Array {
    const floatArr = castToUint32Array(x)
    const length = floatArr.length
    const halfArr = new Uint16Array(length)
    for (let n = 0; n < length; n++) {
        const x = floatArr[n]
        // halfArr[n] = ((x >> 16) & 0x8000) | ((((x & 0x7f800000) - 0x38000000) >> 13) & 0x7c00) | ((x >> 13) & 0x03ff)
        const b = x + 0x00001000 // round-to-nearest-even: add last bit after truncated mantissa
        const e = (b & 0x7f800000) >> 23 // exponent
        const m = b & 0x007fffff // mantissa; in line below: 0x007FF000 = 0x00800000-0x00001000 = decimal indicator flag - initial rounding
        // sign : normalized : denormalized : saturate
        let h = (b & 0x80000000) >> 16
        if (e > 112) h |= (((e - 112) << 10) & 0x7c00) | (m >> 13)
        if (e < 113 && e > 101) h |= (((0x007ff000 + m) >> (125 - e)) + 1) >> 1
        if (e > 143) h |= 0x7fff
        halfArr[n] = h
    }
    return halfArr
}

export function trimDecimalPlaces(value: number, places: number) {
    let factor = 1
    while (places--) factor *= 10
    return Math.round(value * factor) / factor
}

export function sleep(ms: number): Promise<void> {
    return new Promise((r) => setTimeout(r, ms))
}

export function zip<A, B>(a: A[], b: B[]): [A, B][] {
    return a.map((x, i) => [x, b[i]])
}

export function mapFields<T extends ArrayLike<unknown> | {[s: string]: unknown}, F extends (v: T[keyof T], k: keyof T) => any>(
    x: T,
    fn: F,
): {[K in keyof T]: ReturnType<F>} {
    const r: any = {}
    for (const [k, v] of Object.entries(x)) {
        r[k as any] = fn(v as any, k as any)
    }
    return r
}

export function mapFieldNames<T extends ArrayLike<unknown> | {[s: string]: unknown}>(x: T, fn: (fielName: string) => string): {[s: string]: T[keyof T]} {
    const r: any = {}
    for (const [k, v] of Object.entries(x)) {
        r[fn(k)] = v
    }
    return r
}

type PlainObj = Record<string, unknown>
export type PromisesMap<T extends PlainObj> = {
    [P in keyof T]: Promise<T[P]> | T[P]
}

export function promiseAllProperties<T extends PlainObj>(promisesMap: PromisesMap<T>): Promise<T> {
    const keys = Object.keys(promisesMap)
    const promises = keys.map((key) => {
        return (promisesMap as any)[key]
    })

    return Promise.all(promises).then((results) => {
        return results.reduce((resolved, result, index) => {
            resolved[keys[index]] = result
            return resolved
        }, {})
    })
}

type TupleEntry<T extends readonly unknown[], I extends unknown[] = [], R = never> = T extends readonly [infer Head, ...infer Tail]
    ? TupleEntry<Tail, [...I, unknown], R | [`${I["length"]}`, Head]>
    : R

type ObjectEntry<T extends {}> = T extends object
    ? {
          [K in keyof T]: [K, Required<T>[K]]
      }[keyof T] extends infer E
        ? E extends [infer K, infer V]
            ? K extends string | number
                ? [`${K}`, V]
                : never
            : never
        : never
    : never

type TypedObjectEntry<T extends {}> = T extends readonly [unknown, ...unknown[]]
    ? TupleEntry<T>
    : T extends ReadonlyArray<infer U>
      ? [`${number}`, U]
      : ObjectEntry<T>

export function typedEntries<K extends string | number | symbol, V>(record: Record<K, V>): ReadonlyArray<readonly [K, V]>
export function typedEntries<T extends {}>(object: T): ReadonlyArray<TypedObjectEntry<T>>
export function typedEntries(object: any): any {
    return Object.entries(object)
}

export function bytesToSize(bytes: number): string {
    const sizes = ["B", "KB", "MB", "GB", "TB"]
    if (bytes === 0) {
        return "0 B"
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + " " + sizes[i]
}

export let isBlobLike: (x: any) => boolean
if (typeof window !== "undefined") {
    isBlobLike = function (x: any) {
        return ArrayBuffer.isView(x) || x instanceof ArrayBuffer || x instanceof Blob
    }
} else {
    isBlobLike = function (x: any) {
        return ArrayBuffer.isView(x) || x instanceof ArrayBuffer
    }
}

export function assertNever(x: never): never {
    throw new Error(`Unexpected object: ${x}`)
}

export function removeUndefinedEntriesFromObject<T extends object>(obj: T): T {
    const clonedObject = deepCopy(obj)

    function deleteUndefinedEntries(obj: any): any {
        if (obj && typeof obj === "object") {
            Object.keys(obj).forEach((key: string) => {
                if (obj[key] === undefined) {
                    delete obj[key]
                } else if (typeof obj[key] === "object" && obj[key] !== null) {
                    deleteUndefinedEntries(obj[key])
                }
            })
        }
    }

    deleteUndefinedEntries(clonedObject)
    return clonedObject
}
