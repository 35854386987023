<div class="cm-container">
    <div class="cm-item-list">
        <div class="cm-button-bar-container">
            <div>
                <ng-content select="[cm-buttons]"></ng-content>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</div>
