import {exhaustMap, map, Observable, OperatorFunction, switchMap, takeWhile} from "rxjs"
import {intervalBackoff} from "backoff-rxjs"

export const keepEmittingWithExponentialBackoff = <ValueType>(initialIntervalInSeconds = 1, factor = 1.1) =>
    switchMap((value: ValueType) =>
        intervalBackoff({
            initialInterval: initialIntervalInSeconds,
            backoffDelay: (index) => Math.pow(factor, index) * initialIntervalInSeconds,
        }).pipe(map(() => value)),
    )

export const reEmitWithExponentialBackoff = <ValueType, FetchType>(
    fetchItem: (item: ValueType) => Observable<FetchType>,
    emitWhile: (item: FetchType) => boolean = () => false,
    initialIntervalInSeconds = 1,
    factor = 2,
) =>
    switchMap((value: ValueType) =>
        intervalBackoff({
            initialInterval: initialIntervalInSeconds * 1_000,
            backoffDelay: (index) => Math.pow(factor, index) * initialIntervalInSeconds * 1_000,
        }).pipe(
            exhaustMap(() => fetchItem(value)),
            takeWhile((item) => emitWhile(item), true),
            map(() => value),
        ),
    )

export const refetchWithExponentialBackoff = <FetchArgumentType, FetchResultType>(
    fetchItem: (item: FetchArgumentType) => Observable<FetchResultType> | Promise<FetchResultType>,
    emitWhile: (item: FetchResultType) => boolean = () => false,
    options?: {initialIntervalInSeconds?: number; factor?: number},
): OperatorFunction<FetchArgumentType, FetchResultType> =>
    switchMap((value: FetchArgumentType) =>
        intervalBackoff({
            initialInterval: (options?.initialIntervalInSeconds ?? 1) * 1_000,
            backoffDelay: (index) => Math.pow(options?.factor ?? 2, index) * (options?.initialIntervalInSeconds ?? 1) * 1_000,
        }).pipe(
            exhaustMap(() => fetchItem(value)),
            takeWhile((item) => emitWhile(item), true),
        ),
    )
