import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const tapDescriptor = {
    input: inletDescriptor<any>(),
    fn: inletDescriptor<(x: any) => void>(),
    output: outletDescriptor<any>(),
}

export class Tap<T> implements NodeClassImpl<typeof tapDescriptor, typeof Tap> {
    static descriptor = tapDescriptor
    static uniqueName = "Tap"
    input!: Inlet<T>
    fn!: Inlet<(x: T | typeof NotReady) => void>
    output!: Outlet<T>

    run() {
        if (this.fn !== NotReady) {
            this.fn(this.input)
        }
        this.output.emit(this.input)
    }
}
