import {RunTemplate} from "@cm/lib/templates/runtime-graph/nodes/run-template"
import {INodeInstance} from "@cm/lib/templates/runtime-graph/types"
import {isBuilderOutlet} from "@cm/lib/templates/runtime-graph/graph-builder"
import {Observable} from "rxjs"
import * as SimpleGraph from "@platform/helpers/simple-graph/simple-graph"
import {GraphBuilder} from "@cm/lib/templates/runtime-graph/graph-builder"

export type Options = {
    mergeCompileAndRun?: boolean
    outputConstants?: boolean
}

export class RuntimeGraphExporter {
    generateSimpleGraph(graphBuilder: GraphBuilder, options?: Partial<Options>): Observable<SimpleGraph.Graph> {
        const defaultOptions: Options = {
            mergeCompileAndRun: false,
            outputConstants: true,
        }
        return new Observable((observer) => {
            const simpleGraph = new SimpleGraph.Graph()
            const [rootNodes, _] = this.createNodesAndEdges(graphBuilder, simpleGraph, {...defaultOptions, ...options})
            simpleGraph.rootNodes.push(...rootNodes)
            observer.next(simpleGraph)
            observer.complete()
        })
    }

    private splitId(scopePrefix: string, id: string): [namespaces: string[], label: string] {
        // remove scope prefix
        const scopePrefixIndex = id.indexOf(scopePrefix)
        if (scopePrefixIndex < 0) {
            throw Error("Invalid id")
        }
        id = id.substring(scopePrefixIndex + scopePrefix.length + 1)
        // split into namespaces and label
        const namespaces: string[] = []
        while (true) {
            const index = id.indexOf(":")
            if (index >= 0) {
                const namespace = id.substring(0, index)
                namespaces.push(namespace)
                id = id.substring(index + 1)
            } else {
                const label = id.substring(index + 1)
                return [namespaces, label]
            }
        }
    }

    private getSubBuilder(node: INodeInstance) {
        return (node as any).builder as GraphBuilder | undefined
    }

    private createNodesAndEdges(
        graphBuilder: GraphBuilder,
        simpleGraph: SimpleGraph.Graph,
        options: Options,
    ): [rootNodes: SimpleGraph.Node[], simpleNodeById: Map<string, SimpleGraph.Node>] {
        const rootSimpleNodes: SimpleGraph.Node[] = []
        const namespaceSimpleNodeByNamespace: Map<string, SimpleGraph.Node> = new Map()

        // create node instances
        const compileTemplateIdByRunTemplateId = new Map<string, string>()
        const simpleNodeByIdByCompileTemplateId = new Map<string, Map<string, SimpleGraph.Node>>()
        const simpleNodeById = new Map<string, SimpleGraph.Node>()
        for (const node of graphBuilder.instanceData.values()) {
            if (!node.$id) {
                throw Error("Encountered node without id.")
            }
            if (options.mergeCompileAndRun && node instanceof RunTemplate) {
                // determine compile template node this run template is connected to
                const nodeId = (node as any).$id
                const compiledTemplateId = (graphBuilder.connectionData.get(nodeId) as any)?.compiledTemplate?.inst.fromId
                if (!compiledTemplateId) {
                    throw Error("Could not find compiled template id.")
                }
                compileTemplateIdByRunTemplateId.set(nodeId, compiledTemplateId)
                continue
            }
            const [namespaces, label] = this.splitId(graphBuilder.scopePrefix, node.$id)
            let namespaceSimpleNode: SimpleGraph.Node | undefined
            let fullNamespace = ""
            for (const namespace of namespaces) {
                fullNamespace += namespace + ":"
                let nestedNamespaceSimpleNode = namespaceSimpleNodeByNamespace.get(fullNamespace)
                if (!nestedNamespaceSimpleNode) {
                    nestedNamespaceSimpleNode = new SimpleGraph.Node(namespace, "transparent", [])
                    namespaceSimpleNodeByNamespace.set(fullNamespace, nestedNamespaceSimpleNode)
                    if (namespaceSimpleNode) {
                        namespaceSimpleNode.children?.push(nestedNamespaceSimpleNode)
                    } else {
                        rootSimpleNodes.push(nestedNamespaceSimpleNode)
                    }
                }
                namespaceSimpleNode = nestedNamespaceSimpleNode
            }
            const subGraphBuilder = this.getSubBuilder(node)
            const [children, subGraphSimpleNodeById] = subGraphBuilder
                ? this.createNodesAndEdges(subGraphBuilder, simpleGraph, options)
                : [undefined, undefined]
            if (options.mergeCompileAndRun && subGraphBuilder) {
                if (!subGraphSimpleNodeById) {
                    throw Error("Could not find sub graph simple node by id.")
                }
                simpleNodeByIdByCompileTemplateId.set((node as any).$id, subGraphSimpleNodeById)
            }
            let fullNodeLabel = label
            if (options.outputConstants) {
                if (node.$constKeys?.length) {
                    fullNodeLabel += "\n"
                    node.$constKeys?.forEach((key) => {
                        fullNodeLabel += "\n" + key + ": " + (node as any)[key]
                    })
                }
            }
            const simpleNode = new SimpleGraph.Node(fullNodeLabel, undefined, children)
            if (namespaceSimpleNode) {
                namespaceSimpleNode.children?.push(simpleNode)
            } else {
                rootSimpleNodes.push(simpleNode)
            }
            simpleNodeById.set(node.$id, simpleNode)
        }

        const simpleNodeByIdByRunTemplateId = new Map<string, Map<string, SimpleGraph.Node>>()
        for (const [runTemplateId, compileTemplateId] of compileTemplateIdByRunTemplateId.entries()) {
            const compileTemplateGraphBuilder = simpleNodeByIdByCompileTemplateId.get(compileTemplateId)
            if (!compileTemplateGraphBuilder) {
                throw Error("Could not find compile template graph builder.")
            }
            simpleNodeByIdByRunTemplateId.set(runTemplateId, compileTemplateGraphBuilder)
        }

        // create input nodes
        if (graphBuilder.inputs.size > 0) {
            const children = [] as SimpleGraph.Node[]
            const inputsSimpleNode = new SimpleGraph.Node("Inputs", "transparent", children)
            rootSimpleNodes.push(inputsSimpleNode)
            for (const [key, value] of graphBuilder.inputs.entries()) {
                const simpleNode = new SimpleGraph.Node(key, "#FFCCCC", undefined)
                children.push(simpleNode)
                simpleNodeById.set(key, simpleNode)
            }
        }

        // create output nodes
        if (graphBuilder.outputs.size > 0) {
            const children = [] as SimpleGraph.Node[]
            const outputsSimpleNode = new SimpleGraph.Node("Outputs", "transparent", children)
            rootSimpleNodes.push(outputsSimpleNode)
            for (const [key, value] of graphBuilder.outputs.entries()) {
                const simpleNode = new SimpleGraph.Node(key, "#CCFFCC")
                children.push(simpleNode)
                simpleNodeById.set(key, simpleNode)
                const edge = this.createInletEdge(simpleNodeById, simpleNode, undefined, value, simpleNodeByIdByRunTemplateId)
                if (edge) {
                    simpleGraph.edges.push(edge)
                }
            }
        }

        // create edges
        for (const [nodeId, connection] of graphBuilder.connectionData) {
            const nodeInstance = graphBuilder.instanceData.get(nodeId)
            if (!nodeInstance) {
                throw Error(`Could not find node instance with id '${nodeId}'.`)
            }
            if (nodeInstance.$inletKeys) {
                const subGraphSimpleNodeById = simpleNodeByIdByRunTemplateId.get(nodeId)
                if (subGraphSimpleNodeById) {
                    // nodeId refers to a run template node; skip those when merging compile and run
                    for (const inletKey of nodeInstance.$inletKeys) {
                        if (inletKey !== "compiledTemplate") {
                            // skip the connection to the compile template node, since we merged the two nodes which connect to it
                            const simpleNode = subGraphSimpleNodeById.get(inletKey)
                            if (!simpleNode) {
                                throw Error(`Could not find simple node with id '${nodeId}'.`)
                            }
                            const inlet = (connection as any)[inletKey]
                            const edge = this.createInletEdge(simpleNodeById, simpleNode, undefined, inlet, simpleNodeByIdByRunTemplateId)
                            if (edge) {
                                simpleGraph.edges.push(edge)
                            }
                        }
                    }
                } else {
                    const simpleNode = simpleNodeById.get(nodeId)
                    if (!simpleNode) {
                        throw Error(`Could not find simple node with id '${nodeId}'.`)
                    }
                    for (const inletKey of nodeInstance.$inletKeys) {
                        const inlet = (connection as any)[inletKey]
                        const edge = this.createInletEdge(simpleNodeById, simpleNode, inletKey, inlet, simpleNodeByIdByRunTemplateId)
                        if (edge) {
                            simpleGraph.edges.push(edge)
                        }
                    }
                }
            }
        }

        return [rootSimpleNodes, simpleNodeById]
    }

    private createInletEdge(
        simpleNodeById: Map<string, SimpleGraph.Node>,
        simpleNode: SimpleGraph.Node,
        inletKey: string | undefined,
        inlet: any,
        simpleNodeByIdByRunTemplateId: Map<string, Map<string, SimpleGraph.Node>>,
    ) {
        if (!isBuilderOutlet(inlet)) {
            return null
        }
        const sourceNodeId = inlet.inst.fromId
        if (!sourceNodeId) {
            // if this is not set, then it refers to an input
            const sourceSimpleNode = simpleNodeById.get(inlet.key)
            if (!sourceSimpleNode) {
                throw Error(`Could not find simple node with id '${inlet.key}'.`)
            }
            return new SimpleGraph.Edge(sourceSimpleNode, simpleNode, new SimpleGraph.EdgeStyle("solid", "none", "standard"), undefined, inletKey)
        } else {
            // this is a connection to a node
            const sourceNodePropertyKey = inlet.inst.fromKey
            if (!sourceNodePropertyKey) {
                throw Error("Encountered builder outlet without source node property key.")
            }
            const subGraphSimpleNodeById = simpleNodeByIdByRunTemplateId.get(sourceNodeId)
            if (subGraphSimpleNodeById) {
                // sourceNodeId refers to a merged run template node
                const sourceSimpleNode = subGraphSimpleNodeById.get(sourceNodePropertyKey)
                if (!sourceSimpleNode) {
                    throw Error(`Could not find simple node with id '${sourceNodePropertyKey}'.`)
                }
                return new SimpleGraph.Edge(sourceSimpleNode, simpleNode, new SimpleGraph.EdgeStyle("solid", "none", "standard"), undefined, inletKey)
            } else {
                const sourceSimpleNode = simpleNodeById.get(sourceNodeId)
                if (!sourceSimpleNode) {
                    throw Error(`Could not find simple node with id '${sourceNodeId}'.`)
                }
                return new SimpleGraph.Edge(
                    sourceSimpleNode,
                    simpleNode,
                    new SimpleGraph.EdgeStyle("solid", "none", "standard"),
                    sourceNodePropertyKey,
                    inletKey,
                )
            }
        }
    }
}
