<input
    type="number"
    [value]="displayString"
    (change)="displayString = $any($event.target).value"
    (focus)="onFocus()"
    (blur)="onBlur()"
    placeholder="{{ placeholder }}"
    class="cm-input"
    [readonly]="readonly"
    [class.cm-button]="isButton"
/>
