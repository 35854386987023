import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {MeshData} from "@src/geometry-processing/mesh-data"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {IDataObjectNew} from "@src/templates/interfaces/data-object"

const TD = TypeDescriptors

const loadMeshNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    drcDataObject: TD.inlet(TD.DataObjectNew),
    plyDataObjectId: TD.inlet(TD.Number),
    displaySubdivisionLevel: TD.inlet(TD.Number),
    renderSubdivisionLevel: TD.inlet(TD.Number),
    meshData: TD.outlet(TD.MeshData),
}

export class LoadMeshNew implements NodeClassImpl<typeof loadMeshNewDescriptor, typeof LoadMeshNew> {
    static descriptor = loadMeshNewDescriptor
    static uniqueName = "LoadMeshNew"
    sceneManager!: Inlet<ISceneManagerNew>
    drcDataObject!: Inlet<IDataObjectNew>
    plyDataObjectId!: Inlet<number>
    displaySubdivisionLevel!: Inlet<number>
    renderSubdivisionLevel!: Inlet<number>
    meshData!: Outlet<MeshData>

    run() {
        this.meshData.emitIfChanged(NotReady)
        if (
            this.sceneManager === NotReady ||
            this.drcDataObject === NotReady ||
            this.plyDataObjectId === NotReady ||
            this.displaySubdivisionLevel === NotReady ||
            this.renderSubdivisionLevel === NotReady
        ) {
            return
        }

        this.sceneManager
            .loadMeshDataNew(this.drcDataObject, this.plyDataObjectId, {
                displaySubdivisionLevel: this.displaySubdivisionLevel,
                renderSubdivisionLevel: this.renderSubdivisionLevel,
            })
            .then((meshData) => {
                this.meshData.emitIfChanged(meshData)
            })
    }

    cleanup() {}
}
