<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Mesh Decal </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'mesh'" [label]="'Base Mesh'"></cm-value-slot>
        <div class="cm-image-grid">
            <cm-value-slot [node]="node()" [key]="'materialAssignment'" [label]="'Material'" (requestUpdate)="onRequestUpdate($event)"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Parameters </ng-container>
    <ng-container cm-content>
        <div class="cm-decal-settings">
            <cm-value-slot [node]="node()" [key]="'offset'" [subKey]="0" [label]="'Offset U'"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'offset'" [subKey]="1" [label]="'Offset V'"></cm-value-slot>
        </div>
        <cm-button class="cm-action-button" (click)="chooseUV()" [disabled]="!mesh() || sceneManagerService.watchingForClickedTemplateNodePart()">
            <ng-container cm-text> Choose Point </ng-container>
        </cm-button>
        <div class="cm-decal-settings">
            <cm-value-slot [node]="node()" [key]="'size'" [subKey]="0" [label]="'Size U'"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'size'" [subKey]="1" [label]="'Size V'"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'rotation'" [label]="'Rotation'"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'distance'" [label]="'Distance'"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Mask </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'mask'" [label]="'Mask'"></cm-value-slot>
        @if (node().parameters.mask) {
            <cm-value-slot [node]="node()" [key]="'invertMask'" [label]="'Mask polarity'" [selectionPossibilities]="invertMaskPossibilities"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'maskType'" [label]="'Mask type'" [selectionPossibilities]="maskTypePossibilities"></cm-value-slot>
        }
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Overlay Image </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'color'" [label]="'Overlay Image'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
