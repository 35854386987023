import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {MathInputs, MathMethod, MathOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-math-node",
    templateUrl: "./math-node.component.html",
    styleUrls: ["./math-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class MathNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = MathOutputs
    inputs = MathInputs
    typeInfo = MathNodeType
    settings = [MathMethod]
}

export const MathNodeType: MaterialNodeType<typeof MathNodeComponent> = {
    id: "mathNode",
    label: "Math",
    color: "#4987af",
    name: "ShaderNodeMath",
    inputs: [MathInputs.value01, MathInputs.value02],
    outputs: [MathOutputs.value],
    component: MathNodeComponent,
}
