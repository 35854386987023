import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {StringLike, stringLike} from "@src/templates/node-types"
import {z} from "zod"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"

const regexReplaceParameters = z.object({
    input: stringLike,
    regex: stringLike,
    replace: stringLike,
})
export type RegexReplaceParameters = {
    input: StringLike
    regex: StringLike
    replace: StringLike
}

@registerNode
export class RegexReplace
    extends DeclareTemplateNodeTS<RegexReplaceParameters>({validation: {paramsSchema: regexReplaceParameters}}, {nodeClass: "RegexReplace"})
    implements EvaluableTemplateNode<string | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const input = evaluator.evaluateString(scope, this.parameters.input)
        const regex = evaluator.evaluateString(scope, this.parameters.regex)
        const replace = evaluator.evaluateString(scope, this.parameters.replace)
        return scope.lambda(
            scope.tuple(input, regex, replace),
            ([input, regex, replace]) => {
                if (input === null || regex === null || replace === null) return null
                return input.replace(new RegExp(regex), replace)
            },
            "regexpReplace",
        )
    }
}

export type RegexReplaceFwd = TemplateNode<RegexReplaceParameters> & EvaluableTemplateNode<string | null>
