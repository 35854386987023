import {DeclareMaterialNode, cyclesNode, materialSlots} from "@src/materials/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {z} from "zod"
import {color} from "@src/materials/types"
import {getDefaultMaterial} from "@src/materials/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode} from "@src/materials/three-utils"

export class BsdfTransparent extends DeclareMaterialNode(
    {
        returns: z.object({bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)}),
        inputs: z.object({
            color: materialSlots.optional(),
        }),
        parameters: z.object({
            color: color.optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const color = await get(inputs.color)
            const material = getDefaultMaterial()

            const alphaValue =
                color ?? threeConvert(parameters.color, threeRGBColorNode, (val) => val.r < 0.95 || val.g < 0.95 || val.b < 0.95) ?? material.opacityNode

            if (alphaValue) {
                material.transparent = true
                material.opacityNode = alphaValue
            }

            return {bsdf: material}
        },
    },
) {}
