// @ts-strict-ignore
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"

@Component({
    selector: "cm-json-input",
    templateUrl: "./json-input.component.html",
    styleUrls: ["./json-input.component.scss"],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule],
})
export class JSONInputComponent implements OnInit {
    @Input() label: string
    @Input() validate?: (x: any) => boolean
    @Output() valueChange = new EventEmitter<any>()

    constructor() {}

    private _value: any

    @Input() set value(value: any) {
        this._value = value
        this.valueString = JSON.stringify(value)
    }

    get value() {
        return this._value
    }

    private editing = false
    private valueString: string
    editValid = true
    _editString: string | null = null

    get editString(): string {
        if (this.editing) {
            return this._editString
        }
        return this.valueString
    }

    set editString(str: string) {
        if (this.editing) {
            this._editString = str
        }
        try {
            const value = JSON.parse(str)
            if ((this.editValid = this.validate ? this.validate(value) : true)) {
                this.valueChange.emit(value)
            }
        } catch {
            this.editValid = false
        }
    }

    ngOnInit(): void {}

    onFocus() {
        this._editString = this.valueString
        this.editing = true
        this.editValid = true
    }

    onBlur() {
        this.editing = false
        this._editString = null
        this.editValid = true
    }
}
