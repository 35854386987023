import {Component, Inject, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule} from "@angular/material/core"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {EditJobFarmGroupDialogJobFarmGroupFragment} from "@api"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {JobFarmGroupDialogResult} from "@platform/components/job-farm-groups/job-farm-groups-table/job-farm-groups-table.component"
import {v4 as uuid4} from "uuid"

@Component({
    selector: "cm-edit-job-farm-group-dialog",
    templateUrl: "./edit-job-farm-group-dialog.component.html",
    standalone: true,
    imports: [MatInputModule, MatDialogModule, FormsModule, MatOptionModule, MatSelectModule, MatButtonModule],
})
export class EditJobFarmGroupDialogComponent {
    result: JobFarmGroupDialogResult

    organizations = inject(OrganizationsService)

    constructor(
        public dialogRef: MatDialogRef<EditJobFarmGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public jobFarmGroup: EditJobFarmGroupDialogJobFarmGroupFragment,
    ) {
        this.result = {
            id: jobFarmGroup.id,
            name: jobFarmGroup.name,
            organizationId: jobFarmGroup.organization?.id,
            queueGroup: jobFarmGroup.queueGroup,
        }
    }

    cancel(): void {
        this.dialogRef.close()
    }

    delete(): void {
        this.result.delete = true
        this.result.create = false
        this.dialogRef.close(this.result)
    }

    updateOrCreate(): void {
        this.result.delete = false
        this.result.create = this.result.id == null
        if (this.result.create) this.result.id = uuid4()
        this.dialogRef.close(this.result)
    }
}
