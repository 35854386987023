import {MaterialSocket} from "@material-editor/models/material-socket"
import {NodeSettings} from "@material-editor/models/material-nodes"

export const PrincipledBsdfInputs: Record<string, MaterialSocket> = {
    baseColor: {paramType: "socket", id: "Base Color", type: "input", valueType: "color", label: "Base Color"},
    // subsurface: {paramType: "socket", id: "Subsurface", type: "input", valueType: "scalar", label: "Subsurface"},
    // subsurfaceRadius: {paramType: "socket", id: "Subsurface Radius", type: "input", valueType: "vector", label: "Subsurface Radius"},
    // subsurfaceColor: {paramType: "socket", id: "Subsurface Color", type: "input", valueType: "vector", label: "Subsurface Color"},
    metallic: {paramType: "socket", id: "Metallic", type: "input", valueType: "scalar", label: "Metallic"},
    specular: {paramType: "socket", id: "Specular", type: "input", valueType: "scalar", label: "Specular"},
    roughness: {paramType: "socket", id: "Roughness", type: "input", valueType: "scalar", label: "Roughness"},
    anisotropic: {paramType: "socket", id: "Anisotropic", type: "input", valueType: "scalar", label: "Anisotropic"},
    anisotropicRotation: {paramType: "socket", id: "Anisotropic Rotation", type: "input", valueType: "scalar", label: "Anisotropic Rotation"},
    // ior: {paramType: "socket", id: "IOR", type: "input", valueType: "scalar", label: "IOR"},
    alpha: {paramType: "socket", id: "Alpha", type: "input", valueType: "scalar", label: "Alpha"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "plain", label: "Normal"},
    tangent: {paramType: "socket", id: "Tangent", type: "input", valueType: "plain", label: "Tangent"},
    emission: {paramType: "socket", id: "Emission", type: "input", valueType: "color", label: "Emission"},
}
export const PrincipledBsdfOutputs: Record<string, MaterialSocket> = {
    bsdf: {paramType: "socket", id: "BSDF", type: "output", valueType: "output", label: "BSDF"},
}
export const DistributionMethod: NodeSettings = {
    id: "internal.distribution",
    valueType: "string",
    options: [
        {value: "GGX", label: "GGX"},
        {value: "MULTI_GGX", label: "Multiscatter GGX"},
    ],
}
export const SubsurfaceMethod: NodeSettings = {
    id: "internal.subsurface_method",
    valueType: "string",
    options: [
        {value: "BURLEY", label: "Christensen-Burley"},
        {value: "RANDOM_WALK", label: "Random Walk"},
    ],
}
