import {DataObjectState} from "@api"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const DataObjectStateLabels: Map<DataObjectState, StateLabel<DataObjectState>> = new Map<DataObjectState, StateLabel<DataObjectState>>([
    [DataObjectState.Init, {state: DataObjectState.Init, label: "Init", background: "#989898"}],
    [DataObjectState.UploadFinished, {state: DataObjectState.UploadFinished, label: "Upload finished", background: "#ffab4a"}],
    [DataObjectState.Processing, {state: DataObjectState.Processing, label: "Processing", background: "#7cbcb0"}],
    [DataObjectState.ProcessingFailed, {state: DataObjectState.ProcessingFailed, label: "Processing failed", background: "#000000"}],
    [DataObjectState.Completed, {state: DataObjectState.Completed, label: "Completed", background: "#7ec17a"}],
])
