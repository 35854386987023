import {Component, OnInit, computed, forwardRef, inject, signal} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {SelectionPossibilities, SelectionPossibility, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {z} from "zod"
import {
    BooleanInfo,
    ConfigInfo,
    ImageInfo,
    JSONInfo,
    MaterialInfo,
    NumberInfo,
    ObjectInfo,
    StringInfo,
    TemplateInfo,
    getInterfaceIdPrefix,
    wrapInterfaceId,
} from "@cm/lib/templates/interface-descriptors"
import {Output, booleanLike, imageLike, isNode, jsonLike, materialLike, numberLike, objectLike, stringLike, templateLike} from "@cm/lib/templates/node-types"
import {ConfigGroup} from "@cm/lib/templates/nodes/config-group"
import {getNodeIconClass} from "@app/template-editor/helpers/template-icons"
import {BooleanOutput, ImageOutput, JSONOutput, MaterialOutput, NumberOutput, ObjectOutput, StringOutput, TemplateOutput} from "@cm/lib/templates/nodes/output"
import {TemplateInstanceOutputComponent} from "../../template-instance-output/template-instance-output.component"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {StringResolve} from "@cm/lib/templates/nodes/string-resolve"
import {isAnyJSONValue, isTemplateNode, isValidExternalId} from "@cm/lib/templates/types"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {TemplateReference} from "@cm/lib/templates/nodes/template-reference"
import {toSignal, toObservable, takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {switchMap, from, of} from "rxjs"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {Settings} from "@app/common/models/settings/settings"
import {CardComponent} from "../../../../common/components/cards/card/card.component"
import {ThumbnailComponent} from "../../../../common/components/thumbnail/thumbnail.component"
import {DataObjectThumbnailComponent} from "../../../../common/components/data-object-thumbnail/data-object-thumbnail.component"
import {MatTooltipModule} from "@angular/material/tooltip"
import {TemplateNodeComponent} from "../../template-node/template-node.component"
import {ButtonComponent} from "../../../../common/components/buttons/button/button.component"

type ParameterInput = {
    key: string
    label: string
    icon?: string
    schema: z.ZodTypeAny
    selectionPossibilities?: SelectionPossibilities<unknown>
    overwrittenValue: unknown
}

@Component({
    selector: "cm-template-instance-inspector",
    standalone: true,
    templateUrl: "./template-instance-inspector.component.html",
    styleUrl: "./template-instance-inspector.component.scss",
    imports: [
        InspectorSectionComponent,
        forwardRef(() => ValueSlotComponent),
        TemplateInstanceOutputComponent,
        CardComponent,
        ThumbnailComponent,
        DataObjectThumbnailComponent,
        MatTooltipModule,
        TemplateNodeComponent,
        ButtonComponent,
    ],
})
export class TemplateInstanceInspectorComponent extends BaseInspectorComponent<TemplateInstance> implements OnInit {
    isValidExternalId = isValidExternalId
    isActive = computed(() => this.sceneManagerService.isNodeActive(this.node()))
    private triggerTemplateRevisionRecompute = signal(0)
    protected descriptors = computed(() => {
        return this.sceneManagerService.getDescriptorsForInstance(this.node())
    })
    template = computed(() => {
        const {template} = this.parameters()
        return template
    })
    templateReference = computed(() => {
        const template = this.template()
        if (!(template instanceof TemplateReference)) return undefined
        return template
    })
    templateRevisionId = computed(() => {
        this.triggerTemplateRevisionRecompute()
        return this.templateReference()?.parameters.templateRevisionId
    })
    private templateRevisionData = toSignal(
        toObservable(this.templateRevisionId).pipe(
            switchMap((templateRevisionId) => {
                if (templateRevisionId == undefined) return of(null)
                return from(this.sdk.gql.getTemplateRevisionDetailsForTemplateInstanceInspector({legacyId: templateRevisionId}))
            }),
        ),
        {
            initialValue: null,
        },
    )
    thumbnailUrl = computed(() => {
        const templateRevisionData = this.templateRevisionData()
        if (!templateRevisionData) return Settings.IMAGE_NOT_AVAILABLE_SMALL_URL
        return undefined
    })
    thumbnailObject = computed(() => {
        const templateRevisionData = this.templateRevisionData()
        if (templateRevisionData) return templateRevisionData.templateRevision.template.galleryImage
        return undefined
    })
    title = computed(() => {
        const templateRevisionData = this.templateRevisionData()
        if (!templateRevisionData) return this.parameters().name

        const {template} = templateRevisionData.templateRevision

        return `${template.legacyId} - ${template.name}`
    })
    latestTemplateRevisionId = computed(() => {
        const templateRevisionData = this.templateRevisionData()
        return templateRevisionData?.templateRevision.template.latestRevision?.legacyId
    })

    revisionPossibilities = computed<SelectionPossibilities<number>>(() => {
        const templateRevisionData = this.templateRevisionData()
        return (
            templateRevisionData?.templateRevision.template.revisions?.map((revision) => ({
                value: revision.legacyId,
                name: `v${revision.number}`,
            })) ?? []
        )
    })
    private sdk = inject(SdkService)
    private clipboardService = inject(TemplateNodeClipboardService)
    private notificationsService = inject(NotificationsService)
    inputs = computed<ParameterInput[]>(() => {
        const parameters = this.sceneManagerService.$instanceParameters()
        const id = this.parameters().id

        const overwrittenValue = (key: string) => {
            const identifier = wrapInterfaceId(id, key)
            return parameters.parameters[identifier]
        }

        return this.descriptors()
            .filter((descriptor) => descriptor.props.type === "input")
            .map<ParameterInput>((descriptor) => {
                const [, key] = getInterfaceIdPrefix(descriptor.props.id)

                if (descriptor instanceof ConfigInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        icon: getNodeIconClass(ConfigGroup.getNodeClass()),
                        schema: stringLike.optional(),
                        selectionPossibilities: descriptor.props.variants.map<SelectionPossibility<unknown>>((variant) => ({
                            name: variant.name,
                            value: variant.id,
                            actions: [
                                {
                                    iconClass: "far fa-copy",
                                    toolTip: "Copy",
                                    fn: () => {
                                        this.clipboardService.copy(
                                            new StringResolve({
                                                name: `ConfigRef: ${variant.name}`,
                                                template: this.node().parameters.template,
                                                nodeId: variant.id,
                                            }),
                                        )
                                        this.notificationsService.showInfo("Copied config reference to clipboard")
                                    },
                                },
                            ],
                        })),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof ObjectInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: objectLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof MaterialInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: materialLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof TemplateInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: templateLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof ImageInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: imageLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof StringInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: stringLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof NumberInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: numberLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof BooleanInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: booleanLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else if (descriptor instanceof JSONInfo) {
                    return {
                        key,
                        label: descriptor.props.name,
                        schema: jsonLike.optional(),
                        overwrittenValue: overwrittenValue(key),
                    }
                } else throw new Error("Invalid descriptor type")
            })
    })
    outputs = computed(() => {
        const template = this.node()

        return this.descriptors()
            .filter((descriptor) => descriptor.props.type === "output")
            .map<Output>((descriptor) => {
                const [, key] = getInterfaceIdPrefix(descriptor.props.id)

                if (descriptor instanceof ObjectInfo) {
                    return new ObjectOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else if (descriptor instanceof MaterialInfo) {
                    return new MaterialOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else if (descriptor instanceof TemplateInfo) {
                    return new TemplateOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else if (descriptor instanceof ImageInfo) {
                    return new ImageOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else if (descriptor instanceof StringInfo) {
                    return new StringOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else if (descriptor instanceof NumberInfo) {
                    return new NumberOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else if (descriptor instanceof BooleanInfo) {
                    return new BooleanOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else if (descriptor instanceof JSONInfo) {
                    return new JSONOutput({template, outputId: key, name: `OutputRef: ${descriptor.props.name}`})
                } else throw new Error("Invalid descriptor type")
            })
    })

    override ngOnInit() {
        super.ngOnInit()

        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const templateReference = this.templateReference()
            if (templateReference && differences.modifiedNodes.has(templateReference)) this.triggerTemplateRevisionRecompute.update((x) => x + 1)
        })
    }

    updateOverwrittenValue(key: string, value: unknown) {
        if (isAnyJSONValue(value) || (isTemplateNode(value) && isNode(value))) {
            const parameters = this.sceneManagerService.$instanceParameters().clone({cloneSubNode: () => true})
            const id = this.parameters().id
            const identifier = wrapInterfaceId(id, key)

            parameters.updateParameters({[identifier]: value})
            this.sceneManagerService.$instanceParameters.set(parameters)
        }
    }

    updateToLatestTemplateRevision() {
        const templateReference = this.templateReference()
        if (!templateReference) return
        const latestTemplateRevisionId = this.latestTemplateRevisionId()
        if (latestTemplateRevisionId === undefined) return
        this.sceneManagerService.modifyTemplateGraph(() => {
            templateReference.updateParameters({templateRevisionId: latestTemplateRevisionId})
        })
    }
}
