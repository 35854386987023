import {NgClass} from "@angular/common"
import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-action-panel-row",
    templateUrl: "./action-panel-row.component.html",
    styleUrls: ["./action-panel-row.component.scss"],
    standalone: true,
    imports: [NgClass],
})
export class ActionPanelRowComponent {
    @Input() cmIconClass?: string
}
