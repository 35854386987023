// @ts-strict-ignore
import {SlicePipe} from "@angular/common"
import {Component, inject, Input, signal, OnInit, computed} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {BasicTagInfoFragment} from "@api"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {TagsService} from "@common/services/tags/tags.service"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {Labels} from "@labels"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {IsNonNull} from "@cm/lib/utils/filter"

@Component({
    selector: "cm-template-input-inspector",
    templateUrl: "./template-input-inspector.component.html",
    styleUrls: ["./template-input-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        InputContainerComponent,
        StringInputComponent,
        NodeReferenceSlotComponent,
        SearchComponent,
        DropdownComponent,
        SlicePipe,
        MatTooltipModule,
    ],
})
export class TemplateInputInspectorComponent implements OnInit {
    @Input() editor: IEditor
    @Input() node: Nodes.TemplateInput | null = null

    public readonly Labels = Labels

    focusSearch = false
    searchTags?: BasicTagInfoFragment[]
    $searchText = signal<string | undefined>(undefined)

    isValidId = NodeUtils.isValidId

    tags = inject(TagsService)

    updateNode(node: Nodes.TemplateInput): void {
        this.editor.sceneManager.markNodeChanged(node)
        this.getAssignedTags(node)
    }

    $assignedTags = signal<BasicTagInfoFragment[]>([])

    ngOnInit(): void {
        if (this.node) {
            this.getAssignedTags(this.node)
        }
    }

    $searchTags = computed(() =>
        this.tags.$templateInputsOutputs().filter((tag) => tag.name.toLowerCase().search(this.$searchText()?.toLowerCase() ?? "") != -1),
    )

    getTagIds(node: Nodes.TemplateInput): number[] {
        if (!node.tags) return []
        return node.tags.map((x) => x.tagId)
    }

    getTagById(id: number): BasicTagInfoFragment | null {
        return this.tags.byLegacyId(id)
    }

    getAssignedTags(node: Nodes.TemplateInput) {
        const tags = this.getTagIds(node)
            .map((id) => this.getTagById(id))
            .filter(IsNonNull)
        this.$assignedTags.set(tags)
    }

    addTag(node: Nodes.TemplateInput, tag: BasicTagInfoFragment): void {
        if (!node.tags) {
            node.tags = []
        }
        node.tags.push(
            Nodes.create<Nodes.TagReference>({
                type: "tagReference",
                name: tag.name,
                tagId: tag.legacyId,
            }),
        )
        this.updateNode(node)
    }

    removeTag(node: Nodes.TemplateInput, tag: BasicTagInfoFragment): void {
        if (!node.tags) return
        node.tags = node.tags.filter((x) => x.tagId !== tag.legacyId)
        this.updateNode(node)
    }
}
