import {
    Operator,
    OperatorCanvasToolboxType,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {TextureType} from "@api"
import {ImageOpNodeGraphEvaluator} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"
import {lambda} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"

export class OperatorInitial extends OperatorBase<undefined> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["is-internal", "no-clone", "apply-to-all-texture-types"])

    readonly panelComponentType?: OperatorPanelComponentType = undefined
    readonly canvasToolbox?: OperatorCanvasToolboxType = undefined

    readonly type = "operator-initial" as const

    constructor(callback: OperatorCallback) {
        super(callback, undefined)
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        throw new Error("Not supported")
    }

    // OperatorBase
    async getImageOpNodeGraph(_evaluator: ImageOpNodeGraphEvaluator, _textureType: TextureType, input: OperatorInput): Promise<OperatorOutput> {
        return {resultImage: lambda({input}, async ({parameters: {input}}) => new ImagePtr(input))}
    }
}
