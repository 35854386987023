import {Component, EventEmitter, Input, Output} from "@angular/core"

@Component({
    selector: "cm-sidenav",
    templateUrl: "./sidenav.component.html",
    styleUrls: ["./sidenav.component.scss"],
    standalone: true,
})
export class SidenavComponent {
    @Input() sidenavOpen = false
    @Input() attachToParent = false
    @Output() backgroundClick: EventEmitter<null> = new EventEmitter<null>()
}
