import type {ToneMappingData} from "@src/image-processing/tone-mapping"
import type {CryptomatteManifest, CryptomatteId} from "@src/image-processing/matte-processing"
import type {TypedImageData, MultichannelLayouts} from "@src/image-processing/image-processing-actions"
import type {JobNodes} from "@src/job-task/job-nodes"

export namespace ImageProcessingNodes {
    export type ImageData = TypedImageData

    export type EncodedData = {
        data: Uint8Array
        mediaType: string
        colorSpace: ImageData["colorSpace"]
    }

    export type RGBColor = readonly [number, number, number]
    export type RGBAColor = readonly [number, number, number, number]

    export type BBox = readonly [number, number, number, number] // [x, y, width, height]

    export type PixelAddressMode = "wrap" | "clamp"

    export type CreateImage = {
        readonly type: "createImage"
        width: ImageData["width"]
        height: ImageData["height"]
        dataType: ImageData["dataType"]
        colorSpace: ImageData["colorSpace"]
        color: number | RGBColor | RGBAColor
        dpi?: ImageData["dpi"]
    }

    export type Input = {
        readonly type: "input"
        image: ImageData
    }

    export type AdjustExposure = {
        readonly type: "adjustExposure"
        input: ImageNode
        ev: number
    }

    export type LinearRGB = {
        readonly type: "linearRGB"
        color: RGBColor | RGBAColor
    }

    export type SRGB = {
        readonly type: "sRGB"
        color: RGBColor | RGBAColor
    }

    export type ColorTemperature = {
        readonly type: "colorTemperature"
        temperature: number
    }

    export type Color = LinearRGB | SRGB | ColorTemperature

    export type ConstKernel = {
        readonly type: "constKernel"
        width: number // must be odd
        height: number // must be odd
        data: number[] // must be width * height long, indexed by (y * width + x)
    }

    export type WhiteBalance = {
        readonly type: "whiteBalance"
        input: ImageNode
        whitePoint: Color
    }

    export type Composite = {
        readonly type: "composite"
        foreground: ImageNode
        background: ImageNode | Color
        linear?: boolean
    }

    export type ToneMap = {
        readonly type: "toneMap"
        input: ImageNode
        lutResolution?: number
    } & ToneMappingData

    export type PredefinedLUT = {
        readonly type: "predefinedLUT"
        url: string
    }

    export type LUT = PredefinedLUT

    export type ApplyLUT = {
        type: "applyLUT"
        input: ImageNode
        lut: LUT
    }

    export type ToGrayscale = {
        type: "toGrayscale"
        input: ImageNode
        mode: "luminance" | "average"
    }

    export type CryptomatteData = {
        readonly type: "cryptomatteData"
        manifest: Value<CryptomatteManifest> | ExternalCryptomatteManifest
        passes: List<ImageNode>
    }

    export type CryptomatteMask = {
        readonly type: "cryptomatteMask"
        data: CryptomatteData
        ids: CryptomatteId[]
    }

    export type ApplyMask = {
        readonly type: "applyMask"
        input: ImageNode | Color
        mask: Mask
    }

    export type DetectMaskRegion = {
        readonly type: "detectMaskRegion"
        input: Mask
        margin?: number
    }

    export type DilateRegion = {
        readonly type: "dilateRegion"
        region: RegionNode
        amount: number
    }

    export type Region = {
        readonly type: "region"
        region: BBox
    }

    export type Offset = {
        readonly type: "offset"
        offset: [number, number]
    }

    export type CropImage = {
        readonly type: "crop"
        input: ImageNode
        region: RegionNode
        constrainRegion?: boolean // region can not be larger than input, defaults to true
    }

    export type CropMask = {
        readonly type: "crop"
        input: Mask
        region: RegionNode
        constrainRegion?: boolean // region can not be larger than input, defaults to true
    }

    export type Resize = {
        readonly type: "resize"
        input: ImageNode
        width?: number
        height?: number
        mode?: "absolute" | "scale-factor" // interpret width and height as absolute values or as scale factors; default to "absolute"
    }

    export type CopyRegion = {
        readonly type: "copyRegion"
        source: ImageNode
        sourceRegion?: Region
        target?: ImageNode
        targetOffset?: Offset
    }

    // this could be handled by the more advanced (but slower) AffineTransform node
    export type Shift = {
        readonly type: "shift"
        input: ImageNode
        offset: Offset
        borderMode: "wrap" // TODO support more border modes like "clamp" or "transparent"
    }

    export type AffineTransform = {
        readonly type: "affineTransform"
        input: ImageNode
        transform: [number, number, number, number, number, number] // [m11, m12, m21, m22, m31, m32]
        borderMode: PixelAddressMode
        target?: ImageNode
    }

    export type Convolve = {
        readonly type: "convolve"
        input: ImageNode
        kernel: ConstKernel
        borderMode: "wrap" | "clamp" // TODO support more border modes like "clamp" or "transparent"
        subSamplingFactor?: {x: number; y: number} | number // factor to downsize the input before applying the filter; after the filter is applied the result is scaled back to the original size; default to 1
    }

    export type GetPixelValue = {
        readonly type: "getPixelValue"
        input: ImageNode
        x: number
        y: number
        channel: number
    }

    export type AdjustShadowMask = {
        readonly type: "adjustShadowMask"
        input: ImageNode | Mask
        mode: "aoMask"
        falloff: number
        outer: number
        inner: number
        autoMargin?: number
    }

    export type LevelsImage = {
        readonly type: "levels"
        input: ImageNode
        blackLevel: number | ImageNode
        whiteLevel: number | ImageNode
        gamma?: number
    }

    export type LevelsMask = {
        readonly type: "levels"
        input: Mask
        blackLevel: number
        whiteLevel: number
        gamma?: number
    }

    export type InvertImage = {
        readonly type: "invert"
        input: ImageNode
    }

    export type InvertMask = {
        readonly type: "invert"
        input: Mask
    }

    export type ClampImage = {
        readonly type: "clamp"
        input: ImageNode
    }

    export type ClampMask = {
        readonly type: "clamp"
        input: Mask
    }

    export type Blend = {
        readonly type: "blend"
        background: ImageNode
        foreground: ImageNode
        mode: "normal" | "add" | "multiply" | "darken" | "lighten" | "screen"
        clamp?: boolean
        linear?: boolean
        amount?: number
    }

    export type ColorToMask = {
        readonly type: "colorToMask"
        input: ImageNode
    }

    export type AlphaToMask = {
        readonly type: "alphaToMask"
        input: ImageNode
    }

    export type ToColor = {
        readonly type: "toColor"
        input: Mask
    }

    type MathUnaryOp = "sqrt" | "square" | "cos" | "sin" | "clip01" | "abs"
    type MathBinaryOp = "+" | "-" | "*" | "/" | ">" | "<" | ">=" | "<=" | "==" | "max" | "min" | "constLike" | "atan2" | "pow" | "mod"

    export type Math = {
        readonly type: "math"
        firstInput: ImageNode
        secondInput?: ImageNode | NumberNode | number
        operation: MathUnaryOp | MathBinaryOp
    }

    export type Reduce = {
        readonly type: "reduce"
        operation: "sum" | "min" | "max" | "mean" | "sum-square" | "mean-square" | "root-mean-square"
        initialValue?: number // default: 0
        input: ImageNode
    }

    export type ExtractChannel = {
        readonly type: "extractChannel"
        input: ImageNode
        channel: number
    }

    // export type SeparateChannels = {
    //     readonly type: 'separateChannels';
    //     input: ImageNode
    // }

    export type CombineChannels<LayoutT extends MultichannelLayouts> = {
        readonly type: "combineChannels"
        channelLayout: LayoutT
        input: LayoutT extends "RGB" | "BGR" ? [ImageNode, ImageNode, ImageNode] : [ImageNode, ImageNode, ImageNode, ImageNode]
    }

    export type SetDpi = {
        readonly type: "setDpi"
        input: ImageNode | Mask
        dpi: number | undefined
    }

    // CAUTION: in order to release some memory (useful for the material exporter), this externalData node will cause an error when it is referenced multiple times ! (see "externalData"-handler in _evalNode in image-processing.ts)
    // TODO find a more robust solution
    type ExternalDataType = "typedImageData" | "cryptomatteManifest" | "encodedData"
    export type ExternalData<ResToT extends ExternalDataType = ExternalDataType, SrcDatT = JobNodes.DataObjectReference> = {
        type: "externalData"
        sourceData: SrcDatT
        resolveTo: ResToT
        resolvedData?: ResToT extends "typedImageData"
            ? ImageData
            : never | ResToT extends "cryptomatteManifest"
              ? CryptomatteManifest
              : never | ResToT extends "encodedData"
                ? EncodedData
                : never
    }

    export type ExternalEncodedData = ExternalData<"encodedData">
    export type ExternalTypedImageData = ExternalData<"typedImageData">
    export type ExternalCryptomatteManifest = ExternalData<"cryptomatteManifest">

    export type Convert = {
        type: "convert"
        input: ImageNode | Mask
        dataType: TypedImageData["dataType"]
        channelLayout: TypedImageData["channelLayout"]
        sRGB?: boolean
    }

    export type Encode = {
        type: "encode"
        input: ImageNode | Mask
        mediaType: EncodedData["mediaType"]
        options?: unknown
    }

    export type Decode = {
        type: "decode"
        input: EncodedDataNode
        options?: unknown
    }

    export type List<T> = {
        type: "list"
        items: T[]
    }

    export type Value<T> = {
        type: "value"
        value: T
    }

    export type Struct<T> = {
        type: "struct"
        fields: T
    }

    export type Trace = {
        type: "trace"
        message: string
        input: ImageNode
    }

    export interface ListOutput extends List<Output> {}
    export interface StructOutput extends Struct<Record<string, Output>> {}
    export type Output = ImageNode | EncodedDataNode | Mask | ListOutput | StructOutput

    export type ImageNode =
        | CreateImage
        | Input
        | Decode
        | Blend
        | CropImage
        | Resize
        | CopyRegion
        | Shift
        | AffineTransform
        | Convolve
        | Composite
        | AdjustExposure
        | WhiteBalance
        | ToneMap
        | ApplyLUT
        | ToGrayscale
        | ApplyMask
        | AdjustShadowMask
        | LevelsImage
        | ClampImage
        | InvertImage
        | ToColor
        | Convert
        | Math
        | Reduce
        | ExtractChannel
        | CombineChannelsNode
        | SetDpi
        | Trace

    export type Mask = CreateImage | Input | CryptomatteMask | ColorToMask | AlphaToMask | InvertMask | CropMask | LevelsMask | ClampMask

    export type RegionNode = DetectMaskRegion | Region | DilateRegion

    export type OffsetNode = Offset

    export type EncodedDataNode = Encode | ExternalEncodedData

    export type CombineChannelsNode<LayoutT = MultichannelLayouts> = LayoutT extends MultichannelLayouts ? CombineChannels<LayoutT> : never

    export type NumberNode = GetPixelValue

    export type Node = ImageNode | LUT | Convert | Color | Mask | RegionNode | OffsetNode | CryptomatteData | Output | ExternalData | NumberNode

    export function list<T>(items: T[]): List<T> {
        return {type: "list", items: items}
    }
    export function value<T>(value: T): Value<T> {
        return {type: "value", value: value}
    }
    export function struct<T>(fields: T): Struct<T> {
        return {type: "struct", fields: fields}
    }
    export function region(x: number, y: number, w: number, h: number): Region {
        return {type: "region", region: [x, y, w, h]}
    }
    export function offset(x: number, y: number): Offset {
        return {type: "offset", offset: [x, y]}
    }
    export function createImage(
        width: ImageData["width"],
        height: ImageData["height"],
        dataType: ImageData["dataType"],
        colorSpace: ImageData["colorSpace"],
        color: number | RGBColor | RGBAColor,
        dpi?: ImageData["dpi"],
    ): CreateImage {
        return {type: "createImage", width, height, dataType, colorSpace, color, dpi}
    }

    export function input(image: Input["image"]): Input {
        return {type: "input", image: image}
    }
    export function adjustExposure(input: AdjustExposure["input"], ev: AdjustExposure["ev"]): AdjustExposure {
        return {type: "adjustExposure", input, ev}
    }
    export function levelsImage(
        input: LevelsImage["input"],
        blackLevel: LevelsImage["blackLevel"],
        whiteLevel: LevelsImage["whiteLevel"],
        gamma?: LevelsImage["gamma"],
    ): LevelsImage {
        return {type: "levels", input, blackLevel, whiteLevel, gamma}
    }
    export function cropImage(input: CropImage["input"], region: CropImage["region"], constrainRegion: CropImage["constrainRegion"]): CropImage {
        return {type: "crop", input, region, constrainRegion}
    }
    export function resize(input: Resize["input"], width: Resize["width"], height: Resize["height"]): Resize {
        return {type: "resize", input: input, width: width, height: height}
    }
    export function copyRegion(
        source: CopyRegion["source"],
        sourceRegion: CopyRegion["sourceRegion"],
        target: CopyRegion["target"],
        targetOffset: CopyRegion["targetOffset"],
    ): CopyRegion {
        return {type: "copyRegion", source, sourceRegion, target, targetOffset}
    }
    export function shift(input: Shift["input"], offset: Shift["offset"], borderMode: Shift["borderMode"]): Shift {
        return {type: "shift", input, offset, borderMode}
    }
    export function affineTransform(
        input: AffineTransform["input"],
        transform: AffineTransform["transform"],
        borderMode: AffineTransform["borderMode"],
        target?: AffineTransform["target"],
    ): AffineTransform {
        return {type: "affineTransform", input, transform, borderMode, target}
    }
    export function constKernel(width: number, height: number, data: number[]): ConstKernel {
        return {type: "constKernel", width, height, data}
    }
    export function convolve(
        input: Convolve["input"],
        kernel: Convolve["kernel"],
        borderMode: Convolve["borderMode"],
        subSamplingFactor: Convolve["subSamplingFactor"],
    ): Convolve {
        return {type: "convolve", input, kernel, borderMode, subSamplingFactor}
    }
    export function getPixelValue(
        input: GetPixelValue["input"],
        x: GetPixelValue["x"],
        y: GetPixelValue["y"],
        channel: GetPixelValue["channel"],
    ): GetPixelValue {
        return {type: "getPixelValue", input, x, y, channel}
    }
    export function blend(
        background: Blend["background"],
        foreground: Blend["foreground"],
        mode: Blend["mode"],
        clamp?: Blend["clamp"],
        linear?: Blend["linear"],
        amount?: Blend["amount"],
    ): Blend {
        return {type: "blend", background, foreground, mode, clamp, linear, amount}
    }
    export function cryptomatteData(manifest: CryptomatteData["manifest"], passes: CryptomatteData["passes"]): CryptomatteData {
        return {type: "cryptomatteData", manifest, passes}
    }
    export function cryptomatteMask(data: CryptomatteMask["data"], ids: CryptomatteMask["ids"]): CryptomatteMask {
        return {type: "cryptomatteMask", data, ids}
    }
    export function applyMask(input: ApplyMask["input"], mask: ApplyMask["mask"]): ApplyMask {
        return {type: "applyMask", input, mask}
    }

    export function toGrayscale(input: ToGrayscale["input"], mode: ToGrayscale["mode"]): ToGrayscale {
        return {type: "toGrayscale", input, mode}
    }

    export function math<T extends Math["operation"]>(
        operation: T,
        firstInput: Math["firstInput"],
        ...args: T extends MathBinaryOp ? [Exclude<Math["secondInput"], undefined>] : [undefined?]
    ): Math {
        return {type: "math", operation: operation, firstInput: firstInput, secondInput: args[0]}
    }

    export function reduce(operation: Reduce["operation"], input: Reduce["input"], initialValue?: Reduce["initialValue"]): Reduce {
        return {type: "reduce", operation, input, initialValue}
    }

    export function extractChannel(input: ExtractChannel["input"], chanel: ExtractChannel["channel"]): ExtractChannel {
        return {type: "extractChannel", input: input, channel: chanel}
    }
    export function combineChannels<LayoutT extends MultichannelLayouts>(
        input: CombineChannels<LayoutT>["input"],
        channelLayout: LayoutT,
    ): CombineChannels<LayoutT> {
        return {type: "combineChannels", input: input, channelLayout: channelLayout}
    }

    export function externalData<ResToT extends ExternalDataType>(
        sourceData: ExternalData<ResToT>["sourceData"],
        resolveTo: ResToT,
    ): ExternalData<ResToT, typeof sourceData> {
        return {type: "externalData", sourceData, resolveTo}
    }

    // TODO currently attempting to convert RGB to RGBA format will silently fail (still outputting RGB) !
    export function convert(input: Convert["input"], dataType: Convert["dataType"], channelLayout: Convert["channelLayout"], sRGB?: Convert["sRGB"]): Convert {
        return {type: "convert", input, dataType, channelLayout, sRGB}
    }
    export function encode(input: Encode["input"], mediaType: Encode["mediaType"], options?: Encode["options"]): Encode {
        return {type: "encode", input: input, mediaType: mediaType, options: options}
    }
    export function decode(input: Decode["input"], options?: Decode["options"]): Decode {
        return {type: "decode", input: input, options: options}
    }
    export function trace(message: string, input: Trace["input"]): Trace {
        return {type: "trace", message, input}
    }
    export function setDpi(input: SetDpi["input"], dpi: SetDpi["dpi"]): SetDpi {
        return {type: "setDpi", input, dpi}
    }
}
