import {registerNode} from "@src/graph-system/register-node"
import {DeclareObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {visitNone} from "@src/graph-system/declare-visitor-node"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {namedNodeParameters} from "@src/templates/nodes/named-node"

const planeGuideParameters = namedNodeParameters.merge(
    z.object({
        width: z.number(),
        height: z.number(),
    }),
)
export type PlaneGuideParameters = z.infer<typeof planeGuideParameters>

@registerNode
export class PlaneGuide extends DeclareObjectNode(
    {parameters: planeGuideParameters},
    {
        onVisited: {
            onCompile: function (this: PlaneGuide, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {displayList} = currentTemplate
                const {width, height} = parameters

                const scope = evaluator.getScope(this)

                const objectProps = this.setupObject(scope, context, "Rectangle", undefined, undefined)
                const rect = scope.struct<SceneNodes.Rectangle>("Rectangle", {
                    type: "Rectangle",
                    ...objectProps,
                    width,
                    height,
                })
                displayList.push(rect)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "PlaneGuide"},
) {}

export type PlaneGuideFwd = TemplateObjectNode<PlaneGuideParameters>
