import {DestroyRef, Injectable, OnDestroy, computed, effect, inject, signal, untracked} from "@angular/core"
import {SceneManagerService, SceneNodePart} from "@template-editor/services/scene-manager.service"
import * as THREE from "three"
import {ObjectId, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {ThreeObject, ThreeObjectPart} from "@template-editor/helpers/three-object"
import {ThreeGrid} from "@template-editor/helpers/three-grid"
import {ThreeMesh} from "@app/template-editor/helpers/three-mesh"
import {ThreeMaterialManagerService} from "@template-editor/services/three-material-manager.service"
import {ThreeEnvironment} from "@template-editor/helpers/three-environment"
import {ThreeAreaLight} from "@template-editor/helpers/three-area-light"
import {Subject, debounceTime, defer, from, range, switchMap, concatMap, timer} from "rxjs"
import {ThreeProgressiveUVShadowMap} from "@template-editor/helpers/three-progressive-uv-shadow-map"
import {ThreeCamera} from "@template-editor/helpers/three-camera"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {ThreeShadowCatcher} from "@cm/lib/materials/three-shadow-catcher"
import {objectFieldsDifferent} from "../helpers/change-detection"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {threeValueNode} from "@cm/lib/materials/three-utils"
import {ThreeRectangle} from "../helpers/three-rectangle"
import {ThreePoint} from "../helpers/three-point"
import {ThreeAnnotation} from "../helpers/three-annotation"
import {ThreeLightPortal} from "../helpers/three-light-portal"
import {Matrix4, Vector3} from "@app/common/helpers/vector-math"
import {ThreePreloadMaterial} from "../helpers/three-preload-material"
import {ThreeWireframeMesh} from "../helpers/three-wireframe-mesh"

const NUM_SHADOW_MAP_OUTER_ITERATIONS = 75
const NUM_SHADOW_MAP_INNER_ITERATIONS = 4
const SHADOW_MAP_RESOLUTION = 1024

export type DisplayMode = "configurator" | "editor"

const defaultGrid = {
    type: "Grid",
    id: "grid",
    size: 1000,
    divisions: 10,
    color1: [0.26, 0.26, 0.26],
    color2: [0.73, 0.73, 0.73],
    transform: Matrix4.identity(),
} as SceneNodes.Grid

const defaultEnvironment = {
    id: "defaultEnvironment",
    type: "Environment",
    envData: {
        type: "url",
        url: "assets/images/template-editor-envmap.exr",
        originalFileExtension: "exr",
    },
    intensity: 1,
    rotation: new Vector3(0, 0, 0),
    mirror: false,
    priority: 9999,
} as SceneNodes.Environment

@Injectable()
export class ThreeSceneManagerService implements OnDestroy {
    $ambientLight = signal(true)
    $showGrid = signal(true)
    $displayMode = signal<DisplayMode>("editor")
    $showProgressiveLightMapDebug = signal(false)

    sceneManagerService = inject(SceneManagerService)
    materialManagerService = inject(ThreeMaterialManagerService)
    private destroyRef = inject(DestroyRef)

    protected threeFactory: {
        [key in SceneNodes.SceneNode["type"]]?: () => ThreeObject
    } = {
        Grid: () => new ThreeGrid(this),
        Mesh: () => new ThreeMesh(this),
        WireframeMesh: () => new ThreeWireframeMesh(this),
        Environment: () => new ThreeEnvironment(this),
        AreaLight: () => new ThreeAreaLight(this),
        Camera: () => new ThreeCamera(this),
        Rectangle: () => new ThreeRectangle(this),
        Point: () => new ThreePoint(this),
        Annotation: () => new ThreeAnnotation(this),
        LightPortal: () => new ThreeLightPortal(this),
        PreloadMaterial: () => new ThreePreloadMaterial(this),
    }

    private objectCache = new Map<ObjectId, ThreeObject>()
    private baseScene = new THREE.Scene()

    getObjectsFromSceneNodes(sceneNode: SceneNodePart[]): ThreeObjectPart[] {
        const allThreeObjects = Array.from(this.objectCache.values())

        return sceneNode
            .map((sceneNodePart) => {
                return allThreeObjects
                    .filter((threeObject) => {
                        const objectSceneNode = threeObject.getSceneNode()
                        return sceneNodePart.sceneNode === objectSceneNode
                    })
                    .map((threeObject) => ({
                        threeObject,
                        part: sceneNodePart.part,
                    }))
            })
            .flat()
    }

    $selectedObjects = computed(() => this.getObjectsFromSceneNodes(this.sceneManagerService.$selectedSceneNodes()))
    $hoveredObjects = computed(() => this.getObjectsFromSceneNodes(this.sceneManagerService.$hoveredSceneNodes()))
    private renderer: THREE.WebGLRenderer | undefined

    private requestedRedraw = new Subject<void>()
    requestedRedraw$ = this.requestedRedraw.asObservable()

    $cursorPosition = signal<Vector3 | undefined>(undefined)
    private $cursorObject = computed(() => {
        const cursorPosition = this.$cursorPosition()
        if (!cursorPosition) return undefined

        const transform = new Matrix4()
        transform.setTranslation(cursorPosition)
        return {id: "cursorObject", type: "Point", size: 20, transform} as SceneNodes.Point
    })

    private shadowMapMeshes = new Set<THREE.Mesh<THREE.BufferGeometry, THREE.Material>>()
    private shadowMapMeshesChanged = new Subject<void>()

    private $sceneOptions = computed(() => this.sceneManagerService.$scene().find(SceneNodes.SceneOptions.is))
    private $renderLights = computed(() => this.$sceneOptions()?.enableRealtimeLights !== false)

    private $staticDisplayList = computed<SceneNodes.SceneNode[]>(() => [
        ...(this.$showGrid() ? [defaultGrid] : []),
        ...(() => {
            const cursorObject = this.$cursorObject()
            return cursorObject ? [cursorObject] : []
        })(),
    ])
    private $renderScene = computed(() => {
        const getScene = () => {
            const scene = this.sceneManagerService.$scene()
            const reviewMode = this.sceneManagerService.$reviewMode()

            const sceneAdaptedForLights =
                !this.$renderLights() || reviewMode !== undefined ? scene.map((x) => (SceneNodes.AreaLight.is(x) ? {...x, on: false} : x)) : scene
            const sceneAdaptedForWireframes =
                reviewMode === "wireframe"
                    ? [
                          ...sceneAdaptedForLights,
                          ...sceneAdaptedForLights.filter(SceneNodes.Mesh.is).map(({meshData, transform, id, topLevelObjectId}) => {
                              const ret: SceneNodes.WireframeMesh = {
                                  type: "WireframeMesh",
                                  meshData,
                                  transform,
                                  id: id + "_wireframe",
                                  topLevelObjectId: topLevelObjectId === undefined ? undefined : topLevelObjectId + "_wireframe",
                              }
                              return ret
                          }),
                      ]
                    : sceneAdaptedForLights

            return sceneAdaptedForWireframes
        }

        const scene = [...getScene(), ...this.$staticDisplayList()]

        const hasIllumination = () => scene.some((x) => (SceneNodes.AreaLight.is(x) && x.on) || SceneNodes.Environment.is(x))

        const envMapNodes = scene.filter(SceneNodes.Environment.is)
        const otherNodes = scene.filter((item) => !SceneNodes.Environment.is(item))

        const selectedEnvironment =
            envMapNodes.reduce<SceneNodes.Environment | undefined>((acc, item) => {
                if (!acc) return item
                if (item.priority <= acc.priority) return item
                return acc
            }, undefined) ?? (this.$ambientLight() && !hasIllumination() ? defaultEnvironment : undefined)

        return selectedEnvironment ? [selectedEnvironment, ...otherNodes] : otherNodes
    })
    private $hasAreaLights = computed(() => this.$renderScene().some((x) => SceneNodes.AreaLight.is(x) && x.on))
    $hasIllumination = computed(() => this.$hasAreaLights() || this.sceneManagerService.$scene().some((x) => SceneNodes.Environment.is(x)))
    private $hasMeshes = computed(() => this.$renderScene().some(SceneNodes.Mesh.is))

    private $requiresProgressiveLightMap = computed(() => this.$hasAreaLights() && this.$hasMeshes() && this.$sceneOptions()?.enableRealtimeShadows !== false)
    private progressiveLightMap: ThreeProgressiveUVShadowMap | undefined
    private progressiveLightMapUpdate$ = new Subject<void>()

    constructor() {
        //this.materialManagerService.requestedRedraw$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.requestRedraw())

        effect(() => {
            const selectedSceneNodes = this.sceneManagerService.$selectedSceneNodes().map((sceneNodePart) => sceneNodePart.sceneNode)

            this.objectCache.forEach((threeObject) => {
                const objectSceneNode = threeObject.getSceneNode()
                threeObject.setSelected(selectedSceneNodes.includes(objectSceneNode))
            })
        })

        effect(() => {
            const displayMode = this.$displayMode()
            this.objectCache.forEach((threeObject) => {
                threeObject.onDisplayModeChange(displayMode)
            })
            this.requestRedraw()
        })

        effect(() => {
            this.updateScene()
        })

        effect(() => {
            const showProgressiveLightMapDebug = untracked(this.$showProgressiveLightMapDebug)

            if (this.$requiresProgressiveLightMap()) {
                if (!this.progressiveLightMap) {
                    this.progressiveLightMap = new ThreeProgressiveUVShadowMap(this.getRenderer(), SHADOW_MAP_RESOLUTION)

                    if (showProgressiveLightMapDebug) this.baseScene.add(this.progressiveLightMap.getDebugObject())
                    this.progressiveLightMap.attachedUVShadowMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mesh) => {
                        if (!this.progressiveLightMap) throw new Error("Progressive light map not set")
                        const {material} = mesh
                        //@ts-ignore
                        material.uvShadowMap = this.progressiveLightMap.getUVShadowMap()
                        material.needsUpdate = true
                    })
                    this.progressiveLightMap.detachedUVShadowMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mesh) => {
                        const {material} = mesh
                        //@ts-ignore
                        material.uvShadowMap = null
                        material.needsUpdate = true
                    })

                    this.shadowMapMeshesChanged.next()
                }
            } else {
                if (this.progressiveLightMap) {
                    if (showProgressiveLightMapDebug) this.baseScene.remove(this.progressiveLightMap.getDebugObject())
                    this.progressiveLightMap.dispose()
                    this.progressiveLightMap = undefined
                    this.requestRedraw()
                }
            }
        })

        effect(() => {
            const showProgressiveLightMapDebug = this.$showProgressiveLightMapDebug()
            if (!this.progressiveLightMap) return

            if (showProgressiveLightMapDebug) this.baseScene.add(this.progressiveLightMap.getDebugObject())
            else this.baseScene.remove(this.progressiveLightMap.getDebugObject())
            this.requestRedraw()
        })

        const previousSceneOptionsDifferent = (
            current: SceneNodes.SceneOptions | undefined,
            previous: SceneNodes.SceneOptions | undefined,
            fields: (keyof SceneNodes.SceneOptions)[],
        ) => {
            if (current === undefined) return previous !== undefined
            else return objectFieldsDifferent(current, previous, fields, undefined)
        }

        let previousSceneOptions: SceneNodes.SceneOptions | undefined = undefined
        effect(() => {
            const sceneOptions = this.$sceneOptions()

            if (previousSceneOptionsDifferent(sceneOptions, previousSceneOptions, ["shadowCatcherFalloff"])) {
                const shadowCatcherFalloff = sceneOptions?.shadowCatcherFalloff

                this.materialManagerService.updateMaterials((material) => {
                    if (material instanceof ThreeShadowCatcher) {
                        if (shadowCatcherFalloff) material.setFilterParameters({...shadowCatcherFalloff, bias: 0.0})
                        else material.resetFilterParameters()
                    }
                })
            }

            const specularOnlyMaterialModifier = (material: THREE.Material, remove: boolean = false) => {
                if (!(material instanceof THREENodes.MeshStandardNodeMaterial)) return false
                if (material.roughnessNode) {
                    if (remove) {
                        //TODO THREE UPGRADE
                        //@ts-ignore
                        material.envMapIntensityNode = null
                    } else {
                        const zero = threeValueNode(0)
                        const one = threeValueNode(1)

                        //TODO THREE UPGRADE
                        //@ts-ignore
                        material.envMapIntensityNode = THREENodes.pow(
                            THREENodes.sub(one, THREENodes.clamp(material.roughnessNode, zero, one)),
                            threeValueNode(4),
                        )
                    }

                    return true
                }

                return false
            }

            if (previousSceneOptionsDifferent(sceneOptions, previousSceneOptions, ["environmentMapMode"])) {
                const environmentMapMode = sceneOptions?.environmentMapMode

                if (environmentMapMode === "specularOnly") this.materialManagerService.materialModifier = specularOnlyMaterialModifier
                else this.materialManagerService.materialModifier = undefined

                this.materialManagerService.updateMaterials((material) => {
                    if (specularOnlyMaterialModifier(material, environmentMapMode !== "specularOnly")) material.needsUpdate = true
                })
            }

            previousSceneOptions = sceneOptions
        })

        toObservable(this.$displayMode)
            .pipe(
                switchMap((displayMode) =>
                    this.shadowMapMeshesChanged
                        .asObservable()
                        .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(displayMode === "configurator" ? 10 : 1000)),
                ),
            )
            .subscribe(() => this.shadowMapMeshesSettled())

        const shadowUpdate = async (outerIteration: number) => {
            return new Promise<void>((resolve) => {
                if (!this.progressiveLightMap) resolve()

                requestAnimationFrame(() => {
                    if (this.progressiveLightMap) {
                        //if (iteration === 0) this.progressiveLightMap.reset()
                        const iteration = outerIteration * NUM_SHADOW_MAP_INNER_ITERATIONS
                        this.progressiveLightMap.update(this.baseScene, iteration, NUM_SHADOW_MAP_INNER_ITERATIONS)

                        this.requestedRedraw.next()
                    }
                    resolve()
                })
            })
        }

        //If for 1000ms there are no other progressive light map update events, start accumulating shadow map updates
        this.progressiveLightMapUpdate$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() =>
                    timer(this.$displayMode() === "configurator" ? 10 : 1000).pipe(
                        switchMap(() => range(NUM_SHADOW_MAP_OUTER_ITERATIONS).pipe(concatMap((iteration) => defer(() => from(shadowUpdate(iteration)))))),
                    ),
                ),
            )
            .subscribe()
    }

    init(canvas: HTMLCanvasElement) {
        if (this.renderer) throw new Error("Renderer already initialized")

        this.renderer = new THREE.WebGLRenderer({
            canvas,
            preserveDrawingBuffer: true,
            powerPreference: "high-performance",
            antialias: false,
            alpha: true,
        })

        const pixelRatio = window.devicePixelRatio
        this.renderer.setPixelRatio(pixelRatio)
        this.renderer.setClearColor(0x000000, 0)
    }

    resizeRenderer(width: number, height: number) {
        if (!this.renderer) throw new Error("Renderer not set, forgot to call init()?")
        this.renderer.setSize(width, height)
    }

    private updateScene() {
        const scene = this.$renderScene()

        const toDelete = new Set<ObjectId>()
        for (const [id] of this.objectCache) toDelete.add(id)

        const getThreeObject = (sceneNode: SceneNodes.SceneNode) => {
            const cachedThreeObject = this.objectCache.get(sceneNode.id)
            if (cachedThreeObject) return cachedThreeObject

            const threeObject = this.threeFactory[sceneNode.type]?.()
            if (!threeObject) return undefined

            this.objectCache.set(sceneNode.id, threeObject)
            return threeObject
        }

        let needsUpdate = false
        let needsShadowMapUpdate = false
        for (const sceneNode of scene) {
            const threeObject = getThreeObject(sceneNode)
            if (threeObject) {
                if (threeObject.update(sceneNode)) {
                    if (threeObject instanceof ThreeMesh || threeObject instanceof ThreeAreaLight) needsShadowMapUpdate = true
                    needsUpdate = true
                }

                const threeRenderObject = threeObject.getRenderObject()
                if (!threeRenderObject.parent) this.baseScene.add(threeRenderObject)
            }
            toDelete.delete(sceneNode.id)
        }

        for (const id of toDelete) {
            const threeObject = this.objectCache.get(id)
            if (threeObject) {
                this.baseScene.remove(threeObject.getRenderObject())
                this.objectCache.delete(id)
                threeObject.dispose(true)
                if (threeObject instanceof ThreeMesh || threeObject instanceof ThreeAreaLight) needsShadowMapUpdate = true
                needsUpdate = true
            }
        }

        if (needsUpdate) this.requestRedraw()
        if (needsShadowMapUpdate) this.progressiveLightMapUpdate$.next()
    }

    getRenderer() {
        if (!this.renderer) throw new Error("Renderer not set")
        return this.renderer
    }

    modifyBaseScene(modifier: (scene: THREE.Scene) => void) {
        modifier(this.baseScene)
    }

    getSceneReference() {
        return this.baseScene
    }

    ngOnDestroy() {
        for (const threeObject of this.objectCache.values()) threeObject.dispose(true)
        this.objectCache.clear()

        if (this.progressiveLightMap) this.progressiveLightMap.dispose()
        if (this.renderer) this.renderer.dispose()
    }

    requestRedraw() {
        this.requestedRedraw.next()
    }

    addShadowMapMesh(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        this.shadowMapMeshes.add(mesh)
        this.requestShadowMapUpdate()
    }

    removeShadowMapMesh(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        this.shadowMapMeshes.delete(mesh)
        this.requestShadowMapUpdate()
    }

    requestShadowMapUpdate() {
        this.shadowMapMeshesChanged.next()
    }

    exchangedShadowMapMeshTexture(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        if (this.progressiveLightMap && this.progressiveLightMap.has(mesh)) {
            const {material} = mesh
            //@ts-ignore
            material.uvShadowMap = this.progressiveLightMap.getUVShadowMap()
            material.needsUpdate = true
        }
    }

    protected shadowMapMeshesSettled() {
        if (this.progressiveLightMap) {
            this.progressiveLightMap.attachToMeshes(this.shadowMapMeshes)
            this.progressiveLightMapUpdate$.next()
        }
    }
}
