import {z} from "zod"

export namespace ZodUtils {
    // TODO extend to also support unions, nesting
    export function templateLiteral<ArgsT extends (z.ZodNumber | z.ZodString | z.ZodLiteral<string>)[]>(...args: readonly [...ArgsT]) {
        type ArgsToBaseArgs<T extends ArgsT> = {
            [E in keyof T]: T[E] extends z.ZodNumber ? `${number}` : T[E] extends z.ZodString ? `${string}` : T[E] extends z.ZodLiteral<infer B> ? B : never
        }
        type TupleToString<T extends Readonly<any[]>, U extends string = ``> =
            T extends Readonly<[string]> ? `${U}${T[0]}` : T extends Readonly<[string, ...infer Rest]> ? TupleToString<Rest, `${U}${T[0]}`> : never

        type BaseArgsT = ArgsToBaseArgs<ArgsT>
        type BaseT = TupleToString<BaseArgsT>

        const regExpArgBase = args.reduce((acc: string, item: ArgsT[number]) => {
            if (item instanceof z.ZodLiteral) {
                acc = `${acc}${item.value}`
            } else if (item instanceof z.ZodNumber) {
                acc = `${acc}[\\d]+`
            } else if (item instanceof z.ZodString) {
                acc = `${acc}[\\w]+`
            } else {
                throw Error(`Failed to create schema for template literal, unsupported type: ${item}`)
            }
            return acc
        }, "")

        return z.custom<BaseT>((x: any) => {
            return typeof x === "string" ? new RegExp(`\\b${regExpArgBase}\\b`).test(x) : false
        })
    }
}
