import {inject, Injectable} from "@angular/core"
import {ContentTypeModel, DataObjectAssignmentType} from "@api"
import {forceEnum} from "@common/helpers/utils/enum"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"

type ItemT = {id: string; __typename: string; galleryImage?: {id: string}}

@Injectable({
    providedIn: "root",
})
export class GalleryImageService {
    notifications = inject(NotificationsService)
    sdk = inject(SdkService)
    refresh = inject(RefreshService)

    async assign(item: ItemT, galleryImageId: string) {
        await this.notifications.withUserFeedback(
            async () => {
                await this.sdk.gql.createAttachment({
                    input: {
                        dataObjectId: galleryImageId,
                        objectId: item.id,
                        contentTypeModel: forceEnum(item.__typename, ContentTypeModel),
                        type: this.assignmentTypeForItem(item, true)[0],
                    },
                })
                this.refresh.item(item)
            },
            {
                success: `Successfully assigned ${this.labelForItem(item)}`,
                error: `Failed to assign ${this.labelForItem(item)}`,
            },
        )
    }

    async remove(item: ItemT) {
        await this.notifications.withUserFeedback(
            async () => {
                const assignments = await Promise.all(
                    this.assignmentTypeForItem(item, false).map(async (assignmentType) =>
                        this.sdk.gql.galleryImageAssignments({
                            objectId: item.id,
                            contentTypeModel: forceEnum(item.__typename, ContentTypeModel),
                            assignmentType,
                        }),
                    ),
                ).then((results) => results.map((result) => result.galleryImageAssignments).reduce((acc, val) => acc.concat(val), []))

                if (!(assignments.length && assignments[0])) throw new Error(`No ${this.labelForItem(item)} assignments found`)
                await this.sdk.gql.galleryImageAssignmentRemove(assignments[0])
                this.refresh.item(item)
            },
            {
                success: `Successfully removed ${this.labelForItem(item)}`,
                error: `Failed to remove ${this.labelForItem(item)}`,
            },
        )
    }

    async available(item: ItemT) {
        const count = await Promise.all(
            this.assignmentTypeForItem(item, false).map(async (assignmentType) =>
                this.sdk.gql.galleryImageCount({
                    objectId: item.id,
                    contentTypeModel: forceEnum(item.__typename, ContentTypeModel),
                    assignmentType: assignmentType,
                }),
            ),
        ).then((results) => results.map((result) => result.galleryImageCount).reduce((acc, val) => acc + val, 0))

        return !!count
    }

    private assignmentTypeForItem(
        item: ItemT,
        newAssignment: boolean,
    ): (DataObjectAssignmentType.GalleryImage | DataObjectAssignmentType.ProfilePicture | DataObjectAssignmentType.CustomerLogo)[] {
        switch (item.__typename) {
            case ContentTypeModel.Organization:
                return [DataObjectAssignmentType.CustomerLogo]
            case ContentTypeModel.User:
                return newAssignment
                    ? [DataObjectAssignmentType.ProfilePicture] // TODO double check this
                    : [DataObjectAssignmentType.GalleryImage, DataObjectAssignmentType.ProfilePicture]
            default:
                return [DataObjectAssignmentType.GalleryImage]
        }
    }

    labelForItem(item: ItemT) {
        switch (item.__typename) {
            case ContentTypeModel.Organization:
                return "organization logo"
            case ContentTypeModel.User:
                return "profile picture"
            default:
                return "gallery image"
        }
    }
}
