import {CommonModule, NgClass, NgTemplateOutlet} from "@angular/common"
import {AfterContentInit, Component, EventEmitter, HostListener, Input, Output} from "@angular/core"
import {MatMenuModule, MatMenuPanel} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActionPanelComponent} from "@common/components/menu/action-menu/action-panel/action-panel.component"

@Component({
    selector: "cm-action-item",
    templateUrl: "./action-item.component.html",
    styleUrls: ["./action-item.component.scss"],
    standalone: true,
    imports: [CommonModule, MatMenuModule, NgClass, MatTooltipModule, NgTemplateOutlet],
})
export class ActionItemComponent implements AfterContentInit {
    @Input() cmIconClass?: string
    @Input() tooltip?: string
    @Input() panel?: ActionPanelComponent
    @Input() disabled = false
    @Input() showPanelByDefault = false
    @Input() menuTriggerFor: MatMenuPanel | null = null // use this instead of matMenuTriggerFor to trigger menus which respect the item's disabled state

    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    private _active = false
    get active(): boolean {
        return this._active
    }

    @Input() set active(value: boolean) {
        this._active = value
        if (value) this.panel?.show()
        else this.panel?.hide()
        this.activeChange.emit(value)
    }

    @Output() itemClick = new EventEmitter<void>()

    @HostListener("click", ["$event"]) onClick() {
        if (this.disabled) return
        if (this.panel) {
            this.active = !this.active
        }
        this.itemClick.emit()
    }

    ngAfterContentInit() {
        if (this.showPanelByDefault) {
            this.active = true
        }
    }
}
