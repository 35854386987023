export namespace AsyncReentrancyGuard {
    export class RejectionError extends Error {
        constructor(message: string) {
            super(message)
            this.name = "RejectionError"
        }
    }

    // serialize concurrent requests
    export class PromiseSerializer {
        async executeSequentially<T>(asyncFunction: () => Promise<T>): Promise<T> {
            return new Promise<T>(async (resolve, reject) => {
                // Create a function that wraps the provided async function
                const wrappedFunction = async () => {
                    try {
                        const result = await asyncFunction()
                        resolve(result)
                    } catch (error) {
                        reject(error)
                    }
                }

                // Add the wrapped function to the execution queue
                this.executionQueue.push(wrappedFunction)

                if (!this.isExecuting) {
                    // If there's no ongoing execution, start executing the queue
                    this.isExecuting = true
                    while (this.executionQueue.length > 0) {
                        const nextFunction = this.executionQueue.shift()
                        if (nextFunction) {
                            await nextFunction()
                        }
                    }
                    this.isExecuting = false
                }
            })
        }

        private executionQueue: (() => Promise<void>)[] = []
        private isExecuting = false
    }

    // fail concurrent requests
    export class PromiseGate<T extends unknown> {
        startIfIdleOrGetCurrent(ifIdlePromiseFn: () => Promise<T>): Promise<T> {
            if (!this.currentlySettlingPromise) {
                this.rejectCurrentPromise = false
                return this.startWrappedPromise(ifIdlePromiseFn, Promise.reject)
            } else {
                return this.currentlySettlingPromise
            }
        }

        startAndRejectCurrent(promiseFn: () => Promise<T>, rejectFnIn?: (rejectedResult: T | undefined) => void): Promise<T> {
            const rejectFn: (rejectedResult: T | undefined) => Promise<T> = (rejectedResult) => {
                if (rejectFnIn) {
                    rejectFnIn(rejectedResult)
                }
                throw new RejectionError("Promise rejected due to initiation of a more recent promise")
            }
            if (this.currentlySettlingPromise) {
                this.rejectCurrentPromise = true
                const nextPromise = (this.lastQueuedPromise || this.currentlySettlingPromise).catch(() => {
                    if (nextPromise === this.lastQueuedPromise) {
                        this.rejectCurrentPromise = false
                        this.lastQueuedPromise = null
                    }
                    return this.startWrappedPromise(promiseFn, rejectFn)
                })
                this.lastQueuedPromise = nextPromise
                return nextPromise
            } else {
                this.rejectCurrentPromise = false
                return this.startWrappedPromise(promiseFn, rejectFn)
            }
        }

        startIfIdleOrReject(ifIdlePromiseFn: () => Promise<T>, ifBusyFailPromiseFnIn?: () => Promise<T>): Promise<T> {
            const ifBusyFailPromiseFn = ifBusyFailPromiseFnIn || Promise.reject
            if (!this.currentlySettlingPromise) {
                this.rejectCurrentPromise = false
                return this.startWrappedPromise(ifIdlePromiseFn, ifBusyFailPromiseFn)
            } else {
                return ifBusyFailPromiseFn()
            }
        }

        private startWrappedPromise(promiseFn: () => Promise<T>, rejectFn: (rejectedResult: T | undefined) => Promise<T>): Promise<T> {
            if (this.rejectCurrentPromise) {
                return rejectFn(undefined)
            }
            this.currentlySettlingPromise = promiseFn().then(
                (result) => {
                    this.currentlySettlingPromise = null
                    if (this.rejectCurrentPromise) {
                        return rejectFn(result)
                    }
                    return result
                },
                (error) => {
                    this.currentlySettlingPromise = null
                    throw error
                },
            )
            return this.currentlySettlingPromise
        }

        private currentlySettlingPromise: Promise<T> | null = null
        private lastQueuedPromise: Promise<T> | null = null
        private rejectCurrentPromise = false
    }
}
