import {FilterStates} from "@common/services/filters/filters.service"

export const filtersAreEqual = (previous: FilterStates, current: FilterStates): boolean => {
    const previousEntries = Array.from(Object.entries(previous.criteria))
    const currentEntries = Array.from(Object.entries(current.criteria))
    return (
        previous.search === current.search &&
        previousEntries.every(([key, value]) => {
            return current.criteria[key] === value
        }) &&
        previousEntries.length === currentEntries.length
    )
}
