import {Component, Input} from "@angular/core"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {OperatorShift} from "app/textures/texture-editor/operator-stack/operators/shift/operator-shift"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

@Component({
    selector: "cm-shift-panel",
    templateUrl: "./shift-panel.component.html",
    styleUrls: ["./shift-panel.component.scss"],
    standalone: true,
    imports: [InspectorSectionComponent, ToggleComponent],
})
export class ShiftPanelComponent {
    @Input() operator!: OperatorShift
    @Input() disabled = false

    constructor() {}

    set showGuides(value: boolean) {
        this.operator.showGuides = value
    }

    get showGuides(): boolean {
        return this.operator.showGuides
    }
}
