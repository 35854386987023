import {JobState} from "@api"

export enum SimpleJobState {
    InProgress = "InProgress",
    Complete = "Complete",
    Failed = "Failed",
    Cancelled = "Cancelled",
}

export const getSimpleJobState = (jobState: JobState): SimpleJobState => {
    switch (jobState) {
        case JobState.Cancelled:
            return SimpleJobState.Cancelled
        case JobState.Failed:
            return SimpleJobState.Failed
        case JobState.Init:
        case JobState.Runnable:
        case JobState.Running:
            return SimpleJobState.InProgress
        case JobState.Complete:
            return SimpleJobState.Complete
        default:
            throw Error(`Unexpected job state: ${jobState}`)
    }
}
