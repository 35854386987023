import {inject, Injectable} from "@angular/core"
import {ContentTypeModel, DataObjectAssignmentType, DataObjectForArServiceFragment, JobTaskState, SceneViewerJobTaskStateWithOutputGQL} from "@api"
import {isAndroid, isIoS} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {triggerDOMLink} from "@app/common/helpers/routes"
import {Settings} from "@app/common/models/settings/settings"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {arGltfGenerationTask, arUsdzGenerationTask} from "@cm/lib/job-task/ar"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {Parameters} from "@cm/lib/templates/nodes/template-instance"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {filter, firstValueFrom, map, Subject, take} from "rxjs"

type ArContentModel = "model/vnd.usdz+zip" | "model/gltf-binary"

@Injectable()
export class ArService {
    private sdk = inject(SdkService)
    private jobStateWithOutput = inject(SceneViewerJobTaskStateWithOutputGQL)

    //Events
    private desktopGcsObjectName: Subject<string | undefined> = new Subject()
    desktopGcsObjectName$ = this.desktopGcsObjectName.asObservable()

    private creatingArSubject = new Subject<boolean>()
    creatingAr$ = this.creatingArSubject.asObservable()

    private async getExistingArModelDataObject(
        templateRevisionId: string,
        parameters: Parameters,
        contentType: ArContentModel,
    ): Promise<DataObjectForArServiceFragment | undefined> {
        const {dataObjectAssignments} = await this.sdk.gql.getDataObjectAssignmentsForArService({
            filter: {
                contentTypeModel: ContentTypeModel.TemplateRevision,
                objectId: templateRevisionId,
                //assignmentKey: {equals: parameters.getHash()},
                //TODO: Once the backend is updated, replace this hard-coded string with the config hash above
                assignmentKey: {
                    equals: '{"c08933f4-748e-4a8f-a084-2eed9224bfae/0b14fa66-aa8d-49b0-b57e-09cf0fa2c341/db734a48-e31b-4a45-8d88-1bf459ea9840":"7f9e8eb7-616f-46bd-b2d7-667b1e310164","c08933f4-748e-4a8f-a084-2eed9224bfae/bea7885c-a3d8-4eca-8439-76288adb8bef/49ad2b49-9085-4d76-a2ca-c51c40b9616c":"cbb4a402-95ec-454e-a327-b5a2530797b0","c08933f4-748e-4a8f-a084-2eed9224bfae/bea7885c-a3d8-4eca-8439-76288adb8bef/5a257a5e-fd55-455f-abef-8e73df6a3b64/b6f43c21-167f-4674-9ccd-796fe1426e77":"07eee68c-1fe6-4059-bb54-a1f2bef0115b","c08933f4-748e-4a8f-a084-2eed9224bfae/f8d4daf1-8113-4026-94da-4032c85be962/607294be-9e05-45be-bf90-c77f7c402c7b":"b0ce2d25-bd92-4d9b-84cf-5e3c4405e787","c08933f4-748e-4a8f-a084-2eed9224bfae/f8d4daf1-8113-4026-94da-4032c85be962/e108da2b-835b-44b0-86be-37683f0dadac/5a21b88f-cb5d-4659-948c-dc7d93a6c97d":"569f8db5-00ab-4477-85ef-11f05de1de61","c08933f4-748e-4a8f-a084-2eed9224bfae/f8d4daf1-8113-4026-94da-4032c85be962/e108da2b-835b-44b0-86be-37683f0dadac/bc2bbf09-d451-44ee-88d0-3eacb0f5fde8":"d72bd791-16c9-4396-af20-c3ccd7e86f9b"}',
                },
                assignmentType: [DataObjectAssignmentType.CachedTemplateGltf],
            },
        })

        if (dataObjectAssignments.length > 1)
            console.warn(`Multiple data object assignments found for template revision ${templateRevisionId} and config hash ${parameters.getHash()}`)

        const gltfDataObject = dataObjectAssignments[0]?.dataObject
        if (!gltfDataObject) return undefined

        /*The gltf is the main model, the usdz file is derived from this and thus attached as related, see processUsdz() in tasks.*/
        switch (contentType) {
            case "model/gltf-binary":
                return gltfDataObject
            case "model/vnd.usdz+zip":
                return gltfDataObject.related.find((x) => x.mediaType === "model/vnd.usdz+zip")
            default:
                throw Error(`Unknown type: ${contentType}.`)
        }
    }

    private async getArModelDataObject(
        templateRevisionId: string,
        parameters: Parameters,
        contentType: ArContentModel,
    ): Promise<DataObjectForArServiceFragment> {
        let existingArModel = await this.getExistingArModelDataObject(templateRevisionId, parameters, contentType)
        if (existingArModel) return existingArModel

        await this.generateNewArModels(templateRevisionId, parameters)

        existingArModel = await this.getExistingArModelDataObject(templateRevisionId, parameters, contentType)
        if (!existingArModel) throw new Error("Ar generation failed")

        return existingArModel
    }

    private makeJobGraph(templateLegacyId: number, configurationBase64: string) {
        const gltfGenerationTask = JobNodes.task(arGltfGenerationTask, {
            input: JobNodes.struct({
                templateId: JobNodes.value(templateLegacyId),
                configString: JobNodes.value(configurationBase64),
            }),
        })
        const usdzGenerationTask = JobNodes.task(arUsdzGenerationTask, {
            input: gltfGenerationTask,
        })

        return JobNodes.jobGraph(JobNodes.list([gltfGenerationTask, usdzGenerationTask]), {
            platformVersion: Settings.APP_VERSION,
        })
    }

    private async waitForJobToFinish(jobId: string) {
        const jobTask = await firstValueFrom(
            this.jobStateWithOutput.watch({id: jobId}, {pollInterval: 2000}).valueChanges.pipe(
                map(({data: {jobTask}}) => jobTask),
                filter((jobTask) => {
                    switch (jobTask.state) {
                        case JobTaskState.Runnable:
                        case JobTaskState.Running:
                        case JobTaskState.Init:
                            return false
                        default:
                            return true
                    }
                }),
                take(1),
            ),
        )

        if (jobTask.state !== JobTaskState.Complete) {
            throw new Error("AR generation failed")
        }
    }

    private async generateNewArModels(templateRevisionId: string, currentParams: Parameters) {
        const {templateRevision} = await this.sdk.gql.getTemplateIdForArService({templateRevisionId})

        const configurationBase64 = btoa(JSON.stringify(currentParams.serialize()))

        console.log(`Starting AR generation job for template revision ${templateRevisionId} and configuration ${configurationBase64}`)

        const {createJob: arGenerationJob} = await this.sdk.gql.sceneViewerCreateJob({
            input: {
                graph: graphToJson(this.makeJobGraph(templateRevision.template.legacyId, configurationBase64)),
                name: "AR generation",
                organizationLegacyId: templateRevision.template.organization.legacyId,
            },
        })

        await this.waitForJobToFinish(arGenerationJob.id)
    }

    async viewArModel(templateRevisionId: string, parameters: Parameters) {
        this.creatingArSubject.next(true)

        const arModelDataObject = await this.getArModelDataObject(templateRevisionId, parameters, isIoS ? "model/vnd.usdz+zip" : "model/gltf-binary")

        if (isAndroid) {
            this.viewInArAndroid(arModelDataObject)
        } else if (isIoS) {
            this.viewInArIos(arModelDataObject)
        } else {
            this.desktopGcsObjectName.next(arModelDataObject.objectName)
        }

        this.creatingArSubject.next(false)
    }

    private viewInArAndroid(gltfDataObject: DataObjectForArServiceFragment) {
        const dataObjectURL = gltfDataObject.downloadUrl
        triggerDOMLink({
            href:
                `intent://arvr.google.com/scene-viewer/1.0?` +
                `mode=ar_only&` +
                `file=${dataObjectURL}#Intent;` +
                `scheme=https;package=com.google.android.googlequicksearchbox;` +
                `action=android.intent.action.VIEW;` +
                `S.browser_fallback_url=https://developers.google.com/ar;end;`,
        })
    }

    private viewInArIos(usdzDataObject: DataObjectForArServiceFragment) {
        const dataObjectURL = usdzDataObject.downloadUrl
        // need to create a link with an <img> tag inside, as noted here: https://cwervo.com/writing/quicklook-web
        const a = document.createElement("a")
        a.href = dataObjectURL
        a.rel = "ar"
        const img = document.createElement("img")
        a.appendChild(img)
        document.body.appendChild(a)
        a.style.display = "none"
        a.click()
        a.remove()
    }
}
