@if (object(); as object) {
    <span [matMenuTriggerFor]="transformMenu" (click)="$event.stopPropagation()">
        @if (object.parameters.lockedTransform) {
            <i class="far fa-lock" matTooltip="Transform is locked"></i>
        } @else {
            <i class="far fa-unlock" matTooltip="Transform is not locked"></i>
        }
        <mat-menu #transformMenu>
            <cm-template-menu>
                <cm-template-menu-section>
                    <ng-container cm-section-title>
                        <i class="far fa-arrows-up-down-left-right"></i>
                        Position
                    </ng-container>
                    <ng-container cm-section>
                        <cm-list-item (click)="resetNodeTransform()" [disabled]="!object.parameters.lockedTransform">
                            <ng-container cm-pre-title><i class="far fa-trash"></i></ng-container>
                            <ng-container cm-title>Reset transform</ng-container>
                        </cm-list-item>
                        <cm-list-item (click)="$event.stopPropagation()">
                            <ng-container cm-pre-title>
                                <cm-toggle [toggle]="!!object.parameters.lockedTransform" (toggleChange)="setTransformLocked($event)"></cm-toggle>
                            </ng-container>
                            <ng-container cm-title>Lock transform</ng-container>
                        </cm-list-item>
                        <cm-list-item (click)="moveToOrigin()" [disabled]="!object.parameters.lockedTransform">
                            <ng-container cm-pre-title><i class="far fa-compress-arrows"></i></ng-container>
                            <ng-container cm-title>Move to origin</ng-container>
                        </cm-list-item>
                    </ng-container>
                </cm-template-menu-section>
            </cm-template-menu>
        </mat-menu>
    </span>
}
