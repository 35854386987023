import {DataObject, ITransientDataObject as ITransientDataObjectNewOrg, ImageResource} from "@src/materials/material-node-graph"

export interface IDataObject {
    id: number
    width: number
    height: number
}

export type IDataObjectNew = DataObject & {
    related: DataObject[]
}

export function isIDataObject(x: IDataObject | ITransientDataObject): x is IDataObject {
    return x.hasOwnProperty("id")
}

export function isIDataObjectNew(x: IDataObjectNew | ITransientDataObjectNew): x is IDataObjectNew {
    return x.hasOwnProperty("legacyId")
}

export interface ITransientDataObject {
    data: Uint8Array
}
export interface ITransientDataObjectNew extends ITransientDataObjectNewOrg {
    data: Uint8Array
    mediaType: string
}

export function dataObjectNewToImageRessource(dataObject: IDataObjectNew): ImageResource {
    const {related, ...rest} = dataObject
    return {mainDataObject: rest, relatedDataObjects: related}
}
