// @ts-strict-ignore
import {Component, Input} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"

@Component({
    selector: "cm-render-inspector",
    templateUrl: "./render-inspector.component.html",
    styleUrls: ["./render-inspector.component.scss"],
    standalone: true,
    imports: [InspectorSectionComponent, InputContainerComponent, NodeReferenceSlotComponent, NumericInputComponent, MatTooltipModule],
})
export class RenderInspectorComponent {
    @Input() editor: IEditor
    @Input() node: Nodes.Render | null = null

    constructor() {}

    updateNode(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
