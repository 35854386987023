import {TagType} from "@api"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const TagTypeLabels: Map<TagType, StateLabel<TagType>> = new Map<TagType, StateLabel<TagType>>([
    [TagType.Production, {state: TagType.Production, label: "Production", background: "#06b6d4"}],
    [TagType.MaterialRange, {state: TagType.MaterialRange, label: "Material Range", background: "#84cc16"}],
    [TagType.MaterialColor, {state: TagType.MaterialColor, label: "Color", background: "#f43f5e"}],
    [TagType.ProductRange, {state: TagType.ProductRange, label: "Product Range", background: "#f4d03f"}],
    [TagType.PdfSpecItem, {state: TagType.PdfSpecItem, label: "PDF Spec Item", background: "#d33ff4"}],
])

export const TagTypeOptions = [TagType.Production, TagType.MaterialRange, TagType.MaterialColor, TagType.ProductRange, TagType.PdfSpecItem].map(
    (tagType) => TagTypeLabels.get(tagType) as StateLabel<TagType>,
)
