import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {Size2Like} from "@cm/lib/math/size2"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {getHalAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"

export type GridPoint = {
    sourcePixel: Vector2Like
    targetPixel: Vector2Like
}

export type ParameterType = {
    sourceImage: ImagePtr
    gridPoints: GridPoint[][] // 2D array of grid points (inner array = x-axis, outer array = y-axis)
    resultSize?: Size2Like // default: computed from targetPixel in gridPoints
    addressMode?: AddressMode // default: "wrap"
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpGridMapping: ImageOpType<ParameterType, ReturnType> = {
    name: "GridMapping",

    WebGL2: async ({context, parameters: {sourceImage, gridPoints, resultSize, addressMode, resultImage}}) => {
        if (!resultSize) {
            let maxX = Number.NEGATIVE_INFINITY
            let maxY = Number.NEGATIVE_INFINITY
            for (const row of gridPoints) {
                for (const point of row) {
                    maxX = Math.max(maxX, Math.round(point.targetPixel.x))
                    maxY = Math.max(maxY, Math.round(point.targetPixel.y))
                }
            }
            resultSize = {width: maxX, height: maxY}
        }
        addressMode ??= "wrap"
        const halPrimitivePainter = await context.getOrCreatePrimitivePainter(`
            vec4 computeColor(vec2 worldPosition, vec2 uv, vec4 color) {
                return texelFetchInterpolated0(uv, ${getHalAddressMode(addressMode)});
            }
        `)
        using sourceImageWebGl2 = await context.getImage(sourceImage)
        resultImage = await context.prepareResultImage(resultImage, {
            ...sourceImageWebGl2.ref.descriptor,
            ...resultSize,
        })
        using resultImageWebGl2 = await context.getImage(resultImage)
        const vertexPositions: Vector2[] = []
        const vertexUVs: Vector2[] = []
        const indices: number[] = []
        const numGridPointsX = gridPoints[0].length
        const numGridPointsY = gridPoints.length
        for (let y = 0; y < numGridPointsY; y++) {
            for (let x = 0; x < numGridPointsX; x++) {
                // vertex
                const point = gridPoints[y][x]
                vertexPositions.push(new Vector2(point.targetPixel.x, point.targetPixel.y))
                vertexUVs.push(new Vector2(point.sourcePixel.x, point.sourcePixel.y))
                // index
                if (x > 0 && y > 0) {
                    const i0 = (y - 1) * numGridPointsX + x - 1
                    const i1 = i0 + 1
                    const i2 = i0 + numGridPointsX
                    const i3 = i0 + numGridPointsX + 1
                    indices.push(i0, i1, i2, i1, i3, i2)
                }
            }
        }
        halPrimitivePainter.clearGeometry()
        halPrimitivePainter.addVertices(vertexPositions, vertexUVs)
        halPrimitivePainter.addIndices(indices)
        halPrimitivePainter.setSourceImage(0, sourceImageWebGl2.ref.halImage)
        await halPrimitivePainter.paint(resultImageWebGl2.ref.halImage, {blendMode: "none"})
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, gridPoints, resultSize, addressMode, resultImage}}) => {
        throw new Error("Not implemented") // TODO
    },
}
