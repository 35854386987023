@if (needsSave) {
    <cm-hint>
        <ng-container cm-text>
            Once you have uploaded <b>all</b> the textures you want to update, be sure to <b>click "Save"</b> to apply your changes.
        </ng-container>
    </cm-hint>
    @if ($status() === "inconsistent-sizes") {
        <cm-hint [hintType]="HintTypes.Warning">
            <ng-container cm-text>
                The uploaded textures have different resolutions. Please make sure all textures have share the same resolution.
            </ng-container>
        </cm-hint>
    } @else if ($status() === "processing") {
        <cm-hint>
            <ng-container cm-text> The uploaded textures are being processed. This may take a moment.</ng-container>
        </cm-hint>
    }
}
<div class="cm-grid-container">
    @for (textureType of textureTypes; track textureType) {
        @if (descriptorByTextureType(textureType); as textureTypeDescriptor) {
            <ng-container *ngVar="getDataObjectId(textureType) as dataObjectId">
                @if (isUploading(textureType) || dataObjectId !== undefined) {
                    <a
                        [routerLink]="allowTextureClick ? ['set-revisions', textureSetRevisionId] : null"
                        [queryParams]="{textureType: textureType}"
                        queryParamsHandling="merge"
                    >
                        <cm-texture-thumbnail-view
                            #textureThumbnailView
                            [label]="textureTypeDescriptor.label"
                            [dataObjectId]="dataObjectId"
                            [isUploading]="isUploading(textureType)"
                            [canDrop]="true"
                            (fileDropped)="onTextureDropped(textureType, $event)"
                            (click)="onTextureClicked(textureType)"
                            (thumbnailAvailable)="onThumbnailAvailable(textureType, $event)"
                        ></cm-texture-thumbnail-view>
                    </a>
                } @else if (shouldShowEmptyTextures) {
                    <cm-texture-thumbnail-view
                        [ngClass]="'cm-texture-pointer-cursor'"
                        [label]="textureTypeDescriptor.label"
                        [dataObjectId]="undefined"
                        [isUploading]="isUploading(textureType)"
                        [canDrop]="true"
                        (fileDropped)="onTextureDropped(textureType, $event)"
                        (click)="onTextureClicked(textureType)"
                    ></cm-texture-thumbnail-view>
                }
            </ng-container>
        }
    }
    <div class="cm-show-empty-textures-2" (click)="shouldShowEmptyTextures = !shouldShowEmptyTextures" matTooltip="Show empty textures.">
        <i [class]="shouldShowEmptyTextures ? 'far fa-chevron-left' : 'far fa-chevron-right'"></i>
    </div>
</div>
@if (needsSave) {
    <div class="cm-input-fields">
        <cm-input-container>
            <ng-container cm-inline-label> Name: </ng-container>
            <ng-container cm-input>
                <cm-string-input [(value)]="data.name" placeholder="(Enter title of new revision)" />
            </ng-container>
        </cm-input-container>

        <cm-input-container>
            <ng-container cm-inline-label> Width: </ng-container>
            <ng-container cm-input>
                <cm-numeric-input [(value)]="data.textureSetRevisionInfo.width" placeholder="(Enter width in cm)" />
            </ng-container>
        </cm-input-container>
        ×
        <cm-input-container>
            <ng-container cm-inline-label> Height: </ng-container>
            <ng-container cm-input>
                <cm-numeric-input [(value)]="data.textureSetRevisionInfo.height" placeholder="(Enter height in cm)" />
            </ng-container>
        </cm-input-container>
    </div>
    <div class="cm-buttons">
        <cm-button class="cm-button" [class.cm-active]="canSave" [disabled]="!canSave" (click)="onSave()">
            <ng-container cm-text>Save</ng-container>
        </cm-button>
        <cm-button class="cm-button" (click)="onRevert()">
            <ng-container cm-text> Discard changes </ng-container>
        </cm-button>
    </div>
}
