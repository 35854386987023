import {z} from "zod"
import {Injectable, inject} from "@angular/core"
import {MaterialGraphConnectionFragment, MaterialGraphDataObjectDetailsFragment, MaterialGraphExtendedRevisionFragment, MaterialGraphNodeFragment} from "@api"
import {SdkService} from "@common/services/sdk/sdk.service"
import {
    IMaterialNode,
    IMaterialConnection,
    IMaterialGraphManager,
    IMaterialGraph,
    materialGraphFromNodesAndConnections,
    ImageResource,
    DataObjectSchema,
    LoadImageResource,
    LoadImageResourceArgs,
} from "@cm/lib/materials/material-node-graph"
import {assertNever} from "@cm/lib/utils/utils"
import {TextureRevisionForMaterialGraphBatchApiCall} from "@common/services/material-graph/texture-revision-for-material-graph-batch-api-call"
import {DataObjectForMaterialGraphBatchApiCall} from "@common/services/material-graph/data-object-for-material-graph-batch-api-call"
import {MaterialRevisionForMaterialGraphBatchApiCall} from "@common/services/material-graph/material-revision-for-material-graph-batch-api-call"

type TextureRevisionDetails = Awaited<ReturnType<MaterialGraphService["sdk"]["gql"]["textureRevisionForMaterialGraph"]>>["textureRevision"]
type RelatedDataObjectDetails = Awaited<ReturnType<MaterialGraphService["sdk"]["gql"]["relatedDataObjectForMaterialGraph"]>>["dataObject"]["thumbnail"]

@Injectable({
    providedIn: "root",
})
export class MaterialGraphService implements IMaterialGraphManager {
    private sdk = inject(SdkService)
    private textureRevisionBatchCall = inject(TextureRevisionForMaterialGraphBatchApiCall)
    private legacyDataObjectBatchCall = inject(DataObjectForMaterialGraphBatchApiCall)
    private materialRevisionBatchCall = inject(MaterialRevisionForMaterialGraphBatchApiCall)

    private textureRevisionCache = new Map<number, TextureRevisionDetails>()
    private dataObjectCache = new Map<number, MaterialGraphDataObjectDetailsFragment>()
    private relatedDataObjectCache = new Map<string, RelatedDataObjectDetails>()

    private loadImageResource: LoadImageResource = async (args: LoadImageResourceArgs): Promise<ImageResource> => {
        let dataObject: MaterialGraphDataObjectDetailsFragment
        let widthCm: number
        let heightCm: number
        switch (args.type) {
            case "data-object": {
                let details = this.dataObjectCache.get(args.legacyId)
                if (!details) {
                    details = await this.legacyDataObjectBatchCall.fetch({legacyId: args.legacyId})
                    this.dataObjectCache.set(args.legacyId, details)
                }
                dataObject = details
                widthCm = args.width
                heightCm = args.height

                const validatedDataObject = DataObjectSchema.safeParse(dataObject)
                if (!validatedDataObject.success) throw Error(`Fetched data object not conforming to expected schema: ${JSON.stringify(dataObject)}`)

                const validatedRelatedObjects = z.array(DataObjectSchema).safeParse(details.related)
                if (!validatedRelatedObjects.success)
                    throw Error(`Fetched related data object not conforming to expected schema: ${JSON.stringify(details.related)}`)

                return {
                    metadata: {
                        heightCm: heightCm,
                        widthCm: widthCm,
                    },
                    mainDataObject: validatedDataObject.data,
                    relatedDataObjects: validatedRelatedObjects.data,
                }
            }
            case "texture-revision": {
                const textureRevision = {legacyId: args.legacyId}
                let details = this.textureRevisionCache.get(textureRevision.legacyId) // TODO correctly handle possibly pending requests
                if (!details) {
                    details = await this.textureRevisionBatchCall.fetch(textureRevision)
                    this.textureRevisionCache.set(textureRevision.legacyId, details)
                }
                dataObject = details.dataObject
                widthCm = details.width
                heightCm = details.height

                const validatedDataObject = DataObjectSchema.safeParse(dataObject)
                if (!validatedDataObject.success) throw Error(`Fetched data object not conforming to expected schema: ${JSON.stringify(dataObject)}`)

                const validatedRelatedObjects = z.array(DataObjectSchema).safeParse(details.dataObject.related)
                if (!validatedRelatedObjects.success)
                    throw Error(`Fetched related data object not conforming to expected schema: ${JSON.stringify(details.dataObject.related)}`)

                return {
                    metadata: {
                        heightCm: heightCm,
                        widthCm: widthCm,
                    },
                    mainDataObject: validatedDataObject.data,
                    relatedDataObjects: validatedRelatedObjects.data,
                }
            }
            default:
                assertNever(args)
        }
    }

    public async graphFromNodesAndConnections(
        nodes: IMaterialNode[],
        connections: IMaterialConnection[],
        revisionLegacyId: number,
        materialLegacyId: number,
        materialName: string,
    ): Promise<IMaterialGraph> {
        return materialGraphFromNodesAndConnections(nodes, connections, revisionLegacyId, materialLegacyId, materialName, this.loadImageResource.bind(this))
    }

    public convertNodeFromFragment(node: MaterialGraphNodeFragment): IMaterialNode {
        return {
            id: node.id,
            name: node.name,
            parameters: node.parameters,
            textureRevision: node.textureRevision?.legacyId,
            textureSetRevision: node.textureSetRevision
                ? {
                      id: node.textureSetRevision.id,
                      width: node.textureSetRevision.width,
                      height: node.textureSetRevision.height,
                      mapAssignments: node.textureSetRevision.mapAssignments.map((assignment) => ({
                          textureType: assignment.textureType,
                          dataObjectLegacyId: assignment.dataObject.legacyId,
                      })),
                  }
                : undefined,
        }
    }

    public convertConnectionFromFragment(connection: MaterialGraphConnectionFragment): IMaterialConnection {
        return {
            source: connection.source.id,
            sourceParameter: connection.sourceParameter,
            destination: connection.destination.id,
            destinationParameter: connection.destinationParameter,
        }
    }

    public async graphFromMaterialRevision(revisionDetails: MaterialGraphExtendedRevisionFragment): Promise<IMaterialGraph> {
        const materialDetailSchema = z.object({name: z.string(), legacyId: z.number()})
        const parsedMaterialDetails = materialDetailSchema.safeParse(revisionDetails.material)
        if (!parsedMaterialDetails.success) throw Error("Invalid material details")

        const _nodes = revisionDetails.nodes.map(this.convertNodeFromFragment)
        const _connections = revisionDetails.connections.map(this.convertConnectionFromFragment)

        return this.graphFromNodesAndConnections(
            _nodes,
            _connections,
            revisionDetails.legacyId,
            parsedMaterialDetails.data.legacyId,
            parsedMaterialDetails.data.name,
        )
    }

    public async graphFromMaterialRevisionId(revisionIdDetails: {legacyId: number}): Promise<IMaterialGraph> {
        const revision = await this.materialRevisionBatchCall.fetch(revisionIdDetails)
        if (!revision.material.name) throw new Error(`Name field not set for material ${revision.material.name}`)
        return this.graphFromMaterialRevision(revision)
    }

    public invalidateCache() {
        this.textureRevisionCache.clear()
        this.dataObjectCache.clear()
        this.relatedDataObjectCache.clear()
    }
}
