import {Component, Input, OnDestroy, ViewChild, ViewContainerRef} from "@angular/core"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {Operator} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {TilingCanvasComponent} from "@app/textures/texture-editor/tiling-canvas/tiling-canvas.component"
import {TextureType} from "@api"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

@Component({
    selector: "cm-operator-inspector",
    templateUrl: "./operator-inspector.component.html",
    styleUrls: ["./operator-inspector.component.scss"],
    standalone: true,
    imports: [InspectorSectionComponent, ToggleComponent],
})
export class OperatorInspectorComponent implements OnDestroy {
    @ViewChild("operatorPanelContainer", {read: ViewContainerRef}) operatorPanelContainer?: ViewContainerRef

    @Input() set disabled(value: boolean) {
        this._disabled = value
        this.setOperatorPanelAndToolbox(this._operatorToShow)
    }

    @Input() textureEditCanvas!: TilingCanvasComponent
    @Input() availableTextureTypes: TextureType[] = []

    @Input() set operator(value: Operator | null) {
        if (this._operatorToShow === value) {
            return
        }
        this._operatorToShow = value
        this.setOperatorPanelAndToolbox(this._operatorToShow)
    }

    constructor() {}

    ngOnDestroy(): void {
        this.setOperatorPanelAndToolbox(null)
    }

    get disabled(): boolean {
        return this._disabled
    }

    shouldApplyTo(textureType: TextureType): boolean {
        return this.operator?.shouldApplyTo(textureType) ?? false
    }

    setShouldApplyTo(textureType: TextureType, enabled: boolean) {
        if (!this.operator) {
            return
        }
        this.operator.setShouldApplyTo(textureType, enabled)
        this.operator.requestEvaluation.emit()
    }

    get showApplyToSelection(): boolean {
        return this.operator != null && !this.operator.flags.has("apply-to-all-texture-types")
    }

    get operator(): Operator | null {
        return this._operatorToShow
    }

    private setOperatorPanelAndToolbox(operator: Operator | null): void {
        if (!this.operatorPanelContainer) {
            return
        }
        this.operatorPanelContainer.clear()
        if (this.textureEditCanvas.toolbox) {
            this.textureEditCanvas.toolbox = null
        }
        if (!operator) {
            return
        }
        if (operator.panelComponentType) {
            const componentRef = this.operatorPanelContainer.createComponent(operator.panelComponentType)
            componentRef.setInput("operator", operator)
            componentRef.setInput("disabled", this.disabled)
        }

        if (this.disabled) return // Don't show canvas toolbox if editing disabled

        if (operator.canvasToolbox) {
            this.textureEditCanvas.toolbox = operator.canvasToolbox
        }
    }

    protected descriptorByTextureType = descriptorByTextureType

    private _disabled = false
    private _operatorToShow: Operator | null = null
}
