import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatPaginatorModule, PageEvent} from "@angular/material/paginator"
import {PagingService} from "@common/services/paging/paging.service"

@Component({
    selector: "cm-paginator",
    standalone: true,
    imports: [MatPaginatorModule],
    templateUrl: "./paginator.component.html",
    styleUrl: "./paginator.component.scss",
})
export class PaginatorComponent implements OnInit {
    destroyRef = inject(DestroyRef)
    paging = inject(PagingService)

    @Input() totalCount?: number
    @Input() page: number | null = null
    @Input() pageSize: number | null = null
    @Input() pageSizeOptions: number[] = [10, 20, 50, 100]

    @Output() pageChange = new EventEmitter<number>()
    @Output() pageSizeChange = new EventEmitter<number>()

    ngOnInit() {
        if (this.totalCount === undefined) {
            this.paging.totalCount$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((totalCount) => {
                this.totalCount = totalCount
            })
        }
    }

    async setPage(pageEvent: PageEvent) {
        if (this.pageChange.observed || this.pageSizeChange.observed) {
            this.pageSizeChange.emit(pageEvent.pageSize)
            this.pageChange.emit(pageEvent.pageIndex)
        } else {
            await this.paging.changePage(pageEvent.pageIndex)
            await this.paging.changePageSize(pageEvent.pageSize)
        }
    }
}
