import {Component, inject} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent} from "@common/components/filters/checkboxes-filter/checkboxes-filter.component"
import {PinnedFilterOptionComponent} from "@common/components/filters/pinned-filter-option/pinned-filter-option.component"
import {PinnedFilterComponent} from "@common/components/filters/pinned-filter/pinned-filter.component"
import {TagSearchFilterComponent} from "@common/components/filters/tag-search-filter/tag-search-filter.component"
import {ItemFiltersComponent} from "@common/components/item/item-filters/item-filters.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {UsersService} from "@common/services/users/users.service"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {MaterialsGridComponent} from "@platform/components/materials/materials-grid/materials-grid.component"
import {NativeCheckboxComponent} from "@common/components/inputs/native/native-checkbox/native-checkbox.component"
import {FiltersService} from "@common/services/filters/filters.service"

@Component({
    selector: "cm-materials-page",
    standalone: true,
    imports: [
        SidebarLayoutComponent,
        CheckboxesFilterComponent,
        PinnedFilterComponent,
        TagSearchFilterComponent,
        ItemFiltersComponent,
        PinnedFilterOptionComponent,
        RouterOutlet,
        MaterialsGridComponent,
        NativeCheckboxComponent,
    ],
    templateUrl: "./materials-page.component.html",
    styleUrl: "./materials-page.component.scss",
})
export class MaterialsPageComponent {
    readonly Enums = Enums
    readonly Labels = Labels

    can = inject(PermissionsService)
    filters = inject(FiltersService)
    organizations = inject(OrganizationsService)
    users = inject(UsersService)

    async toggleWithoutScan(scannedByNone: boolean) {
        if (scannedByNone) {
            await this.filters.updateCriteria("scannedByNone", ["1"])
        } else {
            await this.filters.updateCriteria("scannedByNone", [])
        }
    }
}
