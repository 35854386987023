import {GetParameters, NodeParameters} from "@cm/lib/graph-system/node-graph"
import {GetAll, getAll} from "@cm/lib/graph-system/utils"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {DeclareNodeTS} from "@cm/lib/graph-system/declare-node"

export function lambda<ReturnType, ParameterType extends NodeParameters>(
    parameters: GetParameters<Context, ParameterType>,
    fn: (args: {parameters: GetAll<ParameterType>; context: Context}) => Promise<ReturnType>,
) {
    class LambdaNode extends DeclareNodeTS<ReturnType, Context, ParameterType, Record<string, unknown>>(
        {
            run: async ({get, parameters, context}) => {
                return await fn({parameters: await getAll(parameters as GetParameters<unknown, ParameterType>, get), context})
            },
        },
        {label: "lambda"},
    ) {}

    return new LambdaNode(parameters)
}
