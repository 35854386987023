import {MaterialSocket} from "@material-editor/models/material-socket"
import {NodeSettings} from "@material-editor/models/material-nodes"

export const VectorMathInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "input", valueType: "vector", label: "Vector"},
    vector001: {paramType: "socket", id: "Vector_001", type: "input", valueType: "vector", label: "Vector"},
    vector002: {paramType: "socket", id: "Vector_002", type: "input", valueType: "output", label: "Vector"},
    scale: {paramType: "socket", id: "Scale", type: "input", valueType: "scalar", label: "Scale"},
}

export const VectorMathOutputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "output", valueType: "output", label: "Vector"},
    value: {paramType: "socket", id: "Value", type: "output", valueType: "output", label: "Value"},
}

export const VectorMathOperation: NodeSettings = {
    id: "internal.operation",
    valueType: "string",
    options: [
        {label: "Add", value: "ADD"},
        {label: "Subtract", value: "SUBTRACT"},
        {label: "Multiply", value: "MULTIPLY"},
        {label: "Divide", value: "DIVIDE"},
        {label: "Scale", value: "SCALE"},
        {label: "Distance", value: "DISTANCE"},
        {label: "Length", value: "LENGTH"},
        {label: "Normalize", value: "NORMALIZE"},
    ],
}
