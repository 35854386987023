<cm-infinite-list [count]="totalCount" [intersectionStatusChange]="pageFilledStatusChange" (createItemEvent)="openNewItemDialog()" class="cm-items-list">
    <cm-list-info cm-list-header [parentList]="this" [count]="totalCount" (createItemEvent)="openNewItemDialog()"></cm-list-info>
    @for (item of items; track item) {
        @if (item.data; as hdri) {
            <cm-entity-card @fadeInCard [$entity]="hdri" [entityLink]="[hdri.id]"></cm-entity-card>
        } @else if (item.placeholder) {
            <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
        } @else if (item.error) {
            <cm-card-error @fadeInPlaceholder></cm-card-error>
        }
    }
</cm-infinite-list>
<ng-template #newItemDialog>
    <div class="cm-new-item-dialog">
        <h2 mat-dialog-title>New HDRI</h2>
        <mat-dialog-content>
            <form class="cm-new-item-form">
                @if (userIsStaff) {
                    <mat-form-field appearance="fill">
                        <mat-select [(ngModel)]="newItemData.organizationId" placeholder="Organization" name="Organization">
                            @for (organizationLabel of organizations.$filterOptions(); track organizationLabel) {
                                <mat-option [value]="organizationLabel.state">{{ organizationLabel.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
                <mat-form-field appearance="fill">
                    <input matInput placeholder="Name" [(ngModel)]="newItemData.name" name="mName" />
                </mat-form-field>
            </form>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button [mat-dialog-close]="newItemData" [disabled]="!newItemData.name || !newItemData.organizationId">Create</button>
            <button mat-button mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </div>
</ng-template>
