<cm-inspector-section>
    <ng-container cm-title> Config Variant </ng-container>
    <ng-container cm-content>
        <cm-input-container>
            <ng-container cm-inline-label> ID: </ng-container>
            <ng-container cm-input>
                <cm-string-input [(value)]="node.id" (valueChange)="updateNode(node)" [validate]="isValidId"> </cm-string-input>
            </ng-container>
        </cm-input-container>
        <cm-input-container>
            <ng-container cm-inline-label> Name: </ng-container>
            <ng-container cm-input>
                <cm-string-input [(value)]="node.name" (valueChange)="updateNode(node)"> </cm-string-input>
            </ng-container>
        </cm-input-container>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title> Icon </ng-container>
    <ng-container cm-content>
        <div class="cm-config-variant-settings">
            <div class="cm-icon-container">
                <cm-drop-files (filesDropped)="handleDroppedIconFile($event)" [subtitle]="'or click to browse for a file'">
                    <img
                        alt="Config icon"
                        class="cm-icon-img"
                        [src]="iconDataObject ? iconDataObject.thumbnail?.downloadUrl ?? Settings.DATA_OBJECT_PROCESSING_URL : Settings.IMAGE_NOT_AVAILABLE_URL"
                    />
                    @if (iconDataObject) {
                        <div class="cm-delete-button" mat-icon-button matTooltip="Delete icon" matTooltipPosition="below" (click)="deleteIcon()">
                            <i class="far fa-trash"></i>
                        </div>
                    }
                </cm-drop-files>
            </div>
            <cm-input-container>
                <ng-container cm-inline-label> Color: </ng-container>
                <ng-container cm-input>
                    <div class="cm-color-input-container" [style.background-color]="iconColor">
                        <input class="cm-color-input" type="color" [(ngModel)]="iconColor" />
                    </div>
                </ng-container>
            </cm-input-container>
        </div>
    </ng-container>
</cm-inspector-section>
