import {Component, EventEmitter, Output} from "@angular/core"
import {MenuDialogComponent} from "@common/components/dialogs/menu-dialog/menu-dialog.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextComponent} from "@common/components/inputs/native/native-input-text/native-input-text.component"

@Component({
    standalone: true,
    selector: "cm-sales-enquiry-dialog",
    templateUrl: "./sales-enquiry-dialog.component.html",
    styleUrls: ["./sales-enquiry-dialog.component.scss"],
    imports: [InputContainerComponent, MenuDialogComponent, NativeInputTextComponent],
})
export class SalesEnquiryDialogComponent {
    @Output() submitEmail: EventEmitter<SalesEnquiryResult> = new EventEmitter<SalesEnquiryResult>()
    @Output() close: EventEmitter<void> = new EventEmitter<void>()
    result: SalesEnquiryResult = new SalesEnquiryResult()
    showErrors = false

    submitEmailForm(form: HTMLFormElement) {
        if (form.checkValidity()) {
            this.submitEmail.emit(this.result)
        } else {
            this.showErrors = true
        }
    }
}

export class SalesEnquiryResult {
    name = ""
    email = ""
    companyName = ""
    phoneNumber = ""
}
