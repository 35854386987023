import {HalContext} from "@common/models/hal/hal-context"
import {WebGl2Context, WebGl2PainterImageCompositor} from "@common/models/webgl2"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor/index"

export const createHalPainterImageCompositor = (context: HalContext, compositingFunction: string): HalPainterImageCompositor => {
    if (context instanceof WebGl2Context) {
        return new WebGl2PainterImageCompositor(context, compositingFunction)
    }
    throw Error("Unsupported context")
}
