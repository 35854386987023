import {RectangleRegion} from "@common/helpers/canvas/canvas-base-toolbox/std-toolbox-items/rectangle-region"
import {TilingHint} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/toolbox/toolbox-items/tiling-hint"
import {takeUntil} from "rxjs"
import {
    TilingResultOverlayItem,
    TilingResultOverlayItemDesc,
} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/toolbox/toolbox-items/tiling-result-overlay-item"
import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorAutoTiling} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/operator-auto-tiling"
import {Area, AutoTilingService} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/service/auto-tiling.service"
import * as AutoTilingNodes from "app/textures/texture-editor/operator-stack/operators/auto-tiling/service/auto-tiling-nodes"
import {GlobalAppInjector} from "@common/helpers/app-injector/app-injector"
import * as paper from "paper"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"

export class TilingAutoGenerateToolbox extends OperatorToolboxBase<OperatorAutoTiling> {
    private _tilingArea: RectangleRegion | null = null
    readonly tilingHints: TilingHint[] = []

    private _tilingAreaActive = false
    private _itemsEnabled = true

    private tilingService: AutoTilingService

    constructor(operator: OperatorAutoTiling) {
        super(operator, SelectionMode.SingleSelect, false)

        if (!GlobalAppInjector.injector) {
            throw new Error("GlobalAppInjector not set")
        }

        this.tilingService = GlobalAppInjector.injector.get(AutoTilingService)

        this.tilingService.alignmentDataReceived.pipe(takeUntil(this.unsubscribe)).subscribe((alignmentData) => {
            this.showTilingAlignment(alignmentData)
        })
        this.tilingService.patternHintsReceived.pipe(takeUntil(this.unsubscribe)).subscribe((patternHints) => {
            this.replaceTilingHints(patternHints)
        })

        if (this.operator.cutoutArea) {
            this.setTilingArea(this.operator.cutoutArea, false)
            this.tilingCutoutActive = true
        }
        this.operator.tilingHints.forEach((hint) => this.addTilingHint(hint.x1, hint.y1, hint.x2, hint.y2, hint.radius, false))
    }

    override remove() {
        super.remove()
        this.tilingService.processing?.destroy()
    }

    setItemsEnabled(enabled: boolean) {
        this._itemsEnabled = enabled
        this.tilingHints.forEach((hint) => (hint.disabled = !enabled))
        if (this._tilingArea) {
            this._tilingArea.disabled = !enabled
        }
    }

    // get selectedTextureType(): TextureType {
    //     return this.operator.callback.textureEditorData.textureTypeSpecific.textureType
    // }

    // private get textureRevision(): TextureRevisionDataFragment | null {
    //     return this.operator.callback.textureEditorData.textureTypeSpecific.sourceTextureRevision
    // }

    // startTiling(
    //     mode: Tiling.Mode,
    //     textureEdits: TextureEditNodes.TextureEdits | undefined, // TODO this is only here to restore edits for local tiling; remove when local tiling is removed
    //     textureSetLegacyId: number,
    // ) {
    //     if (!this.textureRevision) {
    //         throw Error("Texture revision not set")
    //     }
    //     return this.tilingService.startTiling(
    //         mode,
    //         this.operator.node.params,
    //         this.getTilingArea(),
    //         this.operator.node.hints,
    //         this.operator.callback.customerLegacyId,
    //         textureEdits,
    //         textureSetLegacyId,
    //     )
    // }

    // Tiling hints
    addTilingHint(x1: number, y1: number, x2: number, y2: number, radius: number, updateOperator = true) {
        const newHint = new TilingHint(this, x1, y1, x2, y2, radius)
        newHint.disabled = !this._itemsEnabled
        this.tilingHints.push(newHint)
        newHint.itemRemove.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.onTilingHintRemoved(newHint))
        newHint.changed.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.onTilingHintChanged(newHint))
        if (updateOperator) {
            this.updateOperatorTilingHints()
        }
    }

    clearTilingHints() {
        this.tilingHints
            .slice()
            .reverse()
            .forEach((x) => x.remove())
        this.clearTilingResults()
    }

    private onTilingHintRemoved(newHint: TilingHint): void {
        this.tilingHints.splice(this.tilingHints.indexOf(newHint), 1)
        this.updateOperatorTilingHints()
    }

    private onTilingHintChanged(_newHint: TilingHint): void {
        this.updateOperatorTilingHints()
    }

    private onTilingAreaChanged() {
        this.updateOperatorTilingHints()
    }

    private updateOperatorTilingHints() {
        this.operator.cutoutArea = this.getTilingArea()
        this.operator.tilingHints = this.tilingHints.map((x) => x.getCoords())
    }

    private clearTilingResults() {
        this.tilingResultItems.forEach((x) => x.remove())
        this.tilingResultItems.length = 0
    }

    private addTilingResultOverlay(itemDesc: TilingResultOverlayItemDesc) {
        const cutoutRegionGrid: paper.Point = new paper.Point(0, 0)
        if (this._tilingAreaActive) {
            const tilingArea = this.getTilingArea()
            if (!tilingArea) {
                throw Error("Tiling area not set")
            }
            cutoutRegionGrid.x = tilingArea.topLeftX
            cutoutRegionGrid.y = tilingArea.topLeftY
        }
        const toolboxItem = new TilingResultOverlayItem(this, itemDesc, cutoutRegionGrid)
        this.tilingResultItems.push(toolboxItem)
    }

    get tilingCutoutActive() {
        return this._tilingAreaActive
    }

    set tilingCutoutActive(value: boolean) {
        this._tilingAreaActive = value
        if (value && !this._tilingArea) {
            if (!this.canvasBase) {
                throw Error("canvasBase not set")
            }
            const border = 300
            this.setTilingArea({
                topLeftX: border / 2,
                topLeftY: border / 2,
                width: this.canvasBase.canvasBounds.width - border,
                height: this.canvasBase.canvasBounds.height - border,
            })
        }
        if (this._tilingArea) {
            this._tilingArea.visible = value
            this._tilingArea.selected = value && this._itemsEnabled
        }
    }

    toggleCutoutActive() {
        this.tilingCutoutActive = !this.tilingCutoutActive
    }

    // onAddTilingHintClick(): void {
    //     if (this.tilingHints.length === 1) {
    //         this.addPerpendicularTilingHint(this.tilingHints[0])
    //     } else {
    //         const textureRevision = this.textureRevision
    //         if (!textureRevision) {
    //             throw Error("Texture revision not set")
    //         }
    //         if (!textureRevision.dataObject.width || !textureRevision.dataObject.height) {
    //             throw Error("Texture revision data-object width/height not set")
    //         }
    //         const markerWidth: number = 0.7 * textureRevision.dataObject.width
    //         const x1: number = Math.round(textureRevision.dataObject.width / 2 - markerWidth / 2)
    //         const x2: number = Math.round(x1 + markerWidth)
    //         const y: number = Math.round(textureRevision.dataObject.height / 4)
    //         this.addTilingHint(x1, y, x2, y, 200)
    //     }
    // }

    // private addPerpendicularTilingHint(referenceHint: TilingHint): void {
    //     const textureRevision = this.textureRevision
    //     if (!textureRevision) {
    //         throw Error("Texture revision not set")
    //     }
    //     if (!textureRevision.dataObject.width || !textureRevision.dataObject.height) {
    //         throw Error("Texture revision data-object width/height not set")
    //     }
    //     const referenceHintCoords = referenceHint.getCoords()
    //     const refX1: number = referenceHintCoords.x1
    //     const refY1: number = referenceHintCoords.y1
    //     const refX2: number = referenceHintCoords.x2
    //     const refY2: number = referenceHintCoords.y2
    //     const refCenterX = (refX1 + refX2) / 2
    //     const refCenterY = (refY1 + refY2) / 2
    //     const newCenterX = Math.round(textureRevision.dataObject.width * 0.2)
    //     const newCenterY = Math.round(textureRevision.dataObject.height * 0.5)
    //     const x1: number = newCenterX - (refY1 - refCenterY)
    //     const y1: number = newCenterY + (refX1 - refCenterX)
    //     const x2: number = newCenterX - (refY2 - refCenterY)
    //     const y2: number = newCenterY + (refX2 - refCenterX)
    //     this.addTilingHint(x1, y1, x2, y2, referenceHintCoords.radius)
    // }

    // async loadTilingParams(): Promise<void> {
    //     if (!this.textureRevision) {
    //         throw Error("Texture revision not set")
    //     }
    //     const tilingGraph = this.textureRevision.getTilingGraph()
    //     if (!tilingGraph) {
    //         throw Error("Tiling graph not set")
    //     }
    //     const graphUrl = tilingGraph.getOriginalFileUrl()
    //     const graph = await (await fetch(graphUrl)).json()
    //     const parsedGraph = this.tilingService.parseTilingGraph(graph)
    //     const params = parsedGraph.tilingParams

    //     // Generic
    //     this.operator.tilingParams.patternType = params.patternType
    //     this.operator.tilingParams.processingMode = params.processingMode
    //     this.operator.tilingParams.filterMode = params.filterMode
    //     this.operator.tilingParams.featureWeighting = params.featureWeighting
    //     this.operator.tilingParams.blendingBorder = params.blendingBorder
    //     this.operator.tilingParams.maxBlendingRadius = params.maxBlendingRadius
    //     this.operator.tilingParams.gradientCorrection = params.gradientCorrection

    //     // Filtering
    //     this.operator.tilingParams.filterMode = params.filterMode
    //     this.operator.tilingParams.filterRadius = params.filterRadius

    //     // Alignment
    //     this.operator.tilingParams.edgeAlignmentWindowSize = params.edgeAlignmentWindowSize
    //     this.operator.tilingParams.edgeAlignmentSmoothingIterations = params.edgeAlignmentSmoothingIterations
    //     this.operator.tilingParams.alignIndividualMaps = params.alignIndividualMaps

    //     // Tiling hints
    //     this.clearTilingHints()
    //     if (parsedGraph.markerCoordinates) {
    //         parsedGraph.markerCoordinates.forEach((markerCoordinates) => {
    //             this.addTilingHint(...markerCoordinates)
    //         })
    //     }

    //     // Cut-out area
    //     this._tilingAreaActive = false
    //     if (parsedGraph.tilingArea) {
    //         this._tilingAreaActive = true
    //         // this.changeDetector.detectChanges()
    //         this.setTilingArea(parsedGraph.tilingArea)
    //     }

    //     // Switch to texture revision
    //     throw Error("Not implemented")
    //     // const textureRevision = this.textureMapCollection.texture.revisions.find((revision) => revision.number === parsedGraph.textureSetRevisionNumber)
    //     // if (textureRevision == null) {
    //     //     this.notification.showError(`Cannot find texture revision (${parsedGraph.textureSetRevisionNumber}) which was used for the tiling.`)
    //     // } else {
    //     //     this.requestShowTextureRevision.emit(textureRevision)
    //     // }
    // }

    private setTilingArea(tilingArea: Area, updateOperator = true): void {
        if (!this._tilingArea) {
            this._tilingArea = new RectangleRegion(this, tilingArea.topLeftX, tilingArea.topLeftY, tilingArea.width, tilingArea.height)
            this._tilingArea.changed.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.onTilingAreaChanged())
        }
        this._tilingArea.sendToBack() // we always send the cut-out region to the background that it doesn't cover tiling markers
        this._tilingArea.visible = this._tilingAreaActive
        this._tilingArea.disabled = !this._itemsEnabled
        this._tilingArea.selected = !this._tilingArea.disabled
        if (updateOperator) {
            this.updateOperatorTilingHints()
        }
    }

    getTilingArea(): Area | undefined {
        if (!this._tilingAreaActive) {
            return undefined
        }
        if (!this._tilingArea) {
            throw Error("Internal error: cut-out region not set, yet it is active")
        }
        const rect = this._tilingArea.getRect()
        return {
            topLeftX: rect[0],
            topLeftY: rect[1],
            width: rect[2] - rect[0],
            height: rect[3] - rect[1],
        }
    }

    private replaceTilingHints(hints: AutoTilingNodes.PatternHints) {
        this.clearTilingHints()
        for (const marker of hints.markers) {
            this.addTilingHint(...marker)
        }
    }

    private showTilingAlignment(info: AutoTilingNodes.AlignmentData) {
        this.clearTilingResults()
        const _showVector = (origin: [number, number], direction: [number, number], color: string) => {
            this.addTilingResultOverlay({
                type: "path",
                points: [origin, [origin[0] + direction[0], origin[1] + direction[1]]],
                color,
            })
        }
        const showLine = (points: [number, number][], color: string) => {
            this.addTilingResultOverlay({
                type: "path",
                points,
                color,
            })
        }
        const showPoint = (point: [number, number], color: string) => {
            this.addTilingResultOverlay({type: "point", point, color})
        }
        const _base_origin: [number, number] = [500, 500]
        // showVector(base_origin, info.src_base_x, 'red');
        // showVector(base_origin, info.src_base_y, 'green');
        for (const point of info.src_pts) {
            showPoint(point, "magenta")
        }
        if (info.lines) {
            for (const indices of info.lines) {
                showLine(
                    indices.map((idx) => info.src_pts[idx]),
                    "magenta",
                )
            }
        }
    }

    private tilingResultItems: TilingResultOverlayItem[] = []
}
