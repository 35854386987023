import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr, ImagePtrArray, ImagePtrReassignable} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {Size2Like} from "@cm/lib/math/size2"
import {Box2Like} from "@cm/lib/math/box2"
import {imageOpCopyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-copy-region"

export type ParameterType = {
    sourceImage: ImagePtr
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    maxLevels?: number // defaults to full pyramid down to 1x1
    minResolution?: Size2Like // defaults to 1x1
}

export type ReturnType = ImagePtrArray

export const imageOpCreateImagePyramid: ImageOpType<ParameterType, ReturnType> = {
    name: "CreateImagePyramid",

    WebGL2: async ({context, parameters: {sourceImage, sourceRegion, maxLevels, minResolution}}) => {
        const halDownSample2x2 = await context.getOrCreateImageCompositor(`
            vec4 computeColor(ivec2 targetPixel) {
                return (texelFetch0(targetPixel * 2 + ivec2(0, 0), ADDRESS_MODE_CLAMP_TO_EDGE) +
                        texelFetch0(targetPixel * 2 + ivec2(1, 0), ADDRESS_MODE_CLAMP_TO_EDGE) +
                        texelFetch0(targetPixel * 2 + ivec2(0, 1), ADDRESS_MODE_CLAMP_TO_EDGE) +
                        texelFetch0(targetPixel * 2 + ivec2(1, 1), ADDRESS_MODE_CLAMP_TO_EDGE)) * 0.25;
            }
        `)
        using sourceImageToUse = new ImagePtrReassignable(sourceImage)

        if (sourceRegion) {
            using croppedSourceImage = await imageOpCopyRegion.WebGL2({
                context,
                parameters: {sourceImage, sourceRegion},
            })
            sourceImageToUse.set(croppedSourceImage)
        } else {
            const sourceDescriptor = await context.getImageDescriptor(sourceImage)
            sourceRegion ??= {x: 0, y: 0, width: sourceDescriptor.width, height: sourceDescriptor.height}
        }
        if (maxLevels !== undefined) {
            if (maxLevels < 1) {
                throw new Error("maxLevels must be at least 1")
            }
            if (minResolution !== undefined) {
                throw new Error("minResolution is not allowed when maxLevels is set")
            }
            const maxLevelScaling = 2 ** (maxLevels - 1)
            minResolution = {
                width: Math.ceil(sourceRegion.width / maxLevelScaling),
                height: Math.ceil(sourceRegion.height / maxLevelScaling),
            }
        }
        if (!minResolution) {
            minResolution = {width: 1, height: 1}
        }
        const resultImages = new ImagePtrArray(sourceImageToUse)
        using currentSourceWebGL2 = ImagePtrReassignable.promoteSmartPtr(await context.getImage(sourceImageToUse))
        while (currentSourceWebGL2.ref.descriptor.width > minResolution.width || currentSourceWebGL2.ref.descriptor.height > minResolution.height) {
            using target = await context.createImage({
                width: Math.max(minResolution.width, Math.ceil(sourceRegion.width / 2)),
                height: Math.max(minResolution.height, Math.ceil(sourceRegion.height / 2)),
                format: currentSourceWebGL2.ref.descriptor.format,
                channelLayout: currentSourceWebGL2.ref.descriptor.channelLayout,
                isSRGB: currentSourceWebGL2.ref.descriptor.isSRGB,
            })
            using targetWebGL2 = await context.getImage(target)
            await halDownSample2x2.paint(targetWebGL2.ref.halImage, currentSourceWebGL2.ref.halImage)
            resultImages.push(target)
            currentSourceWebGL2.set(targetWebGL2)
            sourceRegion = {x: 0, y: 0, width: targetWebGL2.ref.descriptor.width, height: targetWebGL2.ref.descriptor.height}
        }
        return resultImages
    },

    ImgProc: async () => {
        throw new Error("Not implemented")
    },
}
