<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Template Instance </ng-container>
    <ng-container cm-content>
        <cm-card [defaultThumbnail]="false">
            <ng-container cm-thumbnail>
                @if (thumbnailObject(); as thumbnailData) {
                    <cm-data-object-thumbnail [dataObjectId]="thumbnailData.id"></cm-data-object-thumbnail>
                } @else {
                    @if (thumbnailUrl(); as thumbnailData) {
                        <cm-thumbnail [url]="thumbnailData"></cm-thumbnail>
                    }
                }
            </ng-container>
            <ng-container cm-title>
                @if (templateRevisionId() !== undefined) {
                    {{ title() }}
                } @else {
                    <cm-template-node [node]="template()" [noDrag]="true" #dragImage></cm-template-node>
                }
            </ng-container>
        </cm-card>
        <cm-value-slot [node]="node()" [key]="'id'" [label]="'ID'" [validate]="isValidExternalId"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <div class="cm-template-revision-settings">
            @if (templateReference(); as templateReference) {
                <cm-value-slot
                    [node]="templateReference"
                    [key]="'templateRevisionId'"
                    [label]="'Revision'"
                    [selectionPossibilities]="revisionPossibilities()"
                ></cm-value-slot>

                @if (latestTemplateRevisionId() !== undefined && latestTemplateRevisionId() !== templateRevisionId()) {
                    <cm-button class="cm-action-button" (click)="updateToLatestTemplateRevision()">
                        <ng-container cm-text> <i class="far fa-arrow-up"></i> Update </ng-container>
                    </cm-button>
                } @else {
                    <div class="cm-latest"><i class="far fa-check"></i>Latest</div>
                }
            }
        </div>
    </ng-container>
</cm-inspector-section>

@if (isActive()) {
    <cm-inspector-section>
        <ng-container cm-title> Inputs </ng-container>
        <ng-container cm-content>
            @if (inputs().length === 0) {
                No inputs
            } @else {
                <div class="cm-template-settings">
                    @for (input of inputs(); track input.key) {
                        <cm-value-slot
                            [node]="parameters().parameters"
                            [key]="input.key"
                            [label]="input.label"
                            [icon]="input.icon"
                            [schema]="input.schema"
                            [selectionPossibilities]="input.selectionPossibilities"
                            [topLabel]="true"
                            [overwrittenValue]="input.overwrittenValue"
                            (updatedOverwrittenValue)="updateOverwrittenValue(input.key, $event)"
                        ></cm-value-slot>
                    }
                </div>
            }
        </ng-container>
    </cm-inspector-section>

    <cm-inspector-section>
        <ng-container cm-title> Outputs </ng-container>
        <ng-container cm-content>
            @for (output of outputs(); track output.parameters.outputId) {
                <cm-template-instance-output [node]="output"></cm-template-instance-output>
            }
            @if (outputs().length === 0) {
                No outputs
            }
        </ng-container>
    </cm-inspector-section>
} @else {
    <cm-inspector-section>
        <ng-container cm-title> Inputs / Outputs </ng-container>
        <ng-container cm-content>Instance is currently not active </ng-container>
    </cm-inspector-section>
}
