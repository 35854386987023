import {Component, input, output} from "@angular/core"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextComponent} from "@common/components/inputs/native/native-input-text/native-input-text.component"
import {SectionComponent} from "@common/components/item"

type ItemType = {articleId?: string}

@Component({
    selector: "cm-article-id-section",
    standalone: true,
    imports: [SectionComponent, InputContainerComponent, NativeInputTextComponent],
    templateUrl: "./article-id-section.component.html",
    styleUrl: "./article-id-section.component.scss",
})
export class ArticleIdSectionComponent {
    $item = input<ItemType | null | undefined>(null)
    $label = input<string>("Article ID:")
    $canEdit = input<boolean>(false)
    $onChanged = output<ItemType>()
}
