import {Component, inject, Input, OnInit, signal} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule, MatOptionSelectionChange} from "@angular/material/core"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {BasicTagInfoFragment, ContentTypeModel, TagType} from "@api"
import {IsLoadingDirective} from "@app/common/directives"
import {UtilsService} from "@legacy/helpers/utils"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {TagsService} from "@common/services/tags/tags.service"

@Component({
    selector: "cm-tag-select",
    standalone: true,
    imports: [MatInputModule, MatSelectModule, MatFormFieldModule, MatOptionModule, FormsModule, IsLoadingDirective, MatButtonModule],
    templateUrl: "./tag-select.component.html",
    styleUrl: "./tag-select.component.scss",
})
export class TagSelectComponent implements OnInit {
    @Input({required: true}) assignedToId!: string
    @Input({required: true}) contentTypeModel!: ContentTypeModel
    @Input() organizationId?: string
    @Input() type?: TagType
    @Input() label = "Tags"
    @Input() canEdit = true
    @Input() includeTagsWithNoOrganization = false

    tags = inject(TagsService)
    notifications = inject(NotificationsService)
    sdk = inject(SdkService)
    utils = inject(UtilsService)

    options?: BasicTagInfoFragment[]
    $assigned = signal<{tagId: string; tagAssignmentId: string}[]>([])

    ngOnInit() {
        void this.refresh()
    }

    async refresh() {
        const tags = await this.tags.all
        this.options = tags.filter((tag) => {
            if (this.type) {
                if (tag.type !== this.type) {
                    return false
                }
            }
            if (this.organizationId) {
                if (!tag.organization && this.includeTagsWithNoOrganization) return true
                if (tag.organization?.id !== this.organizationId) {
                    return false
                }
            }
            return true
        })
        const assignments = await this.tags.assignments(this.assignedToId, this.contentTypeModel)
        const assignedOptions = assignments
            .map((assignment) => ({
                tagId: assignment.tag.id,
                tagAssignmentId: assignment.id,
            }))
            .filter((assigned) => this.options?.some((option) => option.id === assigned.tagId))
        this.$assigned.set(assignedOptions)
    }

    toggleTag(toggleEvent: MatOptionSelectionChange<BasicTagInfoFragment>) {
        if (toggleEvent.isUserInput) {
            const tagId = toggleEvent.source.value.id
            this.notifications.withUserFeedback(
                async () => {
                    const assignment = this.$assigned().find((assigned) => assigned.tagId === tagId)
                    if (assignment) {
                        await this.sdk.gql.tagSelectUnassign({tagAssignmentId: assignment.tagAssignmentId})
                        this.$assigned.update((tags) => {
                            return tags.filter((assigned) => assigned.tagId !== tagId)
                        })
                    } else {
                        const {createTagAssignment: tagAssignment} = await this.sdk.gql.tagSelectAssign({
                            tagId,
                            contentTypeModel: this.contentTypeModel,
                            objectId: this.assignedToId,
                        })
                        this.$assigned.update((tags) => {
                            return [...tags, {tagAssignmentId: tagAssignment.id, tagId}]
                        })
                    }
                },
                {
                    success: "Tag updated",
                    error: "Failed to update tag",
                },
            )
        }
    }

    compareAssignments(option: BasicTagInfoFragment, selection: {tagId: string}): boolean {
        return option.id === selection.tagId
    }
}
