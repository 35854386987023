<cm-input-container>
    <ng-container cm-inline-label>
        <i class="far fa-search"></i>
    </ng-container>
    <ng-container cm-input>
        <cm-native-input-text
            [value]="searchText"
            [placeholder]="placeholder"
            (focus)="focus.emit($event)"
            (blur)="blur.emit($event)"
            (keyUp)="searchTextChanged.next($event)"
        >
        </cm-native-input-text>
    </ng-container>
</cm-input-container>
