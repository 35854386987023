import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {color} from "@src/materials/types"
import {threeRGBColorNode, threeConvert, threeValueNode} from "@src/materials/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class Gamma extends DeclareMaterialNode(
    {
        returns: z.object({color: materialSlots}),
        inputs: z.object({color: materialSlots.optional(), gamma: materialSlots.optional()}),
        parameters: z.object({color: color.optional(), gamma: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const colorValue = (await get(inputs.color)) ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const gammaValue = (await get(inputs.gamma)) ?? threeConvert(parameters.gamma, threeValueNode) ?? threeValueNode(1)
            return {color: THREENodes.pow(colorValue, gammaValue)}
        },
    },
) {}
