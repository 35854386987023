/** This worker is adapted from
 * https://github.com/webpack/webpack/discussions/14066 and
 * https://github.com/webpack/webpack/discussions/14648
 * to circumvent CORS restrictions when loading a web worker from a different domain. For workers, this is always strictly enforced
 * and cannot be circumvented. Using a blob url seems to be a widely used workaround.
 */
export class CorsWorker {
    private readonly worker: Worker

    constructor(url: string | URL, workerOptions?: WorkerOptions) {
        const objectURL = URL.createObjectURL(
            new Blob([`importScripts(${JSON.stringify(url.toString())});`], {
                type: "application/javascript",
            }),
        )
        this.worker = new Worker(objectURL, workerOptions)
        URL.revokeObjectURL(objectURL)

        // This also works:
        // const dataURL = `data:application/javascript,importScripts(${JSON.stringify(url.toString())});`
        // this.worker = new Worker(dataURL, workerOptions)
    }

    getWorker() {
        return this.worker
    }
}
