<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Rigid Relation Inspector </ng-container>
    <ng-container cm-content>
        <div class="cm-target-settings">
            <cm-value-slot [node]="node()" [key]="'targetA'" [label]="'Align from'"></cm-value-slot>
            @if (node().parameters.targetA; as targetA) {
                @if (isObject(targetA)) {
                    <cm-template-tree-object-transform [object]="targetA"></cm-template-tree-object-transform>
                }
            }
        </div>
        <div class="cm-target-settings">
            <cm-value-slot [node]="node()" [key]="'targetB'" [label]="'Align to'"></cm-value-slot>
            @if (node().parameters.targetB; as targetB) {
                @if (isObject(targetB)) {
                    <cm-template-tree-object-transform [object]="targetB"></cm-template-tree-object-transform>
                }
            }
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Translation </ng-container>
    <ng-container cm-content>
        @if (translation(); as translation) {
            <div class="cm-connection-settings">
                <cm-value-slot
                    [node]="translation"
                    [key]="'x'"
                    [label]="'Offset X (horizontal)'"
                    [selectionPossibilities]="translationTypePossibilites"
                    [isSelected]="translationTypeSelected"
                    [topLabel]="true"
                ></cm-value-slot>
                @if (isR1Variable(translation.parameters.x)) {
                    <cm-value-slot [node]="translation.parameters.x" [key]="'default'" [label]="'Default'"></cm-value-slot>
                    <cm-value-slot [node]="translation.parameters.x" [key]="'range'" [subKey]="0" [label]="'Min'"></cm-value-slot>
                    <cm-value-slot [node]="translation.parameters.x" [key]="'range'" [subKey]="1" [label]="'Max'"></cm-value-slot>
                } @else {
                    <cm-value-slot [node]="translation" [key]="'x'" [label]="'Offset'" [schema]="numberSchema"></cm-value-slot>
                }
            </div>

            <div class="cm-connection-settings">
                <cm-value-slot
                    [node]="translation"
                    [key]="'y'"
                    [label]="'Offset Y (vertical)'"
                    [selectionPossibilities]="translationTypePossibilites"
                    [isSelected]="translationTypeSelected"
                    [topLabel]="true"
                ></cm-value-slot>
                @if (isR1Variable(translation.parameters.y)) {
                    <cm-value-slot [node]="translation.parameters.y" [key]="'default'" [label]="'Default'"></cm-value-slot>
                    <cm-value-slot [node]="translation.parameters.y" [key]="'range'" [subKey]="0" [label]="'Min'"></cm-value-slot>
                    <cm-value-slot [node]="translation.parameters.y" [key]="'range'" [subKey]="1" [label]="'Max'"></cm-value-slot>
                } @else {
                    <cm-value-slot [node]="translation" [key]="'y'" [label]="'Offset'" [schema]="numberSchema"></cm-value-slot>
                }
            </div>

            <div class="cm-connection-settings">
                <cm-value-slot
                    [node]="translation"
                    [key]="'z'"
                    [label]="'Offset Z (distance)'"
                    [selectionPossibilities]="translationTypePossibilites"
                    [isSelected]="translationTypeSelected"
                    [topLabel]="true"
                ></cm-value-slot>
                @if (isR1Variable(translation.parameters.z)) {
                    <cm-value-slot [node]="translation.parameters.z" [key]="'default'" [label]="'Default'"></cm-value-slot>
                    <cm-value-slot [node]="translation.parameters.z" [key]="'range'" [subKey]="0" [label]="'Min'"></cm-value-slot>
                    <cm-value-slot [node]="translation.parameters.z" [key]="'range'" [subKey]="1" [label]="'Max'"></cm-value-slot>
                } @else {
                    <cm-value-slot [node]="translation" [key]="'z'" [label]="'Offset'" [schema]="numberSchema"></cm-value-slot>
                }
            </div>
        }
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Rotation </ng-container>
    <ng-container cm-content>
        @if (rotation(); as rotation) {
            <cm-value-slot
                [node]="node()"
                [key]="'rotation'"
                [label]="'Type'"
                [selectionPossibilities]="rotationTypePossibilites"
                [isSelected]="rotationTypeSelected"
                [topLabel]="true"
            ></cm-value-slot>
            @if (isFixedRotation(rotation)) {
                <div class="cm-rotation-settings">
                    <cm-value-slot [node]="rotation" [key]="'x'" [label]="'X'"></cm-value-slot>
                    <cm-value-slot [node]="rotation" [key]="'y'" [label]="'Y'"></cm-value-slot>
                    <cm-value-slot [node]="rotation" [key]="'z'" [label]="'Z'"></cm-value-slot>
                </div>
            } @else if (isHingeRotation(rotation)) {
                <div class="cm-rotation-settings">
                    <cm-value-slot [node]="rotation" [key]="'axis'" [label]="'Axis'" [selectionPossibilities]="hingeRotationAxisPossibilites"></cm-value-slot>
                    <cm-value-slot [node]="rotation.parameters.rotation" [key]="'default'" [subKey]="0" [label]="'Sin'"></cm-value-slot>
                    <cm-value-slot [node]="rotation.parameters.rotation" [key]="'default'" [subKey]="1" [label]="'Cos'"></cm-value-slot>
                </div>
            } @else if (isBallRotation(rotation)) {
                <div class="cm-rotation-settings">
                    <cm-value-slot [node]="rotation.parameters.rotation" [key]="'default'" [subKey]="0" [label]="'X'"></cm-value-slot>
                    <cm-value-slot [node]="rotation.parameters.rotation" [key]="'default'" [subKey]="1" [label]="'Y'"></cm-value-slot>
                    <cm-value-slot [node]="rotation.parameters.rotation" [key]="'default'" [subKey]="2" [label]="'Z'"></cm-value-slot>
                    <cm-value-slot [node]="rotation.parameters.rotation" [key]="'default'" [subKey]="3" [label]="'W'"></cm-value-slot>
                </div>
            }
        }
    </ng-container>
</cm-inspector-section>

<cm-button class="cm-action-button" (click)="captureRelationParams()" [disabled]="!matrixA() || !matrixB()">
    <ng-container cm-text> Capture Transformation Parameters </ng-container>
</cm-button>
