import {ImagePtr, ImagePtrReassignable} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageOpType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {imageOpNormalize} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-normalize"
import {imageOpMath} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-math"
import {imageOpReduce} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-reduce"
import {imageOpExtractPatches} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-extract-patches"
import {Box2Like} from "@cm/lib/math/box2"
import {DebugImage} from "@app/textures/texture-editor/operator-stack/image-op-system/util/debug-image"
import {imageOpCopyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-copy-region"

// computes the cross correlation of two images by sweeping the template image over the source image

export type ParameterType = {
    sourceImage: ImagePtr
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    templateImage: ImagePtr
    templateRegion?: Box2Like // default: {x: 0, y: 0, width: templateImage.width, height: templateImage.height}
    normalize?: boolean // default: true
    resultImage?: ImagePtr

    debugImage?: DebugImage
}

export type ReturnType = ImagePtr // returns the cross correlation image of size sourceImage.size - templateImage.size + 1

export const imageOpCrossCorrelation: ImageOpType<ParameterType, ReturnType> = {
    name: "CrossCorrelation",

    WebGL2: async ({context, parameters: {sourceImage, sourceRegion, templateImage, templateRegion, normalize, resultImage, debugImage}}) => {
        const sourceImageDescriptor = await context.getImageDescriptor(sourceImage)
        const templateImageDescriptor = await context.getImageDescriptor(templateImage)
        using templateImageToUse = new ImagePtrReassignable(templateImage)
        if (templateRegion) {
            using croppedTemplateImage = await imageOpCopyRegion.WebGL2({
                context,
                parameters: {
                    sourceImage: templateImage,
                    sourceRegion: templateRegion,
                },
            })
            templateImageToUse.set(croppedTemplateImage)
        }
        sourceRegion ??= {x: 0, y: 0, width: sourceImageDescriptor.width, height: sourceImageDescriptor.height}
        templateRegion ??= {x: 0, y: 0, width: templateImageDescriptor.width, height: templateImageDescriptor.height}
        const resultSize = {
            width: sourceRegion.width - templateRegion.width + 1,
            height: sourceRegion.height - templateRegion.height + 1,
        }
        if (resultSize.width <= 0 || resultSize.height <= 0) {
            throw new Error("Template image must be smaller than source image")
        }
        normalize ??= true
        if (debugImage) {
            await debugImage.addImage(templateImageToUse)
        }
        if (normalize) {
            using normalizedTemplateImage = await imageOpNormalize.WebGL2({context, parameters: {sourceImage: templateImageToUse}})
            templateImageToUse.set(normalizedTemplateImage)
            if (debugImage) {
                await debugImage.addImage(normalizedTemplateImage)
            }
        }

        // extract the patches of the source image
        const sourcePatches = ImagePtrReassignable.promoteSmartPtr(
            await imageOpExtractPatches.WebGL2({
                context,
                parameters: {
                    sourceImage,
                    sourceRegion,
                    patchSize: {width: templateRegion.width, height: templateRegion.height},
                },
            }),
        )
        if (debugImage) {
            await debugImage.addImage(sourcePatches)
        }

        if (normalize) {
            using normalizedSourcePatches = await imageOpNormalize.WebGL2({context, parameters: {sourceImage: sourcePatches, batchSize: resultSize}})
            sourcePatches.set(normalizedSourcePatches)
            if (debugImage) {
                await debugImage.addImage(normalizedSourcePatches)
            }
        }
        // compute the dot product of the source region and the template image
        const product = await imageOpMath.WebGL2({
            context,
            parameters: {
                operator: "*",
                operandA: sourcePatches,
                operandB: templateImageToUse,
                batchSizeA: resultSize,
                batchSizeB: {width: 1, height: 1},
            },
        })
        if (debugImage) {
            await debugImage.addImage(product)
        }
        sourcePatches.release()
        // sum up the product
        const summedProduct = await imageOpReduce.WebGL2({
            context,
            parameters: {sourceImage: product, operator: "sum", batchSize: resultSize},
        })
        product.release()
        if (debugImage) {
            await debugImage.addImage(summedProduct)
        }
        // rescale and return
        resultImage = await imageOpMath.WebGL2({
            context,
            parameters: {
                operator: "/",
                operandA: summedProduct,
                operandB: templateRegion.width * templateRegion.height,
                resultImage,
            },
        })
        summedProduct.release()
        return resultImage
    },

    ImgProc: async () => {
        throw new Error("Not implemented")
    },
}
