@if ($isLoading()) {
    <div class="cm-image-state-overlay">
        <mat-progress-spinner mode="indeterminate" [diameter]="48" [strokeWidth]="6" color="primary"></mat-progress-spinner>
    </div>
}
<div id="canvas-container" #canvasContainer>
    @if (!$isLoadingRevision() && $isInitialized()) {
        <cm-data-object-thumbnail
            id="feedbackPicture"
            [dataObjectId]="thumbnailDataObject?.id"
            [resolution]="DownloadResolution.High"
            (load)="onThumbnailLoaded()"
        ></cm-data-object-thumbnail>
    }
    <canvas #canvasElement id="feedbackCanvas" resize [class.cm-feedback-canvas--shown]="$showDrawing()"></canvas>
</div>
