<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [$title]="title"
                    [$isLoaded]="$item() !== undefined"
                    [$canEdit]="can.editOrganizationName()"
                    (onChanged)="updateItemSubject.next({name: $event})"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>

            <ng-container cm-buttons>
                @if ($item(); as organization) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menuOptions="matMenu">
                        <button mat-menu-item (click)="removeGalleryImage()" [disabled]="!canRemoveGalleryImage">Remove gallery image</button>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-main>
                <cm-gallery-image [item]="$item()"></cm-gallery-image>
                <cm-attachments
                    [item]="$item()"
                    [organizationId]="$item()?.id"
                    class="cm-files"
                    [$showSetGalleryImageOption]="false"
                    [$showSetCustomerLogoOption]="true"
                    [assignmentTypes]="assignmentTypes"
                ></cm-attachments>
            </ng-container>
            <ng-container cm-sidebar>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Name</mat-label>
                    <input matInput [ngModel]="$item()?.name" (ngModelChange)="updateItem({name: $event})" />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>URL</mat-label>
                    <input matInput id="url" [ngModel]="$item()?.url" (ngModelChange)="updateItem({url: $event})" />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Visible in filters</mat-label>
                    <mat-select [value]="$item()?.visibleInFilters" (valueChange)="updateItem({visibleInFilters: $event})">
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Sales email</mat-label>
                    <input matInput id="salesEmail" [ngModel]="$item()?.salesEmail" (ngModelChange)="updateItem({salesEmail: $event})" />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Default scanner URL</mat-label>
                    <input matInput id="defaultScannerUrl" [ngModel]="$item()?.defaultScannerUrl" (ngModelChange)="updateItem({defaultScannerUrl: $event})" />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Material Explorer Template ID</mat-label>
                    <input
                        matInput
                        id="matExplorerTemplateId"
                        [ngModel]="$item()?.matExplorerTemplate?.legacyId"
                        (ngModelChange)="updateItem({matExplorerTemplateId: parseLegacyIdFromString($event)})"
                        type="number"
                    />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Material Explorer Material ID</mat-label>
                    <input
                        matInput
                        id="matExplorerMaterialId"
                        [ngModel]="$item()?.matExplorerMaterial?.legacyId"
                        (ngModelChange)="updateItem({matExplorerMaterialId: parseLegacyIdFromString($event)})"
                        type="number"
                    />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Node Editor Template ID</mat-label>
                    <input
                        matInput
                        id="nodeEditorTemplateId"
                        [ngModel]="$item()?.nodeEditorTemplate?.legacyId"
                        (ngModelChange)="updateItem({nodeEditorTemplateId: parseLegacyIdFromString($event)})"
                        type="number"
                    />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Organization type</mat-label>
                    <mat-select [value]="$item()?.type" (valueChange)="updateItem({type: $event})">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option [value]="OrganizationType.FabricManufacturer">Fabric manufacturer</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
