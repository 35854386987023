export const pairwise = <ItemType>(items: ItemType[]): [ItemType, ItemType][] => {
    const pairs: [ItemType, ItemType][] = []
    for (let i = 1; i < items.length; i++) {
        pairs.push([items[i - 1], items[i]])
    }
    return pairs
}

export const groupBy = <ItemType, KeyType>(items: ItemType[], keyFn: (item: ItemType) => KeyType): Map<KeyType, ItemType[]> => {
    const groups = new Map<KeyType, ItemType[]>()
    for (const item of items) {
        const key = keyFn(item)
        if (!groups.has(key)) {
            groups.set(key, [])
        }
        groups.get(key)!.push(item)
    }
    return groups
}
