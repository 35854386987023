import {HttpHeaders} from "@angular/common/http"
import {environment} from "@environment"

export class Resolution {
    x: number
    y: number
    label: string

    constructor(x: number, y: number, label: string) {
        this.x = x
        this.y = y
        this.label = label
    }
}

export class Settings {
    // General
    public static readonly APP_VERSION = environment.version
    public static readonly GCS_ENDPOINT = "https://storage.googleapis.com/"
    public static readonly GCS_IMAGE_SUFFIX_JPG = "-jpg"
    public static readonly GCS_IMAGE_SUFFIX_500 = Settings.GCS_IMAGE_SUFFIX_JPG + "-500"
    public static readonly GCS_IMAGE_SUFFIX_1000 = Settings.GCS_IMAGE_SUFFIX_JPG + "-1000"
    public static readonly GCS_IMAGE_SUFFIX_2000 = Settings.GCS_IMAGE_SUFFIX_JPG + "-2000"
    public static readonly IMAGE_NOT_AVAILABLE_URL = "assets/images/no_image_available.jpg"
    public static readonly IMAGE_NOT_AVAILABLE_SMALL_URL = "assets/images/no_image_available_small.svg"
    public static readonly IMAGE_URL = "assets/images/image.svg"
    public static readonly IMAGE_INVALID_URL = "assets/images/image-slash.svg"
    public static readonly CLOUD_INVALID_URL = "assets/images/cloud-slash.svg"
    public static readonly CLOUD_BROKEN_URL = "assets/images/cloud-exclamation.svg"
    public static readonly CLOUD_CHECK_URL = "assets/images/cloud-check.svg"
    public static readonly CLOUD_UPLOAD_URL = "assets/images/cloud-arrow-up.svg"
    public static readonly CLOUD_DOWNLOAD_URL = "assets/images/cloud-arrow-down.svg"
    public static readonly CLOUD_PROCESSING_URL = "assets/images/processing-data-object.svg"
    public static readonly QUEUED_URL = "assets/images/processing-data-object.svg"
    public static readonly PROFILE_IMAGE_NOT_AVAILABLE_URL = "assets/images/no_image_available_small.svg"
    public static readonly NO_MATERIAL_URL = "assets/images/no-material.jpg"
    public static readonly SWITCH_MATERIAL_URL = "assets/images/material-switch.jpg"
    public static readonly SWITCH_IMAGE_URL = "assets/images/image-switch.png"
    public static readonly DATA_OBJECT_PROCESSING_URL = "assets/images/processing-data-object.jpg"
    public static readonly UPLOADING_URL = "assets/images/uploading.gif"
    public static readonly MAX_ALLOWED_PARALLEL_UPLOADS = 4
    public static readonly CONFIGURATOR_URL = "https://configurator.colormass.com?apiVersion=2"
    public static readonly CONFIGURATOR_NEW_URL_TMP = "https://cm-configurator-new-prod.web.app?apiVersion=2"

    public static readonly IMAGE_ICON = "fa-regular fa-image"
    public static readonly IMAGE_INVALID_ICON = "fa-regular fa-image-slash"
    public static readonly CLOUD_BROKEN_ICON = "fa-regular fa-cloud-exclamation"
    public static readonly CLOUD_PROCESSING_ICON = "fa-solid fa-cloud-binary"
    public static readonly QUEUED_ICON = "fa-solid fa-timer"

    public static readonly API_CALL_BUFFER_TIME_IN_MS = 100

    // API Endpoints
    public static readonly REST_API_ENDPOINT = environment.restApiEndpoint
    public static readonly GQL_API_ENDPOINT = `${environment.gqlApiEndpoint}/graphql`

    // API General
    public static readonly dataObjectAssignmentsUrl = Settings.REST_API_ENDPOINT + "data-object-assignments/"
    public static readonly googleStorageUploadUrl = Settings.REST_API_ENDPOINT + "google-storage-upload-url/"
    public static readonly usersUrl = Settings.REST_API_ENDPOINT + "users/"
    public static readonly userPasswordUrlSuffix = "/password/"
    public static readonly passwordResetUrl = Settings.REST_API_ENDPOINT + "password-reset/"
    public static readonly generateArUrl = Settings.REST_API_ENDPOINT + "generate-ar/"

    // API Library
    public static readonly materialsUrl = Settings.REST_API_ENDPOINT + "materials/"
    public static readonly materialRevisionsUrl = Settings.REST_API_ENDPOINT + "material-revisions/"
    public static readonly materialNodesUrl = Settings.REST_API_ENDPOINT + "material-nodes/"
    public static readonly materialConnectionsUrl = Settings.REST_API_ENDPOINT + "material-connections/"
    public static readonly dataObjectsUrl = Settings.REST_API_ENDPOINT + "data-objects/"

    // API Feedback
    public static readonly headers = new Headers({"Content-Type": "application/json"})

    // API Templates
    public static readonly templatesUrl = Settings.REST_API_ENDPOINT + "templates/"
    public static readonly templateRevisionsUrl = Settings.REST_API_ENDPOINT + "template-revisions/"

    // Default Resolutions
    public static readonly A5_300DPI_Portrait: Resolution = new Resolution(1748, 2480, "A5 Portrait (300 DPI)")
    public static readonly A5_300DPI_Landscape: Resolution = new Resolution(2480, 1748, "A5 Landscape (300 DPI)")
    public static readonly A4_300DPI_Portrait: Resolution = new Resolution(2480, 3508, "A4 Portrait (300 DPI)")
    public static readonly A4_300DPI_Landscape: Resolution = new Resolution(3508, 2480, "A4 Landscape (300 DPI)")
    public static readonly A3_300DPI_Portrait: Resolution = new Resolution(3508, 4960, "A3 Portrait (300 DPI)")
    public static readonly A3_300DPI_Landscape: Resolution = new Resolution(4960, 3508, "A3 Landscape (300 DPI)")
    public static readonly HD_Portrait: Resolution = new Resolution(1080, 1920, "HD Portrait")
    public static readonly HD_Landscape: Resolution = new Resolution(1920, 1080, "HD Landscape")
    public static readonly K4_Portrait: Resolution = new Resolution(2160, 3840, "4K Portrait")
    public static readonly K4_Landscape: Resolution = new Resolution(3840, 2160, "4K Landscape")
    public static readonly defaultResolutions: Array<Resolution> = [
        Settings.A3_300DPI_Portrait,
        Settings.A3_300DPI_Landscape,
        Settings.A4_300DPI_Portrait,
        Settings.A4_300DPI_Landscape,
        Settings.A5_300DPI_Portrait,
        Settings.A5_300DPI_Landscape,
        Settings.HD_Portrait,
        Settings.HD_Landscape,
        Settings.K4_Portrait,
        Settings.K4_Landscape,
    ]

    public static API_JSON_HEADERS = new HttpHeaders({"Content-Type": "application/json"})

    // Palettes 1, 3 and 7 and others from here: https://digitalsynopsis.com/design/minimal-web-color-palettes-combination-hex-code/
    public static colorPalette: number[] = [
        0xa8e6ce, 0xdcedc2, 0xffd3b5, 0xff8c94, 0xe5fcc2, 0x9de0ad, 0x45ada8, 0x547980, 0x594f4f, 0xf67280, 0xc06c84, 0x6c5b7b, 0x355c7d, 0xe8175d, 0xf7db4f,
        0xf0e4e4, 0xfad9c1, 0x009688, 0x7bc043, 0x0392cf,
    ]

    public static readonly defaultCommentField: string = "Tag:\n\n" + "Parts / Variations:\n\n" + "Dimensions:\n\n" + "Links / References:"

    private static readonly ONBOARDING_KEY: string = "onboardingCounter"
    public static readonly ONBOARDING_COUNT: number = 3

    public static getOnboardingKey(): string {
        return `${Settings.ONBOARDING_KEY}_${window.location.hostname}`
    }

    // note that multiple `pages` might be shown on the same page of the application
    public static readonly ENTITY_PAGE_SIZE = 25
    // we can load a batch containing multiple pages at once, but only up to this limit
    public static readonly ENTITY_MAX_BATCH_SIZE = 100

    // Automated rendering of material assets
    public static MATERIAL_ASSETS_RENDERING_SHADER_BALL_TEMPLATE_ID = 620
    public static MATERIAL_ASSETS_RENDERING_SHADER_BALL_HDRI_ID = 38

    // Material node editor
    public static MATERIAL_TEMPLATE_0_MATERIAL_REVISION_ID = "e1172331-37dc-4e32-9fc9-c88899a27de4"
    public static MATERIAL_TEMPLATE_1_MATERIAL_REVISION_ID = "8dde22be-edc4-47f8-9701-42f86e487fa8"
    public static MATERIAL_TEMPLATE_0_DISPLACEMENT_MATERIAL_REVISION_ID = "0250b316-0b80-4aa9-8589-bbd79bbd6ca9"
    public static MATERIAL_TEMPLATE_1_DISPLACEMENT_MATERIAL_REVISION_ID = "d7c46295-a9f1-44eb-a27d-df24a4345536"

    public static MATERIAL_TEMPLATE_2_MATERIAL_REVISION_ID = "0b6387db-a4df-4652-bda2-d134d73a3a62"

    public static DEFAULT_RENDER_QUEUE_DOMAIN = environment.rendering.defaultQueueDomain
}

export enum PageOrientation {
    Landscape = 0,
    Portrait = 1,
}

export const CM_DATE_FORMATS = {
    parse: {
        dateInput: "D MMMM YYYY",
    },
    display: {
        dateInput: "D MMMM YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "D MMMM YYYY",
        monthYearA11yLabel: "MMMM YYYY",
    },
}

export const CM_SHORT_DATE_FORMAT = {
    parse: {
        dateInput: "D M YYYY",
    },
    display: {
        dateInput: "D MMM YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "D MMMM YYYY",
        monthYearA11yLabel: "MMMM YYYY",
    },
}

export class MeasurementType {
    constructor(
        private label: string,
        private conversionRateToMetric: number,
    ) {}

    convertFromMetric(measurement: number): number {
        return measurement / this.conversionRateToMetric
    }

    convertToMetric(measurement: number): number {
        return measurement * this.conversionRateToMetric
    }
}

export const MeasurementTypes: {
    Metric: MeasurementType
    Imperial: MeasurementType
} = {
    Metric: new MeasurementType("Metric", 1),
    Imperial: new MeasurementType("Imperial", 2.54),
}
