import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalPainterParameterValueType} from "@common/models/hal/hal-painter/types"
import {Box2} from "@cm/lib/math/box2"
import {Vector2} from "@cm/lib/math/vector2"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {HalPainterImageCompositorOptions} from "@common/models/hal/hal-painter-image-compositor/types"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2PainterPrimitive} from "@common/models/webgl2/webgl2-painter-primitive"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"

export class WebGl2PainterImageCompositor implements HalPainterImageCompositor {
    constructor(
        readonly context: WebGl2Context,
        compositingFunction: string,
    ) {
        this.painterPrimitive = new WebGl2PainterPrimitive(this.context, this.getShadingFunction(compositingFunction))
    }

    // HalEntity
    dispose(): void {
        this.painterPrimitive.dispose()
    }

    // HalPainterImageCompositor
    setParameter(name: string, value: HalPainterParameterValueType, isOptional?: boolean): void {
        this.painterPrimitive.setParameter(name, value, isOptional)
    }

    // HalPainterImageCompositor
    async paint(target: HalPaintable, sourceImages?: HalImage | (HalImage | undefined)[], options?: HalPainterImageCompositorOptions): Promise<void> {
        const targetRegion = options?.targetRegion ?? Box2.fromPositionAndSize(new Vector2(0, 0), new Vector2(target.width, target.height))
        this.painterPrimitive.clearGeometry()
        this.painterPrimitive.addRect(targetRegion, targetRegion)
        if (sourceImages) {
            if (sourceImages instanceof Array) {
                for (let i = 0; i < sourceImages.length; i++) {
                    this.painterPrimitive.setSourceImage(i, sourceImages[i])
                }
            } else {
                this.painterPrimitive.setSourceImage(0, sourceImages)
            }
        }
        await this.painterPrimitive.paint(target, options)
    }

    private getShadingFunction(compositingFunction: string): string {
        return `
            ${compositingFunction}
            vec4 computeColor(vec2 position, vec2 uv, vec4 color) {
                return computeColor(ivec2(uv)) * color;
            }
        `
    }

    private painterPrimitive: HalPainterPrimitive
}
