<cm-sidebar-layout>
    <cm-item-filters cm-filters>
        <cm-tag-search-filter placeholder="Name, description, ..."></cm-tag-search-filter>

        @if (can.viewExtraAssetFilters()) {
            <cm-pinned-filter label="Pinned">
                <cm-pinned-filter-option>Clear filters</cm-pinned-filter-option>
                <cm-pinned-filter-option [$queryParams]="{assetState: Enums.InPipelineStates.Asset, nextActor: Enums.NextActors.ProductionInProgress}">
                    In progress
                </cm-pinned-filter-option>
                <cm-pinned-filter-option [$queryParams]="{assetState: Enums.InPipelineStates.Asset, nextActor: Enums.NextActors.ProductionWaitingForFeedback}">
                    Waiting for feedback
                </cm-pinned-filter-option>
            </cm-pinned-filter>
            <cm-checkboxes-filter label="Up Next" name="nextActor" [options]="Labels.NextActor"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Availability" name="hasAssignedUser" [options]="Labels.Assigned"></cm-checkboxes-filter>
        }
        <cm-checkboxes-filter label="State" name="assetState" [options]="Labels.AssetState"></cm-checkboxes-filter>
        @if (can.viewExtraAssetFilters()) {
            <cm-checkboxes-filter label="User" name="userId" [options]="users.$filterOptions()"></cm-checkboxes-filter>
        }
    </cm-item-filters>
    <cm-assets-grid></cm-assets-grid>
</cm-sidebar-layout>
<router-outlet></router-outlet>
