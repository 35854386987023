<cm-action-panel #infoPanel arrowRight="13px" minWidth="250px" maxWidth="250px">
    @if (dataObject) {
        <cm-action-panel-row cmIconClass="far fa-image-polaroid">
            <span class="cm-file-name" [matTooltip]="dataObject.originalFileName" matTooltipPosition="above" matTooltipClass="cm-untruncated-tooltip">
                {{ dataObject.originalFileName }}
            </span>
        </cm-action-panel-row>
    }
    @if (dataObject) {
        <cm-action-panel-row cmIconClass="far fa-up-right-and-down-left-from-center">
            {{ dataObject.width }} px × {{ dataObject.height }} px
            @if (dataObject.size) {
                ({{ dataObject.size | filesize: {base: 2} }})
            }
        </cm-action-panel-row>
    }
    @if (imageData) {
        <cm-action-panel-row cmIconClass="far fa-up-right-and-down-left-from-center">
            {{ imageData.width }} px × {{ imageData.height }} px
            <ng-container>({{ imageData.data.length | filesize: {base: 2} }})</ng-container>
        </cm-action-panel-row>
    }
    @if (dataObject?.imageColorProfile) {
        <cm-action-panel-row cmIconClass="far fa-palette">
            {{ dataObject.imageColorProfile == "none" ? "No color profile" : dataObject.imageColorProfile }}
        </cm-action-panel-row>
    }
    <cm-action-panel-row cmIconClass="far fa-magnifying-glass">{{ getZoomLevel() }}%</cm-action-panel-row>
</cm-action-panel>
<cm-action-bar>
    <ng-container cm-action-items>
        <ng-content select="[cm-action-items-pos1]"></ng-content>
        <cm-action-item (click)="pictureCanvas.navigation?.zoomTo(1)" tooltip="Zoom to 100%" cmIconClass="far fa-expand"></cm-action-item>
        <cm-action-item (click)="pictureCanvas.navigation?.zoomToFitImage()" tooltip="Zoom to fit" cmIconClass="far fa-compress"></cm-action-item>
        <cm-action-item tooltip="Download" cmIconClass="far fa-arrow-to-bottom" [matMenuTriggerFor]="downloadPictureMenu"></cm-action-item>
        <ng-content select="[cm-action-items-pos2]"></ng-content>
        <cm-action-item [showPanelByDefault]="!isMobileDevice" tooltip="Info" [panel]="infoPanel" cmIconClass="far fa-info-circle"></cm-action-item>
    </ng-container>
</cm-action-bar>
<mat-menu #downloadPictureMenu="matMenu">
    @if (dataObject) {
        <button mat-menu-item (click)="downloadOriginal(dataObject)">Original ({{ getFileTypeLabel(dataObject) }})</button>
    }
    @if (dataObject) {
        <button mat-menu-item (click)="downloadJpeg(dataObject)">JPEG</button>
    }
    @if (imageData) {
        <button mat-menu-item (click)="downloadFromImageData()">PNG</button>
    }
</mat-menu>
