import {inject, Injectable} from "@angular/core"
import {MaterialsCardFragment} from "@api"
import {ApiRequest} from "@common/models/api-request/api-request"
import {BatchApiCallById} from "@common/helpers/batch-api-call/batch-api-call-by-id"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsUnique} from "@cm/lib/utils/filter"

export type RequestPayload = {
    id: string
}

export type ResponsePayload = MaterialsCardFragment

export type BatchedRequestPayload = {
    requests: ApiRequest<RequestPayload, ResponsePayload>[]
}

@Injectable({
    providedIn: "root",
})
export class MaterialsCardService extends BatchApiCallById<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    private sdk = inject(SdkService)

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        return [{requests}]
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        const ids = payload.requests.map((request) => request.payload.id).filter(IsUnique)
        return this.sdk.gql.materialsCardData({ids}).then((response) => response.materials)
    }
}
