import {JobNodes, makeJobTaskDescriptor} from "@src/job-task/job-nodes"

type EntityId = number

export type ArGltfGenerationInput = {
    templateId: EntityId
    configString: string
}

export type ArGltfGenerationOutput = {
    dataObject: JobNodes.DataObjectReference
}

export const arGltfGenerationTask = makeJobTaskDescriptor<ArGltfGenerationInput, ArGltfGenerationOutput>("Ar.gltf", 1)

export type ArUsdzGenerationInput = {
    dataObject: JobNodes.DataObjectReference
}

export type ArUsdzGenerationOutput = {
    dataObject: JobNodes.DataObjectReference
}

export const arUsdzGenerationTask = makeJobTaskDescriptor<ArUsdzGenerationInput, ArUsdzGenerationOutput>("Ar.usdz", 1)
