import {StateLabel} from "@labels"
import {BasicOrganizationInfoFragment} from "@api"
import {IsTruthy} from "@cm/lib/utils/filter"

export const sortOrganizationsStateLabelListAlphabetically = (organizations: StateLabel<string>[]): StateLabel<string>[] => {
    return organizations.sort((a, b) => a.label.localeCompare(b.label))
}

export const sortOrganizationsDetailsWithNameAlphabetically = (organizationsDetails: BasicOrganizationInfoFragment[]): BasicOrganizationInfoFragment[] => {
    return [
        ...organizationsDetails.filter((organization) => IsTruthy(organization.name)).sort((a, b) => a.name!.localeCompare(b.name!)),
        ...organizationsDetails.filter((organization) => !IsTruthy(organization.name)),
    ]
}
