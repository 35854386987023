import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-section-content",
    standalone: true,
    templateUrl: "./section-content.component.html",
    styleUrls: ["./section-content.component.scss"],
})
export class SectionContentComponent {
    @Input() indent = true
}
