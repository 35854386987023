import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {ValueOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-value-node",
    templateUrl: "./value-node.component.html",
    styleUrls: ["./value-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class ValueNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = ValueOutputs
    typeInfo = ValueNodeType
}

export const ValueNodeType: MaterialNodeType<typeof ValueNodeComponent> = {
    id: "value",
    label: "Value",
    color: "#9E3500",
    name: "ShaderNodeValue",
    inputs: [] as never[],
    outputs: [ValueOutputs.value],
    component: ValueNodeComponent,
}
