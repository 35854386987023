import {DestroyRef, Inject, inject, Injectable} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {DataObjectState, SdkServiceDataObjectGQL} from "@api"
import {CM_COLORMASS_CLIENT} from "@common/modules/client-injector"
import {TokenService} from "@common/services/auth/token.service"
import {environment} from "@environment"
import {getSdk as getRawSdk} from "generated/raw"
import {getSdk} from "generated/sdk"
import {GraphQLClient} from "graphql-request"
import {filter, firstValueFrom, map} from "rxjs"

/**
 * Convenience service for accessing the GraphQL API.
 * Inject this service into your components and use the `gql` property to access the API.
 */
@Injectable({
    providedIn: "root",
})
export class SdkService {
    constructor(@Inject(CM_COLORMASS_CLIENT) private colormassClient: string) {}

    public raw = getRawSdk(new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "all"}), (action) => action(this.headersWithToken))
    public gql = getSdk(new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "ignore"}), (action) => action(this.headersWithToken))
    public throwable = getSdk(new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "none"}), (action) => action(this.headersWithToken))
    // we might as well hardcode this endpoint, as the legacy GQL API only runs on prod at this point

    public getDataObject = inject(SdkServiceDataObjectGQL)
    tokenService = inject(TokenService)

    /**
     * Add JWT token from local storage to request headers for authentication
     */
    private get headersWithToken(): Record<string, string> {
        const token = this.tokenService.load()
        return token
            ? {
                  "X-Colormass-Client": this.colormassClient,
                  Authorization: `Bearer ${token}`,
              }
            : {
                  "X-Colormass-Client": this.colormassClient,
              }
    }

    public waitForUploadProcessing(dataObjectId: string, destroyRef?: DestroyRef) {
        return firstValueFrom(
            this.getDataObject.watch({id: dataObjectId}, {pollInterval: 1000}).valueChanges.pipe(
                takeUntilDestroyed(destroyRef),
                map(({data: subscriptionData}) => subscriptionData?.dataObject),
                filter((dataObject) => dataObject?.state === DataObjectState.Completed || dataObject?.state === DataObjectState.ProcessingFailed),
                map((dataObject) => dataObject!.id),
            ),
            {defaultValue: null},
        )
    }
}
