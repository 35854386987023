import {Component} from "@angular/core"
import {InfiniteScrollAnchorComponent} from "@common/components/data/infinite-scroll-anchor/infinite-scroll-anchor.component"

@Component({
    selector: "cm-table-layout",
    standalone: true,
    imports: [InfiniteScrollAnchorComponent],
    templateUrl: "./table-layout.component.html",
    styleUrl: "./table-layout.component.scss",
})
export class TableLayoutComponent {}
