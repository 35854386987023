<cm-routed-dialog [dialogSize]="dialogSizes.Medium" [dialogScroll]="false" (close)="overlayClosed()">
    <ng-container cm-overlay-content>
        <div class="cm-logs-container">
            <div class="cm-header">
                <i class="far fa-memo cm-header-icon"></i>
                <span class="cm-header-title">Scanner Logs</span>
            </div>
            <div #logText class="cm-log-text"></div>
            <div class="cm-button-container">
                <button mat-raised-button (click)="clearLogs()">Clear logs</button>
            </div>
        </div>
    </ng-container>
</cm-routed-dialog>
