import {Component, ViewChild} from "@angular/core"
import {EmissionInputs, EmissionOutputs} from "@app/material-editor/models/nodes/emission"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-emission-node",
    templateUrl: "./emission-node.component.html",
    styleUrls: ["./emission-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class EmissionNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = EmissionInputs
    outputs = EmissionOutputs
    typeInfo = EmissionNodeType
}

export const EmissionNodeType: MaterialNodeType<typeof EmissionNodeComponent> = {
    id: "emission",
    label: "Emission",
    color: "#4987af",
    name: "ShaderNodeEmission",
    inputs: [EmissionInputs.color, EmissionInputs.strength] as never[],
    outputs: [EmissionOutputs.emission],
    component: EmissionNodeComponent,
}
