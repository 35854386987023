import {ImageColorSpace} from "@api"
import {ImageColorSpaces as RestImageColorSpace} from "@legacy/api-model/data-object"

export function mapRestToGqlImageColorSpace(colorSpace: ImageColorSpace | (typeof RestImageColorSpace)[keyof typeof RestImageColorSpace]): ImageColorSpace {
    switch (colorSpace) {
        case RestImageColorSpace.Unknown:
        case ImageColorSpace.Unknown:
            return ImageColorSpace.Unknown
        case RestImageColorSpace.sRgb:
        case ImageColorSpace.Srgb:
            return ImageColorSpace.Srgb
        case RestImageColorSpace.Linear:
        case ImageColorSpace.Linear:
            return ImageColorSpace.Linear
        case RestImageColorSpace.Gamma2_0:
        case ImageColorSpace.Gamma_2_0:
            return ImageColorSpace.Gamma_2_0
        case RestImageColorSpace.Gamma2_2:
        case ImageColorSpace.Gamma_2_2:
            return ImageColorSpace.Gamma_2_2
        default:
            throw new Error(`Unknown image color space: ${colorSpace}`)
    }
}

export function mapGqlToRestImageColorSpace(colorSpace: ImageColorSpace): (typeof RestImageColorSpace)[keyof typeof RestImageColorSpace] {
    switch (colorSpace) {
        case ImageColorSpace.Unknown:
            return RestImageColorSpace.Unknown
        case ImageColorSpace.Srgb:
            return RestImageColorSpace.sRgb
        case ImageColorSpace.Linear:
            return RestImageColorSpace.Linear
        case ImageColorSpace.Gamma_2_0:
            return RestImageColorSpace.Gamma2_0
        case ImageColorSpace.Gamma_2_2:
            return RestImageColorSpace.Gamma2_2
        default:
            throw new Error(`Unknown image color space: ${colorSpace}`)
    }
}
