import {Component, EventEmitter, Input, Output} from "@angular/core"
import {EndpointUrls} from "@common/models/constants/urls"
import {LoadingSpinnerComponent} from "@common/components/progress"
import {MenuDialogComponent} from "@common/components/dialogs/menu-dialog/menu-dialog.component"
import {toDataURL} from "qrcode"

@Component({
    standalone: true,
    selector: "cm-ar-dialog",
    templateUrl: "./ar-dialog.component.html",
    styleUrls: ["./ar-dialog.component.scss"],
    imports: [MenuDialogComponent, LoadingSpinnerComponent],
})
export class ArDialogComponent {
    @Output() close: EventEmitter<null> = new EventEmitter<null>()

    private _gcsObjectName?: string | null
    @Input() set gcsObjectName(value: string | null | undefined) {
        this._gcsObjectName = value
        if (this.gcsObjectName == null) {
            this.waitingForArFile = true
            return
        }
        this.generateQrCode(`${EndpointUrls.AR_REDIRECT_URL}/${this.gcsObjectName}`).then(() => {
            this.waitingForArFile = false
        })
    }

    get gcsObjectName() {
        return this._gcsObjectName
    }

    qrCode = ""
    waitingForArFile = true

    async generateQrCode(url: string): Promise<string | void> {
        return toDataURL(url, {errorCorrectionLevel: "high", width: 1000, margin: 4})
            .then((url: string) => (this.qrCode = url))
            .catch((error) => console.error(error))
    }
}
