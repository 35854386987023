import {DeclareMaterialNode, cyclesNode, materialSlots} from "@src/materials/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {z} from "zod"
import {color, vec3} from "@src/materials/types"
import {getDefaultMaterial} from "@src/materials/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode, threeValueNode, threeVec3Node} from "@src/materials/three-utils"
import {getAll} from "@src/graph-system/utils"

export class BsdfDiffuse extends DeclareMaterialNode(
    {
        returns: z.object({bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)}),
        inputs: z.object({
            color: materialSlots.optional(),
            roughness: materialSlots.optional(),
            normal: materialSlots.optional(),
        }),
        parameters: z.object({
            color: color.optional(),
            roughness: z.number().optional(),
            normal: vec3.optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, normal} = await getAll(inputs, get)
            const material = getDefaultMaterial()

            material.colorNode = color ?? threeConvert(parameters.color, threeRGBColorNode) ?? material.colorNode
            material.roughnessNode = threeValueNode(1) // Always 1 for Lambertian diffuse (Oren-Nayar not supported yet)

            material.normalNode =
                normal ?? threeConvert(parameters.normal, threeVec3Node, (val) => val.x !== 0 || val.y !== 0 || val.z !== 0) ?? material.normalNode

            return {bsdf: material}
        },
    },
) {}
