import {Injectable, signal} from "@angular/core"
import {CanvasMode} from "@platform/models/pictures/canvas/canvas"
import {BehaviorSubject, Subject, map} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class DrawingService {
    $color = signal("#40c4ff")
    activeTool$ = new BehaviorSubject<"blue" | "pink" | "cursor">("blue")
    canvasMode$ = new BehaviorSubject<CanvasMode>("navigating")
    clearDrawing$ = new Subject<void>()
    isDrawing$ = this.canvasMode$.pipe(map((canvasMode) => canvasMode === "drawing" || canvasMode === "editing"))

    enable() {
        this.$color.set("#40c4ff")
        this.activeTool$.next("blue")
        this.canvasMode$.next("drawing")
    }

    disable() {
        this.canvasMode$.next("navigating")
    }

    drawInBlue() {
        this.$color.set("#40c4ff")
        this.activeTool$.next("blue")
        this.canvasMode$.next("drawing")
    }

    drawInPink() {
        this.$color.set("#f36")
        this.activeTool$.next("pink")
        this.canvasMode$.next("drawing")
    }

    startEditing() {
        this.canvasMode$.next("editing")
        this.activeTool$.next("cursor")
    }

    clear() {
        this.clearDrawing$.next()
    }
}
