import {Component, Input} from "@angular/core"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"

@Component({
    selector: "cm-card-placeholder",
    templateUrl: "./card-placeholder.component.html",
    styleUrls: ["./card-placeholder.component.scss"],
    standalone: true,
    imports: [DataObjectThumbnailComponent],
})
export class CardPlaceholderComponent {
    @Input() squareFormat = false
}
