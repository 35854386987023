<div #bar [hidden]="!editingEnabled || editMode !== 'bar'" class="cm-bar">
    <div #barFill [hidden]="!_rangeSet || hideBarFill" class="cm-bar-fill" [style.width]="barFillWidth + '%'"></div>
    <span class="cm-label">{{ label }}</span>
    <span>{{ value | number: "1.3" }}</span>
</div>
<div [hidden]="!editingEnabled || editMode !== 'input'" class="cm-input">
    <input
        #valueInput
        type="number"
        [value]="value"
        (change)="onValueChange($any($event.target).valueAsNumber); valueChange.emit($any($event.target).valueAsNumber)"
        step="0.001"
    />
</div>
<div [hidden]="editingEnabled">
    <span>{{ label }}</span>
</div>
