import {DeclareMaterialNode, cyclesNode, materialSlots} from "@src/materials/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {z} from "zod"
import {vec3} from "@src/materials/types"
import {getDefaultMaterial} from "@src/materials/nodes/bsdf-principled"
import * as THREE from "three"
import {ThreeShadowCatcher} from "@src/materials/three-shadow-catcher"

const getDefaultSurface = () => ({surface: getDefaultMaterial()})

export class OutputMaterial extends DeclareMaterialNode(
    {
        returns: z.object({surface: z.instanceof(THREE.Material).or(cyclesNode)}),
        inputs: z.object({
            surface: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode).optional(),
            volume: materialSlots.optional(),
            displacement: materialSlots.optional(),
        }),
        parameters: z.object({
            displacement: vec3.optional(),
            shadowCatcher: z.boolean().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            if (parameters.shadowCatcher) {
                const shadowCatcher = new ThreeShadowCatcher()

                return {surface: shadowCatcher}
            }

            const {surface} = inputs
            if (!surface) return getDefaultSurface()

            const result = await get(surface)
            if (!result) return getDefaultSurface()

            return {surface: result}
        },
    },
) {}
