import {MaterialSocket} from "@material-editor/models/material-socket"

export const SeparateRgbInputs: Record<string, MaterialSocket> = {
    image: {paramType: "socket", id: "Image", type: "input", valueType: "color", label: "Image"},
}

export const SeparateRgbOutputs: Record<string, MaterialSocket> = {
    red: {paramType: "socket", id: "R", type: "output", valueType: "output", label: "R"},
    green: {paramType: "socket", id: "G", type: "output", valueType: "output", label: "G"},
    blue: {paramType: "socket", id: "B", type: "output", valueType: "output", label: "B"},
}
