import {ConfigurationGroupNode} from "@src/pricing/nodes/configuration-group-node"
import {FlattenPriceList} from "@src/pricing/nodes/flatten-pricelist"
import {PriceList} from "@src/pricing/nodes/price-list"
import {PricedItemNode} from "@src/pricing/nodes/priced-item-node"

export function checkConfigurationGroupNodes(rootNode: FlattenPriceList) {
    const configurationGroupNodes = rootNode.getNodesOfType(ConfigurationGroupNode)
    const configurationGroupNodeIds = new Set(configurationGroupNodes.map((node) => node.getGroupId()))
    if (configurationGroupNodeIds.size !== configurationGroupNodes.length) throw new Error("Duplicate configuration group node id")

    const pricedItemNodes = rootNode.getNodesOfType(PricedItemNode)
    for (const pricedItemNode of pricedItemNodes) {
        pricedItemNode.getVariantIds().forEach((condition) => {
            let configGroupParentFound = false

            for (const configurationGroupNode of pricedItemNode.parameters.condition?.parameters.currentConfigurations?.parameters.list ?? []) {
                if (configurationGroupNode.hasVariant(condition)) {
                    configGroupParentFound = true
                    break
                }
            }

            if (!configGroupParentFound) throw new Error("Priced item node has condition that is not a variant of any of its configuration group parents")
        })
    }
}

export function checkMutuallyExclusiveConditions(rootNode: FlattenPriceList) {
    const subPriceNodes = rootNode.getNodesOfType(PriceList)
    for (const subPriceNode of subPriceNodes) if (!subPriceNode.mutuallyExclusive()) throw new Error("Price list node has non mutually exclusive conditions")
}
