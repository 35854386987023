import * as paper from "paper"
import {EventEmitter} from "@angular/core"
import * as Tiling from "@app/textures/texture-editor/operator-stack/operators/auto-tiling/service/auto-tiling.service"
import {takeUntil} from "rxjs"
import {Vector2Like} from "@cm/lib/math/vector2"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class TilingHint extends CanvasBaseToolboxItemBase {
    readonly changed = new EventEmitter<void>()

    public static readonly labelGroupName = "LabelsGroup"
    public static readonly groupName = "TilingHintGroup"

    private readonly line: paper.Path.Line
    private circle1: paper.Shape.Circle
    private circle2: paper.Shape.Circle

    protected readonly hintGroup = new paper.Group()
    protected readonly labelGroup = new paper.Group()
    private label!: paper.PointText
    private baseFontSize = 28
    protected baseFontStrokeWidth = 1
    protected fontStrokeColor = new paper.Color("black")
    private labelOffset: number = this.baseFontSize / 2 + 5

    private minRadius = 10

    private editMode = EditMode.None

    constructor(
        parent: CanvasBaseToolboxItem,
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        private radius: number,
    ) {
        super(parent)
        this.hintGroup.name = TilingHint.groupName
        this.labelGroup.name = TilingHint.labelGroupName

        const strokeWidth = 1.5

        this.line = new paper.Path.Line(new paper.Point(x1, y1), new paper.Point(x2, y2))
        this.line.data.canvasObject = this
        this.line.data.canvasObjectPart = "line"
        this.line.strokeWidth = strokeWidth
        this.line.strokeScaling = false
        this.line.strokeColor = new paper.Color("white")
        this.hintGroup.addChild(this.line)

        this.circle1 = new paper.Shape.Circle(new paper.Point(x1, y1), radius)
        this.hintGroup.addChild(this.circle1)
        this.circle2 = new paper.Shape.Circle(new paper.Point(x2, y2), radius)
        this.hintGroup.addChild(this.circle2)
        this.circle1.data.canvasObject = this
        this.circle1.data.canvasObjectPart = "circle1"
        this.circle2.data.canvasObject = this
        this.circle2.data.canvasObjectPart = "circle2"
        this.circle1.strokeWidth = this.circle2.strokeWidth = strokeWidth
        this.circle1.strokeScaling = this.circle2.strokeScaling = false
        this.circle1.strokeColor = this.circle2.strokeColor = new paper.Color("white")
        this.circle1.fillColor = this.circle2.fillColor = new paper.Color(1, 1, 1, 0.15)

        this.initLabel()

        this.selectedChange.subscribe((selected) => {
            const color = new paper.Color(selected ? "#40c4ff" : "white")
            this.line.strokeColor = color
            this.circle1.strokeColor = color
            this.circle2.strokeColor = color
        })
        this.viewChange.subscribe(() => this.updateLabel())
        this.physicalInfoChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.updateLabel())
    }

    private initLabel(): void {
        this.label = new paper.PointText(new paper.Point(0, 0))
        this.label.fillColor = new paper.Color("white")
        this.label.justification = "center"
        this.label.content = "Hint label"
        this.label.fontSize = this.baseFontSize
        this.label.strokeColor = this.fontStrokeColor
        this.label.strokeWidth = this.baseFontStrokeWidth / this.zoomLevel
        this.label.fontFamily = "Roboto"
        this.label.fontWeight = "Bold"
        this.label.fillColor = new paper.Color("white")
        this.labelGroup.addChild(this.label)
        this.hintGroup.addChild(this.labelGroup)
        this.updateLabel()
    }

    override onMouseDrag(event: paper.ToolEvent & {event: MouseEvent}): boolean {
        const mouseEvent = event.event
        if (mouseEvent.buttons === 1) {
            this.handleEdit(event)
            return false
        }
        return super.onMouseDrag(event)
    }

    override hitTest(point: Vector2Like): boolean {
        if (this.disabled) {
            return false
        }
        const hitResult = this.paperHitTest(point)
        this.editMode = this.getEditModeByHitResult(hitResult)
        if (this.editMode !== EditMode.None) {
            this.cursor = this.getCursorByEditMode(this.editMode)
            return true
        }
        return false
    }

    private getEditModeByHitResult(hitResult: paper.HitResult | null): EditMode {
        if (!this.disabled && hitResult) {
            const part = hitResult?.item.data.canvasObjectPart as string
            if (part === "circle1") {
                if (hitResult.type === "fill") {
                    return EditMode.Move1
                } else if (hitResult.type === "stroke") {
                    return EditMode.Resize1
                }
            } else if (part === "circle2") {
                if (hitResult.type === "fill") {
                    return EditMode.Move2
                } else if (hitResult.type === "stroke") {
                    return EditMode.Resize2
                }
            }
        }
        return EditMode.None
    }

    private getCursorByEditMode(editMode: EditMode): string {
        switch (editMode) {
            case EditMode.Move1:
            case EditMode.Move2:
                return "move"
            case EditMode.Resize1:
            case EditMode.Resize2:
                return "nwse-resize"
            default:
                throw new Error(`Unknown edit mode: ${editMode}`)
        }
    }

    private handleEdit(event: paper.ToolEvent): void {
        if (this.editMode === EditMode.None) {
            return
        }
        const coords = this.getCoords()
        let pos1 = new paper.Point(coords.x1, coords.y1)
        let pos2 = new paper.Point(coords.x2, coords.y2)
        let radius = coords.radius
        const canvasMin = this.canvasBounds.min
        const canvasMax = this.canvasBounds.max
        switch (this.editMode) {
            case EditMode.Move1:
                pos1 = paper.Point.max(canvasMin, paper.Point.min(canvasMax, pos1.add(event.delta)))
                break
            case EditMode.Move2:
                pos2 = paper.Point.max(canvasMin, paper.Point.min(canvasMax, pos2.add(event.delta)))
                break
            case EditMode.Resize1:
                radius = Math.max(this.minRadius, pos1.getDistance(event.point))
                break
            case EditMode.Resize2:
                radius = Math.max(this.minRadius, pos2.getDistance(event.point))
                break
        }
        this.setCoords({x1: pos1.x, y1: pos1.y, x2: pos2.x, y2: pos2.y, radius: radius})
        this.updateLabel()
    }

    setCoords(coords: Tiling.Hint) {
        this.radius = coords.radius
        this.line.segments[0].point.x = coords.x1
        this.line.segments[0].point.y = coords.y1
        this.line.segments[1].point.x = coords.x2
        this.line.segments[1].point.y = coords.y2
        this.circle1.position.x = coords.x1
        this.circle1.position.y = coords.y1
        this.circle1.radius = coords.radius
        this.circle2.position.x = coords.x2
        this.circle2.position.y = coords.y2
        this.circle2.radius = coords.radius
        this.changed.emit()
    }

    getCoords(): Tiling.Hint {
        if (!this.line) {
            throw Error("Attempting to get tiling-hint coords, but no line was created.")
        }
        return {
            x1: this.line.segments[0].point.x,
            y1: this.line.segments[0].point.y,
            x2: this.line.segments[1].point.x,
            y2: this.line.segments[1].point.y,
            radius: this.radius,
        }
    }

    public updateLabel(): void {
        this.label.content = this.getLabel(this.line.length)
        this.label.fontSize = this.baseFontSize / this.zoomLevel
        this.label.strokeWidth = this.baseFontStrokeWidth / this.zoomLevel
        const lineVector: paper.Point = this.line.segments[1].point.subtract(this.line.segments[0].point)
        const orthogonalVector: paper.Point = lineVector.rotate(90, new paper.Point(0, 0))
        let angle: number = lineVector.angle
        let position: paper.Point
        if (90 < angle && angle < 180) {
            angle -= 180
        } else if (-180 < angle && angle < -90) {
            angle += 180
        }
        this.label.rotation = angle
        if (-90 < lineVector.angle && lineVector.angle < 90) {
            position = this.line.bounds.center.subtract(orthogonalVector.normalize(this.labelOffset / this.zoomLevel))
        } else {
            position = this.line.bounds.center.add(orthogonalVector.normalize(this.labelOffset / this.zoomLevel))
        }
        this.label.position = position
        const lineWidthWithoutCircles: number = this.line.length - this.circle1.bounds.width
        this.label.visible = Math.max(this.label.bounds.width, this.label.bounds.height) <= 0.9 * lineWidthWithoutCircles
    }

    override remove() {
        super.remove()
        this.line.remove()
        this.circle1.remove()
        this.circle2.remove()
        this.label.remove()
    }

    private getCentimetersLabel(pixels: number): string {
        if (!this.physicalInfo) {
            return "??? cm"
        }
        const cm: number = Math.round((pixels / this.physicalInfo.pixelsPerCm) * 100) / 100
        return `${cm.toFixed(2)} cm`
    }

    private getLabel(pixels: number): string {
        return `${this.getCentimetersLabel(pixels)} (${Math.round(pixels)} px)`
    }
}

enum EditMode {
    None = "None",
    Move1 = "Move1",
    Resize1 = "Resize1",
    Move2 = "Move2",
    Resize2 = "Resize2",
}
