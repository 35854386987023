import {from, Observable, switchMap} from "rxjs"
import {MeshData, MeshDataGraph} from "@cm/lib/geometry-processing/mesh-data"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {decompressMesh, WebAssemblyWorkerService} from "@app/editor/helpers/mesh-processing/mesh-processing"
import {MeshLoadSettings} from "@cm/lib/templates/interfaces/scene-manager"
import {IDataObjectNew} from "@cm/lib/templates/interfaces/data-object"

export class MeshDataCache {
    private cache: AsyncCacheMap<string, MeshData, [IDataObjectNew, number, MeshLoadSettings]>

    constructor(private workerService: WebAssemblyWorkerService) {
        this.cache = new AsyncCacheMap((key, [dataObject, plyId, settings]) => {
            const dataObjData = from(
                (async () => {
                    const response = await fetch(dataObject.downloadUrl)
                    if (!response.ok) {
                        throw new Error(`Failed to download data: ${response.statusText}`)
                    }
                    const buffer = await response.arrayBuffer()
                    return new Uint8Array(buffer)
                })(),
            )

            return dataObjData.pipe(
                switchMap((buffer) => {
                    let graph: MeshDataGraph = {
                        type: "loadMesh",
                        data: {
                            type: "dataObjectReference",
                            dataObjectId: plyId,
                        },
                    }
                    // only add subdivision node if we're actually subdividing
                    if (settings.renderSubdivisionLevel > 0) {
                        graph = {
                            type: "subdivide",
                            levels: settings.renderSubdivisionLevel,
                            input: graph,
                        }
                    }
                    return decompressMesh(workerService, graph, buffer.buffer.slice(0), settings.displaySubdivisionLevel)
                }),
            )
        })
    }

    getMeshData(dataObject: IDataObjectNew, plyDataObjectId: number, settings: MeshLoadSettings): Observable<MeshData> {
        const key = `${dataObject.legacyId},${settings.displaySubdivisionLevel},${settings.renderSubdivisionLevel}`
        return this.cache.get(key, [dataObject, plyDataObjectId, settings])
    }

    invalidate() {
        this.cache.clear()
    }
}
