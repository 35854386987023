import {Component, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {Matrix4, Vector3} from "@common/helpers/vector-math"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

export type TransformDialogData = {
    position?: Vector3
    rotation?: Vector3
    scale?: Vector3
}

function convertFrom3dsMaxTransform(data: TransformDialogData): TransformDialogData {
    const {position, quaternion, scale} = Matrix4.from3dsMaxTransform(data.position, data.rotation, data.scale, false).decompose()
    const rotation = quaternion.toEuler()
    return {
        position: data.position ? position : undefined,
        rotation: data.rotation ? rotation : undefined,
        scale: data.scale ? scale : undefined,
    }
}

@Component({
    selector: "cm-transform-dialog",
    templateUrl: "./transform-dialog.component.html",
    styleUrls: ["./transform-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogModule, InspectorSectionComponent, InputContainerComponent, NumericInputComponent, MatCheckboxModule, FormsModule, MatButtonModule],
})
export class TransformDialogComponent {
    position?: Vector3
    rotation?: Vector3
    scale?: Vector3
    use3dsMaxCoordSys = false

    convertRotation = {
        toDisplay: (rad: number) => rad * (180 / Math.PI),
        fromDisplay: (deg: number) => deg * (Math.PI / 180),
    }

    constructor(
        public dialogRef: MatDialogRef<TransformDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: TransformDialogData,
    ) {
        this.position = data?.position?.copy() ?? new Vector3()
        this.rotation = data?.rotation?.copy() ?? new Vector3()
        this.scale = data?.scale?.copy()
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }

    confirmAction(): void {
        let data: TransformDialogData = {
            position: this.position,
            rotation: this.rotation,
            scale: this.scale,
        }
        if (this.use3dsMaxCoordSys) {
            console.log("convert")
            data = convertFrom3dsMaxTransform(data)
        }
        this.dialogRef.close(data)
    }
}
