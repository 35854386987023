import * as paper from "paper"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {EventEmitter} from "@angular/core"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {TilingAreaToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-toolbox-item"

export class TilingAreaControlPointToolboxItem extends CanvasBaseToolboxItemBase<TilingAreaToolboxItem> {
    readonly positionChanged = new EventEmitter<{newPosition: Vector2; oldPosition: Vector2; delta: Vector2}>()
    readonly dragging = new EventEmitter<void>()
    readonly dragFinished = new EventEmitter<void>()

    constructor(
        parent: TilingAreaToolboxItem,
        private properties: {
            radius: number
            color: string
            movable: boolean
        },
    ) {
        super(parent)

        this.viewChange.subscribe(() => this.onViewChange())
        this.selectedChange.subscribe(() => this.onSelectionChange())

        this.createCircle()
    }

    override remove() {
        super.remove()
        this._positionCircle?.remove()
    }

    private updateCircles() {
        if (!this._positionCircle) {
            throw Error("Position circle not created")
        }
        const color = this.selected ? COLOR_SELECTED : this.properties.color
        this._positionCircle.strokeColor = new paper.Color(color)
        this._positionCircle.fillColor = new paper.Color(color)
        this._positionCircle.fillColor.alpha = 0.1
        this._positionCircle.position.set(this._position.x, this._position.y)
    }

    get position(): Vector2 {
        return this._position
    }

    set position(value: Vector2Like) {
        this.setPosition(value)
    }

    setPosition(value: Vector2Like, notify = true) {
        if (this._position.equals(value)) {
            return
        }
        const oldPosition = this._position.clone()
        this._position.setFromVector2Like(value)
        this.updateCircles()
        if (notify) {
            this.positionChanged.emit({newPosition: this._position, oldPosition, delta: this._position.sub(oldPosition)})
        }
    }

    get lastTouchedTimeStamp(): number {
        return this._lastTouchedTimeStamp
    }

    override hitTest(point: Vector2Like): boolean {
        if (this.properties.movable && this._positionCircle?.hitTest(point, {stroke: true, fill: true, tolerance: 2})) {
            this.cursor = "move"
            return true
        }
        return false
    }

    override onMouseDown(_event: paper.ToolEvent): boolean {
        if (!this.properties.movable) {
            return false
        }
        this._lastTouchedTimeStamp = Date.now()
        this._dragPosition = this.position
        return true
    }

    override onMouseUp(event: paper.ToolEvent): boolean {
        if (this._dragPosition) {
            this._dragPosition = undefined
            this.dragFinished.emit()
            return true
        }
        return super.onMouseUp(event)
    }

    override onMouseDrag(event: paper.ToolEvent): boolean {
        if (this.selected && this._dragPosition) {
            this.position = this.position.add(Vector2.fromVector2Like(event.delta))
            this.dragging.emit()
            return false
        }
        return super.onMouseDrag(event)
    }

    private updateZoomDependent() {
        this.createCircle()
    }

    private onViewChange() {
        this.updateZoomDependent()
    }

    private onSelectionChange() {
        this.updateCircles()
    }

    private createCircle() {
        this._positionCircle?.remove()

        this.beginPaperCreation()

        this._positionCircle = new paper.Path.Circle(this._position, (this.properties.radius * window.devicePixelRatio) / this.zoomLevel)
        this._positionCircle.strokeWidth = window.devicePixelRatio / this.zoomLevel

        this.updateCircles()
    }

    private _dragPosition?: Vector2
    private _position = new Vector2(0, 0)
    private _positionCircle?: paper.Path
    private _lastTouchedTimeStamp = 0
}

const COLOR_SELECTED = "white"
