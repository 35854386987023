<div part="cm-icon-with-caption" class="cm-icon-with-caption">
    <div
        part="cm-icon-container"
        class="cm-icon cm-icon-shadow"
        [tp]="$variant()?.name ?? $notAvailableTooltip()"
        [tpAppendTo]="$tooltipContainer()"
        [style.background]="$variant()?.iconDataObjectId ? 'none' : getConfigVariantColor()"
        draggable="true"
        (dragstart)="onDragStart($event, $variant(), $config())"
    >
        @if (fetchThumbnailForDataObject($variant()?.iconDataObjectId) | async; as thumbnailUrl) {
            <img alt="Variant icon" [src]="thumbnailUrl" />
        }

        @if ($loading()) {
            <cm-loading-spinner-icon class="cm-mini-spinner"></cm-loading-spinner-icon>
        } @else {
            <i class="far fa-check" [class.cm-active-variant]="$selectedVariant()?.id === $variant()?.id"></i>
        }
    </div>
    @if ($useCaptions()) {
        <div class="caption">{{ $variant().name }}</div>
    }
</div>
