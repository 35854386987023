import {Component, input, computed, output} from "@angular/core"
import {RouterLink} from "@angular/router"
import {ButtonComponent} from "@common/components/buttons/button/button.component"

@Component({
    selector: "cm-show-pictures-button",
    standalone: true,
    imports: [ButtonComponent, RouterLink],
    templateUrl: "./show-pictures-button.component.html",
    styleUrl: "./show-pictures-button.component.scss",
})
export class ShowPicturesButtonComponent {
    $queryParamsKey = input<string | undefined>(undefined)
    $entityId = input<string | undefined>(undefined)
    $queryParams = computed(() => Object.fromEntries([[this.$queryParamsKey(), this.$entityId()]]))
    $activated = output<boolean>()
}
