import {Overlay, OverlayConfig} from "@angular/cdk/overlay"
import {ComponentPortal, TemplatePortal} from "@angular/cdk/portal"
import {inject, Injectable} from "@angular/core"
import {Router} from "@angular/router"
import {DialogSize, ModalInfo, ModalOverlayRef} from "@common/models/dialogs"
import {IdInFilter} from "@api"
import {Subscription, merge} from "rxjs"
import {BaseSelectComponent} from "@platform/components/base/base-select/base-select.component"
import {MatDialog} from "@angular/material/dialog"

/**
 * Service for opening and closing modal dialogs
 */
@Injectable({
    providedIn: "root",
})
export class DialogService {
    matDialog = inject(MatDialog)
    overlay = inject(Overlay)
    router = inject(Router)

    openModal<T>(portal: TemplatePortal | ComponentPortal<T>): ModalInfo {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
        const config = new OverlayConfig({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            positionStrategy,
        })
        const overlayRef = this.overlay.create(config)
        const dialogRef = new ModalOverlayRef(overlayRef)
        overlayRef.attach(portal)
        return {dialogRef, overlayRef}
    }

    closeModal(modal: ModalInfo) {
        modal.dialogRef.close()
        modal.overlayRef.dispose()
    }

    selectInDialog<EntityType extends {id: string}, FilterInputType extends {search?: string | null; organizationId?: IdInFilter | null}>(
        component: typeof BaseSelectComponent<EntityType, FilterInputType>,
        options: {dialogSize?: DialogSize; filters?: FilterInputType; onSelect: (item: EntityType) => void; onCancel?: () => void},
    ) {
        const dialogRef = this.matDialog.open(component, {
            data: {
                dialogSize: options.dialogSize ?? DialogSize.Large,
                customFilters: options.filters,
            },
            disableClose: true,
            panelClass: [`cm-dialog`, `cm-dialog--${DialogSize[options.dialogSize ?? DialogSize.Large].toLowerCase()}`],
        })
        const subscriptions: Subscription[] = []
        subscriptions.push(
            merge(dialogRef.backdropClick(), dialogRef.componentInstance.cancel).subscribe(() => {
                options.onCancel?.()
                dialogRef.close()
            }),
        )
        subscriptions.push(
            dialogRef.componentInstance.selectItem.subscribe((item) => {
                options.onSelect(item)
                dialogRef.close()
            }),
        )
        subscriptions.push(
            dialogRef.componentInstance.done.subscribe(() => {
                dialogRef.close()
            }),
        )
        subscriptions.push(
            dialogRef.afterClosed().subscribe(() => {
                subscriptions.forEach((sub) => sub.unsubscribe())
            }),
        )
    }
}
