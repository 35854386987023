import {Component, computed, output} from "@angular/core"
import {SearchComponent} from "../../../common/components/inputs/search/search.component"
import {InfiniteListComponent} from "../../../common/components/lists/infinite-list/infinite-list.component"
import {CardErrorComponent} from "../../../common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "../../../common/components/cards/card-placeholder/card-placeholder.component"
import {cardAnimations} from "@app/common/components/cards/animations"
import {TemplateAddTemplateItemFragment, TemplateFilterInput, TemplateType} from "@api"
import {BaseSelectComponent} from "@app/platform/components/base/base-select/base-select.component"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"
import {Matrix4} from "@app/common/helpers/vector-math"
import {TemplateInstance, Parameters} from "@cm/lib/templates/nodes/template-instance"
import {TemplateReference} from "@cm/lib/templates/nodes/template-reference"
import {v4 as uuid4} from "uuid"
import {OwnOrganizationsFilterComponent} from "../../../common/components/filters/own-organizations-filter/own-organizations-filter.component"

@Component({
    selector: "cm-template-add-product",
    standalone: true,
    templateUrl: "./template-add-product.component.html",
    styleUrl: "./template-add-product.component.scss",
    animations: [...cardAnimations],
    imports: [SearchComponent, InfiniteListComponent, CardErrorComponent, TemplateAddCardComponent, CardPlaceholderComponent, OwnOrganizationsFilterComponent],
})
export class TemplateAddProductComponent extends BaseSelectComponent<TemplateAddTemplateItemFragment, TemplateFilterInput> {
    private rawData = this.implementDataLoader(this.sdk.gql.templateAddTemplateItems)
    data = computed(() => this.rawData().items.map((item) => ({...item, node: item.data ? this.getTemplateInstance(item.data) : undefined})))
    onItemClicked = output()

    constructor() {
        super()
        this.customFilters = {type: [TemplateType.Part, TemplateType.Product]}
    }

    private getTemplateInstance(data: TemplateAddTemplateItemFragment) {
        const templateRevisionId = data.latestRevision?.legacyId
        if (templateRevisionId === undefined) {
            return undefined
        }

        return new TemplateInstance({
            id: uuid4(),
            name: data.name ?? "Template Instance",
            lockedTransform: Matrix4.identity().toArray(),
            template: new TemplateReference({
                templateRevisionId,
            }),
            parameters: new Parameters({}),
        })
    }
}
