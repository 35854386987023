@if (node) {
    <cm-inspector-section>
        <ng-container cm-title> Features </ng-container>
        <ng-container cm-content>
            <cm-toggle [(toggle)]="node.enableSnapshot" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>Snapshot</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.enableAr" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>AR</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.enableGltfDownload" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>glTF Download</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.enableStlDownload" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>STL Download</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.enableFullscreen" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>Fullscreen</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.enablePdfGeneration" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>PDF Generation</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.enableOnboardingHint" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>Onboarding hint</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.enableSalesEnquiry" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>Sales Enquiry</ng-container>
            </cm-toggle>
            <cm-toggle [(toggle)]="node.showAnnotations" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>Annotations</ng-container>
            </cm-toggle>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Rendering Settings </ng-container>
        <ng-container cm-content>
            <cm-toggle class="cm-span2" [(toggle)]="node.enableAdaptiveSubdivision" (toggleChange)="nodeChanged(node)">
                <ng-container cm-text>Enable Adaptive Subdiv</ng-container>
            </cm-toggle>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Configurator UI Settings </ng-container>
        <ng-container cm-content>
            <div class="cm-scene-property-settings">
                <cm-toggle
                    class="cm-span2"
                    [toggle]="node.backgroundColor == null"
                    (toggleChange)="node.backgroundColor = $event ? null : [1, 1, 1]; nodeChanged(node)"
                >
                    <ng-container cm-text>Transparent Background</ng-container>
                </cm-toggle>
                @if (node.backgroundColor != null) {
                    <cm-input-container>
                        <ng-container cm-inline-label> Background: </ng-container>
                        <ng-container cm-input>
                            <cm-color-input [(value)]="node.backgroundColor" (valueChange)="nodeChanged(node)"></cm-color-input>
                        </ng-container>
                    </cm-input-container>
                }
                <cm-input-container>
                    <ng-container cm-inline-label> UI Color: </ng-container>
                    <ng-container cm-input>
                        <cm-color-input [(value)]="node.uiColor" (valueChange)="nodeChanged(node)"> </cm-color-input>
                    </ng-container>
                </cm-input-container>
                <mat-form-field>
                    <mat-label>UI Style</mat-label>
                    <mat-select [(ngModel)]="node.uiStyle" (selectionChange)="nodeChanged(node)">
                        <mat-option [value]="'default'">Default</mat-option>
                        <mat-option [value]="'icons'">Icons</mat-option>
                        <mat-option [value]="'hidden'">Hidden</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Icon size</mat-label>
                    <mat-select [(ngModel)]="node.iconSize" (selectionChange)="nodeChanged(node)">
                        <mat-option value="24">24px</mat-option>
                        <mat-option value="32">32px</mat-option>
                        <mat-option value="40">40px</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Configurator Settings </ng-container>
        <ng-container cm-content>
            <div class="cm-scene-property-settings">
                <cm-input-container>
                    <ng-container cm-inline-label>
                        <span matTooltip="Maximum subdivision level">Max subdiv.</span>
                    </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.maxSubdivisionLevel" (valueChange)="nodeChanged(node)" [decimalPlaces]="0"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label>
                        <span matTooltip="Maximum subdivision level on mobile devices">Mob. max subdiv.</span>
                    </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input
                            [value]="node.maxSubdivisionLevelOnMobile ?? 0"
                            (valueChange)="node.maxSubdivisionLevelOnMobile = $event; nodeChanged(node)"
                            [decimalPlaces]="0"
                        >
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <mat-form-field>
                    <mat-label>Texture resolution</mat-label>
                    <mat-select [(ngModel)]="node.textureResolution" (selectionChange)="nodeChanged(node)" matTooltip="(Requires reload to take effect.)">
                        <mat-option value="500px">500 px</mat-option>
                        <mat-option value="1000px">1000 px</mat-option>
                        <mat-option value="2000px">2000 px</mat-option>
                        <mat-option value="original">Original</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Texture filtering</mat-label>
                    <mat-select
                        [(ngModel)]="node.textureFiltering"
                        (selectionChange)="nodeChanged(node)"
                        matTooltip="(Disable for higher-quality final image. Requires reload to take effect.)"
                    >
                        <mat-option [value]="true">On</mat-option>
                        <mat-option [value]="false">Off</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Environment reflection mode</mat-label>
                    <mat-select [(ngModel)]="node.environmentMapMode" (selectionChange)="nodeChanged(node)" matTooltip="(Requires reload to take effect.)">
                        <mat-option value="full">Diffuse+Specular</mat-option>
                        <mat-option value="specularOnly">Specular Only</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Focus mode</mat-label>
                    <mat-select [(ngModel)]="node.focusMode" (selectionChange)="nodeChanged(node)" matTooltip="(Requires reload to take effect.)">
                        <mat-option value="target">Camera Target</mat-option>
                        <mat-option value="auto">Automatic</mat-option>
                        <mat-option value="click">Click to Focus</mat-option>
                    </mat-select>
                </mat-form-field>
                <cm-toggle
                    class="cm-span2"
                    [toggle]="node.shadowCatcherFalloff !== undefined"
                    (toggleChange)="node.shadowCatcherFalloff = $event ? {sizeX: 100, sizeZ: 100, smoothness: 0.5, opacity: 0.8} : undefined; nodeChanged(node)"
                >
                    <ng-container cm-text>Shadow catcher falloff</ng-container>
                </cm-toggle>
                @if (node.shadowCatcherFalloff; as falloff) {
                    <cm-input-container>
                        <ng-container cm-inline-label> Size X </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="falloff.sizeX" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label> Size Z </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="falloff.sizeZ" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label> Smoothness </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="falloff.smoothness" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                    <cm-input-container>
                        <ng-container cm-inline-label> Opacity </ng-container>
                        <ng-container cm-input>
                            <cm-numeric-input [(value)]="falloff.opacity" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                        </ng-container>
                    </cm-input-container>
                }
            </div>
        </ng-container>
    </cm-inspector-section>
}
