<p class="cm-list-title">
    {{ label }}
</p>
<div class="cm-list">
    @for (option of options; track option) {
        <cm-native-checkbox [connectedSelectionList]="selectedOptions" [value]="option.state" (valueChange)="onCheckboxToggled(option.state, $event)">
            <ng-container cm-label>
                {{ option.label }}
            </ng-container>
        </cm-native-checkbox>
    }
    <ng-content></ng-content>
</div>
