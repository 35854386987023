import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {imageOpGridMapping, ParameterType} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-grid-mapping"

const GridMappingNode = declareImageOpNode(imageOpGridMapping)

export function gridMapping(parameters: GetParameters<Context, ParameterType>) {
    return new GridMappingNode(parameters)
}
