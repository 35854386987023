import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {imageOpCopyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-copy-region"
import {Size2Like} from "@cm/lib/math/size2"
import {imageOpCreateImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-create-image"

export class DebugImage {
    constructor(private imageOpContextWebGL2: ImageOpContextWebGL2) {}

    get imageRef() {
        if (!this._debugImageRef) {
            throw new Error("DebugImage not initialized")
        }
        return this._debugImageRef
    }

    async init(size: Size2Like) {
        if (this._debugImageRef) {
            this._debugImageRef.release()
        }
        this._debugImageRef = await imageOpCreateImage.WebGL2({
            context: this.imageOpContextWebGL2,
            parameters: {
                descriptor: {
                    width: size.width,
                    height: size.height,
                    channelLayout: "RGBA",
                    format: "float32",
                    isSRGB: false,
                },
                fillColor: {r: 0, g: 0, b: 0, a: 1},
            },
        })
        this._currentPosition = 0
    }

    async addImage(image: ImagePtr) {
        using result = await imageOpCopyRegion.WebGL2({
            context: this.imageOpContextWebGL2,
            parameters: {
                sourceImage: image,
                targetOffset: {
                    x: 0,
                    y: this._currentPosition,
                },
                addressMode: "border",
                resultImage: this._debugImageRef,
            },
        })
        const imageDescriptor = await this.imageOpContextWebGL2.getImageDescriptor(image)
        this._currentPosition += imageDescriptor.height + 5
    }

    private _debugImageRef: ImagePtr | undefined = undefined
    private _currentPosition = 0
}
