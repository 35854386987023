import * as paper from "paper"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {Color} from "@cm/lib/math/color"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class CrosshairToolboxItem extends CanvasBaseToolboxItemBase {
    constructor(parent: CanvasBaseToolboxItem) {
        super(parent)

        this.crosshairLineH = new paper.Path.Line(new paper.Point(-1, 0), new paper.Point(1, 0))
        this.crosshairLineV = new paper.Path.Line(new paper.Point(0, -1), new paper.Point(0, 1))
        this.updateCrosshair()

        this.viewChange.subscribe(() => this.updateCrosshair())
    }

    override remove() {
        super.remove()
        this.crosshairLineH.remove()
        this.crosshairLineV.remove()
    }

    set crosshairPosition(value: Vector2Like) {
        if (this._crosshairPosition.equals(value)) {
            return
        }
        this._crosshairPosition.setFromVector2Like(value)
        this.updateCrosshair()
    }

    get crosshairPosition(): Vector2 {
        return this._crosshairPosition
    }

    set crosshairSize(value: number) {
        if (this._crosshairSizeH === value && this._crosshairSizeV === value) {
            return
        }
        this._crosshairSizeH = value
        this._crosshairSizeV = value
        this.updateCrosshair()
    }

    set crosshairSizeH(value: number) {
        if (this._crosshairSizeH === value) {
            return
        }
        this._crosshairSizeH = value
        this.updateCrosshair()
    }

    get crosshairSizeH(): number {
        return this._crosshairSizeH
    }

    set crosshairSizeV(value: number) {
        if (this._crosshairSizeV === value) {
            return
        }
        this._crosshairSizeV = value
        this.updateCrosshair()
    }

    get crosshairSizeV(): number {
        return this._crosshairSizeV
    }

    set crosshairSizeIsScreenSize(value: boolean) {
        if (this._crosshairSizeIsScreenSize === value) {
            return
        }
        this._crosshairSizeIsScreenSize = value
        this.updateCrosshair()
    }

    get crosshairSizeIsScreenSize(): boolean {
        return this._crosshairSizeIsScreenSize
    }

    set crosshairRotation(value: number) {
        if (this._crosshairRotation === value) {
            return
        }
        this._crosshairRotation = value
        this.updateCrosshair()
    }

    get crosshairRotation(): number {
        return this._crosshairRotation
    }

    set crosshairColor(value: Color) {
        if (this._crosshairColor.equals(value)) {
            return
        }
        this._crosshairColor.setFromColorLike(value)
        this.updateCrosshair()
    }

    get crosshairColor(): Color {
        return this._crosshairColor
    }

    private updateCrosshair() {
        const color = new paper.Color(this._crosshairColor.r, this._crosshairColor.g, this._crosshairColor.b, 1)
        const lineWidth = window.devicePixelRatio / this.zoomLevel
        let halfLineLengthH = this._crosshairSizeH / 2
        let halfLineLengthV = this._crosshairSizeV / 2
        if (this._crosshairSizeIsScreenSize) {
            halfLineLengthH /= this.zoomLevel
            halfLineLengthV /= this.zoomLevel
        }

        const transform = new Matrix3x2().rotate(this._crosshairRotation)

        this.crosshairLineH.strokeColor = color
        this.crosshairLineH.strokeWidth = lineWidth
        const halfLineH = transform.multiplyVector(new Vector2(halfLineLengthH, 0))
        this.crosshairLineH.firstSegment.point.x = this._crosshairPosition.x + -halfLineH.x
        this.crosshairLineH.firstSegment.point.y = this._crosshairPosition.y + -halfLineH.y
        this.crosshairLineH.lastSegment.point.x = this._crosshairPosition.x + halfLineH.x
        this.crosshairLineH.lastSegment.point.y = this._crosshairPosition.y + halfLineH.y

        this.crosshairLineV.strokeColor = color
        this.crosshairLineV.strokeWidth = lineWidth
        const halfLineV = transform.multiplyVector(new Vector2(0, halfLineLengthV))
        this.crosshairLineV.firstSegment.point.x = this._crosshairPosition.x + -halfLineV.x
        this.crosshairLineV.firstSegment.point.y = this._crosshairPosition.y + -halfLineV.y
        this.crosshairLineV.lastSegment.point.x = this._crosshairPosition.x + halfLineV.x
        this.crosshairLineV.lastSegment.point.y = this._crosshairPosition.y + halfLineV.y
    }

    private crosshairLineH: paper.Path
    private crosshairLineV: paper.Path
    private _crosshairPosition = new Vector2(0, 0)
    private _crosshairRotation = 0 // in degrees CCW
    private _crosshairSizeH = 40
    private _crosshairSizeV = 40
    private _crosshairSizeIsScreenSize = true
    private _crosshairColor = new Color(1, 1, 1)
}
