import {DeclareMaterialNode, cyclesNode, materialSlots} from "@src/materials/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {z} from "zod"
import {color} from "@src/materials/types"
import {getDefaultMaterial} from "@src/materials/nodes/bsdf-principled"
import {threeConvert, threeRGBColorNode, threeValueNode} from "@src/materials/three-utils"
import {getAll} from "@src/graph-system/utils"

export class Emission extends DeclareMaterialNode(
    {
        returns: z.object({emission: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode)}),
        inputs: z.object({
            color: materialSlots.optional(),
            strength: materialSlots.optional(),
        }),
        parameters: z.object({
            color: color.optional(),
            strength: z.number().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, strength} = await getAll(inputs, get)

            const material = getDefaultMaterial()
            material.colorNode = threeRGBColorNode({r: 0, g: 0, b: 0})

            const emissionValue =
                color ?? threeConvert(parameters.color, threeRGBColorNode, (val) => val.r > 0 || val.g > 0 || val.b > 0) ?? material.emissiveNode
            if (emissionValue) {
                const emissionStrengthValue = strength ?? threeConvert(parameters.strength, threeValueNode)
                if (emissionStrengthValue) material.emissiveNode = THREENodes.mul(emissionValue, emissionStrengthValue)
                else material.emissiveNode = emissionValue
            }

            return {emission: material}
        },
    },
) {}
