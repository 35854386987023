import {DescriptorToProperty, NodeClassDescriptor} from "@src/templates/runtime-graph/descriptors"

export interface INodeInstance {
    run(): void
    cleanup?(): void
    $id?: NodeId
    $info?: ConnectionData["$info"]
    $debug?: true
    $inletKeys?: string[]
    $constKeys?: string[]
    $scheduled?: boolean
    $scheduleGroup?: INodeInstance[]
}

export type NodeClass<D extends NodeClassDescriptor> = {
    new (): INodeInstance & {
        [K in keyof D]: DescriptorToProperty<D[K]>
    }
    descriptor: D
    uniqueName: string
}
type StaticImplements<I extends new (...args: any[]) => any, C extends I> = InstanceType<I>
export type NodeClassImpl<D extends NodeClassDescriptor, Self extends NodeClass<D>> = StaticImplements<NodeClass<D>, Self>

export type NodeId = string

export const ResolveId = Symbol("ResolveId")

export const ThisStructID: typeof ResolveId = ResolveId

export class ResolveAlias<T> {
    constructor(public id: string) {}
}

export type ConnectionData<D extends NodeClassDescriptor = {}> = {
    $info: {
        id: NodeId
        nodeClass: NodeClass<D>
        provideScopeAs?: string
    }
    $debug?: true
}
