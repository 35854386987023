import {NodeGraph, ParameterValue, isNodeGraphInstance} from "@src/graph-system/node-graph"
import {NodeGraphResultBase} from "@src/graph-system/evaluators/node-graph-result-base"
import {AsyncReentrancyGuard} from "@src/utils/async-reentrancy-guard"

export class CachedNodeGraphResult<ReturnType, Context> extends NodeGraphResultBase<ReturnType, Context> {
    constructor(root: ParameterValue<ReturnType, Context>, context: Context, disableValidation: boolean = false) {
        super(root, context, disableValidation)
    }

    run() {
        const asyncPromiseSerializerByNode = new Map<NodeGraph<unknown, Context>, AsyncReentrancyGuard.PromiseSerializer>()
        const get = async <ReturnType>(value: ReturnType | NodeGraph<ReturnType, Context>): Promise<ReturnType> => {
            if (isNodeGraphInstance<ReturnType, Context>(value)) {
                // get async promise serializer
                let asyncPromiseSerializer = asyncPromiseSerializerByNode.get(value)
                if (asyncPromiseSerializer === undefined) {
                    asyncPromiseSerializer = new AsyncReentrancyGuard.PromiseSerializer()
                    asyncPromiseSerializerByNode.set(value, asyncPromiseSerializer)
                }
                return asyncPromiseSerializer.executeSequentially(async () => {
                    // lookup cached value
                    const cachedValue = this.runCache.get(value)
                    if (cachedValue !== undefined) {
                        return cachedValue as ReturnType
                    }
                    // evaluate value and store in cache
                    const computedValue = await this.evaluateGraph(value, get)
                    this.runCache.set(value, computedValue)
                    return computedValue
                })
            }
            return value
        }
        return get(this.root)
    }

    runSync() {
        const getSync = <ReturnType>(value: ReturnType | NodeGraph<ReturnType, Context>): ReturnType => {
            if (isNodeGraphInstance<ReturnType, Context>(value)) {
                // lookup cached value
                const cachedValue = this.runCache.get(value)
                if (cachedValue !== undefined) {
                    return cachedValue as ReturnType
                }
                // evaluate value and store in cache
                const computedValue = this.evaluateGraphSync(value, getSync)
                this.runCache.set(value, computedValue)
                return computedValue
            }

            return value
        }

        return getSync(this.root)
    }

    override invalidateNodeGraph(nodeGraph: NodeGraph<unknown, Context>) {
        this.runCache.delete(nodeGraph)
        nodeGraph.parents.forEach((parent) => this.invalidateNodeGraph(parent))
    }

    private readonly runCache = new Map<NodeGraph<unknown, Context>, unknown>()
}
