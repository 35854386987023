import {HalContext} from "@common/models/hal/hal-context"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {ImageCacheWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-cache-webgl2"
import {ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {ImagePtrWebGl2, ImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {getHalImageDescriptor, getHalImageOptions} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {ImagePtr, ImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageRefBase, ImageRefId} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref-base"
import {SmartPtr} from "app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {isDescriptorCompatible} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {createHalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor/create"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {createHalPainterPrimitive} from "@common/models/hal/hal-painter-primitive/create"
import {isImageDescriptor} from "@common/helpers/hal"

const NUM_EVALS_TO_KEEP_UNUSED_CACHED_IMAGES = 10

export class ImageOpContextWebGL2 {
    constructor(
        private halContext: HalContext,
        readonly texturesApi?: TexturesApiService,
        readonly drawableImageCache?: DrawableImageCache,
    ) {
        this.halBlitter = new HalPainterImageBlit(halContext)
        this.halImageCache = new ImageCacheWebGL2(halContext)
    }

    // ImageOpContext
    dispose(): void {
        this.halBlitter.dispose()
        // release image caches
        this.halImageCache.dispose()
        // release painters
        for (const imageCompositor of this.imageCompositorByCompositingFunction.values()) {
            imageCompositor.dispose()
        }
        this.imageCompositorByCompositingFunction.clear()
        for (const primitivePainter of this.primitivePainterByShadingFunction.values()) {
            primitivePainter.dispose()
        }
        this.primitivePainterByShadingFunction.clear()
    }

    // ImageOpContext
    preEvaluation(): void {
        // make sure we don't evaluate multiple times in parallel as the temporary resources are shared
        if (this.currentlyProcessing) {
            throw new Error("ImageOpEvaluator is already processing")
        }
        this.currentlyProcessing = true
        this.halImageCache.beginUsageFrame()
    }

    // ImageOpContext
    postEvaluation(): void {
        this.halImageCache.endUsageFrame()
        this.halImageCache.freeRarelyUsedImages(NUM_EVALS_TO_KEEP_UNUSED_CACHED_IMAGES)

        this.currentlyProcessing = false
    }

    async getOrCreateImageCompositor(compositingFunction: string): Promise<HalPainterImageCompositor> {
        let imageCompositor = this.imageCompositorByCompositingFunction.get(compositingFunction)
        if (!imageCompositor) {
            imageCompositor = createHalPainterImageCompositor(this.halContext, compositingFunction)
            this.imageCompositorByCompositingFunction.set(compositingFunction, imageCompositor)
        }
        return imageCompositor
    }

    async getOrCreatePrimitivePainter(shadingFunction: string): Promise<HalPainterPrimitive> {
        let primitivePainter = this.primitivePainterByShadingFunction.get(shadingFunction)
        if (!primitivePainter) {
            primitivePainter = createHalPainterPrimitive(this.halContext, shadingFunction)
            this.primitivePainterByShadingFunction.set(shadingFunction, primitivePainter)
        }
        return primitivePainter
    }

    private releaseImage(imageRef: ImageRef) {
        if (imageRef.addressSpace !== "temporary") {
            throw Error("image is not temporary")
        }
        if (!this.temporaryImageByImageRefId.has(imageRef.id)) {
            throw new Error(`Image ${imageRef.id} not found`)
        }
        const imageWebGl2 = this.getTemporaryImage(imageRef.id)
        imageWebGl2.release() // release the reference from getTemporaryImage
        imageWebGl2.release() // release the reference from the imageRef
    }

    async prepareResultImage(resultImage: ImagePtr | undefined, descriptorOrImageRef: ImageDescriptor | ImagePtr): Promise<ImagePtr> {
        if (resultImage) {
            return new SmartPtr(resultImage)
        } else {
            return this.createImage(descriptorOrImageRef)
        }
    }

    async createImage(descriptorOrImageRef: ImageDescriptor | ImagePtr): Promise<ImagePtr> {
        return this.createImageInternal(descriptorOrImageRef)
    }

    private async createImageInternal(descriptorOrImageRef: ImageDescriptor | ImagePtr, disposeFn?: (imageRef: ImageRef) => void): Promise<ImagePtr> {
        let descriptor: ImageDescriptor
        if (isImageDescriptor(descriptorOrImageRef)) {
            descriptor = descriptorOrImageRef
        } else {
            descriptor = await this.getImageDescriptor(descriptorOrImageRef)
        }
        const image = await this.halImageCache.getImage(descriptor)
        const imagePtr = new SmartPtr(
            new ImageRef(
                "temporary",
                this.nextImageRefId++,
                (image) => {
                    if (disposeFn) {
                        disposeFn(image)
                    }
                    this.releaseImage(image)
                },
                `ImageOpContextWebGL2.createImage[${image.debugId}]`,
            ),
        )
        this.temporaryImageByImageRefId.set(imagePtr.ref.id, image)
        return imagePtr
    }

    async getImage(imagePtr: ImagePtr): Promise<ImagePtrWebGl2> {
        switch (imagePtr.ref.addressSpace) {
            case "temporary":
                return new SmartPtr(this.getTemporaryImage(imagePtr.ref.id))
            case "drawable":
                return new SmartPtr(await this.getDrawableImage(imagePtr.ref.id))
            case "data-object":
                if (typeof imagePtr.ref.id !== "string") {
                    throw new Error(`Data object id must be a string, but got ${imagePtr.ref.id}`)
                }
                return new SmartPtr(await this.getDataObjectImage(imagePtr.ref.id))
            default:
                throw new Error(`Unknown address space: ${imagePtr.ref.addressSpace}`)
        }
    }

    async getImageDescriptor(imagePtr: ImagePtr): Promise<ImageDescriptor> {
        using image = await this.getImage(imagePtr)
        return image.ref.descriptor
    }

    async createDataObjectImageRef(dataObjectId: string): Promise<ImagePtr> {
        let imageRef = this.dataObjectImageRefByDataObjectId.get(dataObjectId)
        if (!imageRef) {
            // create and load the image
            if (TextureEditorSettings.EnableAllocTrace) {
                console.log(`ImageOpContextWebGL2: creating data-object image-ref ${dataObjectId}`)
            }
            imageRef = new ImageRefBase(
                "data-object",
                dataObjectId,
                () => this.disposeDataObjectImageRef(dataObjectId),
                "ImageOpContextWebGL2.createDataObjectImageRef",
            )
            this.dataObjectImageRefByDataObjectId.set(dataObjectId, imageRef)
        } else {
            imageRef.addRef()
        }
        return new ImagePtr(imageRef)
    }

    private disposeDataObjectImageRef(dataObjectId: string) {
        if (!this.dataObjectImageRefByDataObjectId.has(dataObjectId)) {
            throw new Error(`Data object ${dataObjectId} not found`)
        }
        if (TextureEditorSettings.EnableAllocTrace) {
            console.log(`ImageOpContextWebGL2: disposing data-object image-ref ${dataObjectId}`)
        }
        this.dataObjectImageRefByDataObjectId.delete(dataObjectId)
        const image = this.dataObjectImageByDataObjectId.get(dataObjectId)
        if (image) {
            this.dataObjectImageByDataObjectId.delete(dataObjectId)
            image.release()
        }
    }

    async createDrawableImage(drawableImageRef: DrawableImageRef, descriptor: ImageDescriptor): Promise<ImagePtr> {
        const imagePtr = new ImagePtr(new ImageRef("drawable", this.nextImageRefId++, undefined, "ImageOpContextWebGL2.makeDrawableImageRef"))
        const newInfo: DrawableImageInfo = {imagePtr, drawableImageRef, descriptor}
        this.drawableImageInfoByImageRefId.set(imagePtr.ref.id, newInfo)
        return newInfo.imagePtr
    }

    private getDrawableImageInfo(imageRefId: ImageRefId): DrawableImageInfo {
        const info = this.drawableImageInfoByImageRefId.get(imageRefId)
        if (!info) {
            throw new Error(`Drawable image ${imageRefId} not found`)
        }
        return info
    }

    private getTemporaryImage(id: ImageRefId): ImageWebGL2 {
        const image = this.temporaryImageByImageRefId.get(id)
        if (!image) {
            throw new Error(`Image ${id} not found`)
        }
        image.addRef()
        return image
    }

    private async getDrawableImage(imageRefId: ImageRefId): Promise<ImageWebGL2> {
        const drawableImageCache = this.drawableImageCache
        if (!drawableImageCache) {
            throw new Error("Drawable image cache not available")
        }
        const info = this.getDrawableImageInfo(imageRefId)
        const createNewDrawableImage = async (): Promise<ImageWebGL2> => {
            const newHalImage = await drawableImageCache.createDrawableImageFromDataObjectOrDescriptor(
                info.drawableImageRef,
                getHalImageDescriptor(info.descriptor),
                getHalImageOptions(info.descriptor),
            )
            return new ImageWebGL2("drawable", imageRefId, undefined, newHalImage)
        }
        if (drawableImageCache.hasDrawableImage(info.drawableImageRef)) {
            // the image exists already
            const image = await drawableImageCache.getWebGl2ImageByRef(info.drawableImageRef)
            if (isDescriptorCompatible(image.descriptor, info.descriptor)) {
                return image
            } else {
                // the descriptor of the image does not match the requested descriptor
                console.warn("The descriptor of the drawable image does not match the requested descriptor. Creating a new image.")
                // remove the old image without disposing the hal-image
                drawableImageCache.removeDrawableImage(info.drawableImageRef, false)
                // try to preserve as much data as possible
                const newImage = await createNewDrawableImage()
                await this.halBlitter.paint(newImage.halImage, [image.halImage]) // TODO why is this not copying the contents ???
                // dispose the old hal-image
                image.halImage.dispose()
                return newImage
            }
        } else {
            // create a new image
            return createNewDrawableImage()
        }
    }

    async getDataObjectImage(dataObjectId: string): Promise<ImageWebGL2> {
        const texturesApi = this.texturesApi
        if (!texturesApi) {
            throw new Error("Textures API not available")
        }
        let image = this.dataObjectImageByDataObjectId.get(dataObjectId)
        if (!image) {
            if (TextureEditorSettings.EnableAllocTrace) {
                console.log(`ImageOpContextWebGL2: loading data-object image ${dataObjectId}`)
            }
            const paintableImage = await texturesApi.createHalImageFromDataObject(this.halContext, dataObjectId, {
                downloadFormat: "jpg",
                preferredOutputFormat: TextureEditorSettings.PreviewProcessingImageFormat,
            })
            image = new ImageWebGL2("data-object", dataObjectId, () => paintableImage.dispose(), paintableImage, undefined, `getDataObjectImage`)
            this.dataObjectImageByDataObjectId.set(dataObjectId, image)
        }
        image.addRef()
        return image
    }

    private halBlitter: HalPainterImageBlit
    private halImageCache: ImageCacheWebGL2
    private temporaryImageByImageRefId = new Map<ImageRefId, ImageWebGL2>()
    private drawableImageInfoByImageRefId = new Map<ImageRefId, DrawableImageInfo>()
    private dataObjectImageRefByDataObjectId = new Map<string, ImageRef>()
    private dataObjectImageByDataObjectId = new Map<string, ImageWebGL2>()
    private imageCompositorByCompositingFunction = new Map<string, HalPainterImageCompositor>()
    private primitivePainterByShadingFunction = new Map<string, HalPainterPrimitive>()
    private currentlyProcessing = false
    private nextImageRefId = 1
}

type DrawableImageInfo = {imagePtr: ImagePtr; drawableImageRef: DrawableImageRef; descriptor: ImageDescriptor}
