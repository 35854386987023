import {Component, computed, ElementRef, forwardRef, inject, input, output, viewChild} from "@angular/core"
import {MaterialAssignment, MaterialAssignmentParameters} from "@cm/lib/templates/nodes/material-assignment"
import {CardComponent} from "@common/components/cards/card/card.component"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {AsyncPipe} from "@angular/common"
import {from, of, switchMap} from "rxjs"
import {BaseInspectorComponent} from "@template-editor/components/inspectors/base-inspector/base-inspector.component"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {TemplateNodeComponent} from "@template-editor/components/template-node/template-node.component"
import {SelectionPossibilities, ValueSlotComponent} from "app/template-editor/components/value-slot/value-slot.component"
import {SelectMaterialComponent} from "@app/platform/components/materials/select-material/select-material.component"
import {Settings} from "@common/models/settings/settings"
import {TriggeredDialogComponent} from "@common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {MaterialFilterInput} from "@api"
import {DialogService} from "@common/services/dialog/dialog.service"
import {toSignal, toObservable} from "@angular/core/rxjs-interop"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"

@Component({
    selector: "cm-material-assignment-inspector",
    standalone: true,
    templateUrl: "./material-assignment-inspector.component.html",
    styleUrl: "./material-assignment-inspector.component.scss",
    imports: [
        CardComponent,
        AsyncPipe,
        MatMenuModule,
        MatTooltipModule,
        TemplateNodeComponent,
        forwardRef(() => ValueSlotComponent),
        TriggeredDialogComponent,
        SelectMaterialComponent,
        ThumbnailComponent,
        DataObjectThumbnailComponent,
    ],
})
export class MaterialAssignmentInspectorComponent extends BaseInspectorComponent<MaterialAssignment | null> {
    label = input<string | undefined>(undefined)
    labelText = computed(() => this.label() ?? "Material Assignment")
    highlighted = input(false)
    requestUpdateMaterialAssignment = output<MaterialAssignment | null>()
    private dragImage = viewChild.required<TemplateNodeComponent>("dragImage")

    private sdk = inject(SdkService)
    dialogService = inject(DialogService)
    organizations = inject(OrganizationsService)
    drag = inject(TemplateNodeDragService)
    material = computed(() => {
        const parameters = this.parameters()
        if (!parameters) return undefined
        return parameters.node
    })
    private thumbnailData = toSignal(
        toObservable(this.material).pipe(
            switchMap((material) => {
                if (!material) return of(Settings.NO_MATERIAL_URL)
                if (material instanceof MaterialReference)
                    return from(this.sdk.gql.getMaterialAssignmentDetailsForMaterialAssignmentInspector({legacyId: material.parameters.materialRevisionId}))
                else return of(Settings.SWITCH_MATERIAL_URL)
            }),
        ),
        {
            initialValue: Settings.IMAGE_NOT_AVAILABLE_SMALL_URL,
        },
    )
    thumbnailUrl = computed(() => {
        const thumbnailData = this.thumbnailData()
        if (typeof thumbnailData === "string") return thumbnailData
        else return undefined
    })
    thumbnailObject = computed(() => {
        const thumbnailData = this.thumbnailData()
        if (typeof thumbnailData === "string") return undefined
        return thumbnailData.materialRevision.material.galleryImage
    })
    isReference = computed(() => {
        const node = this.node()
        if (!node) return false
        const material = this.material()
        if (!material) return false
        return !(material.parents.size === 1 && material.parents.has(node))
    })
    sideLabel = computed(() => {
        const parameters = this.parameters()
        if (!parameters) return undefined
        return this.sidePossibilities.find((x) => x.value === parameters.side)?.name ?? "Not set"
    })

    materialFilters: MaterialFilterInput = {hasCyclesMaterial: true}

    removeAssignment() {
        this.requestUpdateMaterialAssignment.emit(null)
    }

    openSelectMaterialDialog() {
        this.dialogService.selectInDialog(SelectMaterialComponent, {
            filters: this.materialFilters,
            onSelect: async ({id}: {id: string}) => {
                const {material} = await this.sdk.gql.getMaterialDetailsForTemplateTreeAdd({id: id})
                const {latestCyclesRevision} = material

                if (latestCyclesRevision && latestCyclesRevision.legacyId !== undefined) {
                    const materialReference = new MaterialReference({
                        name: material.name ?? "New Material Reference",
                        materialRevisionId: latestCyclesRevision.legacyId,
                    })

                    const materialAssignment = this.node()

                    if (materialAssignment)
                        return this.requestUpdateMaterialAssignment.emit(
                            materialAssignment.clone({cloneSubNode: () => true, parameterOverrides: {node: materialReference}}),
                        )
                    else return this.requestUpdateMaterialAssignment.emit(new MaterialAssignment({node: materialReference, side: "front"}))
                }
            },
        })
    }

    gotoReference() {
        if (!this.isReference()) return

        const material = this.material()
        if (!material) return

        this.sceneManagerService.selectNode({templateNode: material, part: "root"})
    }

    sidePossibilities: SelectionPossibilities<MaterialAssignmentParameters["side"]> = [
        {value: "front", name: "Front"},
        {value: "back", name: "Back"},
        {value: "double", name: "Double"},
    ]

    dragStart(event: DragEvent) {
        const material = this.material()
        if (!material) {
            event.preventDefault()
            return
        }

        event.dataTransfer?.setDragImage(this.dragImage().getDragImage(), 0, 0)

        this.drag.dragStart(event, material)
    }
}
