<cm-routed-dialog #overlayDialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" (close)="overlayClosed()">
    <ng-container cm-overlay-content>
        <div class="cm-editor-container">
            <cm-action-bar class="cm-close-button" (click)="closeOverlay()">
                <ng-container cm-action-items>
                    <cm-action-item cmIconClass="fas fa-times"></cm-action-item>
                </ng-container>
            </cm-action-bar>
            <cm-picture-viewer></cm-picture-viewer>
        </div>
    </ng-container>
</cm-routed-dialog>
