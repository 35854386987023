import {Component, inject, Input} from "@angular/core"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Labels} from "@labels"
import {AssetBoxesFragment, DownloadResolution} from "@api"
import {RouterLink} from "@angular/router"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"

@Component({
    selector: "cm-asset-boxes",
    standalone: true,
    imports: [RouterLink, DataObjectThumbnailComponent],
    templateUrl: "./asset-boxes.component.html",
    styleUrl: "./asset-boxes.component.scss",
})
export class AssetBoxesComponent {
    @Input({required: true}) assetId!: string

    asset?: AssetBoxesFragment

    sdk = inject(SdkService)

    protected readonly Labels = Labels

    ngOnInit() {
        this.sdk.gql.assetBoxes({assetId: this.assetId}).then(({asset}) => {
            this.asset = asset
        })
    }

    protected readonly DownloadResolution = DownloadResolution
}
