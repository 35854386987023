import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "@template-editor/components/inspectors/base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ImageInspectorComponent} from "app/template-editor/components/inspectors/image-inspector/image-inspector.component"
import {MatMenuModule} from "@angular/material/menu"
import {SelectionPossibilities, ValueSlotComponent} from "@template-editor/components/value-slot/value-slot.component"
import {ProceduralMesh} from "@cm/lib/templates/nodes/procedural-mesh"
import {commonGeometryGraphTable} from "@cm/lib/geometry-processing/common-geometry-graphs"
import {ButtonComponent} from "../../../../common/components/buttons/button/button.component"
import * as changeCase from "change-case"
import {Mesh} from "@cm/lib/templates/node-types"

@Component({
    selector: "cm-mesh-inspector",
    standalone: true,
    templateUrl: "./mesh-inspector.component.html",
    styleUrl: "./mesh-inspector.component.scss",
    imports: [BaseInspectorComponent, InspectorSectionComponent, ImageInspectorComponent, MatMenuModule, ValueSlotComponent, ButtonComponent],
})
export class MeshInspectorComponent extends BaseInspectorComponent<Mesh> {
    proceduralMesh = computed(() => {
        const node = this.node()
        if (node instanceof ProceduralMesh) return node
        return undefined
    })

    geometryPossibilities: SelectionPossibilities<string> = Object.keys(commonGeometryGraphTable).map((key) => ({
        value: key,
        name: changeCase.capitalCase(key),
    }))

    addMaterialSlot() {
        this.sceneManagerService.modifyTemplateGraph(() => {
            const {materialAssignments} = this.parameters()
            for (let slotIdx = 0; slotIdx < 100; slotIdx++) {
                const slot = slotIdx.toString()
                if (!(slot in materialAssignments.parameters)) {
                    materialAssignments.parameters[slot] = null
                    break
                }
            }
        })
    }

    removeEmptyMaterialSlots() {
        this.sceneManagerService.modifyTemplateGraph(() => {
            const {materialAssignments} = this.parameters()
            for (const slot in materialAssignments.parameters) {
                if (materialAssignments.parameters[slot] === null) {
                    delete materialAssignments.parameters[slot]
                }
            }
        })
    }
}
