// @ts-strict-ignore
import {inject, Injectable} from "@angular/core"
import {ImageProcessingActions, TypedImageData} from "@cm/lib/image-processing/image-processing-actions"
import {ImageProcessingNodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageProcessingInput, imageProcessingTask} from "@cm/lib/job-task/image-processing"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {jsonToFile} from "@legacy/helpers/utils"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {map, Observable} from "rxjs"

@Injectable()
export class ImageProcessingService extends ImageProcessingActions {
    sdk = inject(SdkService)
    uploadService = inject(UploadGqlService)

    constructor(workerService: WebAssemblyWorkerService) {
        super(workerService)
    }

    toCanvasImageData(image: TypedImageData | null): Observable<ImageData | null> {
        return this.convertToUint8RGBA(image).pipe(
            map((data) => new ImageData(new Uint8ClampedArray(data.buffer, data.byteOffset, data.byteLength), image.width, image.height)),
        )
    }

    async createImageProcessingTask(graph: ImageProcessingNodes.Output, data: {organizationId: string}, filename = "image-processing.json") {
        const file: File = jsonToFile(graphToJson(graph), filename)
        const dataObject = await this.uploadService.createAndUploadDataObject(
            file,
            {organizationId: data.organizationId},
            {showUploadToolbar: false, processUpload: false},
        )
        const input: ImageProcessingInput = {
            graphDataObjectReference: JobNodes.dataObjectReference(dataObject.legacyId),
        }
        return JobNodes.task(imageProcessingTask, {
            input: JobNodes.value(input),
        })
    }
}
