import {Injector} from "@angular/core"

/**
 * Ugly hack to circumvent circular import.
 * Import this file in the app.module.ts and set the injector
 * rather than importing app.module.ts in a service/component.
 * TODO: refactor imports and remove this hack
 */
export const GlobalAppInjector = {
    injector: null as Injector | null,
}
