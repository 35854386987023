<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Size </ng-container>
    <ng-container cm-content>
        <div class="cm-size">
            <cm-value-slot [node]="node()" [key]="'width'" [label]="'Width'"></cm-value-slot>
            <div class="cm-multiplier">×</div>
            <cm-value-slot [node]="node()" [key]="'height'" [label]="'Height'"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Light Portal </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
