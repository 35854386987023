<mat-menu #menuRef="matMenu" [overlapTrigger]="false">
    @for (child of _items; track child) {
        @if (child.subItems && child.subItems.length > 0) {
            <cm-multilevel-menu #subMenu [items]="child.subItems" (itemClicked)="onMenuItemClicked($event)"></cm-multilevel-menu>

            @if (subMenu?.menuRef) {
                <button mat-menu-item color="primary" [matMenuTriggerFor]="subMenu.menuRef">
                    <span>{{ child.text }}</span>
                </button>
            }
        }

        @if (!child.subItems || child.subItems.length === 0) {
            <button mat-menu-item (click)="onMenuItemClicked(child)">
                <span>{{ child.text }}</span>
            </button>
        }
    }
</mat-menu>
