import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {LoadGraphNew} from "@src/templates/runtime-graph/nodes/load-graph-new"

const templateReferenceParameters = z.object({
    templateRevisionId: z.number(),
})
export type TemplateReferenceParameters = z.infer<typeof templateReferenceParameters>

@registerNode
export class TemplateReference
    extends DeclareTemplateNode({parameters: templateReferenceParameters}, {}, {nodeClass: "TemplateReference"})
    implements EvaluableTemplateNode<TemplateGraph>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return scope.node(LoadGraphNew, {
            sceneManager: evaluator.templateContext.sceneManager,
            templateRevisionId: this.parameters.templateRevisionId,
        }).graph
    }
}

export type TemplateReferenceFwd = TemplateNode<TemplateReferenceParameters> & EvaluableTemplateNode<TemplateGraph>
