<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Material Reference </ng-container>
    <ng-container cm-content>
        <cm-card [defaultThumbnail]="false" (click)="onClick()">
            <ng-container cm-thumbnail>
                @if (thumbnailObject(); as thumbnailData) {
                    <cm-data-object-thumbnail [dataObjectId]="thumbnailData.id"></cm-data-object-thumbnail>
                } @else {
                    @if (thumbnailUrl(); as thumbnailData) {
                        <cm-thumbnail [url]="thumbnailData"></cm-thumbnail>
                    }
                }
            </ng-container>
            <ng-container cm-title>
                {{ title() }}
            </ng-container>
        </cm-card>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <div class="cm-material-revision-settings">
            <cm-value-slot [node]="node()" [key]="'materialRevisionId'" [label]="'Revision'" [selectionPossibilities]="revisionPossibilities()"></cm-value-slot>

            @if (latestCyclesRevisionId() !== undefined && latestCyclesRevisionId() !== materialRevisionId()) {
                <cm-button class="cm-action-button" (click)="updateToLatestCyclesRevision()">
                    <ng-container cm-text> <i class="far fa-arrow-up"></i> Update </ng-container>
                </cm-button>
            } @else {
                <div class="cm-latest"><i class="far fa-check"></i>Latest</div>
            }
        </div>
    </ng-container>
</cm-inspector-section>
