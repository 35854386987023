import {OperatorToolboxBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"
import {TilingAreaToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-toolbox-item"
import paper from "paper"
import {Box2Like} from "@cm/lib/math/box2"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"

export class TilingToolbox extends OperatorToolboxBase<OperatorTiling> {
    constructor(operator: OperatorTiling) {
        super(operator, SelectionMode.SingleSelect, true)

        this.tilingArea = new TilingAreaToolboxItem(this)
        this.viewChange.subscribe(() => this.onViewChanged())
    }

    clearDebugRects() {
        this.debugRects.forEach((rect) => rect.remove())
        this.debugRects = []
    }

    createDebugRect(box: Box2Like, color: string) {
        this.tilingArea.beginPaperCreation()
        const rect = new paper.Path.Rectangle(new paper.Point(box.x, box.y), new paper.Size(box.width, box.height))
        rect.strokeColor = new paper.Color(color)
        rect.strokeWidth = 1 / this.zoomLevel
        this.debugRects.push(rect)
    }

    private onViewChanged() {
        this.debugRects.forEach((rect) => (rect.strokeWidth = 1 / this.zoomLevel))
    }

    readonly tilingArea: TilingAreaToolboxItem
    private debugRects: paper.Path[] = []
}
