<div class="cm-container">
    @if ($sceneManagerService().$pendingTasks() > 0) {
        <a class="cm-icon-container" [tp]="'Loading ' + $sceneManagerService().$pendingTasks() + ' tasks'" [tpAppendTo]="tooltipContainer">
            <cm-loading-spinner-icon class="cm-mini-spinner"></cm-loading-spinner-icon>
        </a>
    }
    @if ($stlDownloadEnabled()) {
        <a class="cm-icon-container" (click)="exportStlClicked()" tp="Download STL" [tpAppendTo]="tooltipContainer">
            <i class="far fa-arrow-to-bottom"></i>
        </a>
    }
    @if ($snapshotEnabled()) {
        <a
            class="cm-icon-container"
            (click)="actionMenuService.captureSnapshot(this.threeSceneManagerService)"
            tp="Capture snapshot"
            [tpAppendTo]="tooltipContainer"
        >
            <i class="far fa-camera"></i>
        </a>
    }
    @if ($fullscreenAvailable()) {
        <a class="cm-icon-container" (click)="toggleFullscreen()" [tp]="$inFullscreen() ? 'Exit full screen' : 'Full screen'" [tpAppendTo]="tooltipContainer">
            <i [ngClass]="$inFullscreen() ? 'far fa-compress' : 'far fa-expand'"></i>
        </a>
    }
    @if ($arEnabled()) {
        <a class="cm-icon-container" (click)="viewInArClicked()" tp="View in AR" [tpAppendTo]="tooltipContainer">
            <i class="far fa-cube"></i>
            <span class="cm-ar-icon-text"> AR </span>
            <div
                [tp]="arDialog"
                tpVariation="popper"
                [tpAppendTo]="tooltipContainer"
                [tpHideOnClick]="false"
                [tpIsVisible]="arModelGcsObjectName !== undefined"
                [tpOffset]="tpOffset"
            ></div>
            <ng-template #arDialog let-hide>
                <cm-ar-dialog (close)="arModelGcsObjectName = undefined" [gcsObjectName]="arModelGcsObjectName"> </cm-ar-dialog>
            </ng-template>
        </a>
    }
</div>
<div class="tooltip-container" #tooltipContainer></div>
