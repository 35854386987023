import {Component, EventEmitter, inject, Output, computed} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonToggleModule} from "@angular/material/button-toggle"
import {MatTooltipModule} from "@angular/material/tooltip"
import {NextActor, UserListItemFragment} from "@api"
import {UsersService} from "@common/services/users/users.service"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"

@Component({
    selector: "cm-assign-user-dialog",
    templateUrl: "./assign-user-dialog.component.html",
    styleUrls: ["./assign-user-dialog.component.scss"],
    standalone: true,
    imports: [MatTooltipModule, MatButtonToggleModule, FormsModule, UserThumbnailComponent],
})
export class AssignUserDialogComponent extends AssignUserComponent {
    @Output() userChange = new EventEmitter<{id: string} | undefined>()
    @Output() nextActorChange = new EventEmitter<NextActor>()
    readonly NextActor = NextActor

    users = inject(UsersService)
    $visibleUsers = computed(() => this.users.$visible())

    updateNextActor(nextActor: NextActor) {
        this.nextActor = nextActor
        this.nextActorChange.emit(nextActor)
        this.update()
    }

    async assignUser(user: Pick<UserListItemFragment, "id" | "firstName" | "lastName" | "galleryImage">) {
        this.userChange.emit(user)
        this.update()
    }

    async deleteAssignedUser() {
        this.userChange.emit(undefined)
        this.update()
    }
}
