import {Component, DestroyRef, inject, signal} from "@angular/core"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {MatPaginatorModule} from "@angular/material/paginator"
import {MatTableModule} from "@angular/material/table"
import {RouterOutlet} from "@angular/router"
import {TaskTypesTableItemFragment, TaskTypesTableItemsGQL} from "@api"
import {OverflowableTextComponent, PaginatorComponent} from "@common/components/data"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {EntityAddComponent} from "@common/components/entity/entity-add/entity-add.component"
import {TypedMatCellDefDirective} from "@common/directives"
import {BreakpointsService} from "@common/services/breakpoints/breakpoints.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {BaseTableComponent} from "@platform/components/base/base-table/base-table.component"
import {EditTaskTypeDialogComponent, EditTaskTypeDialogData} from "@platform/components/task-types/edit-task-type-dialog/edit-task-type-dialog.component"
import {PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {BehaviorSubject, of, switchMap, tap} from "rxjs"
import {v4 as uuid4} from "uuid"

@Component({
    selector: "cm-task-types-table",
    templateUrl: "./task-types-table.component.html",
    styleUrls: ["./task-types-table.component.scss"],
    standalone: true,
    imports: [
        MatTableModule,
        MatPaginatorModule,
        RouterOutlet,
        EntityAddComponent,
        TableCellComponent,
        TypedMatCellDefDirective,
        OverflowableTextComponent,
        PaginatorComponent,
    ],
})
export class TaskTypesTableComponent extends BaseTableComponent<TaskTypesTableItemFragment> {
    breakpoints = inject(BreakpointsService)
    can = inject(PermissionsService)
    destroyRef = inject(DestroyRef)
    dialog = inject(MatDialog)
    taskTypesTableItems = inject(TaskTypesTableItemsGQL)

    refreshAll$ = new BehaviorSubject<void>(undefined)

    $columns = signal(["taskType", "queueGroup", "defaultTimeout", "maxTimeout", "cancelTimeout", "endpoint"])
    $data = toSignal(
        this.refreshAll$.pipe(
            switchMap(() =>
                this.dataLoader
                    .watchPagedDataItems$<TaskTypesTableItemFragment>(
                        of((skip, take) => this.taskTypesTableItems.fetch({skip, take})),
                        "global",
                    )
                    .pipe(
                        takeUntilDestroyed(this.destroyRef),
                        tap((result) => this.paging.$totalCount.set(result.totalCount ?? 0)),
                    ),
            ),
        ),
        {initialValue: PLACEHOLDER_ITEMS<TaskTypesTableItemFragment>()},
    )

    // need arrow functions here to keep `this` in scope
    private onActionSuccess = () => {
        this.refreshAll$.next()
    }

    //TODO: show message
    private onActionError = () => {
        this.refreshAll$.next()
    }

    private showEditDialog(item: TaskTypesTableItemFragment, edit = false): void {
        const dialogRef = this.dialog.open<EditTaskTypeDialogComponent, EditTaskTypeDialogData, EditTaskTypeDialogData>(EditTaskTypeDialogComponent, {
            width: "500px",
            data: {
                taskTypeInfo: item,
                edit,
            },
        })
        dialogRef.afterClosed().subscribe((result) => {
            const info = result?.taskTypeInfo
            if (!info?.taskType) return
            if (result?.delete) {
                this.sdk.gql.taskTypesTableDeleteTaskType({id: info.id}).then(this.onActionSuccess).catch(this.onActionError)
            } else if (edit) {
                this.sdk.gql
                    .taskTypesTableUpdateTaskType({
                        input: {
                            id: info.id,
                            taskType: info.taskType,
                            queueGroup: info.queueGroup,
                            endpoint: info.endpoint,
                            defaultTimeout: info.defaultTimeout,
                            maxTimeout: info.maxTimeout,
                            cancelTimeout: info.cancelTimeout,
                        },
                    })
                    .then(this.onActionSuccess)
                    .catch(this.onActionError)
            } else {
                this.sdk.gql
                    .taskTypesTableCreateTaskType({
                        input: {
                            id: info.id,
                            taskType: info.taskType,
                            queueGroup: info.queueGroup,
                            endpoint: info.endpoint,
                            defaultTimeout: info.defaultTimeout,
                            maxTimeout: info.maxTimeout,
                            cancelTimeout: info.cancelTimeout,
                        },
                    })
                    .then(this.onActionSuccess)
                    .catch(this.onActionError)
            }
        })
    }

    rowClick(item: {data: TaskTypesTableItemFragment}): void {
        this.showEditDialog({...item.data}, true)
    }

    createItem() {
        this.showEditDialog({
            id: uuid4(),
            taskType: undefined!,
            queueGroup: undefined!,
            endpoint: undefined,
            defaultTimeout: undefined,
            maxTimeout: undefined,
            cancelTimeout: undefined,
        })
    }
}
