import {Injectable} from "@angular/core"
import {Observable, Subject} from "rxjs"

@Injectable()
export class NavigationBarService {
    private progressSubject: Subject<number> = new Subject<number>()
    progress: Observable<number> = this.progressSubject.asObservable()

    customerFilterActive = true
    private customerFilterChangeSource = new Subject<boolean>()
    customerFilterChange$ = this.customerFilterChangeSource.asObservable()

    constructor() {}

    customerFilterChanged(value: boolean): void {
        this.customerFilterActive = value
        this.customerFilterChangeSource.next(value)
    }

    showProgressBar(progress = -1): void {
        this.progressSubject.next(progress)
    }

    hideProgressBar(): void {
        this.progressSubject.next(100)
    }
}
