import {NodeGraph, nodeGraph} from "@src/graph-system/node-graph"

export const isNodeInstance =
    <T extends NodeGraph>(nodeClass: string) =>
    (instance: NodeGraph): instance is T =>
        instance.getNodeClass() === nodeClass

export const nodeInstanceFwd = <T extends NodeGraph<any, any, any>>(nodeClass: string) =>
    nodeGraph.refine(isNodeInstance<T>(nodeClass), {message: `Expected ${nodeClass}`})
export const nodeInstance = <T extends NodeGraph<any, any, any>>(targetClass: new (...args: any[]) => T) => {
    const nodeClass = (targetClass as any).getNodeClass()
    return nodeInstanceFwd<T>(nodeClass)
}
