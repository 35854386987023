import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2Shader} from "@common/helpers/webgl2/webgl2-shader"

export class WebGl2ShaderCache {
    dispose(): void {
        for (const contextShaderCache of this.cacheEntryByContext.values()) {
            contextShaderCache.contextShaderCache.dispose()
        }
        this.cacheEntryByContext.clear()
    }

    getOrCreateShader(context: WebGl2Context, shadingFunction: string): WebGl2Shader {
        let cacheEntry: ContextCacheEntry | undefined = this.cacheEntryByContext.get(context)
        if (!cacheEntry) {
            cacheEntry = {
                contextShaderCache: new WebGl2ContextShaderCache(this, context),
                refCount: 0,
            }
            this.cacheEntryByContext.set(context, cacheEntry)
        }
        cacheEntry.refCount++
        const contextShaderCache = cacheEntry.contextShaderCache
        return contextShaderCache.getOrCreateShader(shadingFunction)
    }

    releaseShader(context: WebGl2Context, shadingFunction: string) {
        const cacheEntry = this.cacheEntryByContext.get(context)
        if (!cacheEntry) {
            throw Error("Context not found")
        }
        const contextShaderCache = cacheEntry.contextShaderCache
        contextShaderCache.releaseShader(shadingFunction)
        if (contextShaderCache.isEmpty) {
            cacheEntry.refCount--
            if (cacheEntry.refCount <= 0) {
                cacheEntry.contextShaderCache.dispose()
                this.cacheEntryByContext.delete(context)
            }
        }
    }

    private readonly cacheEntryByContext = new Map<WebGl2Context, ContextCacheEntry>()
}

type ContextCacheEntry = {
    contextShaderCache: WebGl2ContextShaderCache
    refCount: number
}

class WebGl2ContextShaderCache {
    constructor(
        private shaderCache: WebGl2ShaderCache,
        private context: WebGl2Context,
    ) {}

    dispose(): void {
        for (const contextShaderCache of this.cacheEntryBySource.values()) {
            contextShaderCache.shader.dispose()
        }
        this.cacheEntryBySource.clear()
    }

    getOrCreateShader(source: string): WebGl2Shader {
        let cacheEntry: ShaderCacheEntry | undefined = this.cacheEntryBySource.get(source)
        if (!cacheEntry) {
            cacheEntry = {
                shader: new WebGl2Shader(this.context, source),
                refCount: 0,
            }
            this.cacheEntryBySource.set(source, cacheEntry)
        }
        cacheEntry.refCount++
        return cacheEntry.shader
    }

    releaseShader(source: string): void {
        const cacheEntry = this.cacheEntryBySource.get(source)
        if (!cacheEntry) {
            throw Error("Context not found")
        }
        cacheEntry.refCount--
        if (cacheEntry.refCount <= 0) {
            cacheEntry.shader.dispose()
            this.cacheEntryBySource.delete(source)
        }
    }

    get isEmpty(): boolean {
        return this.cacheEntryBySource.size === 0
    }

    private readonly cacheEntryBySource = new Map<string, ShaderCacheEntry>()
}

type ShaderCacheEntry = {
    shader: WebGl2Shader
    refCount: number
}
