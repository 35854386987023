import {Pipe, PipeTransform} from "@angular/core"
import * as moment from "moment"

@Pipe({
    name: "cmDuration",
    standalone: true,
})
export class DurationFromMillisecondsPipe implements PipeTransform {
    transform(value: number): string {
        const days = moment.duration(value).days()
        if (days > 0) {
            return `${moment.utc(value).format("HH:mm:ss")} (+${days} days)`
        } else {
            return moment.utc(value).format("HH:mm:ss")
        }
    }
}
