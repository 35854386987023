import {environment} from "@environment"

const GQL_API_ENDPOINT = `${environment.gqlApiEndpoint}/graphql`
const GCS_ENDPOINT = "https://storage.googleapis.com/"

export const EndpointUrls = {
    GQL_API_ENDPOINT,
    AR_REDIRECT_URL: new URL("ar", GQL_API_ENDPOINT),
    DATA_OBJECT_PROCESSING_URL: "assets/images/processing-data-object.jpg",
    IMAGE_NOT_AVAILABLE_URL: "assets/images/no_image_available.jpg",
    GoogleStorage: (gcsBucketName: string, gcsFileName: string, serviceUrl?: string) => {
        return `${serviceUrl || GCS_ENDPOINT}${gcsBucketName}/${gcsFileName}`
    },
}
