// @ts-strict-ignore
import {KeyValue} from "@angular/common"
import {HttpClient, HttpEventType, HttpParams, HttpResponse} from "@angular/common/http"
import {Injectable, NgZone} from "@angular/core"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltip} from "@angular/material/tooltip"
import {Params} from "@angular/router"
import {isApple} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {Settings} from "@common/models/settings/settings"
import {
    combineLatest,
    filter,
    forkJoin,
    map,
    mapTo,
    Observable,
    of as observableOf,
    ReplaySubject,
    shareReplay,
    Subject,
    Subscriber,
    Subscription,
    take,
} from "rxjs"
import {v4 as uuid4} from "uuid"

@Injectable()
export class UtilsService {
    dragEnterListener: any
    dropListener: any
    dragOverListener: any
    dragLeaveListener: any

    public keyValueStore: KeyValueStore<unknown>

    constructor(
        private snackBar: MatSnackBar,
        private zone: NgZone,
        private http: HttpClient,
    ) {
        this.keyValueStore = new KeyValueStore()
    }

    static mimeTypeMatch(mimeTypeFilter: MimeType, mimeType: string | null | undefined): boolean {
        const mimeTypeStr: string = mimeTypeFilter.toString()
        if (mimeTypeStr === "*" || mimeTypeStr === "*/*") {
            return true
        }

        if (!mimeType) {
            return false
        }

        // Return false if any of the MIME types is incorrectly defined
        let [filterType, filterSubtype] = mimeTypeStr.split("/")
        let [type, subtype] = mimeType.split("/")
        if (filterSubtype === undefined || subtype === undefined) {
            return false
        }

        filterType = filterType.toLowerCase()
        type = type.toLowerCase()

        if (type === filterType) {
            if (subtype === filterSubtype || filterSubtype === "*") {
                return true
            }
        }
        return false
    }

    static queryParamsEqual(params1: any, params2: any): boolean {
        if (Object.keys(params1).length !== Object.keys(params2).length) {
            return false
        }
        for (const key in params1) {
            if (!params2.hasOwnProperty(key)) {
                return false
            }
            if (Array.isArray(params1[key])) {
                if (!Array.isArray(params2[key]) || params1[key].length !== params2[key].length) {
                    return false
                }
                for (let i = 0; i < params1[key].length; i++) {
                    if (String(params1[key][i]) !== String(params2[key][i])) {
                        return false
                    }
                }
            } else {
                if (String(params1[key]) !== String(params2[key])) {
                    return false
                }
            }
        }
        return true
    }

    static paramsToHttpParams(params: Params): HttpParams {
        let queryParams: HttpParams = new HttpParams()
        for (const key in params) {
            if (Array.isArray(params[key])) {
                for (const subParam of params[key]) {
                    queryParams = queryParams.append(key, subParam)
                }
            } else {
                queryParams = queryParams.set(key, params[key])
            }
        }
        return queryParams
    }

    paramsToDictionary(params: Params): Dictionary<any> {
        const paramsDictionary: Dictionary<any> = {}

        const parseVal = (val: string) => {
            const n = Number.parseFloat(val)
            if (Number.isNaN(n)) return val
            else return n
        }

        for (const key in params) {
            if (key == "search") {
                paramsDictionary[key] = params["search"]
            } else {
                const values = []
                const paramList = params[key].split(",")
                for (const value of paramList) values.push(parseVal(value))
                paramsDictionary[key] = values
            }
        }
        return paramsDictionary
    }

    dictionaryToParams(dictionary: Dictionary<any>): Dictionary<string> {
        const newDictionary: Dictionary<string> = {}
        for (const key in dictionary) {
            if (dictionary[key] && key === "search") {
                newDictionary[key] = dictionary[key]
            } else if (dictionary[key] && dictionary[key].length > 0) {
                newDictionary[key] = dictionary[key].join(",")
            }
        }
        return newDictionary
    }

    static createIdFilter(entities: {id: number}[], propertyName: string, filters: HttpParams = new HttpParams()): HttpParams {
        const entityIds: any[] = []
        for (const entity of entities) {
            entityIds.push((entity as any)[propertyName])
        }
        filters = filters.append("id", entityIds.join(","))
        return filters
    }

    orderByKey(a: any, b: any): any {
        //FIXME: Angular keyvalue pipe converts Record<number, any> key values to string, so that is why we convert values here.
        const aNumber = Number(a.key)
        const bNumber = Number(b.key)
        if (!isNaN(aNumber) && !isNaN(bNumber)) {
            return a.key - b.key
        } else {
            return 0
        }
    }

    /**
     * Initializes a drop zone and uploads the files automatically.
     * It makes sure to remove the handler before initializing them, so it can be called multiple times if necessary.
     * @param dropZone The HTML element which receives the drag/drop etc. events.
     * @param maxFiles Maximum number of files allowed.
     * @param dropZoneActive An object containing a boolean property which is going to be set to true or false depending on the drag events.
     * @param mimeTypeFilter Defines which files are accepted. Everything is accepted by default.
     * @returns {Observable<File>}
     */
    initDropZoneHelper(
        dropZone: EventTarget,
        dropZoneActive: {[value: string]: boolean} = {},
        maxFiles = 100,
        mimeTypeFilter: MimeType = MimeType.All,
    ): Observable<File> {
        const droppedFile = new Subject<File>()
        const droppedFile$ = droppedFile.asObservable()
        let counter = 0

        //To learn more about the hack used here read this: https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
        dropZone.removeEventListener("dragenter", this.dragEnterListener)
        this.dragEnterListener = (event: any) => {
            if (event.dataTransfer.types.indexOf("Files") == -1) {
                return
            }
            event.preventDefault()
            counter++
            dropZoneActive.value = true
        }
        dropZone.addEventListener("dragenter", this.dragEnterListener)

        dropZone.removeEventListener("dragleave", this.dragLeaveListener)
        this.dragLeaveListener = (event: DragEvent) => {
            if (event.dataTransfer?.types?.indexOf("Files") == -1) {
                return
            }
            counter--
            if (counter === 0) {
                dropZoneActive.value = false
            }
        }
        dropZone.addEventListener("dragleave", this.dragLeaveListener)

        // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#prevent_the_browsers_default_drag_behavior
        dropZone.removeEventListener("dragover", this.dragOverListener)
        this.dragOverListener = (event: any) => {
            if (event.dataTransfer.types.indexOf("Files") == -1) {
                return
            }
            event.stopPropagation()
            event.preventDefault()
        }
        dropZone.addEventListener("dragover", this.dragOverListener)

        dropZone.removeEventListener("drop", this.dropListener)
        this.dropListener = (event: DragEvent) => {
            if (event.dataTransfer?.types?.indexOf("Files") == -1) {
                return
            }
            counter = 0
            event.stopPropagation()
            event.preventDefault()
            dropZoneActive.value = false
            if (event.dataTransfer?.files?.length > maxFiles) {
                this.snackBar.open("The number of files allowed is " + maxFiles + ".", "", {duration: 3000})
                return
            }
            for (let i = 0; i < event.dataTransfer?.files?.length; i++) {
                const file: File = event.dataTransfer.files[i]
                // Using the so-called magic numbers would be a better solution, but this is good enough for now.
                // https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload/29672957
                let mimeType = file.type
                if (file.name.toLowerCase().endsWith(".exr")) mimeType = "image/x-exr"
                else if (file.name.toLowerCase().endsWith(".hdr")) mimeType = "image/vnd.radiance"

                if (!UtilsService.mimeTypeMatch(mimeTypeFilter, mimeType)) {
                    this.snackBar.open(`Cannot upload file. Only MIME type ${mimeTypeFilter.toString()} is accepted.`, "", {duration: 3000})
                    return
                }
                droppedFile.next(file)
            }
        }
        dropZone.addEventListener("drop", this.dropListener)
        return droppedFile$
    }

    releaseDropZoneHelper(dropZone: EventTarget) {
        dropZone.removeEventListener("dragenter", this.dragEnterListener)
        dropZone.removeEventListener("dragleave", this.dragLeaveListener)
        dropZone.removeEventListener("dragover", this.dragOverListener)
        dropZone.removeEventListener("drop", this.dropListener)
    }

    static getExtension(fileName: string): string {
        const splitName: string[] = fileName.split(".")
        return splitName[splitName.length - 1].toLowerCase()
    }

    static isProjectView(params: Params) {
        const numParams: number = Object.keys(params).length
        let customerParamsLength: number
        if (params["customer"] == undefined) {
            return false
        } else if (!Array.isArray(params["customer"])) {
            customerParamsLength = 1
        } else {
            customerParamsLength = params["customer"].length
        }
        return numParams == 1 && customerParamsLength == 1
    }

    copyToClipboardWithTooltip(text: any, tooltip: MatTooltip): void {
        this.copyToClipboard(text.toString())
        tooltip.message = `Copied: ${text}`
        tooltip.show()
    }

    // https://stackoverflow.com/a/33928558/1373359
    copyToClipboard(text: string): boolean {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            const textArea = document.createElement("textarea")
            textArea.textContent = text
            // Prevent scrolling to bottom of page in MS Edge.
            textArea.style.position = "fixed"
            document.body.appendChild(textArea)
            textArea.select()
            try {
                return document.execCommand("copy") // Security exception may be thrown by some browsers.
            } catch (e) {
                console.warn("Copy to clipboard failed.", e)
                return false
            } finally {
                document.body.removeChild(textArea)
            }
        } else {
            return false
        }
    }

    entityCompareFunction(value1: {id: number}, value2: {id: number}): boolean {
        return value1 && value2 && value1.id === value2.id
    }

    static arrayBufferToFile(arrayBuffer: ArrayBuffer, name: string, mimeType: string): File {
        const blob: Blob = new Blob([arrayBuffer], {type: mimeType})
        return new File([blob], name, {type: mimeType})
    }

    static runningInElectron(): boolean {
        return !!(window as any).require
    }

    getResourceAsBuffer(url: string): Observable<ArrayBuffer> {
        return this.http
            .get(url, {
                responseType: "arraybuffer",
                observe: "events",
                reportProgress: true,
            })
            .pipe(
                filter((event) => event.type === HttpEventType.Response && event.body && true),
                map((event) => new Uint8Array((event as HttpResponse<ArrayBuffer>).body)),
            )
    }

    static printAppVersion(): void {
        console.info(`colormass App version: ${Settings.APP_VERSION}`)
    }
}

class KeyValueStore<ValueType> {
    _map: Map<string, ValueType>

    constructor() {
        this._map = new Map<string, ValueType>()
    }

    add(value: ValueType): string {
        const id = uuid4()
        this._map.set(id, value)
        return id
    }

    get(id: string): ValueType | null {
        return this._map.get(id)
    }

    delete(id: string): boolean {
        return this._map.delete(id)
    }
}

// MIME types
export enum MimeType {
    All = "*",
    Images = "image/*",
    ImageExr = "image/x-exr",
    Zip = "application/x-zip-compressed",
    Pdf = "application/pdf",
    Text = "text/plain",
    Video = "video/*",
}

// File types
// export enum MimeType {Image = 10, Video = 20, Pdf = 30, Archive = 40, Text = 50, Model = 60, ModelObj = 61, ModelDraco = 62, ModelColormass = 63, Other = 100}

// Grid sizes
export enum GridSize {
    Small = 10,
    Medium = 15,
    Large = 20,
}

export interface Dictionary<T> {
    [arg: string]: T
}

export interface Dictionary2<T> {
    [arg: number]: T
}

//export function forkJoinZeroOrMore<T extends unknown[]>(arr: Observable<...T>[]): Observable<any>; //TODO: make this work with variadic tuples
export function forkJoinZeroOrMore<T1>(arr: [Observable<T1>]): Observable<[T1]>
export function forkJoinZeroOrMore<T1, T2>(arr: [Observable<T1>, Observable<T2>]): Observable<[T1, T2]>
export function forkJoinZeroOrMore<T1, T2, T3>(arr: [Observable<T1>, Observable<T2>, Observable<T3>]): Observable<[T1, T2, T3]>
export function forkJoinZeroOrMore<T1, T2, T3, T4>(arr: [Observable<T1>, Observable<T2>, Observable<T3>, Observable<T4>]): Observable<[T1, T2, T3, T4]>
export function forkJoinZeroOrMore<T>(arr: Observable<T>[]): Observable<T[]>
export function forkJoinZeroOrMore<T>(arr: any) {
    if (arr.length == 0) return observableOf([] as T[])
    else return forkJoin(arr)
}

export function join<T1>(arr: [Observable<T1>]): Observable<[T1]>
export function join<T1, T2>(arr: [Observable<T1>, Observable<T2>]): Observable<[T1, T2]>
export function join<T1, T2, T3>(arr: [Observable<T1>, Observable<T2>, Observable<T3>]): Observable<[T1, T2, T3]>
export function join<T1, T2, T3, T4>(arr: [Observable<T1>, Observable<T2>, Observable<T3>, Observable<T4>]): Observable<[T1, T2, T3, T4]>
export function join<T>(x: Array<Observable<T>>): Observable<Array<T>>
export function join<T>(x: ReadonlyArray<Observable<T>>): Observable<ReadonlyArray<T>>
// export function join<T extends Record<string, Observable<T>>>(x: {[K in keyof T]: Observable<T[K]>}): Observable<T>
export function join<T extends {}>(x: {[K in keyof T]: Observable<T[K]>}): Observable<T>
export function join<T>(x: Observable<T>[] | ReadonlyArray<Observable<T>> | Record<string, Observable<T>>) {
    if (Array.isArray(x)) {
        return forkJoinZeroOrMore(x)
    } else {
        const keys = Object.keys(x)
        const values = Object.values(x)
        return forkJoinZeroOrMore(values).pipe(map((values) => Object.fromEntries(values.map((v, i) => [keys[i], v]))))
    }
}

export function combineLatestZeroOrMore<T>(arr: Observable<T>[]) {
    if (arr.length == 0) return observableOf([] as T[])
    else return combineLatest(arr)
}

export function getSelectionModifier(evt: PointerEvent | MouseEvent): false | "single" | "range" {
    if (evt.getModifierState("Shift")) return "range"
    else if (isApple ? evt.metaKey : evt.getModifierState("Control")) return "single"
    else return false
}

// Sort function for keyvalue pipe
export function originalOrder(_a: KeyValue<number, string>, _b: KeyValue<number, string>): number {
    return 0
}

export function jsonToFile(json: unknown, fileName: string, contentType = "application/json"): File {
    return new File([new Blob([JSON.stringify(json)], {type: contentType})], fileName, {type: contentType})
}

export class SubscriptionCountedObservable<T> extends Observable<T> {
    private _subscriptionCount = 0

    constructor(source: Observable<T>) {
        super((observer) => {
            const innerSubscription = source.subscribe(observer)
            this._subscriptionCount += 1
            return (): void => {
                innerSubscription.unsubscribe()
                this._subscriptionCount -= 1
            }
        })
    }

    get subscriptionCount() {
        return this._subscriptionCount
    }
}

// This will only emit to the most recent subscriber
export class PrioritizedObservableStack<T> extends Observable<T> {
    private stack: Subscriber<T>[] = []

    constructor(source: Observable<T>) {
        super((subscriber) => {
            this.stack.push(subscriber)
            const innerSubscription = source.subscribe(
                (value) => {
                    if (this.stack.indexOf(subscriber) === this.stack.length - 1) {
                        subscriber.next(value)
                    }
                },
                (error) => {
                    if (this.stack.indexOf(subscriber) === this.stack.length - 1) {
                        subscriber.error(error)
                    }
                },
                () => {
                    subscriber.complete()
                },
            )
            return () => {
                innerSubscription.unsubscribe()
                const idx = this.stack.indexOf(subscriber)
                if (idx >= 0) {
                    this.stack.splice(idx, 1)
                }
            }
        })
    }

    get subscriptionCount() {
        return this.stack.length
    }
}

export function blobToDataURL(blob: Blob): Observable<string> {
    const result$ = new ReplaySubject<string>(1)
    const reader = new FileReader()
    reader.onerror = (e) => {
        result$.error(e)
    }
    reader.onload = (_e) => {
        result$.next(reader.result as string)
        result$.complete()
    }
    reader.readAsDataURL(blob)
    return result$
}

export function fileToArrayBuffer(file: File): Observable<ArrayBuffer> {
    const result$ = new ReplaySubject<ArrayBuffer>(1)
    const reader = new FileReader()
    reader.onerror = (e) => {
        result$.error(e)
    }
    reader.onload = (_e) => {
        result$.next(reader.result as ArrayBuffer)
        result$.complete()
    }
    reader.readAsArrayBuffer(file)
    return result$
}

export function isOnboardingRequired(): boolean {
    try {
        const onboardingCompleted: string | null = localStorage.getItem(Settings.getOnboardingKey())
        let times = 0
        if (onboardingCompleted) {
            times = parseInt(onboardingCompleted, 10)
            if (times >= Settings.ONBOARDING_COUNT) return false
        }
        return true
    } catch (error) {
        console.error("Failed to get onboarding status, defaulting to 'not required': ", error)
        return false
    }
}

export function increaseOnboardingCounter(): void {
    try {
        const onboardingCompleted: string | null = localStorage.getItem(Settings.getOnboardingKey())
        let times = 0
        if (onboardingCompleted) {
            times = parseInt(onboardingCompleted, 10)
            if (times < Settings.ONBOARDING_COUNT) {
                localStorage.setItem(Settings.getOnboardingKey(), (++times).toString(10))
            }
        } else {
            localStorage.setItem(Settings.getOnboardingKey(), "1")
        }
    } catch (error) {
        console.error("Failed to increase onboarding counter: ", error)
    }
}

export function inIframe() {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

export class SyncTaskSet {
    private _tasks: Observable<unknown>[] = []

    add<T>(task: Observable<T>): Observable<T> {
        task = task.pipe(
            map((val) => {
                const idx = this._tasks.indexOf(task)
                this._tasks.splice(idx, 1)
                return val
            }),
            shareReplay(1),
        )
        this._tasks.push(task)
        task.subscribe()
        return task
    }

    get count() {
        return this._tasks.length
    }

    sync(): Observable<void> {
        return forkJoinZeroOrMore([...this._tasks]).pipe(mapTo(undefined))
    }
}

export class SyncEvent {
    private _subject = new Subject<void>()

    notify(): void {
        this._subject.next()
    }

    wait(): Observable<void> {
        return this._subject.pipe(take(1))
    }
}

export function throttleSwitchMap<A, B>(fn: (a: A) => Observable<B>) {
    return (source: Observable<A>) => {
        return new Observable<B>((subscriber: Subscriber<B>) => {
            let pendingInput: A
            let havePendingInput = false
            let pendingComplete = false
            let currentInnerSubscription: Subscription
            const checkNext = () => {
                if (currentInnerSubscription) return
                if (havePendingInput) {
                    const value = pendingInput
                    pendingInput = undefined
                    havePendingInput = false
                    let thisActive = true
                    currentInnerSubscription = fn(value).subscribe(
                        (b: B) => {
                            if (thisActive) {
                                currentInnerSubscription.unsubscribe()
                                currentInnerSubscription = null
                                thisActive = false
                                subscriber.next(b)
                                checkNext()
                            }
                        },
                        (err: unknown) => {
                            subscriber.error(err)
                        },
                        () => {
                            if (thisActive) {
                                currentInnerSubscription.unsubscribe()
                                currentInnerSubscription = null
                                thisActive = false
                                checkNext()
                            }
                        },
                    )
                } else if (pendingComplete) {
                    pendingComplete = false
                    subscriber.complete()
                }
            }
            const outerSubs = source.subscribe(
                (value: A) => {
                    // outer next
                    pendingInput = value
                    havePendingInput = true
                    checkNext()
                },
                (err: unknown) => {
                    subscriber.error(err)
                },
                () => {
                    pendingComplete = true
                    checkNext()
                },
            )
            return () => {
                outerSubs.unsubscribe()
                currentInnerSubscription?.unsubscribe()
            }
        })
    }
}

export function clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value))
}
