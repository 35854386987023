import {Component, input} from "@angular/core"
import {MatTooltip} from "@angular/material/tooltip"
import {TippyDirective} from "@ngneat/helipopper"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DownloadResolution} from "@api"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"

@Component({
    selector: "cm-user-thumbnail",
    standalone: true,
    imports: [MatTooltip, TippyDirective, DataObjectThumbnailComponent],
    templateUrl: "./user-thumbnail.component.html",
    styleUrl: "./user-thumbnail.component.scss",
})
export class UserThumbnailComponent {
    $user = input<{galleryImage?: {id: string}} | null>(null)

    protected readonly DownloadResolution = DownloadResolution
    protected readonly ThumbnailLayout = ThumbnailLayout
}
