import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {ThreeObject, mathIsEqual, setThreeObjectPart} from "@template-editor/helpers/three-object"
import * as THREE from "three"
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader"
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader"
import {from, switchMap, tap} from "rxjs"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {Vector3} from "@app/common/helpers/vector-math"
import {degToRad} from "three/src/math/MathUtils"
import {DEFAULT_FLOAT_TEXTURE_TYPE, MAX_FAR_CLIP, MIN_NEAR_CLIP} from "@template-editor/helpers/three-utils"
import {objectDifferent, objectFieldsDifferent} from "@template-editor/helpers/change-detection"

export class ThreeEnvironment extends ThreeObject<SceneNodes.Environment> {
    protected override renderObject: THREE.Group = new THREE.Group() //Dummy object
    wasDisposed = false

    private rawEnvironmentTexture: THREE.Texture | null = null
    private processedEnvironmentTexture: THREE.WebGLRenderTarget | null = null
    private cubeRenderTarget: THREE.WebGLCubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
        format: THREE.RGBAFormat,
        type: DEFAULT_FLOAT_TEXTURE_TYPE,
        colorSpace: THREE.LinearSRGBColorSpace,
    })
    private cubeCamera: THREE.CubeCamera = new THREE.CubeCamera(MIN_NEAR_CLIP, MAX_FAR_CLIP, this.cubeRenderTarget)

    constructor(protected override threeSceneManagerService: ThreeSceneManagerService) {
        super(threeSceneManagerService)
        setThreeObjectPart(this.renderObject, this)
    }

    private transformedEnvMapTextureMaterial = new THREE.ShaderMaterial({
        uniforms: {
            inputTexture: {value: null},
            mirror: {value: null},
            intensity: {value: null},
            clamp: {value: null},
        },
        vertexShader: `varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }`,
        fragmentShader: `varying vec2 vUv;
                uniform sampler2D inputTexture;
                uniform bool mirror;
                uniform float intensity;
                uniform float clamp;
    
                void main() {
                    vec2 uv = mirror ? vec2(1.0 - vUv.x, vUv.y) : vUv;
                    vec4 color = texture2D(inputTexture, uv) * intensity;
                    color = min(color, clamp);
                    gl_FragColor = color;
                }`,
    })

    override setup(sceneNode: SceneNodes.Environment) {
        if (
            objectDifferent(sceneNode.envData, this.parameters?.envData, undefined, (envData) => {
                const {sceneManagerService} = this.threeSceneManagerService

                const getLoaderForExtension = (extension: string) => {
                    switch (extension.toLowerCase()) {
                        case "exr":
                            return new EXRLoader().setDataType(DEFAULT_FLOAT_TEXTURE_TYPE)
                        case "hdr":
                            return new RGBELoader().setDataType(DEFAULT_FLOAT_TEXTURE_TYPE)
                        default:
                            return new THREE.TextureLoader()
                    }
                }

                const getLoaderAndData = async (): Promise<{
                    loader: THREE.TextureLoader
                    dataOrUrl: Uint8Array | string
                }> => {
                    if (envData.type === "hdri") {
                        const {buffer, extension} = await sceneManagerService.getHdriAsBufferAndExtension({legacyId: envData.hdriID})
                        return {loader: getLoaderForExtension(extension), dataOrUrl: buffer}
                    } else return {loader: getLoaderForExtension(envData.originalFileExtension), dataOrUrl: envData.url}
                }

                const loadEnvironmentMap = async (textureLoader: THREE.TextureLoader, dataOrUrl: Uint8Array | string) => {
                    return new Promise<THREE.Texture>((resolve, reject) => {
                        const url = typeof dataOrUrl === "string" ? dataOrUrl : URL.createObjectURL(new Blob([dataOrUrl.buffer]))
                        const cleanup = () => {
                            if (typeof dataOrUrl !== "string") URL.revokeObjectURL(url)
                        }

                        textureLoader.load(
                            url,
                            (texture) => {
                                texture.mapping = THREE.EquirectangularReflectionMapping
                                resolve(texture)
                                cleanup()
                            },
                            undefined,
                            () => {
                                reject()
                                cleanup()
                            },
                        )
                    })
                }

                const data = envData.type === "hdri" ? envData.hdriID : envData.url

                sceneManagerService.addTask(
                    `loadEnvironmentMap(${data})`,
                    from(getLoaderAndData()).pipe(
                        switchMap(({loader, dataOrUrl}) => loadEnvironmentMap(loader, dataOrUrl)),
                        tap((texture) => {
                            if (this.wasDisposed) {
                                texture.dispose()
                                return
                            }

                            if (this.rawEnvironmentTexture) this.rawEnvironmentTexture.dispose()
                            this.rawEnvironmentTexture = texture
                            this.updateEnvironmentProperties(sceneNode)

                            this.threeSceneManagerService.requestRedraw()
                        }),
                    ),
                )
            })
        )
            return false
        else
            return objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["rotation", "clampHighlights", "mirror", "intensity"],
                (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") return mathIsEqual(valueA, valueB)
                    else return valueA === valueB
                },
                (sceneNode) => this.updateEnvironmentProperties(sceneNode),
            )
    }

    private updateEnvironmentProperties(sceneNode: Pick<SceneNodes.Environment, "rotation" | "clampHighlights" | "mirror" | "intensity">) {
        if (!this.rawEnvironmentTexture) return

        const {rotation, mirror} = sceneNode

        if (!(rotation instanceof Vector3)) throw new Error("env.rotation is not a Vector3")

        const renderer = this.threeSceneManagerService.getRenderer()
        const transformedEnvMap = this.getTransformedEnvMap(sceneNode, renderer)
        if (!transformedEnvMap) return

        const scene = new THREE.Scene()
        scene.background = transformedEnvMap.texture

        this.cubeCamera.rotation.copy(
            new THREE.Euler(
                degToRad(mirror ? -rotation.x : rotation.x),
                degToRad(mirror ? 180.0 - rotation.y : rotation.y),
                degToRad(mirror ? -rotation.z : rotation.z),
            ),
        )
        this.cubeCamera.update(renderer, scene)

        transformedEnvMap.dispose()

        const rotatedTransformedEnvMapTexture = this.cubeCamera.renderTarget.texture

        const pmremGenerator = new THREE.PMREMGenerator(renderer)
        const target = pmremGenerator.fromCubemap(rotatedTransformedEnvMapTexture)
        pmremGenerator.dispose()

        if (this.processedEnvironmentTexture) this.processedEnvironmentTexture.dispose()
        this.processedEnvironmentTexture = target

        this.threeSceneManagerService.modifyBaseScene((scene) => {
            scene.environment = target.texture
        })
    }

    private getTransformedEnvMap(
        sceneNode: Pick<SceneNodes.Environment, "clampHighlights" | "mirror" | "intensity">,
        renderer: THREE.WebGLRenderer,
    ): THREE.WebGLRenderTarget | null {
        if (!this.rawEnvironmentTexture) return null

        const scene = new THREE.Scene()
        const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 1000)

        const width = this.rawEnvironmentTexture.image.width || 512
        const height = this.rawEnvironmentTexture.image.height || 512

        const {clampHighlights, mirror, intensity} = sceneNode
        const clampValue = clampHighlights ?? 1000.0

        const renderTarget = new THREE.WebGLRenderTarget(width, height, {
            format: THREE.RGBAFormat,
            type: DEFAULT_FLOAT_TEXTURE_TYPE,
            colorSpace: THREE.LinearSRGBColorSpace,
        })

        this.transformedEnvMapTextureMaterial.uniforms.inputTexture.value = this.rawEnvironmentTexture
        this.transformedEnvMapTextureMaterial.uniforms.mirror.value = mirror
        this.transformedEnvMapTextureMaterial.uniforms.intensity.value = intensity
        this.transformedEnvMapTextureMaterial.uniforms.clamp.value = clampValue

        const mesh = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), this.transformedEnvMapTextureMaterial)
        scene.add(mesh)

        camera.position.z = 1

        const currentXrEnabled = renderer.xr.enabled

        renderer.xr.enabled = false
        const currentRenderTarget = renderer.getRenderTarget()
        const currentActiveCubeFace = renderer.getActiveCubeFace()
        const currentActiveMipmapLevel = renderer.getActiveMipmapLevel()

        renderer.setRenderTarget(renderTarget)
        renderer.render(scene, camera)
        renderer.setRenderTarget(currentRenderTarget, currentActiveCubeFace, currentActiveMipmapLevel)

        renderer.xr.enabled = currentXrEnabled

        mesh.geometry.dispose()

        const {texture} = renderTarget
        texture.mapping = this.rawEnvironmentTexture.mapping
        texture.colorSpace = this.rawEnvironmentTexture.colorSpace
        texture.minFilter = THREE.LinearFilter
        texture.magFilter = THREE.LinearFilter
        texture.needsUpdate = true

        return renderTarget
    }

    override dispose(final: boolean) {
        if (final) {
            this.threeSceneManagerService.modifyBaseScene((scene) => {
                scene.environment = null
            })

            if (this.rawEnvironmentTexture) {
                this.rawEnvironmentTexture.dispose()
                this.rawEnvironmentTexture = null
            }

            if (this.processedEnvironmentTexture) {
                this.processedEnvironmentTexture.dispose()
                this.processedEnvironmentTexture = null
            }

            this.cubeRenderTarget.dispose()

            this.transformedEnvMapTextureMaterial.dispose()

            this.wasDisposed = true
        }
    }
}
