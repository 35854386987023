<div class="cm-node-reference-slot" (cmNodeDrop)="onDrop($event)" [cmNodeDropFilter]="filter" (click)="$event.stopPropagation(); _chooseNode()">
    @if (isNode) {
        <ng-content select="[cm-before-node]"></ng-content>
        <i class="fa fa-sign-in"></i>
        <div [matTooltip]="description" class="cm-node-reference-title">
            {{ description }}
        </div>
        @if (isNode) {
            <i (click)="$event.stopPropagation(); clear()" class="far fa-times-circle cm-button"></i>
        }
    }
    @if (!isNode) {
        <i class="fa fa-sign-in"></i>
        <div>
            {{ fallbackText }}
        </div>
        @if (canPaste) {
            <i (click)="paste()" class="far fa-paste cm-button"></i>
        }
    }
    @if (!isNode) {
        <ng-content select="[cm-fallback]"></ng-content>
    }
</div>
