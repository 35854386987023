import {NodeTypeInfo, Socket} from "@node-editor/models"

export function findSocket(typeInfo: NodeTypeInfo, id: Socket["id"], type: Socket["type"]): Socket | undefined {
    if (typeInfo.inputs) {
        for (const socket of Object.values(typeInfo.inputs)) {
            if (socket.id === id && socket.type === type) {
                return socket
            }
        }
    }
    if (typeInfo.outputs) {
        for (const socket of Object.values(typeInfo.outputs)) {
            if (socket.id === id && socket.type === type) {
                return socket
            }
        }
    }
    return undefined
}
