import {CommonModule} from "@angular/common"
import {Component, inject, Input, TemplateRef, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog, MatDialogContent, MatDialogModule} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActivatedRoute, Router, RouterModule} from "@angular/router"
import {ContentTypeModel} from "@api"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"

@Component({
    selector: "cm-add-entity-button",
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        RouterModule,
        FormsModule,
        MatDialogContent,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    templateUrl: "./add-entity-button.component.html",
    styleUrl: "./add-entity-button.component.scss",
})
export class AddEntityButtonComponent<CreateType> {
    dialog = inject(MatDialog)
    notification = inject(NotificationsService)
    refresh = inject(RefreshService)
    route = inject(ActivatedRoute)
    router = inject(Router)

    @ViewChild("newItemDialog") newItemDialog?: TemplateRef<{data: CreateType}>

    @Input() initialData?: CreateType
    @Input() title = "Add item"
    @Input() formTemplate?: TemplateRef<{item: CreateType}>
    @Input() confirm?: (data: CreateType) => Promise<{id: string; legacyId: number}>
    @Input() canConfirm: (data: CreateType) => boolean = () => true
    @Input({required: true}) contentTypeModel!: ContentTypeModel

    openDialog() {
        if (!this.formTemplate) {
            throw new Error("Missing formTemplate ref in add-entity-button component")
        }
        if (!this.newItemDialog) {
            throw new Error("Missing newItemDialog ref in add-entity-button component")
        }
        this.dialog
            .open<{data: CreateType}>(this.newItemDialog, {
                data: this.initialData ?? {},
            })
            .afterClosed()
            .subscribe(async (data) => {
                if (data) {
                    await this.notification.withUserFeedback(
                        async () => {
                            if (!this.confirm) {
                                throw new Error("Missing confirm function in add-entity-button component")
                            }
                            const newItem = await this.confirm(data)
                            if (newItem) {
                                await this.router.navigate([newItem.id], {relativeTo: this.route, queryParamsHandling: "preserve"})

                                // need to reload the entire page to know where to insert the item and whether it is shown at all
                                // (it might not match the current filter)
                                this.refresh.contentTypeModel(this.contentTypeModel)

                                return newItem
                            }
                            return undefined
                        },
                        {
                            error: "Cannot create new item.",
                        },
                    )
                }
            })
    }
}
