import * as THREE from "three"
import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer"

// Simplified implementation of CSS2DRenderer. Allows to specify HTML elements position
//in terms of % relative to the parent element instead of in px.
export class HTMLViewRenderer {
    readonly domElement: HTMLDivElement
    private vector: THREE.Vector3
    private viewMatrix: THREE.Matrix4
    private viewProjectionMatrix: THREE.Matrix4
    private raycaster: THREE.Raycaster

    constructor() {
        this.domElement = document.createElement("div")
        this.vector = new THREE.Vector3()
        this.viewMatrix = new THREE.Matrix4()
        this.viewProjectionMatrix = new THREE.Matrix4()
        this.raycaster = new THREE.Raycaster()
        this.raycaster.layers.set(0)
    }

    opacityForObject(object: CSS2DObject, scene: THREE.Scene, camera: THREE.PerspectiveCamera): number {
        const objWorld = object.localToWorld(object.position.clone())
        const camObjVect = objWorld.sub(camera.position)
        const camObjDist = camObjVect.length()
        const dir = camObjVect.normalize()

        this.raycaster.set(camera.position, dir)
        const intersections = this.raycaster.intersectObjects(scene.children, true)
        if (intersections.length == 0 || intersections[0].distance > camObjDist) {
            return 1.0
        }
        return 0
    }

    renderObject(object: THREE.Object3D, scene: THREE.Scene, camera: THREE.PerspectiveCamera) {
        const vector = this.vector
        const viewProjectionMatrix = this.viewProjectionMatrix
        const domElement = this.domElement

        if (object instanceof CSS2DObject) {
            object.onBeforeRender(this, scene, camera)

            vector.setFromMatrixPosition(object.matrixWorld)
            vector.applyMatrix4(viewProjectionMatrix)

            const element = object.element

            // element.style.WebkitTransform = style;
            // element.style.MozTransform = style;
            // element.style.oTransform = style;
            // element.style.transform = style;
            element.style.top = (-vector.y + 1.0) * 0.5 * 100 + "%"
            element.style.left = (vector.x + 1.0) * 0.5 * 100 + "%"
            element.style.opacity = this.opacityForObject(object, scene, camera).toString()
            // console.log("opacity: ", element.style.opacity);

            const inFrame = vector.x > -1 && vector.x < 1 && vector.y > -1 && vector.y < 1 && vector.z >= -1 && vector.z <= 1
            element.style.display = object.visible && inFrame ? "" : "none"

            // var objectData = {
            // 	distanceToCameraSquared: getDistanceToSquared( camera, object )
            // };

            // cache.objects.set( object, objectData );

            if (element.parentNode !== domElement) {
                domElement.appendChild(element)
            }

            object.onAfterRender(this, scene, camera)
        }

        for (let i = 0, l = object.children.length; i < l; i++) {
            this.renderObject(object.children[i], scene, camera)
        }
    }

    render(scene: THREE.Scene, camera: THREE.PerspectiveCamera) {
        if (scene.matrixWorldAutoUpdate === true) scene.updateMatrixWorld()
        if (scene.parent === null) camera.updateMatrixWorld()

        this.viewMatrix.copy(camera.matrixWorldInverse)
        this.viewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, this.viewMatrix)

        while (this.domElement.firstChild) {
            this.domElement.removeChild(this.domElement.firstChild)
        }

        this.renderObject(scene, scene, camera)
    }
}
