import {Component, ElementRef, computed, inject, input, output, viewChild} from "@angular/core"
import {TemplateNodeComponent} from "../template-node/template-node.component"
import {EntityCardComponent} from "../../../common/components/entity/entity-card/entity-card.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {Node} from "@cm/lib/templates/node-types"
import {cardAnimations} from "@app/common/components/cards/animations"
import {MatTooltipModule} from "@angular/material/tooltip"
import {CardComponent} from "../../../common/components/cards/card/card.component"
import {DataObjectThumbnailComponent} from "../../../common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailLayout} from "@app/common/models/enums/thumbnail-layout"

@Component({
    selector: "cm-template-add-card",
    standalone: true,
    templateUrl: "./template-add-card.component.html",
    styleUrl: "./template-add-card.component.scss",
    animations: [...cardAnimations],
    imports: [TemplateNodeComponent, EntityCardComponent, MatTooltipModule, CardComponent, DataObjectThumbnailComponent],
})
export class TemplateAddCardComponent<
    EntityType extends {
        name?: string | null
        legacyId?: number
        organization?: {id?: string; name?: string | null}
        galleryImage?: {
            id: string
        }
        iconClass?: string
    },
> {
    data = input.required<EntityType | undefined>()
    node = input.required<Node | undefined>()
    disabled = input<boolean | undefined>(false)
    action = input<(() => Promise<void | false> | void | false) | undefined>(undefined)
    onItemClicked = output()

    galleryImage = computed(() => this.data()?.galleryImage)
    iconClass = computed(() => this.data()?.iconClass)

    private dragImage = viewChild.required<TemplateNodeComponent>("dragImage")
    drag = inject(TemplateNodeDragService)
    private sceneManagerService = inject(SceneManagerService)

    private getNodeInstance() {
        const node = this.node()
        if (!node) throw new Error("Node is undefined")
        return node.clone({cloneSubNode: () => true})
    }

    addNode() {
        if (this.disabled()) return

        this.sceneManagerService.modifyTemplateGraph((graph) => {
            graph.parameters.nodes.addEntry(this.getNodeInstance())
        })
    }

    dragStart(event: DragEvent) {
        event.dataTransfer?.setDragImage(this.dragImage().getDragImage(), 0, 0)

        this.drag.dragStart(event, this.getNodeInstance())
    }

    async onClicked() {
        if (this.disabled()) return

        const action = this.action()
        if (action) {
            const result = await action()
            if (result === false) return
        } else {
            this.addNode()
        }

        this.onItemClicked.emit()
    }

    protected readonly ThumbnailLayout = ThumbnailLayout
}
