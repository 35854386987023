import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, ViewChild} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {TippyDirective} from "@ngneat/helipopper"

@Component({
    selector: "cm-overflowable-text",
    standalone: true,
    imports: [MatTooltipModule, TippyDirective],
    templateUrl: "./overflowable-text.component.html",
    styleUrl: "./overflowable-text.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverflowableTextComponent implements AfterViewInit {
    @ViewChild("textElement") textElement!: ElementRef<HTMLSpanElement>
    tooltip?: string
    changeDetectorRef = inject(ChangeDetectorRef)

    ngAfterViewInit() {
        this.tooltip = this.textElement.nativeElement.innerText
        this.changeDetectorRef.markForCheck()
    }
}
