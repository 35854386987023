import {ConfiguratorParameterType} from "@cm/lib/templates/configurator-parameters"

// export function decodeConfiguratorURLParams(urlParams: Record<string, string>): Record<string, {type: ParamType; value: any}> | undefined {
//     let initSceneParams: Record<string, {type: ParamType; value: any}> | undefined
//     for (const [key, value] of Object.entries(urlParams)) {
//         const paramMatch = key.match(/param\(([^)]*)\)/)
//         if (paramMatch && paramMatch[1]) {
//             const id = paramMatch[1]
//             const valueMatch = value.match(/([^(]+)\(([^)]*)\)/)
//             if (valueMatch && valueMatch[1] && valueMatch[2]) {
//                 let type = valueMatch[1]
//                 let value: any = valueMatch[2]
//                 switch (type) {
//                     case "int": //TODO: not in ParamType
//                         type = "number"
//                         value |= 0
//                         break
//                     case "material":
//                     case "template":
//                         value |= 0
//                         break
//                     case "number":
//                         value = parseFloat(value)
//                         break
//                     case "float": //TODO: not in ParamType
//                         type = "number"
//                         value = parseFloat(value)
//                         break
//                     case "boolean":
//                         value = !!(value == "true" || value == 1)
//                         break
//                 }
//                 if (!initSceneParams) initSceneParams = {}
//                 initSceneParams[id] = {type: type as ParamType, value}
//             } else {
//                 console.error("Unrecognized parameter encoding:", key, "=", value)
//             }
//         }
//     }
//     return initSceneParams
// }

export function encodeConfiguratorURLParams(params: Record<string, {type: ConfiguratorParameterType; value: unknown}>): Record<string, string> | undefined {
    let urlParams: Record<string, string> | undefined
    for (const [id, {type, value}] of Object.entries(params)) {
        if (!urlParams) urlParams = {}
        urlParams[`param(${id})`] = `${type}(${value})`
    }
    return urlParams
}
