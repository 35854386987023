import {Component, EventEmitter, Input, Output} from "@angular/core"
import {ScalarInputSocketComponent} from "@node-editor/components/scalar-input-socket/scalar-input-socket.component"
import {ScalarSocketRange} from "@node-editor/models"

@Component({
    selector: "cm-vector-input-socket",
    templateUrl: "./vector-input-socket.component.html",
    styleUrls: ["./vector-input-socket.component.scss"],
    standalone: true,
    imports: [ScalarInputSocketComponent],
})
export class VectorInputSocketComponent {
    @Input() label: string | undefined
    @Input() value: [number, number, number] | undefined

    _connected: {value: boolean} = {value: false}
    @Input() set connected(value: {value: boolean}) {
        this._connected = value
    }
    get connected(): {value: boolean} {
        return this._connected
    }

    @Output() valueChange = new EventEmitter<[number, number, number]>()

    range: ScalarSocketRange = null
}
