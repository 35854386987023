import {Component, OnDestroy, OnInit} from "@angular/core"
import {MatDialog} from "@angular/material/dialog"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {BackgroundOperationService} from "@app/platform/services/background-operation/background-operation.service"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {BackgroundOperationItem, BackgroundOperationState} from "@app/common/models/background-operation"
import {TippyDirective} from "@ngneat/helipopper"
import {Subscription} from "rxjs"

@Component({
    selector: "cm-background-operation-list",
    templateUrl: "background-operation-list.component.html",
    styleUrls: ["background-operation-list.component.scss"],
    standalone: true,
    imports: [MatProgressBarModule, MatTooltipModule, MatProgressSpinnerModule, TippyDirective],
})
export class BackgroundOperationListComponent implements OnInit, OnDestroy {
    subscriptions: Array<Subscription> = []
    operations: Array<BackgroundOperationItem> = []
    minimized = false

    constructor(
        private backgroundOperationService: BackgroundOperationService,
        public mdDialog: MatDialog,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit() {
        const backgroundOperationItemSubscription: Subscription = this.backgroundOperationService.backgroundOperationItem$.subscribe(
            (backgroundOperationItem) => {
                this.operations.push(backgroundOperationItem)
                backgroundOperationItem.progressObservable?.subscribe({
                    next: (progress) => {
                        backgroundOperationItem.state = BackgroundOperationState.InProgress
                        backgroundOperationItem.progress = progress
                    },
                    error: () => {
                        backgroundOperationItem.state = BackgroundOperationState.Error
                    },
                    complete: () => {
                        backgroundOperationItem.state = BackgroundOperationState.Completed
                        if (backgroundOperationItem.discardOnCompletion) {
                            this.operations = this.operations.filter((operation) => operation !== backgroundOperationItem)
                        }
                    },
                })
            },
        )
        this.subscriptions.push(backgroundOperationItemSubscription)

        const closeToolbarAndCleanSubscription: Subscription = this.backgroundOperationService.closeToolbarAndCleanOperations$.subscribe(
            () => {
                this.closeAndCancel()
            },
            (error) => {
                console.log(error)
            },
        )
        this.subscriptions.push(closeToolbarAndCleanSubscription)
    }

    minimize(): void {
        this.minimized = true
    }

    maximize(): void {
        this.minimized = false
    }

    closeAndCancel(): void {
        for (const operation of this.operations) {
            if (operation.state === BackgroundOperationState.InProgress) {
                this.confirmCancellation()
                return
            }
        }
        this.operations = []
        this.maximize()
    }

    confirmCancellation() {
        const dialogRef = this.mdDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Cancel operations",
                message: "The operations are not completed yet. Do you want to cancel all operations?",
                confirmLabel: "Cancel operations",
                cancelLabel: "Continue operations",
            },
        })

        dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.cancelAllOperations()
            }
        })
    }

    cancelAllOperations(): void {
        const cleanedOperations: BackgroundOperationItem[] = []
        for (const operation of this.operations) {
            if (operation.cancellable && (operation.state === BackgroundOperationState.Waiting || operation.state === BackgroundOperationState.InProgress))
                operation.cancel()
            else cleanedOperations.push(operation)
        }
        this.operations = cleanedOperations
        if (this.operations.length > 0) this.snackBar.open("The remaining operations are not cancellable.", "", {duration: 3000})
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe()
        }
    }
}
