import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {Nodes} from "@src/templates/legacy/template-nodes"

const TD = TypeDescriptors

const filterAndWrapInterfaceDescriptor = {
    interface: TD.inlet(TD.Array(TD.Identity<Nodes.Meta.InterfaceDescriptor>())),
    wrapWithId: TD.inlet(TD.Primitive<Nodes.Meta.InterfaceId>()),
    claimedInputIds: TD.inlet(TD.Array(TD.Primitive<Nodes.Meta.InterfaceId>())),
    includeClaimed: TD.inlet(TD.Boolean),
    output: TD.outlet(TD.Array(TD.Identity<Nodes.Meta.InterfaceDescriptor>())),
}

export class FilterAndWrapInterface implements NodeClassImpl<typeof filterAndWrapInterfaceDescriptor, typeof FilterAndWrapInterface> {
    static descriptor = filterAndWrapInterfaceDescriptor
    static uniqueName = "FilterAndWrapInterface"
    interface!: Inlet<Nodes.Meta.InterfaceDescriptor[]>
    wrapWithId!: Inlet<Nodes.Meta.InterfaceId>
    claimedInputIds!: Inlet<Nodes.Meta.InterfaceId[]>
    includeClaimed!: Inlet<boolean>
    output!: Outlet<Nodes.Meta.InterfaceDescriptor[]>

    run() {
        if (this.interface === NotReady || this.wrapWithId === NotReady || this.claimedInputIds === NotReady || this.includeClaimed === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const output: Nodes.Meta.InterfaceDescriptor[] = []
        for (const input of this.interface) {
            if (this.claimedInputIds.indexOf(input.id) == -1) {
                output.push({
                    ...input,
                    id: Nodes.Meta.wrapInterfaceId(this.wrapWithId, input.id),
                })
            } else if (this.includeClaimed) {
                output.push({
                    ...input,
                    id: Nodes.Meta.wrapInterfaceId(this.wrapWithId, input.id),
                    isSetByTemplate: true,
                })
            }
        }
        this.output.emitIfChanged(output)
    }
}
