import {JobNodes, makeJobTaskDescriptor} from "@src/job-task/job-nodes"
import {ImageProcessingNodes} from "@src/image-processing/image-processing-nodes"

type EntityId = number

type ImageProcessingGraphInput = {
    graph: ImageProcessingNodes.Output
    graphDataObjectReference?: never
}

type ImageProcessingGraphDataObjectInput = {
    graph?: never
    graphDataObjectReference: JobNodes.DataObjectReference
}

export type ImageProcessingInput = ImageProcessingGraphInput | ImageProcessingGraphDataObjectInput

export type ImageProcessingOutput = any //JobNodes.DataObjectReference | string | [] | {} // TODO this return type should be typed (but it's tricky)

export const imageProcessingTask = makeJobTaskDescriptor<ImageProcessingInput, ImageProcessingOutput>("ImageProcessing.imageProcessing", 1)
