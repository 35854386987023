import {ImageDataType} from "@api"
import {ImageDataType as RestImageDataType} from "@legacy/api-model/data-object"

export function mapRestToGqlImageDataType(dataType: ImageDataType | RestImageDataType): ImageDataType {
    switch (dataType) {
        case ImageDataType.Unknown:
        case RestImageDataType.Unknown:
            return ImageDataType.Unknown
        case ImageDataType.Color:
        case RestImageDataType.Color:
            return ImageDataType.Color
        case ImageDataType.NonColor:
        case RestImageDataType.NonColor:
            return ImageDataType.NonColor
        default:
            throw new Error(`Unknown image data type: ${dataType}`)
    }
}
