// @ts-strict-ignore
import {Injectable} from "@angular/core"

@Injectable()
export class NodeSelectionManagerService<NodeComponentT extends {selected: boolean}> {
    selected: NodeComponentT

    select(node: NodeComponentT): void {
        if (this.selected) this.selected.selected = false
        node.selected = true
        this.selected = node
    }

    deselectAll(): void {
        if (this.selected) this.selected.selected = false
        this.selected = null
    }
}
