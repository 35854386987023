import {NgClass} from "@angular/common"
import {Component, DestroyRef, OnInit, computed, inject, input} from "@angular/core"
import {ArDialogComponent} from "@app/common/components/dialogs/ar-dialog/ar-dialog.component"
import {ActionMenuService} from "@app/common/components/viewers/configurator/action-menu-new/services/action-menu.service"
import {ArService} from "@app/common/components/viewers/configurator/services/ar.service"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {TippyDirective} from "@ngneat/helipopper"
import {LoadingSpinnerIconComponent} from "@app/common/components/progress/loading-spinner-icon/loading-spinner-icon.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu-new/services/config-menu.service"
import {isFullscreenApiAvailable} from "@app/common/helpers/fullscreen/fullscreen"

@Component({
    standalone: true,
    selector: "cm-action-menu-new",
    templateUrl: "./action-menu-new.component.html",
    styleUrls: ["./action-menu-new.component.scss"],
    providers: [SceneManagerService],
    imports: [NgClass, TippyDirective, LoadingSpinnerIconComponent, ArDialogComponent],
})
export class ActionMenuNewComponent implements OnInit {
    $inFullscreen = input.required<boolean>()
    $sceneManagerService = input.required<SceneManagerService>()
    $fullscreenEnabled = input<boolean>(false)
    $arEnabled = input<boolean>(false)
    $stlDownloadEnabled = input<boolean>(false)
    $snapshotEnabled = input<boolean>(false)

    tpOffset: [number, number] = [0, 20]
    arModelGcsObjectName: string | undefined

    private arService = inject(ArService)
    private destroyRef = inject(DestroyRef)
    private configMenuService = inject(ConfigMenuService)
    protected actionMenuService = inject(ActionMenuService)
    protected localSceneManagerService = inject(SceneManagerService)
    protected threeSceneManagerService = inject(ThreeSceneManagerService)

    $fullscreenAvailable = computed(() => {
        return isFullscreenApiAvailable() && this.$fullscreenEnabled()
    })

    constructor() {
        this.arService.desktopGcsObjectName$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((gcsObjectName) => {
            this.arModelGcsObjectName = gcsObjectName
        })

        this.configMenuService.configSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.arModelGcsObjectName = undefined
        })
    }

    ngOnInit(): void {}

    toggleFullscreen() {
        this.actionMenuService.toggleFullscreen()
    }

    async exportStlClicked() {
        const sceneManagerService = this.$sceneManagerService()
        this.actionMenuService.downloadStl(sceneManagerService.$templateGraph(), sceneManagerService.$instanceParameters(), this.localSceneManagerService)
    }

    async viewInArClicked() {
        const templateRevisionId = this.$sceneManagerService().$templateRevisionId()
        if (!templateRevisionId) return
        this.arService.viewArModel(templateRevisionId, this.$sceneManagerService().$currentLocalConfiguration())
    }
}
