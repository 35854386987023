import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ChannelLayout, Format, ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {deepEqual} from "@cm/lib/utils/utils"
import {getImgProcChannelLayout, getImgProcFormat, toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"

export type ParameterType = {
    sourceImage: ImagePtr
    channelLayout?: ChannelLayout
    format?: Format
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpConvert: ImageOpType<ParameterType, ReturnType> = {
    name: "Convert",

    WebGL2: async ({context, parameters: {sourceImage, channelLayout, format, resultImage}}) => {
        const halConvert = await context.getOrCreateImageCompositor(`
            vec4 computeColor(ivec2 targetPixel) {
                return texelFetch0(targetPixel);
            }
        `)
        using sourceImageWebGl2 = await context.getImage(sourceImage)
        const resultDescriptor: ImageDescriptor = {
            width: sourceImageWebGl2.ref.descriptor.width,
            height: sourceImageWebGl2.ref.descriptor.height,
            channelLayout: channelLayout ?? sourceImageWebGl2.ref.descriptor.channelLayout,
            format: format ?? sourceImageWebGl2.ref.descriptor.format,
            isSRGB: sourceImageWebGl2.ref.descriptor.isSRGB,
        }
        if (!resultImage && deepEqual(resultDescriptor, sourceImageWebGl2.ref.descriptor)) {
            // if no conversion is needed and no result image is specified, return the source image
            sourceImageWebGl2.release()
            return sourceImage
        }
        resultImage = await context.prepareResultImage(resultImage, resultDescriptor)
        using resultImageWebGl2 = await context.getImage(resultImage)
        await halConvert.paint(resultImageWebGl2.ref.halImage, sourceImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, channelLayout, format, resultImage}}) => {
        using sourceImageImgProc = await context.getImage(sourceImage)
        const resultDescriptor: ImageDescriptor = {
            width: sourceImageImgProc.ref.descriptor.width,
            height: sourceImageImgProc.ref.descriptor.height,
            channelLayout: channelLayout ?? sourceImageImgProc.ref.descriptor.channelLayout,
            format: format ?? sourceImageImgProc.ref.descriptor.format,
            isSRGB: sourceImageImgProc.ref.descriptor.isSRGB,
        }
        if (!resultImage && deepEqual(resultDescriptor, sourceImageImgProc.ref.descriptor)) {
            // if no conversion is needed and no result image is specified, return the source image
            return sourceImage
        }
        const resultNode = Nodes.convert(
            sourceImageImgProc.ref.node,
            getImgProcFormat(resultDescriptor.format),
            getImgProcChannelLayout(resultDescriptor.channelLayout),
        )
        using result = await context.createImage(resultDescriptor, resultNode)
        return await toImgProcResultImage(context, result, resultImage)
    },
}
