<cm-select-dialog
    [data]="$data()"
    (selectItem)="onSelectItem($event)"
    (cancel)="cancelDialog()"
    [$title]="'Select asset'"
    [pageFilledStatusChange]="pageFilledStatusChange$"
    [gridSize]="gridSize"
>
    <ng-container cm-filters>
        <cm-search (valueChange)="searchText$.next($event)" placeholder="Name, description, ID, ..."></cm-search>
        <cm-own-organizations-filter (selectionChanged)="organizationsIds$.next($event)"></cm-own-organizations-filter>
    </ng-container>
</cm-select-dialog>
