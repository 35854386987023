import {Component, inject, EventEmitter, Output, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {AuthService} from "@app/common/services/auth/auth.service"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {FilterListComponent} from "@common/components/filters/filter-list/filter-list.component"
import {StateLabel} from "@labels"

@Component({
    selector: "cm-own-organizations-filter",
    standalone: true,
    imports: [FilterListComponent, MatTooltipModule],
    templateUrl: "./own-organizations-filter.component.html",
    styleUrl: "./own-organizations-filter.component.scss",
})
export class OwnOrganizationsFilterComponent implements OnInit {
    auth = inject(AuthService)
    organizations = inject(OrganizationsService)
    @Output() selectionChanged = new EventEmitter<string[] | undefined>()

    public selectedOrganizations: StateLabel<string>[] = []

    ngOnInit() {
        this.selectOwnOrganizations()
    }

    onRemoveOrganization(organization: StateLabel<string>) {
        this.selectedOrganizations = this.selectedOrganizations.filter((selectedOrganization) => selectedOrganization.state !== organization.state)
        this.selectionChanged.emit(this.selectedOrganizations.length ? this.selectedOrganizations.map((organization) => organization.state) : undefined)
    }

    selectOwnOrganizations() {
        this.selectedOrganizations = this.organizations.$own()?.map((organization) => ({label: organization.name ?? "", state: organization.id})) ?? []
        this.selectionChanged.emit(this.auth.isStaff() ? undefined : this.selectedOrganizations.map((organization) => organization.state))
    }
}
