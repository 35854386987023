<h2 mat-dialog-title>Add title</h2>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <input [(ngModel)]="title" matInput (keyup.enter)="confirm()" />
    </mat-form-field>
</div>
<div class="cm-dialog-actions" mat-dialog-actions align="end">
    <button mat-button (click)="cancelAction()" autofocus>Cancel</button>
    <button mat-button color="primary" (click)="confirm()" [disabled]="!title">Confirm</button>
</div>
