import {NgClass} from "@angular/common"
import {ChangeDetectionStrategy, Component, DestroyRef, inject, input, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ConfigButtonComponent} from "@app/common/components/viewers/configurator/config-menu-new/icons-accordion-menu/config-button-new/config-button-new.component"
import {SelectMaterialButton} from "@app/common/components/viewers/configurator/config-menu-new/select-material/select-material.component"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu-new/services/config-menu.service"
import {ConfigInfo, InterfaceDescriptor, isConfigInput, isMaterialInput, VariantInfo} from "@cm/lib/templates/interface-descriptors"
import {UiStyle} from "@cm/lib/templates/nodes/scene-properties"

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "cm-icons-accordion-menu",
    templateUrl: "./icons-accordion-menu.component.html",
    styleUrls: ["./icons-accordion-menu.component.scss"],
    imports: [NgClass, ConfigButtonComponent, SelectMaterialButton],
})
export class IconsAccordionMenuComponent {
    $useCaptions = input<boolean>(false)
    $tooltipContainer = input.required<HTMLDivElement | undefined>()
    $interfaceDescriptors = input.required<InterfaceDescriptor<unknown, object>[]>()
    $uiStyle = input.required<UiStyle>()

    $iconSize = signal<number>(24)
    $selectedDescriptorId = signal<string | undefined>(undefined)

    isConfigInput = isConfigInput
    isMaterialInput = isMaterialInput

    configMenuService = inject(ConfigMenuService)
    private destroyRef = inject(DestroyRef)

    constructor() {
        this.configMenuService.iconSize$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((iconSize) => {
            this.$iconSize.set(iconSize)
        })
    }

    onDescriptorClicked(id: string) {
        if (this.$selectedDescriptorId() === id) this.$selectedDescriptorId.set(undefined)
        else this.$selectedDescriptorId.set(id)
    }

    selectVariant(config: ConfigInfo, variant: VariantInfo): void {
        this.configMenuService.emitConfigSelected({config, variant})
    }
}
