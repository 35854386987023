import {Component, input} from "@angular/core"
import {EntityMetadataTooltipComponent} from "@common/components/entity/entity-metdata-tooltip/entity-metadata-tooltip.component"
import {TippyDirective} from "@ngneat/helipopper"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"
import {MomentModule} from "ngx-moment"

@Component({
    selector: "cm-entity-metadata",
    standalone: true,
    imports: [MomentModule, UserThumbnailComponent, TippyDirective],
    templateUrl: "./entity-metadata.component.html",
    styleUrl: "./entity-metadata.component.scss",
})
export class EntityMetadataComponent {
    $data = input<{
        createdAt?: string | Date | null
        updatedAt?: string | Date | null
        createdBy?: {name: string; galleryImage?: {id: string} | null}
        updatedBy?: {name: string; galleryImage?: {id: string} | null}
    } | null>(null)
    protected readonly EntityChangedTooltipComponent = EntityMetadataTooltipComponent
}
