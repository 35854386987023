import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {color} from "@src/materials/types"
import {threeRGBColorNode, threeConvert} from "@src/materials/three-utils"

export class RGB extends DeclareMaterialNode(
    {
        returns: z.object({color: materialSlots}),
        inputs: z.object({color: materialSlots.optional()}),
        parameters: z.object({color: color.optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const colorValue = (await get(inputs.color)) ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            return {color: colorValue}
        },
    },
) {}
