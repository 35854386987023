import {HalContext} from "@common/models/hal/hal-context"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {hashObject} from "@cm/lib/utils/hashing"
import {ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {ImageWebGL2, isImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {traceObject} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {getHalImageDescriptor, getHalImageOptions} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {ImageRefBase} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref-base"
import {createHalPaintableImage} from "@common/models/hal/hal-paintable-image/create"

const TRACE = TextureEditorSettings.EnableFullTrace
const TRACE_ALLOCATIONS = TextureEditorSettings.EnableAllocTrace

export class ImageCacheWebGL2 {
    constructor(readonly halContext: HalContext) {}

    dispose(): void {
        this.freeAllImages()
    }

    beginUsageFrame(): void {
        for (const usageStats of this.usageStatsByImage.values()) {
            usageStats.currFrameUsage = 0
        }
    }

    endUsageFrame(): void {
        for (const usageStats of this.usageStatsByImage.values()) {
            if (usageStats.currFrameUsage === 0) {
                usageStats.numUnusedFrames++
            } else {
                usageStats.numUnusedFrames = 0
            }
        }
    }

    getNumCachedAvailableImages(): number {
        return Array.from(this.availableImagesByHash.values()).flat().length
    }

    getNumCachedUsedImages(): number {
        return Array.from(this.usedImagesByHash.values()).flat().length
    }

    async getImage(descriptor: ImageDescriptor): Promise<ImageWebGL2> {
        const hash = this.getHash(descriptor)
        let availableTemporaryRenderTargets = this.availableImagesByHash.get(hash)
        if (!availableTemporaryRenderTargets) {
            availableTemporaryRenderTargets = []
            this.availableImagesByHash.set(hash, availableTemporaryRenderTargets)
        }
        let target = availableTemporaryRenderTargets.pop()
        if (!target) {
            target = await this.createImage(descriptor)
        } else {
            if (TRACE) {
                console.log(`ImageCacheWebGL2: reusing image for descriptor ${traceObject(descriptor)} with id ${target.debugId}`)
            }
            target.addRef()
        }
        let usedTemporaryRenderTargets = this.usedImagesByHash.get(hash)
        if (!usedTemporaryRenderTargets) {
            usedTemporaryRenderTargets = []
            this.usedImagesByHash.set(hash, usedTemporaryRenderTargets)
        }
        usedTemporaryRenderTargets.push(target)
        this.registerImageUsage(target)
        return target
    }

    private releaseImage(image: ImageRefBase, errorIfNotFound = true): void {
        if (!isImageWebGL2(image)) {
            throw Error("image is not ImageWebGL2")
        }
        if (TRACE) {
            console.log(`ImageCacheWebGL2: releasing image with id ${image.debugId}`)
        }
        const hash = this.getHash(image.descriptor)
        // remove from used
        const usedImages = this.usedImagesByHash.get(hash)
        if (!usedImages) {
            if (errorIfNotFound) {
                throw Error("usedImages not found")
            } else {
                return
            }
        }
        const index = usedImages.indexOf(image)
        if (index < 0) {
            if (errorIfNotFound) {
                throw Error("image not found")
            } else {
                return
            }
        }
        usedImages.splice(index, 1)
        // add to available
        const availableImages = this.availableImagesByHash.get(hash)
        if (!availableImages) {
            throw Error("availableImages not found")
        }
        availableImages.push(image)
        this.registerImageRelease(image)
        if (TRACE) {
            this.logStats()
        }
    }

    private releaseAllImages(): void {
        Array.from(this.usedImagesByHash.values()).forEach((targets) => targets.slice().forEach((target) => this.releaseImage(target)))
    }

    freeRarelyUsedImages(numUsageFramesToKeepUnused: number): void {
        const imagesToFree: ImageWebGL2[] = []
        this.usageStatsByImage.forEach((usageStats, image) => {
            if (usageStats.isAvailable && usageStats.numUnusedFrames >= numUsageFramesToKeepUnused) {
                imagesToFree.push(image)
            }
        })
        this.freeImages(imagesToFree)
        if (TRACE_ALLOCATIONS) {
            const numFreed = imagesToFree.length
            if (numFreed > 0) {
                console.log(`Freed ${numFreed} images from cache due to low usage.`)
                this.logStats()
            }
        }
    }

    logStats(): void {
        const numAvail = this.getNumCachedAvailableImages()
        const numUsed = this.getNumCachedUsedImages()
        console.log(`ImageCacheWebGl2: ${numAvail + numUsed} images in cache (${numUsed} used, ${numAvail} available)`)
    }

    private freeAllImages() {
        this.releaseAllImages()
        this.freeImages(Array.from(this.availableImagesByHash.values()).flat())
    }

    private async createImage(descriptor: ImageDescriptor) {
        const id = this.nextId++
        const debugId = `ImageCacheWebGL2[${id}]`
        if (TRACE_ALLOCATIONS) {
            console.log(`ImageCacheWebGL2: allocating image with descriptor ${traceObject(descriptor)} with id ${debugId}`)
        }
        const halImage = createHalPaintableImage(this.halContext)
        await halImage.create(getHalImageDescriptor(descriptor), getHalImageOptions(descriptor))
        const image = new ImageWebGL2("temporary", id, (image) => this.releaseImage(image), halImage, descriptor, debugId)
        this.usageStatsByImage.set(image, {currFrameUsage: 0, numUnusedFrames: 0, isAvailable: true})
        return image
    }

    private freeImage(image: ImageWebGL2): void {
        if (TRACE_ALLOCATIONS) {
            console.log(`ImageCacheWebGL2: freeing image with id ${traceObject(image.debugId)}`)
        }
        const hash = this.getHash(image.descriptor)
        const availableImages = this.availableImagesByHash.get(hash)
        if (!availableImages) {
            throw Error("availableImages not found")
        }
        const index = availableImages.indexOf(image)
        if (index < 0) {
            throw Error("image not found")
        }
        availableImages.splice(index, 1)
        this.usageStatsByImage.delete(image)
        image.halImage.dispose()
    }

    private freeImages(images: ImageWebGL2[]): void {
        for (const image of images.slice()) {
            this.freeImage(image)
        }
    }

    private registerImageUsage(image: ImageWebGL2): void {
        const usage = this.usageStatsByImage.get(image)
        if (!usage) {
            throw Error("usage not found")
        }
        usage.currFrameUsage++
        usage.isAvailable = false
    }

    private registerImageRelease(image: ImageWebGL2): void {
        const usage = this.usageStatsByImage.get(image)
        if (!usage) {
            throw Error("usage not found")
        }
        usage.isAvailable = true
    }

    private getHash(descriptor: ImageDescriptor): string {
        // make a copy for hash in case imageDesc contains excess properties
        const imageDescCopyForHash: ImageDescriptor = {
            width: descriptor.width,
            height: descriptor.height,
            channelLayout: descriptor.channelLayout,
            format: descriptor.format,
            isSRGB: descriptor.isSRGB,
        }
        const hash = hashObject(imageDescCopyForHash)
        return hash
    }

    private readonly availableImagesByHash = new Map<string, ImageWebGL2[]>()
    private readonly usedImagesByHash = new Map<string, ImageWebGL2[]>()
    private readonly usageStatsByImage = new Map<ImageWebGL2, UsageStats>()
    private nextId: number = 1
}

type UsageStats = {
    currFrameUsage: number
    numUnusedFrames: number
    isAvailable: boolean
}
