import {urlBase64Decode} from "@common/helpers/auth/base64"
import {z} from "zod"

export const decodeToken = (token: string): {id: string} | null => {
    const parts = token.split(".")
    if (parts.length !== 3) {
        throw new Error("JWT must have 3 parts")
    }
    const decoded = urlBase64Decode(parts[1])
    if (!decoded) {
        throw new Error("Cannot decode the token")
    }
    const TokenSchema = z.object({id: z.string()})
    const parsedToken = TokenSchema.safeParse(JSON.parse(decoded))
    return parsedToken.success ? {id: parsedToken.data.id} : null
}

export const getTokenExpirationDate = (token: Record<string, unknown>): Date | undefined => {
    if ("exp" in token && Number.isFinite(token["exp"])) {
        const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
        date.setUTCSeconds(Number(token["exp"]))
        return date
    }
    return undefined
}

export const localStorageToken = () => {
    try {
        return localStorage.getItem("token")
    } catch {
        // probably don't have access to localStorage, default to false
    }
    return null
}
