import * as THREE from "three"

const vertexShader = `
#include <common>
#include <bsdfs>
#include <lights_pars_begin>
#include <shadowmap_pars_vertex>

varying vec3 vNormal;
varying vec3 vViewPosition;
varying vec3 vWPosition;

void main() {
    #include <beginnormal_vertex>
    #include <defaultnormal_vertex>
    #include <begin_vertex>
    #include <project_vertex>
    #include <worldpos_vertex>
    #include <shadowmap_vertex>

    vViewPosition = -mvPosition.xyz;
    vNormal = transformedNormal;
    vWPosition = ( modelMatrix * vec4( transformed, 1.0 ) ).xyz;
}
`

const fragmentShader = `
uniform float opacity;
uniform float bias;
uniform vec3 falloffScale;
uniform vec3 falloffOffset;
uniform float maskScale;

varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vWPosition;

#include <common>
#include <packing>
#include <bsdfs>
#include <lights_pars_begin>
#include <shadowmap_pars_fragment>
#include <shadowmask_pars_fragment>

#if ( NUM_POINT_LIGHTS > 0 )
uniform vec3 pointLightDirections[NUM_POINT_LIGHTS];
#endif

void main() {

    vec3 geometryPosition = - vViewPosition;
    vec3 geometryNormal = vNormal;

    IncidentLight directLight;

    vec3 sumLight = vec3(1e-4, 1e-4, 1e-4);
    vec3 sumShadowed = vec3(1e-4, 1e-4, 1e-4);

#if ( NUM_POINT_LIGHTS > 0 )

    vec3 diffuse;

    #pragma unroll_loop_start
    for ( int i = 0; i < NUM_POINT_LIGHTS; i ++ ) {

        getPointLightInfo( pointLights[ i ], geometryPosition, directLight );

        diffuse = directLight.color * clamp(dot(geometryNormal, directLight.direction), 0., 1.);
        diffuse *= clamp( -dot(pointLightDirections[ i ], directLight.direction), 0., 1. );

        sumLight += diffuse;

        #if defined( USE_SHADOWMAP ) && ( UNROLLED_LOOP_INDEX < NUM_POINT_LIGHT_SHADOWS )
        diffuse *= all( bvec2( directLight.visible, receiveShadow ) ) ? getPointShadow( pointShadowMap[ i ], pointLightShadows[ i ].shadowMapSize, pointLightShadows[ i ].shadowBias, pointLightShadows[ i ].shadowRadius, vPointShadowCoord[ i ], pointLightShadows[ i ].shadowCameraNear, pointLightShadows[ i ].shadowCameraFar ) : 1.0;
        #endif

        sumShadowed += diffuse;
    }
    #pragma unroll_loop_end

#endif

    float alpha = 1. - ((sumShadowed.r + sumShadowed.g + sumShadowed.b) / (sumLight.r + sumLight.g + sumLight.b));

    float mask = clamp((1.0 - length((vWPosition - falloffOffset) * falloffScale)) * maskScale, 0., 1.);
    mask = smoothstep(0., 1., mask);

    gl_FragColor = vec4(0., 0., 0., clamp(alpha * opacity - bias, 0., 1.) * mask);
}
`

export class ShadowCatcherMaterial extends THREE.ShaderMaterial {
    constructor(properties: {opacity?: number; bias?: number; falloffX?: number; falloffZ?: number; smoothness?: number}) {
        super({
            uniforms: THREE.UniformsUtils.merge([
                THREE.UniformsLib.lights,
                {
                    opacity: {value: properties.opacity ?? 1.0},
                    bias: {value: properties.bias ?? 0.0},
                    falloffOffset: {value: new THREE.Vector3(0, 0, 0)},
                    falloffScale: {value: new THREE.Vector3(1 / (properties.falloffX ?? 100000), 0, 1 / (properties.falloffZ ?? 100000))},
                    maskScale: {value: 1.0 / (properties.smoothness ?? 1)},
                },
            ]),
            fragmentShader,
            vertexShader,
            transparent: true,
            lights: true,
        })

        this.properties = properties
    }

    //when using non-primitive types here, make sure to create a deep copy in clone
    private properties: {opacity?: number; bias?: number; falloffX?: number; falloffZ?: number; smoothness?: number}

    //when using a non-standard constructor, it is required to implement clone
    override clone(): this {
        return new ShadowCatcherMaterial({...this.properties}) as this
    }
}
