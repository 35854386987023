import {Component, computed, input} from "@angular/core"
import {ActionPanelComponent} from "../../../common/components/menu/action-menu/action-panel/action-panel.component"
import {ActionPanelRowComponent} from "../../../common/components/menu/action-menu/action-panel-row/action-panel-row.component"
import {FilesizePipe} from "../../../common/pipes/filesize/filesize.pipe"
import {ActionBarComponent} from "../../../common/components/menu/action-menu/action-bar/action-bar.component"
import {ActionItemComponent} from "../../../common/components/menu/action-menu/action-item/action-item.component"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatMenuModule} from "@angular/material/menu"
import {ButtonComponent} from "../../../common/components/buttons/button/button.component"
import {TemplateImageViewerCanvasComponent} from "../template-image-viewer-canvas/template-image-viewer-canvas.component"
import {CanvasNavigation} from "@app/common/helpers/canvas/canvas-navigation"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {FilesService} from "@app/common/services/files/files.service"
@Component({
    selector: "cm-template-image-viewer-controls",
    standalone: true,
    templateUrl: "./template-image-viewer-controls.component.html",
    styleUrl: "./template-image-viewer-controls.component.scss",
    imports: [
        ActionPanelComponent,
        ActionPanelRowComponent,
        FilesizePipe,
        ActionBarComponent,
        ActionItemComponent,
        MatTooltipModule,
        MatMenuModule,
        ButtonComponent,
    ],
})
export class TemplateImageViewerControlsComponent {
    parent = input.required<TemplateImageViewerCanvasComponent>()

    isMobileDevice = isMobileDevice

    postProcessedImageData = computed(() => {
        return this.parent().postProcessedImageData()
    })
    canvasBase = computed(() => {
        return this.parent().canvasBase()
    })
    navigation = (): CanvasNavigation | undefined => {
        return this.canvasBase().navigation
    }
    dataObject = computed(() => {
        const postProcessedImageData = this.postProcessedImageData()
        if (!postProcessedImageData) return undefined
        if (postProcessedImageData.type === "cached") {
            return postProcessedImageData.dataObject
        } else {
            return undefined
        }
    })
    imageData = computed(() => {
        const postProcessedImageData = this.postProcessedImageData()
        if (!postProcessedImageData) return undefined
        if (postProcessedImageData.type === "computed") {
            return postProcessedImageData.imageData
        } else {
            return undefined
        }
    })
    fileName = computed(() => {
        return this.dataObject()?.originalFileName
    })
    imageColorProfile = computed(() => {
        return this.dataObject()?.imageColorProfile
    })
    size = computed(() => {
        return this.imageData()?.data.length ?? this.dataObject()?.size
    })
    width = computed(() => {
        return this.imageData()?.width ?? this.dataObject()?.width
    })
    height = computed(() => {
        return this.imageData()?.height ?? this.dataObject()?.height
    })

    getZoomLevel() {
        return Math.round((this.navigation()?.getZoomLevel() ?? 1) * 100)
    }

    getFileTypeLabel() {
        const dataObject = this.dataObject()
        if (!dataObject) return ""

        switch (dataObject.mediaType) {
            case "image/x-exr":
                return "EXR"
            case "image/tiff":
                return "TIFF"
            case "image/jpeg":
                return "JPEG"
            default:
                return dataObject.mediaType
        }
    }

    downloadOriginal() {
        const dataObject = this.dataObject()
        if (!dataObject) return
        FilesService.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    downloadJpeg() {
        const dataObject = this.dataObject()
        if (!dataObject) return
        const jpegUrl = dataObject.thumbnail?.downloadUrl ?? dataObject.downloadUrl
        FilesService.downloadFile(dataObject.originalFileName, jpegUrl)
    }

    downloadFromImageData() {
        const imageData = this.imageData()
        if (!imageData) return

        const canvas = document.createElement("canvas")
        canvas.width = imageData.width
        canvas.height = imageData.height

        const context = canvas.getContext("2d")
        if (!context) return

        context.putImageData(imageData, 0, 0)
        const dataUrl = canvas.toDataURL("image/png")
        FilesService.downloadFile("snapshot.png", dataUrl.replace("image/png", "image/octet-stream"))
    }
}
