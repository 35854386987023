<div class="cm-filters-container">
    <p class="cm-list-title">Dates</p>
    <div class="cm-list">
        <div class="cm-date-option">
            <mat-form-field class="cm-date-picker cm-datepicker" appearance="outline">
                <input #dateFrom matInput />
            </mat-form-field>
        </div>
        <div class="cm-date-option">
            <mat-form-field class="cm-date-picker cm-datepicker" appearance="outline">
                <input #dateTo matInput />
            </mat-form-field>
        </div>
    </div>
</div>
