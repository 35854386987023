@for (item of $data().items; track item) {
    <div class="card-container">
        @if (item.data; as tag) {
            <cm-card @fadeInCard [defaultThumbnail]="false" [link]="['/tags', tag.id]" [queryParamsHandling]="'preserve'">
                <ng-container cm-thumbnail>
                    <cm-tag-thumbnail [tag]="tag"></cm-tag-thumbnail>
                </ng-container>
                <ng-container cm-title>
                    <span> {{ tag.legacyId }} - {{ tag.name }} </span>
                </ng-container>
                @if (can.changeTagOrganization()) {
                    <ng-container cm-subtitle>
                        <span class="cm-customer">
                            {{ tag.organization?.name ?? "(No customer)" }}
                        </span>
                    </ng-container>
                }
            </cm-card>
        } @else {
            <cm-card-placeholder class="card" @fadeInPlaceholder></cm-card-placeholder>
        }
    </div>
}
