import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http"
import {Injectable, Injector} from "@angular/core"
import {TokenService} from "@common/services/auth/token.service"
import {Settings} from "@common/models/settings/settings"
import {Observable} from "rxjs"

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    tokenService: TokenService
    constructor(private injector: Injector) {
        this.tokenService = this.injector.get(TokenService)
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Manually injecting AuthService here.
        // Reason: When the page is refreshed, the TokenInterceptor class is instantiated before the authService is instantiated.
        // So, this way we delay asking the injector for an instance of the class until a request is made. The injector keeps track of the AuthService instance
        // anyway, so we can ask it any number of times. Ref: https://github.com/angular/angular/issues/18224

        // Only add the authorization token to the requests accessing the API
        if (request.url.indexOf(Settings.REST_API_ENDPOINT) == -1) {
            return next.handle(request)
        }

        const token = this.tokenService.load()
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            })
        }
        return next.handle(request)
    }
}
