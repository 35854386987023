import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpReduce} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-reduce"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const ReduceNode = declareImageOpNode(imageOpReduce)

export function reduce(parameters: GetParameters<Context, ParameterType>) {
    return new ReduceNode(parameters)
}
