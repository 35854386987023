<div class="cm-button-list">
    <cm-button (click)="copyConfiguratorUrl()">
        <ng-container cm-text> Copy configurator URL to clipboard </ng-container>
    </cm-button>
    <cm-button (click)="downloadStl()">
        <ng-container cm-text> Download STL data for current variant </ng-container>
    </cm-button>
    <cm-three-template-scene-provider (onInitialized)="setThreeSceneManagerService($event)">
        <cm-button (click)="downloadGltf()" [style]="{width: '100%'}">
            <ng-container cm-text> Download GLTF data for current variant </ng-container>
        </cm-button>
    </cm-three-template-scene-provider>
</div>
