<h2 mat-dialog-title>Physical texture size</h2>
<mat-dialog-content>
    @if (!aspectRatioCorrect) {
        <cm-hint [hintType]="HintTypes.Warning">
            <ng-container cm-text>
                The aspect ratio of the physical dimensions does not match the aspect ratio of the map resolution !<br /><br /><b
                    ><a href="javascript:void(0)" (click)="fixAspectRatio()">Click here to automatically adjust the physical size accordingly.</a></b
                >
            </ng-container>
        </cm-hint>
    }
    <form id="textureForm" (submit)="confirmAction()">
        Width
        <mat-form-field>
            <input [(ngModel)]="$width" matInput type="number" [placeholder]="!isImperial ? 'Width (cm)' : 'Width (in)'" name="textureWidth" />
        </mat-form-field>
        Height
        <mat-form-field>
            <input [(ngModel)]="$height" matInput type="number" [placeholder]="!isImperial ? 'Height (cm)' : 'Height (in)'" name="textureHeight" />
        </mat-form-field>
        @if (showDisplacementSetting) {
            Displacement
            <mat-form-field>
                <input
                    [(ngModel)]="$displacement"
                    matInput
                    type="number"
                    [placeholder]="!isImperial ? 'Displacement (cm)' : 'Displacement (in)'"
                    name="textureDisplacement"
                />
            </mat-form-field>
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancelAction()">Cancel</button>
    <button mat-button form="textureForm" [disabled]="!$isValidData()">Ok</button>
</mat-dialog-actions>
