import {MaterialSocket} from "@material-editor/models/material-socket"

export const BrightContrastOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
}

export const BrightContrastInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
    bright: {paramType: "socket", id: "Bright", type: "input", valueType: "scalar", label: "Bright", defaultValue: 0, range: {min: -100, max: 100}},
    contrast: {paramType: "socket", id: "Contrast", type: "input", valueType: "scalar", label: "Contrast", defaultValue: 0, range: {min: -100, max: 100}},
}
