import {z} from "zod"

//WARNING: this is copied from job-manager.ts in the api-gql project!
//TODO: use this file for all projects

export interface JobTaskDescriptor<InputT, OutputT> {
    name: string
    version: number
    minCompatibleVersion?: number
    _inputTypeTag: InputT
    _outputTypeTag: OutputT
}

export function makeJobTaskDescriptor<InputT, OutputT>(name: string, version: number, minCompatibleVersion?: number): JobTaskDescriptor<InputT, OutputT> {
    return {name, version, minCompatibleVersion} as JobTaskDescriptor<InputT, OutputT>
}

export type JobTaskInput<InputT = any> = {
    taskType: string
    taskVersion: number
    input?: InputT
    timeout?: number
    cancelTimeout?: number
    name?: string // for display purposes only
}

//TODO: this is a bit redundant (used for CreateJob), some of this should be absorbed into the API
export type CreateJobGraphData = {
    taskType: "runJob"
    taskVersion: 1
    input: {
        definition: JobNodes.JobDefinition
        input?: any
    }
}

export namespace JobNodes {
    export type JobDefinition = {
        type: "jobDefinition"
        output: DataNode
        progress?: ProgressNode
        keepTasks?: boolean
        platformVersion?: string
    }

    export type Task = {
        type: "task"
        taskType: string
        taskVersion: number
        input?: DataNode
        timeout?: number // seconds
        queueDomain?: string
    }

    export type ExternalJob = {
        type: "externalJob"
        jobId: number
    }

    export type Input = {
        type: "input"
    }

    export type List = {
        type: "list"
        items: DataNode[]
    }

    export type Struct = {
        type: "struct"
        fields: {[id: string]: DataNode}
    }

    export type Value = {
        type: "value"
        value: any
    }

    // export type DataObjectReference = {
    //     type: "dataObjectReference"
    //     dataObjectId: number
    // }

    export const DataObjectReferenceSchema = z.object({
        type: z.literal("dataObjectReference"),
        dataObjectId: z.number(),
    })

    export type DataObjectReference = z.infer<typeof DataObjectReferenceSchema>

    export type Get = {
        type: "get"
        from: DataNode
        key: string | number
    }

    export type ProgressGroup = {
        type: "progressGroup"
        items: {node: ProgressNode; factor?: number}[]
        message?: string
        output?: ProgressDataNode
    }

    export type TaskNode = Task | ExternalJob
    export type DataNode = TaskNode | Input | Get | Struct | List | Value
    export type ProgressNode = TaskNode | ProgressGroup
    export type ProgressDataNode = ProgressNode | Input | Get | Struct | List | Value
    export type TypedDataNode<T> = DataNode & {_typeTag: T}
    export type TypedTaskNode<T> = TaskNode & {_typeTag: T}

    export type MapStructType<T extends Record<string, TypedDataNode<any>>> = {[K in keyof T]: T[K]["_typeTag"]}

    function _tagData<T>(x: DataNode): TypedDataNode<T> {
        return x as any
    }
    function _tagTask<T>(x: TaskNode): TypedTaskNode<T> {
        return x as any
    }

    export function value<T>(value: T): TypedDataNode<T> {
        return _tagData({type: "value", value})
    }
    export function list<T>(items: TypedDataNode<T>[]): TypedDataNode<T[]> {
        return _tagData({type: "list", items})
    }
    export function struct<F extends Record<string, TypedDataNode<any>>>(fields: F): TypedDataNode<MapStructType<F>> {
        return _tagData({type: "struct", fields})
    }
    export function input<T>(): TypedDataNode<T> {
        return _tagData({type: "input"})
    }
    export function get<T, K extends keyof T>(from: TypedDataNode<T>, key: K): TypedDataNode<T[K]> {
        return _tagData({type: "get", from, key: key as string | number})
    }
    export function dataObjectReference(dataObjectId: number): DataObjectReference {
        return {type: "dataObjectReference", dataObjectId}
    }
    export function externalJob(jobId: number): ExternalJob {
        return {type: "externalJob", jobId}
    }
    export function taskRaw(taskType: string, taskVersion: number, options?: {input?: DataNode; timeout?: number; queueDomain?: string}): Task {
        return {type: "task", taskType, taskVersion, ...options}
    }
    export function task<I, O>(desc: JobTaskDescriptor<I, O>, options?: {input?: TypedDataNode<I>; timeout?: number; queueDomain?: string}): TypedTaskNode<O> {
        return _tagTask({type: "task", taskType: desc.name, taskVersion: desc.version, ...options})
    }
    export function progressGroup(items: ProgressGroup["items"], options?: {output?: DataNode; message?: string}): ProgressGroup {
        return {type: "progressGroup", items: items.filter((x) => x), ...options}
    }
    export function progressGroupNoWeights(items: ProgressNode[], options?: {output?: DataNode; message?: string}): ProgressGroup {
        return progressGroup(
            items.filter((x) => x).map((x) => ({node: x})),
            options,
        )
    }

    export function isDataObjectReference(x: any): x is DataObjectReference {
        return typeof x === "object" && x?.type === "dataObjectReference" && x.dataObjectId != null
    }
    export function isGetNode<T>(x: any): x is TypedDataNode<T> {
        return typeof x === "object" && x?.type === "get"
    }
    export function isStructNode<T>(x: any): x is TypedDataNode<T> {
        return typeof x === "object" && x?.type === "struct"
    }

    export function jobGraph<T>(
        output: TypedDataNode<T>,
        options: {platformVersion?: string; input?: any; progress?: ProgressNode; keepTasks?: boolean},
    ): CreateJobGraphData {
        return {
            taskType: "runJob",
            taskVersion: 1,
            input: {
                definition: {
                    type: "jobDefinition",
                    output,
                    progress: options?.progress,
                    keepTasks: options?.keepTasks,
                    platformVersion: options?.platformVersion,
                },
                input: options?.input,
            },
        }
    }
}
