<div class="cm-texture-set">
    @if (data) {
        <div class="cm-texture-set-header">
            <div class="cm-texture-set-name">
                {{ data.textureSet.legacyId }} -
                @if (editName) {
                    <input
                        #nameInput
                        class="cm-name"
                        [cmInput]="data.textureSet.name"
                        (change)="changeName($event)"
                        (focusout)="editName = false"
                        placeholder="Enter a name"
                        [disabled]="!can.changeTextureSetName()"
                    />
                } @else {
                    @if (data.textureSet.name) {
                        <span (click)="editName = true">
                            {{ data.textureSet.name }}
                        </span>
                    } @else {
                        <span (click)="editName = true">Untitled</span>
                    }
                    @if (data.textureSetRevision; as revision) {
                        <span> ({{ revision.width | number: "1.2-2" }} × {{ revision.height | number: "1.2-2" }} cm)</span>
                    }
                }
            </div>

            <i class="far fa-trash cm-button cm-delete-texture-set" (click)="deleteTextureSet()" matTooltip="Delete" matTooltipPosition="above"> </i>
            @if (!showEmptyTextures) {
                <div class="cm-show-empty-textures cm-button" (click)="showEmptyTextures = true">Show empty textures</div>
            } @else {
                <div class="cm-show-empty-textures cm-button" (click)="showEmptyTextures = false">Hide empty textures</div>
            }
            <button class="cm-tile-textures cm-button" [routerLink]="['set-revisions', data.textureSetRevisionId]" queryParamsHandling="merge">
                <i class="far fa-pen"></i>
                Edit textures
            </button>
        </div>
        <ng-content select="[cm-content]"></ng-content>
        <cm-texture-set-revision-view
            [textureSetId]="data.textureSetId"
            [textureSetRevisionId]="data.textureSetRevisionId"
            [(showEmptyTextures)]="showEmptyTextures"
        ></cm-texture-set-revision-view>
    }
</div>
