import {Component, computed, input, output} from "@angular/core"
import {PricingNode} from "@cm/lib/pricing/declare-pricing-node"
import {PriceContainer} from "@cm/lib/pricing/nodes/price-container"
import {VariantConditionNodeComponent} from "@pricing/components/node-visualizers/variant-condition-node/variant-condition-node.component"

@Component({
    standalone: true,
    selector: "cm-price-container",
    templateUrl: "./price-container-node.component.html",
    styleUrls: ["./price-container-node.component.scss", "../node-styles.scss"],
    imports: [VariantConditionNodeComponent],
})
export class PriceContainerNodeComponent {
    $node = input.required<PricingNode>()

    $priceContainerNode = computed<PriceContainer | undefined>(() => {
        const node = this.$node()
        if (node instanceof PriceContainer) return node
        else return undefined
    })

    $description = computed<string>(() => {
        const node = this.$priceContainerNode()
        const destcription = node?.getDescription()
        if (destcription) return destcription
        return ""
    })

    $conditionRemoved = output<void>()
}
