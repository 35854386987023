<cm-routed-dialog [dialogSize]="DialogSize.Medium">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()" [wideSidebar]="true">
            <ng-container cm-title>
                <div class="cm-title-container">
                    <cm-title-section
                        [$title]="$item()?.name"
                        [$iconClass]="'far fa-image'"
                        [$isLoaded]="$item() !== undefined"
                        [$canEdit]="can.editPictureName($item())"
                        (onChanged)="updateItemSubject.next({name: $event})"
                        (onClosed)="closeModal()"
                    >
                        <div class="cm-title-prefix" cm-pretitle>Picture {{ $item()?.number ?? "" }}:</div>
                    </cm-title-section>

                    <div class="cm-links-container">
                        @if ($item()?.set; as set) {
                            <a
                                class="cm-simple-link"
                                [routerLink]="['/pictures']"
                                [queryParams]="{projectId: set.project.id, organizationId: set.project.organization.id, tab: 'projects'}"
                            >
                                Project {{ set.project.number }}{{ set.project.name ? ":&nbsp;" : "" }}{{ set.project.name }}</a
                            >,&nbsp;
                            <a
                                class="cm-simple-link"
                                [routerLink]="['/pictures']"
                                [queryParams]="{setId: set.id, organizationId: set.project.organization.id, tab: 'projects'}"
                            >
                                Set {{ set.number }}{{ set.name ? ":&nbsp;" : "" }}{{ set.name }}
                            </a>
                        } @else {
                            <div class="cm-link-placeholder cm-loading-placeholder cm-loading-placeholder--loading cm-loading-placeholder--dark"></div>
                            <div class="cm-link-placeholder cm-loading-placeholder cm-loading-placeholder--loading cm-loading-placeholder--dark"></div>
                        }
                    </div>
                </div>
            </ng-container>
            <ng-container cm-buttons>
                @if ($item(); as picture) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="moreMenu">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item (click)="requestDeleteConfirmation()" [disabled]="!picture || !this.can.deleteItem(picture)">
                            Delete picture
                        </button>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-sidebar>
                @if ($item(); as picture) {
                    @if (can.viewTasks(picture)) {
                        <cm-project-management-tasks-section
                            [$item]="picture"
                            ($onAddNewTask)="updatePicture({nextActor: Enums.NextActor.Team2})"
                        ></cm-project-management-tasks-section>
                    }

                    @if (can.submitPicture(picture)) {
                        <div class="cm-response-buttons">
                            <div
                                class="cm-button-container"
                                (click)="updatePicture({state: Enums.PictureState.InfoReview, nextActor: Enums.NextActor.ProjectManager}, true)"
                            >
                                <button mat-flat-button color="primary" class="cm-button-submit">Submit</button>
                            </div>
                        </div>
                    }
                    @if (can.movePictureAlongPipeline(picture)) {
                        @if (picture.nextActor == NextActor.Customer && !task.$isBeingAdded()) {
                            <div class="cm-response-buttons">
                                @if (picture.state == PictureState.Stage1) {
                                    <div class="cm-button-container">
                                        <button
                                            matTooltip="After pressing this button the picture will move to the second stage (Refinement)."
                                            class="cm-button-approve"
                                            mat-flat-button
                                            color="primary"
                                            (click)="updatePicture({nextActor: Enums.NextActor.Team2, state: Enums.PictureState.Stage2}, true)"
                                        >
                                            Accept concept
                                        </button>
                                    </div>
                                }
                                @if (picture.state == PictureState.Stage2) {
                                    <div
                                        class="cm-button-container"
                                        (click)="updatePicture({nextActor: Enums.NextActor.Team2, state: Enums.PictureState.FinalRender}, true)"
                                    >
                                        <button
                                            matTooltip="After pressing this button we will generate the final high-resolution image."
                                            class="cm-button-approve"
                                            mat-flat-button
                                            color="primary"
                                        >
                                            Submit for final render
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                    }
                }
            </ng-container>
            <ng-container cm-main>
                <cm-picture-revision-selector></cm-picture-revision-selector>
                @if (can.viewPictureStatistics()) {
                    <cm-section>
                        <ng-container cm-icon><i class="far fa-chart-line"></i></ng-container>
                        <ng-container cm-title> Statistics</ng-container>
                        <ng-container cm-content>
                            <cm-picture-statistics
                                [templateId]="$item()?.template?.id"
                                [templateRevisionId]="$item()?.template?.latestRevision?.id"
                            ></cm-picture-statistics>
                        </ng-container>
                    </cm-section>
                }
                @if (can.viewPictureDescription() && $item()) {
                    <cm-section>
                        <ng-container cm-icon><i class="far fa-align-left"></i></ng-container>
                        <ng-container cm-title> Description</ng-container>
                        <ng-container cm-content>
                            <cm-input-container>
                                <ng-container cm-input>
                                    <cm-native-input-text-area [value]="$item().description" [minRows]="6" (valueChange)="updatePicture({description: $event})">
                                    </cm-native-input-text-area>
                                </ng-container>
                            </cm-input-container>
                        </ng-container>
                    </cm-section>
                }
                @if (can.viewPictureRenderJobs()) {
                    @if ($item()?.jobAssignmentsCount) {
                        <cm-section>
                            <ng-container cm-icon><i class="far fa-cogs"></i></ng-container>
                            <ng-container cm-title> Render Jobs</ng-container>
                            <ng-container cm-content>
                                <cm-associated-jobs-table
                                    [assignedTo]="{legacyId: $item().legacyId, contentTypeModel: Enums.ContentTypeModel.Picture}"
                                ></cm-associated-jobs-table>
                            </ng-container>
                        </cm-section>
                    }
                }
                @if (can.viewPictureAssets()) {
                    @if ($item()?.id; as itemId) {
                        <cm-picture-assets-section [pictureId]="itemId" (afterAssetChange)="afterAssetChange()"></cm-picture-assets-section>
                    }
                }

                <cm-files-section [$item]="$item()"></cm-files-section>
                @if (can.viewPictureActivity()) {
                    <cm-activity-section [$item]="$item()"></cm-activity-section>
                }
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>

<router-outlet></router-outlet>
