import {ApiRequest} from "@common/models/api-request/api-request"
import {BatchApiCall} from "@common/helpers/batch-api-call/batch-api-call"

/*
    This class is an extension of the BatchApiCall class. It will dispatch the responses by an "id" field.
*/
export abstract class BatchApiCallById<
    RequestPayload extends {id: string},
    ResponsePayload extends {id: string},
    BatchedRequestPayload extends {
        requests: ApiRequest<RequestPayload, ResponsePayload>[]
    },
> extends BatchApiCall<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    protected dispatchResponses(batchedPayload: BatchedRequestPayload, responses: ResponsePayload[]) {
        const requestsById = new Map<string, ApiRequest<RequestPayload, ResponsePayload>[]>()
        batchedPayload.requests.forEach((request) => {
            if (!requestsById.has(request.payload.id)) {
                requestsById.set(request.payload.id, [])
            }
            requestsById.get(request.payload.id)!.push(request)
        })
        responses.forEach((response) => {
            const requests = requestsById.get(response.id)
            if (!requests) {
                throw new Error("No request not found")
            }
            requests.forEach((request) => request.resolve(response))
            requestsById.delete(response.id)
        })
        requestsById.forEach((requests) => requests.forEach((request) => request.reject("No response received")))
    }
}
