import {HalPainterImageStretch} from "@common/models/hal/common/hal-painter-image-stretch"
import {HalContext} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalPainterPrimitiveOptions} from "@common/models/hal/hal-painter-primitive/types"
import {Box2, Box2Like} from "@cm/lib/math/box2"
import {Matrix3x2Like} from "@cm/lib/math/matrix3x2"
import {Vector2} from "@cm/lib/math/vector2"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {HalPainter} from "@app/common/models/hal/hal-painter"

export class HalPainterImageStretchTiled implements HalPainter {
    constructor(readonly context: HalContext) {
        this.halPainterImageStretch = new HalPainterImageStretch(this.context)
    }

    // HalEntity
    dispose(): void {
        this.halPainterImageStretch.dispose()
    }

    async paint(target: HalPaintable, source: HalImage, tiledTargetRegion?: Box2Like, options?: HalPainterPrimitiveOptions): Promise<Box2> {
        if (tiledTargetRegion) {
            const transform = options?.transform ? Matrix3x2.fromMatrix3x2Like(options.transform) : new Matrix3x2()
            const invertedMatrix = transform.inverse()
            const pixelMin = invertedMatrix.multiplyVector(new Vector2(tiledTargetRegion.x, tiledTargetRegion.y))
            const pixelMax = invertedMatrix.multiplyVector(
                new Vector2(tiledTargetRegion.x + tiledTargetRegion.width, tiledTargetRegion.y + tiledTargetRegion.height),
            )
            const imageSize = new Vector2(source.descriptor.width, source.descriptor.height)
            const imageMin = new Vector2(pixelMin.x / imageSize.x, pixelMin.y / imageSize.y).floorInPlace()
            const imageMax = new Vector2(pixelMax.x / imageSize.x, pixelMax.y / imageSize.y).ceilInPlace()
            const promisedPaints: Promise<void>[] = []
            for (let y = imageMin.y; y < imageMax.y; y++) {
                for (let x = imageMin.x; x < imageMax.x; x++) {
                    const thisTransform: Matrix3x2Like = {
                        a: transform.elements[0],
                        b: transform.elements[1],
                        c: transform.elements[2],
                        d: transform.elements[3],
                        tx: transform.elements[4] + x * imageSize.x * transform.elements[0],
                        ty: transform.elements[5] + y * imageSize.y * transform.elements[3],
                    }
                    promisedPaints.push(this.halPainterImageStretch.paint(target, source, {...options, transform: thisTransform}))
                }
            }
            await Promise.all(promisedPaints)
            return Box2.fromMinMax(imageMin.mul(imageSize), imageMax.mul(imageSize))
        } else {
            await this.halPainterImageStretch.paint(target, source, options)
            return new Box2(0, 0, source.descriptor.width, source.descriptor.height)
        }
    }

    private halPainterImageStretch: HalPainterImageStretch
}
