import {Injectable} from "@angular/core"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"

@Injectable({
    providedIn: "root",
})
export class NodeClipboardService {
    nodes: Nodes.Node[] = []

    constructor() {}

    get valid() {
        return this.nodes.length > 0
    }

    clear(): void {
        this.nodes = []
    }

    copy(nodes: Nodes.Node | Nodes.Node[]) {
        if (Array.isArray(nodes)) {
            this.nodes = [...nodes]
            // navigator.clipboard?.writeText(nodes.map(x => x.id).join("\n"));
        } else {
            this.nodes = [nodes]
            // navigator.clipboard?.writeText(nodes.id);
        }
    }

    pasteDuplicates(): Nodes.Node[] {
        return NodeUtils.copySubgraphStructure(this.nodes)
    }

    pasteReferences(): Nodes.Node[] {
        return [...this.nodes]
    }
}
