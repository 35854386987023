export enum DataObjectAssignmentType {
    Attachment = "Attachment",
    CachedTemplateGltf = "CachedTemplateGltf",
    CachedTemplateUsdz = "CachedTemplateUsdz",
    CryptoMatte01 = "CryptoMatte01",
    CryptoMatte02 = "CryptoMatte02",
    CryptoMatte03 = "CryptoMatte03",
    CustomerLogo = "CustomerLogo",
    DrawingData = "DrawingData",
    GalleryImage = "GalleryImage",
    GalleryImageMain = "GalleryImageMain",
    Icon = "Icon",
    JobTaskData = "JobTaskData",
    JobTaskInternalData = "JobTaskInternalData",
    MaterialCetSimple = "MaterialCetSimple",
    MaterialMapsExport = "MaterialMapsExport",
    MaterialThumbnail_7x7Inches = "MaterialThumbnail_7x7Inches",
    MaterialThumbnail_9x9Inches = "MaterialThumbnail_9x9Inches",
    MaterialThumbnail_10x10 = "MaterialThumbnail_10x10",
    MaterialThumbnail_16x16Inches = "MaterialThumbnail_16x16Inches",
    MaterialThumbnail_20x20 = "MaterialThumbnail_20x20",
    MaterialThumbnail_30x30 = "MaterialThumbnail_30x30",
    MaterialTileableRender = "MaterialTileableRender",
    MeshDataDrc = "MeshDataDrc",
    MeshDataDrcProxy = "MeshDataDrcProxy",
    MeshDataPly = "MeshDataPly",
    PdfTemplate = "PdfTemplate",
    PictureData = "PictureData",
    PictureDataLinear = "PictureDataLinear",
    PictureDataSecondary = "PictureDataSecondary",
    PictureDataTertiary = "PictureDataTertiary",
    ProfilePicture = "ProfilePicture",
    ScanAssemblyWorkPackage = "ScanAssemblyWorkPackage",
    ScanCalibrationData = "ScanCalibrationData",
    ScanFittingWorkPackage = "ScanFittingWorkPackage",
    ScanLog = "ScanLog",
    ScanMergedFile = "ScanMergedFile",
    ScanReferenceFile = "ScanReferenceFile",
    TemplateConfigIcon = "TemplateConfigIcon",
    TemplateDataOther = "TemplateDataOther",
    TextureEditDescriptor = "TextureEditDescriptor",
    TextureEditMask = "TextureEditMask",
    TilingGraph = "TilingGraph",
    BatchExport = "BatchExport",
    Font = "Font",
    FontBold = "FontBold",
}
