import {AddressSpace, ImageRefBase, ImageRefId} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref-base"
import {descriptorFromHalImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {SmartPtrArray} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr-array"
import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"

export class ImageWebGL2 extends ImageRefBase {
    constructor(
        addressSpace: AddressSpace,
        id: ImageRefId,
        disposeFn: ((image: ImageRefBase) => void) | undefined,
        readonly halImage: HalPaintableImage,
        readonly descriptor = descriptorFromHalImage(halImage),
        debugId = "no-debug-id",
    ) {
        super(addressSpace, id, disposeFn, debugId)
    }
}

export function isImageWebGL2(obj: unknown): obj is ImageWebGL2 {
    return obj instanceof ImageWebGL2
}

export class ImagePtrWebGl2 extends SmartPtr<ImageWebGL2> {}

export class ImagePtrArrayWebGl2 extends SmartPtrArray<ImageWebGL2> {}
