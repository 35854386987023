import {DestroyRef, inject, Injectable} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ContentTypeModel} from "@api"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {filter, firstValueFrom, switchMap, take, takeUntil} from "rxjs"
import {intervalBackoff} from "backoff-rxjs"
import {TabStateService} from "@common/services/tab-state/tab-state.service"

@Injectable({
    providedIn: "root",
})
export class ThumbnailsService {
    destroyRef = inject(DestroyRef)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    tabState = inject(TabStateService)

    async waitUntilAvailable(dataObjectId: string, refreshOnAvailable: boolean = true) {
        const scheduleCheck = this.tabState.becomesActive$.pipe(
            switchMap(() =>
                intervalBackoff({
                    initialInterval: 1000,
                    backoffDelay: (index) => Math.pow(1.1, index) * 1000,
                }).pipe(takeUntil(this.tabState.becomesInactive$)),
            ),
        )
        return firstValueFrom(
            scheduleCheck.pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() => this.sdk.gql.thumbnailAvailable({id: dataObjectId})),
                filter(({dataObject}) => !!dataObject?.thumbnailAvailable),
                take(1),
            ),
        ).then(() => {
            if (refreshOnAvailable) this.refresh.item({id: dataObjectId, __typename: ContentTypeModel.DataObject})
        })
    }
}
