<div class="cm-change-list">
    @if (changeList && entityUrl) {
        @for (historyChange of changeList; track historyChange) {
            @if (historyChange.object) {
                <a [routerLink]="[entityUrl, historyChange.object.id]">
                    <div class="cm-box">
                        <cm-data-object-thumbnail [dataObjectId]="historyChange.object?.galleryImage?.id" class="cm-box-image" />
                        <div class="cm-user">
                            <cm-data-object-thumbnail [dataObjectId]="historyChange.createdBy?.galleryImage?.id" class="cm-user-picture" />
                        </div>
                    </div>
                </a>
            } @else {
                <div class="cm-box cm-box-deleted">Deleted</div>
            }
        }
        @if (changeList.length === 0) {
            <cm-full-page-feedback icon="no-files" text="Nothing to show"></cm-full-page-feedback>
        }
    } @else {
        <cm-placeholder></cm-placeholder>
        <cm-placeholder></cm-placeholder>
        <cm-placeholder></cm-placeholder>
    }
</div>
