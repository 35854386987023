import {Injectable, signal} from "@angular/core"
import {JobOrderByCriteria, JobOrderByInput, JobTaskOrderByInput, SortOrder, TagOrderByCriteria, TagOrderByInput} from "@api"
import {of} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class SortOrderService {
    // TODO: Implement this
    tags$ = of<TagOrderByInput[]>([{key: TagOrderByCriteria.Id, direction: SortOrder.Asc}])
    $tags = signal<TagOrderByInput[]>([])

    jobs$ = of<JobOrderByInput[]>([{key: JobOrderByCriteria.LegacyId, direction: SortOrder.Desc}])
    jobTasks$ = of<JobTaskOrderByInput[]>([])
}
