import {AsyncPipe} from "@angular/common"
import {Component, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatOptionModule} from "@angular/material/core"
import {MatInputModule} from "@angular/material/input"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {RouterLink, RouterOutlet} from "@angular/router"
import {AssetDetailsFragment, ContentTypeModel, MutationUpdateAssetInput} from "@api"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {CopyLegacyIdButtonComponent} from "@common/components/buttons/copy-legacy-id-button/copy-legacy-id-button.component"
import {CopyToClipboardButtonComponent} from "@common/components/buttons/copy-to-clipboard-button/copy-to-clipboard-button.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {EntityActivityComponent} from "@common/components/entity/entity-activity/entity-activity.component"
import {EntityAddCardComponent} from "@common/components/entity/entity-add-card/entity-add-card.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {IsLoadingDirective} from "@common/directives"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {CornersLayoutComponent} from "@platform/components/layouts/corners-layout/corners-layout.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {SelectMaterialComponent} from "@platform/components/materials/select-material/select-material.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {CommentSectionComponent} from "@platform/components/details/comment-section/comment-section.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {ActivitySectionComponent} from "@platform/components/details/activity-section/activity-section.component"
import {ProjectManagementTasksSectionComponent} from "@platform/components/details/project-management-tasks-section/project-management-tasks-section.component"
import {ArticleIdSectionComponent} from "@platform/components/details/article-id-section/article-id-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {ShowPicturesButtonComponent} from "@platform/components/details/show-pictures-button/show-pictures-button.component"
import {TriggeredDialogComponent} from "@common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {SelectModelComponent} from "@platform/components/models/select-model/select-model.component"

@Component({
    selector: "cm-asset-details",
    templateUrl: "./asset-details.component.html",
    styleUrls: ["./asset-details.component.scss"],
    standalone: true,
    imports: [
        CopyToClipboardButtonComponent,
        DetailsDialogLayoutComponent,
        SectionComponent,
        GalleryImageComponent,
        EntityCardComponent,
        StateLabelComponent,
        EntityAddCardComponent,
        ButtonComponent,
        RouterLink,
        ToggleComponent,
        EntityActivityComponent,
        MatInputModule,
        IsLoadingDirective,
        FormsModule,
        MatOptionModule,
        MatSelectModule,
        SelectMaterialComponent,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        RoutedDialogComponent,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        CornersLayoutComponent,
        CopyLegacyIdButtonComponent,
        CommentSectionComponent,
        FilesSectionComponent,
        ActivitySectionComponent,
        ProjectManagementTasksSectionComponent,
        ArticleIdSectionComponent,
        OrganizationSelectComponent,
        TitleSectionComponent,
        ShowPicturesButtonComponent,
        TriggeredDialogComponent,
        SelectModelComponent,
        RouterOutlet,
        AsyncPipe,
    ],
})
export class AssetDetailsComponent extends BaseDetailsComponent<AssetDetailsFragment, Omit<MutationUpdateAssetInput, "id">> {
    override _contentTypeModel = ContentTypeModel.Asset
    override _fetchItem = this.sdk.gql.assetDetails
    override _updateItem = this.sdk.gql.updateAssetDetails
    override _deleteItem = this.sdk.raw.deleteAssetDetails

    override displayName = "asset"

    organizations = inject(OrganizationsService)

    openSelectMaterialDialog = () => {
        this.dialog.selectInDialog(SelectMaterialComponent, {
            onSelect: (material) => {
                const assetId = this.$item()?.id
                if (assetId) {
                    void this.notifications.withUserFeedback(
                        async () => {
                            await this.sdk.gql.createAssetDetailsMaterialAssignment({
                                input: {
                                    assetId,
                                    materialId: material.id,
                                },
                            })
                            this.refresh.item(this.$item())
                        },
                        {
                            success: "Material assignment created",
                            error: "Failed to create material assignment",
                        },
                    )
                }
            },
        })
    }

    deleteMaterialAssignment = (materialAssignment: {id: string}) => {
        void this.notifications.withUserFeedback(
            async () => {
                await this.sdk.gql.deleteAssetDetailsMaterialAssignment({id: materialAssignment.id})
                this.refresh.item(this.$item())
            },
            {
                success: "Material assignment deleted",
                error: "Failed to delete material assignment",
            },
        )
    }

    protected readonly DialogSize = DialogSize
}
