// @ts-strict-ignore
import {Component, Input} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatSliderModule} from "@angular/material/slider"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {ColorInputComponent} from "@common/components/inputs/color-input/color-input.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {IEditor} from "@editor/models/editor"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {colorToString, parseColor} from "@cm/lib/utils/utils"

@Component({
    selector: "cm-light-inspector",
    templateUrl: "./light-inspector.component.html",
    styleUrls: ["./light-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        InputContainerComponent,
        NumericInputComponent,
        ToggleComponent,
        StringInputComponent,
        MatSliderModule,
        ColorInputComponent,
        FormsModule,
    ],
})
export class LightInspectorComponent {
    @Input() editor: IEditor
    @Input() node: Nodes.Light | null = null

    isAreaLight = NodeUtils.isAreaLight
    isLightPortal = NodeUtils.isLightPortal
    parseColor = parseColor
    colorToString = colorToString

    visibilityOptions = ["DIRECT", "REFLECTIONS", "REFRACTIONS"]
    visibilityOptionsText = {
        DIRECT: "Visible directly",
        REFLECTIONS: "Visible in reflections",
        REFRACTIONS: "Visible in refractions",
    }

    nodeChanged(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
