import {DOCUMENT} from "@angular/common"
import {Inject, Injectable} from "@angular/core"
import {EventManager} from "@angular/platform-browser"
import {Observable} from "rxjs"
import {isApple} from "@app/common/helpers/device-browser-detection/device-browser-detection"

/* taken from https://netbasal.com/diy-keyboard-shortcuts-in-your-angular-application-4704734547a2 and extended

  Injectable hotkey manager.
  Add hotkeys like this (optional to specify a single hotkey or an array of hotkeys):
        hotkeys.addShortcut('meta.e').subscribe(() => console.log("Cmd/Win+E pressed"));
        hotkeys.addShortcut('shift.control.c').subscribe(() => console.log("Shift+Control+C pressed"));
        hotkeys.addShortcut(['shift.c', 'control.c', 'c']).subscribe(() => console.log("C (possibly with Shift xor Control) pressed"));
  Optionally provide the element to listen to (default is the entire document):
        hotkeys.addShortcut('shift.control.c', canvas).subscribe(() => console.log("Shift+Control+C pressed"));
*/

export type HotkeyLayerId = number

@Injectable({providedIn: "root"})
export class Hotkeys {
    private lastHotkeyLayerId: HotkeyLayerId = 0
    private hotkeyLayerIdStack: HotkeyLayerId[] = [0]

    constructor(
        private eventManager: EventManager,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    // call this function if you want to add a fresh layer of hotkeys while blocking out all previously registered ones (e.g. for a modal dialog)
    // when using this function don't forget to remove the layer at the appropriate time (e.g. when the modal dialog is destroyed)
    createLayer(): HotkeyLayerId {
        const id = ++this.lastHotkeyLayerId
        this.hotkeyLayerIdStack.push(id)
        return id
    }

    // removes the hotkey layer which had been added by pushLayer() and will reactivate the hotkeys of the previous layer
    removeLayer(id: HotkeyLayerId) {
        if (id <= 0) {
            throw Error("Id of hotkey layer must be >0.")
        }
        const index = this.hotkeyLayerIdStack.indexOf(id)
        if (index < 0) {
            throw Error("Attempting to remove a non-existent hotkey layer.")
        }
        this.hotkeyLayerIdStack.splice(index, 1)
    }

    // allows only hotkeys of that layer to be active, disabling all others (e.g. when a modal dialog opens)
    enableLayer(id: HotkeyLayerId) {
        const index = this.hotkeyLayerIdStack.indexOf(id)
        if (index < 0) {
            throw Error("Attempting to remove a non-existent hotkey layer.")
        }
        // put this id to the top of the stack and activate
        this.hotkeyLayerIdStack.splice(index, 1)
        this.hotkeyLayerIdStack.push(id)
    }

    // disables the hotkey layer; disabling all hotkeys in it and re-enabling the lower layer. the layer is not removed, only disabled. this is useful for modal dialogs which open/close without being recreated/destroyed.
    disableLayer(id: HotkeyLayerId) {
        const index = this.hotkeyLayerIdStack.indexOf(id)
        if (index < 0) {
            throw Error("Attempting to remove a non-existent hotkey layer.")
        }
        // put this id to the back of the stack and activate
        this.hotkeyLayerIdStack.splice(index, 1)
        this.hotkeyLayerIdStack.splice(0, 0, id)
    }

    // adds a hotkey to the currently active layer and returns a observable for it which
    addShortcut(keys: FlexibleKeybinding, element = this.document) {
        const events = this.getEventStrings(keys)
        const id = this.getActiveLayer()
        return new Observable((observer) => {
            const handler = (event: Event) => {
                // only forward the event if it is part of the active layer
                if (this.getActiveLayer() === id) {
                    // only forward valid key events
                    if (this.isValidKeyEvent(event)) {
                        event.preventDefault()
                        observer.next(event)
                    }
                }
            }
            const disposes = events.map((event) => this.eventManager.addEventListener(element as unknown as HTMLElement, event, handler))
            return () => {
                disposes.forEach((dispose) => dispose())
            }
        })
    }

    private getEventStrings(keys: FlexibleKeybinding): string[] {
        if (typeof keys === "string") {
            return [this.getEventString(keys)]
        } else if (Array.isArray(keys)) {
            return keys.map(this.getEventString)
        } else {
            return isApple ? this.getEventStrings(keys.mac) : this.getEventStrings(keys.win)
        }
    }

    private getActiveLayer(): number {
        if (this.hotkeyLayerIdStack.length < 1) {
            throw Error("Internal error. Somehow the base hotkey layer got removed.")
        }
        return this.hotkeyLayerIdStack[this.hotkeyLayerIdStack.length - 1]
    }

    private getEventString(keys: string): string {
        keys = standardFunctionMappings[keys] || keys
        return `keydown.${keys}`
    }

    private isValidKeyEvent(event: Event): boolean {
        if ((event.target as unknown as {nodeName: string}).nodeName == "INPUT") return false // do not forward keys which are directed at an input field
        return true
    }
}

const standardFunctionMappings: Record<string, string> = isApple
    ? {
          undo: "meta.z",
          redo: "meta.shift.z",
          copy: "meta.c",
          cut: "meta.x",
          paste: "meta.v",
      }
    : {
          undo: "control.z",
          redo: "control.y",
          copy: "control.c",
          cut: "control.x",
          paste: "control.v",
      }

export type Keybinding = string | string[]

export type MultiPlatformKeybinding = {
    mac: Keybinding
    win: Keybinding
}

export type FlexibleKeybinding = Keybinding | MultiPlatformKeybinding
