import {MaterialSocket} from "@material-editor/models/material-socket"
import {NodeSettings} from "@material-editor/models/material-nodes"
export const MappingOutputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "output", valueType: "output", label: "Vector"},
}

export const MappingInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "input", valueType: "vector", label: "Vector"},
    location: {paramType: "socket", id: "Location", type: "input", valueType: "vector", label: "Location"},
    rotation: {paramType: "socket", id: "Rotation", type: "input", valueType: "vector", label: "Rotation"},
    scale: {paramType: "socket", id: "Scale", type: "input", valueType: "vector", label: "Scale", defaultValue: [1, 1, 1]},
}

export const VectorType: NodeSettings = {
    id: "internal.vector_type",
    valueType: "string",
    options: [
        {label: "Point", value: "POINT"},
        {label: "Texture", value: "TEXTURE"},
        {label: "Vector", value: "VECTOR"},
        {label: "Normal", value: "NORMAL"},
    ],
}
