import {MaterialSocket} from "@material-editor/models/material-socket"

// Width and height are in user units, relative to the parent svg. See svg nesting and viewBox definition in the template.
export const GRAPH_WIDTH = 100
export const GRAPH_HEIGHT = 100

export class RgbPoint {
    svgElement?: HTMLElement | undefined

    constructor(
        public x: number,
        public y: number,
    ) {}

    static fromBlenderCoordinates(x: number, y: number): RgbPoint {
        return new RgbPoint(GRAPH_WIDTH * x, GRAPH_HEIGHT * (1 - y))
    }

    toBlenderCoordinates(): [number, number] {
        return [this.x / GRAPH_WIDTH, 1 - this.y / GRAPH_HEIGHT]
    }

    updateSVGPosition() {
        if (this.x) this.svgElement?.setAttribute("cx", this.x.toString())
        if (this.y) this.svgElement?.setAttribute("cy", this.y.toString())
    }

    getBlenderCoordinates(): {x: number; y: number} {
        return {x: this.x / GRAPH_WIDTH, y: 1 - this.y / GRAPH_HEIGHT}
    }
}

export const RgbCurvesInputs: Record<string, MaterialSocket> = {
    fac: {paramType: "socket", id: "Fac", type: "input", valueType: "scalar", label: "Fac", defaultValue: 1, range: {min: 0, max: 1}},
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
}

export const RgbCurvesOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
}

export type RgbCurve = {
    points: RgbPoint[]
    selector: string
    blenderIndex: 0 | 1 | 2 | 3
}

export type CurveType = "rgb" | "r" | "g" | "b"

export type CurveParameterType = "location" | "handle_type"

// At the moment we are not handling the different handle types, only the default one used by Blender, which auto.
// type CurveHandleType = "AUTO"
