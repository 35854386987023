@if (configGroupNode() || switchNode()) {
    <div class="cm-add-menu-items-container">
        <div class="cm-add-menu-items">
            @if (configGroupNode()) {
                <div matTooltip="Add Variant" class="cm-add-menu-item cm-lights" (click)="addConfigVariant()">+ Add variant</div>
                <div
                    matTooltip="Add Switch"
                    class="cm-add-menu-item cm-lights"
                    [matMenuTriggerFor]="addSwitchMenu"
                    (onMenuOpen)="onMenuOpen()"
                    (onMenuClose)="onMenuClose()"
                >
                    + Add switch
                    <!--Add switch menu-->
                    <mat-menu #addSwitchMenu class="cm-template-add-library">
                        <cm-template-menu>
                            <cm-template-menu-section>
                                <ng-container cm-section-title>
                                    <i class="far fa-random"></i>
                                    Switches
                                </ng-container>
                                <ng-container cm-section>
                                    <cm-template-menu-item [nodeClass]="ObjectSwitch.getNodeClass()" (click)="addObjectReferenceSwitch()">
                                    </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="MaterialSwitch.getNodeClass()" (click)="addMaterialSwitch()"> </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="TemplateSwitch.getNodeClass()" (click)="addTemplateSwitch()"> </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="ImageSwitch.getNodeClass()" (click)="addImageSwitch()"> </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="StringSwitch.getNodeClass()" (click)="addStringSwitch()"> </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="NumberSwitch.getNodeClass()" (click)="addNumberSwitch()"> </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="BooleanSwitch.getNodeClass()" (click)="addBooleanSwitch()"> </cm-template-menu-item>
                                    <cm-template-menu-item [nodeClass]="JSONSwitch.getNodeClass()" (click)="addJSONSwitch()"> </cm-template-menu-item>
                                </ng-container>
                            </cm-template-menu-section>
                        </cm-template-menu>
                    </mat-menu>
                </div>
            } @else {
                @if (switchNode(); as switchNode) {
                    <div
                        [matTooltip]="'Add Selected ' + getTemplateSwitchItemLabel(switchNode) + 's'"
                        class="cm-add-menu-item cm-lights"
                        (click)="addSelectionToSwitch()"
                    >
                        + Add selected
                    </div>
                }
            }
        </div>
    </div>
}
