const jitterVectors = [
    0, 0, 0.495, -0.499, -0.007, 0.498, -0.5, 0.002, 0.253, 0.245, 0.247, -0.25, -0.257, -0.251, -0.249, 0.245, 0.245, 0.497, 0.007, 0.248, -0.256, -0.498,
    -0.005, -0.252, 0.254, -0.005, 0.491, -0.249, -0.257, -0.006, -0.495, 0.258, -0.129, -0.122, -0.128, -0.376, -0.374, 0.123, 0.132, 0.12, 0.123, -0.123,
    0.119, -0.38, -0.386, -0.371, 0.371, 0.375, -0.383, -0.13, -0.135, 0.376, -0.119, 0.127, 0.374, -0.372, 0.389, 0.133, 0.118, 0.378, -0.374, 0.379, 0.386,
    -0.112, 0.123, -0.255, 0.255, 0.119, 0.246, 0.367, -0.132, -0.245, -0.119, 0.25, 0.25, -0.378, -0.134, 0.002, -0.002, -0.126, -0.011, 0.37, 0.374, 0.497,
    0.257, -0.128, -0.244, 0.122, 0.131, 0.256, -0.38, -0.496, 0.007, 0.126, -0.257, -0.13, -0.371, 0.254, -0.262, -0.376, 0.376, 0.253, 0.369, -0.247, -0.003,
    -0.379, 0.13, -0.002, -0.131, -0.497, 0.379, 0.009, -0.254, 0.364, 0.119, -0.499, -0.381, -0.25, -0.377, -0.006, 0.496, 0.379, -0.498, -0.124, -0.492, 0.12,
    0.489, -0.361,
]

export const getJitterVector = (frame: number) => {
    const idx = frame * 2
    if (idx < jitterVectors.length) return [jitterVectors[idx + 0], jitterVectors[idx + 1]]
    else return [Math.random() - 0.5, Math.random() - 0.5]
}
