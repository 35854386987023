import {EventEmitter} from "@angular/core"
import {wrap} from "@cm/lib/utils/utils"
import * as paper from "paper"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {Box2} from "@cm/lib/math/box2"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class ShiftableToolboxItem extends CanvasBaseToolboxItemBase {
    readonly shiftInPixelsChanged = new EventEmitter<Vector2>()

    // TODO reuse crosshair item
    private imageZeroLineGroup: paper.Group | null = null
    private imageZeroLineX: paper.Path | null = null
    private imageZeroLineY: paper.Path | null = null
    private _shiftInPixels = new Vector2(0, 0)
    private lastIntegrationMousePoint = new paper.Point(0, 0)

    constructor(parent: CanvasBaseToolboxItem) {
        super(parent)
        this.canvasBoundsChange.subscribe((value) => this.onCanvasBoundsChange(value))
        this.viewChange.subscribe(() => this.onViewChange())
        this.selectedChange.subscribe(() => this.updateShowGuides())
        this.createZeroLineGuides()
    }

    override remove() {
        super.remove()
        this.imageZeroLineX?.remove()
        this.imageZeroLineY?.remove()
        this.imageZeroLineGroup?.remove()
    }

    override hitTest(point: Vector2Like): boolean {
        if (this.selected && this.isPointInImage(point)) {
            this.cursor = "move"
            return true
        }
        return false
    }

    set showGuides(value: boolean) {
        this._showGuides = value
        this.updateShowGuides()
    }

    get showGuides(): boolean {
        return this._showGuides
    }

    set shiftInPixels(value: Vector2Like) {
        if (this._shiftInPixels.equals(value)) {
            return
        }
        this._shiftInPixels = Vector2.fromVector2Like(value)
        this.updateGuides()
        this.shiftInPixelsChanged.emit(this._shiftInPixels)
    }

    get shiftInPixels(): Vector2 {
        return this._shiftInPixels
    }

    override onMouseDown(event: paper.ToolEvent): boolean {
        this.lastIntegrationMousePoint = event.point
        return false
    }

    override onMouseUp(_event: paper.ToolEvent): boolean {
        return false
    }

    override onMouseDrag(event: paper.ToolEvent): boolean {
        this.selected = true
        const delta = event.point.subtract(this.lastIntegrationMousePoint).round()
        this.lastIntegrationMousePoint = this.lastIntegrationMousePoint.add(delta)
        this.shiftInPixels = this.shiftInPixels.add(Vector2.fromVector2Like(delta))
        return false
    }

    private createZeroLineGuides() {
        if (this.imageZeroLineX) {
            this.imageZeroLineX.remove()
            this.imageZeroLineX = null
        }
        if (this.imageZeroLineY) {
            this.imageZeroLineY.remove()
            this.imageZeroLineY = null
        }
        if (this.imageZeroLineGroup) {
            this.imageZeroLineGroup.remove()
            this.imageZeroLineGroup = null
        }
        this.layer.activate()
        this.imageZeroLineX = new paper.Path.Line(new paper.Point(0, this.canvasBounds.min.y), new paper.Point(0, this.canvasBounds.max.y))
        this.imageZeroLineX.strokeColor = new paper.Color("red")
        this.imageZeroLineY = new paper.Path.Line(new paper.Point(this.canvasBounds.min.x, 0), new paper.Point(this.canvasBounds.max.x, 0))
        this.imageZeroLineY.strokeColor = new paper.Color("red")
        this.imageZeroLineGroup = new paper.Group([this.imageZeroLineX, this.imageZeroLineY])
        this.updateShowGuides()
        this.updateGuides()
        this.updateZoomDependent()
    }

    private updateGuides() {
        if (!this.canvasBounds.width || !this.canvasBounds.height) {
            return
        }
        if (this.imageZeroLineX) {
            this.imageZeroLineX.position.x = wrap(this._shiftInPixels.x - this.canvasBounds.min.x, this.canvasBounds.width) + this.canvasBounds.min.x
        }
        if (this.imageZeroLineY) {
            this.imageZeroLineY.position.y = wrap(this._shiftInPixels.y - this.canvasBounds.min.y, this.canvasBounds.height) + this.canvasBounds.min.y
        }
    }

    private updateZoomDependent() {
        if (this.imageZeroLineX) {
            this.imageZeroLineX.strokeWidth = window.devicePixelRatio / this.zoomLevel
        }
        if (this.imageZeroLineY) {
            this.imageZeroLineY.strokeWidth = window.devicePixelRatio / this.zoomLevel
        }
    }

    private onCanvasBoundsChange(_value: Box2) {
        this.createZeroLineGuides()
    }

    private onViewChange() {
        this.updateZoomDependent()
    }

    private updateShowGuides() {
        if (this.imageZeroLineGroup) {
            this.imageZeroLineGroup.visible = this._showGuides && this.selected
        }
    }

    private _showGuides = true
}
