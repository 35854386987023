import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from "@angular/core"
import {BasicTagInfoFragment, TagType} from "@api"
import {AuthService} from "@common/services/auth/auth.service"

@Component({
    selector: "cm-tag-labels",
    templateUrl: "./tag-labels.component.html",
    styleUrls: ["./tag-labels.component.scss"],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagLabelsComponent implements OnInit {
    @Input() tags: BasicTagInfoFragment[] = []
    @Input() filterType?: TagType

    auth = inject(AuthService)

    ngOnInit() {
        this.getTags()
    }

    getTags(): void {
        if (this.filterType) {
            this.tags = this.tags.filter((tag) => tag.type !== this.filterType)
        }
    }
}
