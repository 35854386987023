import * as z from "zod"
import {ExtensionT} from "@src/utils/content-types"

export namespace DataObjectDefinitions {
    export type ImageFormat = Exclude<ExtensionT, "zip" | "bmp">
    export type DerivedImageFormat = Exclude<ImageFormat, "exr" | "png">
    // TODO, possible to avoid duplication?
    export const DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET = [
        {format: "jpg", resolution: "original"},
        {format: "tif", resolution: "original"},
        {format: "jpg", resolution: "px2000"},
        {format: "jpg", resolution: "px1000"},
        {format: "jpg", resolution: "px500"},
    ] as const

    export const DerivedImageFilenameWithFormatAndResolutionSetSchema = z
        .tuple([
            z.object({format: z.literal("jpg"), resolution: z.literal("original"), filename: z.string().nullable()}),
            z.object({format: z.literal("tif"), resolution: z.literal("original"), filename: z.string().nullable()}),
            z.object({format: z.literal("jpg"), resolution: z.literal("px2000"), filename: z.string().nullable()}),
            z.object({format: z.literal("jpg"), resolution: z.literal("px1000"), filename: z.string().nullable()}),
            z.object({format: z.literal("jpg"), resolution: z.literal("px500"), filename: z.string().nullable()}),
        ])
        .readonly()

    // export type DerivedImageResolution = (typeof DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET)[number]["resolution"]
    // type AuxT<K extends ReadonlyArray<any>, E> = {[I in keyof K]: K[I] & E}
    // export type DerivedImageFilenameWithFormatAndResolutionSet = AuxT<typeof DERIVED_IMAGE_FORMAT_AND_RESOLUTION_SET, {filename: string | null}>

    export type DerivedImageFilenameWithFormatAndResolutionSet = z.infer<typeof DerivedImageFilenameWithFormatAndResolutionSetSchema>
    export type DerivedImageResolution = DerivedImageFilenameWithFormatAndResolutionSet[number]["resolution"]
}
