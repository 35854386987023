import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageOpType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {imageOpReduce} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-reduce"
import {imageOpMath} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-math"
import {Size2Like} from "@cm/lib/math/size2"

// removes the mean and rescales by the standard deviation

export type ParameterType = {
    sourceImage: ImagePtr
    batchSize?: Size2Like // default: {width: 1, height: 1}
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpNormalize: ImageOpType<ParameterType, ReturnType> = {
    name: "Normalize",

    WebGL2: async ({context, parameters: {sourceImage, batchSize, resultImage}}) => {
        batchSize ??= {width: 1, height: 1}

        using sourceMean = await imageOpReduce.WebGL2({
            context,
            parameters: {sourceImage: sourceImage, operator: "mean", batchSize},
        })
        using meanFreeSource = await imageOpMath.WebGL2({
            context,
            parameters: {
                operator: "-",
                operandA: sourceImage,
                operandB: sourceMean,
                batchSizeA: batchSize,
                batchSizeB: batchSize,
            },
        })
        using meanFreeSourceStdDev = await imageOpReduce.WebGL2({
            context,
            parameters: {sourceImage: meanFreeSource, operator: "root-mean-square", batchSize},
        })
        using safeMeanFreeSourceStdDev = await imageOpMath.WebGL2({
            context,
            parameters: {
                operator: "+",
                operandA: meanFreeSourceStdDev,
                operandB: 1e-6,
            },
        })
        return await imageOpMath.WebGL2({
            context,
            parameters: {
                operator: "/",
                operandA: meanFreeSource,
                operandB: safeMeanFreeSourceStdDev,
                batchSizeA: batchSize,
                batchSizeB: batchSize,
                resultImage: resultImage,
            },
        })
    },

    ImgProc: async () => {
        throw new Error("Not implemented")
    },
}
