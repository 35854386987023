// @ts-strict-ignore
import {KeyValuePipe} from "@angular/common"
import {AfterViewInit, Component, ContentChildren, DoCheck, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren} from "@angular/core"
import {MatSelectModule} from "@angular/material/select"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {UtilsService} from "@legacy/helpers/utils"
import {NodeSettings, NodeSettingValueType} from "@material-editor/models/material-nodes"
import {MaterialSocket} from "@material-editor/models/material-socket"
import {NodeIoComponent} from "@node-editor/components/node-io/node-io.component"
import {findSocket} from "@node-editor/helpers/socket"
import {ParameterEvent, ParameterEventType, ParameterId, ParameterType, ParameterValue, Socket, SocketEvent, SocketPosition} from "@node-editor/models"

@Component({
    selector: "cm-node-base",
    templateUrl: "./node-base.component.html",
    styleUrls: ["./node-base.component.scss"],
    host: {
        "[class.cm-selected]": "selected",
    },
    standalone: true,
    imports: [NodeIoComponent, ToggleComponent, KeyValuePipe, NodeIoComponent, MatSelectModule, NodeIoComponent],
})
export class NodeBaseComponent<NodeT extends {id: string; name: string}> implements AfterViewInit, DoCheck {
    @ContentChildren(NodeIoComponent) nodeIosContent: QueryList<NodeIoComponent>
    @ViewChildren(NodeIoComponent) nodeIos: QueryList<NodeIoComponent> | undefined
    @Input() inputs: Record<string, MaterialSocket> | undefined
    @Input() outputs: Record<string, MaterialSocket> | undefined
    @Input() settings: NodeSettings[]
    @Input() typeInfo: any

    @Output() connectionChange: EventEmitter<SocketEvent> = new EventEmitter<SocketEvent>()
    @Output() parameterChange: EventEmitter<ParameterEvent<NodeT>> = new EventEmitter<ParameterEvent<NodeT>>()

    id: string | undefined = null
    getParameter: (id: ParameterId) => any
    setParameter: (id: ParameterId, value: ParameterValue, type: ParameterType) => void
    setParameterNoUpdate: (id: ParameterId, value: ParameterValue, type: ParameterType) => void
    disabled: () => boolean
    disableable: () => boolean
    toggleDisabled: () => void
    @Input() selected = false

    constructor(
        public element: ElementRef,
        public utils: UtilsService,
    ) {}

    ngAfterViewInit(): void {
        // bind all parameter changes
        // TODO: dynamic parameter sets
        for (const io of [...this.nodeIos, ...this.nodeIosContent]) {
            io.valueChange.subscribe((event) =>
                this.emitParameterChange({node: this.node, type: "update", parameter: {id: io.socket.id, value: event.value, type: event.type}}),
            )
        }
        for (const io of this.nodeIosContent) {
            io.connectionChange.subscribe((event) => this.connectionChange.emit(event))
        }
        this.updateConnectedSockets()
        if (this.settings) {
            for (const setting of this.settings) {
                if (!this.getParameter(setting.id))
                    this.setParameter(setting.id, setting.options.length > 0 ? setting.options[0].value : undefined, setting.valueType)
            }
        }
    }

    ngDoCheck() {
        //TODO: optimize change detection
        if (this.node && (this.nodeIos || this.nodeIosContent) && this.getParameter) {
            for (const io of [...this.nodeIos, ...this.nodeIosContent]) {
                if (io.socket.type === "input") {
                    io.value = this.getParameter(io.socket.id)
                }
            }
        }
    }

    onParameterChange(id: ParameterId, value: ParameterValue, type: ParameterType | NodeSettingValueType, eventType: ParameterEventType): void {
        this.emitParameterChange({node: this.node, type: eventType, parameter: {id: id, value: value, type: type}})
    }

    emitParameterChange(event: ParameterEvent<NodeT>) {
        this.parameterChange.emit(event)
    }

    addParamIfMissing(id: ParameterId, value: ParameterValue, type: ParameterType) {
        if (this.getParameter(id) === undefined) {
            this.setParameterNoUpdate(id, value, type)
        }
    }

    private _node: NodeT
    set node(n: NodeT) {
        this._node = n
    }

    get node() {
        return this._node
    }

    // private _typeInfo: NodeTypeInfo<NodeT>;
    // set typeInfo(info: NodeTypeInfo<NodeT>) {
    //     this._typeInfo = info;
    // }
    //
    // get typeInfo() {
    //     return this._typeInfo;
    // }

    private _connectedSockets: Set<Socket>
    @Input() set connectedSockets(sockets: Set<Socket>) {
        this._connectedSockets = sockets
        this.updateConnectedSockets()
    }

    get connectedSockets() {
        return this._connectedSockets
    }

    private updateConnectedSockets() {
        if ((this.nodeIos || this.nodeIosContent) && this._connectedSockets) {
            for (const io of [...this.nodeIos, ...this.nodeIosContent]) {
                io.connected = {value: this._connectedSockets.has(io.socket)}
            }
        }
    }

    getSocketPosition(socket: Socket): SocketPosition {
        if (!this.nodeIos && !this.nodeIosContent) {
            return undefined
        }
        for (const io of [...this.nodeIos, ...this.nodeIosContent]) {
            if (io.socket === socket) {
                return io.getSocketPosition()
            }
        }
        console.error("Could not find socket.", socket)
        return undefined
    }

    // TODO: Socket IDs (confusing name) are not unique, they are simply called "vector" or "value". Does this cause any problems?
    resolveSocket(id: Socket["id"], type: Socket["type"]): Socket | undefined {
        if (!this.typeInfo) {
            console.warn("Could not resolve socket: typeInfo is null!", this)
            return undefined
        }
        return findSocket(this.typeInfo, id, type)
    }
}
