<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" [needsConfirmationToClose]="modified" (close)="overlayClosed()">
    <ng-container cm-overlay-content>
        <div class="cm-main-card">
            @if (isLoading) {
                <cm-loading-spinner>
                    <ng-container cm-text-bottom>
                        <div class="cm-loading-text">Loading...</div>
                    </ng-container>
                </cm-loading-spinner>
            }
            <div class="cm-section-label">
                <div class="cm-section-label-01">
                    <i class="far fa-info-circle"></i>
                    <span class="cm-section-label-text">{{ entityName }}</span>
                    <cm-button [class.cm-disabled]="!modified" [class.cm-active]="modified" (click)="save()" class="cm-save">
                        <ng-container cm-left-icon>
                            <i class="far fa-save"></i>
                        </ng-container>
                        <ng-container cm-text> Save</ng-container>
                    </cm-button>
                    <div class="cm-icon-button" (click)="saveThumbnail()" matTooltip="Save snapshot as gallery image"><i class="far fa-camera"></i></div>
                    <div class="cm-icon-button" (click)="viewSubTemplate(null)" matTooltip="Back to root template"><i class="far fa-backward"></i></div>
                    <div class="cm-icon-button" (click)="openPriceMapping()" matTooltip="Open price mapping"><i class="fas fa-euro-sign"></i></div>
                    <div class="cm-icon-button" (click)="openInNewEditor()" matTooltip="Open in new editor"><i class="fas fa-arrow-up"></i></div>

                    <!-- TODO: Remove component? -->
                    <!--<div class="cm-mat-icon-button" (click)="submitRenderOverlay.openOverlay()" matTooltip="Submit and preview renders">-->
                    <!--<i class="fa-regular fa-industry-windows"></i>-->
                    <!--</div>-->
                    @if (enableDebugFeatures) {
                        <cm-button [matMenuTriggerFor]="downloadGraphMenu" class="cm-render">
                            <ng-container cm-left-icon>
                                <i class="fa-solid fa-diagram-project"></i>
                            </ng-container>
                            <ng-container cm-text> Download graph</ng-container>
                        </cm-button>
                    }
                </div>
            </div>
            <div class="cm-view-type">
                <div class="cm-section-label-01">
                    <cm-button (click)="mainViewMode = '3dedit'" [class.cm-active]="mainViewMode === '3dedit'" class="cm-render">
                        <ng-container cm-left-icon>
                            <i class="fa-solid fa-cube"></i>
                        </ng-container>
                        <ng-container cm-text> 3D Edit</ng-container>
                    </cm-button>
                    <cm-button (click)="mainViewMode = 'image'" [class.cm-active]="mainViewMode === 'image'" class="cm-render">
                        <ng-container cm-left-icon>
                            <i class="fa-solid fa-image"></i>
                        </ng-container>
                        <ng-container cm-text> Image</ng-container>
                    </cm-button>
                    <cm-button (click)="mainViewMode = 'configurator'" [class.cm-active]="mainViewMode === 'configurator'" class="cm-render">
                        <ng-container cm-left-icon>
                            <i class="fa-solid fa-browser"></i>
                        </ng-container>
                        <ng-container cm-text> Configurator / AR</ng-container>
                    </cm-button>
                </div>
            </div>
            <div class="cm-left-panel-container">
                <div [matMenuTriggerFor]="sideBarModeMenu" class="cm-tree-view-type-selector">
                    <div>
                        View:
                        @if (sideBarMode === "structure") {
                            Main
                        }
                        @if (sideBarMode === "interface") {
                            Select a Variation
                        }
                        @if (sideBarMode === "variations") {
                            All Variations
                        }
                    </div>
                    <i class="far fa-chevron-down"></i>
                </div>
                @if (entityRevision) {
                    <div class="cm-template-tree">
                        @if (sideBarMode === "interface") {
                            <cm-config-menu [hideInputsSetByTemplate]="true"></cm-config-menu>
                        }
                        @if (sideBarMode === "variations" && getConfigurationString(false); as curConfigString) {
                            <div class="cm-all-variations-container">
                                <div class="cm-render-actions">
                                    <cm-button
                                        class="cm-render"
                                        [disabled]="!hasRenderNode()"
                                        [class.cm-disabled]="!hasRenderNode()"
                                        (click)="submitJobsForAllVariations('render')"
                                    >
                                        <ng-container cm-left-icon>
                                            <i class="fa-solid fa-image"></i>
                                        </ng-container>
                                        <ng-container cm-text> Render All</ng-container>
                                    </cm-button>
                                    <cm-button
                                        class="cm-render"
                                        [disabled]="!hasPostProcessRenderNode()"
                                        [class.cm-disabled]="!hasPostProcessRenderNode()"
                                        (click)="submitJobsForAllVariations('postProcess')"
                                    >
                                        <ng-container cm-left-icon>
                                            <i class="fa-solid fa-save"></i>
                                        </ng-container>
                                        <ng-container cm-text> Finalize All</ng-container>
                                    </cm-button>
                                    <cm-button class="cm-delete-button" [matMenuTriggerFor]="deleteAllMenu">
                                        <ng-container cm-left-icon>
                                            <i class="far fa-trash"></i>
                                        </ng-container>
                                    </cm-button>
                                    <mat-menu #deleteAllMenu>
                                        <ng-template matMenuContent>
                                            <button mat-menu-item (click)="deleteAllJobs('render'); deleteAllJobs('postProcess')">Delete all images</button>
                                            <button mat-menu-item (click)="deleteAllJobs('postProcess')">Delete all final images only</button>
                                        </ng-template>
                                    </mat-menu>
                                </div>
                                <div class="cm-variation-list">
                                    @if (getAllVariations() | async; as entries) {
                                        @if (entries.length === 1) {
                                            <cm-list-item matTooltip="To define more variations go to the Main view">
                                                <ng-container cm-title> Single variation</ng-container>
                                            </cm-list-item>
                                            @if (watchJobsForVariation | memoize: this : entries[0].configString | async; as jobs) {
                                                <div class="cm-variation-list-icons">
                                                    <ng-container
                                                        *ngTemplateOutlet="jobIcon; context: {job: jobs.render, iconClass: 'fas fa-image'}"
                                                    ></ng-container>
                                                    <ng-container
                                                        *ngTemplateOutlet="jobIcon; context: {job: jobs.postProcess, iconClass: 'fas fa-palette'}"
                                                    ></ng-container>
                                                </div>
                                            }
                                        } @else {
                                            @for (entry of entries; track entry) {
                                                <cm-list-item
                                                    [selected]="entry.configString === curConfigString"
                                                    [matTooltip]="entry.name"
                                                    (click)="setConfigurationString(entry.configString)"
                                                >
                                                    <ng-container cm-title>
                                                        {{ entry.name }}
                                                    </ng-container>
                                                </cm-list-item>
                                                @if (watchJobsForVariation | memoize: this : entry.configString | async; as jobs) {
                                                    <div class="cm-variation-list-icons">
                                                        <ng-container
                                                            *ngTemplateOutlet="jobIcon; context: {job: jobs.render, iconClass: 'fas fa-image'}"
                                                        ></ng-container>
                                                        <ng-container
                                                            *ngTemplateOutlet="jobIcon; context: {job: jobs.postProcess, iconClass: 'fas fa-palette'}"
                                                        ></ng-container>
                                                    </div>
                                                }
                                            }
                                        }
                                    }
                                    <ng-template #jobIcon let-job="job" let-iconClass="iconClass">
                                        @if (job) {
                                            <div matTooltip="Job {{ job.id }} - {{ job.name }} - {{ job.state }} - {{ job.progress ?? '?' }}%">
                                                @if (isJobPending(job)) {
                                                    <cm-loading-spinner-icon class="cm-mini-spinner"></cm-loading-spinner-icon>
                                                } @else {
                                                    <i
                                                        [ngClass]="iconClass"
                                                        [class.cm-job-icon-complete]="isJobComplete(job)"
                                                        [class.cm-job-icon-error]="isJobError(job)"
                                                    >
                                                    </i>
                                                }
                                            </div>
                                        }
                                    </ng-template>
                                </div>
                            </div>
                        }
                        @if (sideBarMode === "structure" && entity && templateGraph) {
                            <cm-template-tree
                                #templateTree
                                [editor]="this"
                                [entity]="entity"
                                [templateGraph]="templateGraph"
                                (highlightNode)="highlightNode($event.node, $event.highlight, $event.transient, $event.modifier)"
                                (highlightSurface)="highlightSurface($event.object, $event.surfaceId)"
                                (activateConfigVariant)="activateConfigVariant($event.config, $event.variant)"
                                (viewSubTemplate)="viewSubTemplate($event)"
                                [hdris]="hdris"
                            >
                            </cm-template-tree>
                        }
                    </div>
                }
            </div>
            <div class="cm-inspector-area">
                @for (selectedNode of selectionService.selectedNodes; track selectedNode; let index = $index) {
                    @if (index > 0) {
                        <div class="cm-inspector-divider"></div>
                    }
                    @if (NodeUtils.isMeshOrInstance(selectedNode)) {
                        <cm-mesh-inspector
                            [editor]="this"
                            [meshNode]="selectedNode"
                            (showSurface)="surfaceDefiner.showSurface($event[0], $event[1], $event[2])"
                            (editSurface)="surfaceDefiner.editSurface($event[0], $event[1], $event[2])"
                            (defineNewSurface)="surfaceDefiner.defineNewSurface($event[0], $event[1])"
                            (chooseMaterialNode)="chooseMaterialNode($event)"
                            class="cm-inspector"
                        >
                            <ng-container cm-content>
                                <ng-container *ngTemplateOutlet="transformationInspector"></ng-container>
                            </ng-container>
                        </cm-mesh-inspector>
                    }
                    <!--FIXME: Show post processing inspector when post processing node is clicked.-->
                    <!--<cm-post-processing-inspector class="cm-inspector">-->
                    <!--</cm-post-processing-inspector>-->
                    @if (NodeUtils.isMeshDecal(selectedNode)) {
                        <cm-decal-inspector [editor]="this" [node]="selectedNode" (chooseMeshUV)="chooseMeshUV($event[0], $event[1])" class="cm-inspector">
                        </cm-decal-inspector>
                    }
                    @if (NodeUtils.isMaterialReference(selectedNode)) {
                        <cm-material-inspector
                            (chooseMaterial)="chooseLibraryMaterial($event)"
                            [materialNode]="selectedNode"
                            [editor]="this"
                            [templateEditorMode]="true"
                            class="cm-inspector"
                        >
                        </cm-material-inspector>
                    }
                    @if (NodeUtils.isConfigGroup(selectedNode)) {
                        <cm-config-group-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-config-group-inspector>
                    }
                    @if (NodeUtils.isConfigVariant(selectedNode)) {
                        <cm-config-variant-inspector [editor]="this" [node]="selectedNode" [editorService]="editorService" class="cm-inspector">
                        </cm-config-variant-inspector>
                    }
                    @if (NodeUtils.isRelation(selectedNode)) {
                        <cm-connection-inspector [editor]="this" [relation]="selectedNode" class="cm-inspector"></cm-connection-inspector>
                    }
                    @if (NodeUtils.isTemplateOrInstance(selectedNode)) {
                        <cm-template-inspector [editor]="this" [node]="selectedNode" class="cm-inspector">
                            <ng-container cm-content>
                                <ng-container *ngTemplateOutlet="transformationInspector"></ng-container>
                            </ng-container>
                        </cm-template-inspector>
                    }
                    @if (NodeUtils.isGuide(selectedNode)) {
                        <cm-guide-inspector [editor]="this" [node]="selectedNode" class="cm-inspector">
                            <ng-container cm-content>
                                <ng-container *ngTemplateOutlet="transformationInspector"></ng-container>
                            </ng-container>
                        </cm-guide-inspector>
                    }
                    @if (NodeUtils.isGroup(selectedNode)) {
                        <cm-group-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-group-inspector>
                    }
                    @if (NodeUtils.isValue(selectedNode)) {
                        <cm-value-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-value-inspector>
                    }
                    @if (NodeUtils.isTemplateInput(selectedNode)) {
                        <cm-template-input-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-template-input-inspector>
                    }
                    @if (NodeUtils.isTemplateExport(selectedNode)) {
                        <cm-template-output-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-template-output-inspector>
                    }
                    @if (NodeUtils.isLight(selectedNode)) {
                        <cm-light-inspector [editor]="this" [node]="selectedNode" class="cm-inspector">
                            <ng-container cm-content>
                                <ng-container *ngTemplateOutlet="transformationInspector"></ng-container>
                            </ng-container>
                        </cm-light-inspector>
                    }
                    @if (NodeUtils.isHDRILight(selectedNode)) {
                        <cm-hdri-light-inspector [editor]="this" [node]="selectedNode" [hdris]="hdris" class="cm-inspector"></cm-hdri-light-inspector>
                    }
                    @if (NodeUtils.isCamera(selectedNode)) {
                        <cm-camera-inspector [editor]="this" [node]="selectedNode" class="cm-inspector">
                            <ng-container cm-content>
                                <ng-container *ngTemplateOutlet="transformationInspector"></ng-container>
                                <cm-inspector-section>
                                    <ng-container cm-title> Preview</ng-container>
                                    <ng-container cm-content>
                                        <ng-template #cameraViewViewport>
                                            <div class="cm-camera-view-viewport-container">
                                                <div class="cm-camera-view-viewport"></div>
                                            </div>
                                        </ng-template>
                                        <cm-scene-view
                                            #cameraView
                                            [id]="'Camera View'"
                                            [cameraId]="sceneManager.getObjectIdForNode | memoize: sceneManager : selectedNode"
                                            [fixedAspectRatio]="true"
                                            [viewManager]="viewManager"
                                            [viewportTemplate]="cameraViewViewport"
                                            class="cm-camera-view"
                                        >
                                        </cm-scene-view>
                                    </ng-container>
                                </cm-inspector-section>
                            </ng-container>
                        </cm-camera-inspector>
                    }
                    @if (NodeUtils.isAnnotation(selectedNode)) {
                        <cm-annotation-inspector [editor]="this" [node]="selectedNode" class="cm-inspector">
                            <ng-container cm-content>
                                <ng-container *ngTemplateOutlet="transformationInspector"></ng-container>
                            </ng-container>
                        </cm-annotation-inspector>
                    }
                    @if (NodeUtils.isSceneProperties(selectedNode)) {
                        <cm-scene-properties-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-scene-properties-inspector>
                    }
                    @if (NodeUtils.isPostProcessRender(selectedNode)) {
                        <cm-post-processing-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-post-processing-inspector>
                    }
                    @if (NodeUtils.isRender(selectedNode)) {
                        <cm-render-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-render-inspector>
                    }
                    @if (selectedNode.type === "overlayMaterialColor") {
                        <cm-generic-node-inspector [editor]="this" [node]="selectedNode" class="cm-inspector"></cm-generic-node-inspector>
                    }
                }
                <cm-surface-definer #surfaceDefiner [editor]="this"></cm-surface-definer>
            </div>
            <div class="cm-viewer-section">
                <cm-drop-files class="cm-template-drop-files" [enableDrop]="can.change3d | memoize: this" (filesDropped)="handleDroppedFiles($event)">
                    @if (totalTasks > 0) {
                        <div class="cm-task-progress">
                            Completed {{ completeTasks }} of {{ totalTasks }} tasks...
                            <mat-progress-bar mode="determinate" [value]="100 * (completeTasks / totalTasks)"></mat-progress-bar>
                        </div>
                    }
                    @if (mainViewMode === "3dedit") {
                        <cm-interactive-scene-view
                            #mainSceneView
                            [config]="editViewConfig"
                            [viewManager]="viewManager"
                            [defaultCameraPosition]="[-200, 150, 200]"
                            [defaultCameraTarget]="[0, 0, 0]"
                            [cameraId]="trackActiveCamera ? activeCameraNode?.id : undefined"
                            [fixedAspectRatio]="activeCameraNode && trackActiveCamera"
                            (mouseEvent)="onViewMouseEvent($event)"
                            (selectionEvent)="onViewSelectionEvent($event)"
                            (initCompletedEvent)="onInitCompletedEvent()"
                        >
                            <ng-container cm-content>
                                <div class="cm-editor-settings-panel">
                                    <ng-container *ngTemplateOutlet="editorConfiguration"></ng-container>
                                </div>
                            </ng-container>
                        </cm-interactive-scene-view>
                    }
                    @if (mainViewMode === "image" && entityRevision && getConfigurationString(false); as configString) {
                        @if (watchJobsForVariation | memoize: this : configString | async; as jobs) {
                            @if (jobs.render) {
                                @if (isJobPending(jobs.render) && (jobs.render.output.preview === null || jobs.render.output.preview === undefined)) {
                                    <div class="cm-render-progress">
                                        <cm-loading-spinner-icon></cm-loading-spinner-icon>
                                        <div class="cm-render-progress-title">
                                            Rendering in progress
                                            <span class="cm-render-progress-percentage">{{ jobs.render.progress ?? "?" }} %</span>
                                            <i
                                                matTooltip="{{ jobs.render.id }} - {{ jobs.render.name }} - {{ jobs.render.state }}"
                                                class="far fa-circle-info"
                                            ></i>
                                            <i
                                                matTooltip="Refresh (Automatically refreshes every 5 minutes)"
                                                (click)="refreshJob()"
                                                class="far fa-refresh cm-pointer"
                                            ></i>
                                        </div>
                                        <cm-button (click)="deleteJob(jobs.render)" class="cm-delete-button">
                                            <ng-container cm-left-icon></ng-container>
                                            <ng-container cm-text> Stop render</ng-container>
                                        </cm-button>
                                    </div>
                                } @else {
                                    @if (isJobPending(jobs.render) && jobs.render.progress < 100) {
                                        <div class="cm-show-progress">
                                            <div class="cm-render-progress-title">
                                                Rendering in progress
                                                <span class="cm-render-progress-percentage">{{ jobs.render.progress ?? "?" }} %</span>
                                                <i
                                                    matTooltip="{{ jobs.render.id }} - {{ jobs.render.name }} - {{ jobs.render.state }}"
                                                    class="far fa-circle-info"
                                                ></i>
                                                <i
                                                    matTooltip="Refresh (Automatically refreshes every 5 minutes)"
                                                    (click)="refreshJob()"
                                                    class="far fa-refresh cm-pointer"
                                                ></i>
                                            </div>
                                        </div>
                                    }
                                    <cm-render-output-viewer
                                        [dataObject]="
                                            jobs.postProcess
                                                ? (fetchDataObjectFromRef | memoize: this : (watchJobOutput | memoize: this : jobs.postProcess | async) | async)
                                                : null
                                        "
                                        [renderJobOutput]="!jobs.postProcess ? (watchJobOutput | memoize: this : jobs.render | async) : null"
                                        [settings]="getPostProcessingSettings()"
                                        (deleteRender)="deleteJob(jobs.render); jobs.postProcess ? deleteJob(jobs.postProcess) : null"
                                        class="cm-render-output"
                                    >
                                        <ng-container cm-action-items-pos1>
                                            <div class="cm-delete-variation-button">
                                                <i [matMenuTriggerFor]="deleteMenu" class="far fa-trash"></i>
                                            </div>
                                            <mat-menu #deleteMenu>
                                                <ng-template matMenuContent>
                                                    <button
                                                        (click)="deleteJob(jobs.render); jobs.postProcess ? deleteJob(jobs.postProcess) : null"
                                                        mat-menu-item
                                                    >
                                                        Delete image
                                                    </button>
                                                    <button (click)="reRenderJob(jobs)" mat-menu-item>Re-render image</button>
                                                    @if (jobs.postProcess) {
                                                        <button
                                                            (click)="deleteJob(jobs.postProcess)"
                                                            matTooltip="This will only delete the finalized image, the preview will NOT be deleted.
                          If you want to remove both images click on Delete image"
                                                            mat-menu-item
                                                        >
                                                            Delete finalized image only
                                                        </button>
                                                    }
                                                </ng-template>
                                            </mat-menu>
                                        </ng-container>
                                        <ng-container cm-action-items-pos2>
                                            @if (!jobs.postProcess) {
                                                <cm-button
                                                    (click)="submitJobForVariation('postProcess', configString)"
                                                    class="cm-post-process-variation"
                                                    tooltip="The image you see is only a preview. If you want to download the final .tif file you need to save the final version."
                                                >
                                                    <ng-container cm-left-icon>
                                                        <i class="far fa-save"></i>
                                                    </ng-container>
                                                    <ng-container cm-text> Finalize</ng-container>
                                                </cm-button>
                                            }
                                        </ng-container>
                                    </cm-render-output-viewer>
                                }
                            } @else {
                                <div class="cm-no-render">
                                    <i class="cm-no-render-icon fa-regular fa-image-slash"></i>
                                    <div [matTooltip]="hasRenderNode() ? '' : 'Add a render-node to generate an image'">
                                        <cm-button
                                            [disabled]="!hasRenderNode()"
                                            (click)="submitJobForVariation('render', configString)"
                                            class="cm-render-variation-button"
                                        >
                                            <ng-container cm-left-icon></ng-container>
                                            <ng-container cm-text> Render this variation</ng-container>
                                        </cm-button>
                                    </div>
                                    <div class="cm-change-mode-text">
                                        To render more variations change to
                                        <span (click)="sideBarMode = 'variations'" class="cm-change-mode">All variations.</span>
                                    </div>
                                </div>
                            }
                        }
                    }
                    @if (mainViewMode === "configurator") {
                        <div class="cm-button-list">
                            <cm-button (click)="copyConfiguratorURL()">
                                <ng-container cm-text> Copy configurator URL to clipboard</ng-container>
                            </cm-button>
                            <cm-button (click)="clearCurrentARCache()">
                                <ng-container cm-text> Clear current cached AR data</ng-container>
                            </cm-button>
                            <cm-button (click)="clearAllARCaches()">
                                <ng-container cm-text> Clear all cached AR data</ng-container>
                            </cm-button>
                            <cm-button (click)="generateAllARVariants()">
                                <ng-container cm-text> Generate all AR variants</ng-container>
                            </cm-button>
                            <cm-button (click)="triggerARGeneration('backend')">
                                <ng-container cm-text> Generate current AR variant</ng-container>
                            </cm-button>
                            <cm-button (click)="triggerARGeneration('local')">
                                <ng-container cm-text> Generate current AR variant (local)</ng-container>
                            </cm-button>
                            <cm-button (click)="triggerARGeneration('info')">
                                <ng-container cm-text> Print info for current AR variant</ng-container>
                            </cm-button>
                            <cm-button (click)="exportARFile('gltf')">
                                <ng-container cm-text> Export glTF for current AR variant</ng-container>
                            </cm-button>
                            <cm-button (click)="downloadARFile('gltf')">
                                <ng-container cm-text> Download glTF for current AR variant</ng-container>
                            </cm-button>
                            <cm-button (click)="downloadARFile('usdz')">
                                <ng-container cm-text> Download USDZ for current AR variant</ng-container>
                            </cm-button>
                        </div>
                    }
                </cm-drop-files>
            </div>
        </div>
    </ng-container>
    <ng-template #transformationInspector>
        <cm-floating-controls [editor]="this" [(transformMode)]="transformControls.mode"></cm-floating-controls>
    </ng-template>
</cm-routed-dialog>
<ng-template #editorConfiguration>
    <i class="far fa-gear" matTooltip="Editor Settings" [matMenuTriggerFor]="editorConfigurationMenu"> </i>
    @if (activeCameraNode) {
        <cm-toggle (click)="$event.stopPropagation()" [(toggle)]="trackActiveCamera">
            <ng-container cm-text>Camera</ng-container>
        </cm-toggle>
    }
</ng-template>
<mat-menu #sideBarModeMenu>
    <div>
        <button mat-menu-item (click)="sideBarMode = 'structure'" [class.cm-tree-view-active]="sideBarMode === 'structure'">Main</button>
        <button mat-menu-item (click)="sideBarMode = 'interface'" [class.cm-tree-view-active]="sideBarMode === 'interface'">Select Variation</button>
        <button mat-menu-item (click)="sideBarMode = 'variations'" [class.cm-tree-view-active]="sideBarMode === 'variations'">All Variations</button>
    </div>
</mat-menu>
<mat-menu #editorConfigurationMenu>
    <div class="cm-editor-settings-menu">
        @if (defaultEnvironmentMapActive()) {
            <cm-toggle (click)="$event.stopPropagation()" [(toggle)]="displayConfig.ambientLight" (toggleChange)="displayConfigChanged()">
                <ng-container cm-text>Ambient Light</ng-container>
            </cm-toggle>
        }
        <cm-toggle (click)="$event.stopPropagation()" [(toggle)]="displayConfig.showGrid" (toggleChange)="displayConfigChanged()">
            <ng-container cm-text>Show Grid</ng-container>
        </cm-toggle>
    </div>
</mat-menu>
<mat-menu #downloadGraphMenu>
    <ng-template matMenuContent>
        <button mat-menu-item (click)="downloadTemplateGraph()">Template graph</button>
        <button mat-menu-item (click)="downloadRuntimeGraph()">Runtime graph</button>
    </ng-template>
</mat-menu>
