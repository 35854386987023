import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpAffineTransform} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-affine-transform"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"

const AffineTransformNode = declareImageOpNode(imageOpAffineTransform)

export function affineTransform(parameters: GetParameters<Context, ParameterType>) {
    return new AffineTransformNode(parameters)
}
