import {DataObjectAssignmentType} from "@api"
import {FlatThumbnail} from "@enums"
import {StateLabel} from "@platform/models/state-labels/state-label"

export type FlatOption = {
    size: number
    resolution: number
    dataObjectAssignmentType:
        | DataObjectAssignmentType.MaterialThumbnail_10x10
        | DataObjectAssignmentType.MaterialThumbnail_20x20
        | DataObjectAssignmentType.MaterialThumbnail_30x30
        | DataObjectAssignmentType.MaterialThumbnail_7x7Inches
        | DataObjectAssignmentType.MaterialThumbnail_9x9Inches
        | DataObjectAssignmentType.MaterialThumbnail_16x16Inches
}

export const FlatThumbnailOptionLabels = new Map<FlatThumbnail, StateLabel<FlatOption>>([
    [
        FlatThumbnail.FlatThumbnail_10x10,
        {
            state: {
                size: 10,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_10x10,
            },
            label: "10cm x 10cm",
        },
    ],
    [
        FlatThumbnail.FlatThumbnail_20x20,
        {
            state: {
                size: 20,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_20x20,
            },
            label: "20cm x 20cm",
        },
    ],
    [
        FlatThumbnail.FlatThumbnail_30x30,
        {
            state: {
                size: 30,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_30x30,
            },
            label: "30cm x 30cm",
        },
    ],
    [
        FlatThumbnail.FlatThumbnail_7x7Inches,
        {
            state: {
                size: 17.78,
                resolution: 2100,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_7x7Inches,
            },
            label: "7in x 7in",
        },
    ],
    [
        FlatThumbnail.FlatThumbnail_9x9Inches,
        {
            state: {
                size: 22.86,
                resolution: 3000,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_9x9Inches,
            },
            label: "9in x 9in",
        },
    ],
    [
        FlatThumbnail.FlatThumbnail_16x16Inches,
        {
            state: {
                size: 40.64,
                resolution: 4800,
                dataObjectAssignmentType: DataObjectAssignmentType.MaterialThumbnail_16x16Inches,
            },
            label: "16in x 16in",
        },
    ],
])
