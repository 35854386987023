import {ImageDescriptor} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageOpType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {Vector2Like} from "@cm/lib/math/vector2"
import {ColorLike} from "@cm/lib/math/color"
import {Vector4Like} from "@cm/lib/math/vector4"

const MAX_STOPS = 8

export type ParameterType = {
    descriptor: ImageDescriptor
    type: "linear"
    startPos: Vector2Like
    endPos: Vector2Like
    stops: {t: number; color: ColorLike}[] // stops are sorted by t
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpColorGradient: ImageOpType<ParameterType, ReturnType> = {
    name: "ColorGradient",

    WebGL2: async ({context, parameters: {descriptor, startPos, endPos, stops, resultImage}}) => {
        const halColorGradient = await context.getOrCreateImageCompositor(`
            uniform vec2 u_startPos;
            uniform vec2 u_endPos;
            uniform float u_stopsT[${MAX_STOPS}];
            uniform vec4 u_stopsColor[${MAX_STOPS}];

            vec4 computeColor(ivec2 targetPixel) {
                vec2 delta = u_endPos - u_startPos;
                float t = dot(u_endPos - u_startPos, vec2(targetPixel) - u_startPos) / dot(delta, delta);
                for (int i = 0; i < ${MAX_STOPS}; i++) {
                    if (t <= u_stopsT[i]) {
                        if (i == 0) {
                            return u_stopsColor[0];
                        }
                        float alpha = (t - u_stopsT[i - 1]) / (u_stopsT[i] - u_stopsT[i - 1]);
                        return mix(u_stopsColor[i - 1], u_stopsColor[i], alpha);
                    }
                }
                return vec4(0, 0, 0, 0);
            }
        `)
        const allStops = new Array<number>(MAX_STOPS).fill(0)
        const allColors = new Array<Vector4Like>(MAX_STOPS).fill({x: 0, y: 0, z: 0, w: 0})
        for (let i = 0; i < stops.length; i++) {
            allStops[i] = stops[i].t
            allColors[i] = {x: stops[i].color.r, y: stops[i].color.g, z: stops[i].color.b, w: stops[i].color.a ?? 1}
        }
        resultImage = await context.prepareResultImage(resultImage, descriptor)
        using resultImageWebGl2 = await context.getImage(resultImage)
        halColorGradient.setParameter("u_startPos", {type: "float2", value: startPos})
        halColorGradient.setParameter("u_endPos", {type: "float2", value: endPos})
        halColorGradient.setParameter("u_stopsT", {type: "float[]", value: allStops})
        halColorGradient.setParameter("u_stopsColor", {type: "float4[]", value: allColors})
        await halColorGradient.paint(resultImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async (_unused) => {
        throw new Error("Not implemented")
    },
}
