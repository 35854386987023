<!--  [class.cm-drop-zone-active]="dataObjects.length==0 || dropZoneActive.value"-->
<div class="cm-drop-files">
    <div class="cm-drop-files-content" [class.cm-drop-files-content--dropping]="isDropping">
        <ng-content></ng-content>
    </div>
    <input #fileInput class="cm-select-file" (change)="selectFiles($event)" type="file" multiple />
    @if (isDropping || showDropzone) {
        <button class="cm-drop-message-container" (click)="openFileSelector()">
            <i class="far fa-cloud-upload cm-upload-icon"></i>
            <span class="cm-upload-text">Drop your files here</span>
            @if (subtitle) {
                <span class="cm-upload-text-subtitle">
                    {{ subtitle }}
                </span>
            }
        </button>
    }
</div>
