import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const stateDescriptor = {
    stateClass: inletDescriptor<{new (): any}>(),
    output: outletDescriptor<any>(),
}

export class State<T> implements NodeClassImpl<typeof stateDescriptor, typeof State> {
    static descriptor = stateDescriptor
    static uniqueName = "State"
    stateClass!: Inlet<{new (): T}>
    output!: Outlet<T>
    private _state!: T

    run() {
        if (this.stateClass === NotReady) return
        this._state = new this.stateClass()
        this.output.emit(this._state)
    }
}
