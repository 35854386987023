import {Component, Inject} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {Options} from "app/templates/template-publisher/graph-exporter/runtime-graph-exporter"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"

@Component({
    selector: "cm-runtime-graph-export-options",
    templateUrl: "./runtime-graph-export-options.component.html",
    styleUrls: ["./runtime-graph-export-options.component.scss"],
    standalone: true,
    imports: [MatCheckboxModule, MatDialogModule, MatButtonModule],
})
export class RuntimeGraphExportOptionsComponent {
    constructor(
        private dialogRef: MatDialogRef<RuntimeGraphExportOptionsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Options,
    ) {}
}
