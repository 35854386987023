import {Component, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MAT_DATE_FORMATS} from "@angular/material/core"
import {MatDatepickerModule} from "@angular/material/datepicker"
import {MatInputModule} from "@angular/material/input"
import {Router} from "@angular/router"
import {midnightThisMorning, midnightTonight} from "@common/models/date/date"
import {CM_SHORT_DATE_FORMAT} from "@common/models/settings/settings"
import {FiltersService} from "@common/services/filters/filters.service"
import flatpickr from "flatpickr"

@Component({
    selector: "cm-statistics-filters",
    templateUrl: "./statistics-filters.component.html",
    styleUrls: ["./statistics-filters.component.scss"],
    providers: [{provide: MAT_DATE_FORMATS, useValue: CM_SHORT_DATE_FORMAT}],
    standalone: true,
    imports: [MatInputModule, FormsModule, MatDatepickerModule],
})
export class StatisticsFiltersComponent implements OnInit {
    @ViewChild("dateFrom", {static: true}) dateFrom!: ElementRef<HTMLInputElement>
    @ViewChild("dateTo", {static: true}) dateTo!: ElementRef<HTMLInputElement>

    filters = inject(FiltersService)
    router = inject(Router)

    fromDate = midnightThisMorning()
    toDate = midnightTonight()

    ngOnInit(): void {
        this.filters.statisticsFilter$.subscribe(({from, to}) => {
            this.fromDate = from
            this.toDate = to
        })
        const datePickerOptions = {
            enableTime: true,
            time_24hr: true,
            dateFormat: "D d M Y H:i",
        }
        flatpickr(this.dateFrom.nativeElement, {
            ...datePickerOptions,
            defaultDate: this.fromDate,
            onClose: (selectedDates) => {
                this.fromDate = selectedDates?.[0]
                this.filterChanged()
            },
        })
        flatpickr(this.dateTo.nativeElement, {
            ...datePickerOptions,
            defaultDate: this.toDate,
            onClose: (selectedDates) => {
                this.toDate = selectedDates?.[0]
                this.filterChanged()
            },
        })
    }

    @Output() filterChange: EventEmitter<{from: Date; to: Date}> = new EventEmitter<{from: Date; to: Date}>()

    filterChanged() {
        this.filterChange.emit({from: this.fromDate, to: this.toDate})
        void this.router.navigate([], {
            queryParams: {
                dateFrom: this.fromDate.toISOString(),
                dateTo: this.toDate.toISOString(),
            },
        })
    }
}
