import {Component, Input, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {JobTaskState} from "@api"
import {jobTaskStateIconClass} from "@app/common/helpers/jobs/icons"
import {jobTaskStateColor} from "@common/helpers/jobs/color"
import {jobTaskStateTooltip} from "@common/helpers/jobs/tooltip"
import {TippyDirective} from "@ngneat/helipopper"
import {JobStateTooltipComponent} from "@platform/components/jobs/job-state-tooltip/job-state-tooltip.component"
import {JobTaskStateTooltipComponent} from "@platform/components/jobs/job-task-state-tooltip/job-task-state-tooltip.component"

@Component({
    selector: "cm-job-task-thumbnail",
    standalone: true,
    imports: [TippyDirective, MatTooltipModule],
    templateUrl: "./job-task-thumbnail.component.html",
    styleUrl: "./job-task-thumbnail.component.scss",
})
export class JobTaskThumbnailComponent implements OnInit {
    @Input() jobTask: {state: JobTaskState} | null = null
    protected readonly jobTaskStateIconClass = jobTaskStateIconClass
    public tooltip?: string

    ngOnInit() {
        this.tooltip = jobTaskStateTooltip(this.jobTask)
    }

    protected readonly JobStateTooltipComponent = JobStateTooltipComponent
    protected readonly JobTaskStateTooltipComponent = JobTaskStateTooltipComponent
    protected readonly jobTaskStateColor = jobTaskStateColor
}
