<div class="cm-container">
    <cm-entity-responsive-sidebar class="cm-entity-sidebar">
        <cm-tabs [tabs]="tabs" (onChangeTab)="onChangeTabEvent($event)" [activeTab]="selectedTab"></cm-tabs>
        <div class="cm-sidebar">
            <div class="cm-sidebar-content">
                @if (selectedTab === "projects") {
                    <cm-project-tree [currentProjectId]="projectId" [currentSetId]="setId" [$organization]="$organization()"></cm-project-tree>
                }
                @if (selectedTab === "filters") {
                    <cm-item-filters>
                        <cm-tag-search-filter title="Search"></cm-tag-search-filter>
                        @if (userIsStaff) {
                            <cm-pinned-filter label="Pinned">
                                <cm-pinned-filter-option>Clear filters</cm-pinned-filter-option>
                                <cm-pinned-filter-option
                                    [$queryParams]="{pictureState: Enums.InPipelineStates.Picture, nextActor: Enums.NextActors.ProductionInProgress}"
                                >
                                    In progress
                                </cm-pinned-filter-option>
                                <cm-pinned-filter-option
                                    [$queryParams]="{pictureState: Enums.InPipelineStates.Picture, nextActor: Enums.NextActors.ProductionWaitingForFeedback}"
                                >
                                    Waiting for feedback
                                </cm-pinned-filter-option>
                            </cm-pinned-filter>
                        } @else {
                            <cm-pinned-filter label="Pinned">
                                <cm-pinned-filter-option [$indicateActivated]="false" [$queryParams]="{organizationId: $organization()?.id}"
                                    >Clear filters
                                </cm-pinned-filter-option>
                                <cm-pinned-filter-option [$queryParams]="{pictureState: Enums.PictureState.Draft, organizationId: $organization()?.id}">
                                    Draft
                                </cm-pinned-filter-option>
                                <cm-pinned-filter-option
                                    [$queryParams]="{
                                        pictureState: Enums.InPipelineStates.Picture,
                                        nextActor: Enums.NextActors.CustomerInProgress,
                                        organizationId: $organization()?.id
                                    }"
                                    >In progress
                                </cm-pinned-filter-option>
                                <cm-pinned-filter-option
                                    [$queryParams]="{
                                        pictureState: Enums.InPipelineStates.Picture,
                                        nextActor: Enums.NextActor.Customer,
                                        organizationId: $organization()?.id
                                    }"
                                    >Waiting for feedback
                                </cm-pinned-filter-option>
                                <cm-pinned-filter-option [$queryParams]="{pictureState: Enums.PictureState.OnHold, organizationId: $organization()?.id}"
                                    >On hold
                                </cm-pinned-filter-option>
                                <cm-pinned-filter-option [$queryParams]="{pictureState: Enums.PictureState.Completed, organizationId: $organization()?.id}">
                                    Completed
                                </cm-pinned-filter-option>
                            </cm-pinned-filter>
                        }
                        <cm-checkboxes-filter label="Up Next" name="nextActor" [options]="Labels.NextActor"></cm-checkboxes-filter>
                        @if (userIsStaff) {
                            <cm-checkboxes-filter label="Availability" name="hasAssignedUser" [options]="Labels.Assigned"></cm-checkboxes-filter>
                            <cm-checkboxes-filter label="Assets" name="assetsCompleted" [options]="Labels.Completed"></cm-checkboxes-filter>
                        }
                        <cm-checkboxes-filter label="State" name="pictureState" [options]="Labels.PictureState"></cm-checkboxes-filter>
                        @if (userIsStaff) {
                            <cm-checkboxes-filter label="User" name="userId" [options]="users.$filterOptions()"></cm-checkboxes-filter>
                            <cm-checkboxes-filter label="Payment State" name="paymentState" [options]="Labels.PaymentState"></cm-checkboxes-filter>
                        }
                    </cm-item-filters>
                }
            </div>
        </div>
    </cm-entity-responsive-sidebar>
    <cm-infinite-list [count]="totalCount" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
        <cm-list-info
            cm-list-header
            [parentList]="this"
            [enableBatchUpdates]="can.batchUpdatePictures()"
            [stateLabels]="stateLabels"
            [count]="totalCount"
            [batchDownloadData]="batchDownloadData"
            (createItemEvent)="addPicture()"
        ></cm-list-info>
        @for (picture of items; track picture) {
            @if (picture.placeholder) {
                <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
            } @else if (picture.error) {
                <cm-card-error @fadeInPlaceholder></cm-card-error>
            } @else {
                <cm-entity-card
                    @fadeInCard
                    [$entity]="picture.data"
                    [defaultTitle]="false"
                    [defaultSubtitle]="userIsStaff"
                    [entityLink]="cardLink(picture.data)"
                    [entityQueryParamsHandling]="'preserve'"
                    [enableDropZone]="can.changePicture($organization()?.id)"
                    (dropZoneUpload)="dropZoneUploadSave(picture.data, $event)"
                    (dragstart)="dragStart($event, picture.data)"
                    (dragenter)="dragEnter($event, picture.data)"
                    (dragend)="dragEnd()"
                    (drop)="drop($event, picture.data)"
                    ondragover="return false"
                    ondrop="return false"
                    [draggable]="selectedTab !== 'projects'"
                    [ngClass]="{'cm-drag-over': isSorting && dragTarget != undefined && picture.data.id === dragTarget.id}"
                    class="cm-picture"
                >
                    <ng-container cm-background>
                        @if (
                            !userIsStaff &&
                            picture.data.nextActor !== Enums.NextActor.Customer &&
                            (picture.data.state === Enums.PictureState.Stage1 ||
                                picture.data.state == Enums.PictureState.Stage2 ||
                                picture.data.state == Enums.PictureState.FinalRender)
                        ) {
                            <div class="cm-picture-overlay">In Progress</div>
                        }
                    </ng-container>
                    <ng-container cm-top-left>
                        <div class="cm-action-container" (click)="$event.stopPropagation()">
                            @if (userIsStaff || userIsFabricManufacturer) {
                                <a [routerLink]="createLinkToEditor(picture.data)" queryParamsHandling="preserve" class="cm-action-icon-container">
                                    <i class="far fa-pen-to-square cm-action-icon" matTooltip="Open editor"></i>
                                </a>
                            }
                            @if (picture.data.template) {
                                <a [routerLink]="[picture.data.id, 'view']" queryParamsHandling="preserve" class="cm-action-icon-container">
                                    <i class="far fa-cube cm-action-icon" matTooltip="View in 3D"></i>
                                </a>
                            }
                            @if (picture.data.downloadUrl) {
                                <a href="{{ picture.data.downloadUrl }}" download="filename" class="cm-action-icon-container">
                                    @if (picture.data.state == Enums.PictureState.Completed) {
                                        <i class="far fa-arrow-to-bottom cm-action-icon cm-download-high-res" matTooltip="Download high-res picture"></i>
                                    } @else {
                                        <i class="far fa-arrow-to-bottom cm-action-icon" matTooltip="Download"></i>
                                    }
                                </a>
                            }
                            @if (userIsStaff) {
                                <a (click)="setHighestPriority(picture.data)" class="cm-action-icon-container">
                                    <i class="far fa-arrow-to-top cm-action-icon" matTooltip="Highest priority"></i>
                                </a>
                            }
                            @if (picture.data.template != null) {
                                <a (click)="copyTemplate(picture.data, picture.data.set.id)" class="cm-action-icon-container">
                                    <i class="far fa-copy cm-action-icon" matTooltip="Make a copy"></i>
                                </a>
                            }
                        </div>
                    </ng-container>
                    <ng-container cm-bottom-left>
                        @if (userIsStaff) {
                            <cm-payment-state-label
                                [item]="picture.data"
                                [editable]="true"
                                (stateChange)="updateItem({id: picture.data.id, paymentState: $event})"
                            >
                            </cm-payment-state-label>
                        }
                        @if (!userIsPhotographer) {
                            <cm-state-label
                                [stateLabels]="Labels.PictureState"
                                [state]="picture.data.state"
                                [disableCompleted]="!userIsStaff"
                                [backgroundColor]="!userIsStaff && picture.data.nextActor === Enums.NextActor.Customer ? '#f13536' : undefined"
                                [editable]="userIsStaff || (userIsFabricManufacturer && picture.data.state !== Enums.PictureState.Completed)"
                                (stateChange)="updateItem({id: picture.data.id, state: $event})"
                            >
                            </cm-state-label>
                        }
                    </ng-container>
                    <ng-container cm-bottom-right>
                        @if (userIsStaff) {
                            <cm-assign-user
                                [matMenuTriggerFor]="userMenu"
                                [matMenuTriggerData]="{entity: picture.data}"
                                [$user]="picture.data.currentUserAssignment"
                                [nextActor]="picture.data.nextActor"
                            >
                            </cm-assign-user>
                        }
                    </ng-container>
                    <ng-container cm-title>
                        @if (userIsStaff) {
                            (P{{ picture.data.set.project.number }}, S{{ picture.data.set.number }})
                        }
                        <span [matTooltip]="picture.data.name">
                            Picture {{ picture.data.number }}{{ picture.data.name ? ":" : "" }} {{ picture.data.name }}
                        </span>
                    </ng-container>
                    <ng-container cm-subtitle>
                        @if (userIsStaff) {
                            @if (
                                picture.data.assetInfos &&
                                (picture.data.assetInfos.new > 0 ||
                                    picture.data.assetInfos.readyForShading > 0 ||
                                    picture.data.assetInfos.shading > 0 ||
                                    picture.data.assetInfos.review > 0 ||
                                    picture.data.assetInfos.completed > 0)
                            ) {
                                <div class="cm-shaded-model-boxes">
                                    <i class="far fa-loveseat"></i>
                                    @if (picture.data.assetInfos.new != 0) {
                                        <div matTooltip="Info review" class="cm-shaded-model-info-box cm-dashed-border">
                                            {{ picture.data.assetInfos.new }}
                                        </div>
                                    }
                                    @if (picture.data.assetInfos.readyForShading != 0) {
                                        <div class="cm-shaded-model-info-box cm-grey-background">
                                            {{ picture.data.assetInfos.readyForShading }}
                                        </div>
                                    }
                                    @if (picture.data.assetInfos.shading != 0 || picture.data.assetInfos.review != 0) {
                                        <div matTooltip="In progress" class="cm-shaded-model-info-box cm-yellow-background">
                                            {{ picture.data.assetInfos.shading + picture.data.assetInfos.review }}
                                        </div>
                                    }
                                    @if (picture.data.assetInfos.completed != 0) {
                                        <div matTooltip="Completed" class="cm-shaded-model-info-box cm-green-background">
                                            {{ picture.data.assetInfos.completed }}
                                        </div>
                                    }
                                </div>
                            }
                            <cm-comment-boxes [entity]="picture.data"></cm-comment-boxes>
                        } @else {
                            <div class="cm-project-set" [matTooltip]="picture.data.set.project.name + ' - ' + picture.data.set.name">
                                Project: {{ picture.data.set.project.number }}, Set: {{ picture.data.set.number }}
                            </div>
                        }
                    </ng-container>
                    <ng-container cm-details-bottom-right>
                        @if ($canChangePicture()) {
                            <i [matMenuTriggerFor]="pictureMenu" [matMenuTriggerData]="{picture: picture.data}" class="far fa-ellipsis-v cm-menu-dots"></i>
                        }
                    </ng-container>
                </cm-entity-card>
            }
        }
    </cm-infinite-list>
</div>
<router-outlet></router-outlet>
<mat-menu #userMenu [overlapTrigger]="false" [hasBackdrop]="true">
    <ng-template matMenuContent let-entity="entity">
        <cm-assign-user-dialog
            [$user]="entity.currentUserAssignment"
            [nextActor]="entity.nextActor"
            (userChange)="updateAssignedUser(Enums.ContentTypeModel.Picture, entity, $event)"
            (nextActorChange)="updateItem({id: entity.id, nextActor: $event})"
        ></cm-assign-user-dialog>
    </ng-template>
</mat-menu>
<mat-menu #pictureMenu="matMenu" [overlapTrigger]="false" [hasBackdrop]="true">
    <ng-template matMenuContent let-picture="picture">
        <button mat-menu-item (click)="renamePicture(picture)">
            <span>Rename</span>
        </button>
        <button mat-menu-item (click)="renameDataObject(picture)">
            <span>Rename file (last revision)</span>
        </button>
        @if (userIsStaff) {
            <button mat-menu-item (click)="movePicture(picture)">
                <span>Move</span>
            </button>
        }
        @if (picture.template) {
            <button mat-menu-item (click)="copyTemplate(picture)">
                <span>Copy scene</span>
            </button>
            <button mat-menu-item>
                <a [routerLink]="['../', 'templates', picture.template.id]" queryParamsHandling="">
                    <span>Goto template</span>
                </a>
            </button>
        }
        @if (userIsStaff || picture.state === Enums.PictureState.Draft) {
            <button mat-menu-item (click)="deletePicture(picture)">
                <span>Delete</span>
            </button>
        }
    </ng-template>
</mat-menu>
