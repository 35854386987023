<cm-sidebar-layout>
    <cm-item-filters cm-filters>
        <cm-tag-search-filter
            placeholder="Name, description, ..."
            [selectableTagTypes]="[Enums.TagType.MaterialColor, Enums.TagType.MaterialRange, Enums.TagType.Production]"
        ></cm-tag-search-filter>
        @if (can.viewExtraMaterialFilters()) {
            <cm-pinned-filter label="Pinned">
                <cm-pinned-filter-option>Clear filters</cm-pinned-filter-option>
                <cm-pinned-filter-option [$queryParams]="{materialState: Enums.InPipelineStates.Material, nextActor: Enums.NextActors.ProductionInProgress}"
                    >In progress
                </cm-pinned-filter-option>
                <cm-pinned-filter-option
                    [$queryParams]="{materialState: Enums.InPipelineStates.Material, nextActor: Enums.NextActors.ProductionWaitingForFeedback}"
                    >Waiting for feedback
                </cm-pinned-filter-option>
            </cm-pinned-filter>
            <cm-checkboxes-filter label="Up Next" name="nextActor" [options]="Labels.NextActor"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Availability" name="hasAssignedUser" [options]="Labels.Assigned"></cm-checkboxes-filter>
        }
        @if (can.filterMaterialsByScanner()) {
            <cm-checkboxes-filter label="Scanned by" name="scannedByUserId" [options]="users.$scannerUserOptions()">
                <cm-native-checkbox [value]="null" (valueChange)="toggleWithoutScan($event)">
                    <ng-container cm-label> None </ng-container>
                </cm-native-checkbox>
            </cm-checkboxes-filter>
        }
        <cm-checkboxes-filter label="State" name="materialState" [options]="Labels.MaterialState"></cm-checkboxes-filter>
        @if (can.viewExtraMaterialFilters()) {
            <cm-checkboxes-filter label="Sample" name="sampleArrived" [options]="Labels.Available"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Cycles Material" name="hasCyclesMaterial" [options]="Labels.Available"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Usage" name="meshSpecific" [options]="Labels.MeshSpecific"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="User" name="userId" [options]="users.$filterOptions()"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Payment State" name="paymentState" [options]="Labels.PaymentState"></cm-checkboxes-filter>
            <cm-checkboxes-filter label="Visibility" name="public" [options]="Labels.Public"></cm-checkboxes-filter>
        }
    </cm-item-filters>
    <cm-materials-grid></cm-materials-grid>
</cm-sidebar-layout>
<router-outlet></router-outlet>
