import * as THREE from "three"
import {Matrix4Like} from "@cm/lib/math/matrix4"
import {IMatrix4} from "@cm/lib/templates/interfaces/matrix"
import {IQuaternion} from "@cm/lib/templates/interfaces/quaternion"
import {AABB} from "@cm/lib/templates/interfaces/scene-manager"
import {IVector3} from "@cm/lib/templates/interfaces/vector"

export class Vector3 implements IVector3 {
    constructor(
        public x = 0,
        public y = 0,
        public z = 0,
    ) {}

    set(x: number, y: number, z: number) {
        this.x = x
        this.y = y
        this.z = z
    }

    toArray(): [number, number, number] {
        return [this.x, this.y, this.z]
    }

    static fromArray(a: number[]) {
        return new this(a[0], a[1], a[2])
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    toThree() {
        return new THREE.Vector3(this.x, this.y, this.z)
    }

    static fromThree(v: THREE.Vector3) {
        return new this(v.x, v.y, v.z)
    }

    static fromIVector3(v: IVector3) {
        return new this(v.x, v.y, v.z)
    }

    equals(other: Vector3) {
        return this.x == other.x && this.y == other.y && this.z == other.z
    }

    copy(): Vector3 {
        return new Vector3(this.x, this.y, this.z)
    }

    dot(v: Vector3): number {
        return this.x * v.x + this.y * v.y + this.z * v.z
    }

    norm(): number {
        return Math.sqrt(this.dot(this))
    }

    distance(v: Vector3): number {
        return this.sub(v).norm()
    }

    add(b: Vector3): Vector3 {
        return new Vector3(this.x + b.x, this.y + b.y, this.z + b.z)
    }

    addInPlace(b: Vector3): Vector3 {
        this.x += b.x
        this.y += b.y
        this.z += b.z
        return this
    }

    sub(b: Vector3): Vector3 {
        return new Vector3(this.x - b.x, this.y - b.y, this.z - b.z)
    }

    subInPlace(b: Vector3): Vector3 {
        this.x -= b.x
        this.y -= b.y
        this.z -= b.z
        return this
    }

    mul(s: number): Vector3 {
        return new Vector3(this.x * s, this.y * s, this.z * s)
    }

    mulInPlace(s: number): Vector3 {
        this.x *= s
        this.y *= s
        this.z *= s
        return this
    }

    div(s: number): Vector3 {
        return new Vector3(this.x / s, this.y / s, this.z / s)
    }

    divInPlace(s: number): Vector3 {
        this.x /= s
        this.y /= s
        this.z /= s
        return this
    }

    cross(b: Vector3): Vector3 {
        return new Vector3(this.y * b.z - this.z * b.y, this.z * b.x - this.x * b.z, this.x * b.y - this.y * b.x)
    }

    normalized(): Vector3 {
        return this.mul(1 / this.norm())
    }
}

export class Matrix4 implements IMatrix4 {
    elements: number[]

    constructor(elements?: ArrayLike<number>) {
        this.elements = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
        if (elements !== undefined) {
            if (elements.length !== 16) {
                throw new Error("Matrix4 must have exactly 16 elements!")
            }
            for (let n = 0; n < 16; n++) {
                this.elements[n] = elements[n]
            }
        }
    }

    static identity(): Matrix4 {
        return new Matrix4()
    }

    toArray(): number[] {
        return this.elements
    }

    static fromArray(elements: ArrayLike<number>) {
        return new this(elements)
    }

    toArrayTransposed(): number[] {
        const e = this.elements
        return [e[0], e[4], e[8], e[12], e[1], e[5], e[9], e[13], e[2], e[6], e[10], e[14], e[3], e[7], e[11], e[15]]
    }

    static fromArrayTransposed(elements: ArrayLike<number>) {
        const e = elements
        return new this([e[0], e[4], e[8], e[12], e[1], e[5], e[9], e[13], e[2], e[6], e[10], e[14], e[3], e[7], e[11], e[15]])
    }

    // TODO is this the right order ?
    static fromMatrix4Like(m: Matrix4Like) {
        return new this([m.m11, m.m12, m.m13, m.m14, m.m21, m.m22, m.m23, m.m24, m.m31, m.m32, m.m33, m.m34, m.m41, m.m42, m.m43, m.m44])
    }

    toThreeMatrix() {
        const threeMatrix = new THREE.Matrix4()
        threeMatrix.fromArray(this.elements)
        return threeMatrix
    }

    decompose() {
        //TODO: don't depent on threejs here
        const position = new THREE.Vector3()
        const quaternion = new THREE.Quaternion()
        const scale = new THREE.Vector3()
        this.toThreeMatrix().decompose(position, quaternion, scale)
        return {
            position: Vector3.fromThree(position),
            quaternion: Quaternion.fromThree(quaternion),
            scale: Vector3.fromThree(scale),
        }
    }

    compose(position: Vector3, quaternion: Quaternion, scale: Vector3) {
        const threeMatrix = new THREE.Matrix4()
        threeMatrix.compose(position.toThree(), quaternion.toThree(), scale.toThree())
        this.elements = threeMatrix.elements
        return this
    }

    static fromThreeMatrix(threeMatrix: THREE.Matrix4) {
        return new this(threeMatrix.toArray())
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    equals(other: Matrix4 | THREE.Matrix4) {
        const ea = this.elements
        const eb = other.elements
        for (let n = 0; n < 16; n++) {
            if (ea[n] !== eb[n]) {
                return false
            }
        }
        return true
    }

    withinEpsilon(other: Matrix4 | THREE.Matrix4, eps: number) {
        const ea = this.elements
        const eb = other.elements
        for (let n = 0; n < 16; n++) {
            if (Math.abs(ea[n] - eb[n]) > eps) {
                return false
            }
        }
        return true
    }

    maxDifference(other: Matrix4 | THREE.Matrix4) {
        let md = 0
        const ea = this.elements
        const eb = other.elements
        for (let n = 0; n < 16; n++) {
            const d = Math.abs(ea[n] - eb[n])
            if (d > md) {
                md = d
            }
        }
        return md
    }

    static from3dsMaxTransform(t: Vector3 | undefined, r: Vector3 | undefined, s: Vector3 | undefined, orientation: boolean): Matrix4 {
        // cameras have a different orientation in Max and threeJS, fix that by leaving out the initial transform
        let matrix = orientation ? Matrix4.identity() : Matrix4.fromArray([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1]) // from native to Max
        if (s) {
            matrix = Matrix4.scaling(s.x, s.y, s.z).multiply(matrix)
        }
        if (r) {
            matrix = Matrix4.rotationX(r.x * (180 / Math.PI)).multiply(matrix)
            matrix = Matrix4.rotationY(r.y * (180 / Math.PI)).multiply(matrix)
            matrix = Matrix4.rotationZ(r.z * (180 / Math.PI)).multiply(matrix)
        }
        if (t) {
            matrix = Matrix4.translation(t.x, t.y, t.z).multiply(matrix)
        }
        matrix = Matrix4.fromArray([1, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1]).multiply(matrix) // from Max to native
        return matrix
    }

    static to3dsMaxTransform(
        matrix: Matrix4,
        orientation: boolean,
    ): {
        position: Vector3
        rotation: Vector3
        scale: Vector3
    } {
        const nm = Matrix4.fromArray([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1]) // from native to Max
        const mn = Matrix4.fromArray([1, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1]) // from Max to native

        // cameras have a different orientation in Max and threeJS, fix that by leaving out the initial transform
        const {position, quaternion, scale} = orientation ? nm.multiply(matrix).decompose() : nm.multiply(matrix).multiply(mn).decompose()
        const tmpEuler = new THREE.Euler()
        tmpEuler.setFromQuaternion(new THREE.Quaternion(quaternion.x, quaternion.y, quaternion.z, quaternion.w), "ZYX")
        const rotation = new Vector3(tmpEuler.x, tmpEuler.y, tmpEuler.z)

        return {position, rotation, scale}
    }

    static automaticVerticalTilt(
        cameraTransformIn: Matrix4,
        filmGauge: number,
        focalLength: number,
        width: number,
        height: number,
    ): readonly [Matrix4, number] {
        const cameraTransform = cameraTransformIn.toThreeMatrix()

        // calculate target in front of camera
        const positionCamera = new THREE.Vector3()
        const quaternionCamera = new THREE.Quaternion()
        const scaleCamera = new THREE.Vector3()
        cameraTransform.decompose(positionCamera, quaternionCamera, scaleCamera)

        const normal = new THREE.Vector3(0, 0, -1).applyQuaternion(quaternionCamera)
        const positionTarget = Vector3.fromThree(new THREE.Vector3().add(positionCamera).add(normal.setLength(100)))

        // move target onto same y height as camera (y is up!)
        const targetAbove = positionTarget.y > positionCamera.y
        const positionNew = new THREE.Vector3().add(positionTarget.toThree())
        positionNew.y = positionCamera.y

        // calculate direction vectors to both points
        const directionTarget = new THREE.Vector3().add(positionTarget.toThree()).sub(positionCamera).normalize()
        const directionNew = new THREE.Vector3().add(positionNew).sub(positionCamera).normalize()

        // calculate rotation quaternion between both points
        let rotationQuaternion = new THREE.Quaternion().setFromUnitVectors(directionTarget, directionNew)

        // rotate camera
        rotationQuaternion = rotationQuaternion.multiply(quaternionCamera)
        cameraTransform.compose(positionCamera, rotationQuaternion, scaleCamera)

        // calculate rotation angle
        let rotationAngle = quaternionCamera.angleTo(rotationQuaternion)

        if (targetAbove) {
            rotationAngle = -rotationAngle
        }

        // camera shift amount is in units of camera FOV
        const [_fovX, fovY] = calculateFOV(filmGauge, focalLength, width, height)
        const additionalShiftY = -Math.tan(rotationAngle) / (2 * Math.tan(fovY / 2))

        return [Matrix4.fromThreeMatrix(cameraTransform), additionalShiftY]
    }

    static targetFromTransform(value: Matrix4): Vector3 {
        const threeMatrix = value.toThreeMatrix()

        const position = new THREE.Vector3()
        const quaternion = new THREE.Quaternion()
        const scale = new THREE.Vector3()

        threeMatrix.decompose(position, quaternion, scale)

        const normal = new THREE.Vector3(0, 0, -1).applyQuaternion(quaternion)
        const result = Vector3.fromThree(new THREE.Vector3().add(position).add(normal.setLength(300)))

        return result
    }

    multiplyOld(other: Matrix4) {
        const ea = this.elements
        const eb = other.elements
        const result = []
        for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 4; j++) {
                result.push(ea[i * 4] * eb[j] + ea[i * 4 + 1] * eb[j + 4] + ea[i * 4 + 2] * eb[j + 8] + ea[i * 4 + 3] * eb[j + 12])
            }
        }
        return new Matrix4(result)
    }

    multiply(other: Matrix4) {
        const ret = new Matrix4()
        const ae = this.elements
        const be = other.elements
        const re = ret.elements
        const a11 = ae[0],
            a12 = ae[4],
            a13 = ae[8],
            a14 = ae[12]
        const a21 = ae[1],
            a22 = ae[5],
            a23 = ae[9],
            a24 = ae[13]
        const a31 = ae[2],
            a32 = ae[6],
            a33 = ae[10],
            a34 = ae[14]
        const a41 = ae[3],
            a42 = ae[7],
            a43 = ae[11],
            a44 = ae[15]
        const b11 = be[0],
            b12 = be[4],
            b13 = be[8],
            b14 = be[12]
        const b21 = be[1],
            b22 = be[5],
            b23 = be[9],
            b24 = be[13]
        const b31 = be[2],
            b32 = be[6],
            b33 = be[10],
            b34 = be[14]
        const b41 = be[3],
            b42 = be[7],
            b43 = be[11],
            b44 = be[15]
        re[0] = a11 * b11 + a12 * b21 + a13 * b31 + a14 * b41
        re[4] = a11 * b12 + a12 * b22 + a13 * b32 + a14 * b42
        re[8] = a11 * b13 + a12 * b23 + a13 * b33 + a14 * b43
        re[12] = a11 * b14 + a12 * b24 + a13 * b34 + a14 * b44
        re[1] = a21 * b11 + a22 * b21 + a23 * b31 + a24 * b41
        re[5] = a21 * b12 + a22 * b22 + a23 * b32 + a24 * b42
        re[9] = a21 * b13 + a22 * b23 + a23 * b33 + a24 * b43
        re[13] = a21 * b14 + a22 * b24 + a23 * b34 + a24 * b44
        re[2] = a31 * b11 + a32 * b21 + a33 * b31 + a34 * b41
        re[6] = a31 * b12 + a32 * b22 + a33 * b32 + a34 * b42
        re[10] = a31 * b13 + a32 * b23 + a33 * b33 + a34 * b43
        re[14] = a31 * b14 + a32 * b24 + a33 * b34 + a34 * b44
        re[3] = a41 * b11 + a42 * b21 + a43 * b31 + a44 * b41
        re[7] = a41 * b12 + a42 * b22 + a43 * b32 + a44 * b42
        re[11] = a41 * b13 + a42 * b23 + a43 * b33 + a44 * b43
        re[15] = a41 * b14 + a42 * b24 + a43 * b34 + a44 * b44
        return ret
    }

    inverse(): Matrix4 {
        const ret = new Matrix4()
        const e = this.elements
        const re = ret.elements
        const n11 = e[0],
            n21 = e[1],
            n31 = e[2],
            n41 = e[3]
        const n12 = e[4],
            n22 = e[5],
            n32 = e[6],
            n42 = e[7]
        const n13 = e[8],
            n23 = e[9],
            n33 = e[10],
            n43 = e[11]
        const n14 = e[12],
            n24 = e[13],
            n34 = e[14],
            n44 = e[15]
        const t11 = n23 * n34 * n42 - n24 * n33 * n42 + n24 * n32 * n43 - n22 * n34 * n43 - n23 * n32 * n44 + n22 * n33 * n44
        const t12 = n14 * n33 * n42 - n13 * n34 * n42 - n14 * n32 * n43 + n12 * n34 * n43 + n13 * n32 * n44 - n12 * n33 * n44
        const t13 = n13 * n24 * n42 - n14 * n23 * n42 + n14 * n22 * n43 - n12 * n24 * n43 - n13 * n22 * n44 + n12 * n23 * n44
        const t14 = n14 * n23 * n32 - n13 * n24 * n32 - n14 * n22 * n33 + n12 * n24 * n33 + n13 * n22 * n34 - n12 * n23 * n34
        const det = n11 * t11 + n21 * t12 + n31 * t13 + n41 * t14
        if (det === 0) {
            console.warn("Could not compute matrix inverse!")
            return ret
        }
        const detInv = 1 / det
        re[0] = t11 * detInv
        re[1] = (n24 * n33 * n41 - n23 * n34 * n41 - n24 * n31 * n43 + n21 * n34 * n43 + n23 * n31 * n44 - n21 * n33 * n44) * detInv
        re[2] = (n22 * n34 * n41 - n24 * n32 * n41 + n24 * n31 * n42 - n21 * n34 * n42 - n22 * n31 * n44 + n21 * n32 * n44) * detInv
        re[3] = (n23 * n32 * n41 - n22 * n33 * n41 - n23 * n31 * n42 + n21 * n33 * n42 + n22 * n31 * n43 - n21 * n32 * n43) * detInv
        re[4] = t12 * detInv
        re[5] = (n13 * n34 * n41 - n14 * n33 * n41 + n14 * n31 * n43 - n11 * n34 * n43 - n13 * n31 * n44 + n11 * n33 * n44) * detInv
        re[6] = (n14 * n32 * n41 - n12 * n34 * n41 - n14 * n31 * n42 + n11 * n34 * n42 + n12 * n31 * n44 - n11 * n32 * n44) * detInv
        re[7] = (n12 * n33 * n41 - n13 * n32 * n41 + n13 * n31 * n42 - n11 * n33 * n42 - n12 * n31 * n43 + n11 * n32 * n43) * detInv
        re[8] = t13 * detInv
        re[9] = (n14 * n23 * n41 - n13 * n24 * n41 - n14 * n21 * n43 + n11 * n24 * n43 + n13 * n21 * n44 - n11 * n23 * n44) * detInv
        re[10] = (n12 * n24 * n41 - n14 * n22 * n41 + n14 * n21 * n42 - n11 * n24 * n42 - n12 * n21 * n44 + n11 * n22 * n44) * detInv
        re[11] = (n13 * n22 * n41 - n12 * n23 * n41 - n13 * n21 * n42 + n11 * n23 * n42 + n12 * n21 * n43 - n11 * n22 * n43) * detInv
        re[12] = t14 * detInv
        re[13] = (n13 * n24 * n31 - n14 * n23 * n31 + n14 * n21 * n33 - n11 * n24 * n33 - n13 * n21 * n34 + n11 * n23 * n34) * detInv
        re[14] = (n14 * n22 * n31 - n12 * n24 * n31 - n14 * n21 * n32 + n11 * n24 * n32 + n12 * n21 * n34 - n11 * n22 * n34) * detInv
        re[15] = (n12 * n23 * n31 - n13 * n22 * n31 + n13 * n21 * n32 - n11 * n23 * n32 - n12 * n21 * n33 + n11 * n22 * n33) * detInv
        return ret
    }

    transpose(): Matrix4 {
        const ret = new Matrix4()
        const e = this.elements
        const re = ret.elements
        re[0] = e[0]
        re[4] = e[1]
        re[8] = e[2]
        re[12] = e[3]
        re[1] = e[4]
        re[5] = e[5]
        re[9] = e[6]
        re[13] = e[7]
        re[2] = e[8]
        re[6] = e[9]
        re[10] = e[10]
        re[14] = e[11]
        re[3] = e[12]
        re[7] = e[13]
        re[11] = e[14]
        re[15] = e[15]
        return ret
    }

    multiplyVector(v: Vector3) {
        const e = this.elements
        const x = e[0] * v.x + e[4] * v.y + e[8] * v.z
        const y = e[1] * v.x + e[5] * v.y + e[9] * v.z
        const z = e[2] * v.x + e[6] * v.y + e[10] * v.z
        return new Vector3(x, y, z)
    }

    multiplyVectorXYZ(x: number, y: number, z: number) {
        const e = this.elements
        const rx = e[0] * x + e[4] * y + e[8] * z
        const ry = e[1] * x + e[5] * y + e[9] * z
        const rz = e[2] * x + e[6] * y + e[10] * z
        return [rx, ry, rz] as const
    }

    multiplyVectorXYZW(x: number, y: number, z: number, w: number) {
        const e = this.elements
        const rx = e[0] * x + e[4] * y + e[8] * z + e[12] * w
        const ry = e[1] * x + e[5] * y + e[9] * z + e[13] * w
        const rz = e[2] * x + e[6] * y + e[10] * z + e[14] * w
        const rw = e[3] * x + e[7] * y + e[11] * z + e[15] * w
        return [rx, ry, rz, rw] as const
    }

    getNormalXYZ() {
        const v1l = this.multiplyVectorXYZW(0, 0, 0, 1)
        const v2l = this.multiplyVectorXYZW(1, 0, 0, 1)
        const v3l = this.multiplyVectorXYZW(0, 1, 0, 1)

        const v1 = new Vector3(v1l[0], v1l[1], v1l[2])
        const v2 = new Vector3(v2l[0], v2l[1], v2l[2])
        const v3 = new Vector3(v3l[0], v3l[1], v3l[2])

        const d31 = v3.sub(v1)
        const d21 = v2.sub(v1)
        const ret = d31.cross(d21).normalized()
        return [ret.x, ret.y, ret.z] as const
    }

    getTranslation(): Vector3 {
        return new Vector3(this.elements[12], this.elements[13], this.elements[14])
    }

    setTranslationXYZ(x: number, y: number, z: number): void {
        this.elements[12] = x
        this.elements[13] = y
        this.elements[14] = z
    }

    setTranslation(value: Vector3): void {
        this.elements[12] = value.x
        this.elements[13] = value.y
        this.elements[14] = value.z
    }

    copy(): Matrix4 {
        return Matrix4.fromArray(this.toArray().slice())
    }

    static translation(x: number, y: number, z: number): Matrix4 {
        return new Matrix4([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, x, y, z, 1])
    }

    static scaling(x: number, y: number, z: number): Matrix4 {
        return new Matrix4([x, 0, 0, 0, 0, y, 0, 0, 0, 0, z, 0, 0, 0, 0, 1])
    }

    static rotationX(angleDegrees: number): Matrix4 {
        const theta = angleDegrees * (Math.PI / 180)
        const c = Math.cos(theta)
        const s = Math.sin(theta)
        return new Matrix4([1, 0, 0, 0, 0, c, s, 0, 0, -s, c, 0, 0, 0, 0, 1])
    }

    static rotationY(angleDegrees: number): Matrix4 {
        const theta = angleDegrees * (Math.PI / 180)
        const c = Math.cos(theta)
        const s = Math.sin(theta)
        return new Matrix4([c, 0, -s, 0, 0, 1, 0, 0, s, 0, c, 0, 0, 0, 0, 1])
    }

    static rotationZ(angleDegrees: number): Matrix4 {
        const theta = angleDegrees * (Math.PI / 180)
        const c = Math.cos(theta)
        const s = Math.sin(theta)
        return new Matrix4([c, s, 0, 0, -s, c, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])
    }

    static cameraLookAt(position: Vector3, target: Vector3): Matrix4 {
        let vz = position.sub(target).normalized()
        const up = new Vector3(0, 1, 0)
        let vx = up.cross(vz)
        if (vx.norm() === 0) {
            // parallel! let's force one slightly off
            vz.z += 0.0001
            vz = vz.normalized()
            vx = up.cross(vz)
        }
        vx = vx.normalized()
        const vy = vz.cross(vx)
        return new Matrix4([vx.x, vx.y, vx.z, 0, vy.x, vy.y, vy.z, 0, vz.x, vz.y, vz.z, 0, position.x, position.y, position.z, 1])
    }

    static cameraAutomaticTarget(position: Vector3, target: Vector3, aabb: AABB, focalLength: number, filmGauge: number, uiSize: [number, number]): Matrix4 {
        // assumes that camera is never tilted, and can orbit 360deg around the target
        const [fovX, fovY] = calculateFOV(filmGauge, focalLength, ...uiSize)
        const [[minX, minY, minZ], [maxX, maxY, maxZ]] = aabb
        const sizeEps = 1e-3
        if (maxX - minX < sizeEps && maxY - minY < sizeEps && maxZ - minZ < sizeEps) {
            // use original positions if bounding box is zero size
        } else {
            const [cx, cy, cz] = target.toArray()
            // calculate the swept radii for orbits around the target
            const distTo = (x: number, y: number, z: number) => {
                const dx = x - cx
                const dy = y - cy
                const dz = z - cz
                return Math.sqrt(dx * dx + dy * dy + dz * dz)
            }
            const radiusXZ = Math.max(distTo(minX, cy, minZ), distTo(maxX, cy, minZ), distTo(minX, cy, maxZ), distTo(maxX, cy, maxZ))
            const radiusXYZ = Math.max(
                distTo(minX, minY, minZ),
                distTo(maxX, minY, minZ),
                distTo(minX, maxY, minZ),
                distTo(maxX, maxY, minZ),
                distTo(minX, minY, maxZ),
                distTo(maxX, minY, maxZ),
                distTo(minX, maxY, maxZ),
                distTo(maxX, maxY, maxZ),
            )
            // calculate the distance required to fit the radii within the FOV
            const distance = Math.max(radiusXZ / Math.tan(fovX / 2), radiusXYZ / Math.tan(fovY / 2))

            if (target.y > position.y) {
                // don't allow camera to look upwards
                position = new Vector3(position.x, target.y, position.z)
            }
            position = position.sub(target).normalized().mul(distance).add(target)
        }
        return Matrix4.cameraLookAt(position, target)
    }

    //TODO: expand/clarify these utility functions
    static withNewHorizontalRotation(srcMatrix: Matrix4, newRotationDegrees: number) {
        const arr = srcMatrix.toArray()
        const theta = newRotationDegrees * ((Math.PI * 2) / 360)
        const a = Math.cos(theta)
        const b = Math.sin(theta)
        const x = arr[12]
        const y = arr[13]
        const z = arr[14]
        return new Matrix4([a, 0, -b, 0, 0, 1, 0, 0, b, 0, a, 0, x, y, z, 1])
    }

    static fromBasis(x: Vector3, y: Vector3, z: Vector3): Matrix4 {
        return new Matrix4([x.x, x.y, x.z, 0, y.x, y.y, y.z, 0, z.x, z.y, z.z, 0, 0, 0, 0, 1])
    }

    toBasis(): [Vector3, Vector3, Vector3] {
        const e = this.elements
        return [new Vector3(e[0], e[1], e[2]), new Vector3(e[4], e[5], e[6]), new Vector3(e[8], e[9], e[10])]
    }
}

export class Quaternion implements IQuaternion {
    constructor(
        public x: number = 0,
        public y: number = 0,
        public z: number = 0,
        public w: number = 1,
    ) {}

    static fromThree(q: THREE.Quaternion): Quaternion {
        return new Quaternion(q.x, q.y, q.z, q.w)
    }

    toThree() {
        return new THREE.Quaternion(this.x, this.y, this.z, this.w)
    }

    multiply(b: Quaternion): Quaternion {
        const ax = this.x,
            ay = this.y,
            az = this.z,
            aw = this.w
        const bx = b.x,
            by = b.y,
            bz = b.z,
            bw = b.w
        const x = ax * bw + aw * bx + ay * bz - az * by
        const y = ay * bw + aw * by + az * bx - ax * bz
        const z = az * bw + aw * bz + ax * by - ay * bx
        const w = aw * bw - ax * bx - ay * by - az * bz
        return new Quaternion(x, y, z, w)
    }

    sqrt(): Quaternion {
        let w = this.w
        let x = this.x
        let y = this.y
        let z = this.z
        w = Math.sqrt((w + Math.sqrt(w * w + x * x + y * y + z * z)) * 0.5)
        x /= 2 * w
        y /= 2 * w
        z /= 2 * w
        return new Quaternion(x, y, z, w)
    }

    square(): Quaternion {
        return this.multiply(this)
    }

    inverse(): Quaternion {
        return new Quaternion(-this.x, -this.y, -this.z, this.w)
    }

    toEuler(): Vector3 {
        const tmpEuler = new THREE.Euler()
        tmpEuler.setFromQuaternion(new THREE.Quaternion(this.x, this.y, this.z, this.w))
        return new Vector3(tmpEuler.x, tmpEuler.y, tmpEuler.z)
    }

    static fromEuler(x: number, y: number, z: number): Quaternion {
        return Quaternion.fromThree(new THREE.Quaternion().setFromEuler(new THREE.Euler(x, y, z)))
    }
}

export function calculateFOV(filmGauge: number, focalLength: number, width: number, height: number): [number, number] {
    if (width > height) {
        return [2 * Math.atan(filmGauge / (2 * focalLength)), 2 * Math.atan((filmGauge * (height / width)) / (2 * focalLength))]
    } else {
        return [2 * Math.atan((filmGauge * (width / height)) / (2 * focalLength)), 2 * Math.atan(filmGauge / (2 * focalLength))]
    }
}
