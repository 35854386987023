import {Component, OnInit} from "@angular/core"

@Component({
    standalone: true,
    selector: "cm-loading-spinner-icon",
    templateUrl: "./loading-spinner-icon.component.html",
    styleUrls: ["./loading-spinner-icon.component.scss"],
})
export class LoadingSpinnerIconComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
