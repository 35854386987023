import {IMaterialData, keyForMaterialData} from "@cm/lib/templates/interfaces/material-data"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {ThreeObject} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {Observable, tap} from "rxjs"
import * as THREE from "three"

export class ThreePreloadMaterial extends ThreeObject<SceneNodes.PreloadMaterial> {
    protected override renderObject = new THREE.Mesh<THREE.BufferGeometry, THREE.Material>(new THREE.PlaneGeometry(0, 0))
    wasDisposed = false

    constructor(protected override threeSceneManagerService: ThreeSceneManagerService) {
        super(threeSceneManagerService)
        this.renderObject.frustumCulled = false
    }

    override setup(sceneNode: SceneNodes.PreloadMaterial) {
        const {sceneManagerService, materialManagerService} = this.threeSceneManagerService

        const setupDeferredMaterialMaterial = (
            mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>,
            deferredMaterial: [Observable<THREE.Material>, IMaterialData],
        ) => {
            const [material, materialData] = deferredMaterial
            sceneManagerService.addTask(
                `preloadMaterial(${keyForMaterialData(materialData)})`,
                material.pipe(
                    tap((material) => {
                        if (this.wasDisposed) {
                            materialManagerService.releaseMaterial(material)
                            return
                        }

                        materialManagerService.releaseMaterial(mesh.material)
                        mesh.material = material

                        this.threeSceneManagerService.requestRedraw()
                    }),
                ),
            )
        }

        const getMaterial = (materialData: IMaterialData): [THREE.Material, [Observable<THREE.Material>, IMaterialData] | null] => {
            const [defaultMaterial, deferredMaterial] = materialManagerService.acquireMaterial(materialData, 0)
            if (!deferredMaterial) return [defaultMaterial, null]
            return [defaultMaterial, [deferredMaterial, materialData]]
        }

        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["materialData"],
                (valueA, valueB) => keyForMaterialData(valueA) === keyForMaterialData(valueB),
                ({materialData}) => {
                    const [, deferredMaterial] = getMaterial(materialData)
                    if (deferredMaterial) setupDeferredMaterialMaterial(this.renderObject, deferredMaterial)
                },
            ),
        ])
    }

    override dispose(final: boolean) {
        const {materialManagerService} = this.threeSceneManagerService

        this.renderObject.geometry.dispose()
        materialManagerService.releaseMaterial(this.renderObject.material)

        if (final) this.wasDisposed = true
    }
}
