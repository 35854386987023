// @ts-strict-ignore
import {IMaterialGraphManager, getDominantTextureRepeatSizeForMaterialGraph} from "@cm/lib/materials/material-node-graph"
import {forkJoinZeroOrMore} from "@legacy/helpers/utils"
import {Matrix4} from "@common/helpers/vector-math"
import {compressMeshGLTF} from "@editor/helpers/mesh-processing"
import {ThreeCamera} from "@editor/helpers/scene/three-proxies/camera"
import {MaterialExporter} from "@editor/helpers/scene/three-proxies/material-exporter"
import {ThreeMesh} from "@editor/helpers/scene/three-proxies/mesh"
import {ITextureManager} from "@editor/helpers/scene/three-proxies/utils"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {GLTFBuilder} from "@cm/lib/gltf/gltf-builder"
import {map, Observable, of as observableOf, ReplaySubject, shareReplay, switchMap} from "rxjs"
import * as THREE from "three"
import {IMaterialData, keyForMaterialData} from "@cm/lib/templates/interfaces/material-data"

const DEFAULT_GEOMETRY_COMPRESSION_BIT_DEPTH = 14

type UVOffset = {
    horizontal?: number
    vertical?: number
    rotation?: number
}

export function exportSceneGLB(
    workerService: WebAssemblyWorkerService,
    textureManager: ITextureManager,
    materialGraphManager: IMaterialGraphManager,
    meshes: ThreeMesh[],
    camera: ThreeCamera,
) {
    // const beginTime = performance.now();
    const builder = new GLTFBuilder({
        generator: "colormass-exportScene",
    })

    const materialExporter = new MaterialExporter(textureManager, materialGraphManager)

    type UVBounds = [number, number, number, number] // bounds are corner coordinates, not [x,y,w,h]!

    builder.useExtension("KHR_draco_mesh_compression")

    const makeIndicesAccessor = (positionAttr: THREE.BufferAttribute | THREE.InterleavedBufferAttribute) => {
        const count = positionAttr.count
        const array = new Uint32Array(count)
        for (let idx = 0; idx < array.length; idx++) {
            array[idx] = idx
        }
        // const buffer = builder.addBuffer({
        //     byteLength: array.length * 4
        // }, array);
        // const view = builder.addBufferView({
        //     buffer: buffer.id,
        //     byteLength: array.length * 4,
        // });
        const accessor = builder.addAccessor({
            // bufferView: view.id,
            type: "SCALAR",
            componentType: GLTFBuilder.ComponentType.UNSIGNED_INT,
            count,
        })
        return accessor
    }
    const transformUVs = (array: Float32Array, bounds: number[], offset?: UVOffset) => {
        // This will remap UVs inside 'bounds' to normalized (0..1)
        const elemSz = 2
        const count = array.length / elemSz
        const newArray = new Float32Array(array.length)
        let tmpArray: Float32Array
        const ox = offset?.horizontal ?? 0
        const oy = offset?.vertical ?? 0
        const rz = offset?.rotation ?? 0
        const sx = 1 / (bounds[2] - bounds[0])
        const sy = 1 / (bounds[3] - bounds[1])
        const matrix = new THREE.Matrix3()
        matrix.setUvTransform(ox, oy, 1, 1, rz * (Math.PI / 180), 0, 0)
        const vec = new THREE.Vector2()
        let idx = 0
        for (let elemIdx = 0; elemIdx < count; elemIdx++) {
            vec.x = array[idx + 0]
            vec.y = array[idx + 1]
            vec.applyMatrix3(matrix)
            newArray[idx++] = (vec.x - bounds[0]) * sx
            newArray[idx++] = (vec.y - bounds[1]) * sy
        }
        return newArray
    }
    const fixNormals = (array: Float32Array) => {
        const elemSz = 3
        const count = array.length / elemSz
        const newArray = new Float32Array(array.length)
        let idx = 0
        for (let n = 0; n < count; n++) {
            let x = array[idx + 0]
            let y = array[idx + 1]
            let z = array[idx + 2]
            let m = x * x + y * y + z * z
            if (m < 1e-3) {
                x = 0
                y = 0
                z = 1
            } else if (m < 0.9999) {
                m = 1 / Math.sqrt(m)
                x *= m
                y *= m
                z *= m
            }
            newArray[idx + 0] = x
            newArray[idx + 1] = y
            newArray[idx + 2] = z
            idx += 3
        }
        return newArray
    }
    const makeAccessor = (array: Float32Array, elemSz: number, count?: number) => {
        if (count === undefined) {
            count = array.length / elemSz
        }
        const rangeMin: number[] = []
        const rangeMax: number[] = []
        for (let elemOfs = 0; elemOfs < elemSz; elemOfs++) {
            let idx = elemOfs
            let min = array[idx]
            let max = array[idx]
            for (let n = 0; n < count; n++) {
                const val = array[idx]
                if (val < min) min = val
                if (val > max) max = val
                idx += elemSz
            }
            rangeMin.push(min)
            rangeMax.push(max)
        }
        // const buffer = builder.addBuffer({
        //     byteLength: array.length * 4
        // }, array as Float32Array);
        // const view = builder.addBufferView({
        //     buffer: buffer.id,
        //     byteLength: array.length * 4,
        // });
        const accessor = builder.addAccessor({
            //bufferView: view?.id,
            type: undefined,
            componentType: GLTFBuilder.ComponentType.FLOAT,
            count,
            min: rangeMin,
            max: rangeMax,
        })
        switch (elemSz) {
            case 1:
                accessor.data.type = "SCALAR"
                break
            case 2:
                accessor.data.type = "VEC2"
                break
            case 3:
                accessor.data.type = "VEC3"
                break
            case 4:
                accessor.data.type = "VEC4"
                break
        }
        return accessor
    }
    const gltfScene = builder.addScene({
        nodes: [],
    })
    const pending: Observable<void>[] = []

    const getUVBounds = (uvAttr: THREE.BufferAttribute | THREE.InterleavedBufferAttribute, offset?: UVOffset) => {
        const uvArray = uvAttr.array
        const numUVs = uvArray.length / 2
        const uvBounds: UVBounds = [uvArray[0], uvArray[1], uvArray[0], uvArray[1]]
        const ox = offset?.horizontal ?? 0
        const oy = offset?.vertical ?? 0
        const rz = offset?.rotation ?? 0
        const matrix = new THREE.Matrix3()
        matrix.setUvTransform(ox, oy, 1, 1, rz * (Math.PI / 180), 0, 0)
        const vec = new THREE.Vector2()
        for (let idx = 0; idx < uvArray.length; ) {
            vec.x = uvArray[idx++]
            vec.y = uvArray[idx++]
            vec.applyMatrix3(matrix)
            const u = vec.x
            const v = vec.y
            if (u < uvBounds[0]) uvBounds[0] = u
            if (v < uvBounds[1]) uvBounds[1] = v
            if (u > uvBounds[2]) uvBounds[2] = u
            if (v > uvBounds[3]) uvBounds[3] = v
        }
        return uvBounds
    }

    const identityMatrix = Matrix4.identity()
    const scaleMatrix = Matrix4.scaling(0.01, 0.01, 0.01) // convert from centimeters to meters
    const exportMatrix = (matrix: THREE.Matrix4) => {
        const scaledMatrix = scaleMatrix.multiply(Matrix4.fromThreeMatrix(matrix))
        // GLTF validation requires that the node matrix is omitted if it is equal to identity
        if (scaledMatrix.equals(identityMatrix)) {
            return undefined
        } else {
            return scaledMatrix.toArray()
        }
    }

    const exportMaterial = (materialData: IMaterialData, uvBounds: UVBounds) => {
        const gltfMaterial = builder.addMaterial({
            doubleSided: materialData?.side == "double",
        })

        const exportTexture = (data: Uint8Array, mimeType: "image/jpeg" | "image/png") => {
            const gltfImageBuffer = builder.addBuffer(
                {
                    byteLength: data.byteLength,
                },
                data,
            )
            const gltfImageBufferView = builder.addBufferView({
                buffer: gltfImageBuffer.id,
                byteLength: data.byteLength,
            })
            const gltfImage = builder.addImage({
                bufferView: gltfImageBufferView.id,
                mimeType,
            })
            // const gltfSampler = builder.addSampler({
            //     minFilter: GLTFBuilder.SamplerFilter.LINEAR_MIPMAP_LINEAR,
            //     magFilter: GLTFBuilder.SamplerFilter.LINEAR,
            //     wrapS: GLTFBuilder.SamplerWrap.REPEAT,
            //     wrapT: GLTFBuilder.SamplerWrap.REPEAT
            // });
            const gltfTexture = builder.addTexture({
                source: gltfImage.id,
                //sampler: gltfSampler.id,
            })
            return gltfTexture
        }

        if (materialData) {
            pending.push(
                materialExporter.renderPBRMaps(materialData, uvBounds, 1024).pipe(
                    map(({mapData, alphaType, alphaTest}) => {
                        const diffuseTexture = exportTexture(...mapData.diffuse)
                        const normalTexture = exportTexture(...mapData.normal)
                        const metallicRoughnessTexture = exportTexture(...mapData.metallicRoughness)
                        gltfMaterial.data.pbrMetallicRoughness = {
                            baseColorTexture: {index: diffuseTexture.id},
                            metallicRoughnessTexture: {index: metallicRoughnessTexture.id},
                        }
                        gltfMaterial.data.normalTexture = {index: normalTexture.id}
                        if (alphaType === "blend") {
                            gltfMaterial.data.alphaMode = "BLEND"
                            gltfMaterial.data.alphaCutoff = alphaTest
                        } else if (alphaType === "mask") {
                            gltfMaterial.data.alphaMode = "MASK"
                            gltfMaterial.data.alphaCutoff = alphaTest
                        }
                        if (mapData.emission) {
                            const emissiveTexture = exportTexture(...mapData.emission)
                            gltfMaterial.data.emissiveTexture = {index: emissiveTexture.id}
                            gltfMaterial.data.emissiveFactor = [1, 1, 1]
                        }
                    }),
                ),
            )
        } else {
            // default material
            console.warn("WARNING! Exporting default material in glTF. This will cause issues with USDZ!")
            gltfMaterial.data.pbrMetallicRoughness = {
                baseColorFactor: [0.9, 0.9, 0.9, 1.0],
                metallicFactor: 0.0,
                roughnessFactor: 0.8,
            }
        }
        return gltfMaterial
    }

    class KeyedReducer<K, T1, T2> {
        private map = new Map<K, [T1[], ReplaySubject<T2>, Observable<T2>]>()
        submitAndWaitForReduced(k: K, x: T1): Observable<T2> {
            let entry = this.map.get(k)
            if (!entry) {
                const subj = new ReplaySubject<T2>(1)
                entry = [[], subj, subj.pipe(shareReplay())]
                this.map.set(k, entry)
            }
            entry[0].push(x)
            return entry[2]
        }
        reduceAll(fn: (xs: T1[]) => Observable<T2>) {
            for (const [_k, [xs, s, _o]] of this.map) {
                fn(xs).subscribe((r) => {
                    s.next(r)
                    s.complete()
                })
            }
        }
    }

    const materialMerge = new KeyedReducer<string, [IMaterialData, UVBounds], [number, UVBounds]>()

    // const foundCamera = false
    for (const mesh of meshes) {
        for (const [geometry, _material, materialData] of mesh.gatherGeometryAndMaterialData()) {
            const uvBounds = getUVBounds(geometry.attributes.uv)
            const reduceKey = materialData ? keyForMaterialData(materialData) : null
            pending.push(
                materialMerge.submitAndWaitForReduced(reduceKey, [materialData, uvBounds]).pipe(
                    switchMap(([gltfMaterialID, uvBounds]) => {
                        const positionArray = geometry.attributes.position.array as Float32Array
                        const normalArray = fixNormals(geometry.attributes.normal.array as Float32Array)
                        const uvArray = transformUVs(geometry.attributes.uv.array as Float32Array, uvBounds)
                        const geometryCompressionBitDepth = DEFAULT_GEOMETRY_COMPRESSION_BIT_DEPTH

                        return compressMeshGLTF(workerService, positionArray, normalArray, uvArray, geometryCompressionBitDepth).pipe(
                            map((dracoFile) => {
                                const gltfDracoBuf = builder.addBuffer(
                                    {
                                        byteLength: dracoFile.byteLength,
                                    },
                                    dracoFile,
                                )
                                const gltfDracoView = builder.addBufferView({
                                    buffer: gltfDracoBuf.id,
                                    byteLength: dracoFile.byteLength,
                                })
                                const gltfMesh = builder.addMesh({
                                    primitives: [
                                        {
                                            attributes: {
                                                POSITION: makeAccessor(positionArray, 3).id,
                                                NORMAL: makeAccessor(normalArray, 3).id,
                                                TEXCOORD_0: gltfMaterialID !== undefined ? makeAccessor(uvArray, 2).id : undefined,
                                            },
                                            indices: makeIndicesAccessor(geometry.attributes.position).id,
                                            material: gltfMaterialID,
                                            extensions: {
                                                KHR_draco_mesh_compression: {
                                                    bufferView: gltfDracoView.id,
                                                    attributes: {
                                                        POSITION: 0, //TODO: get these attribute IDs from dracoWriter.cpp!
                                                        NORMAL: 1,
                                                        TEXCOORD_0: gltfMaterialID !== undefined ? 2 : undefined,
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                })
                                const gltfNode = builder.addNode({
                                    matrix: exportMatrix(mesh.threeObject.matrixWorld),
                                    mesh: gltfMesh.id,
                                })
                                gltfScene.data.nodes.push(gltfNode.id)
                            }),
                        )
                    }),
                ),
            )
        }
    }

    if (camera) {
        const gltfCamera = builder.addCamera({
            type: "perspective",
            perspective: {
                aspectRatio: camera.threeCamera.aspect,
                yfov: camera.threeCamera.fov * (Math.PI / 180),
                zfar: camera.threeCamera.far,
                znear: camera.threeCamera.near,
            },
        })
        const gltfNode = builder.addNode({
            matrix: exportMatrix(camera.threeCamera.matrixWorld),
            camera: gltfCamera.id,
        })
        gltfScene.data.nodes.push(gltfNode.id)
    }

    materialMerge.reduceAll((allEntries) => {
        let materialData: IMaterialData = undefined
        const uvBounds: UVBounds = [0, 0, 0, 0]
        if (allEntries.length > 0) {
            materialData = allEntries[0][0]
            uvBounds[0] = allEntries[0][1][0]
            uvBounds[1] = allEntries[0][1][1]
            uvBounds[2] = allEntries[0][1][2]
            uvBounds[3] = allEntries[0][1][3]
            for (const entry of allEntries) {
                const entryUVBounds = entry[1]
                if (entryUVBounds[0] < uvBounds[0]) uvBounds[0] = entryUVBounds[0]
                if (entryUVBounds[1] < uvBounds[1]) uvBounds[1] = entryUVBounds[1]
                if (entryUVBounds[2] > uvBounds[2]) uvBounds[2] = entryUVBounds[2]
                if (entryUVBounds[3] > uvBounds[3]) uvBounds[3] = entryUVBounds[3]
            }
        }
        const mapScale = materialData?.materialGraph ? getDominantTextureRepeatSizeForMaterialGraph(materialData.materialGraph) : undefined
        if (mapScale) {
            // If UV bounds size exceeds the map scale, it can be safely cropped to the map scale (repeats will be handled by glTF texture wrapping).
            // (UV origin does not matter, because the same offset will be used for both transformUVs and exportMaterial)
            if (uvBounds[2] - uvBounds[0] > mapScale[0]) {
                uvBounds[2] = uvBounds[0] + mapScale[0]
            }
            if (uvBounds[3] - uvBounds[1] > mapScale[1]) {
                uvBounds[3] = uvBounds[1] + mapScale[1]
            }
        }
        if (uvBounds[0] === uvBounds[2] || uvBounds[1] === uvBounds[3]) {
            console.warn(`Invalid uvBounds: ${JSON.stringify(uvBounds)}`)
            uvBounds[2] += 1
            uvBounds[3] += 1
        }
        return observableOf([materialData && exportMaterial(materialData, uvBounds).id, uvBounds])
    })

    return forkJoinZeroOrMore(pending).pipe(
        map((_) => {
            const glb = builder.generateGLB()
            materialExporter.dispose()
            // console.log(`exportScene took ${Math.round(performance.now() - beginTime)} ms`);
            return glb
        }),
    )
}
