import {Component, computed, forwardRef, input} from "@angular/core"
import {MaterialAssignment, MaterialAssignments} from "@cm/lib/templates/nodes/material-assignment"
import {BaseInspectorComponent} from "@template-editor/components/inspectors/base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {MaterialAssignmentInspectorComponent} from "@template-editor/components/inspectors/material-assignment-inspector/material-assignment-inspector.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {Mesh} from "@cm/lib/templates/node-types"

@Component({
    selector: "cm-material-assignments-inspector",
    standalone: true,
    templateUrl: "./material-assignments-inspector.component.html",
    styleUrl: "./material-assignments-inspector.component.scss",
    imports: [InspectorSectionComponent, MaterialAssignmentInspectorComponent, forwardRef(() => ValueSlotComponent)],
})
export class MaterialAssignmentsInspectorComponent extends BaseInspectorComponent<MaterialAssignments> {
    mesh = input.required<Mesh>()
    materialSlotNames = computed(() => this.mesh().parameters.materialSlotNames)
    materialAssignments = computed(() => Object.entries(this.parameters()))
    getSlotName(slot: string) {
        return this.materialSlotNames()[slot] ?? `Slot ${slot}`
    }

    onMouseEnter(slot: string) {
        const mesh = this.mesh()
        this.sceneManagerService.hoverNode({templateNode: mesh, part: `group${Number(slot)}`})
    }

    onMouseLeave() {
        this.sceneManagerService.hoverNode(undefined)
    }

    onRequestUpdate(slot: string, materialAssignment: unknown) {
        if (materialAssignment instanceof MaterialAssignment) {
            this.sceneManagerService.modifyTemplateGraph(() => this.node().updateParameters({[slot]: materialAssignment}))
        } else if (materialAssignment === null) {
            this.sceneManagerService.modifyTemplateGraph(() => this.node().updateParameters({[slot]: null}))
        }
    }
}
