// @ts-strict-ignore
import {Component, Input} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {CanvasBaseComponent} from "@common/components/canvas/canvas-base/canvas-base.component"
import {ActionBarComponent} from "@common/components/menu/action-menu/action-bar/action-bar.component"
import {ActionItemComponent} from "@common/components/menu/action-menu/action-item/action-item.component"
import {ActionPanelRowComponent} from "@common/components/menu/action-menu/action-panel-row/action-panel-row.component"
import {ActionPanelComponent} from "@common/components/menu/action-menu/action-panel/action-panel.component"
import {FilesizePipe} from "@common/pipes/filesize/filesize.pipe"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {AuthService} from "@common/services/auth/auth.service"
import {DataObject} from "@legacy/api-model/data-object"
import {FilesService} from "@common/services/files/files.service"

@Component({
    selector: "cm-render-output-viewer-controls",
    templateUrl: "./render-output-viewer-controls.component.html",
    styleUrls: ["./render-output-viewer-controls.component.scss"],
    standalone: true,
    imports: [ActionPanelRowComponent, ActionPanelComponent, MatTooltipModule, FilesizePipe, ActionBarComponent, ActionItemComponent, MatMenuModule],
})
export class RenderOutputViewerControlsComponent {
    @Input() dataObject: DataObject
    @Input() imageData: ImageData
    @Input() pictureCanvas: CanvasBaseComponent

    isMobileDevice = isMobileDevice

    constructor(public auth: AuthService) {}

    getZoomLevel(): number {
        return Math.round((this.pictureCanvas.navigation?.getZoomLevel() ?? 1) * 100)
    }

    getFileTypeLabel(dataObject: DataObject): string {
        switch (dataObject.contentType) {
            case "image/x-exr":
                return "EXR"
            case "image/tiff":
                return "TIFF"
            case "image/jpeg":
                return "JPEG"
            default:
                return dataObject.contentType
        }
    }

    downloadOriginal(dataObject: DataObject): void {
        FilesService.downloadFile(dataObject.originalFileName, dataObject.getOriginalFileUrl())
    }

    downloadJpeg(dataObject: DataObject): void {
        FilesService.downloadFile(dataObject.originalFileName, dataObject.getJpegUrl())
    }

    downloadFromImageData(): void {
        const canvas = document.createElement("canvas")
        canvas.width = this.imageData.width
        canvas.height = this.imageData.height
        canvas.getContext("2d").putImageData(this.imageData, 0, 0)
        const dataUrl = canvas.toDataURL("image/png")
        FilesService.downloadFile("snapshot.png", dataUrl.replace("image/png", "image/octet-stream"))
    }
}
