import {Component, OnInit} from "@angular/core"

@Component({
    selector: "cm-filter-list",
    templateUrl: "./filter-list.component.html",
    styleUrls: ["./filter-list.component.scss"],
    standalone: true,
})
export class FilterListComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
