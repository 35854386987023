// @ts-strict-ignore
import {DecimalPipe} from "@angular/common"
import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild} from "@angular/core"
import {fromEvent, Subject, Subscription, takeUntil} from "rxjs"
import {ScalarSocketRange} from "@node-editor/models"

@Component({
    selector: "cm-scalar-input-socket",
    templateUrl: "./scalar-input-socket.component.html",
    styleUrls: ["./scalar-input-socket.component.scss"],
    standalone: true,
    imports: [DecimalPipe],
})
export class ScalarInputSocketComponent implements AfterViewInit, OnDestroy {
    @ViewChild("bar", {static: false}) bar: ElementRef<HTMLDivElement>
    @ViewChild("barFill", {static: false}) barFill: ElementRef<HTMLDivElement>
    @ViewChild("valueInput", {static: false}) valueInput: ElementRef<HTMLInputElement>
    @Input() label: string
    @Input() type: string
    @Input() disableEditingIfConnected: boolean = true

    _connected: {value: boolean} = {value: false}
    get connected(): {value: boolean} {
        return this._connected
    }

    @Input() set connected(value: {value: boolean}) {
        this._connected = value
    }

    private _range: ScalarSocketRange
    private _rangeMaxSet: boolean
    private _rangeMinSet: boolean
    _rangeSet: boolean

    @Input() set range(newRange: ScalarSocketRange) {
        this._range = newRange
        this._rangeMaxSet = newRange && "max" in newRange
        this._rangeMinSet = newRange && "min" in newRange
        this._rangeSet = this._rangeMaxSet && this._rangeMinSet
    }

    private defaultValue = 0

    private _value: number
    get value(): number {
        return this._value
    }

    @Input() set value(value: number) {
        this._value = value
        this.updateBar()
    }

    @Output() valueChange = new EventEmitter<number>()

    @Input() hideBarFill = false
    barFillWidth = 25
    barWidth: number

    startValue: number = this.value
    startX: number
    dragActive = false

    editMode: "bar" | "input" = "bar"
    mouseMoveSubscription: Subscription = null
    readonly destroySubject = new Subject<void>()

    @HostListener("document:mouseup", ["$event"])
    onMouseUp(event: MouseEvent) {
        this.dragEnd(event)
        if (this.editingEnabled && event.target !== this.valueInput.nativeElement) this.activateBarEditMode()
    }

    constructor() {}

    get editingEnabled(): boolean {
        return !this.disableEditingIfConnected || !this.connected.value
    }

    ngAfterViewInit(): void {
        fromEvent<MouseEvent>(this.bar.nativeElement, "mousedown")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => this.dragStart(event))
        fromEvent<MouseEvent>(this.bar.nativeElement, "click")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => this.activateInputEditMode(event))
        // Prevent dragging of the node when trying to select the input's value.
        fromEvent<MouseEvent>(this.valueInput.nativeElement, "mousedown")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => event.stopPropagation())
        fromEvent<KeyboardEvent>(this.valueInput.nativeElement, "keydown")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => this.commitInputValue(event))
    }

    onValueChange(value: number) {
        if (value == null) value = this.defaultValue
        else if (this._rangeMinSet && value < this._range.min) value = this._range.min
        else if (this._rangeMaxSet && this._range.max < value) value = this._range.max
        else value = Math.round(value * 1000) / 1000
        this.value = value
    }

    dragStart(event: MouseEvent): void {
        this.mouseMoveSubscription = fromEvent<MouseEvent>(document.body, "mousemove")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                if (!this.editingEnabled) return
                this.dragValue(event)
            })
        this.startX = event.clientX
        this.startValue = this.value
        this.hideCursor()
        this.dragActive = true
        const barBoundingRect: DOMRect = this.bar.nativeElement.getBoundingClientRect()
        this.barWidth = barBoundingRect.width
        event.stopPropagation()
    }

    dragValue(event: MouseEvent): void {
        if (!this.dragActive) return
        event.stopPropagation()
        this.onValueChange(this.startValue + ((event.clientX - this.startX) / this.barWidth) * (this._rangeMaxSet ? this._range.max : 1.0))
    }

    updateBar(): void {
        this._rangeSet ? (this.barFillWidth = ((this.value - this._range.min) / (this._range.max - this._range.min)) * 100) : 0
    }

    dragEnd(_event: MouseEvent): void {
        if (this.dragActive) {
            this.mouseMoveSubscription?.unsubscribe()
            this.showCursor()
            this.dragActive = false
            this.valueChange.emit(this.value)
        }
    }

    hideCursor(): void {
        document.body.style.cursor = "none"
    }

    showCursor(): void {
        document.body.style.cursor = "auto"
    }

    activateInputEditMode(event: MouseEvent): void {
        if (event.clientX !== this.startX) return
        this.editMode = "input"
        setTimeout(() => {
            this.valueInput.nativeElement.focus()
            this.valueInput.nativeElement.select()
        }, 0)
    }

    commitInputValue(event: KeyboardEvent): void {
        if (event.code !== "Enter" && event.code !== "NumpadEnter") return
        this.valueInput.nativeElement.blur()
        this.activateBarEditMode()
    }

    activateBarEditMode(): void {
        this.editMode = "bar"
    }

    ngOnDestroy(): void {
        this.destroySubject.next()
        this.destroySubject.complete()
    }
}
