// @ts-strict-ignore
import {forkJoinZeroOrMore, UtilsService} from "@legacy/helpers/utils"
import {from as observableFrom, mapTo, mergeMap, Observable, tap, throwError} from "rxjs"
import * as JSZip from "jszip"
import {CmmMeta} from "@common/models/cmm/cmm-meta"
import {CmmMesh} from "@common/models/cmm/cmm-mesh"

export {CmmMeta}

function unzipModelJson(zip: JSZip): Observable<string> {
    for (const filename in zip.files) {
        const zipObject: JSZip.JSZipObject = zip.files[filename]
        if (UtilsService.getExtension(zipObject.name) === "json") {
            return observableFrom(zipObject.async("text"))
        }
    }
    return throwError(() => new Error("There is no json file in the ZIP."))
}

export class CmmModel {
    meshes: CmmMesh[] = []
    meta: CmmMeta

    private CmmModel() {}

    static fromCmmFile(cmmFile: File, requirePLY = true): Observable<CmmModel> {
        return observableFrom(JSZip.loadAsync(cmmFile)).pipe(
            mergeMap((zip: JSZip) =>
                unzipModelJson(zip).pipe(
                    mergeMap((jsonString: string) => {
                        const modelJson = JSON.parse(jsonString)
                        const pending: Observable<ArrayBuffer>[] = []
                        const cmmModel: CmmModel = new CmmModel()
                        cmmModel.meta = CmmMeta.fromJson(modelJson.meta)
                        for (const meshJson of modelJson.meshes) {
                            const mesh: CmmMesh = CmmMesh.fromJson(meshJson, modelJson)
                            cmmModel.meshes.push(mesh)
                            mesh.originalFileName = `${cmmFile.name}-${mesh.id}`

                            const drcFile = zip.file(`${mesh.id}.drc`)
                            if (drcFile) {
                                pending.push(
                                    observableFrom(drcFile.async("arraybuffer")).pipe(
                                        tap((buffer: ArrayBuffer) => {
                                            mesh.data.set("drc", buffer)
                                        }),
                                    ),
                                )
                            } else {
                                return throwError(() => `${cmmFile.name} does not contain a .drc file for mesh ${mesh.id}!`)
                            }

                            const drcProxyFile = zip.file(`${mesh.id}_proxy.drc`)
                            if (drcProxyFile) {
                                pending.push(
                                    observableFrom(drcProxyFile.async("arraybuffer")).pipe(
                                        tap((buffer: ArrayBuffer) => {
                                            mesh.data.set("drc_proxy", buffer)
                                        }),
                                    ),
                                )
                            } else {
                                // CMM files may omit proxies.
                            }

                            if (requirePLY) {
                                const plyFile = zip.file(`${mesh.id}.ply`)
                                if (plyFile) {
                                    pending.push(
                                        observableFrom(plyFile.async("arraybuffer")).pipe(
                                            tap((buffer: ArrayBuffer) => {
                                                mesh.data.set("ply", buffer)
                                            }),
                                        ),
                                    )
                                } else {
                                    return throwError(() => `${cmmFile.name} does not contain a .ply file for mesh ${mesh.id}!`)
                                }
                            }
                        }
                        return forkJoinZeroOrMore(pending).pipe(mapTo(cmmModel))
                    }),
                ),
            ),
        )
    }
}
