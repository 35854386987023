import {Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild} from "@angular/core"

@Component({
    selector: "cm-native-checkbox",
    templateUrl: "./native-checkbox.component.html",
    styleUrls: ["./native-checkbox.component.scss"],
    standalone: true,
})
export class NativeCheckboxComponent {
    @Input({required: true}) value!: string
    @Input() checked?: boolean
    @Input() connectedSelectionList?: Set<string>
    @ViewChild("inputelement", {static: true}) checkbox!: ElementRef<HTMLInputElement>
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.connectedSelectionList) {
            this.updateCheckbox()
        }
    }

    updateValue(checked: boolean) {
        if (this.connectedSelectionList) {
            checked ? this.connectedSelectionList.add(this.value) : this.connectedSelectionList.delete(this.value)
        }
        this.valueChange.emit(checked)
    }

    updateCheckbox() {
        if (this.checkbox) {
            this.checkbox.nativeElement.checked = this.connectedSelectionList?.has(this.value) ?? false
        }
    }
}
