// @ts-strict-ignore
import {Observable, of as observableOf} from "rxjs"
import {map} from "rxjs"
import {TemplateRevision} from "@legacy/api-model/template-revision"
import {Template} from "@legacy/api-model/template"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {TemplateGraphMigrationTemplateRevisionFragment} from "@api"

export function getGraphJSONForTemplateRevision(templateRevision: TemplateGraphMigrationTemplateRevisionFragment) {
    if (templateRevision.graph) {
        // console.log(`Template revision ${templateRevision.id} has a JSON graph`);
        templateRevision.graph = migrateTemplateGraph(templateRevision.graph)
        return templateRevision.graph
    } else {
        console.warn(`Template revision ${templateRevision.id} has no graph. Creating default structure`)
        return {
            id: `templateRevision_${templateRevision.id}`,
            name: templateRevision.template.name,
            type: "templateGraph",
            schema: Nodes.currentTemplateGraphSchema,
            nodes: [],
        }
    }
}

function upgradeTemplateGraphToNextSchema(graphJson: any): any {
    switch (graphJson.schema) {
        // case "templateGraph_dev6": {
        //     // rename "selector" to "switch"
        //     const graph = jsonToGraph(graphJson);
        //     const nodeMap = getAllGraphNodes(graph);
        //     for (const [id,node] of nodeMap) {
        //         switch (node.type) {
        //         case "selector":
        //             node.type = "switch";
        //             break;
        //         }
        //     }
        //     graph.schema = "templateGraph_dev7";
        //     return graphToJson(graph);
        // }
        // case "templateGraph_dev7": {
        //     // rename "selector" to "switch"
        //     const graph = jsonToGraph(graphJson);
        //     const nodeMap = getAllGraphNodes(graph);
        //     for (const [id,node] of nodeMap) {
        //         switch (node.type) {
        //         case "areaLight":
        //             node.target = [0, 0, 0];
        //             break;
        //         }
        //     }
        //     graph.schema = "templateGraph_dev8";
        //     return graphToJson(graph);
        // }
        // case "templateGraph_dev8": {
        //     // add "active" property to groups
        //     const graph = jsonToGraph(graphJson);
        //     const nodeMap = getAllGraphNodes(graph);
        //     for (const [id,node] of nodeMap) {
        //         switch (node.type) {
        //         case "group":
        //             if (node.name === "Template Parts") {
        //                 node.active = false;
        //             } else {
        //                 node.active = true;
        //             }
        //             break;
        //         }
        //     }
        //     graph.schema = "templateGraph_dev9";
        //     return graphToJson(graph);
        // }
        // case "templateGraph_dev9": {
        //     // add "active" property to groups
        //     const graph = jsonToGraph(graphJson);
        //     const nodeMap = getAllGraphNodes(graph);
        //     for (const [id,node] of nodeMap) {
        //         if ("materialAssignments" in node) {
        //             const oldAssignments = node.materialAssignments;
        //             const newAssignments: any = {};
        //             for (const key in oldAssignments) {
        //                 newAssignments[key] = { node: oldAssignments[key] };
        //             }
        //             node.materialAssignments = newAssignments;
        //         }
        //     }
        //     graph.schema = "templateGraph_dev10";
        //     return graphToJson(graph);
        // }
        default: {
            return null
        }
    }
}

export function migrateTemplateGraph(graphJson: any) {
    while (true) {
        const prevSchema = graphJson.schema
        const upgraded = upgradeTemplateGraphToNextSchema(graphJson)
        if (!upgraded) {
            return graphJson
        } else {
            console.log(`Upgraded graph schema from ${prevSchema} to ${upgraded.schema}`)
            graphJson = upgraded
        }
    }
}
