import {Pipe, PipeTransform} from "@angular/core"

@Pipe({
    name: "memoize",
    pure: true,
    standalone: true,
})
export class MemoizePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform<T extends (...args: any[]) => any>(fn: T, this_?: any, ...args: Parameters<T>): ReturnType<T> {
        return fn.call(this_, ...args)
    }
}
