import {Component, inject, OnInit} from "@angular/core"
import {MatDialogContent} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {RouterLink, RouterOutlet} from "@angular/router"
import {AssetListItemFragment, AssetOrderByCriteria, AssetState, ContentTypeModel, MutationUpdateAssetInput, NextActor, SortOrder} from "@api"
import {CardErrorComponent} from "@common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "@common/components/cards/card-placeholder/card-placeholder.component"
import {CommentBoxesComponent} from "@common/components/comment-boxes/comment-boxes.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {ItemListComponent} from "@common/components/item/item-list/item-list.component"
import {BatchUpdateProperty, ListInfoComponent} from "@common/components/item/list-info/list-info.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {Labels, StateLabel} from "@labels"
import {Settings} from "@common/models/settings/settings"
import {AssetBoxesComponent} from "@platform/components/assets/asset-boxes/asset-boxes.component"
import {SelectModelComponent} from "@platform/components/models/select-model/select-model.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {TagLabelsComponent} from "@platform/components/tags/tag-labels/tag-labels.component"
import {AssignUserDialogComponent} from "@platform/components/users/assign-user-dialog/assign-user-dialog.component"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {DialogSize} from "@common/models/dialogs"
import {TriggeredDialogComponent} from "@common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {SelectMaterialComponent} from "@platform/components/materials/select-material/select-material.component"
import {DialogService} from "@common/services/dialog/dialog.service"

@Component({
    selector: "cm-assets-grid",
    standalone: true,
    imports: [
        AssignUserComponent,
        CardErrorComponent,
        CardPlaceholderComponent,
        CommentBoxesComponent,
        EntityCardComponent,
        InfiniteListComponent,
        ListInfoComponent,
        StateLabelComponent,
        AssignUserDialogComponent,
        MatDialogContent,
        MatMenuModule,
        RouterOutlet,
        SelectModelComponent,
        RouterLink,
        TagLabelsComponent,
        AssetBoxesComponent,
        TriggeredDialogComponent,
        SelectMaterialComponent,
    ],
    templateUrl: "./assets-grid.component.html",
    styleUrl: "./assets-grid.component.scss",
})
export class AssetsGridComponent
    extends ItemListComponent<AssetListItemFragment, MutationUpdateAssetInput, {modelId: string; organizationId?: string}>
    implements OnInit
{
    dialogService = inject(DialogService)

    public stateLabels: StateLabel<AssetState>[] = Array.from(Labels.AssetState.values())

    // OVERLOADS

    protected override _contentTypeModel = ContentTypeModel.Asset
    protected override _batchUpdate = (property: BatchUpdateProperty, value: string | boolean) =>
        this.sdk.gql
            .batchUpdateAssets({
                filter: this.filters.assetFilter(),
                [property]: value,
            })
            .then(({batchUpdateAssets: count}) => count)

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.sdk.gql
            .getAssets({
                take: take,
                skip: skip,
                filter: this.filters.assetFilter(),
                orderBy: [{key: AssetOrderByCriteria.Priority, direction: SortOrder.Asc}],
            })
            .then(({assets, assetsCount}) => ({
                items: assets,
                totalCount: assetsCount,
            }))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .getAssets({
                take: 1,
                filter: {...this.filters.assetFilter(), id: id ? {equals: id} : undefined, legacyId: legacyId ? {equals: legacyId} : undefined},
            })
            .then(({assets}) => assets?.[0] || undefined)

    protected override _updateItem = (data: MutationUpdateAssetInput) =>
        this.sdk.gql
            .updateAsset({
                input: data,
            })
            .then(({updateAsset}) => updateAsset)

    protected override _createItem = async (data: {modelId: string; organizationId?: string}) => {
        const organizationId = data.organizationId ?? this.organizations.$current()?.id
        if (!organizationId) {
            throw new Error("Organization is not set")
        }
        const {createAsset} = await this.sdk.gql.createAsset({
            input: {
                modelId: data.modelId,
                comment: Settings.defaultCommentField,
                nextActor: this.userIsStaff ? NextActor.Team1 : NextActor.Customer,
                organizationId,
                state: AssetState.InfoReview,
            },
        })
        void this.router.navigate(["/assets", createAsset.id])
        return createAsset
    }

    openSelectMaterialDialog() {
        this.dialogService.selectInDialog(SelectModelComponent, {
            onSelect: (item) => this.createItem({modelId: item.id, organizationId: item.organization?.id}),
        })
    }

    protected readonly DialogSize = DialogSize
}
