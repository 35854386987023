import {VadoCatalog} from "@src/pricing/catalogs/vado/catalog"
import {ConditionalAmountGroupNode} from "@src/pricing/nodes/conditional-amount-group-node"
import {ConditionalAmountList} from "@src/pricing/nodes/conditional-amount-list"
import {ConditionalAmountNode} from "@src/pricing/nodes/conditional-amount-node"
import {ConfigurationGroupNode} from "@src/pricing/nodes/configuration-group-node"
import {ConfigurationList} from "@src/pricing/nodes/configuration-list"
import {PricingNodegraph} from "@src/pricing/nodes/core"
import {PriceContainer} from "@src/pricing/nodes/price-container"
import {PriceList} from "@src/pricing/nodes/price-list"
import {PricedItemNode} from "@src/pricing/nodes/priced-item-node"
import {VariantCondition, VariantConditionNode} from "@src/pricing/nodes/variant-condition-node"

export interface CameoPriceGraphData {
    amountGroups: ConditionalAmountGroupNode[]
    pricedItemNodes: PricedItemNode[]
    priceContainers: PriceContainer[]
}

// function createPriceNodesFromSearchResult(catalog: VadoCatalog, term: string, expectedAmount: number): PricedItemNode[] {
//     const searchResults = catalog.search(term)
//     if (searchResults.length !== expectedAmount) throw new Error(`Expected ${expectedAmount} search results for term ${term}, got ${searchResults.length}`)
//     return searchResults.map((item) => item.createPriceGraph((uniqueId: string) => undefined))
// }

function createPriceNodesFromSearchResult(
    catalog: VadoCatalog,
    term: string,
    expectedAmount: number,
    configMap?: Map<string, [ConfigurationGroupNode, string]>,
    amountGroupNode?: ConditionalAmountGroupNode,
): PricedItemNode[] {
    const searchResults = catalog.search(term)
    if (searchResults.length !== expectedAmount) throw new Error(`Expected ${expectedAmount} search results for term ${term}, got ${searchResults.length}`)

    return searchResults.map((item) => {
        const pricedItemNode = item.createPriceGraph((uniqueId: string) => undefined)

        if (amountGroupNode) pricedItemNode.replaceParameters({...pricedItemNode.parameters, amount: amountGroupNode})

        if (configMap) {
            let found = false
            configMap.forEach((value, key) => {
                if (pricedItemNode.parameters.description.includes(key)) {
                    if (found) throw new Error(`Key ${key} already found`)
                    found = true
                    pricedItemNode.addDependency(value[0], value[1])
                }
            })
        }

        return pricedItemNode
    })
}

function createPriceContainer(description: string, uniqueId: string, subPrices: PricingNodegraph[], condition?: VariantConditionNode): PriceContainer {
    return new PriceContainer({
        description: description,
        uniqueId: uniqueId,
        subPrices: new PriceList({list: [...subPrices]}),
        condition: condition
            ? condition
            : new VariantConditionNode({
                  condition: {variantIds: [], variantOperator: "AND", negated: false},
                  currentConfigurations: new ConfigurationList({list: []}),
              }),
    })
}

export function createCameoPriceGraph(catalog: VadoCatalog): CameoPriceGraphData {
    //---------------------------------------------Configuration groups---------------------------------------------
    let furnitureWidth = new ConfigurationGroupNode({groupId: "furniture-width", currentVariantId: "400", allVariantIds: ["400", "600", "800", "1200"]})

    let LeverOption = new ConfigurationGroupNode({groupId: "lever-option", currentVariantId: "lever", allVariantIds: ["lever", "leverless"]})

    let TapFinish = new ConfigurationGroupNode({
        groupId: "3acf90cf-dfd6-4043-be66-cb0590cfd635",
        currentVariantId: "4022413b-478d-4a96-88e7-9c99bcd96546",
        allVariantIds: [
            "4022413b-478d-4a96-88e7-9c99bcd96546",
            "6de4129f-561c-4365-a832-15cd95eefd3a",
            "6d20d045-897c-4aef-8368-d622157a2ec3",
            "5dd635b6-9803-4ce7-ba91-e6c2cecf4e8f",
        ],
    })

    let HandleFinish = new ConfigurationGroupNode({
        groupId: "408cbb56-d6a4-444a-87f6-dfb0fe49f48e",
        currentVariantId: "fb08d24f-d153-4752-a60e-c577c67e2605",
        allVariantIds: [
            "fb08d24f-d153-4752-a60e-c577c67e2605",
            "5eb302cf-8b60-4ca4-b1a4-1677da74e3af",
            "2b4174b9-fe15-4c05-ae69-60faf93e5deb",
            "d80de114-01d0-4190-8ce5-9cbbec4846d3",
        ],
    })

    let Handle = new ConfigurationGroupNode({
        groupId: "handle",
        currentVariantId: "top-mount-left",
        allVariantIds: ["top-mount-left", "top-mount-right", "bar", "knob", "top-mount"],
    })

    let FittingOption = new ConfigurationGroupNode({
        groupId: "fitting-option",
        currentVariantId: "wall-mounted",
        allVariantIds: ["wall-mounted", "floor-mounted"],
    })

    let BasinOrientation = new ConfigurationGroupNode({groupId: "basin-orientation", currentVariantId: "left", allVariantIds: ["left", "right"]})

    let BasinDesign = new ConfigurationGroupNode({
        groupId: "basin-design",
        currentVariantId: "ceramic",
        allVariantIds: ["ceramic", "mineral", "mineral-countertop"],
    })

    let BasinFinish = new ConfigurationGroupNode({
        groupId: "c3030e94-f7bf-4ae0-a714-bfa2fc055c94",
        currentVariantId: "36458f8a-5bd0-43a5-969c-25cc8ca03c8b",
        allVariantIds: [
            "36458f8a-5bd0-43a5-969c-25cc8ca03c8b",
            "50eaa083-5a9a-4058-a33e-acb6a3051f28",
            "e3adfd05-dfd9-417d-b779-d7d6602d29bc",
            "587a81be-ccaa-4c99-9ada-7790e7eba2b4",
        ],
    })

    let FurnitureFinish = new ConfigurationGroupNode({
        groupId: "a3030e94-f7bf-4ae0-a714-bfa2fc055c94",
        currentVariantId: "50eaa083-5a9a-4058-a33e-acb6a3051f28",
        allVariantIds: [
            "50eaa083-5a9a-4058-a33e-acb6a3051f28",
            "e3adfd05-dfd9-417d-b779-d7d6602d29bc",
            "587a81be-ccaa-4c99-9ada-7790e7eba2b4",
            "c0e9884a-8f72-485e-82a2-113ad8190c36",
            "34a6d81a-0852-4ba2-be44-e7eca1c79430",
        ],
    })

    let Worktopfinish = new ConfigurationGroupNode({
        groupId: "b3030e94-f7bf-4ae0-a714-bfa2fc055c94",
        currentVariantId: "36458f8a-5bd0-43a5-969c-25cc8ca03c8b",
        allVariantIds: [
            "36458f8a-5bd0-43a5-969c-25cc8ca03c8b",
            "50eaa083-5a9a-4058-a33e-acb6a3051f28",
            "e3adfd05-dfd9-417d-b779-d7d6602d29bc",
            "587a81be-ccaa-4c99-9ada-7790e7eba2b4",
        ],
    })

    let BasinWaste = new ConfigurationGroupNode({
        groupId: "10e620bf-de82-4cfa-bb49-ed8b7f90adab",
        currentVariantId: "fb291d04-d423-447b-95e5-35a7c81e5d10",
        allVariantIds: ["fb291d04-d423-447b-95e5-35a7c81e5d10", "721829e7-bc50-4279-981c-8fd6181a3d4a"],
    })

    //!let BasinFinish = new ConfigurationGroupNode({groupId: "a3030e94-f7bf-4ae0-a714-bfa2fc055c94", currentVariantId: "36458f8a-5bd0-43a5-969c-25cc8ca03c8b", allVariantIds: ["36458f8a-5bd0-43a5-969c-25cc8ca03c8b", "50eaa083-5a9a-4058-a33e-acb6a3051f28", "e3adfd05-dfd9-417d-b779-d7d6602d29bc", "587a81be-ccaa-4c99-9ada-7790e7eba2b4"]})

    let BasinShape = new ConfigurationGroupNode({groupId: "basin-shape", currentVariantId: "round", allVariantIds: ["round", "oval"]})

    let TapType = new ConfigurationGroupNode({groupId: "tap-type", currentVariantId: "deck-mounted", allVariantIds: ["deck-mounted", "wall-mounted"]})

    //!let BasinDesign = new ConfigurationGroupNode({groupId: "basin-design", currentVariantId: "ceramic", allVariantIds: ["ceramic", "mineral", "mineral-countertop"]})
    //!let Handle = new ConfigurationGroupNode({groupId: "handle", currentVariantId: "top-mount", allVariantIds: ["top-mount", "bar", "knob"]})

    //---------------------------------------------Maps for config group assignment---------------------------------------------
    const funitureFinishConfigMap = new Map<string, [ConfigurationGroupNode, string]>([
        ["Arctic White", [FurnitureFinish, "50eaa083-5a9a-4058-a33e-acb6a3051f28"]],
        ["Cove Blue", [FurnitureFinish, "e3adfd05-dfd9-417d-b779-d7d6602d29bc"]],
        ["Pink Clay", [FurnitureFinish, "587a81be-ccaa-4c99-9ada-7790e7eba2b4"]],
        ["Atlantic Blue", [FurnitureFinish, "c0e9884a-8f72-485e-82a2-113ad8190c36"]],
        ["Natural Oak", [FurnitureFinish, "34a6d81a-0852-4ba2-be44-e7eca1c79430"]],
    ])

    const tapFinishConfigMap = new Map<string, [ConfigurationGroupNode, string]>([
        ["Chrome", [TapFinish, "4022413b-478d-4a96-88e7-9c99bcd96546"]],
        ["Matt Black", [TapFinish, "6de4129f-561c-4365-a832-15cd95eefd3a"]],
        ["Matt White", [TapFinish, "6d20d045-897c-4aef-8368-d622157a2ec3"]],
        ["Satin Brass", [TapFinish, "5dd635b6-9803-4ce7-ba91-e6c2cecf4e8f"]],
    ])

    const handleFinishConfigMap = new Map<string, [ConfigurationGroupNode, string]>([
        ["Chrome", [HandleFinish, "fb08d24f-d153-4752-a60e-c577c67e2605"]],
        ["Matt Black", [HandleFinish, "5eb302cf-8b60-4ca4-b1a4-1677da74e3af"]],
        ["Matt White", [HandleFinish, "2b4174b9-fe15-4c05-ae69-60faf93e5deb"]],
        ["Satin Brass", [HandleFinish, "d80de114-01d0-4190-8ce5-9cbbec4846d3"]],
    ])

    const worktopFinishConfigMap = new Map<string, [ConfigurationGroupNode, string]>([
        ["Gloss White", [Worktopfinish, "36458f8a-5bd0-43a5-969c-25cc8ca03c8b"]],
        ["Arctic White", [Worktopfinish, "50eaa083-5a9a-4058-a33e-acb6a3051f28"]],
        ["Cove Blue", [Worktopfinish, "e3adfd05-dfd9-417d-b779-d7d6602d29bc"]],
        ["Pink Clay", [Worktopfinish, "587a81be-ccaa-4c99-9ada-7790e7eba2b4"]],
    ])

    const basinFinishConfigMap = new Map<string, [ConfigurationGroupNode, string]>([
        ["Gloss White", [BasinFinish, "36458f8a-5bd0-43a5-969c-25cc8ca03c8b"]],
        ["Arctic White", [BasinFinish, "50eaa083-5a9a-4058-a33e-acb6a3051f28"]],
        ["Cove Blue", [BasinFinish, "e3adfd05-dfd9-417d-b779-d7d6602d29bc"]],
        ["Pink Clay", [BasinFinish, "587a81be-ccaa-4c99-9ada-7790e7eba2b4"]],
    ])

    //---------------------------------------------Handle amount---------------------------------------------

    const result: CameoPriceGraphData = {
        amountGroups: [],
        pricedItemNodes: [],
        priceContainers: [],
    }

    const handleAmount2 = new ConditionalAmountNode({
        description: "Handle: 2",
        amount: 2,
        condition: new VariantConditionNode({
            condition: {variantIds: ["600", "800"], variantOperator: "OR", negated: false},
            currentConfigurations: new ConfigurationList({list: [furnitureWidth]}),
        }),
    })
    const handleAmount4 = new ConditionalAmountNode({
        description: "Handle: 4",
        amount: 4,
        condition: new VariantConditionNode({
            condition: {variantIds: ["1200"], variantOperator: "AND", negated: false},
            currentConfigurations: new ConfigurationList({list: [furnitureWidth]}),
        }),
    })
    const handleAmountGroup = new ConditionalAmountGroupNode({
        defaultAmount: 1,
        description: "Handle amount",
        amountNodes: new ConditionalAmountList({list: [handleAmount2, handleAmount4]}),
    })
    result.amountGroups.push(handleAmountGroup)

    //---------------------------------------------Bar/knob handle (shared across all units with different amounts)---------------------------------------------

    //containers are added further below to keep the outpt order of the api consistent
    const handleBarUnits = createPriceNodesFromSearchResult(catalog, "Furniture Bar Handle", 4, handleFinishConfigMap, handleAmountGroup)
    const handleBar = createPriceContainer("Handle bar", "Handle bar", handleBarUnits)
    handleBar.addDependency(Handle, "bar")

    const handleKnobUnits = createPriceNodesFromSearchResult(catalog, "Furniture Knob", 4, handleFinishConfigMap, handleAmountGroup)
    const handleKnob = createPriceContainer("Handle knob", "Handle knob", handleKnobUnits)
    handleKnob.addDependency(Handle, "knob")

    //---------------------------------------------Lever amount---------------------------------------------

    const leverAmount2 = new ConditionalAmountNode({
        description: "Lever: 2",
        amount: 2,
        condition: new VariantConditionNode({
            condition: {variantIds: ["1200"], variantOperator: "AND", negated: false},
            currentConfigurations: new ConfigurationList({list: [furnitureWidth]}),
        }),
    })

    const leverAmountGroup = new ConditionalAmountGroupNode({
        defaultAmount: 1,
        description: "Lever amount",
        amountNodes: new ConditionalAmountList({list: [leverAmount2]}),
    })
    result.amountGroups.push(leverAmountGroup)

    //---------------------------------------------Lever for 600/800/1200 (shared across those, 400 has its own)---------------------------------------------
    //extended: no basin waste -> used with countertop basin
    const extendedTapLeverlessUnits = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Leverless Extended Mono Basin Mixer 5 l/min Flow Regulator",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerExtendedTapLeverless = createPriceContainer(
        "Extended Leverless tap 600/800/1200",
        "Extended Leverless tap 600/800/1200",
        extendedTapLeverlessUnits,
    )
    containerExtendedTapLeverless.addDependency(LeverOption, "leverless")

    const extendedTapLeverUnits = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Lever Extended Mono Basin Mixer 5 l/min Flow Regulator",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerExtendedTapLever = createPriceContainer("Extended Lever tap 600/800/1200", "Extended Lever tap 600/800/1200", extendedTapLeverUnits)
    containerExtendedTapLever.addDependency(LeverOption, "lever")

    //wall: no basin waste -> used with countertop basin
    const wallTapLeverlessUnits = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Leverless Wall Mounted Basin Mixer 5 l/min Flow Regulator",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerWallTapLeverless = createPriceContainer("Wall Leverless tap 600/800/1200", "Wall Leverless tap 600/800/1200", wallTapLeverlessUnits)
    containerWallTapLeverless.addDependency(LeverOption, "leverless")

    const wallTapLeverUnits = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Lever Wall Mounted Basin Mixer 5 l/min Flow Regulator",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerWallTapLever = createPriceContainer("Wall Lever tap 600/800/1200", "Wall Lever tap 600/800/1200", wallTapLeverUnits)
    containerWallTapLever.addDependency(LeverOption, "lever")

    const containerCountertopDeckTap = createPriceContainer("Deck tap countertop 600/800/1200", "Deck tap countertop 600/800/1200", [
        containerExtendedTapLeverless,
        containerExtendedTapLever,
    ])
    containerCountertopDeckTap.addDependency(TapType, "deck-mounted")

    const containerCountertopWallTap = createPriceContainer("Wall tap countertop 600/800/1200", "Wall tap countertop 600/800/1200", [
        containerWallTapLeverless,
        containerWallTapLever,
    ])
    containerCountertopWallTap.addDependency(TapType, "wall-mounted")

    const containerTapsCountertop = createPriceContainer(
        "Taps countertop basin 600/800/1200",
        "Taps countertop basin 600/800/1200",
        [containerCountertopDeckTap, containerCountertopWallTap],
        new VariantConditionNode({
            condition: {variantIds: ["mineral-countertop"], variantOperator: "AND", negated: false},
            currentConfigurations: new ConfigurationList({list: [BasinDesign]}),
        }),
    )

    const monoTapLeverlessUnits = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Leverless Mono Basin Mixer 5 l/min Flow Regulator, ",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerMonoTapLeverless = createPriceContainer("Mono Leverless tap 600/800/1200", "Mono Leverless tap 600/800/1200", monoTapLeverlessUnits)
    containerMonoTapLeverless.addDependency(LeverOption, "leverless")

    const monoTapLeverUnits = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Lever Mono Basin Mixer 5 l/min Flow Regulator, ",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerMonoTapLever = createPriceContainer("Mono Lever tap 600/800/1200", "Mono Lever tap 600/800/1200", monoTapLeverUnits)
    containerMonoTapLever.addDependency(LeverOption, "lever")

    const monoTapLeverlessUnitsBasinWaste = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Leverless Mono Basin Mixer 5 l/min Flow Regulator + Universal Basin Waste, ",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerMonoTapLeverlessBasinWaste = createPriceContainer(
        "Mono Leverless tap 600/800/1200 basin waste",
        "Mono Leverless tap 600/800/1200 basin waste",
        monoTapLeverlessUnitsBasinWaste,
    )
    containerMonoTapLeverlessBasinWaste.addDependency(LeverOption, "leverless")

    const monoTapLeverUnitsBasinWaste = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Lever Mono Basin Mixer 5 l/min Flow Regulator + Universal Basin Waste, ",
        4,
        tapFinishConfigMap,
        leverAmountGroup,
    )
    const containerMonoTapLeverBasinWaste = createPriceContainer(
        "Mono Lever tap 600/800/1200 basin waste",
        "Mono Lever tap 600/800/1200 basin waste",
        monoTapLeverUnitsBasinWaste,
    )
    containerMonoTapLeverBasinWaste.addDependency(LeverOption, "lever")

    const containerTaps600_1200WithoutBasinWaste = createPriceContainer("Taps without basin waste 600-1200", "Taps without basin waste 600-1200", [
        containerMonoTapLeverless,
        containerMonoTapLever,
    ])
    containerTaps600_1200WithoutBasinWaste.addDependency(BasinWaste, "721829e7-bc50-4279-981c-8fd6181a3d4a")

    const containerTaps600_1200WithBasinWaste = createPriceContainer("Taps with basin waste 600-1200", "Taps with basin waste 600-1200", [
        containerMonoTapLeverBasinWaste,
        containerMonoTapLeverlessBasinWaste,
    ])
    containerTaps600_1200WithBasinWaste.addDependency(BasinWaste, "fb291d04-d423-447b-95e5-35a7c81e5d10")

    const containerTapsNonCountertop = createPriceContainer(
        "Taps non-countertop basin 600/800/1200",
        "Taps non-countertop basin 600/800/1200",
        [containerTaps600_1200WithoutBasinWaste, containerTaps600_1200WithBasinWaste],
        new VariantConditionNode({
            condition: {variantIds: ["mineral-countertop"], variantOperator: "AND", negated: true},
            currentConfigurations: new ConfigurationList({list: [BasinDesign]}),
        }),
    )
    //---------------------------------------------Countertop basin---------------------------------------------

    const countertopBasinRoundUnits = createPriceNodesFromSearchResult(catalog, "Countertop basin round", 4, basinFinishConfigMap, leverAmountGroup)
    const countertopBasinOvalUnits = createPriceNodesFromSearchResult(catalog, "Countertop basin oval", 4, basinFinishConfigMap, leverAmountGroup)
    const countertopRound = createPriceContainer("Countertop basin round", "Countertop basin round", countertopBasinRoundUnits)
    countertopRound.addDependency(BasinShape, "round")
    const countertopOval = createPriceContainer("Countertop basin oval", "Countertop basin oval", countertopBasinOvalUnits)
    countertopOval.addDependency(BasinShape, "oval")
    const countertopBasin = createPriceContainer("Countertop basin", "Countertop basin", [countertopRound, countertopOval])
    countertopBasin.addDependency(BasinDesign, "mineral-countertop")

    //---------------------------------------------400mm price container---------------------------------------------
    const wall400Units = createPriceNodesFromSearchResult(catalog, "400mm wall hung Cloakroom Unit with reversable door", 5, funitureFinishConfigMap)
    const containerWall = createPriceContainer("Fitting option wall mounted", "Fitting option wall mounted", wall400Units)
    containerWall.addDependency(FittingOption, "wall-mounted")

    const floor400Units = createPriceNodesFromSearchResult(catalog, "400mm floor mounted Cloakroom Unit with reversable door", 5, funitureFinishConfigMap)
    const containerFloor = createPriceContainer("Fitting option floor mounted", "Fitting option floor mounted", floor400Units)
    containerFloor.addDependency(FittingOption, "floor-mounted")

    const basinLeft400CeramicUnits = createPriceNodesFromSearchResult(catalog, "ceramic furniture basin, left tap hole", 1)
    basinLeft400CeramicUnits[0].addDependency(BasinDesign, "ceramic")
    const basinLeft400MineralUnits = createPriceNodesFromSearchResult(catalog, "mineral cast, left tap hole", 3, basinFinishConfigMap)
    const basinLeft = createPriceContainer("Basin orientation left", "Basin orientation left", [...basinLeft400CeramicUnits, ...basinLeft400MineralUnits])
    basinLeft.addDependency(BasinOrientation, "left")

    const basinRight400CeramicUnits = createPriceNodesFromSearchResult(catalog, "ceramic furniture basin, right tap hole", 1)
    basinRight400CeramicUnits[0].addDependency(BasinDesign, "ceramic")
    const basinRight400MineralUnits = createPriceNodesFromSearchResult(catalog, "mineral cast, right tap hole", 3, basinFinishConfigMap)
    const basinRight = createPriceContainer("Basin orientation right", "Basin orientation right", [...basinRight400CeramicUnits, ...basinRight400MineralUnits])
    basinRight.addDependency(BasinOrientation, "right")

    const handleLeftUnits400 = createPriceNodesFromSearchResult(catalog, "400mm Furniture Top Mount Handle, Left Pul", 4, handleFinishConfigMap)
    const handleLeft = createPriceContainer("Handle orientation left", "Handle orientation left", handleLeftUnits400)
    handleLeft.addDependency(Handle, "top-mount-left")

    const handleRightUnits400 = createPriceNodesFromSearchResult(catalog, "400mm Furniture Top Mount Handle, Right Pul", 4, handleFinishConfigMap)
    const handleRight = createPriceContainer("Handle orientation right", "Handle orientation right", handleRightUnits400)
    handleRight.addDependency(Handle, "top-mount-right")

    const leverlessUnits400 = createPriceNodesFromSearchResult(catalog, "Cameo Leverless Mini Mono Basin Mixer 5 l/min Flow Regulator", 4, tapFinishConfigMap)
    const leverOptionLeverless = createPriceContainer("Lever option leverless", "Lever option leverless", [...leverlessUnits400])
    leverOptionLeverless.addDependency(LeverOption, "leverless")

    const leverlessUnits400BasinWaste = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Leverless Mini Mono Basin Mixer + Universal Basin Waste",
        4,
        tapFinishConfigMap,
    )
    const leverOptionLeverlessBasinWaste = createPriceContainer("Lever option leverless basin waste", "Lever option leverless basin waste", [
        ...leverlessUnits400BasinWaste,
    ])
    leverOptionLeverlessBasinWaste.addDependency(LeverOption, "leverless")

    const leverUnits400 = createPriceNodesFromSearchResult(catalog, "Cameo Lever Mini Mono Basin Mixer 5 l/min Flow Regulator", 4, tapFinishConfigMap)
    const leverOptionLever = createPriceContainer("Lever option lever", "Lever option lever", [...leverUnits400])
    leverOptionLever.addDependency(LeverOption, "lever")

    const leverUnits400BasinWaste = createPriceNodesFromSearchResult(
        catalog,
        "Cameo Lever Mini Mono Basin Mixer + Universal Basin Waste",
        4,
        tapFinishConfigMap,
    )
    const leverOptionLeverBasinWaste = createPriceContainer("Lever option lever basin waste", "Lever option lever basin waste", [...leverUnits400BasinWaste])
    leverOptionLeverBasinWaste.addDependency(LeverOption, "lever")

    const containerTaps400WithoutBasinWaste = createPriceContainer("Taps without basin waste 400", "Taps without basin waste 400", [
        leverOptionLeverless,
        leverOptionLever,
    ])
    containerTaps400WithoutBasinWaste.addDependency(BasinWaste, "721829e7-bc50-4279-981c-8fd6181a3d4a")

    const containerTaps400BasinWaste = createPriceContainer("Taps with basin waste 400", "Taps with basin waste 400", [
        leverOptionLeverlessBasinWaste,
        leverOptionLeverBasinWaste,
    ])
    containerTaps400BasinWaste.addDependency(BasinWaste, "fb291d04-d423-447b-95e5-35a7c81e5d10")

    const container400mm = createPriceContainer("400mm", "400mm", [
        containerWall,
        containerFloor,
        basinLeft,
        basinRight,
        containerTaps400BasinWaste,
        containerTaps400WithoutBasinWaste,
        handleBar,
        handleKnob,
        handleLeft,
        handleRight,
    ])
    container400mm.addDependency(furnitureWidth, "400")
    result.priceContainers.push(container400mm)

    //---------------------------------------------600mm price container---------------------------------------------

    const furniture600Units = createPriceNodesFromSearchResult(catalog, "600mm vanity unit with 2 drawers", 5, funitureFinishConfigMap)

    const worktop600Units = createPriceNodesFromSearchResult(catalog, "Worktop 600mm", 4, worktopFinishConfigMap)
    const containerWorktop600 = createPriceContainer("Worktop 600mm", "Worktop 600mm", worktop600Units)
    containerWorktop600.addDependency(BasinDesign, "mineral-countertop")

    const handle600Units = createPriceNodesFromSearchResult(catalog, "600mm Furniture Top Mount Handle Pair", 4, handleFinishConfigMap)
    const containerHandle600 = createPriceContainer("Handle 600mm", "Handle 600mm", handle600Units)
    containerHandle600.addDependency(Handle, "top-mount")

    const basin600CeramicUnits = createPriceNodesFromSearchResult(catalog, "600mm ceramic furniture basin", 1)
    basin600CeramicUnits[0].addDependency(BasinDesign, "ceramic")
    const basin600MineralUnits = createPriceNodesFromSearchResult(catalog, "600mm basin, mineral cast", 3, basinFinishConfigMap)
    const basin600Mineral = createPriceContainer("Basin design mineral", "mineral", basin600MineralUnits)
    basin600Mineral.addDependency(BasinDesign, "mineral")

    const container600mm = new PriceContainer({
        description: "600mm",
        uniqueId: "600mm",
        subPrices: new PriceList({
            list: [
                ...furniture600Units,
                ...basin600CeramicUnits,
                basin600Mineral,
                countertopBasin,
                containerWorktop600,
                containerTapsCountertop,
                containerTapsNonCountertop,
                containerHandle600,
                handleBar,
                handleKnob,
            ],
        }),
        condition: new VariantConditionNode({
            condition: {variantIds: [], variantOperator: "AND", negated: false},
            currentConfigurations: new ConfigurationList({list: []}),
        }),
    })
    container600mm.addDependency(furnitureWidth, "600")
    result.priceContainers.push(container600mm)

    //---------------------------------------------800mm price container---------------------------------------------
    const furniture800Units = createPriceNodesFromSearchResult(catalog, "800mm vanity unit with 2 drawers", 5, funitureFinishConfigMap)

    const worktop800Units = createPriceNodesFromSearchResult(catalog, "Worktop 800mm", 4, worktopFinishConfigMap)
    const containerWorktop800 = createPriceContainer("Worktop 800mm", "Worktop 800mm", worktop800Units)
    containerWorktop800.addDependency(BasinDesign, "mineral-countertop")

    const handle800Units = createPriceNodesFromSearchResult(catalog, "800mm Furniture Top Mount Handle Pair", 4, handleFinishConfigMap)
    const containerHandle800 = createPriceContainer("Handle 800mm", "Handle 800mm", handle800Units)
    containerHandle800.addDependency(Handle, "top-mount")

    const basin800CeramicUnits = createPriceNodesFromSearchResult(catalog, "800mm ceramic furniture basin", 1)
    basin800CeramicUnits[0].addDependency(BasinDesign, "ceramic")
    const basin800MineralUnits = createPriceNodesFromSearchResult(catalog, "800mm basin, mineral cast", 3, basinFinishConfigMap)
    const basin800Mineral = createPriceContainer("Basin design mineral", "mineral", basin800MineralUnits)
    basin800Mineral.addDependency(BasinDesign, "mineral")

    const container800mm = new PriceContainer({
        description: "800mm",
        uniqueId: "800mm",
        subPrices: new PriceList({
            list: [
                ...furniture800Units,
                ...basin800CeramicUnits,
                basin800Mineral,
                countertopBasin,
                containerWorktop800,
                containerTapsCountertop,
                containerTapsNonCountertop,
                containerHandle800,
                handleBar,
                handleKnob,
            ],
        }),
        condition: new VariantConditionNode({
            condition: {variantIds: [], variantOperator: "AND", negated: false},
            currentConfigurations: new ConfigurationList({list: []}),
        }),
    })
    container800mm.addDependency(furnitureWidth, "800")
    result.priceContainers.push(container800mm)

    //---------------------------------------------1200mm price container---------------------------------------------
    const furniture1200Units = createPriceNodesFromSearchResult(catalog, "1200mm vanity unit with 4 drawers", 5, funitureFinishConfigMap)

    const worktop1200Units = createPriceNodesFromSearchResult(catalog, "Worktop 1200mm", 4, worktopFinishConfigMap)
    const containerWorktop1200 = createPriceContainer("Worktop 1200mm", "Worktop 1200mm", worktop1200Units)
    containerWorktop1200.addDependency(BasinDesign, "mineral-countertop")

    const handle1200LeftUnits = createPriceNodesFromSearchResult(catalog, "1200mm Furniture Top Mount Handle, Left", 4, handleFinishConfigMap)
    handle1200LeftUnits.forEach((node) => (node.parameters.amount = 2))
    const handle1200RightUnits = createPriceNodesFromSearchResult(catalog, "1200mm Furniture Top Mount Handle, Right", 4, handleFinishConfigMap)
    handle1200RightUnits.forEach((node) => (node.parameters.amount = 2))
    const containerHandle1200 = createPriceContainer("Handle 1200mm", "Handle 1200mm", [...handle1200LeftUnits, ...handle1200RightUnits])
    containerHandle1200.addDependency(Handle, "top-mount")

    const basin1200CeramicUnits = createPriceNodesFromSearchResult(catalog, "1200mm ceramic furniture basin", 1)
    basin1200CeramicUnits[0].addDependency(BasinDesign, "ceramic")
    const basin1200MineralUnits = createPriceNodesFromSearchResult(catalog, "1200mm basin, mineral cast", 3, basinFinishConfigMap)
    const basin1200Mineral = createPriceContainer("Basin design mineral", "mineral", basin1200MineralUnits)
    basin1200Mineral.addDependency(BasinDesign, "mineral")

    const container1200mm = new PriceContainer({
        description: "1200mm",
        uniqueId: "1200mm",
        subPrices: new PriceList({
            list: [
                ...furniture1200Units,
                ...basin1200CeramicUnits,
                basin1200Mineral,
                countertopBasin,
                containerWorktop1200,
                containerTapsCountertop,
                containerTapsNonCountertop,
                containerHandle1200,
                handleBar,
                handleKnob,
            ],
        }),
        condition: new VariantConditionNode({
            condition: {variantIds: [], variantOperator: "AND", negated: false},
            currentConfigurations: new ConfigurationList({list: []}),
        }),
    })
    container1200mm.addDependency(furnitureWidth, "1200")
    result.priceContainers.push(container1200mm)

    //---------------------------------------------Overflow covers---------------------------------------------
    const overflowCoverMineralUnits = createPriceNodesFromSearchResult(catalog, "Mineral Basin Overflow Cover", 4, tapFinishConfigMap, leverAmountGroup)
    const containerOverflowCoverMineral = createPriceContainer(
        "Overflow cover mineral",
        "Overflow cover mineral",
        overflowCoverMineralUnits,
        new VariantConditionNode({
            condition: {variantIds: ["mineral"], variantOperator: "OR", negated: false},
            currentConfigurations: new ConfigurationList({list: [BasinDesign]}),
        }),
    )
    result.priceContainers.push(containerOverflowCoverMineral)

    const overflowContainerCeramicUnits = createPriceNodesFromSearchResult(catalog, "Ceramic Basin Overflow Cover", 4, tapFinishConfigMap, leverAmountGroup)
    const containerOverflowCoverCeramic = createPriceContainer("Overflow cover ceramic", "Overflow cover ceramic", overflowContainerCeramicUnits)
    containerOverflowCoverCeramic.addDependency(BasinDesign, "ceramic")
    result.priceContainers.push(containerOverflowCoverCeramic)

    return result
}
