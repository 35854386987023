<i class="far fa-gear" matTooltip="Editor Settings" [matMenuTriggerFor]="editorConfigurationMenu">
    <mat-menu #editorConfigurationMenu>
        <div class="cm-editor-settings-menu">
            <mat-button-toggle-group
                class="cm-toggle-group"
                [value]="sceneManagerService.$lodType()"
                (change)="sceneManagerService.$lodType.set($event.value)"
                [hideSingleSelectionIndicator]="true"
            >
                <mat-button-toggle [matTooltip]="getLodTypeDescription('web')" [value]="'web'" (click)="$event.stopPropagation()">{{
                    getLodTypeDescription("web")
                }}</mat-button-toggle>
                <mat-button-toggle [matTooltip]="getLodTypeDescription('ar')" [value]="'ar'" (click)="$event.stopPropagation()">{{
                    getLodTypeDescription("ar")
                }}</mat-button-toggle>
                <mat-button-toggle [matTooltip]="getLodTypeDescription('pathTraced')" [value]="'pathTraced'" (click)="$event.stopPropagation()">{{
                    getLodTypeDescription("pathTraced")
                }}</mat-button-toggle>
            </mat-button-toggle-group>
            @if (!threeSceneManagerService.$hasIllumination()) {
                <cm-toggle
                    (click)="$event.stopPropagation()"
                    [toggle]="threeSceneManagerService.$ambientLight()"
                    (toggleChange)="threeSceneManagerService.$ambientLight.set($event)"
                >
                    <ng-container cm-text>Ambient Light</ng-container>
                </cm-toggle>
            }
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="threeSceneManagerService.$showGrid()"
                (toggleChange)="threeSceneManagerService.$showGrid.set($event)"
            >
                <ng-container cm-text>Show Grid</ng-container>
            </cm-toggle>
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="threeSceneManagerService.$showProgressiveLightMapDebug()"
                (toggleChange)="threeSceneManagerService.$showProgressiveLightMapDebug.set($event)"
            >
                <ng-container cm-text>Debug Shadows</ng-container>
            </cm-toggle>
            <cm-toggle
                (click)="$event.stopPropagation()"
                [toggle]="sceneManagerService.$reviewMode() !== undefined"
                (toggleChange)="sceneManagerService.$reviewMode.set($event ? 'wireframe' : undefined)"
            >
                <ng-container cm-text>Review</ng-container>
            </cm-toggle>
            @if (sceneManagerService.$reviewMode() !== undefined) {
                <mat-button-toggle-group
                    class="cm-toggle-group"
                    [value]="sceneManagerService.$reviewMode()"
                    (change)="sceneManagerService.$reviewMode.set($event.value)"
                    [hideSingleSelectionIndicator]="true"
                >
                    <mat-button-toggle matTooltip="Wireframe" [value]="'wireframe'" (click)="$event.stopPropagation()">Wireframe</mat-button-toggle>
                    <mat-button-toggle matTooltip="UV" [value]="'uv1'" (click)="$event.stopPropagation()">UV</mat-button-toggle>
                    <mat-button-toggle matTooltip="UV2" [value]="'uv2'" (click)="$event.stopPropagation()">UV2</mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </mat-menu>
</i>
@if (sceneManagerService.$cameras().length > 0) {
    <cm-toggle
        (click)="$event.stopPropagation()"
        [toggle]="selectedCameraId() !== undefined"
        (toggleChange)="selectedCameraIdChanged.emit($event ? sceneManagerService.$cameras()[0].id : undefined)"
    >
        <ng-container cm-text>Camera</ng-container>
    </cm-toggle>
}
