<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Overlay Material Color </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'material'" [label]="'Material'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'overlay'" [label]="'Overlay Image'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'size'" [subKey]="0" [label]="'Width'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'size'" [subKey]="1" [label]="'Height'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
