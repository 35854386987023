import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {color} from "@src/materials/types"
import {threeConvert, threeRGBColorNode, threeValueNode} from "@src/materials/three-utils"
import {getAll} from "@src/graph-system/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class NormalMap extends DeclareMaterialNode(
    {
        returns: z.object({normal: materialSlots}),
        inputs: z.object({
            color: materialSlots.optional(),
            strength: materialSlots.optional(),
        }),
        parameters: z.object({color: color.optional(), strength: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, strength} = await getAll(inputs, get)

            const colorValue = color ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0.5, g: 0.5, b: 1.0})
            const strengthValue = strength ?? threeConvert(parameters.strength, threeValueNode) ?? threeValueNode(1)

            return {normal: THREENodes.normalMap(colorValue, THREENodes.vec2(strengthValue, strengthValue))}
        },
    },
) {}
