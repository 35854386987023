<div class="cm-viewer-container">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <link href="https://storage.googleapis.com/cm-platform-prod-static/fonts/fontawesome-pro-6.0.0-alpha3/css/all.css" rel="stylesheet" />
    @if ($runningOperation() !== "none") {
        <cm-loading-spinner class="cm-processing-overlay">
            <ng-container cm-text-bottom>
                @switch ($runningOperation()) {
                    @case ("stl") {
                        Generating STL files...
                    }
                    @case ("ar") {
                        Generating AR model...
                    }
                }
            </ng-container>
        </cm-loading-spinner>
    }
    <cm-three-template-scene-provider (onInitialized)="onInititalizedThreeSceneManagerService($event)">
        <cm-three-template-scene-viewer #viewer [camera]="camera()" [allowEdit]="false"></cm-three-template-scene-viewer>

        @if (showUi() && $runningOperation() !== "loading") {
            <cm-action-menu-new
                class="cm-action-menu"
                [$inFullscreen]="$inFullscreen()"
                [$sceneManagerService]="localSceneManagerService"
                [$fullscreenEnabled]="sceneProperties()?.parameters.enableFullscreen ?? true"
                [$stlDownloadEnabled]="sceneProperties()?.parameters.enableStlDownload ?? false"
                [$snapshotEnabled]="sceneProperties()?.parameters.enableSnapshot ?? true"
                [$arEnabled]="sceneProperties()?.parameters.enableAr ?? false"
            >
            </cm-action-menu-new>
            @if (showMenu()) {
                <cm-config-menu-new
                    class="cm-config-menu"
                    exportparts="config-group-title, cm-icon-container, cm-configurator-menu, cm-icon-with-caption, cm-config-menu-icons"
                ></cm-config-menu-new>
            }
        }
    </cm-three-template-scene-provider>
</div>
