<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Size </ng-container>
    <ng-container cm-content>
        <div class="cm-size">
            <cm-value-slot [node]="node()" [key]="'width'" [label]="'Width'"></cm-value-slot>
            <div class="cm-multiplier">×</div>
            <cm-value-slot [node]="node()" [key]="'height'" [label]="'Height'"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Area Light </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'on'" [label]="'On'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'targeted'" [label]="'Targeted'" (onChanged)="onChangedTargeted($event)"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'intensity'" [label]="'Intensity'" [min]="0" [max]="60"></cm-value-slot>
        <div class="cm-light-settings">
            <cm-value-slot [node]="node()" [key]="'color'" [label]="'Color'"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'directionality'" [label]="'Directionality'"></cm-value-slot>
        </div>
        <cm-value-slot [node]="node()" [key]="'lightType'" [label]="'Type'" [selectionPossibilities]="lightTypePossibilites"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'visibleDirectly'" [label]="'Visible to Camera'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'visibleInReflections'" [label]="'Visible in Reflections'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'visibleInRefractions'" [label]="'Visible in Refractions'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'transparent'" [label]="'Transparent'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
