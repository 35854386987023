import {Component, inject} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {
    CheckboxesFilterComponent,
    PinnedFilterComponent,
    PinnedFilterOptionComponent,
    SearchFilterComponent,
    TagSearchFilterComponent,
} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {UsersService} from "@common/services/users/users.service"
import {Labels} from "@labels"
import {AssetsGridComponent} from "@platform/components/assets/assets-grid/assets-grid.component"
import {Enums} from "@enums"
import {HdrisGridComponent} from "@platform/components/hdris/hdris-grid/hdris-grid.component"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"

@Component({
    selector: "cm-assets-page",
    standalone: true,
    imports: [
        CheckboxesFilterComponent,
        HdrisGridComponent,
        ItemFiltersComponent,
        RouterOutlet,
        SearchFilterComponent,
        SidebarLayoutComponent,
        PinnedFilterComponent,
        PinnedFilterOptionComponent,
        TagSearchFilterComponent,
        AssetsGridComponent,
    ],
    templateUrl: "./assets-page.component.html",
    styleUrl: "./assets-page.component.scss",
})
export class AssetsPageComponent {
    readonly Enums = Enums
    readonly Labels = Labels

    can = inject(PermissionsService)
    organizations = inject(OrganizationsService)
    users = inject(UsersService)
}
