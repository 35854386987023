import {Component} from "@angular/core"
import {CommonModule} from "@angular/common"
import {RouterOutlet} from "@angular/router"
import {JobFarmGroupsTableComponent} from "@platform/components/job-farm-groups/job-farm-groups-table/job-farm-groups-table.component"
import {JobFarmInstancesTableComponent} from "@platform/components/job-farm-instances/job-farm-instances-table/job-farm-instances-table.component"
import {TableLayoutComponent} from "@platform/components/layouts/table-layout/table-layout.component"

@Component({
    selector: "cm-job-farm-instances-page",
    standalone: true,
    imports: [CommonModule, JobFarmGroupsTableComponent, RouterOutlet, TableLayoutComponent, JobFarmInstancesTableComponent],
    templateUrl: "./job-farm-instances-page.component.html",
    styleUrl: "./job-farm-instances-page.component.scss",
})
export class JobFarmInstancesPageComponent {}
