import {Component, input} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"
import {AsyncPipe} from "@angular/common"
import {ViewMode} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-toolbox-item"
import {MatTooltipModule} from "@angular/material/tooltip"

@Component({
    selector: "cm-tiling-panel",
    standalone: true,
    imports: [InspectorSectionComponent, SliderComponent, ToggleComponent, ButtonComponent, AsyncPipe, MatTooltipModule],
    templateUrl: "./tiling-panel.component.html",
    styleUrl: "./tiling-panel.component.scss",
})
export class TilingPanelComponent {
    $operator = input<OperatorTiling | undefined>(undefined, {alias: "operator"})
    $disabled = input(false, {alias: "disabled"})

    protected readonly ViewMode = ViewMode

    get isDebugEnabled(): boolean {
        return this.$operator()?.callback.isDebugEnabled ?? false
    }
}
