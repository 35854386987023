// @ts-strict-ignore
import {AsyncPipe} from "@angular/common"
import {Component, EventEmitter, Input, Output} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {NodeDropTargetDirective} from "@app/templates/template-publisher/template-tree/node-drop-target.directive"
import {CardComponent} from "@common/components/cards/card/card.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {Material} from "@legacy/api-model/material"
import {MaterialRevision} from "@legacy/api-model/material-revision"
import {Settings} from "@common/models/settings/settings"
import {Observable, of as observableOf} from "rxjs"
import {switchMap, map} from "rxjs"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"

@Component({
    selector: "cm-material-assignment",
    templateUrl: "./material-assignment.component.html",
    styleUrls: ["./material-assignment.component.scss"],
    standalone: true,
    imports: [
        CardComponent,
        NodeDropTargetDirective,
        MemoizePipe,
        AsyncPipe,
        MatTooltipModule,
        MatMenuModule,
        NodeReferenceSlotComponent,
        NumericInputComponent,
    ],
})
export class MaterialAssignmentComponent {
    @Input() assignment: Nodes.MaterialAssignment
    @Output() chooseMaterialNode = new EventEmitter<(node: Nodes.Material | null) => void>()
    @Output() assignmentChanged = new EventEmitter<Nodes.MaterialAssignment>()
    @Output() addAssignment = new EventEmitter<Nodes.MaterialAssignment>()
    @Output() removeAssignment = new EventEmitter<Nodes.MaterialAssignment>()

    readonly noMaterialUrl = Settings.NO_MATERIAL_URL
    readonly isSwitch = NodeUtils.isSwitch
    readonly isMaterialReference = NodeUtils.isMaterialReference
    readonly resolvesToMaterial = NodeUtils.resolvesToMaterial

    constructor() {}

    fetchMaterialRevision(id?: number): Observable<MaterialRevision | null> {
        if (id != null) {
            return MaterialRevision.get(id)
        } else {
            return observableOf(null)
        }
    }

    fetchMaterial(materialRevision?: MaterialRevision): Observable<Material | null> {
        if (materialRevision) {
            return Material.get(materialRevision.material)
        } else {
            return observableOf(null)
        }
    }

    getMaterialRevisionId(): number | null {
        const node = this.assignment?.node
        if (!node) return null
        return NodeUtils.isMaterialReference(node) ? node.materialRevisionId : null
    }

    hasName(material: Nodes.Material): material is Nodes.Material & {name: string} {
        return "name" in material
    }

    fetchNewerRevision(id?: number): Observable<MaterialRevision | null> {
        let asdf: any
        if (id != null) {
            return MaterialRevision.get(id).pipe(
                switchMap((materialRevision) => Material.get(materialRevision.material)),
                map((material) => {
                    const latest = material.getLatestCyclesRevision()
                    return latest.id !== id ? latest : null
                }),
            )
        } else {
            return observableOf(null)
        }
    }

    updateRevision(assignment: Nodes.MaterialAssignment, materialRevision: MaterialRevision) {
        if (assignment?.node?.type === "materialReference") {
            assignment.node.materialRevisionId = materialRevision.id
            this.assignmentChanged.emit(assignment)
        }
    }

    _chooseMaterialNode(assignment: Nodes.MaterialAssignment) {
        this.chooseMaterialNode.emit((node: Nodes.Material | null) => {
            if (node) {
                assignment.node = node
                this.updateAssignment(assignment)
            }
        })
    }

    _addAssignment() {
        this.chooseMaterialNode.emit((node: Nodes.Material | null) => {
            if (node) {
                this.addAssignment.emit({
                    node,
                })
            }
        })
    }

    updateAssignment(assignment: Nodes.MaterialAssignment) {
        this.assignmentChanged.emit(assignment)
    }

    _dropNode(node: Nodes.Material) {
        if (this.assignment) {
            this.assignment.node = node
            this.updateAssignment(this.assignment)
        } else {
            this.addAssignment.emit({
                node,
            })
        }
    }
}
