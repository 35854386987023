import {AsyncCacheMap} from "@app/common/helpers/async-cache-map/async-cache-map"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {IMaterialGraph} from "@cm/lib/templates/interfaces/material-data"
import {from} from "rxjs"

export class MaterialGraphCache extends AsyncCacheMap<number, IMaterialGraph> {
    constructor(private materialGraphService: MaterialGraphService) {
        super((materialRevisionId: number) => {
            return from(this.materialGraphService.graphFromMaterialRevisionId({legacyId: materialRevisionId}))
        })
    }
}
