import {Component} from "@angular/core"

@Component({
    selector: "cm-template-menu-section",
    standalone: true,
    imports: [],
    templateUrl: "./template-menu-section.component.html",
    styleUrl: "./template-menu-section.component.scss",
})
export class TemplateMenuSectionComponent {}
