import {Component, DestroyRef, OnInit, computed, inject, input, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNode} from "@cm/lib/templates/types"

@Component({
    selector: "cm-base-inspector",
    standalone: true,
    imports: [],
    template: "",
})
export class BaseInspectorComponent<T extends TemplateNode | null> implements OnInit {
    node = input.required<T>()
    private triggerRecompute = signal(0)
    parameters = computed<T extends null ? NonNullable<T>["parameters"] | null : NonNullable<T>["parameters"]>(
        () => {
            this.triggerRecompute()
            const node = this.node()
            if (!node) return null as T extends null ? NonNullable<T>["parameters"] | null : NonNullable<T>["parameters"]
            return node.parameters as T extends null ? NonNullable<T>["parameters"] | null : NonNullable<T>["parameters"]
        },
        {equal: () => false},
    )

    protected sceneManagerService = inject(SceneManagerService)
    protected destroyRef = inject(DestroyRef)

    ngOnInit() {
        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const node = this.node()
            if (node && differences.modifiedNodes.has(node)) this.triggerRecompute.update((x) => x + 1)
        })
    }
}
