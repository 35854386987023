export const midnightThisMorning = (): Date => {
    const result = new Date()
    result.setHours(0, 0, 0, 0)
    return result
}

export const midnightTonight = (): Date => {
    const result = new Date()
    result.setHours(23, 59, 59, 999)
    return result
}
