// @ts-strict-ignore
import {Component, inject} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"
import {SdkService} from "@common/services/sdk/sdk.service"

@Component({
    selector: "cm-template-viewer-dialog",
    templateUrl: "./template-viewer-dialog.component.html",
    styleUrls: ["./template-viewer-dialog.component.scss"],
    standalone: true,
    imports: [RoutedDialogComponent, SceneViewerComponent],
})
export class TemplateViewerDialogComponent {
    dialogSizes = DialogSize
    templateLegacyId: number
    closeNavigationPath: string

    sdk = inject(SdkService)

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {
        const templateId = route.snapshot.paramMap.get("templateId")
        this.sdk.gql.getViewerTemplateDetails({id: templateId}).then((queryResult) => {
            const templateLegacyId = queryResult?.template?.legacyId
            this.templateLegacyId = templateLegacyId && !isNaN(templateLegacyId) ? templateLegacyId : undefined
        })

        this.closeNavigationPath = this.route.snapshot.data.closeNavigationPath ?? "../"
    }

    overlayClosed() {
        this.router.navigate([this.closeNavigationPath], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }
}
