import {ImageRefBase} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref-base"
import {isSmartPtr, SmartPtr, SmartPtrReassignable} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {SmartPtrArray} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr-array"

export class ImageRef extends ImageRefBase {}

export class ImagePtr extends SmartPtr<ImageRef> {}

export class ImagePtrReassignable extends SmartPtrReassignable<ImageRef> {}

export class ImagePtrArray extends SmartPtrArray<ImageRef> {}

// export type ImageRefOrPtr = ImageRef | ImagePtr

// export const resolveImageRef = (refOrPtr: ImageRefOrPtr): ImageRef => {
//     return isSmartPtr(refOrPtr) ? refOrPtr.ref : refOrPtr
// }

// export const ensureImagePtr = (refOrPtr: ImageRefOrPtr): ImagePtr => {
//     return isSmartPtr(refOrPtr) ? refOrPtr : new SmartPtr(refOrPtr)
// }

// export function isImageRefOrPtr(obj: unknown): obj is ImageRefOrPtr {
//     return obj instanceof ImageRefBase || isImagePtr(obj)
// }

export function isImagePtr(obj: unknown): obj is ImagePtr {
    return isSmartPtr(obj) && isImageRef(obj.ref)
}

function isImageRef(obj: unknown): obj is ImageRef {
    return obj instanceof ImageRefBase
}
