import {Object} from "@cm/lib/templates/node-types"
import {Matrix4, Vector3} from "@app/common/helpers/vector-math"
import {IMatrix4} from "@cm/lib/templates/interfaces/matrix"
import {Camera} from "@cm/lib/templates/nodes/camera"
import {AreaLight} from "@cm/lib/templates/nodes/area-light"
import {TemplateNodePart, TransformMode} from "../services/scene-manager.service"

export function constrainTransform(
    transform: IMatrix4,
    transformedNodePart: {
        templateNode: Camera | AreaLight
        part: TemplateNodePart["part"]
    },
    mode: TransformMode,
): {transform: Matrix4; target?: Vector3} {
    const {templateNode, part} = transformedNodePart

    if (!templateNode.parameters.targeted) {
        if (part !== "root") throw new Error("Part must be root")
        return {transform: new Matrix4(transform.toArray())}
    }

    if (part !== "root" && part !== "target") throw new Error("Part must be root or target")

    if (mode === "translate") {
        if (part === "target") {
            const newTarget = transform.getTranslation()
            if (!(newTarget instanceof Vector3)) throw new Error("newTarget is not a Vector3")
            const newTransform = Matrix4.cameraLookAt(new Matrix4(templateNode.parameters.lockedTransform).getTranslation(), newTarget)
            return {transform: newTransform, target: newTarget}
        } else {
            const target = Vector3.fromArray(templateNode.parameters.target)
            const newPosition = transform.getTranslation()
            if (!(newPosition instanceof Vector3)) throw new Error("newPosition is not a Vector3")
            const newTransform = Matrix4.cameraLookAt(newPosition, target)
            return {transform: newTransform}
        }
    } else if (mode === "rotate") {
        if (part === "target") {
            throw new Error("Rotating around a target is currently not supported")
        } else {
            const delta = new Matrix4(getTransformDelta(transform, templateNode).toArray())
            const curTarget = Vector3.fromArray(templateNode.parameters.target)
            const lockedTransform = templateNode.parameters.lockedTransform
            const curPosition = new Matrix4(lockedTransform).getTranslation()
            const newTarget = delta.multiplyVector(curTarget.sub(curPosition)).add(curPosition)

            const newTranslation = transform.getTranslation()

            const newTransform = Matrix4.cameraLookAt(new Vector3(newTranslation.x, newTranslation.y, newTranslation.z), newTarget)
            return {transform: newTransform, target: newTarget}
        }
    } else {
        throw new Error("Scaling a camera or area light is currently not supported")
    }
}

export function getTransformDelta(transform: IMatrix4, templateNode: Object) {
    const worldMatrix = new Matrix4(templateNode.parameters.lockedTransform)
    const delta = transform.multiply(worldMatrix.inverse())
    return delta
}
