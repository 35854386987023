import {Injectable} from "@angular/core"
import {CreateSalesEnquiryGQL, MutationCreateSalesEnquiryInput} from "@api"

@Injectable({
    providedIn: "root",
})
export class SalesEnquiryService {
    constructor(private createSalesEnquiryGql: CreateSalesEnquiryGQL) {}

    createSalesEnquiry(input: MutationCreateSalesEnquiryInput) {
        return this.createSalesEnquiryGql.mutate({input: input})
    }
}
