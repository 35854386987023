import {DataObjectAssignmentType} from "@src/api-gql/data-object-assignment"
import {CachedNodeGraphResult} from "@src/graph-system/evaluators/cached-node-graph-result"
import {DataObjectReference} from "@src/templates/nodes/data-object-reference"
import {MaterialReference} from "@src/templates/nodes/material-reference"
import {TemplateReference} from "@src/templates/nodes/template-reference"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {TemplateNode, VisitMode, VisitorNodeContext} from "@src/templates/types"
import {HDRILight} from "@src/templates/nodes/hdri-light"

export type TemplateGraphReferencedIds = {
    templateReferences: Set<number>
    materialReferences: Set<number>
    hdriReferences: Set<number>
    dataObjectAssignments: Map<number, Set<DataObjectAssignmentType>>
}

/*The old template system does this in the backend, see
api-gql/pothos/src\helpers/graph/templates/collect-references.ts*/
export function collectReferences(templateGraph: TemplateGraph): TemplateGraphReferencedIds {
    const templateReferences = new Set<number>()
    const materialReferences = new Set<number>()
    const hdriReferences = new Set<number>()
    const dataObjectAssignments = new Map<number, Set<DataObjectAssignmentType>>()

    const context: VisitorNodeContext = {
        visitMode: VisitMode.TraverseAll,
        onTraverseAll: {},
        onPostUnskippedNode: function (this: TemplateNode) {
            if (this instanceof TemplateReference) {
                templateReferences.add(this.parameters.templateRevisionId)
            } else if (this instanceof MaterialReference) {
                materialReferences.add(this.parameters.materialRevisionId)
            } else if (this instanceof HDRILight) {
                hdriReferences.add(this.parameters.hdriId)
            } else if (this instanceof DataObjectReference) {
                dataObjectAssignments.set(
                    this.parameters.dataObjectId,
                    dataObjectAssignments.get(this.parameters.dataObjectId)?.add(DataObjectAssignmentType.TemplateDataOther) ??
                        new Set<DataObjectAssignmentType>([DataObjectAssignmentType.TemplateDataOther]),
                )
            }
        },
    }

    const resultTraverseAll = new CachedNodeGraphResult(templateGraph, context)
    resultTraverseAll.runSync()

    return {templateReferences, materialReferences, hdriReferences, dataObjectAssignments}
}
