import * as paper from "paper"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class TilingResultOverlayItem extends CanvasBaseToolboxItemBase {
    constructor(parent: CanvasBaseToolboxItem, itemDesc: TilingResultOverlayItemDesc, cutoutRegionGrid: paper.Point) {
        super(parent)
        if (itemDesc.type === "path") {
            this.path = new paper.Path(itemDesc.points.map((pt) => new paper.Segment(new paper.Point(pt[0], pt[1]).add(cutoutRegionGrid))))
            this.path.strokeColor = new paper.Color(itemDesc.color)
            this.path.strokeWidth = 2
            this.path.strokeScaling = false
        } else if (itemDesc.type === "point") {
            this.path = new paper.Path.Circle(new paper.Point(itemDesc.point[0], itemDesc.point[1]).add(cutoutRegionGrid), 10)
            this.path.strokeColor = new paper.Color(itemDesc.color)
            this.path.strokeWidth = 2
            this.path.strokeScaling = false
            this.path.fillColor = new paper.Color(itemDesc.color)
            this.path.fillColor.alpha = 0.25
        } else if (itemDesc.type === "region") {
            this.path = new paper.Path(itemDesc.points.map((pt) => new paper.Segment(new paper.Point(pt[0], pt[1]).add(cutoutRegionGrid))))
            this.path.strokeColor = new paper.Color(itemDesc.color)
            this.path.strokeWidth = 2
            this.path.strokeScaling = false
            this.path.fillColor = new paper.Color(itemDesc.color)
            this.path.fillColor.alpha = 0.25
        } else {
            throw Error("Invalid result overlay type")
        }
    }

    private path: paper.Path
}

export type TilingResultOverlayItemDesc =
    | {type: "path"; points: [number, number][]; color: string}
    | {type: "point"; point: [number, number]; color: string}
    | {type: "region"; points: [number, number][]; color: string}
