import {Component, Input, OnInit} from "@angular/core"
import {LoadingSpinnerIconComponent} from "@common/components/progress/loading-spinner-icon/loading-spinner-icon.component"

@Component({
    standalone: true,
    selector: "cm-loading-spinner",
    templateUrl: "./loading-spinner.component.html",
    styleUrls: ["./loading-spinner.component.scss"],
    imports: [LoadingSpinnerIconComponent],
})
export class LoadingSpinnerComponent implements OnInit {
    constructor() {}

    @Input() showSpinner = true

    ngOnInit(): void {}
}
