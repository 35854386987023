import {Injectable, OnDestroy} from "@angular/core"
import {Subject, takeUntil} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class ScanningService implements OnDestroy {
    private unsubscribe = new Subject<void>()
    log = new Subject<string>()
    logs = ""

    constructor() {
        this.log.pipe(takeUntil(this.unsubscribe)).subscribe((newLog) => this.appendLog(newLog))
    }

    private appendLog(newLog: string): void {
        if (this.logs.length > 0) newLog = `\n${newLog}`
        this.logs += `${newLog}`
    }

    clearLogs(): void {
        this.logs = ""
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
