import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {imageColorSpace} from "@src/templates/types"

const transientDataObjectParameters = z.object({
    data: z.instanceof(Uint8Array),
    contentType: z.string(),
    imageColorSpace: imageColorSpace,
})
export type TransientDataObjectParameters = z.infer<typeof transientDataObjectParameters>

@registerNode
export class TransientDataObject
    extends DeclareTemplateNode({parameters: transientDataObjectParameters}, {}, {nodeClass: "TransientDataObject"})
    implements EvaluableTemplateNode<TemplateImageDataNew>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return {
            dataObject: evaluator.templateContext.sceneManager.createTransientDataObjectNew(
                this.parameters.data,
                this.parameters.contentType,
                this.parameters.imageColorSpace,
            ),
        }
    }
}

export type TransientDataObjectFwd = TemplateNode<TransientDataObjectParameters> & EvaluableTemplateNode<TemplateImageDataNew>
