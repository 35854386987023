// @ts-strict-ignore
import {Component, EventEmitter, Input, Output} from "@angular/core"

@Component({
    selector: "cm-native-input-text",
    templateUrl: "./native-input-text.component.html",
    styleUrls: ["./native-input-text.component.scss"],
    standalone: true,
})
export class NativeInputTextComponent {
    @Input() placeholder: string = "Search..."
    @Input() value: string = ""
    @Output() valueChange = new EventEmitter<string>()
    @Output() focus = new EventEmitter<FocusEvent>()
    @Output() blur = new EventEmitter<FocusEvent>()
    @Output() keyUp = new EventEmitter<string>()
    @Input() disabled = false
    @Input() required = false
    @Input() showInvalid = false
    @Input() type = "text"
}
