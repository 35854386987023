import {map, Subscription} from "rxjs"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {Nodes} from "@src/templates/legacy/template-nodes"
import {ISceneManager} from "@src/templates/interfaces/scene-manager"

const TD = TypeDescriptors

const loadGraphDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    templateRevisionId: TD.inlet(TD.Number),
    graph: TD.outlet(TD.Identity<Nodes.TemplateGraph>()),
}

export class LoadGraph implements NodeClassImpl<typeof loadGraphDescriptor, typeof LoadGraph> {
    static descriptor = loadGraphDescriptor
    static uniqueName = "LoadGraph"
    sceneManager!: Inlet<ISceneManager>
    templateRevisionId!: Inlet<number>
    graph!: Outlet<Nodes.TemplateGraph>

    private _pending: Subscription | null = null

    run() {
        // this.graph.emitIfChanged(NotReady);
        this._pending?.unsubscribe()
        if (this.sceneManager === NotReady || this.templateRevisionId === NotReady) {
            return
        }
        this._pending = this.sceneManager.addTask(
            this.sceneManager.loadTemplateGraph(this.templateRevisionId).pipe(
                map((graph) => {
                    this._pending = null
                    this.graph.emitIfChanged(graph)
                }),
            ),
        )
    }

    cleanup() {
        this._pending?.unsubscribe()
    }
}
