import {Vector2} from "@cm/lib/math/vector2"
import {GridInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/grid-interpolator"

export type BoundaryCurveFunction = (t: number) => Vector2

export class TransfiniteInterpolator2D implements GridInterpolator {
    constructor(
        private boundaryCurveTopLeftToRight: BoundaryCurveFunction,
        private boundaryCurveBottomLeftToRight: BoundaryCurveFunction,
        private boundaryCurveLeftTopToBottom: BoundaryCurveFunction,
        private boundaryCurveRightTopToBottom: BoundaryCurveFunction,
    ) {}

    interpolate(uv: Vector2): Vector2 {
        const sampledLeftC2 = this.boundaryCurveLeftTopToBottom(uv.y)
        const sampledRightC4 = this.boundaryCurveRightTopToBottom(uv.y)
        const sampledTopC1 = this.boundaryCurveTopLeftToRight(uv.x)
        const sampledBottomC3 = this.boundaryCurveBottomLeftToRight(uv.x)

        const sampledTopLeft = this.boundaryCurveTopLeftToRight(0)
        const sampledTopRight = this.boundaryCurveTopLeftToRight(1)
        const sampledBottomLeft = this.boundaryCurveBottomLeftToRight(0)
        const sampledBottomRight = this.boundaryCurveBottomLeftToRight(1)

        const secondTerm = new Vector2(0, 0)
        secondTerm.addInPlace(sampledTopLeft.mul((1 - uv.x) * (1 - uv.y)))
        secondTerm.addInPlace(sampledTopRight.mul(uv.x * (1 - uv.y)))
        secondTerm.addInPlace(sampledBottomLeft.mul((1 - uv.x) * uv.y))
        secondTerm.addInPlace(sampledBottomRight.mul(uv.x * uv.y))

        const pos = new Vector2(0, 0)
        pos.addInPlace(sampledLeftC2.mul(1 - uv.x))
        pos.addInPlace(sampledRightC4.mul(uv.x))
        pos.addInPlace(sampledTopC1.mul(1 - uv.y))
        pos.addInPlace(sampledBottomC3.mul(uv.y))
        pos.subInPlace(secondTerm)

        return pos
    }
}
