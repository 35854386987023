// @ts-strict-ignore
import {KeyValuePipe} from "@angular/common"
import {Component, EventEmitter, Input, Output} from "@angular/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {Router} from "@angular/router"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {NodeDragSourceDirective, NodeDropTargetDirective} from "@app/templates/template-publisher/template-tree/node-drop-target.directive"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {AuthService} from "@common/services/auth/auth.service"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {IEditor} from "@editor/models/editor"
import {NodeClipboardService} from "app/templates/template-publisher/node-clipboard.service"

@Component({
    selector: "cm-template-inspector",
    templateUrl: "./template-inspector.component.html",
    styleUrls: ["./template-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        InputContainerComponent,
        StringInputComponent,
        NodeReferenceSlotComponent,
        MatInputModule,
        MatSelectModule,
        NodeDropTargetDirective,
        KeyValuePipe,
        NodeDragSourceDirective,
        NumericInputComponent,
    ],
})
export class TemplateInspectorComponent {
    @Input() editor: IEditor
    @Input() node: Nodes.TemplateOrInstance | null = null
    @Output() chooseMaterialNode = new EventEmitter<(material: Nodes.Material | null) => void>()

    isConfigInput = Nodes.Meta.isConfigInput
    isMaterialInput = Nodes.Meta.isMaterialInput
    isTemplateInput = Nodes.Meta.isTemplateInput
    isNumberInput = Nodes.Meta.isNumberInput
    isStringInput = Nodes.Meta.isStringInput
    isBooleanInput = Nodes.Meta.isBooleanInput
    isOutput = Nodes.Meta.isOutput
    isNode = NodeUtils.isNode
    resolvesToTemplateDefinition = NodeUtils.resolvesToTemplateDefinition
    isValidId = NodeUtils.isValidId

    constructor(
        private router: Router,
        readonly authService: AuthService,
        readonly clipboardService: NodeClipboardService,
    ) {}

    isOutputDescriptor(iface: Nodes.Meta.InterfaceDescriptor): iface is Nodes.Meta.OutputDescriptor {
        return this.isOutput(iface)
    }

    updateNode(node: Nodes.Node) {
        this.editor.sceneManager.markNodeChanged(node)
    }

    getInterfaceDescriptors(node: Nodes.TemplateOrInstance) {
        return (node && this.editor.sceneManager.getInterfaceForNode(node)) ?? []
    }

    getControllingNode = Nodes.Meta.getControllingNode
    getParameter = Nodes.Meta.getParameter

    setControllingNode(node: Nodes.TemplateOrInstance, id: string, ctrlNode: Nodes.Node) {
        Nodes.Meta.setControllingNode(node, id, ctrlNode)
        this.updateNode(node)
    }

    setParameter(node: Nodes.TemplateOrInstance, id: string, value: any): void {
        Nodes.Meta.setParameter(node, id, value)
        this.updateNode(node)
    }

    copyVariantReference(node: Nodes.TemplateOrInstance, variant: Nodes.Meta.VariantInfo) {
        let template: Nodes.TemplateInstance["template"]
        if (node.type === "templateReference") {
            template = node
        } else if (node.type === "templateInstance") {
            template = node.template
        } else {
            throw new Error()
        }

        if (template) {
            this.clipboardService.copy(
                Nodes.create<Nodes.ResolveNode<Nodes.ConfigVariant>>({
                    type: "resolveNode",
                    name: `ConfigRef: ${variant.name}`,
                    template,
                    nodeId: variant.id,
                }),
            )
        }
    }

    createOutputReference(node: Nodes.TemplateOrInstance, output: Nodes.Meta.OutputDescriptor) {
        return Nodes.create<Nodes.GetTemplateOutput<Nodes.Node>>({
            type: "getTemplateOutput",
            name: `OutputRef: ${output.name}`,
            template: this.node,
            outputId: output.id,
            outputType: output.outputType,
        })
    }

    copyOutputReference(node: Nodes.TemplateOrInstance, output: Nodes.Meta.OutputDescriptor) {
        this.clipboardService.copy(this.createOutputReference(node, output))
    }

    getInvalidInputs(node: Nodes.TemplateOrInstance, ifaces: Nodes.Meta.InterfaceDescriptor[]) {
        const invalid = {...Nodes.Meta.getAllParameters(node)}

        for (const id of Object.keys(invalid)) {
            for (const iface of ifaces) {
                if (iface.id === id) {
                    delete invalid[id]
                    break
                }
            }
        }
        return Object.keys(invalid).length > 0 ? invalid : null
    }
}
