import {NodeGraph, isNodeGraphInstance} from "@cm/lib/graph-system/node-graph"
import * as SimpleGraph from "@platform/helpers/simple-graph/simple-graph"
import {ExporterYed} from "@platform/helpers/simple-graph/exporter/exporter-yed"
import {DebugMap, GeneratedOperatorNodeGraphResult} from "app/textures/texture-editor/operator-stack/operator-stack"
import {Operator} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {downloadFromMemory} from "@app/common/helpers/utils/download-from-memory"

export class ImageOpGraphExporter {
    exportGraph(generatedOperatorNodeGraphResult: GeneratedOperatorNodeGraphResult) {
        const OPERATOR_COLOR = "#CCCCCC"
        const NODE_COLOR = "#CCFF88"
        const VALUE_COLOR = "#88CCFF"
        const gatherNodes = (
            node: NodeGraph,
            debugMap: DebugMap | undefined,
            simpleNodeByOperator: Map<Operator | null, SimpleGraph.Node>,
            simpleNodeByNode: Map<NodeGraph, SimpleGraph.Node>,
            // simpleNodes: SimpleGraph.Node[],
            simpleEdges: SimpleGraph.Edge[],
            currOperator: Operator | null,
        ): SimpleGraph.Node => {
            const simpleNode = simpleNodeByNode.get(node)
            if (simpleNode) {
                return simpleNode
            }
            if (debugMap) {
                for (const [operator, debugInfo] of debugMap) {
                    if (debugInfo.outputParameter === node) {
                        currOperator = operator
                    }
                }
            }
            const parentSimpleNode = simpleNodeByOperator.get(currOperator)
            if (!parentSimpleNode) {
                throw new Error(`No parent node found for operator ${currOperator}`)
            }
            const label = node.getNodeLabel()
            const newSimpleNode = new SimpleGraph.Node(label, NODE_COLOR)
            simpleNodeByNode.set(node, newSimpleNode)
            parentSimpleNode.children!.push(newSimpleNode)
            Object.entries(node.parameters).forEach(([parameterName, value]) => {
                let simpleSource: SimpleGraph.Node
                if (isNodeGraphInstance(value)) {
                    simpleSource = gatherNodes(value, debugMap, simpleNodeByOperator, simpleNodeByNode, simpleEdges, currOperator)
                } else {
                    simpleSource = new SimpleGraph.Node(JSON.stringify(value, undefined, 2), VALUE_COLOR)
                    parentSimpleNode.children!.push(simpleSource)
                }
                simpleEdges.push({
                    source: simpleSource,
                    target: newSimpleNode,
                    targetLabel: parameterName,
                    style: {
                        lineStyle: "solid",
                        sourceArrowStyle: "none",
                        targetArrowStyle: "standard",
                    },
                })
            })
            return newSimpleNode
        }
        const simpleNodeByNode = new Map<NodeGraph, SimpleGraph.Node>()
        const simpleEdges: SimpleGraph.Edge[] = []

        // create high level nodes for each operator
        const simpleNodeByOperator = new Map<Operator | null, SimpleGraph.Node>()
        if (generatedOperatorNodeGraphResult.debugMap) {
            generatedOperatorNodeGraphResult.debugMap.forEach((nodeGraph, operator) => {
                const simpleNode = new SimpleGraph.Node(operator?.constructor?.name ?? "Setup", OPERATOR_COLOR, [])
                simpleNodeByOperator.set(operator, simpleNode)
            })
        }

        gatherNodes(
            generatedOperatorNodeGraphResult.nodeGraph as NodeGraph,
            generatedOperatorNodeGraphResult.debugMap,
            simpleNodeByOperator,
            simpleNodeByNode,
            simpleEdges,
            null,
        )
        const graph = new SimpleGraph.Graph()
        graph.rootNodes = Array.from(simpleNodeByOperator.values())
        graph.edges = simpleEdges
        const exporter = new ExporterYed()
        const xml = exporter.export(graph)
        downloadFromMemory("node-graph.graphml", xml)
    }
}
