import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from "@angular/core"
import {HalPainterImageFinalConversion} from "@common/models/hal/common/hal-painter-image-final-conversion"
import {HalContext} from "@common/models/hal/hal-context"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {Vector2} from "@cm/lib/math/vector2"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2PaintableCanvas} from "@common/models/webgl2/webgl2-paintable-canvas"
import {createHalPaintableImage} from "@common/models/hal/hal-paintable-image/create"
import {HalPaintableImage} from "@app/common/models/hal/hal-paintable-image"

@Component({
    standalone: true,
    selector: "cm-webgl2-canvas",
    templateUrl: "./webgl2-canvas.component.html",
    styleUrls: ["./webgl2-canvas.component.scss"],
})
export class WebGl2CanvasComponent implements OnInit, OnDestroy {
    @ViewChild("webGlCanvasElement", {static: true}) webGlCanvasElementRef!: ElementRef

    readonly resized = new EventEmitter<Vector2>()

    // OnInit
    ngOnInit() {
        const canvas = this.webGlCanvasElementRef.nativeElement
        if (!canvas) {
            throw Error("Canvas not found")
        }
        this._canvas = canvas
        this._halContext = WebGl2Context.createFromCanvas(this._canvas)

        this._paintableCanvas = new WebGl2PaintableCanvas(this._halContext, this._canvas)
        this._backBufferImage = createHalPaintableImage(this._halContext)
        this._painterImageFinalConversion = HalPainterImageFinalConversion.create(this._halContext)

        this.resizeObserver = new ResizeObserver(async (entries) => {
            const entry = entries.find((entry) => entry.target === this.canvas)
            if (!entry) {
                throw Error("ResizeObserver: entry not found")
            }
            let width: number
            let height: number
            if (entry.devicePixelContentBoxSize) {
                width = entry.devicePixelContentBoxSize[0].inlineSize
                height = entry.devicePixelContentBoxSize[0].blockSize
            } else {
                width = entry.contentRect.width * devicePixelRatio
                height = entry.contentRect.height * devicePixelRatio
            }
            await this.resizeCanvasToDisplaySize(width, height)
            this.resized.emit(new Vector2(width, height))
        })
        try {
            this.resizeObserver.observe(this._canvas, {box: "device-pixel-content-box"})
        } catch (e) {
            console.warn("'device-pixel-content-box' feature not supported; try the established way (which may lead to blurry images)")
            this.resizeObserver.observe(this._canvas, {box: "content-box"})
        }
    }

    // OnDestroy
    ngOnDestroy(): void {
        this.resizeObserver?.disconnect()

        this._backBufferImage.dispose()
        this._painterImageFinalConversion.dispose()
        this._paintableCanvas.dispose()

        this._halContext.dispose()
    }

    get canvas(): HTMLCanvasElement {
        if (!this._canvas) {
            throw Error("Canvas not initialized")
        }
        return this._canvas
    }

    get halContext(): HalContext {
        if (!this._halContext) {
            throw Error("WebGlCanvas not initialized")
        }
        return this._halContext
    }

    get backBufferImage(): HalPaintableImage {
        return this._backBufferImage
    }

    async clearBackBuffer(): Promise<void> {
        await this._backBufferImage.clear({r: 0, g: 0, b: 0, a: 0})
    }

    async presentBackBuffer(convertToSRGB: boolean, gamma: number): Promise<void> {
        // blit the sRgb image to the canvas
        this._painterImageFinalConversion.convertToSrgb = convertToSRGB
        this._painterImageFinalConversion.gamma = gamma
        await this._painterImageFinalConversion.paint(this._paintableCanvas, this._backBufferImage)
        await this._halContext.flush()
    }

    private async resizeCanvasToDisplaySize(displayWidth: number, displayHeight: number) {
        const needResize = this._allocatedSize.x !== displayWidth || this._allocatedSize.y !== displayHeight
        if (!needResize) {
            return
        }
        this._allocatedSize = new Vector2(displayWidth, displayHeight)
        this.canvas.width = displayWidth
        this.canvas.height = displayHeight
        await this._backBufferImage.create(
            {
                width: this.canvas.width,
                height: this.canvas.height,
                channelLayout: "RGBA",
                format: "uint8",
            },
            {
                useMipMaps: false,
                useSRgbFormat: true,
            },
        )
    }

    private _canvas!: HTMLCanvasElement
    private _halContext!: HalContext
    private resizeObserver!: ResizeObserver
    // these are the gl elements for the linear color space framebuffer (the main render target)
    private _paintableCanvas!: HalPaintable
    private _backBufferImage!: HalPaintableImage
    private _painterImageFinalConversion!: HalPainterImageFinalConversion
    private _allocatedSize = new Vector2(0, 0)
}
