import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {blendMode, RgbMixInputs, RgbMixOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-rgb-mix-node",
    templateUrl: "./rgb-mix-node.component.html",
    styleUrls: ["./rgb-mix-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class RgbMixNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = RgbMixOutputs
    inputs = RgbMixInputs
    typeInfo = RgbMixNodeType
    settings = [blendMode]
}

export const RgbMixNodeType: MaterialNodeType<typeof RgbMixNodeComponent> = {
    id: "rgbMix",
    label: "RGB Mix",
    color: "#8d802f",
    name: "ShaderNodeMixRGB",
    inputs: [RgbMixInputs.color1, RgbMixInputs.color2, RgbMixInputs.fac],
    outputs: [RgbMixOutputs.color],
    component: RgbMixNodeComponent,
}
