// @ts-strict-ignore
import {getIOSVersion, getSafariVersion, isIoS, isIpadOS} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {Vector3} from "@common/helpers/vector-math"
import {MeshData} from "@cm/lib/geometry-processing/mesh-data"
import {MeshBVH, acceleratedRaycast, SAH} from "three-mesh-bvh"
import {IMeshGeometryAccessor, SurfacePointCoordinates, ObjectId, IDisplaySceneEvent, MeshRenderSettings} from "@cm/lib/templates/interfaces/scene-object"
import {Observable, Subject} from "rxjs"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {VertexNormalsHelper} from "three/examples/jsm/helpers/VertexNormalsHelper"
import {IMatrix4} from "@cm/lib/templates/interfaces/matrix"
import {IMaterialData} from "@cm/lib/templates/interfaces/material-data"

export let DEFAULT_FLOAT_TEXTURE_TYPE: typeof THREE.HalfFloatType | typeof THREE.FloatType = THREE.HalfFloatType

if (isIpadOS) {
    const versionFields = getSafariVersion()
    if (versionFields) {
        const [major, minor, rev] = versionFields
        if (major == 14) {
            // THREE.FloatType textures render all black on iPadOS Safari 14...
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.HalfFloatType
        } else if (major <= 13) {
            console.warn(`Enabling workaround for half-float textures on iPadOS Safari ${major}.${minor}.${rev}`)
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.FloatType
        }
    } else {
        console.warn("Can't detect iPadOS Safari version! No WebGL workarounds applied.")
    }
} else if (isIoS) {
    const versionFields = getIOSVersion()
    if (versionFields) {
        const [major, minor, rev] = versionFields
        if (major == 14) {
            // THREE.FloatType textures render all black on iOS 14...
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.HalfFloatType
        } else if (major <= 13) {
            console.warn(`Enabling workaround for half-float textures on iOS ${major}.${minor}.${rev}`)
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.FloatType
        }
    } else {
        console.warn("Can't detect iOS version! No WebGL workarounds applied.")
    }
}

THREE.Mesh.prototype.raycast = acceleratedRaycast

const DEBUG_showNormals = false
const DEBUG_showWireframe = false

export type LoadTextureDescriptor = {
    lowResURL?: string
    primaryURL: string
    freeObjectURL?: boolean
    colorSpace: THREE.ColorSpace
}

export interface ITextureManager {
    loadTexture(desc: LoadTextureDescriptor, onReady: (texture: THREE.Texture) => void, onHighResReady?: (texture: THREE.Texture) => void): THREE.Texture
    loadHDRTexture(url: string, extension: string, onReady: (texture: THREE.Texture) => void): THREE.Texture
}

export interface IMaterialManager {
    getMaterial(materialData: IMaterialData, meshRenderSettings?: MeshRenderSettings): Observable<THREE.Material>
    getDefaultMaterial(materialSlot: number): THREE.Material
    releaseMaterial(material: THREE.Material): void
}

export interface IScene extends ITextureManager {
    readonly config: {editMode?: boolean}
    readonly materialManager: IMaterialManager
    getRenderer(createIfNoViews: boolean): THREE.WebGLRenderer
    getThreeScene(): THREE.Scene
    update(): void
    deferUpdate(fn: () => void): void
    addTask(task: Observable<any>): void
    readonly sceneEvent$: Subject<IDisplaySceneEvent>
}

export abstract class ThreeObjectBase {
    abstract readonly threeObject: THREE.Object3D
    readonly threeHelperObject?: THREE.Object3D
    topLevelObjectId: ObjectId

    constructor(protected scene: IScene) {}

    set showInScene(value: boolean) {
        if (value !== this.threeObject.visible) {
            this.threeObject.visible = value
            if (this.threeHelperObject) {
                this.threeHelperObject.visible = value
            }
        }
    }

    updateTransform(transform: IMatrix4) {
        if (!transform) return false
        if (!transform.withinEpsilon(this.threeObject.matrix, 1e-6)) {
            const threeMatrix = transform.toThreeMatrix()
            if (!(threeMatrix instanceof THREE.Matrix4)) throw new Error("Expected a THREE.Matrix4")
            this.threeObject.matrix = threeMatrix
            this.threeObject.matrix.decompose(this.threeObject.position, this.threeObject.quaternion, this.threeObject.scale)
            this.scene.update()
            return true
        } else {
            return false
        }
    }

    getOutlineTokens(materialSlot: number | null): any[] {
        return [this.threeObject]
    }

    updateSamplingFrame(frame: number, maxFrame: number) {}

    dispose() {
        const disposeFn = (x: THREE.Object3D) => {
            if (x instanceof THREE.Mesh) {
                ;(x as THREE.Mesh).geometry.dispose()
                this.scene.materialManager.releaseMaterial((x as THREE.Mesh).material as THREE.Material)
            }
        }
        this.threeObject?.traverse(disposeFn)
        this.threeHelperObject?.traverse(disposeFn)
    }

    showEditHelpers(show: boolean) {}

    addEventBindings(): void {}
}

export function mapOverMeshes(obj: THREE.Mesh | THREE.Object3D, fn: (mesh: THREE.Mesh) => void) {
    if (obj instanceof THREE.Mesh) {
        fn(obj as THREE.Mesh)
    } else {
        for (const child of obj.children) {
            mapOverMeshes(child, fn)
        }
    }
}

export function disposeMaterialAndTextures(mat: THREE.Material | THREE.Material[]) {
    if (Array.isArray(mat)) {
        mat = mat as THREE.Material[]
        mat.map(disposeMaterialAndTextures)
    } else {
        mat = mat as THREE.Material
        if (mat instanceof THREE.MeshStandardMaterial) {
            const meshMat = mat as THREE.MeshStandardMaterial
            if (meshMat.map) {
                meshMat.map.dispose()
            }
        }
        mat.dispose()
    }
}

export class MeshGeometryAccessor implements IMeshGeometryAccessor {
    readonly bufferGeometry: THREE.BufferGeometry
    readonly bvh: MeshBVH
    mesh: THREE.Mesh
    private triToFaceMap: Int32Array //TODO: use Int32Array
    private faceToTriListMap: Int32Array
    private faceToTriList: Int32Array

    private constructor(
        readonly pointAttr: THREE.BufferAttribute,
        readonly normalAttr: THREE.BufferAttribute,
        readonly uvAttr: THREE.BufferAttribute,
        faceIDs: Int32Array,
    ) {
        this.bufferGeometry = new THREE.BufferGeometry()
        this.bufferGeometry.setAttribute("position", pointAttr)
        this.bufferGeometry.setAttribute("normal", normalAttr)
        this.bufferGeometry.setAttribute("uv", uvAttr)
        this.bvh = new MeshBVH(this.bufferGeometry, {strategy: SAH})
        ;(this.bufferGeometry as any).boundsTree = this.bvh
        this.mesh = new THREE.Mesh(this.bufferGeometry)
        this.mesh.matrixAutoUpdate = false

        // a double-sided material assignment is needed for BVH ray casting to work from both sides
        this.mesh.material = new THREE.MeshStandardMaterial({
            side: THREE.DoubleSide,
        })

        const numTris = pointAttr.array.length / (3 * 3)
        this.triToFaceMap = new Int32Array(numTris)
        for (let n = 0; n < numTris; n++) {
            this.triToFaceMap[n] = faceIDs[n * 3]
        }

        // defer creation of faceToTriMap until it is needed
    }

    private checkFaceToTriMap(): void {
        if (this.faceToTriListMap !== undefined) {
            return
        }
        let maxFaceID = -1
        for (const faceID of this.triToFaceMap) {
            if (faceID > maxFaceID) {
                maxFaceID = faceID
            }
        }
        const numFaces = maxFaceID + 1
        const numTris = this.triToFaceMap.length
        this.faceToTriListMap = new Int32Array(2 * numFaces) // two entries: (offset,count)
        this.faceToTriListMap.fill(0)
        this.faceToTriList = new Int32Array(numTris)
        //TODO: optimize this using array sorting
        const tmpFaceToTriListMap = new Map<number, number[]>()
        for (let triIdx = 0; triIdx < numTris; triIdx++) {
            const faceID = this.triToFaceMap[triIdx]
            let triList = tmpFaceToTriListMap.get(faceID)
            if (triList === undefined) {
                triList = []
                tmpFaceToTriListMap.set(faceID, triList)
            }
            triList.push(triIdx)
        }
        let offset = 0
        for (const [faceID, triList] of tmpFaceToTriListMap) {
            this.faceToTriListMap[faceID * 2 + 0] = offset
            this.faceToTriListMap[faceID * 2 + 1] = triList.length
            for (const triIdx of triList) {
                this.faceToTriList[offset++] = triIdx
            }
        }
    }

    static createFromMeshData(meshData: MeshData): MeshGeometryAccessor {
        return new this(
            new THREE.Float32BufferAttribute(meshData.vertices, 3),
            new THREE.Float32BufferAttribute(meshData.normals, 3),
            new THREE.Float32BufferAttribute(meshData.uvs[0], 2),
            meshData.faceIDs,
        )
    }

    public getTriangleIndexForUV(u: number, v: number): number[] {
        const triIndices: number[] = []
        const numTris = this.uvAttr.array.length / (3 * 2)
        const uvData = this.uvAttr.array as Float32Array
        let offset = 0
        for (let triIndex = 0; triIndex < numTris; triIndex++) {
            const u0 = uvData[offset + 0]
            const v0 = uvData[offset + 1]
            const u1 = uvData[offset + 2]
            const v1 = uvData[offset + 3]
            const u2 = uvData[offset + 4]
            const v2 = uvData[offset + 5]
            // check if point is in triangle
            const dX = u - u2
            const dY = v - v2
            const dX21 = u2 - u1
            const dY12 = v1 - v2
            const D = dY12 * (u0 - u2) + dX21 * (v0 - v2)
            let s = dY12 * dX + dX21 * dY
            let t = (v2 - v0) * dX + (u0 - u2) * dY
            const inside = D < 0 ? s <= 0 && t <= 0 && s + t >= D : s >= 0 && t >= 0 && s + t <= D
            if (inside) {
                const sc = 1 / D
                s *= sc
                t *= sc
                // when uv = uv0, s = 1, t = 0
                // when uv = uv1, s = 0, t = 1
                // when uv = uv2, s = 0, t = 0
                // uv = uv0*s + uv1*t + uv2*(1-s-t)
                triIndices.push(triIndex)
            }
            offset += 6
        }
        return triIndices
    }

    public interpolateTriangleNormal(pt: SurfacePointCoordinates): [number, number, number] {
        const triIndex = pt[3]
        const u = pt[4]
        const v = pt[5]
        const idx0 = triIndex * 3 + 0
        const idx1 = triIndex * 3 + 1
        const idx2 = triIndex * 3 + 2
        const normalData = this.normalAttr.array as Float32Array
        const nx0 = normalData[idx0 * 3 + 0]
        const ny0 = normalData[idx0 * 3 + 1]
        const nz0 = normalData[idx0 * 3 + 2]
        const nx1 = normalData[idx1 * 3 + 0]
        const ny1 = normalData[idx1 * 3 + 1]
        const nz1 = normalData[idx1 * 3 + 2]
        const nx2 = normalData[idx2 * 3 + 0]
        const ny2 = normalData[idx2 * 3 + 1]
        const nz2 = normalData[idx2 * 3 + 2]
        const _1_uv = 1 - u - v
        const nx = nx0 * u + nx1 * v + nx2 * _1_uv
        const ny = ny0 * u + ny1 * v + ny2 * _1_uv
        const nz = nz0 * u + nz1 * v + nz2 * _1_uv
        const vLen = Math.sqrt(nx * nx + ny * ny + nz * nz)
        return [nx / vLen, ny / vLen, nz / vLen]
    }

    public getBarycentricCoordsForPoint(triIndex: number, x: number, y: number, z: number): [number, number, number] {
        // Compute barycentric coordinates (u,v,w) for point (x,y,z) with respect to triangle (a,b,c)
        const idxA = triIndex * 3 + 0
        const idxB = triIndex * 3 + 1
        const idxC = triIndex * 3 + 2
        const attrData = this.pointAttr.array as Float32Array
        const ax = attrData[idxA * 3 + 0]
        const ay = attrData[idxA * 3 + 1]
        const az = attrData[idxA * 3 + 2]
        const bx = attrData[idxB * 3 + 0]
        const by = attrData[idxB * 3 + 1]
        const bz = attrData[idxB * 3 + 2]
        const cx = attrData[idxC * 3 + 0]
        const cy = attrData[idxC * 3 + 1]
        const cz = attrData[idxC * 3 + 2]
        const abx = bx - ax
        const aby = by - ay
        const abz = bz - az
        const acx = cx - ax
        const acy = cy - ay
        const acz = cz - az
        const apx = x - ax
        const apy = y - ay
        const apz = z - az
        const d00 = abx * abx + aby * aby + abz * abz
        const d01 = abx * acx + aby * acy + abz * acz
        const d11 = acx * acx + acy * acy + acz * acz
        const d20 = apx * abx + apy * aby + apz * abz
        const d21 = apx * acx + apy * acy + apz * acz
        const invDenom = 1 / (d00 * d11 - d01 * d01)
        const v = (d11 * d20 - d01 * d21) * invDenom
        const w = (d00 * d21 - d01 * d20) * invDenom
        const u = 1 - v - w
        return [u, v, w]
    }

    public getVerticesForTriangle(triIndex: number): [Vector3, Vector3, Vector3] {
        const idxA = triIndex * 3 + 0
        const idxB = triIndex * 3 + 1
        const idxC = triIndex * 3 + 2
        const attrData = this.pointAttr.array as Float32Array
        const ax = attrData[idxA * 3 + 0]
        const ay = attrData[idxA * 3 + 1]
        const az = attrData[idxA * 3 + 2]
        const bx = attrData[idxB * 3 + 0]
        const by = attrData[idxB * 3 + 1]
        const bz = attrData[idxB * 3 + 2]
        const cx = attrData[idxC * 3 + 0]
        const cy = attrData[idxC * 3 + 1]
        const cz = attrData[idxC * 3 + 2]

        return [new Vector3(ax, ay, az), new Vector3(bx, by, bz), new Vector3(cx, cy, cz)]
    }

    public getNormalsForTriangle(triIndex: number): [Vector3, Vector3, Vector3] {
        const idxA = triIndex * 3 + 0
        const idxB = triIndex * 3 + 1
        const idxC = triIndex * 3 + 2
        const attrData = this.normalAttr.array as Float32Array
        const ax = attrData[idxA * 3 + 0]
        const ay = attrData[idxA * 3 + 1]
        const az = attrData[idxA * 3 + 2]
        const bx = attrData[idxB * 3 + 0]
        const by = attrData[idxB * 3 + 1]
        const bz = attrData[idxB * 3 + 2]
        const cx = attrData[idxC * 3 + 0]
        const cy = attrData[idxC * 3 + 1]
        const cz = attrData[idxC * 3 + 2]

        return [new Vector3(ax, ay, az), new Vector3(bx, by, bz), new Vector3(cx, cy, cz)]
    }

    public getNeighborsForTrianglesBruteFoce(triIndices: Set<number>): Set<number> {
        const attrData = this.pointAttr.array as Float32Array

        // brute force through all tri indices of the mesh to find the ones sharing vertices
        // not sure if we can speed up the loops by moving around statements or if the compilers are smart enough already
        // TODO: replace with a smarter function when we have connectivity information accessible
        const maxDist = 1e-3
        const neighboringTriIndices = new Set<number>()
        const numTris = this.pointAttr.array.length / (3 * 3)

        // for all tris of the mesh
        for (let triIndex = 0; triIndex < numTris; triIndex++) {
            const triIndex3 = triIndex * 3
            for (let vIdx = triIndex3; vIdx < triIndex3 + 3; vIdx++) {
                const vIdx3 = vIdx * 3
                const vx = attrData[vIdx3 + 0]
                const vy = attrData[vIdx3 + 1]
                const vz = attrData[vIdx3 + 2]

                // for all provided tris for which we are searching the neighbors
                for (const targetIndex of triIndices) {
                    const targetIndex3 = targetIndex * 3
                    for (let tIdx = targetIndex3; tIdx < targetIndex3 + 3; tIdx++) {
                        const tIdx3 = tIdx * 3

                        // check if vertex is very close
                        const dist = Math.sqrt((vx - attrData[tIdx3 + 0]) ** 2 + (vy - attrData[tIdx3 + 1]) ** 2 + (vz - attrData[tIdx3 + 2]) ** 2)
                        if (dist < maxDist && !triIndices.has(triIndex)) {
                            neighboringTriIndices.add(triIndex)
                        }
                    }
                }
            }
        }

        return neighboringTriIndices
    }

    public getNeighborsForTriangles(triIndices: Set<number>): Set<number> {
        const attrData = this.pointAttr.array as Float32Array

        const neighboringTriIndices = new Set<number>()
        const maxDist = 1e-3
        const queryPoint = new THREE.Vector3(0, 0, 0)
        const tempPoint = new THREE.Vector3()

        const boundsTraverseOrder = (box: THREE.Box3) => box.distanceToPoint(queryPoint)
        const intersectsBounds = (box: THREE.Box3, isLeaf: boolean, score: number) => score < maxDist
        const intersectsTriangle = (tri: THREE.Triangle, i0: number) => {
            tri.closestPointToPoint(queryPoint, tempPoint)
            const dist = queryPoint.distanceTo(tempPoint)
            const triIndex = this.bufferGeometry.index.getX(i0) / 3
            if (dist < maxDist && !triIndices.has(triIndex)) {
                neighboringTriIndices.add(triIndex)
            }
            return false // keep going
        }

        // for all provided tris for which we are searching the neighbors
        for (const targetIndex of triIndices) {
            const targetIndex3 = targetIndex * 3
            for (let tIdx = targetIndex3; tIdx < targetIndex3 + 3; tIdx++) {
                const tIdx3 = tIdx * 3
                queryPoint.x = attrData[tIdx3 + 0]
                queryPoint.y = attrData[tIdx3 + 1]
                queryPoint.z = attrData[tIdx3 + 2]

                this.bvh.shapecast({intersectsBounds, intersectsTriangle, boundsTraverseOrder})
            }
        }

        return neighboringTriIndices
    }

    public getCoplanarNeighborsForTriangles(triIndices: Set<number>, targetTri: number): Set<number> {
        const attrData = this.pointAttr.array as Float32Array
        const neighboringTriIndices = this.getNeighborsForTriangles(triIndices)
        const coplanarNeighboringTriIndices = new Set<number>()

        //const maxDist = 1e-02; // max allowed distance of considered vertices to the target tri plane
        const maxAngleDist = 2e-3 // maximum allowed angular distance between geometric triangle normals

        const idx1 = targetTri * 3 + 0
        const idx2 = targetTri * 3 + 1
        const idx3 = targetTri * 3 + 2
        const v1 = new Vector3(attrData[idx1 * 3 + 0], attrData[idx1 * 3 + 1], attrData[idx1 * 3 + 2])
        const v2 = new Vector3(attrData[idx2 * 3 + 0], attrData[idx2 * 3 + 1], attrData[idx2 * 3 + 2])
        const v3 = new Vector3(attrData[idx3 * 3 + 0], attrData[idx3 * 3 + 1], attrData[idx3 * 3 + 2])

        const v3subv1 = v3.sub(v1)
        const v2subv1 = v2.sub(v1)

        for (const neighborIndex of neighboringTriIndices) {
            let coplanar = true

            /*
            // check coplanarity of vertices
            for (const vIdx of [neighborIndex*3 + 0, neighborIndex*3 + 1, neighborIndex*3 + 2]) {
                const v4subv1 = new Vector3(attrData[vIdx*3+0], attrData[vIdx*3+1], attrData[vIdx*3+2]).sub(v1);
                const dist = v2subv1.cross(v4subv1).dot(v3subv1);
                if (Math.abs(dist) > maxDist) coplanar = false;
            }
            */

            const nidx1 = neighborIndex * 3 + 0
            const nidx2 = neighborIndex * 3 + 1
            const nidx3 = neighborIndex * 3 + 2
            const nv1 = new Vector3(attrData[nidx1 * 3 + 0], attrData[nidx1 * 3 + 1], attrData[nidx1 * 3 + 2])
            const nv2 = new Vector3(attrData[nidx2 * 3 + 0], attrData[nidx2 * 3 + 1], attrData[nidx2 * 3 + 2])
            const nv3 = new Vector3(attrData[nidx3 * 3 + 0], attrData[nidx3 * 3 + 1], attrData[nidx3 * 3 + 2])

            const nv3subv1 = nv3.sub(nv1)
            const nv2subv1 = nv2.sub(nv1)

            // check for similar orientation of geometric normal
            const angleDist = 1.0 - v3subv1.cross(v2subv1).normalized().dot(nv3subv1.cross(nv2subv1).normalized())
            if (angleDist > maxAngleDist) coplanar = false

            if (coplanar) coplanarNeighboringTriIndices.add(neighborIndex)
        }

        return coplanarNeighboringTriIndices
    }

    public getSurfaceCoordinatesForPoint(triIndex: number, x: number, y: number, z: number): SurfacePointCoordinates {
        const baryCoords = this.getBarycentricCoordsForPoint(triIndex, x, y, z)
        return [x, y, z, triIndex, baryCoords[0], baryCoords[1]]
    }

    public getPointOnMeshFromRaycasting(x: number, y: number, z: number, dx: number, dy: number, dz: number): SurfacePointCoordinates {
        const queryPoint = new THREE.Vector3(x, y, z)
        const dirVec = new THREE.Vector3(dx, dy, dz)
        const raycaster = new THREE.Raycaster()
        const inverseMatrixWorld = this.mesh.matrixWorld.invert()
        const ray = new THREE.Ray(queryPoint, dirVec)

        //let closestHit = this.bvh.raycastFirst(this.mesh, raycaster, ray);

        let closestHit: THREE.Intersection = undefined
        const intersects: THREE.Intersection[] = this.bvh.raycast(ray, THREE.FrontSide)

        let minDistance = Infinity
        for (const intersect of intersects) {
            intersect.point.applyMatrix4(inverseMatrixWorld) // convert back result into mesh space/frame
            const dist = Math.sqrt((x - intersect.point.x) ** 2 + (y - intersect.point.y) ** 2 + (z - intersect.point.z) ** 2)
            if (dist < minDistance) {
                minDistance = dist
                closestHit = intersect
            }
        }

        if (!closestHit) return [Infinity, Infinity, Infinity, -1, 0, 0]
        const triIndex = closestHit.face.a / 3
        return this.getSurfaceCoordinatesForPoint(triIndex, closestHit.point.x, closestHit.point.y, closestHit.point.z)
    }

    public getClosestPointOnMesh(x: number, y: number, z: number): SurfacePointCoordinates {
        const queryPoint = new THREE.Vector3(x, y, z)
        const tempPoint = new THREE.Vector3()
        const closestPoint = new THREE.Vector3()
        let closestDistance = Infinity
        let closestIndexOffset: number = null
        const intersectsBounds = (box: THREE.Box3, isLeaf: boolean, score: number) => score < closestDistance
        const intersectsTriangle = (tri: THREE.Triangle, i0: number) => {
            tri.closestPointToPoint(queryPoint, tempPoint)
            const dist = queryPoint.distanceTo(tempPoint)
            if (dist < closestDistance) {
                closestIndexOffset = i0
                closestDistance = dist
                closestPoint.copy(tempPoint)
            }
            return false // keep going
        }
        const boundsTraverseOrder = (box: THREE.Box3) => box.distanceToPoint(queryPoint)
        this.bvh.shapecast({intersectsBounds, intersectsTriangle, boundsTraverseOrder})
        const triIndex = this.bufferGeometry.index.getX(closestIndexOffset) / 3
        return this.getSurfaceCoordinatesForPoint(triIndex, closestPoint.x, closestPoint.y, closestPoint.z)
    }

    public interpolateAttribute(surfacePoint: SurfacePointCoordinates, attr: THREE.BufferAttribute): number[] {
        const triIndex = surfacePoint[3]
        const idxA = triIndex * 3 + 0
        const idxB = triIndex * 3 + 1
        const idxC = triIndex * 3 + 2
        const u = surfacePoint[4]
        const v = surfacePoint[5]
        const w = 1 - (u + v)
        switch (attr.itemSize) {
            case 1:
                return [attr.getX(idxA) * u + attr.getX(idxB) * v + attr.getX(idxC) * w]
            case 2:
                return [attr.getX(idxA) * u + attr.getX(idxB) * v + attr.getX(idxC) * w, attr.getY(idxA) * u + attr.getY(idxB) * v + attr.getY(idxC) * w]
            case 3:
                return [
                    attr.getX(idxA) * u + attr.getX(idxB) * v + attr.getX(idxC) * w,
                    attr.getY(idxA) * u + attr.getY(idxB) * v + attr.getY(idxC) * w,
                    attr.getZ(idxA) * u + attr.getZ(idxB) * v + attr.getZ(idxC) * w,
                ]
            default:
                return undefined
        }
    }

    public triangleIndicesToFaceIDs(triIndices: number[]): number[] {
        const faceIDs = new Set<number>()
        for (const triIdx of triIndices) {
            const faceID = this.triToFaceMap[triIdx]
            faceIDs.add(faceID)
        }
        return Array.from(faceIDs)
    }

    public faceIDsToTriangleIndices(faceIDs: number[]): number[] {
        this.checkFaceToTriMap()
        const triIndices = new Set<number>()
        for (const faceID of faceIDs) {
            const offset = this.faceToTriListMap[faceID * 2 + 0]
            const count = this.faceToTriListMap[faceID * 2 + 1]
            for (let n = 0; n < count; n++) {
                triIndices.add(this.faceToTriList[offset + n])
            }
        }
        return Array.from(triIndices)
    }
}

export interface MeshUserData {
    threeSceneObject: ThreeObjectBase
    materialSlot: number
    triIndexOffset: number
    geometryAccessor: MeshGeometryAccessor
}

//TODO: this is a hack to speed up the configurator loading, find a nicer way to load BVH on demand
let USE_MESH_BVH = false

export function setBVHCreatedWithMesh(useBVH: boolean) {
    USE_MESH_BVH = useBVH
}

export function ensureIndexForGeometry(geometry: THREE.BufferGeometry) {
    if (!geometry.getIndex()) {
        const vertexCount = geometry.attributes.position.count
        const index = new (vertexCount > 65535 ? Uint32Array : Uint16Array)(vertexCount)
        geometry.setIndex(new THREE.BufferAttribute(index, 1))
        for (let i = 0; i < vertexCount; i++) {
            index[i] = i
        }
    }
}

/**
 * Segment the object into a single level THREE.Group based on the material names. Each part of the object which has a different material assigned to it will become a separate
 * mesh. There can be multiple separate meshes with the same material name.
 */
export function createSegmentedMesh(segmentedObject: THREE.Group, meshData: MeshData, applyFn: (mesh: THREE.Mesh, materialSlot: number) => THREE.Mesh): void {
    if (meshData.materialGroups.length > 1) {
        // One mesh has multiple materials
        for (const group of meshData.materialGroups) {
            if (group.count === 0) continue
            const pointAttr = new THREE.BufferAttribute(meshData.vertices.subarray(group.start * (3 * 3), (group.start + group.count) * (3 * 3)), 3)
            const normalAttr = new THREE.BufferAttribute(meshData.normals.subarray(group.start * (3 * 3), (group.start + group.count) * (3 * 3)), 3)
            const uvAttr = new THREE.BufferAttribute(meshData.uvs[0].subarray(group.start * (3 * 2), (group.start + group.count) * (3 * 2)), 2)
            const bufferGeometry = new THREE.BufferGeometry()
            bufferGeometry.setAttribute("position", pointAttr)
            bufferGeometry.setAttribute("normal", normalAttr)
            bufferGeometry.setAttribute("uv", uvAttr)
            for (let uvIdx = 1; uvIdx < meshData.uvs.length; uvIdx++) {
                bufferGeometry.setAttribute(
                    `uv${uvIdx + 1}`,
                    new THREE.BufferAttribute(meshData.uvs[uvIdx].subarray(group.start * (3 * 2), (group.start + group.count) * (3 * 2)), 2),
                )
            }
            ensureIndexForGeometry(bufferGeometry)
            let mesh: THREE.Mesh = new THREE.Mesh(bufferGeometry)
            mesh.matrixAutoUpdate = false
            mesh = applyFn(mesh, group.materialIndex)
            ;(mesh.userData as MeshUserData).triIndexOffset = group.start
            if (USE_MESH_BVH) {
                mesh.userData.bvh = new MeshBVH(bufferGeometry, {strategy: SAH})
                ;(bufferGeometry as any).boundsTree = mesh.userData.bvh
            }
            if (DEBUG_showWireframe) {
                const wireframe = new THREE.WireframeGeometry(bufferGeometry)
                const line = new THREE.LineSegments(wireframe)
                line.material = new THREE.LineBasicMaterial()
                line.material.depthTest = false
                line.material.opacity = 1.0
                line.material.transparent = true
                segmentedObject.add(line)
            }
            segmentedObject.add(mesh)
            if (DEBUG_showNormals) {
                var helper = new VertexNormalsHelper(mesh, 3, 0xcc4400)
                segmentedObject.add(helper)
            }
        }
    } else if (meshData.vertices.length > 0) {
        // One mesh has a single material
        const materialSlot: number | undefined = meshData.materialGroups[0].materialIndex
        if (materialSlot === undefined) {
            throw Error("Material index cannot be undefined.")
        }
        const pointAttr = new THREE.BufferAttribute(meshData.vertices, 3)
        const normalAttr = new THREE.BufferAttribute(meshData.normals, 3)
        const uvAttr = new THREE.BufferAttribute(meshData.uvs[0], 2)
        const bufferGeometry = new THREE.BufferGeometry()
        bufferGeometry.setAttribute("position", pointAttr)
        bufferGeometry.setAttribute("normal", normalAttr)
        bufferGeometry.setAttribute("uv", uvAttr)
        for (let uvIdx = 1; uvIdx < meshData.uvs.length; uvIdx++) {
            bufferGeometry.setAttribute(`uv${uvIdx + 1}`, new THREE.BufferAttribute(meshData.uvs[uvIdx], 2))
        }
        ensureIndexForGeometry(bufferGeometry)
        let mesh: THREE.Mesh = new THREE.Mesh(bufferGeometry)
        mesh.matrixAutoUpdate = false
        mesh = applyFn(mesh, materialSlot)
        ;(mesh.userData as MeshUserData).triIndexOffset = 0
        if (USE_MESH_BVH) {
            mesh.userData.bvh = new MeshBVH(bufferGeometry, {strategy: SAH})
            ;(bufferGeometry as any).boundsTree = mesh.userData.bvh
        }
        if (DEBUG_showWireframe) {
            const wireframe = new THREE.WireframeGeometry(bufferGeometry)
            const line = new THREE.LineSegments(wireframe)
            line.material = new THREE.LineBasicMaterial()
            line.material.depthTest = false
            line.material.opacity = 1.0
            line.material.transparent = true
            segmentedObject.add(line)
        }
        segmentedObject.add(mesh)
        if (DEBUG_showNormals) {
            var helper = new VertexNormalsHelper(mesh, 3, 0xcc4400)
            segmentedObject.add(helper)
        }
    }
}

export function projectBoundingBoxToScreen(box: THREE.Box3, matrix: THREE.Matrix4, camera: THREE.Camera) {
    const corners = [
        box.min,
        new THREE.Vector3(box.max.x, box.min.y, box.min.z),
        new THREE.Vector3(box.min.x, box.max.y, box.min.z),
        new THREE.Vector3(box.max.x, box.max.y, box.min.z),
        new THREE.Vector3(box.min.x, box.min.y, box.max.z),
        new THREE.Vector3(box.max.x, box.min.y, box.max.z),
        new THREE.Vector3(box.min.x, box.max.y, box.max.z),
        box.max,
    ]
    return new THREE.Box3().setFromPoints(corners.map((point) => point.applyMatrix4(matrix).project(camera)))
}

export class ColorMapMaterial extends THREE.ShaderMaterial {
    constructor(props: {map?: THREE.Texture; [key: string]: any} = {}) {
        const map = props.map
        delete props.map
        super({
            vertexShader: `
uniform float opacity;
varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`,
            fragmentShader: `
uniform float opacity;
uniform bool banded;
uniform sampler2D tex;
varying vec2 vUv;
vec4 colormap_hsv2rgb(float h, float s, float v) {
    float r = v;
    float g = v;
    float b = v;
    if (s > 0.0) {
        h *= 6.0;
        int i = int(h);
        float f = h - float(i);
        if (i == 1) {
            r *= 1.0 - s * f;
            b *= 1.0 - s;
        } else if (i == 2) {
            r *= 1.0 - s;
            b *= 1.0 - s * (1.0 - f);
        } else if (i == 3) {
            r *= 1.0 - s;
            g *= 1.0 - s * f;
        } else if (i == 4) {
            r *= 1.0 - s * (1.0 - f);
            g *= 1.0 - s;
        } else if (i == 5) {
            g *= 1.0 - s;
            b *= 1.0 - s * f;
        } else {
            g *= 1.0 - s * (1.0 - f);
            b *= 1.0 - s;
        }
    }
    return vec4(r, g, b, 1.0);
}
vec4 colormap(float x) {
    if (x < 0.0) {
        return vec4(0.0, 0.0, 0.0, 1.0);
    } else if (1.0 < x) {
        return vec4(0.0, 0.0, 0.0, 1.0);
    } else {
        float h = clamp(-9.42274071356572E-01 * x + 8.74326827903982E-01, 0.0, 1.0);
        float s = 1.0;
        float v = clamp(4.90125513855204E+00 * x + 9.18879034690780E-03, 0.0, 1.0);
        return colormap_hsv2rgb(h, s, v);
    }
}

#define TWO_PI 6.28318530718

void main()
{
    //gl_FragColor = texture2D(ditherTex, vec2(0.5,0.5));
    //gl_FragColor = vec4(vUv.x, vUv.y, 1, opacity);
    float value = texture2D(tex, vUv).r;
    float bands = banded ? (cos(value * (TWO_PI * 25.0)) > 0.0 ? 1.0 : 0.7) : 1.0;
    gl_FragColor = vec4(colormap(value).xyz * bands, value > 0.0 ? opacity : 0.0);
}
`,
            uniforms: {
                opacity: {value: props.opacity},
                banded: {value: props.banded || false},
                tex: {value: map},
            },
            ...props,
        })
    }
}

export function createCanvasAndRenderer() {
    const options: WebGLContextAttributes = {
        alpha: true,
        antialias: false,
        powerPreference: "high-performance",
    }
    const canvas = document.createElement("canvas")
    let context: WebGL2RenderingContext | WebGLRenderingContext = null
    try {
        context = canvas.getContext("webgl2", options) // may return null
    } catch (e) {
        console.warn("Failed to create WebGL2 context: ", e)
    }
    if (!context) {
        console.warn("WebGL2 not available - falling back to default renderer")
        context = canvas.getContext("webgl", options)
    }
    // const rendererInfo = context.getExtension('WEBGL_debug_renderer_info');
    // if (rendererInfo) {
    //     const gpuInfo = context.getParameter(rendererInfo.UNMASKED_RENDERER_WEBGL);
    //     console.log("GPU:", gpuInfo);
    //     //TODO: use GPU info to determine texture resolution and subdiv limits for mobile devices?
    // }
    const renderer = new THREE.WebGLRenderer({canvas, context})
    return [canvas, renderer] as const
}

export function createQuadGeometry(x0: number, y0: number, u0: number, v0: number, x1: number, y1: number, u1: number, v1: number) {
    const vertices = [x0, y0, 0, x1, y0, 0, x1, y1, 0, x0, y1, 0]
    const normals = [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1]
    const uvs = [u0, v0, u1, v0, u1, v1, u0, v1]
    const indices = [0, 1, 2, 2, 3, 0]

    const geometry = new THREE.BufferGeometry()
    geometry.setIndex(indices)
    geometry.setAttribute("position", new THREE.Float32BufferAttribute(vertices, 3))
    geometry.setAttribute("normal", new THREE.Float32BufferAttribute(normals, 3))
    geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2))

    return geometry
}

export class Vec3TransformNode extends THREENodes.TempNode {
    constructor(
        public input: THREENodes.Node,
        public matrix: THREENodes.Node,
    ) {
        super("vec3")
    }
    override generate(builder: THREENodes.NodeBuilder, output?: string | null) {
        const type = this.getNodeType(builder)

        const input = this.input.build(builder, "vec3")
        const matrix = this.matrix.build(builder, "mat4")
        const result = `((${matrix}) * vec4((${input}), 1.0)).xyz`
        return builder.format(result, type, output as THREENodes.NodeTypeOption)
    }
}
