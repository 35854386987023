// @ts-strict-ignore
import {Component, Input} from "@angular/core"
import {JSONInputComponent} from "@editor/components/json-input/json-input.component"
import {IEditor} from "@editor/models/editor"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"

@Component({
    selector: "cm-value-inspector",
    templateUrl: "./value-inspector.component.html",
    styleUrls: ["./value-inspector.component.scss"],
    standalone: true,
    imports: [InspectorSectionComponent, JSONInputComponent],
})
export class ValueInspectorComponent {
    @Input() editor: IEditor
    @Input() node: Nodes.Value | null = null
    NodeUtils = NodeUtils

    constructor() {}

    updateNode(node: Nodes.Node) {
        this.editor.sceneManager.markNodeChanged(node)
    }
}
