import {Observable} from "rxjs"
import {MeshData} from "@cm/lib/geometry-processing/mesh-data"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {clipAndOffsetMeshForDecal, WebAssemblyWorkerService} from "@app/editor/helpers/mesh-processing/mesh-processing"

export class DecalMeshDataCache {
    private cache: AsyncCacheMap<string, MeshData, [MeshData, [number, number], number, [number, number], number]>

    constructor(private workerService: WebAssemblyWorkerService) {
        this.cache = new AsyncCacheMap((key, [mesh, uvCenter, uvRotation, size, offset]) => {
            return clipAndOffsetMeshForDecal(this.workerService, mesh, uvCenter, uvRotation, size, offset)
        })
    }

    getMeshData(mesh: MeshData, uvCenter: [number, number], uvRotation: number, size: [number, number], offset: number): Observable<MeshData> {
        const key = `${mesh.id},${uvCenter[0]},${uvCenter[1]},${uvRotation},${size[0]},${size[1]},${offset}`
        return this.cache.get(key, [mesh, uvCenter, uvRotation, size, offset])
    }

    invalidate() {
        this.cache.clear()
    }
}
