<h2 mat-dialog-title>Change password</h2>
<mat-dialog-content>
    <form id="changePasswordForm" class="change-password-form" [formGroup]="changePasswordForm" novalidate (ngSubmit)="confirmAction()">
        <mat-form-field class="cm-password-field">
            <input type="password" matInput placeholder="New password" formControlName="newPasswordControl" [(ngModel)]="newPassword" />
            @if (changePasswordForm.controls.newPasswordControl.hasError("required")) {
                <mat-error> This field is <strong>required</strong> </mat-error>
            }
        </mat-form-field>
        <mat-form-field class="cm-password-field top-bottom-spacing">
            <input
                type="password"
                matInput
                placeholder="Confirm password"
                formControlName="confirmPasswordControl"
                [(ngModel)]="confirmPassword"
                [errorStateMatcher]="matcher"
            />
            @if (changePasswordForm.hasError("notSame")) {
                <mat-error> Passwords do not match </mat-error>
            }
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="cm-dialog-actions">
    <button mat-button type="submit" form="changePasswordForm" [disabled]="!changePasswordForm.valid" autofocus>Change</button>
    <button mat-button (click)="cancelAction()" autofocus>Cancel</button>
</mat-dialog-actions>
