import {computed, inject, Injectable} from "@angular/core"
import {ContentTypeModel, JobState, OrganizationType, PictureState, SystemRole} from "@api"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {Enums} from "@enums"

@Injectable({
    providedIn: "root",
})
export class PermissionsService {
    organizations = inject(OrganizationsService)
    auth = inject(AuthService)

    get isPhotographer(): boolean {
        const user = this.auth.$user()
        return !!user?.isPhotographer
    }

    $isPhotographer = computed(() => !!this.auth.$user()?.isPhotographer)

    get isStaff(): boolean {
        const user = this.auth.$user()
        return user?.isStaff || user?.isSuperuser || user?.role === SystemRole.Staff || user?.role === SystemRole.Superadmin
    }

    $isStaff = computed(() => {
        const user = this.auth.$user()
        return user?.isStaff || user?.isSuperuser || user?.role === SystemRole.Staff || user?.role === SystemRole.Superadmin
    })

    get isSuperuser(): boolean {
        const user = this.auth.$user()
        return user?.isSuperuser || user?.role === SystemRole.Superadmin
    }

    $isSuperuser = computed(() => {
        const user = this.auth.$user()
        return user?.isSuperuser || user?.role === SystemRole.Superadmin
    })

    get isLoggedIn(): boolean {
        return !!this.auth.$user()
    }

    $isLoggedIn = computed(() => !!this.auth.$user())

    get isFabricManufacturer(): boolean {
        return this.organizations.$own()?.some((organization) => organization.type === OrganizationType.FabricManufacturer) ?? false
    }
    $isFabricManufacturer = computed(
        () => this.organizations.$own()?.some((organization) => organization.type === OrganizationType.FabricManufacturer) ?? false,
    )

    // Administration
    viewFilesMenu(): boolean {
        return this.isStaff
    }

    viewUsersMenu(): boolean {
        return this.isStaff
    }

    viewCustomersMenu(): boolean {
        return this.isSuperuser
    }

    viewTagsMenu(): boolean {
        return this.isStaff || this.isFabricManufacturer
    }

    viewHdrisMenu(): boolean {
        return this.isSuperuser
    }

    viewJobsMenu(): boolean {
        return this.isSuperuser
    }

    viewTaskTypesMenu(): boolean {
        return this.isSuperuser
    }

    viewJobFarmInstancesMenu = () => this.isSuperuser
    viewJobFarmGroupsMenu = () => this.isSuperuser
    cancelJob = () => this.isStaff

    changeJobPriority = (job?: {state: JobState}) => this.isStaff && job?.state === JobState.Running

    changeProject = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    changeSet = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    changePicture = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    changeFiles = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    change3d = (organizationId: string | null = null) => this.isStaff || this.isFabricManufacturer || this.organizations.userIsMemberOf(organizationId)

    changeComments = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    rerunAllTasksInJob = () => this.isStaff

    nudgeJob = () => this.isStaff

    copyJobJsonGraph = (_organizationId: string | null = null) => !!this.isSuperuser

    deleteTaskComment = (comment?: {createdBy?: {id: string}}) => this.isStaff || this.auth.$user()?.id === comment?.createdBy?.id

    deleteComment = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    deleteFile = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    changeTasks = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    changeTaskPins = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    restartJob = (_organizationId: string | null = null) => this.isStaff

    setGalleryImage = (item: {__typename?: string; id?: string; organization?: {id: string}} | null = null) => {
        if (!(item && (this.isStaff || this.organizations.userIsMemberOf(item.__typename === ContentTypeModel.Organization ? item.id : item.organization?.id))))
            return false

        switch (item.__typename) {
            case "Asset":
            // fallthrough
            case "Material":
            case "Model":
            case "Organization":
            case "Template":
            case "User":
                return true
            default:
                return false
        }
    }

    removeGalleryImage = (item: {__typename?: string; id?: string; organization?: {id: string}} | null = null) => {
        if (!(item && (this.isStaff || this.organizations.userIsMemberOf(item.__typename === ContentTypeModel.Organization ? item.id : item.organization?.id))))
            return false

        switch (item.__typename) {
            case "Asset":
            // fallthrough
            case "Material":
            case "Model":
            case "Organization":
            case "Template":
            case "User":
                return true
            default:
                return false
        }
    }

    setCustomerLogo = (item: {__typename?: string}) => {
        switch (item.__typename) {
            case "Organization":
                return true
            default:
                return false
        }
    }

    setPdfTemplate = (item: {__typename?: string; organization?: {id: string | null}}) => {
        switch (item.__typename) {
            case "Organization":
                return this.isStaff || this.organizations.userIsMemberOf(item?.organization?.id)
            default:
                return false
        }
    }

    setCustomerFont = (item: {__typename?: string; organization?: {id: string | null}}) => {
        switch (item.__typename) {
            case "Organization":
                return this.isStaff || this.organizations.userIsMemberOf(item?.organization?.id)
            default:
                return false
        }
    }

    shareProjects = (organizationId: string | null = null) => this.isStaff || this.organizations.userIsMemberOf(organizationId)

    update = (item?: {__typename?: string; organization?: {id: string}} | null) => {
        switch (item?.__typename) {
            case "Asset":
            // fallthrough
            case "DataObject":
            case "JobTask":
            case "Material":
            case "Model":
            case "Organization":
            case "Picture":
            case "Template":
            case "Hdri":
                return this.isStaff || this.organizations.userIsMemberOf(item.organization?.id)
            default:
                return false
        }
    }

    viewExtraAssetFilters = () => this.isStaff

    viewExtraMaterialFilters = () => this.isStaff

    viewPinnedModelFilters = () => this.isStaff

    viewExtraModelFilters = () => this.isStaff

    viewExtraTemplateFilters = () => this.isStaff

    filterMaterialsByScanner = () => this.isStaff

    viewMaterialStateFilters = () => this.isFabricManufacturer

    navigateToPictures = () => !this.isPhotographer

    navigateToModel(model?: {organization?: {id: string} | null}): boolean {
        return this.isStaff || this.organizations.userIsMemberOf(model?.organization?.id)
    }

    navigateToMaterial(material: {organization?: {id: string} | null}): boolean {
        return this.isStaff || this.organizations.userIsMemberOf(material?.organization?.id)
    }

    deleteMaterialAssignment = (materialAssignment?: {material: {organization?: {id: string}}}) =>
        this.isStaff || this.organizations.userIsMemberOf(materialAssignment?.material?.organization?.id)

    attachMaterialToAsset = (asset: {organization?: {id: string}}) => this.isStaff || this.organizations.userIsMemberOf(asset?.organization?.id)

    viewTasks = (item: {organization?: {id: string}}) => this.isStaff || this.organizations.userIsMemberOf(item?.organization?.id)
    viewActivity = (_item: {organization?: {id: string}}) => this.isStaff

    editTasks = (_item?: {organization?: {id: string}}) => this.isStaff

    viewTaskVisibility = (_item?: {organization?: {id: string}}) => this.isStaff

    scan = () => this.isStaff || this.isFabricManufacturer

    editProductionTags = () => this.isStaff

    runGenericBatchOperations = () => this.isStaff
    batchUpdateAssets = () => this.isStaff
    batchUpdatePictures = () => this.isStaff
    batchUpdateMaterials = () => this.isStaff
    batchStartMaterialImageJobs = () => this.isStaff || this.isFabricManufacturer
    batchDownloadMaterials = () => this.isStaff || this.isFabricManufacturer
    batchUpdateModels = () => this.isStaff

    changeOrganization = () => this.isStaff

    viewOrganizationInCards = () => this.isStaff

    editTagName = () => this.isStaff
    changeTagType = () => this.isStaff
    changeTagOrganization = () => this.isStaff

    viewPicturesFilters = () => !this.isPhotographer

    drawOnPicture = () => this.isStaff

    deleteRevision = (picture: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(picture?.organization?.id)

    editPictureName = (picture: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(picture?.organization?.id)

    viewPictureDescription = () => this.isStaff

    viewPictureAssets = () => this.isStaff

    viewPictureRenderJobs = () => this.isStaff || this.isPhotographer

    viewPictureStatistics = () => this.isStaff

    viewPictureActivity = () => this.isStaff

    submitPicture = (item?: {state: PictureState}) => !this.isStaff && item?.state === PictureState.Draft

    movePictureAlongPipeline = (item?: {state: PictureState}) => !this.isStaff && item && Enums.InPipelineStates.Picture.includes(item.state)

    createPictureAsset = () => this.isStaff

    editModelName = (model: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(model?.organization?.id)

    viewModelTasks = () => this.isStaff

    viewModelActivity = () => this.isStaff

    viewModelFiles = (model: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(model?.organization?.id)

    viewModelComment = () => this.isStaff

    editModelComment = () => this.isStaff

    editModelArticleId = (model: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(model?.organization?.id)

    editModelDescription = (model: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(model?.organization?.id)

    showModelAssets = () => !this.isPhotographer

    viewModelTags = () => this.isStaff

    editModelTags = () => this.isStaff

    viewModelLabels = () => this.isStaff

    editModelVisibility = () => this.isStaff

    viewModelPaymentState = () => this.isStaff

    viewMaterialLabels = () => this.isStaff

    viewMaterialPaymentState = () => this.isStaff

    viewPicturePaymentState = () => this.isStaff

    viewMaterialUserAssignments = () => this.isStaff

    editMaterialName = (material: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(material?.organization?.id)

    viewMaterialState = () => this.isStaff || this.isFabricManufacturer

    editMaterialState = (material: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(material?.organization?.id))

    viewMaterialRevisions = (material: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(material?.organization?.id))

    createMaterialRevision = (material: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(material?.organization?.id))

    viewMaterialTextures = (material: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(material?.organization?.id))

    viewMaterialFiles = (material: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(material?.organization?.id))

    viewMaterialComment = () => this.isStaff

    editMaterialComment = () => this.isStaff

    editMaterialArticleId = (material: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(material?.organization?.id)

    editMaterialDescription = (material: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(material?.organization?.id)

    viewMaterialActivity = () => this.isStaff

    showMaterialAssets = () => this.isStaff || !(this.isPhotographer || this.isFabricManufacturer)

    editAssetName = (asset: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(asset?.organization?.id)

    viewAssetFiles = (asset: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(asset?.organization?.id)

    viewAssetComment = () => this.isStaff

    viewAssetTasks = (_asset: {organization?: {id?: string}}) => this.isStaff

    editAssetComment = () => this.isStaff

    editAssetArticleId = (asset: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(asset?.organization?.id)

    changeTextureSetName = () => this.isStaff || this.isFabricManufacturer

    viewScanJob = () => this.isStaff || this.isFabricManufacturer

    showScanJobDialog = () => this.isStaff

    editTextureGroupMaterial = () => this.isStaff

    updateTextureGroupUsage = () => this.isStaff

    viewTextureSets = () => this.isStaff || this.isFabricManufacturer

    viewTilingJobs = () => this.isStaff || this.isPhotographer || this.isFabricManufacturer

    editScanJob = () => this.isSuperuser

    viewScanLogs = () => !this.isFabricManufacturer

    viewScanJobFiles = () => this.isStaff

    viewMaterialTasks = () => this.isStaff

    viewMaterialInfo = () => !this.isPhotographer && !this.isFabricManufacturer

    customExportMaterial = () => this.isStaff

    editMaterial = (material: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(material?.organization?.id)

    editMaterialVisibility = () => this.isStaff
    editMaterialUsage = () => this.isStaff

    editMaterialInternalName = () => this.isStaff

    viewMaterialSampleArrival = (material: {organization?: {id?: string}}) => this.isStaff || this.organizations.userIsMemberOf(material?.organization?.id)

    editMaterialSampleArrival = () => this.isStaff

    viewMaterialIconAssignments = () => this.isStaff

    copyTemplate = (template: {organization?: {id?: string}}) =>
        this.isStaff || (this.isPhotographer && this.organizations.userIsMemberOf(template?.organization?.id))

    discardTemplateRevision = (template: {organization?: {id?: string}}) =>
        this.isStaff || (this.isPhotographer && this.organizations.userIsMemberOf(template?.organization?.id))

    editTemplateRevision = () => this.isStaff || this.isPhotographer

    editTemplateVisibility = () => this.isStaff

    editTemplateType = () => this.isStaff

    // TODO: Note that in the case of the user being a memeber of multiple organizations, the isFabricManufacturer is not well defined.
    // Instead, we should check, if the owning organization of a template is a fabric manufacturer and, if the user is a member of that organization.

    editTemplateName = (template: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(template?.organization?.id))

    viewTemplateComment = (template: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(template?.organization?.id))

    editTemplateComment = (template: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(template?.organization?.id))

    viewTemplateFiles = (template: {organization?: {id?: string}}) =>
        this.isStaff || (this.isFabricManufacturer && this.organizations.userIsMemberOf(template?.organization?.id))

    editScanSubJob = () => this.isSuperuser

    editTask = () => this.isStaff

    addCommentSilently = () => this.isStaff

    editMaterialInfo = (material: {organization?: {id: string}}) => this.isStaff || this.organizations.userIsMemberOf(material.organization?.id)

    viewAssetLabels = () => this.isStaff

    editOrganizationName = () => this.isStaff

    startMaterialJob = (option?: {organization?: {id: string}}) => this.isStaff || this.organizations.userIsMemberOf(option?.organization?.id)

    removeMaterialJob = (option?: {organization?: {id: string}}) => this.isStaff || this.organizations.userIsMemberOf(option?.organization?.id)

    viewMaterialOutputsSection = (material?: {organization?: {id: string}}) => this.isStaff || this.organizations.userIsMemberOf(material?.organization?.id)

    selectOrganization = ({organization}: {organization: {id: string}}) => this.isStaff || this.organizations.userIsMemberOf(organization.id)

    deleteItem = (item: {__typename: string; id: string; organization?: {id: string}}) =>
        this.isStaff || this.organizations.userIsMemberOf(item.organization?.id)

    editSystemRole = (user: {id: string}) => this.isSuperuser || (this.isStaff && user.id !== this.auth.$user()?.id)

    editOrganization = () => this.isStaff
}
