import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode, lodType} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {z} from "zod"
import {BuilderInlet} from "@src/templates/runtime-graph/graph-builder"
import {EvaluableTemplateNode} from "../evaluable-template-node"
import {NodeEvaluator} from "../node-evaluator"
import {GraphBuilderScope} from "../runtime-graph/graph-builder-scope"

const lodTypeParameters = z.object({
    lodType,
})
export type LodTypeParameters = z.infer<typeof lodTypeParameters>

@registerNode
export class LodType extends DeclareTemplateNode({parameters: lodTypeParameters}, {}, {nodeClass: "LodType"}) implements EvaluableTemplateNode<boolean> {
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<boolean> {
        const {templateContext} = evaluator
        return templateContext.lodType === this.parameters.lodType
    }
}

export type LodTypeFwd = TemplateNode<LodTypeParameters> & EvaluableTemplateNode<boolean>
