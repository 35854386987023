import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {color, vec3} from "@src/materials/types"
import {threeRGBColorNode, threeValueNode, threeVec3Node} from "@src/materials/three-utils"

export class TexVoronoi extends DeclareMaterialNode(
    {
        returns: z.object({distance: materialSlots, color: materialSlots, position: materialSlots}),
        inputs: z.object({
            vector: materialSlots.optional(),
            color: materialSlots.optional(),
            distance: materialSlots.optional(),
            exponent: materialSlots.optional(),
            feature: materialSlots.optional(),
            position: materialSlots.optional(),
            radius: materialSlots.optional(),
            randomness: materialSlots.optional(),
            scale: materialSlots.optional(),
            smoothness: materialSlots.optional(),
            w: materialSlots.optional(),
        }),
        parameters: z.object({
            color: color.optional(),
            distance: z.enum(["EUCLIDEAN", "MANHATTAN", "CHEBYCHEV", "MINKOWSKI"]).optional(),
            exponent: z.number().optional(),
            feature: z.enum(["F1", "F2", "SMOOTH_F1", "DISTANCE_TO_EDGE", "N_SPHERE_RADIUS"]).optional(),
            position: vec3.optional(),
            radius: z.number().optional(),
            randomness: z.number().optional(),
            scale: z.number().optional(),
            smoothness: z.number().optional(),
            w: z.number().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            console.warn("WARNING: Tex voronoi node not fully implemented!")

            return {distance: threeValueNode(0), color: threeRGBColorNode({r: 0, g: 0, b: 0}), position: threeVec3Node({x: 0, y: 0, z: 0})}
        },
    },
) {}
