import {HalContext} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalPainterImageBlit, HalPainterImageBlitOptions} from "@common/models/hal/common/hal-painter-image-blit"
import {HalPainter} from "@common/models/hal/hal-painter"

export class HalPainterImageFinalConversion implements HalPainter {
    public convertToSrgb = false
    public gamma = 1.0

    static create(context: HalContext): HalPainterImageFinalConversion {
        return new HalPainterImageFinalConversion(context)
    }

    private constructor(readonly context: HalContext) {
        this.halPainterImageBlit = HalPainterImageBlit.create(this.context, BLITTING_FUNCTION)
    }

    dispose(): void {
        this.halPainterImageBlit.dispose()
    }

    async paint(target: HalPaintable, sourceImage: HalImage, options?: HalPainterImageBlitOptions): Promise<void> {
        this.halPainterImageBlit.setParameter("u_applySrgb", {type: "int", value: this.convertToSrgb ? 1 : 0})
        this.halPainterImageBlit.setParameter("u_gamma", {type: "float", value: this.gamma})
        await this.halPainterImageBlit.paint(target, sourceImage, options)
    }

    private halPainterImageBlit: HalPainterImageBlit
}

const BLITTING_FUNCTION = `
    uniform int u_applySrgb;
    uniform float u_gamma;

    float linearToSrgb(float x) {
        if (x <= 0.0) {
            return 0.0;
        }
        else if (x >= 1.0) {
            return 1.0;
        }
        else if (x < 0.0031308) {
            return x * 12.92;
        }
        else {
            return pow(x, 1.0 / 2.4) * 1.055 - 0.055;
        }
    }

    vec4 computeColor(ivec2 targetPixel, ivec2 sourcePixel) {
        vec4 color = texelFetch0(sourcePixel);
        if (u_applySrgb != 0) {
            color.rgb = vec3(linearToSrgb(color.r), linearToSrgb(color.g), linearToSrgb(color.b));
        }
        if (u_gamma != 1.0) {
            color.rgb = pow(color.rgb, vec3(u_gamma));
        }
        return color;
    }
`
