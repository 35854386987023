import {Injectable, signal} from "@angular/core"
import {TemplateTreeItemComponent} from "@template-editor/components/template-tree-item/template-tree-item.component"
import {Subject} from "rxjs"
import {TemplateNode} from "@cm/lib/templates/types"
import {ValueSlotComponent} from "app/template-editor/components/value-slot/value-slot.component"
import {ThreeTemplateSceneViewerComponent} from "../components/three-template-scene-viewer/three-template-scene-viewer.component"
import {TemplateNodePart} from "./scene-manager.service"
import {TemplateTreeFillerComponent} from "../components/template-tree-filler/template-tree-filler.component"
import {Vector3} from "@app/common/helpers/vector-math"

export type DropPosition = "before" | "inside" | "after"

export type DraggableSource = TemplateTreeItemComponent | TemplateTreeItemComponent[] | TemplateNode
export type DroppableComponent = TemplateTreeFillerComponent | TemplateTreeItemComponent | ValueSlotComponent<TemplateNode> | ThreeTemplateSceneViewerComponent
export type TemplateDropTarget<T extends DroppableComponent> = {component: T; position: DropPosition | TemplateNodePart | Vector3}

export const getDropPositionFromPosition = (position: TemplateDropTarget<DroppableComponent>["position"]) => {
    if (typeof position === "string") return position
    else throw new Error("Expected a string")
}

export const getTemplateNodePartFromPosition = (position: TemplateDropTarget<DroppableComponent>["position"]) => {
    if (typeof position === "string" || position instanceof Vector3) throw new Error("Expected a TemplateNodePart")
    else return position
}

export const getVectorFromPosition = (position: TemplateDropTarget<DroppableComponent>["position"]) => {
    if (position instanceof Vector3) return position
    else throw new Error("Expected a Vector3")
}

@Injectable()
export class TemplateNodeDragService {
    constructor() {}

    dragSource = signal<DraggableSource | null>(null)
    dropTarget = signal<TemplateDropTarget<DroppableComponent> | null>(null)

    private draggedItem = new Subject<{dragSource: DraggableSource; dropTarget: TemplateDropTarget<DroppableComponent>}>()
    draggedItem$ = this.draggedItem.asObservable()

    dragStart(event: DragEvent, component: DraggableSource) {
        event.stopPropagation()
        this.dragSource.set(component)
    }

    drop(event: DragEvent) {
        event.stopPropagation()

        const dragSource = this.dragSource()
        const dropTarget = this.dropTarget()

        this.dragSource.set(null)
        this.dropTarget.set(null)

        if (!dragSource || !dropTarget) return

        this.draggedItem.next({dragSource, dropTarget})
    }

    dragLeave(event: DragEvent, component: DroppableComponent, dragArea: HTMLElement) {
        const dropTarget = this.dropTarget()
        if (!dropTarget) return

        if (dropTarget.component !== component) return

        const rect = dragArea.getBoundingClientRect()
        if (event.clientY < rect.top || event.clientY >= rect.bottom || event.clientX < rect.left || event.clientX >= rect.right) {
            this.dropTarget.set(null)
        }
    }

    dragEnd(event: DragEvent) {
        this.dragSource.set(null)
        this.dropTarget.set(null)
    }

    draggableSourceToTemplateNode = (source: DraggableSource): TemplateNode | undefined => {
        if (source instanceof TemplateTreeItemComponent) return source.node()
        else if (source instanceof Array) {
            if (source.length === 1) return source[0].node()
            else return undefined
        } else return source
    }
}
