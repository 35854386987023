<ng-template #overlayTemplate>
    <!-- FIXME: $event.stopPropagation() disables the use of the mousedown event in the dialog!  -->
    <div class="cm-overlay-container" (mousedown)="closeIfClean()">
        <div
            [class.cm-small-container]="dialogSize === DialogSize.Small"
            [class.cm-medium-container]="dialogSize === DialogSize.Medium"
            [class.cm-large-container]="dialogSize === DialogSize.Large"
            [class.cm-full-screen]="!dialogScroll"
            (mousedown)="$event.stopPropagation()"
            class="cm-content-container"
        >
            <ng-content select="[cm-overlay-content]"></ng-content>
        </div>
    </div>
</ng-template>
