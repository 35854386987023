import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpResize} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-resize"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const ResizeNode = declareImageOpNode(imageOpResize)

export function resize(parameters: GetParameters<Context, ParameterType>) {
    return new ResizeNode(parameters)
}
