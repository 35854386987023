import {AsyncCacheMap} from "@app/common/helpers/async-cache-map/async-cache-map"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {loadGraphForNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {Nodes} from "@cm/lib/templates/nodes/nodes"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {map, from} from "rxjs"
import {TemplateRevisionBatchApiCall} from "./template-revision-batch-api-call"

export class TemplateRevisionGraphCache extends AsyncCacheMap<number, TemplateGraph> {
    private templateRevisionBatchApiCall: TemplateRevisionBatchApiCall
    constructor(sdkService: SdkService) {
        super((id: number) => {
            return from(this.templateRevisionBatchApiCall.fetch({legacyId: id})).pipe(
                map((templateRevision) => templateRevision.graph),
                map((graphJson) => {
                    if (!graphJson) return new TemplateGraph({name: "Untitled Template", nodes: new Nodes({list: []})})
                    return loadGraphForNewTemplateSystem(graphJson)
                }),
            )
        })
        this.templateRevisionBatchApiCall = new TemplateRevisionBatchApiCall(sdkService)
    }
}
