import {Component} from "@angular/core"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {InfiniteScrollAnchorComponent} from "@common/components/data/infinite-scroll-anchor/infinite-scroll-anchor.component"

@Component({
    selector: "cm-sidebar-layout",
    standalone: true,
    imports: [EntityResponsiveSidebarComponent, InfiniteScrollAnchorComponent],
    templateUrl: "./sidebar-layout.component.html",
    styleUrl: "./sidebar-layout.component.scss",
})
export class SidebarLayoutComponent {}
