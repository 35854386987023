import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {SolverState} from "@src/templates/runtime-graph/nodes/solver/state"
import {SolverData} from "@src/templates/runtime-graph/nodes/solver/data"
import {ISceneManager, ISceneManagerNew} from "@src/templates/interfaces/scene-manager"

const TD = TypeDescriptors

const solverDescriptor = {
    state: TD.inlet(TD.Identity<SolverState>()),
    sceneManager: TD.inlet(TD.Identity<ISceneManager | ISceneManagerNew>()),
    data: TD.inlet(TD.Identity<SolverData>()),
    updateAll: TD.outlet(TD.Function<(postStep: boolean) => void>()),
}

export class Solver implements NodeClassImpl<typeof solverDescriptor, typeof Solver> {
    static descriptor = solverDescriptor
    static uniqueName = "Solver"
    state!: Inlet<SolverState>
    sceneManager!: Inlet<ISceneManager | ISceneManagerNew>
    data!: Inlet<SolverData>
    updateAll!: Outlet<(postStep: boolean) => void>

    private _state: SolverState | undefined = undefined
    private _updateAllFn: ((postStep: boolean) => void) | undefined = undefined

    run() {
        if (this.state === NotReady || this.sceneManager === NotReady || this.data === NotReady) {
            this.updateAll.emitIfChanged(NotReady)
            return
        }

        this.state.update(this.sceneManager.getConnectionSolver(), this.data)

        if (this.state !== this._state) {
            this._state = this.state
            this._updateAllFn = this.state.runSolver.bind(this.state)
        }

        if (this._updateAllFn) this.updateAll.emitIfChanged(this._updateAllFn)
    }
}
