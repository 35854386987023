import {MaterialSocket} from "@material-editor/models/material-socket"

export const HsvInputs: Record<string, MaterialSocket> = {
    hue: {paramType: "socket", id: "Hue", type: "input", valueType: "scalar", label: "Hue", defaultValue: 0.5, range: {min: 0, max: 1}},
    saturation: {paramType: "socket", id: "Saturation", type: "input", valueType: "scalar", label: "Saturation", defaultValue: 1, range: {min: 0, max: 2}},
    value: {paramType: "socket", id: "Value", type: "input", valueType: "scalar", label: "Value", defaultValue: 1, range: {min: 0, max: 2}},
    fac: {paramType: "socket", id: "Fac", type: "input", valueType: "scalar", label: "Fac", defaultValue: 1, range: {min: 0, max: 1}},
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
}

export const HsvOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
}
