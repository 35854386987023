import {Component, ViewChild} from "@angular/core"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NormalMapInputs, NormalMapOutputs} from "@material-editor/models/nodes"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-normal-map-node",
    templateUrl: "./normal-map-node.component.html",
    styleUrls: ["./normal-map-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class NormalMapNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    outputs = NormalMapOutputs
    inputs = NormalMapInputs
    typeInfo = NormalMapNodeType
}

export const NormalMapNodeType: MaterialNodeType<typeof NormalMapNodeComponent> = {
    id: "normalMap",
    label: "Normal Map",
    color: "#6e5eb2",
    name: "ShaderNodeNormalMap",
    inputs: [NormalMapInputs.image, NormalMapInputs.color],
    outputs: [NormalMapOutputs.normal],
    component: NormalMapNodeComponent,
}
