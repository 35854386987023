<cm-section>
    <ng-container cm-icon><i class="far fa-align-left"></i></ng-container>
    <ng-container cm-title>{{ $label() }}</ng-container>
    <ng-container cm-content>
        <cm-input-container>
            <ng-container cm-input>
                <cm-native-input-text-area
                    [disabled]="!$canEdit()"
                    [value]="$item().comment ?? ''"
                    [minRows]="$minRows()"
                    (valueChange)="$onChanged.emit({comment: $event})"
                    [fixValue]="false"
                >
                </cm-native-input-text-area>
            </ng-container>
        </cm-input-container>
    </ng-container>
</cm-section>
