import {map, Observable, ReplaySubject, Subject, switchMap, takeUntil} from "rxjs"
import {WebSocketMessaging} from "@common/helpers/websocket/websocket-messaging"
import {ScannerStateType} from "@platform/models/scanning/scanner-state-type"
import * as ScannerEvents from "@platform/models/scanning/scanner-events"
import * as ScannerCommands from "@platform/models/scanning/scanner-commands"
import {CaptureTestImageResult, OverviewState, ScanInfo, ScannerGlobalInfo, ScanParameters, TablePosition} from "@platform/models/scanning/types"

export class ScannerControlInterface {
    logEvent$ = new Subject<string>()
    connectionClosed$ = new Subject<void>()
    stateChangeEvent$ = new Subject<{key: string; value: ScannerStateType; replay?: boolean}>()

    private debug = false

    private readyEvent$ = new Subject<void>()

    private _connected = true
    private stateData = new Map<string, ScannerStateType>()

    private constructor(private messaging: WebSocketMessaging | null) {
        const onMessage = this.onMessage.bind(this) as (value: unknown) => void
        const onClose = this.onClose.bind(this)
        if (messaging) {
            messaging.message$.subscribe({next: onMessage, error: onClose, complete: onClose})
        }
    }

    destroy() {
        this.messaging?.destroy()
        this.logEvent$.complete()
        this.readyEvent$.complete()
        this.stateChangeEvent$.complete()
        this.connectionClosed$.complete()
    }

    static connect(url: string, logReplayFn?: (entry: string) => void) {
        return WebSocketMessaging.connect(url).pipe(
            switchMap((messaging) => {
                const scanner = new ScannerControlInterface(messaging)
                if (logReplayFn) {
                    scanner.logEvent$.pipe(takeUntil(scanner.readyEvent$)).subscribe(logReplayFn)
                }
                return scanner.readyEvent$.pipe(map(() => scanner))
            }),
        )
    }

    private onMessage(data: ScannerEvents.Event) {
        switch (data.eventType) {
            case "connect":
                break
            case "ready":
                this.readyEvent$.next()
                this.readyEvent$.complete()
                break
            case "log":
                this.logEvent$.next(data.message)
                break
            case "state":
                if (this.debug) {
                    console.log("scannerEvent", data.key, data.value)
                }
                this.stateData.set(data.key, data.value ?? null)
                this.stateChangeEvent$.next(data)
                break
            default:
                console.log("Unhandled event:", data)
                break
        }
    }

    private sendMessage(data: ScannerCommands.Command) {
        if (this.debug) {
            console.log("scannerCommand", data)
        }
        this.messaging?.send(data)
    }

    private onClose() {
        this._connected = false
        this.connectionClosed$.next()
    }

    get connected(): boolean {
        return this._connected
    }

    get taskState(): "idle" | "busy" | "error" {
        return this.stateData.get("taskState") as "idle" | "busy" | "error"
    }

    get taskMessage(): string | undefined {
        return (this.stateData.get("taskMessage") ?? undefined) as string | undefined
    }

    get taskProgress(): number | undefined {
        return (this.stateData.get("taskProgress") ?? undefined) as number | undefined
    }

    get globalInfo(): ScannerGlobalInfo {
        return this.stateData.get("globalInfo") as ScannerGlobalInfo
    }

    get overviewState(): OverviewState | undefined {
        return (this.stateData.get("overviewState") ?? undefined) as OverviewState | undefined
    }

    get scanParameters(): ScanParameters {
        return this.stateData.get("scanParameters") as ScanParameters
    }

    set scanParameters(params: ScanParameters) {
        this.stateData.set("scanParameters", params)
        this.sendMessage({eventType: "setScanParameters", params})
    }

    get scanInfo(): ScanInfo {
        return this.stateData.get("scanInfo") as ScanInfo
    }

    private setupResultSubject(key: string, ignoreNull = true): Subject<ScannerStateType> {
        const res$ = new ReplaySubject<ScannerStateType>(1)
        this.stateChangeEvent$.subscribe({
            next: (event) => {
                if (event.value == null && ignoreNull) return
                if (event.key == key && !event.replay) {
                    res$.next(event.value)
                    res$.complete()
                }
            },
            error: (err) => res$.error(err),
            complete: () => res$.complete(),
        })
        return res$
    }

    startOverviewScan(regionSize?: ScannerCommands.StartOverviewScan["regionSize"]): void {
        this.sendMessage({eventType: "startOverviewScan", regionSize: regionSize ?? undefined})
    }

    startScan(): void {
        this.sendMessage({eventType: "startScan"})
    }

    captureTestImage(): Observable<CaptureTestImageResult> {
        const result$ = this.setupResultSubject("testImageResult")
        this.sendMessage({eventType: "captureTestImage"})
        return result$ as Observable<CaptureTestImageResult>
    }

    calibrateTable(useExisting: boolean): void {
        this.sendMessage({eventType: "calibrateTable", useExisting})
    }

    moveTable(position: TablePosition): void {
        this.sendMessage({eventType: "moveTable", position})
    }

    cancel(): void {
        this.sendMessage({eventType: "cancel"})
    }
}
