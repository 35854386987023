import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpGetImageDesc} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const GetImageDescNode = declareImageOpNode(imageOpGetImageDesc)

export function getImageDesc(parameters: GetParameters<Context, ParameterType>) {
    return new GetImageDescNode(parameters)
}
