<h2 mat-dialog-title>Transform</h2>
<mat-dialog-content>
    <form id="transformForm" class="cm-form" (submit)="confirmAction()">
        @if (position) {
            <cm-inspector-section>
                <ng-container cm-title> Position </ng-container>
                <ng-container cm-content>
                    <div class="cm-xyz">
                        <cm-input-container>
                            <ng-container cm-inline-label> X: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="position.x" [decimalPlaces]="3"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                        <cm-input-container>
                            <ng-container cm-inline-label> Y: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="position.y" [decimalPlaces]="3"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                        <cm-input-container>
                            <ng-container cm-inline-label> Z: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="position.z" [decimalPlaces]="3"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    </div>
                </ng-container>
            </cm-inspector-section>
        }
        @if (rotation) {
            <cm-inspector-section>
                <ng-container cm-title> Rotation </ng-container>
                <ng-container cm-content>
                    <div class="cm-xyz">
                        <cm-input-container>
                            <ng-container cm-inline-label> X: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="rotation.x" [decimalPlaces]="3" [convert]="convertRotation"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                        <cm-input-container>
                            <ng-container cm-inline-label> Y: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="rotation.y" [decimalPlaces]="3" [convert]="convertRotation"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                        <cm-input-container>
                            <ng-container cm-inline-label> Z: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="rotation.z" [decimalPlaces]="3" [convert]="convertRotation"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    </div>
                </ng-container>
            </cm-inspector-section>
        }
        @if (scale) {
            <cm-inspector-section>
                <ng-container cm-title> Scale </ng-container>
                <ng-container cm-content>
                    <div class="cm-xyz">
                        <cm-input-container>
                            <ng-container cm-inline-label> X: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="scale.x" [decimalPlaces]="3"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                        <cm-input-container>
                            <ng-container cm-inline-label> Y: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="scale.y" [decimalPlaces]="3"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                        <cm-input-container>
                            <ng-container cm-inline-label> Z: </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="scale.z" [decimalPlaces]="3"> </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    </div>
                </ng-container>
            </cm-inspector-section>
        }
    </form>
    <mat-checkbox [(ngModel)]="use3dsMaxCoordSys">3dsMax coordinate system</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button form="transformForm">Confirm</button>
    <button mat-button (click)="cancelAction()" autofocus>Cancel</button>
</mat-dialog-actions>
