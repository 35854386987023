import {Component, inject, Inject, ViewChild} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RenameDialogComponent} from "@common/components/dialogs/rename-dialog/rename-dialog.component"
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTable, MatTableModule} from "@angular/material/table"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {MomentModule} from "ngx-moment"
import {MaterialRevisionsHistoryDialogMaterialRevisionFragment, ContentTypeModel} from "@api"
import {IsNonNull} from "@cm/lib/utils/filter"
import {RefreshService} from "@app/common/services/refresh/refresh.service"

@Component({
    selector: "cm-material-revisions-history-dialog",
    templateUrl: "./material-revisions-history-dialog.component.html",
    styleUrls: ["./material-revisions-history-dialog.component.scss"],
    standalone: true,
    imports: [MatTableModule, MomentModule, MatTooltipModule],
})
export class MaterialRevisionsHistoryDialogComponent {
    @ViewChild("revisionTable", {static: true}) revisionTable?: MatTable<MaterialRevisionsHistoryDialogMaterialRevisionFragment>
    displayedColumns = ["revision", "author", "date", "corona", "cycles", "graph-schema", "comment"]

    material = {revisions: [] as MaterialRevisionsHistoryDialogMaterialRevisionFragment[]}
    notifications = inject(NotificationsService)
    sdk = inject(SdkService)
    refresh = inject(RefreshService)

    constructor(
        public dialogRef: MatDialogRef<RenameDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {material: {id: string}},
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
    ) {
        this.fetchRevisions()
    }

    async fetchRevisions() {
        const result = await this.sdk.gql.materialRevisionsHistoryDialogMaterialRevision({
            materialId: this.data.material.id,
        })
        this.material = {revisions: result.materialRevisions.filter(IsNonNull)}
    }

    getMatFileUrl(revision: MaterialRevisionsHistoryDialogMaterialRevisionFragment): string {
        if (!revision.matFileDataObject) throw Error("No matFileDataObject")
        return revision.matFileDataObject.downloadUrl
    }

    deleteRevision(revision: MaterialRevisionsHistoryDialogMaterialRevisionFragment) {
        const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Delete revision",
                message: "The material revision will be deleted. " + "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: "Delete revision",
                cancelLabel: "Cancel",
            },
        })
        dialogRef.afterClosed().subscribe(async (confirmed) => {
            if (!confirmed) {
                return
            }
            await this.notifications.withUserFeedback(
                async () => {
                    await this.sdk.throwable.materialRevisionsHistoryDialogDeleteMaterialRevision({
                        materialRevisionId: revision.id,
                    })
                    await this.fetchRevisions()
                    this.refresh.item({...this.data.material, __typename: ContentTypeModel.Material})
                    this.revisionTable?.renderRows()
                },
                {success: "Revision deleted successfully", error: "Cannot delete revision"},
            )
        })
    }
}
