import * as THREENodes from "three/examples/jsm/nodes/Nodes"

// see https://blog.selfshadow.com/publications/blending-in-detail/
const blendNormalMapsNode = new THREENodes.FunctionNode(`
vec3 blendNormalMaps(vec3 n1, vec3 n2)
{
    vec3 t = n1*vec3( 2.,  2., 2.) + vec3(-1., -1.,  0.);
    vec3 u = n2*vec3(-2., -2., 2.) + vec3( 1.,  1., -1.);
    return ((t*dot(t, u) - u*t.z) + 1.0) * 0.5;
}
`)

export class NormalMapBlendNode extends THREENodes.TempNode {
    constructor(
        public n1: THREENodes.Node,
        public n2: THREENodes.Node,
    ) {
        super("vec3")
    }

    override generate(builder: THREENodes.NodeBuilder) {
        const type = this.getNodeType(builder)

        return THREENodes.call(blendNormalMapsNode, {
            n1: this.n1,
            n2: this.n2,
        }).build(builder, type)
    }
}
