<div class="cm-title-section-back-button-container">
    <button class="cm-section-icon-back cm-simple-window-header-button" (click)="onClosed.emit()">
        <i class="fas fa-chevron-left"></i>
    </button>
</div>
@if ($iconClass(); as iconClass) {
    <i class="cm-icon" [class]="iconClass"></i>
}
@if ($isLoaded()) {
    <ng-content select="[cm-pretitle]"></ng-content>
    <input
        class="cm-input"
        #inputField
        (change)="onChanged.emit(inputField.value)"
        [value]="$title() ?? '-'"
        [disabled]="!$canEdit()"
        [placeholder]="$placeholder()"
    />
} @else {
    <cm-placeholder class="cm-title-placeholder" [dark]="true"></cm-placeholder>
}
