import {map, Subscription} from "rxjs"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {ISceneManager} from "@src/templates/interfaces/scene-manager"
import {IMaterial} from "@src/templates/interfaces/material-data"

const TD = TypeDescriptors

const findMaterialDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    customerId: TD.inlet(TD.Nullable(TD.Number)),
    articleId: TD.inlet(TD.Nullable(TD.String)),
    material: TD.outlet(TD.Nullable(TD.Identity<IMaterial>("id"))),
}

export class FindMaterial implements NodeClassImpl<typeof findMaterialDescriptor, typeof FindMaterial> {
    static descriptor = findMaterialDescriptor
    static uniqueName = "FindMaterial"
    sceneManager!: Inlet<ISceneManager>
    customerId!: Inlet<number | null>
    articleId!: Inlet<string | null>
    material!: Outlet<IMaterial | null>

    private _pending: Subscription | null = null

    run() {
        if (this.sceneManager === NotReady || this.articleId === NotReady || this.customerId === NotReady) {
            this.material.emitIfChanged(NotReady)
            return
        }
        this._pending?.unsubscribe()
        this._pending = null
        this.material.emitIfChanged(NotReady)
        if (!(this.articleId != null && this.sceneManager)) return
        //TODO: query articleId

        this._pending = this.sceneManager.addTask(
            this.sceneManager.findMaterial(this.articleId, this.customerId).pipe(
                map((material) => {
                    this._pending = null
                    this.material.emitIfChanged(material)
                }),
            ),
        )
    }

    cleanup() {
        this._pending?.unsubscribe()
    }
}
