<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> {{ class() }} </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'id'" [label]="'ID'" [validate]="isValidExternalId"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Value </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'node'" [label]="'Value'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
