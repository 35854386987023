// @ts-strict-ignore
import {Observable} from "rxjs"
import {MeshData, MeshDataGraph} from "@cm/lib/geometry-processing/mesh-data"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {createTaskOperator} from "@common/helpers/tasks/observable-task-manager"

export {WebAssemblyWorkerService}

export function decompressMesh(
    workerService: WebAssemblyWorkerService,
    graph: MeshDataGraph,
    buffer: ArrayBuffer,
    subdivisionLevel: number,
): Observable<MeshData> {
    return workerService
        .invokeFunction<MeshData>("invokeWASMMeshFunction", "decompressDracoFile", [graph, buffer, subdivisionLevel], [buffer])
        .pipe(createTaskOperator("decompressMesh"))
}

export function compressMeshGLTF(
    workerService: WebAssemblyWorkerService,
    positionAttr: Float32Array,
    normalAttr: Float32Array,
    uvAttr: Float32Array,
    bitDepth: number,
): Observable<Uint8Array> {
    return workerService
        .invokeFunction<Uint8Array>("invokeWASMFunction", "compressDracoFileGLTF", [positionAttr, normalAttr, uvAttr, bitDepth])
        .pipe(createTaskOperator("compressMesh"))
}

export function convertOBJToDracoAndPLY(workerService: WebAssemblyWorkerService, buffer: ArrayBuffer, resolution: number): Observable<any[]> {
    return workerService
        .invokeFunction<any[]>("invokeWASMFunction", "convertOBJToDracoAndPLY", [buffer, resolution])
        .pipe(createTaskOperator("convertOBJToDracoAndPLY"))
}

export function convertPLYToDraco(workerService: WebAssemblyWorkerService, buffer: ArrayBuffer, resolution: number): Observable<any> {
    return workerService.invokeFunction<any>("invokeWASMFunction", "convertPLYToDraco", [buffer, resolution]).pipe(createTaskOperator("convertPLYToDraco"))
}

export function evaluateProceduralGeometry(workerService: WebAssemblyWorkerService, graph: MeshDataGraph): Observable<MeshData> {
    return workerService
        .invokeFunction<MeshData>("invokeJSFunction", "evaluateProceduralGeometry", [graph])
        .pipe(createTaskOperator("evaluateProceduralGeometry"))
}

export function clipAndOffsetMeshForDecal(
    workerService: WebAssemblyWorkerService,
    mesh: MeshData,
    uvCenter: [number, number],
    uvRotation: number,
    size: [number, number],
    offset: number,
): Observable<MeshData> {
    return workerService
        .invokeFunction<MeshData>("invokeJSFunction", "clipAndOffsetMeshForDecal", [mesh, uvCenter, uvRotation, size, offset])
        .pipe(createTaskOperator("clipAndOffsetMeshForDecal"))
}
