<template #operatorPanelContainer></template>
@if (operator && !operator.locked) {
    @if (showApplyToSelection) {
        <cm-inspector-section>
            <ng-container cm-title> Apply to</ng-container>
            <ng-container cm-content>
                @for (textureType of availableTextureTypes; track textureType) {
                    <cm-toggle [toggle]="shouldApplyTo(textureType)" (toggleChange)="setShouldApplyTo(textureType, $event)" [disabled]="disabled">
                        <ng-container cm-text
                            ><span [class.cm-disabled]="disabled">{{ descriptorByTextureType(textureType).label }}</span></ng-container
                        >
                    </cm-toggle>
                }
            </ng-container>
        </cm-inspector-section>
    }
}
