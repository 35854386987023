<cm-three-template-scene-provider>
    <div class="cm-main-card">
        <div class="cm-title">
            <div class="cm-title-section">
                <i class="far fa-bars cm-main-menu" [matMenuTriggerFor]="menu"></i>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openInOldEditor()">Open in old editor</button>
                </mat-menu>
                <span class="cm-label-text">{{ title() ?? templateGraph().parameters.name }}</span>
                <cm-button
                    class="cm-save"
                    (click)="save()"
                    [class.cm-disabled]="!localSceneManagerService.$templateGraphModified()"
                    [class.cm-active]="localSceneManagerService.$templateGraphModified()"
                >
                    <ng-container cm-left-icon>
                        <i class="far fa-save"></i>
                    </ng-container>
                    <ng-container cm-text> Save </ng-container>
                </cm-button>
                <div
                    class="cm-icon-button"
                    (click)="localSceneManagerService.undo()"
                    [class.cm-disabled]="!localSceneManagerService.$canUndo()"
                    matTooltip="Undo Changes"
                >
                    <i class="far fa-rotate-left"></i>
                </div>
                <div
                    class="cm-icon-button"
                    (click)="localSceneManagerService.redo()"
                    [class.cm-disabled]="!localSceneManagerService.$canRedo()"
                    matTooltip="Redo Changes"
                >
                    <i class="far fa-rotate-right"></i>
                </div>
            </div>
            @if (templateViewType() === "edit") {
                <div class="cm-title-section">
                    <cm-template-add> </cm-template-add>
                </div>
            }
            <div class="cm-title-section">
                <cm-template-view-type-selector
                    [viewType]="templateViewType()"
                    (viewTypeChanged)="templateViewType.set($event)"
                ></cm-template-view-type-selector>
            </div>
        </div>
        <div class="cm-left-panel-container">
            <cm-template-tree-view-type-selector
                [viewType]="templateTreeViewType()"
                (viewTypeChanged)="templateTreeViewType.set($event)"
            ></cm-template-tree-view-type-selector>
            @switch (templateTreeViewType()) {
                @case ("tree") {
                    <cm-template-tree (requestSaveInLibrary)="requestSaveInLibrary.emit($event)" class="cm-template-tree"></cm-template-tree>
                }
                @case ("selectVariation") {
                    <cm-template-select-variation></cm-template-select-variation>
                }
                @case ("allVariations") {
                    <cm-template-all-variations class="cm-all-variations" [sceneManagerService]="localSceneManagerService"></cm-template-all-variations>
                }
            }
        </div>
        <div class="cm-inspector-area">
            <cm-template-inspector
                [transformMode]="localSceneManagerService.$transformMode()"
                (transformModeChanged)="localSceneManagerService.$transformMode.set($event)"
                #inspector
            ></cm-template-inspector>
        </div>
        <div class="cm-viewer-section">
            @switch (templateViewType()) {
                @case ("edit") {
                    <div class="cm-viewer-container">
                        <cm-three-template-scene-viewer [camera]="camera()"></cm-three-template-scene-viewer>
                        <cm-template-scene-viewer-options
                            class="cm-editor-settings-panel"
                            [selectedCameraId]="selectedCameraId()"
                            (selectedCameraIdChanged)="selectedCameraId.set($event)"
                        ></cm-template-scene-viewer-options>
                        @if (localSceneManagerService.$pendingTasks(); as pendingTasks) {
                            <div class="cm-progress-panel">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>{{ pendingTasks }}
                                {{ pendingTasks > 1 ? "Tasks" : "Task" }}
                                remaining
                            </div>
                        }
                    </div>
                }
                @case ("image") {
                    <cm-template-image-viewer
                        [sceneManagerService]="localSceneManagerService"
                        [viewType]="templateTreeViewType()"
                        (viewTypeChanged)="templateTreeViewType.set($event)"
                    ></cm-template-image-viewer>
                }
                @case ("configurator") {
                    <cm-ar-configurator-actions
                        [templateId]="templateId()"
                        [configuratorUrlParams]="localSceneManagerService.$configuratorUrlParams()"
                        [templateGraph]="localSceneManagerService.$templateGraph()"
                        [parameters]="localSceneManagerService.$instanceParameters()"
                    ></cm-ar-configurator-actions>
                }
            }
        </div>
    </div>
</cm-three-template-scene-provider>
