import {Component, HostBinding, Input, OnChanges, SimpleChanges} from "@angular/core"

@Component({
    standalone: true,
    selector: "cm-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnChanges {
    @Input() disabled = false

    @HostBinding("style.pointer-events")
    pointerEvents = "inherit"

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.disabled) {
            this.pointerEvents = this.disabled ? "none" : "inherit"
        }
    }
}
