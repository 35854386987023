import {Component, EventEmitter, Input, Output} from "@angular/core"
import {ScreenSizes} from "@material-editor/models/screen-sizes"

@Component({
    standalone: true,
    selector: "cm-title-bar",
    templateUrl: "./title-bar.component.html",
    styleUrls: ["./title-bar.component.scss"],
})
export class TitleBarComponent {
    screenSizes: typeof ScreenSizes = ScreenSizes
    @Input("screenSize") screenSize = ScreenSizes.mediumScreen
    @Output("screenSizeChange") screenSizeChange = new EventEmitter<ScreenSizes>()

    constructor() {}

    screenSizeUpdate(value: ScreenSizes) {
        this.screenSize = value
        this.screenSizeChange.emit(value)
    }
}
