// @ts-strict-ignore
import {IMaterialGraphManager} from "@cm/lib/materials/material-node-graph"
import {SyncTaskSet} from "@legacy/helpers/utils"
import {mapLegacyDataObjectToImageResource} from "@app/templates/legacy/material-node-graph"
import {AsyncCache} from "@common/helpers/async-cache-map/async-cache-map"
import {MaterialConverter, MaterialConverterOptions} from "@editor/helpers/scene/three-proxies/material-converter"
import {disposeMaterialAndTextures, IMaterialManager, ITextureManager} from "@editor/helpers/scene/three-proxies/utils"
import {Settings} from "@common/models/settings/settings"
import {firstValueFrom, from, map, Observable, of as observableOf, ReplaySubject} from "rxjs"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {MeshRenderSettings} from "@cm/lib/templates/interfaces/scene-object"
import {IMaterialData, keyForMaterialData} from "@cm/lib/templates/interfaces/material-data"

export function createStandardMaterial(materialSlot: number): THREE.Material {
    const material = new THREENodes.MeshPhysicalNodeMaterial({})

    material.colorNode = THREENodes.uniform(new THREE.Color(Settings.colorPalette[materialSlot % Settings.colorPalette.length]))
    material.roughnessNode = THREENodes.uniform(0.8)
    material.metalnessNode = THREENodes.uniform(0.0)

    material.name = `default-${materialSlot}`

    material.side = THREE.FrontSide
    return material
}

export function createWireframeMaterial(): THREE.MeshBasicMaterial {
    const material = new THREE.MeshBasicMaterial()
    material.wireframe = true
    material.color.set(0x00008b)
    return material
}

type ThreeMaterialData = {
    material: THREE.Material
    exclusiveTextures: THREE.Texture[]
}

type MaterialMapKey = number | string
type MaterialMapEntry = {
    key: MaterialMapKey
    refCount: number
    threeMaterialData: ThreeMaterialData
}

export interface IDataTextureManager {
    createDataTexture(data: BufferSource, width: number, height: number, format: THREE.PixelFormat, type: THREE.TextureDataType): THREE.DataTexture
}

class MaterialDataTextureManager implements IDataTextureManager {
    constructor() {}

    createDataTexture(data: BufferSource, width: number, height: number, format: THREE.PixelFormat, type: THREE.TextureDataType): THREE.DataTexture {
        const texture = new THREE.DataTexture(data, width, height, format, type)
        this.textures.push(texture)
        return texture
    }

    textures: THREE.Texture[] = []
}

const DEBUG_FORCE_DEFAULT_MATERIALS = false
const DEBUG_FORCE_WIREFRAME_MATERIALS = false

export class MaterialManager implements IMaterialManager {
    private materialMap = new AsyncCache<MaterialMapKey, MaterialMapEntry>()
    private retainedMap = new Map<THREE.Material, MaterialMapEntry>()
    private gcSet = new Set<MaterialMapEntry>()
    private tasks = new SyncTaskSet()
    options: MaterialConverterOptions = {}

    constructor(
        readonly textureManager: ITextureManager,
        readonly materialGraphManager: IMaterialGraphManager,
        private compileHook?: (mat: THREE.Material) => THREE.Material,
        private materialModifier?: (material: THREE.Material) => void,
    ) {}

    applyMaterialModifierOnCachedMaterials(): void {
        if (!this.materialModifier) return

        for (const materialMapEntry of this.materialMap.cached.values()) {
            const {material} = materialMapEntry.threeMaterialData
            this.materialModifier(material)
            material.needsUpdate = true
        }
    }

    getMaterial(materialData: IMaterialData, meshRenderSettings?: MeshRenderSettings): Observable<THREE.Material> {
        if (DEBUG_FORCE_WIREFRAME_MATERIALS) return observableOf(this.getWireframeMaterial())
        if (DEBUG_FORCE_DEFAULT_MATERIALS) return observableOf(this.getDefaultMaterial(0))
        let key = keyForMaterialData(materialData)
        if (meshRenderSettings && meshRenderSettings.displacementDataObject) {
            key =
                key +
                ` disp:${meshRenderSettings.displacementDataObject.id},${meshRenderSettings.displacementUvChannel},${meshRenderSettings.displacementMin},${meshRenderSettings.displacementMax}`
        }

        const task = async () => {
            // TODO remove this once fetching of mesh related data also migrated to the new API
            if (meshRenderSettings?.displacementDataObject) {
                meshRenderSettings.displacementImageResource = mapLegacyDataObjectToImageResource(meshRenderSettings.displacementDataObject)
            }
            const material = await firstValueFrom(this.buildMaterialForMaterialData(materialData, new MaterialDataTextureManager(), meshRenderSettings))

            if (this.compileHook) this.compileHook(material)

            return {
                key,
                refCount: 0,
                threeMaterialData: {
                    material,
                    exclusiveTextures: [],
                },
            } as MaterialMapEntry
        }

        return this.retainMaterialAsync(
            this.materialMap.getOrFetch(key, () => {
                return this.tasks.add(from(task()))
            }),
        )
    }

    getDefaultMaterial(materialSlot: number): THREE.Material {
        if (this.materialMap.cached.has(materialSlot)) {
            return this.materialMap.cached.get(materialSlot).threeMaterialData.material
        } else {
            const entry: MaterialMapEntry = {
                key: materialSlot,
                refCount: 0,
                threeMaterialData: {
                    material: createStandardMaterial(materialSlot),
                    exclusiveTextures: [],
                },
            }
            if (this.compileHook) {
                this.compileHook(entry.threeMaterialData.material)
            }
            this.materialMap.set(materialSlot, entry)
            return this.retainMaterial(entry)
        }
    }

    getWireframeMaterial(): THREE.Material {
        const key = `wireframe`
        if (this.materialMap.cached.has(key)) {
            return this.materialMap.cached.get(key).threeMaterialData.material
        } else {
            const entry: MaterialMapEntry = {
                key,
                refCount: 0,
                threeMaterialData: {
                    material: createWireframeMaterial(),
                    exclusiveTextures: [],
                },
            }
            if (this.compileHook) {
                this.compileHook(entry.threeMaterialData.material)
            }
            this.materialMap.set(key, entry)
            return this.retainMaterial(entry)
        }
    }

    private retainMaterial(entry: MaterialMapEntry): THREE.Material {
        const material = entry.threeMaterialData.material
        if (++entry.refCount === 1) {
            // this is the first time the material has been retained
            this.retainedMap.set(material, entry)
        }
        return material
    }

    private retainMaterialAsync(pendingEntry: Observable<MaterialMapEntry>): Observable<THREE.Material> {
        return pendingEntry.pipe(map((entry) => this.retainMaterial(entry)))
    }

    releaseMaterial(material: THREE.Material): void {
        const entry = this.retainedMap.get(material)
        if (entry) {
            if (--entry.refCount <= 0) {
                this.gcSet.add(entry)
            }
        } else if (material) {
            // material was not retained, so dispose it immediately
            disposeMaterialAndTextures(material)
        }
    }

    collectGarbage() {
        for (const entry of this.gcSet) {
            if (entry.refCount <= 0) {
                this.materialMap.delete(entry.key)
                this.retainedMap.delete(entry.threeMaterialData.material)
                this.disposeThreeMaterialData(entry.threeMaterialData)
            }
        }
        this.gcSet.clear()
    }

    private disposeThreeMaterialData(materialData: ThreeMaterialData) {
        disposeMaterialAndTextures(materialData.material)
        materialData.exclusiveTextures.forEach((texture) => {
            texture.dispose()
            console.log("Disposed three material exclusive data texture")
        })
    }

    dispose() {
        for (const [key, entry] of this.materialMap.cached) {
            this.disposeThreeMaterialData(entry.threeMaterialData)
        }
        this.materialMap.clear()
        this.retainedMap.clear()
        this.gcSet.clear()
    }

    sync(): Observable<void> {
        return this.tasks.sync()
    }

    private buildMaterialForMaterialData(
        materialData: IMaterialData,
        dataTextureManager: IDataTextureManager,
        meshRenderSettings?: MeshRenderSettings,
    ): Observable<THREE.Material> {
        const enableDisplacement = false
        if (materialData.materialGraph) {
            const material$ = new ReplaySubject<THREE.Material>(1)
            const onLoadComplete = (material: THREE.Material) => {
                switch (materialData.side) {
                    case "front":
                        material.side = THREE.FrontSide
                        break
                    case "back":
                        material.side = THREE.BackSide
                        break
                    case "double":
                        material.side = THREE.DoubleSide
                        break
                }
                material.name = materialData.name
                material$.next(material)
                material$.complete()
            }
            MaterialConverter.evaluateNodeGraph(
                {
                    materialData,
                    meshDisplacementImageResource: meshRenderSettings?.displacementImageResource,
                    meshDisplacementUVChannel: meshRenderSettings?.displacementUvChannel,
                    meshDisplacementScale:
                        meshRenderSettings?.displacementMax !== undefined ? meshRenderSettings.displacementMax - meshRenderSettings.displacementMin : undefined,
                    textureManager: this.textureManager,
                    dataTextureManager: dataTextureManager,
                    options: this.options,
                    materialModifier: this.materialModifier,
                },
                onLoadComplete,
            )
            return material$
        } else {
            const material = new THREENodes.MeshPhysicalNodeMaterial({})
            material.colorNode = THREENodes.uniform(new THREE.Color(0xaaaaaa))
            material.roughnessNode = THREENodes.uniform(0.8)
            material.metalnessNode = THREENodes.uniform(0.0)
            material.name = `material-nodata`
            material.side = THREE.FrontSide
            return observableOf(material)
        }
    }
}
