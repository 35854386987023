import {EventEmitter} from "@angular/core"
import * as paper from "paper"
import {Subject, takeUntil} from "rxjs"
import {Vector2Like} from "@cm/lib/math/vector2"
import {assertNever} from "@cm/lib/utils/utils"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class RectangleRegion extends CanvasBaseToolboxItemBase {
    readonly changed = new EventEmitter<void>()

    public static readonly labelGroupName = "LabelsGroup"
    public static readonly rectangleRegionGroupName = "RectangleRegionGroup"

    readonly regionModified = new Subject<void>()
    private editingRegion = false
    private mouseDownPoint = new paper.Point(0, 0)
    private mouseDownRectangle = [0, 0, 0, 0]

    protected readonly regionGroup = new paper.Group()
    protected readonly labelGroup = new paper.Group()
    protected readonly rectanglePath: paper.Path.Rectangle

    protected baseFontSize = 28
    protected baseFontStrokeWidth = 1
    protected fontStrokeColor = new paper.Color("black")
    protected labelOffset: number = this.baseFontSize / 2 + 5
    protected widthLabel!: paper.PointText
    protected heightLabel!: paper.PointText

    private minSize = new paper.Size(10, 10)

    private editMode = EditMode.None

    constructor(parent: CanvasBaseToolboxItem, x = 0, y = 0, width = 500, height = 500) {
        super(parent)
        this.hitTestOptions.match = (hitResult: paper.HitResult): boolean => hitResult.item === this.rectanglePath // Only match the actual rectangle, so we do not react to labels and such
        this.regionGroup.name = RectangleRegion.rectangleRegionGroupName
        this.labelGroup.name = RectangleRegion.labelGroupName
        this.rectanglePath = new paper.Path.Rectangle(new paper.Point([x, y]), new paper.Size([width, height]))
        this.rectanglePath.data.canvasObject = this
        this.rectanglePath.strokeWidth = 1.5
        this.rectanglePath.strokeScaling = false
        this.rectanglePath.strokeColor = new paper.Color("whitesmoke")
        this.rectanglePath.fillColor = new paper.Color("whitesmoke")
        this.rectanglePath.fillColor.alpha = 0.15
        this.rectanglePath.selectedColor = new paper.Color("#40c4ff")
        this.regionGroup.addChild(this.rectanglePath)
        this.regionGroup.addChild(this.labelGroup)
        this.initLabels()
        this.visibleChange.subscribe((visible) => this.setVisible(visible))
        this.selectedChange.subscribe((selected) => (this.rectanglePath.selected = selected))
        this.viewChange.subscribe(() => this.updateLabels())
        this.parentItem!.physicalInfoChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.updateLabels())
    }

    private initLabels(): void {
        this.widthLabel = new paper.PointText(new paper.Point(0, 0))
        this.widthLabel.fillColor = new paper.Color("whitesmoke")
        this.widthLabel.strokeColor = this.fontStrokeColor
        this.widthLabel.strokeWidth = this.baseFontStrokeWidth
        this.widthLabel.justification = "center"
        this.widthLabel.content = "Width label"
        this.widthLabel.fontSize = this.baseFontSize
        this.widthLabel.fontFamily = "Roboto"
        this.widthLabel.fontWeight = "Bold"
        this.labelGroup.addChild(this.widthLabel)

        this.heightLabel = new paper.PointText(new paper.Point(0, 0))
        this.heightLabel.rotate(-90)
        this.heightLabel.fillColor = this.widthLabel.fillColor
        this.heightLabel.justification = this.widthLabel.justification
        this.heightLabel.content = "Height label"
        this.heightLabel.fontSize = this.widthLabel.fontSize
        this.heightLabel.fontFamily = this.widthLabel.fontFamily
        this.heightLabel.fontWeight = this.widthLabel.fontWeight
        this.heightLabel.strokeColor = this.widthLabel.strokeColor
        this.labelGroup.addChild(this.heightLabel)

        this.updateLabels()
    }

    protected updateLabels(): void {
        this.updateWidthLabel()
        this.updateHeightLabel()
    }

    private updateWidthLabel(): void {
        const width: number = this.rectanglePath.segments[2].point.x - this.rectanglePath.segments[0].point.x
        const widthPosition: paper.Point = new paper.Point(this.rectanglePath.segments[0].point.x + width / 2, this.rectanglePath.segments[1].point.y)
        widthPosition.y -= this.labelOffset / this.zoomLevel

        this.widthLabel.content = this.getLabel(width)
        this.widthLabel.fontSize = this.baseFontSize / this.zoomLevel
        this.widthLabel.strokeWidth = this.baseFontStrokeWidth / this.zoomLevel
        this.widthLabel.position = widthPosition
        this.widthLabel.visible = this.visible && this.widthLabel.bounds.width <= 0.9 * width
    }

    private updateHeightLabel(): void {
        const height: number = this.rectanglePath.segments[0].point.y - this.rectanglePath.segments[1].point.y
        const heightPosition: paper.Point = new paper.Point(this.rectanglePath.segments[0].point.x, this.rectanglePath.segments[1].point.y + height / 2)
        heightPosition.x -= this.labelOffset / this.zoomLevel

        this.heightLabel.content = this.getLabel(height)
        this.heightLabel.fontSize = this.baseFontSize / this.zoomLevel
        this.heightLabel.strokeWidth = this.baseFontStrokeWidth / this.zoomLevel
        this.heightLabel.position = heightPosition
        this.heightLabel.visible = this.visible && this.heightLabel.bounds.height <= 0.9 * height
    }

    private setVisible(value: boolean) {
        this.rectanglePath.visible = value
        this.widthLabel.visible = value
        this.heightLabel.visible = value
    }

    override remove(): void {
        super.remove()
        this.regionGroup.remove()
        this.regionModified.complete()
    }

    get width(): number {
        const [x1, _y1, x2, _y2] = this.getRect()
        return x2 - x1
    }

    get height(): number {
        const [_x1, y1, _x2, y2] = this.getRect()
        return y2 - y1
    }

    setRect(x1: number, y1: number, x2: number, y2: number): void {
        this.rectanglePath.segments[0].point.x = x1
        this.rectanglePath.segments[0].point.y = y2
        this.rectanglePath.segments[1].point.x = x1
        this.rectanglePath.segments[1].point.y = y1
        this.rectanglePath.segments[2].point.x = x2
        this.rectanglePath.segments[2].point.y = y1
        this.rectanglePath.segments[3].point.x = x2
        this.rectanglePath.segments[3].point.y = y2
        this.updateLabels()
        this.changed.emit()
    }

    getRect(): [number, number, number, number] {
        return [
            this.rectanglePath.segments[0].point.x,
            this.rectanglePath.segments[1].point.y,
            this.rectanglePath.segments[2].point.x,
            this.rectanglePath.segments[3].point.y,
        ]
    }

    override onMouseDown(event: paper.ToolEvent): boolean {
        this.mouseDownPoint = event.point
        this.mouseDownRectangle = this.getRect()
        return false
    }

    override onMouseUp(_event: paper.ToolEvent): boolean {
        this.handleRegionEditFinished()
        return false
    }

    override onMouseDrag(event: paper.ToolEvent): boolean {
        this.handleEdit(event)
        return false
    }

    private handleEdit(event: paper.ToolEvent): void {
        if (this.editMode === EditMode.None || this.editMode === EditMode.Select) {
            return
        }
        const mouseDelta = event.point.subtract(this.mouseDownPoint)
        // const [x1, y1, x2, y2] = this.getRect()
        // let rectMin = new paper.Point(x1, y1)
        // let rectMax = new paper.Point(x2, y2)
        let rectMin = new paper.Point(this.mouseDownRectangle[0], this.mouseDownRectangle[1])
        let rectMax = new paper.Point(this.mouseDownRectangle[2], this.mouseDownRectangle[3])
        const rectSize = rectMax.subtract(rectMin)
        const canvasMin = this.canvasBounds.min
        const canvasMax = this.canvasBounds.max
        switch (this.editMode) {
            case EditMode.Move:
                rectMin = paper.Point.max(canvasMin, paper.Point.min(canvasMax.sub(rectSize), rectMin.add(mouseDelta)))
                rectMax = paper.Point.max(canvasMin.add(rectSize), paper.Point.min(canvasMax, rectMax.add(mouseDelta)))
                break
            case EditMode.ResizeW:
                rectMin = new paper.Point(Math.max(canvasMin.x, Math.min(rectMax.x - this.minSize.width, rectMin.x + mouseDelta.x)), rectMin.y)
                break
            case EditMode.ResizeN:
                rectMin = new paper.Point(rectMin.x, Math.max(canvasMin.y, Math.min(rectMax.y - this.minSize.height, rectMin.y + mouseDelta.y)))
                break
            case EditMode.ResizeE:
                rectMax = new paper.Point(Math.max(rectMin.x + this.minSize.width, Math.min(canvasMax.x, rectMax.x + mouseDelta.x)), rectMax.y)
                break
            case EditMode.ResizeS:
                rectMax = new paper.Point(rectMax.x, Math.max(rectMin.y + this.minSize.height, Math.min(canvasMax.y, rectMax.y + mouseDelta.y)))
                break
            case EditMode.ResizeNW:
                rectMin = new paper.Point(
                    Math.max(canvasMin.x, Math.min(rectMax.x - this.minSize.width, rectMin.x + mouseDelta.x)),
                    Math.max(canvasMin.y, Math.min(rectMax.y - this.minSize.height, rectMin.y + mouseDelta.y)),
                )
                break
            case EditMode.ResizeNE:
                rectMin = new paper.Point(rectMin.x, Math.max(canvasMin.y, Math.min(rectMax.y - this.minSize.height, rectMin.y + mouseDelta.y)))
                rectMax = new paper.Point(Math.max(rectMin.x + this.minSize.width, Math.min(canvasMax.x, rectMax.x + mouseDelta.x)), rectMax.y)
                break
            case EditMode.ResizeSW:
                rectMin = new paper.Point(Math.max(canvasMin.x, Math.min(rectMax.x - this.minSize.width, rectMin.x + mouseDelta.x)), rectMin.y)
                rectMax = new paper.Point(rectMax.x, Math.max(rectMin.y + this.minSize.height, Math.min(canvasMax.y, rectMax.y + mouseDelta.y)))
                break
            case EditMode.ResizeSE:
                rectMax = new paper.Point(
                    Math.max(rectMin.x + this.minSize.width, Math.min(canvasMax.x, rectMax.x + mouseDelta.x)),
                    Math.max(rectMin.y + this.minSize.height, Math.min(canvasMax.y, rectMax.y + mouseDelta.y)),
                )
                break
        }
        this.setRect(Math.round(rectMin.x), Math.round(rectMin.y), Math.round(rectMax.x), Math.round(rectMax.y))
        this.editingRegion = true
    }

    private handleRegionEditFinished(): void {
        if (this.editingRegion) {
            this.editingRegion = false
            this.regionModified.next()
        }
    }

    override hitTest(point: Vector2Like): boolean {
        if (this.disabled) {
            return false
        }
        if (!this.editingRegion) {
            // only allow edit mode switching when not currently editing
            const hitResult = this.paperHitTest(point)
            this.editMode = this.getEditModeByHitResult(hitResult)
        }
        if (this.editMode !== EditMode.None) {
            this.cursor = this.getCursorByEditMode(this.editMode)
            return true
        }
        return false
    }

    protected getEditModeByHitResult(hitResult: paper.HitResult | null): EditMode {
        if (!this.disabled && hitResult) {
            if (hitResult.type === "stroke") {
                switch (hitResult.location.index) {
                    case 0:
                        return EditMode.ResizeW
                    case 1:
                        return EditMode.ResizeN
                    case 2:
                        return EditMode.ResizeE
                    case 3:
                        return EditMode.ResizeS
                }
            } else if (hitResult.type === "segment") {
                switch (hitResult.segment.index) {
                    case 0:
                        return EditMode.ResizeSW
                    case 1:
                        return EditMode.ResizeNW
                    case 2:
                        return EditMode.ResizeNE
                    case 3:
                        return EditMode.ResizeSE
                }
            } else if (hitResult.type === "fill") {
                return EditMode.Move
            }
        }
        return EditMode.None
    }

    private getCursorByEditMode(editMode: EditMode): string {
        switch (editMode) {
            case EditMode.None:
            case EditMode.Select:
                return "default"
            case EditMode.Move:
                return "move"
            case EditMode.ResizeW:
            case EditMode.ResizeE:
                return "ew-resize"
            case EditMode.ResizeN:
            case EditMode.ResizeS:
                return "ns-resize"
            case EditMode.ResizeNE:
            case EditMode.ResizeSW:
                return "nesw-resize"
            case EditMode.ResizeNW:
            case EditMode.ResizeSE:
                return "nwse-resize"
            default:
                assertNever(editMode)
        }
    }

    protected getLabel(pixels: number): string {
        if (this.physicalInfo) {
            const cm: number = pixels / this.physicalInfo.pixelsPerCm
            return `${cm.toFixed(2)} cm (${pixels} px)`
        } else {
            return `${pixels} px`
        }
    }
}

export enum EditMode {
    None = "None",
    Select = "Select",
    Move = "Move",
    ResizeNW = "ResizeNW",
    ResizeN = "ResizeN",
    ResizeNE = "ResizeNE",
    ResizeW = "ResizeW",
    ResizeE = "ResizeE",
    ResizeSW = "ResizeSW",
    ResizeS = "ResizeS",
    ResizeSE = "ResizeSE",
}
