import {NodeGraph, SerializedNodeGraph, TraversalAction, deserializeNodeGraph} from "@cm/lib/graph-system/node-graph"
import {unwrapInterfaceId} from "@cm/lib/templates/interface-descriptors"
import {FlattenPriceList} from "@cm/lib/pricing/nodes/flatten-pricelist"
import {ConfigurationGroupNode} from "@cm/lib/pricing/nodes/configuration-group-node"
import {NamedConfiguratorVariant, PricingContext, PricingNodegraph} from "@cm/lib/pricing/nodes/core"
import {PriceList} from "@cm/lib/pricing/nodes/price-list"
import {PricedItemNode} from "@cm/lib/pricing/nodes/priced-item-node"
import {PriceContainer} from "@cm/lib/pricing/nodes/price-container"
import * as SimpleGraph from "@platform/helpers/simple-graph/simple-graph"
import {ConditionalAmountGroupNode} from "@cm/lib/pricing/nodes/conditional-amount-group-node"
import {ConditionalAmountNode} from "@cm/lib/pricing/nodes/conditional-amount-node"
import {PricingNode} from "@cm/lib/pricing/declare-pricing-node"

export class PriceGraphAccessor {
    rootNode: FlattenPriceList
    configurationGroupnodes: ConfigurationGroupNode[] = []

    constructor(rootNode: FlattenPriceList) {
        this.rootNode = rootNode
        this.updateConfigurationGroupNodes()
    }

    updateConfigurationGroupNodes() {
        this.configurationGroupnodes = this.rootNode.getNodesOfType(ConfigurationGroupNode)
        for (const curConfigGroupNode of this.configurationGroupnodes) curConfigGroupNode.setCurrentVariantId("")
    }

    /**It is required to always set all configurations. Updating only e.g. the configration that was clicked does not work, as this can hide other configurations that need to be unset. */
    setCurrentConfigurations(configVariants: NamedConfiguratorVariant[]) {
        this.configurationGroupnodes.forEach((node) => {
            const variant = configVariants.find((variant) => unwrapInterfaceId(variant.groupId)[1] === node.parameters.groupId)
            if (variant === undefined) node.setCurrentVariantId("")
            else node.setCurrentVariantId(variant.variantId)
        })
    }
}

export class PriceGraphBuilder {
    configurationGroupnodes: ConfigurationGroupNode[] = []
    amountGroupNodes: ConditionalAmountGroupNode[] = []
    rootNode: FlattenPriceList
    subPrices: PriceList

    constructor(_name: string) {
        this.subPrices = new PriceList({list: []})
        this.rootNode = new FlattenPriceList({subprices: this.subPrices})
    }

    initFromSerialized(serializedGraph: SerializedNodeGraph) {
        const deserialized = deserializeNodeGraph(serializedGraph)
        if (!(deserialized instanceof FlattenPriceList)) throw new Error(`Deserialized node is not a FlattenPriceList`)
        this.rootNode = deserialized
        this.subPrices = this.rootNode.getSubprices()
        this.configurationGroupnodes = this.rootNode.getNodesOfType(ConfigurationGroupNode)
        this.amountGroupNodes = this.rootNode.getNodesOfType(ConditionalAmountGroupNode)
        for (const curConfigGroupNode of this.configurationGroupnodes) curConfigGroupNode.setCurrentVariantId("")
    }

    addAmountGroupNode(amountGroupNode: ConditionalAmountGroupNode) {
        if (this.amountGroupNodes.includes(amountGroupNode)) throw new Error(`AmountGroupNode ${amountGroupNode} already exists`)
        this.amountGroupNodes.push(amountGroupNode)
    }

    getNodesForDisplay() {
        let result: (PricingNodegraph | ConditionalAmountGroupNode)[] = this.getSubprices()
        result = result.concat(this.amountGroupNodes)
        return result
    }

    getSubprices(): PricingNodegraph[] {
        return this.subPrices.parameters.list
    }

    addSubPriceGraph(pricedItemnode: PricedItemNode | PriceContainer) {
        this.subPrices.addEntry(pricedItemnode)
    }

    /**This function allows to delete a very specific node configuration (resulting from Darran's catalog):
     * This catalog has options that have a price. In some cases, the options are only used as container for suboptions.
     * In this case, it is not possible to assign a meaningful condition to the node. This function can be used
     * to remove those nodes but keeping all of the subprices. The subprices are then added to the parent of the deleted node.
     * An example are the premium PET colors of "Dove". An example where this option must not be deleted is the cushion of "Dove".
     */
    removeDummyPriceNode(node: PricingNode) {
        if (!(node instanceof PricedItemNode)) throw new Error(`Node ${node} is not a PriceContainer`)

        if (node.parents.size !== 1)
            throw new Error(`PricedItemNode ${node.getDescription()} has ${node.parents.size} parents. A dummy price node must have exactly one parent.`)

        const parent = node.parents.values().next().value
        if (!(parent instanceof PriceList)) throw new Error(`Parent of PricedItemNode ${node.getDescription()} is not a PriceList`)

        let subPricesOfDeletedNode: PriceList | undefined = undefined
        for (const child of node.children) {
            if (child instanceof PriceList) {
                if (subPricesOfDeletedNode !== undefined) throw new Error(`PricedItemNode ${node.getDescription()} has more than one child PriceList`)
                subPricesOfDeletedNode = child
            }
        }
        if (subPricesOfDeletedNode === undefined) throw new Error(`PricedItemNode ${node.getDescription()} has no child PriceList`)

        parent.removeEntry(node)

        for (const subPrice of subPricesOfDeletedNode.parameters.list) {
            if (!(subPrice instanceof PricedItemNode) && !(subPrice instanceof PriceContainer))
                throw new Error(`PriceList has a child that is neither a PricedItemNode nor a PriceContainer`)
            parent.addEntry(subPrice)
        }
    }

    async addDependency(priceNode: PricedItemNode | ConditionalAmountNode | PriceContainer, configVariant: NamedConfiguratorVariant) {
        const configGroupId = configVariant.groupId
        const configVariantId = configVariant.variantId

        let configGroupNode = this.configurationGroupnodes.find((node) => node.parameters.groupId === configGroupId)
        if (configGroupNode === undefined) {
            const newConfigGroupNode = new ConfigurationGroupNode({groupId: configGroupId, currentVariantId: configVariantId, allVariantIds: undefined})
            this.configurationGroupnodes.push(newConfigGroupNode)
            configGroupNode = newConfigGroupNode
        }

        await priceNode.addDependency(configGroupNode, configVariantId)
    }

    getSubgraph(uniqueId: string): PricedItemNode | PriceContainer | undefined {
        let result: PricedItemNode | PriceContainer | undefined = undefined

        const collectExistingNodes = (node: NodeGraph<unknown, PricingContext>) => {
            if (node instanceof PricedItemNode || node instanceof PriceContainer) {
                const curUniqueId = node.getUniqueId()
                if (curUniqueId === uniqueId) {
                    result = node
                    return TraversalAction.StopCompletely
                }
            }
            return TraversalAction.Continue
        }

        this.rootNode.depthFirstTraversalPreorder(collectExistingNodes)

        return result
    }

    transformToSimpleGraph(): SimpleGraph.Graph {
        const simpleGraph = new SimpleGraph.Graph()
        const nodeMap = new Map<PricingNode, SimpleGraph.Node>()
        const processedEdges = new Set<string>()
        const nodeCounterMap = new Map<PricingNode, number>()
        let nodeIdCounter = 0

        const addSimpleNode = (node: PricingNode) => {
            const simpleNodeChildren: SimpleGraph.Node[] | undefined = node instanceof PricedItemNode ? ([] as SimpleGraph.Node[]) : undefined
            const simpleNode = new SimpleGraph.Node(node.getNodeLabel(), undefined, simpleNodeChildren)
            nodeMap.set(node, simpleNode)
            nodeCounterMap.set(node, nodeIdCounter++)

            if (node instanceof PricedItemNode) simpleGraph.rootNodes.push(simpleNode)

            if (node instanceof PriceList) {
                if (node.parents.size !== 1) throw new Error(`PriceList has ${node.parents.size} parents. PriceList should have exactly one parent.`)
                const parent = node.parents.values().next().value
                const parentSimpleNode = nodeMap.get(parent)
                parentSimpleNode!.children!.push(simpleNode)
            }
        }

        this.rootNode.depthFirstTraversalPreorder((node: NodeGraph<unknown, PricingContext>) => {
            const pricingNode = node as PricingNode
            if (!nodeMap.has(pricingNode)) addSimpleNode(pricingNode)

            const currentSimpleNode = nodeMap.get(pricingNode)!

            pricingNode.children.forEach((node: NodeGraph<unknown, PricingContext>) => {
                const child = node as PricingNode
                if (!nodeMap.has(child)) addSimpleNode(child)

                const skipEdge = child instanceof PriceList && pricingNode instanceof PricedItemNode
                const childSimpleNode = nodeMap.get(child)!
                const edgeIdentifier = `${nodeCounterMap.get(pricingNode)}-${nodeCounterMap.get(child)}`

                if (!skipEdge && !processedEdges.has(edgeIdentifier)) {
                    processedEdges.add(edgeIdentifier)
                    const edgeStyle = new SimpleGraph.EdgeStyle("solid", "none", "standard")
                    const edge = new SimpleGraph.Edge(currentSimpleNode, childSimpleNode, edgeStyle)
                    simpleGraph.edges.push(edge)
                }
            })

            return TraversalAction.Continue
        })

        return simpleGraph
    }
}
