import {EventEmitter, inject, Injectable} from "@angular/core"
import {jsonToFile} from "@legacy/helpers/utils"
import {TextureType} from "generated/graphql"
import {Observable} from "rxjs"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {TilingInput, tilingTask} from "@cm/lib/job-task/tiling"
import {graphToJson} from "@cm/lib/utils/graph-json"
import * as AutoTilingNodes from "app/textures/texture-editor/operator-stack/operators/auto-tiling/service/auto-tiling-nodes"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {descriptorByTextureType, textureTypes} from "@app/textures/utils/texture-type-descriptor"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"

@Injectable({
    providedIn: "root",
})
export class AutoTilingService {
    readonly alignmentDataReceived = new EventEmitter<AutoTilingNodes.AlignmentData>()
    readonly patternHintsReceived = new EventEmitter<AutoTilingNodes.PatternHints>()

    processing?: IScanProcessing
    tilingGraph: AutoTilingNodes.Node | null = null
    isBusy = false

    upload = inject(UploadGqlService)

    constructor(
        private sdk: SdkService,
        private texturesApi: TexturesApiService,
    ) {}

    async getTextureRevisionDataObjectId(textureRevisionId: string) {
        return this.sdk.gql.autoTilingGetTextureRevisionDataObjectId({textureRevisionId}).then((result) => result.textureRevision.dataObject.id)
    }

    async createTilingTask(tilingGraph: AutoTilingNodes.Node, organizationId: number) {
        const file: File = jsonToFile(graphToJson(tilingGraph), "tilingGraph.json")

        const renderGraphFile = await this.upload.createAndUploadDataObject(
            file,
            {
                mediaType: "application/json",
                organizationLegacyId: organizationId,
            },
            {
                showUploadToolbar: false,
            },
        )
        const input: TilingInput = {graph: JobNodes.dataObjectReference(renderGraphFile.legacyId)}
        return JobNodes.task(tilingTask, {input: JobNodes.value(input)})
    }

    async buildTilingGraph(
        organizationLegacyId: number,
        mode: Mode,
        tilingParams: Params,
        tilingArea: Area | undefined,
        tilingHints: Hint[],
        pxPerCm: number,
        sourceMapDataObjectIds: Map<TextureType, string>,
    ) {
        const filterMapTypes: number[] = []
        if (tilingParams.filterRadius <= 1e-3) {
            // no filtering
        } else if (tilingParams.filterMode === "normal") {
            filterMapTypes.push(descriptorByTextureType(TextureType.Normal).legacyEnumId)
        } else if (tilingParams.filterMode === "allExceptDiffuse") {
            for (const textureType of textureTypes) {
                if (textureType !== TextureType.Diffuse) {
                    filterMapTypes.push(descriptorByTextureType(textureType).legacyEnumId)
                }
            }
        } else if (tilingParams.filterMode === "all") {
            for (const textureType of textureTypes) {
                filterMapTypes.push(descriptorByTextureType(textureType).legacyEnumId)
            }
        }

        const dataObjectIdByTextureType: {
            [textureTypeId: number]: number | undefined
        } = {}
        for (const [textureType, dataObjectId] of sourceMapDataObjectIds.entries()) {
            const dataObjectLegacyId = await this.texturesApi.getDataObjectLegacyId(dataObjectId)
            const textureTypeId = descriptorByTextureType(textureType as TextureType).legacyEnumId
            dataObjectIdByTextureType[textureTypeId] = dataObjectLegacyId
        }
        let maps: AutoTilingNodes.Maps = {
            type: "textureSet",
            pxPerCm,
            dataObjectIdByTextureType,
        }

        if (tilingArea) {
            maps = {
                type: "crop",
                maps: maps,
                region: tilingArea,
            }
        }

        const features: AutoTilingNodes.Features = {
            type: "extractFeatures",
            maps: maps,
            weighting: tilingParams.featureWeighting,
            // components: this.tilingPatternType === 'small_fft' ? 3 : undefined,
            normalize: tilingParams.patternType === "small_fft" ? true : undefined,
        }

        const markers: MarkerCoordinates[] = tilingHints.map((hint) => {
            return [hint.x1, hint.y1, hint.x2, hint.y2, hint.radius]
        })
        const pattern: AutoTilingNodes.Pattern = {
            type: "patternHints",
            markers: markers,
        }

        let alignment: AutoTilingNodes.Alignment

        if (tilingParams.patternType === "large") {
            alignment = {
                type: "edgeAlignment",
                maps: maps,
                features: features,
                pattern: pattern,
                alignIndividualMaps: tilingParams.alignIndividualMaps,
                edgeAlignmentSmoothingIterations: tilingParams.edgeAlignmentSmoothingIterations,
                edgeAlignmentWindowSize_mm: tilingParams.edgeAlignmentWindowSize * 10,
            }
        } else if (tilingParams.patternType === "small_fft") {
            alignment = {
                type: "fftAlignment",
                maps: maps,
                features: features,
                pattern: {
                    type: "patternHints",
                    markers: markers,
                },
            }
        } else {
            alignment = {
                type: "basicAlignment",
                maps: maps,
                features: features,
                border_mm: tilingParams.blendingBorder / 16, //TODO: correct scaling
            }
        }

        let graph: AutoTilingNodes.Node

        if (mode == "detection") {
            graph = {
                type: "largePatternDetection",
                features: features,
                hints: pattern?.markers?.length > 0 ? pattern : undefined,
            }
        } else if (mode == "alignment") {
            graph = alignment
        } else {
            graph = {
                customer: organizationLegacyId,
                processingMode: tilingParams.processingMode,
                type: "tiling",
                maps: maps,
                features: features,
                alignment: alignment,
                blendingBorder: tilingParams.blendingBorder,
                maxBlendingRadius: tilingParams.maxBlendingRadius,
                gradientCorrection: tilingParams.gradientCorrection,
                filterMapTypes,
                filterRadius_mm: tilingParams.filterRadius * 10,
            }
        }
        return graph
    }
}

export interface IScanProcessing {
    destroy(): void

    run(inputJson: unknown): Observable<void>
}

type MarkerCoordinates = [x1: number, y1: number, x2: number, y2: number, radius: number]

export type Params = {
    processingMode: ProcessingMode
    blendingBorder: number
    maxBlendingRadius: number
    patternType: PatternType
    featureWeighting: FeatureWeighting
    gradientCorrection: boolean
    filterMode: FilterMode
    filterRadius: number
    alignIndividualMaps: boolean
    edgeAlignmentSmoothingIterations: number
    edgeAlignmentWindowSize: number
}

export type Mode = "detection" | "alignment" | "tiling"

export type PatternType = "no_pattern" | "small" | "small_fft" | "large"

export type FeatureWeighting = "diffuse" | "normal" | "normal_diffuse"

export type FilterMode = "normal" | "allExceptDiffuse" | "all"

export type ProcessingMode = "local" | "cloud"

export type Area = {
    topLeftX: number
    topLeftY: number
    width: number
    height: number
}

export type Hint = {
    x1: number
    y1: number
    x2: number
    y2: number
    radius: number
}
