import {Component, computed, input, output} from "@angular/core"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {PricingNode} from "@cm/lib/pricing/declare-pricing-node"
import {PricingContext} from "@cm/lib/pricing/nodes/core"
import {PricedItemNode} from "@cm/lib/pricing/nodes/priced-item-node"
import {VariantConditionNodeComponent} from "@pricing/components/node-visualizers/variant-condition-node/variant-condition-node.component"

@Component({
    standalone: true,
    selector: "cm-priced-item",
    templateUrl: "./priced-item-node.component.html",
    styleUrls: ["./priced-item-node.component.scss", "../node-styles.scss"],
    imports: [VariantConditionNodeComponent],
})
export class PricedItemNodeComponent {
    $node = input.required<PricingNode>()

    $pricedItemNode = computed<PricedItemNode | undefined>(() => {
        const node = this.$node()
        if (node instanceof PricedItemNode) return node
        else return undefined
    })

    $description = computed<string>(() => {
        const node = this.$pricedItemNode()
        if (node) return node.getDescription() + ": " + node.getPrice(this.$pricingContext())
        else return ""
    })

    $pricingContext = input.required<PricingContext>()

    $conditionRemoved = output<void>()

    constructor(public configMenuService: ConfigMenuLegacyService) {}
}
