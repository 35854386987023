import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const getFieldDescriptor = {
    input: inletDescriptor<any>({deepCompare: 1}),
    key: inletDescriptor<any>(),
    output: outletDescriptor<any>(),
}

export class GetField<T> implements NodeClassImpl<typeof getFieldDescriptor, typeof GetField> {
    static descriptor = getFieldDescriptor
    static uniqueName = "GetField"
    input!: Inlet<T>
    key!: Inlet<keyof T>
    output!: Outlet<any>

    run() {
        if (this.input === NotReady || this.key === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const value = this.input[this.key]
        this.output.emitIfChanged(value)
    }
}
