import {GetParameters, NodeParameters} from "@cm/lib/graph-system/node-graph"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {GetAll, getAll} from "@cm/lib/graph-system/utils"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {DeclareNodeTS} from "@cm/lib/graph-system/declare-node"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImageOpContextImgProc} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-imgproc"

const TRACE = TextureEditorSettings.EnableFullTrace

export function declareImageOpNode<ReturnType, ParameterType extends NodeParameters>(imageOp: ImageOpType<ParameterType, ReturnType>) {
    class ImageOpNode extends DeclareNodeTS<ReturnType, Context, ParameterType, Record<string, unknown>>(
        {
            run: async ({get, parameters, context}) => {
                const evaledParameters = await getAll(parameters as GetParameters<unknown, ParameterType>, get)
                if (TRACE) {
                    console.log(`Running image-op '${imageOp.name}' with parameters: `, parameters)
                }
                // // make sure every op get their own unique ImagePtrs
                // const mappedEvaledParameters = mapObject(evaledParameters, (value) => {
                //     if (isImagePtr(value)) {
                //         return {
                //             value: new ImagePtr(value),
                //             keepTraversing: false,
                //         }
                //     } else {
                //         return {value, keepTraversing: true}
                //     }
                // })
                if (context instanceof ImageOpContextWebGL2)
                    return await imageOp.WebGL2({context: context, parameters: evaledParameters as GetAll<ParameterType>})
                else if (context instanceof ImageOpContextImgProc)
                    return await imageOp.ImgProc({
                        context: context,
                        parameters: evaledParameters as GetAll<ParameterType>,
                    })
                else {
                    throw new Error(`Unknown context type: ${context}`)
                }
            },
        },
        {label: imageOp.name},
    ) {}

    return ImageOpNode
}
