import {Component} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {RouterModule} from "@angular/router"
import {ContentTypeModel, MutationCreateOrganizationInput, MutationUpdateOrganizationInput, OrganizationListItemFragment} from "@api"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {SearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent, ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"

@Component({
    imports: [
        CardErrorComponent,
        EntityResponsiveSidebarComponent,
        ItemFiltersComponent,
        SearchFilterComponent,
        InfiniteListComponent,
        ListInfoComponent,
        CardPlaceholderComponent,
        EntityCardComponent,
        RouterModule,
        MatDialogModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        SidebarLayoutComponent,
    ],
    selector: "cm-organizations-page",
    standalone: true,
    styleUrls: ["./organizations-page.component.scss"],
    templateUrl: "./organizations-page.component.html",
})
export class OrganizationsPageComponent extends ItemListComponent<
    OrganizationListItemFragment,
    MutationUpdateOrganizationInput,
    MutationCreateOrganizationInput
> {
    // OVERLOADS
    protected override _contentTypeModel = ContentTypeModel.Organization
    protected override _createItem = (data: MutationCreateOrganizationInput) =>
        this.sdk.gql.createOrganization({input: data}).then(({createOrganization}) => createOrganization)

    protected _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.sdk.gql
            .getOrganizationListItems({
                take: take,
                skip: skip,
                filter: this.filters.organizationFilter(),
            })
            .then(({organizations, organizationsCount}) => ({items: organizations, totalCount: organizationsCount}))

    protected _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .getOrganizationListItems({
                take: 1,
                filter: {...this.filters.organizationFilter(), id: id ? {equals: id} : undefined, legacyId: legacyId ? {equals: legacyId} : undefined},
            })
            .then(({organizations}) => organizations?.[0] || undefined)
}
