@if (node) {
    <cm-inspector-section>
        <ng-container cm-title> Config Group </ng-container>
        <ng-container cm-content>
            <cm-input-container>
                <ng-container cm-inline-label> ID: </ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.id" (valueChange)="updateNode(node)" [validate]="isValidId"> </cm-string-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Name: </ng-container>
                <ng-container cm-input>
                    <cm-string-input [(value)]="node.name" (valueChange)="updateNode(node)"> </cm-string-input>
                </ng-container>
            </cm-input-container>
            <cm-toggle [(toggle)]="node.displayWithLabels" (toggleChange)="updateNode(node)">
                <ng-container cm-text>Display with labels</ng-container>
            </cm-toggle>
        </ng-container>
    </cm-inspector-section>
}
