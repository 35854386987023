import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"

export type AddressSpace = "temporary" | "drawable" | "data-object"
export type ImageRefId = number | string

const TRACE = TextureEditorSettings.EnableFullTrace && false

export class ImageRefBase {
    constructor(
        readonly addressSpace: AddressSpace,
        readonly id: ImageRefId,
        private disposeFn: ((image: ImageRefBase) => void) | undefined,
        readonly debugId: string,
    ) {
        if (TRACE) {
            console.log(`ImageRefBase: creation of ${addressSpace}[${id}] (${debugId}); refCount=${this._refCount}`)
        }
    }

    get refCount(): number {
        return this._refCount
    }

    addRef() {
        ++this._refCount
        if (TRACE) {
            console.log(`ImageRefBase: addRef of ${this.addressSpace}[${this.id}] (${this.debugId}); refCount=${this._refCount}`)
        }
    }

    release() {
        --this._refCount
        if (TRACE) {
            console.log(`ImageRefBase: release of ${this.addressSpace}[${this.id}] (${this.debugId}); refCount=${this._refCount}`)
        }
        if (this._refCount === 0 && this.disposeFn) {
            if (TextureEditorSettings.EnableAllocTrace) {
                console.log(`ImageRefBase: dispose of ${this.addressSpace}[${this.id}] (${this.debugId})`)
            }
            this.disposeFn(this)
        }
    }

    equals(other: ImageRefBase) {
        return this.addressSpace === other.addressSpace && this.id === other.id
    }

    private _refCount = 1
}
