<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section [$title]="$item()?.originalFileName" [$isLoaded]="!!$item() !== undefined" (onClosed)="closeModal()"></cm-title-section>
            </ng-container>
            <ng-container cm-buttons>
                @if ($item(); as file) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menuOptions="matMenu">
                        <button mat-menu-item [disabled]="!file.thumbnail?.downloadUrl" (click)="galleryImage.remove(this.$item())">
                            Remove gallery image
                        </button>
                        <button mat-menu-item [disabled]="!can.deleteFile()" (click)="requestDeleteConfirmation()">Delete entity</button>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-main>
                <cm-section>
                    <ng-container cm-icon><i class="far fa-image"></i></ng-container>
                    <ng-container cm-title>Gallery image</ng-container>
                    <cm-thumbnail cm-content [url]="$item()?.thumbnail?.downloadUrl"></cm-thumbnail>
                </cm-section>
            </ng-container>
            <ng-container cm-sidebar>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Name</mat-label>
                    <input matInput id="name" [ngModel]="$item()?.originalFileName" disabled />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Customer</mat-label>
                    <mat-select [ngModel]="$item()?.organization?.id ?? null" [disabled]="true">
                        <mat-option [value]="null">(Not assigned)</mat-option>
                        @for (option of organizations.$filterOptions(); track option) {
                            <mat-option [value]="option.state">{{ option.label }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Created by</mat-label>
                    <input matInput id="createdBy" [ngModel]="$item()?.createdBy?.name" disabled />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Bucket</mat-label>
                    <input matInput id="bucketName" [ngModel]="$item()?.bucketName" disabled />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>URL</mat-label>
                    <input matInput id="downloadUrl" [ngModel]="$item()?.downloadUrl" disabled />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Width</mat-label>
                    <input matInput id="width" [ngModel]="$item()?.width" disabled />
                </mat-form-field>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-label>Height</mat-label>
                    <input matInput id="height" [ngModel]="$item()?.height" disabled />
                </mat-form-field>

                <a [cmIsLoading]="!$item()" color="primary" mat-button [routerLink]="['/files']" [queryParams]="{relatedToId: this.$item()?.id}">
                    <i class="far fa-sitemap"></i>
                    Show related
                </a>
                @if ($item()?.downloadUrl) {
                    <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="downloadFile()">
                        <i class="far fa-download"></i>
                        Download
                    </button>
                }
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
