import {
    MaterialEnumSetting,
    MaterialEnumSettingOption,
    MaterialSocketDefaultValue,
    MaterialSocketT,
    MaterialSocketValueType,
} from "@material-editor/models/material-socket"

export type NodeSettingOption = {value: string; label: string}
export type NodeSettings = {id: string; valueType: NodeSettingValueType; options: NodeSettingOption[]}
export type NodeSettingValueType = "string"

export const getInputSocketDefaultValue = <T extends MaterialSocketValueType>(
    socket: MaterialSocketT<T>,
): MaterialSocketDefaultValue<T> | number | number[] | null => {
    if (typeof socket.defaultValue !== "undefined") {
        switch (socket.valueType) {
            case "vector":
            case "color":
                return [...socket.defaultValue]
            case "scalar":
                return socket.defaultValue
            default:
                throw new Error("Default value type not supported")
        }
    }
    switch (socket.valueType) {
        case "vector":
            return [0, 0, 0]
        case "color":
            return [0.5, 0.5, 0.5, 1]
        case "scalar":
            return 0.5
        default:
            return null
    }
}

export const getEnumSettingDefaultOption = (setting: MaterialEnumSetting): MaterialEnumSettingOption => {
    return setting.options[0]
}

export function formatToApiMaterialSocketType(type: MaterialSocketValueType): string {
    return type === "scalar" ? "float" : type
}
