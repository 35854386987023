import {Injectable} from "@angular/core"
import {localStorageToken} from "@common/helpers/auth/token"

@Injectable({
    providedIn: "root",
})
export class TokenService {
    public useStoredToken = true

    public load(): string | null {
        if (!this.useStoredToken) {
            return null
        }
        return localStorageToken()
    }

    public store(token: string) {
        try {
            localStorage.setItem("token", token)
        } catch (error) {
            console.error("Failed to store token in localStorage: ", error)
        }
    }
}
