import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {
    ThreeObject,
    getThreeObjectPart,
    mathIsEqual,
    setThreeObjectPart,
    threeObjectPartToSceneNodePart,
    updateTransform,
} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import * as THREE from "three"

const css = `
.cm-annotation-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: #00000080;
    box-shadow: 0 0 0 2px #ffffff80;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 50%;
}
.cm-annotation-container {
    position: absolute;
    cursor: pointer;
    user-select: none;
    pointer-events: auto;
}
.cm-annotation-container:hover .cm-annotation-label {
    color: #40c4ff;
    box-shadow: 0 0 0 2px #40c4ff;
}`

const style = document.createElement("style")
style.innerHTML = css
document.body.appendChild(style)

export class ThreeAnnotationObject extends THREE.Object3D {
    private readonly labelContainerElement: HTMLDivElement
    private readonly labelElement: HTMLDivElement
    private readonly descriptionElem: HTMLDivElement
    private elementCache = new Map<string, HTMLDivElement>()

    constructor(protected threeSceneManagerService: ThreeSceneManagerService) {
        super()

        this.labelElement = document.createElement("div")
        this.labelElement.className = "cm-annotation-label"
        this.descriptionElem = document.createElement("div")

        this.labelContainerElement = document.createElement("div")
        this.labelContainerElement.className = "cm-annotation-container"
        this.labelContainerElement.appendChild(this.labelElement)

        //this.labelContainerElement.appendChild(this.descriptionElem)
    }

    updateLabels(label: string, description: string) {
        this.labelElement.innerHTML = label
        this.descriptionElem.innerHTML = description
        this.dispose()
    }

    getElement(camera: THREE.Camera) {
        const cached = this.elementCache.get(camera.uuid)
        if (cached) return cached
        else {
            const cloned = this.labelContainerElement.cloneNode(true) as HTMLDivElement

            cloned.addEventListener("mousedown", this.onMouseDown)
            cloned.addEventListener("click", this.onClick)

            this.elementCache.set(camera.uuid, cloned)
            return cloned
        }
    }

    private onMouseDown = (event: MouseEvent) => {
        event.stopPropagation()
    }

    private onClick = (event: MouseEvent) => {
        if (this.parent) {
            const annotationPart = getThreeObjectPart(this)
            if (!(annotationPart?.threeObject instanceof ThreeAnnotation)) throw new Error("Annotation not found")

            const {sceneManagerService} = this.threeSceneManagerService

            const templateNodePart = sceneManagerService.sceneNodePartToTemplateNodePart(threeObjectPartToSceneNodePart(annotationPart))

            if (!templateNodePart) throw new Error("TemplateNodePart not found")

            this.threeSceneManagerService.sceneManagerService.handleClickEvent({
                target: {templateNodePart},
                modifiers: {
                    shiftKey: event.shiftKey,
                    ctrlKey: event.ctrlKey,
                },
            })
            event.stopPropagation()
        }
    }

    dispose() {
        for (const element of this.elementCache.values()) {
            element.removeEventListener("mousedown", this.onMouseDown)
            element.removeEventListener("click", this.onClick)
            element.remove()
        }
        this.elementCache.clear()
    }
}

export class ThreeAnnotation extends ThreeObject<SceneNodes.Annotation> {
    protected override renderObject

    constructor(protected override threeSceneManagerService: ThreeSceneManagerService) {
        super(threeSceneManagerService)
        this.renderObject = new ThreeAnnotationObject(threeSceneManagerService)
        setThreeObjectPart(this.renderObject, this)
    }

    override setup(sceneNode: SceneNodes.Annotation) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => {
                    return mathIsEqual(valueA, valueB)
                },
                ({transform}) => {
                    updateTransform(transform, this.renderObject)
                },
            ),
            objectFieldsDifferent(sceneNode, this.parameters, ["label", "description"], undefined, ({label, description}) => {
                this.renderObject.updateLabels(label, description)
            }),
        ])
    }

    override dispose() {
        this.renderObject.dispose()
    }
}
