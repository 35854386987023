<cm-drop-files (filesDropped)="handleDroppedFiles($event)">
    <ng-content select="[cm-background]"></ng-content>
    @if ($entity(); as entity) {
        <cm-card
            [link]="entityLink"
            [queryParamsHandling]="entityQueryParamsHandling"
            [squareFormat]="squareFormat"
            [defaultThumbnail]="false"
            (click)="selectEntity.emit(entity)"
        >
            @if (defaultThumbnail) {
                <ng-container cm-thumbnail>
                    <cm-data-object-thumbnail [dataObjectId]="entity.galleryImage?.id" [layout]="ThumbnailLayout.Cover"></cm-data-object-thumbnail>
                </ng-container>
            }
            <ng-container cm-top-left>
                <ng-content select="[cm-top-left]"> </ng-content>
            </ng-container>
            <ng-container cm-top-right>
                <ng-content select="[cm-top-right]"> </ng-content>
            </ng-container>
            <ng-container cm-bottom-left>
                <div class="cm-bottom-left-container">
                    <ng-content select="[cm-bottom-left]"> </ng-content>
                </div>
            </ng-container>
            <ng-container cm-bottom-right>
                <ng-content select="[cm-bottom-right]"> </ng-content>
            </ng-container>
            <ng-container cm-title>
                @if (defaultTitle) {
                    {{ entity.legacyId ?? "" }} -
                    @if (hasName(entity)) {
                        <span [matTooltip]="showTooltip ? entity.name : undefined">
                            {{ entity.name }}
                        </span>
                    } @else {
                        (Untitled)
                    }
                }
                <!--Custom title-->
                <ng-content select="[cm-title]"> </ng-content>
            </ng-container>
            <ng-container cm-subtitle>
                <!--Default subtitle-->
                @if (defaultSubtitle) {
                    @if (userIsStaff) {
                        @if (entity?.organization) {
                            <span class="cm-customer">
                                {{ entity.organization?.name ?? "(No customer)" }}
                            </span>
                        }
                    }
                    @if (!userIsStaff) {
                        <div class="cm-article-id">
                            @if (hasArticleId(entity)) {
                                {{ entity.articleId }}
                            } @else {
                                (No Article ID)
                            }
                        </div>
                    }
                }
                <!--Custom subtitle-->
                <ng-content select="[cm-subtitle]"> </ng-content>
            </ng-container>
            <ng-container cm-details-bottom-right>
                <ng-content select="[cm-details-bottom-right]"> </ng-content>
            </ng-container>
        </cm-card>
    }
    @if (!$entity(); as entity) {
        <cm-card-placeholder></cm-card-placeholder>
    }
</cm-drop-files>
