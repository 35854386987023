import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {AddressSpace, ImageRefBase, ImageRefId} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref-base"
import {SmartPtrArray} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr-array"
import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"

export class ImageImgProc extends ImageRefBase {
    constructor(
        addressSpace: AddressSpace,
        id: ImageRefId,
        disposeFn: (() => void) | undefined,
        readonly node: Nodes.ImageNode,
        readonly descriptor: ImageDescriptor,
        debugId = "no-debug-id",
    ) {
        super(addressSpace, id, disposeFn, debugId)
    }
}

export function isImageImgProc(obj: unknown): obj is ImageImgProc {
    return obj instanceof ImageImgProc
}

// export function makeImageImgProc(node: Nodes.ImageNode, descriptor: ImageDescriptor): ImageImgProc {
//     return {
//         isImageImgProc: true,
//         descriptor,
//         node,
//     }
// }

export class ImagePtrImgProc extends SmartPtr<ImageImgProc> {}

export class ImagePtrArrayImgProc extends SmartPtrArray<ImageImgProc> {}
