import {Component} from "@angular/core"
import {RoutedDialogComponent} from "@app/common/components/dialogs/routed-dialog/routed-dialog.component"
import {LoadingSpinnerComponent} from "@app/common/components/progress"
import {DialogSize} from "@app/common/models/dialogs"
import {TemplateEditComponent} from "@app/template-editor/components/template-edit/template-edit.component"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {ConfigMenuComponent} from "@app/common/components/menu"
import {ConfigMenuNewComponent} from "@app/common/components/viewers/configurator/config-menu-new/config-menu-new.component"

@Component({
    selector: "cm-configurator-test",
    standalone: true,
    imports: [RoutedDialogComponent, LoadingSpinnerComponent, TemplateEditComponent, ConfiguratorComponent, ConfigMenuComponent, ConfigMenuNewComponent],
    templateUrl: "./configurator-test.component.html",
    styleUrl: "./configurator-test.component.scss",
})
export class ConfiguratorTestComponent {
    dialogSizes = DialogSize
}
