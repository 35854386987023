import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {z} from "zod"
import {vec3} from "@src/materials/types"
import {threeConvert, threeVec3Node} from "@src/materials/three-utils"

export class TexCoord extends DeclareMaterialNode(
    {
        returns: z.object({
            camera: materialSlots,
            generated: materialSlots,
            normal: materialSlots,
            object: materialSlots,
            reflection: materialSlots,
            uv: materialSlots,
            window: materialSlots,
        }),
        inputs: z.object({}),
        parameters: z.object({
            camera: vec3.optional(),
            generated: vec3.optional(),
            normal: vec3.optional(),
            object: vec3.optional(),
            reflection: vec3.optional(),
            uv: vec3.optional(),
            window: vec3.optional(),
        }),
    },
    {
        toThree: async ({parameters}) => {
            const camera = threeConvert(parameters.camera, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const generated = THREENodes.uv(0)
            const normal = threeConvert(parameters.normal, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const object = threeConvert(parameters.object, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const reflection = threeConvert(parameters.reflection, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})
            const uv = THREENodes.uv(0)
            const window = threeConvert(parameters.window, threeVec3Node) ?? threeVec3Node({x: 0, y: 0, z: 0})

            return {camera, generated, normal, object, reflection, uv, window}
        },
    },
) {}
