import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr, ImageRef, isImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"

export type ParameterType = {
    sourceImage: ImagePtr
    blackLevel: ImagePtr | number
    whiteLevel: ImagePtr | number
    gamma?: number
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpLevels: ImageOpType<ParameterType, ReturnType> = {
    name: "Levels",

    WebGL2: async ({context, parameters: {sourceImage, blackLevel, whiteLevel, gamma, resultImage}}) => {
        const halLevels = await context.getOrCreateImageCompositor(`
            uniform float u_gamma;

            vec3 getBlackLevel(ivec2 index) {
                ${blackLevel instanceof ImageRef ? "return texelFetch1(index, ADDRESS_MODE_CLAMP_TO_EDGE).rgb;" : `return vec3(${blackLevel});`}
            }

            vec3 getWhiteLevel(ivec2 index) {
                ${whiteLevel instanceof ImageRef ? "return texelFetch2(index, ADDRESS_MODE_CLAMP_TO_EDGE).rgb;" : `return vec3(${whiteLevel});`}
            }

            vec3 getGamma() {
                return vec3(u_gamma);
            }

            vec3 getOffset(vec3 blackLevel, vec3 whiteLevel) {
                return -blackLevel;
            }

            vec3 getScale(vec3 blackLevel, vec3 whiteLevel) {
                return vec3(1.0) / max(vec3(1e-3), whiteLevel - blackLevel);
            }

            vec4 computeColor(ivec2 targetPixel) {
                vec3 bl = getBlackLevel(targetPixel);
                vec3 wl = getWhiteLevel(targetPixel);
                vec3 gamma = getGamma();
                vec3 offset = getOffset(bl, wl);
                vec3 scale = getScale(bl, wl);
                vec4 source = texelFetch0(targetPixel);
                vec4 result = vec4(pow(max(vec3(0.0), (source.rgb + offset) * scale), gamma), source.a);
                return result;
            }
        `)
        using sourceImageWebGl2 = await context.getImage(sourceImage)
        resultImage = await context.prepareResultImage(resultImage, sourceImageWebGl2.ref.descriptor)
        using resultImageWebGl2 = await context.getImage(resultImage)
        using blackLevelWebGl2 = isImagePtr(blackLevel) ? await context.getImage(blackLevel) : undefined
        using whiteLevelWebGl2 = isImagePtr(whiteLevel) ? await context.getImage(whiteLevel) : undefined
        halLevels.setParameter("u_gamma", {type: "float", value: gamma ?? 1.0})
        await halLevels.paint(resultImageWebGl2.ref.halImage, [sourceImageWebGl2.ref.halImage, blackLevelWebGl2?.ref.halImage, whiteLevelWebGl2?.ref.halImage])
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, blackLevel, whiteLevel, gamma, resultImage}}) => {
        using sourceImageImgProc = await context.getImage(sourceImage)
        using blackLevelImageImgProc = isImagePtr(blackLevel) ? await context.getImage(blackLevel) : undefined
        using whiteLevelImageImgProc = isImagePtr(whiteLevel) ? await context.getImage(whiteLevel) : undefined
        const blackLevelIn = isImagePtr(blackLevel) ? blackLevelImageImgProc!.ref.node : blackLevel
        const whiteLevelIn = isImagePtr(whiteLevel) ? whiteLevelImageImgProc!.ref.node : whiteLevel
        const resultNode = Nodes.levelsImage(sourceImageImgProc.ref.node, blackLevelIn, whiteLevelIn, gamma)
        using result = await context.createImage(sourceImageImgProc.ref.descriptor, resultNode)
        return await toImgProcResultImage(context, result, resultImage)
    },
}
