import {inject, Injectable} from "@angular/core"
import {AuthService} from "@common/services/auth/auth.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {DataObjectThumbnailFragment, DownloadResolution} from "@api"
import {ApiRequest} from "@common/models/api-request/api-request"
import {BatchApiCallById} from "@common/helpers/batch-api-call/batch-api-call-by-id"
import {IsUnique} from "@cm/lib/utils/filter"

export type RequestPayload = {
    id: string
    resolution: DownloadResolution
}

export type ResponsePayload = DataObjectThumbnailFragment

export type BatchedRequestPayload = {
    requests: ApiRequest<RequestPayload, ResponsePayload>[]
    resolution: DownloadResolution
}

@Injectable({
    providedIn: "root",
})
export class DataObjectThumbnailService extends BatchApiCallById<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    private auth = inject(AuthService)
    private sdk = inject(SdkService)

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        // separate the requests by resolution and data object id
        const requestsByResolution = new Map<DownloadResolution, BatchedRequestPayload>()
        requests.forEach((request) => {
            const resolution = request.payload.resolution
            if (!requestsByResolution.has(resolution)) {
                requestsByResolution.set(resolution, {requests: [], resolution})
            }
            requestsByResolution.get(request.payload.resolution)!.requests.push(request)
        })
        return Array.from(requestsByResolution.values())
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        // don't attempt to fetch if the user has logged out in the meantime
        if (this.auth.isLoggedIn()) {
            const ids = payload.requests.map((request) => request.payload.id).filter(IsUnique)
            return this.sdk.gql
                .queryDataObjectThumbnails({
                    ids,
                    resolution: payload.resolution,
                })
                .then((response) => response.dataObjects)
                .catch((error) => {
                    // ignore error if user has logged out in the meantime
                    if (this.auth.isLoggedIn()) {
                        throw error
                    } else {
                        return []
                    }
                })
        } else {
            return []
        }
    }
}
