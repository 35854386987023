import {Component, computed, DestroyRef, ElementRef, forwardRef, inject, input, Input, OnInit, output, signal} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {isTemplateNode, TemplateNode} from "@cm/lib/templates/types"
import {NgTemplateOutlet} from "@angular/common"
import {getDropPositionFromPosition, TemplateDropTarget, TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {DeclareTemplateNodeTS} from "@cm/lib/templates/declare-template-node"
import {imageLikeClasses, isImageLike, isMaterialLike, isMesh, nodeClasses} from "@cm/lib/templates/node-types"
import {TemplateNodeComponent} from "app/template-editor/components/template-node/template-node.component"
import {getNodeIconClass, getNodeIconSeconaryClass} from "@app/template-editor/helpers/template-icons"
import {BooleanValue, JSONValue, NumberValue, StringValue} from "@cm/lib/templates/nodes/value"
import {getTemplateNodeClassLabel} from "@cm/lib/templates/utils"
import {MaterialAssignment, MaterialAssignments} from "@cm/lib/templates/nodes/material-assignment"
import {MaterialAssignmentsInspectorComponent} from "../inspectors/material-assignments-inspector/material-assignments-inspector.component"
import {MaterialAssignmentInspectorComponent} from "../inspectors/material-assignment-inspector/material-assignment-inspector.component"
import {ImageInspectorComponent} from "../inspectors/image-inspector/image-inspector.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {ColorInputComponent} from "@common/components/inputs/color-input/color-input.component"
import {JSONInputComponent} from "@common/components/json-input/json-input.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {Matrix4} from "@app/common/helpers/vector-math"
import {Parameters, TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {z} from "zod"
import {MatMenuModule} from "@angular/material/menu"
import {StringResolve} from "@cm/lib/templates/nodes/string-resolve"
import {ListItemComponent} from "@common/components/item/list-item/list-item.component"
import {SliderComponent} from "../../../common/components/inputs/slider/slider.component"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {NativeInputTextAreaComponent} from "@app/common/components/inputs/native/native-input-text-area/native-input-text-area.component"

export type SelectionPossibilityValue<T = unknown> = T | (() => T)
export function resolveSelectionPossibilityValue<T>(value: SelectionPossibilityValue<T>): T {
    if (typeof value === "function") return (value as () => T)()
    return value
}
export type SelectionPossibility<T = unknown> = {
    name: string
    value: SelectionPossibilityValue<T>
    actions?: {iconClass: string; toolTip?: string; fn: () => void}[]
}
export type SelectionPossibilities<T = unknown> = SelectionPossibility<T>[]

@Component({
    selector: "cm-value-slot",
    standalone: true,
    templateUrl: "./value-slot.component.html",
    styleUrls: ["./value-slot.component.scss", "../../helpers/template-icons.scss"],
    imports: [
        MatTooltipModule,
        NumericInputComponent,
        NgTemplateOutlet,
        TemplateNodeComponent,
        forwardRef(() => MaterialAssignmentsInspectorComponent),
        forwardRef(() => MaterialAssignmentInspectorComponent),
        ImageInspectorComponent,
        StringInputComponent,
        ColorInputComponent,
        JSONInputComponent,
        InputContainerComponent,
        ToggleComponent,
        MatMenuModule,
        ListItemComponent,
        NativeInputTextAreaComponent,
        SliderComponent,
    ],
})
export class ValueSlotComponent<T extends TemplateNode> implements OnInit {
    node = input.required<T>()
    key = input.required<keyof T["parameters"]>()
    subKey = input<string | number | undefined>(undefined)
    label = input<string | undefined>(undefined)
    icon = input<string | undefined>(undefined)
    fallbackText = input("-")
    schema = input<z.ZodTypeAny | undefined>(undefined)
    selectionPossibilities = input<SelectionPossibilities | undefined>(undefined)
    resolveSelectionPossibilityValue = resolveSelectionPossibilityValue
    isSelected = input<(selectionPossibility: SelectionPossibility<any>, value: any) => boolean>(
        (selectionPossibility, value) => resolveSelectionPossibilityValue(selectionPossibility.value) === value,
    )
    topLabel = input(false)
    decimalPlaces = input(2)
    min = input<number | undefined>(undefined)
    max = input<number | undefined>(undefined)
    validate = input<((x: string) => boolean) | undefined>(undefined)
    minRows = input(1)

    overwrittenValue = input<unknown | undefined>(undefined)
    updatedOverwrittenValue = output<unknown>()
    onChanged = output<unknown>()

    requestUpdate = output<unknown>()
    JSON = JSON

    protected destroyRef = inject(DestroyRef)
    protected sceneManagerService = inject(SceneManagerService)
    drag = inject(TemplateNodeDragService)
    private clipboardService = inject(TemplateNodeClipboardService)
    private triggerRecompute = signal(0)
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
    StringResolve = StringResolve

    labelText = computed(() => {
        const label = this.label()
        if (label && label.length > 0) return `${label}: `
        return undefined
    })
    labelTooltipText = computed(() => {
        const label = this.label()
        const acceptedValuesText = this.acceptedValuesText()
        if (label && acceptedValuesText.length > 0) return `${label} (${acceptedValuesText})`
        else if (label) return label
        else if (acceptedValuesText.length > 0) return acceptedValuesText
        else return undefined
    })
    tooltipText = computed(() => {
        const property = this.property()
        if (property === undefined || property === null) return undefined
        if (typeof property === "string") {
            if (property.length === 0) return undefined
            else return property
        }
        return JSON.stringify(property, null, 2)
    })
    parameter = () => {
        this.triggerRecompute()
        return (this.node().parameters as T["parameters"])[this.key()]
    }
    property = computed(() => {
        const overwrittenValue = this.overwrittenValue()
        if (overwrittenValue !== undefined) return this.overwrittenValue()

        const subKey = this.subKey()
        const parameter = this.parameter()
        if (subKey !== undefined) {
            if (parameter === undefined || parameter === null) return undefined
            if (typeof parameter === "object" && typeof subKey === "string") return (parameter as Record<string, unknown>)[subKey]
            else if (Array.isArray(parameter) && typeof subKey === "number") return parameter[subKey]
            else throw new Error(`Invalid subKey ${subKey} for property ${String(this.key())} ${JSON.stringify(parameter)}`)
        }

        return parameter
    })
    nodeProperty = computed(() => {
        const property = this.property()
        return isTemplateNode(property) ? property : undefined
    })
    materialAssignmentNode = computed(() => {
        if (!this.propertyAcceptsNodeClass(MaterialAssignment.getNodeClass())) return undefined

        const nodeProperty = this.nodeProperty()
        if (!nodeProperty) return {materialAssignment: null}
        else if (nodeProperty instanceof MaterialAssignment) return {materialAssignment: nodeProperty}
        else return undefined
    })
    materialAssignmentsNode = computed(() => {
        if (!this.propertyAcceptsNodeClass(MaterialAssignments.getNodeClass())) return undefined

        const node = this.node()
        const nodeProperty = this.nodeProperty()
        if (isMesh(node) && nodeProperty instanceof MaterialAssignments) return {materialAssignments: nodeProperty, mesh: node}
        else return undefined
    })
    imageLikeNode = computed(() => {
        if (imageLikeClasses.every((nodeClass) => !this.propertyAcceptsNodeClass(nodeClass))) return undefined

        const nodeProperty = this.nodeProperty()
        if (!nodeProperty) return {imageLike: null}
        else if (isTemplateNode(nodeProperty) && isImageLike(nodeProperty)) return {imageLike: nodeProperty}
        else return undefined
    })
    parametersNode = computed(() => {
        if (!this.propertyAcceptsNodeClass(Parameters.getNodeClass())) return undefined

        const node = this.node()
        const nodeProperty = this.nodeProperty()
        if (node instanceof TemplateInstance && nodeProperty instanceof Parameters) return {templateInstance: node, parameters: nodeProperty}
        else return undefined
    })
    acceptedNodeClasses = computed(() => {
        return [...new Set(nodeClasses.filter((nodeClass) => this.propertyAcceptsNodeClass(nodeClass)))]
    })
    propertyFilled = computed(() => this.property() !== undefined && this.property() !== null)
    droppedValueMapper = computed<((value: unknown) => unknown) | undefined>(() => {
        const materialAssignmentNode = this.materialAssignmentNode()

        if (materialAssignmentNode) {
            const {materialAssignment} = materialAssignmentNode

            return (value) => {
                if (isTemplateNode(value) && isMaterialLike(value)) {
                    if (materialAssignment) return materialAssignment.clone({cloneSubNode: () => true, parameterOverrides: {node: value}})
                    else
                        return new MaterialAssignment({
                            node: value,
                            side: "front",
                        })
                } else return undefined
            }
        } else return undefined
    })

    propertyAcceptsNumber = computed(() => this.propertyAccepts(1))
    propertyAcceptsString = computed(() => this.propertyAccepts("Text"))
    propertyAcceptsBoolean = computed(() => this.propertyAccepts(true))
    propertyAcceptsJSON = computed(() => this.propertyAccepts({}))
    propertyAcceptsVector = computed(() => this.propertyAccepts([-1, -1, -1]))
    propertyAcceptsColor = computed(() => !this.propertyAcceptsVector() && this.propertyAccepts([1, 1, 1]))
    propertyAcceptsMatrix = computed(() => this.propertyAccepts(Matrix4.identity().toArray()))

    acceptedValuesText = computed(() => {
        const extendedNodeClasses = [...this.acceptedNodeClasses()]
        if (!this.selectionPossibilities()) {
            if (this.propertyAcceptsNumber()) extendedNodeClasses.push("Number")
            if (this.propertyAcceptsString()) extendedNodeClasses.push("Text")
            if (this.propertyAcceptsBoolean()) extendedNodeClasses.push("Boolean")
            if (this.propertyAcceptsJSON()) extendedNodeClasses.push("JSON")
            if (this.propertyAcceptsVector()) extendedNodeClasses.push("Vector")
            if (this.propertyAcceptsColor()) extendedNodeClasses.push("Color")
            if (this.propertyAcceptsMatrix()) extendedNodeClasses.push("Matrix")
        }

        const uniqueNodeClasses = [...new Set(extendedNodeClasses.map((nodeClass) => getTemplateNodeClassLabel(nodeClass)))]
        return uniqueNodeClasses.join(", ")
    })

    acceptedValuesIconClass = computed(() => {
        const icon = this.icon()
        if (icon) return icon

        const getIconFromClass = (nodeClass: string): string => getNodeIconSeconaryClass(nodeClass) ?? getNodeIconClass(nodeClass)

        const iconClasses = this.acceptedNodeClasses().map((nodeClass) => getIconFromClass(nodeClass))
        if (this.propertyAcceptsNumber()) iconClasses.push(getIconFromClass(NumberValue.getNodeClass()))
        if (this.propertyAcceptsString()) iconClasses.push(getIconFromClass(StringValue.getNodeClass()))
        if (this.propertyAcceptsBoolean()) iconClasses.push(getIconFromClass(BooleanValue.getNodeClass()))
        if (this.propertyAcceptsJSON()) iconClasses.push(getIconFromClass(JSONValue.getNodeClass()))
        if (this.propertyAcceptsVector()) iconClasses.push("far fa-solid fa-up-down-left-right")
        if (this.propertyAcceptsColor()) iconClasses.push("far fa-palette")
        if (this.propertyAcceptsMatrix()) iconClasses.push("far fa-table-cells")

        const invalidIcon = getNodeIconClass("invalid-node-class")

        const uniqueIconClasses = [...new Set(iconClasses.filter((iconClass) => iconClass !== invalidIcon))]
        return uniqueIconClasses.length > 0 ? uniqueIconClasses[0] : "far fa-circle-info"
    })

    numberValue = computed(() => {
        const value = this.property()
        if (typeof value === "number") return value
        return undefined
    })
    stringValue = computed(() => {
        const value = this.property()
        if (typeof value === "string") return value
        return undefined
    })
    selectionText = computed(() => {
        const selectionPossibilities = this.selectionPossibilities()
        if (!selectionPossibilities) return undefined

        const isSelected = this.isSelected()

        const value = this.property()
        return selectionPossibilities.find((possibility) => isSelected(possibility, value))?.name ?? this.fallbackText()
    })
    booleanValue = computed(() => {
        const value = this.property()
        if (typeof value === "boolean") return value
        return undefined
    })
    jsonValue = computed(() => {
        const value = this.property()
        if (typeof value === "object") return value
        return undefined
    })
    colorValue = computed(() => {
        const value = this.property()
        if (Array.isArray(value) && value.length === 3 && value.every((x) => typeof x === "number")) return value as [number, number, number]
        return undefined
    })

    private isDragging = false
    private valueChangedWhileDragging = false
    setDragging(isDragging: boolean) {
        this.isDragging = isDragging

        if (!this.isDragging && this.valueChangedWhileDragging) {
            this.sceneManagerService.endModifyTemplateGraph()
            this.valueChangedWhileDragging = false
        }
    }

    canClear = computed(() => {
        return (
            this.propertyAccepts(undefined) ||
            this.propertyAccepts(null) ||
            (this.nodeProperty() &&
                (this.propertyAcceptsJSON() || this.propertyAcceptsNumber() || this.propertyAcceptsBoolean() || this.propertyAcceptsString()))
        )
    })
    canPaste = computed(() => {
        const nodes = this.clipboardService.nodes()
        if (nodes.length !== 1) return false
        const node = nodes[0]

        return this.propertyAccepts(node)
    })

    protected propertyAccepts(value: unknown) {
        const overwrittenSchema = this.schema()
        if (overwrittenSchema) {
            const result = overwrittenSchema.safeParse(value)
            return result.success
        }

        const node = this.node()
        const schema = node.getParamsSchema()

        return schema.safeParse({...node.parameters, ...{[this.key()]: this.getParameterValue(value)}}).success
    }

    protected propertyAcceptsNodeClass(nodeClass: string) {
        class DummyClass extends DeclareTemplateNodeTS<{}>({}, {nodeClass}) {}
        return this.propertyAccepts(new DummyClass({}))
    }

    protected getParameterValue(value: unknown) {
        const subKey = this.subKey()

        if (subKey !== undefined) {
            const parameter = this.parameter()
            if (typeof parameter === "object" && parameter !== null && typeof subKey === "string") {
                const modifiedValue = {...parameter, [subKey]: value}
                return modifiedValue
            } else if (Array.isArray(parameter) && typeof subKey === "number") {
                const modifiedValue = [...parameter]
                modifiedValue[subKey] = value
                return modifiedValue
            } else throw new Error(`Invalid subKey ${subKey} for property ${String(this.key())} ${JSON.stringify(parameter)}`)
        }

        return value
    }

    protected setValue(value: unknown) {
        if (this.overwrittenValue() !== undefined) this.updatedOverwrittenValue.emit(value)
        else {
            if (this.isDragging) {
                this.sceneManagerService.beginModifyTemplateGraph()
                this.valueChangedWhileDragging = true
            }

            this.sceneManagerService.modifyTemplateGraph(() => {
                const key = this.key()
                const newParameter = this.getParameterValue(value)
                const node = this.node()
                node.updateParameters({[key]: newParameter})
                this.onChanged.emit(newParameter)
            })
        }
    }

    ngOnInit() {
        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const node = this.node()
            if (node && differences.modifiedNodes.has(node)) this.triggerRecompute.update((x) => x + 1)

            const nodeProperty = this.nodeProperty()
            if (nodeProperty && differences.modifiedNodes.has(nodeProperty)) this.triggerRecompute.update((x) => x + 1)
        })

        this.drag.draggedItem$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({dragSource, dropTarget}) => {
            const {component} = dropTarget
            if (component === this.getDroppableComponent()) {
                const droppedValueMapper = this.droppedValueMapper()
                const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
                if (draggedNode) this.setValue(droppedValueMapper ? droppedValueMapper(draggedNode) : draggedNode)
            }
        })
    }

    paste() {
        const nodes = this.clipboardService.pasteReferences()
        if (nodes.length !== 1) return
        const node = nodes[0]

        return this.setValue(node)
    }

    clear() {
        if (this.propertyAccepts(undefined)) this.setValue(undefined)
        else if (this.propertyAccepts(null)) this.setValue(null)
        else {
            if (this.nodeProperty()) {
                if (this.propertyAcceptsJSON()) this.setValue({})
                else if (this.propertyAcceptsNumber()) this.setValue(0)
                else if (this.propertyAcceptsBoolean()) this.setValue(false)
                else if (this.propertyAcceptsString()) this.setValue("")
            }
        }
    }

    updateValue(value: unknown) {
        const getMappedValue = (value: unknown) => {
            if (value === undefined || value === null) {
                if (!this.propertyAccepts(value)) {
                    const mappedValue: unknown = value === undefined ? null : undefined
                    if (this.propertyAccepts(mappedValue)) return mappedValue
                }
            }
            return value
        }

        const mappedValue = getMappedValue(value)

        if (!this.propertyAccepts(mappedValue)) {
            const subKey = this.subKey()
            throw new Error(
                `Invalid value ${mappedValue} for property ${String(this.key())}${subKey ? "." + subKey : ""} of node ${this.node().getNodeClass()}`,
            )
        }

        this.setValue(mappedValue)
    }

    private getDroppableComponent() {
        return this as unknown as ValueSlotComponent<TemplateNode>
    }

    dragOver(event: DragEvent) {
        event.stopPropagation()

        const dragSource = this.drag.dragSource()
        if (!dragSource) return

        const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
        if (!draggedNode) return

        this.drag.dropTarget.update((previous) => {
            const droppedValueMapper = this.droppedValueMapper()
            const nodeProperty = this.nodeProperty()

            if (droppedValueMapper) {
                const mappedNode = droppedValueMapper(draggedNode.clone({cloneSubNode: () => true}))
                if (!this.propertyAccepts(mappedNode)) return null

                if (isTemplateNode(mappedNode) && nodeProperty) {
                    if (mappedNode.getHash() === nodeProperty.getHash()) return null
                }
            } else {
                if (!this.propertyAccepts(draggedNode)) return null
                //Prevent dropping the same node
                if (nodeProperty === draggedNode) return null
            }

            const dropTarget = {component: this.getDroppableComponent(), position: "inside"} as TemplateDropTarget<ValueSlotComponent<TemplateNode>>

            if (
                previous &&
                previous.component === dropTarget.component &&
                getDropPositionFromPosition(previous.position) === getDropPositionFromPosition(dropTarget.position)
            )
                return previous
            return dropTarget
        })

        if (this.drag.dropTarget() !== null) event.preventDefault()
    }

    dragLeave(event: DragEvent) {
        event.stopPropagation()

        this.drag.dragLeave(event, this.getDroppableComponent(), this.elementRef.nativeElement)
    }

    isDropTarget() {
        const dropTarget = this.drag.dropTarget()
        if (!dropTarget) return false
        const {component} = dropTarget

        return component === this.getDroppableComponent()
    }
}
