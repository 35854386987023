import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpExtractChannel} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-extract-channel"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const ExtractChannelNode = declareImageOpNode(imageOpExtractChannel)

export function extractChannel(parameters: GetParameters<Context, ParameterType>) {
    return new ExtractChannelNode(parameters)
}
