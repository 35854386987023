import {imageOpCrossCorrelation} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-cross-correlation"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {Box2Like} from "@cm/lib/math/box2"
import {DebugImage} from "@app/textures/texture-editor/operator-stack/image-op-system/util/debug-image"
import {imageOpToGrayscale} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-to-grayscale"
import {Vector2} from "@cm/lib/math/vector2"

export const crossCorrelate = async (
    imageOpContextWebGL2: ImageOpContextWebGL2,
    sourceImage: ImagePtr,
    sourceRegion: Box2Like | undefined,
    templateImage: ImagePtr,
    templateRegion: Box2Like | undefined,
    debugImage?: DebugImage,
) => {
    let crossCorrelation = await imageOpCrossCorrelation.WebGL2({
        context: imageOpContextWebGL2,
        parameters: {
            sourceImage,
            sourceRegion,
            templateImage,
            templateRegion,
            debugImage,
        },
    })
    if (debugImage) {
        await debugImage?.addImage(crossCorrelation)
    }
    // reduce correlation image to grayscale by adding the individual channels
    const sourceImageDescriptor = await imageOpContextWebGL2.getImageDescriptor(sourceImage)
    if (sourceImageDescriptor.channelLayout !== "R") {
        const singleChannelCrossCorrelation = await imageOpToGrayscale.WebGL2({
            context: imageOpContextWebGL2,
            parameters: {
                sourceImage: crossCorrelation,
                mode: "average",
            },
        })
        crossCorrelation.release()
        crossCorrelation = singleChannelCrossCorrelation
    }
    // find peak
    let peakValue = Number.NEGATIVE_INFINITY
    const peakOffset = new Vector2(0, 0)
    {
        using crossCorrelationImage = await imageOpContextWebGL2.getImage(crossCorrelation)
        const crossCorrelationImageData = await crossCorrelationImage.ref.halImage.readRawImageData("float32")
        for (let y = 0; y < crossCorrelationImage.ref.descriptor.height; y++) {
            for (let x = 0; x < crossCorrelationImage.ref.descriptor.width; x++) {
                const value = crossCorrelationImageData[y * crossCorrelationImage.ref.descriptor.width + x]
                if (value > peakValue) {
                    peakValue = value
                    peakOffset.set(x, y)
                }
            }
        }
    }
    crossCorrelation.release()
    return {peakOffset, peakValue}
}
