import {Component} from "@angular/core"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {ActivatedRoute, Router} from "@angular/router"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"

@Component({
    selector: "cm-scene-viewer-dialog",
    templateUrl: "./scene-viewer-dialog.component.html",
    styleUrls: ["./scene-viewer-dialog.component.scss"],
    standalone: true,
    imports: [SceneViewerComponent, RoutedDialogComponent],
})
export class SceneViewerDialogComponent {
    dialogSizes = DialogSize
    templateId: number
    loading = true

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.templateId = route.snapshot.data.templateData.template.id
    }

    overlayClosed() {
        void this.router.navigate(["../../"], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }
}
