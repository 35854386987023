import {registerNode} from "@src/graph-system/register-node"
import {DeclareObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {visitNone} from "@src/graph-system/declare-visitor-node"
import {idNodeParameters} from "@src/templates/nodes/id-node"
import {namedNodeParameters} from "@src/templates/nodes/named-node"

const annotationParameters = namedNodeParameters.merge(idNodeParameters).merge(
    z.object({
        label: z.string(),
        description: z.string(),
    }),
)
export type AnnotationParameters = z.infer<typeof annotationParameters>

@registerNode
export class Annotation extends DeclareObjectNode(
    {parameters: annotationParameters},
    {
        onVisited: {
            onCompile: function (this: Annotation, {context, parameters}) {
                const {evaluator, currentTemplate, sceneProperties} = context

                if (sceneProperties?.parameters.showAnnotations === false) return visitNone(parameters)

                const {displayList} = currentTemplate
                const {label, description, id} = parameters

                const scope = evaluator.getScope(this)

                const objectProps = this.setupObject(scope, context, "Annotation", undefined, undefined)
                const annotation = scope.struct<SceneNodes.Annotation>("Annotation", {
                    type: "Annotation",
                    ...objectProps,
                    label,
                    description,
                    annotationID: id,
                })
                displayList.push(annotation)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "Annotation"},
) {}

export type AnnotationFwd = TemplateObjectNode<AnnotationParameters>
