// @ts-strict-ignore
import {IScene, ThreeObjectBase} from "@editor/helpers/scene/three-proxies/utils"
import {map, of as observableOf} from "rxjs"
import * as THREE from "three"
import {IMaterialData} from "@cm/lib/templates/interfaces/material-data"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"

export class PreloadMaterial extends ThreeObjectBase {
    threeObject = new THREE.Mesh(new THREE.PlaneGeometry(0, 0))
    curMaterialData: IMaterialData

    constructor(scene: IScene) {
        super(scene)
        // this.threeObject.geometry = new THREE.Geometry();
    }

    update(node: SceneNodes.PreloadMaterial) {
        if (node.materialData?.materialGraph === this.curMaterialData?.materialGraph) return
        this.curMaterialData = node.materialData
        const mgr = this.scene.materialManager
        this.scene.addTask(
            (node.materialData ? mgr.getMaterial(node.materialData) : observableOf(null)).pipe(
                map((material) => {
                    if (this.threeObject.material) {
                        mgr.releaseMaterial(this.threeObject.material as THREE.Material)
                    }
                    if (material) {
                        this.threeObject.material = material
                    }
                }),
            ),
        )
    }
}
