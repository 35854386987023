import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpDrawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-drawable-image"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const DrawableImageNode = declareImageOpNode(imageOpDrawableImage)

export function drawableImage(parameters: GetParameters<Context, ParameterType>) {
    return new DrawableImageNode(parameters)
}
