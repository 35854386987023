import {MaterialSocket} from "@material-editor/models/material-socket"

export const SeparateXyzInputs: Record<string, MaterialSocket> = {
    image: {paramType: "socket", id: "Vector", type: "input", valueType: "vector", label: "Vector"},
}

export const SeparateXyzOutputs: Record<string, MaterialSocket> = {
    red: {paramType: "socket", id: "X", type: "output", valueType: "output", label: "X"},
    green: {paramType: "socket", id: "Y", type: "output", valueType: "output", label: "Y"},
    blue: {paramType: "socket", id: "Z", type: "output", valueType: "output", label: "Z"},
}
