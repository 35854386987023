import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeId} from "@src/templates/runtime-graph/types"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const traceDescriptor = {
    input: inletDescriptor<any>(),
    key: inletDescriptor<string>(),
    output: outletDescriptor<any>(),
}

export class Trace<T> implements NodeClassImpl<typeof traceDescriptor, typeof Trace> {
    static descriptor = traceDescriptor
    static uniqueName = "Trace"
    input!: Inlet<T>
    key!: Inlet<string>
    output!: Outlet<T>
    $id?: NodeId

    run() {
        const value = this.input
        if (this.key !== NotReady) {
            const msg = this.key ?? `Trace[${this.$id}`
            console.log(msg, value)
        }
        this.output.emit(value)
    }
}
