import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-dropdown",
    templateUrl: "./dropdown.component.html",
    styleUrls: ["./dropdown.component.scss"],
    standalone: true,
})
export class DropdownComponent {
    @Input() isOpen = false

    constructor() {}
}
