const apertureVectors = [
    0, 0, -0.124, 0.241, 0.277, -0.064, -0.272, -0.033, 0.002, -0.221, 0.15, 0.169, 0.147, -0.304, 0.337, 0.122, -0.185, -0.26, 0.036, 0.32, -0.264, 0.171,
    0.18, 0.027, -0.096, -0.123, 0.095, -0.121, -0.114, 0.07, 0.05, 0.184, -0.294, -0.128, 0.241, 0.203, 0.28, -0.161, -0.366, -0.01, 0.373, -0.047, -0.213,
    0.279, -0.079, 0.327, 0.05, -0.306, -0.093, -0.392, 0.181, 0.328, 0.182, -0.168, 0.337, 0.219, -0.362, 0.176, -0.001, -0.098, 0.101, -0.39, -0.027, 0.245,
    0.243, -0.321, -0.278, -0.29, -0.203, -0.164, 0.238, 0.106, 0.376, -0.144, 0.4, 0.047, -0.168, 0.366, 0.101, 0.085, 0.099, -0.219, 0.095, -0.021, -0.096,
    -0.22, -0.016, 0.402, 0.004, -0.402, 0.105, 0.389, -0.185, -0.358, 0.301, 0.031, -0.207, 0.04, -0.309, 0.258, -0.094, -0.025, -0.169, 0.151, 0.273, 0.296,
    0.112, 0.259, -0.072, 0.158, 0.005, 0.097, -0.296, 0.079, -0.389, -0.105, -0.181, -0.069, -0.34, -0.215, -0.048, -0.305, 0.313, -0.253, 0.179, -0.07,
    -0.394, 0.084,
]

export function getApertureVector(frame: number) {
    const aidx = frame * 2
    if (aidx < apertureVectors.length) return [apertureVectors[aidx + 0], apertureVectors[aidx + 1]]

    while (true) {
        const x = Math.random() - 0.5
        const y = Math.random() - 0.5
        if (x * x + y * y < 0.25) {
            return [x, y]
        }
    }
}
