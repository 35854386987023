import {Directive, ElementRef, inject, Input, OnChanges, OnInit} from "@angular/core"

@Directive({
    selector: "[cmIsLoading]",
    standalone: true,
})
export class IsLoadingDirective implements OnInit, OnChanges {
    @Input() cmIsLoading: boolean = false

    private elementRef = inject(ElementRef)

    ngOnInit() {
        this.elementRef.nativeElement?.classList?.add("cm-loading-placeholder")
    }

    ngOnChanges() {
        this.elementRef.nativeElement?.classList?.toggle("cm-loading-placeholder--loading", this.cmIsLoading)
    }
}
