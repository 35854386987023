import {Component, inject, Input, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {HistoryType} from "@api"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {pairwise} from "@common/helpers/array"
import {forceEnum} from "@common/helpers/utils/enum"
import {SdkService} from "@common/services/sdk/sdk.service"
import {MomentModule} from "ngx-moment"
import {BehaviorSubject} from "rxjs"
import {IsDefined} from "@cm/lib/utils/filter"
import {Labels, StateLabel} from "@app/platform/models/state-labels"

export type EntityActivityType = {
    id: string
    __typename: string
    state: string
}

@Component({
    standalone: true,
    selector: "cm-entity-activity",
    templateUrl: "./entity-activity.component.html",
    styleUrls: ["./entity-activity.component.scss"],
    imports: [MatTooltipModule, MomentModule, PlaceholderComponent],
})
export class EntityActivityComponent<EntityType extends EntityActivityType> implements OnInit {
    item$ = new BehaviorSubject<EntityType | null | undefined>(undefined)

    @Input({required: true}) set item(item: EntityType) {
        this.item$.next(item)
    }

    activities:
        | {
              createdBy?: {firstName?: string | null; lastName?: string | null} | null
              historyDate?: string | null
              historyType: HistoryType
              stateLabel: string
              previousStateLabel?: string
          }[]
        | undefined = undefined

    sdk = inject(SdkService)

    ngOnInit(): void {
        this.item$.subscribe((item) => this.updateActivities(item))
    }

    async updateActivities(item: EntityType | null | undefined) {
        const dataFetcher = this.dataFetcher(item)
        if (dataFetcher && item) {
            const rawActivities = (await dataFetcher({id: item.id}).then(({items}) => items)).filter(IsDefined)
            const parsedActivities = rawActivities.map((activity) => {
                return {
                    createdBy: activity.createdBy,
                    historyDate: activity.historyDate,
                    historyType: forceEnum(activity.historyType, HistoryType),
                    stateLabel: this.labelForState(activity.state, item),
                }
            })

            if (parsedActivities.length) {
                this.activities = [
                    ...pairwise(parsedActivities).map(([activity, previousActivity]) => {
                        return {
                            ...activity,
                            previousStateLabel: previousActivity.stateLabel,
                        }
                    }),
                    {...parsedActivities?.[parsedActivities.length - 1], previousStateLabel: undefined},
                ].filter(
                    (activity) =>
                        activity.historyType !== HistoryType.Moved || (!!activity.previousStateLabel && activity.previousStateLabel !== activity.stateLabel),
                )
            } else {
                this.activities = undefined
            }
        } else {
            this.activities = undefined
        }
    }

    private dataFetcher = (item: EntityType | null | undefined) => {
        if (!item) {
            return null
        }
        switch (item.__typename) {
            case "Asset":
                return this.sdk.gql.assetActivityItems
            case "Material":
                return this.sdk.gql.materialActivityItems
            case "Model":
                return this.sdk.gql.modelActivityItems
            case "Picture":
                return this.sdk.gql.pictureActivityItems
            default:
                throw new Error("Not implemented")
        }
    }

    private labelForState = (state: string, item: EntityType) => {
        const labelForState_ = (state: string, labels: Map<string, StateLabel<string>>) => {
            const label = labels.get(state)
            if (!label) {
                console.warn(`No label for state ${state}, using state as label`)
                return state
            }
            return label.label
        }

        switch (item.__typename) {
            case "Asset":
            case "Material":
            case "Model":
            case "Picture":
                return labelForState_(state, Labels.States[item.__typename])
            default:
                throw new Error("Not implemented")
        }
    }

    protected readonly HistoryType = HistoryType
}
