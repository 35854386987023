import {Component} from "@angular/core"

@Component({
    selector: "cm-template-menu",
    standalone: true,
    imports: [],
    templateUrl: "./template-menu.component.html",
    styleUrl: "./template-menu.component.scss",
})
export class TemplateMenuComponent {}
