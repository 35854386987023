<div class="cm-container">
    <cm-entity-responsive-sidebar class="cm-entity-sidebar">
        <ng-container>
            <cm-statistics-filters></cm-statistics-filters>
        </ng-container>
    </cm-entity-responsive-sidebar>
    <div class="cm-statistics-list">
        <h2>Pictures</h2>
        <div class="cm-cards-row">
            <mat-card class="cm-section-25">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.picture?.infoReview?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Pictures moved from Info Review to Concept, Refinement or Final Render"
                            class="cm-title"
                        >
                            Info review
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.picture?.infoReview ?? undefined" [contentTypeModel]="ContentTypeModel.Picture"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-25">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.picture?.concept?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Pictures in state Concept passed from Team 1 or Team 2 to Customer"
                            class="cm-title"
                        >
                            Concept
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.picture?.concept ?? undefined" [contentTypeModel]="ContentTypeModel.Picture"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-25">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.picture?.adjustment?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Pictures in state Refinement passed from Team 1 or Team 2 to Customer"
                            class="cm-title"
                        >
                            Refinement
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.picture?.adjustment ?? undefined" [contentTypeModel]="ContentTypeModel.Picture"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-25">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.picture?.finalRender?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Pictures moved from Final Render to Completed"
                            class="cm-title"
                        >
                            Final render
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.picture?.finalRender ?? undefined" [contentTypeModel]="ContentTypeModel.Picture"></cm-change-list>
                </mat-card-content>
            </mat-card>
        </div>
        <h2>Assets</h2>
        <div class="cm-cards-row">
            <mat-card class="cm-section-50">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.asset?.shading?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Assets moved from Shading to Review or Completed"
                            class="cm-title"
                        >
                            Shading
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.asset?.shading ?? undefined" [contentTypeModel]="ContentTypeModel.Asset"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-50">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.asset?.review?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Assets moved from Review to Shading or Completed"
                            class="cm-title"
                        >
                            Review
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.asset?.review ?? undefined" [contentTypeModel]="ContentTypeModel.Asset"></cm-change-list>
                </mat-card-content>
            </mat-card>
        </div>
        <h2>Materials</h2>
        <div class="cm-cards-row">
            <mat-card class="cm-section-20">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.material?.infoReview?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Materials moved from Info Review to Scanning, Tiling or Shading"
                            class="cm-title"
                        >
                            Info review
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.material?.infoReview ?? undefined" [contentTypeModel]="ContentTypeModel.Material"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-20">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.material?.scanning?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Materials moved from Scanning to Tiling or Shading"
                            class="cm-title"
                        >
                            Scanning
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.material?.scanning ?? undefined" [contentTypeModel]="ContentTypeModel.Material"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-20">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.material?.tiling?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Materials moved from Tiling to Shading or Review"
                            class="cm-title"
                        >
                            Tiling
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.material?.tiling ?? undefined" [contentTypeModel]="ContentTypeModel.Material"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-20">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.material?.shading?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Materials moved from Shading to Review or Completed"
                            class="cm-title"
                        >
                            Shading
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.material?.shading ?? undefined" [contentTypeModel]="ContentTypeModel.Material"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-20">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.material?.review?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Materials moved from Review to Scanning, Tiling, Shading or Completed"
                            class="cm-title"
                        >
                            Review
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.material?.review ?? undefined" [contentTypeModel]="ContentTypeModel.Material"></cm-change-list>
                </mat-card-content>
            </mat-card>
        </div>
        <h2>Models</h2>
        <div class="cm-cards-row">
            <mat-card class="cm-section-30">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.model?.infoReview?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Models moved from Info Review to Modelling"
                            class="cm-title"
                        >
                            Info review
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.model?.infoReview ?? undefined" [contentTypeModel]="ContentTypeModel.Model"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-30">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.model?.modelling?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Models moved from Modelling to Review or Completed"
                            class="cm-title"
                        >
                            Modelling
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.model?.modelling ?? undefined" [contentTypeModel]="ContentTypeModel.Model"></cm-change-list>
                </mat-card-content>
            </mat-card>
            <mat-card class="cm-section-30">
                <mat-card-header>
                    <mat-card-title>
                        <div
                            [matBadge]="data?.model?.review?.length || '&nbsp;'"
                            [matBadgeOverlap]="false"
                            matTooltip="Models moved from Review to Completed or Modelling"
                            class="cm-title"
                        >
                            Review
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <cm-change-list [changeList]="data?.model?.review ?? undefined" [contentTypeModel]="ContentTypeModel.Model"></cm-change-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
