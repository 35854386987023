import {Primitives as Prim, Operators as Op, StandardGeometryAttributes, AttributeExpr, Tilings} from "@src/geometry-processing/geometry-graph"

export function plane3(
    x0: number,
    y0: number,
    z0: number,
    ux: number,
    uy: number,
    uz: number,
    vx: number,
    vy: number,
    vz: number,
    u0: number,
    v0: number,
    u1: number,
    v1: number,
) {
    const uv = Prim.grid2(u0, v0, u1 - u0, 0, 0, v1 - v0, 1, 1)
    const u = uv.x
    const v = uv.y
    return {
        pos: Op.pack(u.mul(ux).add(v.mul(vx)).add(x0), u.mul(uy).add(v.mul(vy)).add(y0), u.mul(uz).add(v.mul(vz)).add(z0)),
        materialID: uv.constInt(0),
        uv,
    }
}

export function cubePrimitive(x0: number, x1: number, y0: number, y1: number, z0: number, z1: number) {
    const xSeg = {x: Prim.line1(x0, x1, 1)}
    const ySeg = {y: Prim.line1(y0, y1, 1)}
    const zSeg = {z: Prim.line1(z0, z1, 1)}
    const xzPlane = Op.disjointProduct("x", xSeg, "z", zSeg) // looking from bottom
    const xyPlane = Op.disjointProduct("x", xSeg, "y", ySeg) // looking from front
    const zyPlane = Op.disjointProduct("z", zSeg, "y", ySeg) // looking from left
    const bottom = {
        pos: Op.pack(xzPlane.x, xzPlane.x.constFloat(y0), xzPlane.z),
        faceID: xzPlane.x.constInt(0),
        materialID: xzPlane.x.constInt(0),
        uv: Op.pack(xzPlane.x, xzPlane.z),
    }
    const top = Op.flip({
        pos: Op.pack(xzPlane.x, xzPlane.x.constFloat(y1), xzPlane.z),
        faceID: xzPlane.x.constInt(5),
        materialID: xzPlane.x.constInt(2),
        uv: Op.pack(xzPlane.x, xzPlane.z.neg()),
    })
    const left = {
        pos: Op.pack(zyPlane.z.constFloat(x0), zyPlane.y, zyPlane.z),
        faceID: zyPlane.z.constInt(1),
        materialID: zyPlane.z.constInt(1),
        uv: Op.pack(zyPlane.z, zyPlane.y),
    }
    const right = Op.flip({
        pos: Op.pack(zyPlane.z.constFloat(x1), zyPlane.y, zyPlane.z),
        faceID: zyPlane.z.constInt(3),
        materialID: zyPlane.z.constInt(1),
        uv: Op.pack(zyPlane.z.neg(), zyPlane.y),
    })
    const front = {
        pos: Op.pack(xyPlane.x, xyPlane.y, xyPlane.x.constFloat(z1)),
        faceID: xyPlane.x.constInt(2),
        materialID: xyPlane.x.constInt(1),
        uv: Op.pack(xyPlane.x, xyPlane.y),
    }
    const back = Op.flip({
        pos: Op.pack(xyPlane.x, xyPlane.y, xyPlane.x.constFloat(z0)),
        faceID: xyPlane.x.constInt(4),
        materialID: xyPlane.x.constInt(1),
        uv: Op.pack(xyPlane.x.neg(), xyPlane.y),
    })
    return Op.merge(bottom, top, left, right, front, back)
}

function jitter2(pt: AttributeExpr, sc = 0.5) {
    const r = pt.hashFloat()
    return Op.pack(pt.x.add(r.sub(0.5).mul(sc)), pt.y.add(r.sub(0.5).mul(sc)))
}

function jitter3(pt: AttributeExpr, sc = 0.5) {
    const r = pt.hashFloat()
    return Op.pack(pt.x.add(r.sub(0.5).mul(sc)), pt.y.add(r.sub(0.5).mul(sc)), pt.z.add(r.sub(0.5).mul(sc)))
}

function transform(pt: AttributeExpr, vx: AttributeExpr, vy: AttributeExpr, vz: AttributeExpr) {
    return Op.pack(
        vx.x.mul(pt.x).add(vy.x.mul(pt.x)).add(vz.x.mul(pt.x)),
        vx.y.mul(pt.y).add(vy.y.mul(pt.y)).add(vz.y.mul(pt.y)),
        vx.z.mul(pt.z).add(vy.z.mul(pt.z)).add(vz.z.mul(pt.z)),
    )
}

function simpleStudioRoom(params: {
    width?: number
    length?: number
    inset?: number
    height?: number
    tileDepth?: number
    showFloor?: boolean
    showWalls?: boolean
    showCeiling?: boolean
}): StandardGeometryAttributes {
    const w = (params.width ?? 10) * 100
    const h = (params.length ?? 10) * 100
    const inset = params.inset ?? 0.5
    const depth = (params.height ?? 2) * 100
    const tileDepth = params.tileDepth ?? 0
    const showFloor = params.showFloor ?? true
    const showWalls = params.showWalls ?? true
    const showCeiling = params.showCeiling ?? true
    const floorplan = {uv: Prim.grid2(-w / 2, -h / 2, w, 0, 0, h, 1, 1)}
    const outline = Op.boundary("uv", floorplan)
    const sweep = {u: Prim.line1(0, depth, 1)}
    const wallsP = Op.disjointProduct("uv", {...outline, v: outline.uv.flatten()}, "u", sweep)
    let floorTiling = Tilings.rectangularTiling(-w / 2, -h / 2, w / 2, h / 2, 200, 20, 3, inset)
    let _unused: any
    ;[floorTiling, _unused] = Op.join("uv", floorTiling, "uv", floorplan)
    let floor = Op.flip({
        pos: Op.pack(floorplan.uv.x, floorplan.uv.constFloat(-tileDepth), floorplan.uv.y),
        materialID: floorplan.uv.constInt(0),
        uv: floorplan.uv,
    })
    const ceiling = {pos: Op.pack(floorplan.uv.x, floorplan.uv.constFloat(depth), floorplan.uv.y), materialID: floorplan.uv.constInt(2), uv: floorplan.uv}
    const walls = {pos: Op.pack(wallsP.uv.x, wallsP.u, wallsP.uv.y), materialID: wallsP.uv.constInt(1), uv: Op.pack(wallsP.v, wallsP.u)}
    if (tileDepth > 0) {
        const tileSweep = {u: Prim.line1(-tileDepth, 0, 1)}
        const tilingP = Op.disjointProduct("uv", floorTiling, "u", tileSweep)
        const tiles = {pos: Op.pack(tilingP.uv.x, tilingP.u, tilingP.uv.y), materialID: tilingP.tileID.hashInt().mod(1), uv: tilingP.uv}
        floor = Op.merge(floor, tiles)
    }
    const components: (typeof floor)[] = []
    if (showFloor) components.push(floor)
    if (showWalls) components.push(walls)
    if (showCeiling) components.push(ceiling)
    if (components.length === 0) {
        //TODO: Allow empty geometry. Need to create degenerate plane for now...
        const degenerate_uv = Prim.grid2(0, 0, 0, 0, 0, 0, 1, 1)
        const empty = {pos: Op.pack(degenerate_uv.x, degenerate_uv.constFloat(0), degenerate_uv.y), materialID: degenerate_uv.constInt(0), uv: degenerate_uv}
        components.push(empty)
    }
    const room = Op.merge(...components)
    return {
        position: room.pos,
        normal: room.pos.normals(),
        materialID: room.materialID,
        uv: room.uv,
    }
}

function cube(params: {width?: number; height?: number; depth?: number; inside?: boolean; faceMaterials?: boolean}): StandardGeometryAttributes {
    const w = params.width ?? 100
    const h = params.height ?? 100
    const d = params.depth ?? 100
    const inside = params.inside ?? false
    const faceMats = params.faceMaterials ?? false
    const x0 = -w / 2,
        x1 = w / 2
    const y0 = 0,
        y1 = h //TODO: center cubes (use "box" for bottom at origin)
    const z0 = -d / 2,
        z1 = d / 2
    let cube = cubePrimitive(x0, x1, y0, y1, z0, z1)
    if (inside) {
        cube = Op.flip({...cube, uv: Op.pack(cube.uv.x.neg(), cube.uv.y)})
    }
    return {
        position: cube.pos,
        normal: cube.pos.normals(),
        materialID: faceMats ? cube.faceID : cube.materialID,
        uv: cube.uv,
    }
}

function box(params: {width?: number; height?: number; depth?: number; inside?: boolean; faceMaterials?: boolean}): StandardGeometryAttributes {
    const w = params.width ?? 100
    const h = params.height ?? 100
    const d = params.depth ?? 100
    const inside = params.inside ?? false
    const faceMats = params.faceMaterials ?? false
    const x0 = -w / 2,
        x1 = w / 2
    const y0 = 0,
        y1 = h
    const z0 = -d / 2,
        z1 = d / 2
    let cube = cubePrimitive(x0, x1, y0, y1, z0, z1)
    if (inside) {
        cube = Op.flip({...cube, uv: Op.pack(cube.uv.x.neg(), cube.uv.y)})
    }
    return {
        position: cube.pos,
        normal: cube.pos.normals(),
        materialID: faceMats ? cube.faceID : cube.materialID,
        uv: cube.uv,
    }
}

function plane(params: {width?: number; height?: number}): StandardGeometryAttributes {
    const w = params.width ?? 100
    const h = params.height ?? 100
    const plane = plane3(0, 0, 0, 1, 0, 0, 0, 0, -1, -w / 2, -h / 2, w / 2, h / 2)
    return {
        position: plane.pos,
        normal: plane.pos.normals(),
        materialID: plane.materialID,
        uv: plane.uv,
    }
}

function sphere(params: {radius?: number; numU?: number; numV?: number}): StandardGeometryAttributes {
    const radius = params.radius ?? 10
    const numU = params.numU ?? 64
    const numV = params.numV ?? 32
    const sphere = Prim.uvSphere(radius, numU, numV)
    sphere.uv = Op.pack(sphere.uv.x.mul(radius * Math.PI * 2), sphere.uv.y.sub(0.5).mul(radius * Math.PI))
    return {
        position: sphere.position,
        normal: sphere.position.normals(1),
        materialID: sphere.uv.constInt(0),
        uv: sphere.uv,
    }
}

function transformUVsForClipping(uvAttr: AttributeExpr, offset: [number, number], rotation: number, size: [number, number]) {
    //TODO: unify this with what is in web-assembly.worker.ts
    const c = Math.cos(rotation)
    const s = Math.sin(rotation)
    const [cx, cy] = offset
    const ox = size[0] / 2
    const oy = size[1] / 2

    let [u, v] = uvAttr.unpack2()
    u = u.sub(cx)
    v = v.sub(cy)
    return Op.pack(
        u.mul(c).sub(v.mul(s)).add(ox), // transformed U
        u.mul(s).add(v.mul(c)).add(oy), // transformed V
    )
}

export function clipAndOffsetMeshForDecal(
    input: StandardGeometryAttributes,
    uvOffset: [number, number],
    rotation: number,
    size: [number, number],
    normalOffset: number,
): StandardGeometryAttributes {
    //TODO: handle multiple UV channels
    input = {
        ...input,
        uv: transformUVsForClipping(input.uv, uvOffset, rotation, size),
    }
    const clipUV = Prim.grid2(0, 0, size[0], 0, 0, size[1], 1, 1)
    const [output, _] = Op.join("uv", input, "uv", {uv: clipUV})
    output.position = output.position.add(output.normal.mul(normalOffset))
    return output
}

export const commonGeometryGraphTable: {[name: string]: (params: any) => StandardGeometryAttributes} = {
    simpleStudioRoom: simpleStudioRoom,
    cube: cube,
    box: box,
    plane: plane,
    sphere: sphere,
}
