import {Component, computed, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, DownloadResolution, HdriDetailsFragment, MutationUpdateHdriInput} from "@api"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {AttachmentsComponent} from "@common/components/files/attachments/attachments.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {HdriService} from "@editor/services/hdri.service"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {CopyToClipboardButtonComponent} from "@common/components/buttons/copy-to-clipboard-button/copy-to-clipboard-button.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {IsLoadingDirective} from "@common/directives"
import {DropFilesComponent} from "@app/common/components/files"
import {MatIcon} from "@angular/material/icon"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {DialogSize} from "@app/common/models/dialogs"
import {CopyValueToClipboardMenuComponent} from "@platform/components/shared/copy-value-to-clipboard-menu/copy-value-to-clipboard-menu.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"

@Component({
    selector: "cm-hdri-details",
    templateUrl: "hdri-details.component.html",
    styleUrls: ["hdri-details.component.scss"],
    standalone: true,
    imports: [
        CopyToClipboardButtonComponent,
        DetailsDialogLayoutComponent,
        SectionComponent,
        ThumbnailComponent,
        AttachmentsComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        IsLoadingDirective,
        DropFilesComponent,
        RoutedDialogComponent,
        MatIcon,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        TitleSectionComponent,
        DataObjectThumbnailComponent,
        CopyValueToClipboardMenuComponent,
        OrganizationSelectComponent,
    ],
})
export class HdriDetailsComponent extends BaseDetailsComponent<HdriDetailsFragment, Omit<MutationUpdateHdriInput, "id">> {
    override _contentTypeModel = ContentTypeModel.Hdri
    override _fetchItem = this.sdk.gql.hdriDetails
    override _updateItem = this.sdk.gql.updateHdriDetails
    override _deleteItem = this.sdk.raw.deleteHdri

    organizations = inject(OrganizationsService)
    hdriService = inject(HdriService)

    $canAddHdri = computed(() => this.can.changeFiles(this.$item()?.organization?.id) && this.can.update(this.$item()))

    protected DownloadResolution = DownloadResolution

    performDelete = async () => {
        const item = this.$item()
        if (item) {
            await this.sdk.gql.deleteHdri(item)
            await this.router.navigate(["/hdris"])
            this.refresh.item(item)
        }
    }

    async uploadHdri(files: File[]) {
        if (files.length !== 1) throw new Error("Upload of multiple files not supported")
        const item = this.$item()
        if (!item) throw new Error("Hdri details not set")
        await this.hdriService.uploadHdriFromFile(files[0], item)
        this.refresh.item(item)
    }

    deleteConfirmationMessage() {
        const item = this.$item()
        return (
            (item ? `The HDRI '${item.name}'` : "This HDRI") +
            " will be deleted. This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?"
        )
    }

    $copyItems = computed(() => {
        const result: {value: string | undefined | (() => string | undefined); displayText: string; icon: string}[] = [
            {value: this.$item()?.legacyId?.toString(), displayText: "Legacy ID", icon: "elderly"},
            {value: this.$item()?.id, displayText: "ID", icon: "badge"},
        ]
        return result
    })

    protected readonly DialogSize = DialogSize
}
