import {Nodes} from "@src/templates/legacy/template-nodes"
import {NodeUtils} from "@src/templates/legacy/template-node-utils"
import {BuilderInlet, BuilderOutlet, isBuilderOutlet} from "@src/templates/runtime-graph/graph-builder"
import {TemplateImageData, TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {FindMaterial} from "@src/templates/runtime-graph/nodes/find-material"
import {TransformColorOverlay} from "@src/templates/runtime-graph/nodes/transform-color-overlay"
import {SolverVariableData} from "@src/templates/runtime-graph/nodes/solver/variable-data"
import {FetchDataObject} from "@src/templates/runtime-graph/nodes/fetch-data-object"
import {LoadGraph} from "@src/templates/runtime-graph/nodes/load-graph"
import {
    EvaluatedTemplateInput,
    EvaluatedTemplateInputs,
    EvaluatedTemplateOutputs,
    EvaluatedTemplateValueType,
    getTemplateInput,
} from "@src/templates/legacy/evaluation/template-slots"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {ThisStructID} from "@src/templates/runtime-graph/types"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {LoadMaterialRevision} from "@src/templates/runtime-graph/nodes/load-material-revision"
import {ISceneManager} from "@src/templates/interfaces/scene-manager"

export type TemplateContext = {
    sceneManager: ISceneManager
    maxSubdivisionLevel?: number
    showAnnotations?: boolean
    defaultCustomerId?: number
}

const TD = TypeDescriptors

export function makeTemplateNodeEvaluator({
    idMap,
    templateScope,
    templateContext,
    ambientInputs,
    readyList,
    activeNodeSet,
    solverVariables,
}: {
    idMap: {intern(x: object): string}
    templateScope: GraphBuilderScope
    templateContext: TemplateContext
    ambientInputs: EvaluatedTemplateInputs
    readyList?: BuilderOutlet<any>[]
    activeNodeSet?: Set<Nodes.Node>
    solverVariables?: BuilderInlet<SolverVariableData>[]
}) {
    const getLocalId = (x: Nodes.Node | null) => {
        if (!x) return x
        const localId: string = (x as any).$localId
        if (localId) return localId
        else return idMap.intern(x)
    }

    const getTemplateOutput = <T>(scope: GraphBuilderScope, node: Nodes.GetTemplateOutput<any>, type: EvaluatedTemplateValueType) => {
        if (node.outputType != null && type !== node.outputType) {
            throw Error(`Template output type mismatch: expected ${type}, got ${node.outputType}`)
        }
        if (!node.template) {
            console.warn("Reference to output of null template node:", node)
            return null
        }
        const outputs = templateScope.resolve<EvaluatedTemplateOutputs>(`templateOutputs-${getLocalId(node.template)}`)
        return scope.get(outputs, `output-${type}-${node.outputId}`) as BuilderOutlet<T>
    }

    const fetchDataObject = (scope: GraphBuilderScope, dataObjectId: BuilderInlet<number>) => {
        return scope.node(FetchDataObject, {
            sceneManager: templateContext.sceneManager,
            dataObjectId,
        }).dataObject
    }

    const evalSwitch = <T extends Nodes.Node>(node: any): T | null => {
        if (node == null) return node
        else if (typeof node !== "object") return node
        else if (node.type === "switch") {
            const nodes = (node as Nodes.Switch<T>).nodes
            if (nodes) {
                for (const src of nodes) {
                    if (!activeNodeSet || activeNodeSet.has(src)) {
                        return src
                    } else if (src.type === "surfaceReference" && activeNodeSet.has((src as any).object)) {
                        return src
                    }
                }
            }
            return null
        } else {
            return node
        }
    }

    const evalString = (scope: GraphBuilderScope, value: any): BuilderInlet<string | null> => {
        if (value == null) {
            console.warn("evalString was passed null/undefined!")
            return null
        }
        if (NodeUtils.isNode(value)) {
            const node = evalSwitch<Nodes.Node>(value)
            if (!node) return null
            if (node.type === "value") {
                return String(node.value)
            } else if (node.type === "templateInput") {
                //TODO: Handle case where the template input value is undefined. What should happen?
                return getTemplateInput<string>(ambientInputs, node) ?? evalString(scope, node.default)
            } else if (node.type === "resolveNode") {
                return node.nodeId
            } else if (node.type === "regexReplace") {
                const input = evalString(scope, node.input)
                const regex = evalString(scope, node.regex)
                const replace = evalString(scope, node.replace)
                return scope.lambda(
                    scope.tuple(input, regex, replace),
                    ([input, regex, replace]) => {
                        if (input === null || regex === null || replace === null) return null
                        return input.replace(new RegExp(regex), replace)
                    },
                    "regexpReplace",
                )
            } else {
                return null
            }
        }
        return String(value)
    }

    const evalBoolean = (scope: GraphBuilderScope, value: any): BuilderInlet<boolean> => {
        if (NodeUtils.isNode(value)) {
            const node = evalSwitch<Nodes.Node>(value)
            if (!node) return false
            if (node.type === "value") {
                return Boolean(node.value)
            } else if (node.type === "templateInput") {
                //TODO: Handle case where the template input value is undefined. What should happen?
                return getTemplateInput<boolean>(ambientInputs, node) ?? evalBoolean(scope, node.default)
            } else {
                return false
            }
        }
        return Boolean(value)
    }

    const evalNumber = (scope: GraphBuilderScope, value: any): BuilderInlet<number> => {
        if (value == null) {
            return value as any
        }
        if (NodeUtils.isNode(value)) {
            const node = evalSwitch<Nodes.Node>(value)
            if (!node) return 0
            if (node.type === "value") {
                return Number(node.value)
            } else if (node.type === "templateInput") {
                //TODO: Handle case where the template input value is undefined. What should happen?
                return getTemplateInput<number>(ambientInputs, node) ?? evalNumber(scope, node.default)
            } else {
                return 0
            }
        }
        return Number(value)
    }

    const evalArray = <T>(
        scope: GraphBuilderScope,
        evalFn: (scope: GraphBuilderScope, value: any) => BuilderInlet<T>,
        value: any,
    ): BuilderInlet<T[] | null> => {
        if (!value) {
            return value
        } else if (NodeUtils.isNode(value)) {
            const node = evalSwitch<Nodes.Node>(value)
            if (!node) return null
            if (node.type === "value") {
                return node.value
            } else {
                return null
            }
        } else if (Array.isArray(value)) {
            return scope.list(value.map((x) => evalFn(scope, x)))
        } else {
            return null
        }
    }

    const evalMaterial = makeMemoizingEvaluator(getLocalId, (scope: GraphBuilderScope, node: Nodes.Material | null): BuilderInlet<IMaterialGraph | null> => {
        if (!node) {
            return null
        } else if (NodeUtils.isSwitch(node)) {
            return evalMaterial(scope, evalSwitch<Nodes.Material>(node))
        } else if (node.type === "materialReference") {
            if (node.allowOverride) {
                const input = getTemplateInput<IMaterialGraph>(ambientInputs, node)
                if (input) return input
            }
            if (node.materialRevisionId == null) return null
            const {materialGraph} = scope.node(LoadMaterialRevision, {
                sceneManager: templateContext.sceneManager,
                materialRevisionId: node.materialRevisionId,
            })
            readyList?.push(materialGraph)
            return materialGraph
        } else if (node.type === "materialGraphReference") {
            if (node.graph == null) return null
            const materialGraph = scope.value(node.graph, TD.MaterialGraph)
            readyList?.push(materialGraph)
            return materialGraph
        } else if (node.type === "findMaterial") {
            const {material} = scope.node(FindMaterial, {
                sceneManager: templateContext.sceneManager,
                articleId: (node.articleId && evalString(scope, node.articleId)) ?? null,
                customerId: evalNumber(scope, node.customerId) ?? templateContext.defaultCustomerId ?? null,
            })
            const [materialValid, materialInvalid] = scope.branch(material)
            const materialRevisionId = scope.lambda(
                materialValid,
                (material) => {
                    const latestRevision = material.getLatestCyclesRevision()
                    if (!latestRevision) return null
                    return latestRevision.id
                },
                "getMaterialRevisionId",
            )
            const [materialRevisionIdValid, materialRevisionIdInvalid] = scope.branch(materialRevisionId)
            const {materialGraph} = scope.node(LoadMaterialRevision, {
                sceneManager: templateContext.sceneManager,
                materialRevisionId: materialRevisionIdValid,
            })
            const res = scope.phi(materialGraph, materialInvalid, materialRevisionIdInvalid)
            readyList?.push(res)
            return res
        } else if (node.type === "overlayMaterialColor") {
            const material = evalMaterial(scope, node.material)
            const image = evalImage(scope, node.overlay)
            const size = evalArray(scope, evalNumber, node.size) as BuilderInlet<[number, number]>
            const [materialValid, materialInvalid] = scope.branch(material)
            const [imageValid, imageInvalid] = scope.branch(image)
            const result = scope.node(TransformColorOverlay, {
                material: materialValid,
                image: imageValid,
                size,
                useAlpha: true,
                sceneManager: templateContext.sceneManager,
            }).outputMaterial
            return scope.phi(result, materialInvalid, imageInvalid)
        } else if (node.type === "templateInput" && node.inputType === "material") {
            return getTemplateInput<IMaterialGraph>(ambientInputs, node) ?? evalMaterial(scope, node.default ?? null)
        } else if (node.type === "getTemplateOutput") {
            return getTemplateOutput<IMaterialGraph>(scope, node, "material")
        } else {
            return null
        }
    })

    const evalImage = makeMemoizingEvaluator(getLocalId, (scope: GraphBuilderScope, node: Nodes.Image | null): BuilderInlet<TemplateImageData | null> => {
        if (!node) {
            return null
        } else if (NodeUtils.isSwitch(node)) {
            return evalImage(scope, evalSwitch<Nodes.Image>(node))
        } else if (node.type === "dataObjectReference") {
            if (node.dataObjectId === undefined || node.dataObjectId === null) return null
            return scope.struct<TemplateImageData>("TemplateImageData", {
                dataObject: fetchDataObject(scope, node.dataObjectId),
            })
        } else if (node.type === "transientDataObject") {
            return {
                dataObject: templateContext.sceneManager.createTransientDataObject(node.data, node.contentType, node.imageColorSpace),
            }
        } else if (node.type === "templateInput" && node.inputType === "image") {
            return getTemplateInput<TemplateImageData>(ambientInputs, node) ?? evalImage(scope, node.default ?? null)
        } else if (node.type === "getTemplateOutput") {
            return getTemplateOutput<TemplateImageData>(scope, node, "image")
        } else {
            return null
        }
    })

    const evalTemplateDefinition = makeMemoizingEvaluator(
        getLocalId,
        (scope: GraphBuilderScope, node: Nodes.TemplateDefinition | null): BuilderInlet<Nodes.TemplateGraph | null> => {
            if (!node) {
                return null
            } else if (NodeUtils.isSwitch(node)) {
                return evalTemplateDefinition(scope, evalSwitch<Nodes.TemplateDefinition>(node))
            } else if (node.type === "templateReference") {
                return scope.node(LoadGraph, {
                    sceneManager: templateContext.sceneManager,
                    templateRevisionId: node.templateRevisionId,
                }).graph
            } else if (node.type === "templateGraph") {
                return node
            } else if (node.type === "templateInput" && node.inputType === "template") {
                return getTemplateInput<Nodes.TemplateGraph>(ambientInputs, node) ?? evalTemplateDefinition(scope, node.default ?? null)
            } else if (node.type === "getTemplateOutput") {
                return getTemplateOutput<Nodes.TemplateGraph>(scope, node, "template")
            } else {
                return null
            }
        },
    )

    const evalObjectData = makeMemoizingEvaluator(
        getLocalId,
        (scope: GraphBuilderScope, node: Nodes.ObjectReference | null): BuilderInlet<ObjectData | null> => {
            if (!node) {
                return null
            } else if (NodeUtils.isSwitch(node)) {
                return evalObjectData(scope, evalSwitch<Nodes.ObjectReference>(node))
            } else if (node.type === "templateInput" && node.inputType === "object") {
                return getTemplateInput<ObjectData>(ambientInputs, node) ?? evalObjectData(scope, node.default ?? null)
            }
            if (node.type === "getTemplateOutput") {
                return getTemplateOutput<ObjectData>(scope, node, "object")
            } else {
                return templateScope.resolve<ObjectData>(`objectData-${getLocalId(node)}`)
            }
        },
    )

    const evalVariable = (scope: GraphBuilderScope, node: Nodes.Variable) => {
        const data = scope.struct<SolverVariableData>("SolverVariableData", {
            id: ThisStructID,
            topology: node.topology,
            default: node.default,
            range: "range" in node ? node.range : undefined,
        })
        solverVariables?.push(data)
        return data
    }

    const evalTranslation = (scope: GraphBuilderScope, trans: Nodes.RelationTranslation) => {
        type RelationTranslationData = {
            tx: number | SolverVariableData
            ty: number | SolverVariableData
            tz: number | SolverVariableData
        }
        return scope.struct<RelationTranslationData>("RelationTranslation", {
            tx: typeof trans.x === "number" ? trans.x : evalVariable(scope, trans.x),
            ty: typeof trans.y === "number" ? trans.y : evalVariable(scope, trans.y),
            tz: typeof trans.z === "number" ? trans.z : evalVariable(scope, trans.z),
        })
    }

    const evalRotation = (scope: GraphBuilderScope, rot: Nodes.RelationRotation) => {
        if (rot.type === "hinge") {
            return scope.struct("RelationRotation_hinge", {
                type: "hinge" as const,
                axis: rot.axis,
                angleVariable: evalVariable(scope, rot.rotation),
            })
        } else if (rot.type === "ball") {
            return scope.struct("RelationRotation_ball", {
                type: "ball" as const,
                angleVariable: evalVariable(scope, rot.rotation),
            })
        } else {
            return scope.struct("RelationRotation_fixed", {
                type: "fixed" as const,
                rx: rot.x,
                ry: rot.y,
                rz: rot.z,
            })
        }
    }

    const evalTemplateInputs = (scope: GraphBuilderScope, node: Nodes.TemplateOrInstance | Nodes.TemplateReference) => {
        const evaledInputs: EvaluatedTemplateInputs = {}
        const claimedInputIds: string[] = []
        const externalId = Nodes.getExternalId(node)

        for (const [inputId, value] of Object.entries(Nodes.Meta.getAllParameters(node as Nodes.TemplateOrInstance))) {
            if (value == null) continue
            claimedInputIds.push(inputId) //TODO: distinguish between inputs should and should not be hidden
            if (NodeUtils.isNode(value)) {
                const valueNode = evalSwitch<Nodes.Node>(value)
                if (valueNode) {
                    if (NodeUtils.resolvesToMaterial(valueNode)) {
                        setTemplateInput(scope, evaledInputs, "material", inputId, evalMaterial(scope, valueNode))
                    } else if (NodeUtils.resolvesToTemplateDefinition(valueNode)) {
                        setTemplateInput(scope, evaledInputs, "template", inputId, evalTemplateDefinition(scope, valueNode))
                    } else if (NodeUtils.resolvesToObject(valueNode)) {
                        setTemplateInput(scope, evaledInputs, "object", inputId, evalObjectData(scope, valueNode))
                    } else if (NodeUtils.resolvesToImage(valueNode)) {
                        setTemplateInput(scope, evaledInputs, "image", inputId, evalImage(scope, valueNode))
                    } else if (NodeUtils.resolvesToString(valueNode)) {
                        setTemplateInput(scope, evaledInputs, "string", inputId, evalString(scope, valueNode))
                    } else if (NodeUtils.resolvesToNumber(valueNode)) {
                        setTemplateInput(scope, evaledInputs, "number", inputId, evalNumber(scope, valueNode))
                    } else if (NodeUtils.resolvesToBoolean(valueNode)) {
                        setTemplateInput(scope, evaledInputs, "boolean", inputId, evalBoolean(scope, valueNode))
                    } else {
                        console.warn("Cannot use node as input:", value)
                    }
                }
            } else if (typeof value === "string") {
                setTemplateInput(scope, evaledInputs, "string", inputId, value)
            } else if (typeof value === "number") {
                setTemplateInput(scope, evaledInputs, "number", inputId, value)
            } else if (typeof value === "boolean") {
                setTemplateInput(scope, evaledInputs, "boolean", inputId, value)
            } else {
                setTemplateInput(scope, evaledInputs, "unknown", inputId, value)
            }
        }

        for (const [inputId, entry] of Object.entries(ambientInputs)) {
            const [prefix, unwrappedId] = Nodes.Meta.unwrapInterfaceId(inputId)
            if (prefix === externalId) {
                evaledInputs[unwrappedId] = entry
            }
        }

        return [scope.record(evaledInputs, "evaledInput"), claimedInputIds] as const
    }

    return {
        getLocalId,
        getTemplateOutput,
        fetchDataObject,
        evalSwitch,
        evalString,
        evalBoolean,
        evalNumber,
        evalMaterial,
        evalTemplateDefinition,
        evalObjectData,
        evalImage,
        evalVariable,
        evalTranslation,
        evalRotation,
        evalTemplateInputs,
    }
}

function makeMemoizingEvaluator<C, K, T, R>(keyFn: (x: T) => K, evalFn: (context: C, x: T) => R): (context: C, x: T) => R | null {
    const cache = new Map<K, R>()
    return (context, x) => {
        if (x === undefined) return null
        else if (x === null) return null
        const k = keyFn(x)
        let y = cache.get(k)
        if (y === undefined) {
            y = evalFn(context, x)
            cache.set(k, y)
        }
        return y
    }
}

function setTemplateInput(
    scope: GraphBuilderScope,
    inputs: {[inputId: string]: BuilderInlet<EvaluatedTemplateInput>},
    type: EvaluatedTemplateValueType,
    id: string,
    value: EvaluatedTemplateInput["value"],
) {
    if (isBuilderOutlet(value)) {
        inputs[id] = scope.struct("EvaluatedTemplateInput", {type, value})
    } else {
        inputs[id] = {type, value}
    }
}
