// @ts-strict-ignore
import {NgTemplateOutlet} from "@angular/common"
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    InjectionToken,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core"
import {forkJoinZeroOrMore} from "@legacy/helpers/utils"
import {Matrix4, Vector3} from "@common/helpers/vector-math"
import {SelectionEvent} from "@editor/helpers/scene/selection"
import {InteractiveSceneView, SceneView, SceneViewConfiguration, SceneViewManager} from "@editor/models/editor"
import {Observable, Subject, switchMap, takeUntil} from "rxjs"

const SCENE_VIEW = "SceneView"
const INTERACTIVE_SCENE_VIEW = "InteractiveSceneView"
type SceneViewType = typeof SCENE_VIEW | typeof INTERACTIVE_SCENE_VIEW
const SCENE_VIEW_TYPE = new InjectionToken<SceneViewType>("sceneViewType")

function fitCameraFrameRect(aspectRatio: number, maxWidth: number, maxHeight: number): [number, number] {
    // aspectRatio: width/height
    let width: number
    let height: number
    if (aspectRatio < 1) {
        height = maxHeight
        width = height * aspectRatio
        if (width > maxWidth) {
            width = maxWidth
            height = width / aspectRatio
        }
    } else {
        width = maxWidth
        height = width / aspectRatio
        if (height > maxHeight) {
            height = maxHeight
            width = height * aspectRatio
        }
    }
    return [width, height]
}

@Component({
    templateUrl: "./scene-view.component.html",
    standalone: true,
    imports: [NgTemplateOutlet],
})
class SceneViewBaseComponent<SceneViewT extends SceneView | InteractiveSceneView> implements OnDestroy, AfterViewInit {
    @Input() viewportTemplate: TemplateRef<HTMLDivElement>
    @ViewChild("defaultViewportTemplate", {static: true}) defaultViewportTemplate: TemplateRef<HTMLDivElement>
    viewport: ElementRef<HTMLDivElement>
    private viewportContainer: ElementRef<HTMLDivElement>
    private resizeObserver?: ResizeObserver

    @Input() defaultCameraPosition: [number, number, number] = [0, 0, 100]
    @Input() defaultCameraTarget: [number, number, number] = [0, 0, 0]

    @Input() id: string

    private _fixedAspectRatio = false
    @Input() set fixedAspectRatio(fixedAspectRatio: boolean) {
        if (this._fixedAspectRatio === fixedAspectRatio) return
        this._fixedAspectRatio = fixedAspectRatio
        this.updateSize()
    }

    private _config: SceneViewConfiguration
    @Input() set config(config: SceneViewConfiguration) {
        this._config = config
        if (this.sceneView) {
            this.sceneView.setConfig(config)
            this.updateSize(false)
        }
    }

    get config(): SceneViewConfiguration {
        return this.sceneView.config
    }

    private _cameraId?: string
    @Input() set cameraId(cameraId: string | undefined) {
        this._cameraId = cameraId
        this.sceneView?.setCameraId(cameraId)
    }

    get cameraId(): string {
        return this._cameraId
    }

    @HostListener("window:resize", ["$event"]) onResize(_event: Event) {
        this.updateSize()
    }

    _viewManager: SceneViewManager
    @Input() set viewManager(manager: SceneViewManager) {
        this._viewManager = manager
    }

    @Output() sizeChanged: EventEmitter<[number, number]> = new EventEmitter<[number, number]>()

    private _pendingTasks: Observable<void>[] = []
    sceneView: SceneViewT

    protected destroySubject: Subject<void> = new Subject<void>()

    constructor(
        @Inject(SCENE_VIEW_TYPE) private type: SceneViewType,
        private elementRef: ElementRef,
    ) {}

    private _afterViewInit = false
    ngAfterViewInit(): void {
        // locate viewport and viewport container HTML element from the component elementRef (for some reason this doesn't work with ContentChild decorator)
        const viewportContainerElement = Array.from(this.elementRef.nativeElement.children).find(
            (n) => typeof n === "object" && "nodeName" in n && (n as {nodeName: string}).nodeName === "DIV",
        ) as HTMLDivElement
        if (!viewportContainerElement) throw new Error("Viewport container element not found")
        const viewportElement = Array.from(viewportContainerElement.children).find(
            (n) => typeof n === "object" && "nodeName" in n && (n as {nodeName: string}).nodeName === "DIV",
        ) as HTMLDivElement
        if (!viewportElement) throw new Error("Viewport element not found")
        this.viewportContainer = new ElementRef(viewportContainerElement)
        this.viewport = new ElementRef(viewportElement)

        this._afterViewInit = true
        if (this._pendingTasks.length) {
            forkJoinZeroOrMore(this._pendingTasks).subscribe(() => {
                this._pendingTasks = []
            })
        }

        if (this.type === SCENE_VIEW) {
            ;(this.sceneView as SceneView) = new SceneView(this._viewManager)
        } else {
            ;(this.sceneView as InteractiveSceneView) = new InteractiveSceneView(this._viewManager)
        }

        this.sceneView.initViewport(this.viewport.nativeElement)

        this.sceneView.setCameraId(this._cameraId)
        this.sceneView.setConfig(this._config)

        const defaultCameraPosition = new Vector3(...this.defaultCameraPosition)
        const defaultCameraTarget = new Vector3(...this.defaultCameraTarget)
        const defaultCameraFocalLength = 50
        this.sceneView.updateDefaultCamera({
            type: "Camera",
            id: "defaultCamera-${uuid4()}",
            focalLength: defaultCameraFocalLength,
            focalDistance: 100,
            autoFocus: false,
            aspectRatio: 1,
            target: defaultCameraTarget,
            targeted: true,
            filmGauge: 36,
            fStop: 512,
            exposure: 1,
            toneMapping: {mode: "linear"},
            shiftX: 0,
            shiftY: 0,
            transform: Matrix4.cameraLookAt(defaultCameraPosition, defaultCameraTarget),
        })

        this.updateSize()

        this.resizeObserver = new ResizeObserver((_entries) => {
            this.updateSize()
        })
        this.resizeObserver.observe(this.viewportContainer.nativeElement)

        this.sceneView.renderView.camera$
            .pipe(
                takeUntil(this.destroySubject),
                switchMap((camera) => camera.aspectRatio$),
            )
            .subscribe((_aspectRatio) => this.updateSize(false))
    }

    private _curSize: [number, number] = [0, 0]

    private updateSize(emitEvent = true): void {
        if (this.viewport && this.viewportContainer.nativeElement && this.viewport.nativeElement) {
            let {width, height} = this.viewportContainer.nativeElement.getBoundingClientRect()
            if (this._fixedAspectRatio) {
                const aspectRatio = this.sceneView.renderView.camera._aspectRatio
                if (aspectRatio) {
                    ;[width, height] = fitCameraFrameRect(aspectRatio, width, height)
                }
            }
            if (this.sceneView.updateSize(width, height)) {
                this.viewport.nativeElement.style.width = `${width}px`
                this.viewport.nativeElement.style.height = `${height}px`
                this._curSize = [width, height]
                if (emitEvent) this.sizeChanged.emit([width, height])
            }
        }
    }

    getCurSize() {
        return this._curSize
    }

    ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.viewportContainer.nativeElement)
        }
        this.destroySubject.next()
        this.destroySubject.complete()
        this.sceneView.destroy()
    }
}

@Component({
    selector: "cm-scene-view",
    templateUrl: "./scene-view.component.html",
    styleUrls: ["./scene-view.component.scss"],
    providers: [{provide: SCENE_VIEW_TYPE, useValue: SCENE_VIEW}],
    imports: [NgTemplateOutlet],
    standalone: true,
})
export class SceneViewComponent extends SceneViewBaseComponent<SceneView> {
    constructor(@Inject(SCENE_VIEW_TYPE) type: SceneViewType, elementRef: ElementRef) {
        super(type, elementRef)
    }
}

@Component({
    standalone: true,
    selector: "cm-interactive-scene-view",
    templateUrl: "./scene-view.component.html",
    styleUrls: ["./scene-view.component.scss"],
    imports: [NgTemplateOutlet],
    providers: [{provide: SCENE_VIEW_TYPE, useValue: INTERACTIVE_SCENE_VIEW}],
})
export class InteractiveSceneViewComponent extends SceneViewBaseComponent<InteractiveSceneView> {
    @Output() mouseEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()

    @Output() selectionEvent: EventEmitter<SelectionEvent> = new EventEmitter<SelectionEvent>()

    @Output() initCompletedEvent: EventEmitter<void> = new EventEmitter<void>()

    @HostListener("window:focus", ["$event"]) onFocus(_event: Event) {
        if (this.config.navigationFocus && this.config.navigationFocus === "trackHostFocusBlurEvents") this.navigation.focused = true
    }

    @HostListener("window:blur", ["$event"]) onBlur(_event: Event) {
        if (this.config.navigationFocus && this.config.navigationFocus === "trackHostFocusBlurEvents") this.navigation.focused = false
    }

    @HostListener("mouseup", ["$event"])
    @HostListener("mousedown", ["$event"])
    @HostListener("mousemove", ["$event"])
    onMouseEvent(event: MouseEvent) {
        this.mouseEvent.emit(event)
    }

    NavigationInterface = class {
        constructor(private viewRef: InteractiveSceneViewComponent) {}
        set focused(val: boolean) {
            this.viewRef.sceneView && this.viewRef.sceneView.navigation ? (this.viewRef.sceneView.navigation.focused = val) : null
        }
        get focused(): boolean {
            return this.viewRef.sceneView && this.viewRef.sceneView.navigation ? this.viewRef.sceneView.navigation.focused : false
        }

        zoomIn(amount: number): void {
            this.viewRef.sceneView.navigation.zoomIn(amount)
        }

        zoomOut(amount: number): void {
            this.viewRef.sceneView.navigation.zoomOut(amount)
        }
    }
    navigation = new this.NavigationInterface(this)

    constructor(@Inject(SCENE_VIEW_TYPE) type: SceneViewType, elementRef: ElementRef) {
        super(type, elementRef)
    }

    override ngAfterViewInit() {
        super.ngAfterViewInit()
        this.sceneView.onSelectionEvent = (event: SelectionEvent) => this.selectionEvent.emit(event)
        this.initCompletedEvent.emit()
    }

    zoomIn(amount: number): void {
        this.navigation.zoomIn(amount)
    }

    zoomOut(amount: number): void {
        this.navigation.zoomOut(amount)
    }

    surfaceInfoAtPoint = (x: number, y: number) => this.sceneView.renderView.surfaceInfoAtPoint(x, y)

    renderCanvasToDataURL = (mimeType: "image/png" | "image/jpeg", quality?: number) => this.sceneView.renderView.renderCanvasToDataURL(mimeType, quality)
}
