import {Injectable} from "@angular/core"
import {INFINITE_SCROLL_ROOT_MARGIN, INFINITE_SCROLL_THRESHOLD} from "@common/models/data/constants"
import {BehaviorSubject} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class InfiniteScrollService {
    shouldLoadMore$ = new BehaviorSubject(false)

    private intersectionObserver?: IntersectionObserver
    private element?: Element

    connectElement(element: Element) {
        if (this.intersectionObserver) {
            throw new Error("Infinite scroll service is already observing an element, observing multiple elements is currently not supported.")
        }
        this.element = element
        this.intersectionObserver = new IntersectionObserver(
            (entries) => {
                const latestEntry = entries?.[entries.length - 1]
                const isIntersecting = latestEntry?.isIntersecting ?? false
                this.shouldLoadMore$.next(isIntersecting)
            },
            {
                root: document.querySelector(".cm-app-main-container"),
                rootMargin: INFINITE_SCROLL_ROOT_MARGIN,
                threshold: INFINITE_SCROLL_THRESHOLD,
            },
        )
        this.intersectionObserver.observe(element)
    }

    disconnectElement() {
        if (this.element) {
            this.intersectionObserver?.unobserve(this.element)
        }
        this.intersectionObserver = undefined
        this.element = undefined
    }
}
