// @ts-strict-ignore
import {ContentTypeModel} from "@api"
import {APIEndpoint, APIQueryParams, APIService, PaginatedResponse} from "@legacy/services/api/api.service"
import {AsyncCacheMap, RequestBatcher} from "@common/helpers/async-cache-map/async-cache-map"
import {EntityType} from "@legacy/models/entity-type"
import {InjectorService} from "@common/services/injector/injector.service"
import {Settings} from "@common/models/settings/settings"
import {filter, map, merge, Observable, Subject, tap} from "rxjs"

export const EndpointUrls = Settings

interface ISerializableFieldDescriptor {
    name: string

    fromJson(model: any, data: any, allowCaching: boolean): void

    toJson(model: any, data: any): void
}

export interface ISerializableEntity {
    id: number

    populateFromJson(data: any, allowCaching: boolean): void

    toJson(): any
}

export type ApiEntityClassMetadata<T extends ISerializableEntity> = {
    entityTypeName: string
    entityType: EntityType
    fields: ISerializableFieldDescriptor[]
    getEndpoint: () => APIEndpoint<T> // will be filled in by declareApiModel
    constructorFn: new () => T
    contentTypeModel: ContentTypeModel | null
}

export interface ISerializableEntityClass<T extends ISerializableEntity> {
    entityClassMetadata: ApiEntityClassMetadata<T>

    fromJson(data: any, allowCaching: boolean): T
}

// const modelMetaMap = new Map<EntityType, ApiEntityClassMetadata<any>>()

export type ApiModelBaseClassConstructor = abstract new () => any

export abstract class ApiModelBase extends MakeApiModelBase<ApiModelBase>() {}
// export type ApiModelBaseClass = typeof ApiModelBase

// export const isApiModelClassInstance = <T extends typeof ApiModelBase>(module: T, instance: typeof ApiModelBase): instance is T => {
//     return instance.prototype instanceof module
// }

export function MakeApiModelBase<T, B extends ApiModelBaseClassConstructor = never>(baseClass?: B) {
    // this will be filled in after the declareApiModel decorator is called on the subclass:
    const entityClassMetadata: ApiEntityClassMetadata<T & BaseClass> = {
        entityTypeName: undefined,
        entityType: undefined,
        fields: undefined,
        getEndpoint: undefined,
        constructorFn: undefined,
        contentTypeModel: undefined,
    }

    const batcher = new RequestBatcher<number, T & {id: number}>(
        (id) => entityClassMetadata.getEndpoint().get(id),
        (ids) => {
            return entityClassMetadata.getEndpoint().getAll({id: ids})
        },
    )

    batcher.enabled = false

    const cache = new AsyncCacheMap<number, T, APIQueryParams>((id: number, queryParams?: APIQueryParams) => {
        if (queryParams) {
            console.warn("WARNING: using queryParams with cache!")
            return entityClassMetadata.getEndpoint().get(id, queryParams)
        } else {
            return batcher.request(id)
        }
    })

    const update$ = new Subject<[number, T | null]>()

    const deleteById = (id: number) => {
        cache.delete(id)
        return entityClassMetadata
            .getEndpoint()
            .delete(id)
            .pipe(tap(() => update$.next([id, null])))
    }

    abstract class Empty {}
    class BaseClass extends (baseClass || Empty) {
        static entityClassMetadata = entityClassMetadata
        static cache = cache

        get entityClassMetadata() {
            return entityClassMetadata
        }

        get entityType() {
            return entityClassMetadata.entityType
        }

        get contentTypeModel(): ContentTypeModel {
            return entityClassMetadata.contentTypeModel
        }

        public id: number

        static enableCache(): boolean {
            // console.log(`Enabling cache for ${entityClassMetadata.entityTypeName}`);
            const prevState = cache.enabled
            if (!prevState) {
                cache.clear()
                cache.enabled = true
            }
            return prevState
        }

        static disableCache(_silent = false): boolean {
            // console.log(`Disabling cache for ${entityClassMetadata.entityTypeName}`);
            const prevState = cache.enabled
            if (prevState) {
                cache.clear()
                cache.enabled = false
            }
            return prevState
        }

        static restoreCacheState(state: boolean): void {
            if (state !== cache.enabled) {
                cache.clear()
                cache.enabled = state
            }
        }

        static enableBatching() {
            batcher.enabled = true
        }

        static disableBatching() {
            batcher.enabled = false
        }

        static getEndpoint() {
            return entityClassMetadata.getEndpoint()
        }

        static get(id: number, queryParams?: APIQueryParams): Observable<T> {
            if (id === undefined || id === null) {
                throw new Error("Entity id may not be null or undefined!")
            }
            return cache.get(id, queryParams)
        }

        static watch(id: number): Observable<T> {
            if (id === undefined || id === null) {
                throw new Error("Entity id may not be null or undefined!")
            }
            const notifications = update$.pipe(
                filter(([_id, _]) => id === _id),
                map(([_id, entity]) => entity),
            )
            return merge(cache.get(id), notifications)
        }

        static getAll(queryParams?: APIQueryParams): Observable<T[]> {
            return entityClassMetadata.getEndpoint().getAll(queryParams)
        }

        static findOne(queryParams: APIQueryParams): Observable<T | null> {
            return entityClassMetadata
                .getEndpoint()
                .getAllPaginatedBaseByOffset(1, 0, queryParams)
                .pipe(map((res) => res.list[0] ?? null))
        }

        static delete(id: number): Observable<void> {
            return deleteById(id)
        }

        // Extended:
        static getAllPaginatedByOffset(maxCount: number, offset: number, queryParams?: APIQueryParams): Observable<PaginatedResponse<T>> {
            return entityClassMetadata.getEndpoint().getAllPaginatedBaseByOffset(maxCount, offset, queryParams)
        }

        static getAllPaginatedByPage(pageSize: number, pageNumber: number, queryParams?: APIQueryParams): Observable<PaginatedResponse<T>> {
            const maxCount: number = pageSize
            const offset: number = pageNumber * pageSize
            return entityClassMetadata.getEndpoint().getAllPaginatedBaseByOffset(maxCount, offset, queryParams)
        }

        static fromJson(data: any, allowCaching: boolean): T {
            let model = allowCaching && (cache.getSync(data.id) as T & BaseClass)
            if (!model) {
                model = new entityClassMetadata.constructorFn()
            }
            model.populateFromJson(data, allowCaching)
            return model
        }

        populateFromJson(data: any, allowCaching: boolean): void {
            this.id = data.id
            for (const f of entityClassMetadata.fields) {
                f.fromJson(this, data, allowCaching)
            }
            if (allowCaching) {
                cache.set(this.id, this as any as T)
            }
        }

        toJson(): any {
            const data: any = {}
            data.id = this.id
            for (const f of entityClassMetadata.fields) {
                f.toJson(this, data)
            }
            return data
        }

        getApi(): APIService {
            return this.entityClassMetadata.getEndpoint().api
        }

        load(): Observable<T> {
            return this.entityClassMetadata
                .getEndpoint()
                .load(this as any)
                .pipe(
                    map((entity: T & BaseClass) => {
                        update$.next([entity.id, entity])
                        return entity
                    }),
                )
        }

        save(): Observable<void> {
            const notifyOp = map((entity: T & BaseClass) => {
                update$.next([entity.id, entity])
            })
            if (this.id === undefined) {
                return this.entityClassMetadata
                    .getEndpoint()
                    .create(this as any)
                    .pipe(notifyOp)
            } else {
                return this.entityClassMetadata
                    .getEndpoint()
                    .update(this as any)
                    .pipe(notifyOp)
            }
        }

        delete(): Observable<void> {
            return deleteById(this.id)
        }
    }

    return BaseClass
}

const entityClassMap = new Map<EntityType, any>()

const FIELDS_METADATA_PROPERTY_KEY = "_fields" // this is the property key that is used to collect decorator annotations in the class prototype

export function declareApiModel<T extends ISerializableEntity>(
    endpointUrl: string,
    entityType: EntityType,
    contentTypeModel: ContentTypeModel | null = null,
    useCache = false,
    onlyCacheSingleRequests = false,
) {
    return function (targetClass: ISerializableEntityClass<T> & {new (): T}) {
        let api: APIEndpoint<T> = null
        const entityClassMetadata = targetClass.prototype.entityClassMetadata as ApiEntityClassMetadata<T>
        entityClassMetadata.constructorFn = targetClass
        entityClassMetadata.entityType = entityType
        entityClassMetadata.entityTypeName = targetClass.name
        entityClassMetadata.fields = targetClass.prototype[FIELDS_METADATA_PROPERTY_KEY] as ISerializableFieldDescriptor[]
        //console.log(`declareApiModel: ${targetClass.name}`)
        //entityClassMetadata.fields.forEach((f) => console.log(`  ${f.name}`))
        entityClassMetadata.getEndpoint = () => {
            if (api === null) api = new APIEndpoint(InjectorService.injector.get(APIService), endpointUrl, targetClass, onlyCacheSingleRequests)
            return api
        }
        entityClassMetadata.contentTypeModel = contentTypeModel
        entityClassMap.set(entityType, targetClass)

        if (useCache) {
            ;(targetClass as any).enableCache()
        } else {
            ;(targetClass as any).cache.enabled = false // silent disable
        }
    }
}

function fieldsForTarget(target: any) {
    let fields: ISerializableFieldDescriptor[] = undefined
    if (!Object.prototype.hasOwnProperty.call(target, "fields")) {
        const superclassFields = target[FIELDS_METADATA_PROPERTY_KEY] as ISerializableFieldDescriptor[]
        if (superclassFields !== undefined) {
            fields = superclassFields.slice()
        } else {
            fields = []
        }
        target[FIELDS_METADATA_PROPERTY_KEY] = fields
    } else {
        fields = target[FIELDS_METADATA_PROPERTY_KEY]
    }
    return fields
}

type RelationFieldProperties = {
    name: string
    model: {fromJson: (data: any, allowCaching: boolean) => any}
    allowCaching?: false
}

type ValueFieldProperties = {
    name: string
    readOnly?: true
}

function isNullLike(x: any) {
    return x === undefined || x === null
}

export class ApiFields {
    static manyRelated({name, model, allowCaching}: RelationFieldProperties) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, allowCaching2) => {
                    const jsonField = json[name]
                    if (isNullLike(jsonField)) {
                        obj[key] = []
                    } else {
                        obj[key] = jsonField.map((elemJson: any) => model.fromJson(elemJson, (allowCaching ?? true) && allowCaching2))
                    }
                },
                toJson: (_obj, _json) => {
                    // assume readOnly
                },
            })
        }
    }

    static singleRelated({name, model, allowCaching}: RelationFieldProperties) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, allowCaching2) => {
                    const jsonField = json[name]
                    if (isNullLike(jsonField)) {
                        obj[key] = jsonField
                        //TODO: check nullable?
                    } else {
                        obj[key] = model.fromJson(jsonField, (allowCaching ?? true) && allowCaching2)
                    }
                },
                toJson: (_obj, _json) => {
                    // assume readOnly
                },
            })
        }
    }

    static singleRelatedGeneric({name, entityTypeFieldName, allowCaching}: {name: string; entityTypeFieldName: string; allowCaching?: false}) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, allowCaching2) => {
                    const jsonObjectField = json[name]
                    const jsonTypeField = json[entityTypeFieldName]
                    if (isNullLike(jsonObjectField)) {
                        obj[key] = jsonObjectField
                        //TODO: check nullable?
                    } else {
                        const entityClass = entityClassMap.get(jsonTypeField)
                        obj[key] = entityClass.fromJson(jsonObjectField, (allowCaching ?? true) && allowCaching2)
                    }
                },
                toJson: (_obj, _json) => {
                    // assume readOnly
                },
            })
        }
    }

    static singleReference({name, model, allowCaching}: RelationFieldProperties) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, allowCaching2) => {
                    const jsonField = json[name]
                    if (isNullLike(jsonField)) {
                        obj[key] = jsonField
                        //TODO: check nullable?
                    } else {
                        obj[key] = model.fromJson(jsonField, (allowCaching ?? true) && allowCaching2)
                    }
                },
                toJson: (obj, json) => {
                    const objField = obj[key]
                    if (isNullLike(objField)) {
                        json[name + "_id"] = objField
                    } else {
                        json[name + "_id"] = obj[key].id
                    }
                },
            })
        }
    }

    static date({name, readOnly}: ValueFieldProperties) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, allowCaching2) => {
                    const jsonField = json[name]
                    if (isNullLike(jsonField)) {
                        obj[key] = jsonField
                        //TODO: check nullable?
                    } else {
                        obj[key] = new Date(jsonField)
                    }
                },
                toJson: (obj, json) => {
                    if (!readOnly) {
                        json[name] = obj[key]
                    }
                },
            })
        }
    }

    static enumObject({name, model, readOnly}: {name: string; model: {get: (id: number) => any}; readOnly?: true}) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, allowCaching2) => {
                    const jsonField = json[name]
                    if (isNullLike(jsonField)) {
                        obj[key] = jsonField
                        //TODO: check nullable?
                    } else {
                        obj[key] = model.get(jsonField)
                    }
                },
                toJson: (obj, json) => {
                    if (!readOnly) {
                        const objField = obj[key]
                        if (isNullLike(objField)) {
                            json[name] = objField
                        } else {
                            json[name] = obj[key].id
                        }
                    }
                },
            })
        }
    }

    static custom({name, readOnly, fromJson, toJson}: ValueFieldProperties & {fromJson: (json: any) => any; toJson: (val: any) => any}) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, _allowCaching2) => {
                    obj[key] = fromJson(json[name])
                },
                toJson: (obj, json) => {
                    if (!readOnly) {
                        json[name] = toJson(obj[key])
                    }
                },
            })
        }
    }

    static commaSeparatedStrings({name, readOnly}: ValueFieldProperties) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, _allowCaching2) => {
                    obj[key] = json[name].split(",")
                },
                toJson: (obj, json) => {
                    if (!readOnly) {
                        json[name] = obj[key].join(",")
                    }
                },
            })
        }
    }

    private static _generic({name, readOnly}: ValueFieldProperties) {
        return (target: any, key: string) => {
            fieldsForTarget(target).push({
                name,
                fromJson: (obj, json, _allowCaching2) => {
                    obj[key] = json[name]
                },
                toJson: (obj, json) => {
                    if (!readOnly) {
                        json[name] = obj[key]
                    }
                },
            })
        }
    }

    //TODO: runtime type checks?
    static id = ApiFields._generic
    static entityType = ApiFields._generic
    static enum = ApiFields._generic
    static number = ApiFields._generic
    static string = ApiFields._generic
    static boolean = ApiFields._generic
    static json = ApiFields._generic
}
