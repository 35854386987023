import {Component, EventEmitter, Input, Output} from "@angular/core"
import {MatDialog} from "@angular/material/dialog"
import {ActivatedRoute, Router} from "@angular/router"
import {ContentTypeModel} from "@api"
import {FloatingButtonComponent} from "@common/components/buttons/floating-button/floating-button.component"
import {NewItemDialogDataGql, NewItemDialogComponent} from "@common/components/item/new-item-dialog/new-item-dialog.component"

/**
 * Floating "+" button that opens a dialog used to add a new entity
 */
@Component({
    selector: "cm-entity-add",
    templateUrl: "./entity-add.component.html",
    styleUrls: ["./entity-add.component.scss"],
    standalone: true,
    imports: [FloatingButtonComponent],
})
export class EntityAddComponent<EntityType extends {id: string; legacyId: number}, CreateType> {
    @Input() large: boolean = false
    @Input() contentTypeModel: ContentTypeModel = ContentTypeModel.Asset
    @Input() createItem: ((dialogResult: CreateType) => Promise<EntityType>) | undefined = undefined
    @Output() onClick: EventEmitter<void> = new EventEmitter<void>()

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    onOpenDialog: () => Promise<void> = async () => {
        this.onClick.emit()
        if (this.createItem) {
            const dialogData: NewItemDialogDataGql = new NewItemDialogDataGql()
            dialogData.entityType = this.contentTypeModel
            const dialogRefModel = this.dialog.open(NewItemDialogComponent, {
                width: "350px",
                data: dialogData,
            })
            dialogRefModel.afterClosed().subscribe((result: CreateType) => {
                if (!result) {
                    return
                }
                this.createItem?.(result)?.then((entity) => {
                    this.router.navigate([entity.id], {relativeTo: this.route, queryParamsHandling: "preserve"})
                })
            })
        }
    }
}
