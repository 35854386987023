// @ts-strict-ignore
import {DOCUMENT} from "@angular/common"
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef,
} from "@angular/core"
import {ActivatedRoute, Router} from "@angular/router"
import {MaterialNodeTypes} from "@material-editor/components/material-node-component-types"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {NodeEditorCanvasComponent} from "@node-editor/components/node-editor-canvas/node-editor-canvas.component"
import {Connection} from "@node-editor/helpers/connection"
import {NodeEditorNavigation} from "@node-editor/helpers/node-editor-navigation"
import {
    ConnectionEvent,
    ConnectionId,
    keyForSocket,
    MoveEvent,
    NodeAccessor,
    NodeId,
    ParameterEvent,
    ParameterId,
    ParameterType,
    ParameterValue,
    SelectEvent,
    Socket,
    SocketEvent,
    SocketPosition,
} from "@node-editor/models"
import {NodeSelectionManagerService} from "@node-editor/services/node-selection-manager.service"
import {fromEvent, Subject, takeUntil} from "rxjs"

@Component({
    selector: "cm-node-editor",
    templateUrl: "./node-editor.component.html",
    styleUrls: ["./node-editor.component.scss"],
    host: {oncontextmenu: "return false"},
    standalone: true,
    imports: [NodeEditorCanvasComponent],
})
export class NodeEditorComponent<NodeT extends {id: string; name: string}> implements OnDestroy, AfterViewInit {
    @Input() nodeAccessor: NodeAccessor<NodeT>

    @Output() deleteNodes: EventEmitter<NodeT[]> = new EventEmitter<NodeT[]>()
    @Output() deleteConnections: EventEmitter<ConnectionEvent<NodeT>[]> = new EventEmitter<ConnectionEvent<NodeT>[]>()
    @Output() addConnection: EventEmitter<ConnectionEvent<NodeT>> = new EventEmitter<ConnectionEvent<NodeT>>()
    @Output() moveNode: EventEmitter<MoveEvent<NodeT>> = new EventEmitter<MoveEvent<NodeT>>()
    @Output() parameterChange: EventEmitter<ParameterEvent<NodeT>> = new EventEmitter<ParameterEvent<NodeT>>()
    @Output() selectNode: EventEmitter<SelectEvent<NodeT>> = new EventEmitter<SelectEvent<NodeT>>()

    @ViewChild("nodeEditorCanvas", {static: true}) nodeEditorCanvas: NodeEditorCanvasComponent
    @ViewChild("nodeContainer", {read: ViewContainerRef}) nodeContainer: ViewContainerRef
    @ViewChild("nodesAnchor", {read: ViewContainerRef}) nodesAnchor: ViewContainerRef
    @ViewChild("nodeEditorContainer", {static: true}) nodeEditorContainer: ElementRef<HTMLDivElement>

    @HostListener("window:resize", ["$event"]) onResize(_event: Event) {
        this.nodeEditorCanvas.updateVisualCanvasSize(this.element.nativeElement.getBoundingClientRect())
        this.updateConnectionsForComponents()
    }

    @HostListener("wheel", ["$event"]) onWheel(event: WheelEvent) {
        this.navigation.mouseWheel(event)
        this.updateConnectionsForComponents()
    }

    @HostListener("mousedown", ["$event"]) onMouseDown(event: MouseEvent) {
        this.selectionManager.deselectAll()
        this.navigation.mouseDown(event)
    }

    @HostListener("document:mousemove", ["$event"]) onMouseMove(event: MouseEvent) {
        this.drag(event)
        this.navigation.mouseMove(event)
        this.updateConnectionsForComponents()
        if (this.draggingConnection) {
            const fromPosition = this.getSocketPosition(this.draggingConnection.fromComponent, this.draggingConnection.fromSocket)
            const connectionId = this.draggingConnection.type === "new" ? this.draggingConnection.lineId : this.draggingConnection.connection.id
            this.nodeEditorCanvas.updateConnectionLine(connectionId, fromPosition, {x: event.x, y: event.y})
        }
    }

    @HostListener("document:mouseup", ["$event"]) onMouseUp(event: MouseEvent) {
        this.stopDrag()
        this.navigation.mouseUp(event)
        this.updateConnectionsForComponents()
        const drag = this.draggingConnection
        if (drag) {
            event.stopPropagation()
            this.draggingConnection = null
            if (drag.type === "new") {
                // abort new connection
                this.deleteConnection(drag.lineId)
            } else {
                // disconnect existing
                this.emitDeleteConnections([drag.connection])
            }
        } else {
            this.selectNode.emit({node: this.selectionManager.selected ? this.selectionManager.selected.node : null})
        }
    }

    @HostListener("document:keydown", ["$event"]) onKeyDown(event: KeyboardEvent) {
        if (event.code === "Delete" && this.selectionManager.selected) {
            event.stopPropagation()
            this.deleteNodes.emit([this.selectionManager.selected.node])
        } else if (event.code === "KeyM" && this.selectionManager.selected && this.nodeAccessor.disableable(this.selectionManager.selected.node)) {
            event.stopPropagation()
            this.nodeAccessor.toggleDisabled(this.selectionManager.selected.node)
        }
    }

    readonly destroy = new Subject<void>()
    private connectionMap = new Map<string, Connection<NodeBaseComponent<NodeT>>>()
    private resizeObserver?: ResizeObserver

    private nodeDragStart: {x: number; y: number} = {x: 0, y: 0}
    private nodeTranslationStart: {x: number; y: number} = {x: 0, y: 0}
    private nodeDragPosition?: [number, number]
    private nodeDragged: NodeBaseComponent<NodeT>

    private nodeComponentRefs: Map<NodeId, NodeBaseComponent<NodeT>> = new Map<NodeId, NodeBaseComponent<NodeT>>()
    private nodeComponentRefs2: Map<NodeId, ComponentRef<unknown>> = new Map<NodeId, ComponentRef<unknown>>()
    private draggingConnection:
        | {
              type: "new"
              lineId: string
              fromComponent: NodeBaseComponent<NodeT>
              fromSocket: Socket
          }
        | {
              type: "existing"
              connection: Connection<NodeBaseComponent<NodeT>>
              fromComponent: NodeBaseComponent<NodeT>
              fromSocket: Socket
          }

    navigation: NodeEditorNavigation

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected element: ElementRef,
        protected selectionManager: NodeSelectionManagerService<NodeBaseComponent<NodeT>>,
        private changeDetector: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngAfterViewInit() {
        this.navigation = new NodeEditorNavigation(this.element.nativeElement, this.nodeContainer.element.nativeElement)
        this.changeDetector.detectChanges()
        this.resizeObserver = new ResizeObserver((_entries) => {
            this.nodeEditorCanvas.updateVisualCanvasSize(this.element.nativeElement.getBoundingClientRect())
            this.updateConnectionsForComponents()
        })
        this.resizeObserver.observe(this.nodeEditorContainer.nativeElement)
    }

    private getNodeId(node: NodeT): NodeId {
        return this.nodeAccessor.getNodeId(node)
    }

    updateNodesAndConnections(nodes: NodeT[]): void {
        const removedNodeIds = new Set(this.nodeComponentRefs.keys())
        const removedConnectionIds = new Set(this.connectionMap.keys())

        // first create nodes
        for (const node of nodes) {
            const nodeId = this.getNodeId(node)
            removedNodeIds.delete(nodeId)
            const componentRef = this.nodeComponentRefs.get(nodeId)
            if (componentRef) {
                // existing
                this.updateNodePosition(componentRef)
                componentRef.node = node // update object, as the instance may be different even if the ID is the same
            } else {
                // added
                this.addComponentForNode(nodeId, node)
            }
        }

        // then resolve sockets/connections
        for (const node of nodes) {
            const nodeId = this.getNodeId(node)
            const component = this.nodeComponentRefs.get(nodeId)
            if (!component) continue
            for (const inputConnection of this.nodeAccessor.getInputConnections(node)) {
                const sourceComponent = this.nodeComponentRefs.get(inputConnection.sourceNodeId)
                if (!sourceComponent) continue
                const sourceSocket = sourceComponent.resolveSocket(inputConnection.sourceSocketId, "output")
                const destinationComponent = component
                const destinationSocket = destinationComponent.resolveSocket(inputConnection.destinationSocketId, "input")

                if (!(sourceSocket && destinationSocket)) continue

                const connectionId = `${sourceComponent.id}|${keyForSocket(sourceSocket)}|${destinationComponent.id}|${keyForSocket(destinationSocket)}`

                removedConnectionIds.delete(connectionId)
                if (this.connectionMap.has(connectionId)) {
                    // existing
                } else {
                    // added
                    this.connectionMap.set(connectionId, {
                        id: connectionId,
                        sourceComponent,
                        sourceSocket,
                        destinationComponent,
                        destinationSocket,
                    })
                }
            }
        }

        for (const nodeId of removedNodeIds) {
            this.removeComponentForNodeId(nodeId)
        }

        for (const connectionId of removedConnectionIds) {
            this.deleteConnection(connectionId)
        }

        this.updateConnectionsForComponents()
    }

    private addComponentForNode(id: NodeId, node: NodeT): NodeBaseComponent<NodeT> | null {
        const materialType = MaterialNodeTypes.find((nodeType) => nodeType.name === node.name)
        if (!materialType) throw Error(`Cannot find node type: ${node.name}`)
        const component = this.nodesAnchor.createComponent(materialType.component)
        //FIXME: The typeinfo should be passed by the main component directly (it is not updated quick enough)
        component.instance.nodeBase.typeInfo = component.instance.typeInfo
        component.instance.nodeBase.id = id
        component.instance.nodeBase.node = node
        component.instance.nodeBase.getParameter = (id: ParameterId) =>
            component.instance.nodeBase.node && this.nodeAccessor.getParameter(component.instance.nodeBase.node, id)
        component.instance.nodeBase.setParameter = (id: ParameterId, value: ParameterValue, type: ParameterType) =>
            this.nodeAccessor.setParameter(component.instance.nodeBase.node, id, value, type)
        component.instance.nodeBase.setParameterNoUpdate = (id: ParameterId, value: ParameterValue, type: ParameterType) =>
            this.nodeAccessor.setParameterNoUpdate(component.instance.nodeBase.node, id, value, type)
        component.instance.nodeBase.disabled = () => this.nodeAccessor.disabled(component.instance.nodeBase.node)
        component.instance.nodeBase.disableable = () => this.nodeAccessor.disableable(component.instance.nodeBase.node)
        component.instance.nodeBase.toggleDisabled = () => this.nodeAccessor.toggleDisabled(component.instance.nodeBase.node)
        fromEvent<MouseEvent>(component.location.nativeElement, "mousedown")
            .pipe(takeUntil(this.destroy))
            .subscribe((event: MouseEvent) => this.onNodeMouseDown(event, component.instance.nodeBase))
        this.nodeComponentRefs.set(component.instance.nodeBase.id, component.instance.nodeBase)
        this.nodeComponentRefs2.set(component.instance.nodeBase.id, component)
        component.instance.nodeBase.connectionChange.subscribe((event: SocketEvent) => this.onSocketEvent(component.instance.nodeBase, event))
        component.instance.nodeBase.parameterChange.subscribe((event: ParameterEvent<NodeT>) => this.parameterChange.emit(event))
        setTimeout(() => this.updateNodePosition(component.instance.nodeBase), 100)
        return component.instance.nodeBase
    }

    private onSocketEvent(component: NodeBaseComponent<NodeT>, event: SocketEvent): void {
        if (event.type === "start") {
            const existingConnection = this.getConnectionByDestination(component, event.socket)
            if (event.socket.type === "input") {
                if (existingConnection) {
                    // change destination of existing connection
                    this.draggingConnection = {
                        type: "existing",
                        connection: existingConnection,
                        fromComponent: existingConnection.sourceComponent,
                        fromSocket: existingConnection.sourceSocket,
                    }
                } else {
                    // drag new connection, starting from input
                    this.draggingConnection = {
                        type: "new",
                        lineId: "new-connection",
                        fromComponent: component,
                        fromSocket: event.socket,
                    }
                }
            } else if (event.socket.type === "output") {
                // drag new connection, starting from output
                this.draggingConnection = {
                    type: "new",
                    lineId: "new-connection",
                    fromComponent: component,
                    fromSocket: event.socket,
                }
            } else {
                this.draggingConnection = null
            }
        } else if (event.type === "end") {
            const drag = this.draggingConnection
            const connectionWithSameDestination = this.getConnectionByDestination(component, event.socket)
            if (connectionWithSameDestination) {
                this.deleteConnection(connectionWithSameDestination.id)
                this.emitDeleteConnections([connectionWithSameDestination])
            }
            if (drag) {
                this.draggingConnection = null
                if (drag.type === "existing") {
                    this.emitChangeConnection(drag.connection, drag.fromComponent, drag.fromSocket, component, event.socket)
                } else {
                    this.deleteConnection(drag.lineId)
                    this.emitAddConnection(drag.fromComponent, drag.fromSocket, component, event.socket)
                }
            }
        } else if (event.type === "deactivate") {
            /*
             * In some cases the socket needs to get deactivated/hidden depending on the settings of the node. In these cases, if there is a connection to this socket,
             * it needs to be removed.
             */
            let existingConnection
            if (event.socket.type === "input") {
                existingConnection = this.getConnectionByDestination(component, event.socket)
            } else if (event.socket.type === "output") {
                existingConnection = this.getConnectionBySource(component, event.socket)
            }
            if (existingConnection) this.emitDeleteConnections([existingConnection])
        }
    }

    private emitAddConnection(fromComponent: NodeBaseComponent<NodeT>, fromSocket: Socket, toComponent: NodeBaseComponent<NodeT>, toSocket: Socket): void {
        if (fromSocket.type == "input" && toSocket.type == "output") {
            // swap reversed connection
            ;[fromComponent, toComponent] = [toComponent, fromComponent]
            ;[fromSocket, toSocket] = [toSocket, fromSocket]
        }

        if (fromSocket.type == "output" && toSocket.type == "input") {
            //TODO: other socket type checks? or should this be the responsibility of the handler?
            this.addConnection.emit({
                sourceNode: fromComponent.node,
                sourceSocketId: fromSocket.id,
                destinationNode: toComponent.node,
                destinationSocketId: toSocket.id,
            })
        } else {
            console.error(`Cannot connect socket types: ${fromSocket.type} -> ${toSocket.type}`)
        }
    }

    private emitDeleteConnections(connections: Connection<NodeBaseComponent<NodeT>>[]) {
        this.deleteConnections.emit(
            connections.map((connection) => ({
                sourceNode: connection.sourceComponent.node,
                sourceSocketId: connection.sourceSocket.id,
                destinationNode: connection.destinationComponent.node,
                destinationSocketId: connection.destinationSocket.id,
            })),
        )
    }

    private emitChangeConnection(
        existing: Connection<NodeBaseComponent<NodeT>>,
        fromComponent: NodeBaseComponent<NodeT>,
        fromSocket: Socket,
        toComponent: NodeBaseComponent<NodeT>,
        toSocket: Socket,
    ) {
        this.emitDeleteConnections([existing])
        this.emitAddConnection(fromComponent, fromSocket, toComponent, toSocket)
    }

    private getConnectionByDestination(component: NodeBaseComponent<NodeT>, socket: Socket): Connection<NodeBaseComponent<NodeT>> | undefined {
        for (const connection of this.connectionMap.values()) {
            if (connection.destinationComponent === component && connection.destinationSocket === socket) {
                return connection
            }
        }
        return undefined
    }

    private getConnectionBySource(component: NodeBaseComponent<NodeT>, socket: Socket): Connection<NodeBaseComponent<NodeT>> | undefined {
        for (const connection of this.connectionMap.values()) {
            if (connection.sourceComponent === component && connection.sourceSocket === socket) {
                return connection
            }
        }
        return undefined
    }

    private deleteConnection(id: ConnectionId): void {
        this.connectionMap.delete(id)
        this.nodeEditorCanvas.deleteConnectionLine(id)
    }

    private getSocketPosition(component: NodeBaseComponent<NodeT>, socket: Socket): SocketPosition {
        return component?.getSocketPosition(socket)
    }

    private updateConnectionsForComponents(components?: NodeBaseComponent<NodeT>[]): void {
        const connectionsToUpdate: Set<Connection<NodeBaseComponent<NodeT>>> = new Set<Connection<NodeBaseComponent<NodeT>>>()
        if (!components) {
            components = []
            this.nodeComponentRefs.forEach((v, _k) => {
                components.push(v)
            })
        }
        for (const component of components) {
            const connectedSockets = new Set<Socket>()
            for (const [_id, connection] of this.connectionMap) {
                if (connection.sourceComponent === component) {
                    connectedSockets.add(connection.sourceSocket)
                    connectionsToUpdate.add(connection)
                } else if (connection.destinationComponent === component) {
                    connectedSockets.add(connection.destinationSocket)
                    connectionsToUpdate.add(connection)
                }
            }
            component.connectedSockets = connectedSockets
        }
        for (const connection of connectionsToUpdate) {
            const sourceSocketPosition = this.getSocketPosition(connection.sourceComponent, connection.sourceSocket)
            const destinationSocketPosition = this.getSocketPosition(connection.destinationComponent, connection.destinationSocket)
            if (!(sourceSocketPosition && destinationSocketPosition)) {
                // positions not available yet... skip
                continue
            }
            this.nodeEditorCanvas.updateConnectionLine(connection.id, sourceSocketPosition, destinationSocketPosition)
        }
    }

    private removeComponentForNodeId(id: NodeId): void {
        const componentRef = this.nodeComponentRefs2.get(id)
        if (componentRef) {
            componentRef.destroy()
            //TODO: fix remove
            //this.nodesAnchor.remove(this.nodesAnchor.indexOf(componentRef.hostView))
        }
        this.nodeComponentRefs.delete(id)
    }

    private onNodeMouseDown(event: MouseEvent, node: NodeBaseComponent<NodeT>): void {
        if (event.button === 0) {
            this.startDrag(event, node)
            this.selectionManager.select(node)
            event.stopPropagation()
        }
    }

    private startDrag(event: MouseEvent, node: NodeBaseComponent<NodeT>): void {
        const computedStyle = window.getComputedStyle(node.element.nativeElement)
        const matrix = new WebKitCSSMatrix(computedStyle.transform)
        this.nodeTranslationStart.x = matrix.m41
        this.nodeTranslationStart.y = matrix.m42
        this.nodeDragStart.x = event.clientX
        this.nodeDragStart.y = event.clientY
        this.nodeDragged = node
        this.nodeDragPosition = null
    }

    private drag(event: MouseEvent): void {
        if (!this.nodeDragged) return
        const translationX: number = this.nodeTranslationStart.x + (event.clientX - this.nodeDragStart.x) * (1 / this.navigation.scale)
        const translationY: number = this.nodeTranslationStart.y + (event.clientY - this.nodeDragStart.y) * (1 / this.navigation.scale)
        this.nodeDragPosition = [translationX, translationY]
        this.updateNodePosition(this.nodeDragged, this.nodeDragPosition)
    }

    private updateNodePosition(node: NodeBaseComponent<NodeT>, position?: [number, number]): void {
        if (!position) {
            position = this.nodeAccessor.getPosition(node.node)
        }
        const [x, y] = position
        node.element.nativeElement.setAttribute("style", `transform: translate(${x}px, ${y}px)`)
        this.updateConnectionsForComponents([node])
    }

    private stopDrag(): void {
        if (this.nodeDragged) {
            const node = this.nodeDragged.node
            this.nodeDragged = null
            if (this.nodeDragPosition) {
                const position = this.nodeDragPosition
                this.nodeDragPosition = null
                this.moveNode.emit({node, position})
            }
        }
    }

    ngOnDestroy() {
        Array.from(this.connectionMap.keys()).map((id) => this.deleteConnection(id))
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.nodeEditorContainer.nativeElement)
        }
        this.destroy.next()
        this.destroy.complete()
    }
}
