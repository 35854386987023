<cm-responsive-table-layout>
    <cm-item-filters cm-filters>
        <cm-tag-search-filter placeholder="Name, description, ID ..."></cm-tag-search-filter>
        <cm-checkboxes-filter label="State" name="state" [options]="Labels.JobState"></cm-checkboxes-filter>
        <cm-checkboxes-filter label="Organizations" name="organizationId" [options]="organizations.$filterOptions()"></cm-checkboxes-filter>
        <cm-checkboxes-filter label="Users" name="userId" [options]="users.$filterOptions()"></cm-checkboxes-filter>
    </cm-item-filters>

    <ng-template cmTableContent>
        <cm-jobs-table></cm-jobs-table>
    </ng-template>
</cm-responsive-table-layout>

<router-outlet></router-outlet>
