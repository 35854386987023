import {ScanJobState} from "@api"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const ScanJobStateLabels: Map<ScanJobState, StateLabel<ScanJobState>> = new Map<ScanJobState, StateLabel<ScanJobState>>([
    [ScanJobState.Init, {state: ScanJobState.Init, label: "Init", background: "#989898"}],
    [ScanJobState.Scanning, {state: ScanJobState.Scanning, label: "Scanning", background: "#ffab4a"}],
    [ScanJobState.ImageProcessing, {state: ScanJobState.ImageProcessing, label: "Image processing", background: "#ffab4a"}],
    [ScanJobState.Uploading, {state: ScanJobState.Uploading, label: "Uploading", background: "#ffab4a"}],
    [ScanJobState.Preprocessing, {state: ScanJobState.Preprocessing, label: "Preprocessing", background: "#7cbcb0"}],
    [ScanJobState.Fitting, {state: ScanJobState.Fitting, label: "Fitting", background: "#7cbcb0"}],
    [ScanJobState.Assembly, {state: ScanJobState.Assembly, label: "Assembly", background: "#7cbcb0"}],
    // no longer used (?)
    // [ScanJobState.SeamRemoval, {state: ScanJobState.SeamRemoval, label: "Seam removal", background: "#7cbcb0"}],
    [ScanJobState.Completed, {state: ScanJobState.Completed, label: "Completed", background: "#7ec17a"}],
    [ScanJobState.Aborted, {state: ScanJobState.Aborted, label: "Aborted", background: "#000000"}],
    [ScanJobState.Failed, {state: ScanJobState.Failed, label: "Failed", background: "#000000"}],
])
