import {Component, EventEmitter, Output} from "@angular/core"

@Component({
    standalone: true,
    selector: "cm-menu-dialog",
    templateUrl: "./menu-dialog.component.html",
    styleUrls: ["./menu-dialog.component.scss"],
})
export class MenuDialogComponent {
    @Output() close: EventEmitter<null> = new EventEmitter<null>()
}
