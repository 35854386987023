import {CommonModule} from "@angular/common"
import {Component, ContentChild, inject} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {DisplayStyleSwitchesComponent} from "@platform/components/shared/display-style-switches/display-style-switches.component"
import {InfiniteScrollAnchorComponent} from "@common/components/data/infinite-scroll-anchor/infinite-scroll-anchor.component"
import {PaginatorComponent} from "@common/components/data/paginator/paginator.component"
import {BreakpointsService} from "@common/services/breakpoints/breakpoints.service"
import {TableContentDirective} from "@platform/directives/content/table-content.directive"
import {GridContentDirective} from "@platform/directives/content/grid-content.directive"

@Component({
    selector: "cm-responsive-table-layout",
    standalone: true,
    imports: [CommonModule, DisplayStyleSwitchesComponent, EntityResponsiveSidebarComponent, InfiniteScrollAnchorComponent, PaginatorComponent, RouterOutlet],
    templateUrl: "./responsive-table-layout.component.html",
    styleUrl: "./responsive-table-layout.component.scss",
})
export class ResponsiveTableLayoutComponent {
    breakpoints = inject(BreakpointsService)

    @ContentChild(GridContentDirective) gridContent?: GridContentDirective
    @ContentChild(TableContentDirective) tableContent?: TableContentDirective
}
