import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpBlend} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-blend"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const BlendNode = declareImageOpNode(imageOpBlend)

export function blend(parameters: GetParameters<Context, ParameterType>) {
    return new BlendNode(parameters)
}
