<cm-responsive-table-layout>
    <cm-add-tag-button cm-buttons></cm-add-tag-button>
    <cm-item-filters cm-filters>
        <cm-search-filter></cm-search-filter>
    </cm-item-filters>

    <ng-template cmTableContent>
        <cm-tags-table></cm-tags-table>
    </ng-template>
    <ng-template cmGridContent>
        <cm-tags-grid></cm-tags-grid>
    </ng-template>
</cm-responsive-table-layout>
<router-outlet></router-outlet>
