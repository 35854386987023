import {ObjectId} from "@src/templates/interfaces/scene-object"
import {CompactUIDTable} from "@src/utils/utils"
import {BuilderOutlet, GraphBuilder} from "@src/templates/runtime-graph/graph-builder"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {TransformAccessor} from "@src/templates/runtime-graph/nodes/transform"
import {SolverObjectData} from "@src/templates/runtime-graph/nodes/solver/object-data"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {IMatrix4} from "@src/templates/interfaces/matrix"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {TemplateNode} from "@src/templates/types"
import {VisitorNodeContext, TemplateContext, VisitMode, ExternalId, EvaluatedTemplateInputs, getEmptyCompileContextData} from "@src/templates/types"
import {SceneProperties} from "@src/templates/nodes/scene-properties"
import {SolverData} from "@src/templates/runtime-graph/nodes/solver/data"
import {MergeSolverData} from "@src/templates/runtime-graph/nodes/merge-solver-data"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {mergeBounds} from "@src/templates/utils/scene-geometry-utils"
import {TemplateInstance} from "@src/templates/nodes/template-instance"
import {CachedNodeGraphResult} from "@src/graph-system/evaluators/cached-node-graph-result"
import {MaterialLike} from "@src/templates/node-types"
import {MaterialAssignments} from "@src/templates/nodes/material-assignment"
import {MeshDecal} from "@src/templates/nodes/mesh-decal"

const TD = TypeDescriptors

export interface TransformAccessorListEntry {
    objectId: ObjectId
    transformAccessor: TransformAccessor
}

const compileTemplateNewDescriptor = {
    templateContext: TD.inlet(TD.Identity<TemplateContext>()),
    graph: TD.inlet(TD.Nullable(TD.Identity<TemplateGraph>())), //TODO: deepCompare for graph?
    sceneProperties: TD.inlet(TD.Nullable(TD.Identity<SceneProperties>())),
    inputs: TD.inlet(TD.ShallowJSON<EvaluatedTemplateInputs>()),
    topLevelObjectId: TD.inlet(TD.Nullable(TD.Primitive<ObjectId>())),
    templateDepth: TD.inlet(TD.Number),
    exposeClaimedSubTemplateInputs: TD.inlet(TD.Boolean),
    overrideMaterial: TD.inlet(
        TD.Nullable(TD.Function<(parent: MeshDecal | {materialAssignments: MaterialAssignments; key: string}) => MaterialLike | null | undefined>()),
    ),
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    builder: TD.builder(),
    compiledTemplate: TD.outlet(TD.Function<(instanceToBind: any) => () => void>()),
    activeNodeSet: TD.outlet(TD.Set<TemplateNode>()),
    externalIdToNodeMap: TD.outlet(TD.Map<string, TemplateNode>()),
    objectToNodeMap: TD.outlet(TD.Map<ObjectId, TemplateNode>()),
    nodeToObjectMap: TD.outlet(TD.Map<TemplateNode, ObjectId>()),
}

export class CompileTemplateNew implements NodeClassImpl<typeof compileTemplateNewDescriptor, typeof CompileTemplateNew> {
    static descriptor = compileTemplateNewDescriptor
    static uniqueName = "CompileTemplateNew"
    templateContext!: Inlet<TemplateContext>
    graph!: Inlet<TemplateGraph | null>
    sceneProperties!: Inlet<SceneProperties | null>
    inputs!: Inlet<EvaluatedTemplateInputs>
    topLevelObjectId!: Inlet<ObjectId | null>
    templateDepth!: Inlet<number>
    exposeClaimedSubTemplateInputs!: Inlet<boolean>
    overrideMaterial!: Inlet<((parent: MeshDecal | {materialAssignments: MaterialAssignments; key: string}) => MaterialLike | null | undefined) | null>
    sceneManager!: Inlet<ISceneManagerNew>
    builder!: GraphBuilder
    compiledTemplate!: Outlet<(instanceToBind: any) => () => void>
    activeNodeSet!: Outlet<Set<TemplateNode>>
    externalIdToNodeMap!: Outlet<Map<string, TemplateNode>>
    objectToNodeMap!: Outlet<Map<ObjectId, TemplateNode>>
    nodeToObjectMap!: Outlet<Map<TemplateNode, ObjectId>>
    private idMap = new CompactUIDTable<string>()

    run() {
        if (this.templateContext === NotReady) return
        if (this.graph === NotReady) return
        if (this.sceneProperties === NotReady) return
        if (this.inputs === NotReady) return
        if (this.topLevelObjectId === NotReady) return
        if (this.templateDepth === NotReady) return
        if (this.exposeClaimedSubTemplateInputs === NotReady) return
        if (this.overrideMaterial === NotReady) return
        if (this.sceneManager === NotReady) return

        const templateScope = this.builder.scope()

        if (this.graph === null) throw new Error("Graph is null")

        //These are passed from the RunTemplate node
        const templateMatrix = templateScope.input<IMatrix4>("matrix")
        const templateSolverObject = templateScope.input<SolverObjectData>("solverObject")

        const {activeNodeSet, sceneProperties: localSceneProperties} = getTemplateProperties(
            this.idMap,
            templateScope,
            templateMatrix,
            this.templateContext,
            this.inputs,
            this.graph,
        )

        const sceneProperties = this.sceneProperties ?? localSceneProperties

        const context: VisitorNodeContext = {
            visitMode: VisitMode.Compile,
            onCompile: getEmptyCompileContextData(
                new NodeEvaluator(this.idMap, templateScope, templateMatrix, this.templateContext, this.inputs, activeNodeSet),
                this.graph,
                sceneProperties,
                this.templateDepth,
                this.topLevelObjectId,
                this.exposeClaimedSubTemplateInputs,
                this.overrideMaterial ?? undefined,
            ),
            skipNode: function (this: TemplateNode) {
                return !activeNodeSet.has(this)
            },
        }

        const resultCompile = new CachedNodeGraphResult(this.graph, context, true)
        resultCompile.runSync()

        if (!context.onCompile) throw new Error("No onCompile")

        const {currentTemplate, subTemplates} = context.onCompile
        const {
            preDisplayList: currentPreDisplayList,
            displayList: currentDisplayList,
            descriptorList: currentDescriptorList,
            solverData,
            transformAccessorList,
            allBounds,
            templateOutputs,
            objectToNodeMap,
            nodeToObjectMap,
        } = currentTemplate
        const {solverObjects, solverVariables, solverRelations} = solverData
        const {readyList, preDisplayLists, displayLists, descriptorLists, solverDatas, lookupByExternalIdPathMap} = subTemplates

        const thisPreDisplayList = templateScope.filterInvalid(templateScope.list(currentPreDisplayList, "thisPreDisplayList"))
        const thisDisplayList = templateScope.filterInvalid(templateScope.list(currentDisplayList, "thisDisplayList"))

        const mergedCurrentDisplayLists = templateScope.merge([thisPreDisplayList, thisDisplayList])
        const currentDisplayListsReady = templateScope.phi(
            templateScope.lambda(mergedCurrentDisplayLists, () => true, "displayDataReady"),
            false,
        )

        const ready = templateScope.and([...readyList, currentDisplayListsReady])

        templateScope.output("ready", ready)

        const thisPreDisplayListSparse = templateScope.filterInvalid(templateScope.sparseList(currentPreDisplayList, "thisPreDisplayListSparse"))
        const preDisplayList = templateScope.sparseMerge([thisPreDisplayListSparse, ...preDisplayLists], "preDisplayList")
        templateScope.output("preDisplayList", preDisplayList)

        const displayList = templateScope.merge([thisDisplayList, ...displayLists], "displayList")
        templateScope.output("displayList", displayList)

        const descriptorList = templateScope.merge([templateScope.list(currentDescriptorList, "thisDescriptorList"), ...descriptorLists], "descriptorList")
        templateScope.output("descriptorList", descriptorList)

        const currentSolverData = templateScope.struct<SolverData>("SolverData", {
            objects: templateScope.list(solverObjects),
            relations: templateScope.filterInvalid(templateScope.list(solverRelations)),
            variables: templateScope.list(solverVariables),
        })
        templateScope.output(
            "solverData",
            templateScope.node(MergeSolverData, {
                input: templateScope.list([currentSolverData, ...solverDatas]),
            }).output,
        )

        const outputs = templateScope.record(templateOutputs, "outputDict")
        templateScope.output("outputs", outputs)

        const mergedBounds = templateScope.lambda(templateScope.list(allBounds), (allBounds) => mergeBounds(allBounds), "mergedBounds")
        const objectData = templateScope.group<ObjectData>("ObjectData", {
            solverObject: templateSolverObject,
            matrix: templateMatrix,
            bounds: mergedBounds,
        })
        templateScope.output("objectData", objectData)

        templateScope.output("transformAccessorList", templateScope.list(transformAccessorList))

        const externalIdToNodeMap = getExternalIdToNodeMap(activeNodeSet)
        const childLookupFnKeys = [...lookupByExternalIdPathMap.keys()]
        const lookupByExternalIdPath = templateScope.lambda(
            templateScope.list([...lookupByExternalIdPathMap.values()]),
            (childLookupFns) => {
                return (path: string[]) => {
                    const shiftedPath = path.shift()
                    if (shiftedPath === undefined) {
                        return null
                    } else {
                        const childNode = externalIdToNodeMap.get(shiftedPath)
                        if (!childNode) return null
                        else if (path.length === 1) return childNode
                        if (childNode instanceof TemplateInstance) {
                            const idx = childLookupFnKeys.indexOf(childNode)
                            if (idx < 0) return null
                            return childLookupFns[idx](path)
                        } else return null
                    }
                }
            },
            "lookupByExternalIdPath",
        )
        templateScope.output("lookupByExternalIdPath", lookupByExternalIdPath)

        const compiledTemplate = this.builder.finalizeChanges()

        this.compiledTemplate.emit(compiledTemplate)
        this.activeNodeSet.emit(activeNodeSet)
        this.externalIdToNodeMap.emit(externalIdToNodeMap)
        this.objectToNodeMap.emit(objectToNodeMap)
        this.nodeToObjectMap.emit(nodeToObjectMap)
    }
}

const getTemplateProperties = (
    idMap: CompactUIDTable<string>,
    templateScope: GraphBuilderScope,
    templateMatrix: BuilderOutlet<IMatrix4>,
    templateContext: TemplateContext,
    inputs: EvaluatedTemplateInputs,
    graph: TemplateGraph,
) => {
    const activeNodeSet = new Set<TemplateNode>()

    const preEvaluator = new NodeEvaluator(idMap, templateScope, templateMatrix, templateContext, inputs, undefined)
    const context: VisitorNodeContext = {
        visitMode: VisitMode.FilterActive,
        onFilterActive: {
            evaluator: preEvaluator,
            root: graph,
            sceneProperties: undefined,
        },
        onPostUnskippedNode: function (this: TemplateNode) {
            if (activeNodeSet.has(this)) console.warn("Node will be processed multiple times", this)
            activeNodeSet.add(this)
        },
    }

    const resultFilterActive = new CachedNodeGraphResult(graph, context, true)
    resultFilterActive.runSync()

    if (!context.onFilterActive) throw new Error("No onFilterActive")

    const {sceneProperties} = context.onFilterActive

    return {activeNodeSet, sceneProperties}
}

const getExternalIdToNodeMap = (activeNodeSet: Set<TemplateNode>) => {
    const externalIdToNodeMap = new Map<string, TemplateNode>()
    const hasId = (node: TemplateNode): node is TemplateNode<{id: ExternalId}> => {
        const {id} = node as {id?: ExternalId}
        return typeof id === "string"
    }
    for (const node of activeNodeSet) if (hasId(node)) externalIdToNodeMap.set(node.parameters.id, node)
    return externalIdToNodeMap
}
