export enum InterpolationMode {
    Linear = "linear",
    // TODO more interpolation modes (e.g. cubic, spline, ...) could be added here
}

export enum OutOfBoundsMode {
    Clamp = "clamp",
    Extrapolate = "extrapolate",
    Undefined = "undefined",
}

// This class approximates a function given by x/y coordinate pairs.
// The pairs are sorted by x and then interpolated between.
// Different modes how to deal with out-of-bound values are supported.
export class SampledFunction {
    constructor(
        private values: {x: number; y: number}[],
        readonly interpolation = InterpolationMode.Linear,
        readonly outOfBoundsMode: OutOfBoundsMode = OutOfBoundsMode.Clamp,
    ) {
        if (values.length < 2) {
            throw Error("LUT must have at least 2 values.")
        }
        // sort values by x
        this.values = this.values.sort((a, b) => a.x - b.x)
    }

    evaluate(x: number): number | undefined {
        // check if out of bounds
        if (x < this.values[0].x) {
            switch (this.outOfBoundsMode) {
                case OutOfBoundsMode.Clamp:
                    return this.values[0].y
                case OutOfBoundsMode.Extrapolate:
                    return this.values[0].y + ((x - this.values[0].x) * (this.values[1].y - this.values[0].y)) / (this.values[1].x - this.values[0].x)
                case OutOfBoundsMode.Undefined:
                    return undefined
            }
        }
        if (x > this.values[this.values.length - 1].x) {
            switch (this.outOfBoundsMode) {
                case OutOfBoundsMode.Clamp:
                    return this.values[this.values.length - 1].y
                case OutOfBoundsMode.Extrapolate:
                    return (
                        this.values[this.values.length - 1].y +
                        ((x - this.values[this.values.length - 1].x) * (this.values[this.values.length - 1].y - this.values[this.values.length - 2].y)) /
                            (this.values[this.values.length - 1].x - this.values[this.values.length - 2].x)
                    )
                case OutOfBoundsMode.Undefined:
                    return undefined
            }
        }
        // bisect
        let minIndex = 0
        let maxIndex = this.values.length - 1
        while (minIndex < maxIndex) {
            const midIndex = Math.floor((minIndex + maxIndex) / 2)
            const midX = this.values[midIndex].x
            if (x < midX) {
                maxIndex = midIndex
            } else {
                minIndex = midIndex + 1
            }
        }
        // interpolate
        switch (this.interpolation) {
            case "linear":
                const min = this.values[minIndex - 1]
                const max = this.values[minIndex]
                const t = (x - min.x) / (max.x - min.x)
                return min.y + t * (max.y - min.y)
            default:
                throw Error(`Unknown interpolation mode: ${this.interpolation}`)
        }
    }
}
