import {NodeSettings} from "@material-editor/models/material-nodes"
import {MaterialSocket} from "@material-editor/models/material-socket"

export const Space: NodeSettings = {
    id: "internal.space",
    valueType: "string",
    options: [
        {label: "Object space", value: "OBJECT"},
        {label: "World space", value: "WORLD"},
    ],
}

export const DisplacementOutputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Displacement", type: "output", valueType: "output", label: "Displacement"},
}

export const DisplacementInputs: Record<string, MaterialSocket> = {
    height: {paramType: "socket", id: "Height", type: "input", valueType: "scalar", label: "Height"},
    midlevel: {paramType: "socket", id: "Midlevel", type: "input", valueType: "scalar", label: "Midlevel"},
    scale: {paramType: "socket", id: "Scale", type: "input", valueType: "scalar", label: "Scale"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "vector", label: "Normal"},
}
