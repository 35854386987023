import {Subject} from "rxjs"
import {OverlayRef} from "@angular/cdk/overlay"

export class ModalOverlayRef {
    private onCloseSource = new Subject<void>()
    onClose = this.onCloseSource.asObservable()

    constructor(private overlayRef: OverlayRef) {}

    close(): void {
        this.overlayRef.dispose()
        this.onCloseSource.next()
    }
}

// Dialog sizes
export enum DialogSize {
    Small = 10,
    Medium = 20,
    Large = 30,
}

export type ModalInfo = {
    dialogRef: ModalOverlayRef
    overlayRef: OverlayRef
}
