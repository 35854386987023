import {Component} from "@angular/core"
import {Annotation} from "@cm/lib/templates/nodes/annotation"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {isValidExternalId} from "@cm/lib/templates/types"

@Component({
    selector: "cm-annotation-inspector",
    standalone: true,
    templateUrl: "./annotation-inspector.component.html",
    styleUrl: "./annotation-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class AnnotationInspectorComponent extends BaseInspectorComponent<Annotation> {
    isValidExternalId = isValidExternalId
}
