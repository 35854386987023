import {inject, Injectable} from "@angular/core"
import {DownloadableFileFragment} from "@api"
import {SdkService} from "@common/services/sdk/sdk.service"

@Injectable()
export class FilesService {
    sdk = inject(SdkService)

    static downloadFile(filename: string, file: File | Blob | string) {
        const link = document.createElement("a")
        document.body.appendChild(link)
        link.setAttribute("download", filename)
        let revokeURL: string | undefined
        if (file instanceof File || file instanceof Blob) {
            link.href = revokeURL = URL.createObjectURL(file)
        } else {
            link.href = file
        }
        link.style.display = "none"
        link.click()
        document.body.removeChild(link)
        if (revokeURL) {
            window.setTimeout(() => URL.revokeObjectURL(revokeURL!), 1000)
        }
    }

    static downloadDataObject(dataObject: DownloadableFileFragment): void {
        this.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    downloadDataObjectByLegacyId(legacyId: number) {
        this.sdk.gql.downloadableFile({legacyId}).then(({dataObject}) => FilesService.downloadDataObject(dataObject))
    }

    downloadDataObjectById(id: string): void {
        this.sdk.gql.downloadableFile({id}).then(({dataObject}) => FilesService.downloadDataObject(dataObject))
    }
}
