import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {ValueSlotComponent} from "../../value-slot/value-slot.component"
import {Export} from "@cm/lib/templates/node-types"
import {isValidExternalId} from "@cm/lib/templates/types"
import {getTemplateNodeClassLabel} from "@cm/lib/templates/utils"

@Component({
    selector: "cm-export-inspector",
    standalone: true,
    templateUrl: "./export-inspector.component.html",
    styleUrl: "./export-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent],
})
export class ExportInspectorComponent extends BaseInspectorComponent<Export> {
    class = computed(() => getTemplateNodeClassLabel(this.node()).replace("Export", "Output"))
    isValidExternalId = isValidExternalId
}
