import {bufferTime, filter, Subject} from "rxjs"
import {Settings} from "@common/models/settings/settings"
import {ApiRequest} from "@common/models/api-request/api-request"
import {IsDefined} from "@cm/lib/utils/filter"

/*
    This class is a helper for batching API calls. It batches API calls and sends them in a single request to reduce strain on the backend.
    If you are making multiple API calls in a short period of time (e.g. by instantiating a lot of individual components fetching some data),
    you can use this class to batch them together.

    If you are dispatching the responses by an "id" field you can use the BatchApiCallById class instead.
 */
export abstract class BatchApiCall<
    RequestPayload,
    ResponsePayload,
    BatchedRequestPayload extends {
        requests: ApiRequest<RequestPayload, ResponsePayload>[]
    },
> {
    protected bufferTime = Settings.API_CALL_BUFFER_TIME_IN_MS

    protected abstract batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[]

    protected abstract callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]>

    protected abstract dispatchResponses(batchedPayload: BatchedRequestPayload, responses: ResponsePayload[]): void

    protected constructor() {
        this.apiRequests$
            .pipe(
                bufferTime(this.bufferTime),
                filter((requests) => requests.length > 0),
            )
            .subscribe((requests) =>
                Promise.all(
                    this.batchRequests(requests).map((batchedPayload) => {
                        this.callApi(batchedPayload)
                            .then((responses) => {
                                this.dispatchResponses(batchedPayload, responses.filter(IsDefined))
                            })
                            .catch((error) => {
                                batchedPayload.requests.forEach((request) => request.reject(error))
                            })
                    }),
                ),
            )
    }

    fetch(payload: RequestPayload) {
        return new Promise<ResponsePayload>((resolve, reject) => {
            this.apiRequests$.next({
                payload,
                resolve,
                reject,
            })
        })
    }

    private apiRequests$ = new Subject<ApiRequest<RequestPayload, ResponsePayload>>()
}
