import {SlicePipe} from "@angular/common"
import {Component, inject, Input} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {BasicTagInfoFragment} from "@api"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {TagsService} from "@common/services/tags/tags.service"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {Labels} from "@labels"
import {IEditor} from "@editor/models/editor"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {IsNonNull} from "@cm/lib/utils/filter"

@Component({
    selector: "cm-template-output-inspector",
    templateUrl: "./template-output-inspector.component.html",
    styleUrls: ["./template-output-inspector.component.scss"],
    standalone: true,
    imports: [StringInputComponent, NodeReferenceSlotComponent, SearchComponent, DropdownComponent, SlicePipe, MatTooltipModule],
})
export class TemplateOutputInspectorComponent {
    @Input({required: true}) editor!: IEditor
    @Input() node: Nodes.TemplateExport | null = null

    focusSearch = false
    searchTags?: BasicTagInfoFragment[]
    private _searchText?: string

    isValidId = NodeUtils.isValidId

    tags = inject(TagsService)

    updateNode(node: Nodes.Node): void {
        this.editor.sceneManager.markNodeChanged(node)
    }

    get searchText() {
        return this._searchText
    }

    set searchText(text: string | undefined) {
        this._searchText = text
        if (text == null) {
            this.searchTags = undefined
            return
        }
        this.searchTags = []
        const existingIds = this.node && this.getTagIds(this.node)
        for (const tag of this.tags.$templateInputsOutputs()) {
            if (tag.name.toLowerCase().search(text.toLowerCase()) != -1 && existingIds?.indexOf(tag.legacyId) === -1) {
                this.searchTags.push(tag)
            }
        }
    }

    getTagIds(node: Nodes.TemplateExport): number[] {
        if (!node.tags) return []
        return node.tags.map((x) => x.tagId)
    }

    getTags(node: Nodes.TemplateExport): BasicTagInfoFragment[] {
        if (!node.tags) return []
        return node.tags
            .map((x) => x.tagId)
            .map((tagLegacyId) => this.tags.byLegacyId(tagLegacyId))
            .filter(IsNonNull)
    }

    getTagById(id: number): BasicTagInfoFragment | null {
        return this.tags.byLegacyId(id)
    }

    addTag(node: Nodes.TemplateExport, tag: BasicTagInfoFragment): void {
        if (!node.tags) {
            node.tags = []
        }
        node.tags.push(
            Nodes.create<Nodes.TagReference>({
                type: "tagReference",
                name: tag.name,
                tagId: tag.legacyId,
            }),
        )
        this.updateNode(node)
    }

    removeTag(node: Nodes.TemplateExport, tag: BasicTagInfoFragment): void {
        if (!node.tags) return
        node.tags = node.tags.filter((x) => x.tagId !== tag.legacyId)
        this.updateNode(node)
    }

    protected readonly Labels = Labels
}
