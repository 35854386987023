import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {
    Operator,
    OperatorCanvasToolboxType,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorParameterValue,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {RotatePanelComponent} from "app/textures/texture-editor/operator-stack/operators/rotate/panel/rotate-panel.component"
import {EventEmitter} from "@angular/core"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {deepCopy, wrap} from "@cm/lib/utils/utils"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {deg2rad} from "@cm/lib/math/utils"
import {affineTransform} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/affine-transform-node"
import {lambda} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {ParameterValue} from "@cm/lib/graph-system/node-graph"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {extractChannel} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/extract-channel-node"
import {math} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/math-node"
import {combineChannels} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/combine-channels-node"
import {TextureType} from "@api"
import {ImageOpNodeGraphEvaluator} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"

export class OperatorRotate extends OperatorBase<TextureEditNodes.OperatorRotate> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["apply-to-all-texture-types"]) // rotation is not preserving texture size, so it must be applied to all texture types

    readonly angleInDegreesChanged = new EventEmitter<number>()

    readonly panelComponentType: OperatorPanelComponentType = RotatePanelComponent
    readonly canvasToolbox: OperatorCanvasToolboxType = null

    readonly type = "operator-rotate" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorRotate | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-rotate",
                enabled: true,
                angleInDegrees: 0,
            },
        )
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorRotate(this.callback, deepCopy(this.node))
        return clonedOperator
    }

    // OperatorBase
    async getImageOpNodeGraph(evaluator: ImageOpNodeGraphEvaluator, textureType: TextureType, input: OperatorInput): Promise<OperatorOutput> {
        if (this.angleInDegrees === 0) {
            // don't do anything if angle is 0
            return {resultImage: input}
        } else {
            let resultImage: ParameterValue<ImagePtr, Context> = affineTransform({
                sourceImage: input,
                transform: lambda({angleInDegrees: this.angleInDegrees}, async ({parameters: {angleInDegrees}}) => {
                    const transform = new Matrix3x2().rotate(angleInDegrees)
                    return transform
                }),
            })
            if (textureType === TextureType.Normal) {
                // normal maps need to be rotated as well to keep consistent
                resultImage = this.rotateVectorMap(resultImage, this.angleInDegrees, -1) // we need to rotate the opposite direction
            } else if (textureType === TextureType.Anisotropy) {
                // anisotropy maps need to be rotated as well to keep consistent
                resultImage = this.rotateVectorMap(resultImage, this.angleInDegrees, -2) // we need to rotate by twice the angle because anisotropy is periodic in 180°
            } else if (textureType === TextureType.AnisotropyRotation) {
                // anisotropy rotation map need to be rotated as well to keep consistent
                resultImage = this.rotateAngleMap(resultImage, this.angleInDegrees, -1) // anisotropy is periodic in 180° but the map stores only 0..0.5 range
            }
            return {resultImage}
        }
    }

    private rotateVectorMap(
        source: OperatorParameterValue<ImagePtr>,
        angleInDegrees: OperatorParameterValue<number>,
        angleFactor = 1,
    ): OperatorParameterValue<ImagePtr> {
        let x: OperatorParameterValue<ImagePtr> = extractChannel({
            sourceImage: source,
            channel: "R",
        })
        let y: OperatorParameterValue<ImagePtr> = extractChannel({
            sourceImage: source,
            channel: "G",
        })
        const z: OperatorParameterValue<ImagePtr> = extractChannel({
            sourceImage: source,
            channel: "B",
        })

        x = this.zeroOneToMinusOnePlusOneRangeConversion(x)
        y = this.zeroOneToMinusOnePlusOneRangeConversion(y)

        const xTimesAngleCos = math({
            operandA: x,
            operandB: lambda({angleInDegrees}, async ({parameters: {angleInDegrees}}) => Math.cos(deg2rad(angleInDegrees * angleFactor))),
            operator: "*",
        })
        const yTimesAngleSin = math({
            operandA: y,
            operandB: lambda({angleInDegrees}, async ({parameters: {angleInDegrees}}) => Math.sin(deg2rad(angleInDegrees * angleFactor))),
            operator: "*",
        })
        let rotatedX: OperatorParameterValue<ImagePtr> = math({
            operandA: xTimesAngleCos,
            operandB: yTimesAngleSin,
            operator: "+",
        })

        const yTimesAngleCos = math({
            operandA: y,
            operandB: lambda({angleInDegrees}, async ({parameters: {angleInDegrees}}) => Math.cos(deg2rad(angleInDegrees * angleFactor))),
            operator: "*",
        })
        const xTimesAngleSin = math({
            operandA: x,
            operandB: lambda({angleInDegrees}, async ({parameters: {angleInDegrees}}) => Math.sin(deg2rad(angleInDegrees * angleFactor))),
            operator: "*",
        })
        let rotatedY: OperatorParameterValue<ImagePtr> = math({
            operandA: yTimesAngleCos,
            operandB: xTimesAngleSin,
            operator: "-",
        })

        rotatedX = this.minusOnePlusOneToZeroOneRangeConversion(rotatedX)
        rotatedY = this.minusOnePlusOneToZeroOneRangeConversion(rotatedY)

        return combineChannels({
            sourceImages: lambda({x: rotatedX, y: rotatedY, z}, async ({parameters: {x, y, z}}) => {
                return [new ImagePtr(x), new ImagePtr(y), new ImagePtr(z)]
            }),
        })
    }

    private rotateAngleMap(
        source: OperatorParameterValue<ImagePtr>,
        angleInDegrees: OperatorParameterValue<number>,
        angleFactor = 1,
    ): OperatorParameterValue<ImagePtr> {
        return math({
            operandA: math({
                operandA: source,
                operandB: lambda({angleInDegrees}, async ({parameters: {angleInDegrees}}) => wrap((angleFactor * angleInDegrees) / 360, 1)),
                operator: "+",
            }),
            operandB: 0.5,
            operator: "mod",
        })
    }

    // [0..1] -> [-1..1]
    private zeroOneToMinusOnePlusOneRangeConversion(source: OperatorParameterValue<ImagePtr>): OperatorParameterValue<ImagePtr> {
        let result = math({
            operandA: source,
            operandB: 2,
            operator: "*",
        })
        result = math({
            operandA: result,
            operandB: 1,
            operator: "-",
        })
        return result
    }

    // [-1..1] -> [0..1]
    private minusOnePlusOneToZeroOneRangeConversion(source: OperatorParameterValue<ImagePtr>): OperatorParameterValue<ImagePtr> {
        let result = math({
            operandA: source,
            operandB: 0.5,
            operator: "*",
        })
        result = math({
            operandA: result,
            operandB: 0.5,
            operator: "+",
        })
        return result
    }

    get angleInDegrees(): number {
        return this.node.angleInDegrees
    }

    set angleInDegrees(value: number) {
        if (this.node.angleInDegrees === value) {
            return
        }
        this.node.angleInDegrees = value
        this.markEdited()
        this.requestEval()
        this.angleInDegreesChanged.emit(value)
    }
}
