import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"

const TD = TypeDescriptors

const findMaterialNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    customerId: TD.inlet(TD.Nullable(TD.Number)),
    articleId: TD.inlet(TD.Nullable(TD.String)),
    materialGraph: TD.outlet(TD.Nullable(TD.MaterialGraph)),
}

export class FindMaterialNew implements NodeClassImpl<typeof findMaterialNewDescriptor, typeof FindMaterialNew> {
    static descriptor = findMaterialNewDescriptor
    static uniqueName = "FindMaterialNew"
    sceneManager!: Inlet<ISceneManagerNew>
    customerId!: Inlet<number | null>
    articleId!: Inlet<string | null>
    materialGraph!: Outlet<IMaterialGraph | null>

    run() {
        this.materialGraph.emitIfChanged(NotReady)
        if (this.sceneManager === NotReady || this.articleId === NotReady || this.customerId === NotReady) return

        if (this.articleId === null) {
            this.materialGraph.emitIfChanged(null)
            return
        }

        this.sceneManager.findMaterialNew(this.articleId, this.customerId).then((materialGraph) => {
            this.materialGraph.emitIfChanged(materialGraph)
        })
    }

    cleanup() {}
}
