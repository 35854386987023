@if ($data().isPlaceholder || isLoading) {
    <cm-thumbnail [ngClass]="'cm-placeholder'" [url]="$data().url" [layout]="$data().layout" (load)="onLoad($event)"></cm-thumbnail>
} @else {
    @if ($data().isImage) {
        <cm-thumbnail [ngClass]="'cm-thumbnail'" [url]="$data().url" [layout]="$data().layout" (load)="onLoad($event)"></cm-thumbnail>
    } @else {
        <i [ngClass]="$data().icon"></i>
    }
}
@if ($shouldShowLabel()) {
    @if ($data().label; as label) {
        <span>{{ label }}</span>
    }
}
