import {inject, Injectable} from "@angular/core"
import {MaterialForConfigMenuLegacyServiceFragment} from "@api"
import {NamedConfiguratorVariant} from "@cm/lib/pricing/nodes/core"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {colorToString} from "@cm/lib/utils/utils"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {Dictionary} from "@legacy/helpers/utils"
import {SdkService} from "@common/services/sdk/sdk.service"
import {BehaviorSubject, from, Observable, of, Subject} from "rxjs"
import {InterfaceDescriptor as InterfaceDescriptorNew} from "@cm/lib/templates/interface-descriptors"

export type MaterialSelectionEvent = {input: Nodes.Meta.MaterialInputInfo; material: MaterialForConfigMenuLegacyServiceFragment}
export type ConfigSelectionEvent = {config: Nodes.Meta.ConfigInfo; variant: Nodes.Meta.VariantInfo}
export type ParameterChangeEvent = {input: Nodes.Meta.InputDescriptor; value: unknown}

@Injectable({
    providedIn: "root",
})
export class ConfigMenuLegacyService {
    sdk = inject(SdkService)

    configVariantColors: Dictionary<string> = {}
    private interfaces: Nodes.Meta.InterfaceDescriptor[] = []

    constructor() {}

    private dataObjectThumbnailCache = new AsyncCacheMap<number, string | null>((dataObjectLegacyId) =>
        from(
            (async () => {
                const {dataObject} = await this.sdk.gql.dataObjectThumbnailForConfigMenuLegacyService({legacyId: dataObjectLegacyId})
                return dataObject?.thumbnail?.downloadUrl ?? null
            })(),
        ),
    )

    //Inputs of the menu
    private interfaceSubject = new BehaviorSubject<Nodes.Meta.InterfaceDescriptor[]>([])
    interface$ = this.interfaceSubject.asObservable()
    setInterfaceLegacy(node: Nodes.Meta.InterfaceDescriptor[]): void {
        // consider interface changed if the id or value of any interface has changed.
        // does this cover all cases?
        const isEqual = (a: Nodes.Meta.InterfaceDescriptor, b: Nodes.Meta.InterfaceDescriptor) => a.id === b.id && a.value === b.value
        if (!(this.interfaces.some((x) => !node.some((y) => isEqual(x, y))) || node.some((x) => !this.interfaces.some((y) => isEqual(x, y))))) return
        this.interfaces = node
        this.interfaceSubject.next(node)
    }

    private interfaceNewSubject = new BehaviorSubject<InterfaceDescriptorNew<unknown, object>[]>([])
    interfaceNew$ = this.interfaceNewSubject.asObservable()
    setInterface(interfaceDescriptors: InterfaceDescriptorNew<unknown, object>[]): void {
        this.interfaceNewSubject.next(interfaceDescriptors)
        this.interfaceSubject.next(interfaceDescriptors.map((desc) => desc.toLegacy()))
    }

    private uiStyleSubject = new BehaviorSubject<Nodes.UiStyle | undefined>(undefined)
    uiStyle$ = this.uiStyleSubject.asObservable()
    setUiStyle(uiStyle: Nodes.UiStyle): void {
        this.uiStyleSubject.next(uiStyle)
    }

    private iconSizeSubject = new BehaviorSubject<number>(24)
    iconSize$ = this.iconSizeSubject.asObservable()
    setIconSize(iconSize: number): void {
        this.iconSizeSubject.next(iconSize)
    }

    //Outputs of the menu
    private materialSelectedSubject = new Subject<MaterialSelectionEvent>()
    materialSelected$ = this.materialSelectedSubject.asObservable()
    emitMaterialSelected(configEvent: MaterialSelectionEvent): void {
        this.materialSelectedSubject.next(configEvent)
    }

    private configSelectedSubject = new Subject<ConfigSelectionEvent>()
    configSelected$ = this.configSelectedSubject.asObservable()
    emitConfigSelected(configEvent: ConfigSelectionEvent): void {
        this.configSelectedSubject.next(configEvent)
    }

    private parameterChangedSubject = new Subject<ParameterChangeEvent>()
    parameterChanged$ = this.parameterChangedSubject.asObservable()
    emitParameterChanged(parameterChangeEvent: ParameterChangeEvent): void {
        this.parameterChangedSubject.next(parameterChangeEvent)
    }

    private pdfDownloadRequestedSubject = new Subject<void>()
    pdfDownloadRequested$ = this.pdfDownloadRequestedSubject.asObservable()
    emitPdfDownloadRequested(): void {
        this.pdfDownloadRequestedSubject.next()
    }

    private pdfDownloadFinishedSubject = new Subject<void>()

    emitPdfDownloadFinished(): void {
        this.pdfDownloadFinishedSubject.next()
    }

    pdfDownloadFinished$(): Observable<void> {
        return this.pdfDownloadFinishedSubject.asObservable()
    }

    getSelectedVariants(): NamedConfiguratorVariant[] {
        const result: NamedConfiguratorVariant[] = []

        this.interfaceSubject.getValue().forEach((desc) => {
            if (Nodes.Meta.isConfigInput(desc)) {
                result.push({
                    groupId: desc.id,
                    groupName: desc.name,
                    variantId: Nodes.Meta.getValueForInterface(desc) as string, //this is the value that is selected in the ui, i.e. the crucial part
                    variantName: desc.variants.find((v) => v.id === Nodes.Meta.getValueForInterface(desc))?.name ?? "unknown",
                })
            }
        })

        return result
    }

    getSelectedVariantForGroup(groupId: string): NamedConfiguratorVariant {
        const desc = this.interfaceSubject.getValue().find((desc) => desc.id === groupId)
        if (!desc) throw new Error(`Group ${groupId} not found`)

        if (!Nodes.Meta.isConfigInput(desc)) throw new Error(`Group ${groupId} is not a config input`)

        return {
            groupId: desc.id,
            groupName: desc.name,
            variantId: Nodes.Meta.getValueForInterface(desc) as string, //this is the value that is selected in the ui, i.e. the crucial part
            variantName: desc.variants.find((v) => v.id === Nodes.Meta.getValueForInterface(desc))?.name ?? "unknown",
        }
    }

    getVariant(variantId: string): Nodes.Meta.VariantInfo | undefined {
        for (const descriptor of this.interfaceSubject.getValue()) {
            if (!Nodes.Meta.isConfigInput(descriptor)) continue
            for (const variant of descriptor.variants) {
                if (variant.id === variantId) return variant
            }
        }

        return undefined
    }

    getDescriptorForVariant(variantId: string): Nodes.Meta.InterfaceDescriptor | undefined {
        for (const descriptor of this.interfaceSubject.getValue()) {
            if (!Nodes.Meta.isConfigInput(descriptor)) continue
            for (const variant of descriptor.variants) {
                if (variant.id === variantId) return descriptor
            }
        }

        return undefined
    }

    fetchThumbnailForDataObject(id?: number): Observable<string | null> {
        if (id != null) {
            return this.dataObjectThumbnailCache.get(id)
        } else {
            return of(null)
        }
    }

    updateConfigVariantColors(): void {
        for (const config of this.interfaceSubject.getValue()) {
            if (!Nodes.Meta.isConfigInput(config)) continue
            for (const variant of config.variants) {
                let colorString = "#000000"
                if ("iconColor" in variant) {
                    colorString = colorToString(variant.iconColor)
                }
                this.configVariantColors[variant.id] = colorString
            }
        }
    }
}

// //Convenience method relevant for mapping prices programmatically, can be removed later.
// function createNode(config: {id: string; name: string; variants: {id: string}[]}): string {
//     const varName = config.name.replace(/\s+/g, "")
//     const groupId = unwrapInterfaceId(config.id)[1]
//     const currentVariantId = config.variants[0].id
//     const allVariantIds = config.variants.map((v) => `"${v.id}"`).join(", ")
//
//     return `let ${varName} = new ConfigurationGroupNode({groupId: "${groupId}", currentVariantId: "${currentVariantId}", allVariantIds: [${allVariantIds}]})`
// }

//The following is needed inside the service:
//public interfaceCache: Map<string, Array<Nodes.Meta.InterfaceDescriptor>>
//this.interfaceCache = new Map()
// printNodes() {
//     console.log("aa", this.interfaceCache)
//     let result = ""
//
//     this.interfaceCache.forEach((values, _key) => {
//         const prefix = values.length !== 1 ? "!" : ""
//
//         values.forEach((value) => {
//             result += prefix + createNode(value) + "\n\n"
//         })
//     })
//
//     console.log(result)
//
//     const nodesAsArray: Nodes.Meta.InterfaceDescriptor[] = Array.from(this.interfaceCache.values()).flat()
//     console.log(JSON.stringify(nodesAsArray))
// }
//
// //Convenience method relevant for mapping prices programmatically, can be removed later.
// cacheInterface(node: Nodes.Meta.InterfaceDescriptor[]): void {
//     node.forEach((desc) => {
//         if (Nodes.Meta.isConfigInput(desc)) {
//             const newVariantIds = desc.variants.map((v) => v.id)
//
//             const key = unwrapInterfaceId(desc.id)[1]
//             if (this.interfaceCache.has(key)) {
//                 const existingDescriptors = this.interfaceCache.get(key)!
//
//                 const exists = existingDescriptors.some((existingDesc) => {
//                     const existingVariantIds = existingDesc.variants.map((v) => v.id)
//                     return existingVariantIds.length === newVariantIds.length && newVariantIds.every((id) => existingVariantIds.includes(id))
//                 })
//
//                 if (!exists) existingDescriptors.push(desc)
//             } else {
//                 this.interfaceCache.set(key, [desc])
//             }
//         }
//     })
// }
