import {Component} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent, SearchFilterComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {GridTableLayoutComponent} from "@platform/components/layouts/grid-table-layout/grid-table-layout.component"
import {ResponsiveTableLayoutComponent} from "@platform/components/layouts/responsive-table-layout/responsive-table-layout.component"
import {AddTagButtonComponent} from "@platform/components/tags/add-tag-button/add-tag-button.component"
import {TagsGridComponent} from "@platform/components/tags/tags-grid/tags-grid.component"
import {TagsTableComponent} from "@platform/components/tags/tags-table/tags-table.component"
import {TableContentDirective} from "@platform/directives/content/table-content.directive"
import {GridContentDirective} from "@platform/directives/content/grid-content.directive"

@Component({
    imports: [
        AddTagButtonComponent,
        GridTableLayoutComponent,
        SearchFilterComponent,
        TagsTableComponent,
        TagsGridComponent,
        ItemFiltersComponent,
        RouterOutlet,
        ResponsiveTableLayoutComponent,
        TagSearchFilterComponent,
        CheckboxesFilterComponent,
        TableContentDirective,
        GridContentDirective,
    ],
    selector: "cm-tags-page",
    standalone: true,
    styleUrls: ["./tags-page.component.scss"],
    templateUrl: "./tags-page.component.html",
})
export class TagsPageComponent {}
