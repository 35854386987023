import {Component, Input} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {JobState} from "@api"
import {jobStateColor} from "@common/helpers/jobs/color"
import {jobStateIconClass} from "@common/helpers/jobs/icons"
import {TippyDirective} from "@ngneat/helipopper"
import {JobStateTooltipComponent} from "@platform/components/jobs/job-state-tooltip/job-state-tooltip.component"

@Component({
    selector: "cm-job-thumbnail",
    standalone: true,
    imports: [MatTooltipModule, TippyDirective],
    templateUrl: "./job-thumbnail.component.html",
    styleUrl: "./job-thumbnail.component.scss",
})
export class JobThumbnailComponent {
    @Input() job: {state: JobState} | null = null
    protected readonly jobStateIconClass = jobStateIconClass
    protected readonly JobStateTooltipComponent = JobStateTooltipComponent
    protected readonly jobStateColor = jobStateColor
}
