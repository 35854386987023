import {Component, computed} from "@angular/core"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {SelectionPossibilities, ValueSlotComponent} from "../../value-slot/value-slot.component"
import {DecalMaskType, MeshDecal} from "@cm/lib/templates/nodes/mesh-decal"
import {ButtonComponent} from "../../../../common/components/buttons/button/button.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MaterialAssignment} from "@cm/lib/templates/nodes/material-assignment"

const UV_PRECISION = 0.01

@Component({
    selector: "cm-mesh-decal-inspector",
    standalone: true,
    templateUrl: "./mesh-decal-inspector.component.html",
    styleUrl: "./mesh-decal-inspector.component.scss",
    imports: [InspectorSectionComponent, ValueSlotComponent, ButtonComponent],
})
export class MeshDecalInspectorComponent extends BaseInspectorComponent<MeshDecal> {
    mesh = computed(() => this.parameters().mesh)
    invertMaskPossibilities: SelectionPossibilities<boolean> = [
        {value: true, name: "White is transparent"},
        {value: false, name: "Black is transparent"},
    ]
    maskTypePossibilities: SelectionPossibilities<DecalMaskType> = [
        {value: "binary", name: "Binary"},
        {value: "opacity", name: "Opacity"},
    ]

    onRequestUpdate(materialAssignment: unknown) {
        if (materialAssignment instanceof MaterialAssignment) {
            this.sceneManagerService.modifyTemplateGraph(() => this.node().updateParameters({materialAssignment: materialAssignment}))
        } else if (materialAssignment === null) {
            this.sceneManagerService.modifyTemplateGraph(() => this.node().updateParameters({materialAssignment: null}))
        }
    }

    chooseUV() {
        const mesh = this.mesh()
        if (!mesh) return

        this.sceneManagerService
            .watchForClickedTemplateNodePart()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((templateNodePartClickEvent) => {
                const {target} = templateNodePartClickEvent
                if (!target) return

                const {templateNodePart, intersection} = target
                if (!intersection) return

                const {uv} = intersection
                if (!uv) return

                const {templateNode} = templateNodePart
                if (templateNode !== mesh) return

                const {x, y} = uv

                const u = Math.round(x / UV_PRECISION) * UV_PRECISION
                const v = Math.round(y / UV_PRECISION) * UV_PRECISION

                this.sceneManagerService.modifyTemplateGraph(() => {
                    this.node().updateParameters({offset: [u, v]})
                })
            })
    }
}
