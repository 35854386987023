// @ts-strict-ignore
import {TextFieldModule} from "@angular/cdk/text-field"
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core"

@Component({
    selector: "cm-native-input-text-area",
    standalone: true,
    templateUrl: "./native-input-text-area.component.html",
    styleUrls: ["./native-input-text-area.component.scss"],
    imports: [TextFieldModule],
})
export class NativeInputTextAreaComponent implements OnInit, AfterViewInit {
    @ViewChild("inputField") private inputElement: ElementRef
    @Input() placeholder: string
    @Input() value: string
    @Input() autoFocus = false
    @Output() valueChange = new EventEmitter<string>()
    @Output() keyUp = new EventEmitter<string>()
    @Input() disabled = false
    @Input() minRows = 0
    // ignore the value input after the initial value is set
    // useful to prevent the cursor from jumping while the user is typing
    @Input() fixValue = true

    initialValue: string
    ngOnInit() {
        this.initialValue = this.value
    }

    ngAfterViewInit(): void {
        if (this.autoFocus) {
            this.inputElement.nativeElement.focus()
        }
    }
}
