import {Component, Input} from "@angular/core"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {BrushSettingsPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/shared/panel/brush-settings-panel/brush-settings-panel.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {SliderComponent} from "@common/components/inputs/slider/slider.component"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {LayerEditMode, LayerMoveMode, OperatorLayerAndMask} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/operator-layer-and-mask"

@Component({
    selector: "cm-layer-and-mask-panel",
    templateUrl: "./layer-and-mask-panel.component.html",
    styleUrls: ["./layer-and-mask-panel.component.scss"],
    standalone: true,
    imports: [InspectorSectionComponent, ToggleComponent, SliderComponent, ButtonComponent, BrushSettingsPanelComponent],
})
export class LayerAndMaskPanelComponent {
    @Input() operator!: OperatorLayerAndMask
    @Input() disabled = false

    constructor() {}

    set layerMinOpacity(value: number) {
        this.operator.layerMinOpacity = value
    }

    get layerMinOpacity(): number {
        return this.operator.layerMinOpacity
    }

    set layerMaxOpacity(value: number) {
        this.operator.layerMaxOpacity = value
    }

    get layerMaxOpacity(): number {
        return this.operator.layerMaxOpacity
    }

    set maskFeathering(value: number) {
        this.operator.maskFeathering = value
    }

    get maskFeathering(): number {
        return this.operator.maskFeathering
    }

    set layerEditMode(value: LayerEditMode) {
        this.operator.layerEditMode = value
    }

    get layerEditMode(): LayerEditMode {
        return this.operator.layerEditMode
    }

    set layerMoveMode(value: LayerMoveMode) {
        this.operator.layerMoveMode = value
    }

    get layerMoveMode(): LayerMoveMode {
        return this.operator.layerMoveMode
    }

    get brushSettings(): BrushSettings {
        return this.operator.brushSettings
    }

    set showGuides(value: boolean) {
        this.operator.showGuides = value
    }

    get showGuides(): boolean {
        return this.operator.showGuides
    }

    set showMask(value: boolean) {
        this.operator.showMask = value
    }

    get showMask(): boolean {
        return this.operator.showMask
    }

    invertMask(): void {
        this.operator.invertMask()
    }

    layerEditModes = LayerEditMode
    layerMoveModes = LayerMoveMode
}
