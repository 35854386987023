import {Box2Like} from "@cm/lib/math/box2"
import {Vector2Like} from "@cm/lib/math/vector2"
import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {getHalAddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {wrap} from "@cm/lib/utils/utils"

export type ParameterType = {
    sourceImage: ImagePtr
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    targetOffset?: Vector2Like // default: {x: 0, y: 0}
    addressMode?: AddressMode // default: "wrap"
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpCopyRegion: ImageOpType<ParameterType, ReturnType> = {
    name: "CopyRegion",

    WebGL2: async ({context, parameters: {sourceImage: source, sourceRegion, targetOffset, addressMode, resultImage}}) => {
        addressMode ??= "wrap"
        const halCopyRegion = await context.getOrCreateImageCompositor(`
            uniform ivec2 u_textureOfs0;
        
            vec4 computeColor(ivec2 targetPixel) {
                return texelFetch0(targetPixel + u_textureOfs0, ${getHalAddressMode(addressMode)});
            }
        `)
        using sourceImageWebGl2 = await context.getImage(source)
        sourceRegion ??= {x: 0, y: 0, width: sourceImageWebGl2.ref.halImage.descriptor.width, height: sourceImageWebGl2.ref.halImage.descriptor.height}
        targetOffset ??= {x: 0, y: 0}
        const targetRegion = {x: targetOffset.x, y: targetOffset.y, width: sourceRegion.width, height: sourceRegion.height}
        resultImage = await context.prepareResultImage(resultImage, {
            width: targetOffset.x + sourceRegion.width,
            height: targetOffset.y + sourceRegion.height,
            channelLayout: sourceImageWebGl2.ref.descriptor.channelLayout,
            format: sourceImageWebGl2.ref.descriptor.format,
            isSRGB: sourceImageWebGl2.ref.descriptor.isSRGB,
        })
        using resultImageWebGl2 = await context.getImage(resultImage)
        halCopyRegion.setParameter("u_textureOfs0", {type: "int2", value: {x: sourceRegion.x - targetOffset.x, y: sourceRegion.y - targetOffset.y}})
        await halCopyRegion.paint(resultImageWebGl2.ref.halImage, sourceImageWebGl2.ref.halImage, {targetRegion})
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, sourceRegion, targetOffset, addressMode, resultImage}}) => {
        using sourceImageImgProc = await context.getImage(sourceImage)
        sourceRegion ??= {x: 0, y: 0, width: sourceImageImgProc.ref.descriptor.width, height: sourceImageImgProc.ref.descriptor.height}
        targetOffset ??= {x: 0, y: 0}
        resultImage = await context.prepareResultImage(resultImage, {
            width: targetOffset.x + sourceRegion.width,
            height: targetOffset.y + sourceRegion.height,
            channelLayout: sourceImageImgProc.ref.descriptor.channelLayout,
            format: sourceImageImgProc.ref.descriptor.format,
            isSRGB: sourceImageImgProc.ref.descriptor.isSRGB,
        })
        using resultImageImgProc = await context.getImage(resultImage)
        addressMode ??= "wrap"
        switch (addressMode) {
            case "wrap":
                {
                    let node: Nodes.ImageNode = resultImageImgProc.ref.node
                    sourceRegion.x = wrap(sourceRegion.x, sourceImageImgProc.ref.descriptor.width)
                    sourceRegion.y = wrap(sourceRegion.y, sourceImageImgProc.ref.descriptor.height)
                    const sw = Math.min(sourceRegion.width, sourceImageImgProc.ref.descriptor.width - sourceRegion.x)
                    const sh = Math.min(sourceRegion.height, sourceImageImgProc.ref.descriptor.height - sourceRegion.y)
                    if (sw > 0 && sh > 0) {
                        node = Nodes.copyRegion(
                            sourceImageImgProc.ref.node,
                            Nodes.region(sourceRegion.x, sourceRegion.y, sw, sh),
                            node,
                            Nodes.offset(targetOffset.x, targetOffset.y),
                        )
                    }
                    if (sw < sourceRegion.width) {
                        node = Nodes.copyRegion(
                            sourceImageImgProc.ref.node,
                            Nodes.region(0, sourceRegion.y, sourceRegion.width - sw, sh),
                            node,
                            Nodes.offset(targetOffset.x + sw, targetOffset.y),
                        )
                    }
                    if (sh < sourceRegion.height) {
                        node = Nodes.copyRegion(
                            sourceImageImgProc.ref.node,
                            Nodes.region(sourceRegion.x, 0, sw, sourceRegion.height - sh),
                            node,
                            Nodes.offset(targetOffset.x, targetOffset.y + sh),
                        )
                    }
                    if (sw < sourceRegion.width && sh < sourceRegion.height) {
                        node = Nodes.copyRegion(
                            sourceImageImgProc.ref.node,
                            Nodes.region(0, 0, sourceRegion.width - sw, sourceRegion.height - sh),
                            node,
                            Nodes.offset(targetOffset.x + sw, targetOffset.y + sh),
                        )
                    }
                    resultImage = await context.createImage(resultImageImgProc.ref.descriptor, node)
                }
                break
            case "clamp":
                throw new Error("Clamp address mode not supported for ImageProcessing")
                break
            case "border":
                throw new Error("Border address mode not supported for ImageProcessing")
        }
        return resultImage
    },
}
