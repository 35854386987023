interface FullscreenElement extends Element {
    requestFullscreen(): Promise<void>
    webkitRequestFullscreen(): Promise<void>
    msRequestFullscreen(): Promise<void>
}

export function openFullscreen(element: Element): void {
    if (fullscreenActive()) return
    const elem = element as FullscreenElement
    if (elem.requestFullscreen) {
        elem.requestFullscreen()
    } else if (elem.webkitRequestFullscreen) {
        // Safari
        elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
        //IE11
        elem.msRequestFullscreen()
    }
}

export function exitFullscreen(): void {
    if (!fullscreenActive()) return
    const doc = document
    if (doc.exitFullscreen) {
        void doc.exitFullscreen()
    } else if ("webkitExitFullscreen" in doc && typeof doc.webkitExitFullscreen === "function") {
        // Safari
        doc.webkitExitFullscreen()
    } else if ("msExitFullscreen" in doc && typeof doc.msExitFullscreen === "function") {
        // IE11
        doc.msExitFullscreen()
    }
}

export function fullscreenActive(): boolean {
    const doc = document
    const fullscreenElement =
        doc.fullscreenElement || ("webkitFullscreenElement" in doc && doc.webkitFullscreenElement) || ("msFullscreenElement" in doc && doc.msFullscreenElement)
    return Boolean(fullscreenElement)
}

export function inIframe() {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

export function isFullscreenApiAvailable(): boolean {
    const doc = document
    return Boolean(
        ("fullscreenEnabled" in doc && doc.fullscreenEnabled) ||
            ("webkitFullscreenEnabled" in doc && doc.webkitFullscreenEnabled) ||
            ("mozFullScreenEnabled" in doc && doc.mozFullScreenEnabled) ||
            ("msFullscreenEnabled" in doc && doc.msFullscreenEnabled),
    )
}
