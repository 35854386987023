<div [class.cm-content]="$galleryImage()" (click)="openImageViewer()">
    <cm-drop-files
        class="cm-inner-content"
        [mimeTypeFilter]="MimeType.Images"
        [showDropzone]="$showDropzone()"
        [enableDrop]="can.setGalleryImage(item$.value)"
        [maxFiles]="1"
        (filesDropped)="assignGalleryImage($event)"
        [subtitle]="
            item$.value
                ? '...to add as ' + (galleryImageService.labelForItem | memoize: galleryImageService : item$.value) + ' or click to browse for files'
                : ''
        "
    >
        <cm-data-object-thumbnail [dataObjectId]="$galleryImage()?.id"> </cm-data-object-thumbnail>
    </cm-drop-files>
</div>
<cm-image-viewer #imageViewer></cm-image-viewer>
