import {Component, computed, EventEmitter, inject, Input, OnInit, Output, signal} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterLink} from "@angular/router"
import {TagType} from "@api"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {FilterListComponent} from "@common/components/filters/filter-list/filter-list.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {AuthService} from "@common/services/auth/auth.service"
import {FiltersService, FilterStates} from "@common/services/filters/filters.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {TagsService} from "@common/services/tags/tags.service"
import {Labels, StateLabel} from "@labels"

@Component({
    selector: "cm-tag-search-filter",
    templateUrl: "./tag-search-filter.component.html",
    styleUrls: ["./tag-search-filter.component.scss"],
    standalone: true,
    imports: [MatTooltipModule, FilterListComponent, DropdownComponent, SearchComponent, RouterLink],
})
export class TagSearchFilterComponent implements OnInit {
    @Input() selectableTagTypes: TagType[] = []
    @Input() placeholder = "Search..."
    @Output() change = new EventEmitter<string>()
    public focus = false
    public showDropdown = false
    public searchText = ""
    public filteredTags: (StateLabel<string> & {typeLabel?: string})[] = []
    private $states = signal<FilterStates>({search: undefined, criteria: {}})
    public $selectedTags = computed(() => {
        return this.$visibleTags().filter((tag) => Array.from(this.$states().criteria["tagId"]?.values() ?? []).includes(tag.state))
    })
    public filteredOrganizations: StateLabel<string>[] = []
    public $selectedOrganizations = computed(() => {
        return this.organizations
            .$filterOptions()
            .filter((option) => Array.from(this.$states().criteria["organizationId"]?.values() ?? []).includes(option.state))
    })
    public isStaff = false
    public showLoadingPlaceholders = false

    private auth = inject(AuthService)
    private filtersService = inject(FiltersService)
    private tags = inject(TagsService)
    public organizations = inject(OrganizationsService)

    $visibleTags = computed<(StateLabel<string> & {typeLabel?: string})[]>(() => {
        return this.tags
            .$visible()
            .filter((tag) => this.selectableTagTypes.includes(tag.type))
            .map((tag) => {
                const tagStateLabel = Labels.TagType.get(tag.type)
                return {
                    state: tag.id,
                    label: tag.name,
                    background: tagStateLabel?.background,
                    typeLabel: tagStateLabel?.label,
                }
            })
    })

    ngOnInit() {
        this.isStaff = this.auth.isStaff()
        this.filteredTags = []
        this.filteredOrganizations = []
        this.showLoadingPlaceholders = true
        this.searchText = this.filtersService.currentStates.search ?? ""
        this.showDropdown = !!this.searchText

        this.filtersService.states.subscribe((states) => {
            this.searchText = states.search ?? ""
            this.showDropdown = !!this.searchText

            this.$states.set(states)
        })
    }

    onInputStarted = async (text: string) => {
        if (text === this.searchText) {
            // ignore arrow keys etc. that don't change the content,
            // as the onSearchTextChanged event is not triggered in this case
            return
        }
        this.filteredTags = []
        this.filteredOrganizations = []
        this.showLoadingPlaceholders = !!text
        this.showDropdown = !!text
    }

    onSearchTextChanged = async (text: string) => {
        this.searchText = text
        await this.filtersService.updateSearchText(text)
        this.change.emit(text)
        this.updateSearchRanges()
    }

    onSelectOrganization = async (organization: StateLabel<string>) => {
        await this.filtersService.updateCriteria(
            "organizationId",
            [...this.$selectedOrganizations(), organization].map((stateLabel) => stateLabel.state),
        )
        await this.filtersService.updateSearchText("")
        this.change.emit("")
    }

    onRemoveOrganization = async (organization: StateLabel<string>) => {
        const selectedOrganizations = this.$selectedOrganizations().filter((selectedOrganization) => selectedOrganization.state != organization.state)
        await this.filtersService.updateCriteria(
            "organizationId",
            selectedOrganizations.map((stateLabel) => stateLabel.state),
        )
    }

    onSelectTag = async (tag: StateLabel<string> & {typeLabel?: string}) => {
        await this.filtersService.updateCriteria(
            "tagId",
            [...this.$selectedTags(), tag].map((stateLabel) => stateLabel.state),
        )
        await this.filtersService.updateSearchText("")
        this.change.emit(this.searchText)
    }

    onRemoveTag = async (tag: StateLabel<string> & {typeLabel?: string}) => {
        await this.filtersService.updateCriteria(
            "tagId",
            this.$selectedTags()
                .filter((selectedTag) => selectedTag.state !== tag.state)
                .map((stateLabel) => stateLabel.state),
        )
        this.change.emit(this.searchText)
    }

    updateSearchRanges() {
        this.showLoadingPlaceholders = false
        this.filteredOrganizations = this.organizations
            .$filterOptions()
            .filter(
                (option) =>
                    option.label.toLowerCase().search(this.searchText.toLowerCase()) != -1 &&
                    this.$selectedOrganizations().every((selectedOrganization) => option.state !== selectedOrganization.state),
            )
            .slice(0, 2)
        this.filteredTags = this.$visibleTags()
            .filter((tag) => tag.label.toLowerCase().search(this.searchText.toLowerCase()) != -1)
            .filter((tag) => !this.$selectedTags().includes(tag))
            .slice(0, 5)
    }
}
