import {MaterialSocket} from "@material-editor/models/material-socket"

export const ImageTextureOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
    alpha: {paramType: "socket", id: "Alpha", type: "output", valueType: "output", label: "Alpha"},
}

export const ImageTextureInputs: Record<string, MaterialSocket> = {
    vector: {paramType: "socket", id: "Vector", type: "input", valueType: "input", label: "Vector"},
}
