<div #canvasContainer class="cm-canvas-container">
    <div
        [ngStyle]="{
            width: $renderDimensions().width + 'px',
            height: $renderDimensions().height + 'px',
            cursor: sceneManagerService.watchingForClickedTemplateNodePart() ? 'crosshair' : undefined
        }"
        class="cm-base-canvas"
        #canvas
        resize
        (contextmenu)="(false)"
        (dragover)="dragOver($event)"
        (dragleave)="dragLeave($event)"
        (drop)="drag.drop($event)"
    >
        <div class="cm-controls-canvas" #controls></div>
        <div class="cm-annotations-canvas" #annotations></div>
    </div>
</div>
