import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const entriesToMapDescriptor = {
    input: inletDescriptor<[any, any][]>(),
    output: outletDescriptor<Map<any, any>>(),
}

export class EntriesToMap<K, V> implements NodeClassImpl<typeof entriesToMapDescriptor, typeof EntriesToMap> {
    static descriptor = entriesToMapDescriptor
    static uniqueName = "EntriesToMap"
    input!: Inlet<[K, V][]>
    output!: Outlet<Map<K, V>>

    run() {
        if (this.input === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const map = new Map<K, V>()
        for (const [key, value] of this.input) {
            map.set(key, value)
        }
        this.output.emitIfChanged(map)
    }
}
