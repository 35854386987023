import {TaskState} from "@api"
import {StateLabel} from "@platform/models/state-labels/state-label"

export const TaskStateLabels: Map<TaskState, StateLabel<TaskState>> = new Map<TaskState, StateLabel<TaskState>>([
    [TaskState.InProgress, {state: TaskState.InProgress, label: "In progress", background: "#989898"}],
    [TaskState.WaitingForFeedback, {state: TaskState.WaitingForFeedback, label: "Waiting for reply", background: "#f13536"}],
    [TaskState.Completed, {state: TaskState.Completed, label: "Completed", background: "#7ec17a"}],
    // do not include this in the list of selectable states - archiving is a separate operation
    // [TaskState.Archived, {state: TaskState.Archived, label: "Archived", background: "#7ec17a"}],
])
