import {takeUntil} from "rxjs"
import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {ShiftableToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/shiftable-toolbox-item"
import {OperatorShift} from "app/textures/texture-editor/operator-stack/operators/shift/operator-shift"
import * as paper from "paper"
import {SelectionMode} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"

export class ShiftToolbox extends OperatorToolboxBase<OperatorShift> {
    constructor(operator: OperatorShift) {
        super(operator, SelectionMode.None, true)
        this.shiftableItem = new ShiftableToolboxItem(this)
        this.shiftableItem.selected = true
        this.shiftableItem.showGuides = this.operator.showGuides
        this.shiftableItem.shiftInPixels = this.operator.shiftInPixels
        this.shiftableItem.shiftInPixelsChanged.subscribe((shiftInPixels) => (operator.shiftInPixels = shiftInPixels))
        this.operator.shiftInPixelsChanged
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((shiftInPixels) => (this.shiftableItem.shiftInPixels = new paper.Point(shiftInPixels)))
        this.operator.showGuidesChanged.pipe(takeUntil(this.unsubscribe)).subscribe((value) => (this.shiftableItem.showGuides = value))
    }

    // override hitTestObject(point: paper.Point): CanvasBaseToolbox.HitResultInfo | null {
    //     if (!this.isPointInImage(point)) {
    //         return null
    //     }
    //     return {item: this.shiftableItem, cursor: "move"}
    // }

    private shiftableItem: ShiftableToolboxItem
}
