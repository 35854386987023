import {Component, DestroyRef, ElementRef, inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {GetExecutionPlanGQL} from "@api"
import {SdkService} from "@common/services/sdk/sdk.service"
import {TippyInstance, TippyService} from "@ngneat/helipopper"
import {ExecutionPlanTooltipComponent} from "@platform/components/jobs/execution-plan-tooltip/execution-plan-tooltip.component"
import {drawGraph} from "@platform/components/jobs/execution-plan/graph"
import {ExecutionPlanNodeType} from "@platform/components/jobs/execution-plan/graph/types"
import {exhaustMap, Observable, of, Subject, Subscription} from "rxjs"

@Component({
    selector: "cm-execution-plan",
    templateUrl: "execution-plan.component.html",
    styleUrls: ["execution-plan.component.sass"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ExecutionPlanTooltipComponent],
})
export class ExecutionPlanComponent implements OnDestroy, OnInit {
    // the job's UUID
    @Input({required: true}) jobId$: Observable<string | null | undefined> = of(undefined)

    // the SVG element that contains the execution plan chart
    @ViewChild("executionPlanSvg") executionPlanSvg?: ElementRef<SVGElement>

    @ViewChild("executionPlanTooltip", {read: TemplateRef}) executionPlanTooltip!: TemplateRef<HTMLElement>

    public tooltipData: ExecutionPlanNodeType | undefined

    private jobSubscription?: Subscription
    private destroySubject = new Subject<void>()
    private tooltipInstances: TippyInstance[] = []

    private destroyRef = inject(DestroyRef)
    private sdk = inject(SdkService)
    private tippyService = inject(TippyService)

    protected showPlaceholder = true

    constructor(private getExecutionPlan: GetExecutionPlanGQL) {}

    ngOnDestroy(): void {
        this.destroySubject.next()
        this.destroySubject.complete()
        this.jobSubscription?.unsubscribe()
        this.tooltipInstances.forEach((tooltipInstance) => tooltipInstance.destroy())
    }

    ngOnInit() {
        this.jobId$
            .pipe(
                exhaustMap(async (id) => this.sdk.gql.getExecutionPlan({id})),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(({job}) => {
                this.showPlaceholder = false
                try {
                    const nodes: ExecutionPlanNodeType[] = job?.executionPlan?.nodes ?? []
                    drawGraph(nodes, {
                        create: (node: SVGGElement, data: ExecutionPlanNodeType) => {
                            const newTooltip = this.tippyService.create(node, this.executionPlanTooltip, {
                                theme: "light",
                                animation: "scale-subtle",
                                onShow: (instance: TippyInstance) => {
                                    this.tooltipData = instance.data
                                },
                            })
                            this.tooltipInstances.push(newTooltip)
                            newTooltip.data = data
                        },
                        update: (node: SVGGElement, data: ExecutionPlanNodeType) => {
                            const tooltipInstance = this.tooltipInstances.find((tooltipInstance) => {
                                return data.id === tooltipInstance.data.id
                            })
                            if (tooltipInstance) {
                                tooltipInstance.data = data

                                if (tooltipInstance.state.isVisible) {
                                    this.tooltipData = data
                                }
                            } else {
                                console.error("Could not find tooltip instance to update", data.id)
                            }
                        },
                        remove: (node: SVGGElement) => {
                            const tooltipId = node.getAttribute("data-tooltip-id")
                            const existingTooltipInstance = this.tooltipInstances.find((tooltipInstance) => {
                                return tooltipId === tooltipInstance.data.id
                            })
                            if (existingTooltipInstance) {
                                this.tooltipInstances = this.tooltipInstances.filter((tooltipInstance) => {
                                    return existingTooltipInstance.id !== tooltipInstance.id
                                })
                                if (this.tooltipData?.id === existingTooltipInstance.data.id) {
                                    this.tooltipData = undefined
                                }
                                existingTooltipInstance.destroy()
                            } else {
                                console.error("Could not find tooltip instance to remove", node)
                            }
                        },
                    })
                } catch (e) {
                    console.error(e)
                }
            })
    }
}
