import {AsyncPipe} from "@angular/common"
import {MatIconButton} from "@angular/material/button"
import {MatDialogClose} from "@angular/material/dialog"
import {MatIcon} from "@angular/material/icon"
import {Component, DestroyRef, EventEmitter, Input, Output, inject, input} from "@angular/core"
import {provideAnimations} from "@angular/platform-browser/animations"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {cardAnimations} from "@common/components/cards/animations"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {ItemFiltersComponent} from "@common/components/item"
import {GridSize, InfiniteListComponent} from "@common/components/lists"
import {LoadedData} from "@platform/models/data"
import {Subject} from "rxjs"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {SelectDialogLayoutComponent} from "@common/components/layouts/select-dialog-layout/select-dialog-layout.component"
import {JobState} from "@api"

@Component({
    selector: "cm-select-dialog",
    standalone: true,
    imports: [
        CardPlaceholderComponent,
        EntityCardComponent,
        CardErrorComponent,
        InfiniteListComponent,
        ItemFiltersComponent,
        EntityResponsiveSidebarComponent,
        RoutedDialogComponent,
        MatDialogClose,
        MatIcon,
        MatIconButton,
        AsyncPipe,
        DetailsDialogLayoutComponent,
        SelectDialogLayoutComponent,
    ],
    templateUrl: "./select-dialog.component.html",
    styleUrl: "./select-dialog.component.scss",
    animations: [...cardAnimations],
    providers: [provideAnimations()],
})
export class SelectDialogComponent<
    EntityType extends {
        name?: string | null
        legacyId?: number
        organization?: {id?: string; name?: string | null}
        thumbnail?: {downloadUrl: string}
        thumbnailProcessingJob?: {state: JobState}
    },
> {
    @Input() data?: LoadedData<EntityType>
    @Input() pageFilledStatusChange!: Subject<boolean>
    @Input() gridSize: GridSize = "large"
    @Output() selectItem = new EventEmitter<EntityType>()

    @Output() cancel = new EventEmitter<void>()

    $title = input<string>("Select an entity")

    destroyRef = inject(DestroyRef)

    selectEntity(entity: EntityType) {
        this.selectItem.next(entity)
    }

    protected readonly DialogSize = DialogSize
}
