import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {inletDescriptor, outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const lambdaDescriptor = {
    input: inletDescriptor<any>(),
    fn: inletDescriptor<(x: any) => any>(),
    output: outletDescriptor<any>(),
}

export class Lambda<I, O> implements NodeClassImpl<typeof lambdaDescriptor, typeof Lambda> {
    static descriptor = lambdaDescriptor
    static uniqueName = "Lambda"
    input!: Inlet<I>
    fn!: Inlet<(x: I) => O>
    output!: Outlet<O>

    run() {
        if (this.input === NotReady || this.fn === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        this.output.emitIfChanged(this.fn(this.input))
    }
}
