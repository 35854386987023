import {EventEmitter, Injector} from "@angular/core"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {HalContext} from "@common/models/hal/hal-context"
import {HalImageDescriptor, HalImageOptions} from "@common/models/hal/hal-image/types"
import {HalImage} from "@common/models/hal/hal-image"
import {InjectorService} from "@common/services/injector/injector.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {ImageProcessingService} from "@common/services/rendering/image-processing.service"
import {of, Subject, switchMap, takeUntil, tap} from "rxjs"
import {AsyncReentrancyGuard} from "@cm/lib/utils/async-reentrancy-guard"
import {hashObject} from "@cm/lib/utils/hashing"
import {assertNever, DeferredBatchCall} from "@cm/lib/utils/utils"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {TilingCanvasComponent} from "app/textures/texture-editor/tiling-canvas/tiling-canvas.component"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImagePtrWebGl2, ImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {lambda} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {convert} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/convert-node"
import {SmartPtr} from "app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {ImageOpGraphExporter} from "app/textures/texture-editor/operator-stack/image-op-system/util/image-op-graph-exporter"
import {Operator, OperatorParameterValue, OperatorType} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {OperatorAutoTiling} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/operator-auto-tiling"
import {OperatorCloneStamp} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/operator-clone-stamp"
import {OperatorHighpass} from "app/textures/texture-editor/operator-stack/operators/highpass/operator-highpass"
import {OperatorLayerAndMask} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/operator-layer-and-mask"
import {OperatorRotate} from "app/textures/texture-editor/operator-stack/operators/rotate/operator-rotate"
import {imageOpChannelLayoutByTextureChannelLayout} from "app/textures/texture-editor/operator-stack/operators/shared/utils/utils"
import {OperatorShift} from "app/textures/texture-editor/operator-stack/operators/shift/operator-shift"
import {levels} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/levels-node"
import {SourceImageData, TextureEditorCallback, TextureEditorData} from "app/textures/texture-editor/texture-editor-callback"
import {TextureType} from "@api"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {ImageOpNodeGraphEvaluatorWebGl2} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator-webgl2"
import {ImageOpNodeGraphEvaluator, OperatorExecutionMode} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"
import {OperatorInitial} from "app/textures/texture-editor/operator-stack/operators/initial/operator-initial"
import {createHalPaintableImage} from "@common/models/hal/hal-paintable-image/create"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"
import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"

const TRACE = TextureEditorSettings.EnableFullTrace
const AUTO_DOWNLOAD_GRAPH = false

const CACHE_SELECTED_OPERATOR_SOURCE = true

export class OperatorStack implements OperatorCallback {
    readonly selectedOperatorChanged = new EventEmitter<Operator | null>() // OperatorCallback
    readonly resultImageChanged = new EventEmitter<ImagePtrWebGl2>()

    constructor(
        private textureEditorCallback: TextureEditorCallback,
        private injectorService: InjectorService,
        readonly tilingCanvas: TilingCanvasComponent,
    ) {
        const imageProcessingService = this.injectorService.injector.get(ImageProcessingService)
        const uploadGqlService = this.injectorService.injector.get(UploadGqlService)
        const texturesApiService = this.injectorService.injector.get(TexturesApiService)
        this._halContext = this.tilingCanvas.canvasBase.halContext
        this._drawableImageCache = new DrawableImageCache(this.halContext, imageProcessingService, uploadGqlService, texturesApiService)
        this._imageOpNodeGraphEvaluator = new ImageOpNodeGraphEvaluatorWebGl2(this._halContext, texturesApiService, this._drawableImageCache)
        this._imageOpEvaluatorPromiseGate = new AsyncReentrancyGuard.PromiseGate()
        this._halBlitter = HalPainterImageBlit.create(this._halContext)

        this.textureEditorCallback.sourceDataChanged
            .pipe(
                tap(() => this.beginBulkChange()),
                switchMap((data) => this.setSourceImage(data?.sourceImage ?? null).then(() => data)),
                switchMap((data) => (!data || data.textureEdits !== undefined ? this.setTextureEdits(data?.textureEdits ?? null) : of(undefined))), // only set texture edits if provided or reset if no data is provided at all
                tap(() => this.endBulkChange()),
                takeUntil(this.unsubscribe),
            )
            .subscribe()
    }

    dispose(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.deferredUpdateResultImage.cancel()
        this.freeTemporaryRenderTargets()
        this._sourceImagePtr?.release()
        this._drawableImageCache.dispose()
        this._imageOpNodeGraphEvaluator.dispose()
        this._halBlitter.dispose()
    }

    get isDebugEnabled(): boolean {
        return this.textureEditorCallback.isDebugEnabled
    }

    get textureEditorData(): TextureEditorData {
        if (!this.textureEditorCallback.data) {
            throw Error("data is null")
        }
        return this.textureEditorCallback.data
    }

    get customerLegacyId(): number {
        return this.textureEditorData.textureSetRevisionSpecific.textureSet.textureGroup.organization.legacyId
    }

    get isBusy(): boolean {
        return this._busyCounter > 0
    }

    get isEdited(): boolean {
        return this._operatorsChanged || this._operators.some((operator) => operator.edited)
    }

    get muteUpdates(): boolean {
        return this._updatesMutedCounter > 0
    }

    set muteUpdates(value: boolean) {
        if (value) {
            if (this._updatesMutedCounter === 0) {
                this._mutedUpdatePending = this.deferredUpdateResultImage.isPending
                this.deferredUpdateResultImage.cancel()
            }
            this._updatesMutedCounter++
        } else {
            this._updatesMutedCounter--
            if (this._updatesMutedCounter < 0) {
                throw Error("Mismatched muteUpdates calls")
            }
            if (this._updatesMutedCounter === 0) {
                if (this._mutedUpdatePending) {
                    this._mutedUpdatePending = false
                    this.requestUpdateResultImage()
                }
            }
        }
    }

    // OperatorCallback
    get injector(): Injector {
        return this.injectorService.injector
    }

    // OperatorCallback
    get drawableImageCache(): DrawableImageCache {
        return this._drawableImageCache
    }

    // OperatorCallback
    get halContext(): HalContext {
        return this._halContext
    }

    // OperatorCallback
    get selectedOperatorInput(): ImagePtr | undefined {
        return this._cachedOperator?.sourceImage
    }

    // OperatorCallback
    get imageOpContextWebGL2(): ImageOpContextWebGL2 {
        return this._imageOpNodeGraphEvaluator.imageOpContextWebGL2
    }

    get operators(): readonly Operator[] {
        return this._operators
    }

    get selectedOperator(): Operator | null {
        return this._selectedOperator
    }

    set selectedOperator(value: Operator | null) {
        if (this._selectedOperator === value) {
            return
        }
        this._selectedOperator = value
        this.requestUpdateResultImage()
        this.selectedOperatorChanged.emit(this._selectedOperator)
    }

    get processToSelectionOnly(): boolean {
        return this._processToSelectionOnly
    }

    set processToSelectionOnly(value: boolean) {
        if (this._processToSelectionOnly === value) {
            return
        }
        this._processToSelectionOnly = value
        this.requestUpdateResultImage()
    }

    resetChangedFlag() {
        this._operatorsChanged = false
    }

    async setTextureEdits(edits: TextureEditNodes.TextureEdits | null) {
        this.beginBulkChange()
        await this.resetAllOperators()
        if (edits) {
            for (const operator of edits.operators) {
                await this.createOperator(operator)
            }
            this.selectedOperator = this._operators.length > 0 ? this._operators[this._operators.length - 1] : null
        }
        this.resetChangedFlag()
        this.endBulkChange()
    }

    async duplicateOperator(operator: Operator): Promise<Operator> {
        const clonedOperator = await operator.clone()
        const index = this._operators.indexOf(operator)
        await this.addOperator(clonedOperator, index + 1)
        clonedOperator.markEdited()
        return clonedOperator
    }

    async createOperator(operatorTypeOrNode: OperatorType | TextureEditNodes.Operator): Promise<Operator> {
        if (TRACE) {
            console.log("createOperator - begin: ", operatorTypeOrNode)
        }
        const operator = this.createOperatorFromTypeOrNode(operatorTypeOrNode)
        await this.addOperator(operator)
        if (TRACE) {
            console.log("createOperator - end: ", operatorTypeOrNode)
        }
        return operator
    }

    removeOperator(operator: Operator): void {
        const operatorIndex = this._operators.indexOf(operator)
        if (operatorIndex < 0) {
            throw Error("operator not found")
        }
        this.onOperatorChanged(operator)
        if (this._selectedOperator === operator) {
            this.selectedOperator =
                operatorIndex > 0 ? this._operators[operatorIndex - 1] : this._operators.length > 1 ? this._operators[operatorIndex + 1] : null
        }
        operator.dispose()
        this._operators.splice(operatorIndex, 1)
        this.resetOperatorCache()
        this.requestUpdateResultImage()
    }

    async resetAllOperators() {
        while (this._operators.length > 0) {
            this.removeOperator(this._operators[this._operators.length - 1])
        }
        // add and select initial operator per default
        this.selectedOperator = await this.createOperator("operator-initial")
    }

    setOperatorLocked(operator: Operator, locked: boolean): void {
        if (operator.locked === locked) {
            return
        }
        operator.locked = locked
        this.requestUpdateResultImage()
    }

    setOperatorEnabled(operator: Operator, enabled: boolean): void {
        if (operator.enabled === enabled) {
            return
        }
        operator.enabled = enabled
        this.onOperatorChanged(operator)
        this.requestUpdateResultImage()
    }

    async requestTemporaryPaintableImage(descriptor: HalImageDescriptor, options: HalImageOptions): Promise<HalPaintableImage> {
        // make a copy for hash in case imageDesc contains excess properties
        const imageDescCopyForHash: HalImageDescriptor & HalImageOptions = {
            ...descriptor,
            ...options,
        }
        const hash = hashObject(imageDescCopyForHash)
        let availableTemporaryRenderTargets = this.availableTemporaryRenderTargets.get(hash)
        if (!availableTemporaryRenderTargets) {
            availableTemporaryRenderTargets = []
            this.availableTemporaryRenderTargets.set(hash, availableTemporaryRenderTargets)
        }
        let target = availableTemporaryRenderTargets.pop()
        if (!target) {
            target = createHalPaintableImage(this._halContext)
            await target.create(descriptor, options)
        }
        let usedTemporaryRenderTargets = this.usedTemporaryRenderTargets.get(hash)
        if (!usedTemporaryRenderTargets) {
            usedTemporaryRenderTargets = []
            this.usedTemporaryRenderTargets.set(hash, usedTemporaryRenderTargets)
        }
        usedTemporaryRenderTargets.push(target)
        return target
    }

    private resetTemporaryRenderTargetUsagess(exception: HalImage | null): void {
        // add back all used temporary render targets to available (except the exception)
        for (const [key, targets] of this.usedTemporaryRenderTargets) {
            const availableTemporaryRenderTargets = this.availableTemporaryRenderTargets.get(key)
            if (!availableTemporaryRenderTargets) {
                throw Error("availableTemporaryRenderTargets not found")
            }
            const allButException = targets.filter((target) => target !== exception)
            availableTemporaryRenderTargets.push(...allButException)
            const containsException = allButException.length < targets.length
            if (containsException) {
                const exceptionTarget = targets.find((target) => target === exception)
                if (!exceptionTarget) {
                    throw Error("exceptionTarget not found")
                }
                targets.length = 0
                targets.push(exceptionTarget)
            } else {
                targets.length = 0
            }
        }
    }

    private freeTemporaryRenderTargets() {
        this.resetTemporaryRenderTargetUsagess(null)
        for (const renderTargets of this.availableTemporaryRenderTargets.values()) {
            renderTargets.forEach((target) => target.dispose())
        }
    }

    private async addOperator(operator: Operator, insertAtIndex?: number): Promise<void> {
        operator.requestEvaluation.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.requestUpdateResultImage())
        if (insertAtIndex !== undefined && insertAtIndex >= 0 && insertAtIndex < this._operators.length) {
            this._operators.splice(insertAtIndex, 0, operator)
        } else {
            this._operators.push(operator)
        }
        this.resetOperatorCache()
        this.onOperatorChanged(operator)
        this.requestUpdateResultImage()
    }

    private beginBulkChange() {
        this.busyInc()
        this.muteUpdates = true
    }

    private endBulkChange(forceUpdate = false) {
        if (forceUpdate) {
            this.requestUpdateResultImage()
        }
        this.muteUpdates = false
        this.busyDec()
    }

    private async setSourceImage(data: SourceImageData | null) {
        this._sourceImagePtr = data?.dataObjectId ? await this._imageOpNodeGraphEvaluator.createDataObjectImage(data.dataObjectId) : undefined
        this._sourceImageIsResult = data?.isResult ?? false
        this.resetOperatorCache()
        this.requestUpdateResultImage()
    }

    private onOperatorChanged(operator: Operator) {
        this._operatorsChanged = true
        if (this._cachedOperator) {
            const cachedOperatorIndex = this._operators.indexOf(this._cachedOperator.operator)
            const changedOperatorIndex = this._operators.indexOf(operator)
            if (cachedOperatorIndex >= 0 && changedOperatorIndex >= 0 && changedOperatorIndex < cachedOperatorIndex) {
                this.resetOperatorCache()
            }
        }
    }

    private resetOperatorCache() {
        if (!this._cachedOperator) {
            return
        }
        if (TRACE) {
            console.log("resetting operator cache")
        }
        this._cachedOperator.sourceImage.release()
        this._cachedOperator = null
    }

    private createOperatorFromTypeOrNode(operatorTypeOrNode: OperatorType | TextureEditNodes.Operator): Operator {
        const isOperatorNode = typeof operatorTypeOrNode === "object"
        const operatorType = isOperatorNode ? operatorTypeOrNode.type : operatorTypeOrNode
        const operatorNode = isOperatorNode ? operatorTypeOrNode : null
        switch (operatorType) {
            case "operator-initial":
                return new OperatorInitial(this)
            case "operator-auto-tiling":
                return new OperatorAutoTiling(this, operatorNode as TextureEditNodes.OperatorAutoTiling)
            case "operator-shift":
                return new OperatorShift(this, operatorNode as TextureEditNodes.OperatorShift)
            case "operator-rotate":
                return new OperatorRotate(this, operatorNode as TextureEditNodes.OperatorRotate)
            case "operator-layer-and-mask":
                return new OperatorLayerAndMask(this, operatorNode as TextureEditNodes.OperatorLayerAndMask)
            case "operator-highpass":
                return new OperatorHighpass(this, operatorNode as TextureEditNodes.OperatorHighpass)
            case "operator-clone-stamp":
                return new OperatorCloneStamp(this, operatorNode as TextureEditNodes.OperatorCloneStamp)
            case "operator-tiling":
                return new OperatorTiling(this, operatorNode as TextureEditNodes.OperatorTiling)
            default:
                assertNever(operatorType)
        }
    }

    requestUpdateResultImage(): void {
        if (this.muteUpdates) {
            this._mutedUpdatePending = true
        } else {
            this.deferredUpdateResultImage.schedule()
        }
    }

    private async updateResultImage(): Promise<void> {
        if (TRACE) {
            console.log("updateResultImage - started")
        }
        this.busyInc()
        this._imageOpEvaluatorPromiseGate
            .startAndRejectCurrent(
                () => this.executeOperators(),
                (result) => result?.release(),
            )
            .then((resultImage) => {
                this.resultImageChanged.emit(resultImage)
                resultImage.release()
                if (TRACE) {
                    console.log("updateResultImage - finished")
                }
            })
            .catch((e) => {
                if (e instanceof AsyncReentrancyGuard.RejectionError) {
                    if (TRACE) {
                        console.log("updateResultImage - cancelled")
                    }
                } else {
                    throw e
                }
            })
            .finally(() => this.busyDec())
    }

    private async executeOperators(): Promise<ImagePtrWebGl2> {
        if (!this._sourceImagePtr) {
            return new ImagePtrWebGl2()
        }
        if (TRACE) {
            console.log("executeOperators - begin")
        }

        // generate node graph
        const generatedNodeGraph = await this.generateOperatorNodeGraph(this._imageOpNodeGraphEvaluator, this.selectedTextureType, this._sourceImagePtr, {
            sourceImageIsResult: this._sourceImageIsResult,
            allowCaching: CACHE_SELECTED_OPERATOR_SOURCE,
        })

        if (AUTO_DOWNLOAD_GRAPH) {
            this.downloadOperatorNodeGraph()
        }

        // execute node graph
        const result = await this._imageOpNodeGraphEvaluator.evaluate(generatedNodeGraph.nodeGraph)

        if (TRACE) {
            console.log("executeOperators - end")
        }
        return result
    }

    get selectedTextureType(): TextureType {
        return this.textureEditorCallback.data?.textureTypeSpecific?.textureType ?? TextureType.Diffuse
    }

    async downloadOperatorNodeGraph() {
        if (!this._sourceImagePtr) {
            throw Error("No source image available")
        }
        // generate node graph
        const textureType = this.selectedTextureType
        const generatedNodeGraphResult = await this.generateOperatorNodeGraph(this._imageOpNodeGraphEvaluator, textureType, this._sourceImagePtr, {
            allowCaching: false,
            generateDebugMap: true,
        })
        const exporter = new ImageOpGraphExporter()
        exporter.exportGraph(generatedNodeGraphResult)
    }

    hasAppliedOperators(): boolean {
        return this._operators.some((operator) => !operator.flags.has("is-internal"))
    }

    hasAppliedOperatorsForTextureType(textureType: TextureType): boolean {
        for (let i = 0; i < this._operators.length; i++) {
            const operator = this._operators[i]
            if (!operator.flags.has("is-internal") && operator.shouldApplyTo(textureType)) {
                return true
            }
        }
        return false
    }

    prepareSourceImage(mode: OperatorExecutionMode, textureType: TextureType, sourceImage: OperatorParameterValue<ImagePtr>) {
        if (this.hasAppliedOperatorsForTextureType(textureType)) {
            // apply levels to ensure no negative values. we only do this if we have applied operators to keep the graph trivial otherwise (for further down optimizations)
            sourceImage = levels({
                sourceImage: sourceImage,
                blackLevel: 0.0,
                whiteLevel: 1.0,
            })
            sourceImage = convert({
                sourceImage: sourceImage,
                channelLayout: imageOpChannelLayoutByTextureChannelLayout(descriptorByTextureType(textureType).channelLayout),
                format: mode === "preview" ? TextureEditorSettings.PreviewProcessingImageFormat : TextureEditorSettings.FinalProcessingImageFormat,
            })
        }
        return sourceImage
    }

    async generateOperatorNodeGraph(
        evaluator: ImageOpNodeGraphEvaluator,
        textureType: TextureType,
        sourceImage: ImagePtr,
        options?: {allowCaching?: boolean; generateDebugMap?: boolean; sourceImageIsResult?: boolean},
    ): Promise<GeneratedOperatorNodeGraphResult> {
        const allowCaching = options?.allowCaching ?? false
        const generateDebugMap = options?.generateDebugMap ?? false
        const sourceImageIsResult = options?.sourceImageIsResult ?? false
        if (allowCaching && evaluator.mode !== "preview") {
            throw Error("Caching is only supported for preview mode")
        }

        let currSourceImage: OperatorParameterValue<ImagePtr>
        let firstOperatorIndexToExecute: number
        if (
            allowCaching &&
            !sourceImageIsResult &&
            this._cachedOperator &&
            this._selectedOperator &&
            this._operators.indexOf(this._selectedOperator) >= this._operators.indexOf(this._cachedOperator.operator)
        ) {
            if (TRACE) {
                console.log(`starting evaluation at cached operator: ${this._cachedOperator.operator.type}`)
            }
            currSourceImage = this._cachedOperator.sourceImage
            firstOperatorIndexToExecute = this._operators.indexOf(this._cachedOperator.operator)
        } else {
            this.resetOperatorCache()
            firstOperatorIndexToExecute = 0
            currSourceImage = this.prepareSourceImage(evaluator.mode, textureType, sourceImage)
        }

        const debugMap: DebugMap | undefined = generateDebugMap ? new Map<Operator | null, OperatorDebugInfo>() : undefined
        if (debugMap) {
            const operatorDebugInfo: OperatorDebugInfo = {
                outputParameter: currSourceImage,
            }
            debugMap.set(null, operatorDebugInfo)
        }

        let hasAppliedOperators = false
        if (!sourceImageIsResult) {
            const operatorIndexToCacheSource = allowCaching && this._selectedOperator ? this._operators.indexOf(this._selectedOperator) : -1
            for (let i = firstOperatorIndexToExecute; i < this._operators.length; i++) {
                const operator = this._operators[i]
                if (TRACE) {
                    console.log(`  executeOperators - operator(${i}): `, operator)
                }

                if (i === operatorIndexToCacheSource) {
                    if (TRACE) {
                        console.log("  executeOperators - caching operator source image: ", currSourceImage)
                    }
                    // let's cache this result
                    currSourceImage = lambda({currSourceImage: currSourceImage}, async ({parameters: {currSourceImage}}) => {
                        const newCachedSourceImage = new ImagePtr(currSourceImage) // make sure we don't release the source image when calling resetOperatorCache
                        this.resetOperatorCache()
                        if (TRACE) {
                            console.log("Running input caching node for input", newCachedSourceImage.ref)
                        }
                        this._cachedOperator = {
                            operator: operator,
                            sourceImage: newCachedSourceImage,
                        }
                        return new ImagePtr(newCachedSourceImage)
                    })
                }

                if (operator.shouldApplyTo(textureType)) {
                    hasAppliedOperators = true
                    const operatorOutput = await operator.getImageOpNodeGraph(evaluator, textureType, currSourceImage)
                    if (debugMap) {
                        const operatorDebugInfo: OperatorDebugInfo = {
                            outputParameter: operatorOutput.resultImage,
                        }
                        debugMap.set(operator, operatorDebugInfo)
                    }
                    currSourceImage = operatorOutput.resultImage
                    if (operatorOutput.options?.stopEvaluation) {
                        break
                    }
                }

                if (this._processToSelectionOnly && this.selectedOperator === operator) {
                    break
                }
            }

            // this addRef here is needed because if we return the cached image it will get released later, but we need to keep it alive
            // if (currSourceImage === this._cachedOperator?.sourceImage) {
            //     currSourceImage.addRef()
            // }
        }

        return {
            nodeGraph: currSourceImage,
            hasAppliedOperators,
            debugMap,
        }
    }

    private busyInc() {
        this._busyCounter++
    }

    private busyDec() {
        this._busyCounter--
    }

    private readonly unsubscribe = new Subject<void>()
    private _busyCounter = 0
    private _updatesMutedCounter = 0
    private _mutedUpdatePending = false
    private _sourceImagePtr?: ImagePtr
    private _sourceImageIsResult = true
    private readonly _halContext: HalContext
    private _operatorsChanged = false
    private readonly _operators: Operator[] = []
    private _selectedOperator: Operator | null = null
    private _processToSelectionOnly = true
    private readonly deferredUpdateResultImage = new DeferredBatchCall(() => this.updateResultImage())
    private readonly availableTemporaryRenderTargets = new Map<string, HalPaintableImage[]>()
    private readonly usedTemporaryRenderTargets = new Map<string, HalPaintableImage[]>()
    private readonly _imageOpNodeGraphEvaluator: ImageOpNodeGraphEvaluatorWebGl2
    private readonly _imageOpEvaluatorPromiseGate: AsyncReentrancyGuard.PromiseGate<SmartPtr<ImageWebGL2>>
    private _cachedOperator: CachedOperatorResult | null = null
    private readonly _drawableImageCache: DrawableImageCache
    private readonly _halBlitter: HalPainterImageBlit
}

type CachedOperatorResult = {
    operator: Operator
    sourceImage: ImagePtr
}

export type GeneratedOperatorNodeGraphResult = {
    nodeGraph: OperatorParameterValue<ImagePtr>
    hasAppliedOperators: boolean
    debugMap?: DebugMap
}

export type DebugMap = Map<Operator | null, OperatorDebugInfo> // null for pre-operator setup

export type OperatorDebugInfo = {
    outputParameter: OperatorParameterValue<ImagePtr>
}
