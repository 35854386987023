import {Box2Like} from "@cm/lib/math/box2"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {Size2Like} from "@cm/lib/math/size2"
import {Vector2Like} from "@cm/lib/math/vector2"

export type ParameterType = {
    sourceImage: ImagePtr
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    patchSize: Size2Like
    stride?: Vector2Like // default: {width: 1, height: 1}
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpExtractPatches: ImageOpType<ParameterType, ReturnType> = {
    name: "ExtractPatches",

    WebGL2: async ({context, parameters: {sourceImage: source, sourceRegion, patchSize, stride, resultImage}}) => {
        stride ??= {x: 1, y: 1}
        if (stride.x < 1 || stride.y < 1) {
            throw new Error("Stride must be >= 1")
        }
        const halExtractPatches = await context.getOrCreateImageCompositor(`
            uniform ivec2 u_textureOfs0;
            uniform ivec2 u_patchSize;
            uniform ivec2 u_stride;
        
            vec4 computeColor(ivec2 targetPixel) {
                ivec2 patchIndex = targetPixel / u_patchSize;
                ivec2 patchPixel = targetPixel % u_patchSize;
                ivec2 patchOffset = patchIndex * u_stride;
                ivec2 sourcePixel = u_textureOfs0 + patchOffset + patchPixel;
                return texelFetch0(sourcePixel);
            }
        `)
        using sourceImageWebGl2 = await context.getImage(source)
        sourceRegion ??= {x: 0, y: 0, width: sourceImageWebGl2.ref.halImage.descriptor.width, height: sourceImageWebGl2.ref.halImage.descriptor.height}
        const numPatches = {
            x: Math.floor((sourceRegion.width - patchSize.width) / stride.x) + 1,
            y: Math.floor((sourceRegion.height - patchSize.height) / stride.y) + 1,
        }
        const targetSize = {width: numPatches.x * patchSize.width, height: numPatches.y * patchSize.height}
        resultImage = await context.prepareResultImage(resultImage, {
            ...targetSize,
            channelLayout: sourceImageWebGl2.ref.descriptor.channelLayout,
            format: sourceImageWebGl2.ref.descriptor.format,
            isSRGB: sourceImageWebGl2.ref.descriptor.isSRGB,
        })
        using resultImageWebGl2 = await context.getImage(resultImage)
        halExtractPatches.setParameter("u_textureOfs0", {type: "int2", value: {x: sourceRegion.x, y: sourceRegion.y}})
        halExtractPatches.setParameter("u_patchSize", {type: "int2", value: {x: patchSize.width, y: patchSize.height}})
        halExtractPatches.setParameter("u_stride", {type: "int2", value: stride})
        await halExtractPatches.paint(resultImageWebGl2.ref.halImage, sourceImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, sourceRegion, resultImage}}) => {
        throw new Error("Not implemented")
    },
}
