import {NodeGraph, NodeParamEvaluator, NodeParamSyncEvaluator, ParameterValue} from "@src/graph-system/node-graph"
import {NodeGraphResult} from "@src/graph-system/node-graph-result"
import {ensureValidContext, ensureValidParameters, ensureValidResult} from "@src/graph-system/validation"

export type NodeParamEvaluatorImpl<Context> = <ReturnType>(value: ParameterValue<ReturnType, Context>) => Promise<ReturnType>
export type NodeParamSyncEvaluatorImpl<Context> = <ReturnType>(value: ParameterValue<ReturnType, Context>) => ReturnType

export abstract class NodeGraphResultBase<ReturnType, Context> implements NodeGraphResult<ReturnType> {
    constructor(
        protected readonly root: ParameterValue<ReturnType, Context>,
        private readonly context: Context,
        private disableValidation: boolean,
    ) {}

    protected async evaluateGraph<ReturnType>(graph: NodeGraph<ReturnType, Context>, get: NodeParamEvaluatorImpl<Context>) {
        if (this.disableValidation === true) return graph.run(get as NodeParamEvaluator, this.context)
        ensureValidContext(graph, this.context)
        ensureValidParameters(graph, graph.parameters)
        const computedValue = await graph.run(get as NodeParamEvaluator, this.context)
        ensureValidResult(graph, computedValue)
        return computedValue
    }

    protected evaluateGraphSync<ReturnType>(graph: NodeGraph<ReturnType, Context>, get: NodeParamSyncEvaluatorImpl<Context>) {
        if (this.disableValidation === true) return graph.runSync(get as NodeParamSyncEvaluator, this.context)
        ensureValidContext(graph, this.context)
        ensureValidParameters(graph, graph.parameters)
        const computedValue = graph.runSync(get as NodeParamSyncEvaluator, this.context)
        ensureValidResult(graph, computedValue)
        return computedValue
    }

    abstract run(): Promise<ReturnType>
    abstract runSync(): ReturnType

    invalidateNodeGraph(nodeGraph: NodeGraph) {}
}
