import {animate, style, transition, trigger} from "@angular/animations"
import {DatePipe} from "@angular/common"
import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatButtonModule} from "@angular/material/button"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {provideAnimations} from "@angular/platform-browser/animations"
import {JobTaskTableItemFragment} from "@api"
import {LoadedData} from "@app/platform/models/data"
import {OverflowableTextComponent} from "@common/components/data"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {EntityMetadataComponent} from "@common/components/entity/entity-metadata/entity-metadata.component"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {TypedMatCellDefDirective} from "@common/directives"
import {AuthService} from "@common/services/auth/auth.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {TippyDirective} from "@ngneat/helipopper"
import {JobTaskThumbnailComponent} from "@platform/components/jobs/job-task-thumbnail/job-task-thumbnail.component"
import {JobThumbnailComponent} from "@platform/components/jobs/job-thumbnail/job-thumbnail.component"
import {DataLoaderService} from "@platform/services/data"
import {MomentModule} from "ngx-moment"
import {filter, Observable, switchMap} from "rxjs"
import {PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {IsDefined} from "@cm/lib/utils/filter"

@Component({
    selector: "cm-job-tasks-table",
    templateUrl: "./job-tasks-table.component.html",
    styleUrls: ["./job-tasks-table.component.scss"],
    standalone: true,
    imports: [
        MatTableModule,
        MatTooltipModule,
        MatButtonModule,
        MatProgressBarModule,
        MomentModule,
        DatePipe,
        OverflowableTextComponent,
        TypedMatCellDefDirective,
        TableCellComponent,
        FullPageFeedbackComponent,
        JobTaskThumbnailComponent,
        EntityMetadataComponent,
        JobThumbnailComponent,
        TippyDirective,
    ],
    animations: [
        trigger("fadeInPlaceholder", [transition("void => *", [style({opacity: 0.4, scale: 0.7}), animate("1000ms", style({opacity: 1, scale: 0.98}))])]),
    ],
    providers: [provideAnimations()],
})
export class JobTasksTableComponent implements OnInit {
    @Output() clickItem = new EventEmitter<JobTaskTableItemFragment>()

    auth = inject(AuthService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)

    @Input({required: true}) jobId$!: Observable<string | null | undefined>

    dataLoader = inject(DataLoaderService)
    destroyRef = inject(DestroyRef)

    $data = signal(PLACEHOLDER_ITEMS(5) as LoadedData<JobTaskTableItemFragment>)

    ngOnInit() {
        this.jobId$
            .pipe(
                filter(IsDefined),
                switchMap((id) => this.sdk.gql.jobTaskTableItems({id})),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(({items: dataItems}) => {
                this.$data.set({
                    items: dataItems.map((dataItem) => ({data: dataItem, error: null})),
                    error: null,
                    complete: false,
                    totalCount: dataItems.length,
                })
            })
    }
}
