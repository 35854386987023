import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpCopyRegion} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-copy-region"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const CopyRegionNode = declareImageOpNode(imageOpCopyRegion)

export function copyRegion(parameters: GetParameters<Context, ParameterType>) {
    return new CopyRegionNode(parameters)
}
