import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {color} from "@src/materials/types"
import {threeConvert, threeRGBColorNode, threeValueNode} from "@src/materials/three-utils"
import {getAll} from "@src/graph-system/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class Invert extends DeclareMaterialNode(
    {
        returns: z.object({color: materialSlots}),
        inputs: z.object({
            color: materialSlots.optional(),
            fac: materialSlots.optional(),
        }),
        parameters: z.object({color: color.optional(), fac: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {color, fac} = await getAll(inputs, get)

            const colorValue = color ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const facValue = fac ?? threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(1)
            const inverted = THREENodes.sub(threeValueNode(1), colorValue)
            return {color: THREENodes.mix(colorValue, inverted, facValue)}
        },
    },
) {}
