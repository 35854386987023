import {NgTemplateOutlet} from "@angular/common"
import {Component, HostListener, inject, OnDestroy, OnInit} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {ActivatedRoute, NavigationEnd, Router, RouterLink, UrlSerializer} from "@angular/router"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {Breakpoints} from "@common/models/constants"
import {NavigationItemComponent} from "@common/components/navigation/navigation-item/navigation-item.component"
import {SidenavComponent} from "@common/components/navigation/sidenav/sidenav.component"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {NavigationBarService} from "@common/services/navigation-bar/navigation-bar-service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {filter, Subject, takeUntil} from "rxjs"
import {environment} from "@environment"

@Component({
    selector: "cm-navigation-bar",
    templateUrl: "navigation-bar-component.html",
    styleUrls: ["navigation-bar-component.scss"],
    standalone: true,
    imports: [RouterLink, NgTemplateOutlet, ToggleComponent, MatMenuModule, MemoizePipe, NavigationItemComponent, SidenavComponent, MatButtonModule],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
    @HostListener("window:resize", ["$event"])
    onResize(_event: Event) {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
    }

    progressBarValue = 100
    backDropHidden = true
    selectedLink: string | undefined
    unsubscribe = new Subject<void>()
    sidenavOpen = false
    isBigScreen: boolean = false
    isTemplate = false

    environment = environment
    backgroundColor = environment.style.navigationBar.background ?? "#0000000"

    can = inject(PermissionsService)
    auth = inject(AuthService)
    organizations = inject(OrganizationsService)
    serializer = inject(UrlSerializer)

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        public navBarService: NavigationBarService,
    ) {}

    ngOnInit() {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
        this.navBarService.progress.pipe(takeUntil(this.unsubscribe)).subscribe((progress: number) => {
            this.progressBarValue = progress
        })
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe),
            )
            .subscribe(() => {
                this.selectedLink = this.route.snapshot.children[0].routeConfig?.path
            })
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
