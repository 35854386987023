import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {ParameterType, imageOpCombineChannels} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-combine-channels"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"

const CombineChannelsNode = declareImageOpNode(imageOpCombineChannels)

export function combineChannels(parameters: GetParameters<Context, ParameterType>) {
    return new CombineChannelsNode(parameters)
}
