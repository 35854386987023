import {Component} from "@angular/core"
import {RouterModule} from "@angular/router"
import {ContentTypeModel, FilesGridDataObjectFragment, MutationCreateDataObjectInput, MutationUpdateDataObjectInput} from "@api"
import {CardErrorComponent} from "@common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "@common/components/cards/card-placeholder/card-placeholder.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent} from "@common/components/filters/checkboxes-filter/checkboxes-filter.component"
import {TagSearchFilterComponent} from "@common/components/filters/tag-search-filter/tag-search-filter.component"
import {ItemFiltersComponent} from "@common/components/item/item-filters/item-filters.component"
import {ItemListComponent} from "@common/components/item/item-list/item-list.component"
import {ListInfoComponent} from "@common/components/item/list-info/list-info.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {MemoizePipe} from "@app/common/pipes/memoize/memoize.pipe"

@Component({
    imports: [
        CardErrorComponent,
        EntityResponsiveSidebarComponent,
        ItemFiltersComponent,
        TagSearchFilterComponent,
        CheckboxesFilterComponent,
        InfiniteListComponent,
        ListInfoComponent,
        CardPlaceholderComponent,
        EntityCardComponent,
        StateLabelComponent,
        RouterModule,
        MemoizePipe,
    ],
    selector: "cm-files-grid",
    standalone: true,
    styleUrls: ["./files-grid.component.scss"],
    templateUrl: "./files-grid.component.html",
})
export class FilesGridComponent extends ItemListComponent<FilesGridDataObjectFragment, MutationUpdateDataObjectInput, MutationCreateDataObjectInput> {
    protected override _contentTypeModel = ContentTypeModel.DataObject
    protected override _createItem = (data: MutationCreateDataObjectInput) =>
        this.sdk.gql
            .filesGridCreateDataObject({
                input: data,
            })
            .then(({createDataObject: dataObject}) => dataObject)

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.sdk.gql
            .filesGridItems({
                take: take,
                skip: skip,
                filter: this.filters.dataObjectFilter(),
            })
            .then(({dataObjects, dataObjectsCount}) => ({items: dataObjects, totalCount: dataObjectsCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .filesGridItems({
                take: 1,
                filter: {
                    ...this.filters.dataObjectFilter(),
                    id: id ? {equals: id} : undefined,
                    legacyId: legacyId ? {equals: legacyId} : undefined,
                },
            })
            .then(({dataObjects}) => dataObjects?.[0] || undefined)

    provideWithGalleryImageField = (item: FilesGridDataObjectFragment) => {
        return {
            ...item,
            galleryImage: {
                id: item.id,
            },
        }
    }
}
