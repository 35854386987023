import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core"
import {MatDialog} from "@angular/material/dialog"
import {ActivatedRoute, Params, Router} from "@angular/router"
import {AuthService} from "@common/services/auth/auth.service"
import {FilterNameType} from "@common/services/filters/filters.service"
import {Enums} from "@enums"
import {Dictionary, UtilsService} from "@legacy/helpers/utils"
import {debounceTime, distinctUntilChanged, Subject} from "rxjs"

@Component({
    selector: "cm-item-filters",
    templateUrl: "./item-filters.component.html",
    styleUrls: ["./item-filters.component.scss"],
    standalone: true,
})
export class ItemFiltersComponent implements OnInit {
    @Input() optionalFilters: (FilterNameType | "search")[] = []
    @Input() stateFilter?: FilterNameType | "search"
    @Output() filterChange = new EventEmitter<Dictionary<string>>()

    @Input() filter: Dictionary<string> = {}

    public readonly Enums = Enums
    searchTextChanged = new Subject<string | MouseEvent>()

    private optionalFiltersSet = new Set<FilterNameType | "search">()

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        public utils: UtilsService,
    ) {}

    filterChanged(): void {
        this.applyFiltersToQueryParams(this.filter)
    }

    applyQueryParamsToFilters(params: Params) {
        this.filter = this.utils.paramsToDictionary(params)
        this.filterChange.emit(this.filter)
    }

    applyFiltersToQueryParams(dictionary: Dictionary<string>) {
        const params = this.utils.dictionaryToParams(dictionary)
        void this.router.navigate([], {relativeTo: this.route, queryParams: params})
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams: Params) => this.applyQueryParamsToFilters(queryParams))
        this.optionalFiltersSet = new Set(this.optionalFilters ?? [])
        this.searchTextChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => this.filterChanged())
    }

    showFilterType(type: FilterNameType | "search"): FilterNameType | "search" | null {
        return this.optionalFiltersSet.has(type) ? type : null
    }
}
