import {registerNode} from "@src/graph-system/register-node"
import {AnyJSONValue, anyJsonValue} from "@src/templates/types"
import {DeclareTemplateNodeTS, TemplateNodeImplementation, TemplateNodeMeta} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {z} from "zod"
import {ObjectData} from "../interfaces/object-data"

type ZodNode<T> = z.ZodType<T, z.ZodTypeDef, any>

const valueParameters = <T>(tValidation: ZodNode<T>) =>
    z.object({
        value: tValidation,
    })
type ValueParameters<T> = {value: T}

const generateValue = <T>(
    tValidation: ZodNode<T>,
    implementation: TemplateNodeImplementation<ValueParameters<T>>,
    meta: TemplateNodeMeta<ValueParameters<T>>,
) => {
    const retClass = class
        extends DeclareTemplateNodeTS<ValueParameters<T>>({validation: {paramsSchema: valueParameters(tValidation)}, ...implementation}, meta)
        implements EvaluableTemplateNode<T>
    {
        evaluate() {
            return this.parameters.value
        }
    }
    return retClass
}

////////////////////////////////////////////////////////////////////

export type StringValueParameters = ValueParameters<string>

@registerNode
export class StringValue extends generateValue<string>(z.string(), {}, {nodeClass: "StringValue"}) {}

export type StringValueFwd = TemplateNode<StringValueParameters> & EvaluableTemplateNode<string>

////////////////////////////////////////////////////////////////////

export type NumberValueParameters = ValueParameters<number>

@registerNode
export class NumberValue extends generateValue<number>(z.number(), {}, {nodeClass: "NumberValue"}) {}

export type NumberValueFwd = TemplateNode<NumberValueParameters> & EvaluableTemplateNode<number>

////////////////////////////////////////////////////////////////////

export type BooleanValueParameters = ValueParameters<boolean>

@registerNode
export class BooleanValue extends generateValue<boolean>(z.boolean(), {}, {nodeClass: "BooleanValue"}) {}

export type BooleanValueFwd = TemplateNode<BooleanValueParameters> & EvaluableTemplateNode<boolean>

////////////////////////////////////////////////////////////////////

export type JSONValueParameters = ValueParameters<AnyJSONValue>

@registerNode
export class JSONValue extends generateValue<AnyJSONValue>(anyJsonValue, {}, {nodeClass: "JSONValue"}) {}

export type JSONValueFwd = TemplateNode<JSONValueParameters> & EvaluableTemplateNode<AnyJSONValue>

////////////////////////////////////////////////////////////////////

export type ObjectValueParameters = ValueParameters<ObjectData>

const isObjectData = (instance: object): instance is ObjectData => {
    return instance.hasOwnProperty("bounds") && instance.hasOwnProperty("solverObject") && instance.hasOwnProperty("matrix")
}
@registerNode
export class ObjectValue extends generateValue<ObjectData>(
    z.object({}).refine(isObjectData, {message: "Expected object data"}),
    {},
    {nodeClass: "ObjectValue"},
) {}

export type ObjectValueFwd = TemplateNode<ObjectValueParameters> & EvaluableTemplateNode<ObjectData>
