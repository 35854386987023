<div class="node-flex-container">
    @if ($priceContainerNode()) {
        <div class="node-icon" matTooltip="Price Container" matTooltipPosition="above">
            <i class="fa-solid fa-layer-group"></i>
        </div>
        <span class="node-text">{{ $description() }}</span>
        @if ($priceContainerNode().parameters.condition) {
            <div class="tools-right">
                <cm-variant-condition
                    [variantConditionNode]="$priceContainerNode().parameters.condition"
                    ($conditionRemoved)="$conditionRemoved.emit()"
                ></cm-variant-condition>
            </div>
        }
    }
</div>
