<div class="container">
    @switch (icon) {
        @case ("search") {
            <i class="icon far fa-magnifying-glass"></i>
        }
        @case ("error") {
            <i class="icon far fa-circle-exclamation"></i>
        }
        @case ("no-files") {
            <i class="icon far fa-file-slash"></i>
        }
    }
    <div class="text">{{ text }}</div>
</div>
