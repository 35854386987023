export class NodeEditorNavigation {
    scale = 1
    scaleFactor = 1.15
    maxScale = 4
    minScale = 0.2

    panningButtons: MouseButton[] = [MouseButton.Right, MouseButton.Middle]
    panning = false
    panStart: {x: number; y: number} = {x: 0, y: 0}
    translationX = 0
    translationY = 0
    // The transform-origin CSS property of the container has to be set to "0 0" explicitly, because the default value is "center".
    transformOrigin: {x: number; y: number} = {x: 0, y: 0}

    constructor(
        public nodeEditor: HTMLElement,
        public nodeContainer: HTMLElement,
    ) {}

    mouseDown(event: MouseEvent): void {
        if (this.panningButtons.indexOf(event.button) !== -1) {
            event.stopPropagation()
            this.panning = true
            this.panStart.x = event.clientX
            this.panStart.y = event.clientY
        }
    }

    mouseMove(event: MouseEvent): void {
        if (this.panning) this.panView(this.nodeContainer, event)
    }

    mouseUp(event: MouseEvent): void {
        if (this.panningButtons.indexOf(event.button) !== -1) {
            if (this.panning) {
                event.stopPropagation()
                this.panning = false
                this.translationX += event.clientX - this.panStart.x
                this.translationY += event.clientY - this.panStart.y
                return
            }
        }
    }

    mouseWheel(event: WheelEvent): void {
        this.scaleView(this.nodeContainer, event)
    }

    private panView(element: HTMLElement, event: MouseEvent): void {
        const deltaX: number = this.translationX + event.clientX - this.panStart.x
        const deltaY: number = this.translationY + event.clientY - this.panStart.y
        this.setTransformStyle(element, deltaX, deltaY, this.scale)
    }

    private scaleView(element: HTMLElement, event: WheelEvent): void {
        let currentScaleFactor: number
        if (event.deltaY < 0) {
            currentScaleFactor = this.scaleFactor
        } else if (event.deltaY > 0) {
            currentScaleFactor = 1 / this.scaleFactor
        } else {
            return
        }
        const newScale = this.scale * currentScaleFactor

        if (newScale < this.minScale || this.maxScale < newScale) {
            return
        }
        this.scale = newScale

        const nodeContainerBoundingRect: DOMRect = this.nodeEditor.getBoundingClientRect()
        const mouseX: number = event.clientX - nodeContainerBoundingRect.x
        const mouseY: number = event.clientY - nodeContainerBoundingRect.y

        const mouseVectorX: number = mouseX - this.transformOrigin.x - this.translationX
        const mouseVectorY: number = mouseY - this.transformOrigin.y - this.translationY
        const mouseVectorScaledX: number = mouseVectorX * currentScaleFactor
        const mouseVectorScaledY: number = mouseVectorY * currentScaleFactor

        const zoomCompensatingTranslationX: number = mouseVectorX - mouseVectorScaledX
        const zoomCompensatingTranslationY: number = mouseVectorY - mouseVectorScaledY

        this.translationX += zoomCompensatingTranslationX
        this.translationY += zoomCompensatingTranslationY
        this.setTransformStyle(element, this.translationX, this.translationY, this.scale)
    }

    private setTransformStyle(element: HTMLElement, translationX: number, translationY: number, scale: number): void {
        element.style.transform = `translate(${translationX}px, ${translationY}px) scale(${scale})`
    }
}

enum MouseButton {
    Left = 0,
    Middle = 1,
    Right = 2,
}
