import {Component, computed, effect, Inject, Signal, signal, WritableSignal} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {HintComponent, HintTypes} from "@app/common/components/hint/hint.component"
import {MeasurementType, MeasurementTypes} from "@common/models/settings/settings"

@Component({
    selector: "cm-upload-texture-settings-dialog",
    templateUrl: "./adjust-size-dialog.component.html",
    styleUrls: ["./adjust-size-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogModule, MatInputModule, FormsModule, MatSelectModule, MatButtonModule, HintComponent, ButtonComponent],
})
export class AdjustSizeDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<AdjustSizeDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            width: number
            height: number
            resolutionAspectRatio: number
            displacement?: number
            showDisplacementSetting?: boolean
            allowUndefinedDisplacement?: boolean
            measurementType: MeasurementType
        },
    ) {
        if (!data) {
            throw new Error("Data is not set.")
        }
        this.isImperial = data.measurementType === MeasurementTypes.Imperial
        this.showDisplacementSetting = data.showDisplacementSetting ?? false
        this.allowUndefinedDisplacement = data.allowUndefinedDisplacement ?? false
        this.resolutionAspectRatio = data.resolutionAspectRatio
        this.$width = signal(this.isImperial ? MeasurementTypes.Imperial.convertFromMetric(data.width) : data.width)
        this.$height = signal(this.isImperial ? MeasurementTypes.Imperial.convertFromMetric(data.height) : data.height)
        this.$displacement = signal(
            data.displacement === undefined ? undefined : this.isImperial ? MeasurementTypes.Imperial.convertFromMetric(data.displacement) : data.displacement,
        )
        const sizeAspectRatio = data.width / data.height
        this.aspectRatioCorrect = Math.abs(sizeAspectRatio - this.resolutionAspectRatio) < 1e-6

        effect(
            () => {
                const height = this.$height()
                if (this.aspectRatioCorrect) {
                    this.$width.set(height === undefined ? undefined : height * this.resolutionAspectRatio)
                }
            },
            {allowSignalWrites: true},
        )
        effect(
            () => {
                const width = this.$width()
                if (this.aspectRatioCorrect) {
                    this.$height.set(width === undefined ? undefined : width / this.resolutionAspectRatio)
                }
            },
            {allowSignalWrites: true},
        )
    }

    cancelAction(): void {
        this.dialogRef.close(undefined)
    }

    confirmAction(): void {
        let width = this.$width()
        let height = this.$height()
        let displacement = this.$displacement()
        if (!width || !height) {
            throw new Error("Width and height must be set.")
        }
        if (this.isImperial) {
            width = MeasurementTypes.Imperial.convertToMetric(width)
            height = MeasurementTypes.Imperial.convertToMetric(height)
            if (displacement) {
                displacement = MeasurementTypes.Imperial.convertToMetric(displacement)
            }
        }
        this.dialogRef.close({width, height, displacement})
    }

    protected fixAspectRatio() {
        const width = this.$width()
        const height = this.$height()
        if (width === undefined || height === undefined) {
            this.$width.set(30 * this.resolutionAspectRatio)
            this.$height.set(30)
        } else if (width > height) {
            this.$height.set(width / this.resolutionAspectRatio)
        } else {
            this.$width.set(height * this.resolutionAspectRatio)
        }
        this.aspectRatioCorrect = true
    }

    protected isImperial: boolean
    protected showDisplacementSetting: boolean
    protected allowUndefinedDisplacement: boolean
    protected resolutionAspectRatio: number
    protected $width: WritableSignal<number | undefined>
    protected $height: WritableSignal<number | undefined>
    protected $displacement: Signal<number | undefined>
    protected aspectRatioCorrect: boolean
    protected $isValidData = computed(() => {
        const width = this.$width()
        const height = this.$height()
        const displacement = this.$displacement()
        return (
            (!this.showDisplacementSetting || this.allowUndefinedDisplacement || (displacement !== undefined && displacement > 0)) &&
            width !== undefined &&
            width > 0 &&
            height !== undefined &&
            height > 0 &&
            this.aspectRatioCorrect
        )
    })

    protected HintTypes = HintTypes
}

export type AdjustSizeDialogResult = {
    width: number
    height: number
    displacement?: number
}
