import {Component, Input, OnInit} from "@angular/core"
import {NativeCheckboxComponent} from "@common/components/inputs/native/native-checkbox/native-checkbox.component"
import {FiltersService} from "@common/services/filters/filters.service"
import {StateLabel} from "@labels"
import {forceSetValue, setsAreEqual} from "@common/helpers/utils/set"
import {filter, map} from "rxjs"

/**
 * Multiple checkboxes used to toggle options for a single filter criterion
 */
@Component({
    selector: "cm-checkboxes-filter",
    templateUrl: "./checkboxes-filter.component.html",
    styleUrls: ["./checkboxes-filter.component.scss"],
    standalone: true,
    imports: [NativeCheckboxComponent],
})
export class CheckboxesFilterComponent implements OnInit {
    @Input() label = ""
    @Input() name: string = ""
    _options: StateLabel<string>[] = []
    @Input() set options(value: StateLabel<string>[] | Map<string, StateLabel<string>>) {
        if (value instanceof Map) {
            this._options = Array.from(value.values())
        } else {
            this._options = value
        }
    }
    get options(): StateLabel<string>[] {
        return this._options
    }

    public selectedOptions = new Set<string>()

    public onCheckboxToggled = (option: string, value: boolean) => {
        forceSetValue(this.selectedOptions, option, value)
        void this.filtersService.updateCriteria(this.name, this.selectedOptions)
    }

    constructor(private filtersService: FiltersService) {
        this.filtersService.states
            .pipe(
                map((states) => states.criteria[this.name]),
                filter((selection) => !setsAreEqual(selection, this.selectedOptions)),
            )
            .subscribe((selection) => {
                this.selectedOptions = selection ?? new Set()
            })
    }

    ngOnInit() {
        this.filtersService.currentStates.criteria[this.name]?.forEach((option) => forceSetValue(this.selectedOptions, option, true))
    }
}
