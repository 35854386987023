import {registerNode} from "@src/graph-system/register-node"
import {AnyJSONValue, anyJsonValue} from "@src/templates/types"
import {Node, TemplateLike, node, templateLike} from "@src/templates/node-types"
import {DeclareObjectNodeTS, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {visitNone} from "@src/graph-system/declare-visitor-node"
import {RunTemplateNew} from "@src/templates/runtime-graph/nodes/run-template-new"
import {CompileTemplateNew} from "@src/templates/runtime-graph/nodes/compile-template-new"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {FilterAndWrapInterfaceNew} from "@src/templates/runtime-graph/nodes/filter-and-wrap-interface-new"
import {Nodes} from "@src/templates/nodes/nodes"
import {nodeInstanceFwd} from "@src/graph-system/instance"
import {idNodeParameters, IdNodeParameters} from "@src/templates/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"

export type TemplateParameterValue = AnyJSONValue | Node
type ParametersParameters = {[paramId: string]: TemplateParameterValue}

@registerNode
export class Parameters extends DeclareTemplateNodeTS<ParametersParameters>(
    {
        validation: {paramsSchema: z.record(z.string(), z.union([anyJsonValue, node]))},
    },
    {nodeClass: "Parameters"},
) {}
type ParametersFwd = TemplateNode<ParametersParameters>

const templateInstanceParameters = namedNodeParameters.merge(idNodeParameters).merge(
    z.object({
        template: templateLike,
        parameters: nodeInstanceFwd<ParametersFwd>("Parameters"), //Workaround for circular dependency
    }),
)

export type TemplateInstanceParameters = NamedNodeParameters &
    IdNodeParameters & {
        template: TemplateLike
        parameters: ParametersFwd
    }

@registerNode
export class TemplateInstance extends DeclareObjectNodeTS<TemplateInstanceParameters>( //owns parameters
    {
        validation: {paramsSchema: templateInstanceParameters},
        onVisited: {
            onCompile: function (this: TemplateInstance, {context, parameters}) {
                const {evaluator, currentTemplate, topLevelObjectId, templateDepth, subTemplates, sceneProperties} = context
                const {allBounds, objectToNodeMap, nodeToObjectMap} = currentTemplate
                const {readyList, preDisplayLists, displayLists, solverDatas, lookupByExternalIdPathMap, descriptorLists, exposeClaimedSubTemplateInputs} =
                    subTemplates
                const {templateContext, templateScope} = evaluator
                const {sceneManager} = templateContext
                const {template, id} = parameters

                const scope = evaluator.getScope(this)

                const [inputs, claimedInputIds] = evaluator.evaluateTemplateInputs(scope, this)
                const [graphValid, graphInvalid] = scope.branch(evaluator.evaluateTemplate(scope, template))
                const graph = scope.phi(
                    graphValid,
                    scope.lambda(graphInvalid, () => new TemplateGraph({name: "Empty", nodes: new Nodes({list: []})}), ""),
                )

                const objectId = scope.genNodeId(RunTemplateNew)
                objectToNodeMap.set(objectId, this)
                nodeToObjectMap.set(this, objectId)

                const [transformMatrix, solverObject] = this.setupTransform(scope, context, objectId)

                const compileTemplate = scope.node(CompileTemplateNew, {
                    templateContext,
                    graph,
                    sceneProperties: sceneProperties ?? null,
                    inputs,
                    topLevelObjectId: topLevelObjectId ?? objectId,
                    templateDepth: templateDepth + 1,
                    exposeClaimedSubTemplateInputs: false,
                    overrideMaterial: context.overrideMaterial ?? null,
                    sceneManager,
                })
                const runTemplate = scope.node(RunTemplateNew, {
                    $id: objectId,
                    compiledTemplate: compileTemplate.compiledTemplate,
                    matrix: transformMatrix,
                    solverObject,
                })

                readyList.push(runTemplate.ready)
                preDisplayLists.push(runTemplate.preDisplayList)
                displayLists.push(runTemplate.displayList)
                solverDatas.push(runTemplate.solverData)
                lookupByExternalIdPathMap.set(this, runTemplate.lookupByExternalIdPath)

                descriptorLists.push(
                    scope.node(FilterAndWrapInterfaceNew, {
                        interface: runTemplate.descriptorList,
                        wrapWithId: id,
                        claimedInputIds,
                        includeClaimed: exposeClaimedSubTemplateInputs,
                    }).output,
                )

                allBounds.push(scope.get(runTemplate.objectData, "bounds"))
                const localId = evaluator.getLocalId(this)
                templateScope.alias(runTemplate.outputs, `templateOutputs-${localId}`)
                templateScope.alias(runTemplate.objectData, `objectData-${localId}`)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "TemplateInstance"},
) {}

export type TemplateInstanceFwd = TemplateObjectNode<TemplateInstanceParameters>
