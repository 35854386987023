import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeValueNode, threeConvert, threeVec4Node, lutSize, ApplyLUTNode} from "@src/materials/three-utils"
import {color} from "@src/materials/types"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import * as THREE from "three"

export class ColorRamp extends DeclareMaterialNode(
    {
        returns: z.object({color: materialSlots, alpha: materialSlots}),
        inputs: z.object({
            fac: materialSlots.optional(),
        }),
        parameters: z.object({
            fac: z.number().optional(),
            cyclesRampAlphaTable: z.array(z.number()).optional(),
            cyclesRampColorTable: z.array(color).optional(),
            color0: color.optional(),
            position0: z.number().optional(),
            color1: color.optional(),
            position1: z.number().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters, context}) => {
            const {cyclesRampColorTable, cyclesRampAlphaTable} = parameters
            const fac = (await get(inputs.fac)) ?? threeConvert(parameters.fac, threeValueNode) ?? threeValueNode(1)

            if (
                cyclesRampColorTable &&
                cyclesRampAlphaTable &&
                cyclesRampColorTable.length === cyclesRampAlphaTable.length &&
                cyclesRampColorTable.length === lutSize
            ) {
                const lut_rgba = cyclesRampColorTable.map((color, index) => [color.r, color.g, color.b, cyclesRampAlphaTable.at(index)!])

                const texture = new THREE.DataTexture(new Float32Array(lut_rgba.flat()), lut_rgba.length, 1, THREE.RGBAFormat, THREE.FloatType)
                texture.minFilter = THREE.NearestFilter
                texture.magFilter = THREE.NearestFilter
                texture.wrapS = THREE.ClampToEdgeWrapping
                texture.wrapT = THREE.ClampToEdgeWrapping
                texture.anisotropy = 1
                texture.colorSpace = THREE.LinearSRGBColorSpace
                texture.needsUpdate = true
                context.onThreeCreatedTexture?.(texture)

                const result = new ApplyLUTNode(THREENodes.vec4(fac), texture, threeValueNode(1))

                return {color: THREENodes.color(new THREENodes.SplitNode(result, "xyz")), alpha: new THREENodes.SplitNode(result, "w")}
            }

            console.warn("ColorRamp only implemeted as an approximation")

            const color0 = parameters.color0 ?? {r: 0, g: 0, b: 0}
            const color1 = parameters.color1 ?? {r: 1, g: 1, b: 1}
            const alpha0 = parameters.color0?.a ?? 1
            const alpha1 = parameters.color1?.a ?? 1
            const position0 = parameters.position0 ?? 0
            const position1 = parameters.position1 ?? 1
            const positionDelta = position1 - position0

            // this tries to linearly approximate a ramp node by just looking at the first two points that define the ramp
            const t = THREENodes.div(THREENodes.sub(fac, threeValueNode(position0)), threeValueNode(positionDelta))
            const tC = t //THREENodes.clamp(t, threeValueNode(0), threeValueNode(1))
            const value0 = threeVec4Node({x: color0.r, y: color0.g, z: color0.b, w: alpha0})
            const value1 = threeVec4Node({x: color1.r, y: color1.g, z: color1.b, w: alpha1})
            const result = THREENodes.mix(value0, value1, tC)
            return {color: THREENodes.color(new THREENodes.SplitNode(result, "xyz")), alpha: new THREENodes.SplitNode(result, "w")}
        },
    },
) {}
