import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeValueNode, threeConvert} from "@src/materials/three-utils"
import {vec3} from "@src/materials/types"
import {getAll} from "@src/graph-system/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class Bump extends DeclareMaterialNode(
    {
        returns: z.object({normal: materialSlots.optional()}),
        inputs: z.object({
            height: materialSlots.optional(),
            distance: materialSlots.optional(),
            strength: materialSlots.optional(),
            normal: materialSlots.optional(),
        }),
        parameters: z.object({distance: z.number().optional(), invert: z.boolean().optional(), normal: vec3.optional(), strength: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {height, strength} = await getAll(inputs, get)
            const heightValue = height ?? threeValueNode(0)
            const strengthValue = strength ?? threeConvert(parameters.strength, threeValueNode) ?? threeValueNode(1.0)

            if (!(heightValue instanceof THREENodes.TextureNode)) {
                console.warn("Bump node cannot use non-texture node as input")
                return {normal: undefined}
            }

            //@ts-ignore
            return {normal: THREENodes.bumpMap(heightValue, strengthValue)}
        },
    },
) {}
