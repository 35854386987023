import {Component, Input} from "@angular/core"

/**
 * Pinned filters are shown at the top, before non-pinned filters.
 * TODO: Do we really need a separate component for this? Could we not use a normal filter in a dedicated slot?
 */
@Component({
    selector: "cm-pinned-filter",
    templateUrl: "./pinned-filter.component.html",
    styleUrls: ["./pinned-filter.component.scss"],
    standalone: true,
})
export class PinnedFilterComponent {
    @Input() label: string = ""
}
