import {GetNodeParameters} from "@src/graph-system/declare-node"
import {NodeGraph, NodeParameters, registerNodeGraph} from "@src/graph-system/node-graph"

export function registerNode<ReturnType, Context, ParamTypes extends NodeParameters, PrimitiveParamTypes extends NodeParameters>(targetClass: {
    new (
        parameters: GetNodeParameters<Context, ParamTypes, PrimitiveParamTypes>,
    ): NodeGraph<ReturnType, Context, GetNodeParameters<Context, ParamTypes, PrimitiveParamTypes>>
}) {
    return registerNodeGraph(targetClass)
}
