<ng-content></ng-content>
<cm-inspector-section>
    <ng-container cm-title> Position </ng-container>
    <ng-container cm-content>
        @if (lockedTransform() !== undefined) {
            <div class="cm-xyz" (click)="transformModeChanged.emit(TransformMode.Translate)">
                <div class="cm-xyz-label" [class.cm-active]="transformMode() === TransformMode.Translate">
                    <i class="far fa-arrows-up-down-left-right"></i>
                </div>
                <cm-input-container>
                    <ng-container cm-inline-label> X: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="translateValueX" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Y: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="translateValueY" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Z: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="translateValueZ" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
            <div class="cm-xyz" (click)="transformModeChanged.emit(TransformMode.Rotate)">
                <div [class.cm-active]="transformMode() === TransformMode.Rotate" class="cm-xyz-label">
                    <i class="far fa-arrows-rotate"></i>
                </div>
                <cm-input-container>
                    <ng-container cm-inline-label> X: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="rotationValueX" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Y: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="rotationValueY" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Z: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="rotationValueZ" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
            @if (auth.isStaff()) {
                <cm-toggle [toggle]="useMaxCoordinates()" (toggleChange)="useMaxCoordinates.set($event)">
                    <ng-container cm-text> 3DS Max / Blender coordinates </ng-container>
                </cm-toggle>
            }
        } @else {
            Position is computed and cannot be set
        }
    </ng-container>
</cm-inspector-section>
