// @ts-strict-ignore
import {Component, inject, Input, OnInit, OnDestroy, ViewChild} from "@angular/core"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"
import {IMaterialGraph} from "@cm/lib/materials/material-node-graph"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {ReplaySubject, take, map, combineLatest} from "rxjs"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {IsNonNull} from "@cm/lib/utils/filter"

@Component({
    selector: "cm-preview-scene",
    templateUrl: "./preview-scene.component.html",
    styleUrls: ["./preview-scene.component.scss"],
    standalone: true,
    imports: [SceneViewerComponent],
})
export class PreviewSceneComponent implements OnInit, OnDestroy {
    @ViewChild("sceneViewer", {static: true}) sceneViewer: SceneViewerComponent

    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)

    @Input() templateId?: number

    @Input() set materialGraph(_materialGraph: IMaterialGraph) {
        if (_materialGraph) {
            this.materialGraphSubject.next(_materialGraph)
        }
    }

    materialGraphSubject: ReplaySubject<IMaterialGraph> = new ReplaySubject<IMaterialGraph>(1)

    ngOnInit() {
        this.sdk.gql.organizationsWithNodeEditorTemplateId({organizationIds: this.organizations.$own().map((organization) => organization.id)}).then((data) => {
            if (!this.templateId) {
                this.templateId = data.organizations.map((organization) => organization.nodeEditorTemplate?.legacyId).filter(IsNonNull)?.[0]
            }
        })
        combineLatest([
            this.sceneViewer.loadingCompleted.pipe(
                take(1),
                map(() => {
                    const firstMaterialDescriptor = this.sceneViewer.getInterface().find(Nodes.Meta.isMaterialInput)
                    if (!firstMaterialDescriptor) throw Error("material interface not found")
                    return firstMaterialDescriptor.id
                }),
            ),
            this.materialGraphSubject,
        ]).subscribe({
            next: ([id, _materialGraph]) => {
                this.sceneViewer.setMaterialInput(id, _materialGraph)
            },
        })
    }

    ngOnDestroy() {
        this.materialGraphSubject.complete()
    }
}
