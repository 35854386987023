import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-full-page-feedback",
    standalone: true,
    imports: [],
    templateUrl: "./full-page-feedback.component.html",
    styleUrl: "./full-page-feedback.component.scss",
})
export class FullPageFeedbackComponent {
    @Input() text: string | null = "An error has occurred"
    @Input() icon: "no-files" | "cloud-plus" | "search" | "error" = "error"
}
