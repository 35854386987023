export const INFINITE_SCROLL_ROOT_MARGIN = "400px"
export const INFINITE_SCROLL_THRESHOLD = [0, 1]

export const PLACEHOLDER_INITIAL_BATCH_SIZE = 100
export const PLACEHOLDER_ADD_BATCH_SIZE = 100
export const DATA_LOAD_BATCH_SIZE = 25

export const DEFAULT_BATCH_SIZES = {
    initial: 20,
    placeholders: 25,
    max: 1_000_000,
}
