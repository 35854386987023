import {map, Subscription} from "rxjs"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {IDataObject} from "@src/templates/interfaces/data-object"
import {ISceneManager} from "@src/templates/interfaces/scene-manager"

const TD = TypeDescriptors

const fetchDataObjectDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    dataObjectId: TD.inlet(TD.Number),
    dataObject: TD.outlet(TD.DataObject),
}

export class FetchDataObject implements NodeClassImpl<typeof fetchDataObjectDescriptor, typeof FetchDataObject> {
    static descriptor = fetchDataObjectDescriptor
    static uniqueName = "FetchDataObject"
    sceneManager!: Inlet<ISceneManager>
    dataObjectId!: Inlet<number>
    dataObject!: Outlet<IDataObject>

    private _pending: Subscription | null = null

    run() {
        this._pending?.unsubscribe()
        this.dataObject.emitIfChanged(NotReady)
        const sceneManager = this.sceneManager
        if (sceneManager === NotReady || this.dataObjectId === NotReady) {
            return
        }
        this._pending = sceneManager.addTask(
            sceneManager.loadDataObject(this.dataObjectId).pipe(
                map((dataObject) => {
                    this._pending = null
                    this.dataObject.emitIfChanged(dataObject)
                }),
            ),
        )
    }

    cleanup() {
        this._pending?.unsubscribe()
    }
}
