import {Injectable, Injector} from "@angular/core"

@Injectable()
export class InjectorService {
    static injector: Injector

    constructor(public injector: Injector) {
        InjectorService.injector = injector
    }
}
