import {EventEmitter} from "@angular/core"
import {Vector2Like} from "@cm/lib/math/vector2"
import {deepCopy} from "@cm/lib/utils/utils"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {Operator, OperatorInput, OperatorOutput, OperatorPanelComponentType} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {LayerAndMaskPanelComponent} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/panel/layer-and-mask-panel.component"
import {LayerAndMaskToolbox} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/toolbox/layer-and-mask-toolbox"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {HalInvertMask} from "app/textures/texture-editor/operator-stack/operators/layer-and-mask/hal/hal-invert-mask"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {getProperty} from "@cm/lib/graph-system/utils"
import {blur} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/blur-node"
import {NodeGraph} from "@cm/lib/graph-system/node-graph"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {getImageDesc} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/get-image-desc-node"
import {drawableImage} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/drawable-image-node"
import {shift} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/shift-node"
import {math} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/math-node"
import {blend} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/blend-node"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {struct} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/struct-node"
import {TextureType} from "@api"
import {ImageOpNodeGraphEvaluator} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"

export class OperatorLayerAndMask extends OperatorBase<TextureEditNodes.OperatorLayerAndMask> {
    readonly showGuidesChanged = new EventEmitter<boolean>()
    readonly layerEditModeChanged = new EventEmitter<LayerEditMode>()
    readonly layerMoveModeChanged = new EventEmitter<LayerMoveMode>()

    readonly panelComponentType: OperatorPanelComponentType = LayerAndMaskPanelComponent
    readonly canvasToolbox: LayerAndMaskToolbox

    readonly type = "operator-layer-and-mask" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorLayerAndMask | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-layer-and-mask",
                enabled: true,
                maskReference: {
                    type: "data-object-reference",
                    dataObjectId: "",
                },
                mapOffsetInPixels: {x: 0, y: 0},
                maskOffsetInPixels: {x: 0, y: 0},
                layerMinOpacity: 0,
                layerMaxOpacity: 1,
                maskFeathering: 0,
            },
        )

        this._maskDrawableImageRef = new DrawableImageRef(this.node.maskReference, this.callback.customerLegacyId)

        this.canvasToolbox = new LayerAndMaskToolbox(this)
        this.canvasToolbox.setMaskReference(this._maskDrawableImageRef)
        this.halInvertMask = new HalInvertMask(this.callback.halContext)
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this.canvasToolbox.remove()
        this.halInvertMask.dispose()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorLayerAndMask(this.callback, deepCopy(this.node))
        await this.callback.drawableImageCache.cloneImage(this._maskDrawableImageRef, clonedOperator._maskDrawableImageRef)
        return clonedOperator
    }

    // OperatorBase
    async getImageOpNodeGraph(evaluator: ImageOpNodeGraphEvaluator, textureType: TextureType, input: OperatorInput): Promise<OperatorOutput> {
        const sourceImageDesc = getImageDesc({
            sourceImage: input,
        })
        const maskImage = drawableImage({
            drawableImageRef: this._maskDrawableImageRef,
            descriptor: struct({
                width: getProperty(sourceImageDesc, "width"),
                height: getProperty(sourceImageDesc, "height"),
                channelLayout: "R" as const,
                format: TextureEditorSettings.PreviewProcessingImageFormat,
                isSRGB: false,
            }),
        })
        let shiftedMaskImage: NodeGraph<ImagePtr, Context> = shift({
            sourceImage: maskImage,
            offset: this.maskOffsetInPixels,
        })
        if (this.maskFeathering > 0) {
            shiftedMaskImage = blur({
                sourceImage: shiftedMaskImage,
                blurKernelSize: {
                    x: this.maskFeathering + 1,
                    y: this.maskFeathering + 1,
                },
                borderMode: "wrap",
            })
        }
        const drawMaskOnly = evaluator.mode === "preview" && this._showMask && this.selected
        if (drawMaskOnly) {
            return {
                resultImage: shiftedMaskImage,
                options: {
                    stopEvaluation: true,
                },
            }
        } else {
            if (this.layerMinOpacity !== 0 || this.layerMaxOpacity !== 1) {
                const offset = this.layerMinOpacity
                const scale = this.layerMaxOpacity - this.layerMinOpacity
                if (scale !== 1) {
                    shiftedMaskImage = math({
                        operandA: shiftedMaskImage,
                        operandB: scale,
                        operator: "*",
                    })
                }
                if (offset !== 0) {
                    shiftedMaskImage = math({
                        operandA: shiftedMaskImage,
                        operandB: offset,
                        operator: "+",
                    })
                }
            }
            const shiftedSourceImage = shift({
                sourceImage: input,
                offset: this.mapOffsetInPixels,
            })
            const resultImage = blend({
                backgroundImage: input,
                foregroundImage: shiftedSourceImage,
                alpha: shiftedMaskImage,
                premultipliedAlpha: false,
                blendMode: "normal",
            })
            return {
                resultImage,
            }
        }
    }

    get mapOffsetInPixels(): Vector2Like {
        return this.node.mapOffsetInPixels
    }

    set mapOffsetInPixels(value: Vector2Like) {
        if (this.node.mapOffsetInPixels.x === value.x && this.node.mapOffsetInPixels.y === value.y) {
            return
        }
        this.node.mapOffsetInPixels = {x: value.x, y: value.y}
        this.markEdited()
        this.requestEval()
    }

    get maskOffsetInPixels(): Vector2Like {
        return this.node.maskOffsetInPixels
    }

    set maskOffsetInPixels(value: Vector2Like) {
        if (this.node.maskOffsetInPixels.x === value.x && this.node.maskOffsetInPixels.y === value.y) {
            return
        }
        this.node.maskOffsetInPixels = {x: value.x, y: value.y}
        this.markEdited()
        this.requestEval()
    }

    get layerMinOpacity(): number {
        return this.node.layerMinOpacity ?? 0
    }

    set layerMinOpacity(value: number) {
        if (this.node.layerMinOpacity === value) {
            return
        }
        this.node.layerMinOpacity = value
        this.markEdited()
        this.requestEval()
    }

    get layerMaxOpacity(): number {
        return this.node.layerMaxOpacity ?? 1
    }

    set layerMaxOpacity(value: number) {
        if (this.node.layerMaxOpacity === value) {
            return
        }
        this.node.layerMaxOpacity = value
        this.markEdited()
        this.requestEval()
    }

    get maskFeathering(): number {
        return this.node.maskFeathering ?? 0
    }

    set maskFeathering(value: number) {
        if (this.node.maskFeathering === value) {
            return
        }
        this.node.maskFeathering = value
        this.markEdited()
        this.requestEval()
    }

    get showGuides(): boolean {
        return this._showGuides
    }

    set showGuides(value: boolean) {
        if (this._showGuides === value) {
            return
        }
        this._showGuides = value
        this.showGuidesChanged.emit(value)
    }

    set showMask(value: boolean) {
        if (this._showMask === value) {
            return
        }
        this._showMask = value
        this.requestEval()
    }

    get showMask(): boolean {
        return this._showMask
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    get layerEditMode(): LayerEditMode {
        return this._layerEditMode
    }

    set layerEditMode(value: LayerEditMode) {
        if (this._layerEditMode === value) {
            return
        }
        this._layerEditMode = value
        this.layerEditModeChanged.emit(value)
    }

    get layerMoveMode(): LayerMoveMode {
        return this._layerMoveSettings.layerMoveMode
    }

    set layerMoveMode(value: LayerMoveMode) {
        if (this._layerMoveSettings.layerMoveMode === value) {
            return
        }
        this._layerMoveSettings.layerMoveMode = value
        this.layerMoveModeChanged.emit(value)
    }

    async invertMask(): Promise<void> {
        const strokeImage = await this.canvasToolbox.getStrokeImage()
        const invertedStrokeImage = await this.callback.requestTemporaryPaintableImage(strokeImage.descriptor, strokeImage.options)
        await this.halInvertMask.paint(invertedStrokeImage, strokeImage)
        await this.canvasToolbox.setStrokeImage(invertedStrokeImage)
        this.markEdited()
        this.requestEval()
    }

    override async save(_processingJobId: string): Promise<TextureEditNodes.Operator> {
        this.resetEdited()
        return deepCopy(this.node) // make a copy to detach the maskReference from the drawableImageRef
    }

    private _layerEditMode = LayerEditMode.Move
    private _layerMoveSettings = new LayerMoveSettings()
    private _brushSettings = new BrushSettings()
    private _showGuides = true
    private _showMask = false
    private _maskDrawableImageRef: DrawableImageRef

    private halInvertMask: HalInvertMask // TODO replace by image-op
}

export enum LayerEditMode {
    Move = "move",
    Draw = "draw",
}

export class LayerMoveSettings {
    readonly changed = new EventEmitter<void>()

    set layerMoveMode(value: LayerMoveMode) {
        this._layerMoveMode = value
        this.changed.emit()
    }

    get layerMoveMode(): LayerMoveMode {
        return this._layerMoveMode
    }

    private _layerMoveMode = LayerMoveMode.ImageAndMask
}

export enum LayerMoveMode {
    ImageAndMask = "image-and-mask",
    ImageOnly = "image-only",
    MaskOnly = "mask-only",
}
