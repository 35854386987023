// @ts-strict-ignore
import {ApiFields, EndpointUrls, MakeApiModelBase} from "@legacy/api-model/api-model"
import {declareApiModel} from "@legacy/api-model/api-model"
import {DataObject} from "@legacy/api-model/data-object"
import {EntityType} from "@legacy/models/entity-type"
import type {Material} from "@legacy/api-model/material"
import {IMaterialRevision} from "@cm/lib/templates/interfaces/material-data"

@declareApiModel(EndpointUrls.materialRevisionsUrl, EntityType.MaterialRevision, null, true)
export class MaterialRevision extends MakeApiModelBase<MaterialRevision>() implements IMaterialRevision {
    static _Material: typeof Material // for breaking circular import
    @ApiFields.id({name: "material"}) material: number
    @ApiFields.number({name: "number", readOnly: true}) number: number
    @ApiFields.string({name: "comment"}) comment: string
    @ApiFields.string({name: "graph_schema"}) graphSchema: string
    @ApiFields.date({name: "created", readOnly: true}) created: Date
    @ApiFields.singleReference({name: "mat_file", model: DataObject, allowCaching: false}) matFile: DataObject
    @ApiFields.boolean({name: "has_cycles_material", readOnly: true}) hasCyclesMaterial: boolean
}
