@if (operations.length) {
    <div class="cm-background-operations-header">
        <div>Operations</div>
        @if (!minimized) {
            <div class="cm-background-operations-header-icon" (click)="minimize()">
                <i class="far fa-chevron-down"></i>
            </div>
        }
        @if (minimized) {
            <div class="cm-background-operations-header-icon" (click)="maximize()">
                <i class="far fa-chevron-up"></i>
            </div>
        }
        <div class="cm-background-operations-header-icon" (click)="closeAndCancel()">
            <i class="far fa-times"></i>
        </div>
    </div>

    <div [hidden]="minimized" class="background-operation-container">
        @for (operation of operations; track operation) {
            <div class="row">
                <div class="item">
                    <span class="cm-task-type-badge">{{ operation.taskType }}</span>
                </div>
                <div class="item">
                    @if (operation.state === 3 && operation.resultUrl) {
                        <a [href]="operation.resultUrl" target="_blank" [innerHTML]="operation.resultTitle || operation.resultUrl"></a>
                    } @else {
                        <div class="cm-list-item-name">{{ operation.description }}</div>
                    }
                </div>
                <div class="item">
                    @if (operation.state === 1) {
                        <i tp="Waiting" [tpAppendTo]="tooltipContainer" class="far fa-clock cm-operation-state"></i>
                    }
                    @if (operation.state === 2) {
                        @if (operation.progress === -1) {
                            <mat-progress-spinner tp="Running" [tpAppendTo]="tooltipContainer" mode="indeterminate" [diameter]="14"></mat-progress-spinner>
                        } @else {
                            <mat-progress-spinner
                                [tp]="'Running: ' + operation.progress + '%'"
                                [tpAppendTo]="tooltipContainer"
                                [diameter]="14"
                                value="{{ operation.progress }}"
                            ></mat-progress-spinner>
                        }
                    }
                    @if (operation.state === 3) {
                        <i tp="Completed" [tpAppendTo]="tooltipContainer" class="fas fa-check cm-operation-state cm-operation-state-completed"></i>
                    }
                    @if (operation.state === 4) {
                        <i tp="Cancelled" [tpAppendTo]="tooltipContainer" class="far fa-times-circle cm-operation-state"></i>
                    }
                    @if (operation.state === 5) {
                        <i
                            [tp]="operation.errorCause ? operation.errorCause : 'failed'"
                            [tpAppendTo]="tooltipContainer"
                            class="far fa-exclamation-circle cm-operation-state cm-operation-state-error"
                        ></i>
                    }
                </div>
            </div>
        }
    </div>

    <div class="tooltip-container" #tooltipContainer></div>
}
