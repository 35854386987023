import {JobState, JobTaskState} from "@api"

/**
 * An active job task is expected to change its state without user action.
 */
export const jobTaskIsActive = (state?: JobTaskState) => {
    switch (state) {
        case JobTaskState.Init:
        case JobTaskState.Queued:
        case JobTaskState.Runnable:
        case JobTaskState.Running:
            return true
        default:
            return false
    }
}

/**
 * An active job is expected to change its state without user action.
 */
export const jobIsActive = (job?: {state?: JobState}) => {
    switch (job?.state) {
        case JobState.Init:
        case JobState.Running:
        case JobState.Runnable:
            return true
        default:
            return false
    }
}
