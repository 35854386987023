import {TextureType} from "@api"
import {assertNodeOfType, MaterialGraphNode, UnwrappedMaterialGraphNode, wrapNodeOutput, WrappedMaterialGraphNode} from "@cm/lib/materials/material-node-graph"
import {rotateNormalMapVector} from "@cm/lib/materials/material-node-graph-transformations"
import {CubicBezierSpline, Knot} from "@cm/lib/math/cubic-bezier-spline"
import {InterpolationMode, OutOfBoundsMode, SampledFunction} from "@cm/lib/math/sampled-function"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"

export function computeRgbCurveSampledFunction(controlPoints: Vector2Like[]): SampledFunction {
    const numCurveSamples = 256
    const knots = controlPoints.map((point) => new Knot(point))
    const spline = new CubicBezierSpline(knots)
    const points = spline.evaluatePoints(numCurveSamples)
    return new SampledFunction(points, InterpolationMode.Linear, OutOfBoundsMode.Extrapolate)
}

// returns a [lutSize][3] array of numbers which map inputs for R, G and B (mapped between 0 and 1) to their respective output values
export function computeRgbCurveLUT(lutSize: number, getParamFn: (paramName: string) => [number, number] | undefined): number[][] {
    const sampledFunctions: SampledFunction[] = []
    for (let curveIndex = 0; curveIndex < 4; curveIndex++) {
        const controlPoints: Vector2[] = []
        for (let i = 0; ; i++) {
            const pointLocation: [number, number] | undefined = getParamFn(`internal.mapping.curves[${curveIndex}].points[${i}].location`)
            if (!pointLocation) {
                break
            }
            controlPoints.push(new Vector2(pointLocation[0], pointLocation[1]))
        }
        const sampledFunction = computeRgbCurveSampledFunction(controlPoints)
        sampledFunctions.push(sampledFunction)
    }
    const lut: number[][] = new Array(lutSize).fill(0).map(() => new Array(3).fill(0))
    for (let c = 0; c < 3; c++) {
        for (let i = 0; i < lut.length; i++) {
            const input = i / (lut.length - 1)
            const output = sampledFunctions[c].evaluate(sampledFunctions[3].evaluate(input)!)! // we use "extrapolate" so it can not be undefined
            lut[i][c] = output
        }
    }
    return lut
}

function createConstantValue(value: number) {
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeValue",
            parameters: {
                Value: value,
            },
        },
        "Value",
    )
}

function createConstantColor(color: [number, number, number]) {
    return wrapNodeOutput(
        {
            nodeType: "ShaderNodeRGB",
            parameters: {
                Color: color,
            },
        },
        "Color",
    )
}

function createMappingNode(
    uvNode: WrappedMaterialGraphNode | undefined,
    scale: [number, number, number] = [1, 1, 1],
    rotation: [number, number, number] = [0, 0, 0],
    location: [number, number, number] = [0, 0, 0],
) {
    return wrapNodeOutput(
        {
            nodeType: "Mapping",
            parameters: {
                "internal.vector_type": "TEXTURE",
                Location: location,
                Rotation: rotation,
                Scale: scale,
            },
            inputs: uvNode ? {Vector: uvNode} : undefined,
        },
        "Vector",
    )
}

function createTexImageNodeForNodeImageResourceSlot(node: UnwrappedMaterialGraphNode, resourceSlotIdx: number, mappingNode: WrappedMaterialGraphNode) {
    if (!node.imageResources) {
        throw new Error("Expected imageResources")
    }
    return wrapNodeOutput(
        {
            nodeType: "TexImage",
            inputs: {
                Vector: mappingNode,
            },
            parameters: {
                "internal.extension": "REPEAT",
                "internal.interpolation": "Closest",
                "internal.projection": "FLAT",
            },
            imageResources: [node.imageResources[resourceSlotIdx]],
        },
        "Color",
    )
}

export type ImageTextureSetNodes = {
    baseColor: WrappedMaterialGraphNode
    metallic: WrappedMaterialGraphNode
    specular: WrappedMaterialGraphNode
    roughness: WrappedMaterialGraphNode
    anisotropic: WrappedMaterialGraphNode
    anisotropicRotation: WrappedMaterialGraphNode
    alpha: WrappedMaterialGraphNode
    normal: WrappedMaterialGraphNode
    displacement: WrappedMaterialGraphNode
}

export const textureSetMapAssignmentParameterKey = (textureType: TextureType) => `MapAssignment_${textureType.toString()}_ImageResourceSlot`
export const SET_TEXTURE_MAP_ASSIGNMENT_PARAMETER_KEY = "MapAssignment_ImageResourceSlot"

export function createSetImageTextureNode(node: MaterialGraphNode): WrappedMaterialGraphNode {
    const node_ = assertNodeOfType<"ShaderNodeSetTexture">(node, "ShaderNodeSetTexture")

    const widthCm = node_.parameters?.WidthCm as number | undefined
    const heightCm = node_.parameters?.HeightCm as number | undefined
    const slotIndex = node_.parameters?.[SET_TEXTURE_MAP_ASSIGNMENT_PARAMETER_KEY] as number | undefined
    if (!widthCm || !heightCm || typeof slotIndex !== "number") return createConstantValue(0.0)

    const inUV = node_.inputs?.UV
    const mapping = createMappingNode(inUV, [widthCm ?? 1, heightCm ?? 1, 1])
    return createTexImageNodeForNodeImageResourceSlot(node_, slotIndex, mapping)
}

// this is the original material template transformation for all the texture maps in code (including the initial mapping node)
export function createImageTextureSetNodes(node: MaterialGraphNode): ImageTextureSetNodes {
    if (node.nodeType !== "ShaderNodeTextureSet") {
        throw new Error("Expected ShaderNodeTextureSet node")
    }

    const createDefaultBaseColor = () => createConstantColor([0.5, 0.5, 0.5])
    const createDefaultMetallic = () => createConstantValue(0)
    const createDefaultSpecular = () => createConstantValue(0)
    const createDefaultRoughness = () => createConstantValue(1)
    const createDefaultAnisotropic = () => createConstantValue(0)
    const createDefaultAnisotropicRotation = () => createConstantValue(0)
    const createDefaultAlpha = () => createConstantValue(1)
    const createDefaultNormal = () =>
        wrapNodeOutput(
            {
                nodeType: "ShaderNodeNormalMap",
                inputs: {
                    Color: createConstantColor([0.5, 0.5, 1]),
                },
                parameters: {
                    Strength: 1,
                    attribute: "UVMap0",
                },
            },
            "Normal",
        )
    const createDefaultDisplacement = () => createConstantValue(0)
    const textureSetRevisionId = node.parameters?.TextureSetRevisionId as string | undefined
    const widthCm = node.parameters?.WidthCm as number | undefined
    const heightCm = node.parameters?.HeightCm as number | undefined
    const displacementCm = node.parameters?.DisplacementCm as number | undefined

    if (!textureSetRevisionId || !widthCm || !heightCm) {
        return {
            baseColor: createDefaultBaseColor(),
            metallic: createDefaultMetallic(),
            specular: createDefaultSpecular(),
            roughness: createDefaultRoughness(),
            anisotropic: createDefaultAnisotropic(),
            anisotropicRotation: createDefaultAnisotropicRotation(),
            alpha: createDefaultAlpha(),
            normal: createDefaultNormal(),
            displacement: createDefaultDisplacement(),
        }
    }

    const inUV = node.inputs?.UV
    const mapping = createMappingNode(inUV, [widthCm, heightCm, 1])
    const inNormalStrength = node.inputs?.NormalStrength ?? createConstantValue(node.parameters?.NormalStrength ?? 1)

    const createTexImageForTextureType = (textureType: TextureType) => {
        const slotIndex = node.parameters?.[textureSetMapAssignmentParameterKey(textureType)] as number | undefined
        return typeof slotIndex === "number" ? createTexImageNodeForNodeImageResourceSlot(node, slotIndex, mapping) : undefined
    }

    const createAnisotropicFromAnisotrophy = (colorMassAnisotrophy: WrappedMaterialGraphNode | undefined) => {
        if (!colorMassAnisotrophy) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeVectorMath",
                inputs: {
                    Vector: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeVectorMath",
                            inputs: {
                                Vector: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeVectorMath",
                                        inputs: {
                                            Vector: colorMassAnisotrophy,
                                        },
                                        parameters: {
                                            "internal.operation": "ADD",
                                            Vector_001: [-0.5, -0.5, 0],
                                        },
                                    },
                                    "Vector",
                                ),
                            },
                            parameters: {
                                "internal.operation": "MULTIPLY",
                                Vector_001: [2, -2, 0],
                            },
                        },
                        "Vector",
                    ),
                },
                parameters: {
                    "internal.operation": "LENGTH",
                },
            },
            "Value",
        )
    }
    const createAnisotropicRotationFromAnisotrophy = (colorMassAnisotrophy: WrappedMaterialGraphNode | undefined) => {
        if (!colorMassAnisotrophy) {
            return undefined
        }
        const xyz: UnwrappedMaterialGraphNode = {
            nodeType: "ShaderNodeSeparateXYZ",
            inputs: {
                Vector: wrapNodeOutput(
                    {
                        nodeType: "ShaderNodeVectorMath",
                        inputs: {
                            Vector: wrapNodeOutput(
                                {
                                    nodeType: "ShaderNodeVectorMath",
                                    inputs: {
                                        Vector: colorMassAnisotrophy,
                                    },
                                    parameters: {
                                        "internal.operation": "ADD",
                                        Vector_001: [-0.5, -0.5, 0],
                                    },
                                },
                                "Vector",
                            ),
                        },
                        parameters: {
                            "internal.operation": "MULTIPLY",
                            Vector_001: [2, -2, 0],
                        },
                    },
                    "Vector",
                ),
            },
        }
        const angle = wrapNodeOutput(
            {
                nodeType: "ShaderNodeMath",
                inputs: {
                    Value: wrapNodeOutput(xyz, "Y"),
                    Value_001: wrapNodeOutput(xyz, "X"),
                },
                parameters: {
                    "internal.operation": "ARCTAN2",
                },
            },
            "Value",
        )
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeMath",
                inputs: {
                    Value: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeMath",
                            inputs: {
                                Value: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeMath",
                                        inputs: {
                                            Value: wrapNodeOutput(
                                                {
                                                    nodeType: "ShaderNodeMath",
                                                    inputs: {
                                                        Value: angle,
                                                    },
                                                    parameters: {
                                                        "internal.operation": "GREATER_THAN",
                                                        Value_001: 0,
                                                    },
                                                },
                                                "Value",
                                            ),
                                            Value_001: angle,
                                        },
                                        parameters: {
                                            "internal.operation": "MULTIPLY",
                                        },
                                    },
                                    "Value",
                                ),
                                Value_001: wrapNodeOutput(
                                    {
                                        nodeType: "ShaderNodeMath",
                                        inputs: {
                                            Value: wrapNodeOutput(
                                                {
                                                    nodeType: "ShaderNodeMath",
                                                    inputs: {
                                                        Value: angle,
                                                    },
                                                    parameters: {
                                                        "internal.operation": "LESS_THAN",
                                                        Value_001: 0,
                                                    },
                                                },
                                                "Value",
                                            ),
                                            Value_001: wrapNodeOutput(
                                                {
                                                    nodeType: "ShaderNodeMath",
                                                    inputs: {
                                                        Value: angle,
                                                    },
                                                    parameters: {
                                                        "internal.operation": "ADD",
                                                        Value_001: Math.PI * 2,
                                                    },
                                                },
                                                "Value",
                                            ),
                                        },
                                        parameters: {
                                            "internal.operation": "MULTIPLY",
                                        },
                                    },
                                    "Value",
                                ),
                            },
                            parameters: {
                                "internal.operation": "ADD",
                            },
                        },
                        "Value",
                    ),
                },
                parameters: {
                    "internal.operation": "DIVIDE",
                    Value_001: Math.PI * 2,
                },
            },
            "Value",
        )
    }
    const convertNormal = (normalMap: WrappedMaterialGraphNode | undefined, normalRotation: number) => {
        if (!normalMap) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeNormalMap",
                inputs: {
                    Color: rotateNormalMapVector(
                        wrapNodeOutput(
                            {
                                nodeType: "ShaderNodeRGBCurve",
                                inputs: {
                                    Color: normalMap,
                                },
                                parameters: {
                                    Fac: 1,
                                    // R
                                    "internal.mapping.curves[0].points[0].location": [0, 0],
                                    "internal.mapping.curves[0].points[1].location": [1, 1],
                                    // G (inverted)
                                    "internal.mapping.curves[1].points[0].location": [0, 1],
                                    "internal.mapping.curves[1].points[1].location": [1, 0],
                                    // B
                                    "internal.mapping.curves[2].points[0].location": [0, 0],
                                    "internal.mapping.curves[2].points[1].location": [1, 1],
                                    // RGB
                                    "internal.mapping.curves[3].points[0].location": [0, 0],
                                    "internal.mapping.curves[3].points[1].location": [1, 1],
                                },
                            },
                            "Color",
                        ),
                        normalRotation,
                    ),
                    Strength: inNormalStrength,
                },
            },
            "Normal",
        )
    }
    const convertDisplacement = (displacementMap: WrappedMaterialGraphNode | undefined) => {
        if (!displacementMap) {
            return undefined
        }

        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeDisplacement",
                inputs: {
                    Height: displacementMap,
                    Scale: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeMath",
                            inputs: {
                                Value: inNormalStrength,
                            },
                            parameters: {
                                "internal.operation": "MULTIPLY",
                                Value_001: displacementCm ?? 0,
                            },
                        },
                        "Value",
                    ),
                },
                parameters: {
                    MidLevel: 0.5,
                },
            },
            "Displacement",
        )
    }
    const createMetallicFromF0 = (colormassF0: WrappedMaterialGraphNode | undefined) => {
        if (!colormassF0) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeSeparateRGB",
                inputs: {
                    Image: colormassF0,
                },
            },
            "G",
        )
    }
    const createSpecularFromF0 = (colormassF0: WrappedMaterialGraphNode | undefined) => {
        if (!colormassF0) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeSeparateRGB",
                inputs: {
                    Image: colormassF0,
                },
            },
            "R",
        )
    }
    const createAnisotropicFromAnisotrophyStrength = (colorMassAnisotrophyStrength: WrappedMaterialGraphNode | undefined) => {
        if (!colorMassAnisotrophyStrength) {
            return undefined
        }
        return wrapNodeOutput(
            {
                nodeType: "ShaderNodeMath",
                inputs: {
                    Value: wrapNodeOutput(
                        {
                            nodeType: "ShaderNodeMath",
                            inputs: {
                                Value: colorMassAnisotrophyStrength,
                            },
                            parameters: {
                                "internal.operation": "ADD",
                                Value_001: -0.5,
                            },
                        },
                        "Value",
                    ),
                },
                parameters: {
                    "internal.operation": "MULTIPLY",
                    Value_001: 2,
                },
            },
            "Value",
        )
    }

    // current set
    const sourceMapDiffuse = createTexImageForTextureType(TextureType.Diffuse)
    const sourceMapMetalness = createTexImageForTextureType(TextureType.Metalness)
    const sourceMapSpecularStrength = createTexImageForTextureType(TextureType.SpecularStrength)
    const sourceMapRoughness = createTexImageForTextureType(TextureType.Roughness)
    const sourceMapAnisotrophy = createTexImageForTextureType(TextureType.Anisotropy)
    const sourceMapNormal = createTexImageForTextureType(TextureType.Normal)
    const sourceMapDisplacement = createTexImageForTextureType(TextureType.Displacement)
    // legacy set
    const sourceMapF0 = createTexImageForTextureType(TextureType.F0)
    const sourceMapAnisotrophyStrength = createTexImageForTextureType(TextureType.AnisotropyStrength)
    const sourceMapAnisotrophyRotation = createTexImageForTextureType(TextureType.AnisotropyRotation)
    // special maps
    const sourceMapMask = createTexImageForTextureType(TextureType.Mask)

    let normalRotation = 0
    if (node.parameters && typeof node.parameters["internal.normal_rotation"] === "number") {
        normalRotation = node.parameters["internal.normal_rotation"]
    }

    // create the nodes
    const baseColor = sourceMapDiffuse ?? createDefaultBaseColor()
    const metallic = sourceMapMetalness ?? createMetallicFromF0(sourceMapF0) ?? createDefaultMetallic()
    const specular = sourceMapSpecularStrength ?? createSpecularFromF0(sourceMapF0) ?? createDefaultSpecular()
    const roughness = sourceMapRoughness ?? createDefaultRoughness()
    const anisotropic =
        createAnisotropicFromAnisotrophy(sourceMapAnisotrophy) ??
        createAnisotropicFromAnisotrophyStrength(sourceMapAnisotrophyStrength) ??
        createDefaultAnisotropic()
    const anisotropicRotation =
        createAnisotropicRotationFromAnisotrophy(sourceMapAnisotrophy) ?? sourceMapAnisotrophyRotation ?? createDefaultAnisotropicRotation()
    const alpha = sourceMapMask ?? createDefaultAlpha()
    const normal = convertNormal(sourceMapNormal, normalRotation) ?? createDefaultNormal()
    const displacement = convertDisplacement(sourceMapDisplacement) ?? createDefaultDisplacement()

    return {
        baseColor,
        metallic,
        specular,
        roughness,
        anisotropic,
        anisotropicRotation,
        alpha,
        normal,
        displacement,
    }
}
