import {Component, input, output} from "@angular/core"
import {ButtonComponent} from "@common/components/buttons/button/button.component"

export type TemplateViewType = "edit" | "image" | "configurator"

@Component({
    selector: "cm-template-view-type-selector",
    standalone: true,
    templateUrl: "./template-view-type-selector.component.html",
    styleUrl: "./template-view-type-selector.component.scss",
    imports: [ButtonComponent],
})
export class TemplateViewTypeSelectorComponent {
    viewType = input.required<TemplateViewType>()

    viewTypeChanged = output<TemplateViewType>()
}
