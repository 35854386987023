import {ImageProcessingNodes, ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageImgProc, ImagePtrImgProc} from "app/textures/texture-editor/operator-stack/image-op-system/image-imgproc"
import {ChannelLayout, ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {deepEqual} from "@cm/lib/utils/utils"
import {ImageRefId} from "app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref-base"
import {ImagePtr, ImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {SmartPtr} from "app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {JobNodes} from "@cm/lib/job-task/job-nodes"

export class ImageOpContextImgProc {
    constructor(
        readonly texturesApi: TexturesApiService,
        readonly drawableImageCache: DrawableImageCache,
    ) {}

    // ImageOpContext
    dispose(): void {}

    // ImageOpContext
    preEvaluation(): void {
        // make sure we don't evaluate multiple times in parallel as the temporary resources are shared
        if (this.currentlyProcessing) {
            throw new Error("ImageOpEvaluator is already processing")
        }
        this.currentlyProcessing = true
    }

    // ImageOpContext
    postEvaluation(): void {
        this.currentlyProcessing = false
    }

    private async releaseImage(imageRef: ImageRef): Promise<void> {
        if (!this.temporaryImageByImageRefId.has(imageRef.id)) {
            throw new Error(`Image ${imageRef.id} not found`)
        }
        this.temporaryImageByImageRefId.delete(imageRef.id)
    }

    private releaseDataObjectImage(imageRefId: ImageRefId): void {
        if (!this.dataObjectImageByImageRefId.has(imageRefId)) {
            throw new Error(`Image ${imageRefId} not found`)
        }
        this.dataObjectImageByImageRefId.delete(imageRefId)
    }

    async prepareResultImage(resultImage: ImagePtr | undefined, descriptor: ImageDescriptor, node?: ImageProcessingNodes.ImageNode): Promise<ImagePtr> {
        if (resultImage) {
            return new SmartPtr(resultImage)
        } else {
            return this.createImage(descriptor, node)
        }
    }

    async createImage(descriptor: ImageDescriptor, node?: ImageProcessingNodes.ImageNode): Promise<ImagePtr> {
        node ??= this.createImageNode(descriptor)
        const image = new ImageImgProc("temporary", this.nextImageRefId++, undefined, node, descriptor)
        const imagePtr = new SmartPtr(new ImageRef("temporary", image.id, (image) => this.releaseImage(image), "ImageOpContextImgProc.createImage"))
        this.temporaryImageByImageRefId.set(imagePtr.ref.id, image)
        return imagePtr
    }

    async getImage(imagePtr: ImagePtr): Promise<ImagePtrImgProc> {
        switch (imagePtr.ref.addressSpace) {
            case "temporary":
                return new SmartPtr(await this.getTemporaryImage(imagePtr.ref.id))
            case "drawable":
                return new SmartPtr(await this.getDrawableImage(imagePtr.ref.id))
            case "data-object":
                if (typeof imagePtr.ref.id !== "string") {
                    throw new Error(`Data object image ref id must be a string, but got ${imagePtr.ref.id}`)
                }
                return new SmartPtr(await this.getDataObjectImage(imagePtr.ref.id))
        }
    }

    async getImageDescriptor(ref: ImagePtr): Promise<ImageDescriptor> {
        using image = await this.getImage(ref)
        return image.ref.descriptor
    }

    async createDataObjectImageRef(dataObjectId: string): Promise<ImagePtr> {
        const dataObjectImageDescriptor = await this.texturesApi.getDataObjectImageDescriptor(dataObjectId)
        const node = Nodes.decode(Nodes.externalData(JobNodes.dataObjectReference(dataObjectImageDescriptor.legacyId), "encodedData"))
        const descriptor: ImageDescriptor = {
            width: dataObjectImageDescriptor.width,
            height: dataObjectImageDescriptor.height,
            channelLayout: "RGBA", // TODO this is technically not always correct, but is it a problem in this case ?
            format: "float32",
            isSRGB: false, //
        }
        const image = new ImageImgProc("data-object", dataObjectId, undefined, node, descriptor)
        const imageRef = new ImageRef(
            "data-object",
            dataObjectId,
            (_image) => this.releaseDataObjectImage(dataObjectId),
            "ImageOpContextImgProc.createDataObjectImageRef",
        )
        this.dataObjectImageByImageRefId.set(dataObjectId, image)
        return new ImagePtr(imageRef)
    }

    async createDrawableImage(drawableImageRef: DrawableImageRef, descriptor: ImageDescriptor): Promise<ImagePtr> {
        const imagePtr = new ImagePtr(new ImageRef("drawable", this.nextImageRefId++, undefined, "ImageOpContextImgProc.makeDrawableImageRef"))
        const newInfo: DrawableImageInfo = {imagePtr, drawableImageRef, descriptor}
        this.drawableImageInfoByImageRefId.set(imagePtr.ref.id, newInfo)
        return newInfo.imagePtr
    }

    private getDrawableImageInfo(imageRefId: ImageRefId): DrawableImageInfo {
        const info = this.drawableImageInfoByImageRefId.get(imageRefId)
        if (!info) {
            throw new Error(`Drawable image ${imageRefId} not found`)
        }
        return info
    }

    private async getTemporaryImage(id: ImageRefId): Promise<ImageImgProc> {
        const image = this.temporaryImageByImageRefId.get(id)
        if (!image) {
            throw new Error(`Image ${id} not found`)
        }
        return image
    }

    private async getDrawableImage(imageRefId: ImageRefId): Promise<ImageImgProc> {
        const info = this.getDrawableImageInfo(imageRefId)
        if (!this.drawableImageCache.hasDrawableImage(info.drawableImageRef)) {
            throw new Error(`Drawable image ${info.drawableImageRef} not found`)
        }
        const image = await this.drawableImageCache.getImgProcImageByRef(info.drawableImageRef)
        if (!deepEqual(image.descriptor, info.descriptor)) {
            throw new Error(`Drawable image ${info.drawableImageRef} has wrong descriptor`)
        }
        return image
    }

    private async getDataObjectImage(dataObjectId: string): Promise<ImageImgProc> {
        const image = this.dataObjectImageByImageRefId.get(dataObjectId)
        if (!image) {
            throw new Error(`Image ${dataObjectId} not found`)
        }
        return image
    }

    private createImageNode(descriptor: ImageDescriptor): ImageProcessingNodes.ImageNode {
        const getColor = (channels: ChannelLayout): number | Nodes.RGBColor | Nodes.RGBAColor => {
            switch (channels) {
                case "RGBA":
                    return [0, 0, 0, 1]
                case "RGB":
                    return [0, 0, 0]
                case "R":
                    return 0
                default:
                    throw new Error(`Unsupported channel layout: ${channels}`)
            }
        }
        const color = getColor(descriptor.channelLayout)
        const node = Nodes.createImage(
            descriptor.width,
            descriptor.height,
            "float32",
            "linear", // TODO which color space?
            color,
        )
        return node
    }

    private temporaryImageByImageRefId = new Map<ImageRefId, ImageImgProc>()
    private drawableImageInfoByImageRefId = new Map<ImageRefId, DrawableImageInfo>()
    private dataObjectImageByImageRefId = new Map<ImageRefId, ImageImgProc>()
    private currentlyProcessing = false
    private nextImageRefId = 1
}

type DrawableImageInfo = {imagePtr: ImagePtr; drawableImageRef: DrawableImageRef; descriptor: ImageDescriptor}
