// These values are getting mapped to the Django ContentTypes by the API. Keep the ContentTypeEntityTypeMap in the back end consistent with these numbers.
export enum EntityType {
    Model = 1,
    Material = 3,
    Customer = 4,
    PictureRevision = 5,
    Picture = 6,
    Set = 7,
    Project = 8,
    DataObject = 9,
    ShadedModel2 = 10,
    User = 11,
    ScanJob = 12,
    ScanSubJob = 13,
    DataObjectAssignment = 14,
    MaterialRevision = 15,
    Texture = 16,
    TextureRevision = 17,
    Scene = 18,
    SceneRevision = 19,
    Light = 21,
    TextureGroup = 22,
    TextureSet = 23,
    Template = 24,
    TemplateRevision = 25,
    RenderJob = 38,
    Hdri = 39,
    MaterialAssignment2 = 43,
    PictureShadedModel2Assignment = 44,
    Tag = 47,
    Task = 48,
    TaskComment = 49,
    TaskPin = 50,
    UserAssignment = 51,
    UserObjectPermission = 52,
    MaterialNode = 53,
    MaterialConnection = 54,
    TemplateReference = 58,
    MaterialReference = 59,
    TextureReference = 60,
    StorageDeleteTask = 61,
    AuthorizedDomain = 62,
    HdriReference = 63,
    TagAssignment = 64,
}
