import {Injectable, OnDestroy, signal} from "@angular/core"
import {CachedNodeGraphResult} from "@cm/lib/graph-system/evaluators/cached-node-graph-result"
import {LegacyMaterialConverter} from "@cm/lib/materials/legacy-material-converter"
import {Context} from "@cm/lib/materials/types"
import {IMaterialData, keyForMaterialData} from "@cm/lib/templates/interfaces/material-data"
import {TextureResolution} from "@cm/lib/templates/nodes/scene-properties"
import {AsyncReentrancyGuard} from "@cm/lib/utils/async-reentrancy-guard"
import {Observable, Subject, defer, from} from "rxjs"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

const colorPalette: number[] = [
    0xa8e6ce, 0xdcedc2, 0xffd3b5, 0xff8c94, 0xe5fcc2, 0x9de0ad, 0x45ada8, 0x547980, 0x594f4f, 0xf67280, 0xc06c84, 0x6c5b7b, 0x355c7d, 0xe8175d, 0xf7db4f,
    0xf0e4e4, 0xfad9c1, 0x009688, 0x7bc043, 0x0392cf,
]

@Injectable()
export class ThreeMaterialManagerService implements OnDestroy {
    private asyncPromiseSerializerByMaterial = new Map<string, AsyncReentrancyGuard.PromiseSerializer>()
    private keyToMaterial = new Map<string, THREE.Material>()
    private materialRefCounts = new Map<
        THREE.Material,
        {key: string; refCount: number; referencedTextureNodes: (THREE.Texture | THREENodes.TextureNode)[]; defaultMaterial: boolean}
    >()

    progressiveTextureLoading = signal(true)
    private requestedRedraw = new Subject<void>()
    private defaultTextureResolution: TextureResolution = "2000px"

    materialModifier: ((material: THREE.Material) => void) | undefined = undefined
    requestedRedraw$ = this.requestedRedraw.asObservable()

    private tryToAcquireFromCache = (key: string) => {
        const cachedMaterial = this.keyToMaterial.get(key)
        if (cachedMaterial !== undefined) {
            const refCount = this.materialRefCounts.get(cachedMaterial)
            if (refCount === undefined) throw new Error("Material is in cache but not in refCounts")
            refCount.refCount++
            return cachedMaterial
        }

        return null
    }

    acquireMaterial(materialData: IMaterialData, materialIndex: number): [THREE.Material, Observable<THREE.Material> | null] {
        const key = keyForMaterialData(materialData)

        const cachedMaterial = this.tryToAcquireFromCache(key)
        if (cachedMaterial) return [cachedMaterial, null]

        const generateMaterial = async () => {
            let asyncPromiseSerializer = this.asyncPromiseSerializerByMaterial.get(key)
            if (asyncPromiseSerializer === undefined) {
                asyncPromiseSerializer = new AsyncReentrancyGuard.PromiseSerializer()
                this.asyncPromiseSerializerByMaterial.set(key, asyncPromiseSerializer)
            }

            return asyncPromiseSerializer.executeSequentially(async () => {
                const cachedMaterial = this.tryToAcquireFromCache(key)
                if (cachedMaterial) return cachedMaterial

                const materialConverter = new LegacyMaterialConverter()
                const materialGraph = materialConverter.convertMaterialGraph(materialData.materialGraph)

                const referencedTextureNodes: (THREE.Texture | THREENodes.TextureNode)[] = []
                const context: Context = {
                    type: "three",
                    textureResolution: materialData.realtimeSettings?.textureResolution ?? this.defaultTextureResolution,
                    onThreeCreatedTexture: (textureNode) => referencedTextureNodes.push(textureNode),
                    onRequestRedraw: () => this.requestedRedraw.next(),
                    progressiveTextureLoading: this.progressiveTextureLoading(),
                    forceFiltering: materialData.realtimeSettings?.textureFiltering,
                }

                const result = new CachedNodeGraphResult(materialGraph, context)
                const {surface: material} = await result.run()
                if (!(material instanceof THREE.Material)) throw new Error("Material is not a THREE.Material")

                this.setupMaterial(material, materialData, key, referencedTextureNodes, false)

                return material
            })
        }

        return [this.getDefaultMaterial(materialData, materialIndex), materialData.realtimeSettings?.disable ? null : defer(() => from(generateMaterial()))]
    }

    getDefaultMaterial(materialData: IMaterialData | undefined, materialIndex: number): THREE.Material {
        const usedMaterialData = materialData ?? {side: "front", alphaMaskThreshold: 0}
        const key = `default-${materialIndex},${usedMaterialData.side},${usedMaterialData.alphaMaskThreshold}`

        const cachedMaterial = this.tryToAcquireFromCache(key)
        if (cachedMaterial) return cachedMaterial

        const material = getDefaultMaterial(materialIndex)

        this.setupMaterial(material, usedMaterialData, key, [], true)
        return material
    }

    private setupMaterial(
        material: THREE.Material,
        materialData: Pick<IMaterialData, "side" | "alphaMaskThreshold">,
        key: string,
        referencedTextureNodes: (THREE.Texture | THREENodes.TextureNode)[],
        defaultMaterial: boolean,
    ) {
        switch (materialData.side) {
            case "front":
                material.side = THREE.FrontSide
                break
            case "back":
                material.side = THREE.BackSide
                break
            case "double":
                material.side = THREE.DoubleSide
                break
            default:
                material.side = THREE.FrontSide
                break
        }
        material.alphaTest = materialData.alphaMaskThreshold ?? 0
        this.materialModifier?.(material)

        this.keyToMaterial.set(key, material)
        this.materialRefCounts.set(material, {key, refCount: 1, referencedTextureNodes, defaultMaterial})
    }

    releaseMaterial(material: THREE.Material | THREE.Material[]) {
        if (Array.isArray(material)) for (const m of material) this.releaseMaterial(m)
        else {
            const refCount = this.materialRefCounts.get(material)
            if (refCount !== undefined) {
                refCount.refCount--
                if (refCount.refCount <= 0) {
                    this.keyToMaterial.delete(refCount.key)
                    this.materialRefCounts.delete(material)
                    for (const textureNode of refCount.referencedTextureNodes) {
                        if (textureNode instanceof THREE.Texture) textureNode.dispose()
                        else textureNode.value.dispose()
                    }
                    material.dispose()
                    console.info("Material disposed", refCount.key)
                }
            } else material.dispose()
        }
    }

    ngOnDestroy() {
        this.clearTextures()
    }

    private clearTextures() {
        for (const [material, cacheData] of this.materialRefCounts.entries()) {
            for (const textureNode of cacheData.referencedTextureNodes) {
                if (textureNode instanceof THREE.Texture) textureNode.dispose()
                else textureNode.value.dispose()
            }
            material.dispose()
            console.info("Material disposed", material)
        }

        this.materialRefCounts.clear()
        this.keyToMaterial.clear()
    }

    updateMaterials(updateFunction: (material: THREE.Material) => void) {
        for (const [key, material] of this.keyToMaterial.entries()) updateFunction(material)
    }
}

export const getDefaultMaterial = (materialIndex: number) => {
    const usedMaterialIndex = materialIndex % colorPalette.length

    const material = new THREE.MeshStandardMaterial({
        color: colorPalette[usedMaterialIndex],
        roughness: 0.8,
        metalness: 0.0,
    })

    return material
}
