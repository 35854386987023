import {computed, inject, Injectable, Signal, signal} from "@angular/core"
import {toObservable} from "@angular/core/rxjs-interop"
import {BasicTagAssignmentFragment, BasicTagInfoFragment, ContentTypeModel, FullTagInfoFragment, TagType} from "@api"
import {IsNonNull} from "@cm/lib/utils/filter"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"

import {SdkService} from "@common/services/sdk/sdk.service"
import {filter, firstValueFrom, Observable} from "rxjs"

@Injectable({
    providedIn: "root",
})
export class TagsService {
    sdk = inject(SdkService)
    auth = inject(AuthService)
    organizations = inject(OrganizationsService)

    all$: Observable<BasicTagInfoFragment[]>
    all: Promise<BasicTagInfoFragment[]>
    $all = signal<BasicTagInfoFragment[]>([])
    $loaded = signal(false)
    $index = computed(() => new Map(this.$all().map((tag) => [tag.id, tag])))
    $legacyIndex = computed(() => new Map(this.$all().map((tag) => [tag.legacyId, tag])))
    $templateInputsOutputs = computed(() => this.$all())
    $visible = computed(() => this.$all().filter((tag) => this.auth.isStaff || this.organizations.userIsMemberOf(tag.organization?.id)))

    $materialColor = computed(() => this.$all().filter((tag) => tag.type === TagType.MaterialColor))
    $materialRange = computed(() => this.$all().filter((tag) => tag.type === TagType.MaterialRange))
    $production = computed(() => this.$all().filter((tag) => tag.type === TagType.Production))
    $productRange = computed(() => this.$all().filter((tag) => tag.type === TagType.ProductRange))

    byLegacyId(id: number): BasicTagInfoFragment | null {
        if (!id) {
            return null
        }
        return this.$legacyIndex().get(id) ?? null
    }

    byId(id: string | null | undefined): BasicTagInfoFragment | null {
        if (!id) {
            return null
        }
        return this.$index().get(id) ?? null
    }

    visibleAmong(tags: BasicTagInfoFragment[]) {
        const allVisible = new Set(this.$visible().map((tag) => tag.id))
        return tags.filter((tag) => allVisible.has(tag.id))
    }

    typeLabel(tag: BasicTagInfoFragment): string {
        switch (tag.type) {
            case TagType.MaterialRange:
                return "Material Range"
            case TagType.MaterialColor:
                return "Material Color"
            case TagType.ProductRange:
                return "Product Range"
            case TagType.Production:
                return "Production"
            case TagType.PdfSpecItem:
                return "PDF Spec Item"
        }
    }

    constructor() {
        this.all$ = toObservable(this.$all).pipe(filter((tags) => !!tags.length))
        this.all = firstValueFrom(this.all$)
        this.auth.user$.subscribe((user) => {
            if (user) {
                this.load()
            } else {
                this.$all.set([])
                this.$loaded.set(true)
            }
        })
    }

    load() {
        this.sdk.gql.allTagsBasicInfo().then(({tags}) => {
            this.$all.set(tags.filter(IsNonNull).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
            this.$loaded.set(true)
        })
    }

    async isAssignedToItem(itemId: string, contentTypeModel: ContentTypeModel, tagId: string): Promise<boolean> {
        return this.sdk.gql
            .hasTagAssignments({
                filter: {
                    objectId: itemId,
                    contentTypeModel,
                    tagId: {equals: tagId},
                },
            })
            .then(({tagAssignmentsCount}) => {
                return !!tagAssignmentsCount
            })
    }

    async isAssignedToLegacyItem(
        item:
            | {
                  id: number
                  contentTypeModel: ContentTypeModel
              }
            | undefined,
        tagId: string,
    ): Promise<boolean> {
        return item
            ? this.sdk.gql
                  .hasTagAssignments({
                      filter: {
                          objectLegacyId: item.id,
                          contentTypeModel: item.contentTypeModel,
                          tagId: {equals: tagId},
                      },
                  })
                  .then(({tagAssignmentsCount}) => {
                      return !!tagAssignmentsCount
                  })
            : false
    }

    async find({ids, legacyIds, types}: {ids?: string[]; legacyIds?: number[]; types: TagType[]}): Promise<BasicTagInfoFragment | null> {
        return this.$all().find((tag) => types.includes(tag.type) && (ids?.includes(tag.id) || legacyIds?.includes(tag.legacyId))) ?? null
    }

    async fullInfo(id: string) {
        return this.sdk.gql.tagFullInfo({id}).then(({tag}) => tag)
    }

    async forItem(id: string, contentTypeModel: ContentTypeModel, type?: TagType): Promise<BasicTagInfoFragment[]> {
        const {tagAssignments} = await this.sdk.gql.getTagAssignments({filter: {objectId: id, contentTypeModel}})
        const tags = tagAssignments
            .map((tagAssignment) => this.byId(tagAssignment?.tag?.id))
            .filter((tag) => {
                if (type) {
                    return tag?.type === type
                } else {
                    return true
                }
            })
        return tags.filter(IsNonNull)
    }

    async assignments(id: string, contentTypeModel: ContentTypeModel, type?: TagType): Promise<BasicTagAssignmentFragment[]> {
        const {tagAssignments} = await this.sdk.gql.getTagAssignments({filter: {objectId: id, contentTypeModel}})
        const tags = tagAssignments.filter((tagAssignment) => {
            if (type) {
                return tagAssignment?.tag?.type === type
            } else {
                return true
            }
        })
        return tags.filter(IsNonNull)
    }

    async forLegacyItem(id: number, contentTypeModel: ContentTypeModel, type?: TagType): Promise<FullTagInfoFragment[]> {
        const {tagAssignments} = await this.sdk.gql.getTagAssignments({filter: {objectLegacyId: id, contentTypeModel}})
        const tags = tagAssignments
            .map((tagAssignment) => this.byId(tagAssignment?.tag?.id))
            .filter((tag) => {
                if (type) {
                    return tag?.type === type
                } else {
                    return true
                }
            })
        return tags.filter(IsNonNull)
    }

    async toggle(id: string, contentTypeModel: ContentTypeModel, tagId: string, value?: boolean): Promise<void> {
        const currentValue = value ?? (await this.isAssignedToItem(id, contentTypeModel, tagId))
        if (currentValue) {
            await this.unassign(id, contentTypeModel, tagId)
        } else {
            await this.assign(id, contentTypeModel, tagId)
        }
    }

    async assign(id: string, contentTypeModel: ContentTypeModel, tagId: string) {
        return this.sdk.gql.assignTag({objectId: id, contentTypeModel, tagId})
    }

    async unassign(id: string, contentTypeModel: ContentTypeModel, tagId: string) {
        const {tagAssignments} = await this.sdk.gql.getTagAssignments({
            filter: {
                objectId: id,
                contentTypeModel,
                tagId: {equals: tagId},
            },
        })
        for (const tagAssignment of tagAssignments.filter(IsNonNull)) {
            await this.sdk.gql.unassignTag({tagAssignmentId: tagAssignment.id})
        }
    }

    async unassignLegacy(id: number, contentTypeModel: ContentTypeModel, tagId: string) {
        const {tagAssignments} = await this.sdk.gql.getTagAssignments({
            filter: {
                objectLegacyId: id,
                contentTypeModel,
                tagId: {equals: tagId},
            },
        })
        for (const tagAssignment of tagAssignments.filter(IsNonNull)) {
            await this.sdk.gql.unassignTag({tagAssignmentId: tagAssignment.id})
        }
    }

    $rangeTags({organizationId, assignedTo}: {organizationId: string; assignedTo?: {id: number; contentTypeModel: ContentTypeModel}}) {
        return computed(() =>
            this.$materialRange().filter((tag) => {
                if (assignedTo) {
                    if (!this.isAssignedToLegacyItem(assignedTo, tag.id)) {
                        return false
                    }
                }
                return tag.organization?.id === organizationId
            }),
        )
    }
    $productionTags({organizationId, assignedTo}: {organizationId: string; assignedTo?: {id: number; contentTypeModel: ContentTypeModel}}) {
        return computed(() =>
            this.$production().filter((tag) => {
                if (assignedTo) {
                    if (!this.isAssignedToLegacyItem(assignedTo, tag.id)) {
                        return false
                    }
                }
                return tag.organization?.id === organizationId
            }),
        )
    }

    $filterOptions = ({
        organizationId,
        type,
    }: {
        assignedTo?: {id: number; contentTypeModel: ContentTypeModel}
        organizationId?: string
        type: TagType
    }): Signal<{all: BasicTagInfoFragment[]; selected: BasicTagInfoFragment[]} | undefined> => {
        return computed(() => {
            if (this.$loaded()) {
                const all = this.$all().filter((tag) => {
                    if (type && tag?.type !== type) {
                        return false
                    }
                    if (organizationId && organizationId !== tag?.organization?.id) {
                        return false
                    }
                    return true
                })
                return {
                    all,
                    selected: [],
                }
            } else {
                return undefined
            }
        })
    }
}
