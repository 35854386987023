import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-hint",
    templateUrl: "./hint.component.html",
    styleUrls: ["./hint.component.scss"],
    standalone: true,
})
export class HintComponent {
    @Input() hintType: number = HintTypes.Info
    hintTypes = HintTypes
}

export enum HintTypes {
    Info = 0,
    Warning = 10,
}
