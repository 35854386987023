// @ts-strict-ignore
import {Injectable} from "@angular/core"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {MoveMode, TemplateService} from "app/templates/template.service"

export type DropPosition = MoveMode

@Injectable({
    providedIn: "root",
})
export class DragAndDropService {
    dragSourceContext: Nodes.Context
    dragSource: Nodes.Node
    dropTargetContext: Nodes.Context
    dropTarget: Nodes.Node
    dropPosition: DropPosition
    dropAllowed: boolean

    constructor(private templateService: TemplateService) {}

    private clear() {
        this.dragSourceContext = null
        this.dragSource = null
        this.dropTargetContext = null
        this.dropTarget = null
    }

    dragStart(event: DragEvent, context: Nodes.Context, node: Nodes.Node): void {
        event.stopPropagation()
        this.dragSourceContext = context
        this.dragSource = node
    }

    dragEnter(event: DragEvent, context: Nodes.Context, node: Nodes.Node): void {
        event.stopPropagation()
        this.dropTargetContext = context
        this.dropTarget = node
        this.dropPosition = "inside"
    }

    // Return false to enable and true to disable drop
    dragOver(event: DragEvent, context: Nodes.Context, node: Nodes.Node, position: DropPosition): boolean {
        event.stopPropagation()
        this.dropPosition = position
        this.dropAllowed = this.templateService.moveAllowed(this.dragSource, context)
        return !this.dropAllowed
    }

    drop(event: DragEvent, context: Nodes.Context, node: Nodes.Node): void {
        event.stopPropagation()
        this.templateService.moveNode(this.dragSource, context, node, this.dropPosition)
        this.clear()
    }

    dragEnd(): void {
        this.clear()
    }

    isDropTarget(context: Nodes.Context, node: Nodes.Node, position?: DropPosition): boolean {
        if (!this.dropAllowed) return false
        if (context !== this.dropTargetContext) return false
        if (node !== this.dropTarget) return false
        if (position != null && position !== this.dropPosition) return false
        return true
    }
}
