<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Config Variant </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'id'" [label]="'ID'" [validate]="isValidExternalId"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Icon </ng-container>
    <ng-container cm-content>
        <div class="cm-config-variant-settings">
            <cm-value-slot [node]="node()" [key]="'iconDataObject'" [label]="'Icon'"></cm-value-slot>
            <cm-value-slot [node]="node()" [key]="'iconColor'" [label]="'Color'"></cm-value-slot>
        </div>
    </ng-container>
</cm-inspector-section>
