import {ChangeDetectionStrategy, Component, Input} from "@angular/core"
import {TaskInfos} from "@api"

@Component({
    selector: "cm-comment-boxes",
    templateUrl: "./comment-boxes.component.html",
    styleUrls: ["./comment-boxes.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class CommentBoxesComponent {
    @Input() entity?: {
        taskInfos: TaskInfos
    }
}
