import {DeclareNodeGraphTS} from "@src/graph-system/node-graph"
import {registerNode} from "@src/graph-system/register-node"
import {ConfigurationGroupNode} from "@src/pricing/nodes/configuration-group-node"
import {GroupId, PricedItem, PricingContext, PricingNodegraph, VariantId} from "@src/pricing/nodes/core"
import {PriceList} from "@src/pricing/nodes/price-list"
import {VariantConditionNode} from "@src/pricing/nodes/variant-condition-node"

export type PriceContainerParameters = {
    description: string //human-readable name, usually derived from the customer's catalog
    uniqueId: string //unique id, which is usually derived from the customer's catalog
    subPrices: PriceList //a list of related prices that depend on the current node
    condition?: VariantConditionNode
}

/**This is a named container for prices. It can be used as structuring element and can be used to enforce e.g. mutually exclusive conditions during verification. Can also be displayed in the ui. */
@registerNode
export class PriceContainer extends DeclareNodeGraphTS<PricedItem[], PricingContext, PriceContainerParameters>(
    {
        run: async ({get, parameters, context}) => {
            if (parameters.condition) {
                const condition = await get(parameters.condition)
                if (!condition) return []
            }

            const subPrices = await get(parameters.subPrices)
            return subPrices.flat()
        },
    },
    {
        nodeClass: "PriceContainer",
    },
) {
    getUniqueId(): string {
        return this.parameters.uniqueId
    }

    getDescription(): string | undefined {
        return this.parameters.description
    }

    getSubprices(): PricingNodegraph[] {
        if (!this.parameters.subPrices) return []
        return this.parameters.subPrices.parameters.list
    }

    addDependency(configGroupNode: ConfigurationGroupNode, variantId: VariantId) {
        if (this.parameters.condition === undefined) throw new Error("Node does not accept conditions")
        this.parameters.condition.addDependency(configGroupNode, variantId)
    }

    removeDependency(variantId: VariantId) {
        if (this.parameters.condition === undefined) throw new Error("Node does not accept conditions")
        this.parameters.condition.removeDependency(variantId)
    }

    canAddDependency(groupId: GroupId, variantId: VariantId) {
        if (this.parameters.condition === undefined) return false
        return this.parameters.condition.canAddDependency(groupId, variantId)
    }

    getVariantIds(): VariantId[] {
        if (this.parameters.condition === undefined) return []
        return this.parameters.condition.getVariantIds()
    }
}
