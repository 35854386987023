// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// @ts-strict-ignore
import {
    BoxGeometry,
    BufferGeometry,
    Camera,
    CylinderGeometry,
    DoubleSide,
    Euler,
    Float32BufferAttribute,
    Line,
    LineBasicMaterial,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Object3DEventMap,
    OctahedronGeometry,
    PlaneGeometry,
    Quaternion,
    Raycaster,
    SphereGeometry,
    TorusGeometry,
    Vector2,
    Vector3,
} from "three"

export interface TransformControlsEventMap extends Object3DEventMap {
    change: {}
    mouseDown: {}
    mouseUp: {}
    objectChange: {}
    transform: {position?: Vector3; quaternion?: Quaternion; scale?: Vector3}
    "camera-changed": {value: unknown}
    "object-changed": {value: unknown}
    "enabled-changed": {value: unknown}
    "axis-changed": {value: unknown}
    "mode-changed": {value: unknown}
    "translationSnap-changed": {value: unknown}
    "rotationSnap-changed": {value: unknown}
    "scaleSnap-changed": {value: unknown}
    "space-changed": {value: unknown}
    "size-changed": {value: unknown}
    "dragging-changed": {value: unknown}
    "showX-changed": {value: unknown}
    "showY-changed": {value: unknown}
    "showZ-changed": {value: unknown}
    "worldPosition-changed": {value: unknown}
    "worldPositionStart-changed": {value: unknown}
    "worldQuaternion-changed": {value: unknown}
    "worldQuaternionStart-changed": {value: unknown}
    "cameraPosition-changed": {value: unknown}
    "cameraQuaternion-changed": {value: unknown}
    "pointStart-changed": {value: unknown}
    "pointEnd-changed": {value: unknown}
    "rotationAxis-changed": {value: unknown}
    "rotationAngle-changed": {value: unknown}
    "eye-changed": {value: unknown}
}

export class TransformControls extends Object3D<TransformControlsEventMap> {
    private offset = new Vector3()
    private startNorm = new Vector3()
    private endNorm = new Vector3()

    private raycaster = new Raycaster()

    private _tempVector = new Vector3()
    private _tempVector2 = new Vector3()
    private _tempQuaternion = new Quaternion()
    private _unit = {
        X: new Vector3(1, 0, 0),
        Y: new Vector3(0, 1, 0),
        Z: new Vector3(0, 0, 1),
    }

    private cameraScale = new Vector3()

    private tmpQuaternion = new Quaternion()
    private tmpPosition = new Vector3()
    private tmpScale = new Vector3()

    private _properties: any = {}

    static defProp() {
        return function (target: TransformControls, key: keyof TransformControls) {
            Object.defineProperty(target, key, {
                get: function (this: TransformControls) {
                    return this._properties[key]
                },

                set: function (this: TransformControls, value) {
                    if (this._properties[key] !== value) {
                        this._properties[key] = value
                        ;(this._plane as any)[key] = value
                        ;(this._gizmo as any)[key] = value
                        this.dispatchEvent({type: key + "-changed", value: value})
                        this.dispatchEvent({type: "change"})
                    }
                },
            })
        }
    }

    // these must be created before setting other wrapped properties
    _plane = new TransformControlsPlane()
    _gizmo = new TransformControlsGizmo()

    @TransformControls.defProp() camera: Camera
    @TransformControls.defProp() enabled = true
    @TransformControls.defProp() axis: "X" | "Y" | "Z" | "E" | "XY" | "XZ" | "YZ" | "XYZ" | "XYZE" | null = null
    @TransformControls.defProp() mode: "translate" | "rotate" | "scale" = "translate"
    @TransformControls.defProp() space: "local" | "world" = "world"
    @TransformControls.defProp() size = 1
    @TransformControls.defProp() dragging = false
    @TransformControls.defProp() showX = true
    @TransformControls.defProp() showY = true
    @TransformControls.defProp() showZ = true

    @TransformControls.defProp() pointStart = new Vector3()
    @TransformControls.defProp() pointEnd = new Vector3()
    @TransformControls.defProp() rotationAxis = new Vector3()
    @TransformControls.defProp() rotationAngle = 0
    @TransformControls.defProp() cameraPosition = new Vector3()
    @TransformControls.defProp() cameraQuaternion = new Quaternion()
    @TransformControls.defProp() worldPositionStart = new Vector3()
    @TransformControls.defProp() worldQuaternionStart = new Quaternion()
    @TransformControls.defProp() worldScaleStart = new Vector3()
    @TransformControls.defProp() worldPosition = new Vector3()
    @TransformControls.defProp() worldQuaternion = new Quaternion()
    @TransformControls.defProp() worldQuaternionInv = new Quaternion()
    @TransformControls.defProp() worldScale = new Vector3()
    @TransformControls.defProp() eye = new Vector3()

    private _transform: Matrix4 | null = null

    set transform(transform: Matrix4 | null) {
        this.visible = !!transform
        this._transform = transform
    }

    get transform(): Matrix4 | null {
        return this._transform
    }

    private domElementMap = new Map<HTMLElement, {dispose: () => void; camera?: Camera}>()
    private documentListenerRefCount = 0
    private disposeDocumentListeners: () => void

    registerDOMElement(domElement: HTMLElement): void {
        if (this.domElementMap.has(domElement)) return

        const onPointerDown = (evt: any) => this.onPointerDown(evt, domElement)
        const onPointerHover = (evt: any) => this.onPointerHover(evt, domElement)
        const onPointerMove = (evt: any) => this.onPointerMove(evt, domElement)
        const onPointerUp = (evt: any) => this.onPointerUp(evt, domElement)

        domElement.addEventListener("mousedown", onPointerDown, false)
        domElement.addEventListener("touchstart", onPointerDown, false)
        domElement.addEventListener("mousemove", onPointerHover, false)
        domElement.addEventListener("touchmove", onPointerHover, false)
        domElement.addEventListener("touchmove", onPointerMove, false)
        domElement.addEventListener("touchend", onPointerUp, false)
        domElement.addEventListener("touchcancel", onPointerUp, false)
        domElement.addEventListener("touchleave", onPointerUp, false)

        if (++this.documentListenerRefCount === 1) {
            //TODO: need to keep track of dom element which is being dragged. currently only works with a single view which has transform controls.
            domElement.ownerDocument.addEventListener("mousemove", onPointerMove, false)
            domElement.ownerDocument.addEventListener("mouseup", onPointerUp, false)
            this.disposeDocumentListeners = () => {
                domElement.ownerDocument.removeEventListener("mousemove", onPointerMove)
                domElement.ownerDocument.removeEventListener("mouseup", onPointerUp)
            }
        }

        this.domElementMap.set(domElement, {
            dispose: () => {
                domElement.removeEventListener("mousedown", onPointerDown)
                domElement.removeEventListener("touchstart", onPointerDown)
                domElement.removeEventListener("mousemove", onPointerHover)
                domElement.removeEventListener("touchmove", onPointerHover)
                domElement.removeEventListener("touchmove", onPointerMove)
                domElement.removeEventListener("touchend", onPointerUp)
                domElement.removeEventListener("touchcancel", onPointerUp)
                domElement.removeEventListener("touchleave", onPointerUp)
                if (--this.documentListenerRefCount === 0) {
                    this.disposeDocumentListeners()
                    this.disposeDocumentListeners = undefined
                }
            },
        })
    }

    unregisterDOMElement(domElement: HTMLElement): void {
        const entry = this.domElementMap.get(domElement)
        if (!entry) return
        this.domElementMap.delete(domElement)
        entry.dispose()
    }

    setCameraForDOMElement(domElement: HTMLElement, camera: Camera): void {
        const entry = this.domElementMap.get(domElement)
        if (!entry) return
        entry.camera = camera
    }

    constructor(layer: number) {
        super()
        ;(this as any).isTransformControls = true

        this.visible = false
        this.add(this._gizmo)
        this.add(this._plane)

        this.traverse((obj) => obj.layers.set(layer))
        this.raycaster.layers.set(layer)

        this.disposeListeners = () => {
            Array.from(this.domElementMap.keys()).forEach((e) => this.unregisterDOMElement(e))
        }
    }

    private disposeListeners: () => void

    private intersectObjectWithRay(object: Object3D, includeInvisible: boolean) {
        const allIntersections = this.raycaster.intersectObject(object, true)
        for (let i = 0; i < allIntersections.length; i++) {
            if (allIntersections[i].object.visible || includeInvisible) {
                return allIntersections[i]
            }
        }
        return false
    }

    dispose() {
        this.disposeListeners()

        this.traverse(function (child: any) {
            if (child.geometry) child.geometry.dispose()
            if (child.material) child.material.dispose()
        })
    }

    // updateMatrixWorld  updates key transformation variables
    override updateMatrixWorld() {
        if (this._transform) {
            this._transform.decompose(this.worldPosition, this.worldQuaternion, this.worldScale)
            this.worldQuaternionInv.copy(this.worldQuaternion).invert()
        }

        if (this.camera) {
            this.camera.updateMatrixWorld()
            this.camera.matrixWorld.decompose(this.cameraPosition, this.cameraQuaternion, this.cameraScale)
        }

        this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize()

        Object3D.prototype.updateMatrixWorld.call(this)
    }

    pointerHover(pointer: PointerEvent) {
        if (!this._transform || this.dragging === true || (pointer.button !== undefined && pointer.button !== 0)) return

        if (!this.camera) return

        this.raycaster.setFromCamera(new Vector2(pointer.x, pointer.y), this.camera)

        const intersect = this.intersectObjectWithRay(this._gizmo.picker[this.mode], false)

        if (intersect) {
            this.axis = intersect.object.name as TransformControls["axis"]
        } else {
            this.axis = null
        }
    }

    beginTransform() {
        this.updateMatrixWorld()
        this.worldPositionStart.copy(this.worldPosition)
        this.worldQuaternionStart.copy(this.worldQuaternion)
        this.worldScaleStart.copy(this.worldScale)
    }

    positionChanged(p: Vector3) {
        this.dispatchEvent({type: "transform", position: p})
    }

    quaternionChanged(q: Quaternion) {
        this.dispatchEvent({type: "transform", quaternion: q})
    }

    scaleChanged(s: Vector3) {
        this.dispatchEvent({type: "transform", scale: s})
    }

    pointerDown(pointer: PointerEvent) {
        if (!this._transform || this.dragging === true || (pointer.button !== undefined && pointer.button !== 0)) return

        if (!this.camera) return

        if ((pointer.button === 0 || pointer.button === undefined) && this.axis !== null) {
            this.raycaster.setFromCamera(new Vector2(pointer.x, pointer.y), this.camera)

            const planeIntersect = this.intersectObjectWithRay(this._plane, true)

            if (planeIntersect) {
                let space = this.space

                if (this.mode === "scale") {
                    space = "local"
                } else if (this.axis === "E" || this.axis === "XYZE" || this.axis === "XYZ") {
                    space = "world"
                }

                this.beginTransform()

                this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart)
            }

            this.dragging = true // this will dispatch a 'dragging-changed' event
        }
    }

    pointerMove(pointer: PointerEvent) {
        const axis = this.axis
        const mode = this.mode
        let space = this.space

        if (mode === "scale") {
            space = "local"
        } else if (axis === "E" || axis === "XYZE" || axis === "XYZ") {
            space = "world"
        }

        if (!this._transform || axis === null || this.dragging === false || (pointer.button !== undefined && pointer.button !== 0)) return

        if (!this.camera) return

        this.raycaster.setFromCamera(new Vector2(pointer.x, pointer.y), this.camera)

        const planeIntersect = this.intersectObjectWithRay(this._plane, true)

        if (!planeIntersect) return

        this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart)

        if (mode === "translate") {
            // Apply translate

            this.offset.copy(this.pointEnd).sub(this.pointStart)

            const restrictInLocalSpace = space === "local" && axis !== "XYZ"
            if (restrictInLocalSpace) {
                this.offset.applyQuaternion(this.worldQuaternionInv)
            }

            if (axis.indexOf("X") === -1) this.offset.x = 0
            if (axis.indexOf("Y") === -1) this.offset.y = 0
            if (axis.indexOf("Z") === -1) this.offset.z = 0

            if (restrictInLocalSpace) {
                this.offset.applyQuaternion(this.worldQuaternion)
            }

            this.tmpPosition.copy(this.offset).add(this.worldPositionStart)

            this.positionChanged(this.tmpPosition)
        } else if (mode === "scale") {
            if (axis.search("XYZ") !== -1) {
                let d = this.pointEnd.length() / this.pointStart.length()

                if (this.pointEnd.dot(this.pointStart) < 0) d *= -1

                this._tempVector2.set(d, d, d)
            } else {
                this._tempVector.copy(this.pointStart)
                this._tempVector2.copy(this.pointEnd)

                this._tempVector.applyQuaternion(this.worldQuaternionInv)
                this._tempVector2.applyQuaternion(this.worldQuaternionInv)

                this._tempVector2.divide(this._tempVector)

                if (axis.search("X") === -1) {
                    this._tempVector2.x = 1
                }

                if (axis.search("Y") === -1) {
                    this._tempVector2.y = 1
                }

                if (axis.search("Z") === -1) {
                    this._tempVector2.z = 1
                }
            }

            // Apply scale

            this.tmpScale.copy(this.worldScaleStart).multiply(this._tempVector2)

            this.scaleChanged(this.tmpScale)
        } else if (mode === "rotate") {
            this.offset.copy(this.pointEnd).sub(this.pointStart)

            const ROTATION_SPEED = 20 / this.worldPosition.distanceTo(this._tempVector.setFromMatrixPosition(this.camera.matrixWorld))

            if (axis === "E") {
                this.rotationAxis.copy(this.eye)
                this.rotationAngle = this.pointEnd.angleTo(this.pointStart)

                this.startNorm.copy(this.pointStart).normalize()
                this.endNorm.copy(this.pointEnd).normalize()

                this.rotationAngle *= this.endNorm.cross(this.startNorm).dot(this.eye) < 0 ? 1 : -1
            } else if (axis === "XYZE") {
                this.rotationAxis.copy(this.offset).cross(this.eye).normalize()
                this.rotationAngle = this.offset.dot(this._tempVector.copy(this.rotationAxis).cross(this.eye)) * ROTATION_SPEED
            } else if (axis === "X" || axis === "Y" || axis === "Z") {
                this.rotationAxis.copy(this._unit[axis])

                this._tempVector.copy(this._unit[axis])

                if (space === "local") {
                    this._tempVector.applyQuaternion(this.worldQuaternion)
                }

                this.rotationAngle = this.offset.dot(this._tempVector.cross(this.eye).normalize()) * ROTATION_SPEED
            }

            if (space === "local" && axis !== "E" && axis !== "XYZE") {
                this.tmpQuaternion.copy(this.worldQuaternionStart)
                this.tmpQuaternion.multiply(this._tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle)).normalize()
            } else {
                //this.rotationAxis.applyQuaternion( this.parentQuaternionInv );
                this.tmpQuaternion.copy(this._tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle))
                this.tmpQuaternion.multiply(this.worldQuaternionStart).normalize()
            }

            this.quaternionChanged(this.tmpQuaternion)
        }
    }

    pointerUp(pointer: PointerEvent) {
        if (pointer.button !== undefined && pointer.button !== 0) return

        this.dragging = false // this will dispatch a 'dragging-changed' event

        if (pointer.button === undefined) this.axis = null
    }

    // normalize mouse / touch pointer and remap {x,y} to view space.

    getPointer(event: any, domElement: HTMLElement): PointerEvent {
        const camera = this.domElementMap.get(domElement)?.camera
        if (camera !== this.camera) {
            this.camera = camera
        }

        if (domElement.ownerDocument.pointerLockElement) {
            return {
                x: 0,
                y: 0,
                button: event.button,
            } as PointerEvent
        } else {
            const pointer = event.changedTouches ? event.changedTouches[0] : event

            const rect = domElement.getBoundingClientRect()

            return {
                x: ((pointer.clientX - rect.left) / rect.width) * 2 - 1,
                y: (-(pointer.clientY - rect.top) / rect.height) * 2 + 1,
                button: event.button,
            } as PointerEvent
        }
    }

    // mouse / touch event handlers

    onPointerHover(event: PointerEvent, origDomElement: HTMLElement) {
        if (!this.enabled) return
        this.pointerHover(this.getPointer(event, origDomElement))
    }

    onPointerDown(event: PointerEvent, origDomElement: HTMLElement) {
        if (!this.enabled) return

        // this.domElement.ownerDocument.addEventListener( "mousemove", onPointerMove, false );

        this.pointerHover(this.getPointer(event, origDomElement))
        this.pointerDown(this.getPointer(event, origDomElement))
    }

    onPointerMove(event: PointerEvent, origDomElement: HTMLElement) {
        if (!this.enabled) return

        this.pointerMove(this.getPointer(event, origDomElement))
    }

    onPointerUp(event: PointerEvent, origDomElement: HTMLElement) {
        if (!this.enabled) return

        // this.domElement.ownerDocument.removeEventListener( "mousemove", onPointerMove, false );

        this.pointerUp(this.getPointer(event, origDomElement))
    }
}

class TransformControlsGizmo extends Object3D {
    gizmo = {} as any
    helper = {} as any
    picker = {} as any

    axis: TransformControls["axis"]
    camera: TransformControls["camera"]
    cameraPosition: TransformControls["cameraPosition"]
    dragging: TransformControls["dragging"]
    enabled: TransformControls["enabled"]
    eye: TransformControls["eye"]
    mode: TransformControls["mode"]
    rotationAxis: TransformControls["rotationAxis"]
    showX: TransformControls["showX"]
    showY: TransformControls["showY"]
    showZ: TransformControls["showZ"]
    size: TransformControls["size"]
    space: TransformControls["space"]
    override type: TransformControls["type"]
    worldPosition: TransformControls["worldPosition"]
    worldPositionStart: TransformControls["worldPositionStart"]
    worldQuaternion: TransformControls["worldQuaternion"]
    worldQuaternionStart: TransformControls["worldQuaternionStart"]

    constructor() {
        super()
        this.type = "TransformControlsGizmo"
        ;(this as any).isTransformControlsGizmo = true

        // shared materials

        const gizmoMaterial = new MeshBasicMaterial({
            depthTest: false,
            depthWrite: false,
            transparent: true,
            side: DoubleSide,
            fog: false,
            toneMapped: false,
        })

        const gizmoLineMaterial = new LineBasicMaterial({
            depthTest: false,
            depthWrite: false,
            transparent: true,
            linewidth: 1,
            fog: false,
            toneMapped: false,
        })

        // Make unique material for each axis/color

        const matInvisible = gizmoMaterial.clone()
        matInvisible.opacity = 0.15

        const matHelper = gizmoMaterial.clone()
        matHelper.opacity = 0.33

        const matRed = gizmoMaterial.clone()
        matRed.color.set(0xff0000)

        const matGreen = gizmoMaterial.clone()
        matGreen.color.set(0x00ff00)

        const matBlue = gizmoMaterial.clone()
        matBlue.color.set(0x0000ff)

        const matWhiteTransparent = gizmoMaterial.clone()
        matWhiteTransparent.opacity = 0.25

        const matYellowTransparent = matWhiteTransparent.clone()
        matYellowTransparent.color.set(0xffff00)

        const matCyanTransparent = matWhiteTransparent.clone()
        matCyanTransparent.color.set(0x00ffff)

        const matMagentaTransparent = matWhiteTransparent.clone()
        matMagentaTransparent.color.set(0xff00ff)

        const matYellow = gizmoMaterial.clone()
        matYellow.color.set(0xffff00)

        const matLineRed = gizmoLineMaterial.clone()
        matLineRed.color.set(0xff0000)

        const matLineGreen = gizmoLineMaterial.clone()
        matLineGreen.color.set(0x00ff00)

        const matLineBlue = gizmoLineMaterial.clone()
        matLineBlue.color.set(0x0000ff)

        const matLineCyan = gizmoLineMaterial.clone()
        matLineCyan.color.set(0x00ffff)

        const matLineMagenta = gizmoLineMaterial.clone()
        matLineMagenta.color.set(0xff00ff)

        const matLineYellow = gizmoLineMaterial.clone()
        matLineYellow.color.set(0xffff00)

        const matLineGray = gizmoLineMaterial.clone()
        matLineGray.color.set(0x787878)

        const matLineYellowTransparent = matLineYellow.clone()
        matLineYellowTransparent.opacity = 0.25

        // reusable geometry

        const arrowGeometry = new CylinderGeometry(0, 0.05, 0.2, 12, 1, false)

        const scaleHandleGeometry = new BoxGeometry(0.125, 0.125, 0.125)

        const lineGeometry = new BufferGeometry()
        lineGeometry.setAttribute("position", new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3))

        const CircleGeometry = function (radius: number, arc: number) {
            const geometry = new BufferGeometry()
            const vertices = []

            for (let i = 0; i <= 64 * arc; ++i) {
                vertices.push(0, Math.cos((i / 32) * Math.PI) * radius, Math.sin((i / 32) * Math.PI) * radius)
            }

            geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3))

            return geometry
        }

        // Special geometry for transform helper. If scaled with position vector it spans from [0,0,0] to position

        const TranslateHelperGeometry = function () {
            const geometry = new BufferGeometry()

            geometry.setAttribute("position", new Float32BufferAttribute([0, 0, 0, 1, 1, 1], 3))

            return geometry
        }

        // Gizmo definitions - custom hierarchy definitions for setupGizmo() function

        const gizmoTranslate = {
            X: [
                [new Mesh(arrowGeometry, matRed), [1, 0, 0], [0, 0, -Math.PI / 2], null, "fwd"],
                [new Mesh(arrowGeometry, matRed), [1, 0, 0], [0, 0, Math.PI / 2], null, "bwd"],
                [new Line(lineGeometry, matLineRed)],
            ],
            Y: [
                [new Mesh(arrowGeometry, matGreen), [0, 1, 0], null, null, "fwd"],
                [new Mesh(arrowGeometry, matGreen), [0, 1, 0], [Math.PI, 0, 0], null, "bwd"],
                [new Line(lineGeometry, matLineGreen), null, [0, 0, Math.PI / 2]],
            ],
            Z: [
                [new Mesh(arrowGeometry, matBlue), [0, 0, 1], [Math.PI / 2, 0, 0], null, "fwd"],
                [new Mesh(arrowGeometry, matBlue), [0, 0, 1], [-Math.PI / 2, 0, 0], null, "bwd"],
                [new Line(lineGeometry, matLineBlue), null, [0, -Math.PI / 2, 0]],
            ],
            XYZ: [[new Mesh(new OctahedronGeometry(0.1, 0), matWhiteTransparent.clone()), [0, 0, 0], [0, 0, 0]]],
            XY: [
                [new Mesh(new PlaneGeometry(0.295, 0.295), matYellowTransparent.clone()), [0.15, 0.15, 0]],
                [new Line(lineGeometry, matLineYellow), [0.18, 0.3, 0], null, [0.125, 1, 1]],
                [new Line(lineGeometry, matLineYellow), [0.3, 0.18, 0], [0, 0, Math.PI / 2], [0.125, 1, 1]],
            ],
            YZ: [
                [new Mesh(new PlaneGeometry(0.295, 0.295), matCyanTransparent.clone()), [0, 0.15, 0.15], [0, Math.PI / 2, 0]],
                [new Line(lineGeometry, matLineCyan), [0, 0.18, 0.3], [0, 0, Math.PI / 2], [0.125, 1, 1]],
                [new Line(lineGeometry, matLineCyan), [0, 0.3, 0.18], [0, -Math.PI / 2, 0], [0.125, 1, 1]],
            ],
            XZ: [
                [new Mesh(new PlaneGeometry(0.295, 0.295), matMagentaTransparent.clone()), [0.15, 0, 0.15], [-Math.PI / 2, 0, 0]],
                [new Line(lineGeometry, matLineMagenta), [0.18, 0, 0.3], null, [0.125, 1, 1]],
                [new Line(lineGeometry, matLineMagenta), [0.3, 0, 0.18], [0, -Math.PI / 2, 0], [0.125, 1, 1]],
            ],
        }

        const pickerTranslate = {
            X: [[new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), matInvisible), [0.6, 0, 0], [0, 0, -Math.PI / 2]]],
            Y: [[new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), matInvisible), [0, 0.6, 0]]],
            Z: [[new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), matInvisible), [0, 0, 0.6], [Math.PI / 2, 0, 0]]],
            XYZ: [[new Mesh(new OctahedronGeometry(0.2, 0), matInvisible)]],
            XY: [[new Mesh(new PlaneGeometry(0.4, 0.4), matInvisible), [0.2, 0.2, 0]]],
            YZ: [[new Mesh(new PlaneGeometry(0.4, 0.4), matInvisible), [0, 0.2, 0.2], [0, Math.PI / 2, 0]]],
            XZ: [[new Mesh(new PlaneGeometry(0.4, 0.4), matInvisible), [0.2, 0, 0.2], [-Math.PI / 2, 0, 0]]],
        }

        const helperTranslate = {
            START: [[new Mesh(new OctahedronGeometry(0.01, 2), matHelper), null, null, null, "helper"]],
            END: [[new Mesh(new OctahedronGeometry(0.01, 2), matHelper), null, null, null, "helper"]],
            DELTA: [[new Line(TranslateHelperGeometry(), matHelper), null, null, null, "helper"]],
            X: [[new Line(lineGeometry, matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], "helper"]],
            Y: [[new Line(lineGeometry, matHelper.clone()), [0, -1e3, 0], [0, 0, Math.PI / 2], [1e6, 1, 1], "helper"]],
            Z: [[new Line(lineGeometry, matHelper.clone()), [0, 0, -1e3], [0, -Math.PI / 2, 0], [1e6, 1, 1], "helper"]],
        }

        const gizmoRotate = {
            X: [[new Line(CircleGeometry(1, 0.5), matLineRed)], [new Mesh(new OctahedronGeometry(0.04, 0), matRed), [0, 0, 0.99], null, [1, 3, 1]]],
            Y: [
                [new Line(CircleGeometry(1, 0.5), matLineGreen), null, [0, 0, -Math.PI / 2]],
                [new Mesh(new OctahedronGeometry(0.04, 0), matGreen), [0, 0, 0.99], null, [3, 1, 1]],
            ],
            Z: [
                [new Line(CircleGeometry(1, 0.5), matLineBlue), null, [0, Math.PI / 2, 0]],
                [new Mesh(new OctahedronGeometry(0.04, 0), matBlue), [0.99, 0, 0], null, [1, 3, 1]],
            ],
            E: [
                [new Line(CircleGeometry(1.25, 1), matLineYellowTransparent), null, [0, Math.PI / 2, 0]],
                [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent), [1.17, 0, 0], [0, 0, -Math.PI / 2], [1, 1, 0.001]],
                [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent), [-1.17, 0, 0], [0, 0, Math.PI / 2], [1, 1, 0.001]],
                [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent), [0, -1.17, 0], [Math.PI, 0, 0], [1, 1, 0.001]],
                [new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent), [0, 1.17, 0], [0, 0, 0], [1, 1, 0.001]],
            ],
            XYZE: [[new Line(CircleGeometry(1, 1), matLineGray), null, [0, Math.PI / 2, 0]]],
        }

        const helperRotate = {
            AXIS: [[new Line(lineGeometry, matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], "helper"]],
        }

        const pickerRotate = {
            X: [[new Mesh(new TorusGeometry(1, 0.1, 4, 24), matInvisible), [0, 0, 0], [0, -Math.PI / 2, -Math.PI / 2]]],
            Y: [[new Mesh(new TorusGeometry(1, 0.1, 4, 24), matInvisible), [0, 0, 0], [Math.PI / 2, 0, 0]]],
            Z: [[new Mesh(new TorusGeometry(1, 0.1, 4, 24), matInvisible), [0, 0, 0], [0, 0, -Math.PI / 2]]],
            E: [[new Mesh(new TorusGeometry(1.25, 0.1, 2, 24), matInvisible)]],
            XYZE: [[new Mesh(new SphereGeometry(0.7, 10, 8), matInvisible)]],
        }

        const gizmoScale = {
            X: [
                [new Mesh(scaleHandleGeometry, matRed), [0.8, 0, 0], [0, 0, -Math.PI / 2]],
                [new Line(lineGeometry, matLineRed), null, null, [0.8, 1, 1]],
            ],
            Y: [
                [new Mesh(scaleHandleGeometry, matGreen), [0, 0.8, 0]],
                [new Line(lineGeometry, matLineGreen), null, [0, 0, Math.PI / 2], [0.8, 1, 1]],
            ],
            Z: [
                [new Mesh(scaleHandleGeometry, matBlue), [0, 0, 0.8], [Math.PI / 2, 0, 0]],
                [new Line(lineGeometry, matLineBlue), null, [0, -Math.PI / 2, 0], [0.8, 1, 1]],
            ],
            XY: [
                [new Mesh(scaleHandleGeometry, matYellowTransparent), [0.85, 0.85, 0], null, [2, 2, 0.2]],
                [new Line(lineGeometry, matLineYellow), [0.855, 0.98, 0], null, [0.125, 1, 1]],
                [new Line(lineGeometry, matLineYellow), [0.98, 0.855, 0], [0, 0, Math.PI / 2], [0.125, 1, 1]],
            ],
            YZ: [
                [new Mesh(scaleHandleGeometry, matCyanTransparent), [0, 0.85, 0.85], null, [0.2, 2, 2]],
                [new Line(lineGeometry, matLineCyan), [0, 0.855, 0.98], [0, 0, Math.PI / 2], [0.125, 1, 1]],
                [new Line(lineGeometry, matLineCyan), [0, 0.98, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1]],
            ],
            XZ: [
                [new Mesh(scaleHandleGeometry, matMagentaTransparent), [0.85, 0, 0.85], null, [2, 0.2, 2]],
                [new Line(lineGeometry, matLineMagenta), [0.855, 0, 0.98], null, [0.125, 1, 1]],
                [new Line(lineGeometry, matLineMagenta), [0.98, 0, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1]],
            ],
            XYZX: [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), matWhiteTransparent.clone()), [1.1, 0, 0]]],
            XYZY: [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), matWhiteTransparent.clone()), [0, 1.1, 0]]],
            XYZZ: [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), matWhiteTransparent.clone()), [0, 0, 1.1]]],
        }

        const pickerScale = {
            X: [[new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible), [0.5, 0, 0], [0, 0, -Math.PI / 2]]],
            Y: [[new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible), [0, 0.5, 0]]],
            Z: [[new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible), [0, 0, 0.5], [Math.PI / 2, 0, 0]]],
            XY: [[new Mesh(scaleHandleGeometry, matInvisible), [0.85, 0.85, 0], null, [3, 3, 0.2]]],
            YZ: [[new Mesh(scaleHandleGeometry, matInvisible), [0, 0.85, 0.85], null, [0.2, 3, 3]]],
            XZ: [[new Mesh(scaleHandleGeometry, matInvisible), [0.85, 0, 0.85], null, [3, 0.2, 3]]],
            XYZX: [[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [1.1, 0, 0]]],
            XYZY: [[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [0, 1.1, 0]]],
            XYZZ: [[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [0, 0, 1.1]]],
        }

        const helperScale = {
            X: [[new Line(lineGeometry, matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], "helper"]],
            Y: [[new Line(lineGeometry, matHelper.clone()), [0, -1e3, 0], [0, 0, Math.PI / 2], [1e6, 1, 1], "helper"]],
            Z: [[new Line(lineGeometry, matHelper.clone()), [0, 0, -1e3], [0, -Math.PI / 2, 0], [1e6, 1, 1], "helper"]],
        }

        // Creates an Object3D with gizmos described in custom hierarchy definition.

        const setupGizmo = function (gizmoMap: any) {
            const gizmo = new Object3D()

            for (const name in gizmoMap) {
                for (let i = gizmoMap[name].length; i--; ) {
                    const object = gizmoMap[name][i][0].clone()
                    const position = gizmoMap[name][i][1]
                    const rotation = gizmoMap[name][i][2]
                    const scale = gizmoMap[name][i][3]
                    const tag = gizmoMap[name][i][4]

                    // name and tag properties are essential for picking and updating logic.
                    object.name = name
                    object.tag = tag

                    if (position) {
                        object.position.set(position[0], position[1], position[2])
                    }

                    if (rotation) {
                        object.rotation.set(rotation[0], rotation[1], rotation[2])
                    }

                    if (scale) {
                        object.scale.set(scale[0], scale[1], scale[2])
                    }

                    object.updateMatrix()

                    const tempGeometry = object.geometry.clone()
                    tempGeometry.applyMatrix4(object.matrix)
                    object.geometry = tempGeometry
                    object.renderOrder = Infinity

                    object.position.set(0, 0, 0)
                    object.rotation.set(0, 0, 0)
                    object.scale.set(1, 1, 1)

                    gizmo.add(object)
                }
            }

            return gizmo
        }

        // Reusable utility variables

        const tempVector = new Vector3(0, 0, 0)
        const tempEuler = new Euler()
        const alignVector = new Vector3(0, 1, 0)
        const zeroVector = new Vector3(0, 0, 0)
        const lookAtMatrix = new Matrix4()
        const tempQuaternion = new Quaternion()
        const tempQuaternion2 = new Quaternion()
        const identityQuaternion = new Quaternion()

        const unitX = new Vector3(1, 0, 0)
        const unitY = new Vector3(0, 1, 0)
        const unitZ = new Vector3(0, 0, 1)

        // Gizmo creation

        this.add((this.gizmo["translate"] = setupGizmo(gizmoTranslate)))
        this.add((this.gizmo["rotate"] = setupGizmo(gizmoRotate)))
        this.add((this.gizmo["scale"] = setupGizmo(gizmoScale)))
        this.add((this.picker["translate"] = setupGizmo(pickerTranslate)))
        this.add((this.picker["rotate"] = setupGizmo(pickerRotate)))
        this.add((this.picker["scale"] = setupGizmo(pickerScale)))
        this.add((this.helper["translate"] = setupGizmo(helperTranslate)))
        this.add((this.helper["rotate"] = setupGizmo(helperRotate)))
        this.add((this.helper["scale"] = setupGizmo(helperScale)))

        // Pickers should be hidden always

        this.picker["translate"].visible = false
        this.picker["rotate"].visible = false
        this.picker["scale"].visible = false

        // updateMatrixWorld will update transformations and appearance of individual handles

        this.updateMatrixWorld = function () {
            if (!this.camera) return

            let space = this.space

            if (this.mode === "scale") space = "local" // scale always oriented to local rotation

            const quaternion = space === "local" ? this.worldQuaternion : identityQuaternion

            // Show only gizmos for current transform mode

            this.gizmo["translate"].visible = this.mode === "translate"
            this.gizmo["rotate"].visible = this.mode === "rotate"
            this.gizmo["scale"].visible = this.mode === "scale"

            this.helper["translate"].visible = this.mode === "translate"
            this.helper["rotate"].visible = this.mode === "rotate"
            this.helper["scale"].visible = this.mode === "scale"

            let handles: any[] = []
            handles = handles.concat(this.picker[this.mode].children)
            handles = handles.concat(this.gizmo[this.mode].children)
            handles = handles.concat(this.helper[this.mode].children)

            for (let i = 0; i < handles.length; i++) {
                const handle = handles[i]

                // hide aligned to camera

                handle.visible = true
                handle.rotation.set(0, 0, 0)
                handle.position.copy(this.worldPosition)

                var factor

                if ((this.camera as any).isOrthographicCamera) {
                    factor = ((this.camera as any).top - (this.camera as any).bottom) / (this.camera as any).zoom
                } else {
                    factor =
                        this.worldPosition.distanceTo(this.cameraPosition) *
                        Math.min((1.9 * Math.tan((Math.PI * (this.camera as any).fov) / 360)) / (this.camera as any).zoom, 7)
                }

                handle.scale.set(1, 1, 1).multiplyScalar((factor * this.size) / 7)

                // TODO: simplify helpers and consider decoupling from gizmo

                if (handle.tag === "helper") {
                    handle.visible = false

                    if (handle.name === "AXIS") {
                        handle.position.copy(this.worldPositionStart)
                        handle.visible = !!this.axis

                        if (this.axis === "X") {
                            tempQuaternion.setFromEuler(tempEuler.set(0, 0, 0))
                            handle.quaternion.copy(quaternion).multiply(tempQuaternion)

                            if (Math.abs(alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.eye)) > 0.9) {
                                handle.visible = false
                            }
                        }

                        if (this.axis === "Y") {
                            tempQuaternion.setFromEuler(tempEuler.set(0, 0, Math.PI / 2))
                            handle.quaternion.copy(quaternion).multiply(tempQuaternion)

                            if (Math.abs(alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.eye)) > 0.9) {
                                handle.visible = false
                            }
                        }

                        if (this.axis === "Z") {
                            tempQuaternion.setFromEuler(tempEuler.set(0, Math.PI / 2, 0))
                            handle.quaternion.copy(quaternion).multiply(tempQuaternion)

                            if (Math.abs(alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.eye)) > 0.9) {
                                handle.visible = false
                            }
                        }

                        if (this.axis === "XYZE") {
                            tempQuaternion.setFromEuler(tempEuler.set(0, Math.PI / 2, 0))
                            alignVector.copy(this.rotationAxis)
                            handle.quaternion.setFromRotationMatrix(lookAtMatrix.lookAt(zeroVector, alignVector, unitY))
                            handle.quaternion.multiply(tempQuaternion)
                            handle.visible = this.dragging
                        }

                        if (this.axis === "E") {
                            handle.visible = false
                        }
                    } else if (handle.name === "START") {
                        handle.position.copy(this.worldPositionStart)
                        handle.visible = this.dragging
                    } else if (handle.name === "END") {
                        handle.position.copy(this.worldPosition)
                        handle.visible = this.dragging
                    } else if (handle.name === "DELTA") {
                        handle.position.copy(this.worldPositionStart)
                        handle.quaternion.copy(this.worldQuaternionStart)
                        tempVector.set(1e-10, 1e-10, 1e-10).add(this.worldPositionStart).sub(this.worldPosition).multiplyScalar(-1)
                        tempVector.applyQuaternion(this.worldQuaternionStart.clone().invert())
                        handle.scale.copy(tempVector)
                        handle.visible = this.dragging
                    } else {
                        handle.quaternion.copy(quaternion)

                        if (this.dragging) {
                            handle.position.copy(this.worldPositionStart)
                        } else {
                            handle.position.copy(this.worldPosition)
                        }

                        if (this.axis) {
                            handle.visible = this.axis.search(handle.name) !== -1
                        }
                    }

                    // If updating helper, skip rest of the loop
                    continue
                }

                // Align handles to current local or world rotation

                handle.quaternion.copy(quaternion)

                if (this.mode === "translate" || this.mode === "scale") {
                    // Hide translate and scale axis facing the camera

                    const AXIS_HIDE_TRESHOLD = 0.99
                    const PLANE_HIDE_TRESHOLD = 0.2
                    const AXIS_FLIP_TRESHOLD = 0.0

                    if (handle.name === "X" || handle.name === "XYZX") {
                        if (Math.abs(alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.eye)) > AXIS_HIDE_TRESHOLD) {
                            handle.scale.set(1e-10, 1e-10, 1e-10)
                            handle.visible = false
                        }
                    }

                    if (handle.name === "Y" || handle.name === "XYZY") {
                        if (Math.abs(alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.eye)) > AXIS_HIDE_TRESHOLD) {
                            handle.scale.set(1e-10, 1e-10, 1e-10)
                            handle.visible = false
                        }
                    }

                    if (handle.name === "Z" || handle.name === "XYZZ") {
                        if (Math.abs(alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.eye)) > AXIS_HIDE_TRESHOLD) {
                            handle.scale.set(1e-10, 1e-10, 1e-10)
                            handle.visible = false
                        }
                    }

                    if (handle.name === "XY") {
                        if (Math.abs(alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.eye)) < PLANE_HIDE_TRESHOLD) {
                            handle.scale.set(1e-10, 1e-10, 1e-10)
                            handle.visible = false
                        }
                    }

                    if (handle.name === "YZ") {
                        if (Math.abs(alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.eye)) < PLANE_HIDE_TRESHOLD) {
                            handle.scale.set(1e-10, 1e-10, 1e-10)
                            handle.visible = false
                        }
                    }

                    if (handle.name === "XZ") {
                        if (Math.abs(alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.eye)) < PLANE_HIDE_TRESHOLD) {
                            handle.scale.set(1e-10, 1e-10, 1e-10)
                            handle.visible = false
                        }
                    }

                    // Flip translate and scale axis ocluded behind another axis

                    if (handle.name.search("X") !== -1) {
                        if (alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.eye) < AXIS_FLIP_TRESHOLD) {
                            if (handle.tag === "fwd") {
                                handle.visible = false
                            } else {
                                handle.scale.x *= -1
                            }
                        } else if (handle.tag === "bwd") {
                            handle.visible = false
                        }
                    }

                    if (handle.name.search("Y") !== -1) {
                        if (alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.eye) < AXIS_FLIP_TRESHOLD) {
                            if (handle.tag === "fwd") {
                                handle.visible = false
                            } else {
                                handle.scale.y *= -1
                            }
                        } else if (handle.tag === "bwd") {
                            handle.visible = false
                        }
                    }

                    if (handle.name.search("Z") !== -1) {
                        if (alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.eye) < AXIS_FLIP_TRESHOLD) {
                            if (handle.tag === "fwd") {
                                handle.visible = false
                            } else {
                                handle.scale.z *= -1
                            }
                        } else if (handle.tag === "bwd") {
                            handle.visible = false
                        }
                    }
                } else if (this.mode === "rotate") {
                    // Align handles to current local or world rotation

                    tempQuaternion2.copy(quaternion)
                    alignVector.copy(this.eye).applyQuaternion(tempQuaternion.copy(quaternion).invert())

                    if (handle.name.search("E") !== -1) {
                        handle.quaternion.setFromRotationMatrix(lookAtMatrix.lookAt(this.eye, zeroVector, unitY))
                    }

                    if (handle.name === "X") {
                        tempQuaternion.setFromAxisAngle(unitX, Math.atan2(-alignVector.y, alignVector.z))
                        tempQuaternion.multiplyQuaternions(tempQuaternion2, tempQuaternion)
                        handle.quaternion.copy(tempQuaternion)
                    }

                    if (handle.name === "Y") {
                        tempQuaternion.setFromAxisAngle(unitY, Math.atan2(alignVector.x, alignVector.z))
                        tempQuaternion.multiplyQuaternions(tempQuaternion2, tempQuaternion)
                        handle.quaternion.copy(tempQuaternion)
                    }

                    if (handle.name === "Z") {
                        tempQuaternion.setFromAxisAngle(unitZ, Math.atan2(alignVector.y, alignVector.x))
                        tempQuaternion.multiplyQuaternions(tempQuaternion2, tempQuaternion)
                        handle.quaternion.copy(tempQuaternion)
                    }
                }

                // Hide disabled axes
                handle.visible = handle.visible && (handle.name.indexOf("X") === -1 || this.showX)
                handle.visible = handle.visible && (handle.name.indexOf("Y") === -1 || this.showY)
                handle.visible = handle.visible && (handle.name.indexOf("Z") === -1 || this.showZ)
                handle.visible = handle.visible && (handle.name.indexOf("E") === -1 || (this.showX && this.showY && this.showZ))

                // highlight selected axis

                handle.material._opacity = handle.material._opacity || handle.material.opacity
                handle.material._color = handle.material._color || handle.material.color.clone()

                handle.material.color.copy(handle.material._color)
                handle.material.opacity = handle.material._opacity

                if (!this.enabled) {
                    handle.material.opacity *= 0.33
                } else if (this.axis) {
                    if (handle.name === this.axis) {
                        handle.material.opacity = 1.0
                    } else {
                        handle.material.opacity *= 0.33
                        // handle.material.color.lerp( new Color( 1, 1, 1 ), 0.5 );
                    }
                }
            }

            Object3D.prototype.updateMatrixWorld.call(this)
        }
    }
}

class TransformControlsPlane extends Mesh {
    axis: TransformControls["axis"]
    cameraQuaternion: TransformControls["cameraQuaternion"]
    eye: TransformControls["eye"]
    mode: TransformControls["mode"]
    worldQuaternion: TransformControls["worldQuaternion"]
    worldPosition: TransformControls["worldPosition"]
    space: TransformControls["space"]

    constructor() {
        super(
            new PlaneGeometry(100000, 100000, 2, 2),
            new MeshBasicMaterial({visible: false, wireframe: true, side: DoubleSide, transparent: true, opacity: 0.1, toneMapped: false}),
        )
        ;(this as any).isTransformControlsPlane = true

        const unitX = new Vector3(1, 0, 0)
        const unitY = new Vector3(0, 1, 0)
        const unitZ = new Vector3(0, 0, 1)

        const tempVector = new Vector3()
        const dirVector = new Vector3()
        const alignVector = new Vector3()
        const tempMatrix = new Matrix4()
        const identityQuaternion = new Quaternion()

        this.updateMatrixWorld = function () {
            let space = this.space

            this.position.copy(this.worldPosition)

            if (this.mode === "scale") space = "local" // scale always oriented to local rotation

            unitX.set(1, 0, 0).applyQuaternion(space === "local" ? this.worldQuaternion : identityQuaternion)
            unitY.set(0, 1, 0).applyQuaternion(space === "local" ? this.worldQuaternion : identityQuaternion)
            unitZ.set(0, 0, 1).applyQuaternion(space === "local" ? this.worldQuaternion : identityQuaternion)

            // Align the plane for current transform mode, axis and space.

            alignVector.copy(unitY)

            switch (this.mode) {
                case "translate":
                case "scale":
                    switch (this.axis) {
                        case "X":
                            alignVector.copy(this.eye).cross(unitX)
                            dirVector.copy(unitX).cross(alignVector)
                            break
                        case "Y":
                            alignVector.copy(this.eye).cross(unitY)
                            dirVector.copy(unitY).cross(alignVector)
                            break
                        case "Z":
                            alignVector.copy(this.eye).cross(unitZ)
                            dirVector.copy(unitZ).cross(alignVector)
                            break
                        case "XY":
                            dirVector.copy(unitZ)
                            break
                        case "YZ":
                            dirVector.copy(unitX)
                            break
                        case "XZ":
                            alignVector.copy(unitZ)
                            dirVector.copy(unitY)
                            break
                        case "XYZ":
                        case "E":
                            dirVector.set(0, 0, 0)
                            break
                    }

                    break
                case "rotate":
                default:
                    // special case for rotate
                    dirVector.set(0, 0, 0)
            }

            if (dirVector.length() === 0) {
                // If in rotate mode, make the plane parallel to camera
                this.quaternion.copy(this.cameraQuaternion)
            } else {
                tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector)

                this.quaternion.setFromRotationMatrix(tempMatrix)
            }

            Object3D.prototype.updateMatrixWorld.call(this)
        }
    }
}
