import {NgClass} from "@angular/common"
import {ChangeDetectionStrategy, Component, inject, input} from "@angular/core"
import {MatSelectModule} from "@angular/material/select"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {SelectMaterialButton} from "@app/common/components/viewers/configurator/config-menu-new/select-material/select-material.component"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu-new/services/config-menu.service"
import {InterfaceDescriptor, isConfigInput, isMaterialInput} from "@cm/lib/templates/interface-descriptors"

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "cm-default-menu",
    templateUrl: "./default-menu.component.html",
    styleUrls: ["./default-menu.component.scss"],
    imports: [NgClass, MatSelectModule, ButtonComponent, SelectMaterialButton],
})
export class DefaultMenuComponent {
    $interfaceDescriptors = input.required<InterfaceDescriptor<unknown, object>[]>()

    isConfigInput = isConfigInput
    isMaterialInput = isMaterialInput

    configMenuService = inject(ConfigMenuService)

    constructor() {}
}
