import {animate, style, transition, trigger} from "@angular/animations"
import {CommonModule} from "@angular/common"
import {Component, computed, DestroyRef, inject, OnInit} from "@angular/core"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {JobsTableItemFragment, JobState} from "@api"
import {OverflowableTextComponent, PaginatorComponent} from "@common/components/data"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {EntityMetadataComponent} from "@common/components/entity/entity-metadata/entity-metadata.component"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {TypedMatCellDefDirective} from "@common/directives"
import {keepCheckingRunningJobs$} from "@common/helpers/jobs"
import {jobPriorityIconClass} from "@common/helpers/jobs/icons"
import {BreakpointsService} from "@common/services/breakpoints/breakpoints.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {TippyDirective} from "@ngneat/helipopper"
import {BaseTableComponent} from "@platform/components/base/base-table/base-table.component"
import {JobTaskThumbnailComponent} from "@platform/components/jobs/job-task-thumbnail/job-task-thumbnail.component"
import {JobThumbnailComponent} from "@platform/components/jobs/job-thumbnail/job-thumbnail.component"
import {TagThumbnailComponent} from "@platform/components/tags/tag-thumbnail/tag-thumbnail.component"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"
import {MomentModule} from "ngx-moment"
import {combineLatest, map, startWith, tap} from "rxjs"
import {formatForDisplay} from "@common/helpers/utils/dates"
import {PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {TabStateService} from "@common/services/tab-state/tab-state.service"

@Component({
    selector: "cm-jobs-table",
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatTableModule,
        MatTooltipModule,
        MomentModule,
        TagThumbnailComponent,
        TypedMatCellDefDirective,
        JobThumbnailComponent,
        OverflowableTextComponent,
        TableCellComponent,
        PaginatorComponent,
        TippyDirective,
        UserThumbnailComponent,
        EntityMetadataComponent,
        FullPageFeedbackComponent,
        JobTaskThumbnailComponent,
    ],
    templateUrl: "./jobs-table.component.html",
    styleUrl: "./jobs-table.component.scss",
    animations: [
        trigger("fadeInPlaceholder", [
            transition("void => *", [
                style({
                    opacity: 0.4,
                    scale: 0.7,
                }),
                animate("1000ms", style({opacity: 1, scale: 0.98})),
            ]),
        ]),
    ],
})
export class JobsTableComponent extends BaseTableComponent<JobsTableItemFragment> implements OnInit {
    breakpoints = inject(BreakpointsService)
    can = inject(PermissionsService)
    destroyRef = inject(DestroyRef)
    tabState = inject(TabStateService)

    $columns = computed(() => {
        if (this.breakpoints.$isDesktop()) {
            return ["id", "state", "name", "priority", "action", "message", "creator"]
        } else {
            return ["id", "state", "name", "priority", "action"]
        }
    })
    $data = toSignal(
        this.dataLoader
            .pagedData$<JobsTableItemFragment>(
                combineLatest([this.filters.jobFilter$, this.sortOrder.jobs$, this.tabState.becomesActive$.pipe(startWith(true))]).pipe(
                    map(
                        ([jobsFilter, orderBy]) =>
                            (skip: number, take: number) =>
                                this.sdk.gql.jobsTableItems({skip, take, filter: jobsFilter, orderBy}),
                    ),
                ),
                "global",
            )
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                keepCheckingRunningJobs$(this.sdk, this.tabState),
                tap((result) => this.paging.$totalCount.set(result.totalCount ?? 0)),
            ),
        {initialValue: PLACEHOLDER_ITEMS<JobsTableItemFragment>()},
    )

    protected readonly JobState = JobState
    protected readonly jobPriorityIconClass = jobPriorityIconClass

    async togglePriority(item: {id: string}, highPriority: boolean) {
        await this.sdk.gql.jobsTableUpdateJob({input: {id: item.id, priority: highPriority ? 50 : 0}})
        if (highPriority) {
            await this.sdk.gql.jobsTableNudgeJob({id: item.id})
        }
        //TODO: refresh only the updated item, once the data loader has been updated to support this
        this.refresh.all(false)
    }

    async cancelJob(job: {id: string}) {
        await this.sdk.gql.jobsTableCancelJob({id: job.id})
        //TODO: refresh only the updated item, once the data loader has been updated to support this
        this.refresh.all(false)
    }

    async restartJob(job: {id: string}) {
        await this.sdk.gql.jobsTableRestartJob({id: job.id})
        //TODO: refresh only the updated item, once the data loader has been updated to support this
        this.refresh.all(false)
    }

    rowClicked(job?: {id: string}) {
        if (job?.id) {
            this.router.navigate(["/jobs", job?.id], {queryParamsHandling: "preserve"})
        }
    }

    progressTooltip(job: JobsTableItemFragment) {
        if ((job?.state === JobState.Running || job?.state === JobState.Runnable) && job.progress !== null) {
            return `${job.progress}%`
        }
        return null
    }

    ngOnInit() {
        this.paging.setDefaultPageSize(100)
    }

    protected readonly formatForDisplay = formatForDisplay
}
