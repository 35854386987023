<div class="cm-list-item">
    <div class="cm-left">
        <ng-content select="[cm-left]"> </ng-content>
    </div>
    <div class="cm-details">
        <div class="cm-title">
            <ng-content select="[cm-title]"> </ng-content>
        </div>
        <div class="cm-subtitle">
            <ng-content select="[cm-subtitle]"> </ng-content>
        </div>
    </div>
    <div class="cm-right">
        <ng-content select="[cm-right]"> </ng-content>
    </div>
</div>
<div class="cm-top-right">
    <ng-content select="[cm-top-right]"> </ng-content>
</div>
