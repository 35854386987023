<cm-menu-dialog (close)="close.emit()">
    <ng-container cm-title> Scan QR code </ng-container>
    <ng-container cm-subtitle> To view this variation in AR, please scan the code below with your smartphone. </ng-container>
    <ng-container cm-content>
        <div class="cm-qrcode-container">
            @if (!waitingForArFile) {
                @if (qrCode !== "") {
                    <img alt="QR Code" class="cm-qrcode" [src]="qrCode" />
                }
                @if (qrCode === "") {
                    <div class="cm-no-image-container">
                        <i class="far fa-image cm-no-image"></i>
                        <span class="cm-no-image-text"> Please try again later. </span>
                    </div>
                }
            } @else {
                <cm-loading-spinner>
                    <ng-container cm-text-bottom>
                        <span class="cm-loading-text"> Generating AR model... </span>
                    </ng-container>
                </cm-loading-spinner>
            }
            <!-- TODO: indicate that the AR generation is in progress. -->
        </div>
    </ng-container>
</cm-menu-dialog>
