import {MaterialSocket} from "@material-editor/models/material-socket"

export const GammaOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Value"},
}

export const GammaInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
    gamma: {paramType: "socket", id: "Gamma", type: "input", valueType: "scalar", label: "Gamma", defaultValue: 1, range: {min: 0, max: 10}},
}
