import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {z} from "zod"

const TD = TypeDescriptors

const materialGraphReferenceParameters = z.object({
    graph: IMaterialGraph,
})
export type MaterialGraphReferenceParameters = z.infer<typeof materialGraphReferenceParameters>

@registerNode
export class MaterialGraphReference
    extends DeclareTemplateNode({parameters: materialGraphReferenceParameters}, {}, {nodeClass: "MaterialGraphReference"})
    implements EvaluableTemplateNode<IMaterialGraph>
{
    evaluate(scope: GraphBuilderScope) {
        const materialGraph = scope.value(this.parameters.graph, TD.MaterialGraph)
        return materialGraph
    }
}

export type MaterialGraphReferenceFwd = TemplateNode<MaterialGraphReferenceParameters> & EvaluableTemplateNode<IMaterialGraph>
