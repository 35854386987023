<div [matMenuTriggerFor]="sideBarModeMenu" class="cm-tree-view-type-selector">
    <div>
        View:
        @switch (viewType()) {
            @case ("tree") {
                Structure
            }
            @case ("selectVariation") {
                Select Variation
            }
            @case ("allVariations") {
                All Variations
            }
        }
    </div>
    <i class="far fa-chevron-down"></i>
    <mat-menu #sideBarModeMenu>
        <div>
            <button mat-menu-item (click)="viewTypeChanged.emit('tree')" [class.cm-active]="viewType() === 'tree'" class="cm-tree-view-type-button">
                Structure
            </button>
            <button
                mat-menu-item
                (click)="viewTypeChanged.emit('selectVariation')"
                [class.cm-active]="viewType() === 'selectVariation'"
                class="cm-tree-view-type-button"
            >
                Select Variation
            </button>
            <button
                mat-menu-item
                (click)="viewTypeChanged.emit('allVariations')"
                [class.cm-active]="viewType() === 'allVariations'"
                class="cm-tree-view-type-button"
            >
                All Variations
            </button>
        </div>
    </mat-menu>
</div>
