<div id="flowchart-sizer">
    <div id="flowchart-container" [class.cm-placeholder]="showPlaceholder">
        <figure id="flowchart">
            <div class="cm-placeholder-container" [class.cm-shown]="showPlaceholder">
                <i class="cm-placeholder-icon fas fa-project-diagram"></i>
            </div>
            <svg viewBox="0 0 1600 900" #executionPlanSvg id="execution_plan" class="cm-fade-in" [class.cm-shown]="!showPlaceholder">
                <defs>
                    <linearGradient id="fadeGrad" y2="0" x2="1">
                        <stop offset="0" stop-color="white" stop-opacity="0" />
                        <stop offset="0.1" stop-color="white" stop-opacity="1" />
                        <stop offset="0.9" stop-color="white" stop-opacity="1" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>

                    <mask id="fade" maskContentUnits="objectBoundingBox">
                        <rect width="1" height="1" fill="url(#fadeGrad)" />
                    </mask>

                    <clipPath id="myClippingPath">
                        <rect rx="32" ry="32" width="400" height="64" fill="white" />
                    </clipPath>
                </defs>
                <g class="links"></g>
                <g class="nodes"></g>
            </svg>
        </figure>
    </div>
    <ng-template #executionPlanTooltip>
        <cm-execution-plan-tooltip [node]="tooltipData"></cm-execution-plan-tooltip>
    </ng-template>
</div>
