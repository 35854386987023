import {Component, Input, Output, EventEmitter} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {colorToString, parseColor} from "@cm/lib/utils/utils"

@Component({
    selector: "cm-color-input",
    standalone: true,
    templateUrl: "./color-input.component.html",
    styleUrls: ["./color-input.component.scss"],
    imports: [FormsModule, MatInputModule],
})
export class ColorInputComponent {
    @Input() label?: string
    @Input() set value(tupleValue: [number, number, number]) {
        this.hexValue = colorToString(tupleValue)
    }
    get value() {
        return parseColor(this.hexValue)
    }
    @Input() hexValue = "#ffffff"

    changeValue(value: string) {
        this.hexValue = value
        this.hexValueChange.emit(this.hexValue)
        this.valueChange.emit(this.value)
    }

    onFocus() {
        this.isPopupOpen.emit(true)
    }

    onBlur() {
        this.isPopupOpen.emit(false)
    }

    @Output() hexValueChange = new EventEmitter<string>()
    @Output() valueChange = new EventEmitter<[number, number, number]>()
    @Output() isPopupOpen = new EventEmitter<boolean>()
}
