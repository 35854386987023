export type SceneID = number
export type NodeID = number
export type MeshID = number
export type CameraID = number
export type AccessorID = number
export type BufferViewID = number
export type BufferID = number
export type MaterialID = number
export type TextureID = number
export type ImageID = number
export type SamplerID = number

export enum PrimitiveMode {
    POINTS = 0,
    LINES = 1,
    LINE_LOOP = 2,
    LINE_STRIP = 3,
    TRIANGLES = 4,
    TRIANGLE_STRIP = 5,
    TRIANGLE_FAN = 6,
}

export type Scene = {
    nodes: NodeID[] // root nodes
}

export type Node = {
    children?: NodeID[]
    mesh?: MeshID
    camera?: CameraID
    matrix?: number[]
    translation?: [number, number, number]
    rotation?: [number, number, number]
    scale?: [number, number, number]
}

export type PerspectiveCamera = {
    type: "perspective"
    perspective: {
        aspectRatio: number
        yfov: number
        zfar: number
        znear: number
    }
}

export type OrthographicCamera = {
    type: "orthographic"
    orthographic: {
        xmag: number
        ymag: number
        zfar: number
        znear: number
    }
}

export type Camera = PerspectiveCamera | OrthographicCamera

export type AttributeName = "POSITION" | "NORMAL" | "TANGENT" | "TEXCOORD_0" | "TEXCOORD_1" | "COLOR_0" | "JOINTS_0" | "WEIGHTS_0"

export enum ComponentType {
    BYTE = 5120,
    UNSIGNED_BYTE = 5121,
    SHORT = 5122,
    UNSIGNED_SHORT = 5123,
    UNSIGNED_INT = 5125,
    FLOAT = 5126,
}

export type AccessorType = "SCALAR" | "VEC2" | "VEC3" | "VEC4" | "MAT2" | "MAT3" | "MAT4"

export type Extensions = {[extName: string]: any}

export type Mesh = {
    primitives: {
        mode?: PrimitiveMode
        indices?: AccessorID
        attributes: {
            [name in AttributeName]?: AccessorID
        }
        material?: MaterialID
        extensions?: {
            KHR_draco_mesh_compression?: {
                bufferView: BufferViewID
                attributes: {[name in AttributeName]?: number}
            }
        }
    }[]
}

export type Accessor = {
    bufferView?: BufferViewID
    byteOffset?: number
    type: AccessorType
    componentType: ComponentType
    count: number
    min?: any
    max?: any
}

export type BufferView = {
    buffer: BufferID
    byteOffset?: number
    byteLength: number
    byteStride?: number
    // target: number,
}

export type Buffer = {
    byteLength: number
    uri?: string
}

export type Material = {
    name?: string
    pbrMetallicRoughness?: {
        baseColorFactor?: [number, number, number, number]
        baseColorTexture?: {index: TextureID; texCoord?: number}
        metallicFactor?: number
        roughnessFactor?: number
        metallicRoughnessTexture?: {index: TextureID; texCoord?: number}
    }
    normalTexture?: {index: TextureID; texCoord?: number; scale?: number}
    occlusionTexture?: {index: TextureID; texCoord?: number; strength?: number}
    emissiveTexture?: {index: TextureID; texCoord?: number}
    emissiveFactor?: [number, number, number]
    alphaMode?: "OPAQUE" | "MASK" | "BLEND"
    alphaCutoff?: number
    doubleSided?: boolean
}

export type Texture = {
    source: ImageID
    sampler?: SamplerID
    extensions?: Extensions
}

export type URIImage = {
    uri: string
}

export type BufferImage = {
    bufferView: BufferViewID
    mimeType: "image/jpeg" | "image/png"
}

export type Image = URIImage | BufferImage

export enum SamplerFilter {
    NEAREST = 9728,
    LINEAR = 9729,
    NEAREST_MIPMAP_NEAREST = 9984,
    LINEAR_MIPMAP_NEAREST = 9985,
    NEAREST_MIPMAP_LINEAR = 9986,
    LINEAR_MIPMAP_LINEAR = 9987,
}

export enum SamplerWrap {
    CLAMP_TO_EDGE = 33071,
    MIRRORED_REPEAT = 33648,
    REPEAT = 10497,
}

export type Sampler = {
    magFilter: SamplerFilter
    minFilter: SamplerFilter
    wrapS: SamplerWrap
    wrapT: SamplerWrap
}

export type Root = {
    asset: {
        version: "2.0"
        minVersion?: string
        generator?: string
        copyright?: string
        extensions?: any
        extras?: any
    }
    scene: SceneID
    scenes?: Scene[]
    nodes?: Node[]
    cameras?: Camera[]
    meshes?: Mesh[]
    accessors?: Accessor[]
    bufferViews?: BufferView[]
    buffers?: Buffer[]
    materials?: Material[]
    textures?: Texture[]
    images?: Image[]
    samplers?: Sampler[]
    extensionsUsed?: string[]
    extensionsRequired?: string[]
}

export class GLTFObject<ID, T> {
    constructor(
        readonly id: ID,
        readonly data: T,
    ) {}
}

export class GLTFBuilder {
    static PrimitiveMode = PrimitiveMode
    static ComponentType = ComponentType
    static SamplerFilter = SamplerFilter
    static SamplerWrap = SamplerWrap

    private data: Root = {
        asset: {
            version: "2.0",
        },
        scene: 0,
    }

    private bufferMap = new Map<BufferID, ArrayBufferView>()

    constructor(assetInfo?: {copyright?: string; generator?: string}) {
        if (assetInfo) {
            this.data.asset.copyright = assetInfo.copyright
            this.data.asset.generator = assetInfo.generator
        }
    }

    private addStructure<ID extends number, T>(
        key: Exclude<keyof Root, "asset" | "scene" | "extensionsUsed" | "extensionsRequired">,
        data: T,
    ): GLTFObject<ID, T> {
        if (!this.data[key]) {
            this.data[key] = []
        }
        const id = this.data[key]!.length
        const obj = new GLTFObject<ID, T>(id as any, data)
        this.data[key]!.push(obj.data as any)
        return obj
    }

    addScene(data: Scene) {
        return this.addStructure<SceneID, typeof data>("scenes", data)
    }

    addNode(data: Node) {
        return this.addStructure<NodeID, typeof data>("nodes", data)
    }

    addMesh(data: Mesh) {
        return this.addStructure<MeshID, typeof data>("meshes", data)
    }

    addCamera(data: Camera) {
        return this.addStructure<CameraID, typeof data>("cameras", data)
    }

    addAccessor(data: Accessor) {
        return this.addStructure<AccessorID, typeof data>("accessors", data)
    }

    addBufferView(data: BufferView) {
        return this.addStructure<BufferViewID, typeof data>("bufferViews", data)
    }

    addBuffer(data: Buffer, array: ArrayBufferView) {
        const obj = this.addStructure<BufferID, typeof data>("buffers", data)
        this.bufferMap.set(obj.id, array)
        return obj
    }

    addTexture(data: Texture) {
        return this.addStructure<TextureID, typeof data>("textures", data)
    }

    addMaterial(data: Material) {
        return this.addStructure<MaterialID, typeof data>("materials", data)
    }

    addImage(data: Image) {
        return this.addStructure<ImageID, typeof data>("images", data)
    }

    addSampler(data: Sampler) {
        return this.addStructure<SamplerID, typeof data>("samplers", data)
    }

    useExtension(name: string, required = true) {
        if (!this.data.extensionsUsed) {
            this.data.extensionsUsed = []
        }
        this.data.extensionsUsed.push(name)
        if (required) {
            if (!this.data.extensionsRequired) {
                this.data.extensionsRequired = []
            }
            this.data.extensionsRequired.push(name)
        }
    }

    generateGLB(): ArrayBuffer {
        const bufferList = this.data.buffers

        const align = (x: number) => ((x + 3) >> 2) << 2 // align to 4-byte boundary

        let curOffset = 0
        const gatheredOffsets: number[] = []
        if (bufferList) {
            for (let bufferID = 0; bufferID < bufferList.length; bufferID++) {
                const buffer = bufferList[bufferID]
                const array = this.bufferMap.get(bufferID)
                if (!array) throw new Error("No data for buffer " + bufferID)
                curOffset = align(curOffset)
                gatheredOffsets.push(curOffset)
                curOffset += buffer.byteLength ?? array.byteLength
            }
        }
        const gatheredByteLength = align(curOffset)

        this.data.buffers = [
            {
                byteLength: gatheredByteLength,
            },
        ]

        if (this.data.bufferViews) {
            for (const view of this.data.bufferViews) {
                view.byteOffset = (view.byteOffset ?? 0) + gatheredOffsets[view.buffer]
                view.buffer = 0
            }
        }

        const encoder = new TextEncoder()
        const jsonArray = encoder.encode(JSON.stringify(this.data))
        const jsonAlignedLength = align(jsonArray.byteLength)

        const headerSize = 4 * 3
        const chunkHeaderSize = 4 * 2
        const glbData = new Uint8Array(headerSize + chunkHeaderSize + jsonAlignedLength + chunkHeaderSize + gatheredByteLength)
        const glbDataView = new DataView(glbData.buffer, glbData.byteOffset, glbData.byteLength)

        curOffset = 0

        // Write header:
        glbDataView.setUint32(curOffset + 0, 0x46546c67, true) // "glTF" magic
        glbDataView.setUint32(curOffset + 4, 0x00000002, true) // version
        glbDataView.setUint32(curOffset + 8, glbData.byteLength, true) // fileLength
        curOffset += headerSize

        // Write JSON chunk:
        glbDataView.setUint32(curOffset + 0, jsonAlignedLength, true) // chunkLength (excludes header)
        glbDataView.setUint32(curOffset + 4, 0x4e4f534a, true) // chunkType "JSON"
        glbData.set(jsonArray, curOffset + 8)
        curOffset += chunkHeaderSize + jsonArray.byteLength
        // pad JSON chunk with spaces
        for (let n = jsonArray.byteLength; n < jsonAlignedLength; n++) {
            glbDataView.setUint8(curOffset++, 0x20)
        }

        // Write binary chunk:
        glbDataView.setUint32(curOffset + 0, gatheredByteLength, true) // chunkLength (excludes header)
        glbDataView.setUint32(curOffset + 4, 0x004e4942, true) // chunkType "BIN"
        curOffset += chunkHeaderSize

        if (bufferList) {
            for (let bufferID = 0; bufferID < bufferList.length; bufferID++) {
                const array = this.bufferMap.get(bufferID)!
                const byteArray = new Uint8Array(array.buffer, array.byteOffset, array.byteLength)
                glbData.set(byteArray, curOffset + gatheredOffsets[bufferID])
            }
        }

        return glbData
    }
}
