<input
    class="cm-input"
    #inputField
    [type]="type"
    [required]="required"
    [disabled]="disabled"
    [class.cm-show-invalid]="showInvalid"
    [placeholder]="placeholder ? placeholder : ''"
    [value]="value ? value : null"
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    (change)="value = inputField.value; valueChange.emit(value)"
    (keyup)="value = inputField.value; keyUp.emit(value)"
/>
