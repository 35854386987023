import {Component, OnInit, input} from "@angular/core"

@Component({
    selector: "cm-inspector-section",
    templateUrl: "./inspector-section.component.html",
    styleUrls: ["./inspector-section.component.scss"],
    standalone: true,
})
export class InspectorSectionComponent implements OnInit {
    constructor() {}
    $allowOverflow = input<boolean>(false)

    ngOnInit(): void {}
}
