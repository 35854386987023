import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {deepCopy} from "@cm/lib/utils/utils"
import {
    Operator,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorParameterValue,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {CloneStampPanelComponent} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/panel/clone-stamp-panel.component"
import {CloneStampToolbox} from "app/textures/texture-editor/operator-stack/operators/clone-stamp/toolbox/clone-stamp-toolbox"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {Box2Like} from "@cm/lib/math/box2"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {Vector2Like} from "@cm/lib/math/vector2"
import {ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {getImageDesc} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/get-image-desc-node"
import {createImage} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/create-image-node"
import {copyRegion} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/copy-region-node"
import {drawableImage} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/drawable-image-node"
import {lambda} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {blend} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/blend-node"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {TextureType} from "@api"
import {ImageOpNodeGraphEvaluator} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"

export class OperatorCloneStamp extends OperatorBase<TextureEditNodes.OperatorCloneStamp> {
    readonly panelComponentType: OperatorPanelComponentType = CloneStampPanelComponent
    readonly canvasToolbox: CloneStampToolbox

    readonly type = "operator-clone-stamp" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorCloneStamp | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-clone-stamp",
                enabled: true,
                strokes: [],
            },
        )

        this.canvasToolbox = new CloneStampToolbox(this)

        this.halBlitter = new HalPainterImageBlit(this.callback.halContext)
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this.canvasToolbox.remove()
        this.halBlitter.dispose()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorCloneStamp(this.callback, deepCopy(this.node))
        for (let i = 0; i < clonedOperator.node.strokes.length; i++) {
            const stroke = this.node.strokes[i]
            const clonedStroke = clonedOperator.node.strokes[i]
            const strokeMaskDrawableImageRef = this.getDrawableImageRefByMaskReference(stroke.maskReference)
            const clonedStrokeMaskDrawableImageRef = clonedOperator.getDrawableImageRefByMaskReference(clonedStroke.maskReference)
            this.callback.drawableImageCache.cloneImage(strokeMaskDrawableImageRef, clonedStrokeMaskDrawableImageRef)
        }
        return clonedOperator
    }

    get showMask(): boolean {
        return this._showMask
    }

    set showMask(value: boolean) {
        this._showMask = value
        this.requestEval()
    }

    get canUndoStroke(): boolean {
        return this.node.strokes.length > 0
    }

    undoStroke(): void {
        if (this.node.strokes.length === 0) {
            return
        }
        const lastStroke = this.node.strokes.pop()!
        this.removeDrawableImageRefByMaskReference(lastStroke.maskReference)
        this.markEdited()
        this.requestEval()
    }

    // OperatorBase
    async getImageOpNodeGraph(evaluator: ImageOpNodeGraphEvaluator, textureType: TextureType, input: OperatorInput): Promise<OperatorOutput> {
        const showMaskOnly = evaluator.mode === "preview" && this.selected && this.showMask
        let sourceImage: OperatorParameterValue<ImagePtr>
        let resultImage: OperatorParameterValue<ImagePtr>
        if (showMaskOnly) {
            const sourceImageDesc = getImageDesc({
                sourceImage: input,
            })
            sourceImage = createImage({
                descriptor: sourceImageDesc,
                fillColor: {r: 1, g: 1, b: 1, a: 1},
            })
            resultImage = createImage({
                descriptor: sourceImageDesc,
                fillColor: {r: 0, g: 0, b: 0, a: 1},
            })
        } else {
            sourceImage = input
            resultImage = copyRegion({
                sourceImage: sourceImage,
            })
        }
        const applyStroke = (
            drawableImageRef: DrawableImageRef,
            maskRegion: OperatorParameterValue<TextureEditNodes.Region>,
            sourceOffsetInPixels: Vector2Like,
            sourceImage: OperatorParameterValue<ImagePtr>,
            resultImage: OperatorParameterValue<ImagePtr>,
            optionalMaskStrokeBoundingBox?: Box2Like,
        ): OperatorParameterValue<ImagePtr> => {
            const maskImage = drawableImage({
                drawableImageRef,
                descriptor: lambda({maskRegion}, async ({parameters: {maskRegion}}): Promise<ImageDescriptor> => {
                    return {
                        width: maskRegion.width,
                        height: maskRegion.height,
                        channelLayout: "R",
                        format: TextureEditorSettings.PreviewProcessingImageFormat,
                        isSRGB: false,
                    }
                }),
            })
            // TODO if we had views AND imageOpBlend would allow for resultImage==backgroundImage by utilizing actual alpha-blending
            // TODO we could use the following op instead of all of the ones below
            // resultImage = imageOpList.push(imageOpBlend, {
            //     backgroundImage: resultImage,
            //     foregroundImage: makeImageView(sourceImage, {
            //          x: stroke.sourceOffsetInPixels.x,
            //          y: stroke.sourceOffsetInPixels.y,
            //          width: stroke.maskRegion.width,
            //          height: stroke.maskRegion.height,
            //      }),
            //     alphaImage: maskImage,
            //     premultipliedAlpha: false,
            //     blendMode: "normal",
            //     resultImage: makeImageView(sourceImage, stroke.maskRegion),
            // })
            const hasStrokeBoundingBox = optionalMaskStrokeBoundingBox !== undefined
            const maskStrokeBoundingBox: OperatorParameterValue<Box2Like> = hasStrokeBoundingBox ? deepCopy(optionalMaskStrokeBoundingBox) : maskRegion
            const cutoutShiftedSourceImage = copyRegion({
                sourceImage: sourceImage,
                sourceRegion: lambda({maskRegion, maskStrokeBoundingBox}, async ({parameters: {maskRegion, maskStrokeBoundingBox}}): Promise<Box2Like> => {
                    return {
                        x: sourceOffsetInPixels.x - maskRegion.x + maskStrokeBoundingBox.x,
                        y: sourceOffsetInPixels.y - maskRegion.y + maskStrokeBoundingBox.y,
                        width: maskStrokeBoundingBox.width,
                        height: maskStrokeBoundingBox.height,
                    }
                }),
                addressMode: "wrap",
            })
            const cutoutResultImage = copyRegion({
                sourceImage: resultImage,
                sourceRegion: maskStrokeBoundingBox,
                addressMode: "wrap",
            })
            const cutoutMaskImage = hasStrokeBoundingBox
                ? copyRegion({
                      sourceImage: maskImage,
                      sourceRegion: maskStrokeBoundingBox,
                      addressMode: "wrap",
                  })
                : maskImage
            const stampedRegion = blend({
                backgroundImage: cutoutResultImage,
                foregroundImage: cutoutShiftedSourceImage,
                alpha: cutoutMaskImage,
                premultipliedAlpha: false,
                blendMode: "normal",
            })
            resultImage = copyRegion({
                sourceImage: stampedRegion,
                targetOffset: lambda({maskStrokeBoundingBox}, async ({parameters: {maskStrokeBoundingBox}}): Promise<Vector2Like> => {
                    return {
                        x: maskStrokeBoundingBox.x,
                        y: maskStrokeBoundingBox.y,
                    }
                }),
                addressMode: "wrap",
                resultImage: resultImage,
            })
            return resultImage
        }
        for (const stroke of this.node.strokes) {
            const maskDrawableImageRef = this.getDrawableImageRefByMaskReference(stroke.maskReference)
            resultImage = applyStroke(maskDrawableImageRef, stroke.maskRegion, stroke.sourceOffsetInPixels, sourceImage, resultImage)
        }
        if (evaluator.mode === "preview" && this.canvasToolbox.isStrokeInProgress) {
            const sourceImageDesc = getImageDesc({
                sourceImage: sourceImage,
            })
            resultImage = applyStroke(
                this.canvasToolbox.currentStrokeDrawableImageRef,
                lambda({sourceImageDesc}, async ({parameters: {sourceImageDesc}}): Promise<TextureEditNodes.Region> => {
                    return {
                        type: "region",
                        x: 0,
                        y: 0,
                        width: sourceImageDesc.width,
                        height: sourceImageDesc.height,
                    }
                }),
                this.canvasToolbox.sourceOffset,
                sourceImage,
                resultImage,
                this.canvasToolbox.strokeImageBoundingBox,
            )
        }
        return {
            resultImage,
            options: {
                stopEvaluation: showMaskOnly,
            },
        }
    }

    private getDrawableImageRefByMaskReference(maskReference: TextureEditNodes.MaskReference): DrawableImageRef {
        const drawableImageRef = this.drawableImageRefByMaskReference.get(maskReference)
        if (drawableImageRef) {
            return drawableImageRef
        }
        const newDrawableImageRef = new DrawableImageRef(maskReference, this.callback.customerLegacyId)
        this.drawableImageRefByMaskReference.set(maskReference, newDrawableImageRef)
        return newDrawableImageRef
    }

    private removeDrawableImageRefByMaskReference(maskReference: TextureEditNodes.MaskReference): void {
        const drawableImageRef = this.drawableImageRefByMaskReference.get(maskReference)
        if (!drawableImageRef) {
            return
        }
        this.drawableImageRefByMaskReference.delete(maskReference)
        this.callback.drawableImageCache.removeDrawableImage(drawableImageRef)
    }

    async addCloneStampEdit(): Promise<TextureEditNodes.CloneStampStroke | null> {
        const boundingBox = this.canvasToolbox.strokeImageBoundingBox
        if (boundingBox.isEmpty()) {
            return null
        }
        const cloneStampStroke: TextureEditNodes.CloneStampStroke = {
            maskReference: {
                type: "data-object-reference",
                dataObjectId: "",
            },
            maskRegion: {
                type: "region",
                x: boundingBox.x,
                y: boundingBox.y,
                width: boundingBox.width,
                height: boundingBox.height,
            },
            sourceOffsetInPixels: this.canvasToolbox.sourceOffset.add(boundingBox.position),
        }
        const strokeImage = await this.canvasToolbox.getStrokeImage()
        const cloneStampStrokeMaskDrawableImageRef = this.getDrawableImageRefByMaskReference(cloneStampStroke.maskReference)
        const cutoutMaskRT = await this.callback.drawableImageCache.createDrawableImageFromDescriptor(
            cloneStampStrokeMaskDrawableImageRef,
            {
                width: boundingBox.width,
                height: boundingBox.height,
                channelLayout: strokeImage.descriptor.channelLayout,
                format: strokeImage.descriptor.format,
            },
            {
                useSRgbFormat: strokeImage.options.useSRgbFormat,
                useMipMaps: false,
            },
        )
        await this.halBlitter.paint(cutoutMaskRT, strokeImage, {sourceRegion: boundingBox})
        this.node.strokes.push(cloneStampStroke)
        await this.canvasToolbox.clearStrokeImage()
        this.markEdited()
        this.requestEval()
        return cloneStampStroke
    }

    readonly brushSettings = new BrushSettings()

    private halBlitter: HalPainterImageBlit // TODO replace by image-op
    private _showMask = false
    private drawableImageRefByMaskReference = new Map<TextureEditNodes.MaskReference, DrawableImageRef>()
}
