import {Component, input, output, computed} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatOption} from "@angular/material/autocomplete"
import {MatSelect} from "@angular/material/select"
import {MatFormField, MatLabel} from "@angular/material/form-field"
import {StateLabel} from "@labels"
import {z} from "zod"

@Component({
    selector: "cm-boolean-label-select",
    standalone: true,
    imports: [FormsModule, MatLabel, MatFormField, MatOption, MatSelect],
    templateUrl: "./boolean-label-select.component.html",
    styleUrl: "./boolean-label-select.component.scss",
})
export class BooleanLabelSelectComponent {
    $name = input<string>("Label name")
    $options = input<StateLabel<string>[] | Map<string, StateLabel<string>>>([])
    $value = input<boolean>(false)
    $onChanged = output<boolean>()

    $optionsArray = computed(() => {
        const options = this.$options()
        return (options instanceof Map ? Array.from(options.values()) : options).map((options) => {
            const parsedState = z.union([z.literal("0"), z.literal("1")]).safeParse(options.state)
            if (!parsedState.success) {
                throw new Error(`Invalid state for boolean label: ${options.state}`)
            }

            return {
                state: parsedState.data === "1" ? true : false,
                label: options.label,
            }
        })
    })
}
