import {JobNodes as Nodes, makeJobTaskDescriptor} from "@src/job-task/job-nodes"
import {JsonObjectUtils} from "@src/utils/json-object"
import {MaterialMapsExporter as Exporter} from "@src/materials/material-maps-exporter"

type EntityId = number

export namespace MaterialMapsExporter {
    export type UnwrapJobSystemDataObjectReference = {
        type: "unwrapJobSystemDataObjectReference"
        ref: Nodes.DataObjectReference
    }

    export namespace JobNodes {
        export function createConfigFieldUpdateOpForDataObjectReference<T extends Record<string, any>, U extends Nodes.TypedDataNode<any>>(
            config: Exporter.Config,
            struct: T,
            key: Exporter.RecordKeysForValueType<T, JsonObjectUtils.DataObjectReference>,
            dataObjectRef: U,
            dataObjectAssignmentType: number,
        ) {
            const {found, seq} = Exporter.descendSeqForStructOrArray(config, struct)
            if (!found) throw Error("Failed to build the descend sequence")

            const updateOp: Nodes.TypedDataNode<Exporter.UpdateOp> = Nodes.struct({
                type: Nodes.value(Exporter.UPDATE_OP),
                descendSeq: Nodes.value(seq),
                update: Nodes.struct({
                    type: Nodes.value("StructFieldUpdate" as const),
                    key: Nodes.value(key),
                    value: Nodes.struct({
                        type: Nodes.value("jsonObjectDataObjectReference"),
                        dataObjectId: Nodes.struct({
                            type: Nodes.value("unwrapJobSystemDataObjectReference"),
                            ref: dataObjectRef,
                        }),
                        dataObjectAssignmentType: Nodes.value(dataObjectAssignmentType),
                    }),
                }),
            })
            return updateOp
        }
    }
}

export type MaterialMapsExporterUpdateExportConfigInput = {
    jsonObjectId: EntityId
    updateOps: Exporter.UpdateOp[]
}

export const materialMapsExporterUpdateExportConfigTask = makeJobTaskDescriptor<MaterialMapsExporterUpdateExportConfigInput, {}>(
    "MaterialMapsExporter.updateExportConfig",
    1,
)
