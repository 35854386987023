<cm-list-item (click)="selectOperator()" [selected]="selected">
    <ng-container cm-pre-title>
        <div [class.cm-locked]="locked || !enabled || isInactive" class="cm-title-icons-container">
            <i [class]="icon"></i>
        </div>
    </ng-container>
    <ng-container cm-title>
        <div class="cm-title" [class.cm-locked]="locked || !enabled || isInactive">
            <span class="cm-list-item-title-text">{{ label }}{{ edited ? " *" : "" }}</span>
            @if (locked) {
                <i class="far fa-lock"></i>
            }
            @if (!enabled) {
                <i class="far fa-eye-slash"></i>
            }
        </div>
    </ng-container>
    @if (!disabled && !isInternalOperator) {
        <ng-container cm-icons>
            <i
                [matTooltip]="locked ? 'Unlock operator' : 'Lock operator'"
                (click)="toggleLocked()"
                class="far"
                [class.fa-lock]="!locked"
                [class.fa-unlock]="locked"
            ></i>
            @if (canDisable) {
                <i
                    [matTooltip]="enabled ? 'Hide operator' : 'Show operator'"
                    (click)="toggleEnabled()"
                    class="far"
                    [class.fa-eye-slash]="enabled"
                    [class.fa-eye]="!enabled"
                >
                </i>
            }
            @if (canDuplicate) {
                <i matTooltip="Clone operator" (click)="duplicateOperator(); $event.preventDefault(); $event.stopPropagation()" class="far fa-copy"> </i>
            }
            <i matTooltip="Remove operator" (click)="deleteOperator()" class="far fa-trash cm-remove-operator"> </i>
        </ng-container>
    }
</cm-list-item>
