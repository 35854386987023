import {Component, ElementRef, HostListener, OnDestroy, OnInit, effect, inject, input, signal, computed, DestroyRef, viewChild} from "@angular/core"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls"
import {TransformControls} from "three/examples/jsm/controls/TransformControls"
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer"
import {OutputPass} from "three/examples/jsm/postprocessing/OutputPass"
import * as THREE from "three"
import {
    ThreeObjectPart,
    getNextThreeObjectPart,
    getThreeObjectPart,
    mathIsEqual,
    threeObjectPartToSceneNodePart,
} from "@app/template-editor/helpers/three-object"
import {OutlinePass} from "three/examples/jsm/postprocessing/OutlinePass"
import {SceneManagerService, TemplateNodePart} from "@app/template-editor/services/scene-manager.service"
import {Matrix4, Vector3} from "@app/common/helpers/vector-math"
import {Subject, concatMap, defer, from, range, switchMap, timer} from "rxjs"
import {Camera} from "@cm/lib/templates/nodes/camera"
import {AreaLight} from "@cm/lib/templates/nodes/area-light"
import {CameraParameters, MAX_FAR_CLIP, MIN_NEAR_CLIP, updateThreeCamera} from "@app/template-editor/helpers/three-utils"
import {NgStyle} from "@angular/common"
import {IMatrix4} from "@cm/lib/templates/interfaces/matrix"
import {nodeFrame} from "three/examples/jsm/renderers/webgl-legacy/nodes/WebGLNodes.js"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {GlobalRenderConstants, SceneNodes, ToneMappingData} from "@cm/lib/templates/interfaces/scene-object"
import {colorToString} from "@cm/lib/utils/utils"
import {SceneViewRenderPass} from "@app/template-editor/helpers/scene-view-render-pass"
import {ThreeAnnotationRenderer} from "../three-annotation-renderer/three-annotation-renderer"
import {arrayDifferent, objectDifferent, objectFieldsDifferent} from "@app/template-editor/helpers/change-detection"
import {
    TemplateDropTarget,
    TemplateNodeDragService,
    getTemplateNodePartFromPosition,
    getVectorFromPosition,
} from "@app/template-editor/services/template-node-drag.service"
import {isMaterialLike, isMesh, isObject} from "@cm/lib/templates/node-types"
import {MaterialAssignment} from "@cm/lib/templates/nodes/material-assignment"
import {getNodeOwner} from "@cm/lib/templates/utils"
import {ToneMappingRenderPass} from "@app/template-editor/helpers/tone-mapping-render-pass"

const TAA_FRAMES = 64

type Dimensions = {
    width: number
    height: number
}

function findScrollableParent(element: HTMLElement): HTMLElement | null {
    let parent = element.parentElement
    while (parent && parent !== document.body) {
        if (parent.scrollHeight > parent.clientHeight) return parent
        parent = parent.parentElement
    }

    return null
}

export type SceneCameraParameters = CameraParameters &
    Pick<
        SceneNodes.Camera,
        | "target"
        | "targeted"
        | "autoFocus"
        | "focalDistance"
        | "enablePanning"
        | "screenSpacePanning"
        | "minPolarAngle"
        | "maxPolarAngle"
        | "minAzimuthAngle"
        | "maxAzimuthAngle"
        | "minDistance"
        | "maxDistance"
        | "fStop"
        | "toneMapping"
        | "exposure"
    >
export type SceneCamera = {parameters: SceneCameraParameters; transform?: IMatrix4; ignoreAspectRatio?: boolean}

type ThreeObjectPartClickEventTarget = {
    threeObjectPart: ThreeObjectPart
    intersection: THREE.Intersection<THREE.Object3D>
}

@Component({
    selector: "cm-three-template-scene-viewer",
    standalone: true,
    imports: [NgStyle],
    templateUrl: "./three-template-scene-viewer.component.html",
    styleUrl: "./three-template-scene-viewer.component.scss",
})
export class ThreeTemplateSceneViewerComponent implements OnInit, OnDestroy {
    sceneManagerService = inject(SceneManagerService)
    private threeSceneManagerService = inject(ThreeSceneManagerService)
    drag = inject(TemplateNodeDragService)

    private canvas = viewChild.required<ElementRef<HTMLDivElement>>("canvas")
    private controls = viewChild.required<ElementRef<HTMLDivElement>>("controls")
    private annotations = viewChild.required<ElementRef<HTMLDivElement>>("annotations")
    private canvasContainer = viewChild.required<ElementRef<HTMLDivElement>>("canvasContainer")

    private destroyRef = inject(DestroyRef)
    isDestroyed = false
    scrollableParent: HTMLElement | null = null

    private defaultCameraSettings = {
        nearClip: MIN_NEAR_CLIP,
        farClip: MAX_FAR_CLIP,
        filmGauge: 36,
        focalLength: 50,
        shiftX: 0,
        shiftY: 0,
    }
    private threeCamera: THREE.PerspectiveCamera

    private composer!: EffectComposer
    private sceneViewRenderPass!: SceneViewRenderPass
    private toneMappingRenderPass!: ToneMappingRenderPass
    private outputPass!: OutputPass
    private outlinePass!: OutlinePass
    private annotationRenderer!: ThreeAnnotationRenderer

    private orbitControls!: OrbitControls
    private transformControls!: TransformControls
    private transformObject = new THREE.Group()
    private $transformIsDragging = signal(false)

    private raycaster = new THREE.Raycaster()

    private $containerDimensions = signal<Dimensions>({width: 1, height: 1})
    $renderDimensions = computed(() => {
        const selectedCameraNode = this.$camera()
        const {width, height} = this.$containerDimensions()

        if (selectedCameraNode && selectedCameraNode.ignoreAspectRatio !== true) {
            const getCameraCutout = () => {
                const {aspectRatio} = selectedCameraNode.parameters

                const w1 = width
                const h1 = w1 / aspectRatio
                if (h1 <= height) return {x: 0, y: (height - h1) / 2, width: w1, height: h1}

                const h2 = height
                const w2 = h2 * aspectRatio
                if (w2 <= width) return {x: (width - w2) / 2, y: 0, width: w2, height: h2}

                throw new Error("Invalid aspect ratio")
            }

            const cutOut = getCameraCutout()
            return {width: cutOut.width, height: cutOut.height}
        }

        return {
            width,
            height,
        }
    })

    private updatePendingFrameId: number | null = null

    private renderingFinished = new Subject<void>()

    $camera = input.required<SceneCamera | undefined>({alias: "camera"})
    $allowEdit = input<boolean>(true, {alias: "allowEdit"})

    constructor() {
        this.threeCamera = new THREE.PerspectiveCamera()
        this.threeCamera.position.set(-200, 150, 200)
        this.threeCamera.lookAt(0, 0, 0)

        effect(() => {
            const {width, height} = this.$renderDimensions()
            this.outlinePass.setSize(width, height)
            this.composer.setSize(width, height)
            this.annotationRenderer.setSize(width, height)

            this.render()
        })

        let previousOutlineObjects: ThreeObjectPart[] = []
        effect(() => {
            const hoveredObjects = this.threeSceneManagerService.$hoveredObjects()
            const outlineObjects = hoveredObjects.length !== 0 ? hoveredObjects : this.threeSceneManagerService.$selectedObjects()

            if (arrayDifferent(outlineObjects, previousOutlineObjects, (a, b) => a.threeObject === b.threeObject && a.part === b.part)) {
                const outlineSelectedObjects = outlineObjects
                    .map((threeObjectPart) => {
                        const {threeObject, part} = threeObjectPart
                        const renderObject = threeObject.getRenderObject()

                        const objects: THREE.Object3D[] = []
                        const queryTreeObjectPart = (object: THREE.Object3D) => {
                            const threeObjectPart = getThreeObjectPart(object)
                            if (threeObjectPart && threeObjectPart.threeObject === threeObject && threeObjectPart.part === part) {
                                objects.push(object)
                                return
                            }

                            object.children.forEach((child) => queryTreeObjectPart(child))
                        }
                        queryTreeObjectPart(renderObject)

                        return objects
                    })
                    .flat()

                this.outlinePass.selectedObjects = outlineSelectedObjects
                this.outlinePass.enabled = this.outlinePass.selectedObjects.length > 0

                this.render()
            }

            previousOutlineObjects = outlineObjects
        })

        effect(() => {
            const transformIsDragging = this.$transformIsDragging()
            if (!transformIsDragging) this.propagateTransformedNodeToObject()
        })

        const isOrbitEnabled = () =>
            this.$camera()?.parameters?.targeted !== false &&
            (this.threeSceneManagerService.$displayMode() === "configurator" || (this.$camera()?.transform === undefined && !this.$transformIsDragging()))
        effect(() => {
            this.orbitControls.enabled = isOrbitEnabled()
        })

        const previousCameraDifferent = (
            current: SceneCameraParameters | undefined,
            previous: SceneCameraParameters | undefined,
            fields: (keyof SceneCameraParameters)[],
        ) => {
            function isToneMappingData(obj: object): obj is ToneMappingData {
                return "mode" in obj
            }

            if (current === undefined) return previous !== undefined
            else
                return objectFieldsDifferent(current, previous, fields, (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") {
                        if (!isToneMappingData(valueA) && !isToneMappingData(valueB)) return mathIsEqual(valueA, valueB)
                        else if (isToneMappingData(valueA) && isToneMappingData(valueB)) return !objectDifferent(valueA, valueB, undefined)
                        else return false
                    }

                    return valueA === valueB
                })
        }
        const previousTransformDifferent = (current: IMatrix4 | undefined, previous: IMatrix4 | undefined) => {
            if (current === undefined) return previous !== undefined
            else if (previous === undefined) return true
            else return !mathIsEqual(current, previous)
        }

        let previousCamera: SceneCamera | undefined = undefined
        effect(() => {
            const camera = this.$camera()
            const parameters = camera?.parameters
            const transform = camera?.transform

            let orbitControlNeedsUpdate = false

            const orbitEnabled = isOrbitEnabled()

            const previousCameraTransform = this.threeCamera.matrix
            const renderDimensions = this.$renderDimensions()
            if (camera) {
                if (this.threeSceneManagerService.$displayMode() === "editor" && previousCamera === undefined) this.orbitControls.saveState()
                const newTransform = previousTransformDifferent(transform, previousCamera?.transform) ? transform : undefined
                updateThreeCamera(this.threeCamera, {...camera.parameters, aspectRatio: renderDimensions.width / renderDimensions.height}, newTransform)
                this.clear()
            } else {
                updateThreeCamera(this.threeCamera, {...this.defaultCameraSettings, aspectRatio: renderDimensions.width / renderDimensions.height})
                if (orbitEnabled && this.threeSceneManagerService.$displayMode() === "editor" && previousCamera !== undefined) this.orbitControls.reset()
            }

            if (!previousCameraTransform.equals(this.threeCamera.matrix)) orbitControlNeedsUpdate = true

            if (previousCameraDifferent(parameters, previousCamera?.parameters, ["target"])) {
                const target = parameters?.target
                if (target) {
                    this.orbitControls.target = new THREE.Vector3(target.x, target.y, target.z)
                    orbitControlNeedsUpdate = true
                }
            }

            if (
                previousCameraDifferent(parameters, previousCamera?.parameters, [
                    "enablePanning",
                    "screenSpacePanning",
                    "minPolarAngle",
                    "maxPolarAngle",
                    "minAzimuthAngle",
                    "maxAzimuthAngle",
                    "minDistance",
                    "maxDistance",
                ])
            ) {
                this.orbitControls.enablePan = parameters?.enablePanning ?? true
                this.orbitControls.screenSpacePanning = parameters?.screenSpacePanning ?? true
                this.orbitControls.minPolarAngle = (parameters?.minPolarAngle ?? 0) * (Math.PI / 180)
                this.orbitControls.maxPolarAngle = (parameters?.maxPolarAngle ?? 180) * (Math.PI / 180)
                this.orbitControls.minAzimuthAngle = (parameters?.minAzimuthAngle ?? -Infinity) * (Math.PI / 180)
                this.orbitControls.maxAzimuthAngle = (parameters?.maxAzimuthAngle ?? Infinity) * (Math.PI / 180)
                this.orbitControls.minDistance = parameters?.minDistance ?? 0
                this.orbitControls.maxDistance = parameters?.maxDistance ?? 10000
                orbitControlNeedsUpdate = true
            }

            if (orbitControlNeedsUpdate) {
                if (orbitEnabled) {
                    this.orbitControls.update()
                    this.onOrbitChanged()
                    //enables reset of the camera through configurator api
                    if (this.threeSceneManagerService.$displayMode() === "configurator") this.orbitControls.saveState()
                }
                this.render()
            }

            if (previousCameraDifferent(parameters, previousCamera?.parameters, ["toneMapping"])) {
                const toneMapping = parameters?.toneMapping
                this.toneMappingRenderPass.setToneMapping(toneMapping ?? {mode: "linear"})
                this.render()
            }

            if (previousCameraDifferent(parameters, previousCamera?.parameters, ["exposure"])) {
                const exposure = parameters?.exposure ?? 1
                this.toneMappingRenderPass.setExposure(exposure * GlobalRenderConstants.exposureScale)
                this.render()
            }

            if (
                previousCameraDifferent(parameters, previousCamera?.parameters, ["focalDistance", "focalLength", "fStop"]) ||
                previousTransformDifferent(transform, previousCamera?.transform)
            ) {
                if (!parameters) this.sceneViewRenderPass.setDepthOfField(undefined)
                else {
                    const {focalDistance, focalLength, fStop} = parameters

                    if (!transform) this.sceneViewRenderPass.setDepthOfField(undefined)
                    else {
                        this.sceneViewRenderPass.setDepthOfField({
                            apertureSize: (focalLength * 0.1) / fStop,
                            focalDistance,
                        })
                    }
                }

                this.render()
            }

            previousCamera = camera
        })

        effect(() => {
            this.transformControls.setMode(this.sceneManagerService.$transformMode())
        })

        effect(() => {
            const scene = this.sceneManagerService.$scene()
            const sceneOptions = scene.find(SceneNodes.SceneOptions.is)
            const backgroundColor = sceneOptions?.backgroundColor

            const bgColorString = !backgroundColor ? "transparent" : colorToString(backgroundColor)
            this.canvas().nativeElement.style.background = bgColorString
        })

        effect(() => {
            const allowEdit = this.$allowEdit()
            if (allowEdit) {
                this.composer.addPass(this.outlinePass)
                this.threeSceneManagerService.modifyBaseScene((scene) => {
                    scene.add(this.transformControls)
                    scene.add(this.transformObject)
                })
            } else {
                this.composer.removePass(this.outlinePass)
                this.threeSceneManagerService.modifyBaseScene((scene) => {
                    scene.remove(this.transformControls)
                    scene.remove(this.transformObject)
                })
            }
        })
    }

    private propagateTransformedNodeToObject() {
        const transformedNodeParts = this.sceneManagerService.$transformedNodeParts()

        if (transformedNodeParts.length === 0) {
            if (this.transformControls.object) {
                this.transformControls.detach()
                this.render()
            }
        } else {
            const transformedNodePart = transformedNodeParts[0]

            const {templateNode, part} = transformedNodePart

            const getMatrixData = () => {
                if (part === "target" && (templateNode instanceof Camera || templateNode instanceof AreaLight)) {
                    const {target} = templateNode.parameters
                    return new THREE.Matrix4().setPosition(new THREE.Vector3(...target))
                }

                const matrixData = templateNode.parameters.lockedTransform ?? templateNode.parameters.$defaultTransform
                return matrixData ? new THREE.Matrix4().fromArray(matrixData) : new THREE.Matrix4()
            }

            const newMatrix = getMatrixData()

            if (this.transformControls.object !== this.transformObject || !newMatrix.equals(this.transformObject.matrix)) {
                this.transformObject.matrix = newMatrix
                this.transformObject.matrix.decompose(this.transformObject.position, this.transformObject.quaternion, this.transformObject.scale)

                this.transformControls.attach(this.transformObject)
                this.render()
            }
        }
    }

    private transformControlsDraggingChanged = (
        event: {
            value: unknown
        } & THREE.Event<"dragging-changed", TransformControls>,
    ) => {
        const started: boolean = event.value as boolean
        this.$transformIsDragging.set(started)

        if (started) this.sceneManagerService.beginModifyTemplateGraph()
        else this.sceneManagerService.endModifyTemplateGraph()

        this.transformControls.visible = !started
        if (this.transformControls.visible) this.propagateTransformedNodeToObject()
    }

    private transformControlsObjectChanged = () => {
        this.sceneManagerService.transformNodes(Matrix4.fromThreeMatrix(this.transformObject.matrix))
    }

    private scrolled = () => {
        this.render()
    }

    ngOnInit() {
        this.scrollableParent = findScrollableParent(this.canvasContainer().nativeElement)
        if (this.scrollableParent) this.scrollableParent.addEventListener("scroll", this.scrolled)

        this.threeSceneManagerService.requestedRedraw$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.render())

        const renderer = this.threeSceneManagerService.getRenderer()

        this.composer = new EffectComposer(renderer)

        this.sceneViewRenderPass = new SceneViewRenderPass(this.threeSceneManagerService.getSceneReference(), this.threeCamera)
        this.composer.addPass(this.sceneViewRenderPass)

        this.toneMappingRenderPass = new ToneMappingRenderPass({mode: "linear"}, GlobalRenderConstants.exposureScale)
        this.composer.addPass(this.toneMappingRenderPass)

        this.outputPass = new OutputPass()
        this.composer.addPass(this.outputPass)

        const {width, height} = this.$renderDimensions()
        this.outlinePass = new OutlinePass(new THREE.Vector2(width, height), this.threeSceneManagerService.getSceneReference(), this.threeCamera)
        this.outlinePass.visibleEdgeColor = new THREE.Color(0x03a9f4)
        this.outlinePass.hiddenEdgeColor = new THREE.Color(0x03a9f4)
        this.outlinePass.edgeStrength = 4
        this.outlinePass.edgeThickness = 1
        this.outlinePass.edgeGlow = 0.25
        this.outlinePass.overlayMaterial.blending = THREE.CustomBlending

        const controls = this.controls()
        this.orbitControls = new OrbitControls(this.threeCamera, controls.nativeElement)
        this.orbitControls.addEventListener("change", this.onOrbitChanged)
        this.threeSceneManagerService.requestedRedraw$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.onOrbitChanged())
        this.transformControls = new TransformControls(this.threeCamera, controls.nativeElement)
        this.transformControls.size = 2.0 / 5.0
        this.transformControls.addEventListener("dragging-changed", this.transformControlsDraggingChanged)
        this.transformControls.addEventListener("objectChange", this.transformControlsObjectChanged)
        this.transformControls.addEventListener("change", this.render)

        this.annotationRenderer = new ThreeAnnotationRenderer({element: this.annotations().nativeElement})

        this.resizeView()

        const taaUpdate = async (iteration: number) => {
            return new Promise<void>((resolve) => {
                requestAnimationFrame(() => {
                    this.renderInRect(() => {
                        this.sceneViewRenderPass.taaMode = true
                        this.composer.render()
                        this.sceneViewRenderPass.taaMode = false
                    })
                    resolve()
                })
            })
        }

        //If for 100ms there are no other rendering finished events, start accumulating 32 TAA, stop if another rendering finished event is emitted
        this.renderingFinished
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() => timer(100).pipe(switchMap(() => range(TAA_FRAMES).pipe(concatMap((iteration) => defer(() => from(taaUpdate(iteration)))))))),
            )
            .subscribe()

        this.drag.draggedItem$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({dragSource, dropTarget}) => {
            const {component, position} = dropTarget
            if (component === this) {
                const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
                if (!draggedNode) return

                if (isMaterialLike(draggedNode)) {
                    this.sceneManagerService.hoverNode(undefined)

                    const {templateNode, part} = getTemplateNodePartFromPosition(position)
                    if (isMesh(templateNode)) {
                        const match = part.match(/group(\d+)$/)

                        if (match && match.length > 1) {
                            const slot = match[1]

                            const materialAssignment = templateNode.parameters.materialAssignments.parameters[slot]
                            if (materialAssignment)
                                this.sceneManagerService.modifyTemplateGraph(() =>
                                    templateNode.parameters.materialAssignments.updateParameters({
                                        [slot]: materialAssignment.clone({cloneSubNode: () => true, parameterOverrides: {node: draggedNode}}),
                                    }),
                                )
                            else
                                this.sceneManagerService.modifyTemplateGraph(() =>
                                    templateNode.parameters.materialAssignments.updateParameters({
                                        [slot]: new MaterialAssignment({node: draggedNode, side: "front"}),
                                    }),
                                )
                        }
                    }
                } else if (isObject(draggedNode)) {
                    this.threeSceneManagerService.$cursorPosition.set(undefined)

                    const vector = getVectorFromPosition(position)

                    if (!getNodeOwner(draggedNode)) {
                        const transform = new Matrix4()
                        transform.setTranslation(vector)

                        this.sceneManagerService.modifyTemplateGraph((templateGraph) =>
                            templateGraph.parameters.nodes.addEntry(
                                draggedNode.clone({cloneSubNode: () => true, parameterOverrides: {lockedTransform: transform.toArray()}}),
                            ),
                        )
                    } else {
                        const threeMatrix = (new Matrix4(draggedNode.parameters.lockedTransform) ?? new Matrix4()).toThreeMatrix()

                        const translation = new THREE.Vector3()
                        const rotation = new THREE.Quaternion()
                        const scale = new THREE.Vector3()
                        threeMatrix.decompose(translation, rotation, scale)
                        translation.set(vector.x, vector.y, vector.z)

                        threeMatrix.compose(translation, rotation, scale)

                        this.sceneManagerService.modifyTemplateGraph(() => draggedNode.updateParameters({lockedTransform: threeMatrix.toArray()}))
                    }
                }
            }
        })
    }

    clear() {
        this.renderInRect((renderer) => renderer.clear(), this.getCanvasContainerRect())
    }

    ngOnDestroy() {
        this.clear()

        this.threeSceneManagerService.modifyBaseScene((scene) => {
            scene.remove(this.transformControls)
            scene.remove(this.transformObject)
        })
        this.transformControls.removeEventListener("dragging-changed", this.transformControlsDraggingChanged)
        this.transformControls.removeEventListener("objectChange", this.transformControlsObjectChanged)
        this.transformControls.removeEventListener("change", this.render)
        this.transformControls.dispose()
        this.orbitControls.removeEventListener("change", this.onOrbitChanged)
        this.orbitControls.dispose()
        this.composer.dispose()
        this.outlinePass.dispose()
        this.outputPass.dispose()
        this.toneMappingRenderPass.dispose()
        this.sceneViewRenderPass.dispose()
        if (this.scrollableParent) this.scrollableParent.removeEventListener("scroll", this.scrolled)

        this.isDestroyed = true
    }

    private previousDrawRect: DOMRect | null = null
    private renderInRect(callback: (renderer: THREE.WebGLRenderer) => void, rect?: DOMRect) {
        if (this.isDestroyed) return

        const renderer = this.threeSceneManagerService.getRenderer()

        const previousScissorTest = renderer.getScissorTest()
        const previousViewport = new THREE.Vector4()
        renderer.getViewport(previousViewport)
        const previousScissor = new THREE.Vector4()
        renderer.getScissor(previousScissor)

        const scrollableParentRect = this.getScrollableParentRect()
        const clientRect = rect ?? this.getCanvasRect()
        const rendererRect = renderer.domElement.getBoundingClientRect()

        const x = clientRect.left - rendererRect.left
        const y = rendererRect.height - clientRect.height - (clientRect.top - rendererRect.top)

        renderer.setScissorTest(true)
        renderer.setViewport(x, y, clientRect.width, clientRect.height)

        const drawRect = ((): DOMRect => {
            if (scrollableParentRect) {
                const outsideLeft = Math.max(0, scrollableParentRect.left - clientRect.left)
                const outsideTop = Math.max(0, scrollableParentRect.top - clientRect.top)
                const outsideRight = Math.max(0, clientRect.right - scrollableParentRect.right)
                const outsideBottom = Math.max(0, clientRect.bottom - scrollableParentRect.bottom)

                return new DOMRect(
                    Math.max(x + outsideLeft, 0),
                    Math.max(y + outsideBottom, 0),
                    Math.max(clientRect.width - outsideRight, 0),
                    Math.max(clientRect.height - outsideTop, 0),
                )
            } else return new DOMRect(Math.max(x, 0), Math.max(y, 0), clientRect.width, clientRect.height)
        })()

        if (this.previousDrawRect) {
            const {x, y, width, height} = drawRect
            const {x: previousX, y: previousY, width: previousWidth, height: previousHeight} = this.previousDrawRect
            if (x !== previousX || y !== previousY || width !== previousWidth || height !== previousHeight) {
                renderer.setScissor(previousX, previousY, previousWidth, previousHeight)
                renderer.clear()
            }
        }

        renderer.setScissor(drawRect.x, drawRect.y, drawRect.width, drawRect.height)

        callback(renderer)
        this.previousDrawRect = drawRect

        renderer.setScissor(previousScissor.x, previousScissor.y, previousScissor.z, previousScissor.w)
        renderer.setViewport(previousViewport.x, previousViewport.y, previousViewport.z, previousViewport.w)
        renderer.setScissorTest(previousScissorTest)
    }

    private onOrbitChanged = () => {
        if (this.orbitControls.enabled) {
            if (this.threeSceneManagerService.$displayMode() === "configurator") {
                const camera = this.$camera()
                if (camera) {
                    const {parameters} = camera
                    if (parameters.autoFocus) {
                        this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.threeCamera)

                        const intersections = this.raycaster.intersectObjects(this.threeSceneManagerService.getSceneReference().children, true)

                        if (intersections.length > 0) {
                            const intersection = intersections[0]
                            const {distance: focalDistance} = intersection

                            const {focalLength, fStop} = parameters

                            this.sceneViewRenderPass.setDepthOfField({
                                apertureSize: (focalLength * 0.1) / fStop,
                                focalDistance,
                            })
                        }
                    }
                }
            }

            this.render()
        }
    }

    private render = () => {
        if (this.updatePendingFrameId !== null) return
        else {
            this.updatePendingFrameId = requestAnimationFrame(() => {
                nodeFrame.update()
                console.log("render")

                this.renderInRect(() => this.composer.render())
                this.annotationRenderer.render(this.threeSceneManagerService.getSceneReference(), this.threeCamera)
                this.renderingFinished.next()

                this.updatePendingFrameId = null
            })
        }
    }

    private getCanvasRect() {
        return this.canvas().nativeElement.getBoundingClientRect()
    }

    private getCanvasContainerRect() {
        return this.canvasContainer().nativeElement.getBoundingClientRect()
    }

    private getScrollableParentRect() {
        return this.scrollableParent?.getBoundingClientRect()
    }

    private resizeView() {
        const {width, height} = this.getCanvasContainerRect()
        this.$containerDimensions.set({width, height})
    }

    @HostListener("window:resize", ["$event"]) onResize(event: Event) {
        this.resizeView()
    }

    @HostListener("mousedown", ["$event"])
    onMouseDown(downEvent: MouseEvent) {
        const {nativeElement} = this.canvas()
        let isDragging = false

        const onMouseMove = (moveEvent: MouseEvent) => {
            isDragging = true
        }

        const onMouseUp = (upEvent: MouseEvent): void => {
            if (!isDragging) {
                const rect = nativeElement.getBoundingClientRect()
                this.onMouseClick(upEvent.clientX - rect.left, upEvent.clientY - rect.top, upEvent.shiftKey, upEvent.ctrlKey)
            }

            nativeElement.removeEventListener("mousemove", onMouseMove)
            nativeElement.removeEventListener("mouseup", onMouseUp)
        }

        nativeElement.addEventListener("mousemove", onMouseMove)
        nativeElement.addEventListener("mouseup", onMouseUp)
    }

    @HostListener("mousemove", ["$event"])
    onMouseMove(moveEvent: MouseEvent) {
        if (this.sceneManagerService.watchingForClickedTemplateNodePart()) {
            const {nativeElement} = this.canvas()
            const rect = nativeElement.getBoundingClientRect()
            this.sceneManagerService.hoverNode(
                this.getTemplateNodePartUnderCursor(moveEvent.clientX - rect.left, moveEvent.clientY - rect.top, false)?.templateNodePart,
            )
        }
    }

    protected onMouseClick(pointX: number, pointY: number, shiftKey: boolean, ctrlKey: boolean) {
        if (!this.$allowEdit() && !this.sceneManagerService.watchingForClickedTemplateNodePart()) return

        if (this.sceneManagerService.watchingForClickedTemplateNodePart()) this.sceneManagerService.hoverNode(undefined)

        const templateNodePartUnderCursor = this.getTemplateNodePartUnderCursor(pointX, pointY, false)

        this.sceneManagerService.handleClickEvent({
            target: templateNodePartUnderCursor,
            modifiers: {shiftKey, ctrlKey},
        })
    }

    protected getObjectsUnderCursor(pointX: number, pointY: number, meshGroups: boolean): ThreeObjectPartClickEventTarget[] {
        const {width, height} = this.getCanvasRect()
        const x = (pointX / width) * 2 - 1
        const y = -((pointY / height) * 2 - 1)

        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.threeCamera)

        const intersectedObjects: ThreeObjectPartClickEventTarget[] = []
        this.raycaster
            .intersectObjects(this.threeSceneManagerService.getSceneReference().children, true)
            .filter(
                (intersection) => intersection.object.visible && intersection.distance > this.threeCamera.near && intersection.distance < this.threeCamera.far,
            )
            .forEach((intersection) => {
                const {object} = intersection
                const threeObjectPart = getNextThreeObjectPart(object)
                if (threeObjectPart) {
                    const mappedThreeObjectPart = (threeObjectPart: ThreeObjectPart): ThreeObjectPart => {
                        if (!meshGroups) {
                            const {part} = threeObjectPart
                            if (part.match(/group\d+/)) return {threeObject: threeObjectPart.threeObject, part: "root"}
                        }

                        return threeObjectPart
                    }

                    intersectedObjects.push({threeObjectPart: mappedThreeObjectPart(threeObjectPart), intersection})
                }
            })

        return intersectedObjects
    }

    protected getTemplateNodePartUnderCursor(pointX: number, pointY: number, meshGroups: boolean) {
        const objectsUnderCursor = this.getObjectsUnderCursor(pointX, pointY, meshGroups)

        const targetPriorizedObjectsUnderCursor = [
            ...objectsUnderCursor.filter(({threeObjectPart}) => threeObjectPart.part === "target"),
            ...objectsUnderCursor.filter(({threeObjectPart}) => threeObjectPart.part !== "target"),
        ]

        for (const {threeObjectPart, intersection} of targetPriorizedObjectsUnderCursor) {
            const templateNodePart = this.sceneManagerService.sceneNodePartToTemplateNodePart(threeObjectPartToSceneNodePart(threeObjectPart))
            if (templateNodePart)
                return {
                    templateNodePart,
                    intersection,
                }
        }

        return null
    }

    dragOver(event: DragEvent) {
        const dragSource = this.drag.dragSource()
        if (!dragSource) return

        const draggedNode = this.drag.draggableSourceToTemplateNode(dragSource)
        if (!draggedNode) return

        if (isMaterialLike(draggedNode)) {
            this.drag.dropTarget.update((previous) => {
                const {nativeElement} = this.canvas()
                const rect = nativeElement.getBoundingClientRect()
                const templateNodePartUnderCursor = this.getTemplateNodePartUnderCursor(event.clientX - rect.left, event.clientY - rect.top, true)

                if (!templateNodePartUnderCursor) {
                    this.sceneManagerService.hoverNode(undefined)
                    return null
                }

                const {templateNodePart} = templateNodePartUnderCursor
                const {templateNode, part} = templateNodePart

                if (!isMesh(templateNode)) {
                    this.sceneManagerService.hoverNode(undefined)
                    return null
                }

                this.sceneManagerService.hoverNode(templateNodePart)

                const match = part.match(/group(\d+)$/)

                if (!match || match.length <= 1) return null

                const slot = match[1]
                const materialAssignment = templateNode.parameters.materialAssignments.parameters[slot]

                if (materialAssignment && materialAssignment.parameters.node === draggedNode) return null

                const dropTarget = {component: this, position: templateNodePart} as TemplateDropTarget<ThreeTemplateSceneViewerComponent>
                const templateNodePartEqual = (a: TemplateNodePart, b: TemplateNodePart) => a.part === b.part && a.templateNode === b.templateNode

                if (
                    previous &&
                    previous.component === dropTarget.component &&
                    templateNodePartEqual(getTemplateNodePartFromPosition(previous.position), getTemplateNodePartFromPosition(dropTarget.position))
                )
                    return previous

                return dropTarget
            })
        } else if (isObject(draggedNode)) {
            this.drag.dropTarget.update((previous) => {
                const {nativeElement} = this.canvas()
                const rect = nativeElement.getBoundingClientRect()
                const templateNodePartUnderCursor = this.getTemplateNodePartUnderCursor(event.clientX - rect.left, event.clientY - rect.top, false)

                if (!templateNodePartUnderCursor) {
                    this.threeSceneManagerService.$cursorPosition.set(undefined)
                    return null
                }

                const {intersection} = templateNodePartUnderCursor
                const {point} = intersection

                const vector = new Vector3(point.x, point.y, point.z)
                this.threeSceneManagerService.$cursorPosition.set(vector)

                const dropTarget = {component: this, position: vector} as TemplateDropTarget<ThreeTemplateSceneViewerComponent>

                if (
                    previous &&
                    previous.component === dropTarget.component &&
                    mathIsEqual(getVectorFromPosition(previous.position), getVectorFromPosition(dropTarget.position))
                )
                    return previous

                return dropTarget
            })
        } else return

        if (this.drag.dropTarget() !== null) event.preventDefault()
    }

    dragLeave(event: DragEvent) {
        event.stopPropagation()

        const {nativeElement} = this.canvas()

        this.sceneManagerService.hoverNode(undefined)
        this.threeSceneManagerService.$cursorPosition.set(undefined)

        this.drag.dragLeave(event, this, nativeElement)
    }

    zoom(factor: number) {
        if (this.orbitControls.enabled) {
            if (factor < 1) {
                //@ts-ignore
                this.orbitControls.dollyIn(factor)
            } else {
                //@ts-ignore
                this.orbitControls.dollyOut(1.0 / factor)
            }

            this.render()
        } else throw new Error("Orbit controls are disabled, zooming is not possible.")
    }

    resetCamera() {
        if (this.orbitControls.enabled) {
            this.orbitControls.reset()
            this.render()
        } else throw new Error("Orbit controls are disabled, resetting the camera is not possible.")
    }
}
