<h1 mat-dialog-title>{{ result.id ? "Edit" : "Create" }} Group</h1>
<div mat-dialog-content style="display: flex; flex-direction: column; display: flex; gap: 8px; flex-direction: column">
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="result.name" />
    </mat-form-field>
    <mat-form-field>
        <mat-select [(ngModel)]="result.organizationId" placeholder="Organization" [disabled]="organizations.$editOptions().length <= 1">
            @for (option of organizations.$editOptions(); track option) {
                <mat-option [value]="option.state">{{ option.label }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Queue Group</mat-label>
        <input matInput [(ngModel)]="result.queueGroup" />
    </mat-form-field>
    <!-- <mat-form-field>
  <mat-label>Customer</mat-label>
  <input matInput [(ngModel)]="data.jobFarmGroupInfo.???">
  </mat-form-field> -->
    <!-- <mat-form-field>
  <mat-label>Default Timeout (seconds)</mat-label>
  <input matInput type="number" step="1" [(ngModel)]="data.jobFarmGroupInfo.default_timeout">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Max Timeout (seconds)</mat-label>
    <input matInput type="number" step="1" [(ngModel)]="data.jobFarmGroupInfo.max_timeout">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Cancel Timeout (seconds)</mat-label>
      <input matInput type="number" step="1" [(ngModel)]="data.jobFarmGroupInfo.cancel_timeout">
      </mat-form-field> -->
</div>
<div mat-dialog-actions align="end">
    @if (result.id) {
        <button mat-button (click)="delete()">Delete</button>
    }
    <button mat-button (click)="updateOrCreate()">{{ result.id ? "Update" : "Create" }}</button>
    <button mat-button (click)="cancel()" cdkFocusInitial>Cancel</button>
</div>
