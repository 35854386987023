<div class="cm-scanner-main-container" [style.visibility]="!!scannerUrl">
    @if (connectionState === "connected" && notReady) {
        <mat-progress-bar class="cm-progress-bar" mode="indeterminate"></mat-progress-bar>
    }
    <span class="cm-generic-status-message">{{ scanner?.taskMessage }}</span>
    <cm-canvas-base #scanningCanvas [imageUrl]="overviewImageUrl" [physicalInfo]="physicalInfo" (loadingComplete)="imageLoadingComplete()"></cm-canvas-base>
    @if (scanner || this.developmentMode) {
        <cm-scanning-action-bar
            #scanningActionBar
            [scanParams]="developmentMode ? developmentModeData.scanParams : scanner.scanParameters"
            (scanParamsChange)="updateScanParameters()"
            [scanRegion]="selectedScanRegion"
            (materialSelect)="openSelectMaterialDialog()"
            (tableCalibrationSkip)="calibrateTable(true)"
            (tableCalibrationStart)="calibrateTable(false)"
            (tableMove)="moveTable($event)"
            (testImageCapture)="captureTestImage()"
            (overviewScanStart)="startOverviewScan()"
            (logsClick)="showLogs()"
            (scanStart)="startScan()"
            (cancel)="cancelTask()"
            [notReady]="notReady"
            [taskInProgress]="taskInProgress"
            [canStartScan]="canStartScan"
            [resolutionOptions]="resolutionOptions"
        >
        </cm-scanning-action-bar>
    }
    @if (connectionState !== "connected") {
        <div class="cm-status-container">
            <div class="cm-status-text">
                <i class="far fa-plug"></i>
                @switch (connectionState) {
                    @case ("notSetup") {
                        <div class="cm-status-title">No default scanner was specified.</div>
                    }
                    @case ("connecting") {
                        <div class="cm-status-title">
                            Connecting to scanner at<br />
                            {{ scannerUrl }}...
                        </div>
                    }
                    @case ("notConnected") {
                        <div class="cm-status-title">Currently not connected to<br />{{ scannerUrl }}</div>
                    }
                    @case ("failed") {
                        <div class="cm-status-title">Could not connect to scanner at<br />{{ scannerUrl }}</div>
                    }
                }
            </div>
            <div [style.visibility]="connectionState === 'failed' || connectionState === 'notConnected' ? 'visible' : 'hidden'" class="cm-button-container">
                <button mat-flat-button (click)="connectToScanner()">{{ connectionState === "failed" ? "Retry" : "Connect" }}</button>
            </div>
            <div [style.visibility]="connectionState === 'notSetup' ? 'visible' : 'hidden'" [style.width]="'30%'">
                <mat-form-field class="cm-infos-field">
                    <mat-label>Scanner URL</mat-label>
                    <input matTextareaAutosize matInput id="scannerUrl" [(ngModel)]="scannerUrl" />
                </mat-form-field>
                <div class="cm-button-container">
                    <button mat-flat-button (click)="connectToScanner()">Connect</button>
                </div>
            </div>
        </div>
    }
    @if (connectionState === "connected" && !overviewImageValid) {
        <div class="cm-status-container">
            <div class="cm-status-text">
                <i class="far fa-camera-viewfinder"></i>
                @switch (overviewScanState) {
                    @case ("inProgress") {
                        <div class="cm-status-title">{{ scanner.taskMessage }}</div>
                    }
                    @case ("valid") {
                        <div class="cm-status-title">Loading overview scan...</div>
                    }
                    @case ("unavailable") {
                        @if (scanner.taskState !== "error") {
                            <div class="cm-status-title">No overview scan.</div>
                        }
                        @if (scanner.taskState === "error") {
                            <div class="cm-status-error">Error: {{ scanner.taskMessage }}</div>
                        }
                    }
                }
            </div>
            <div [style.visibility]="!notReady && overviewScanState !== 'valid' ? 'visible' : 'hidden'" class="cm-button-container">
                <button mat-flat-button (click)="startOverviewScan('full')">Start overview scan (full)</button>
            </div>
        </div>
    }
</div>
<router-outlet></router-outlet>
