import {DataObject as LegacyDataObject, TransientDataObject as LegacyTransientDataObject} from "@legacy/api-model/data-object"
import {MediaTypeSchema} from "@cm/lib/api-gql/data-object"
import {DataObject, ImageResource} from "@cm/lib/materials/material-node-graph"
import {IDataObject, ITransientDataObject} from "@cm/lib/templates/interfaces/data-object"
import {mapRestToGqlImageColorSpace} from "@legacy/helpers/image-color-space"
import {mapLegacyTransientDataObject} from "@legacy/helpers/transient-data-object"
import {EndpointUrls} from "@common/models/constants/urls"

export function mapLegacyDataObject(dataObject: IDataObject | ITransientDataObject): DataObject {
    if (!(dataObject instanceof LegacyDataObject)) throw Error(`Not a valid legacy data object: ${dataObject}`)
    const parsedMediaType = MediaTypeSchema.safeParse(dataObject.contentType)
    if (!parsedMediaType.success) throw Error(`Invalid media type: ${dataObject.contentType}`)

    return {
        legacyId: dataObject.id,
        imageColorSpace: dataObject.imageColorSpace ? mapRestToGqlImageColorSpace(dataObject.imageColorSpace) : undefined,
        mediaType: parsedMediaType.data,
        width: dataObject.width,
        height: dataObject.height,
        downloadUrl: EndpointUrls.GoogleStorage(dataObject.gcsBucketName, dataObject.gcsObjectName),
    }
}

export function mapLegacyDataObjectToImageResource(legacyDataObject: IDataObject | ITransientDataObject): ImageResource {
    if (legacyDataObject instanceof LegacyDataObject)
        return {mainDataObject: mapLegacyDataObject(legacyDataObject), relatedDataObjects: legacyDataObject.related.map(mapLegacyDataObject)}
    if (legacyDataObject instanceof LegacyTransientDataObject) return {transientDataObject: mapLegacyTransientDataObject(legacyDataObject)}
    throw Error(`Not a valid legacy data object: ${legacyDataObject}`)
}
