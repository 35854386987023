import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {ThreeObject, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import * as THREE from "three"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"

export class ThreePoint extends ThreeObject<SceneNodes.Point> {
    protected override renderObject: THREE.Mesh<THREE.BufferGeometry, THREE.Material>

    constructor(protected override threeSceneManagerService: ThreeSceneManagerService) {
        super(threeSceneManagerService)
        const geometry = new THREE.SphereGeometry()
        const material = new THREE.MeshBasicMaterial({color: 0xcccccc, side: THREE.FrontSide, transparent: true, opacity: 0.5})
        this.renderObject = new THREE.Mesh(geometry, material)
        setThreeObjectPart(this.getRenderObject(), this)
    }

    override setup(sceneNode: SceneNodes.Point) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform", "size"],
                (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") return mathIsEqual(valueA, valueB)
                    else return valueA === valueB
                },
                ({transform, size}) => {
                    const threeMatrix = transform.toThreeMatrix()
                    if (!(threeMatrix instanceof THREE.Matrix4)) throw new Error("Expected a THREE.Matrix4")

                    const translation = new THREE.Vector3()
                    const rotation = new THREE.Quaternion()
                    const scale = new THREE.Vector3()
                    threeMatrix.decompose(translation, rotation, scale)

                    threeMatrix.compose(translation, rotation, scale.multiply(new THREE.Vector3(size, size, size)))

                    updateTransform(threeMatrix, this.renderObject)
                },
            ),
        ])
    }

    override dispose() {
        this.renderObject.geometry.dispose()
        this.renderObject.material.dispose()
    }
}
