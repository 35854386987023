<cm-inspector-section>
    <ng-container cm-title>Display</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Toggle display of visual guides [V]"
            [toggle]="$operator()?.showGuides$.value"
            (toggleChange)="$operator()?.showGuides$.next($event)"
            [disabled]="$disabled()"
        >
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Show guides </span></ng-container>
        </cm-toggle>
        <span class="cm-label">View mode</span>
        <div class="cm-mode-selections">
            <cm-button
                class="cm-mode-selection"
                [class.cm-active]="$operator()?.viewMode$.value === ViewMode.Source"
                matTooltip="View original image [K]"
                (click)="$operator()?.viewMode$.next(ViewMode.Source)"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-inbox-arrow-down"></i></ng-container>
                <ng-container cm-text>Source</ng-container>
            </cm-button>
            <cm-button
                class="cm-mode-selection"
                [class.cm-active]="$operator()?.viewMode$.value === ViewMode.Result"
                matTooltip="View mapped image [L]"
                (click)="$operator()?.viewMode$.next(ViewMode.Result)"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-inbox-arrow-up"></i></ng-container>
                <ng-container cm-text>Result</ng-container>
            </cm-button>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title>Snapping</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Toggle snapping of control point to similar feature of last touched corresponding control point [S]"
            [toggle]="$operator().snapEnabled$ | async"
            (toggleChange)="$operator().snapEnabled$.next($event)"
            [disabled]="$disabled()"
        >
            <ng-container cm-text><span [class.cm-disabled]="$disabled()">Enable snapping</span></ng-container>
        </cm-toggle>
        <div>
            <span class="cm-label">Snap distance</span>
            <cm-slider
                matTooltip="Distance in pixels to search for similar feature"
                [min]="0"
                [max]="256"
                [displayValuePrecision]="0"
                [value]="$operator().snapDistanceInPixels$ | async"
                (valueChange)="$operator().snapDistanceInPixels$.next($event)"
                [disabled]="$disabled() || !$operator()?.snapEnabled$.value"
            ></cm-slider>
        </div>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title>Fine adjustment</ng-container>
    <ng-container cm-content>
        <div>
            <span class="cm-label">Control point spacing</span>
            <cm-slider
                matTooltip="Spacing in pixels for additional control points to be added for fine adjustment"
                [min]="8"
                [max]="256"
                [displayValuePrecision]="0"
                [value]="$operator().fineAdjustSpacingInPixels$ | async"
                (valueChange)="$operator().fineAdjustSpacingInPixels$.next($event)"
                [disabled]="$disabled()"
            ></cm-slider>
        </div>
        <div>
            <span class="cm-label">Search size ratio</span>
            <cm-slider
                matTooltip="Ratio of search radius for similar feature to control point spacing"
                [min]="0.01"
                [max]="1"
                [value]="$operator().fineAdjustSearchSizeRatio$ | async"
                (valueChange)="$operator().fineAdjustSearchSizeRatio$.next($event)"
                [disabled]="$disabled()"
            ></cm-slider>
        </div>
        <div>
            <span class="cm-label">Minimum correlation</span>
            <cm-slider
                matTooltip="Minimum correlation for similar feature to be considered"
                [min]="0"
                [max]="1"
                [value]="$operator().fineAdjustMinCorrelation$ | async"
                (valueChange)="$operator().fineAdjustMinCorrelation$.next($event)"
                [disabled]="$disabled()"
            ></cm-slider>
        </div>
        <div class="cm-mode-selections">
            <cm-button
                class="cm-mode-selection"
                matTooltip="Run fine adjustment (this may take a few seconds)"
                (click)="$operator()?.executeGridFineAdjustment()"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-location-dot"></i></ng-container>
                <ng-container cm-text>Run fine adjustment</ng-container>
            </cm-button>
            <cm-button
                class="cm-mode-selection"
                matTooltip="Remove fine adjustment control points"
                (click)="$operator()?.removeGridFineAdjustment()"
                [disabled]="$disabled()"
            >
                <ng-container cm-left-icon><i class="far fa-location-dot-slash"></i></ng-container>
                <ng-container cm-text>Remove</ng-container>
            </cm-button>
        </div>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title>Border blending</ng-container>
    <ng-container cm-content>
        <div>
            <span class="cm-label">Border width</span>
            <cm-slider
                matTooltip="Width in pixels for blending border"
                [min]="0"
                [max]="256"
                [displayValuePrecision]="0"
                [value]="$operator().blendDistanceInPixels$ | async"
                (valueChange)="$operator().blendDistanceInPixels$.next($event)"
                [disabled]="$disabled()"
            ></cm-slider>
        </div>
        <!--        <div>-->
        <!--            <span class="cm-label">Snap falloff</span>-->
        <!--            <cm-slider [min]="0" [max]="1" [value]="$operator().snapFalloff$ | async" (valueChange)="$operator().snapFalloff$.next($event)"-->
        <!--                       [disabled]="$disabled() || !$operator()?.snapEnabled$.value"></cm-slider>-->
        <!--        </div>-->
    </ng-container>
</cm-inspector-section>
@if (isDebugEnabled) {
    <cm-inspector-section>
        <ng-container cm-title>Debugging</ng-container>
        <ng-container cm-content>
            <cm-toggle [toggle]="$operator().debugDrawEnabled$ | async" (toggleChange)="$operator().debugDrawEnabled$.next($event)" [disabled]="$disabled()">
                <ng-container cm-text><span [class.cm-disabled]="$disabled()">Enable debug draw</span></ng-container>
            </cm-toggle>
        </ng-container>
    </cm-inspector-section>
}
