import {NgTemplateOutlet} from "@angular/common"
import {Component, EventEmitter, HostBinding, Input, Output, ViewChild} from "@angular/core"
import {ParameterType, ParameterValue, ScalarSocketRange, SocketPosition} from "@node-editor/models"
import {ColorInputSocketComponent} from "@node-editor/components/color-input-socket/color-input-socket.component"
import {NodeSocketComponent} from "@node-editor/components/node-socket/node-socket.component"
import {PlainInputSocketComponent} from "@node-editor/components/plain-input-socket/plain-input-socket.component"
import {ScalarInputSocketComponent} from "@node-editor/components/scalar-input-socket/scalar-input-socket.component"
import {VectorInputSocketComponent} from "@node-editor/components/vector-input-socket/vector-input-socket.component"
import {Socket, SocketEvent, SocketValueType} from "@node-editor/models"

@Component({
    selector: "cm-node-io",
    templateUrl: "./node-io.component.html",
    styleUrls: ["./node-io.component.scss"],
    standalone: true,
    imports: [
        ColorInputSocketComponent,
        VectorInputSocketComponent,
        NodeSocketComponent,
        ScalarInputSocketComponent,
        PlainInputSocketComponent,
        NgTemplateOutlet,
    ],
})
export class NodeIoComponent {
    @ViewChild("socketComponent", {static: false}) socketComponent!: NodeSocketComponent

    @Input() valueType: SocketValueType | undefined
    @Input() range: ScalarSocketRange | undefined
    @Input() socket!: Socket
    @Input() disableEditingIfConnected = true

    @Input() value?: string | number | number[]
    @Output() valueChange = new EventEmitter<{value: ParameterValue; type: ParameterType}>()

    private _active = true
    @Input() set active(value: boolean) {
        this._active = value
        this.hidden = !value
        if (!value) this.connectionChange.emit({type: "deactivate", socket: this.socket})
    }

    get active(): boolean {
        return this._active
    }

    connected: {value: boolean} = {value: false}

    @Output() connectionChange: EventEmitter<SocketEvent> = new EventEmitter<SocketEvent>()

    @HostBinding("hidden")
    private hidden = false

    getSocketPosition(): SocketPosition {
        return this.socketComponent.getPosition()
    }

    get valueAsNumberArray() {
        return this.value as [number, number, number]
    }
    get valueAsNumber() {
        return this.value as number
    }
    get valueAsString() {
        return this.value as string
    }
}
