import {ContentTypeModel, JobDetailsForTemplateImageViewerFragment, JobState} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {SceneManagerService} from "../services/scene-manager.service"
import {Settings} from "@app/common/models/settings/settings"
import {postProcessingGraph} from "@cm/lib/image-processing/render-post-processing"
import {ImageProcessingInput, ImageProcessingOutput, imageProcessingTask} from "@cm/lib/job-task/image-processing"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {PictureRenderJobOutputSchema} from "@cm/lib/job-task/rendering"
import {jsonToGraph, graphToJson} from "@cm/lib/utils/graph-json"
import {getPostProcessingInput} from "../components/template-image-viewer/template-image-viewer.component"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {RenderingService} from "@app/common/services/rendering/rendering.service"

export type JobData = JobDetailsForTemplateImageViewerFragment

export function isJobError(job: JobData) {
    switch (job.state) {
        case JobState.Failed:
        case JobState.Cancelled:
            return true
        default:
            return false
    }
}

export function isJobPending(job: JobData) {
    switch (job.state) {
        case JobState.Init:
        case JobState.Running:
        case JobState.Runnable:
            return true
        default:
            return false
    }
}

export const getAssignmentKey = (type: "render" | "postProcess", hash: string) => `${type}:${hash}`

export async function getJobAssignment(sdkService: SdkService, templateRevisionId: string, assignmentKey: string) {
    const result = await sdkService.gql.getJobAssignmentsDetailsForTemplateImageViewer({
        filter: {
            objectId: templateRevisionId,
            contentTypeModel: ContentTypeModel.TemplateRevision,
            assignmentKey: {equals: assignmentKey},
        },
    })

    return result.jobAssignments[0]?.job
}

export async function getJobAssignments<T extends readonly string[]>(
    sdkService: SdkService,
    templateRevisionId: string,
    assignmentKeys: [...T],
): Promise<{[K in keyof T]: JobData | undefined}> {
    const result = await sdkService.gql.getJobAssignmentsDetailsForTemplateImageViewer({
        filter: {
            objectId: templateRevisionId,
            contentTypeModel: ContentTypeModel.TemplateRevision,
            assignmentKey: {in: assignmentKeys},
        },
    })

    return assignmentKeys.map((key) => result.jobAssignments.find((assignment) => assignment?.assignmentKey === key)?.job) as {
        [K in keyof T]: JobData | undefined
    }
}

export const createRenderJob = async (
    sdkService: SdkService,
    renderingService: RenderingService,
    templateRevisionId: string,
    hash: string,
    sceneNodes: SceneNodes.SceneNode[],
    organizationLegacyId: number,
) => {
    const assignmentKey = getAssignmentKey("render", hash)

    const existingRenderJob = await getJobAssignment(sdkService, templateRevisionId, assignmentKey)
    if (existingRenderJob) throw Error(`A render job already exists for this variation. (id: ${existingRenderJob.id})`)

    const jobName = `Render template ${templateRevisionId} (variation)`

    const renderJob = await renderingService.submitRenderJob({
        nodes: sceneNodes,
        final: true,
        name: jobName,
        organizationLegacyId,
    })

    const jobAssignment = (
        await sdkService.gql.createJobAssignmentForTemplateImageViewer({
            input: {
                objectId: templateRevisionId,
                contentTypeModel: ContentTypeModel.TemplateRevision,
                assignmentKey,
                jobId: renderJob.id,
            },
        })
    ).createJobAssignment

    const createdJob = await getJobAssignment(sdkService, templateRevisionId, assignmentKey)
    if (!createdJob) throw Error("Failed to create render job")

    return [jobAssignment, createdJob] as const
}

const deleteJob = async (sdkService: SdkService, templateRevisionId: string, hash: string, type: "render" | "postProcess") => {
    const assignmentKey = getAssignmentKey(type, hash)

    const job = await getJobAssignment(sdkService, templateRevisionId, assignmentKey)
    if (!job) return undefined

    return sdkService.gql.deleteJobForTemplateImageViewer({id: job.id})
}

export const deleteRenderJob = async (sdkService: SdkService, templateRevisionId: string, hash: string) => {
    return deleteJob(sdkService, templateRevisionId, hash, "render")
}

export const createPostProcessJob = async (
    sdkService: SdkService,
    templateRevisionId: string,
    hash: string,
    postProcessingSettings: SceneNodes.RenderPostProcessingSettings,
    organizationLegacyId: number,
) => {
    const assignmentKey = getAssignmentKey("postProcess", hash)

    const [existingPostProcessingJob, existingRenderJob] = await getJobAssignments(sdkService, templateRevisionId, [
        assignmentKey,
        getAssignmentKey("render", hash),
    ])

    if (!existingRenderJob) throw Error(`No existing render job`)
    if (existingPostProcessingJob) throw Error(`A postprocess job already exists for this variation. (id: ${existingPostProcessingJob.id})`)

    if (isJobPending(existingRenderJob) || isJobError(existingRenderJob)) throw Error(`Render job is not complete, state is ${existingRenderJob.state}`)

    const {output} = existingRenderJob
    const graph = jsonToGraph(output)
    const renderOutput = PictureRenderJobOutputSchema.parse(graph)
    if (!renderOutput.renderPasses) throw Error("Render job has no render passes")

    const postProcessingInput = await getPostProcessingInput(renderOutput, sdkService, false)

    const input: ImageProcessingInput = {
        graph: {
            type: "encode",
            mediaType: "image/tiff",
            input: {
                type: "convert",
                input: postProcessingGraph(postProcessingInput, postProcessingSettings).image,
                channelLayout: "RGBA",
                dataType: "uint8",
                sRGB: true,
            },
        },
    }

    const jobName = `Postprocess template ${templateRevisionId} (variation)`

    const jobGraph = JobNodes.jobGraph<ImageProcessingOutput>(JobNodes.task(imageProcessingTask, {input: JobNodes.value(input)}), {
        platformVersion: Settings.APP_VERSION,
    })
    const postProcessingJob = (
        await sdkService.gql.createJobForTemplateImageViewer({
            input: {
                name: jobName,
                organizationLegacyId,
                graph: graphToJson(jobGraph),
            },
        })
    ).createJob

    const jobAssignment = (
        await sdkService.gql.createJobAssignmentForTemplateImageViewer({
            input: {
                objectId: templateRevisionId,
                contentTypeModel: ContentTypeModel.TemplateRevision,
                assignmentKey,
                jobId: postProcessingJob.id,
            },
        })
    ).createJobAssignment

    return [jobAssignment, postProcessingJob] as const
}

export const deletePostProcessJob = async (sdkService: SdkService, templateRevisionId: string, hash: string) => {
    return deleteJob(sdkService, templateRevisionId, hash, "postProcess")
}
