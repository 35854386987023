<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Settings </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'id'" [label]="'ID'" [validate]="isValidExternalId"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'name'" [label]="'Name'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'label'" [label]="'Label'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'description'" [label]="'Description'" [minRows]="4" [topLabel]="true"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
