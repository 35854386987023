import {sortedJSONStringify} from "@src/utils/utils"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {ImageColorSpace} from "@src/templates/types"

type Position_JSON = [number | string, number | string, number | string]
type Euler_JSON = [number | null, number | null, number | null]
type Matrix4_JSON = (number | string)[]
type Color_JSON = [number, number, number]
type Value_JSON = any

export namespace Nodes {
    export type ExternalId = string

    type SoleOwner<T> = T

    export function isNode(value: any): value is Nodes.Node {
        return typeof value === "object" && value != null && "type" in value && nodeTypes.includes(value.type)
    }

    type NodeBase = {
        readonly type: string
    }

    export function getExternalId(node: Nodes.Node, required = false): string {
        const id = (node as any).id as ExternalId
        if (required && id == undefined) {
            console.warn("No external id:", node)
        }
        return id
    }

    type NamedNodeBase = NodeBase & {
        name: string
    }

    export type Switch<T extends NodeBase> = NodeBase & {
        readonly type: "switch"
        name?: string
        nodes: T[] // non-owned
    }

    export type Value<T = Value_JSON> = NodeBase & {
        readonly type: "value"
        value: T
    }

    export type TemplateExport = NodeBase & {
        readonly type: "templateExport"
        id: ExternalId
        name: string
        node: Nodes.Node | null
        tags?: TagReference[]
    }

    type TemplateInputBase = NamedNodeBase & {
        readonly type: "templateInput"
        id: ExternalId
        name: string
        tags?: TagReference[]
    }

    export type TemplateMaterialInput = TemplateInputBase & {
        readonly inputType: "material"
        default?: Material
    }

    export type TemplateObjectInput = TemplateInputBase & {
        readonly inputType: "object"
        default?: ObjectReference
    }

    export type TemplateTemplateInput = TemplateInputBase & {
        readonly inputType: "template"
        default?: TemplateDefinition
    }

    export type TemplateImageInput = TemplateInputBase & {
        readonly inputType: "image"
        default?: Image
    }

    export type TemplateStringInput = TemplateInputBase & {
        readonly inputType: "string"
        default?: string | Value<string> | ResolveNode<any>
    }

    export type TemplateNumberInput = TemplateInputBase & {
        readonly inputType: "number"
        default?: number | Value<number>
    }

    export type TemplateBooleanInput = TemplateInputBase & {
        readonly inputType: "boolean"
        default?: boolean | Value<boolean>
    }

    export type TemplateInput =
        | TemplateMaterialInput
        | TemplateObjectInput
        | TemplateTemplateInput
        | TemplateImageInput
        | TemplateStringInput
        | TemplateNumberInput
        | TemplateBooleanInput

    export type VariableBase = NodeBase & {
        readonly type: "variable"
    }

    export type Variable_R1 = VariableBase & {
        topology: "boundedReal"
        range: [number, number]
        default: number
    }

    export type Variable_S1 = VariableBase & {
        topology: "1-sphere"
        default: readonly [number, number]
    }

    export type Variable_S2 = VariableBase & {
        topology: "2-sphere"
        default: readonly [number, number, number]
    }

    export type Variable_S3 = VariableBase & {
        topology: "3-sphere"
        default: readonly [number, number, number, number] // same as normalized quaternion: [qx, qy, qz, qw]
    }

    export type Variable = Variable_R1 | Variable_S1 | Variable_S2 | Variable_S3

    export type InstanceProperties<T> = {
        [P in Exclude<keyof T, keyof NodeBase>]?: T[P]
    }

    export type OverrideProperties<T> = {
        [P in Exclude<keyof T, keyof NodeBase>]?: T[P]
    }

    // this is used for legacy meshes and templates (TODO check this is true)
    export type Instance<T> = NodeBase & {
        readonly type: "instance"
        node: T
    } & InstanceProperties<T>

    export type DataObjectReference = NodeBase & {
        readonly type: "dataObjectReference"
        dataObjectId: number | null | undefined
    }

    export type MeshSurfaceSourceData = {
        schema: string
        localMatrix?: number[]
        faceIDs?: number[]
        points?: number[][]
        selectionModeTriangles?: boolean
        surfaceModeProjection?: boolean
        selectedMaterialID?: number
        overrideCentroidOffset?: [number, number]
        overrideScaleZ?: number
        overrideRotationAngle?: number
        overrideFlipNormals?: boolean
    }

    export type StoredMeshSurface = NamedNodeBase & {
        readonly type: "meshSurface"
        sourceData: MeshSurfaceSourceData
    }

    export type MeshBase = NamedNodeBase & {
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
        subdivisionRenderIterations?: number | null
        displacementTexture?: Image
        displacementUvChannel?: number
        displacementMin?: number
        displacementMax?: number
        materialAssignments: {[slot: string]: MaterialAssignment | null}
        materialSlotNames: {[slot: string]: string}
        visibleDirectly?: boolean
        visibleInReflections?: boolean
        visibleInRefractions?: boolean
    }

    export type StoredMesh = MeshBase & {
        readonly type: "mesh"
        drcDataObjectId: number
        plyDataObjectId: number
        metadata: Value_JSON
        surfaces: SoleOwner<StoredMeshSurface[]>
    }

    export type ProceduralMesh = MeshBase & {
        readonly type: "proceduralMesh"
        geometryGraph: any
        surfaces: SoleOwner<ProceduralMeshSurface[]>
        parameters: {[key: string]: Value_JSON}
    }

    export type ProceduralMeshSurface = NamedNodeBase & {
        readonly type: "proceduralMeshSurface"
    }

    export type Mesh = StoredMesh | ProceduralMesh
    export type MeshOrInstance = Mesh | Instance<Mesh>
    export type MeshSurface = StoredMeshSurface | ProceduralMeshSurface

    // legacy. Either this is used directly or using the template field of TemplateInstance
    export type TemplateReferenceInstance = NamedNodeBase & {
        readonly type: "templateReference"
        id: ExternalId
        templateRevisionId: number
        //TODO: remove these, and use TemplateInstance of TemplateReference instead
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
        selectedConfigVariants?: {[groupId: string]: ExternalId | Nodes.Node}
        parameters?: {[paramId: string]: Value_JSON | Nodes.Node}
        overrides?: {[nodeId: string]: OverrideProperties<unknown>}
    }

    // this is the current TemplateDefinition used in TemplateInstance
    export type TemplateReference = NodeBase & {
        readonly type: "templateReference"
        templateRevisionId: number
    }

    export type TemplateOutput = GetTemplateOutput<Nodes.TemplateGraph>

    export type TemplateDefinition =
        | TemplateReference // reference to a library template
        | TemplateGraph // refer to a inner/local template
        | TemplateTemplateInput // refer to a template input of "template"-type
        | TemplateOutput // refer to a template output which produces a template graph

    // this is the way templates are currently instantiated within other templates
    export type TemplateInstance = NamedNodeBase & {
        readonly type: "templateInstance"
        id: ExternalId
        template: TemplateDefinition | null | undefined
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
        selectedConfigVariants?: {[groupId: string]: ExternalId | Nodes.Node} // TODO this is legacy and should be migrated; use parameters instead
        parameters?: {[paramId: string]: Value_JSON | Nodes.Node}
        overrides?: {[nodeId: string]: OverrideProperties<unknown>}
    }

    export type ResolveNode<T> = NodeBase & {
        readonly type: "resolveNode"
        name?: string
        template: TemplateDefinition
        nodeId: ExternalId
    }

    export type GetTemplateOutput<T> = NodeBase & {
        readonly type: "getTemplateOutput"
        name?: string
        template: TemplateOrInstance
        outputId: ExternalId
        outputType: "material" | "object" | "template" | "image" | "string" | "number" | "boolean" | undefined
    }

    export type TemplateOrInstance = TemplateReferenceInstance | Instance<TemplateReferenceInstance> | TemplateInstance //TODO: remove TemplateReferenceInstance and Instance<TemplateReference>

    export type TagReference = NamedNodeBase & {
        readonly type: "tagReference"
        tagId: number
    }

    export type MaterialReference = NamedNodeBase & {
        id?: ExternalId //TODO: deprecate allowOverride (ID only used if allowOverride is true)
        readonly type: "materialReference"
        materialRevisionId: number | undefined | null
        allowOverride?: boolean
    }

    export type MaterialGraphReference = NodeBase & {
        readonly type: "materialGraphReference"
        graph: IMaterialGraph
    }

    export type FindMaterial = NodeBase & {
        readonly type: "findMaterial"
        customerId?: number | NumberLike
        articleId?: string | StringLike
        //TODO: tags
    }

    export type MaterialAssignment = {
        node: Material | null | undefined
        horizontalOffset?: number | NumberLike | null
        verticalOffset?: number | NumberLike | null
        rotation?: number | NumberLike | null
        side?: "front" | "back" | "double" // same as MaterialSide
    }

    export type TextureReference = NamedNodeBase & {
        readonly type: "textureReference"
        textureRevisionId: number
    }
    interface TextureSwitch extends Switch<Texture> {} // workaround for recursive type
    export type Texture = TextureReference | TextureSwitch

    interface MaterialSwitch extends Switch<Material> {} // workaround for recursive type
    export interface MaterialOutput extends GetTemplateOutput<Nodes.Material> {}
    export type Material =
        | FindMaterial
        | MaterialReference
        | MaterialGraphReference
        | TemplateMaterialInput
        | MaterialSwitch
        | MaterialOutput
        | OverlayMaterialColor

    interface StringLikeSwitch extends Switch<StringLike> {} // workaround for recursive type
    export interface StringOutput extends GetTemplateOutput<StringLike> {}
    export type StringLike = ResolveNode<any> | Value<string> | TemplateStringInput | StringLikeSwitch | StringOutput

    interface NumberLikeSwitch extends Switch<NumberLike> {} // workaround for recursive type
    export interface NumberOutput extends GetTemplateOutput<NumberLike> {}
    export type NumberLike = Value<number> | TemplateNumberInput | NumberLikeSwitch | NumberOutput

    interface BooleanLikeSwitch extends Switch<BooleanLike> {} // workaround for recursive type
    export interface BooleanOutput extends GetTemplateOutput<BooleanLike> {}
    export type BooleanLike = Value<boolean> | TemplateBooleanInput | BooleanLikeSwitch | BooleanOutput

    export type AreaLight = NamedNodeBase & {
        readonly type: "areaLight"
        intensity: number
        width: number
        height: number
        color: Color_JSON
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
        target: Position_JSON
        on: boolean
        directionality: number
        visibleDirectly?: boolean
        visibleInReflections?: boolean
        visibleInRefractions?: boolean
        transparent?: boolean
    }

    export type LightPortal = NamedNodeBase & {
        readonly type: "lightPortal"
        width: number
        height: number
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
    }

    export type HDRIReference = NamedNodeBase & {
        readonly type: "hdriReference"
        hdriId: number
    }

    export type HDRILight = NamedNodeBase & {
        readonly type: "hdriLight"
        hdri: HDRIReference
        rotation: Euler_JSON
        intensity: number
        clampHighlights?: number
        mirror?: boolean // (mirror on X axis)
    }

    export type Light = AreaLight | LightPortal

    export type FilmicToneMapping = {
        mode: "filmic"
    }

    export type FilmicAdvancedToneMapping = {
        mode: "filmic-advanced"
        contrast: number
        balance: number
        colorBalance: number
    }

    export type ContrastToneMapping = {
        mode: "contrast"
        contrast: number
        balance: number
        colorBalance: number
    }

    export type CoronaToneMapping = {
        mode: "corona"
        highlightCompression: number
        contrast: number
        saturation: number
    }

    export type LinearToneMapping = {
        mode: "linear"
    }

    export type RGBCurveMapping = {
        mode: "rgbCurve"
        parameters: {
            fac: number
            [key: string]: [number, number] | number
            //e.g. "internal.mapping.curves[0].points[0].location": [number, number]
        }
    }

    export type HueSaturationMapping = {
        mode: "hueSaturation"
        parameters: {
            fac: number
            hue: number
            saturation: number
            value: number
        }
    }

    export type ToneMapping =
        | FilmicToneMapping
        | ContrastToneMapping
        | CoronaToneMapping
        | LinearToneMapping
        | FilmicAdvancedToneMapping
        | RGBCurveMapping
        | HueSaturationMapping

    export type Camera = NamedNodeBase & {
        readonly type: "camera"
        resolutionX: number
        resolutionY: number
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
        target: Position_JSON
        filmGauge: number
        fStop: number
        focalLength: number
        focalDistance: number
        zoomFactor?: number
        exposure: number
        shiftX: number
        shiftY: number
        automaticVerticalTilt: boolean
        minDistance?: number
        maxDistance?: number
        minPolarAngle?: number
        maxPolarAngle?: number
        minAzimuthAngle?: number | null
        maxAzimuthAngle?: number | null
        enablePanning?: boolean
        screenSpacePanning?: boolean
        enableAr?: boolean
        toneMapping?: ToneMapping
        automaticTarget?: ObjectReference
        nearClip?: number
        farClip?: number
    }

    export type PlaneGuide = NamedNodeBase & {
        readonly type: "planeGuide"
        width: number
        height: number
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
    }

    export type PointGuide = NamedNodeBase & {
        readonly type: "pointGuide"
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
    }

    export type Annotation = NamedNodeBase & {
        readonly type: "annotation"
        id: ExternalId
        label: string
        description: string
        lockedTransform?: Matrix4_JSON
        $defaultTransform?: Matrix4_JSON
    }

    export type Render = NodeBase & {
        readonly type: "render"
        width: number | NumberLike
        height: number | NumberLike
        samples: number | NumberLike
    }

    export type PostProcessRender = NodeBase & {
        readonly type: "postProcessRender"
        render: Render | null | undefined
        mode: "whiteBackground"
        exposure?: number | NumberLike
        whiteBalance?: number | NumberLike
        toneMapping?: ToneMapping
        lutUrl?: string | null
        transparent?: boolean
        composite?: boolean
        backgroundColor?: string
        processShadows?: boolean
        shadowInner?: number | null
        shadowOuter?: number
        shadowFalloff?: number
        autoCrop?: boolean
        autoCropMargin?: number
    }

    export type SaveImageFile = NodeBase & {
        readonly type: "saveImageFile"
        input: PostProcessRender
        format: "jpeg" | "png" | "tiff" | "exr"
    }

    export type Surface = MeshSurface

    export type Guide = PlaneGuide | PointGuide

    export type Object = MeshOrInstance | TemplateOrInstance | Guide | Light | Camera | Annotation

    export type DirectSurfaceReference = NodeBase & {
        readonly type: "surfaceReference"
        object: Object
        surfaceId: string
    }
    interface SurfaceReferenceSwitch extends Switch<DirectSurfaceReference> {} // workaround for recursive type
    export type SurfaceReference = DirectSurfaceReference | SurfaceReferenceSwitch

    export type RelationTranslation = {x: number | Variable_R1; y: number | Variable_R1; z: number | Variable_R1}
    export type RelationRotation =
        | {readonly type: "fixed"; x: number; y: number; z: number}
        | {readonly type: "hinge"; axis: "x" | "y" | "z"; rotation: Variable_S1}
        | {readonly type: "ball"; rotation: Variable_S3}

    export type AttachSurfaces = NodeBase & {
        readonly type: "attachSurfaces"
        surfaceOffset: {x: number; y: number; angle: number}
        translation: RelationTranslation
        rotation: RelationRotation
        targetA: SurfaceReference | null | undefined
        targetB: SurfaceReference | null | undefined
    }

    export type ObjectOutput = GetTemplateOutput<Object>

    export type Outputs = MaterialOutput | ObjectOutput | TemplateOutput | ImageOutput | NumberOutput | StringOutput | BooleanOutput

    // interface ObjectReferenceSwitch extends Switch<ObjectReference> { } // workaround for recursive type
    export type ObjectReference = Object | Switch<Object> | ObjectOutput | TemplateObjectInput

    export type RigidRelation = NodeBase & {
        readonly type: "rigidRelation"
        translation: RelationTranslation
        rotation: RelationRotation
        targetA: ObjectReference | null | undefined
        targetB: ObjectReference | null | undefined
    }

    export type Relation = AttachSurfaces | RigidRelation

    type ContextBase = {
        nodes: SoleOwner<Node[]>
    }

    export type Group = NamedNodeBase &
        ContextBase & {
            readonly type: "group"
            active: boolean | Nodes.Node
        }

    export type ConfigVariant = NamedNodeBase &
        ContextBase & {
            readonly type: "configVariant"
            id: ExternalId
            iconDataObjectId?: number //TODO: this needs to be tracked by the backend to properly refcount DataObjects. Add a general DataObjectReference node?
            iconColor?: Color_JSON
        }

    export type ConfigGroup = NamedNodeBase &
        ContextBase & {
            readonly type: "configGroup"
            id: ExternalId
            displayWithLabels?: boolean
        }

    export const currentTemplateGraphSchema = "templateGraph_12"
    export type TemplateGraph = ContextBase & {
        readonly type: "templateGraph"
        schema: "templateGraph_12"
        name: string | null
    }

    export type Context = Group | ConfigVariant | ConfigGroup | TemplateGraph

    export const SOLE_OWNER_FIELDS: {[type: string]: string[]} = {
        templateGraph: ["nodes"],
        group: ["nodes"],
        configGroup: ["nodes"],
        configVariant: ["nodes"],
        mesh: ["surfaces"],
        proceduralMesh: ["surfaces"],
    }

    export type TextureResolution = "500px" | "1000px" | "2000px" | "original"
    export type EnvironmentMapMode = "full" | "specularOnly"
    export type FocusMode = "target" | "auto" | "click"
    export type UiStyle = "default" | "icons" | "hidden" | "accordion"

    export type SceneProperties = NodeBase & {
        readonly type: "sceneProperties"
        maxSubdivisionLevel: number
        maxSubdivisionLevelOnMobile?: number
        backgroundColor?: Color_JSON | null
        uiColor?: Color_JSON | null
        uiStyle?: UiStyle
        iconSize?: number | string
        enableAr?: boolean
        enableSalesEnquiry?: boolean
        textureResolution?: TextureResolution
        textureFiltering?: boolean
        enableOnboardingHint?: boolean
        enableGltfDownload?: boolean
        enablePdfGeneration?: boolean
        enableSnapshot?: boolean
        enableFullscreen?: boolean
        enableStlDownload?: boolean
        environmentMapMode?: EnvironmentMapMode
        focusMode?: FocusMode
        showAnnotations?: boolean
        shadowCatcherFalloff?: {sizeX: number; sizeZ: number; smoothness: number; opacity: number}
        enableAdaptiveSubdivision?: boolean
    }

    export type TransientDataObject = NodeBase & {
        readonly type: "transientDataObject"
        data: Uint8Array
        contentType: string
        imageColorSpace: ImageColorSpace
    }

    export interface ImageOutput extends GetTemplateOutput<Nodes.Image> {}
    export type Image = Texture | DataObjectReference | TemplateImageInput | TransientDataObject | ImageOutput

    export type DecalMaskType = "binary" | "opacity"

    export type MeshDecal = NamedNodeBase & {
        readonly type: "meshDecal"
        mesh: MeshOrInstance | null | undefined
        mask?: Image
        invertMask?: boolean
        maskType?: DecalMaskType
        color?: Image
        offset: [number, number]
        rotation: number
        size: [number | undefined | null, number | undefined | null]
        distance: number
        material?: Material
    }

    export type PBRMaterial = NamedNodeBase & {
        readonly type: "pbrMaterial"
        diffuse?: Image
        normal?: Image
        roughness?: Image
        metalness?: Image
        specular?: Image
    }

    export type OverlayMaterialColor = NodeBase & {
        readonly type: "overlayMaterialColor"
        material: Material
        overlay: Image
        size: [number | NumberLike, number | NumberLike] //TODO: ??
    }

    export type RegexReplace = NodeBase & {
        readonly type: "regexReplace"
        input: string | StringLike
        regex: string | StringLike
        replace: string | StringLike
    }

    export type Node =
        | StoredMeshSurface
        | ProceduralMeshSurface
        | MeshDecal
        | SurfaceReference
        | ObjectReference
        | Object
        | Material
        | Image
        | DataObjectReference
        | HDRILight
        | HDRIReference
        | Relation
        | Variable
        | TemplateExport
        | SceneProperties
        | ResolveNode<ConfigVariant>
        | Value
        | RegexReplace
        | TemplateDefinition
        | TemplateInput
        | GetTemplateOutput<Nodes.Material>
        | GetTemplateOutput<Nodes.Object>
        | Render
        | PostProcessRender
        | Context
        | TagReference
        | StringLikeSwitch

    export function create<T extends Nodes.Node>(properties: T): T {
        return {...properties}
    }

    type NodeType = Node["type"]

    // TODO is there a nicer way to do this such that the typescript compiler enforces to exhaustively enumerate all node types here ?
    const nodeTypesInKey: Record<NodeType, any> = {
        transientDataObject: undefined,
        meshDecal: undefined,
        mesh: undefined,
        proceduralMesh: undefined,
        instance: undefined,
        textureReference: undefined,
        dataObjectReference: undefined,
        templateInput: undefined,
        templateReference: undefined,
        switch: undefined,
        getTemplateOutput: undefined,
        findMaterial: undefined,
        materialReference: undefined,
        materialGraphReference: undefined,
        overlayMaterialColor: undefined,
        regexReplace: undefined,
        postProcessRender: undefined,
        resolveNode: undefined,
        templateInstance: undefined,
        value: undefined,
        planeGuide: undefined,
        pointGuide: undefined,
        areaLight: undefined,
        lightPortal: undefined,
        camera: undefined,
        annotation: undefined,
        group: undefined,
        hdriLight: undefined,
        hdriReference: undefined,
        attachSurfaces: undefined,
        rigidRelation: undefined,
        variable: undefined,
        configVariant: undefined,
        configGroup: undefined,
        templateExport: undefined,
        templateGraph: undefined,
        sceneProperties: undefined,
        meshSurface: undefined,
        proceduralMeshSurface: undefined,
        surfaceReference: undefined,
        render: undefined,
        tagReference: undefined,
    }

    export const nodeTypes = Object.keys(nodeTypesInKey) as NodeType[]

    export interface INamed {
        name: string
    }

    export namespace Meta {
        export type InterfaceId = string
        export type VariantId = string

        export type InterfaceDescriptorBase = {
            id: InterfaceId
            name: string
            value?: unknown
            tags?: TagReference[]
            isSetByTemplate?: boolean
        }

        export function isConfigInput(iface: InterfaceDescriptor): iface is ConfigInfo {
            if (!iface) return false
            return iface.interfaceType === "input" && iface.inputType === "config"
        }

        export function isMaterialInput(iface: InterfaceDescriptor): iface is MaterialInputInfo {
            if (!iface) return false
            return iface.interfaceType === "input" && iface.inputType === "material"
        }

        export function isTemplateInput(iface: InterfaceDescriptor): iface is TemplateInputInfo {
            if (!iface) return false
            return iface.interfaceType === "input" && iface.inputType === "template"
        }

        export function isNumberInput(iface: InterfaceDescriptor): iface is NumberInputInfo {
            if (!iface) return false
            return iface.interfaceType === "input" && iface.inputType === "number"
        }

        export function isBooleanInput(iface: InterfaceDescriptor): iface is BooleanInputInfo {
            if (!iface) return false
            return iface.interfaceType === "input" && iface.inputType === "boolean"
        }

        export function isStringInput(iface: InterfaceDescriptor): iface is StringInputInfo {
            if (!iface) return false
            return iface.interfaceType === "input" && iface.inputType === "string"
        }

        export function isImageInput(iface: InterfaceDescriptor): iface is ImageInputInfo {
            if (!iface) return false
            return iface.interfaceType === "input" && iface.inputType === "image"
        }

        export function isMaterial(iface: InterfaceDescriptor): iface is MaterialInputInfo | MaterialOutput {
            if (!iface) return false
            return (iface.interfaceType === "input" && iface.inputType === "material") || (iface.interfaceType === "output" && iface.outputType === "material")
        }

        export function isInput(iface: InterfaceDescriptor): boolean {
            if (!iface) return false
            return iface.interfaceType === "input"
        }

        export function isOutput(iface: InterfaceDescriptor): boolean {
            if (!iface) return false
            return iface.interfaceType === "output"
        }

        export function wrapInterfaceId(prefix: string, id: InterfaceId): InterfaceId {
            return `${prefix}/${id}`
        }

        export function unwrapInterfaceId(id: InterfaceId): [ExternalId | null, InterfaceId] {
            const idx = id.indexOf("/")
            if (idx == -1) return [null, id]
            return [id.substr(0, idx), id.substr(idx + 1)]
        }

        export type ConfigInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "config"
            variants: VariantInfo[]
            value?: VariantInfo["id"]
            displayWithLabels?: boolean
        }

        export type VariantInfo = {
            id: VariantId
            name: string
            iconDataObjectId?: number
            iconColor?: Color_JSON
            excludeFromPermutations?: boolean
        }

        export type MaterialInputInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "material"
            value?: {materialId: number; materialRevisionId: number} // MaterialGraph
        }

        export type ObjectInputInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "object"
        }

        export type TemplateInputInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "template"
        }

        export type ImageInputInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "image"
            value?: {} // TODO:
        }

        export type NumberInputInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "number"
            range?: [number, number]
            step?: number
            value?: number
        }

        export type StringInputInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "string"
            value?: string
        }

        export type BooleanInputInfo = InterfaceDescriptorBase & {
            interfaceType: "input"
            inputType: "boolean"
            value?: boolean
        }

        export type MaterialOutput = InterfaceDescriptorBase & {
            interfaceType: "output"
            outputType: "material"
            value?: {materialId: number; materialRevisionId: number} // MaterialGraph
        }

        export type ObjectOutput = InterfaceDescriptorBase & {
            interfaceType: "output"
            outputType: "object"
        }

        export type TemplateOutput = InterfaceDescriptorBase & {
            interfaceType: "output"
            outputType: "template"
        }

        export type ImageOutput = InterfaceDescriptorBase & {
            interfaceType: "output"
            outputType: "image"
        }

        export type NumberOutput = InterfaceDescriptorBase & {
            interfaceType: "output"
            outputType: "number"
            value?: number
        }

        export type StringOutput = InterfaceDescriptorBase & {
            interfaceType: "output"
            outputType: "string"
            value?: string
        }

        export type BooleanOutput = InterfaceDescriptorBase & {
            interfaceType: "output"
            outputType: "boolean"
            value?: boolean
        }

        export type InputDescriptor =
            | ConfigInfo
            | MaterialInputInfo
            | ObjectInputInfo
            | TemplateInputInfo
            | ImageInputInfo
            | NumberInputInfo
            | StringInputInfo
            | BooleanInputInfo

        export type OutputDescriptor = MaterialOutput | ObjectOutput | TemplateOutput | ImageOutput | NumberOutput | StringOutput | BooleanOutput

        export type InterfaceDescriptor = InputDescriptor | OutputDescriptor

        export function setParameter(template: TemplateOrInstance, id: string, value: any): void {
            if (template.selectedConfigVariants) {
                delete template.selectedConfigVariants[id]
                //TODO: remove empty selectedConfigVariants
            }
            if (!template.parameters) {
                template.parameters = {}
            }
            if (value === undefined) {
                delete template.parameters[id]
                //TODO: remove empty parameters
            } else {
                template.parameters[id] = value
            }
        }

        export function getParameter(template: TemplateOrInstance, id: string): any {
            if (template.parameters) {
                const value = template.parameters[id]
                if (value !== undefined) return value
            }
            if (template.selectedConfigVariants) {
                const value = template.selectedConfigVariants[id]
                if (value !== undefined) return value
            }
            return undefined
        }

        export function getValueForInterface(desc: InterfaceDescriptor) {
            return desc.value
        }

        export function getAllParameters(template: TemplateOrInstance): {[id: string]: any} {
            return {
                ...template.selectedConfigVariants,
                ...template.parameters,
            }
        }

        export function setAllParameters(template: TemplateOrInstance, parameters: {[id: string]: any} | undefined) {
            delete template.selectedConfigVariants
            if (parameters) {
                template.parameters = parameters
            } else {
                delete template.parameters
            }
        }

        export function setControllingNode(template: TemplateOrInstance, id: string, node: Nodes.Node | null): void {
            setParameter(template, id, node)
        }

        export function getControllingNode(template: TemplateOrInstance, id: string): Nodes.Node | null {
            const value = getParameter(template, id)
            if (isNode(value)) {
                return value
            } else {
                return null
            }
        }

        export const OverridePlaceholder = Symbol("OverridePlaceholder")

        export type OverrideTarget<T> = {
            tree: any
        }

        export function getConfigurationString(iface: InterfaceDescriptor[], includeAllSubTemplateInputs: boolean): string {
            const parameters: {[id: string]: any} = {}
            for (const desc of iface) {
                if (desc.isSetByTemplate && !includeAllSubTemplateInputs) continue
                if (Nodes.Meta.isConfigInput(desc)) {
                    parameters[desc.id] = desc.value
                } else if (Nodes.Meta.isMaterialInput(desc)) {
                    if (desc.value?.materialRevisionId !== undefined) {
                        parameters[desc.id] = {type: "materialReference", materialRevisionId: desc.value.materialRevisionId} as Nodes.MaterialReference
                    }
                } else if (Nodes.Meta.isImageInput(desc)) {
                    //TODO:
                    // console.warn("getConfigurationString: ignoring parameter:", desc);
                } else {
                    // console.warn("getConfigurationString: ignoring parameter:", desc);
                }
            }
            return sortedJSONStringify(parameters)
        }
    }
}

// type DescriptorForField<T> =
//     undefined extends T ? { kind: 'optional', type: DescriptorForField<Exclude<T,undefined>> } : (
//         (T extends string ? { kind: 'primitive', type: 'string' } : never) |
//         (T extends number ? { kind: 'primitive', type: 'number' } : never) |
//         (T extends boolean ? { kind: 'primitive', type: 'boolean' } : never) |
//         (T extends (infer ElementType)[] ? { kind: 'array', type: DescriptorForField<ElementType>} : never) |
//         (T extends Nodes.Node ? { kind: 'node' } : never)
//     );

// type DescriptorForNode<T extends Nodes.NodeBase> = { [K in keyof Omit<T,'type'>]-?: DescriptorForField<T[K]> }
