import {NgModule} from "@angular/core"
import {popperVariation, provideTippyConfig, tooltipVariation} from "@ngneat/helipopper"

/**
 * The only purpose of this is to provide the Tippy configuration in one single place. This
 * configuration is required in two different places: The platform and the configurator.
 */
@NgModule({
    declarations: [],
    imports: [],
    providers: [
        provideTippyConfig({
            appendTo: () => document.body, //this is the standard container for tooltips/overlays, can be changed inline with tpAppendTo
            defaultVariation: "tooltip",
            variations: {
                light: {...tooltipVariation, theme: "light", animation: "scale-subtle", delay: [200, 0], placement: "top"},
                tooltip: {...tooltipVariation, theme: "colormass-tooltip", animation: "scale-subtle"},
                popper: {...popperVariation, theme: "colormass-popover", arrow: false},
            },
        }),
    ],
})
export class TippyConfigurationModule {}
