import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"

export function isAutoTiling(operator: TextureEditNodes.Operator): operator is TextureEditNodes.OperatorAutoTiling {
    return operator.type === "operator-auto-tiling"
}

export function isShift(operator: TextureEditNodes.Operator): operator is TextureEditNodes.OperatorShift {
    return operator.type === "operator-shift"
}

export function isLayerAndMask(operator: TextureEditNodes.Operator): operator is TextureEditNodes.OperatorLayerAndMask {
    return operator.type === "operator-layer-and-mask"
}

export function isOperator(obj: TextureEditNodes.Operator): obj is TextureEditNodes.Operator {
    return isAutoTiling(obj) || isShift(obj) || isLayerAndMask(obj)
}

export function isMaskReference(obj: TextureEditNodes.Operator | TextureEditNodes.DataObjectReference): obj is TextureEditNodes.MaskReference {
    return typeof obj === "object" && obj && obj.type === "data-object-reference"
}
