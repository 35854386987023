import {inject, Injectable} from "@angular/core"
import {ActivatedRouteSnapshot, Router} from "@angular/router"
import {MutationCreateTemplateInput, TemplateState, TemplateType} from "@api"
import {Template} from "@legacy/api-model/template"
import {SdkService} from "@common/services/sdk/sdk.service"
import {firstValueFrom} from "rxjs"
import {TemplateRevision} from "@app/legacy/api-model/template-revision"
import {promiseAllProperties} from "@cm/lib/utils/utils"

@Injectable({
    providedIn: "root",
})
export class LegacyTemplateResolve {
    sdk = inject(SdkService)

    constructor(private router: Router) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<{
        template: Template
        revision: TemplateRevision | undefined
    } | null> {
        try {
            //The template is part of the url, but derived only from the template revision
            const templateId = String(route.params["templateId"])

            const {template} = await this.sdk.gql.resolveServiceTemplate({id: templateId})
            const {legacyId, revisions} = template

            const revisionId = String(route.params["templateRevisionId"])
            const legacyRevisionId = revisions.find((revision) => revision.id === revisionId)?.legacyId

            const legacyTemplate = firstValueFrom(Template.get(legacyId))
            const legacyRevision = legacyRevisionId ? firstValueFrom(TemplateRevision.get(legacyRevisionId)) : undefined

            return promiseAllProperties({template: legacyTemplate, revision: legacyRevision})
        } catch (e) {
            this.router.navigate(["/404"])
            return null
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class LegacyPictureTemplateResolve {
    sdk = inject(SdkService)

    constructor(private router: Router) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<{
        template: Template
        revision: TemplateRevision | undefined
    } | null> {
        try {
            const pictureId = String(route.params["pictureId"])

            const pictureToTemplateId = async (pictureId: string) => {
                const {picture} = await this.sdk.gql.resolveServicePicture({id: pictureId})
                if (picture.template) {
                    return picture.template.id
                } else {
                    const createTemplateInput: MutationCreateTemplateInput = {
                        organizationId: picture.organization.id,
                        state: TemplateState.Draft,
                        templateType: TemplateType.Scene,
                        public: false,
                        pictureId: picture.id,
                    }
                    const {createTemplate} = await this.sdk.gql.resolveServiceCreateTemplate({input: createTemplateInput})

                    await this.sdk.gql.resolveServiceUpdateTemplate({
                        input: {
                            id: createTemplate.id,
                            name: `Template ${createTemplate.legacyId}`,
                        },
                    })

                    return createTemplate.id
                }
            }

            const templateId = await pictureToTemplateId(pictureId)
            const revisionId = String(route.params["templateRevisionId"])

            const {template} = await this.sdk.gql.resolveServiceTemplate({id: templateId})
            const {legacyId, revisions} = template

            const legacyRevisionId = revisions.find((revision) => revision.id === revisionId)?.legacyId

            const legacyTemplate = firstValueFrom(Template.get(legacyId))
            const legacyRevision = legacyRevisionId ? firstValueFrom(TemplateRevision.get(legacyRevisionId)) : undefined

            return promiseAllProperties({template: legacyTemplate, revision: legacyRevision})
        } catch (e) {
            this.router.navigate(["/404"])
            return null
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class LegacyPictureResolve {
    sdk = inject(SdkService)

    constructor(private router: Router) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<{
        template: Template
        revision: TemplateRevision | undefined
    } | null> {
        try {
            const pictureLegacyId = String(route.params["itemId"])

            if (/^\d+$/.test(pictureLegacyId)) {
                const {picture} = await this.sdk.gql.resolveServicePictureFromLegacyId({legacyId: Number(pictureLegacyId)})
                if (picture) {
                    const lastSegment = route.url?.[2]
                    void this.router.navigate(["pictures", picture.id, "revisions", lastSegment?.path ?? "latest"], {queryParams: route.queryParams})
                } else {
                    void this.router.navigate(["404"])
                }
            }
            return null
        } catch (e) {
            console.error(`Error resolving picture by legacyId: ${route.params["itemId"]}`, e)
            return null
        }
    }
}
