import {Component, ViewChild} from "@angular/core"
import {AddShaderInputs, AddShaderOutputs} from "@app/material-editor/models/nodes/add-shader"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"

@Component({
    selector: "cm-add-shader-node",
    templateUrl: "./add-shader-node.component.html",
    styleUrls: ["./add-shader-node.component.scss"],
    standalone: true,
    imports: [NodeBaseComponent],
})
export class AddShaderNodeComponent {
    @ViewChild("nodeBase", {static: true}) nodeBase: MaterialNodeBase | undefined
    inputs = AddShaderInputs
    outputs = AddShaderOutputs
    typeInfo = AddShaderNodeType
}

export const AddShaderNodeType: MaterialNodeType<typeof AddShaderNodeComponent> = {
    id: "addShader",
    label: "Add Shader",
    color: "#4987af",
    name: "ShaderNodeAddShader",
    inputs: [AddShaderInputs.shader, AddShaderInputs.shader_001] as never[],
    outputs: [AddShaderOutputs.shader],
    component: AddShaderNodeComponent,
}
