import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {z} from "zod"

export class UVMap extends DeclareMaterialNode(
    {
        returns: z.object({uv: materialSlots}),
        inputs: z.object({}),
        parameters: z.object({uvMapIndex: z.number().optional()}),
    },
    {
        toThree: async ({parameters}) => {
            const index = parameters.uvMapIndex ?? 0
            const uv = THREENodes.uv(index)
            return {uv}
        },
    },
) {}
