import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"

const TD = TypeDescriptors

const loadMaterialRevisionNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    materialRevisionId: TD.inlet(TD.Number),
    materialGraph: TD.outlet(TD.MaterialGraph),
}

export class LoadMaterialRevisionNew implements NodeClassImpl<typeof loadMaterialRevisionNewDescriptor, typeof LoadMaterialRevisionNew> {
    static descriptor = loadMaterialRevisionNewDescriptor
    static uniqueName = "LoadMaterialRevision"
    sceneManager!: Inlet<ISceneManagerNew>
    materialRevisionId!: Inlet<number>
    materialGraph!: Outlet<IMaterialGraph>

    private _curRevisionId: number | null = null

    run() {
        if (this.sceneManager === NotReady || this.materialRevisionId === NotReady) {
            this.materialGraph.emitIfChanged(NotReady)
            return
        }
        if (this._curRevisionId === this.materialRevisionId) return
        this._curRevisionId = this.materialRevisionId

        this.materialGraph.emitIfChanged(NotReady)

        this.sceneManager.loadMaterialNew(this.materialRevisionId).then((materialGraph) => {
            this.materialGraph.emitIfChanged(materialGraph)
        })
    }

    cleanup() {}
}
