import {EventEmitter} from "@angular/core"
import {CanvasBaseComponent, CanvasPhysicalInfo} from "@common/components/canvas/canvas-base/canvas-base.component"
import * as paper from "paper"
import {fromEvent, Subject, Subscription, takeUntil} from "rxjs"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {CanvasBaseToolboxRootItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-root-item"
import {CanvasBaseToolboxItem, HitInfo} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class CanvasNavigation {
    readonly viewChange = new EventEmitter<Matrix3x2>()

    private _toolboxRootItem: CanvasBaseToolboxRootItem | null = null
    private _toolboxSubscriptions: Subscription[] = []
    physicalInfo: CanvasPhysicalInfo | undefined

    private currentDefaultCursor = "auto"
    private currentCustomCursor: string | undefined = undefined
    private _lastMousePos = new Vector2(0, 0) // we need this since there seems to be no way to poll the cursor position :/
    private unhandledMouseDown = false
    private hammer!: HammerManager
    private destroySubject = new Subject<void>()
    private readonly tool = new paper.Tool()
    readonly toolsLayer: paper.Layer

    private _lastHitInfo: HitInfo = {
        hitItem: undefined,
    }

    constructor(
        public canvasBase: CanvasBaseComponent,
        public canvas: HTMLCanvasElement,
        public project: paper.Project,
        public image: HTMLImageElement,
    ) {
        this.init()
        this.toolsLayer = new paper.Layer()
        this.toolsLayer.name = "ToolsLayer"
        this.setDefaultCursor("grab")
    }

    destroy(): void {
        this.toolboxRootItem = null
        this.destroySubject.next()
        this.destroySubject.complete()
        this.tool.remove()
        this.hammer.destroy()
    }

    get toolboxRootItem(): CanvasBaseToolboxRootItem | null {
        return this._toolboxRootItem
    }

    set toolboxRootItem(canvasToolbox: CanvasBaseToolboxRootItem | null) {
        if (this._toolboxRootItem === canvasToolbox) {
            return
        }
        if (this._toolboxRootItem) {
            this._toolboxSubscriptions.forEach((subscription) => subscription.unsubscribe())
            this._toolboxSubscriptions = []
            this._toolboxRootItem.layer.remove()
        }
        this._toolboxRootItem = canvasToolbox
        if (this._toolboxRootItem) {
            if (this._toolboxRootItem.canvasBase !== this.canvasBase) {
                throw Error("Attempting to attach a foreign toolbox to a canvas.")
            }
            // this._toolboxSubscriptions.push(this._toolbox.setCursor.subscribe((cursor) => this.setCustomCursor(cursor)))
            this.toolsLayer.addChild(this._toolboxRootItem.layer)
        }
    }

    private updateToolboxHitInfo(point: Vector2Like) {
        if (this._toolboxRootItem) {
            const resetHitResult = (item: CanvasBaseToolboxItem) => {
                item.hitInfo = {
                    hitItem: undefined,
                }
                for (const child of item.children) {
                    resetHitResult(child)
                }
            }
            const updateHitResult = (item: CanvasBaseToolboxItem, isVisible: boolean): HitInfo => {
                isVisible = isVisible && item.visible
                let hitItem: CanvasBaseToolboxItem | undefined = undefined
                for (const child of item.children) {
                    if (hitItem) {
                        resetHitResult(child)
                    } else {
                        const hitInfo = updateHitResult(child, isVisible)
                        if (hitInfo.hitItem) {
                            hitItem = hitInfo.hitItem
                        }
                    }
                }
                if (!hitItem) {
                    if (isVisible && item.hitTest(point)) {
                        hitItem = item
                    }
                }
                item.hitInfo = {
                    hitItem,
                }
                return item.hitInfo
            }
            // we only update the last hit info if the hit item does not capture the input
            if (!this._lastHitInfo.hitItem?.hasCapture) {
                this._lastHitInfo = updateHitResult(this._toolboxRootItem, true)
            }
            this.setCustomCursor(this._lastHitInfo.hitItem?.cursor)
        }
    }

    private updateCursor(): void {
        this.canvas.style.cursor = this.currentCustomCursor ? this.currentCustomCursor : this.currentDefaultCursor
    }

    private setDefaultCursor(cursor: string) {
        if (this.currentDefaultCursor == cursor) {
            return
        }
        this.currentDefaultCursor = cursor
        this.updateCursor()
    }

    setCustomCursor(cursor: string | undefined) {
        if (this.currentCustomCursor == cursor) {
            return
        }
        this.currentCustomCursor = cursor
        this.updateCursor()
    }

    get canvasCursorPosition(): Vector2 {
        return this._lastMousePos
    }

    private executeToolboxEvent<
        HandlerName extends "onKeyDown" | "onKeyUp" | "onMouseWheel" | "onMouseDown" | "onMouseUp" | "onMouseDrag" | "onMouseMove",
        EventType extends paper.KeyEvent | WheelEvent | paper.ToolEvent,
    >(toolboxEventHandler: HandlerName, event: EventType): boolean {
        if (!this._toolboxRootItem) {
            return true
        }
        this.updateToolboxHitInfo(this._lastMousePos)
        switch (toolboxEventHandler) {
            case "onKeyDown":
                return this._toolboxRootItem.onKeyDown(event as paper.KeyEvent)
            case "onKeyUp":
                return this._toolboxRootItem.onKeyUp(event as paper.KeyEvent)
            case "onMouseWheel":
                return this._toolboxRootItem.onMouseWheel(event as WheelEvent)
            case "onMouseDown":
                return this._toolboxRootItem.onMouseDown(event as paper.ToolEvent)
            case "onMouseUp":
                return this._toolboxRootItem.onMouseUp(event as paper.ToolEvent)
            case "onMouseDrag":
                return this._toolboxRootItem.onMouseDrag(event as paper.ToolEvent)
            case "onMouseMove":
                return this._toolboxRootItem.onMouseMove(event as paper.ToolEvent)
            default:
                throw new Error(`Unknown toolbox event handler: ${toolboxEventHandler}`)
        }
    }

    private init(): void {
        this.destroySubject = new Subject<void>()
        this.initPinchZoom()
        // const tool: paper.Tool = new paper.Tool();
        fromEvent<paper.KeyEvent>(this.tool, "keydown")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                if (!this.executeToolboxEvent("onKeyDown", event)) {
                    return
                }
            })
        fromEvent<paper.KeyEvent>(this.tool, "keyup")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                if (!this.executeToolboxEvent("onKeyUp", event)) {
                    return
                }
            })

        fromEvent<WheelEvent>(this.canvas, "wheel")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event: WheelEvent) => {
                if (!this.executeToolboxEvent("onMouseWheel", event)) {
                    return
                }
                const mousePosition: paper.Point = new paper.Point(event.offsetX, event.offsetY)
                const mousePositionProject: paper.Point = this.project.view.viewToProject(mousePosition)
                const baseZoomFactor = 1.15
                let zoomFactor: number
                if (event.deltaY > 0) {
                    zoomFactor = 1 / baseZoomFactor
                } else {
                    zoomFactor = baseZoomFactor
                }
                this.zoomTo(this.project.view.zoom * zoomFactor * window.devicePixelRatio)
                // Paper's zoom has the view's center as a fixed point. Make the cursor's position fixed instead.
                const mousePositionProjectZoomed: paper.Point = mousePositionProject.subtract(this.project.view.center).multiply(1 / zoomFactor)
                const zoomCompensatingTranslation: paper.Point = mousePositionProject.subtract(mousePositionProjectZoomed).subtract(this.project.view.center)
                this.offsetView(zoomCompensatingTranslation)
            })

        fromEvent<paper.ToolEvent & {event: MouseEvent}>(this.tool, "mousedown")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                this._lastMousePos = Vector2.fromVector2Like(event.point)
                // Clicking on the canvas does not trigger blur() on the currently focused element. On one hand this leads to an inconsistent UI behavior, since the input fields do
                // not lose focus when clicking on the canvas. On the other hand it can lead to the ExpressionChangedAfterItHasBeenCheckedError.
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                }
                this.setDefaultCursor("grabbing")
                if (this.isValidToolMouseEvent(event, false)) {
                    if (!this.executeToolboxEvent("onMouseDown", event)) {
                        this.unhandledMouseDown = false
                        return
                    }
                }
                this.unhandledMouseDown = true
            })

        fromEvent<paper.ToolEvent & {event: MouseEvent}>(this.tool, "mouseup")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                this._lastMousePos = Vector2.fromVector2Like(event.point)
                this.setDefaultCursor("grab")
                if (this.isValidToolMouseEvent(event, true)) {
                    if (!this.executeToolboxEvent("onMouseUp", event)) {
                        this.unhandledMouseDown = false
                        return
                    }
                }
            })

        fromEvent<paper.ToolEvent & {event: MouseEvent}>(this.tool, "mousedrag")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                this._lastMousePos = Vector2.fromVector2Like(event.point)
                // Don't pan in case of a multi-touch event to avoid conflict with pinch to zoom/pan.
                const mouseEvent: MouseEvent = event.event
                if (mouseEvent.type === "touchmove" && (mouseEvent as unknown as TouchEvent).touches.length > 1) return

                if (this.isValidToolMouseEvent(event, false)) {
                    if (!this.executeToolboxEvent("onMouseDrag", event)) {
                        return
                    }
                }
                if (this.unhandledMouseDown && (mouseEvent.buttons === 1 || mouseEvent.buttons === 2 || mouseEvent.type === "touchmove")) {
                    const offset: paper.Point = event.downPoint.subtract(event.point)
                    this.offsetView(offset)
                }
            })

        fromEvent<paper.ToolEvent & {event: MouseEvent}>(this.tool, "mousemove")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                this._lastMousePos = Vector2.fromVector2Like(event.point)
                if (this.isValidToolMouseEvent(event, false)) {
                    if (!this.executeToolboxEvent("onMouseMove", event)) {
                        return
                    }
                }
            })
    }

    private isValidToolMouseEvent(event: {event: MouseEvent}, isMouseUp: boolean): boolean {
        // we only process NO-BUTTON and LMB events for now such that RMB/MMB is reserved for navigation
        const mouseEvent: MouseEvent = event.event
        const button = isMouseUp ? mouseEvent.button : mouseEvent.buttons // it is rather strange that in case of mouse-up events the corresponding button is found in "button" rather than "buttons"
        return button === 0 || button === 1
    }

    // TODO: unite touch and mouse navigation?
    initPinchZoom() {
        const canvasElement = this.canvas
        const box = canvasElement.getBoundingClientRect()
        const offset = new paper.Point(box.left, box.top)

        const hammer = new Hammer(canvasElement, {})
        this.hammer = hammer
        hammer.get("pinch").set({enable: true})

        let startMatrix: paper.Matrix, startMatrixInverted: paper.Matrix, p0ProjectCoords: paper.Point

        fromEvent<HammerInput>(hammer, "pinchstart")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((e) => {
                startMatrix = this.project.view.matrix.clone()
                startMatrixInverted = startMatrix.inverted()
                const p0 = getCenterPoint(e)
                p0ProjectCoords = this.project.view.viewToProject(p0)
            })

        fromEvent<HammerInput>(hammer, "pinch")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((e) => {
                // Translate and scale view using pinch event's "center" and "scale" properties.
                // Translation computes center's distance from initial center (considering current scale).
                const p = getCenterPoint(e)
                const pProject0 = p.transform(startMatrixInverted)
                const delta = pProject0.subtract(p0ProjectCoords).divide(e.scale)
                this.project.view.matrix = startMatrix.clone().scale(e.scale, p0ProjectCoords).translate(delta)
            })

        function getCenterPoint(e: {center: {x: number; y: number}}) {
            return new paper.Point(e.center.x, e.center.y).subtract(offset)
        }
    }

    zoomToFitSize(width: number, height: number, adjustFactor = 1, stretch = false): void {
        if (width >= this.canvas.width / window.devicePixelRatio || height >= this.canvas.height / window.devicePixelRatio || stretch) {
            const canvasAspectRatio: number = this.canvas.width / this.canvas.height
            const pictureAspectRatio: number = width / height
            if (pictureAspectRatio >= canvasAspectRatio) {
                this.zoomTo((this.canvas.width / width) * adjustFactor)
            } else {
                this.zoomTo((this.canvas.height / height) * adjustFactor)
            }
        } else {
            this.zoomTo(1)
        }
        this.centerPosition(width / 2, height / 2)
    }

    centerPosition(x: number, y: number): void {
        this.project.view.center = new paper.Point(x, y)
        this.onViewChanged()
    }

    zoomToFitCanvasBounds(adjustFactor = 1, stretch = false): void {
        this.zoomToFitSize(this.canvasBase.canvasBounds.width, this.canvasBase.canvasBounds.height, adjustFactor, stretch)
    }

    zoomToFitImage(adjustFactor = 1, stretch = false): void {
        this.zoomToFitSize(this.image.width, this.image.height, adjustFactor, stretch)
    }

    zoomTo(percentage: number): void {
        this.project.view.zoom = percentage / window.devicePixelRatio
        this.onViewChanged()
    }

    offsetView(offset: paper.Point): void {
        this.project.view.center = this.project.view.center.add(offset)
        this.onViewChanged()
    }

    getZoomLevel(): number {
        return this.project.view.zoom * window.devicePixelRatio
    }

    private onViewChanged(): void {
        // here we correct the view matrix to avoid fractional offsets (which cause blurry rendering)
        const matrix = new Matrix3x2([
            this.project.view.matrix.a,
            this.project.view.matrix.b,
            this.project.view.matrix.c,
            this.project.view.matrix.d,
            Math.round(this.project.view.matrix.tx),
            Math.round(this.project.view.matrix.ty),
        ])
        this.project.view.matrix.set(matrix.toArray())
        this.viewChange.emit(matrix)
    }
}
