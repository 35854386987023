import {ImagePtr, ImagePtrReassignable} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {Size2Like} from "@cm/lib/math/size2"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {imageOpCopyRegion} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-copy-region"
import {toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"

export type ParameterType = {
    sourceImage: ImagePtr
    resultSize: Size2Like
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpResize: ImageOpType<ParameterType, ReturnType> = {
    name: "Resize",

    WebGL2: async ({context, parameters: {sourceImage, resultSize, resultImage}}) => {
        // first we successively downsample to the nearest pow2 size which is still greater than the resultSize, then we resample to the final size
        using pow2ResampledSource = new ImagePtrReassignable(sourceImage)
        // COMBINED (HORIZONTAL/VERTICAL) POW2 PASS
        // TODO: this could be optimized by using a single shader which does both horizontal and vertical downsampling in a single pass
        // HORIZONTAL POW2 PASS
        // the image should be shrinked horizontally; successively downsample by 2 until we get to the right size or just above it
        const halDownSampleH = await context.getOrCreateImageCompositor(`
            vec4 computeColor(ivec2 targetPixel) {
                vec4 c0 = texelFetch0(ivec2(targetPixel.x * 2 + 0, targetPixel.y), ADDRESS_MODE_CLAMP_TO_EDGE);
                vec4 c1 = texelFetch0(ivec2(targetPixel.x * 2 + 1, targetPixel.y), ADDRESS_MODE_CLAMP_TO_EDGE);
                return (c0 + c1) * 0.5;
            }
        `)
        // eslint-disable-next-line no-constant-condition
        while (true) {
            using pow2ResampledSourceWebGL2 = await context.getImage(pow2ResampledSource)
            if (resultSize.width * 2 > pow2ResampledSourceWebGL2.ref.descriptor.width) {
                break
            }
            const downSampledSize = {
                width: Math.ceil(pow2ResampledSourceWebGL2.ref.descriptor.width / 2),
                height: pow2ResampledSourceWebGL2.ref.descriptor.height,
            }
            using nextDownSampledSource = await context.createImage({
                ...downSampledSize,
                channelLayout: pow2ResampledSourceWebGL2.ref.descriptor.channelLayout,
                format: pow2ResampledSourceWebGL2.ref.descriptor.format,
                isSRGB: pow2ResampledSourceWebGL2.ref.descriptor.isSRGB,
            })
            using nextDownSampledSourceWebGL2 = await context.getImage(nextDownSampledSource)
            await halDownSampleH.paint(nextDownSampledSourceWebGL2.ref.halImage, pow2ResampledSourceWebGL2.ref.halImage)
            pow2ResampledSource.set(nextDownSampledSource)
        }
        // VERTICAL POW2 PASS
        // the image should be shrinked vertically; successively downsample by 2 until we get to the right size or just above it
        const halDownSampleV = await context.getOrCreateImageCompositor(`
            vec4 computeColor(ivec2 targetPixel) {
                vec4 c0 = texelFetch0(ivec2(targetPixel.x, targetPixel.y * 2 + 0), ADDRESS_MODE_CLAMP_TO_EDGE);
                vec4 c1 = texelFetch0(ivec2(targetPixel.x, targetPixel.y * 2 + 1), ADDRESS_MODE_CLAMP_TO_EDGE);
                return (c0 + c1) * 0.5;
            }
        `)
        // eslint-disable-next-line no-constant-condition
        while (true) {
            using pow2ResampledSourceWebGL2 = await context.getImage(pow2ResampledSource)
            if (resultSize.height * 2 > pow2ResampledSourceWebGL2.ref.descriptor.height) {
                break
            }
            const downSampledSize = {
                width: pow2ResampledSourceWebGL2.ref.descriptor.width,
                height: Math.ceil(pow2ResampledSourceWebGL2.ref.descriptor.height / 2),
            }
            using nextDownSampledSource = await context.createImage({
                ...downSampledSize,
                channelLayout: pow2ResampledSourceWebGL2.ref.descriptor.channelLayout,
                format: pow2ResampledSourceWebGL2.ref.descriptor.format,
                isSRGB: pow2ResampledSourceWebGL2.ref.descriptor.isSRGB,
            })
            using nextDownSampledSourceWebGL2 = await context.getImage(nextDownSampledSource)
            await halDownSampleV.paint(nextDownSampledSourceWebGL2.ref.halImage, pow2ResampledSourceWebGL2.ref.halImage)
            pow2ResampledSource.set(nextDownSampledSource)
        }
        // FINAL RESAMPLING PASS
        using pow2ResampledSourceWebGL2 = await context.getImage(pow2ResampledSource)
        if (resultSize.width !== pow2ResampledSourceWebGL2.ref.descriptor.width || resultSize.height !== pow2ResampledSourceWebGL2.ref.descriptor.height) {
            const halResample = await context.getOrCreateImageCompositor(`
                uniform vec2 u_texelStep;

                vec4 computeColor(ivec2 targetPixel) {
                    return texelFetchInterpolated0((vec2(targetPixel) + 0.5) * u_texelStep, ADDRESS_MODE_CLAMP_TO_EDGE); // +0.5 to sample the center of the texel
                }
            `)
            resultImage = await context.prepareResultImage(resultImage, {
                ...resultSize,
                channelLayout: pow2ResampledSourceWebGL2.ref.descriptor.channelLayout,
                format: pow2ResampledSourceWebGL2.ref.descriptor.format,
                isSRGB: pow2ResampledSourceWebGL2.ref.descriptor.isSRGB,
            })
            using resultImageWebGL2 = await context.getImage(resultImage)
            const texelStep = {
                x: resultSize.width > 1 ? (pow2ResampledSourceWebGL2.ref.descriptor.width - 1) / (resultSize.width - 1) : 0,
                y: resultSize.height > 1 ? (pow2ResampledSourceWebGL2.ref.descriptor.height - 1) / (resultSize.height - 1) : 0,
            }
            halResample.setParameter("u_texelStep", {
                type: "float2",
                value: texelStep,
            })
            await halResample.paint(resultImageWebGL2.ref.halImage, pow2ResampledSourceWebGL2.ref.halImage)
        } else if (resultImage) {
            resultImage = await imageOpCopyRegion.WebGL2({context, parameters: {sourceImage: pow2ResampledSource, resultImage}})
        } else {
            resultImage = new ImagePtr(pow2ResampledSource)
        }
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, resultSize, resultImage}}) => {
        using sourceImageImgProc = await context.getImage(sourceImage)
        const resultNode = Nodes.resize(sourceImageImgProc.ref.node, resultSize.width, resultSize.height)
        using result = await context.createImage(
            {
                width: resultSize.width,
                height: resultSize.height,
                channelLayout: sourceImageImgProc.ref.descriptor.channelLayout,
                format: sourceImageImgProc.ref.descriptor.format,
                isSRGB: sourceImageImgProc.ref.descriptor.isSRGB,
            },
            resultNode,
        )
        return await toImgProcResultImage(context, result, resultImage)
    },
}
