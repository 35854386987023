import {outletDescriptor} from "@src/templates/runtime-graph/descriptors"
import {Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"

const groupDescriptor = {
    __output_value: outletDescriptor<any[]>(),
}

export class Group implements NodeClassImpl<typeof groupDescriptor, typeof Group> {
    static descriptor = groupDescriptor
    static uniqueName = "Group"
    __output_value!: Outlet<any>
    $inletKeys?: string[]
    $constKeys?: string[]

    run() {
        const data: any = {}
        if (this.$inletKeys) {
            for (const key of this.$inletKeys) {
                data[key] = (this as any)[key]
            }
        }
        if (this.$constKeys) {
            for (const key of this.$constKeys) {
                data[key] = (this as any)[key]
            }
        }
        this.__output_value.emitIfChanged(data)
    }
}
