import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {IDataObjectNew} from "@src/templates/interfaces/data-object"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"

const TD = TypeDescriptors

const fetchDataObjectNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    dataObjectId: TD.inlet(TD.Number),
    dataObject: TD.outlet(TD.DataObjectNew),
}

export class FetchDataObjectNew implements NodeClassImpl<typeof fetchDataObjectNewDescriptor, typeof FetchDataObjectNew> {
    static descriptor = fetchDataObjectNewDescriptor
    static uniqueName = "FetchDataObjectNew"
    sceneManager!: Inlet<ISceneManagerNew>
    dataObjectId!: Inlet<number>
    dataObject!: Outlet<IDataObjectNew>

    run() {
        this.dataObject.emitIfChanged(NotReady)
        const sceneManager = this.sceneManager
        if (sceneManager === NotReady || this.dataObjectId === NotReady) {
            return
        }
        sceneManager.loadDataObjectNew(this.dataObjectId).then((dataObject) => {
            this.dataObject.emitIfChanged(dataObject)
        })
    }

    cleanup() {}
}
