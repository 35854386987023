@if ($items().length > 0) {
    <table>
        <thead>
            <tr>
                <th>Description</th>
                <th>SKU</th>
                <th>Price</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            @for (item of $items(); track item) {
                <tr>
                    <td [tp]="item.description" [tpAppendTo]="tooltipContainer">{{ item.description }}</td>
                    <td>{{ item.sku || "N/A" }}</td>
                    <td>{{ item.price | currency }}</td>
                    <td>{{ item.amount }}</td>
                </tr>
            }
        </tbody>
    </table>
    <div class="tooltip-container" #tooltipContainer></div>
}
@if ($items().length === 0) {
    <p style="text-align: center">No prices to display.</p>
}
