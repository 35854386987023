import {interval, map, Observable, startWith, switchMap} from "rxjs"

export const keepLoadingMore = (loadMore$: Observable<boolean>, intervalInMs = 1000): Observable<boolean> => {
    return loadMore$.pipe(
        switchMap((value) =>
            interval(intervalInMs).pipe(
                map(() => value),
                startWith(value),
            ),
        ),
    )
}
