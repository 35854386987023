import {MaterialSocket} from "@material-editor/models/material-socket"

export const InvertOutputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "output", valueType: "output", label: "Color"},
}

export const InvertInputs: Record<string, MaterialSocket> = {
    color: {paramType: "socket", id: "Color", type: "input", valueType: "color", label: "Color"},
    fac: {paramType: "socket", id: "Fac", type: "input", valueType: "scalar", label: "Fac", defaultValue: 1, range: {min: 0, max: 1}},
}
