import {MaterialSocket} from "@material-editor/models/material-socket"

export const CombineXYZOutputs: Record<string, MaterialSocket> = {
    image: {paramType: "socket", id: "Vector", type: "output", valueType: "output", label: "Vector"},
}

export const CombineXYZInputs: Record<string, MaterialSocket> = {
    X: {paramType: "socket", id: "X", type: "input", valueType: "scalar", label: "X"},
    Y: {paramType: "socket", id: "Y", type: "input", valueType: "scalar", label: "Y"},
    Z: {paramType: "socket", id: "Z", type: "input", valueType: "scalar", label: "Z"},
}
