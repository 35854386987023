import {JobGraphMaterial} from "@common/models/material-assets-rendering/job-graph-material"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {CM_TO_INCH, MaterialDetails} from "@common/helpers/rendering/material/material-assets-rendering/common"
import {combinedPassFromRenderTask} from "@app/common/helpers/rendering/rendering"
import {getPhysicalSizeInfoForMaterialGraph} from "@cm/lib/materials/material-node-graph"
import {Settings} from "@common/models/settings/settings"
import {ImageProcessingNodes} from "@cm/lib/image-processing/image-processing-nodes"
import {TileableMaterialPostRenderStepInput, tileableMaterialPostRenderStepTask} from "@cm/lib/job-task/asset-rendering"
import {imageProcessingTask} from "@cm/lib/job-task/image-processing"
import {CreateJobGraphData, JobNodes} from "@cm/lib/job-task/job-nodes"
import {cmRenderTaskForPassNames} from "@cm/lib/job-task/rendering"
import {uploadProcessingThumbnailsTask} from "@cm/lib/job-task/upload-processing"
import {Utility} from "@cm/lib/job-task/utility"
import {environment} from "@environment"

export const TILE_PADDING_FRACTION = 0.1
export const TILE_MIN_PADDING = 200

export function jobGraphFn_tile(
    renderGraphDataObjectId: number,
    materialDetails: JobGraphMaterial,
    widthCm: number,
    heightCm: number,
    width: number,
    height: number,
    paddingWidth: number,
    paddingHeight: number,
    filenames: TileableMaterialPostRenderStepInput["filenames"],
): CreateJobGraphData {
    const renderTask = JobNodes.task(cmRenderTaskForPassNames("Combined"), {input: JobNodes.input(), queueDomain: environment.rendering.defaultQueueDomain})

    const externalData: JobNodes.TypedDataNode<ImageProcessingNodes.ExternalEncodedData> = JobNodes.struct({
        type: JobNodes.value("externalData" as const),
        sourceData: combinedPassFromRenderTask(renderTask),
        resolveTo: JobNodes.value("encodedData" as const),
    })
    const decodedImage: JobNodes.TypedDataNode<ImageProcessingNodes.Decode> = JobNodes.struct({
        type: JobNodes.value("decode" as const),
        input: externalData,
    })
    const croppedImage: JobNodes.TypedDataNode<ImageProcessingNodes.CropImage> = JobNodes.struct({
        type: JobNodes.value("crop" as const),
        input: decodedImage,
        region: JobNodes.value({
            type: "region" as const,
            region: [paddingWidth, paddingHeight, width, height] as ImageProcessingNodes.BBox,
        }),
    })
    const dpiX = width / (widthCm * CM_TO_INCH)
    const dpiY = height / (heightCm * CM_TO_INCH)
    const dpi = (dpiX + dpiY) / 2
    const setDpi: JobNodes.TypedDataNode<ImageProcessingNodes.SetDpi> = JobNodes.struct({
        type: JobNodes.value("setDpi" as const),
        input: croppedImage,
        dpi: JobNodes.value(dpi),
    })
    const encodedImage: JobNodes.TypedDataNode<ImageProcessingNodes.Encode> = JobNodes.struct({
        type: JobNodes.value("encode" as const),
        input: setDpi,
        mediaType: JobNodes.value("image/x-exr"),
    })
    const imgProcTask = JobNodes.task(imageProcessingTask, {input: JobNodes.struct({graph: encodedImage})})

    const uploadProcessingTaskNode = JobNodes.task(uploadProcessingThumbnailsTask, {
        input: Utility.DataObject.update(imgProcTask, {imageDataType: "COLOR" as const}),
    })

    const postRenderTask = JobNodes.task(tileableMaterialPostRenderStepTask, {
        input: JobNodes.struct({
            renderImage: JobNodes.get(uploadProcessingTaskNode, "dataObject"),
            materialId: JobNodes.value(materialDetails.legacyId),
            customerId: JobNodes.value(materialDetails.organization.legacyId),
            filenames: JobNodes.value(filenames),
        }),
    })

    return JobNodes.jobGraph(postRenderTask, {
        progress: {
            type: "progressGroup",
            items: [
                {node: renderTask, factor: 15},
                {node: uploadProcessingTaskNode, factor: 2},
                {node: postRenderTask, factor: 4},
            ],
        },
        input: {
            renderGraph: JobNodes.dataObjectReference(renderGraphDataObjectId),
            customerId: materialDetails.organization.legacyId,
        },
        platformVersion: Settings.APP_VERSION,
    })
}

async function tileParamsForMaterial(materialDetails: Pick<MaterialDetails, "latestCyclesRevision">, materialGraphService: MaterialGraphService) {
    const materialGraph = await materialGraphService.graphFromMaterialRevisionId({legacyId: materialDetails.latestCyclesRevision.legacyId})
    const info = getPhysicalSizeInfoForMaterialGraph(materialGraph)

    if (!info) throw Error(`Could not determine physical size for material {material.id}`)
    const repeatWidth = Math.round(info.pxPerCm * info.widthCm)
    const repeatHeight = Math.round(info.pxPerCm * info.heightCm)
    return {repeatWidthCm: info.widthCm, repeatHeightCm: info.heightCm, repeatWidth, repeatHeight, pxPerCm: info.pxPerCm}
}

/**reduces padded image size to maxNumPixels by adjusting the size of the unpadded image and keeping its aspect ratio, assumptions:
 * scaleFactorWidth scales the original width to the padded width
 * scaleFactorWidth * repeatWidth * scaleFactorHeight * repeatHeight = maxNumPixels
 * repeatWidth/repeatHeight = ratio
 */
function resizeTileIfNeeded(repeatWidth: number, repeatHeight: number, repeatWidthCm: number, pxPerCm: number) {
    const maxNumPixels = 16000 * 16000 // when x * y * 4 Channels * 4 Bytes > 2^32 Bytes, rendering fails because only 2^32 Bytes can be addressed.
    const scaleFactorHeight = 1 + TILE_PADDING_FRACTION + TILE_PADDING_FRACTION
    const scaleFactorWidth = 1 + TILE_PADDING_FRACTION + TILE_PADDING_FRACTION
    if (scaleFactorWidth * repeatWidth * scaleFactorHeight * repeatHeight > maxNumPixels) {
        const ratio = repeatWidth / repeatHeight
        repeatHeight = Math.round(Math.sqrt(maxNumPixels / (ratio * scaleFactorHeight * scaleFactorWidth)))
        repeatWidth = Math.round(repeatHeight * ratio)
        pxPerCm = repeatWidth / repeatWidthCm
    }

    return {repeatWidth, repeatHeight, pxPerCm}
}

export async function tileGenerationParamsForMaterial(materialDetails: MaterialDetails, materialGraphService: MaterialGraphService) {
    const {
        repeatWidthCm,
        repeatHeightCm,
        repeatWidth: initialRepeatWidth,
        repeatHeight: initialRepeatHeight,
        pxPerCm: initialPxPerCm,
    } = await tileParamsForMaterial(materialDetails, materialGraphService)
    const {repeatWidth, repeatHeight, pxPerCm} = resizeTileIfNeeded(initialRepeatWidth, initialRepeatHeight, repeatWidthCm, initialPxPerCm)

    const paddingWidth = Math.max(Math.round(TILE_PADDING_FRACTION * repeatWidth), TILE_MIN_PADDING)
    const paddingHeight = Math.max(Math.round(TILE_PADDING_FRACTION * repeatHeight), TILE_MIN_PADDING)
    const paddingWidthCm = paddingWidth / pxPerCm
    const paddingHeightCm = paddingHeight / pxPerCm

    const paddedWidth = repeatWidth + 2 * paddingWidth
    const paddedHeight = repeatHeight + 2 * paddingHeight
    const paddedWidthCm = repeatWidthCm + 2 * paddingWidthCm
    const paddedHeightCm = repeatHeightCm + 2 * paddingHeightCm

    const offsetX = repeatWidthCm / 2
    const offsetY = repeatHeightCm / 2

    return {
        repeatWidth,
        repeatHeight,
        repeatWidthCm,
        repeatHeightCm,
        paddingWidth,
        paddingHeight,
        paddedWidth,
        paddedHeight,
        paddedWidthCm,
        paddedHeightCm,
        offsetX,
        offsetY,
        pxPerCm,
    }
}
