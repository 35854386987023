import {Component, inject, input} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ToggleComponent} from "@app/common/components/buttons/toggle/toggle.component"
import {Object} from "@cm/lib/templates/node-types"
import {TemplateMenuComponent} from "../template-menu/template-menu.component"
import {TemplateMenuSectionComponent} from "../template-menu-section/template-menu-section.component"
import {ListItemComponent} from "@app/common/components/item"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {Matrix4} from "@app/common/helpers/vector-math"

@Component({
    selector: "cm-template-tree-object-transform",
    standalone: true,
    imports: [MatMenuModule, ToggleComponent, MatTooltipModule, TemplateMenuComponent, TemplateMenuSectionComponent, ListItemComponent],
    templateUrl: "./template-tree-object-transform.component.html",
    styleUrl: "./template-tree-object-transform.component.scss",
})
export class TemplateTreeObjectTransformComponent {
    object = input.required<Object>()
    processingNodes = input<Object[] | undefined>(undefined)

    private sceneManagerService = inject(SceneManagerService)

    private getProcessingNodes() {
        const processingNodes = this.processingNodes()
        if (processingNodes) return processingNodes

        const object = this.object()
        return [object]
    }

    resetNodeTransform() {
        const processingNodes = this.getProcessingNodes()
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            processingNodes.forEach((templateNode) => {
                templateNode.updateParameters({
                    lockedTransform: this.sceneManagerService.getISceneManager().defaultTransformForObjectNew(templateNode, false)?.toArray(),
                })
            })
        })
    }

    setTransformLocked(locked: boolean) {
        const processingNodes = this.getProcessingNodes()
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            processingNodes.forEach((templateNode) => {
                if (locked && !templateNode.parameters.lockedTransform) {
                    templateNode.parameters.lockedTransform =
                        this.sceneManagerService.getTransformAccessor(templateNode)?.getTransform()?.toArray() ??
                        this.sceneManagerService.getISceneManager().defaultTransformForObjectNew(templateNode, false)?.toArray()
                } else if (!locked && templateNode.parameters.lockedTransform) {
                    templateNode.updateParameters({lockedTransform: undefined})
                }
            })
        })
    }

    moveToOrigin() {
        const processingNodes = this.getProcessingNodes()
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            const transform = Matrix4.translation(0, 0, 0)
            processingNodes.forEach((templateNode) => {
                if (templateNode.parameters.lockedTransform) templateNode.updateParameters({lockedTransform: transform.toArray()})
            })
        })
    }
}
