@if (viewMode === ViewMode.Setup && isEdited) {
    <cm-hint>
        <ng-container cm-text> Click <b>Submit changes</b> in order to process the changes made and produce a new result image.</ng-container>
    </cm-hint>
}
@if (isComplete) {
    <cm-inspector-section>
        <ng-container cm-content>
            <div class="cm-auto-tiling-actions-container">
                @if (viewMode !== ViewMode.Setup) {
                    <cm-button (click)="viewMode = ViewMode.Setup" matTooltip="Set up the automatic tiling process." class="cm-auto-tiling-actions">
                        <ng-container cm-left-icon><i class="far fa-pen"></i></ng-container>
                        <ng-container cm-text>Edit</ng-container>
                    </cm-button>
                } @else {
                    <cm-button (click)="viewMode = ViewMode.Result" matTooltip="See the result of the automatic tiling process." class="cm-auto-tiling-actions">
                        <ng-container cm-left-icon><i class="far fa-chevron-left"></i></ng-container>
                        <ng-container cm-text>Back to result</ng-container>
                    </cm-button>
                }
            </div>
        </ng-container>
    </cm-inspector-section>
}
@if (viewMode === ViewMode.Setup) {
    @if (!canChangeAutoTilingSetup) {
        <cm-hint>
            <ng-container cm-text> The Auto-Tiling setup can not be modified, because further operators have been applied to the result already. </ng-container>
        </cm-hint>
    }
    <cm-inspector-section>
        <ng-container cm-title> Actions</ng-container>
        <ng-container cm-content>
            <div class="cm-auto-tiling-actions-container">
                <cm-button
                    [disabled]="!canChangeAutoTilingSetup"
                    (click)="cutoutClick()"
                    [class.cm-active]="tilingCutoutActive"
                    matTooltip="Cut-out region"
                    class="cm-auto-tiling-actions"
                >
                    <ng-container cm-left-icon><i class="far fa-draw-square"></i></ng-container>
                    <ng-container cm-text>Cut-out</ng-container>
                </cm-button>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Generic</ng-container>
        <ng-container cm-content>
            <div class="cm-auto-tiling-options">
                <cm-action-panel-row>
                    <mat-form-field>
                        <mat-label>Map impact</mat-label>
                        <mat-select [disabled]="!canChangeAutoTilingSetup" [(value)]="featureWeighting">
                            <mat-option value="diffuse">Diffuse</mat-option>
                            <mat-option value="normal">Normal</mat-option>
                            <mat-option value="normal_diffuse">Diffuse + Normal</mat-option>
                        </mat-select>
                    </mat-form-field>
                </cm-action-panel-row>
                <cm-action-panel-row>
                    <mat-form-field>
                        <mat-label>Blending radius</mat-label>
                        <input matInput [disabled]="!canChangeAutoTilingSetup" type="number" [min]="8" [max]="256" [step]="1" [(ngModel)]="maxBlendingRadius" />
                    </mat-form-field>
                </cm-action-panel-row>
            </div>
        </ng-container>
    </cm-inspector-section>
    @if (patternType === "large") {
        <cm-inspector-section>
            <ng-container cm-title> Alignment</ng-container>
            <ng-container cm-content>
                <div class="cm-auto-tiling-options">
                    <cm-action-panel-row>
                        <mat-form-field>
                            <mat-label>Alignment search distance (cm)</mat-label>
                            <input
                                [disabled]="!canChangeAutoTilingSetup"
                                type="number"
                                min="0"
                                max="2"
                                step=".1"
                                [ngModelOptions]="{updateOn: 'blur'}"
                                [(ngModel)]="edgeAlignmentWindowSize"
                                matInput
                            />
                        </mat-form-field>
                    </cm-action-panel-row>
                    @if (edgeAlignmentWindowSize != 0) {
                        <cm-action-panel-row>
                            <mat-form-field>
                                <mat-label>Alignment smoothing iterations</mat-label>
                                <input
                                    [disabled]="!canChangeAutoTilingSetup"
                                    type="number"
                                    min="0"
                                    max="30"
                                    step="1"
                                    [ngModelOptions]="{updateOn: 'blur'}"
                                    [(ngModel)]="edgeAlignmentSmoothingIterations"
                                    matInput
                                />
                            </mat-form-field>
                        </cm-action-panel-row>
                    }
                    @if (edgeAlignmentWindowSize != 0) {
                        <cm-action-panel-row>
                            <mat-form-field>
                                <mat-label>Map alignment</mat-label>
                                <mat-select
                                    [disabled]="!canChangeAutoTilingSetup"
                                    [(value)]="alignIndividualMaps"
                                    matTooltip="WARNING: Only use this if tiling is not possible because of pattern mismatches between diffuse and normal"
                                >
                                    <mat-option [value]="true">Individual</mat-option>
                                    <mat-option [value]="false">Collective</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </cm-action-panel-row>
                    }
                </div>
            </ng-container>
        </cm-inspector-section>
    }
    @if (isStaff) {
        <cm-inspector-section>
            <ng-container cm-title> Advanced</ng-container>
            <ng-container cm-content>
                <div class="cm-auto-tiling-options">
                    <cm-action-panel-row>
                        <mat-form-field>
                            <mat-label>Tiling border size</mat-label>
                            <input
                                matInput
                                [disabled]="!canChangeAutoTilingSetup"
                                type="number"
                                [min]="50"
                                [max]="1000"
                                [step]="1"
                                [(ngModel)]="blendingBorder"
                            />
                        </mat-form-field>
                    </cm-action-panel-row>
                    <cm-action-panel-row>
                        <mat-form-field>
                            <mat-label>Gradient correction</mat-label>
                            <mat-select
                                [disabled]="!canChangeAutoTilingSetup"
                                [(value)]="gradientCorrection"
                                matTooltip="Try disabling this if there are still color gradients in the output"
                            >
                                <mat-option [value]="true">On</mat-option>
                                <mat-option [value]="false">Off</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </cm-action-panel-row>
                    <cm-action-panel-row>
                        <mat-form-field>
                            <mat-label>Filter mode</mat-label>
                            <mat-select [disabled]="!canChangeAutoTilingSetup" [(value)]="filterMode">
                                <mat-option value="normal">Normal Only</mat-option>
                                <mat-option value="allExceptDiffuse">All except Diffuse</mat-option>
                                <mat-option value="all">All</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </cm-action-panel-row>
                    <cm-action-panel-row>
                        <mat-form-field>
                            <mat-label>Filter radius (cm, 0 = off)</mat-label>
                            <input
                                [disabled]="!canChangeAutoTilingSetup"
                                type="number"
                                min="0"
                                max="25"
                                step="1"
                                [ngModelOptions]="{updateOn: 'blur'}"
                                [(ngModel)]="filterRadius"
                                matInput
                            />
                        </mat-form-field>
                    </cm-action-panel-row>
                </div>
            </ng-container>
        </cm-inspector-section>
    }
}
