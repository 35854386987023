import * as WebGl2ShaderUtils from "@common/helpers/webgl2/webgl2-shader-utils"
import {assertNever} from "@cm/lib/utils/utils"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {ChannelLayout, Format, ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {HalImageDescriptor, HalImageOptions, HalImageChannelLayout, HalImageFormat} from "@common/models/hal/hal-image/types"
import {HalImage} from "@common/models/hal/hal-image"

export function getHalAddressMode(addressMode: AddressMode): number {
    switch (addressMode) {
        case "clamp":
            return WebGl2ShaderUtils.resolveTextureAddressMode("CLAMP_TO_EDGE")
        case "wrap":
            return WebGl2ShaderUtils.resolveTextureAddressMode("REPEAT")
        case "border":
            return WebGl2ShaderUtils.resolveTextureAddressMode("BORDER")
        default:
            assertNever(addressMode)
    }
}

const getHalImageDescriptorChannelLayout = (channelLayout: ChannelLayout): ChannelLayout => {
    switch (channelLayout) {
        case "RGBA":
            return "RGBA"
        case "RGB":
            return "RGB"
        case "R":
            return "R"
        default:
            assertNever(channelLayout)
    }
}

const getHalImageDescriptorFormat = (format: Format): HalImageFormat => {
    switch (format) {
        case "uint8":
            return "uint8"
        case "float32":
            return "float32"
        case "float16":
            return "float16"
        default:
            assertNever(format)
    }
}

export const getHalImageDescriptor = (descriptor: ImageDescriptor): HalImageDescriptor => {
    return {
        width: descriptor.width,
        height: descriptor.height,
        channelLayout: getHalImageDescriptorChannelLayout(descriptor.channelLayout),
        format: getHalImageDescriptorFormat(descriptor.format),
    }
}

export const getHalImageOptions = (descriptor: ImageDescriptor): HalImageOptions => {
    return {
        useMipMaps: false,
        useSRgbFormat: descriptor.isSRGB,
    }
}

export function descriptorFromHalImageDescriptorAndOptions(descriptor: HalImageDescriptor, options: HalImageOptions): ImageDescriptor {
    const getChannelLayoutFromHalChannelLayout = (channelLayout: HalImageChannelLayout): ChannelLayout => {
        switch (channelLayout) {
            case "RGBA":
                return "RGBA"
            case "RGB":
                return "RGB"
            case "R":
                return "R"
            default:
                assertNever(channelLayout)
        }
    }
    const getFormatFromHalFormat = (format: HalImageFormat): Format => {
        switch (format) {
            case "uint8":
                return "uint8"
            case "float32":
                return "float32"
            case "float16":
                return "float16"
            default:
                assertNever(format)
        }
    }
    return {
        width: descriptor.width,
        height: descriptor.height,
        channelLayout: getChannelLayoutFromHalChannelLayout(descriptor.channelLayout),
        format: getFormatFromHalFormat(descriptor.format),
        isSRGB: options.useSRgbFormat,
    }
}

export function descriptorFromHalImage(halImage: HalImage): ImageDescriptor {
    return descriptorFromHalImageDescriptorAndOptions(halImage.descriptor, halImage.options)
}
