// @ts-strict-ignore
import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-plain-input-socket",
    templateUrl: "./plain-input-socket.component.html",
    styleUrls: ["./plain-input-socket.component.scss"],
    standalone: true,
})
export class PlainInputSocketComponent {
    @Input() label: string
    @Input() value: string

    _connected: {value: boolean} = {value: false}
    get connected(): {value: boolean} {
        return this._connected
    }

    @Input() set connected(value: {value: boolean}) {
        this._connected = value
    }
}
