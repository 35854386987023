// @ts-strict-ignore
import {CommonModule} from "@angular/common"
import {Component, HostListener, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Breakpoints} from "@common/models/constants/breakpoints"
import {SidenavComponent} from "@common/components/navigation"

@Component({
    selector: "cm-entity-responsive-sidebar",
    templateUrl: "./entity-responsive-sidebar.component.html",
    styleUrls: ["./entity-responsive-sidebar.component.scss"],
    standalone: true,
    imports: [CommonModule, MatTooltipModule, SidenavComponent],
})
export class EntityResponsiveSidebarComponent implements OnInit {
    @HostListener("window:resize", ["$event"])
    onResize(_event: Event) {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
    }

    isBigScreen: boolean
    sidenavOpen = false

    constructor() {}

    ngOnInit() {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
    }
}
