import {MaterialSocket} from "@material-editor/models/material-socket"

export const FresnelOutputs: Record<string, MaterialSocket> = {
    fac: {paramType: "socket", id: "Fac", type: "output", valueType: "output", label: "Fac"},
}

export const FresnelInputs: Record<string, MaterialSocket> = {
    ior: {paramType: "socket", id: "IOR", type: "input", valueType: "scalar", label: "IOR"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "vector", label: "Normal"},
}
