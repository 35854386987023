<div class="cm-container">
    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <button
                    mat-icon-button
                    class="cm-copy-reference-button"
                    matTooltip="ID: {{ item.id }}"
                    (click)="utils.copyToClipboard(item.id); $event.stopPropagation()"
                >
                    <i class="far fa-copy"></i>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hostName">
            <mat-header-cell *matHeaderCellDef>Host name</mat-header-cell>
            <mat-cell *matCellDef="let item">
                @if (parseOtherInfo(item.otherInfo); as info) {
                    {{ info.hostName }}
                }
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cpuCores">
            <mat-header-cell *matHeaderCellDef>CPU cores</mat-header-cell>
            <mat-cell *matCellDef="let item">
                @if (parseOtherInfo(item.otherInfo); as info) {
                    {{ info.numCpuCores }}
                }
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ram">
            <mat-header-cell *matHeaderCellDef>RAM</mat-header-cell>
            <mat-cell *matCellDef="let item">
                @if (parseOtherInfo(item.otherInfo); as info) {
                    {{ info.totalMemoryGiB }} GiB
                }
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef>Group</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.group?.name ?? "(No group assigned)" }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastReport">
            <mat-header-cell *matHeaderCellDef>Last seen</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.lastReport | amTimeAgo }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.state }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.type }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="version">
            <mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.version }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>User</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item.user?.email }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="cm-list-row" *matRowDef="let item; columns: displayedColumns" (click)="$event.stopPropagation(); rowClick(item)"></mat-row>
    </mat-table>
    <mat-paginator [hidePageSize]="true" [showFirstLastButtons]="false" aria-label="Select page"></mat-paginator>
</div>
