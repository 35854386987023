import {Component} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogActions, MatDialogClose, MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {RouterModule} from "@angular/router"
import {ContentTypeModel, MutationCreateUserInput, MutationUpdateUserInput, UserListItemFragment} from "@api"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {InfiniteScrollAnchorComponent} from "@common/components/data"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent, ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {extractErrorMessage} from "@app/common/helpers/api/errors"

@Component({
    selector: "cm-users-grid",
    templateUrl: "./users-grid.component.html",
    styleUrls: ["./users-grid.component.scss"],
    standalone: true,
    imports: [
        CardErrorComponent,
        CardPlaceholderComponent,
        CheckboxesFilterComponent,
        EntityCardComponent,
        EntityResponsiveSidebarComponent,
        FormsModule,
        InfiniteListComponent,
        ItemFiltersComponent,
        ListInfoComponent,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        RouterModule,
        TagSearchFilterComponent,
        InfiniteScrollAnchorComponent,
    ],
})
export class UsersGridComponent extends ItemListComponent<UserListItemFragment, MutationUpdateUserInput, MutationCreateUserInput> {
    protected override _contentTypeModel = ContentTypeModel.User
    protected override _createItem = (data: MutationCreateUserInput) =>
        this.sdk.throwable
            .inviteUser({
                input: data,
            })
            .then(({inviteUser: model}) => model)
            .catch((errorResponse) => {
                throw Error(extractErrorMessage(errorResponse, {409: "User with this email already exists"}))
            })

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.sdk.gql
            .getUsers({
                take: take,
                skip: skip,
                filter: this.filters.userFilter(),
            })
            .then(({users, usersCount}) => ({items: users, totalCount: usersCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .getUsers({
                take: 1,
                filter: {
                    ...this.filters.userFilter(),
                    id: id ? {equals: id} : undefined,
                    legacyId: legacyId ? {equals: legacyId} : undefined,
                },
            })
            .then(({users}) => users?.[0] || undefined)
}
