import {MaterialSocket} from "@material-editor/models/material-socket"

export const CombineRgbOutputs: Record<string, MaterialSocket> = {
    image: {paramType: "socket", id: "Image", type: "output", valueType: "output", label: "Image"},
}

export const CombineRgbInputs: Record<string, MaterialSocket> = {
    red: {paramType: "socket", id: "R", type: "input", valueType: "scalar", label: "R", range: {min: 0, max: 1}},
    green: {paramType: "socket", id: "G", type: "input", valueType: "scalar", label: "G", range: {min: 0, max: 1}},
    blue: {paramType: "socket", id: "B", type: "input", valueType: "scalar", label: "B", range: {min: 0, max: 1}},
}
