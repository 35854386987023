import {Component, EventEmitter, HostListener, OnInit, Output, ViewChild} from "@angular/core"
import {ActivatedRoute} from "@angular/router"
import {RenderJobViewerDialogJobGQL} from "@api"
import {PictureRenderJobOutput} from "@cm/lib/job-task/rendering"
import {jsonToGraph} from "@cm/lib/utils/graph-json"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {ActionBarComponent, ActionItemComponent} from "@common/components/menu"
import {RenderOutputViewerComponent} from "@editor/components/render-output-viewer/render-output-viewer.component"
import {map} from "rxjs"

@Component({
    selector: "cm-render-job-viewer-dialog",
    templateUrl: "./render-job-viewer-dialog.component.html",
    styleUrls: ["./render-job-viewer-dialog.component.scss"],
    standalone: true,
    imports: [ActionBarComponent, RoutedDialogComponent, RenderOutputViewerComponent, ActionItemComponent],
})
export class RenderJobViewerDialogComponent implements OnInit {
    @ViewChild("overlayDialog", {static: true}) overlayDialog!: RoutedDialogComponent
    @Output() close = new EventEmitter<void>()
    dialogSizes = DialogSize
    renderJobOutput?: PictureRenderJobOutput

    @HostListener("document:keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
        //TODO: ignore these events when an input field is focused
        switch (event.code) {
            case "Escape":
                this.overlayClosed()
                break
        }
    }

    constructor(
        private route: ActivatedRoute,
        private getJobDetailsWithOutputGQL: RenderJobViewerDialogJobGQL,
    ) {
        const renderJob = this.route.snapshot.data.renderJob
        this.getJobDetailsWithOutputGQL
            .fetch({legacyId: renderJob.id})
            .pipe(
                map((res) => res.data.job),
                map((renderJob) => jsonToGraph(renderJob.output) as PictureRenderJobOutput),
            )
            .subscribe((renderJobOutput) => {
                this.renderJobOutput = renderJobOutput
            })
    }

    ngOnInit(): void {}

    closeOverlay(): void {
        this.overlayDialog.closeIfClean()
    }

    overlayClosed(): void {
        this.close.emit()
    }
}
