import {Component, computed, output} from "@angular/core"
import {SearchComponent} from "../../../common/components/inputs/search/search.component"
import {InfiniteListComponent} from "../../../common/components/lists/infinite-list/infinite-list.component"
import {CardErrorComponent} from "../../../common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "../../../common/components/cards/card-placeholder/card-placeholder.component"
import {cardAnimations} from "@app/common/components/cards/animations"
import {MaterialFilterInput, TemplateAddMaterialItemFragment} from "@api"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {BaseSelectComponent} from "@app/platform/components/base/base-select/base-select.component"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"
import {OwnOrganizationsFilterComponent} from "../../../common/components/filters/own-organizations-filter/own-organizations-filter.component"

@Component({
    selector: "cm-template-add-material",
    standalone: true,
    templateUrl: "./template-add-material.component.html",
    styleUrl: "./template-add-material.component.scss",
    animations: [...cardAnimations],
    imports: [SearchComponent, InfiniteListComponent, CardErrorComponent, TemplateAddCardComponent, CardPlaceholderComponent, OwnOrganizationsFilterComponent],
})
export class TemplateAddMaterialComponent extends BaseSelectComponent<TemplateAddMaterialItemFragment, MaterialFilterInput> {
    private rawData = this.implementDataLoader(this.sdk.gql.templateAddMaterialItems)
    data = computed(() => this.rawData().items.map((item) => ({...item, node: item.data ? this.getMaterialReference(item.data) : undefined})))
    onItemClicked = output()

    constructor() {
        super()
        this.customFilters = {hasCyclesMaterial: true}
    }

    private getMaterialReference(data: TemplateAddMaterialItemFragment) {
        const materialRevisionId = data.latestCyclesRevision?.legacyId
        if (materialRevisionId === undefined) throw new Error("Material revision ID is undefined")

        return new MaterialReference({name: data.name ?? "New Material Reference", materialRevisionId})
    }
}
