import {inject} from "@angular/core"
import {ApolloLink, FetchResult, Observable, Operation} from "@apollo/client/core"
import {TokenService} from "@common/services/auth/token.service"
import {Settings} from "@common/models/settings/settings"
import {print} from "graphql"
import {Client, ClientOptions, createClient} from "graphql-sse"

class SSELink extends ApolloLink {
    private client: Client
    private tokenService = inject(TokenService)

    constructor(options: ClientOptions) {
        super()
        this.client = createClient(options)
    }

    public override request(operation: Operation): Observable<FetchResult> {
        return new Observable((sink) => {
            return this.client.subscribe<FetchResult>(
                {...operation, query: print(operation.query)},
                {
                    next: sink.next.bind(sink),
                    complete: sink.complete.bind(sink),
                    error: sink.error.bind(sink),
                },
            )
        })
    }
}

const url = Settings.GQL_API_ENDPOINT

export const getSseLink = (): SSELink => {
    const tokenService = inject(TokenService)
    return new SSELink({
        url,
        headers: (): Record<string, string> => {
            const acceptHeaders: Record<string, string> = {
                Accept: "charset=utf-8",
            }

            // Get the authentication token from local storage if it exists
            const token = tokenService.load()
            const authHeaders: Record<string, string> = token ? {Authorization: `Bearer ${token}`} : {}

            return {
                ...acceptHeaders,
                ...authHeaders,
            }
        },
    })
}
