import {registerNode} from "@src/graph-system/register-node"
import {DeclareListNodeTS} from "@src/graph-system/utils"
import {ConditionalAmountNode} from "@src/pricing/nodes/conditional-amount-node"
import {PricingContext} from "@src/pricing/nodes/core"

@registerNode
export class ConditionalAmountList extends DeclareListNodeTS<PricingContext, ConditionalAmountNode>(
    {},
    {
        nodeClass: "ConditionalAmountList",
    },
) {}
