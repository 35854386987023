import {AfterViewInit, Component, Input, ViewChild} from "@angular/core"
import {CanvasBaseComponent, CanvasPhysicalInfo} from "@common/components/canvas"
import * as paper from "paper"
import {firstValueFrom, Subject, takeUntil} from "rxjs"
import {Box2, Box2Like} from "@cm/lib/math/box2"
import {ImagePtrWebGl2, ImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {SmartPtrReassignable} from "app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {OperatorToolboxBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {HalPainterImageStretchTiled} from "app/textures/texture-editor/tiling-canvas/hal/hal-painter-image-stretch-tiled"
import {AsyncReentrancyGuard} from "@cm/lib/utils/async-reentrancy-guard"
import {TextureEditorCallback} from "app/textures/texture-editor/texture-editor-callback"
import {Operator} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator"

const TRACE = TextureEditorSettings.EnableFullTrace

@Component({
    selector: "cm-tiling-canvas",
    templateUrl: "./tiling-canvas.component.html",
    styleUrls: ["./tiling-canvas.component.scss"],
    standalone: true,
    imports: [CanvasBaseComponent],
})
export class TilingCanvasComponent implements AfterViewInit {
    @ViewChild("canvasBase", {static: true}) canvasBase!: CanvasBaseComponent

    @Input() callback!: TextureEditorCallback

    @Input() physicalInfo!: CanvasPhysicalInfo

    @Input() set gridActive(value: boolean) {
        if (this._gridActive === value) {
            return
        }
        this._gridActive = value
        this.canvasBase.requestRedraw()
    }

    get gridActive(): boolean {
        return this._gridActive
    }

    @Input() set gridOutlineActive(value: boolean) {
        this._gridOutlineActive = value
        this.updateGrid()
    }

    ngAfterViewInit(): void {
        this.canvasBase.paperLayer.activate()
        this.gridLinesGroup = new paper.Group()
        this.gridLinesGroup.name = "GridLines"

        this.halPainterImageDrawTiled = new HalPainterImageStretchTiled(this.canvasBase.halContext)
        this.canvasBase.customDrawFn = this.drawCanvasImage.bind(this)
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.halPainterImageDrawTiled.dispose()
    }

    get toolbox(): OperatorToolboxBase<Operator> | null {
        return this.operatorToolbox
    }

    set toolbox(value: OperatorToolboxBase<Operator> | null) {
        if (this.operatorToolbox === value) {
            return
        }
        this.operatorToolbox = value
        this.canvasBase.toolbox = value
    }

    get displayGamma(): number {
        return this.canvasBase.displayGamma
    }

    set displayGamma(value: number) {
        this.canvasBase.displayGamma = value
        this.canvasBase.requestRedraw()
    }

    async loadImage(url: string, isSRGB: boolean) {
        try {
            await this._loadImageGate.startAndRejectCurrent(async () =>
                firstValueFrom(this.canvasBase.loadImage(url, isSRGB).pipe(takeUntil(this.unsubscribe))),
            )
        } catch (_error) {
            // ignore
        }
    }

    setResultImage(resultImage: ImagePtrWebGl2 | null): void {
        this.resultImage.set(resultImage)
        this.canvasBase.requestRedraw()
        this.updateGrid()
    }

    setMessage(message: string | undefined): void {
        this.message = message
    }

    private async drawCanvasImage(): Promise<Box2Like> {
        if (TRACE) {
            console.log("drawCanvasImage - begin")
        }
        const webGlCanvasComponent = this.canvasBase.webGlCanvasComponent
        const halImage = this.resultImage.isAssigned ? this.resultImage.ref.halImage : this.canvasBase.halImage // show result image if available, fallback to source image
        const drawnBox = await this.halPainterImageDrawTiled.paint(
            webGlCanvasComponent.backBufferImage,
            halImage,
            this._gridActive
                ? new Box2(0, 0, webGlCanvasComponent.backBufferImage.descriptor.width, webGlCanvasComponent.backBufferImage.descriptor.height)
                : undefined,
            {transform: this.canvasBase.viewTransform},
        )
        if (TRACE) {
            console.log("drawCanvasImage - end")
        }
        return drawnBox
    }

    loadingCompleted(): void {
        // This is required to redraw the grid in case the texture size changes while the grid being active.
        const gridOutlineActive = this._gridOutlineActive
        if (this._gridActive) {
            this.gridActive = false
            this.gridActive = true
            this.gridOutlineActive = gridOutlineActive
        }
        this.canvasBase.requestRedraw()
    }

    private updateGrid(): void {
        if (this.gridLinesGroup) {
            this.gridLinesGroup.removeChildren()
            if (this._gridOutlineActive) {
                const canvasBounds = this.resultImage.isAssigned ? this.resultImage.ref.descriptor : this.canvasBase.halImage.descriptor
                const halfGridDisplaySize = 10
                for (let x = -halfGridDisplaySize; x <= halfGridDisplaySize + 1; x++) {
                    const p0 = new paper.Point(x * canvasBounds.width, -halfGridDisplaySize * canvasBounds.height)
                    const p1 = new paper.Point(x * canvasBounds.width, (halfGridDisplaySize + 1) * canvasBounds.height)
                    const linePath = new paper.Path.Line(p0, p1)
                    linePath.strokeColor = new paper.Color(1, 1, 1, 1)
                    linePath.strokeWidth = 1
                    linePath.strokeScaling = false
                    this.gridLinesGroup.addChild(linePath)
                }
                for (let y = -halfGridDisplaySize; y <= halfGridDisplaySize + 1; y++) {
                    const p0 = new paper.Point(-halfGridDisplaySize * canvasBounds.width, y * canvasBounds.height)
                    const p1 = new paper.Point((halfGridDisplaySize + 1) * canvasBounds.width, y * canvasBounds.height)
                    const linePath = new paper.Path.Line(p0, p1)
                    linePath.strokeColor = new paper.Color(1, 1, 1, 1)
                    linePath.strokeWidth = 1
                    linePath.strokeScaling = false
                    this.gridLinesGroup.addChild(linePath)
                }
            }
        }
    }

    public get tileBounds() {
        const halImage = this.resultImage.isAssigned ? this.resultImage.ref.halImage : this.canvasBase.halImage
        return new Box2(0, 0, halImage.descriptor.width, halImage.descriptor.height)
    }

    protected message: string | undefined = undefined

    private unsubscribe = new Subject<void>()
    private _loadImageGate = new AsyncReentrancyGuard.PromiseGate()
    private halPainterImageDrawTiled!: HalPainterImageStretchTiled
    private gridLinesGroup: paper.Group | null = null
    private _gridOutlineActive = false
    private _gridActive = false
    private operatorToolbox: OperatorToolboxBase<Operator> | null = null
    private resultImage = new SmartPtrReassignable<ImageWebGL2>()
}
