import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeValueNode, threeConvert} from "@src/materials/three-utils"

export class Value extends DeclareMaterialNode(
    {
        returns: z.object({value: materialSlots}),
        inputs: z.object({value: materialSlots.optional()}),
        parameters: z.object({value: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const value = (await get(inputs.value)) ?? threeConvert(parameters.value, threeValueNode) ?? threeValueNode(0)
            return {value}
        },
    },
) {}
