import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-card-error",
    standalone: true,
    imports: [],
    templateUrl: "./card-error.component.html",
    styleUrl: "./card-error.component.scss",
})
export class CardErrorComponent {
    @Input() squareFormat = false
    @Input() error: Error = new Error("Not accessible")

    get message() {
        return this.error.message
    }
}
