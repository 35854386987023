import {Component, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {EditTaskTypeDialogFragment} from "@api"

export type EditTaskTypeDialogData = {
    taskTypeInfo: EditTaskTypeDialogFragment
    delete?: boolean
    edit?: boolean
}

@Component({
    selector: "cm-edit-task-type-dialog",
    templateUrl: "../edit-task-type-dialog/edit-task-type-dialog.component.html",
    standalone: true,
    imports: [MatInputModule, FormsModule, MatDialogModule, MatButtonModule, MatMenuModule],
})
export class EditTaskTypeDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<EditTaskTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditTaskTypeDialogData,
    ) {}

    onCancel(): void {
        this.dialogRef.close()
    }

    onDelete(): void {
        this.data.delete = true
        this.dialogRef.close(this.data)
    }
}
