/// <reference lib="dom" />

import {Observable} from "rxjs"
import {ImageResource} from "@src/materials/material-node-graph"
import {MeshData} from "@src/geometry-processing/mesh-data"
import {Nodes} from "@src/templates/legacy/template-nodes"
import TextureResolution = Nodes.TextureResolution
import {IMaterialData} from "@src/templates/interfaces/material-data"
import {IDataObject} from "@src/templates/interfaces/data-object"
import {IMatrix4} from "@src/templates/interfaces/matrix"
import {IVector3} from "@src/templates/interfaces/vector"

export interface MeshRenderSettings {
    cryptoMatteObjectName?: string
    cryptoMatteAssetName?: string
    displacementImageResource?: ImageResource // TODO remove displacementDataObject after mesh related logic migrated to new API
    displacementDataObject?: IDataObject
    displacementUvChannel?: number
    displacementMin?: number
    displacementMax?: number
}

//TODO: move this?
export namespace GlobalRenderConstants {
    export const exposureScale = 0.8

    //TODO: figure out the correct units to use, and how this was originally derived
    // 1 watt of a green 555nm light is 683 lumens
    // Luminous flux/power (Lumen): lm = cd*sr
    // Luminous intensity (Candela): cd = lm/sr (power of a point source)
    // Luminance: cd/m^-2 or lm/(sr*m^2) (Luminous flux per unit solid angle per unit projected source area)
    // ??? normalized to a diffuse white surface illuminated by a 100 watt point source 1 meter away ???
    export const lightIntensityScale = 6.83
}

export type ObjectId = string
export type MaterialSlot = number

export type Color = [number, number, number]

export function colorEqual(a: Color, b: Color): boolean {
    if (!a && !b) {
        return true
    } else if (!b || !a) {
        return false
    } else if (a[0] !== b[0] || a[1] !== b[1] || a[2] !== b[2]) {
        return false
    } else {
        return true
    }
}

export type SurfacePointCoordinates = [number, number, number, number, number, number] // x, y, z, triIndex, barycentric_u, barycentric_v

export type SurfacePointInfo = {
    objectId: ObjectId
    materialSlot: MaterialSlot
    triIndex: number
    objX: number
    objY: number
    objZ: number
    u: number
    v: number
    worldX: number
    worldY: number
    worldZ: number
    displayMeshToken: any
}

export type ToneMappingData = Nodes.ToneMapping

export type EnvironmentImageData = {readonly type: "hdri"; hdriID: number} | {readonly type: "url"; url: string; originalFileExtension: string}

////////////////////////////////////////////////////////////////////////

export namespace SceneNodes {
    interface SceneNodeDef<T extends SceneNodeBase> {
        is(x: SceneNodeBase): x is T
    }

    // export interface Entity {
    //     get<T extends SceneNode>(def: SceneNodeDef<T>): T | undefined;
    // }

    interface SceneNodeBase {
        id: ObjectId
        topLevelObjectId?: ObjectId
        readonly type: string
    }

    export function defSceneNode<T extends SceneNodeBase>(type: T["type"]): SceneNodeDef<T> {
        return {
            is: (x): x is T => x.type === type,
        }
    }

    // export const ObjectId = defSceneNode<ObjectId>('ObjectId');
    // export type ObjectId = {
    //     id: string;
    // }

    // function mkTypeTestFn<T extends SceneNode>(type: T['type']): (x: SceneNode) => x is T {
    //     return (x): x is T => x.type === type;
    // }

    // export const Hierarchy = defSceneNode<Hierarchy>('Hierarchy');
    // export interface Hierarchy extends SceneNode {
    //     type: 'Hierarchy';
    //     children: Entity[];
    // }

    // export const Transform = defSceneNode<Transform>('Transform');
    // export interface Transform extends SceneNode {
    //     type: 'Transform';
    //     matrix: Matrix4;
    //     isFirstTransformUpdate?: boolean;
    //     forceTransform?: Matrix4;
    //     lockedTransform?: number[];
    //     defaultTransform?: number[];
    // }

    export const Mesh = defSceneNode<Mesh>("Mesh")
    export interface Mesh extends SceneNodeBase {
        readonly type: "Mesh"
        meshData: MeshData
        meshRenderSettings: MeshRenderSettings
        transform: IMatrix4
        materialMap: Map<number, IMaterialData | null>
        visibleDirectly: boolean
        visibleInReflections: boolean
        visibleInRefractions: boolean
        receiveRealtimeShadows: boolean
        castRealtimeShadows: boolean
        isDecal: boolean
        isProcedural: boolean
    }

    export const WireframeMesh = defSceneNode<WireframeMesh>("WireframeMesh")
    export interface WireframeMesh extends SceneNodeBase {
        readonly type: "WireframeMesh"
        meshData: MeshData
        transform: IMatrix4
    }

    export const Rectangle = defSceneNode<Rectangle>("Rectangle")
    export interface Rectangle extends SceneNodeBase {
        readonly type: "Rectangle"
        width: number
        height: number
        transform: IMatrix4
    }

    export const Point = defSceneNode<Point>("Point")
    export interface Point extends SceneNodeBase {
        readonly type: "Point"
        size: number
        transform: IMatrix4
    }

    export const SceneOptions = defSceneNode<SceneOptions>("SceneOptions")
    export interface SceneOptions extends SceneNodeBase {
        readonly type: "SceneOptions"
        backgroundColor?: Color
        textureResolution?: TextureResolution
        textureFiltering?: boolean
        environmentMapMode?: "full" | "specularOnly"
        shadowCatcherFalloff?: {sizeX: number; sizeZ: number; smoothness: number; opacity: number}
        enableAdaptiveSubdivision?: boolean
        enableRealtimeShadows?: boolean
        enableRealtimeLights?: boolean
        enableRealtimeMaterials?: boolean
    }

    export const Environment = defSceneNode<Environment>("Environment")
    export interface Environment extends SceneNodeBase {
        readonly type: "Environment"
        rotation: IVector3
        intensity: number
        clampHighlights?: number
        mirror: boolean
        envData: EnvironmentImageData
        priority: number
    }

    export const Camera = defSceneNode<Camera>("Camera")
    export interface Camera extends SceneNodeBase {
        readonly type: "Camera"
        focalLength: number
        focalDistance: number
        autoFocus: boolean
        aspectRatio: number // width/height
        target: IVector3
        targeted: boolean
        filmGauge: number
        fStop: number
        exposure: number
        toneMapping?: ToneMappingData
        shiftX: number
        shiftY: number
        nearClip?: number
        farClip?: number
        transform: IMatrix4
        name?: string // FIXME
        minDistance?: number
        maxDistance?: number
        minPolarAngle?: number
        maxPolarAngle?: number
        minAzimuthAngle?: number
        maxAzimuthAngle?: number
        enablePanning?: boolean
        screenSpacePanning?: boolean
    }

    export const AreaLight = defSceneNode<AreaLight>("AreaLight")
    export interface AreaLight extends SceneNodeBase {
        readonly type: "AreaLight"
        intensity: number
        on: boolean
        color: Color
        width: number
        height: number
        directionality: number
        visibleDirectly: boolean
        visibleInReflections: boolean
        visibleInRefractions: boolean
        target: IVector3
        targeted: boolean
        transform: IMatrix4
        transparent: boolean
    }

    export const LightPortal = defSceneNode<LightPortal>("LightPortal")
    export interface LightPortal extends SceneNodeBase {
        readonly type: "LightPortal"
        width: number
        height: number
        transform: IMatrix4
    }

    export const Annotation = defSceneNode<Annotation>("Annotation")
    export interface Annotation extends SceneNodeBase {
        readonly type: "Annotation"
        transform: IMatrix4
        label: string
        description: string
        annotationID?: string
    }

    export const Marker = defSceneNode<Marker>("Marker")
    export interface Marker extends SceneNodeBase {
        readonly type: "Marker"
        position: IVector3
        normal: IVector3
    }

    export const TriangleHighlights = defSceneNode<TriangleHighlights>("TriangleHighlights")
    export interface TriangleHighlights extends SceneNodeBase {
        readonly type: "TriangleHighlights"
        vertices: IVector3[]
        transform: IMatrix4
    }

    export const SurfaceOverlay = defSceneNode<SurfaceOverlay>("SurfaceOverlay")
    export interface SurfaceOverlay extends SceneNodeBase {
        readonly type: "SurfaceOverlay"
        width: number
        height: number
        data: number[]
        transform: IMatrix4
    }

    export const PlaneOverlay = defSceneNode<PlaneOverlay>("PlaneOverlay")
    export interface PlaneOverlay extends SceneNodeBase {
        readonly type: "PlaneOverlay"
        width: number
        height: number
        transform: IMatrix4
    }

    export const PreloadMaterial = defSceneNode<PreloadMaterial>("PreloadMaterial")
    export interface PreloadMaterial extends SceneNodeBase {
        readonly type: "PreloadMaterial"
        materialData: IMaterialData
    }

    export const Grid = defSceneNode<Grid>("Grid")
    export interface Grid extends SceneNodeBase {
        readonly type: "Grid"
        size: number
        divisions: number
        color1: Color
        color2: Color
        transform: IMatrix4
    }

    //TODO: these aren't really display related, they should be separated

    export const RenderSettings = defSceneNode<RenderSettings>("RenderSettings")
    export interface RenderSettings extends SceneNodeBase {
        readonly type: "RenderSettings"
        width: number
        height: number
        samples: number
    }

    export const RenderPostProcessingSettings = defSceneNode<RenderPostProcessingSettings>("RenderPostProcessingSettings")
    export interface RenderPostProcessingSettings extends SceneNodeBase {
        readonly type: "RenderPostProcessingSettings"
        mode: "whiteBackground"
        exposure: number
        whiteBalance?: number
        toneMapping?: ToneMappingData
        lutUrl?: string
        transparent?: boolean
        composite?: boolean
        backgroundColor?: Color
        processShadows?: boolean
        shadowInner?: number
        shadowOuter?: number
        shadowFalloff?: number
        autoCrop?: boolean
        autoCropMargin?: number
    }

    export type SceneNode =
        | Mesh
        | WireframeMesh
        | Rectangle
        | Point
        | SceneOptions
        | Environment
        | Camera
        | AreaLight
        | LightPortal
        | Annotation
        | Marker
        | TriangleHighlights
        | SurfaceOverlay
        | PlaneOverlay
        | PreloadMaterial
        | Grid
        | RenderSettings
        | RenderPostProcessingSettings
}

export type IDisplaySceneEvent = {
    readonly type: string
}

export interface IDisplayView {
    getDOMElement(): HTMLElement
    findObjectsAtPoint(x: number, y: number): [ObjectId, number][]
    surfaceInfoAtPoint(x: number, y: number, filterFn?: (ids: ObjectId[]) => ObjectId | null): SurfacePointInfo | null
}

export interface IDisplayScene {
    getGeometryAccessorForMeshData(meshData: MeshData): Observable<IMeshGeometryAccessor>
    syncTasks(): Observable<void>
    syncRender(): Observable<void>
    exportScene(excludedObjectIDs: string[]): Observable<ArrayBuffer>
    updateAll(nodeList: SceneNodes.SceneNode[]): void
    flushDeferredUpdates(): void
    dropDeferredUpdates(): void
    renderSuspended: boolean
    readonly sceneEvent$: Observable<IDisplaySceneEvent>
}

export interface IMeshGeometryAccessor {
    interpolateTriangleNormal(pt: SurfacePointCoordinates): [number, number, number] // used by: SurfaceMapBuilder
    getVerticesForTriangle(triIndex: number): [IVector3, IVector3, IVector3] // used by: SurfaceDefiner, SurfaceMapBuilder
    getNormalsForTriangle(triIndex: number): [IVector3, IVector3, IVector3] // used by: SurfaceMapBuilder
    getCoplanarNeighborsForTriangles(triIndices: Set<number>, targetTri: number): Set<number> // used by: SurfaceDefiner
    getClosestPointOnMesh(x: number, y: number, z: number): SurfacePointCoordinates // used by: SurfaceMapBuilder
    getPointOnMeshFromRaycasting(x: number, y: number, z: number, dx: number, dy: number, dz: number): SurfacePointCoordinates // used by: SurfaceMapBuilder
    triangleIndicesToFaceIDs(triIndices: number[]): number[] // used by: SurfaceDefiner
    faceIDsToTriangleIndices(faceIDs: number[]): number[] // used by: SurfaceDefiner, SurfaceMapBuilder
}
