import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-input-container",
    templateUrl: "./input-container.component.html",
    styleUrls: ["./input-container.component.scss"],
    standalone: true,
})
export class InputContainerComponent {
    @Input() showLabel = true
    @Input() backgroundColor = "white"
    @Input() borderColor: string = ""
    @Input() warning = false
}
