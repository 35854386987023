import {Vector2Like} from "@cm/lib/math/vector2"
import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"

export type ParameterType = {
    sourceImage: ImagePtr
    offset: Vector2Like
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpShift: ImageOpType<ParameterType, ReturnType> = {
    name: "Shift",

    WebGL2: async ({context, parameters: {sourceImage, offset, resultImage}}) => {
        const halShift = await context.getOrCreateImageCompositor(`
            uniform ivec2 u_textureOfs0;

            vec4 computeColor(ivec2 targetPixel) {
                return texelFetch0(targetPixel + u_textureOfs0);
            }
        `)
        resultImage = await context.prepareResultImage(resultImage, sourceImage)
        using sourceImageWebGl2 = await context.getImage(sourceImage)
        using resultImageWebGl2 = await context.getImage(resultImage)
        halShift.setParameter("u_textureOfs0", {type: "int2", value: {x: -offset.x, y: -offset.y}})
        await halShift.paint(resultImageWebGl2.ref.halImage, sourceImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, offset, resultImage}}) => {
        using sourceImageImgProc = await context.getImage(sourceImage)
        const resultNode = Nodes.shift(sourceImageImgProc.ref.node, Nodes.offset(offset.x, offset.y), "wrap")
        using result = await context.createImage(sourceImageImgProc.ref.descriptor, resultNode)
        return await toImgProcResultImage(context, result, resultImage)
    },
}
