import {Matrix3x2, Matrix3x2Like} from "@cm/lib/math/matrix3x2"
import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {getImgProcPixelAddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {getHalAddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {Size2Like} from "@cm/lib/math/size2"
import {Vector2} from "@cm/lib/math/vector2"
import {Box2} from "@cm/lib/math/box2"
import {ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"

export type ParameterType = {
    sourceImage: ImagePtr
    transform: Matrix3x2Like
    addressMode?: AddressMode // default: "wrap"
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpAffineTransform: ImageOpType<ParameterType, ReturnType> = {
    name: "AffineTransform",

    WebGL2: async ({context, parameters: {sourceImage, transform, addressMode, resultImage}}) => {
        addressMode ??= "wrap"
        const halTransform = await context.getOrCreateImageCompositor(`
            uniform mat3x2 u_invTransform;

            vec4 computeColor(ivec2 targetPixel) {
                vec2 sourcePixel = u_invTransform * vec3(vec2(targetPixel) + 0.5, 1); // we offset by 0.5 because texelFetchInterpolated samples at the center of the texel
                return texelFetchInterpolated0(sourcePixel, ${getHalAddressMode(addressMode)});
            }
        `)
        using sourceImageWebGl2 = await context.getImage(sourceImage)
        const resultImageDescriptor = computeResultImageDescriptor(sourceImageWebGl2.ref.descriptor, transform)
        resultImage = await context.prepareResultImage(resultImage, resultImageDescriptor)
        using resultImageWebGl2 = await context.getImage(resultImage)
        const invTransform = Matrix3x2.fromMatrix3x2Like(transform).inverse()
        halTransform.setParameter("u_invTransform", {type: "float3x2", value: invTransform})
        await halTransform.paint(resultImageWebGl2.ref.halImage, sourceImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, transform, addressMode, resultImage}}) => {
        addressMode ??= "wrap"
        let resultNode: Nodes.AffineTransform
        let resultImageDescriptor: ImageDescriptor
        {
            using sourceImageImgProc = await context.getImage(sourceImage)
            using resultImageImgProc = resultImage ? await context.getImage(resultImage) : undefined
            resultNode = Nodes.affineTransform(
                sourceImageImgProc.ref.node,
                Matrix3x2.fromMatrix3x2Like(transform).toArray(),
                getImgProcPixelAddressMode(addressMode),
                resultImageImgProc ? resultImageImgProc.ref.node : undefined,
            )
            resultImageDescriptor = resultImageImgProc
                ? resultImageImgProc.ref.descriptor
                : computeResultImageDescriptor(sourceImageImgProc.ref.descriptor, transform)
        }
        resultImage = await context.createImage(resultImageDescriptor, resultNode)
        return resultImage
    },
}

function computeResultImageDescriptor(sourceImageDescriptor: ImageDescriptor, transform: Matrix3x2Like): ImageDescriptor {
    const resultSize = computeResultSize(sourceImageDescriptor, transform)
    return {
        width: resultSize.width,
        height: resultSize.height,
        channelLayout: sourceImageDescriptor.channelLayout,
        format: sourceImageDescriptor.format,
        isSRGB: sourceImageDescriptor.isSRGB,
    }
}

function computeResultSize(sourceSize: Size2Like, transform: Matrix3x2Like): Box2 {
    const matrix = Matrix3x2.fromMatrix3x2Like(transform)
    const p00 = new Vector2(0, 0)
    const p10 = new Vector2(sourceSize.width, 0)
    const p01 = new Vector2(0, sourceSize.height)
    const p11 = new Vector2(sourceSize.width, sourceSize.height)
    const p00t = matrix.multiplyVector(p00)
    const p10t = matrix.multiplyVector(p10)
    const p01t = matrix.multiplyVector(p01)
    const p11t = matrix.multiplyVector(p11)
    const minX = Math.min(p00t.x, p10t.x, p01t.x, p11t.x)
    const maxX = Math.max(p00t.x, p10t.x, p01t.x, p11t.x)
    const minY = Math.min(p00t.y, p10t.y, p01t.y, p11t.y)
    const maxY = Math.max(p00t.y, p10t.y, p01t.y, p11t.y)
    return new Box2(Math.round(minX), Math.round(minY), Math.round(maxX - minX), Math.round(maxY - minY))
}
