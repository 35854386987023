import {Component, input, output} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"

export type TemplateTreeViewType = "tree" | "selectVariation" | "allVariations"

@Component({
    selector: "cm-template-tree-view-type-selector",
    standalone: true,
    imports: [MatMenuModule],
    templateUrl: "./template-tree-view-type-selector.component.html",
    styleUrl: "./template-tree-view-type-selector.component.scss",
})
export class TemplateTreeViewTypeSelectorComponent {
    viewType = input.required<TemplateTreeViewType>()

    viewTypeChanged = output<TemplateTreeViewType>()
}
