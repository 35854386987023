import {AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList} from "@angular/core"
import {ActionItemComponent} from "@common/components/menu/action-menu/action-item/action-item.component"
import {Subject, takeUntil} from "rxjs"

@Component({
    selector: "cm-action-bar",
    templateUrl: "./action-bar.component.html",
    styleUrls: ["./action-bar.component.scss"],
    standalone: true,
})
export class ActionBarComponent implements AfterContentInit, OnDestroy {
    private unsubscribe = new Subject<void>()
    @ContentChildren(ActionItemComponent) actionItems!: QueryList<ActionItemComponent>
    @Input() visible = true
    @Input() activeItem?: ActionItemComponent

    ngAfterContentInit() {
        for (const actionItem of this.actionItems) {
            actionItem.itemClick.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                if (actionItem.panel == null) return
                this.actionItems.forEach((item) => (item.panel != null && item != actionItem ? (item.active = false) : null))
            })
            actionItem.activeChange.pipe(takeUntil(this.unsubscribe)).subscribe((active) => {
                if (!active) return
                this.actionItems.forEach((item) => (item.panel != null && item != actionItem ? (item.active = false) : null))
            })
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }
}
