import {DeclareMaterialNode, MaterialSlot, ThreeNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {RGB} from "./rgb"
import {getProperty} from "@src/graph-system/utils"
import {Value} from "./value"
import {NormalMap} from "./normal-map"
import {Mapping} from "./mapping"
import {GetGraphParamTypes, ParameterValue} from "@src/graph-system/node-graph"
import {Context} from "../types"
import {ImageResource, ImageResourceSchema} from "../material-node-graph"
import {TexImage} from "./tex-image"
import {VectorMath} from "./vector-math"
import {Math as MathNode} from "./math"
import {SeparateXYZ} from "./separate-xyz"
import {SeparateRGB} from "./separate-rgb"
import {CombineRGB} from "./combine-rgb"
import {RGBCurve} from "./rgb-curve"
import {Displacement} from "./displacement"
import {CachedNodeGraphResult} from "@src/graph-system/evaluators/cached-node-graph-result"
import {mapFields, promiseAllProperties} from "@src/utils/utils"
import {RenderNodes} from "@src/rendering/render-nodes"

export enum TextureType {
    Anisotropy = "Anisotropy",
    AnisotropyRotation = "AnisotropyRotation",
    AnisotropyStrength = "AnisotropyStrength",
    Diffuse = "Diffuse",
    DiffuseRoughness = "DiffuseRoughness",
    Displacement = "Displacement",
    Error = "Error",
    F0 = "F0",
    Ior = "IOR",
    Mask = "Mask",
    Metalness = "Metalness",
    Normal = "Normal",
    Roughness = "Roughness",
    SpecularStrength = "SpecularStrength",
    SpecularTint = "SpecularTint",
}

type TextureSetGraph = {
    baseColor: ParameterValue<MaterialSlot, Context>
    metallic: ParameterValue<MaterialSlot, Context>
    specular: ParameterValue<MaterialSlot, Context>
    roughness: ParameterValue<MaterialSlot, Context>
    anisotropic: ParameterValue<MaterialSlot, Context>
    anisotropicRotation: ParameterValue<MaterialSlot, Context>
    alpha: ParameterValue<MaterialSlot, Context>
    normal: ParameterValue<MaterialSlot, Context>
    displacement: ParameterValue<MaterialSlot, Context>
}
export class TextureSet extends DeclareMaterialNode(
    {
        returns: z.object({
            baseColor: materialSlots,
            metallic: materialSlots,
            specular: materialSlots,
            roughness: materialSlots,
            anisotropic: materialSlots,
            anisotropicRotation: materialSlots,
            alpha: materialSlots,
            normal: materialSlots,
            displacement: materialSlots,
        }),
        inputs: z.object({uv: materialSlots.optional(), normalStrength: materialSlots.optional()}),
        parameters: z.object({
            displacementCm: z.number().nullable().optional(),
            widthCm: z.number().optional(),
            heightCm: z.number().optional(),
            mapAssignmentAnisotropyImageResourceSlot: z.number().optional(),
            mapAssignmentDiffuseImageResourceSlot: z.number().optional(),
            mapAssignmentDisplacementImageResourceSlot: z.number().optional(),
            mapAssignmentMetalnessImageResourceSlot: z.number().optional(),
            mapAssignmentNormalImageResourceSlot: z.number().optional(),
            mapAssignmentRoughnessImageResourceSlot: z.number().optional(),
            mapAssignmentSpecularStrengthImageResourceSlot: z.number().optional(),
            normalStrength: z.number().optional(),
            normalRotation: z.number().optional(),
            textureSetRevisionId: z.string().optional(),
            imageResources: z.array(ImageResourceSchema).optional(),
        }),
    },
    {
        toThree: async function (
            this: {
                buildTextureSetGraph: () => TextureSetGraph
            },
            {context},
        ) {
            return promiseAllProperties(mapFields(this.buildTextureSetGraph(), (value) => compileNode<ThreeNode>(value, context)))
        },

        toCycles: async function (
            this: {
                buildTextureSetGraph: () => TextureSetGraph
            },
            {context},
        ) {
            return promiseAllProperties(mapFields(this.buildTextureSetGraph(), (value) => compileNode<RenderNodes.ShaderNode>(value, context)))
        },
    },
) {
    buildTextureSetGraph(): TextureSetGraph {
        const {parameters, ...inputs} = this.parameters

        const createDefaultBaseColor = () => createConstantColor([0.5, 0.5, 0.5])
        const createDefaultMetallic = () => createConstantValue(0)
        const createDefaultSpecular = () => createConstantValue(0)
        const createDefaultRoughness = () => createConstantValue(1)
        const createDefaultAnisotropic = () => createConstantValue(0)
        const createDefaultAnisotropicRotation = () => createConstantValue(0)
        const createDefaultAlpha = () => createConstantValue(1)
        const createDefaultNormal = () => getProperty(new NormalMap({parameters: {strength: 1}, color: createConstantColor([0.5, 0.5, 1])}), "normal")
        const createDefaultDisplacement = () => createConstantValue(0)

        const {textureSetRevisionId, widthCm, heightCm, normalStrength, displacementCm} = parameters

        if (textureSetRevisionId === undefined || widthCm === undefined || heightCm === undefined) {
            return {
                baseColor: createDefaultBaseColor(),
                metallic: createDefaultMetallic(),
                specular: createDefaultSpecular(),
                roughness: createDefaultRoughness(),
                anisotropic: createDefaultAnisotropic(),
                anisotropicRotation: createDefaultAnisotropicRotation(),
                alpha: createDefaultAlpha(),
                normal: createDefaultNormal(),
                displacement: createDefaultDisplacement(),
            }
        }

        const {uv} = inputs
        const mapping = createMappingNode(uv, [widthCm, heightCm, 1])
        const inNormalStrength = inputs.normalStrength ?? createConstantValue(normalStrength ?? 1)

        const createTexImageForTextureType = (textureType: TextureType) => {
            const slotIndex = parameters[textureSetMapAssignmentParameterKey(textureType) as keyof typeof parameters] as number | undefined
            if (typeof slotIndex !== "number") return undefined
            const imageResource = parameters.imageResources?.[slotIndex]
            if (!imageResource) return undefined

            return createTexImageNodeForNodeImageResourceSlot(imageResource, mapping)
        }

        const createAnisotropicFromAnisotrophy = (colorMassAnisotrophy: ParameterValue<MaterialSlot | undefined, Context>) => {
            if (!colorMassAnisotrophy) {
                return undefined
            }
            return getProperty(
                new VectorMath({
                    vector: getProperty(
                        new VectorMath({
                            vector: getProperty(
                                new VectorMath({
                                    vector: colorMassAnisotrophy,
                                    parameters: {
                                        operation: "ADD",
                                        vector_001: {x: -0.5, y: -0.5, z: 0},
                                    },
                                }),
                                "vector",
                            ),
                            parameters: {
                                operation: "MULTIPLY",
                                vector_001: {x: 2, y: -2, z: 0},
                            },
                        }),
                        "vector",
                    ),
                    parameters: {
                        operation: "LENGTH",
                    },
                }),
                "vector",
            )
        }

        const createAnisotropicRotationFromAnisotrophy = (colorMassAnisotrophy: ParameterValue<MaterialSlot | undefined, Context>) => {
            if (!colorMassAnisotrophy) {
                return undefined
            }

            const xyz = new SeparateXYZ({
                vector: getProperty(
                    new VectorMath({
                        vector: getProperty(
                            new VectorMath({
                                vector: colorMassAnisotrophy,
                                parameters: {
                                    operation: "ADD",
                                    vector_001: {x: -0.5, y: -0.5, z: 0},
                                },
                            }),
                            "vector",
                        ),
                        parameters: {
                            operation: "MULTIPLY",
                            vector_001: {x: 2, y: -2, z: 0},
                        },
                    }),
                    "vector",
                ),
                parameters: {},
            })

            const angle = getProperty(
                new MathNode({
                    value: getProperty(xyz, "y"),
                    value_001: getProperty(xyz, "x"),
                    parameters: {operation: "ARCTAN2"},
                }),
                "value",
            )

            return getProperty(
                new MathNode({
                    value: getProperty(
                        new MathNode({
                            value: getProperty(
                                new MathNode({
                                    value: getProperty(
                                        new MathNode({
                                            value: angle,
                                            parameters: {operation: "GREATER_THAN", value_001: 0},
                                        }),
                                        "value",
                                    ),
                                    value_001: angle,
                                    parameters: {operation: "MULTIPLY"},
                                }),
                                "value",
                            ),
                            value_001: getProperty(
                                new MathNode({
                                    value: getProperty(
                                        new MathNode({
                                            value: angle,
                                            parameters: {operation: "LESS_THAN", value_001: 0},
                                        }),
                                        "value",
                                    ),
                                    value_001: getProperty(
                                        new MathNode({
                                            value: angle,
                                            parameters: {operation: "ADD", value_001: Math.PI * 2},
                                        }),
                                        "value",
                                    ),
                                    parameters: {operation: "MULTIPLY"},
                                }),
                                "value",
                            ),
                            parameters: {operation: "ADD"},
                        }),
                        "value",
                    ),
                    parameters: {operation: "DIVIDE", value_001: Math.PI * 2},
                }),
                "value",
            )
        }

        const convertNormal = (normalMap: ParameterValue<MaterialSlot | undefined, Context>, normalRotation: number) => {
            if (!normalMap) {
                return undefined
            }

            return getProperty(
                new NormalMap({
                    color: rotateNormalMapVector(
                        getProperty(
                            new RGBCurve({
                                color: normalMap,
                                parameters: {
                                    fac: 1,
                                    controlPoints: [
                                        [
                                            {x: 0, y: 0},
                                            {x: 1, y: 1},
                                        ], // R
                                        [
                                            {x: 0, y: 1},
                                            {x: 1, y: 0},
                                        ], // G (inverted)
                                        [
                                            {x: 0, y: 0},
                                            {x: 1, y: 1},
                                        ], // B
                                        [
                                            {x: 0, y: 0},
                                            {x: 1, y: 1},
                                        ], // RGB
                                    ],
                                },
                            }),
                            "color",
                        ),
                        normalRotation,
                    ),
                    strength: inNormalStrength,
                    parameters: {},
                }),
                "normal",
            )
        }

        const convertDisplacement = (displacementMap: ParameterValue<MaterialSlot | undefined, Context>) => {
            if (!displacementMap) {
                return undefined
            }

            return getProperty(
                new Displacement({
                    height: displacementMap,
                    scale: getProperty(
                        new MathNode({
                            value: inNormalStrength,
                            parameters: {
                                value_001: displacementCm ?? 0,
                                operation: "MULTIPLY",
                            },
                        }),
                        "value",
                    ),
                    parameters: {midlevel: 0.5},
                }),
                "displacement",
            )
        }

        const createMetallicFromF0 = (colormassF0: ParameterValue<MaterialSlot | undefined, Context>) => {
            if (!colormassF0) {
                return undefined
            }

            return getProperty(
                new SeparateRGB({
                    image: colormassF0,
                    parameters: {},
                }),
                "g",
            )
        }

        const createSpecularFromF0 = (colormassF0: ParameterValue<MaterialSlot | undefined, Context>) => {
            if (!colormassF0) {
                return undefined
            }

            return getProperty(
                new SeparateRGB({
                    image: colormassF0,
                    parameters: {},
                }),
                "r",
            )
        }

        const createAnisotropicFromAnisotrophyStrength = (colorMassAnisotrophyStrength: ParameterValue<MaterialSlot | undefined, Context>) => {
            if (!colorMassAnisotrophyStrength) {
                return undefined
            }

            return getProperty(
                new MathNode({
                    value: getProperty(new MathNode({value: colorMassAnisotrophyStrength, parameters: {operation: "ADD", value_001: -0.5}}), "value"),
                    parameters: {
                        operation: "MULTIPLY",
                        value_001: 2,
                    },
                }),
                "value",
            )
        }

        // current set
        const sourceMapDiffuse = createTexImageForTextureType(TextureType.Diffuse)
        const sourceMapMetalness = createTexImageForTextureType(TextureType.Metalness)
        const sourceMapSpecularStrength = createTexImageForTextureType(TextureType.SpecularStrength)
        const sourceMapRoughness = createTexImageForTextureType(TextureType.Roughness)
        const sourceMapAnisotrophy = createTexImageForTextureType(TextureType.Anisotropy)
        const sourceMapNormal = createTexImageForTextureType(TextureType.Normal)
        const sourceMapDisplacement = createTexImageForTextureType(TextureType.Displacement)
        // legacy set
        const sourceMapF0 = createTexImageForTextureType(TextureType.F0)
        const sourceMapAnisotrophyStrength = createTexImageForTextureType(TextureType.AnisotropyStrength)
        const sourceMapAnisotrophyRotation = createTexImageForTextureType(TextureType.AnisotropyRotation)
        // special maps
        const sourceMapMask = createTexImageForTextureType(TextureType.Mask)

        const normalRotation = parameters.normalRotation ?? 0

        // create the nodes
        const baseColor = sourceMapDiffuse ?? createDefaultBaseColor()
        const metallic = sourceMapMetalness ?? createMetallicFromF0(sourceMapF0) ?? createDefaultMetallic()
        const specular = sourceMapSpecularStrength ?? createSpecularFromF0(sourceMapF0) ?? createDefaultSpecular()
        const roughness = sourceMapRoughness ?? createDefaultRoughness()
        const anisotropic =
            createAnisotropicFromAnisotrophy(sourceMapAnisotrophy) ??
            createAnisotropicFromAnisotrophyStrength(sourceMapAnisotrophyStrength) ??
            createDefaultAnisotropic()
        const anisotropicRotation =
            createAnisotropicRotationFromAnisotrophy(sourceMapAnisotrophy) ?? sourceMapAnisotrophyRotation ?? createDefaultAnisotropicRotation()
        const alpha = sourceMapMask ?? createDefaultAlpha()
        const normal = convertNormal(sourceMapNormal, normalRotation) ?? createDefaultNormal()
        const displacement = convertDisplacement(sourceMapDisplacement) ?? createDefaultDisplacement()

        return {
            baseColor,
            metallic,
            specular,
            roughness,
            anisotropic,
            anisotropicRotation,
            alpha,
            normal,
            displacement,
        }
    }

    hasAlpha() {
        const {parameters} = this.parameters
        const {textureSetRevisionId, widthCm, heightCm} = parameters
        if (textureSetRevisionId === undefined || widthCm === undefined || heightCm === undefined) return false

        const hasImageForTextureType = (textureType: TextureType) => {
            const slotIndex = parameters[textureSetMapAssignmentParameterKey(textureType) as keyof typeof parameters] as number | undefined
            if (typeof slotIndex !== "number") return false
            const imageResource = parameters.imageResources?.[slotIndex]
            if (!imageResource) return false
            return true
        }

        return hasImageForTextureType(TextureType.Mask)
    }
}

function createConstantColor(color: [number, number, number]) {
    return getProperty(new RGB({parameters: {color: {r: color[0], g: color[1], b: color[2]}}}), "color")
}

function createConstantValue(value: number) {
    return getProperty(new Value({parameters: {value: value}}), "value")
}

function createMappingNode(
    uvNode: ParameterValue<MaterialSlot | undefined, Context>,
    scale: [number, number, number] = [1, 1, 1],
    rotation: [number, number, number] = [0, 0, 0],
    location: [number, number, number] = [0, 0, 0],
) {
    return getProperty(
        new Mapping({
            vector: uvNode,
            parameters: {
                vectorType: "TEXTURE",
                location: {x: location[0], y: location[1], z: location[2]},
                rotation: {x: rotation[0], y: rotation[1], z: rotation[2]},
                scale: {x: scale[0], y: scale[1], z: scale[2]},
            },
        }),
        "vector",
    )
}

function textureSetMapAssignmentParameterKey(textureType: TextureType) {
    return `mapAssignment${textureType.toString()}ImageResourceSlot`
}

function createTexImageNodeForNodeImageResourceSlot(imageResource: ImageResource, mappingNode: ParameterValue<MaterialSlot, Context>) {
    return getProperty(
        new TexImage({vector: mappingNode, parameters: {extension: "REPEAT", interpolation: "Closest", projection: "FLAT", imageResource}}),
        "color",
    )
}

function separateRGB(v: ParameterValue<MaterialSlot, Context>) {
    const node = new SeparateRGB({image: v, parameters: {}})
    return [getProperty(node, "r"), getProperty(node, "g"), getProperty(node, "b")]
}

function combineRGB(r: ParameterValue<MaterialSlot, Context>, g: ParameterValue<MaterialSlot, Context>, b: ParameterValue<MaterialSlot, Context>) {
    const node = new CombineRGB({r, g, b, parameters: {}})
    return getProperty(node, "image")
}

function scalarMathOp(
    a: ParameterValue<MaterialSlot, Context> | number,
    b: ParameterValue<MaterialSlot, Context> | number,
    op: GetGraphParamTypes<MathNode>["parameters"]["operation"],
) {
    const getInput = (v: ParameterValue<MaterialSlot, Context> | number) => {
        return typeof v === "number" ? createConstantValue(v) : v
    }

    const node = new MathNode({value: getInput(a), value_001: getInput(b), parameters: {operation: op}})
    return getProperty(node, "value")
}

function sadd(a: ParameterValue<MaterialSlot, Context> | number, b: ParameterValue<MaterialSlot, Context> | number) {
    return scalarMathOp(a, b, "ADD")
}

function ssub(a: ParameterValue<MaterialSlot, Context> | number, b: ParameterValue<MaterialSlot, Context> | number) {
    return scalarMathOp(a, b, "SUBTRACT")
}

function smul(a: ParameterValue<MaterialSlot, Context> | number, b: ParameterValue<MaterialSlot, Context> | number) {
    if (a === 1.0) return b
    else if (a === 0.0) return 0.0
    else if (b === 1.0) return a
    else if (b === 0.0) return 0.0
    return scalarMathOp(a, b, "MULTIPLY")
}

function rotateNormalMapVector(normalMap: ParameterValue<MaterialSlot, Context>, angleDegrees: number) {
    // increasing angleDegrees will rotate the vector CCW around the Z axis
    if (angleDegrees == 0) return normalMap
    const theta = (angleDegrees * Math.PI) / 180
    const [nr, ng, nb] = separateRGB(normalMap)
    const nx = ssub(nr, 0.5)
    const ny = ssub(ng, 0.5)
    const s = Math.sin(theta)
    const c = Math.cos(theta)
    return combineRGB(sadd(sadd(smul(nx, c), smul(ny, s)), 0.5), sadd(ssub(smul(ny, c), smul(nx, s)), 0.5), nb)
}

const compileNode = async <T extends MaterialSlot>(node: ParameterValue<MaterialSlot, Context>, context: Context): Promise<T> => {
    const result = new CachedNodeGraphResult(node, context)
    const compiled = await result.run()
    return compiled as T
}
