import {EventEmitter} from "@angular/core"
import {Box2} from "@cm/lib/math/box2"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import * as paper from "paper"
import {HalStrokeGeometry} from "app/textures/texture-editor/operator-stack/operators/shared/hal/hal-stroke-geometry"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {HalContext} from "@common/models/hal/hal-context"
import {HalBlendCurrentStroke} from "app/textures/texture-editor/operator-stack/operators/shared/hal/hal-blend-current-stroke"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {ImageDescriptor} from "app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {deepEqual} from "@cm/lib/utils/utils"
import {descriptorFromHalImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {HalImage} from "@common/models/hal/hal-image"
import {createHalPaintableImage} from "@common/models/hal/hal-paintable-image/create"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

const DISPLAY_BOUNDING_BOX = TextureEditorSettings.EnableFullTrace

export class BrushToolboxItem extends CanvasBaseToolboxItemBase {
    readonly brushStrokeUpdated = new EventEmitter<void>()
    readonly brushStrokeCompleted = new EventEmitter<void>()

    constructor(parent: CanvasBaseToolboxItem) {
        super(parent)

        this.halContext = this.canvasBase.halContext
        this.halStrokeGeometry = new HalStrokeGeometry(this.halContext)
        this.halBlendCurrentStroke = new HalBlendCurrentStroke(this.halContext)
        this.halBlitter = new HalPainterImageBlit(this.halContext)
        this.brushedImage = createHalPaintableImage(this.halContext)
        this.currentStrokeImage = createHalPaintableImage(this.halContext)
        this.preStrokeBrushedImage = createHalPaintableImage(this.halContext)

        this.brushLine = new paper.Path.Line(new paper.Point(0, 0), new paper.Point(0, 0))
        this.brushLine.strokeColor = new paper.Color("white")
        this.brushLine.visible = false

        this.viewChange.subscribe(() => this.updateBrushCircle(true))
        this.selectedChange.subscribe((_selected) => this.updateBrushCircle())
    }

    override remove(): void {
        super.remove()
        this.halStrokeGeometry.dispose()
        this.halBlendCurrentStroke.dispose()
        this.halBlitter.dispose()
        this.brushedImage.dispose()
        this.currentStrokeImage.dispose()
        this.preStrokeBrushedImage.dispose()
    }

    override hitTest(point: Vector2Like): boolean {
        if (this.selected && this.isPointInImage(point)) {
            this.cursor = "crosshair"
            return true
        }
        if (this.brushCircle) {
            this.brushCircle.visible = false
        }
        this.brushLine.visible = false
        return false
    }

    set brushSettings(value: BrushSettings) {
        void this.updateStrokeImage()
        this._brushSettings = value
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    get isDrawing(): boolean {
        return this._isDrawing
    }

    get brushStartPosition(): Vector2 | null {
        return this._brushStartPosition
    }

    get brushCurrentPosition(): Vector2 | null {
        return this._brushCurrentPosition
    }

    get brushCursorOffset(): Vector2 {
        return this._brushCursorOffset
    }

    set brushCursorOffset(value: Vector2) {
        this._brushCursorOffset = value
    }

    // copies the supplied image to the brush stroke image
    async setStrokeImage(image: HalImage, position?: Vector2): Promise<void> {
        await this.allocateBuffers(descriptorFromHalImage(image))
        position = position ?? new Vector2(0, 0)
        await this.halBlitter.paint(this.preStrokeBrushedImage, image, {targetOffset: position})
        await this.halBlitter.paint(this.brushedImage, image, {targetOffset: position})
        this.boundingBox.setFromMinMax(
            position,
            new Vector2(
                Math.min(position.x + image.descriptor.width, this.brushedImage.descriptor.width),
                Math.min(position.y + image.descriptor.height, this.brushedImage.descriptor.height),
            ),
        )
    }

    async setStrokeImageDescriptor(descriptor: ImageDescriptor): Promise<void> {
        await this.allocateBuffers(descriptor)
    }

    async getStrokeImage(): Promise<HalImage> {
        await this.updateStrokeImage()
        return this.brushedImage
    }

    getCurrentStrokeImageBoundingBox(): Box2 {
        return this.boundingBox
    }

    resetDirtyRegion(): void {
        this.boundingBox.makeEmpty()
    }

    clearStrokeImage(): Promise<void> {
        this.resetDirtyRegion()
        return this.brushedImage.clear()
    }

    override onMouseDown(event: paper.ToolEvent): boolean {
        void this.startBrushStroke(event.point)
        this.updateBrushCircle()
        return false
    }

    override onMouseUp(event: paper.ToolEvent): boolean {
        void this.endBrushStroke(event.point)
        this.updateBrushCircle()
        return false
    }

    override onMouseDrag(event: paper.ToolEvent): boolean {
        void this.drawBrushStroke(event.lastPoint, event.point)
        this.updateBrushCircle()
        return false
    }

    override onMouseMove(_event: paper.ToolEvent): boolean {
        this.updateBrushCircle()
        return false
    }

    override onKeyDown(event: paper.KeyEvent): boolean {
        if (event.key === this.drawLineKey) {
            this.drawLine = true
            this.updateBrushCircle()
        }
        return false
    }

    override onKeyUp(event: paper.KeyEvent): boolean {
        if (event.key === this.drawLineKey) {
            this.drawLine = false
            this.updateBrushCircle()
        }
        return false
    }

    private getCursorPosition(toolPosition: Vector2Like): Vector2 {
        return new Vector2(Math.floor(this.brushCursorOffset.x + toolPosition.x), Math.floor(this.brushCursorOffset.y + toolPosition.y))
    }

    private async allocateBuffers(descriptor: ImageDescriptor): Promise<void> {
        if (!deepEqual(this.currentStrokeImage.descriptor, descriptor)) {
            await this.currentStrokeImage.create(descriptor)
        }
        if (!deepEqual(this.preStrokeBrushedImage.descriptor, descriptor)) {
            await this.preStrokeBrushedImage.create(descriptor)
        }
        if (!deepEqual(this.brushedImage.descriptor, descriptor)) {
            await this.brushedImage.create(descriptor)
        }
    }

    private updateBrushCircle(force = false) {
        const radius = this.brushSettings.brushWidth / 2
        if (force || !this.brushCircle || this.brushCircleRadius !== radius) {
            this.brushCircleRadius = radius
            if (this.brushCircle) {
                this.brushCircle.remove()
            }
            this.beginPaperCreation()
            this.brushCircle = new paper.Path.Circle(new paper.Point(0, 0), radius)
            this.brushCircle.strokeColor = new paper.Color("white")
            this.brushCircle.strokeWidth = window.devicePixelRatio / this.zoomLevel
            // this.brushCircle.blendMode = "difference"   // this has no effect for some reason :'(
            this.brushCircle.visible = false
        }
        const cursorPosition = this.canvasCursorPosition
        this.brushCircle.visible = (this.selected && this.isPointInImage(cursorPosition)) || this._isDrawing
        if (this.brushCircle.visible) {
            this.brushCircle.position = new paper.Point(cursorPosition)
        }
        this.brushLine.visible = this.brushCircle.visible && this.drawLine
        if (this.brushLine.visible) {
            this.brushLine.strokeWidth = window.devicePixelRatio / this.zoomLevel
            this.brushLine.segments[0].point = new paper.Point(this.lastStrokePoint!.x, this.lastStrokePoint!.y)
            this.brushLine.segments[1].point = new paper.Point(cursorPosition.x, cursorPosition.y)
        }
    }

    private async startBrushStroke(point: Vector2Like): Promise<void> {
        const position = this.getCursorPosition(point)
        if (!this._brushStartPosition) {
            this._brushStartPosition = position
            this._brushCurrentPosition = this._brushStartPosition.clone()
        }
        await this.currentStrokeImage.clear()
        await this.halBlitter.paint(this.preStrokeBrushedImage, this.brushedImage)
        if (this.drawLine) {
            this.halStrokeGeometry.startNewStroke()
            this.halStrokeGeometry.pushStrokePoints(Vector2.fromVector2Like(this.lastStrokePoint).addInPlace(this.brushCursorOffset), position)
        } else {
            this._isDrawing = true
            this.halStrokeGeometry.startNewStroke()
            this.halStrokeGeometry.pushStrokePoints(position)
        }
        this.lastStrokePoint = point
        await this.onStrokePathChanged()
    }

    private async drawBrushStroke(_lastPoint: Vector2Like, point: Vector2Like): Promise<void> {
        if (!this._isDrawing) {
            // throw Error("Attempting to draw a brush-stroke without starting any.")
            return
        }
        const position = this.getCursorPosition(point)
        const minStrokeDistanceInScreenPixels = 3
        const minStrokeDistance = minStrokeDistanceInScreenPixels / this.zoomLevel
        const delta = Vector2.fromVector2Like(point).sub(this.lastStrokePoint)
        if (delta.norm() >= minStrokeDistance) {
            this.lastStrokePoint = point
            // set up stroke geometry
            this.halStrokeGeometry.pushStrokePoints(position)
            await this.onStrokePathChanged()
        }
        this._brushCurrentPosition?.setFromVector2Like(position)
    }

    private async endBrushStroke(_point: Vector2Like): Promise<void> {
        await this.finalizeStroke()
        this._brushStartPosition = null
        this._isDrawing = false
    }

    private async onStrokePathChanged(): Promise<void> {
        this.strokePathChanged = true
        this.brushStrokeUpdated.emit()
    }

    private async finalizeStroke(): Promise<void> {
        await this.updateStrokeImage()
        this.brushStrokeCompleted.emit()
    }

    protected async updateStrokeImage(): Promise<boolean> {
        if (!this.strokePathChanged) {
            return false
        }
        if (this.currentStrokeImage.descriptor.width <= 0 || this.currentStrokeImage.descriptor.height <= 0) {
            // no image set (yet); postpone flushing the stroke
            return false
        }
        this.strokePathChanged = false
        // update current stroke
        this.halStrokeGeometry.brushSettings = this._brushSettings
        this.halStrokeGeometry.tilingEnabled = true
        const strokeBoundingBox = await this.halStrokeGeometry.paint(this.currentStrokeImage, true)
        this.boundingBox.expandByBox(strokeBoundingBox)

        // blend current stroke into brush stroke
        await this.halBlendCurrentStroke.paint(
            this.brushedImage,
            this.preStrokeBrushedImage,
            this.currentStrokeImage,
            this.brushSettings.brushOpacity,
            this.brushSettings.brushMode,
            strokeBoundingBox,
        )

        if (DISPLAY_BOUNDING_BOX) {
            this.beginPaperCreation()

            if (this.boundingBoxRect) {
                this.boundingBoxRect.remove()
            }
            this.boundingBoxRect = new paper.Path.Rectangle(
                this.boundingBox.min.sub(this._brushCursorOffset),
                this.boundingBox.max.sub(this._brushCursorOffset),
            )
            this.boundingBoxRect.strokeColor = new paper.Color("red")
            this.boundingBoxRect.strokeWidth = 1 / this.zoomLevel

            if (this.currStrokeBoundingBoxRect) {
                this.currStrokeBoundingBoxRect.remove()
            }
            this.currStrokeBoundingBoxRect = new paper.Path.Rectangle(
                strokeBoundingBox.min.sub(this._brushCursorOffset),
                strokeBoundingBox.max.sub(this._brushCursorOffset),
            )
            this.currStrokeBoundingBoxRect.strokeColor = new paper.Color("green")
            this.currStrokeBoundingBoxRect.strokeWidth = 1 / this.zoomLevel
        }

        return true
    }

    private _brushSettings = new BrushSettings()
    private lastStrokePoint: Vector2Like = {x: 0, y: 0}
    private _isDrawing = false
    private strokePathChanged = false
    private readonly halContext: HalContext
    private halStrokeGeometry: HalStrokeGeometry // TODO replace by image-op
    private halBlendCurrentStroke: HalBlendCurrentStroke // TODO replace by image-op
    protected halBlitter: HalPainterImageBlit // TODO replace by image-op
    private readonly currentStrokeImage: HalPaintableImage
    private readonly preStrokeBrushedImage: HalPaintableImage
    private readonly brushedImage: HalPaintableImage
    private boundingBox = new Box2()
    private _brushStartPosition: Vector2 | null = null
    private _brushCurrentPosition: Vector2 | null = null
    private _brushCursorOffset = new Vector2(0, 0)
    private brushCircle: paper.Path.Circle | null = null
    private brushLine: paper.Path.Line
    private brushCircleRadius = 0
    private boundingBoxRect: paper.Path.Rectangle | null = null
    private currStrokeBoundingBoxRect: paper.Path.Rectangle | null = null
    private drawLine = false
    private readonly drawLineKey = "shift"
}

export enum BrushMode {
    Add = "add",
    Subtract = "subtract",
}

export class BrushSettings {
    brushMode = BrushMode.Add
    brushOpacity = 1
    brushWidth = 150
    brushHardness = 0.5
}
