import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {namedNodeParameters} from "@src/templates/nodes/named-node"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {LoadMaterialRevisionNew} from "@src/templates/runtime-graph/nodes/load-material-revision-new"

const materialReferenceParameters = namedNodeParameters.merge(
    z.object({
        materialRevisionId: z.number(),
    }),
)

export type MaterialReferenceParameters = z.infer<typeof materialReferenceParameters>

@registerNode
export class MaterialReference
    extends DeclareTemplateNode({parameters: materialReferenceParameters}, {}, {nodeClass: "MaterialReference"})
    implements EvaluableTemplateNode<IMaterialGraph>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        const {materialGraph} = scope.node(LoadMaterialRevisionNew, {
            sceneManager: evaluator.templateContext.sceneManager,
            materialRevisionId: this.parameters.materialRevisionId,
        })
        return materialGraph
    }
}

export type MaterialReferenceFwd = TemplateNode<MaterialReferenceParameters> & EvaluableTemplateNode<IMaterialGraph>
