<ng-template #sidebar>
    <ng-content></ng-content>
</ng-template>
<!--Large screen navigation-->
@if (isBigScreen) {
    <div class="cm-sidebar-large-screen">
        <div class="cm-sidebar-content">
            <ng-container *ngTemplateOutlet="sidebar"></ng-container>
        </div>
    </div>
}
<!--Small screen navigation-->
<cm-sidenav #sidenav [sidenavOpen]="sidenavOpen && !isBigScreen" (backgroundClick)="sidenavOpen = false">
    <ng-container cm-content>
        @if (!isBigScreen) {
            <ng-container *ngTemplateOutlet="sidebar"></ng-container>
        }
    </ng-container>
</cm-sidenav>
<div (click)="sidenavOpen = !sidenavOpen" matTooltip="Filters" matTooltipPosition="before" class="cm-filter-button">
    <i class="far fa-filter"></i>
</div>
