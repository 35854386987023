import {Component, EventEmitter, Input, Output} from "@angular/core"

@Component({
    standalone: true,
    selector: "cm-toggle",
    templateUrl: "./toggle.component.html",
    styleUrls: ["./toggle.component.scss"],
})
export class ToggleComponent {
    @Input() toggle = false
    @Input() disabled = false
    @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>()
}
