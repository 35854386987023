import {Observable, ReplaySubject, Subject} from "rxjs"

export type TraverseType = string | number | boolean | {$blob: number} | ArrayBufferView | {[key: string]: TraverseType} | null | undefined | TraverseType[]

function extractBlobs(data: TraverseType): [message: TraverseType, blobs: (ArrayBuffer | ArrayBufferView | Blob)[]] {
    const blobs: (ArrayBuffer | ArrayBufferView | Blob)[] = []
    const check_key = (k: unknown) => {
        if (typeof k !== "string") throw new Error(`Dict keys not a string: ${k}`)
        return k
    }
    const traverse = (x: TraverseType): TraverseType => {
        if (typeof x === "object") {
            if (Array.isArray(x)) {
                return x.map(traverse)
            } else if (ArrayBuffer.isView(x) || x instanceof ArrayBuffer || x instanceof Blob) {
                for (let idx = 0; idx < blobs.length; idx++) {
                    if (blobs[idx] === x) {
                        return {$blob: idx}
                    }
                }
                const idx = blobs.length
                blobs.push(x)
                return {$blob: idx}
            } else if (x === null) {
                return null
            } else {
                const ret: Record<string, TraverseType> = {}
                for (const [k, v] of Object.entries(x)) {
                    ret[check_key(k)] = traverse(v)
                }
                return ret
            }
        } else {
            return x
        }
    }
    return [traverse(data), blobs]
}

function resolveBlobs(msg: TraverseType, blobs: ArrayBufferView[]) {
    const traverse = (x: TraverseType): TraverseType => {
        if (typeof x === "object") {
            if (Array.isArray(x)) {
                return x.map(traverse)
            } else if (x === null) {
                return x
            } else if ("$blob" in x) {
                return blobs[x["$blob"] as number]
            } else {
                const ret: TraverseType = {}
                for (const [k, v] of Object.entries(x)) {
                    ret[k] = traverse(v)
                }
                return ret
            }
        } else {
            return x
        }
    }
    return traverse(msg)
}

export class WebSocketMessaging {
    static connect(url: string): Observable<WebSocketMessaging> {
        const socket = new WebSocket(url)
        const connect$ = new Subject<WebSocketMessaging>()
        socket.onopen = (_event) => {
            connect$.next(new WebSocketMessaging(socket))
            connect$.complete()
        }
        socket.onerror = (event) => {
            connect$.error(event)
            connect$.complete()
        }
        return connect$
    }

    private transactions: Subject<TraverseType>[] = []

    message$ = new Subject<TraverseType>()
    close$ = new Subject<void>()

    destroy() {
        this.socket.close()
        this.message$.complete()
    }

    get connected() {
        return this.socket.readyState === this.socket.OPEN
    }

    send(data: TraverseType): void {
        const [msg, blobs] = extractBlobs(data)
        for (let idx = 0; idx < blobs.length; idx++) {
            this.socket.send(JSON.stringify({$send_blob: idx}))
            this.socket.send(blobs[idx])
        }
        this.socket.send(JSON.stringify(msg))
    }

    doTransaction(msg: TraverseType) {
        const response$ = new ReplaySubject<TraverseType>()
        this.transactions.push(response$)
        this.send(msg)
        return response$
    }

    private recvAwaitingBlob = false
    private recvBlobs: ArrayBufferView[] = []

    private constructor(private socket: WebSocket) {
        socket.binaryType = "arraybuffer"
        socket.onerror = this.onError.bind(this)
        socket.onclose = this.onClose.bind(this)
        socket.onmessage = this.onMessage.bind(this)
    }

    private dispatchMsg(msg: TraverseType) {
        const transaction = this.transactions.shift()
        if (transaction) {
            transaction.next(msg)
            transaction.complete()
        } else {
            this.message$.next(msg)
        }
    }

    private dispatchError(err: string | Event) {
        const transaction = this.transactions.shift()
        if (transaction) {
            transaction.error(err)
            transaction.complete()
        } else {
            throw new Error(err.toString())
        }
    }

    private onMessage(event: MessageEvent) {
        let msg = event.data
        if (msg instanceof ArrayBuffer) {
            if (!this.recvAwaitingBlob) {
                this.dispatchError("Expecting JSON, got binary blob!")
                return
            }
            this.recvBlobs.push(new Uint8Array(msg))
            this.recvAwaitingBlob = false
        } else if (ArrayBuffer.isView(msg)) {
            if (!this.recvAwaitingBlob) {
                this.dispatchError("Expecting JSON, got binary blob!")
                return
            }
            this.recvBlobs.push(new Uint8Array(msg.buffer, msg.byteOffset, msg.byteLength))
            this.recvAwaitingBlob = false
        } else if (msg instanceof Blob) {
            this.dispatchError("Got unexpected binary blob type!")
            return
        } else {
            if (this.recvAwaitingBlob) {
                this.dispatchError("Expecting binary blob, got JSON!")
                return
            }
            msg = JSON.parse(msg)
            if (typeof msg === "object" && "$send_blob" in msg) {
                const idx = msg["$send_blob"] as number
                if (idx !== this.recvBlobs.length) {
                    this.dispatchError("Got binary blob out of sequence!")
                    return
                }
                this.recvAwaitingBlob = true
            } else {
                msg = resolveBlobs(msg, this.recvBlobs)
                this.recvBlobs = []
                this.dispatchMsg(msg)
            }
        }
    }

    private onError(event: Event) {
        this.dispatchError(event)
    }

    private onClose() {
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const transaction = this.transactions.shift()
            if (!transaction) break
            transaction.error("Connection closed")
            transaction.complete()
        }
        this.message$.complete()
        this.close$.next()
        this.close$.complete()
    }
}
