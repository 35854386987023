export class State {
    constructor(
        public id: number,
        public label: string,
        public background?: string,
    ) {}

    static get(id: number, states: {[name: string]: State}): State {
        for (const key in states) {
            const state: State = states[key]
            if (state.id === id) {
                return state
            }
        }
        throw new Error(`Unknown state ID: ${id}.`)
    }
}
