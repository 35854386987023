import {computed, inject, Injectable, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {BasicUserInfoFragment, SystemRole} from "@api"
import {IsNonNull} from "@cm/lib/utils/filter"
import {AuthService} from "@common/services/auth/auth.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {StateLabel} from "@labels"
import {distinctUntilChanged, switchMap} from "rxjs"
import {sortUserStateLabelListByLastName} from "@common/helpers/utils/user"

@Injectable({
    providedIn: "root",
})
export class UsersService {
    $all = signal<BasicUserInfoFragment[]>([])
    $visible = signal<BasicUserInfoFragment[]>([])

    $filterOptions = computed<StateLabel<string>[]>(() =>
        sortUserStateLabelListByLastName(
            this.$visible().map((user) => ({
                label: user.name,
                state: user.id,
            })),
        ),
    )
    $scannerUserOptions = computed<StateLabel<string>[]>(() =>
        this.$all()
            .filter((user) => user.role === SystemRole.Scanner)
            .map((user) => ({
                label: user.name,
                state: user.id,
            })),
    )

    auth = inject(AuthService)
    sdk = inject(SdkService)

    //TODO: load all users either at startup or on demand
    constructor() {
        this.auth.userId$
            .pipe(
                takeUntilDestroyed(),
                distinctUntilChanged(),
                switchMap(async (userId) => {
                    switch (userId) {
                        case null: {
                            return []
                        }
                        case undefined: {
                            return undefined
                        }
                        default: {
                            const {users} = await this.sdk.gql.allUsers()
                            return users
                        }
                    }
                }),
            )
            .subscribe((users: (BasicUserInfoFragment | null)[] | undefined) => {
                this.$all.set(users?.filter(IsNonNull) ?? [])
                this.$visible.set(users?.filter(IsNonNull)?.filter((user) => user.visibleInFilters) ?? [])
            })
    }

    byLegacyId(id: number) {
        return this.$visible().find((user) => user.legacyId === id)
    }

    byId(id: string) {
        return this.$visible().find((user) => user.id === id)
    }
}
