import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {eulerValue} from "@src/templates/types"
import {namedNodeParameters} from "@src/templates/nodes/named-node"
import {z} from "zod"
import {visitNone} from "@src/graph-system/declare-visitor-node"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {ThisStructID} from "@src/templates/runtime-graph/types"

const hdriLightParameters = namedNodeParameters.merge(
    z.object({
        hdriId: z.number(),
        rotation: eulerValue,
        intensity: z.number(),
        clampHighlights: z.number().optional(),
        mirror: z.boolean(), // (mirror on X axis)
    }),
)
export type HDRILightParameters = z.infer<typeof hdriLightParameters>

@registerNode
export class HDRILight extends DeclareTemplateNode(
    {parameters: hdriLightParameters},
    {
        onVisited: {
            onCompile: function (this: HDRILight, {context, parameters}) {
                const {evaluator, templateDepth, currentTemplate} = context
                const {preDisplayList} = currentTemplate
                const {templateContext} = evaluator
                const {sceneManager} = templateContext
                const {clampHighlights, intensity, rotation, mirror, hdriId} = parameters

                const scope = evaluator.getScope(this)

                const environment = scope.struct<SceneNodes.Environment>("Environment", {
                    type: "Environment",
                    id: ThisStructID,
                    clampHighlights,
                    intensity,
                    rotation: sceneManager.vectorFromArray(rotation),
                    mirror,
                    envData: {
                        type: "hdri",
                        hdriID: hdriId,
                    },
                    priority: templateDepth,
                })
                preDisplayList.push(environment)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "HDRILight"},
) {}

export type HDRILightFwd = TemplateNode<HDRILightParameters>
