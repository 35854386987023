import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-placeholder",
    standalone: true,
    imports: [],
    templateUrl: "./placeholder.component.html",
    styleUrl: "./placeholder.component.scss",
})
export class PlaceholderComponent {
    @Input() dark = false
    @Input() loading = true
    @Input() format: "round" | "rect" | "circle" = "round"
}
