import * as moment from "moment/moment"

export const formatForDisplay = (date?: string | Date | null, options: {shorten: boolean} = {shorten: true}) => {
    if (date) {
        const momentDate = moment(date)
        if (options.shorten) {
            const isToday = momentDate.isSame(moment(), "day")
            if (isToday) {
                return momentDate.format("HH:mm")
            }
            const isYesterday = momentDate.isSame(moment().subtract(1, "days"), "day")
            if (isYesterday) {
                return `yesterday at ${momentDate.format("HH:mm")}`
            }
            const isTomorrow = momentDate.isSame(moment().add(1, "days"), "day")
            if (isTomorrow) {
                return `tomorrow at ${momentDate.format("HH:mm")}`
            }
            const isThisYear = momentDate.isSame(moment(), "year")
            if (isThisYear) {
                return `${momentDate.format("Do MMM")} ${momentDate.format("HH:mm")}`
            }
        }
        return `${momentDate.format("Do MMM YY")} at ${momentDate.format("HH:mm")}`
    }
    return null
}

export const formatTimeDifference = (fromDate?: string | Date, toDate?: string | Date) => {
    if (!fromDate || !toDate) {
        return null
    }
    const from = moment(fromDate)
    const to = moment(toDate)
    const duration = moment.duration(to.diff(from, "milliseconds"))
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()
    if (days > 0) {
        return `${days}d ${hours}h ${seconds}s`
    }
    if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`
    }
    if (minutes > 0) {
        return `${minutes}m ${seconds}s`
    }
    return `${seconds}s`
}
