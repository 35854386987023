import {Component, inject, input, output} from "@angular/core"
import {SectionComponent} from "@common/components/item"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {TaskListComponent, TaskListItemType} from "@platform/components/tasks/task-list/task-list.component"
import {TasksService} from "@platform/services/tasks/tasks.service"

@Component({
    selector: "cm-project-management-tasks-section",
    standalone: true,
    imports: [SectionComponent, ToggleComponent, TaskListComponent],
    templateUrl: "./project-management-tasks-section.component.html",
    styleUrl: "./project-management-tasks-section.component.scss",
})
export class ProjectManagementTasksSectionComponent {
    tasks = inject(TasksService)

    $item = input<TaskListItemType | null | undefined>(null)
    $onAddNewTask = output<void>()
}
