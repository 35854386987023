import objectHash, {NotUndefined} from "object-hash"
import {createHash} from "sha1-uint8array"
import {isBlobLike} from "@src/utils/utils"

export function hashObject(obj: NotUndefined): string {
    return objectHash(obj, {
        algorithm: "sha1",
        encoding: "hex",
        replacer: (value) => {
            // use faster binary hasher for blobs
            if (isBlobLike(value)) {
                const data = new Uint8Array(value.buffer, value.byteOffset, value.byteLength)
                return createHash().update(data).digest("hex")
            } else {
                return value
            }
        },
    })
}
