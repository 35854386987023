import {Socket, SocketValueType} from "@node-editor/models/socket"

export function keyForSocket(socket: Socket): string {
    return `${socket.type}:${socket.id}`
}

export interface NodeAccessor<NodeT> {
    getNodeId(node: NodeT): NodeId
    getPosition(node: NodeT): [number, number]
    getParameter(node: NodeT, parameterId: ParameterId): ParameterValue
    setParameter(node: NodeT, parameterId: ParameterId, value: ParameterValue, type: ParameterType): void
    setParameterNoUpdate(node: NodeT, parameterId: ParameterId, value: ParameterValue, type: ParameterType): void
    getInputConnections(node: NodeT): InputConnection<NodeT>[]
    disabled(node: NodeT): boolean
    disableable(node: NodeT): boolean
    toggleDisabled(node: NodeT): void
}

export type ParameterId = string
export type ParameterValue = any
export type ParameterType = SocketValueType | "string" | "boolean"
export type NodeId = string

export type InputConnection<NodeT> = {
    sourceNodeId: NodeId
    sourceSocketId: string
    destinationSocketId: string
}

export type ConnectionEvent<NodeT> = {
    sourceNode: NodeT
    sourceSocketId: string
    destinationNode: NodeT
    destinationSocketId: string
}

export type MoveEvent<NodeT> = {
    node: NodeT
    position: [number, number]
}

export type SelectEvent<NodeT> = {
    node: NodeT | null
}

export type ParameterEvent<NodeT> = {
    node: NodeT
    type: ParameterEventType
    parameter: {
        id: ParameterId
        value?: ParameterValue
        type?: ParameterType
    }
}

export type ParameterEventType = "add" | "update" | "delete" | "updateGraph"
