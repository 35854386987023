import {ErrorHandler, Injectable, inject} from "@angular/core"
import {MatSnackBarRef, TextOnlySnackBar} from "@angular/material/snack-bar"
import {AuthService} from "@common/services/auth/auth.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {z} from "zod"

@Injectable({
    providedIn: "root",
})
export class ErrorHandlerService implements ErrorHandler {
    private authService = inject(AuthService)
    private notifications = inject(NotificationsService)
    private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined

    constructor() {}
    handleError(error: unknown) {
        console.error(error)

        if (this.authService.isStaff()) {
            const parsedError = z.object({message: z.string()}).safeParse(error)
            const message = parsedError.success ? parsedError.data.message || "An unspecific error occurred" : "An unspecific error occurred"

            if (this.snackBarRef)
                this.snackBarRef.afterDismissed().subscribe(() => {
                    this.snackBarRef = this.notifications.showError(`Error: ${message}`, undefined, "Dismiss")
                })
            else this.snackBarRef = this.notifications.showError(`Error: ${message}`, undefined, "Dismiss")

            this.snackBarRef.afterDismissed().subscribe(() => {
                this.snackBarRef = undefined
            })
        }
    }
}
